
import { QUESTIONS } from 'EntityModel/entity-questions.yaml'
import RightView from 'MinuteBookPanelProvider/right-view/RightView'
import { DataComponent } from 'DataModel'
import { computed } from 'utils/decorator'
import { color } from 'styles'
import t from 't'

type WritingDocument = import('../WritingDocument').default

export default class WritingRightViewDocOptions extends RightView {
  document: WritingDocument

  constructor (params) {
    super(params)
    const { document } = params
    Object.assign(this, { document })
  }

  get title () { return 'Document Options' }
  get multiTitleItems () { return null }

  static get css () {
    return {
      ...super.css,
      ...this.repeatCSS,
      heading: {
        fontWeight: '600',
        padding: '10px 0 10px 40px',
      },
    }
  }

  * repeatOptions () {
    for (const q of QUESTIONS) {
      const c = DataComponent.getComponentById(q.component)
      if (c.namespace === 'array') {
        const slotGroupCode = 'ac:' + q.identifier
        yield <option value={slotGroupCode}>{q.title}</option>
      } else if (c.namespace === 'entity' && c.canonicalName === 'capital') {
        const holderGroupCode = `ac:${q.identifier}:ccsv`
        const assetGroupCode = `ac:${q.identifier}:ccav`
        yield <option value={holderGroupCode}>{t.CAPITAL_SHAREHOLDERS}</option>
        yield <option value={assetGroupCode}>{t.CAPITAL_ASSETS}</option>
      }
    }
  }

  @computed()
  repeatSlotOptionsHTML () {
    const repeatCode = this.document.repeat()
    if (!repeatCode) { return [] }
    const sm = this.document.slotManager
    const g = sm.slotGroupByCode(repeatCode) || []
    return [...g].map(s => <option value={g.indexOf(s)}>{g.indexOf(s)}</option>)
  }

  get body () {
    const { jss } = this
    return (
      <div class={jss.body}>
        <div class={jss.heading}>Repeat Document For</div>
        <div class={jss.repeatOptions}>
          <select class={jss.repeatSelect} ko-value={this.document.repeat}>
            <option value=''>None</option>
            {this.repeatOptions()}
          </select>
          {/* <select class={jss.repeatSlot} ko-value={this.document.repeatSlot}>
            {this.repeatSlotOptionsHTML()}
          </select> */}
        </div>
      </div>
    )
  }

  static get repeatCSS () {
    return {
      repeatOptions: {
        display: 'flex',
        width: 'calc(100% - 40px)',
        margin: '0px 0px 0px 40px',
      },
      repeatSelect: {
        height: '30px',
        background: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          background: color.systemBackground.dark.primary,
        },
      },
      repeatSlot: {
        height: '30px',
      },
    }
  }
}

WritingRightViewDocOptions.register()
