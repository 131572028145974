import eisCss from 'entity-information-summary/eisCss'
import { color } from 'styles'

import PanelProvider from "PanelProvider"
import PersonSearchCriteria from "search/PersonSearchCriteria"
import { CrudModel } from "DataModel"
import { getClassByResourceName } from 'models'
import { LeftPanelView } from 'minute-box-app/panel-left'
import RootCommandSet from "minute-box-app/RootCommandSet"

import PersonSummaryView from './PersonSummaryView'
import { getCrudModelForPerson } from 'person/utils'

import './person-information-summary'
import 'minute-box-app/panel-left/view-list-panel-left'
import 'model-audit'
import './person-panel-head'

export default class PersonPanelProvider extends PanelProvider {
  get panelID() { return 'Person' }
  person : PersonRecord | KnockoutObservable<PersonRecord>
  crudModel : KnockoutObservable<CrudModel>
  peerPanelList: PanelProvider[]
  activeView : KnockoutObservable<LeftPanelView> = ko.observable()
  views : KnockoutObservableArray<LeftPanelView>
  showToC : KnockoutObservable<boolean> = ko.observable(true)
  showAllFields : KnockoutObservable<boolean> = ko.observable(true)
  private editEntity: (arg0:CrudModel) => Promise<void>
  get CommandSetConstructor () { return RootCommandSet }

  constructor ({app, model, person, hashKey, editEntity, showAudit}) {
    super({app})
    this.editEntity = editEntity

    if (model && model.selfPerson) {
      person = model.selfPerson
    }

    if (!person) {
      person = this.loadPersonFromHash(app, hashKey)
    }

    Object.assign(this, {
      person,
      crudModel: ko.observable(model || null),
      hashKey: hashKey || createHistoryHash(ko.unwrap(person))
    })

    if (!this.crudModel()) {
      this.computed(() => ko.unwrap(this.person))
        .yet(undefined).then(person => this.crudModel(getCrudModelForPerson(person)))
    }

    this.views = ko.observableArray<LeftPanelView>([
      new PersonSummaryView(this),
    ])

    const auditView = ko.observable<LeftPanelView>(null)
    this.crudModel.yet(null).then(() => {
      const v = new PersonAuditView(this)
      this.views.push(v)
      auditView(v)
    })

    this.activeView(this.views()[0])

    if (showAudit) {
      auditView.yet(null).then(v => this.activeView(v))
    }
  }

  get displayName () : KnockoutComputed<string> {
    return this.computed(() => {
      const person = ko.unwrap(this.person)
      if (!person) { return null }
      if (this.crudModel()) {
        return this.crudModel().cvModelTitle
      } else {
        return person.name.length ? person.name[0] : ''
      }
    })
  }

  get main () {
    const leftPanelOpen = this.computed(() => this.showToC() || undefined)
    return (
      <div class={this.jss.mainContainer} left-panel-open={leftPanelOpen}>
        {this.computed(() => (this.activeView().main))}
      </div>
    )
  }

  get left () {
    return (
      <view-list-panel-left
        views={this.views}
        activeViewID={this.computed(() => this.activeView().id)}
        showPanel={this.showToC} />
    )
  }

  async editClick (evt : MouseEvent) {
    if (!this.crudModel() || !this.editEntity) { return }
    evt.stopPropagation()
    evt.preventDefault()
    this.editEntity(this.crudModel())
  }

  get foot () { return null }
  get right () { return <div></div> }

  get topFixed () {
    const showEditButton = this.computed(() => Boolean(this.crudModel()))
    return (
      <person-panel-head style="width:100%"
        panelProvider={this}
        entityName={this.displayName}
        showEditButton={showEditButton}
        editClick={evt => this.editClick(evt)} />
    )
  }

  get head () { return <div></div> }

  static get tableSectionCss () {
    return {
      tableItem: {
        display: 'block',
        margin: '0.8em 0',
      },

      originLink: {
        ...color.dynamic({
          light: { 'shadow': color.grey.contextShadow },
          dark: { 'shadow': color.dmgrey.contextShadow },
        }),
        display: 'inline',
        cursor: 'pointer',
        borderRadius: 5,
        transition: '150ms ease-in-out',
        '&:hover':{
          background: 'var(--shadow)',
          boxShadow: `
            0px 0px 0px 3px var(--shadow),
            -3px 0px 0px 3px var(--shadow),
            3px 0px 0px 3px var(--shadow)`,
        },
      },

      originUnlink: {
        display: 'inline',
        marginLeft: '10px',
        visibility: 'hidden',
        opacity: 0.5,
        '$tableItem:hover &': {
          visibility: 'visible',
        },
        '&:hover': {
          opacity: 1,
        },
      },
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.tableSectionCss,

      mainContainer: {
        '&:not([left-panel-open])': { '--left-panel-default-width' : '0' },
        width: 'calc(100% - var(--left-panel-default-width))',
        transition: '0.25s ease-in-out',
        transform: 'translate3d(var(--left-panel-default-width), 0, 0)',
      },

      fieldContainer: {
        ...eisCss.fieldContainer,
        padding: '20px',
        backgroundColor: color.systemBackground.light.primary,
        borderRadius: '5px',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
        minWidth: '600px',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        '&[hover]:hover': {
          cursor: 'pointer',
          boxShadow: '0 8px 20px 0 rgba(0,0,0,0.12)',
          transition: '300ms box-shadow cubic-bezier(0.4, 0, 0.6, 1)',
        },
      },
      textContent: {
        ...eisCss.textContent,
        ...eisCss.fieldLarge,
      },
      title: {
        ...eisCss.title,
      },
      indicator: {
        ...eisCss.indicator,
        margin: '0',
        width: 'fit-content',
      },

      _subMenu: {
        overflow: 'hidden'
      },

      subMenuHidden: {
        extend: '_subMenu',
        transition: 'unset',
        transitionTimingFunction: 'ease-in',
        maxHeight: 0
      },
      subMenuShown: {
        extend: '_subMenu',
        transition: 'max-height 0.75s',
        transitionTimingFunction: 'ease-out',
        maxHeight: '100vh',
      },

    }
  }

  get historyUrl () { return this.hashKey }

  loadPersonFromHash (app : MinuteBoxApp, hash : string) : KnockoutObservable<PersonRecord> {
    if (!hash || !hash.startsWith('#person--')) { return null }
    const person = ko.observable<PersonRecord>()
    try {
      const uri = hash.substr(10).split('/')
      const personRef = {
        id: uri[0] === 'id' ? decodeURIComponent(uri[1]) : undefined,
        modelResourceName: uri[0] === 's' ? decodeURIComponent(uri[1]) : undefined,
        modelKey: uri[0] === 's' ? decodeURIComponent(uri[2]) : undefined,
        name: uri[0] === 's' ? decodeURIComponent(uri[3]) : undefined,
      }
      if (personRef.id) {
        const personDb = new PersonSearchCriteria(app.memoryDB)
        this.computed(() => {
          person(personDb.modelList().find(p => p.id === personRef.id))
        })
      } else {
        const Model : typeof CrudModel = getClassByResourceName(personRef.modelResourceName)
        Model.vmGet(app.defaultAuthManager, personRef.modelKey)
          .then((model : CrudModel) => {
            this.computed(() => (
              person([...model.getPersons(p => p.name.includes(personRef.name))].pop())
            ))
          })
      }
    } catch (e) {
      console.warn(`Unable to load person from hash ${hash}`, e)
      return null
    }
    return person
  }
}

function createHistoryHash (person : PersonRecord) : string {
  if (!person) { return '#person' }
  if (person.id) {
    return `#person--/id/${person.id}`
  } else {
    const model = person.origin[0].model
    const name = encodeURIComponent(person.name && person.name[0] || '')
    const docPath = encodeURIComponent(model.vmFirestoreDocPath)
    return `#person--/s/${model.vmResourceName}/${docPath}/${name}`
  }
}


class PersonAuditView extends LeftPanelView {
  get id () { return 'audit' }
  get title () { return 'Audit View' }
  get sections () { return null }
  get main () { return <model-audit model={this.panelProvider.crudModel()} /> }
}

