
import PopOver from 'pop-over'
import { color } from 'styles'

export function tooltip (tip, { my, at }, element) {
  if (!tip) { return element }
  return (
    <tool-tip my={my} at={at}>
      <template slot='anchor'>
        <div>{element}</div>
      </template>
      <template slot='content'>
        {tip}
      </template>
    </tool-tip>
  )
}

/**
 * Create a tool-tip menu
 *
 * Usage:
 *
 *   <tool-tip my='bottom center' at='top center'>
 *      <template slot='anchor'>...</template>
 *      <template slot='content'>
 *        ... menu-items ...
 *      </template>
 *   </tool-tip>
 *
 */
export default class ToolTip extends PopOver {
  static get css () {
    return {
      ...super.css,
      transitionBlock: {
        backgroundColor: color.tooltipBackground,
        color: color.tooltipForeground,
        whiteSpace: 'nowrap',
        padding: 'var(--tool-tip-padding, 9px 0.5em)',
        borderRadius: '4px',
        boxShadow: '0px 1px 4px rgba(0,0,0,0.5)',
        fontSize: '16px',

        opacity: 0,
        transform: 'scale3d(1, 0, 1)',
        transition: '0.2s opacity, 0.2s transform',
        transformOrigin: 'top',

        '[popover-open] > &': {
          opacity: 1,
          transform: 'scale3d(1, 1, 1)',
          '&:empty': {
            display: 'none'
          }
        }
      }
    }
  }

  /**
   * Trigger this popover on click.
   *
   * Edge cases to watch out for:
   * 1. Clicking inside the drop-down
   * 2. Clicking inside nested drop-downs
   * 3. Clicking the open-button inside a drop-down
   */
  get anchorEvents () {
    this.locked = false
    return {
      mouseover: (_, evt) => {
        // evt.stopPropagation()
        // evt.preventDefault()
        this.showing.modify(v => true)
      },

      mousemove: (_, evt) => this.mousemove(evt),
      mouseleave: (_, evt) => this.mouseleave(evt),

      contextmenu: (_, evt) => {
        if (!evt.metaKey) { return true }
        evt.preventDefault()
        this.locked = !this.locked
        console.info(`Setting ToolTip lock state to ${this.locked}`)
        return false
      }
    }
  }

  get contentEvents () {
    return {
      mousemove: (_, evt) => this.mousemove(evt),
      mouseleave: (_, evt) => this.mouseleave(evt),
    }
  }

  mousemove (evt) {
    clearTimeout(this.leaveTimer)
    this.leaveTimer = null
  }

  mouseleave (evt) {
    if (!this.leaveTimer) { this.leaveTimer = setTimeout(() => {
      if (!this.locked) { this.showing.modify(v => false) }
    }, 50)}
  }

}

ToolTip.register()
