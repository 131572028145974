import ViewComponent from 'ViewComponent'

/**
 * View component which can trigger a css height animation
 * to or from 'auto'. Works by rendring offscreen to calculate precise
 * height.
 *
 * Use: 'styleMap' parameter will be applied to the component itself via
 * ko-style-map binding with instances of 'height:auto' replaced by the
 * explicit height.
 */
class AnimateHeight extends ViewComponent {
  constructor ( { styleMap }, ...args ) {
    super(...args)

    Object.assign(this, {
      visibleHeight: 0,
      transitionQueue: [],
      appliedMap: ko.observable(styleMap()),
      element: ko.observable(null),
    })

    this.element.yet(null).then(() => this.checkHeight())

    styleMap.subscribe(map => {
      if (!map.height || map.height !== 'auto') {
        this.appliedMap(map)
      } else {
        if (this.visibleHeight > 0) {
          this.appliedMap({...map, height: `${this.visibleHeight}px`})
        } else {
          this.transitionQueue = [
            { ...map,
              position: 'fixed',
              left: '-9999px',
              transform: 'translate(1px, 1px)',
              transition: 'transform 0s 0.01s'
            },
            { ...this.appliedMap(),
              transition: 'transform 0s 0.01s'
            },
            { ...map },
          ]
          this.appliedMap(this.transitionQueue.shift())
        }
      }
    })
  }

  checkHeight () {
    if (this.element() && this.element().clientHeight && this.appliedMap().height === 'auto') {
      this.visibleHeight = this.element().clientHeight
      this.appliedMap({...this.appliedMap(), height: `${this.visibleHeight}px`})
    }
  }

  transitionend (evt) {
    this.checkHeight()
    const nextTransition = this.transitionQueue.shift()
    if (nextTransition) {
      if (this.visibleHeight > 0 && nextTransition.height && nextTransition.height === 'auto') {
        nextTransition.height = `${this.visibleHeight}px`
      }
      this.appliedMap(nextTransition)
    }
  }

  static get css() {
    return {
      container: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        overflow: 'hidden',
      }
    }
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.container}
        ko-set-node={this.element}
        ko-style-map={this.appliedMap}
        ko-event={{ transitionend: evt => this.transitionend(evt)} }>
        <slot name='content' />
      </div>
    )
  }
}

AnimateHeight.register()
