import { inline } from 'icons'
import todayIcon from 'icons/light/calendar'

import { buttons, typography, color } from 'styles'

import 'date-picker'

import EisCapital from './EisCapital'


class View {
  title: string
  html: () => any

  constructor ({ title, html }) {
    Object.assign(this, { title, html })
  }
}

export default class EisShareholders extends EisCapital {
  view: KnockoutObservable<View>
  views: View[]

  constructor (params) {
    super(params)

    Object.assign(this, {
      views: this.makeViews(),
      view: ko.observable(),
    })

    this.view(this.views[0])
  }

  makeViews () {
    const { persons } = this.component
    return [
      new View({
        title: 'By Person',
        html: () => <shareholders-by-holder
          persons={persons}
          relatedGenerator={this.entity}
          transactions={this.component.transactions}
          state={this.state()} />
      }),
      new View({
        title: 'By Class',
        html: () => <shareholders-by-class
          persons={persons}
          relatedGenerator={this.entity}
          transactions={this.component.transactions}
          state={this.state()} />
      }),
      new View({
        title: 'All Transactions',
        html: () => <capital-share-transfers
          persons={persons}
          relatedGenerator={this.entity}
          datetime={this.datetime}
          state={this.state()}
          transactions={this.component.transactions} />
      })
    ]
  }

  get fieldTitle () { return 'Shareholders' }

  static get tabCSS () {
    return {
      tabContainer: {
        marginTop: '-75px',
        display: 'grid',
        gridTemplateColumns: '1fr repeat(3, auto)',
        gridGap: '6px',
      },
      tabPad: {},
      tab: {
        ...buttons.clickable,
        padding: '5px 1em',
        fontFamily: typography.titleFamily,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        backgroundColor: color.systemBackground.light.primary,
        color: '#4a4a4a',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.primary
        },
        transition: 'box-shadow 100ms ease-in-out, border-bottom 100ms ease-in-out',
        height: '34px',

        '&:hover': {
          borderBottom: '1px solid rgba(0,0,0,0)',
          boxShadow: '0px -3px 2px -2px rgba(0,0,0,0.10)',
          transition: 'box-shadow 100ms ease-in-out, border-bottom 100ms ease-in-out'
        },

        '&[selected="true"]': {
          // The proper thing to use for selection is clip-path, but
          // ... safari.
          fontWeight: 'bold',
          backgroundColor: 'white',
          color: '#4a4a4a',
          boxShadow: '0px -3px 2px -2px rgba(0,0,0,0.10)',
          borderBottom: '1px solid rgba(0,0,0,0)'
        }
      },
      table: {
        gridColumn: '1/-1',
      }
    }
  }

  get tabsHTML () {
    const { jss } = this
    const selectView = (evt: Event, view: View) => {
      evt.preventDefault()
      evt.stopPropagation()
      this.view(view)
    }
    return this.views.map((view: View) =>
      <div class={jss.tab}
        selected={this.computed(() => view === this.view())}
        ko-click={evt => selectView(evt, view)}>
        {view.title}
      </div>
    )
  }

  static get dateCSS () {
    return {
      datePicker: {
        display: 'flex',
        alignItems: 'center',
        gridColumnStart: -2,
        height: '40px',
        '& > input': {
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,0.2)',
        textAlign: 'center',
        height: '24px'
        }
      },
      dateInput: {},
      todayButton: {
        ...buttons.clickable,
        padding: '0px 10px',
      },
    }
  }

  get datePickerHTML () {
    const { jss } = this
    return (
      <div class={jss.datePicker}>
        <div class={jss.todayButton} ko-click={() =>
          this.datetime(new Date())}>
          {inline(todayIcon)}
        </div>
        <date-picker-button
          relatedDateGenerator={this.relatedGenerator}
          target={this.datetime} />
      </div>
    )
  }

  get displayHTML () {
    const { jss } = this
    return (
      <div class={jss.tabContainer}>
        <div class={jss.tabPad} />
        {this.tabsHTML}
        {this.datePickerHTML}
        <div class={jss.table}>
          {this.computed(() => this.capitalHTML).extend({ deferred: true })}
        </div>
      </div>
    )
  }

  get capitalHTML () {
    return Object.keys(this.state().authorized).length
      ? this.view().html()
      : this.noCapitalHTML
  }

  get noCapitalHTML () {
    return (
      <div class={this.jss.noCapital}>
        To issue shares you will first need to create a share class in the Authorized Capital section above.
      </div>
    )
  }

  get editHTML () { return }

  get changeButtonHTML () {
    const { jss } = this
    const onClick = (evt: Event) => {
      evt.preventDefault()
      evt.stopPropagation()
      this.openAdvancedDialog()
    }
    // Just keeping this around for potential future use
    return null
    // return (
    //   <div class={jss.changeButton}
    //     ko-click={evt => onClick(evt)}>
    //     Advanced
    //   </div>
    // )
  }

  openAdvancedDialog () {
    const { jss } = this
    global.app.modal(
      <modal-dialog modalTitle='Transactions (Advanced)'>
        <template slot='content'>
          <capital-transactions-advanced class={jss.advanced}
            transactions={this.component.transactions} />
          <div class={jss.modalButtons}>
            <div class={jss.modalCloseButton}
              ko-click={() => global.app.modal(undefined)}>Close</div>
          </div>
        </template>
      </modal-dialog>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.dateCSS,
      ...this.tabCSS,
      displayArea: {
        ...super.css.displayArea,
        marginTop: '60px',
        gridTemplateAreas: `
          'title    title'
          'content  content'
          'border   border'
          '.        change'
        `,
        '[blank] &': {}
      },
      noCapital: {
        textAlign: 'center',
      }
    }
  }
}

EisShareholders.register()
