import DisplayListBaseComponent from 'DisplayListBaseComponent'
import bookmarkIcon from 'icons/solid/bookmark'

export default class BookmarksList extends DisplayListBaseComponent {
  bookComponent: import('EntityModel').BookComponent
  pdfEngine: import('pdf-engine').PDFEngine
  previewLength: number

  get listItemName () { return 'Bookmark' }
  get listItemNamePlural () { return 'Bookmarks' }
  get svgIcon () { return bookmarkIcon }

  constructor (params) {
    super(params)
    Object.assign(this, {
      pdfEngine: params.pdfEngine,
      bookmarks: this.bookComponent.bookmarks,
      previewLength: 100,
      previews: new WeakMap()
    })
  }

  static get css () {
    return {
      ...super.css,
      spinner: {
        '& > svg': {
          marginTop: '5px',
          '--icon-height': '25px',
        },
      },
    }
  }

  /**
   * @param {BookPage} bookPage
   */
  async getBookmarkPreview (bookPage) {
    const book = this.bookComponent
    const section = this.computed(() => {
      const s = book.sectionTree.getSectionOfPage(bookPage)
      return s ? [ <b>{ s.name }</b>, <br /> ] : ''
    })
    const text = await this.pdfEngine.getText(bookPage)
    const textSlice = text.slice(0, this.previewLength).concat('...')
    return this.computed(() => (<>
      {section()}
      {textSlice}
    </>))
  }

  /**
   * @param {BookPage} bookPage
   * @return {Observable.<JSX>}
   */
  previewLoader (bookPage) {
    const { jss } = this
    let preview = this.previews.get(bookPage)
    if (!preview) {
      preview = ko.observable(<loading-spinner class={jss.spinner} />)
      this.previews.set(bookPage, preview)
      this.getBookmarkPreview(bookPage).then(preview)
    }
    return preview
  }

  get contentList () {
    const book = this.bookComponent
    return [...this.bookmarks]
      .map(pageID => book.getPageByString(pageID))
      .filter(bookPage => bookPage)
      .map(bookPage => ({
        bookPage,
        jsx: this.previewLoader(bookPage),
        delete () { book.bookmarks.remove(bookPage.pageID) }
      }))
  }
}

BookmarksList.register()
