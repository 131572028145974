import Big from 'big.js'

import CapitalState from './CapitalState'
import CapitalAction from './CapitalAction'
import { Transaction, ORIGIN } from './Transaction'

export interface AssetCertificate {
  person: string
  number: number
  assetClassID: string
}

/**
 * A `AssetTransferLine` is a line that appears on a
 * Share Transfer Register (STR).
 *
 * A STR usually consists of the following columns:
 *
 *  - transfer number (number)
 *  - date
 *  - number of shares (number)
 *  - share class (string)
 *  - certificates surrendered []
 *    - certificate number
 *    - shares count
 *  - transferred from (person)
 *  - transferred to (person)
 *
 * See: https://github.com/NetPleadings/MinuteBox/issues/121
 */
interface CommonLedgerLine {
  datetime: iso8601
  certificatesIssued: AssetCertificate[]
  certificatesSurrendered: AssetCertificate[]
  transferNumber: number
  transactionTitle: string
  [ORIGIN]: Transaction
}

/**
 * A `AssetTransferLine` appears on a Share Transfer Register
 */
export interface AssetTransferLine extends CommonLedgerLine {
  parts: AssetTransferLinePart[]
}

export interface AssetTransferLinePart {
  person: string
  amount: string
  assetClassID: string
  asset: {
    classification: string,
    series: string
    prefix: string
  }
}

/**
 * A `AssetLedgerLine` appears on a Shareholder register
 */
export interface AssetLedgerLine extends CommonLedgerLine {
  holder: string
  delta: Big
  balance: Big
  counterParties: Set<string>
  assetClassID: string
  asset: AssetLedgerLineDescription,
  [ORIGIN]: Transaction
}

export interface AssetLedgerLineDescription {
  classification: string
  series: string
  prefix: string
}

export interface SerializedAction {
  [any: string]: any
}


export interface LedgerAction extends CapitalAction {
  assetClassID: string

  /**
   * Update the Share Transfer Register (multi-part) line.
   */
  updateTransferLine (line: AssetTransferLine, state: CapitalState) : void

  /**
   * @return {boolean} true when the given share transaction affects the person
   */
  affects (holder: string, state: CapitalState) : boolean

  /**
   * @return {boolean} true when the given transaction involves the given class
   */
  involves (assetClassID: string) : boolean

  /**
   * Update the Shareholder Ledger
   */
  updateLedgerLine (line: AssetLedgerLine, state: CapitalState) : void
}

export interface ShareTransferRegister {
  lines: AssetTransferLine[]
}

export interface ShareholderLedger {
  person: string
  assetName: string
  assetPrefix: string
  assetClassID: string
  lines: AssetLedgerLine[]
}

export enum CapitalAssetPropertyType {
  bool = 'bool',
  numeric = 'numeric',
  series = 'series',
  money = 'money', // currency + amount
  flag = 'flag', // like a boolean, but its presence only indicates "yes"
}

export type CapitalAssetPropertyChoice = {
  key: string
  title: string
  type: CapitalAssetPropertyType
  default: string | number | boolean
}

export interface CapitalAssetProperty {
  type: CapitalAssetPropertyType
  key: string
  value: KnockoutObservable<boolean|number|string>
  note: KnockoutObservable<string>
}
