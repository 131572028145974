import ViewComponent from "ViewComponent"
import { color } from 'styles'

/**
 *
 * An alternative to <tool-tip> for cases where we can't provide the anchor as a slot.
 *
 * Similar to <tool-tip> except:
 *  1) anchored to an externally defined rect instead of a slot
 *  2) caller must specify when to display
 *  3) doesn't close while mouse is hovering content
 *
 **/
export default class FloatingToolTip extends ViewComponent {
  public anchor: KnockoutObservable<ClientRect | DOMRect> // global coords
  public my: string // e.g. my='top left' at='bottom left'
  public at: string
  public showing: KnockoutObservable<boolean> // optional, if omitted use truthiness of anchor

  private element: KnockoutObservable<HTMLElement> = ko.observable()
  private hoverEvt: KnockoutObservable<MouseEvent> = ko.observable()
  private cachedAnchor: KnockoutObservable<ClientRect | DOMRect> = ko.observable()
  private _showing: KnockoutComputed<boolean>
  private myArr: string[]
  private atArr: string[]
  private content: MaybeObservable<JSX>

  constructor ({ anchor, my, at, showing, content }) {
    super()
    Object.assign(this, {
      anchor, my, at,
      showing: showing || this.computed(() => Boolean(anchor())),
      myArr: my.split(' ').filter(v => v),
      atArr: at.split(' ').filter(v => v),
      content: content,
    })

    this._showing = this.computed(() => Boolean(this.hoverEvt() || this.showing()))
    if (ko.isWriteableObservable(this.showing)) {
      this.computed(() => [this._showing(), this.showing()])
        .subscribe(([_showing]) => this.showing(_showing))
    }

    this.computed(() => { if (this.anchor()) { this.cachedAnchor(this.anchor()) } })

    // Can't force global positioning unless we attach to the body directly
    this.element.yet(undefined).then(element => {
      document.body.appendChild(element)
    })
  }

  dispose () {
    this.element.yet(undefined).then(e => e.remove())
    super.dispose()
  }

  static get css () {
    return {
      tooltip: {
        position: 'fixed',
        visibility: 'hidden',
        top: 0,
        left: 0,
        zIndex: 10,
        backgroundColor: color.tooltipBackground,
        color: color.tooltipForeground,
        whiteSpace: 'nowrap',
        padding: 'var(--tool-tip-padding, 9px 0.5em)',
        borderRadius: '4px',
        boxShadow: '0px 1px 4px rgba(0,0,0,0.5)',
        fontSize: '16px',
        cursor: 'pointer',
      },
    }
  }

  get xOffset () : number {
    const content = this.element().getBoundingClientRect()
    const anchor = this.cachedAnchor()
    let x = anchor.left
    switch (this.atArr[1]) {
      case 'center': x += (anchor.width / 2); break
      case 'right': x += anchor.width; break
    }
    switch (this.myArr[1]) {
      case 'center': x -= (content.width / 2); break
      case 'right': x -= content.width; break
    }
    return x
  }

  get yOffset () : number {
    const content = this.element().getBoundingClientRect()
    const anchor = this.cachedAnchor()
    let y = anchor.top
    switch (this.atArr[0]) {
      case 'center': y += (anchor.height / 2); break
      case 'bottom': y += anchor.height; break
    }
    switch (this.myArr[0]) {
      case 'center': y -= (content.height / 2); break
      case 'bottom': y -= content.height; break
    }
    return y
  }

  get tooltipStyle () : string {
    if (!this._showing() || !this.cachedAnchor() || !this.element()) { return '' }
    const [ x, y ]  = [ this.xOffset, this.yOffset ]
    return `
      visibility: visible;
      transform: translate3d(${x}px, ${y}px, 0);
    `
  }

  get contentHTML () { return this.content || <slot name='content' /> }

  get template () {
    const { jss } = this
    const style = this.computed(() => this.tooltipStyle)
    return (
      <div class={jss.tooltip} style={style} ko-set-node={this.element} ko-hover={this.hoverEvt}>
        {this.contentHTML}
      </div>
    )
  }
}

FloatingToolTip.register()
