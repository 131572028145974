
import { decodeFromBase64, encodeObject } from './base64url'

export function createPublicKey (challenge, userId, email) : PublicKeyCredentialCreationOptions{
  return {
    challenge: decodeFromBase64(challenge),
    rp: {
      id: location.hostname,
      name: "MinuteBox"
    },
    user: {
      id: new TextEncoder().encode(userId),
      name: 'name ' + email,
      displayName: 'displayName' + email,
    },
    pubKeyCredParams: [{
      type: 'public-key',
      alg: -7, // ECDSA + SHA256
    }],
    timeout: 60000,
    attestation: 'direct',
  }
}

/**
 *
 * @param authManager
 */
export async function challengeAuthenticator (authManager) {
  const { email } = authManager.firebaseUser()
  const { challenge, allowCredentials } = await authManager.firebaseFn('authenticatorChallenge', { email })
  return verifyChallenge(authManager, challenge, allowCredentials)
}

export async function signChallenge (challenge, allowCredentials) {
  const publicKey = {
    timeout: 60000,
    challenge: decodeFromBase64(challenge).buffer,
    allowCredentials: allowCredentials.map(c => ({
      ...c,
      id: decodeFromBase64(c.id).buffer,
    }))
  }
  const resp = await navigator.credentials
    .get({ publicKey }) as PublicKeyCredential
  return encodeObject(resp)
}

export async function verifyChallengeSignature (authManager, encoded, params) {
  const args = {...encoded, ...params }
  return authManager.firebaseFn('authenticatorVerification', args)
}

export async function verifyChallenge (authManager, challenge, allowCredentials, params = {}) {
  const encoded = await signChallenge(challenge, allowCredentials)
  console.debug(`challengeAuthenticator`, encoded)
  return {
    credID: encoded.id,
    result: await verifyChallengeSignature(authManager, encoded, params),
  }
}
