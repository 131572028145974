
import ViewComponent from 'ViewComponent'

import { buttons, color, typography } from 'styles'

/**
 * `<yes-no>` is a simple boolean picker.
 */
export default class YesNo extends ViewComponent {
  value: KnockoutObservable<boolean>

  constructor({ value }) {
    super()
    Object.assign(this, { value })
  }

  static get css () {
    return {
      ...super.css,
      choice: {
        display: 'flex',
        padding: '4px 5px',
        fontFamily: typography.bodyFontFamily,
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
        },
        borderRadius: 15,
      },
      option: {
        ...buttons.clickable,
        padding: '4px 6px',
        borderRadius: 10,

        '&[selected]': {
          backgroundColor: color.fill.light.secondary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary,
          },
        }
      },
    }
  }

  get template () {
    const { jss } = this
    const obs = this.value
    const yes = () => obs() === true || undefined
    const no = () => obs() === false || undefined

    return (
      <div class={jss.choice}>
        <div class={jss.option}
          ko-ownClick={() => obs(true)}
          selected={this.computed(yes)}>
            Yes
          </div>
        <div class={jss.option}
          ko-ownClick={() => obs(false)}
          selected={this.computed(no)}>
            No
          </div>
      </div>
    )
  }
}

YesNo.register()
