
import {
  typography, color, border, buttons
} from 'styles'

export default {
  fieldContainer: {
    ...typography.face.text,
    minWidth: '600px',
    color: color.gray.aa,
    'body[dark] &': { // project batman
      color: color.text.dark.primary,
    },
    '--change-area-margin': '15px',

    '&[blank]:not([focused])': {
      opacity: '0.5',
      transition: 'opacity 100ms',
      '&:hover':{
        opacity: '1',
        transition: 'opacity 100ms'
      }
    }
  },

  displayArea: {
    display: 'grid',
    gridTemplateAreas: `
      'title    title'
      'content  change'
      'border   border '
    `,
    gridTemplateColumns: '1fr auto',
    justifyContent: 'space-between',
    backgroundColor: color.systemBackground.light.primary,
    borderRadius: '5px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
    padding: '20px',
    zIndex: 1,
    position: 'relative',
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.primary,
    },

    '[focused] &': {
      //boxShadow: '0 0 3px 0 rgba(0,0,0,0.30), -8px 0px 0px 0px #ffd502',
      boxShadow: '0 8px 20px 0 rgba(0,0,0,0.12)',
      //transition: 'box-shadow 250ms ease-out',
      transition: '300ms box-shadow cubic-bezier(0.4, 0, 0.6, 1)',
      background: 'linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(252,252,252,1) 51%,rgba(255,255,255,1) 100%)',
      'body[dark] &': { // project batman
        background: 'linear-gradient(135deg, rgba(0,0,0,1) 0%,rgba(10,10,17,1) 51%,rgba(0,0,0,1) 100%)',
      },
    },

    '[blank] &': {
      padding: '15px 20px',
      marginBottom: '-10px'
      // gridTemplateAreas: `
      //   'title change'
      // `,
    }
  },

  title: {
    ...typography.face.title,
    color: '#666666',
    textTransform: 'none',
    fontWeight: '400',
    fontStyle: 'italic',
    fontSize: '0.7rem',
    paddingLeft: 5,
    letterSpacing: '0.6px',
    marginBottom: 5,
    '--icon-font-size': '1rem',
    '[blank] &': {
      '--icon-font-size': '0.75rem',
      marginTop: 6,
      marginBottom: 5,
    }
  },

  titleArea: {
    extend: 'title',
    gridArea: 'title',
    'body[dark] &': { // project batman
      color: color.text.dark.primary,
    },

    '[blank=true] &': {
      gridRow: '1/-1',
      alignSelf: 'center',
    },
  },

  titleEdit: {
    extend: 'title',
    gridArea: 'title',
    color: color.text.light.primary,
    'body[dark] &': { // project batman
      color: color.text.dark.altPrimary,
    },
  },

  fieldLarge: {
    fontSize: '1.5rem'
  },

  qtip: {},

  copyIcon: {
    '--icon-color': '#4a90e2',
    margin: '0 10px',
    visibility: 'hidden',
    '$fieldContainer:not([blank]):hover &': {
      visibility: 'visible',
    },
    '&:hover': {
      '--icon-color': '#4a90e2',
      opacity: '0.5',
    },
    '&:active': {
      '--icon-color': '#4a90e2',
      opacity: '1.0'
    }
  },

  textContent: {
    borderRadius: 3,
    width: 'auto',
    padding: '3px 5px',
    marginBottom: 1,
    background: color.systemBackground.light.primary,
    'body[dark] &': { // project batman
      background: color.systemBackground.dark.primary,
    },
    boxShadow: 'inset -2px -2px 10px 0px rgba(0,0,0,0)',
    '&[focused]': {
      borderRadius: 3,
      width: 'fit-content',
      padding: '3px 5px',
      marginBottom: 1,
      background: color.color.light.brandYellow,
      'body[dark] &': { // project batman
        background: color.color.dark.yellow,
        color: color.text.dark.altPrimary
      },
      boxShadow: 'inset 0px 0.5px 3px 0px rgba(0,0,0,0.7)',
      transition: 'box-shadow 300ms ease-out, background 100ms ease-out'
    },
    '&[blank]': {
      color: 'grey',
      'body[dark] &': { // project batman
        color: color.text.dark.secondary,
      },
      fontStyle: 'italic',
    }
  },

  contentArea: {
    gridArea: 'content',
    '[blank=true] &': {
      display: 'none',
    },
    '[blank=true] [editing=true] &': {
      display: 'block',
    },
  },

  indicatorArea: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },

  indicatorTitleArea: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  indicator: {
    color: color.text.light.altPrimary,
    'body[dark] &': { // project batman
      color: color.text.dark.altPrimary,
    },
    margin: '4px 0 4px 4px',
    padding: 5,
    fontSize: '0.6em',
    fontFamily: typography.bodyFontFamily,
    fontWeight: '400',
    borderRadius: 3,
    textTransform: 'uppercase',
    letterSpacing: 0.8,
    background: '#4A90E2',
    '&[focused]': {
      extend: '_indicatorFocused',
    },
  },

  _indicatorFocused: {
    borderRadius: 3,
    width: 'fit-content',
    color: color.text.light.primary,
    background: color.color.light.brandYellow,
    boxShadow: 'inset 0px 0.5px 3px 0px rgba(0,0,0,0.7)',
    transition: 'box-shadow 300ms ease-out, background 100ms ease-out'
},

  changeButton: {
    gridArea: 'change',
    ...buttons.clickable,
    color: color.color.light.brandBlue,
    'body[dark] &': { // project batman
      color: color.color.dark.brandBlue,
    },
    fontWeight: 'bold',
    fontFamily: typography.bodyFontFamily,
    padding: '0px 20px',
    lineHeight: '1.8em',
    fontSize: '0.7em',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    transition: 'color 100ms',

    '&:hover': {
      color: color.light.eisChangeButtonHover,
      'body[dark] &': { // project batman
        color: color.dark.eisChangeButtonHover,
      },
      transition: 'color 120ms'
    },

    '[blank=true] &': {
      gridRow: '-1/1',
      alignSelf: 'center',
    },

    '[eis-field][read-only] &': {
      display: 'none',
    }
  },

  displayAreaBorder: {
    gridArea: 'border',
    ...border.dottedBottom,
    height: 1,
    width: '100%',

    '[blank] &': {
      display: 'none'
    }
  },

  changeArea: {
    gridArea: 'change',
    position: 'relative',
  },

  fieldEditing: {
    display: 'grid',
    grid: {
      templateAreas: `
        'title    title    title'
        'content  content  content'
        '-        button1  button2 '`,
      templateColumns: '1fr auto auto',
      rowGap: '5px',
    },
    width: '100%',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
    transform: 'translate(0, -5px)',
    backgroundColor: color.color.light.brandYellow,
    'body[dark] &': { // project batman
      backgroundColor: color.color.dark.brandYellow,
    },
    borderRadius: '0 0 5px 5px',
    margin: '0 var(--change-area-margin)',
    padding: '20px',
    position: 'relative',
    zIndex: '0',
  },

  peek: {
    extend: 'fieldEditing',
    position: 'absolute',
    zIndex: '-1',
    height: '0px',
    padding: '0px',
    width: 'calc(100% - 2 * var(--change-area-margin))',
    transition: '200ms cubic-bezier(.17,.67,.3,.73)',
    transform: 'rotate(0.5deg) translate(0px, -10px)',
    '&[focused]:not([editing])': {
      height: '20px',
    },
  },

  hideEdit: {
    height: '0',
    transition: 'height 0.2s ease-in',
  },

  showEdit: {
    height: 'auto',
    transition: 'height 0.2s ease-out',
  },

  discardButton: {
    gridArea: 'button1',
    ...buttons.modalCancel,
    fontWeight: '600',
    'body[dark] &': { // project batman
      color: '#424242',
      backgroundColor: 'white'
    },
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5), inset 0px 0px 0px 2px rgba(0,0,0,0)',
    marginTop: 30,
    '&:hover': {
      boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5), inset 0px 0px 0px 2px #ffd502'
    }
  },

  saveButtonGridItem: {
    gridArea: 'button2',
  },

  saveButton: {
    ...buttons.modalOk,
    fontWeight: '600',
    //letterSpacing: '0.05em',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
    marginTop: 30,
    '&:hover': {
      color: color.light.saveButtonHoverText,
      backgroundColor: color.color.light.brandYellow,
      'body[dark] &': { // project batman
        color: color.dark.saveButtonHoverText,
        backgroundColor: color.color.dark.black
      },
    }
  },
}
