
import { sortBy } from 'lodash-es'

import { ShareholderLedger } from 'capital/interfaces'
import { buttons, dropdown, color } from 'styles'
import { inline } from 'icons'
import menuIcon from 'icons/solid/caret-down'

import ShareholdersBy from './ShareholdersBy'

import './capital-share-split-editor'
import './capital-conversion-type-choice'

export class ShareholdersByClass extends ShareholdersBy {
  get groupBy () { return 'assetClassID' }

  static get css () {
    return {
      ...super.css,
      ...this.issueCSS,
      ...this.menuCSS,
      holderName: {
        gridColumnStart: 2
      },
      assetName: {
        fontWeight: '500',
        marginRight: 20
      }
    }
  }

  get headingsHTML () {
    const { jss } = this
    return (
      <>
        <div class={jss.head}>Classification</div>
        <div class={jss.head}>Holder</div>
        <div class={jss.head}>Amount</div>
        <div class={jss.head}></div>
      </>
    )
  }

  * groupLine (ledger: ShareholderLedger, balance: string, percent: string) {
    const { jss } = this
    yield (
      <div class={jss.holderName}>
        {this.persons.getNameOf(ledger.person)}
      </div>
    )
    yield (<div class={jss.balance} title={`${percent}%`}>{balance}</div>)
  }

  groupName (assetClassID) {
    const { jss } = this
    const { prefix, classification, series } = this.state.getAsset(assetClassID).assetNameParts
    return (
      <>
      <div class={jss.assetName}>
        <capital-asset-name prefix={prefix} classification={classification} series={series} />
      </div>
      <div class={jss.menuIcon}>
      <drop-down my='top left' at='bottom left'>
        <template slot='anchor'>
          <div class={jss.buttonIcon}>
            {inline(menuIcon)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.menu}>
            <div class={jss.groupNameLink}
              ko-ownClick={evt => this.issueClick(evt, assetClassID)}>Issue</div>
            <div class={jss.groupNameLink}
              ko-ownClick={evt => this.cancelClick(evt, assetClassID)}>Cancel</div>
            <div class={jss.groupNameLink}
              ko-ownClick={evt => this.splitClick(evt, assetClassID)}>Split</div>
            <div class={jss.groupNameLink}
              ko-ownClick={evt => this.convertClick(evt, assetClassID)}>Convert</div>
            <div class={jss.groupNameLink}
              ko-ownClick={evt => this.transferClick(evt, assetClassID)}>Transfer</div>
          </div>
        </template>
      </drop-down>
      </div>
      </>
    )
  }

  static get menuCSS () {
    return {
      menu: {
        ...dropdown.generalMenu,
      },
      menuIcon: {
        ...buttons.clickable,
        borderRadius: '5px',
        padding: '0px 20px',
        marginLeft: 'auto',
        '--icon-color': color.icon.light.secondary,
        '&:hover': {
          '--icon-color': color.icon.light.primary,
        },
        'body[dark] &': { // project batman
          '--icon-color': color.icon.dark.secondary,
          '&:hover': {
            '--icon-color': color.icon.dark.primary,
          }
        },
      },
      groupNameLink: {
        ...super.css.groupNameLink,
        ...dropdown.item,
        opacity: '1',
        visibility: '',
        display: 'block',
      },
    }
  }

  sortedKeys (byGroup: Record<string, ShareholderLedger[]>) : string[] {
    return sortBy(Object.keys(byGroup), id => this.getAssetName(id))
  }

  issueClick (evt: Event, assetClassID: string) : void {
    const { jss } = this
    const assetName = this.getAssetName(assetClassID)
    const title = `Issue ${assetName}`
    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <capital-share-issue-editor class={jss.issueEditor}
            persons={this.persons}
            relatedGenerator={this.relatedGenerator}
            assetClassID={assetClassID}
            transactions={this.transactions} />
        </template>
      </modal-dialog>
    )
  }

  cancelClick (evt: Event, assetClassID: string) : void {
    const { jss } = this
    const assetName = this.getAssetName(assetClassID)
    const title = `Cancel ${assetName}`
    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <capital-share-cancel-editor class={jss.cancelEditor}
            persons={this.persons}
            relatedGenerator={this.relatedGenerator}
            assetClassID={assetClassID}
            transactions={this.transactions} />
        </template>
      </modal-dialog>
    )
  }


  splitClick (evt: Event, assetClassID: string) : void {
    const { jss } = this
    const assetName = this.getAssetName(assetClassID)
    const title = `Split ${assetName}`

    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <capital-share-split-editor class={jss.splitEditor}
            relatedGenerator={this.relatedGenerator}
            assetClassID={assetClassID}
            transactions={this.transactions} />
        </template>
      </modal-dialog>
    )
  }

  convertClick (evt: Event, assetClassID: string) : void {
    const { jss } = this

    const assetName = this.getAssetName(assetClassID)
    const title = `Convert ${assetName}`

    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <capital-conversion-type-choice class={jss.splitEditor}
            persons={this.persons}
            relatedGenerator={this.relatedGenerator}
            assetClassID={assetClassID}
            transactions={this.transactions}
          />
        </template>
      </modal-dialog>
    )
  }

  static get issueCSS () {
    return {
      issueEditor: {},
      cancelEditor: {}
    }
  }
}

ShareholdersByClass.register()
