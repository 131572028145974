import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'

export class DomainName extends UnitView {
  get elvValue () { return this.domain }

  get fields () {
    return {
      domain: 'string',
      registrar: 'string',
      expiry: 'pureDate',
    }
  }
}

export default class DomainNamesComponent extends ArrayComponent {
  get ItemClass () { return DomainName }
}

DomainNamesComponent.register()
