/**
 * Common styles used by the EIS and PIS views
 */

import {
  typography, color
} from 'styles'

const narrowRules = {
  '@media (max-width: 1250px)': {
    //categoryParts: { gridColumn: '1/-1' },
    categoryTitle: {
      gridRow: 'auto',
      paddingTop: '30px',
      paddingBottom: 0,

      '&:first-child': {
        paddingTop: 0,
      },
    },
    categoryLabel: {
      backgroundColor: color.systemBackground.light.primary,
      height: '37px',
      transition: 'height 100ms',
      position: 'static',
      minWidth: '600px',

      'body[dark] &': { // project batman
        backgroundColor: color.systemBackground.dark.primary,
      },
    },
    gridHalf: {
      gridColumn: '1/-1'
    },
    gridThird: {
      gridColumn: '1/-1'
    },
    gridTwoThirds: {
      gridColumn: '1/-1'
    },
    layout: {
      '& > *': {
        gridColumn: '1/-1'
      }
    }
  }
}

/**
 * Apply the given rules when a parent element matches `selector`
 */
function makeDynamicRules (selector: string, rules: Record<string, any>) {
  return {
    '@global': {
      [`${selector}`]: Object.assign({}, ...Object.entries(rules)
        .map(([key, value]) => ({[`& $${key}`]: value})))
    }
  }
}

export default {
  layout: {
    display: 'grid',
    gridTemplateColumns: 'minmax(185px, max-content) repeat(6, 1fr)',
    gridGap: '25px 40px',
    padding: '30px 90px',
    '& > *': {
      gridColumn: '2/-1'
    }
  },
  categoryTitle: {
    ...typography.face.headingBar,
    gridRow: 'span var(--category-row-count)',
    gridColumn: '1/2',
    paddingBottom: '30px'
  },
  categoryLabel: {
    top: 'calc(var(--head-height) + 10px)',
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    color: color.text.light.primary,
    backgroundColor: color.systemBackground.light.primary,
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.primary,
      color: color.text.dark.primary
    },
    height: '57px',
    justifyContent: 'center',
    fontSize: '0.7em',
    fontFamily: typography.titleFamily,
    letterSpacing: '0.6px',
    borderRadius: '4px',
    fontVariantLigatures: 'none',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)'
  },
  gridHalf: {
    gridColumn: 'span 3'
  },
  gridThird: {
    gridColumn: 'span 2'
  },
  gridTwoThirds: {
    gridColumn: 'span 4'
  },
  '@media (max-width: 1430px)': {
    categoryTitle: {
      gridColumn: '1/2',
      paddingBottom: '30px',
    },
    categoryLabel: {
      backgroundColor: color.systemBackground.light.primary,
      transition: 'background-color 100ms',
      'body[dark] &': { // project batman
        backgroundColor: color.systemBackground.dark.primary,
      },
    },
    gridHalf: {
      gridColumn: '2/-1'
    },
    gridThird: {
      gridColumn: '2/-1'
    },
    gridTwoThirds: {
      gridColumn: '2/-1'
    },
    layout: {
      padding: '10px 20px',
      '& > *': {
        //gridColumn: '2/-1'
      }
    }
  },
  ...narrowRules,
  ...makeDynamicRules('[narrow]', narrowRules['@media (max-width: 1250px)']),
}
