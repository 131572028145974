import { TransactionSplit } from 'capital/Transaction/TransactionSplit'

import TransactionEditor from './TransactionEditor'

import { color } from 'styles'

export default class CapitalShareSplitEditor extends TransactionEditor<TransactionSplit> {

  createTransaction ({ assetClassID }) {
    return new TransactionSplit({
      assetClassID, datetime: null, title: 'Split'
    })
  }

  get questionHTML () {
    const { jss } = this
    const args = this.clone
    return (
      <div class={jss.block}>
        <div class={jss.title}>Date </div>
        <div class={jss.date}>
          <date-picker-popover
            relatedDateGenerator={this.relatedDateGenerator}
            value={args.datetime}
            my='top left' at='bottom left' />
        </div>
        <div class={jss.title}>Split </div>
        <div class={jss.ratio}>
         <div class={jss.secondShare}>
            <input type='text' ko-textInput={args.numerator} />
          </div>
          <div class={jss.title}>for</div>
          <div class={jss.firstShare}><input type='text' ko-textInput={args.denominator} /></div>

        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      layout: {
        ...super.css.layout,
        gridTemplateColumns: 'auto'
      },
      block: {
        display: 'grid',
        gridTemplateColumns: '50px auto',
        gridGap: '20px 30px',
      },
      date: {
        background: color.textInput.light.primary,
        borderRadius: 6,
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        'body[dark] &': { // project batman
          background: color.textInput.dark.primary,
          color: color.text.dark.primary
        },
        '& > input': {
          height: '50px',
          width: '-webkit-fill-available',
          fontSize: '1.5em',
          textAlign: 'center',
          borderRadius: 4,
          border: '1px solid black',
        }
      },

      ratio: {
        display: 'flex'
      },

      splitTextOn: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 10,
        fontWeight: 'bold'
      },

      splitTextEach: {
        extend: '_splitText',
        justifyContent: 'flex-start',
        marginLeft: 10,
      },
      splitText: {
        extend: '_splitText',
        justifyContent: 'center',
      },
      firstShare: {
        '& > input': {
          height: '40px',
          width: '40px',
          fontSize: '1em',
          textAlign: 'center',
          borderRadius: 4,
          margin: 10,
          border: `1px solid transparent`,
          padding: '8px',
          background: color.textInput.light.primary,
          boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
          'body[dark] &': { // project batman
            background: color.textInput.dark.primary,
            border: `1px solid ${color.separator.dark.nonOpaque}`
          },
        }
      },
      secondShare: {
        '& > input': {
          height: '40px',
          width: '40px',
          margin: 10,
          fontSize: '1em',
          textAlign: 'center',
          borderRadius: 4,
          border: `1px solid transparent`,
          padding: '8px',
          background: color.textInput.light.primary,
          boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
          'body[dark] &': { // project batman
            background: color.textInput.dark.primary,
            border: `1px solid ${color.separator.dark.nonOpaque}`
          },
        }
      },
    }
  }

  isValidTransaction (clone: TransactionSplit) {
    const { numerator, denominator } = clone.toJS()
    return numerator && denominator && numerator !== denominator
  }
}

CapitalShareSplitEditor.register()
