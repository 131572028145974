
/**
 * Return the `HTMLElement` containing the given range.
 */
export function containerElement (range: Range) {
  const cac = range.commonAncestorContainer
  return cac.nodeType === document.TEXT_NODE
    ? cac.parentElement
    : cac as HTMLElement
}
