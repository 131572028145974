
import ViewComponent from 'ViewComponent'

// <company-status status={entity.companyStatus} />
export default class CompanyStatus extends ViewComponent {
  status: KnockoutObservable<string>

  constructor ({ status }) {
    super()
    Object.assign(this, { status })
  }

  get activeClass () {
    switch (this.status()) {
      case 'Active': return this.jss.active
      case 'Inactive': return this.jss.inactive
      default: return this.jss.unset
    }
  }

  get template () {
    const css = this.computed(() => this.activeClass)
    return (
      <div class={css}>
        {this.status}
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      _label: {
        fontWeight: 700,
        background: 'rgba(0,255,30,0.5)',
        padding: 5,
        width: 100,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 3,
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        fontSize: '0.8em'
      },
      active: {
        extend: '_label',
        color: 'green',
        background: 'rgba(0,255,30,0.25)',
      },
      inactive: {
        extend: '_label',
        color: 'red',
        background: 'rgba(255,0,30,0.25)',
      },
      unset: {
        // Neither inactive nor active.
      }
    }
  }
}

CompanyStatus.register()
