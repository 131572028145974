
import { sortBy } from 'lodash-es'

import { CloneEditor } from 'Editor'
import { typography, buttons, color } from 'styles'
import { observeProperty } from 'person/utils'
import 'views/auto-number'
import 'views/toggle-button'

type Transaction = import('capital').Transaction
type CapitalState = import('capital').CapitalState
type Asset = import('capital').Asset

export default abstract class TransactionEditor<T extends Transaction> extends CloneEditor<T> {
  assetClassID: string
  transactions: KnockoutObservableArray<Transaction>
  relatedGenerator: RelatedSource
  _stateBeforeTransaction: Transaction
  persons: PersonRecordSet
  personClones: PersonRecord[]

  abstract get questionHTML () : any
  abstract isValidTransaction (args: T) : boolean
  abstract createTransaction ({ assetClassID }) : T

  constructor (params) {
    super(params)
    const {
      assetClassID, transactions, relatedGenerator, persons,
    } = params
    Object.assign(this, {
      relatedGenerator,
      assetClassID: assetClassID || this.clone.assetClassID,
      transactions,
      persons,
      personClones: [],
      _stateBeforeTransaction: this.original || this.clone,
    })
  }

  get relatedDateGenerator () { return this.relatedGenerator }
  get relatedPersonGenerator () { return this.relatedGenerator }

  cloneOrCreate (original: T, params) : T {
    return original ? original.clone() as T : this.createTransaction(params)
  }

  get callbackArgs () { return [this.clone, this.original] }

  async onSave () : Promise<void> {
    const { transactions } = this

    if (!this.isValidTransaction(this.clone)) {
      console.warn(`Invalid transaction.`)
      return
    }
    await super.onSave()

    this.addOrUpdatePersonClones()
    this.addOrUpdateTransactions(transactions)
  }

  addOrUpdatePersonClones () {
    for (const clone of this.personClones) {
      const original = clone.id
        ? this.persons.getByID(clone.id)
        : this.persons.getByName(clone.name[0])[0]
      if (original) {
        original(clone)
      } else {
        this.persons.addRecord(clone)
      }
    }
  }

  /** This may trigger a save */
  addOrUpdateTransactions (transactions) {
    const index = transactions().indexOf(this.original)
    this._stateBeforeTransaction = this.clone
    if (index >= 0) {
      transactions.splice(index, 1, this.clone)
    } else {
      transactions.push(this.clone)
    }
  }

  /**
   * `stateBefore` returns the `CapitalState` as it is immediately prior
   * to this transaction being applied.
   */
  stateBefore () : CapitalState {
    return this._stateBeforeTransaction.stateBefore(this.transactions())
  }

  /**
   * `stateAfter` returns the `CapitalState` as it is immediately after
   * the clone (currently-being-edited) transaction is applied.
   */
  stateAfter () : CapitalState {
    return this.clone.applyTo(this.stateBefore())
  }

  assetPropertyBefore (property: string) {
    return this.stateBefore()
      .getAsset(this.assetClassID)
      .getProperty(property)
  }

  _title (title: string) {
    return (<div class={this.jss.title}>{title}</div>)
  }

  /**
   * Input a `number`. Note: we store it as a string so we don't lose
   * precision with decimal operations.
   */
  _numberInput (title: string, obs: KnockoutObservable<string|number>) {
    return this._textInput<string>(title, obs as KnockoutObservable<string>)
  }

  _textInput<T> (title: string, obs: KnockoutObservable<T>, _type = 'text') {
    const { jss } = this
    return (
      <>
        {this._title(title)}
        <div class={jss.inputArea}>
          <input type={_type}
            class={jss.input}
            ko-textInput={obs} />
        </div>
      </>
    )
  }

  _personPicker (title: string, name: KnockoutObservable<string>) {
    const { jss } = this

    const original = this.persons.getByName(name())[0]
    const recordClone = ko.observable<PersonRecord>(
      this.persons.createBlankRecord(
        Object.assign({ name: [name()] }), ko.unwrap(original)))
    const personName = observeProperty(recordClone, 'name', 0)
    this.subscribe(personName, name)

    this.personClones.push(recordClone())

    const personNames = [...new Set(this.persons.records
      .map(c => c().name[0])
      .filter(n => n))]

    const pickHTML = (field, disableShortcuts = false) => {
      return (
        <>
          {this._title(title)}
          <div class={jss.pickerArea}>
            <person-record-picker
              value={personName}
              disableShortcuts={disableShortcuts}
              generator={this.relatedGenerator}
              forPerson={recordClone()}
              suggestProperty={field}
              inputClass={jss.input}
              choicesWhenQueryIsEmpty={personNames}
            />
          </div>
        </>
      )
    }

    return (
      <>
        {pickHTML('name')}
      </>
    ).flat()
  }

  _proxyPicker (title: string, name: KnockoutObservable<string>) {
    return this.computed(() => this.assetPropertyBefore('proxy-voting') ? (
      this._personPicker(title, name)
    ): null).extend({ deferred: true })
  }

  _datePicker (title: string, obs: KnockoutObservable<Date>) {
    return (
      <>
        {this._title(title)}
        <date-picker-popover
          relatedDateGenerator={this.relatedDateGenerator}
          classes={{ inlineDateValue: this.jss.input }}
          value={obs}
          my='top left' at='bottom left' />
      </>
    )
  }

  _certificatePicker (title, obs: KnockoutObservable<number>) {
    const certNumberID = `${this.assetClassID}.cert`
    return this.computed(() => this.assetPropertyBefore('certificated') ? (
      <>
        {this._title(title)}
        <certificate-input
          state={this.stateBefore()}
          currentAutoNumber={() => this.stateBefore().autoNumber.get(certNumberID)}
          assetClassID={this.assetClassID}
          inputClass={this.jss.input}
          value={obs}
          />
      </>
    ) : null).extend({ deferred: true })
  }

  _moneyPicker (title: string, obs: KnockoutObservable<CurrencyAmount>) {
    const { jss } = this
    return (
      <>
        {this._title(title)}
        <div class={jss.inputArea}>
          <amount-of-currency-input my='top right' at='bottom right'
            defaultCurrency='CAD'
            value={obs} />
        </div>
      </>
    )
  }

  static get considerationCSS () {
    return {
      considerationAggregateLabel: {
        ...buttons.clickable,
        display: 'flex',
        justifyContent: 'flex-end',
        gridColumn: '2/3',
        textAlign: 'right',
        width: 'fit-content',
        borderRadius: 15,
        padding: '4px 5px',
      },
    }
  }

  _considerationPicker (title: string, amount: KnockoutObservable<CurrencyAmountString>, isAggregate: KnockoutObservable<boolean>) {
    const { jss } = this

    const options = [
      { text: 'Aggregate', value: true },
      { text: 'Per share', value: false },
    ]

    // SET DEFAULT VALUE
    if (isAggregate() === undefined) { isAggregate(false) }

    return (
      <>
        {this._title('Monetary Consideration')}
        <div class={jss.inputArea}>
          <amount-of-currency-input my='top right' at='bottom right'
            defaultCurrency='CAD'
            value={amount} />
        </div>
        <toggle-button class={jss.considerationAggregateLabel}
          options={options} selected={isAggregate} />
      </>
    )
  }

  _reasonPicker (title: string, obs: KnockoutObservable<string>, extraChoices = []) {
    const { jss } = this
    return (
      <>
        {this._title(title)}
        <div class={jss.reason}>
          <model-property-picker my='top left' at='bottom left'
            indexName='entity'
            value={obs}
            inputClass={jss.input}
            extraChoices={extraChoices}
            propertyGetter={e => []}
            />
        </div>
      </>
    )
  }

  static get assetPickerCSS () {
    return {
      assetPicker: {
        extend: 'input',
      },
      assetOption: {

      },
    }
  }

  _assetPicker (title: string, assetID: KnockoutObservable<string>, date: KnockoutObservable<Date>) {
    const { jss } = this
    return (
      <>
        {this._title(title)}
        <select class={jss.assetPicker}
          ko-value={assetID}>
          <option class={this.jss.assetOption} value='' disabled>Select Asset</option>
          {this._assetChoices(date).extend({ deferred: true })}
        </select>
      </>
    )
  }

  _assetOptionHTML (asset: Asset) {
    return (
      <option class={this.jss.assetOption} value={asset.id}>
        {asset.assetName}
      </option>
    )
  }

  _assetChoices (odate: KnockoutObservable<Date>) {
    return this.computed(() => {
      const date = odate()
      if (!date) { return [] }
      const { authorized } = this.stateBefore()
      return sortBy(Object.values(authorized), 'assetName')
        .filter((a: Asset) => a.id !== this.assetClassID)
        .map(a => this._assetOptionHTML(a))
    }).extend({ deferred: true })
  }

  _horizRule () {
    return <div class={this.jss.horizRule} />
  }

  _nextTransferNumber (title: string, obs: KnockoutObservable<number>) {
    const { jss } = this
    const currentAutoNumber = () => this.stateBefore().autoNumber.get('transferNumber')
    return (
      <>
        {this._title(title)}
        <div class={jss.autoNumberArea}>
          <auto-number class={jss.inputArea}
            currentAutoNumber={currentAutoNumber}
            target={obs}
            />
        </div>
      </>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.assetPickerCSS,
      ...this.considerationCSS,
      layout: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '20px 30px',
      },
      title: {
        gridColumn: '1/2',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontFamily: typography.titleFamily,
      },
      horizRule: {
        gridColumn: '1/-1',
        borderBottom: '1px dashed #aaa',
      },
      input: {
        border: '1px solid transparent',
        padding: '8px 20px',
        fontSize: 15,
        minWidth: 250,
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        width: '100%',
      },
      inputArea: {
        gridColumn: '2/3',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontFamily: typography.titleFamily
      },
    }
  }
}
