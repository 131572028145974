import { computed } from 'utils/decorator'
import t from 't'

import WritingRightViewComponent from './WritingRightViewComponent'

type CapitalAssetVariable = import('../CapitalVariable').CapitalAssetVariable
type CapitalHolderVariable = import('../CapitalVariable').CapitalHolderVariable
type CapitalVariable = import('../CapitalVariable').CapitalVariable
type Asset = import('capital').Asset
type Stakeholder = import('capital').Stakeholder

abstract class WrvvCapitalWithAssetPicker<T extends CapitalVariable> extends WritingRightViewComponent<T> {
  assetOptionHTML (a: Asset) {
    return (
      <option value={a.id}>{a.assetName}</option>
    )
  }

  @computed()
  assetClassIdOptions () {
    if (!this.variable.model) { return [] }
    const assets = Object.values(this.variable.capitalState.authorized)
    return [
      <option value=''>Not selected</option>,
      ...assets.map(a => this.assetOptionHTML(a)),
      <option value="*">All (A, B, ... and C)</option>,
    ]
  }

  get selectAssetClassIDHTML () {
    return (
      <select class={this.jss._select} ko-value={this.variable.assetClassID}>
        {this.assetClassIdOptions}
      </select>
    )
  }
}

export class WrvvCapitalAsset extends WrvvCapitalWithAssetPicker<CapitalAssetVariable> {
  get body () {
    this.monitorAndUpdatePeers(this.variable.assetClassID)

    return (
      <>
        {super.body}
        {this.inputSection('Asset', this.selectAssetClassIDHTML)}
        {this.inputSection('Slot', this.slotPickerHTML)}
        {this.dateFormatHTML}
      </>
    )
  }

  @computed()
  get dateFormatHTML () {
    return this.variable.isDateValue
      ? this.inputSection(t.DATE_FORMAT, this.dateFormattingHTML(this.variable.dateFormat))
      : null
  }
}

/**
 *
 */
export class WrvvCapitalHolder extends WrvvCapitalWithAssetPicker<CapitalHolderVariable> {

  holderOptionHTML (h: Stakeholder) {
    return (
      <option value={h.idOrName}>{h.idOrName}</option>
    )
  }

  @computed()
  holderIdOrNameOptions () {
    if (!this.variable.model) { return [] }
    const holders = Object.values(this.variable.capitalState.stakeholders)
    return [
      <option value=''>Not selected</option>,
      ...holders.map(h => this.holderOptionHTML(h)),
      <option value="*">All (A, B, ... and C)</option>,
    ]
  }

  get selectHolderHTML () {
    return (
      <select class={this.jss._select} ko-value={this.variable.idOrName}>
        {this.holderIdOrNameOptions}
      </select>
    )
  }

  get possibleAssetSectionHTML () {
    return this.variable.needsAsset
      ? this.inputSection('Asset', this.selectAssetClassIDHTML)
      : null
  }

  get body () {
    this.monitorAndUpdatePeers(this.variable.idOrName)

    return (
      <>
        {super.body}
        {this.inputSection('Holder', this.selectHolderHTML)}
        {this.possibleAssetSectionHTML}
        {this.inputSection('Slot', this.slotPickerHTML)}
      </>
    )
  }
}


WrvvCapitalAsset.register()
WrvvCapitalHolder.register()
