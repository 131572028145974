import { Responsible } from 'DataModel/components'

import ViewComponent from 'ViewComponent'

export default class ResponsibleIndicator extends ViewComponent {
  responsible: Responsible

  constructor ({ responsible }) {
    super()
    Object.assign(this, { responsible })
  }

  static get css () {
    return {
      indicator: {

      },
      name: {

      },
    }
  }

  get template () {
    return (
      <person-indicator person={this.responsible.person} />
    )
  }
}

ResponsibleIndicator.register()
