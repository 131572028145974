
import en from './en'

export enum CHOICES {
  en = 'en'
}

const LANGUAGE_KEYS = Object.keys(en)
const DEFAULT_LANGUAGE = en

const unknowns = new Set()

export class I18n {
  private keyMap: Record<string, LANGUAGE_OBSERVABLE> = {}

  constructor (private chosen: KnockoutObservable<CHOICES>, private languages: Record<string, LANGUAGE> = { en }) {
    Object.assign(this, { chosen })
    this.setupLanguageKeys()
    this.setLang(chosen())
  }

  setLang (v: CHOICES) {
    this.chosen(v)
    const lang = this.languages[v]
    if (!lang) { throw new Error(`Language ${v} not supported.`) }
    for (const key of LANGUAGE_KEYS) {
      if (key in lang) {
        this.keyMap[key](lang[key])
      } else {
        this.keyMap[key](DEFAULT_LANGUAGE[key])
        console.warn(` 📓 Language ${v} is missing ${key}.`)
      }
    }
  }

  private setupLanguageKeys () {
    for (const key of LANGUAGE_KEYS) {
      this.keyMap[key] = ko.observable()
      Object.defineProperty(this, key, {
        get: () => {
          const v = ko.unwrap(this.keyMap[key])
          switch (typeof v) {
            case 'string': return v
            case 'function':
              return (...args) => v(...args.map(ko.unwrap))
            case 'object':
              return (count, ...rest: MaybeObservable<string>[]) =>
                this.asNumberForm(v, ko.unwrap(count), ...rest)
            default: return `🌐²`
          }
        },
        enumerable: true,
        configurable: false,
      })
    }
  }

  private numberFormOf (o: LANGUAGE_PLURALS_OBJECT, c: number) {
    if (c === 0) { return o.zero || o.one }
    if (c === 1) { return o.one }
    return o.two || o.one
  }

  private asNumberForm (o: LANGUAGE_PLURALS_OBJECT, count: number | string, ...rest: MaybeObservable<string>[]) {
    const c = typeof count === 'number' ? count : parseFloat(count)
    return this.numberFormOf(o, c)(c, ...rest.map(ko.unwrap))
  }

  /**
   * Like `I18n[key]` except logs when a key isn't found.
   */
  getValue (key: string, ...params): string {
    if (key in this) {
      const v = ko.unwrap(this.keyMap[key])
      switch (typeof v) {
        case 'string': return v
        case 'function': return v(...params.map(ko.unwrap))
        case 'object': {
          const [count, ...rest] = params.map(ko.unwrap) as [number, ...any[]]
          return this.asNumberForm(v, count,...rest)
        }
      }
      console.warn(`Langauge key "${key}" internal bad type.`)
    } else {
      console.warn(`Langauge key "${key}" not yet implemented.  Check with: >>> i18n_unknowns`)
    }
    unknowns.add(key)
    return `🌐`
  }

  canonicalKey (...parts) {
    return parts.join('_').replace(/\./g, '_').toUpperCase()
  }
}

/**
 * Expose for debugging.
 */
Object.defineProperty(window, 'i18n_unknowns', {
  get: () => [...unknowns].map(u => `${u}: '',\n`).sort().join(''),
  enumerable: true,
})
