import Big from 'big.js'

import { toLocaleDecimal } from 'utils/number'
import { arrayDefault } from 'utils/array'

import { AssetCertificate } from './interfaces'

type CapitalState = import('./CapitalState').default

interface StakeholderParams {
  name: string,
  certificates?: string[],
  assets?: Record<string, Big>
}

class Holding {
  until: Date = null
  constructor (public assetClassID: string, public from: Date, public amount: Big) {}
}


/**
 * A Stakeholder is someone with an interest in the company
 */
export default class Stakeholder {
  private assets: Record<string, Big> = {}
  private assetHoldingDates: Record<string, Holding[]> = {}
  private certificates: AssetCertificate[] = []
  private name: string

  get idOrName () { return this.name }

  constructor (params: StakeholderParams) {
    Object.assign(this, params)
  }

  _assetArithmetic (op: string, assetClassID: string, amount: Bigable, datetime: Date) {
    try {
      const newAmount = this.getAsset(assetClassID)[op](amount || 0)
      this.setAssetValue(assetClassID, newAmount, datetime)
    } catch (err) {
      console.warn(`Unable to ${op}(${amount}) on ${assetClassID}:`, err)
    }
  }

  credit (assetClassID: string, amount: Bigable, datetime: Date): void {
    this._assetArithmetic('add', assetClassID, amount, datetime)
  }

  debit (assetClassID: string, amount: Bigable, datetime: Date): void {
    this._assetArithmetic('sub', assetClassID, amount, datetime)
  }

  setAssetValue (assetClassID: string, amount: Bigable, datetime: Date): void {
    try {
      const heldBefore = this.assets[assetClassID]
      this.setAsset(assetClassID, new Big(amount))
      this.noteAssetHeld(assetClassID, heldBefore, datetime)
    } catch (err) {
      console.warn(`Unable to set ${assetClassID} to ${amount}`, err)
    }
  }

  /**
   * Return the amount of the asset held by this stakeholder.
   * Always returns a `Big` instance.
   */
  getAsset (assetClassID: string): Big {
    return this.assets[assetClassID] || (this.assets[assetClassID] = new Big(0))
  }

  hasAsset (assetClassID: string): boolean {
    return assetClassID in this.assets && !this.assets[assetClassID].eq(0)
  }

  setAsset (assetClassID: string, value: Bigable): void {
    this.assets[assetClassID] = new Big(value || 0)
  }

  addCertificate (certificate: AssetCertificate): void {
    this.certificates.push(certificate)
  }

  removeCertificate (assetClassID: string, number: number): void {
    const match = (c: AssetCertificate) =>
      c.assetClassID === assetClassID
      && c.number === number

    this.certificates = this.certificates
      .filter(c => !match(c))
  }

  noteAssetHeld (assetClassID: string, was: Big, datetime: Date) {
    const ah = arrayDefault<Holding>(this.assetHoldingDates, assetClassID)
    if (ah.length) {
      const last = ah.slice(-1)[0]
      if (+last.from === +datetime) { // Same-day actions/transactions
        last.amount = was = this.assets[assetClassID]
      }
      // if (!last.amount.eq(was || 0)) {
      // TODO: Track these issues.
      // console.warn(`Odd transaction. ${this.name} held ${+last.amount} but expected ${+was}.`, ah)
      // }
      last.until = datetime
    }
    const holding = this.assets[assetClassID] || new Big(0)
    if (holding.eq(was) || holding.eq(0)) { return }
    ah.push(new Holding(assetClassID, datetime, holding))
  }

  * roles (cs: CapitalState): IterableIterator<PersonRoleRecord> {
    for (const [assetClassID, holdings] of Object.entries(this.assetHoldingDates)) {
      const assetName = cs.getAsset(assetClassID).assetName
      for (const holding of holdings) {
        yield {
          title: `Holder of ${toLocaleDecimal(holding.amount)} ${assetName}`,
          start: holding.from,
          end: holding.until,
        }
      }
    }
  }
}
