
import ViewComponent from 'ViewComponent'

import { list, color } from 'styles'

const NEG_V_OFFSET = -100

class MinuteBookEisSections extends ViewComponent {
  constructor ({ entity, eisSectionNodes, showAllEisFields }, ...args) {
    super({ }, ...args)
    Object.assign(this, { entity, eisSectionNodes, showAllEisFields })
  }

  static get css () {
    return {
      ...super.css,
      ...list.grippy,
      listItem: {
        ...list.grippy.listItem,
        margin: '1px',
        marginLeft: 20,
        fontWeight: '400',
        '&[visible=true]': {
          backgroundColor: color.tocHoverColor,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary, // 🔥
          },
          borderRadius: '10px'
        },
        '[visible=true] + &[visible=true]': {
          backgroundColor: 'inherit',
          'body[dark] &': { // project batman
            backgroundColor: 'inherit',
          },
        }
      },
      title: {
        ...list.grippy.title,
        padding: '6px 0px'
      },
      grip: {
        ...list.grippy.grip,
        display: 'none'
      }
    }
  }

  sectionGroupHTML ({ title, scrollTo, inView, hide }) {
    const { jss } = this
    const click = () => {
      scrollTo(NEG_V_OFFSET)
      if (!this.showAllEisFields()) {
        this.eisSectionNodes().forEach(s => {
          s.hide(true)
          s.inView(false)
        })
        hide(false)
      }
    }

    return (
      <div class={jss.listItem} visible={inView}>
        <div class={jss.grip} />
        <div class={jss.hoverable}
          ko-ownClick={() => click()}>
          <div class={jss.title}> {title} </div>
        </div>
      </div>
    )
  }

  get listItems () {
    return this.eisSectionNodes.map(s => this.sectionGroupHTML(s))
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.list}>
        {this.listItems}
      </div>
    )
  }
}

MinuteBookEisSections.register()
