import { inline } from 'icons'
import chevronDown from 'icons/light/chevron-down'
import { dropdown, color } from 'styles'
import { MainView } from './MainView'
import { BookView } from "./BookView"
import { EntityInfoView } from "./EntityInfoView"
/**
 *     |———— SplitView ————|
 *
 */
export class SplitView extends MainView {
  get title () { return 'Entity Split View' }

  get commandName () { return 'navSplit' }

  get showUndoButton () { return true }

  get command () { return { isHidden: true } }

  constructor (p) {
    super(p)
    const pdfScrollElement = ko.observable(null)
    Object.assign(this, {
      pdfScrollElement,
      pdfview: new BookView(this.panelProvider, {
        pagesClass: this.jss.pages,
        scrollElement: pdfScrollElement,
      }),
      eisview: new EntityInfoView(this.panelProvider),
    })
    this.pdfSettings = this.pdfview.pdfSettings
    this.hideSubMenu(false)
    this.isActive = this.panelProvider.computed(() => (
      window.app.panelProvider() === this.panelProvider &&
      this.panelProvider.activeViewID() === this.id))
    // When split-view is activated scroll window to the top
    // and make sure thumbnail view is disabled.
    this.panelProvider.computed(() => {
      if (this.isActive()) {
        window.scrollTo(0, 0)
        this.panelProvider.showThumbnailView(false)
      }
    })
    // Force remove <body> scroll bar
    this.panelProvider.computed(() => document.body.toggleAttribute('noscroll', this.isActive()))
    // Force hide context view && ToC
    const { showContextView, showToC } = this.panelProvider
    this.panelProvider.showContextView = this.panelProvider.computed({
      read: () => this.isActive() ? false : showContextView(),
      write: showContextView,
    })
    this.panelProvider.showToC = this.panelProvider.computed({
      read: () => this.isActive() ? false : showToC(),
      write: showToC,
    })
    function addModify (o) { o.modify = cb => o(cb(o())) }
    addModify(this.panelProvider.showContextView)
    addModify(this.panelProvider.showToC)
  }

  get chevronDown () {
    return <div class={this.jss.chevronDown}>{inline(chevronDown)}</div>
  }

  get pdfSectionMenuHTML () {
    const { jss } = this
    const pp = this.panelProvider
    const section = pp.sectionOfCurrentPage
    const sectionName = pp.computed(() => (ko.unwrap(section) && section().name() || '—'))
    const itemClick = section => () => pp.setAndScrollToPage(section.pageNumber())
    return (pp.computed(() => pp.sections && pp.sections().length ?
      <drop-down my='top left' at='bottom left'>
        <template slot='anchor'>
          <div class={jss.sectionMenuAnchor}>
            {sectionName}{this.chevronDown}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.sectionMenuList}>
            {pp.sections.map(section => (<div class={jss.sectionMenuItem} ko-click={itemClick(section)}>
              {section.name}
            </div>))}
          </div>
        </template>
      </drop-down> : ''))
  }

  get eisSectionMenuHTML () {
    const { jss } = this
    const pp = this.panelProvider
    const sectionName = pp.computed(() => (pp.currentEisSection && pp.currentEisSection.title))
    const itemClick = section => () => section.scrollTo(true)
    return (<drop-down my='top left' at='bottom left'>
      <template slot='anchor'>
        <div class={jss.sectionMenuAnchor}>
          {sectionName}{this.chevronDown}
        </div>
      </template>
      <template slot='content'>
        <div class={jss.sectionMenuList}>
          {pp.eisSectionNodes.map(section => (<div class={jss.sectionMenuItem} ko-click={itemClick(section)}>
            {section.title}
          </div>))}
        </div>
      </template>
    </drop-down>)
  }

  get main () {
    const { jss } = this
    const { listOfNotes, bookComponent } = this.panelProvider
    return (<div class={`${this.jss.mainContainer} ${this.jss.leftcompressed}`}>
      {this.dropPadHTML}
      <div class={jss.main} splitview>
        <div class={jss.menuCell}>
          {this.pdfSectionMenuHTML}
        </div>
        <div class={jss.menuCell}>
          {this.eisSectionMenuHTML}
        </div>
        <pinned-notes class={this.jss.pinnedNotes} bookComponent={bookComponent} listOfNotes={listOfNotes} />
        <div class={jss.pdfview} ko-set-node={this.pdfScrollElement}>
          {this.pdfview.pdfHtml}
        </div>
        <div class={jss.eisview} narrow>
          {this.eisview.mainHTML}
        </div>
      </div>
    </div>)
  }

  static get css () {
    return {
      ...super.css,
      main: {
        ...color.dynamic(color.systemBackground, 'background'),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        height: 'calc(100vh - var(--head-height))',
      },
      menuCell: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: 'var(--background-primary)',
        borderBottom: '1px solid',
      },
      chevronDown: {
        display: 'inline-block',
        marginLeft: '20px',
      },
      sectionMenuAnchor: {
        ...dropdown.item,
        backgroundColor: 'var(--background-primary)',
      },
      sectionMenuList: {
        ...dropdown.functionalMenu,
        padding: '0.5rem 0',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 158px)',
      },
      sectionMenuItem: {
        ...dropdown.item,
      },
      view: {
        padding: '20px',
        overflowY: 'scroll',
      },
      pdfview: { extend: 'view' },
      eisview: { extend: 'view' },
      pages: {
        width: '100%',
      },
      pinnedNotes: {
        ...super.css.pinnedNotes,
        gridColumn: '1/3',
      },
    }
  }
}
