
import { inline } from 'icons'
import deleteIcon from 'icons/light/times'

import 'person/person-indicator'
import { buttons, color, typography } from 'styles'
import { formatForUser } from 'utils/dates'
import { observeProperty } from 'person/utils'
import { memoize } from 'utils/decorator'
import t from 't'

import Navigation from '../Navigation'

import EisTable from './EisTable'

type EditableColumn<T> = {
  property: string
  edit?: (row: T) => JSX
  view?: (row: T) => JSX
} | {
  title: LANGUAGE_VALUE
  edit?: (row: T) => JSX
  view: (row: T) => JSX
}

/**
 * This is a table where the `componentName` refers to an `ArrayComponent`.
 */
export default abstract class EisArrayComponentTable<T extends import('DataModel').UnitView> extends EisTable {
  component: ArrayComponent & DataComponent
  private _tableNavigation: Navigation

  static get itemCSS () { return {} }
  static get editCSS () { return {} }
  static get commonEditCSS () {
    return {
      _input: {
        width: '100%',
        fontSize: 15,
        padding: '8px 20px',
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
        borderRadius: 6,
        minWidth: '250px',
        border: '1px solid transparent',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        '&:focus, &:focus-within, &:active': {
          outline: 'none',
          borderLeft: '3px solid #ffd502',
          borderRadius: '3px'
        }
      },
      input: {
        extend: '_input',
      },
      picker: {
        extend: '_input',
      },
      popover: {
        extend: '_input',
        padding: '0',
      },
      date: {
      //date are the date strings that appear in tables
      },
      //div are the text strings that appear in tables but not dates
      div: {
      },
      breakTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        gridColumn: '1/-1',
        paddingTop: '10px',
        borderTop: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderTop: `1px solid ${color.separator.dark.nonOpaque}`,
        }
      },
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.itemCSS,
      ...this.editCSS,
      ...this.commonEditCSS,
      modalContentClass: {
        minWidth: '600px',
      },
      editGrid: {
        marginTop: '40px',
        display: 'grid',
        gap: '20px 50px',
        gridTemplateColumns: 'auto 1fr',
      },
      editTitle: {
        gridColumn: '1/2',
        fontWeight: 'bold',
        fontFamily: typography.titleFamily,
      },

      deleteButton: {
        ...buttons.clickable,
        gridColumn: '-1',
        textAlign: 'right'
      },
      cell: {
        cursor: 'pointer',
        padding: '3px 0px',
        borderRadius: 5,
        width: 'fit-content',
        transition: 'padding 0.2s ease-in-out, background 0.1s ease-in-out, margin-left 0.2s ease-in-out',
        '&:hover':{
          transition: 'padding 0.2s ease-in-out, background 0.2s ease-in-out, margin-left 0.2s ease-in-out',
          padding: '3px 6px',
          marginLeft: -6,
          borderRadius: 5,
          background: 'rgba(120,120,128,0.16)',
          width: 'fit-content',
        }
      }
    }
  }

  get tableNavigation () {
    if (this._tableNavigation) { return this._tableNavigation }
    return this._tableNavigation = new Navigation()
  }

  * genRows () { yield * this.component.array }

  get rows () {
    return [...this.genRows()]
      .map((item, i) => [
        ...this.itemRowHTML(item)
          .map(r => <div class={this.jss.cell} row={i}>{r}</div>),
        ...this.rowButtonsHTML(item)
      ])
  }

  rowButtonsHTML (item) {
    return [
      this.deleteButtonHTML(item)
    ]
  }

  /**
   * Add the 'delete' column.
   */
  gridHeadHTML () {
    return [...super.gridHeadHTML(), this.gridHeadItem('')]
  }

  // Column titles + delete/utility button column
  get columnCount () { return this.columnTitles.length }
  get gridTemplateColumns () { return super.gridTemplateColumns + ' auto' }
  get ItemClass () { return this.component.ItemClass }

  addClick (evt) {
    evt.preventDefault()
    evt.stopPropagation()
    const { component } = this
    const item = new this.ItemClass({}, this.component) as T
    const onSave = () => component.array.push(item)
    this.openModal(item, onSave)
  }

  /**
   * For the immediate short term, we delete an item.
   *
   * In the longer term, there is an `isDeleted` property on every
   * `UnitView` instance, so we can mark an item as deleted.  When an
   * `ArrayComponent` saves its `UnitView` items, it skips anything where
   * `isDeleted` is `true`.  We can use this as part of an delete / restore
   * functionality.
   */
  deleteClick (evt, item) {
    evt.preventDefault()
    evt.stopPropagation()
    // item.isDeleted.modify(v => !v)
    const { component } = this
    component.array.remove(item)
  }

  deleteButtonHTML (item) {
    const { jss } = this
    return (
      <div class={jss.deleteButton}
        ko-click={evt => this.deleteClick(evt, item)}>
        {inline(deleteIcon)}
      </div>
    )
  }

  modelEditHTML (clone: T) {
    const { jss } = this
    return (
      <div class={jss.editGrid}>
        {this.editCloneHTML(clone).flat()}
      </div>
    )
  }


  openModal (unit: T, onSave?: (unit) => void) {
    window.app.modal(
      <modal-dialog
        modalTitle={`Edit ${this.listAddName}`}
        contentClass={this.jss.modalContentClass}>
        <template slot='content'>
          <table-item-editor
            original={unit}
            onSave={onSave}
            UnitView={this.component.ItemClass}
            parent={this.component}
            html={clone => this.modelEditHTML(clone)} />
        </template>
      </modal-dialog>
    )
  }

  gridClick (target: HTMLElement) {
    const div = target.closest('[row]')
    if (!div) { return }
    const index = div.getAttribute('row')
    if (!index) { return }
    const unit = [...this.component.array][index]
    this.openModal(unit)
  }


  abstract get columns (): Array<string | EditableColumn<T>>

  @memoize()
  private get canoicalColumns (): EditableColumn<T>[] {
    return this.columns.map(c => typeof c === 'string' ? { property: c } : c)
  }

  get columnTitles () {
    return this.canoicalColumns.map(c => ko.unwrap('title' in c
      ? c.title : this.component.i18nStringFor(c.property)))
  }

  itemRowHTML (row: T) {
    return this.canoicalColumns.map(c => this.autoItemRow(row, c))
  }

  autoItemRow (row: T, c: EditableColumn<T>): JSX {
    if (c.view) { return c.view(row) }
    if ('title' in c) { return null }
    const fieldType = row.fields[c.property]
    switch (fieldType) {
      case 'string': return this._simpleDiv(row, c.property)
      case 'pureDate': return this._simpleDate(row, c.property)
      case 'person': return this._simplePersonProperty(row, c.property, 'name')
      case 'datetime': return this._simpleDate(row, 'datetime')
    }
    console.error(`No autoItemRow for ${c.property}/${fieldType} on`, row)
    return null
  }

  editCloneHTML (clone: T): JSX {
    return this.canoicalColumns.map(c => this.autoItemEdit(clone, c))
  }

  autoItemEdit (clone: T, c: EditableColumn<T>): JSX {
    if (c.edit) { return c.edit(clone) }
    if ('title' in c) { return null }
    const fieldType = clone.fields[c.property]
    switch (fieldType) {
      case 'pureDate': return this._simpleDatePicker(clone, c.property)
      case 'person': {
        switch (c.property) {
          case 'agent':
            return this._relatedPersonDetails(clone, c.property, t.PERSON_RELATED_AGENT)
          case 'contact':
            return this._relatedPersonDetails(clone, c.property, t.PERSON_RELATED_CONTACT)
          default: return this._personDetails(clone, c.property)
        }
      }
      case 'string': return this._modelPickerChoices(c.property)
        ? this._modelPicker(clone, c.property)
        : this._simpleInput(clone, c.property)
    }
    console.error(`No autoItemEdit for ${c.property}/${fieldType} on`, clone)
    return null
  }

  /**
   * For expedited development -- show a simple
   * div.
   */
  _simpleDiv (unit: T, name: string) {
    const { jss } = this
    return (
      <div class={[jss.div, jss[name]].join(' ')}>
        {unit[name]}
      </div>
    )
  }

  _getMergedPerson (unit: T, field: string, prop = 'name') : PersonRecord {
    const getter = p => p && p[prop] && p[prop].find(v => v)
    const unitPerson = unit[field]()
    const gen = this.entity.getPersons(p =>
      ( p.id && p.id === unitPerson.id ) ||
      ( p[prop] && p[prop].find(v => v === getter(unitPerson)) )
    )
    return gen.next().value
  }

  _simplePersonProperty (unit: T, field: string, prop: string) {
    const getter = p => p && p[prop] && p[prop].find(v => v)
    const mergedPerson = this._getMergedPerson(unit, field, prop)
    return <person-indicator
      person={unit[field]}
      suggestedPerson={mergedPerson}
      valueGetter={getter}
      onClick={() => null} />
  }

  _simpleDate (unit: T, id: string) {
    const { jss } = this
    return (
      <div class={[jss.date, jss[id]].join(' ')}>
        {this.computed(() => formatForUser(unit[id]()))}
      </div>
    )
  }

  _simpleTitle (title, id?) {
    const { jss } = this
    const classes = [
      jss.editTitle, jss[`${id || title}EditTitle`]
    ].join(' ')
    const str = title || this.component.i18nStringFor(id)
    return <div class={classes}>{str}</div>
  }

  _simpleInput (clone: T, id: string, title?: string) {
    const { jss } = this
    return (
      <>
        {this._simpleTitle(title, id)}
        <input class={[jss.input, jss[`${id}Input`]].join(' ')}
          ko-textInput={clone[id]} />
      </>
    )
  }

  _jurisdictionPicker (clone: T, id: string, title?: LANGUAGE_VALUE) {
    const { jss } = this
    return (
      <>
        {this._simpleTitle(title, id)}
        <jurisdiction-picker my='top left' at='bottom left'
          value={clone[id]}
          inputClass={[jss.picker, jss[`${id}Picker`]].join(' ')} />
      </>
    )
  }

  _personPicker (clone: T, id: string, title?: string, suggestProperty?) {
    const { jss } = this
    return (
      <>
        {this._simpleTitle(title, id)}
        <person-picker
          value={clone[id]}
          generator={clone.parent.model}
          suggestProperty={suggestProperty}
          inputClass={[jss.picker, jss[`${id}Picker`]].join(' ')} />
      </>
    )
  }

  _break (breakText: LANGUAGE_VALUE) {
    return (
      <div class={this.jss.breakTitle}>{breakText}</div>
    )
  }

  _relatedPersonDetails (clone: T, id: string, title: LANGUAGE_VALUE) {
    return (
      <>
        {this._break(title)}
        {this._personDetails(clone, id)}
      </>
    ).flat()

  }

  _personDetails (clone: T, id: string, title?) {
    const { jss } = this
    const person = clone[id] as KnockoutObservable<PersonRecord>

    const pickHTML = (field, title, disableShortcuts = false) => {
      return (
        <>
          {this._simpleTitle(title)}
          <person-record-picker
            value={observeProperty(person, field, 0)}
            disableShortcuts={disableShortcuts}
            generator={clone.parent.model}
            forPerson={person()}
            suggestProperty={field}
            inputClass={[jss.picker, jss[`${id}Picker`]].join(' ')}
          />
        </>
      )
    }

    return (
      <>
        {pickHTML('name', t.PERSON_NAME)}
        {pickHTML('email', t.PERSON_EMAIL)}
        {pickHTML('phone', t.PERSON_PHONE, true)}
      </>
    ).flat()
  }

  private _modelPickerChoices (prop: string) {
    return this[`${prop}ModelPickerChoices`]
  }

  _modelPicker (clone: T, id: string, title?: string, extraChoices = this._modelPickerChoices(id) || []) {
    const { jss } = this
    const getter = e => [...e.answerFor(this.componentName)]
      .map(bn => bn[id]())
    return (
      <>
        {this._simpleTitle(title, id)}
        <model-property-picker my='top left' at='bottom left'
          indexName='entity'
          value={clone[id]}
          onSelect={clone[id]}
          inputClass={[jss.picker, jss[`${id}Picker`]].join(' ')}
          propertyGetter={getter}
          extraChoices={extraChoices}
        />
      </>
    )
  }

  _simpleDatePicker (clone: T, id: string, title?: string) {
    const { jss } = this
    const showing = ko.observable(false)
    return (
      <>
        {this._simpleTitle(title, id)}
        <date-picker-popover
          class={[jss.popover, jss[`${id}Popover`]].join(' ')}
          ko-ownClick={() => showing(true)}
          ko-keydown={{ Enter: () => showing(true) }}
          ko-focus={showing}
          showing={showing}
          tabindex='0'
          drawerSide='left'
          relatedDateGenerator={this.entity}
          value={clone[id]} />
      </>
    )
  }

  _roleCountColumn (personProperty: string) {
    return {
      title: t.PERSON_OTHER_POSITIONS_HELD,
      view: unit => <person-role-count-popover
        filter={unit.isSamePerson.bind(unit, personProperty)}
        skip={unit.roleIsFromThisUnit.bind(unit, personProperty)}
        source={this.entity} />,
    }
  }

  _jurisdictionColumn (property = 'jurisdiction') {
    return {
      property,
      edit: clone => this._jurisdictionPicker(clone, property),
      view: unit => <jurisdiction-flag value={unit[property]} />,
    }
  }

  displayHTML: never
  editHTML: never
  indicatorsHTML: never
}
