
import { IdempotentColumn, TableColumn } from './Column'
import { CapitalState, ShareholderLedger } from 'capital'

import { inline } from 'icons'
import flagIcon from 'icons/solid/flag'
import { ListOfNotes } from 'notes-list'
import { noteFilterOperations } from './FilterOperation'
import PopOver from 'pop-over'

export class FiscalYearEndColumn extends IdempotentColumn {
  get columnID () { return 'fiscalyearend' }
  sortableValue (row) { return this.getter(row).toISO_MD_String() }
  reportValue (row) { return this.getter(row).asString() }
  renderValue (row, jss) {
    const fye = row.answerFor('fiscalyearend')
    const daysHtml = ko.pureComputed(() => {
      const count = fye.daysFromNow()
      const fyeClass = count < 60
        ? jss.fyeImmediate : count < 120 ? jss.fyeSoon : jss.fyeLater
      return (
        <small class={fyeClass}>
          {this.reportValue(row)}
        </small>
      )
    })
    return (
      <tool-tip my='top left' at='bottom left'>
        <template slot='anchor'>
          {daysHtml}
        </template>
        <template slot='content'>
          <small>
            {ko.pureComputed(() => fye.daysFromNow())} days from now
          </small>
        </template>
      </tool-tip>
    )
  }
  searchMatches (row, query) {
    return this.getter(row).searchMatches(query)
  }
}

export class PageCountColumn extends IdempotentColumn {
  get columnID () { return 'page_count' }
  sortableValue (row) { return this.getter(row).pages.length }
  reportValue (row) { return this.getter(row).pages.length }
  renderValue (row) { return this.getter(row).pages.length }
  get searchable () { return false }
}

export class SectionCountColumn extends IdempotentColumn {
  get columnID () { return 'section_count' }
  sortableValue (row) { return this.getter(row).sections.length }
  reportValue (row) { return this.getter(row).sections.length }
  renderValue (row) { return this.getter(row).sections.length }
  get searchable () { return false }
}

export class NotesColumn extends IdempotentColumn {
  static get immutableID () { return 'notes' }
  constructor (title, columnID, options) {
    super(title, columnID, {
      getter: row => this.getListOfNotes(row).list().length,
      ...options,
    })
  }
  get makeFilterOperationFn () { return noteFilterOperations }
  getListOfNotes (row) {
    if (!row.listOfNotes) { row.listOfNotes = new ListOfNotes(row) }
    return row.listOfNotes
  }
  filterValue (row) { return this.getListOfNotes(row) }
  flagged (row) { return this.getListOfNotes(row).list().some(note => note.data().flagged) }

  static get getStyles () {
    return (() => {
      let stylesheet = null
      return () => {
        if (stylesheet) { return stylesheet }
        return stylesheet = window.jssLib.createStyleSheet(this.css).attach()
      }
    })()
  }
  get jss () { return this.constructor.getStyles().classes }

  static get css () {
    return {
      poppverContent: {
        ...PopOver.css.content,
        '--tool-tip-padding': '0px'
      },
      flagIcon: {
        '--icon-color': 'red',
        marginLeft: '0.3em'
      },
      notesList: {
        display: 'inline-block',
      },
      note: {
        backgroundColor: 'white',
        maxWidth: '300px',
        whiteSpace: 'normal',
        color: 'black',
        margin: '0.5em',
        padding: '10px',
        borderRadius: '3px',
      },
    }
  }

  renderValue (row) {
    const { jss } = this
    return (
      this.flagged(row) ?
        <tool-tip my='top right' at='bottom right' contentClass={jss.poppverContent}>
          <template slot='anchor'>
            {this.getter(row)}
            <span class={jss.flagIcon}>{inline(flagIcon)}</span>
          </template>
          <template slot='content'>
            <div>
              <div class={jss.notesList}>
                {this.getListOfNotes(row).list()
                  .filter(note => note.data().flagged)
                  .map(note =>
                    <div class={jss.note}>{note.data().message}</div>)}
              </div>
            </div>
          </template>
        </tool-tip>
        : this.getter(row) || '—'
    )
  }
}

class CapitalColumn extends TableColumn {
  capitalState (row) {
    const datetime = new Date()
    const transactions = row.answerFor('capital').transactions()
    return new CapitalState({ datetime, transactions })
  }
}


export class ShareholderColumn extends CapitalColumn {
  ledgerToString (ledger) {
    return ledger.person
    // TODO: `person (PREFIX:AMOUNT/%, PREFIX:AMOUNT/%)` e.g.
    //      John Smith (C:100/5%, PA: 295/20%)
  }

  balance (ledger:ShareholderLedger) {
    return ledger.lines.length && ledger.lines.slice(-1).pop().balance
  }

  itemsForRow (row) {
    const cs = this.capitalState(row)
    const seen = new Set()

    return [...cs.shareholderLedgers()]
      .filter(l => this.balance(l) && !this.balance(l).eq(0))
      .filter(ledger => ledger.person)
      .map(this.ledgerToString)
      .sort()
      .filter(elvValue => {
        if (seen.has(elvValue)) { return false }
        seen.add(elvValue)
        return true
      })
      .map(elvValue => ({ elvValue }))
  }
}


export class ShareAssetColumn extends CapitalColumn {
  get columnID () { return 'share_assets' }
  itemsForRow (row) {
    const cs = this.capitalState(row)
    return [...cs.shareAssetClassIDs()]
      .map(id => cs.getAsset(id).assetName)
      .filter(assetName => assetName)
      .sort()
      .map(elvValue => ({ elvValue }))
  }
}
