


import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class RegulatoryLicense extends UnitView {
  get elvValue () { return this.description }

  get fields () {
    return {
      description: 'string',
      expiry: 'pureDate', // vs renewal?
      registered: 'pureDate',
      renewal: 'pureDate',
    }
  }
}

export default class RegulatoryLicensesComponent extends ArrayComponent {
  get ItemClass () { return RegulatoryLicense }
}

RegulatoryLicensesComponent.register()
