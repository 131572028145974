
import EntityModel from 'EntityModel'
import UserModel from 'UserModel'

import { addDays, startOfYear, startOfMonth, endOfMonth, addMonths } from 'date-fns/esm'
import { toYMD } from 'utils/dates'

const DEMO_PDF_URL = '/Demo-Incorp-Articles.pdf'

const tags = [
  'new', 'overdue', 'transaction', 'sample', 'conglomerate', 'amalgamation'
]

const entityTypes = [
  'Co-op', 'REIT', 'LLP', 'Corporation', 'Trust', 'Corporation', 'Corpooration'
]

const sampleLawyers = [
  'William Blackstone',
  'Mary Arden',
  'Alfred Thompson Denning',
  'Ruth Bader Ginsburg',
  'Michael Judge',
  'Lance Ito',
  'Frank Iacobucci',
  'Beverley McLachlin'
]

const sampleCompanyNames = [
  'Ace Alice Limited',
  'Bolton Enterprises Inc.',
  'Bombardier Inc',
  'Cambridge Properties',
  'Capital Power Corp.',
  'Dell Computers Inc',
  'Dinesh Developments Inc.',
  'Enbridge Inc',
  'Exchange Income Corporation',
  'Gilfoyle Geek Squad Inc.',
  'Google Inc',
  'Hank Hill Family Trust',
  'Hendricks Compression Inc.',
  'Inter Pipeline Ltd',
  'Jared Dunn Write Inc.',
  'Microsoft Inc',
  'Milton Staples Technologies',
  'Nissan Canada Ltd',
  'Oracle Ltd',
  'Peter Gregory Investment Holdings II',
  'Tesla Inc',
  'WestJet Ltd'
]

const sampleJurisdictions = [
  'Alberta',
  'British Columbia',
  'Federal',
  'Ontario',
  'Ontario'
]

let sampleData = {
  lawyers: sampleLawyers,
  companyNames: sampleCompanyNames,
  jurisdictions: sampleJurisdictions
}

// jurisdiction, date of incorporation, last modified, lawyer responsible

// Return a set of tags based on the index
function tagsFromIndex (i) {
  return tags.filter((_, j) => (j + i * 17) % tags.length <= 1)
}

// Return one or more individuals responsible for the entity
function responsibleFromIndex (i) {
  const resp = sampleData.lawyers
  const prand = (i + 23) % 5
  if (!prand) return []
  return resp.filter((_, j) => (j + i + 23) % resp.length === 0)
}

function entityTypeFromIndex (i) {
  return entityTypes[(i * 19) % entityTypes.length]
}

function matterNumberForIndex (i) {
  return String(((i + 1) * 1234567890) % 100000)
}

function jurisForIndex (i) {
  const idx = (i * 17) + 19
  const j = sampleData.jurisdictions
  return j[idx % j.length]
}

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function makeEntities (authManager = getAuthManager(), bookPageCount = 36) {
  console.log(`[Entity] Pushing test entity to storage`)
  console.log(`[Entity]    test entity pushed.`)
  const names = sampleData.companyNames
  return names.map((n, i) => ({
    id: `demo--${n}`,
    accountID: 'demo',
    modified: addDays(new Date(), i * -17),
    tags: tagsFromIndex(i),
    content: {
      legalname: n,
      clientname: n,
      matter_number: matterNumberForIndex(i),
      jurisdiction: jurisForIndex(i),
      incorporation_date: {
        d: random(1, 28), M: random(0, 11), y: random(1970, 2017)
      },
      fiscalyearend: toYMD(addMonths(endOfMonth(startOfYear(new Date())), i)),
      responsible: responsibleFromIndex(i),
      entity_type: entityTypeFromIndex(i),
      book: {
        pages: [...Array(bookPageCount)].map((_, i) => `${DEMO_PDF_URL}#${i}`),
        sections: [
          { startPage: 3, name: 'Director Resolutions' },
          { startPage: 6, name: 'Shareholder Resolutions' },
          { startPage: 7, name: 'Waivers & Consents' },
          { startPage: 9, name: 'By-laws' },
          { startPage: 16, name: 'Registers' },
          { startPage: 20, name: 'Shareholder Ledgers' },
          { startPage: 27, name: 'Subscriptions' },
          { startPage: 32, name: 'Certificates' }
        ],
        bookmarks: [
          `${DEMO_PDF_URL}#4`, `${DEMO_PDF_URL}#6`
        ]
      }
    }
  }))
}

const USERS = [
  {
    status: 1,
    admin: true,
    enabled: true,
    firstname: 'Brian',
    lastname: 'Hunt',
    email: 'brian@minutebox.com',
  },
  {
    status: 1,
    admin: true,
    enabled: true,
    firstname: 'Sean',
    lastname: 'Bernstein',
    email: 'sean@minutebox.com',
  },
  {
    status: 1,
    enabled: true,
    admin: true,
    firstname: 'Daniel',
    lastname: 'Levine',
    email: 'daniel@minutebox.com',
  },
  {
    status: 1,
    enabled: true,
    admin: true,
    firstname: 'Steven',
    lastname: 'Pulver',
    email: 'steven@minutebox.com',
  },
  {
    status: 1,
    enabled: true,
    admin: true,
    firstname: 'Chris',
    lastname: 'Carton',
    email: 'chris@minutebox.com',
  }
]

/**
 * Get the Demo authentication manager, and switch to the demo account.
 */
export function getAuthManager () {
  const authManager = global.app.defaultAuthManager
  if (authManager.accountID() !== 'demo') {
    throw new Error(
      `[demo-data] Demo account not set: ${authManager.accountID()}`)
  }
  authManager.accountID('demo')
  return authManager
}

export let modelsByType = null

/**
 * Create the models in-memory.
 * @param {AuthManager} authManager
 */
export async function generateDemoData (authManager = getAuthManager()) {
  if (!authManager) { throw new Error('generateDemoData needs authManager') }
  await addUserIds()
  const userModels = USERS.map(e => new UserModel(e, authManager))

  const models = [ ...userModels ]
  models.forEach(em => {
    em.accountID('demo')
    em.vmAddToCache()
  })

  const entityModels = await generateEntityData(authManager)

  modelsByType = { entityModels, userModels }
  return modelsByType
}

export async function addUserIds (authManager = getAuthManager()) {
  for (const user of USERS) {
    if (user.id) { continue }
    const data = await authManager.firebaseFn('getOrCreateUserByEmail', user.email)
    user.id = data.uid
  }
  return USERS
}

export async function generateEntityData (authManager = getAuthManager()) {
  const entityModels = makeEntities(authManager)
    .map(e => new EntityModel(e, authManager))
  return entityModels
}

/**
 * Generate a single entity called 'Sample Entity' with a book of the given
 * URL; its `id` is always `sample`.
 *
 * Usage:
 *
 * >>> demoData.generateOneEntity(url)
 *
 * Where URL is the URL for any PDF.
 */
export async function generateOneEntity (documentUrl) {
  const authManager = getAuthManager()

  const doc = window.pdfjs.getDocument(documentUrl)
  const pageCount = doc.numPages
  const entity = new EntityModel({
    id: 'sample',
    accountID: 'demo',
    modified: new Date(),
    tags: ['sample'],
    content: {
      legalname: 'Sample Entity',
      matter_number: documentUrl,
      jurisdiction: '',
      fiscalyearend: toYMD(addMonths(endOfMonth(startOfYear(new Date())), 3)),
      book: {
        pages: [...Array(pageCount)].map((_, i) => `${DEMO_PDF_URL}#${i}`),
        sections: [],
        bookmarks: []
      }
    }
  }, authManager)

  entity.accountID('demo')
  entity.vmAddToCache()
  await entity.vmSave()

  console.log(`Generated Entity:`, entity)
  return entity
}

/**
 * Clear the Firebase `demo` account.
 * @param {object} customData
 * @param {Array.<string>} customData.lawyers
 * @param {Array.<string>} customData.companyNames
 * @param {Array.<string>} customData.jurisdictions
 */
export async function reset (customData, authManager = getAuthManager()) {
  console.group('Demo Data Reset.', Object.assign(sampleData, customData))
  await this.primeCurrentUser(authManager)
  await this.resetFirestore(authManager)
  console.groupEnd()
}

/**
 * Ensure that there's a current user in the data store that has the
 * authority / claims for resetting the demo account.
 */
export async function primeCurrentUser (authManager = getAuthManager()) {
  const uid = authManager.firebase.auth().currentUser.uid
  console.log(`
      [Firestore] Priming with uid ${uid}
  `)
  return authManager.firestore.doc(`/accounts/demo/user/${uid}`)
    .set({ enabled: true, admin: true })
}

/**
   *                                                     --- Call Manually ---
   * Reset the data in our demo firebase/firestore account.
   */
export async function resetFirestore (authManager = getAuthManager()) {
  console.group('[Firestore] Resetting')
  const db = authManager.firestore

  const collections = [
    'accounts/demo/entity',
    // 'accounts/demo/user',
    // 'accounts/demo/team'
  ]
  await Promise.all(collections.map(c => resetFirestoreCollection(db, c)))
  console.log(`[Firestore] Collections deleted.`)

  // Re-prime the current user so we have permission to update.
  await this.primeCurrentUser(authManager)

  const demoData = await generateDemoData(authManager)

  // We need to add the users first, otherwise we might get permission errors.
  console.log(`[Firestore] Adding demo users`)
  await Promise.all(demoData.userModels.map(u => u.vmSave()))

  console.log(`[Firestore] Adding demo data.`)
  const models = [...demoData.entityModels]

  console.log(`[Firestore] Saving models`, models)

  await Promise.all(models.map(m => m.vmSave()))
  console.log(`[Firestore] Models saved`)
  console.groupEnd()
}

export async function resetFirestoreCollection (db, collection) {
  const qSnapshot = await db.collection(collection).get()
  console.log(`[Firestore] Deleting ${qSnapshot.docs.length} from ${collection}`)
  const batch = db.batch()
  qSnapshot.docs.map(e => batch.delete(e.ref))
  await batch.commit()
}
