//
//   isEqualModel
//   ~~~~~~~~~~~~
//
//
// This compares two models.
//
// It is similar to _.isEqual, but it checks for a 'raw' attribute, and if
// an object has that attribute then equality of the model is a proxy of the
// equality of the 'raw' attributes.
//
import {
  isEqualWith, partialRight, isDate, isString, has, isObject, every, isEqual
} from 'lodash-es'

// Primitives isEqualModel uses for comparison
const KNOWN_CLASSES = ['Boolean', 'Date', 'RegExp', 'String', 'Number']
const KNOWN_CLASS_NAMES = new Set(
  ...KNOWN_CLASSES.map(cn => `[object ${cn}]`)
)

export const isEqualModel = partialRight(isEqualWith, function (a, b) {
  // Convert dates to/from strings for comparison; note we cannot convert
  // to ISOString here since it may/may not have .000Z trailing yet still
  // be "equal".

  // If one is marked ignore, they are assumed to be equal.
  if ((a === isEqualModel.IGNORE) || (b === isEqualModel.IGNORE)) {
    return true
  }

  // Convert a date and a string to two dates
  if (isDate(a) && isString(b)) { b = new Date(b) }
  if (isDate(b) && isString(a)) { a = new Date(a) }

  if (isDate(a) && isDate(b)) { return a.getTime() === b.getTime() }

  // Test for our specific cases
  //
  // The 'raw' attribute appears on addresses created by
  // SnailMailAddressParser.
  //
  const aRaw = has(a, 'raw')
  const bRaw = has(b, 'raw')
  if (aRaw) {
    if (bRaw) { return (a.raw || '') === (b.raw || '') }
    return (a.raw || '') === (b || '')
  }

  if (bRaw) { return (b.raw || '') === (a || '') }

  // use explicitly provided equality testing
  if (has(a, 'is_equal')) {
    return a.is_equal(b)
  } else if (has(b, 'is_equal')) {
    return b.is_equal(a)
  }

  // capture all the early-outs if we are not dealing with two objects
  if (!isObject(a) || !isObject(b)) { return isEqual(a, b) }

  // return false if the types of these objects differ
  if (toString.call(a) !== toString.call(b)) { return false }

  // compare each element of the array
  if (Array.isArray(a)) { // b is also an array, because the types are identical
    let size = a.length
    if (size !== b.length) { return false }

    if (!size) { // both arrays are empty
      return isEqual(a, b) // defer to object/array comparison in lodash
    }

    while (size--) {
      if (!isEqualModel(a[size], b[size])) { return false }
    }

    return true
  }

  // FIXME: the following will almost certainly not be as fast as the isEqual
  // version.

  // make sure key sizes are the same
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (aKeys.length !== bKeys.length) { return false }

  // coersion of known classes -- see lodash isEqual
  const className = toString.call(a)
  if (KNOWN_CLASS_NAMES.has(className)) { return isEqual(a, b) }

  // deep comparison
  return every(a, function (value, key) {
    if (!isEqualModel(value, b[key])) {
      // Print the pinpoint of the difference
      if (isEqualModel.DEBUG) { // and not _.isObject(value)
        console.log(`isEqualModel: '${key}' differs [` +
                    '\n\t', value, '\n\t', b[key], ']')
      }
        //, value,
        //  "<#{value?.constructor.name}>",
        //  b[key],
        //  "<#{b[key]?.constructor.name}>"
        // )
      return false
    }
    return true
  })
}) // isEqualModel
