
import ModelEditor from 'ModelEditor'
import UserModel from 'UserModel'

import { color } from 'styles'

// import { adminOnly } from 'styles'

export default class UserEditor extends ModelEditor {
  constructor (params) {
    super(params)
    const { teamFilters } = params
    Object.assign(this, { teamFilters })
    this.teams = this.computed(() => this.matchingTeams(teamFilters))
  }

  get Model () { return UserModel }
  get user () { return this.model() }

  static get css () {
    return {
      ...super.css,
      layout: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '12px 18px',
      },
      email: {
        border: '1px solid transparent',
        padding: '8px 20px',
        fontSize: 15,
        minWidth: 250,
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        color: color.text.light.tertiary,
        'body[dark] &': { // project batman
          color: color.text.dark.tertiary,
          background: color.textInput.dark.tertiary
        },
        background: color.textInput.light.secondary,
        fontWeight: 'bold',
      },
      team: {
        border: '1px solid green',
        margin: '4px',
        padding: '2px 6px',
        borderRadius: '6px'
      },
      question: {
        justifySelf: 'end',
        fontWeight: 'bold',
        alignSelf: 'center'
      },
      textInput: {
        border: '1px solid transparent',
        padding: '8px 20px',
        fontSize: 15,
        minWidth: 250,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        '&:focus, &:active': {
          outline: 'none',
          borderLeft: '3px solid #ffd502',
          borderRadius: '3px'
        }
      },
      inviteTeamsDescription: {
        width: 270,
        display: 'flex',
        background: 'rgba(0,0,0,0.05)',
        borderRadius: 4,
        padding: 11,
        border: '1px solid rgba(0,0,0,0.1)',
        borderStyle: 'dashed',
        color: color.text.light.tertiary,
        'body[dark] &': { // project batman
          color: color.text.dark.tertiary,
          border: '1px solid rgba(255,255,255,0.1)',
        },
      }
    }
  }

  get questionHTML () {
    const { user, jss } = this
    const adminQuestions = user.authManager.userIsAdmin() ? [
      ...this.boolInputQuestion('Enabled', user.enabled),
      ...this.boolInputQuestion('Admin', user.admin),
      ...this.makeQuestion('Role', this.roleEditorHTML(user.role)),
      ...this.makeQuestion('Office', this.officeEditorHTML(user.office)),
    ] : []

    return [
      ...this.makeQuestion('Email', <div class={jss.email}>{user.email()}</div>),
      ...this.textInputQuestion('First name', user.firstname, jss.textInput),
      ...this.textInputQuestion('Last name', user.lastname, jss.textInput),
      ...this.textInputQuestion('Phone', user.phone, jss.textInput),
      ...this.textInputQuestion('Fax', user.fax, jss.textInput),
      ...adminQuestions,
      ...this.makeQuestion('Teams', this.matchingTeamsHTML()),
      ...this.tagsEditorQuestion('Tags', user.tags, 'user'),
    ]
  }

  officeEditorHTML (office) {
    return <office-picker
      my='top left'
      at='bottom left'
      inputClass={this.jss.textInput} onSelect={office} value={office} />
  }

  roleEditorHTML (role) {
    return <role-picker
      my='top left'
      at='bottom left'
      inputClass={this.jss.textInput}
      onSelect={role} value={role} />
  }

  matchingTeams (teamFilters = this.teamFilters) {
    return teamFilters().filter(fm => this.user.matchesTeamFilter(fm))
  }

  matchingTeamsHTML () {
    const { jss } = this
    return this.computed(() =>
      this.teams().length
        ? this.teams().map(t => <em class={jss.team}>{t.title()}</em>)
        : <span class={jss.inviteTeamsDescription}>If the user you are inviting matches any existing teams, those teams will show up here.</span>
    )
  }

  /**
   * Save the user; note that this may fail if this escalates the
   * privileges of the user (i.e. teamFilterKeys changes and the
   * user is not an admin).
   */
  async saveClick () {
    const user = this.model()
    user.teamFilterKeys(user.matchingTeamFilterKeys(this.teamFilters))
    return super.saveClick()
  }
}

UserEditor.register()
