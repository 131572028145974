import ViewComponent from 'ViewComponent'
import { color, buttons, input, dropdown } from 'styles'

import icons from 'icons'
import plusIcon from 'icons/light/plus'
import removeIcon from 'icons/light/times'
import caretDown from 'icons/solid/caret-down'
import eyeSlashIcon from 'icons/light/eye-slash'

import handleIcon from 'icons/icon-handle'

/**
 * Base class for <model-sorters> and <model-filters>
 */
export default class ModelOptionsEditor extends ViewComponent {
  newModifierClass () { throw new Error(`Overload: Filter | Sorter`) }
  get noModifiersText () { throw new Error(`Overload noModifiersText`) }
  get addText () { throw new Error(`Overload addText`) }
  modifierLeadHTML () { throw new Error(`Overload modifierLeadHTML`) }

  /**
   * @return {bool} true when the column can be modified by this modifier type
   */
  appliesToCol (column) { throw new Error(`Overload appliesToCol`) }

  /**
   * @param {object} param0
   * @param {ModelListOptions} param0.options
   */
  constructor ({ modifierArray, columns }, ...args) {
    super({}, ...args)
    Object.assign(this, { modifierArray, columns })
  }

  addClick (evt, opts, index = this.modifierArray.length) {
    evt.stopPropagation()
    evt.preventDefault()
    const modifier = this.newModifierClass(opts)
    const firstSuitableColumn = this.columns().find(this.appliesToCol)
    if (!firstSuitableColumn) { return }
    modifier.setColumn(firstSuitableColumn)
    this.modifierArray.splice(index, 0, modifier)
  }

  removeModifierClick (evt, modifier) {
    evt.stopPropagation()
    evt.preventDefault()
    this.modifierArray.remove(modifier)
  }

  columnClick (evt, modifier, col) {
    evt.stopPropagation()
    evt.preventDefault()
    modifier.setColumn(col)
  }

  modifierParamHTML (modifer) {
    throw new Error(`Overload modifierParamHTML`)
  }

  columnPickerHTML (modifier) {
    const { jss } = this
    return (
      <drop-down my='top center' at='bottom center'>
        <template slot='anchor'>
          <div class={jss.columnTitle}>
            {modifier.column().title}
            {icons.caret(caretDown)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.columnTitles}>
            {this.columns()
              .filter(c => this.appliesToCol(c))
              .map(c =>
                <div class={c.show() ? jss.columnTitleMenuItem : jss.columnTitleMenuItemHidden}
                  ko-click={evt => this.columnClick(evt, modifier, c)}>
                  {c.title}
                  <span class={jss.columnTitleMenuEyeIcon}title='This field is hidden but you can still filter by it.'>
                    {icons.inline(eyeSlashIcon)}
                  </span>
                </div>)}
          </div>
        </template>
      </drop-down>
    )
  }

  static get columnPickerCSS () {
    return {
      columnTitle: {
        ...buttons.clickable,
        padding: '4px 10px',
        borderRadius: '4px',
        marginLeft: 6,
        textAlign: 'right',
        '&:hover': {
          backgroundColor: color.fill.light.secondary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary,
          },
        }
      },
      columnTitles: {
        ...dropdown.generalMenu,
        backgroundColor: 'white',
        maxHeight: '65vh',
        overflow: 'auto',
      },
      columnTitleMenuItem: {
        ...dropdown.item,
        display: 'flex',
        alignItems: 'center',
        '& span': {
          fontSize: '0.7rem',
          marginLeft: 'auto',
          visibility: 'hidden'
        }
      },
      columnTitleMenuEyeIcon: {
        paddingLeft: '5px'
      },
      columnTitleMenuItemHidden: {
        extend: 'columnTitleMenuItem',
        '& span': {
          '--icon-color': '#AAA',
          visibility: 'visible'
        }
      }
    }
  }

  get gripHTML () {
    return (
      <span class={this.jss.grip} grip=''>
        {icons.inline(handleIcon)}
      </span>
    )
  }

  modifierHTML (modifier) {
    const { jss } = this
    return (
      <div class={jss.modifier}>
        {this.gripHTML}
        <span class={jss.removeIcon}
          ko-click={evt => this.removeModifierClick(evt, modifier)}>
          {icons.caret(removeIcon)}
        </span>
        {this.modifierLeadHTML(modifier)}
        {this.columnPickerHTML(modifier)}
        {this.modifierParamHTML(modifier)}
      </div>
    )
  }

  static get modifierCSS () {
    return {
      modifier: {
        display: 'grid',
        fontSize: '14px',
        gridAutoFlow: 'column',
        gridGap: '10px',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        padding: '8px 0px',
        alignItems: 'center',
        '& input[type="text"]': {
          ...input.text
        }
      },
      removeIcon: {
        ...buttons.clickable,
        marginRight: '10px'
      },

      noModifiers: {
        color: 'grey',
        minWidth: '260px',
        padding: '6px 0px'
      },

      grip: {
        '--icon-color': color.secondaryButton,
        visibility: 'hidden',
        padding: '0px 0px',
        marginRight: '6px',
        cursor: 'grab',
        '$modifier:hover &': {
          visibility: 'visible'
        }
      }
    }
  }

  get modifierListHTML () {
    const { jss } = this
    const modifiersList = this.modifierArray()
    return modifiersList.length
      ? modifiersList.map(f => this.modifierHTML(f))
      : <div class={jss.noModifiers}>{this.noModifiersText}</div>
  }

  static get modifierListCSS () {
    return {
      modifierList: {
        ...dropdown.functionalMenu
      },
      listButtons: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        marginTop: '10px'
      },
      add: {
        ...buttons.clickable,
        marginTop: '10px'
      }
    }
  }

  get otherButtonsHTML () {}

  onGripMove ({ fromIndex, toIndex }) {
    if (fromIndex === toIndex) { return }
    const array = this.modifierArray()
    const item = array[fromIndex]
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, item)
    this.modifierArray(array)
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.modifierList}
        modifier-count={this.computed(() => this.modifierArray.length)}
        ko-grip-area={this.onGripMove.bind(this)}
        ko-click={evt => evt.stopPropagation()}>
        {this.computed(() => this.modifierListHTML).extend({ deferred: true })}
        <div class={jss.listButtons}>
          <div class={jss.add} ko-click={evt => this.addClick(evt)}>
            {icons.button(plusIcon)}
            {this.addText}
          </div>
          {this.otherButtonsHTML}
        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.modifierListCSS,
      ...this.modifierCSS,
      ...this.columnPickerCSS
    }
  }
}
