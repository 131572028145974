import ConfirmDelete from './confirm-delete'

class ConfirmMultiplePageDelete extends ConfirmDelete {
  constructor (params) {
    super(params)
    this.pageCount = params.pageCount
  }
  get messageHTML () {
    return (
      <p>
        This action will delete the selected <b>{this.pageCount}</b> page{this.pageCount > 1 ? 's' : ''}. <strong>This cannot be undone</strong>.
      </p>
    )
  }
}

ConfirmMultiplePageDelete.register()
