
import Command from 'Command'
import ModelCommandSet from 'Command/ModelCommandSet'

// import { RightViewTabGroup } from  'MinuteBookPanelProvider/right-view'

import {
  getBlockInstanceForSelection, BlockRegister,
} from './blocks'
import './writing-views/writing-right-view-fillins'
import './writing-views/writing-right-view-formatting'
import './writing-views/writing-right-view-doc-options'
import './writing-views/writing-right-view-slots'

import { CommandMenu } from './commands/CommandMenu'
import { signatureCommandSubmenu } from './commands/signatureCommands'

type QuestionModel = import('DataModel/QuestionModel').default
type BodyBlock = import('./blocks/BodyBlock').default
type WritingPanelProvider<T> = import('./WritingPanelProvider').default
type WritingModel = import('./WritingModel').default

export default class WritingCommandSet<T extends QuestionModel = WritingModel> extends ModelCommandSet {
  panelProvider: WritingPanelProvider<T>
  _rightViews: Record<string, JSX>
  commandFilter: KnockoutObservable<string> = ko.observable('')
  commandMenu = new CommandMenu(this.commandFilter)

  get rightViews () {
    if (this._rightViews) { return this._rightViews }
    return (this._rightViews = this.makeRightViews(this.panelProvider))
  }

  makeRightViews (wpp: WritingPanelProvider<T>) {
    return {
      formatting: <writing-right-view-formatting commandSet={this} />,
      fillins: <writing-right-view-fillins commandSet={this}
        commandMenuSlashEvent={wpp.document.commandMenuSlashEvent}
        slotManager={wpp.document.slotManager} />,
      docOptions: <writing-right-view-doc-options commandSet={this}
        document={wpp.document} />,
      slots: <writing-right-view-slots commandSet={this}
        slotManager={wpp.document.slotManager}
        rightView={wpp.rightView} />,
    }
  }

  makeAlignCommands () {
    const alignAction = align => () =>
      (getBlockInstanceForSelection() as BodyBlock).align(align)
    const alignLeft = new Command({
      title: 'Align text left',
      action: alignAction('left'),
    })
    const alignRight = new Command({
      title: 'Align text right',
      action: alignAction('right'),
    })
    const alignCenter = new Command({
      title: 'Align text center',
      action: alignAction('center'),
    })
    const alignJustify = new Command({
      title: 'Align text left',
      action: alignAction('justify'),
    })
    return { alignLeft, alignRight, alignCenter, alignJustify }
  }

  makeRightViewCommands () {
    const { rightView } = this.panelProvider
    const { rightViews } = this
    const closeRightView = () => rightView(null)

    const closeRight = new Command({
      title: 'Hide Right Panel',
      helpGroup: 'Viewing Shortcuts',
      help: (
        <span>
          Quickly hide any <strong>Right Panel</strong> by pressing <kbd>`</kbd>. Note that the <kbd>`</kbd> key will not close the right panel if you are typing in an input field, e.g. if you are in Search.
        </span>
      ),
      action: closeRightView,
    })

    const toggleFormatting = new Command({
      title: 'Open Formatting Panel',
      action: this.setValueOrCall(rightView, rightViews.formatting, closeRightView),
    })

    const toggleFillins = new Command({
      title: 'Open Fillins Panel',
      action: this.setValueOrCall(rightView, rightViews.fillins, closeRightView),
    })

    const toggleSlots = new Command({
      title: 'Open Slots Panel',
      action: this.setValueOrCall(rightView, rightViews.slots, closeRightView),
    })

    const toggleDocOptions = new Command({
      title: 'Open Document Options',
      action: this.setValueOrCall(rightView, rightViews.docOptions, closeRightView),
    })

    const save = new Command({
      title: 'Save',
      action: () => this.panelProvider.model.vmSave(),
    })

    const convertToDocx = new Command({
      title: 'Convert to Microsoft Docx',
      action: () => this.panelProvider.document.convertTo('docx'),
    })

    const convertToPdf = new Command({
      title: 'Convert to PDF',
      action: () => this.panelProvider.document.convertTo('pdf'),
    })

    const convertToMarkdown = new Command({
      title: 'Convert to Markdown',
      action: () => this.panelProvider.document.convertTo('markdown'),
    })

    return {
      closeRight,
      convertToDocx,
      convertToMarkdown,
      convertToPdf,
      save,
      toggleDocOptions,
      toggleFillins,
      toggleFormatting,
      toggleSlots,
    }
  }

  makeBlockInsertCommands () {
    const insertSignatureBlock = new Command({
      title: 'Insert Signature Block',
      action: () => {
        const smc = signatureCommandSubmenu(this.panelProvider.document)
        smc.trigger(this.commandMenu)
      },
    })

    return {
      insertSignatureBlock,
    }
  }

  makeBlockTypeCommands () {
    const blockAction = code => () => this.replaceCurrentBlockType(code)
    const blockTypeH = new Command({
      title: 'Header',
      action: blockAction('h'),
    })

    const blockTypeH2 = new Command({
      title: 'Sub-Header',
      action: blockAction('h2'),
    })

    const blockTypeP = new Command({
      title: 'Paragraph',
      action: blockAction('p'),
    })

    const blockTypeQ = new Command({
      title: 'Quote',
      action: blockAction('q'),
    })

    const blockTypeNP = new Command({
      title: 'Numbered Paragraph',
      action: blockAction('np'),
    })

    const blockTypeL = new Command({
      title: 'List',
      action: blockAction('l'),
    })

    const blockTypeB = new Command({
      title: 'Bullet',
      action: blockAction('b'),
    })

    const blockTypeS = new Command({
      title: 'Signatures',
      action: blockAction('s'),
    })

    return {
      blockTypeH,
      blockTypeH2,
      blockTypeP,
      blockTypeQ,
      blockTypeNP,
      blockTypeL,
      blockTypeB,
      blockTypeS,
    }
  }

  makeUndoCommands () {
    const pp = this.panelProvider
    const undoCommand = new Command({
      title: ' Undo',
      helpGroup: 'Viewing Shortcuts',
      help: (
        <span>Undo actions that were done</span>
      ),
      action: () => pp.undoManager.undo(),
    })
    const redoCommand = new Command({
      title: ' Redo',
      helpGroup: 'Viewing Shortcuts',
      help: (
        <span>Redo an action that was previously undone</span>
      ),
      action: () => pp.undoManager.redo(),
    })
    return { undoCommand, redoCommand }
  }

  /**
   * Replace the current block with the given block type.
   */
  setBodyBlockType (code: string) {
    this.commands[`blockType${code.toUpperCase()}`].trigger()
  }

  replaceCurrentBlockType (code) {
    const { blocks } = this.panelProvider.document
    const current = getBlockInstanceForSelection()
    const currentIndex = blocks.indexOf(current)
    const newBlock = BlockRegister.factory(code, ko.toJS(current))
    blocks.replace(currentIndex, newBlock)
    newBlock.setFocus()
  }


  makeCommands () {
    return {
      ...super.makeCommands(),
      ...this.makeAlignCommands(),
      ...this.makeBlockInsertCommands(),
      ...this.makeBlockTypeCommands(),
      ...this.makeRightViewCommands(),
      ...this.makeUndoCommands(),
    }
  }

  /**
   * Convenience helper for justifying
   */
  setJustify (align: 'Left' | 'Right' | 'Center' | 'Justify') {
    this.commands[`align${align}`].trigger()
  }

  get keyboardShortcuts () {
    const c = this.commands
    const { META, CTRL, SHIFT } = this
    return [
      ...super.keyboardShortcuts,

      ...c.undoCommand.keyboardShortcuts('z', CTRL),
      ...c.undoCommand.keyboardShortcuts('z', META),
      ...c.redoCommand.keyboardShortcuts('z', { ...CTRL, ...SHIFT }),
      ...c.redoCommand.keyboardShortcuts('z', { ...META, ...SHIFT }),
    ]
  }
}

