import { sha256digest } from 'jcrypto/digest'

import ArrayOrderedByIndex from 'utils/ArrayOrderedByIndex'
import {
  stringCommandAsRange, getEditableCaretOffset, setEditableCaretOffset,
  lastEditableRange
} from 'utils/editable'
import { containerElement } from 'utils/range'

import {
  Block, BlockRegister, getBlockInstanceForSelection,
} from './blocks'
import SlotManager from './variables/slots/SlotManager'

type PrecedentModel = import('writing/precedents/PrecedentModel').default

type BodyBlock = import('./blocks/BodyBlock').default
type ArrayComponentVariable = import('./variables/Variable').ArrayComponentVariable

type Slot = import('./variables/slots').Slot

interface ConversionTarget {
  mimetype: string
  extension: string
  toFormat: string
}

interface CaretPosition {
  index: number
  selection: EditableSelection
}

export const PANDOC_LS_KEY = 'dev_mb:pandoc/custom-reference'


const toDocx: ConversionTarget = {
  mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  extension: 'docx',
  toFormat: 'docx',
}

const toMarkdown: ConversionTarget = {
  mimetype: 'text/markdown',
  toFormat: 'gfm',
  extension: 'md',
}

const toPdf: ConversionTarget = {
  mimetype: 'application/pdf',
  extension: 'pdf',
  toFormat: 'pdf',
}

const conversionFormats = {
  docx: toDocx,
  markdown: toMarkdown,
  pdf: toPdf,
}


export default class WritingDocument {
  slotManager: SlotManager = new SlotManager()
  blocks: ArrayOrderedByIndex<Block>
  commandMenuSlashEvent: KnockoutObservable<FakePopoverEvent> = ko.observable()

  /**
   * This is the variable code we'll repeat over.
   */
  repeat: KnockoutObservable<string> = ko.observable()
  repeatSlot: KnockoutObservable<number|string> = ko.observable(0)

  constructor (blocks = []) {
    this.blocks = new ArrayOrderedByIndex(blocks)
    if (!blocks.length) { this.blocks.push(BlockRegister.factory('p')) }
  }

  private get firstBodyBlockWithContent () {
    const blocks = this.blocks as Iterable<BodyBlock>
    for (const b of blocks) {
      const body = ko.unwrap(b.body) || ''
      if (body.trim()) { return b }
    }
    return null
  }

  /**
   * The implicit title of a document is the body-content of the first
   * element in the document.
   */
  get implicitTitle (): string {
    if (!this.blocks.length) { return `Empty` }
    const b0 = this.firstBodyBlockWithContent
    return b0 ? b0.asText.slice(0, 100).replace(/_/, ' ').trim() : 'Untitled'
  }

  isBlank () { return !this.blocks.length || this.blocks.every(b => b.isBlank) }

  async documentContent (blocks: Block[]): Promise<string> {
    const paras = await Promise.all(
      blocks.map(b => b.asMarkdown(blocks, this.slotManager)))
    return paras.join('\n\n')
  }

  repeatedSlot (code = this.repeat()): Slot {
    const g = this.slotManager.slotGroupByCode(code)
    return g ? g.getSlotAtIndex(this.repeatSlot()) : null
  }

  async repeatFallback (blocks: Block[]) {
    return [{
      title: this.implicitTitle,
      content: await this.documentContent(blocks),
    }]
  }

  async repeatDocumentContent (blocks: Block[], code = this.repeat()): Promise<{ title: string, content: string }[]> {
    const slot = this.repeatedSlot(code)
    if (!slot) { return this.repeatFallback(blocks) } // slot never used
    const variable = slot.first() as ArrayComponentVariable
    if (!variable) { return this.repeatFallback(blocks) } // variable never used
    const units = variable.componentValue

    if (!units.length) { return this.repeatFallback(blocks) }

    const originalIndex = variable.index()
    const docs = []
    for (const index in units) {
      variable.index(index)
      slot.propagateChanges(variable)
      const unit = units[index]
      const title = [this.implicitTitle, unit.elvValue].join(' - ')
      const content = await this.documentContent(blocks)
      docs.push({ title, content })
    }
    variable.index(originalIndex)
    slot.propagateChanges(variable)
    return docs
  }

  private __debugDocumentContent () {
    this.documentContent([...this.blocks]).then(console.log)
  }

  async convertTo (format: 'docx' | 'pdf' | 'markdown') {
    const blocks = [...this.blocks]
    if (this.repeat()) {
      const docs = await this.repeatDocumentContent(blocks)
      for (const { title, content } of docs) {
        await this.createDocument(content, title, format)
      }
    } else {
      await this.createDocument(await this.documentContent(blocks),
        this.implicitTitle, format)
    }
  }

  private async createDocument (content: string, title: string, format: 'docx' | 'pdf' | 'markdown') {
    const { toFormat, mimetype, extension } = conversionFormats[format]
    const authManager = window.app.defaultAuthManager
    const referenceDoc = localStorage.getItem(PANDOC_LS_KEY) || ''
    const r = await authManager.firebaseFn('convertDoc', {
      content, toFormat, referenceDoc })
    if (r.status === 'ok') {
      const url = `data:${mimetype};base64,` + r.file
      const file = await fetch(url).then(res => res.blob())
      console.log(`WritingDocument as ${toFormat}:
        ${file.size} bytes as ${await sha256digest(file)}`)
      window.saveAs(file, `${title}.${extension}`)
    }
  }

  addBlockAfterCurrent (code, data = {}) { return this.addOrChangeBlock(code, false, data) }
  changeBlockTo (code, data = {}) { return this.addOrChangeBlock(code, true, data) }

  private addOrChangeBlock (code, remove: boolean, data = {}) {
    const newBlock = BlockRegister.factory(code, data)
    const block = getBlockInstanceForSelection()
    const currentIndex = this.blocks.indexOf(block)
    if (remove) {
      this.blocks.replace(currentIndex, newBlock)
    } else {
      this.blocks.insertAfter(currentIndex, newBlock)
    }
    newBlock.setFocus()
    return newBlock
  }

  saveCaretPosition (): CaretPosition {
    const currentBlock = getBlockInstanceForSelection()
    const index = currentBlock.orderIndex()
    const selection = getEditableCaretOffset()
    return { index, selection }
  }

  async restoreCaretPosition (pn: CaretPosition) {
    this.blocks.at(pn.index).setFocus(false)
    // TODO: ^^ Add setFocus(pn.selection) so we don't get the caret at the
    // start / in the middle flicker
    await lastEditableRange
      .when(e => Boolean(containerElement(e).closest('[contenteditable]')))
    setEditableCaretOffset(pn.selection)
  }

  clearSlashCommand (range = stringCommandAsRange()) {
    if (!range) { return }
    const ce = containerElement(range) as Element | HTMLDocument
    const e = ce instanceof Element && ce.closest('[contenteditable=true]')
    range.deleteContents()
    this.commandMenuSlashEvent(null)
    if (e) { e.dispatchEvent(new CustomEvent('needssave')) }
  }

  insertPrecedent (p: PrecedentModel) {
    // hide the /insert command so the isBlank test works
    this.clearSlashCommand()
    const wasBlank = this.isBlank()

    const src = p.componentFor('writing').toJS()
    this.insert(src.blocks)
    if (wasBlank) {
      this.repeat(src.repeat)
      // this.repeatedSlot(src.repeatedSlot)
    }
  }

  insert (serialized: object[]) {
    const b = getBlockInstanceForSelection()
    const at = this.blocks.indexOf(b)
    const blocks = serialized.map(b => BlockRegister.factory(...b))
    for (const b of blocks) { b.setVariables(this.slotManager) }
    this.blocks.insertAfter(at, ...blocks)
  }
}
