
import PanelProvider from 'PanelProvider'

/**
 * A panel provider that conforms to an interface
 */
export default abstract class ModelPanelProvider<T extends DataModel> extends PanelProvider {
  model: T

  static get Model (): any {
    throw new Error(`Overload ModelPanelProvider.Model`)
  }

  constructor (params) {
    const { app, model } = params
    super(params)
    Object.assign(this, { app, model })
  }

  get modelUrlPrefix () { return this.panelID }
  get historyUrl () {
    return this.model.id.yet(undefined)
      .then(() => `#${this.modelUrlPrefix}--${this.model.vmFirestoreDocPath}`)
  }

  static async createForId (app, authManager, firestoreKey) {
    const model = await this.Model.vmGet(authManager, firestoreKey)
    return new this({ app, model })
  }
}
