/**
 * Filters list that can have "perms" associated with them, which indicates
 * whether it is a "team".
 */

import FiltersList from 'filters-list'

import 'users-perm-editor'
import teamIcon from 'icons/light/users'
import selectedTeamIcon from 'icons/solid/users'


/**
 * <users-filters-list> appears on the left of the Users List modal.
 */
class UsersFiltersList extends FiltersList {
  constructor (params, ...args) {
    super(params, ...args)
    const { entityFilters, teamFilters, permissionChange } = params
    const currentUserModel = ko.observable()

    Object.assign(this, {
      currentUserModel,
      entityFilters,
      teamFilters,
      permissionChange
    })

    window.app.defaultAuthManager
      .getCurrentUserModel().then(currentUserModel)
  }

  actionItems (usersFilterDoc, editing) {
    const { jss } = this
    const { userIsAdmin } = global.app.defaultAuthManager
    return userIsAdmin() ? [
      <div class={jss.actionItem}
        ko-click={evt => this.setPermissionsClick(evt, usersFilterDoc)}>
        Set permissions...
      </div>,
      ...super.actionItems(usersFilterDoc, editing)
    ] : super.actionItems(usersFilterDoc, editing)
  }

  sortedList () {
    const { app } = window
    const authManager = app.defaultAuthManager
    const userFilterList = super.sortedList()
    const userModel = this.currentUserModel()
    if (!userModel) { return [] }
    const isAdmin = authManager.userIsAdmin()
    const teamFilters = app.defaultAuthManager.teamFilters.list()
    return this.sortedListWithTeams(userFilterList, teamFilters, userModel,
      isAdmin)
  }

  /**
   * @param {Array.<FilterModel>} userList
   * @param {Array.<FilterModel>} teamList
   * @return {Array.<FilterModel>} of unique items from userList and teamList
   * If the user is an admin, return all teams, otherwise teams to which the
   * user belongs.
   */
  sortedListWithTeams (userList, teamList, userModel, isAdmin) {
    const dynamicTeamList = teamList
      .filter(fm => !fm.immutable() && (isAdmin || fm.test(userModel)))
    const uniqueFilters = new Set([...userList, ...dynamicTeamList])
    return super.sortedList(uniqueFilters)
  }

  selectedIcon (filter) {
    return filter.isTeam() ? selectedTeamIcon : super.selectedIcon()
  }

  unselectedIcon (filter) {
    return filter.isTeam() ? teamIcon : super.unselectedIcon()
  }

  async setPermissionsClick (evt, teamFilterModel) {
    evt.preventDefault()
    const before = teamFilterModel.vmCurrentReprToSave()
    window.app.modal(
      <modal-dialog
        modalTitle={`Set Permissions for users in the group “${teamFilterModel.title()}”`}
        onDismissal={() => teamFilterModel.vmAssignValues(before)} >
        <template slot='content'>
          <users-perm-editor
            teamFilterModel={teamFilterModel}
            teamFilters={this.teamFilters}
            entityFilters={this.entityFilters}
            permissionChange={this.permissionChange} />
        </template>
      </modal-dialog>
    )
  }
}

UsersFiltersList.register()
