import { pick } from 'lodash-es'

import ModelEditor from 'Editor'
import Category from './EisCategory'
import AddressCategory from './EisAddressCategory'
// import EisSectionNode from './EisSectionNode'

import { color } from 'styles'
import * as constants from './constants'
import summaryViewCSS from 'summaryViewCSS'

import icons from 'icons'
import flagIcon from 'icons/solid/flag'

import 'address-list-editor'
import 'responsible'
import 'jurisdictions'
import 'cap-table'
import 'capital'
import 'person'

import './input'
import './tables'
import './multi'

import eisCss from './eisCss'

/**
 * @typedef {Object} Field
 * @property {string} title
 * @property {string} identifer
 * @property {string} component identifies the component type
 * @property {string} visual overrides a default component visual
 * @property {object} validation
 */

/**
 * Entity Information Summary
 */
export class EntityInformationSummary extends ModelEditor {

  get sectionColCount () { return this.constructor.sectionColCount }
  static get sectionColCount () { return 3 }

  constructor ({ entity, eisSectionNodes, navigation, scrollToComponent }, ...args) {
    super({ model: entity }, ...args)
    Object.assign(this, {
      entity,
      navigation,
      scrollToComponent,
      fieldHover: ko.observable(null),
    })
    /**
     * @type {EisSectionNode[]}
     */
    this.eisSectionNodes = eisSectionNodes
    navigation.eis = this
  }

  get jss () { return super.jss }

  category (title, ...parts) {
    return new Category(this, title, ...parts)
  }

  static get fieldCSS () {
    return {
      // TODO: Move to Eis-Address-Category
      addressAddButton: {
        ...eisCss.changeButton,
        position: 'static',
        gridArea: '',
        padding: '',
        marginRight: '40px',
        '[blank=true] &': {
          gridRow: '',
        },
      },
      addressBlankTitle: {
        ...eisCss.title,
        '[blank] &':{
          margin: '',
          alignSelf: 'center',
        },
      },
      ...pick(eisCss, ['fieldContainer', 'displayArea']),
    }
  }

  get leadCategory () {
    return this.computed(() => this.entity.isProjection()
      ? this.category('Sharing', this.shareDetailsHTML())
      : this.category('Client Information',
        <eis-client-matter eis={this} />,
        <eis-standing-accounting eis={this} />,
        <eis-authorized-to-instruct eis={this} componentName='authorized_to_instruct' />//qtip='eisAuthorizedToInstruct'
      ))
  }

  static get shareCSS () {
    return {
      shareDetails: {
        background: color.systemBackground.light.primary,
        padding: 20,
        borderRadius: 4,
        fontWeight: 600,
        textAlign: 'center',
        'body[dark] &': { // project batman
          background: color.systemBackground.dark.primary,
        },
      }
    }
  }

  shareDetailsHTML () {
    const { jss } = this
    return (
      <div class={jss.shareDetails}>
        {icons.inline(flagIcon)} This entity was shared with you. It is read-only and no changes can be made.
      </div>
    )
  }

  get categories () {
    const { jss } = this

    return this._categories || (this._categories = [
      this.leadCategory,

      this.category('Entity Information',
        <eis-name-jurisdiction eis={this} />,
        <eis-company-status eis={this} />,
        <eis-input eis={this} componentName='number' />,
        <eis-business-entity eis={this} />,
        <eis-input eis={this}
          componentName='entity_group'
          picker={{ propertyGetter: e => e.answerFor('entity_group')}} />,
      ),

      this.category('Key Dates',
        <eis-date eis={this} componentName='incorporation_date' />,
        <eis-month-day eis={this} componentName='fiscalyearend' />
      ),

      new AddressCategory({eis:this, navigationIndex:13}),

      this.category('Business Numbers',
        <eis-business-numbers eis={this} />),

      this.category('Directors',
        <eis-directors eis={this} />),

      this.category('Officers',
        <eis-officers eis={this} />),

      this.category('Governance',
        <eis-governance eis={this} />,
        <eis-range eis={this} componentName='directors_authorized' />,
        <eis-governing-documents eis={this} />,
      ),

      this.category('Capital',
        <eis-authorized-capital eis={this} />,
        <eis-shareholders eis={this} />
      ),

      this.category('Business Names',
        <eis-trade-names eis={this} />),

      this.category('Assets',
        <eis-patents eis={this} />,
        <eis-trademarks eis={this} />,
        <eis-land-registrations eis={this} />,
      ),

      this.category('Registrations',
        <eis-extra-jurisdictional-registrations eis={this} />,
        <eis-municipal-business-licenses eis={this} />,
        <eis-domain-names eis={this} />,
        <eis-ppsa-registrations eis={this} />,
        <eis-regulatory-licenses eis={this} />
      ),

      this.category('Service Providers',
        <eis-service-providers eis={this} />
      ),

      this.category('Incorporation',
        <eis-input eis={this} componentName='created_by'
          picker={{
            extraChoices: constants.ENTITY_CREATED_BY,
            propertyGetter: e => e.answerFor('created_by') }} />,
        <eis-incorporators eis={this} />
      ),

      this.category('Administration',
        <eis-input eis={this} componentName='minute_book_location'
          picker={{
            propertyGetter: e => e.answerFor('minute_book_location') }} />,
        <eis-imports eis={this} />
      )
    ])
  }

  static get css () {
    return {
      ...super.css,
      ...summaryViewCSS,
      ...this.fieldCSS,
      ...this.shareCSS,
      governance: {
        padding: 20,
        position: 'relative',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
        borderRadius: 5,
        justifyContent: 'space-between',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        gridColumn: 'span 2',
        marginBottom: 25
      },
    }
  }

  /*
   *  NOTE: Tried a few other possible versions of the flatMap body which
   *  didn't work:
   *
   *  1: `ko.unwrap(c).html`
   *  2: `this.computed(() => ko.unwrap(c).html)`
   */
  get questionHTML () {
    this.eisSectionNodes([])
    return this.categories.flatMap(c => (
      ko.isObservable(c) ? this.computed(() => ko.unwrap(c).html) : c.html ))
  }

  get buttonsHTML () {}

  /**
   * Print some information helpful to the designer.
   */
  printDebugClickInfo (evt) {
    const eisItem = ko.dataFor(evt.target)

    const fields = eisItem.component &&
      eisItem.component.ItemClass.prototype.fields

    console.info(`
      🐁 ℹ️  <entity-information-summary>::onLayoutClick

      ViewComponent: ${eisItem.constructor.name}
      DataComponent: ${eisItem.componentName}
      Fields: ${JSON.stringify(fields, null, 2)}

    `)
  }

  onLayoutClick (evt) {
    if (evt.metaKey) { return this.printDebugClickInfo(evt) }
    const cf = this.navigation.currentFocus()
    if (cf) { cf.focused(false) }
  }

  activateHoveredField () {
    if (!this.fieldHover()) { return }
    const cf = this.navigation.currentFocus()
    if (cf && cf.changedSinceEdit) { return }
    this.fieldHover().editing(true)
  }
}

EntityInformationSummary.register()

export default EntityInformationSummary
