/**
 * PDF uploading & processing.
 */
import { getUrlFile } from 'request/file'
import { sha512digest } from 'jcrypto'
import EntityModel from 'EntityModel'
import PdfUpload from './PdfUpload'

/**
 * Copy a file from the uploads path to its respective sha512/ path.
 *
 * @param {AuthManager} authManager
 * @param {string} gsPath either a gs:// or File object
 */
export async function importPdfAsEntity (authManager, gsPath) {
  const ref = await authManager.storage.ref(gsPath)
  const downloadUrl = await ref.getDownloadURL()
  const buffer = await getUrlFile(downloadUrl, 'ArrayBuffer')

  const blob = new Blob([buffer])
  const filename = gsPath.split('/').pop()
  const entity = await entityFromPdfBlob(authManager, blob, filename)

  return new PdfUpload({
    authManager,
    entity,
    pdf: blob
  })
}

/**
 * @param {AuthManager} authManager
 * @param {Blob|File} blob containing a PDF
 * @return {PdfUpload}
 */
export async function entityFromPdfBlob (authManager, blob, filename = blob.name) : Promise<EntityModel> {
  const { legalname } = entityPartsFromName(filename)

  console.log(`Obtaining digest ...`)
  const hash = await sha512digest(blob)
  console.log(`Digest completed.`)

  const entityID = `up-${hash}`
  const accountID = authManager.accountID()
  const docPath = `/accounts/${accountID}/entity/${entityID}`

  console.log(`Creating entity at ${docPath}`)
  const entity = await EntityModel.vmGetOrCreate(authManager, docPath, {
    id: entityID,
    accountID,
    origin: 'upload',
    content: {
      legalname,
      tags: ['new'],
      book: {
        pages: [],
        sections: [],
        bookmarks: []
      }
    }
  })

  entity.__isNew = true

  return entity
}

/**
 * The decoded parts of the name
 *
 * Format:
 *
 * {Entity Name} --- {Client Name} --- {Fiscal Year End}.pdf
 *
 * Where the Fiscal Year End is in `Month Day` format.
 *
 * @param {string} name
 */
export function entityPartsFromName (name) {
  const filename = name.replace(/\.pdf$/i, '')
  const parts = filename.replace(/_/g, ' ').split('---')
  for (let i = 0; i < 3; ++i) {
    if (!parts[i]) { parts[i] = ''}
  }
  const [legalname] = parts
  return { legalname }
}

export default { importPdfAsEntity }
