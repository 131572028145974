
import ViewComponent from 'ViewComponent'
import icons from 'icons'
import gripIcon from 'icons/icon-handle'

import { color } from 'styles'

import { Block } from '../blocks/Block'

type ArrayOrderedByIndex<T> = import('utils/ArrayOrderedByIndex').default<T>
type WritingDocumentEditor = import('../writing-views/writing-document-editor').default

export default abstract class BlockEditor<T extends Block> extends ViewComponent {
  block: T
  blocks: ArrayOrderedByIndex<T>
  editor: WritingDocumentEditor
  editableNode: KnockoutObservable<HTMLElement> = ko.observable()
  hasFocus: KnockoutObservable<boolean> = ko.observable()

  constructor ({ block, blocks, editor }) {
    super()
    Object.assign(this, { block, blocks, editor })
  }

  get slotManager () { return this.editor.document.slotManager }

  static get css () {
    return {
      ...super.css,
      area: {
        display: 'grid',
        gridTemplateAreas: `
          'g t'
          '. t'
        `,
        gridTemplateColumns: 'auto 1fr',
        margin: 'auto',
        maxWidth: 800,
        '[gripping] &[selected=true]': {
          opacity: '0.3',
          backgroundColor: '#eee',
        },

        '&[selected=true]': {
          backgroundColor: 'cyan',
        },
      },
      grip: {
        gridArea: 'g',
        padding: '3px 6px',
        opacity: 0,
        transition: '0.2s',
        cursor: 'grab',
        '$area:hover &': {
          opacity: 0.5,
          '&:hover': {
            background:  color.hover.light.primary,
            'body[dark] &': { // project batman
              background: color.hover.dark.primary,
            },
            transition: 'background 120ms ease-in',
            borderRadius: '6px'
          }
        }
      },
      placeholder: {
        gridArea: 't',
        padding: '4px 2px',
        color: color.text.light.tertiary,
        'body[dark] &': { // project batman
          color: color.text.dark.tertiary,
        },
        position: 'absolute',
        paddingLeft: 6
      },
      internal: {
        gridArea: 't',
        display: 'flex',
      },
    }
  }

  private get index () { return this.blocks.indexOf(this.block) }
  // private get nextBlock () { return [...this.blocks][this.index + 1] }
  protected get prevBlock () { return [...this.blocks][this.index - 1] }

  abstract get internalHTML () : JSX

  get template () {
    const { jss } = this
    return (
      <div class={jss.area}
        ko-click={() => this.hasFocus(true)}
        focused={this.hasFocus}
        selected={this.block.isSelected}>
        <div class={jss.grip} grip=''>
          {icons.inline(gripIcon)}
        </div>
        <div class={jss.placeholder}>
          {this.computed(() => this.placeholder)}
        </div>
        <div class={jss.internal}>
          {this.internalHTML}
        </div>
      </div>
    )
  }

  get placeholder () {
    const shouldShow = this.hasFocus() && !this.block.isBlank
    return shouldShow ? this.block.placeholder : null
  }
}

BlockEditor.register()
