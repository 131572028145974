/**
 * A section that displays as text but when double clicked opens an editor.
 */
import InlineInput from 'inline-input'
import 'section-picker'

export default class InlineSection extends InlineInput {
  get inputHTML () {
    this.input(this.value())
    const onSelect = v => {
      console.log(`onSelect`, v)
      this.value(v)
      this.editing(false)
    }
    return (
      <section-picker-section-list my='top left' at='top right'
        editing={this.editing}
        onBlur={this.onBlur}
        value={this.input}
        onSelect={onSelect} />
    )
  }
}

InlineSection.register()
