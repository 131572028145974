import { columnsByPanelID } from 'Column/panelListColumns'
import { buttons, color } from 'styles'

import RootListPanelProvider from "../minute-box-app/RootListPanelProvider"
import ModelListCommandSet from 'model-list/ModelListCommandSet'
import PersonSearchCriteria from "../search/PersonSearchCriteria"

import PersonPanelProvider from "./PersonPanelProvider"
import './person-list-options'

import { hidePopovers } from 'pop-over'
type CrudModel = import('DataModel').CrudModel

import 'views/action-menu'
import { ActionItem } from 'views/action-menu'
import { getCrudModelForPerson } from 'person/utils'

async function entityEditorHTML (entity: CrudModel) {
  const { app } = window
  if ( entity.vmResourceName === 'entity' ) {
    return (
      <entity-editor entity={entity}
        okText='Save Changes'
        onFinish={() => app.modal(undefined)} />
    )
  } else if ( entity.vmResourceName === 'user' ) {
    const { teamFilters } = app.defaultAuthManager
    const valuesBeforeEditing = entity.vmCurrentRepr()
    await teamFilters.loaded.yet(undefined)
    return (
      <user-editor model={entity}
        teamFilters={teamFilters.list}
        onCancel={() => entity.vmAssignValues(valuesBeforeEditing)}
        onFinish={() => app.modal(undefined)} />
    )
  } else {
    return null
  }
}

async function editEntity (entity: CrudModel) {
  const entityEditor = await entityEditorHTML(entity)
  if (!entityEditor) { return }
  hidePopovers()
  const valuesBeforeEditing = await entity.vmCurrentReprToSave()
  let name = entity.vmResourceName as string
  name = name[0].toUpperCase() + name.slice(1)
  window.app.modal(
    <modal-dialog modalTitle={`Edit ${name}`}
      onDismissal={() => entity.vmAssignValues(valuesBeforeEditing)}>
      <template slot='content'>
        {entityEditor}
      </template>
    </modal-dialog>
  )
}

export default class PersonListPanelProvider extends RootListPanelProvider {
  selected: KnockoutObservableArray<PersonRecord> = this.selected

  get panelID () { return 'PersonList' }
  get panelWindowTitle () { return 'People' }
  get historyUrl () { return '#people' }
  get peerPanelList () { return this.app.rootProviderList }
  get CommandSetConstructor () { return ModelListCommandSet }
  get collectionID () { return 'person' }

  makeCriteria () {
    return new PersonSearchCriteria(this.app.memoryDB, this.collectionID)
  }

  makeModelListOptions () {
    this.selected = ko.observableArray([])
    return super.makeModelListOptions()
  }

  visiblySelected () : PersonRecord[] {
    const visible = new Set([...this.criteria.hits()])
    return this.selected().filter(p => visible.has(p))
  }

  get possibleColumns () {
    return columnsByPanelID[this.panelID](this)
  }

  deleteModel (model: CrudModel) {
    const { app } = window
    hidePopovers()
    if ( model.vmResourceName === 'user' ) {
      app.modal(
        <modal-dialog modalTitle='Confirm Delete'>
          <template slot='content'>
            <confirm-user-delete
              model={model}
              onFinish={() => app.modal(undefined)} />
          </template>
        </modal-dialog>
      )
    } else if ( model.vmResourceName === 'entity' ) {
      app.modal(
        <modal-dialog modalTitle='Confirm Delete'>
          <template slot='content'>
            <confirm-model-delete
              model={model}
              onFinish={() => app.modal(undefined)} />
          </template>
        </modal-dialog>
      )
    }
  }

  actionsContentHTML (person: PersonRecord) {
    let actions : ActionItem[] = [
      {
        label: 'View',
        action: () => this.selectRow(person),
      },
    ]
    const crudModel = getCrudModelForPerson(person)
    if (crudModel) {
      actions = [
        ...actions,
        {
          label: 'Edit...',
          action: () => editEntity(crudModel),
        },
        {
          label: 'View audit trail',
          action: () => this.selectRow(person, true),
        },
      ]
    }
    return <action-menu-items actions={actions} />
  }

  get main () {
    return this.computed(() => (
      <model-list
        columnJss={this.jss}
        class={this.jss.modelList}
        style='--model-list-offset-top: 57px'
        sorters={this.listOptions.sorters}
        columns={this.listOptions.columns}
        stickyColumns={2}
        hits={this.criteria.hits}
        onSelect={r => this.selectRow(r)}
        contextMenuForRow={row => this.actionsContentHTML(row)}
        commandSet={this.commandSet}
        hoverRow={this.hoverRow} />
    ))
  }

  get rootTopLeft () {
    const { jss } = this
    return (
      <div class={jss.rootTopLeft}>
        <person-list-options
          options={this.listOptions}
          hideMenuItems={this.hideMenuItems}
          savedFilters={this.filtersModelList.list}
          getSelectedPersons={() => this.visiblySelected()}
          />
      </div>
    )
  }

  selectRow (model, showAudit = false) {
    const { app } = this
    this.app.panelProvider(new PersonPanelProvider({ app, person:model, editEntity, showAudit }))
  }

  static get css () {
    return {
      ...super.css,
      selectColumnButton: {
        '--icon-height': '20px',
        paddingTop: '2px',
        '&:hover': {
          '--icon-color': 'orange',
        }
      },
      rootTopMiddle: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      crystalizeButton: {
        ...buttons.clickable,
      },
      footer: {
        position: 'relative',
        height: '75px',
        width: '100%',
      },
      footerViewportSticky: {
        position: 'sticky',
        height: '100%',
        width: 'calc(100vw - 15px)',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
      },
      _type: {
        padding: '4px 8px',
        borderRadius: 4,
        fontSize: '0.9em',
      },
      typeCell: {
        '&[value=reference]': {
          extend:'_type',
          backgroundColor: color.color.light.teal,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.teal,
            color: color.text.dark.altPrimary
          },
        },
        '&[value=entity]': {
          extend:'_type',
          backgroundColor: color.color.light.yellow,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.yellow,
            color: color.text.dark.altPrimary
          },
        },
        '&[value=user]': {
          extend:'_type',
          backgroundColor: color.color.light.green,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.green,
            color: color.text.dark.altPrimary
          },
        }
      },
    }
  }

  get foot () {
    const { jss } = this
    const count = this.criteria.nbHits
    const people = this.computed(() => (
      ko.unwrap(count) === 1 ? 'Person' : 'People'
    ))
    return (
      <div class={jss.footer}>
        <div class={jss.footerViewportSticky}>
          {count}{' '}{people}
        </div>
      </div>
    )
  }

}
