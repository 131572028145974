export default (
  <svg width='213px' height='40px' viewBox='0 0 213 40'>
    <defs>
      <circle id='path-1' cx='106' cy='17' r='17' />
      <filter x='-14.7%' y='-11.8%' width='129.4%' height='129.4%' filterUnits='objectBoundingBox' id='filter-2'>
        <feOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1' />
      </filter>
    </defs>
    <g id='Login-Page' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
      <g id='login-page' transform='translate(-293.000000, -367.000000)'>
        <g id='section-9' transform='translate(189.000000, 255.000000)'>
          <g id='imageOr' transform='translate(105.000000, 114.000000)'>
            <path d='M0.359375,18 L211,18' id='Line' stroke='#F2F2F2' stroke-linecap='round' />
            <g id='Oval'>
              <use fill='black' fill-opacity='1' filter='url(#filter-2)' href='#path-1' />
              <use fill='#FFFFFF' fill-rule='evenodd' href='#path-1' />
            </g>
            <text id='or' font-family='RobotoSlab-Regular, Roboto-Slab, roboto-slab, open-sans, sans-serif' font-size='14.84375' font-weight='normal' fill='#424242'>
              <tspan x='98' y='21'>or</tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
