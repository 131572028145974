/**
 * Editor for an entity.
 **/
import FileModel from 'FileModel'
import { JURISDICTIONS } from 'jurisdictions'

import ModelEditor from 'ModelEditor'

import { buttons, color, typography } from 'styles'
import { observeProperty } from 'person/utils'

import 'jurisdictions'

import { ENTITY_TYPES } from '../entity-information-summary/constants'

export default class EntityEditor extends ModelEditor {
  entity: import('EntityModel').default

  constructor (params) {
    const { entity } = params
    super({ model: entity, ...params })
    Object.assign(this, { entity })
  }

  static get css () {
    return {
      ...super.css,
      ...this.bookCSS,
      ...this.clientCSS,
      layout: {
        ...super.css.layout,
        gridTemplateColumns: 'auto auto',
      },
      entityTitle: {
        fontWeight: 'bold',
        textAlign: 'center'
      },
      entityName: {
        fontWeight: 'normal',
        textTransform: 'uppercase',
        textAlign: 'center'
      },

      textInput: {
        fontSize: 15,
        padding: '8px 20px',
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
        borderRadius: 6,
        minWidth: '250px',
        border: '1px solid transparent',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        '&:focus, &:active': {
          outline: 'none',
          borderLeft: '3px solid #ffd502',
          borderRadius: '3px'
        }

      },
      question: {
        justifySelf: 'end',
        fontWeight: 'bold',
        alignSelf: 'center'
      },

      // Entity type menu
      //
      selectInput: {
        border: '1px solid transparent',
        minHeight: '30px',
        padding: '8px 20px',
        width: '250px',
        fontSize: '0.8em',
        fontFamily: typography.titleFamily,
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        backgroundColor: color.dropdown.light.primary,
        '& optgroup': {
          color: 'green',
        },
        '& option': {
          color: 'orange',
        },
        'body[dark] &': { // project batman
          backgroundColor: color.dropdown.dark.primary,
          color: color.text.dark.primary
        },
      },

      dateInputContainer: {
        width: '250px',
        backgroundColor: color.textInput.light.primary,
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
        borderRadius: 6,
        border: '1px solid transparent',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        '&:focus, &:active': {
          outline: 'none',
          borderLeft: '3px solid #ffd502',
          borderRadius: '3px'
        }
      }

    }
  }

  get questionHTML () {
    const { jss } = this
    const content = this.entity.content
    const legalName = content.getValueByKey('legalname')
    const matterNumber = content.getValueByKey('matter_number')
    const jurisdiction = content.getValueByKey('jurisdiction')
    const entityType = content.getValueByKey('entity_type')
    const responsible = content.getValueByKey('responsible')
    const statute = content.getValueByKey('governing_statute')
    // const fye = content.getValueByKey('fiscalyearend')
    const STATUTES = JURISDICTIONS.map(j => j[3]).flat()

    return [
      ...this.makeQuestion(`Client`, this.clientPickerHTML),
      ...this.textInputQuestion('Matter number', matterNumber, this.jss.textInput),
      ...this.textInputQuestion('Entity name', legalName, this.jss.textInput),
      ...this.makeQuestion('Incorporation Date', this.incorpDateHTML),
      ...this.makeQuestion('Tags',
        <tags-editor-modal class={jss.editArea}
          tags={this.entity.tags}
          index={'entity'}
          inputClass={jss.textInput} />),
      ...this.makeQuestion('Responsible',
        <responsible-editor
          personGenerator={this.entity}
          list={responsible}
          inputClass={jss.textInput} />),
      ...this.makeQuestion('Jurisdiction',
        <jurisdiction-picker-modal my='bottom left' at='top left'
          inputClass={jss.textInput}
          value={jurisdiction}
        />),
      ...this.makeQuestion('Governing Statute',
        <model-property-picker my='top left' at='bottom left'
          extraChoices={STATUTES}
          indexName='entity'
          inputClass={jss.textInput}
          propertyGetter={e => e.answerFor('governing_statute')}
          value={statute}
        />),
      ...this.makeQuestion('Entity Type',
        <model-property-picker my='top left' at='bottom left'
          extraChoices={ENTITY_TYPES}
          indexName='entity'
          inputClass={jss.textInput}
          onSelect={entityType}
          propertyGetter={e => e.answerFor('entity_type')}
          value={entityType}
        />),
      // ...this.selectQuestion('Entity type', entityType, entityTypeOptions, this.jss.selectInput),
      // ...this.makeQuestion('MinuteBook', this.minuteBookQuestionHTML)

      // <div class={jss.layout}>
      //   <div class={jss.question}>
      //     Fiscal Year End
      //   </div>
      //   <div class={jss.field}>
      //     <input class={jss.input} type='text' ko-textInput={fye.M} />
      //     <input class={jss.input} type='text' ko-textInput={fye.d} />
      //   </div>
      // </div>,
    ]
  }

  get incorpDateHTML () {
    const idDC = this.entity.answerFor('incorporation_date')
    return (
      <date-picker-popover my='top left' at='bottom left'
        classes={{ popover: this.jss.dateInputContainer }}
        value={idDC.dateValue} />
    )
  }

  get clientPickerHTML () {
    const { jss } = this
    const value = observeProperty(this.entity.answerFor('client'), 'name', 0)
    return (
      <client-picker-modal
        my='top left'
        at='bottom left'
        extraChoices={[...this.entity.getPersons()].map(p => p.name).flat()}
        inputClass={jss.textInput}
        value={value} />
    )
  }

  static get clientCSS () {
    return {
      clientName: {
        display: 'flex',
        '&:hover $clientTimes': {
          display: 'block'
        }
      },
      clientTimes: {
        ...buttons.clickable,
        display: 'none',
        marginLeft: 'auto'
      }
    }
  }

  get minuteBookQuestionHTML () {
    // const { jss } = this

    // return this.computed(() => (
    //   <div class={jss.bookSeries}>
    //     <input class={jss.input} type='file' placeholder='Add a PDF' />
    //   </div>
    // ))
  }

  static get bookCSS () {
    return {
      bookSeries: {},
      bookItem: {}
    }
  }

  async urlToFilename (gsUrl) {
    const { authManager } = this.entity
    const fm = await FileModel.fromGsUrl(authManager, ko.unwrap(gsUrl))
    return fm.name
  }

  /**
   * Make the saving asynchronous and immediately close the modal.
   */
  async saveClick () {
    super.saveClick()
    window.app.modal(undefined)
  }
}

EntityEditor.register()
