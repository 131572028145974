import ViewComponent from 'ViewComponent'
import { buttons, input, color } from 'styles'
import { format } from 'date-fns/esm'
import icons from 'icons'
import solidPinIcon from 'icons/solid/thumbtack'
import outlinePinIcon from 'icons/regular/thumbtack'
import flagIcon from 'icons/solid/flag'
import calendarIcon from 'icons/solid/calendar'

/**
 * @param {object} param0
 * @param {string} param0.uid the current user's id
 * @param {function} param0.getFirestoreDoc a getter for the doc; if we're
 *   editing then this will always return the same thing; if new, then each
 *   invocation will return a unique new document.
 */
export class NoteEditor extends ViewComponent {
  constructor ({ uid, model, getFirestoreDoc, existingNote, getPinpoint, message, pinned }, ...args) {
    super({}, ...args)
    Object.assign(this, {
      uid,
      model,
      getPinpoint,
      getFirestoreDoc,
      existingNote,
      editing: Boolean(message),
      saving: ko.observable(false),
      message: ko.observable(message || ''),
      pinned: ko.observable(Boolean(pinned)),
      reminder: (existingNote ? existingNote.data().reminder : null) || null,
      flagged: ko.observable((existingNote ? existingNote.data().flagged : false) || false),
    })
  }

  static get css () {
    return {
      ...super.css,
      ...this.buttonCSS,
      area: {
        minWidth: '600px'
      },

      saveButton: {
        ...buttons.modalOK
      },

      messageInput: {
        ...input.all,
        padding: '5px',
        fontSize: '1.1rem',
        width: '100%',
        minHeight: '5rem',
        border: '1px solid rgba(0,0,0,0.5)',
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
      },

      label: {
        display: 'inline-block',
      },
      _noteFeatures: {
        backgroundColor: color.fill.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.secondary,
        },
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      checkbox: {
        display: 'none'
      },
      reminderArea: {
        extend: '_noteFeatures',
        gridArea: 'reminder',
        '&:not([checked])': {
          '--inline-date-value-color': '#aaa',
        },
      },
      reminderLabelPopover: {
        display: 'flex',
      },
      reminderLabel: {
        extend: 'label',
      },
      flagArea: {
        extend: '_noteFeatures',
        gridArea: 'flag',
        '--icon-size': '20px',
        '&[flagged]': {
          '--icon-color': 'red',
        }
      },
      calendarIcon: {
        marginRight: 15
      },
      optionsArea: {
        display: 'grid',
        gridTemplateColumns: '50% auto auto auto',
        gridTemplateAreas: `
        ' . reminder pin flag '
        `
      }
    }
  }

  get reminderHTML () {
    const { jss } = this

    /** TODO
     *  Most of this checkbox + picker interaction logic is duplicated in the
     *  share modal. Might want to consider putting it in a re-usable component.
     */
    const checked = ko.observable(Boolean(this.reminder))
    const displayDate = ko.observable(this.reminder ? new Date(this.reminder) : null)
    const showPicker = ko.observable(false)
    checked.subscribe(ck => { if (ck && !displayDate()) { showPicker(true) }})
    showPicker.subscribe(showing => { if (!showing && !displayDate()) checked(false) })
    displayDate.subscribe(() => checked(true))
    ko.computed(() => { this.reminder = (checked() && displayDate()) ? format(displayDate(), 'yyyy-MM-dd') : null })

    return (
      <div class={jss.reminderLabelPopover}
        checked={this.computed(() => checked() || undefined)}>
        <label class={jss.reminderLabel}>
          <input type='checkbox' class={jss.checkbox} ko-checked={checked}
            ko-click={evt => evt.stopPropagation()}/>
        </label>
        <date-picker-popover
          showing={showPicker}
          value={displayDate}
          drawerSide='left'
          relatedDateGenerator={this.model} />
      </div>
    )
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.area}>
        <textarea type='text'
          class={jss.messageInput}
          ko-disable={this.saving}
          ko-textinput={this.message} />

        <div class={jss.optionsArea}>
            <div class={jss.reminderArea}>
            <span class={jss.calendarIcon}>{icons.inline(calendarIcon)}</span>
              {this.reminderHTML}
            </div>
          <div class={jss.pinButton} ko-click={() => this.pinned.modify(v => !v)}
                pinned={this.computed(() => this.pinned() || undefined)}>
                {this.computed(() => this.pinned()
                  ? [icons.inline(solidPinIcon)]
                  : [icons.inline(outlinePinIcon)])}
          </div>
          <div class={jss.flagArea} flagged={this.computed(() => this.flagged() || undefined)} ko-ownClick={() => this.flagged.modify(v => !v)}>
              {icons.inline(flagIcon)}
          </div>
        </div>

        <div class={jss.buttons}>
          <div class={jss.buttonGroupLeft}>
            { this.editing ?
              <async-button faceClass={jss.shareButton} action={() => this.deleteClick()}>
                <template slot='face'>Delete Note</template>
              </async-button>
              : undefined
            }
          </div>
          <div class={jss.buttonGroupRight}>
            <button class={jss.cancelButton} ko-click={() => this.cancelClick()}>
              Cancel
            </button>
            <async-button faceClass={jss.shareButton} action={() => this.saveClick()}>
              <template slot='face'>{this.editing ? 'Save Note' : 'Add Note'}</template>
            </async-button>
          </div>
        </div>
      </div>
    )
  }

  static get buttonCSS () {
    return {
      _button: {},

      buttons: {
        gridColumn: '1/-1',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingTop: '10px'
      },

      buttonGroupLeft: {
        display: 'flex'
      },

      buttonGroupRight: {
        display: 'flex',
        justifyContent: 'flex-end'
      },

      shareButton: {
        ...buttons.modalOk
      },

      cancelButton: {
        ...buttons.modalCancel
      },

      pinButton: {
        extend: '_noteFeatures',
        margin: '0 5px 0 5px',
        gridArea: 'pin',
        '& svg': {

        },
      },
    }
  }

  /**
   * Save the model
   */
  async saveClick () {
    this.saving(true)
    const noteData = {
      createdByUid: this.uid,
      pinpoint: this.getPinpoint(),
      message: this.message(),
      pinned: this.pinned(),
      reminder: this.reminder || null,
      flagged: this.flagged() || false,
      created: firebase.firestore.FieldValue.serverTimestamp()
    }
    await this.getFirestoreDoc().set(noteData)
    this.message('')
    document.body.click() // Hide the dropdown
    this.saving(false)

    window.app.modal(undefined)
  }

  async deleteClick () {
    this.saving(true)
    await this.getFirestoreDoc().delete()
    this.message('')
    document.body.click() // Hide the dropdown
    this.saving(false)
    window.app.modal(undefined)
  }

  cancelClick () {
    window.app.modal(undefined)
  }
}

NoteEditor.register()
