
import { typography } from 'styles'

import EisSingleComponentField from '../EisSingleComponentField'

type RangeComponent = import('DataModel/components/RangeComponent').default

export default class EisRange extends EisSingleComponentField {
  inputMin = ko.observable<string|number>()
  inputMax = ko.observable<string|number>()
  componentMin: KnockoutObservable<string|number>
  componentMax: KnockoutObservable<string|number>
  component: RangeComponent

  constructor (params) {
    super(params)
    Object.assign(this, {
      componentMin: this.component.min,
      componentMax: this.component.max,
    })
  }

  static get css () {
    return {
      ...super.css,
      label: {
        background: 'white',
        display: 'grid',
        padding: 20,
        zIndex: 1,
        position: 'relative',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        gridTemplateColumns: '1fr auto 1fr'
      },
      title: {
        ...super.css.title,
        fontFamily: typography.titleFamily,
        gridColumn: '1/4',
        marginBottom: 20,
      },
      range: {
        '[blank] &': { display: 'none' },
      },
      input: {
        height: 50,
        fontSize: '1.5em',
        borderRadius: 4,
        border: '1px solid black',
        width: 50,
        textAlign: 'center',
        display: 'flex',
        justifySelf: 'center'
      }
    }
  }

  get displayHTML () {
    const { jss } = this
    return (
      <div class={jss.range}>
        {this.componentMin} to {this.componentMax}
      </div>
    )
  }

  get editHTML () {
    const { jss } = this
    this.inputMin(this.componentMin())
    this.inputMax(this.componentMax())

    return (
      <label class={jss.label}>
        <input class={jss.input} type='input' ko-value={this.inputMin} /> to
        <input class={jss.input} type='input' ko-value={this.inputMax} />
      </label>
    )
  }

  onSave () {
    this.componentMin(this.inputMin())
    this.componentMax(this.inputMax())
  }

  onDiscard () {
    this.inputMin(this.componentMin())
    this.inputMax(this.componentMax())
  }
}

EisRange.register()
