import 'company-status'

import EisSelect from './eis-select'

export default class EisCompanyStatus extends EisSelect {
  constructor (params) {
    const options  = [
      'Active',
      'Inactive',
    ]
    super({ ...params, options })
  }

  get fieldTitle () {
    return 'Entity Status'
  }

  get componentValue () {
    return this.entity.companyStatus
  }

  isBlank () {
    return !this.componentValue()
  }

  get displayValue () {
    return (
      <company-status status={this.componentValue} />
    )
  }

  static get css () {
    return {
      ...super.css,
      displayArea: {
        ...super.css.displayArea,
      },
    }
  }
}

EisCompanyStatus.register()
