//
//   ArrayComponent
//   ~~~~~~~~~~~~~~
//
//   A base class for arrays of class instances.
//
import { isEqual } from 'lodash-es'

import DataComponent from './DataComponent'
import UnitView from './UnitView'

type FieldDescription = import('./UnitField').FieldDescription
type UnlabeledFieldDescription = Omit<FieldDescription, 'label'>

const { ko } = window


// Note that we do not register ArrayComponent as it is esentially "abstract"
// in the sense that it cannot by itself be instantiated.

export default abstract class ArrayComponent extends DataComponent implements RelatedSource {
  array: KnockoutObservableArray<UnitView>

  static get namespace (): 'array' { return 'array' }
  abstract get ItemClass (): typeof UnitView
  static get ItemClass () { return this.prototype.ItemClass }

  static get hasReminderDates () {
    return Object.entries(this.ItemClass.fields).some(([name, fieldType]) => (
      this.ItemClass.fieldOfType(fieldType).reminderDate
    ))
  }

  static get fieldDescriptions (): FieldDescription[] {
    const proto = this.ItemClass as {
      fieldDescriptions: UnlabeledFieldDescription[] }
    return this.setI18nLabels(proto.fieldDescriptions)
  }

  static get variableFieldDescriptions (): FieldDescription[] {
    const proto = this.ItemClass as {
      variableFieldDescriptions: UnlabeledFieldDescription[] }
    return this.setI18nLabels(proto.variableFieldDescriptions)

  }

  /**
   * Generate i18n labels with key of
   * "DC" _ "ARRAY" _ COMPONENT _ PROPERTY
   */
  private static setI18nLabels (unlabeled: UnlabeledFieldDescription[]): FieldDescription[] {
    const descriptions = unlabeled as FieldDescription[]
    for (const desc of descriptions) {
      desc.label = this.i18nValueFor(desc.property)
    }
    return descriptions
  }

  /* A UnitView / ArrayComponent.Item needs only to overload one
     of the following. */

  init () {
    this.array = ko.observableArray([])
  }

  set (values = []) {
    const { ItemClass } = this

    if (!values) {
      this.array([])
      return
    }

    if (!Array.isArray(values)) {
      console.error('ArrayComponent::set given non-array value', values)
      throw new Error(`Bad ArrayComponent::set value`)
    }

    this.array(values.map(v => new ItemClass(v, this)))
  }

  get () { return this.array }
  toJS () { return this.getArray().map(v => v.toJS()) }
  push (data: object) : UnitView {
    const item = new this.ItemClass(data, this)
    this.array.push(item)
    return item
  }

  getArray () {
    return [...this.array].filter(v => v && v.shouldBeSaved(v))
  }

  validate () {
    // TODO: all items validate
  }

  is_blank () {
    return this.getArray().length === 0
  }

  /**
   * Equality - with `undefined` items removed from objects.
   * @param  {[type]}  other Array of objects to compare
   */
  is_equal (other) {
    return isEqual(this.toJS(), other)
  }

  * remindersOf (generator: IterableIterator<Partial<EventRecord>>) : IterableIterator<EventRecord> {
    const { identifier, model } = this
    for (const { unit, date, reason } of generator) {
      yield ({ unit, date, reason, identifier, model, component: this })
    }
  }

  * reminderDates (given, range) : IterableIterator<EventRecord> {
    for (const item of this.array) {
      if (!item.genRemindersAtDate) { continue }
      yield * this.remindersOf(item.genRemindersAtDate(given, range))
    }
  }

  * getPersons (filter: PersonFilter) : IterableIterator<PersonRecord> {
    for (const item of this.array) {
      yield * item.getPersons(filter)
    }
  }

  * signatoryOrigins () {
    const persons = this.ItemClass.fieldDescriptions
      .filter(d => d.type === 'person')
    for (const p of persons) {
      const title = `${this.properties.title} ${p.label} of ${this.model.cvModelTitle}`
      yield {
        title,
        date: new Date(),
        filter: null,
        component: this.properties.property_key,
      }
    }
  }
}
