
import { groupBy, sortBy } from 'lodash-es'

import { interleave } from 'utils/iterable'
import { metaKey } from 'utils/platform'

import ModalDialog from 'modal-dialog'

import { buttons, color, typography } from 'styles'

/**
 * Add lowercase Event key values mappings to unicode/other text.
 */
const keyStringMap = {
  'arrowleft': '←',
  'arrowright': '→'
}
const keyStringRex = new RegExp(Object.keys(keyStringMap).join('|'), 'gi')

/**
 * `<shortcut-help>`
 */
export default class ShortcutHelp extends ModalDialog {
  /**
   * @param {Array.<Shortcut>} shortcuts
   */
  constructor ({ shortcuts }) {
    super({ title: 'Shortcut Help' })
    Object.assign(this, { shortcuts })
  }

  get keyboardShortcuts () {
    const { app } = global
    return [
      ...super.keyboardShortcuts,
      { key: '?', modifiers: ['shift'], action: () => app.modal(undefined) }
    ]
  }

  static get css () {
    return {
      ...super.css,
      ...this.helpCSS,
      ...this.commandCSS,
      okButton: {
        ...buttons.modalOk,
        justifyContent: 'center',
        marginLeft: 'auto',
        display: 'flex',
        width: '120px'
      }
    }
  }

  static get commandCSS () {
    return {
      title: {
        fontSize: '2em',
        fontWeight: 'bold',
        fontFamily: typography.titleFamily,
        textTransform: 'capitalize'
      },
      shortcut: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
          '&:last-child': {
            borderBottom: `1px solid transparent`
          },
        },
        padding: '10px 0px 20px 0px',
        alignItems: 'center',
        '&:first-child': {
          marginTop: '10px'
        },
        '&:last-child': {
          borderBottom: 'none'
        },
        '& kbd': {
          fontFamily: typography.mono,
          backgroundColor: '#eff0f1',
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.primary,
          },
          padding: '2px 6px 0px 6px',
          textTransform: 'uppercase',
          fontWeight: 600,
        }
      },
      shortcutTitle: {
        textTransform: 'capitalize'
      },
      keyList: {
        borderBottomColor: '#cdd2d4',
        width: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'flex-end'
      },
      keyPlus: {
        marginLeft: '5px',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
      keyOr: {
        padding: '0px 8px',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
      keySet: {
        backgroundColor: color.gray.cc,
        display: 'flex',
        fontFamily: typography.altFontFamily,
        fontWeight: '400',
        alignItems: 'center'
      },
      key: {
        border: '1px solid #d6dadc',
        boxShadow: '0 2px 3px rgba(0,0,0,.1)',
        padding: '6px 10px',
        borderBottom: '4px solid #e8e8e8',
        borderRadius: '5px',
        backgroundColor: color.systemBackground.light.tertiary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          borderBottom: `4px solid ${color.systemBackground.dark.primary}`,
          border: `1px solid ${color.systemBackground.dark.primary}`,
          color: color.text.dark.primary
        },
        margin: 5,
        textTransform: 'uppercase'
      },
      modifiers: {
        border: '1px solid #d6dadc',
        padding: '6px 10px',
        boxShadow: '0 2px 3px rgba(0,0,0,.1)',
        borderRadius: 5,
        backgroundColor: color.systemBackground.light.tertiary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          borderBottom: `4px solid ${color.systemBackground.dark.primary}`,
          border: `1px solid ${color.systemBackground.dark.primary}`,
          color: color.text.dark.primary
        },
        borderBottom: '4px solid #e8e8e8',
        margin: '5px 0px 5px 5px',
        '&:empty': {
          display: 'none'
        }
      },
      content: {
        border: '1px solid transparent',
        margin: 'auto',
        marginTop: '90px',
        padding: 30,
        boxShadow: '2px 2px 21px 7px rgba(0,0,0,0.2)',
        borderRadius: 6,
        backgroundColor: color.shortcutModal.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.shortcutModal.dark.primary,
        },
        width: '600px'
      },
    }
  }

  get keyPlusHTML () {
    return <div class={this.jss.keyPlus}>+</div>
  }

  modifierIcon (m) {
    return m.replace('meta', metaKey())
  }

  keyString (key) {
    return key.replace(keyStringRex, m => keyStringMap[m.toLowerCase()])
  }

  keyHTML (shortcut) {
    const { jss } = this
    const keyHTML = (
      <div class={jss.key}>{this.keyString(shortcut.key)}</div>
    )
    const modifiersHTML = Object.keys(shortcut.modifiers)
      .filter(m => shortcut.modifiers[m])
      .sort()
      .map(this.modifierIcon)
      .map(m => <div class={jss.modifiers}>{m}</div>)

    const keysHTML = [...modifiersHTML, keyHTML]
    return (
      <div class={jss.keySet}>
        {[...interleave(keysHTML, this.keyPlusHTML)]}
      </div>
    )
  }

  get keyOrHTML () {
    return <div class={this.jss.keyOr}>or</div>
  }

  /**
   * @param {Array.{object}} shortcuts
   * @param {Command} command
   */
  commandHTML (command, shortcuts) {
    const { jss } = this
    return (
      <div class={jss.shortcut}>
        <div class={jss.shortcutTitle}>
          {command.title}
        </div>
        <div class={jss.keyList}>
          {[...interleave(shortcuts.map(k => this.keyHTML(k)), this.keyOrHTML)]}
        </div>
        <div class={jss.help}>
          {command.help}
        </div>
      </div>
    )
  }

  static get helpCSS () {
    return {
      help: {
        gridColumn: '1/-1',
        fontWeight: 'normal',
        fontSize: '0.8em'
      },
      helpGroup: {
        background: 'rgba(0,0,0,0.05)',
        'body[dark] &': { // project batman
          backgroundColor: color.shortcutModal.dark.secondary,
        },
        borderRadius: '6px',
        padding: '0 10px 0 20px',
        '&:hover': {
          boxShadow: '2px 2px 0px 0px rgba(0,0,0,0.2)',
        },
      },
      code: {
        fontFamily: typography.mono,
        whiteSpace: 'normal',
        backgroundColor: '#eff0f1',
        padding: '1px 5px 0px 5px'
      },
      groupTitle: {
        fontSize: '2em',
        fontWeight: 600,
        paddingBottom: 10,
        paddingTop: 20,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        textTransform: 'capitalize',
        '&:empty': {
          display: 'none'
        }
      },
      commandList: {
        fontWeight: 700,
        marginBottom: '20px'
      },
    }
  }

  helpGroupHTML (title, shortcuts) {
    const { jss } = this
    const commands = sortBy([...new Set(shortcuts.map(s => s.command))],
      c => c.helpIndex || c.title)
      .filter(c => !c.isHidden)
      .map(c => this.commandHTML(c, shortcuts.filter(s => s.command === c)))

    return commands.length ? (
      <div class={jss.helpGroup}>
        <div class={jss.groupTitle}>
          {title}
        </div>
        <div class={jss.commandList}>
          {[...commands]}
        </div>
      </div>
    ) : ''
  }

  get contentHTML () {
    const commands = this.shortcuts.filter(s => s.command)
    const helpGroups = groupBy(commands, s => s.command.helpGroup)
    const groupKeys = Object.keys(helpGroups).sort()

    return [
      ...groupKeys.map(hg => this.helpGroupHTML(hg, helpGroups[hg])),
      <div class={this.jss.okButton}
        ko-click={() => this.close()}>OK</div>
    ]
  }
}

ShortcutHelp.register()
