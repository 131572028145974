/**
 *
        color: color.text.light.primaryText,
        projectBatman: {
          color: color.text.dark.primaryText
        }

        becomes

        composes: [theme.primaryText],

 */
import color from './color'

const { jssLib }= window
// enum THEMES {light = 'light', dark = 'dark' }

const THEMES = ['dark', 'light'] as const
type THEME_TYPE = typeof THEMES[number]

const selected = ko.observable<THEME_TYPE>('light')
  .extend({ localStorage: 'themeName' })

/**
 * Return the selected or "auto" theme.
 */
const chosen = ko.computed({
  read: () => {
    const theme = selected()
    if (!THEMES.includes(theme)) {
      const preferDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      return preferDark ? 'dark' : 'light'
    }
    return theme
  },
  write: selected,
})

function makeStyles (theme: THEME_TYPE) {
  return {
    primaryText: {
      color: color.text[theme].primary,
      '--icon-color': color.text[theme].primary,
    },
    tertiaryText: {
      color: color.text[theme].tertiary,
      '--icon-color': color.text[theme].primary,
    },
    secondaryText: {
      color: color.text[theme].secondary,
      '--icon-color': color.text[theme].primary,
    },

    primaryColor: {
      extend: 'primaryText',
      backgroundColor: color.systemBackground[theme].primary,
    },
    tertiaryColor: {
      extend: 'tertiaryText',
      backgroundColor: color.systemBackground[theme].tertiary,
    },
    secondaryColor: {
      extend: 'secondaryText',
      backgroundColor: color.systemBackground[theme].secondary,
    },

    primaryHover: {
      '&:hover, &[selected=true]': {
        backgroundColor: color.hover[theme].primaryOpaque,
      },
    },
  }
}

const jssOptions = {
  meta: `🌄 Theme`,
  classNamePrefix: `theme__`,
  link: true,
}

const sheet = jssLib.createStyleSheet(makeStyles(chosen()), jssOptions).attach()
const classes = sheet.classes

chosen.subscribe(theme => sheet.update(makeStyles(theme)))

export { sheet, classes, chosen, selected }
export default classes
