import RootListPanelProvider from "minute-box-app/RootListPanelProvider"
import { color } from 'styles'
import icons from 'icons'
import faPlusSquare from 'icons/light/plus-square'

import PrecedentListCommandSet from "./PrecedentListCommandSet"
import PrecedentPanelProvider from './PrecedentPanelProvider'
import PrecedentModel from './PrecedentModel'
import { precedentActions } from '../writing-views/writingCommonListColumns'
import { precedentListColumns } from './precedentListColumns'
import { css as ActionMenuCSS } from 'Column/CommonModelColumns'

export default class PrecedentListPanelProvider extends RootListPanelProvider {
  selected: KnockoutObservableArray<PrecedentModel>

  get panelID () { return 'PrecedentList' }
  get panelWindowTitle () { return 'Precedents' }
  get titleSupplement () { return <span class={this.jss.beta}>Beta</span> }
  get historyUrl () { return '#precedent' }
  get peerPanelList () { return window.app.rootProviderList }
  get CommandSetConstructor () { return PrecedentListCommandSet }
  get collectionID () { return 'precedent' }

  // Blocked and copied from EntityListPanelProvider
  get main () {
    const { memoryDB } = this.app
    const isLoading = this.computed(() => !memoryDB.indexIsLoaded('precedent'))
      .extend({ rateLimit: 115 })
    const isEmpty = this.computed(() => !this.criteria.hits.length)
    return this.computed(() => isLoading()
      ? this.loadingHTML
      : isEmpty()
        ? this.noHitsHTML
        : <model-list
          columnJss={this.jss}
          storageID={`panel.${this.panelID}`}
          class={this.jss.modelList}
          style='--model-list-offset-top: 59px'
          sorters={this.listOptions.sorters}
          columns={this.listOptions.columns}
          hits={this.criteria.hits}
          onSelect={e => this.selectPrecedent(e)}
          contextMenuForRow={row => this.actionsContentHTML(row)}
          hoverRow={this.hoverRow} />)
  }

  selectPrecedent (model: PrecedentModel) {
    const app = this.app
    app.panelProvider(new PrecedentPanelProvider({ app, model }))
  }

  get addModelButton () {
    const { jss } = this
    return (
      <div class={jss.rootTopButton} ko-ownClick={e => this.newModelClick(e)}>
        {icons.button(faPlusSquare)}
        <span class={jss.responsiveHiddenWhenNarrow}>Add New Precedent</span>
      </div>
    )
  }

  async newModelClick (evt) {
    return this.commandSet.commands.newPrecedent.action(evt)
  }

  get possibleColumns () {
    return precedentListColumns(this)
  }

  actionsContentHTML (row) {
    return <action-menu-items actions={precedentActions(row)} />
  }

  static get css () {
    return {
      ...super.css,
      ...ActionMenuCSS,

      beta: {
        fontSize: '0.7rem',
        textTransform: 'uppercase',
        fontWeight: 500,
        background: color.fill.light.secondary,
        padding: '3px 6px',
        borderRadius: 3,
        color: color.text.light.secondary,
        marginLeft: '-60px'
      },

      footContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        left: 'calc(0px - var(--left-panel-default-width))',
      },
      footFlexBox: {
        display: 'flex',
        '--right-edge-allowance': '300px',
        width: 'calc(100vw - var(--right-edge-allowance))',
        height: '100%',
        position: 'sticky',
        left: '0px',
        alignItems: 'center',
        justifyContent: 'center',
      },
      footContent: {
        position: 'relative',
        left: 'calc(var(--right-edge-allowance) / 2)',
      },
    }
  }

  get foot () {
    const { jss } = this

    const hits = this.computed(() =>
      this.criteria.nbHits() === undefined ? 'Loading ' : ' ' + this.criteria.nbHits())
    const items = this.computed(() =>
      this.criteria.nbHits() === 1 ? 'Precedent' : 'Precedents')

    return (
      <div class={jss.footContainer}>
        <div class={jss.footFlexBox}>
          <div class={jss.footContent}>
            <div class={jss.foot}>
              {hits}{' '}
              {items}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
