
import { inline } from 'icons'
import closeIcon from 'icons/solid/times-circle'

import WritingRightViewVar from './WritingRightViewVar'

type EntityModel = import('EntityModel').default
type ComponentVariable = import('../ModelVariable').ComponentVariable

export default class WritingRightViewComponent<T extends ComponentVariable> extends WritingRightViewVar<T> {
  entityName () {
    const model = this.variable.model as EntityModel
    if (!model) { return <em>Loading...</em> }
    return (
      <div class={this.jss.entityName}>
        <span>{model.cvModelTitle}</span>
        <span class={this.jss.entityNameInner} ko-ownClick={() => this.variable.modelKey(null)}>{inline(closeIcon)}</span>
      </div>
    )
  }

  entityPicker () {
    const onPick = (em: EntityModel) =>
      this.variable.modelKey(em.vmFirestoreDocPath)
    return ko.ignoreDependencies(() => (
      <entity-picker
        disableShortcuts={true}
        onSelect={onPick}
        suggestProperty={'id'}
        inputClass={this.jss.input}
      />
    ))
  }

  get modelVariableHTML () {
    return this.computed(() => this.variable.modelKey()
      ? this.entityName()
      : this.entityPicker())
  }

  get body () {
    return (
      <>
        {super.body}
        {/* {this.inputSection('Entity', this.modelVariableHTML)} */}
      </>
    )
  }
}
