import { maybeSamePerson, makePersonRecord } from 'person/utils'

import Picker from './picker'

const DEFAULT_AT = {
  my: 'top left',
  at: 'bottom left',
}

export default class PersonRecordPicker extends Picker<string> {
  generator: PersonGenerator
  searchType: 'startsWith' | 'includes'
  suggestProperty: string
  forPerson: PersonRecord
  unionOn: string[]

  constructor (params) {
    super(Object.assign({}, DEFAULT_AT, params))
    const { generator, searchType, suggestProperty, forPerson } = params
    Object.assign(this, {
      generator,
      searchType,
      forPerson,
      suggestProperty: suggestProperty || 'name',
    })
  }

  /**
   * Choose from all people across the system; see
   * `PersonSearchCriteria::makeModelList` for a generic generator.
   */
  choices () : string[] {
    const { memoryDB } = window.app
    const entityList = memoryDB.getListOf('entity')
    const userList = memoryDB.getListOf('user')
    const forPerson = makePersonRecord(this.forPerson, {
      [this.suggestProperty]: []
    })
    const people = [...entityList(), ...userList()]
      .flatMap(e => ([...e.getPersons()]))
      .filter(p => this.searchUnionMatches(forPerson, p))
    return [...new Set([...people].flatMap(p => p[this.suggestProperty]))]
      .filter(f => f)
  }

  /**
   * @return {boolean} `true` when `this.forPerson` has a property
   *    already (e.g. name, email, phone) and the `other` person
   *    has a matching property.
   *
   * This effectively unions a person based on any common properties
   * that are set on the `forPerson`.
   */
  searchUnionMatches (forPerson: PersonRecord, other: PersonRecord): boolean {
    return !forPerson || maybeSamePerson(forPerson, other)
  }

  onSelectEvent (value) {
    if (!value.trim()) { return }
    return super.onSelectEvent(value)
  }

  filterChoice (item, query = '') {
    const canonStr = (item || '').toLowerCase().trim()
    return canonStr[this._searchType](query.toLowerCase())
    // const q = query.toLowerCase()
    // const match = prop => (prop || '')
    //   .toLowerCase()
    //   .trim()[this._searchType](q)
    // return (item[this.suggestProperty] || []).some(match)
  }

  itemHTML (value: PersonRecord) {
    const { jss } = this
    return <div class={jss.itemValue}>{value}</div>
  }

  /**
   * When picking the name, hard-link-to/unlink-from the ID of a "local"
   * person who has the same name.
   *
   * The generator passed in for the given DataModel e.g. EntiyModel.
   */
  async linkUidToGeneratorValues () {
    if (this.suggestProperty !== 'name' || !this.generator) { return }
    const filter = p => p.id && p.name[0] === this.query()
    const person = this.generator.getPersons(filter).next().value
    const differs = (person && this.forPerson.id !== person.id) ||
      (!person && this.forPerson.id)

    if (differs) {
      this.forPerson.id = person ? person.id : null
      this.query.notifySubscribers(this.query()) // trigger save/update
    }
  }

  onFocusOut () {
    super.onFocusOut()
    this.linkUidToGeneratorValues()
  }

  get _searchType () { return this.searchType || 'includes' }
  get noMatchesMessage () { return 'Nothing matches. [Enter] to add.' }
  get searchMessage () { return 'Type to search or add' }

  static get css () {
    return {
      ...super.css,
      anchor: {
        ...super.css.anchor,
        width: '100%',
      },
    }
  }
}

PersonRecordPicker.register()
