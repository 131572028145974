import { format } from 'date-fns/esm'

import ViewComponent from 'ViewComponent'
import { hidePopovers } from 'pop-over'
import { buttons, color } from 'styles'


export default class DatePickerPopover extends ViewComponent {
  value: KnockoutObservable<Date>
  relatedDateGenerator: RelatedDatesSource
  showing: KnockoutObservable<Boolean>
  drawerSide: boolean
  my: string
  at: string

  constructor ({ value, relatedDateGenerator, showing, classes, drawerSide, my, at }) {
    super()
    Object.assign(this, {
      value,
      relatedDateGenerator,
      drawerSide,
      my: my || 'bottom left',
      at: at || 'top left',
      showing: showing || ko.observable(false),
    })
    Object.assign(this.jss, classes)
  }

  onDatePickerSelect (date: Date) {
    this.value(date)
    this.showing(false)
  }

  static get css () {
    return {
      popover: {
        // display: 'inline-block',
        outline: 'none'
      },
      inlineDateValue: {
        width: 'calc(12ch + 1em)',
        padding: '0.5em 0',
        textAlign: 'center',
        fontSize: '0.9rem',
        color: 'var(--inline-date-value-color, inherit)',
        '&[blank]': {
          color: 'var(--inline-date-value-muted-color, #aaa)',
        }
      },
      popoverPopUp: {
        padding: '15px',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        border: '1px solid grey',
        boxShadow: '2px 2px 21px 7px rgba(0,0,0,0.2)',
        borderRadius: '3px',
        '--related-list-top': '-12px',
        '--related-list-horizontal': '14px',
        '--related-list-items-height': '280px',
      },
      buttons: {
        display: 'flex',
        padding: '10px 0px',
      },
      ok: {
        ...buttons.modalOk,
      },
      cancel: {
        ...buttons.modalCancel,
        marginLeft: 'auto',
      }
    }
  }

  anchorClick () {
    hidePopovers()
    this.showing(true)
  }

  get template () {
    const { jss, value } = this
    const focus = ko.observable<Date>(value())
    const isBlank = this.computed(() => !Boolean(value()) || undefined)
    const displayText = this.computed<string>(() =>
      value() ? format(value(), 'yyyy-MM-dd') : 'YYYY-MM-DD'
    )

    const finish = () => {
      this.showing(false)
    }

    const ok = () => {
      this.value(focus())
      finish()
    }

    const clear = () => {
      this.value(null)
      finish()
    }
    const cancel = () => finish()
    const toggle = () => this.showing.modify(t => !t)
    const shortcuts = {
      Enter: toggle,
      ' ': toggle, // Space
      /**
       * We defer the Escape so that this dialog remains "open" until after
       * they process (so, for example, modal dialogs don't close).
       */
      Escape: () => setTimeout(() => this.showing(false), 5)
    }

    return (
      <div class={jss.popover}
        ko-ownClick={() => toggle()}
        ko-keydown={shortcuts}
        tabindex='0'>
        <pop-over my={this.my} at={this.at} showing={this.showing}>

          <template slot='anchor'>
            <div class={jss.inlineDateValue}
              blank={isBlank}
              ko-ownClick={() => this.anchorClick()}>
              {displayText}
            </div>
          </template>

          <template slot='content'>
            <div class={jss.popoverPopUp} ko-click={evt => { evt.stopPropagation() }}>
              <date-picker
                drawerSide={this.drawerSide}
                relatedDates={this.relatedDateGenerator}
                onSelect={(v:Date) => this.onDatePickerSelect(v)}
                onClear={clear}
                inputFocus={this.showing}
                focus={focus} />

              <div class={jss.buttons}>
                <div class={jss.cancel} ko-ownClick={cancel}>
                  Cancel
                </div>
                <div class={jss.ok} ko-ownClick={ok}>
                  Ok
                </div>
              </div>
            </div>
          </template>

        </pop-over>
      </div>
    )
  }
}

DatePickerPopover.register()
