import { whiteList } from 'utils/sanitize'
import { memoize, computed } from 'utils/decorator'

import { factoryVariableView } from '../variables/views'
import { Variable } from '../variables'
import { UX_STATE } from '../variables/slots/interfaces'

import './variable-indicator'

type WritingDocumentEditor = import('writing/writing-views/writing-document-editor').default

/**
 * This ensures that the style is sanitized if this marker isn't present
 * users can't XSS themselves via a `style` tag.
 */
const SAFE_STYLE_SYM = Symbol('Safe Style Marker')
const SAFE_PARAMS = 'variable: variable, editor: editor'

whiteList('variable-indicator', { params: attr => attr.value === SAFE_PARAMS })
whiteList('wvar-span', { class: true, 'do-not-print': true })
whiteList('wvar-slot', { class: true, 'do-not-print': true })
whiteList('span', {
  var: true,
  contenteditable: true,
  'data-bind': attr => attr.value === 'wvar',
  style: attr => SAFE_STYLE_SYM in attr.ownerElement,
})

export default class WritingVariable extends ko.BindingHandler {
  $element: HTMLElement
  $data: { variable: Variable, editor: WritingDocumentEditor }
  slot: HTMLSpanElement

  constructor (params) {
    super(params)
    const $e = this.$element
    const { variable, editor } = this.$data

    this.subscribe(this.selected, isSelected =>
      variable.changeUxState(UX_STATE.selected, isSelected))

    $e.setAttribute('style', '')
    $e[SAFE_STYLE_SYM] = true
    $e.setAttribute('var', JSON.stringify(variable.serialize()))
    variable.onBindingToDOM($e)
    $e.innerHTML = `<variable-indicator params="variable: variable, onClick: onClick" />`
    const onClick = evt => {
      if (evt.target.closest('wvar-slot')) {
        if (editor.variableFloatingMenu()?.variable === variable) {
          editor.variableFloatingMenu(null)
        } else {
          editor.variableFloatingMenu({
            variable,
            rect: $e.getBoundingClientRect(),
          })
        }
      } else {
        editor.rightView(this.rightView)
      }
    }
    ko.applyBindingsToDescendants({ variable, onClick }, $e)

    this.setStyleVariables(variable)

    /**
     * Create an artificial dependency that triggers when the variable changes,
     * triggering e.g. a save.
     */
    this.computed(() => variable.serialize())
      .extend({ deferred: true })
      .subscribe(v => {
        if (variable.isCohate) { ko.unwrap(variable.value()) }
        const ce = this.$element.closest('[contenteditable=true]')
        this.$element.setAttribute('var', JSON.stringify(v))
        if (ce) { ce.dispatchEvent(new CustomEvent('needssave')) }
      })
  }

  @computed()
  selected () {
    const { editor } = this.$data
    return editor && editor.rightView() === this.rightView
  }

  @memoize()
  get rightView () { return factoryVariableView(this.$data.variable) }

  setStyleVariables (v: Variable) {
    const { style } = this.$element
    style.setProperty('--slot-bg-color', v.color.bg)
    style.setProperty('--slot-border-color', v.color.border)
  }

  get controlsDescendants () { return true }
}

ko.bindingHandlers.wvar = WritingVariable
