
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class TradeName extends UnitView {
  get elvValue () { return this.name }

  get fields () {
    return {
      name: 'string',
      type: 'string',
      identifier: 'string',
      expiry: 'pureDate',
      jurisdiction: 'string',
      registered: 'pureDate',
      agent: 'person',
    }
  }

  * getRoles () : IterableIterator<PersonRoleRecord> {
    yield { title: 'Trade Name Agent' }
  }
}

export default class TradeNamesComponent extends ArrayComponent {
  get ItemClass () { return TradeName }
}

TradeNamesComponent.register()
