import t from 't'

import { AssetCredit } from '../AssetAction'
import { CertificateIssue } from '../CertificateAction'

import { CapitalUnilateralTransfer } from './CapitalUnilateralTransfer'
import { CODES } from './Transaction'


export class TransactionIssue extends CapitalUnilateralTransfer {
  certificateNumber: KnockoutObservable<number>

  get plainObservables () { return [...super.plainObservables, 'certificateNumber'] }
  get code () { return CODES.CapitalIssue }
  get ActionClass () { return AssetCredit }
  shortDescription (cs: CapitalState) {
    const { amount, person } = this
    return t.CAPITAL_SHARE_ISSUE(amount, this.assetNameAt(cs), person)
  }

  * yieldActions () {
    yield * super.yieldActions()
    yield new CertificateIssue(
      this.assetClassID,
      this.certificateNumber(),
      this.person(),
    )
  }
}
