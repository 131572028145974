
import FirstLoginModal from './FirstLoginModal'

export default class FirstUserAccountLoginModal extends FirstLoginModal {
  constructor () {
    super({ modalTitle: 'Welcome to MinuteBox' })
  }

  static get css () {
    return {
      ...super.css,
    }
  }

  get contentHTML () {

    const { jss } = this
    return (
      <div class={jss.terms}>
        Unused. This modal is a placeholder for new (non-sharee) users.
      </div>
    )
  }
}

FirstUserAccountLoginModal.register()
