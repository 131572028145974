
import ViewComponent from 'ViewComponent'

export default class UploadProgressToEntity extends ViewComponent {
  /**
   * @param {string} filename
   * @param {PdfUpload} upload
   */
  constructor ({ upload, entity }) {
    super()
    Object.assign(this, { upload, entity })
  }

  static get css () {
    return {
      ...super.css,
      uploadProgress: {
        display: 'flex',
      },
      status: { color: 'black', marginRight: '6px' },
      pageCountString: {
        color: 'black',
        marginLeft: 'auto',
        marginRight: 1,
        fontSize: '85%',
        padding: '0.2em 0.5em'
      },
      pageCount: {
        color: 'black',
        backgroundColor: 'rgba(27,31,35,0.05)',
        borderRadius: 11,
        padding: '0.2em 0.5em',
        fontSize: '85%',
        fontWeight: 600
      },
      filename: {
        color: 'black',
        fontWeight: '600',
        marginRight: '5px',
        fontSize: '85%',
        borderRadius: 3,
        backgroundColor: 'rgba(27,31,35,0.05)',
        padding: '.2em .4em'
      },
      entityName: {
        color: 'black',
        fontWeight: '600',
        marginRight: '5px',
        fontSize: '85%',
        borderRadius: 3,
        backgroundColor: 'rgba(27,31,35,0.05)',
        padding: '.2em .4em'
      }
    }
  }

  get template () {
    const { jss } = this
    const { entity } = this
    const { state, pdf } = this.upload
    const filename = pdf.name || ''
    const entityName = this.computed(() => ko.unwrap(entity)
      ? ko.unwrap(entity).cvModelTitle
      : '')

    return (
      <div class={jss.uploadProgress}>
        <div class={jss.status}>Uploading</div>
        <div class={jss.filename}>{filename}</div>
        <div class={jss.status}>to the entity:</div>
        <div class={jss.entityName}>{entityName}</div>
        <div class={jss.pageCountString}>{'Upload status: '}</div>
        <div class={jss.pageCount}>{state}</div>
      </div>
    )
  }
}

UploadProgressToEntity.register()
