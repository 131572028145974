
import ViewComponent from 'ViewComponent'
import { buttons, input, typography, color } from 'styles'
import { inline } from 'icons'
import chevronDown from 'icons/solid/chevron-down'
import chevronRight from 'icons/solid/chevron-right'

import './sharing-editor-inputs'

class Permit {
  constructor (target, name, value) {
    Object.assign(this, {
      target,
      name: ko.observable(name),
      value: ko.observable(value),
    })
  }
}

/**
 * Edit the `sharing` property of a model, which is a list of
 * `Sharing` instances.
 *
 * Usage:
 *
 *      <sharing-editor model={CrudModel} />
 */
export class SharingEditor extends ViewComponent {
  constructor ({ model, sections }, ...args) {
    super({}, ...args)

    const book = model.componentFor('book')
    const pages = book && !sections && book.pages()
      .map((pg, i) => ([pg, i]))
      .filter(([pg, i]) => pg.selected())
      .map(([pg, i]) => i+1)
      .join(", ")

    const permits = {
      // direct: new Permit('*', 'Everything'),  // direct
      everything: new Permit('everything', 'Everything'), // copy
      book: new Permit('book', `Book pages ${pages}`, { pages } ),
      eis: new Permit('eis', 'Entity Information Summary'),
    }

    Object.assign(this, {
      model,
      permits,
      sections,
      showAdvanced: ko.observable(Boolean(sections || pages)),
      everyPage: ko.observable(false),
      expiryDate: ko.observable(null),
      emailInput: ko.observable(''),
    })
  }

  cancelClick () {
    window.app.modal(undefined)
  }

  static get css () {
    return {
      ...super.css,
      ...this.buttonCSS,

      editor: {
        minWidth: '600px',
      },

      modelTitle: {
        fontWeight: 'bold'
      },

      inputBox: {
        ...input.all,
        height: '40px',
        borderRadius: '2px',
        fontSize: '0.9rem',
        border: `1px solid ${color.separator.light.opaque}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.opaque}`,
        },
        padding: '5px 0.5em',
        '&::placeholder': {
          color: color.text.light.tertiary,
          'body[dark] &': { // project batman
            color: color.text.dark.tertiary,
          },
        }
      },

      emailInput: {
        extend: 'inputBox',
        width: '100%',
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
      },

      pageListInput: {
        extend: 'inputBox',
        width: '200px',
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
      },

      grid: {
        display: 'grid',
        grid: {
          templateColumns: '100px 1fr',
          rowGap: '20px',
          columnGap: '10px',
        },
      },

      shareBasicSection: {
        extend: 'grid',
        position: 'relative',
        paddingBottom: '10px',
        margin: '20px 0',
        borderBottom: `1px solid ${color.separator.light.opaque}`,
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.opaque}`,
        },
      },

      optionLabel: {
        fontWeight: '600',
        fontFamily: typography.titleFamily,
        gridColumn: '1/2',
        marginTop: '8px',
        '&[alignself]': {
          alignSelf: 'center',
          marginTop: '0px',
        },
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      shareOption: {
        display: 'flex',
        '&[flexcolumn]': {
          flexDirection: 'column',
        },
      },
      shareOptionBasic: {
        extend: 'shareOption',
      },
      checkboxList: {
        background: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
        borderRadius: '7px',
        padding: '10px',
        '$shareOptionBasic &': {
          background: 'inherit',
        }
      },
      expireOption: {
        extend: 'checkboxList',
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 0 10px',
        '&:not([checked])': {
          '--inline-date-value-color': '#aaa',
        },
      },

      checkbox: {
        ...buttons.clickable,
        display: 'block',
        margin: '10px 0',
        [`$checkboxList > &:first-child,
          $checkboxList > :first-child &,
          $checkboxList > &:last-child,
          $checkboxList > :last-child &`]: {
          margin: '0',
        }
      },
      checkboxInput: {
        marginRight: '10px',
      },

      advancedButton: {
        ...buttons.clean,
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        color: color.onPrimary,
        fontWeight: typography.weight.heavy,
      },
      chevronIcon: {
        marginLeft: '5px',
        '--icon-color': color.onPrimary,
      },

      advancedSection: {
        display: 'none',
        '&[show]': {
          display: 'block',
        }
      },

      shareEditorBook: {
        extend: 'grid',
        '&[disabled] $checkbox': {
          opacity: '0.5',
        },
      }
    }
  }

  static get buttonCSS () {
    return {
      _button: {},

      buttons: {
        gridColumn: '1/-1',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10px'
      },

      shareButton: {
        ...buttons.modalOk
      },

      cancelButton: {
        ...buttons.modalCancel
      }
    }
  }

  get shareOptionsHTML () {
    const { jss, model, permits, everyPage, sections } = this

    return (
      <div class={jss.shareOptions}>
        <div class={jss.shareBasicSection}>

          <div class={jss.optionLabel} alignself><span>Share with:</span></div>

          <div class={jss.shareOption}>
            <input type='text' class={jss.emailInput}
              placeholder='Enter email addresses'
              ko-textInput={this.emailInput} />
          </div>

          <div class={jss.optionLabel}><span>Share:</span></div>
          <div class={jss.shareOptionBasic}>
            <div class={jss.checkboxList}>
              <share-editor-everything permit={permits.everything} classes={jss}/>
              <share-editor-eis permit={permits.eis} classes={jss}/>
              <share-editor-every-page everyPage={everyPage} classes={jss}/>
            </div>
          </div>

          <div class={jss.optionLabel} alignself><span>Expires:</span></div>
          <div class={jss.shareOption}>
            <share-editor-expiry value={this.expiryDate} dateGenerator={model} classes={jss}/>
          </div>

          <div class={jss.advancedButton} ko-ownClick={() => this.showAdvanced.modify(v => !v)}>
            Advanced
            {this.computed(() => (
              this.showAdvanced()
                ? <span class={jss.chevronIcon}>{inline(chevronDown)}</span>
                : <span class={jss.chevronIcon}>{inline(chevronRight)}</span>
            ))}
          </div>
        </div>

        <div class={jss.advancedSection} show={this.computed(() => this.showAdvanced() || undefined)}>
          <share-editor-book
            permit={permits.book}
            model={model}
            everyPage={everyPage}
            sections={sections}
            classes={jss}/>
        </div>
      </div>
    )
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.editor}>

        <div class={jss.shareWithOthers}>
          Share <span class={jss.modelTitle}>{this.model.cvModelTitle}</span> minute book with others.
        </div>

        {this.shareOptionsHTML}

        <div class={jss.buttons}>
          <button class={jss.cancelButton}
            ko-click={() => this.cancelClick()}>
            Cancel
          </button>
          <async-button faceClass={jss.shareButton}
            action={() => this.addClick()}
            enable={this.emailInput}>
            <template slot='face'>
              Share minute book
            </template>
          </async-button>
        </div>
      </div>
    )
  }

  /**
   * @yield {<object>}
   */
  * genPermits () {
    const { everything, eis, book } = this.permits
    const isEverythingPermitted = everything.value()
    const isEisPermitted = eis.value()
    const isBookPermitted = book.name() && book.value()

    if (isEisPermitted || isBookPermitted || isEverythingPermitted) {
      const nameParts = []
      const target = 'content'
      const params = {}

      if (isEverythingPermitted) {
        nameParts.push('Everything')
        Object.assign(params, {
          eis: true,
          book: {
            sections: [],
            pages: '',
            bookmarks: true,
            everyPage: true,
          },
        })
      } else if (isEisPermitted) {
        nameParts.push(eis.name())
        Object.assign(params, {
          eis: true,
        })
      }

      if (isBookPermitted && !params.book) {
        nameParts.push(book.name())
        params.book = book.value()
      }

      const name = nameParts.join(', ')
      yield { name, target, params }
    }
  }

  async addClick () {
    const { app } = global
    const { sharing } = this.model
    const accountID = this.model.accountID()
    const emails = this.emailInput()
      .split(',')
      .map(e => e.trim().toLowerCase())

    const permits = [...this.genPermits()]
    console.info(`
      Granting permits for ${this.model.cvModelTitle}:
      To: ${emails.join(', ')}
      Permits: ${JSON.stringify(permits, null, 2)}
    `)

    for (const email of emails) {
      await app.defaultAuthManager
        .firebaseFn('addAccountToUserByEmail', { email, accountID })
    }

    for (const email of emails) {
      sharing.addOrUpdate(email, permits, this.expiryDate())
    }

    this.emailInput('')
    await this.model.vmSave()

    await this.sendShareNotifications(emails)

    app.modal(undefined)
  }

  async sendShareNotifications (emails) {
    const { model } = this
    const mailFutures = []
    console.info(`Sending share notifications to ${emails.join(', ')}`)
    const user = await model.authManager.getCurrentUserModel(model.accountID())
    const entityHash = `#entity--${model.vmFirestoreDocPath}`
    for (const email of emails) {
      const entityType = ko.unwrap(this.model.answerFor('entity_type')) || 'entity'
      const shareParams = {
        to: email,
        entityHash,
        entityType,
        origin: location.origin,
        invitorName: user ? user.name : ''
      }
      mailFutures.push(
        global.app.callFirebaseFunction('sendShareNotification', shareParams, model.authManager)
      )
    }
    await Promise.all(mailFutures)
  }
}

SharingEditor.register()
