import { input, color } from 'styles'

import WritingRightViewVar from './WritingRightViewVar'

type DateVariable = import('../DateVariable').default

export default class WrvvText<T extends DateVariable> extends WritingRightViewVar<T> {
  static get css () {
    return {
      ...super.css,
      text: {
        ...input.text,
      },
      input: {
        ...super.css.input,
        '::placeholder': {
          color: color.text.light.secondary,
          'body[dark] &': { // project batman
            color: color.text.dark.secondary,
          },
        }
      }
    }
  }

  get body () {
    const { jss } = this
    this.monitorAndUpdatePeers(this.variable.text)

    return (
      <>
        {super.body}
        {this.inputSection('Enter Text', <input class={jss.input} ko-textInput={this.variable.text} placeholder='Click to add text' />)}
        {this.inputSection('Slot', this.slotPickerHTML)}
      </>
    )
  }
}

WrvvText.register()
