import EisSingleComponentField from '../EisSingleComponentField'

/**
 * EisPrimitiveComponent is for components where `.get` returns a
 * single observable value e.g. PrimitiveType
 */
export default class EisPrimitiveComponent extends EisSingleComponentField {
  constructor (params) {
    super(params)
    /**
     * @type {(...args:any[]) => any}
     */
    this.inputValue = ko.observable(this.componentValue())
    this.componentValue.subscribe(v => {
      if (!this.editing()) { this.inputValue(v) }
    })
  }

  onStartEdit () {
    this.inputValue(this.componentValue())
  }

  get componentValue () {
    return this.component.get()
  }

  get displayValue () {
    return this.computed(() =>
      this.componentValue() || this.constructor.displayPlaceholder[this.componentName])
  }

  get hasChanges () { return this.inputValue() != this.componentValue() }
  onSave () { this.componentValue(this.inputValue()) }
  onDiscard () { this.inputValue(this.componentValue()) }
}
