
import EisField from '../EisField'
import { whenHeightStabilizes } from 'utils/dom'

export default class EisMultiComponentField extends EisField {
  constructor (params) {
    super({ skipNavRegistration:true, ...params })
    const { eis } = params
    if (eis.scrollToComponent) {
      const names = this.componentNames
      if (names.includes(eis.scrollToComponent)) {
        whenHeightStabilizes(document.body).then(() => {
          window.scrollTo(0,document.body.scrollHeight)
          this.inputfocus[eis.scrollToComponent](true)
        })
      }
    }
  }

  registerNavigation () {
    Object.values(this.inputfocus).forEach(f => {
      const navControl = {
        focused: f,
        editing: this.editing,
        triggerSave: () => this.triggerSave(),
        triggerDiscard: () => this.triggerDiscard(),
      }
      Object.defineProperty(navControl, 'changedSinceEdit', { get: () => this.changedSinceEdit } )
      this.navigation.append(navControl)
    })
  }

  get isBlank () {
    if (this._isBlankOb) { return this._isBlankOb }
    return this.isBlankOb = ko.pureComputed(() =>
      this.componentNames
        .map(c => this.entity.componentFor(c))
        .every(c => c.is_blank()))
  }

  /**
   * @return {Array.<string>}
   */
  get componentNames () { return [] }

  makeComponentMap (accessor) {
    return Object.assign({},
      ...this.componentNames.map(
        c => ({ [c]: accessor(c) }))
    )
  }

  /**
   * @return {object} { componentName: component }
   */
  get componentsMap () {
    if (this._componentMap) { return this._componentMap }
    return this._componentMap = this.makeComponentMap(c => this.entity.componentFor(c).get())
  }

  get displayValue () {
    if (this._displayValue) { return this._displayValue }
    return this._displayValue = this.makeComponentMap(c => (
      this.computed(() => this.componentsMap[c]() || this.constructor.displayPlaceholder[c])
    ))
  }

  get inputs () {
    if (this._inputs) { return this._inputs }
    this.componentNames.forEach(c => {
      this.componentsMap[c].subscribe(v => {
        if (!this.editing()) { this.inputs[c](v) }
      })
    })
    return this._inputs = this.makeComponentMap(c => ko.observable(this.componentsMap[c]()))
  }

  get inputfocus () {
    if (this._focus) { return this._focus }
    this._focus = this.makeComponentMap(() => ko.observable(false))
    const focusValues = Object.values(this._focus)
    this.computed(() => this.focused(focusValues.some(fv => fv())))
    this.focused.subscribe(f => !f
      || focusValues.some(fv => fv())
      || focusValues[0](true))
    return this._focus
  }

  onStartEdit () {
    this.componentNames.forEach(c => this.inputs[c](this.componentsMap[c]()))
  }

  async onTabDown (evt) {
    if (evt.shiftKey) {
      if (this.inputfocus[this.componentNames[0]]()) { await this.triggerSave() }
      this.navigation.previousFocus()
    } else {
      if (this.inputfocus[this.componentNames.slice(-1)]()) { await this.triggerSave() }
      this.navigation.nextFocus()
    }
  }

  onSave () {
    this.componentNames.forEach(c => this.componentsMap[c](this.inputs[c]()))
  }

  onDiscard () {
    this.componentNames.forEach(c => this.inputs[c](this.componentsMap[c]()))
  }

  get hasChanges () {
    return this.componentNames.some(c => this.inputs[c]() != this.componentsMap[c]())
  }
}
