

import { CapitalState } from '../CapitalState'
import EisSingleComponentField from 'entity-information-summary/EisSingleComponentField'

type CapitalComponent = import('capital/CapitalComponent').default

export default abstract class EisCapital extends EisSingleComponentField<CapitalComponent> {
  get componentName () { return 'capital' }

  datetime: KnockoutObservable<Date>
  state: KnockoutObservable<CapitalState>

  constructor (params) {
    super(params)
    Object.assign(this, {
      datetime: ko.observable(this.initialDateTime),
    })

    this.state = this.computed(() => new CapitalState({
      datetime: this.datetime(),
      transactions: this.component.transactions()
    }))
  }

  /**
   * The initial date time for the CapitalState.
   */
  get initialDateTime () { return new Date() }

  static get css () {
    return {
      ...super.css,
      grid: {},
    }
  }

  deleteButtonHTML (item) {
    return (<span>DELETE</span>)
  }

  editButtonHTML (item) {
    return (<span>EDIT</span>)
  }

  onClick () {}

  abstract get displayHTML () : any
  abstract get editHTML () : any
}
