import { ZERO_WIDTH_SPACE } from 'utils/string'

import { ArrayComponentPerson, CapitalHolderVariable } from '../variables'

import { Block } from './Block'
import Paragraph from './Paragraph'

type Variable = import('../variables').Variable
type UnitView = import('DataModel').UnitView
type SlotManager = import('../variables/slots').SlotManager

const signingLine = `${ZERO_WIDTH_SPACE}_____________________________`

export abstract class SignaturesBlock<T extends Variable> extends Block {
  modelKey: KnockoutObservable<string>
  component: KnockoutObservable<string>
  slotIndex: KnockoutObservable<number|string>

  makeNextBlock (body) { return new Paragraph({ body }) }
  markdownLeadingChars () { return null }
  get isFocusable () { return false }

  get plainObservables () {
    return [...super.plainObservables, 'component', 'modelKey', 'slotIndex']
  }

  setVariables (sm: SlotManager) {
    for (const o of this.plainObservables) {
      if (!sm.hasVariable(o)) { continue }
      this[o](sm.getVariableValue(o))
    }
  }

  get isBlank () { return this.component() && this.modelKey() && true }

  signingLine (name) { return [signingLine, '\n\n', name].join('') }

  abstract ephemeralClassConstructor (sm: SlotManager): T
  abstract signers (v: T): string[]
  async asMarkdown (blocks: Block[], sm: SlotManager) {
    const acp = this.ephemeralClassConstructor(sm)
      .assignVariables(ko.toJS(this))
    acp.setSlotByIndex(acp.slotIndex())

    if (!acp.getValueOrImpliedValue('modelKey')) {
      return ['', signingLine].join('\n\n')
    }
    await acp.modelObservable.yet(undefined)

    if (!acp.isCohate) {
      return ['', signingLine].join('\n\n')
    }

    return ['', ...this.signers(acp).map(this.signingLine)].join('\n\n\n')
  }
}

/**
 * Array Component Signatures
 */
export class ArrayComponentSignatures extends SignaturesBlock<ArrayComponentPerson> {
  component: KnockoutObservable<string>
  property: KnockoutObservable<string>
  index: KnockoutObservable<number>

  get plainObservables () {
    return [...super.plainObservables, 'property', 'index']
  }

  get code () { return 's' }
  get isBlank () { return this.property() && this.index()  && super.isBlank }

  ephemeralClassConstructor (sm: SlotManager) {
    return new ArrayComponentPerson(sm)
      .assignVariables({ personProperty: 'name' })
  }

  signers (acp: ArrayComponentPerson) {
    return acp.iterationChosen ? [...acp] : [acp.value()]
  }
}

/**
 * Signatures from individuals referenced in the Capital component.
 */
export class CapitalSignatures extends SignaturesBlock<CapitalHolderVariable> {
  idOrName: KnockoutObservable<string>
  assetClassID: KnockoutObservable<string>

  get code () { return 'sc' }
  ephemeralClassConstructor (sm: SlotManager) {
    return new CapitalHolderVariable(sm)
      .assignVariables({ holderProperty: 'name' })
  }

  get plainObservables () {
    return [...super.plainObservables, 'idOrName', 'assetClassID']
  }

  get isBlank () {
    return (this.idOrName() || this.assetClassID()) && super.isBlank
  }

  signers (v: CapitalHolderVariable) {
    return v.iterationChosen ? [...v] : [v.holder.idOrName]
  }
}
