
import { isEqual } from 'lodash-es'

import CrudModel from 'DataModel/CrudModel'

import {
  PlainMediator, ClassArrayMediator, ArrayMediator
} from 'DataModel/mediators'

import Condition from './Condition'
import DataModel from 'DataModel'

export default class FilterModel extends CrudModel {
  get QUESTIONS () { return [] }
  test (model) {
    return (
      ! (model instanceof DataModel) || (
        model.authManager === this.authManager &&
        model.accountID() === this.accountID() &&
        model.vmResourceName === this.collectionID()) &&
      Condition.test(model, this.conditions())
    )
  }

  /**
   * @return {bool} true when the given conditions are equivalent to this.
   * TODO: set equivalence (i.e. conditions out-of-order).
   */
  isEquivalentTo (conditions) {
    return isEqual(
      conditions.map(c => c.asComparableJS),
      this.conditions().map(c => c.asComparableJS)
    )
  }

  vmCreateMediatorInstances () {
    return [
      ...super.vmCreateMediatorInstances(),
      new PlainMediator('title', this),
      new PlainMediator('panelID', this),
      new PlainMediator('collectionID', this),
      new PlainMediator('immutable', this),
      new PlainMediator('order', this),
      new ClassArrayMediator('conditions', this, Condition),
      new ArrayMediator('columns', this),
      new PlainMediator('isNew', this),
      /**
       * The `perms` is used only by filters that apply to `user` models,
       * which sets permissions for the group of people that the filter
       * matches.
       */
      new ArrayMediator('perms', this),
      new PlainMediator('isTeam', this)
    ]
  }
}

FilterModel.register()
