import Variable from './Variable'

export default class TextVariable extends Variable {
  text: KnockoutObservable<string> = ko.observable('')
  get code () { return 'text' }
  get isCohate () { return this.text().trim() !== '' }
  get incohateText () { return 'Click to add text' }
  get hasSlots () { return true }
  get slotGroupTitle () { return 'Text' }
  get slotTitleText () { return this.value() }
  value () { return ko.unwrap(this.text) }
  propagate (to: TextVariable) { to.text(this.text()) }
}
