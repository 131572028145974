
import {
  format, startOfDay, parseISO, isSameDay, isSameYear, isSameMonth,
  differenceInCalendarYears, differenceInCalendarDays,
} from 'date-fns/esm'

/**
 * @param {int} M month
 * @param {int} d day of month
 * @return {int|null} days from now
 */
export function daysFromNow (M: number, d: number, now?: Date) : number {
  if (!Number.isInteger(M) || !d) { return null }
  const today = startOfDay(now || new Date())
  const future = new Date(today.getFullYear(), M, d)
  const diff = differenceInCalendarDays(future, today)
  if (diff >= 0) { return diff }
  future.setFullYear(today.getFullYear() + 1)
  return differenceInCalendarDays(future, today)
}

/**
 * Return the X years, Z days
 */
export function yearsDaysDiffString (origin: Date, now = new Date()) {
  if (!origin) { return null }
  const days = differenceInCalendarDays(origin, now)
  const years = differenceInCalendarYears(origin, now)

  if (days === 0) { return 'Today' }
  const dayStr = days % 365 === 0 ? `` : `${Math.abs(days) % 365} days`
  const yearStr = years === 0 ? `` : `${Math.abs(years)} years`
  const ago = days < 0 ? 'ago' : ''

  return [yearStr, dayStr, ago].join(' ')

  // const date = moment.utc(origin).clone()
  // const years = now.diff(date, 'years')
  // const days = now.add(-1 * years, 'years').diff(date, 'days')
  // const dayStr = days === 1 ? `1 day` : `${days} days`
  // const yearStr = years === 0 ? '' : years === 1 ? '1 year' : `${years} years`
  // return [yearStr, dayStr].filter(s => s).join(', ')
}


/**
 * Return the ISO8601 string.
 */
export function safeFormat (date: Date|null, fstr: string, default_: string = '') {
  return date ? format(date, fstr) : default_
}

export function formatForUser (date: Date, default_ = '') {
  return safeFormat(date, 'yyyy-MM-dd', default_)
}

export function formatLongForUser (date, default_ = '') {
  return safeFormat(date, 'yyyy-MM-dd \'at\' h:mm:ss a', default_)
}

export function toISO8601 (date, default_ = '') : iso8601 {
  return safeFormat(date, 'yyyy-MM-dd', default_)
}

type RANGE = 'day' | 'month' | 'year' | 'forever'
export function inSameRange (a, b, range: RANGE) {
  return {
    day: isSameDay,
    month: isSameMonth,
    year: isSameYear,
    forever: () => true
  }[range](a, b)
}

type DATE_OBJECT = { y?: number, M?: number, d?: number }
export function fromObject(o: DATE_OBJECT, ref = new Date()) {
  return new Date(
    o.y || ref.getFullYear(),
    Number.isInteger(o.M) ? o.M : ref.getMonth(),
    o.d || ref.getDate()
  )
}

export function fromISO8601 (s: string | null) : Date | null {
  return s ? parseISO(s) : null
}

/**
 * Copy the year, month, and day from `from` to `to`
 */
export function toPureDate (from: Date) {
  return startOfDay(from)
}

/**
 * Convert the func
 */
export function toYMD (date: Date) {
  return {
    y: date.getFullYear(),
    M: date.getMonth(),
    d: date.getDate(),
  }
}

export const LEAP_YEAR = 2016
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December' ]


export function endOfTime () { return new Date(9999, 1) }
export function startOfTime () { return new Date(0) }
