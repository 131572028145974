
import CommonModelColumns from 'Column/CommonModelColumns'

import RootListPanelProvider from './RootListPanelProvider'

import 'users-filters-list'

import { PermissionChange } from 'Permissions'
import CommandSet from 'Command/CommandSet'

/**
 * AdminPanelProvider shows the panels needed to perform administrative functions.
 */
export default abstract class AdminPanelProvider extends RootListPanelProvider {
  get CommandSetConstructor () { return CommandSet }
  get peerPanelList () { return this.app.settingsProviderList }

  static get css () {
    return {
      ...super.css,
      ...this.userCss,
      ...CommonModelColumns.css,

      backButton: {
        whiteSpace: 'nowrap'
      }
    }
  }

  get menuSubItems () {
    const entityFilters = this.app.rootProviders.entityList.modelFilters
    const { memoryDB, defaultAuthManager } = this.app
    const permissionChange = new PermissionChange(memoryDB, defaultAuthManager)
    return (
      <users-filters-list
        colList={this.filtersModelList}
        currentConditions={this.listOptions.conditions}
        entityFilters={entityFilters}
        teamFilters={this.app.defaultAuthManager.teamFilters.list}
        everythingFilter={this.everythingFilter()}
        onSelect={filter => this.onFilterSelect(filter)}
        onDelete={filter => this.onFilterDelete(filter)}
        permissionChange={permissionChange} />
    )
  }

  get topFixed () {
    const { jss } = this
    return [
      <back-button class={jss.backButton} />,
      ...super.topFixed
    ]
  }

  actionsContentHTML (row) { return '' }

  get main () {
    return <model-list
      style='--model-list-offset-top: 59px'
      storageID={`panel.${this.panelID}`}
      sorters={this.listOptions.sorters}
      columns={this.listOptions.columns}
      hits={this.criteria.hits}
      onSelect={model => this.onSelect(model)}
      contextMenuForRow={row => this.actionsContentHTML(row)}
      hoverRow={this.hoverRow} />
  }

  async onSelect (model) {
    const { app } = this
    // Avoid circular import
    const PersonPanelProvider = (await import('person/PersonPanelProvider')).default
    this.app.panelProvider(new PersonPanelProvider({app, model}))
  }
}
