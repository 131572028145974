import { escapeRegExp } from 'lodash-es'
import { PRNG } from './random'

export const ZERO_WIDTH_SPACE = '\u200B'
export const ZERO_WIDTH_JOINER = '\u200D'

//
// hashCode
// --------
// Get a hash code for a string See eg
// http://stackoverflow.com/questions/3426404
//
export function hashCode (str = '') {
  const len = str.length
  let hash = 0
  for (var i = 0; i < len; ++i) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

//
// hashColor
// --------------
// Return a RGB color for the string.
//
export function hashColor (str) {
  const hash = hashCode(str)
  let colour = '#'
  for (var i = 0; i < 3; i++) {
    colour += ('00' + ((hash >> i * 8) & 0xFF).toString(16)).slice(-2)
  }
  return colour
}

/**
 * hashHSL
 *
 * Generates a random HSL based on the hash of the given string with
 * saturation and luminosity clamped to a minimum and maximum
 *
 */
export function hashHSL (str, { s, l } = { s:[20,70], l:[30,50] }) {
  const prng = PRNG(str)
  const nextInt = max => Math.floor(prng.next().value * max)
  const h = nextInt(360)
  const sv = s[0] + nextInt(s[1] - s[0])
  const lv = l[0] + nextInt(l[1] - l[0])
  return `hsl(${h}, ${sv}%, ${lv}%)`
}

/**
 * Perform a locale-based case insensitive search i.e. matching
 *   é == e == E == É
 * @param {string} string to search
 * @param {string} prefix to match
 * @return {bool} true when the prefix matches
 */
export function matchesLocalePrefix (string, prefix, locales = ['en', 'fr']) {
  const qlen = prefix.length
  const opts = {
    ignorePunctuation: true,
    sensitivity: 'base'
  }
  return string.slice(0, qlen).localeCompare(prefix, locales, opts) === 0
}


/**
 * UUID v4 compliant random string generator.
 * See: https://stackoverflow.com/a/7221797/
 *
 * @return {string} UUID v4-compliant random characters
 */
const UUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
export function uuidv4 () {
  const buf = new Uint32Array(4)
  window.crypto.getRandomValues(buf)
  let idx = -1
  return UUID.replace(/[xy]/g, c => {
    idx++
    const r = (buf[idx >> 3] >> ((idx % 8) * 4)) & 15
    const v = c === 'x' ? r : (r & 0xB)
    return v.toString(16)
  })
}


/**
 *
 * Eventually something like this:
 *  - https://stackoverflow.com/questions/23305000
 *  - https://github.com/farzher/fuzzysort/blob/master/fuzzysort.js
 *
 * … this could be _really_ slow, so we have to be judicious about
 * the performance context in which it's used.
 */
export function fuzzyMatch (needle: string, haystack: string): string[] {
  if (!needle) { return [haystack] }
  const nparam = Array.from(needle)
    .map(n => `(${escapeRegExp(n)})`)
    .join('(.*?)')
  const rex = new RegExp(`(.*?)${nparam}(.*)`, 'i')
  const matches = rex.exec(haystack)
  return matches ? matches.slice(1) : null
}

/**
 * Convert `['a', 'b', 'c']` into `a, b, and c`.
 */
export function joinAnd (v: Iterable<string>) {
  const array = Array.from(v)
  if (array.length === 0) { return '' }
  if (array.length === 1) { return array[0] }
  if (array.length === 2) { return array.join(' and ') }
  return [array.slice(0, -1).join(', '), array.slice(-1)].join(', and ')
}

/**
 * Remove accents/diacritics i.e. `é` => `e`.
 * See e.g.:
 *    - https://stackoverflow.com/questions/990904
 */
export function removeAccents (str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
