import { ArrayComponent, UnitView } from 'DataModel'

export class GoverningDocument extends UnitView {
  get elvValue () { return this.title }

  get fields () {
    return {
      title: 'string',
      end: 'pureDate',
      start: 'pureDate',
    }
  }
}

export default class GoverningDocumentsComponent extends ArrayComponent {
  get ItemClass () { return GoverningDocument }
}

GoverningDocumentsComponent.register()
