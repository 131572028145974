
import {
  isFinite, padStart, pick
} from 'lodash-es'
import { isValid, format } from 'date-fns/esm'


import { daysFromNow, fromObject, MONTHS } from 'utils/dates'

import DateParts from './DateParts'

export default class MonthDayComponent extends DateParts {
  static get namespace () { return 'date' }

  setValues (y, M, d) {
    this.y(null)
    this.M(M)
    this.d(d)
  }

  asString () {
    const d = this.d()
    const M = this.M()
    const parts = []
    if (isFinite(M)) { parts.push(this.MONTHS[M]) }
    if (d) { parts.push(this.d()) }
    return parts.join(' ')
  }

  hasMonth() {
    return isFinite(this.M())
  }

  daysFromNow () {
    return daysFromNow(this.M(), this.d())
  }

  toISO_MD_String() {
    // Return the MM-DD string (or just MM there is no day).
    const d = this.d()
    const M = this.M()
    const date = fromObject({d, M})
    return isValid(date)
      ? format(date, 'MM-dd')
      : isFinite(M) ? padStart(M, 2, '0') : ""
  }

  toJS() { return pick(super.toJS(), ['d', 'M']) }

  getSearchDate(props) {
    var M = this.M()
    var d = this.d()
    // M may be 0, for January.
    if (!props || M === undefined || M === null || M === '' || !d) { return }
    const date = fromObject({ M, d })
    return {
      date: format(date, "MM-dd"),
      code: props.code,
      reason: props.reason,
    }
  }

  getSearchIndexDates() {
    if (!this.properties.search) { return }
    return this.getSearchDate(this.properties.search)
  }

  validate() {
    return super.validate() ||
      !Number.isInteger(this.M()) && "The month should be picked." ||
      !this.d() && "A day should be picked."
  }

  /**
   * @param {string} q query search
   * @return {bool} true when this month-day matches the string.
   */
  searchMatches (q) {
    const M = this.M()
    const d = this.d()
    const month = (MONTHS[M] || '').toLowerCase()
    return `${d} ${month}`.includes(q)
  }
}

MonthDayComponent.register()
