
import color from './color'

export const all = {
  outline: 'none',
  '&:focus::placeholder': {
    transition: 'opacity 0.2s ease',
    opacity: 0
  }
}

export const text = {
  backgroundColor: color.textInput.light.secondary,
  'body[dark] &': { // project batman
    backgroundColor: color.textInput.dark.secondary,
    color: color.text.dark.primary
  },
  borderRadius: '4px',
  outline: 'none',
  color: color.text.light.primary,
  padding: '5px 1em',
  border: '1px solid transparent',
  '&:focus': {
    borderColor: color.separator.light.primary,
    'body[dark] &': { // project batman
      borderColor: color.separator.dark.primary,
    },
  },
  '&:focus::placeholder': {
    transition: 'opacity 0.2s ease',
    opacity: 0
  }
}

export const email = {
  ...text
}

export default { all, text, email }
