import { noop } from 'lodash-es'
import { formatLongForUser } from 'utils/dates'
import { Process } from 'utils/Process'

import { color, buttons, dropdown } from 'styles'

import bookmarkIcon from 'icons/solid/bookmark'
import editIcon from 'icons/solid/edit'
import insertIcon from 'icons/regular/plus'
import noteIcon from 'icons/solid/sticky-note'
import responsibleIcon from 'icons/solid/user'
import searchIcon from 'icons/solid/search'
import shareIcon from 'icons/solid/user-circle'
import tagIcon from 'icons/solid/tag'
import viewIcon from 'icons/regular/window'
import zoomInIcon from 'icons/regular/plus-circle'
import zoomOutIcon from 'icons/regular/minus-circle'

import contextIcon from 'icons/custom/iconContextView'
import pageNumbersIcon from 'icons/custom/iconSectionNumbers'
import tocIcon from 'icons/custom/iconToc'
import thumbIcon from 'icons/custom/iconThumbnailView'
import continuousScroll from 'icons/custom/iconContinuousScroll'
import editTogetherIcon from 'icons/custom/iconEditTogether'

import 'select-section'
import PanelHead from './panel-head'
import 'views/button-drop-down'

type ButtonDropDownItem = import('views/button-drop-down').ButtonDropDownItem

type MBPP = import('./MinuteBookPanelProvider').default

/**
 * Top-bar for the minute book panel.
 */
export default class MinuteBookPanelHead extends PanelHead<MBPP> {
  book: BookComponent
  panelProvider: MBPP
  model: import('EntityModel').default

  /**
   * @param {MinuteBookPanelProvider} panelProvider
   * @param {BookComponent} book
   */
  constructor ({ panelProvider, rightView, book, currentPage }) {
    super({panelProvider})
    const {
      model, editing, showEditButton, showUndoButton, showAllEisFields,
    } = panelProvider
    Object.assign(this, {
      rightView,
      book,
      currentPage,
      model,
      editing,
      showEditButton,
      showUndoButton,
      showAllEisFields,
      views: panelProvider.commandSet.rightViews
    })

    this.computed(() => this.panelProvider.searchData.showHighlights(this.rightView() === this.views.search))
  }


  static get buttonCSS () {
    return {
      ...super.buttonCSS,
      ...this.zoomCSS,
      bookmark: {
        'body &': { '--icon-color': '#ff7575' },
        'body[dark] &': { '--icon-color': color.color.dark.red },
      },

      tags: {
        'body &': { '--icon-color': '#f29938' },
        'body[dark] &': { '--icon-color': color.color.dark.orange },
      },

      responsible: {
        'body &': { '--icon-color': '#418EF7' },
        'body[dark] &': { '--icon-color': color.color.dark.blue },
      },

      notes: {
        'body &': { '--icon-color': '#E0BB00' },
        'body[dark] &': { '--icon-color': color.color.dark.yellow },
      }
    }
  }

  get viewHTML () {
    const {
      showContextView, showToC, showSectionPageNumbers, showThumbnailView,
      showAllEisFields, showSplitView,
    } = this.panelProvider
    const items: ButtonDropDownItem[] = [
      this.viewToggleItem('Context View', contextIcon, showContextView),
      this.viewToggleItem('Section Page Numbers', pageNumbersIcon, showSectionPageNumbers),
      this.viewToggleItem('Table of Contents', tocIcon, showToC),
      this.viewToggleItem('Thumbnail View', thumbIcon, showThumbnailView),
      this.viewToggleItem('All EIS Fields', continuousScroll, showAllEisFields),
      this.viewToggleItem('Split View', editTogetherIcon, showSplitView),
    ]

    return (
      <button-drop-down my='top left' at='bottom left'
        text='View' icon={viewIcon} items={items}
      />
    )
  }

  static get viewCSS () {
    return {
      ...super.viewCSS,
      viewItem: {
        ...super.viewCSS.viewItem,
        '&[menu-item="Context View"]': {
          fill: 'orange',
        },
        '&[menu-item="Section Page Numbers"]': {
          fill: 'pink',
        }
      },
    }
  }

  /**
   * @return {Observable.<Number>}
   */
  get zoomObservable () {
    const activeView = this.panelProvider.activeView()
    const { pdfSettings } = activeView
    if (!pdfSettings) { return noop }
    return pdfSettings.zoom
  }

  /**
   * @return {string}
   */
  get zoomPercent () {
    const zoom = this.zoomObservable()
    return Number.isFinite(zoom) ? Math.round(zoom * 100) + '%' : '-'
  }

  get zoomHTML () {
    const { jss } = this
    const ZOOMS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]
    const zoomPct = this.computed(() => this.zoomPercent)

    return this.computed(() => this.panelProvider.activeViewID() === "EntityInfoView" ? '' : (
      <drop-down my='top left' at='bottom left' class={jss.button}>
        <template slot='anchor'>
          <div class={jss.zoomIcons}>
            <div ko-click={e => this.zoomOutClick(e)}>{zoomOutIcon}</div>
            <div class={jss.zoomPercent}>{zoomPct}</div>
            <div ko-click={e => this.zoomInClick(e)}>{zoomInIcon}</div>
          </div>
          <div class={jss.buttonTitle}>Zoom</div>
        </template>
        <template slot='content'>
          <div class={jss.zoomMenu}>
            {ZOOMS.map(z =>
              <div class={jss.zoomItem}
                ko-click={() => this.zoomObservable(z)}>
                {Math.round(z * 100)}%
              </div>)}
          </div>
        </template>
      </drop-down>
    ))
  }

  static get zoomCSS () {
    return {
      zoomIcons: {
        ...buttons.clickable,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '22px',
        padding: '0px 4px',
        borderRadius: '4px',
        border: `1px solid ${color.fill.light.primary}`,
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          border: `1px solid ${color.fill.dark.primary}`,
        backgroundColor: color.fill.dark.primary,
        },

        '& svg': {
          fill: color.systemGrey.light.iconOne,
          height: '18px',
          paddingTop: '4px',
          'body[dark] &': { // project batman
            fill: color.systemGrey.dark.iconOne,
          },
        }
      },

      zoomPercent: {
        fontSize: '0.8rem',
        padding: '0px 4px',
        minWidth: '3.2em',
        textAlign: 'center',
        color: color.light.toolBarIcon,
        'body[dark] &': { // project batman
          color: color.dark.toolBarIcon,
        },
      },

      zoomItem: {
        ...dropdown.item
      },

      zoomMenu: {
        ...dropdown.generalMenu,
        marginTop: '3px'
      }
    }
  }

  zoomInClick (evt, zoom = this.zoomObservable) {
    evt.preventDefault()
    evt.stopPropagation()
    if (!zoom.modify) { return }
    zoom.modify(z => Math.min(2, z + 0.1))
  }

  zoomOutClick (evt, zoom = this.zoomObservable) {
    evt.preventDefault()
    evt.stopPropagation()
    if (!zoom.modify) { return }
    zoom.modify(z => Math.max(0.1, z - 0.1))
  }


  get insertHTML () {
    const items: ButtonDropDownItem[] = [
      { text: 'Section', action: () => this.addSectionClick() },
      { text: 'Upload...', action: () => this.openUploadModal() },
    ]
    return (
      <button-drop-down my='top left' at='bottom left'
        icon={insertIcon} text='Insert' items={items}
      />
    )
  }

  static get moveModalCSS () {
    return {
      moveModal: { position: 'static' },
      moveModalSectionArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '15px',
        marginBottom: '10px',
      },
      moveModalSectionLabel: {
        marginRight: '2em',
      },
      moveModalSectionList: {
        width: '250px',
      },
      moveModalButtonList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      moveModalCancelButton: {
        ...buttons.modalCancel,
      },
      moveModalConfirmButton: {
        ...buttons.modalOk,
      },
    }
  }

  async moveSelectedPages (targetSection) {
    this.book.moveSelectedPagesToSection(targetSection)
    await this.model.vmSave()
    window.app.modal(undefined)
  }

  moveModalHTML () {
    const { jss } = this
    const targetSection = ko.observable(null)
    return (
      <modal-dialog modalTitle={"Move pages"}>
        <template slot='content'>
          <div class={jss.moveModal}>
            <div class={jss.moveModalSectionArea}>
              <div class={jss.moveModalSectionLabel}>Section</div>
              <div class={jss.moveModalSectionList}>
                <select-section book={this.book}
                  targetSection={targetSection}
                  maxHeight={'calc(50vh - 27px - 6.5ex)'} />
              </div>
            </div>
            <div class={jss.moveModalButtonList}>
              <div class={jss.moveModalCancelButton} ko-click={() => window.app.modal(undefined)}>Cancel</div>
              <async-button faceClass={jss.moveModalConfirmButton} action={() => this.moveSelectedPages(targetSection())}>
                <template slot='face'>Confirm</template>
              </async-button>
            </div>
          </div>
        </template>
      </modal-dialog>
    )
  }

  async downloadClick () {
    await this.panelProvider
      .downloadPages(this.book.pages().filter(bp => bp.selected()))
  }

  async sendForSig () {
    await this.panelProvider
      .sendForSig(this.book.pages().filter(bp => bp.selected()))
  }

  get compiledPdfFilename () {
    const { cvModelTitle } = this.model
    const dt = formatLongForUser(new Date())
    return `Minute Book of ${cvModelTitle} on ${dt}.pdf`
  }

  /**
   * Open the PDF in a new window or tab.
   */
  async viewInBrowser () {
    const wref = window.open('about:blank', this.compiledPdfFilename)
    const pStyle = [`text-align: center`].join(';')
    const mStyle = ['margin-bottom: 20px'].join(';')
    const bStyle = wref.document.body.style
    bStyle.display = 'flex'
    bStyle.alignItems = 'center'
    bStyle.justifyContent = 'center'
    const setBody = p => {
      wref.document.body.innerHTML = `
        <div style="${pStyle}">
          <div style="${mStyle}">Please wait while MinuteBox creates your PDF ...</div>
          <div style='font-size: 50px'>${Math.floor(p * 100)}%</div>
        </div>
      `
    }
    setBody(0)
    const process = new Process(this.book.pages.length)

    const blobFuture = this.panelProvider.pdfEngine
      .compile(this.book.pages(), process)
    process.percent.subscribe(setBody)

    const blob = await blobFuture
    const file = new File([blob], this.compiledPdfFilename, { type: 'application/pdf' })
    const url = URL.createObjectURL(file)

    wref.location.href = url

    /**
     * There's evidently no better way to do this.
     * See e.g. https://stackoverflow.com/questions/5712195
     */
    const cleanup = () => {
      clearInterval(timer)
      process.cancel()
      URL.revokeObjectURL(url)
    }
    const timer = setTimeout(() => wref.closed && cleanup(), 200)
    wref.onbeforeunload = cleanup
  }


  get shareMenuHTML () {
    const selectedPageCount = this.computed(() => this.book.pages().filter(bp => bp.selected()).length)

    const downloadText = this.computed<string>(() => {
      if (!selectedPageCount()) { return 'Download' }
      return `Download ${selectedPageCount()} page${selectedPageCount() > 1 ? 's' : ''}`
    })

    const sendForSigText = this.computed<string>(() => {
      const pages = selectedPageCount()
        ? ` ${selectedPageCount()} page${selectedPageCount() > 1 ? 's' : ''}` : ''
      return `Send${pages} for signature`
    })

    const shareMenuToggle = evt => {
      this.panelProvider.commandSet.commands.triggerSharing.click(evt)
    }

    const items: ButtonDropDownItem[] = [
      { text: downloadText, action: () => this.downloadClick() },
      { text: 'View in Browser', action: () => this.viewInBrowser() },
      { text: sendForSigText, action: () => this.sendForSig() },
    ]

    if (!this.model.isProjection()) {
      items.push({ text: 'Share...', action: shareMenuToggle })
    }

    return (
      <button-drop-down my='top right' at='bottom right'
        icon={shareIcon} text='Share' items={items}
      />
    )
  }

  get editMenuHTML () {
    const downloadText = ko.computed(() => {
      const selectedPageCount = this.book.pages().filter(bp => bp.selected()).length
      if (!selectedPageCount) { return 'Download' }
      return `Download ${selectedPageCount} page${selectedPageCount > 1 ? 's' : ''}`
    })

    const items: ButtonDropDownItem[] = [
      { text: 'Move...', action: () => window.app.modal(this.moveModalHTML()) },
      { text: downloadText, action: () => this.downloadClick() },
      { text: 'Delete... ', action: () => this.panelProvider.deleteSelectedPages() },
    ]

    return (
      <button-drop-down my='top left' at='bottom left'
        text='Edit' icon={editIcon} items={items}
      />
    )
  }

  openUploadModal () {
    window.app.modal(<pdf-upload-modal
      book={this.book}
      uploadAction={files => this.panelProvider.uploadPdfMultipleFiles(files)}
      cancelAction={() => this.panelProvider.cancelUpload()} />)
  }

  addSectionClick () {
    this.book.addOrUpdateSection(this.currentPage(), 'New section')
  }

  get projectionButtonRow () {
    const { jss } = this
    return (
      <>
        <div class={jss.buttonGroup}>
          {this.viewHTML}
          {this.zoomHTML}
        </div>
        <div class={jss.buttonGroup}></div>
        <div class={jss.buttonGroup}></div>
        <div class={jss.buttonGroup}></div>
        <div class={jss.buttonGroup}>
          {this.shareMenuHTML}
          {this.commandButton('Search', searchIcon, 'triggerSearch')}
        </div>
      </>
    )
  }

  get undoHTML () {
    return super.undoHTML
  }

  // View - Zoom --  Insert --- Tags --- Bookmark - Notes -- Share - Search
  get buttonRow () {
    const { jss } = this

    if (this.model.isProjection()) { return this.projectionButtonRow }

    return [
      <div class={jss.buttonGroup}>
        {this.viewHTML}
        {this.zoomHTML}
      </div>,
      <div class={jss.buttonGroup}>
        {this.computed(() => this.showUndoButton() ? this.undoHTML : undefined)}
        {this.insertHTML}
      </div>,
      <div class={jss.buttonGroup}>
        <div class={jss.tags}>{this.commandButton('Tags', tagIcon, 'triggerTags')}</div>
        <div class={jss.responsible}>{this.commandButton('Responsible', responsibleIcon, 'triggerResponsible')}</div>
      </div>,
      <div class={jss.buttonGroup}>
        <div class={jss.bookmark}>{this.commandButton('Bookmarks', bookmarkIcon, 'triggerBookmarks')}</div>
        <div class={jss.notes}>{this.commandButton('Notes', noteIcon, 'triggerNotes')}</div>
      </div>,
      <div class={jss.buttonGroup}>
        {this.computed(() => this.showEditButton() ? this.editMenuHTML : undefined)}
        {this.shareMenuHTML}
        {this.commandButton('Search', searchIcon, 'triggerSearch')}
      </div>
    ]
  }

  rightViewClickHandler (view) {
    return evt => {
      evt.stopPropagation()
      evt.preventDefault()
      this.rightView.modify(rv => rv === view ? undefined : view)
    }
  }

  get entityDisplayValue () {
    return this.model.answerFor('legalname')
  }

  static get css () {
    return {
      ...super.css,
      ...this.buttonCSS,
      ...this.headCSS,
      ...this.headActivityCSS,
      ...this.viewCSS,
      ...this.moveModalCSS,
    }
  }
}

MinuteBookPanelHead.register()
