import { Patent } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'


class EisPatents extends EisArrayComponentTable<Patent> {
  get caption () { return 'Patents' }
  get componentName () { return 'patents' }
  get listAddName () { return 'Patent' }
  get columns () {
    return [
      this._jurisdictionColumn('jurisdiction'),
      'description',
      'number',
      'status',
      'expiry',
      'agent',
    ]
  }

  _jurisdictionColumn (property: string) {
    return {
      property: property,
      view: unit => <jurisdiction-flag value={unit[property]} />,
      edit: clone => (
        <>
          {this._simpleTitle('Jurisdiction')}
          <jurisdiction-picker-patents my='top left' at='bottom left'
            value={clone.jurisdiction}
            inputClass={this.jss.picker}
          />
        </>
      ),
    }
  }
}

EisPatents.register()
