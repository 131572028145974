
import { MunicipalBusinessLicense } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisMunicipalBusinessLicenses extends EisArrayComponentTable<MunicipalBusinessLicense> {
  get caption () { return 'Muncipal Business Licenses' }
  get componentName () { return 'municipal_licenses' }
  get listAddName () { return 'Municipal License' }
  get columns () { return ['municipality', 'description', 'registered', 'expiry'] }
}

EisMunicipalBusinessLicenses.register()
