
export default (
  <svg width='28px' height='28px' viewBox='0 0 28 28' version='1.1'>
    <g id='Symbols' stroke-width='1' fill='none' fill-rule='evenodd'>
      <g id='Head' transform='translate(-1105.000000, -25.000000)'>
        <g id='icon-help' transform='translate(1106.000000, 26.000000)'>
          <circle id='Oval' stroke='var(--icon-color)' cx='13' cy='13' r='13' />
          <path d='M11.022,5.613 C13.0460101,5.73566728 14.6023279,6.11133019 15.691,6.74 C16.7796721,7.36866981 17.324,8.23499448 17.324,9.339 C17.324,9.96766981 17.1438351,10.5579972 16.7835,11.11 C16.4231649,11.6620028 15.90567,12.1296648 15.231,12.513 L13.575,13.502 L13.529,13.617 L13.667,15.572 L11.528,15.848 L11.321,15.641 L11.091,12.881 L11.206,12.651 L13.276,11.593 C14.134671,11.1329977 14.564,10.5426703 14.564,9.822 C14.564,9.2086636 14.2343366,8.73716831 13.575,8.4075 C12.9156634,8.07783168 11.8500074,7.86700046 10.378,7.775 L10.171,7.476 L10.723,5.774 L11.022,5.613 Z M12.724,17.895 C13.1993357,17.895 13.5711653,18.0329986 13.8395,18.309 C14.1078347,18.5850014 14.242,18.9683309 14.242,19.459 C14.242,19.9803359 14.0963348,20.3943318 13.805,20.701 C13.5136652,21.0076682 13.1150025,21.161 12.609,21.161 C12.1029975,21.161 11.7158347,21.0230014 11.4475,20.747 C11.1791653,20.4709986 11.045,20.0800025 11.045,19.574 C11.045,19.0679975 11.1944985,18.6616682 11.4935,18.355 C11.7925015,18.0483318 12.2026641,17.895 12.724,17.895 Z' id='?' fill='var(--icon-color)' />
        </g>
      </g>
    </g>
  </svg>
)
