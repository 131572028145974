import { input } from 'styles'

import WritingRightViewVar from './WritingRightViewVar'

type TimeVariable = import('../TimeVariable').default

export default class WrvvTime<T extends TimeVariable> extends WritingRightViewVar<T> {
  static get css () {
    return {
      ...super.css,
      time: {
        ...input.time,
      },
    }
  }

  * suggestedTimes () {
    for (let i = 8; i <= 11; ++i) {
      yield `${i}:00 am`
      yield `${i}:30 am`
    }
    yield `noon`
    yield `12:30 pm`
    for (let i = 1; i <= 7; ++i) {
      yield `${i}:00 pm`
      yield `${i}:30 pm`
    }
  }

  get timeSuggestionHTML () {
    return [...this.suggestedTimes()].map(t => <option value={t}/>)
  }

  get body () {
    const { jss } = this
    this.monitorAndUpdatePeers(this.variable.time)

    return (
      <>
        <datalist id='time-suggestions-list'>
          {this.timeSuggestionHTML}
        </datalist>
        {this.inputSection('Enter Time',
          <input class={jss.input}
            list='time-suggestions-list'
            ko-textInput={this.variable.time} />)}
        {this.inputSection('Slot', this.slotPickerHTML)}
      </>
    )
  }
}

WrvvTime.register()
