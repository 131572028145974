import { default as RightView } from './RightView'

/**
 *          ——— Bookmarks ———
 *
 *        <minute-book-right-view-bookmarks
 *            />
 */
export default class MinuteBookRightViewBookmarks extends RightView {
  bookComponent: import('EntityModel').BookComponent

  constructor (params) {
    const { bookComponent, pdfEngine } = params
    super(params)
    Object.assign(this, { bookComponent, pdfEngine })
  }

  get title () { return 'Bookmarks' }
  get body () {
    return (
      <bookmarks-list
        bookComponent={this.bookComponent}
        pdfEngine={this.pdfEngine} />
    )
  }
}

MinuteBookRightViewBookmarks.register()
