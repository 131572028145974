
import { parseISO } from 'date-fns/esm'
import Big from 'big.js'

import UnitView from './UnitView'
import unitViewEventTextMap from './unitViewEventTextMap'

export interface DateGenerator extends UnitField<any> {
  reminderDate: (v: any) => Date
  reminderText: (property: string, unitView: UnitView) => string
}

export abstract class UnitField<T> {
  abstract toJSON (v: T) : JsonValue
  abstract fromJSON (v: JsonValue, unitView?, constructorOrFactory?) : T | KnockoutObservable<T> | KnockoutObservableArray<T>
  copyValue (
    from: T | KnockoutObservable<T>,
    to: KnockoutObservable<T>
  ) { to(ko.unwrap(from)) }
}

export abstract class DateGeneratingField<T> extends UnitField<T> implements DateGenerator {
  abstract reminderDate (v: T) : Date
  reminderText (property, unitField) {
    const { parent, constructor } = unitField
    const { identifier } = parent
    if (identifier in unitViewEventTextMap) {
      const textMap = unitViewEventTextMap[identifier](unitField.toJS())
      return textMap[property] || `${constructor.name} ${property}`
    }
    console.warn(`
      Missing unitViewEventTextMap for "${identifier}".
      Add it to "DataModel/components/unitViewEventTextMap"
    `)
    return `${constructor.name}[${identifier}] ${property}`
  }
}

class PureDateUnit extends DateGeneratingField<Date> {
  fromJSON (v) { return ko.observable(v ? parseISO(v) : null) }
  toJSON (v) { return v ? v.toISOString().slice(0, 10) : null }
  reminderDate (v) { return v }
}

class DateTimeUnit extends DateGeneratingField<Date> {
  fromJSON (v) { return ko.observable(v ? parseISO(v) : null) }
  toJSON (v) { return v ? v.toISOString() : null }
  reminderDate (v) { return v }
}

class PrimitiveUnit<T> extends UnitField<T> {
  toJSON (v) { return v }
  fromJSON (v) { return ko.observable(v || '') }
}

class IntegerUnit extends PrimitiveUnit<number> {
  fromJSON (v) {
    if (typeof v === 'string') { v = v.replace(/[^\d\.\-]/g, '') }
    return ko.observable(Number.parseInt(v, 10) || null)
  }
}

class NumberUnit extends PrimitiveUnit<number> {
  fromJSON (v) {
    if (typeof v === 'string') { v = v.replace(/[^\d\.\-]/g, '') }
    return ko.observable(parseFloat(v) || null)
  }
}

class BooleanUnit extends UnitField<boolean> {
  toJSON (v) { return v === null ? null : Boolean(v) }
  fromJSON (v) { return ko.observable(v) }
}

class DecimalUnit extends UnitField<Big> {
  toJSON (v) { return v && v.toString() }
  fromJSON (v) { return ko.observable(v ? new Big(v) : null) }
}

class ObjectUnit extends UnitField<object> {
  toJSON (v) { return ko.toJS(v) }
  fromJSON (v) { return ko.observable(v) }
}

class PersonUnit extends UnitField<PersonRecord> {
  toJSON (v) { return ko.toJS(v) }
  fromJSON (v) {
    if (typeof v === 'string') { // EIS 2.0 => 2.1 🐫
      v = { name: [v] }
    }
    return ko.observable(Object.assign({
      id: null,
      name: [],
      email: [],
      address: [],
      phone: [],
      origin: [],
    }, v))
  }
}

interface toJsonAble {
  toJS () : JsonObject
}

interface MapUnitable extends toJsonAble {
  isBlank: () => boolean
  isDeleted: () => boolean
}

class MapUnit<T extends MapUnitable> extends UnitField<MapUnitable[]> {
  shouldBeSaved (v) {
    return !v.isBlank() && !v.isDeleted()
  }

  toJSON (v) { return v.filter(this.shouldBeSaved).map(v => v.toJS()) }
  fromJSON (v, unitView, Ctr) {
    const arr = (v || []).map(o => new Ctr(o, unitView))
    return ko.observableArray<T>(arr)
  }
}

class ClassListUnit<T extends toJsonAble> extends UnitField<toJsonAble[]> {
  toJSON (v) { return v.map(v => v.toJS()) }
  fromJSON (v, unitView, factory) {
    const values = (v || [])
      .map(o => factory(o, unitView))
    return ko.observableArray<T>(values)
  }
}

class ClassUnit<T> extends UnitField<T> {
  toJSON (v) { return v.toJS() }
  fromJSON (v, unit, Ctr) {
    return new Ctr(v, unit)
  }
}

export const UnitFieldMap = {
  datetime: new DateTimeUnit(),
  pureDate: new PureDateUnit(),
  primitive: new PrimitiveUnit(),
  string: new PrimitiveUnit(),
  int: new IntegerUnit(),
  integer: new IntegerUnit(),
  number: new NumberUnit(),
  bool: new BooleanUnit(),
  boolean: new BooleanUnit(),
  decimal: new DecimalUnit(),
  big: new DecimalUnit(),
  object: new ObjectUnit(),
  map: new MapUnit(),
  classList: new ClassListUnit(),
  class: new ClassUnit(),
  person: new PersonUnit(),
}


export type FieldDescription = {
  property: string
  label: MaybeObservable<string>
  type: keyof typeof UnitFieldMap
}
