/*
    This is the full Fiscal Year End, used in a document. It computes the
    fiscal year end from the given corporation and and given a mutable year.
 */
import {
  noop
} from 'lodash-es'

import { MONTHS, fromObject } from 'utils/dates'

import DateParts from './DateParts'

export default class FiscalYearEndComponent extends DateParts {
  static get namespace () { return 'date' }
  init () {
    this.y = ko.observable(null)
    this.M = ko.pureComputed({
      read: this.computeM,
      write: noop,
      owner: this
    })
    this.d = ko.pureComputed({
      read: this.computeD,
      write: noop,
      owner: this
    })

    this.component_instance = this
    this.MONTHS = MONTHS
  }

  get date () {
    return fromObject(this.toJS())
  }

  toJS () {
    return {
      d: parseInt(this.d(), 10) || null,
      M: parseInt(this.M(), 10) || null,
      y: parseInt(this.y(), 10) || null
    }
  }

  linkedCorpFYE () {
    try {
      return this.model.interpretation.lookupByKey('corp').fiscal_year_end_md || {}
    } catch (e) { return {} }
  }

  computeM () {
    const M = ko.unwrap(this.linkedCorpFYE().M)
    return Number.isFinite(M) ? M : null
  }
  computeD () { return ko.unwrap(this.linkedCorpFYE().d) || null }
}

FiscalYearEndComponent.register()
