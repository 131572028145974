
import EisArrayComponentTable from './EisArrayComponentTable'
import { Officer } from 'EntityModel/components'

import { OFFICER_TITLES } from '../constants'

class EisOfficers extends EisArrayComponentTable<Officer> {
  get caption () { return 'Officers' }
  get componentName () { return 'officers' }
  get listAddName () { return 'Officer' }
  get titleModelPickerChoices () { return OFFICER_TITLES }
  get columns () {
    return [
      'person',
      'title',
      'elected',
      'resigned',
      this._roleCountColumn('person'),
    ]
  }
}

EisOfficers.register()
