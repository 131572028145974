import ViewComponent from "../ViewComponent"

import summaryViewCSS from 'summaryViewCSS'

type MinuteBoxApp = import('minute-box-app').default
type LeftPanelSection = import('minute-box-app/panel-left').LeftPanelSection

export default class PersonInformationSummary extends ViewComponent {
  app: MinuteBoxApp
  model: PersonRecord | KnockoutObservable<PersonRecord>
  sections: KnockoutObservableArray<LeftPanelSection>
  showAllFields: KnockoutObservable<boolean>

  constructor ({model, app, sections, showAllFields}) {
    super()
    Object.assign(this, {
      model,
      app,
      sections,
      showAllFields: showAllFields || ko.observable(true)
    })

    this.sections().forEach(s => {
      this.subscribe(s.showField, () => this.showHideSection(s))
    })

    this.sections.subscribe(changes => {
      changes.filter(c => c.status === 'added')
        .forEach(c => {
          if (this.showAllFields()) { c.value.showField(true) }
          this.subscribe(c.value.showField, () => this.showHideSection(c.value))
        })
    }, null, 'arrayChange')

    this.subscribe(this.showAllFields, showAll => {
      if (showAll) {
        const currentlyShown = this.sections().find(s => s.showField()) || this.sections().shift()
        this.sections().forEach(s => s.showField(true))
        if (currentlyShown) {
          currentlyShown.scrollTo(-100)
          currentlyShown.scrollTo(100)
        }
      }
      else {
        const firstVisible = this.sections().find(s => s.visible()) || this.sections().shift()
        this.sections().forEach(s => s.showField(false))
        if (firstVisible) {
          firstVisible.showField(true)
          firstVisible.showField.notifySubscribers()
        }
      }
    })

  }

  showHideSection (section : LeftPanelSection) {
    if (this.showAllFields()) {
      section.showField(true)
    } else if (section.showField()) {
      this.sections().filter(ss => ss !== section).forEach(ss => ss.showField(false))
      section.visible(true)
    }
  }

  static get css () {
    return {
      ...summaryViewCSS,
      layout: {
        ...summaryViewCSS.layout,
      },
      categoryTitle: {
        ...summaryViewCSS.categoryTitle,
        '&[section=Relationships]': {
          marginTop: '32px', // TODO perhaps allow the <tab-container> to configure this automatically
        }
      },
      categoryLabel: {
        ...summaryViewCSS.categoryLabel,
        position: 'sticky',
        top: 'calc(var(--head-height) + 20px)',
      }
    }
  }

  sectionTitleHTML (section : LeftPanelSection) {
    const { jss } = this
    const style = `grid-row: span ${section.fields.length}`
    const koIntersection = {
      value: section.visible,
      options: { rootMargin: `-88px 0px -30px 0px` }
    }
    return (
      <div class={jss.categoryTitle} style={style}
          section={section.title}
          ko-scrollTo={section.scrollTo} >
        <div class={jss.categoryLabel} ko-intersection={koIntersection} >
          {section.title}
        </div>
      </div>
    )
  }

  get viewHTML () {
    return this.sections
      .filter(s => s.fields().length)
      .filter(s => s.showField())
      .flatMap(section => ([
        this.sectionTitleHTML(section),
        ...section.fields(),
      ]))
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        {this.viewHTML}
      </div>
    )
  }
}

PersonInformationSummary.register()
