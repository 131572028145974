import { sortBy } from 'lodash-es'

import RightView from 'MinuteBookPanelProvider/right-view/RightView'
import { color, input } from 'styles'
import { currentOrLastEditableRange } from 'utils/editable'
import { computed, memoize } from 'utils/decorator'
import { fuzzyMatch } from 'utils/string'

import { possibleEntityVariables } from '../variables/entityVariables'
import { staticVariables } from '../variables/staticVariables'

type SlotManager = import('../variables/slots').SlotManager
type Command = import('../commands').Command
type VariableCommand = import('../commands/Command').VariableCommand
type WritingCommandSet = import('../WritingCommandSet').default

interface VariableCreator {
  Command: Command
  isUsed: KnockoutComputed<boolean>
}


export default class WritingRightViewFillins extends RightView {
  slotManager: SlotManager
  filter: KnockoutObservable<string> = ko.observable('')
  commandMenuSlashEvent: KnockoutObservable<FakePopoverEvent>
  commandSet: WritingCommandSet

  constructor (params) {
    super(params)
    const { slotManager, commandMenuSlashEvent, commandSet } = params
    Object.assign(this, { slotManager, commandMenuSlashEvent, commandSet })
  }

  get title () { return 'Fill-Ins' }
  get multiTitleItems () { return null }

  static get css () {
    return {
      ...super.css,
      ...this.variableCSS,
      heading: {
        fontWeight: '600',
        padding: '10px 0 10px 40px',
      },
      list: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        height: '300px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        margin: '0 40px 20px 40px',
        fontSize: '1.5em',
        background: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          background: color.systemBackground.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        '&[active=true]': {

        },
      },

      items: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: '10px',
      },

      customFillInsList: {
        extend: 'list',
        height: '200px',
      },

      searchFillinsMeta: {
        height: 40,
        width: 198,
        // margin: '-43px 0px 0px -10px',
        background: color.textInput.light.primary,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        padding: '0px 12px',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        'body[dark] &': { // project batman
          background: color.textInput.dark.primary,
        },
      },

      searchFillins: {
        ...input.text,
        color: color.text.light.primary,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        borderRadius: 19,
        padding: '4px 15px',
        fontSize: '0.7em',
        backgroundColor: color.textInput.light.primary,
        margin: 7,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          backgroundColor: color.textInput.dark.primary,
        },
      },

      _listItem: {
        cursor: 'grab',
        opacity: '0.95',
        padding: '6px 10px',
        fontSize: '75%',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: 3,
        marginBottom: 10,
        width: 'fit-content',
        userSelect: 'none',
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.5,
          cursor: 'grabbing',
        },

      },

      listClient: {
        extend: '_listItem',
        //color: 'rgba(126,211,33,1)',
        // border: '1px solid rgba(126,211,33,1)',
        background: 'rgba(126,211,33,0.25)',
        marginTop: 35,
      },

      listEntity: {
        extend: '_listItem',
        //color: 'rgba(189,16,224,1)',
        //border: '1px solid rgba(189,16,224,1)',
        background: 'rgba(189,16,224,0.25)',
      },
      listDates: {
        extend: '_listItem',
        //color: 'rgba(75,145,227,1)',
        //border: '1px solid rgba(75,145,227,1)',
        background: 'rgba(75,145,227,0.25)',
      },

      listUsed: {
        extend: '_listItem',
        //color: 'rgba(245,166,35,1)',
        //border: '1px solid rgba(245,166,35,1)',
        background: 'rgba(245,166,35,0.25)',
      },

      listUnused: {
        extend: '_listItem',
        //color: 'rgba(139,87,42,1)',
        //border: '1px solid rgba(139,87,42,1)',
        background: 'rgba(139,87,42,0.25)',
      },

      insertionGhost: {
        padding: '4px 8px',
        borderRadius: '4px',
        border: '2px solid #4a90e2',
        backgroundColor: 'rgba(74,144,226,0.1)',
        fontSize: '0.6em',
        textTransform: 'uppercase',
        color: color.text.light.secondary,
        fontWeight: '700',
        margin: '0px 2px 0px 2px',
        userSelect: 'none',
        '&::selection': {
          background: 'unset',
        },
      },
    }
  }

  private _insertionGhost: HTMLElement
  private get insertionGhost (): HTMLElement {
    if (!this._insertionGhost) {
      const ig = document.createElement('mark')
      ig.setAttribute('class', this.jss.insertionGhost)
      ig.textContent = 'Insert Here'
      this._insertionGhost = ig
    }
    return this._insertionGhost
  }

  private mouseoutVar () { this.insertionGhost.remove() }
  private mouseoverVar () {
    currentOrLastEditableRange().insertNode(this.insertionGhost)
    this.insertionGhost
      .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  }

  private variableButtonHTML (c: Command, isUsed: KnockoutComputed<boolean>) {
    const { jss } = this
    const css = this.computed(() => isUsed() ? jss.listUsed : jss.listUnused)

    return (
      <div class={css}
        ko-ownClick={() => c.trigger(this.commandSet.commandMenu)}>
        {c.text}
      </div>
    )
  }

  static get variableCSS () {
    return {
      slot: {
        border: '1px solid red',
        borderRadius: '6px',
        padding: '3px',
        margin: '0px 2px',
        display: 'none',
      },
    }
  }

  /**
   * FIXME: This needs to have the `slotGroupCode` from `entityVariables`
   * (or elsewhere) in order to be able to determine if the variable
   * that the command creates is one that's being used.
   */
  private isUsed (command: VariableCommand) {
    return this.computed<boolean>(() => {
      const group = this.slotManager.slotGroupByCode(command.variableSlotCode)
      return group && !group.isEmpty
    })
  }

  private makeCreator (command: VariableCommand) {
    const isUsed = this.isUsed(command)
    return {
      command,
      text: command.text,
      html: this.variableButtonHTML(command, isUsed),
      isUsed,
    }
  }

  @memoize()
  private get entityCreators (): VariableCreator {
    return possibleEntityVariables().map(c => this.makeCreator(c))
  }

  private get entityVariables () {
    return sortBy(this.entityCreators, [v => !v.isUsed(), 'text'])
  }

  private get staticVariables () {
    return staticVariables().map(c => this.makeCreator(c))
  }

  @computed()
  private matchingEntityVariables () {
    return this.entityVariables
      .filter(({ text }) => fuzzyMatch(this.filter(), text))
      .map(({ html }) => html)
  }

  @computed()
  private matchingStaticVariables () {
    return this.staticVariables.map(({ html }) => html)
  }

  get body () {
    const { jss } = this
    return (
      <div class={jss.body}>
        <div class={jss.heading}> Available Fill-Ins </div>
        <div class={jss.list}
          ko-event={{
            mouseover: () => this.mouseoverVar(),
            mouseout: () => this.mouseoutVar(),
          }}>
          <div class={jss.searchFillinsMeta}>
            <input class={jss.searchFillins} placeholder='Search'
              ko-textInput={this.filter}/>
          </div>
          <div class={jss.items}>
            {this.matchingEntityVariables}
          </div>
        </div>
        <hr/>
        <div class={jss.heading}>Custom Fill-Ins</div>

        <div class={jss.customFillInsList}>
          <div class={jss.items}>
            {this.matchingStaticVariables}
          </div>
        </div>
      </div>
    )
  }
}

WritingRightViewFillins.register()
