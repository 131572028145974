import MinuteBookPanelProvider from 'MinuteBookPanelProvider'
import UserPanelProvider from 'minute-box-app/UserPanelProvider'

type MinuteBoxApp = import("minute-box-app").default

export function makeLink (app : MinuteBoxApp, origin : PersonOrigin) : () => void {
  if (!origin) { return null }
  if (origin.model.vmResourceName === 'entity') {
    return () => {
      const bookView = new MinuteBookPanelProvider(
        { app, model:origin.model, activeViewID:'EntityInfoView' })
      app.panelProvider(bookView)
    }
  } else if (origin.model.vmResourceName === 'user') {
    return () => {
      const userView = new UserPanelProvider({ app })
      app.panelProvider(userView)
    }
  }
  return null
}
