
import CrudModel from 'DataModel/CrudModel'
import { pick } from 'lodash-es'

import {
  PlainMediator, ArrayMediator, ReadOnlyMediator
} from 'DataModel/mediators'

import { makePersonRecord } from 'person'
import { PersonType } from 'person/interfaces'

export default class UserModel extends CrudModel {
  get QUESTIONS () { return [] }

  get cvModelTitle () {
    return (this.firstname() || this.lastname())
      ? `${this.firstname() || ''} ${this.lastname() || ''} (${this.email()})`
      : this.email()
  }

  get statusName () {
    return ['Disabled', 'Active'][this.enabled() ? 1 : 0]
  }

  get name () {
    return [this.firstname(), this.lastname()].filter(n => n).join(' ')
  }

  matchesTeamFilter (teamFilter) {
    return teamFilter.isTeam() && teamFilter.test(this)
  }

  /**
   * @param {Iterable.<FilterModel>} teamFilters
   * @return {Array.<string>} of filter keys that match this user
   */
  matchingTeamFilterKeys (teamFilters) {
    return [...teamFilters]
      .filter(fm => this.matchesTeamFilter(fm))
      .map(fm => fm.id())
      .sort()
  }

  get selfPerson () : PersonRecord {
    const personData = pick(this, [
      'firstname',
      'lastname',
      'email',
      'phone',
      'fax'
    ])
    const name = `${this.firstname()||''} ${this.lastname()||''}`.trim()
    const personRecord: PersonRecord = makePersonRecord({
      id: `user:${this.id()}`,
      type: PersonType.USER,
      name: name ? [name] : [],
      origin: [{
        update: () => null, // FIXME
        model: this,
        * roles () { yield * [{ title:'User' }] }
      }]
    })
    Object.assign(personRecord,
      ...Object.entries(personData)
        .filter(([key,value]) => ko.unwrap(value))
        .map(([key, value]) => ({[key]:[ko.unwrap(value)]})))
    return personRecord
  }

  * getPersons (filter: (person: PersonRecord) => boolean = () => true) {
    yield * [this.selfPerson].filter(filter)
  }

  vmCreateMediatorInstances () {
    return [
      ...super.vmCreateMediatorInstances(),
      new PlainMediator('firstname', this),
      new PlainMediator('lastname', this),
      new PlainMediator('email', this),
      new PlainMediator('phone', this),
      new PlainMediator('fax', this),
      new PlainMediator('admin', this),
      new PlainMediator('enabled', this),
      new PlainMediator('office', this),
      new PlainMediator('role', this),
      new ReadOnlyMediator('invite', this),
      new ArrayMediator('teamFilterKeys', this)
    ]
  }
}

UserModel.register()
