
import NumberedParagraph from '../blocks/NumberedParagraph'

import BodyBlockEditor from './BodyBlockEditor'

export default class BlockNumberedParaEditor extends BodyBlockEditor<NumberedParagraph> {
  static get css () {
    return {
      ...super.css,
      number: {
        color: 'blue',
        fontSize: '0.8rem',
        lineHeight: '2.2em',
        width: '20px',
        marginRight: '1em'
      }
    }
  }

  get internalHTML () {
    const number = this.computed(() => this.block.getNumber(this.blocks))
      .extend({ deferred: true })
    return (
      <>
        <div class={this.jss.number}>
          {number}
        </div>
        {super.internalHTML}
      </>
    )
  }
}

BlockNumberedParaEditor.register()
