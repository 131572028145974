import t from 't'

import { CapitalAction } from '../CapitalAction'
import { CapitalState } from '../CapitalState'
import { AssetAuthorize, AssetSetProperty } from '../AssetAction'
import { AssetTransferLine, CapitalAssetProperty } from '../interfaces'

import { CODES } from './Transaction'
import { AssetTransaction } from './AssetTransaction'

export class TransactionAuthorize extends AssetTransaction {
  classification: KnockoutObservable<string>
  prefix: KnockoutObservable<string>
  series: KnockoutObservable<string>
  properties: KnockoutObservableArray<CapitalAssetProperty>
  quantumAuthorized: KnockoutObservable<string>

  get deleteable () { return false }
  get plainObservables () {
    return [
      ...super.plainObservables,
      'classification', 'prefix', 'quantumAuthorized', 'series',
    ]
  }

  get propertiesToSerialize () { return ['properties'] }
  shortDescription () { return t.CAPITAL_AUTHORIZED(this.classification) }

  constructor (params) {
    super(params)
    this.propertiesFromJS(params)
    Object.assign(this, {
      properties: ko.observableArray(
        (params.properties || []).map(t => ({
          ...t,
          value: ko.observable(t.value),
          note: ko.observable(t.note),
        }))
      ),
    })
  }

  getPropertyValue (key: string) : KnockoutObservable<any> {
    const prop = this.properties().find(p => p.key === key)
    return prop ? prop.value : null
  }

  * yieldActions (): IterableIterator<CapitalAction> {
    const { assetClassID } = this
    yield new AssetAuthorize(assetClassID)
    const actionSetProperties = [
      'classification', 'prefix', 'series', 'quantumAuthorized',
    ]
    for (const key of actionSetProperties) {
      const value = this[key]()
      if (value === undefined) {
        continue
      }
      yield new AssetSetProperty(assetClassID, key, value)
    }
    for (const { key, value } of this.properties) {
      yield new AssetSetProperty(assetClassID, key, value)
    }
  }

  /**
   * We overload the `shareTransferLine` because the actions related to
   * asset authorizations are determined only _after_ the actions are applied.
   */
  shareTransferLine (state: CapitalState): AssetTransferLine {
    const line = super.shareTransferLine(state)
    const { assetClassID } = this
    line.parts.push({
      assetClassID,
      person: '',
      amount: this.quantumAuthorized(),
      asset: {
        prefix: this.prefix(),
        series: this.series(),
        classification: this.classification(),
      },
    })
    return line
  }

  get code () { return CODES.CapitalAuthorize }
}
