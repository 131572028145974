
import * as demoData from './demo-data'
import mbAdmin from './mbAdmin'

export { default as AuthManager } from './AuthManager'
export { getPossibleAuthManagers } from './detect'

// console.info(`
//   Setting globals:
//     - demoData: Demo-related utilities
//     - mbAdmin: Administrator functions
// `)

Object.assign(window, { mbAdmin, demoData })
