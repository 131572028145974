
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class AuthorizedToInstruct extends UnitView {
  constructor (params, parent) {
    // EIS 2.0 => 2.1 🐫
    params.person = params.person || { name: [params.name] }
    super(params, parent)
  }

  get elvValue () { return this.person().name.slice(0,1).pop() }

  get fields () {
    return {
      person: 'person',
    }
  }

  * getRoles () : IterableIterator<PersonRoleRecord> {
    yield { title: 'Authorized to Instruct' }
  }
}

export default class AuthorizedToInstructComponent extends ArrayComponent {
  get ItemClass () { return AuthorizedToInstruct }
}

AuthorizedToInstructComponent.register()
