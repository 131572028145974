
import EisArrayComponentTable from './EisArrayComponentTable'

import { LandRegistration } from 'EntityModel/components/LandRegistrationsComponent'

class EisLandRegistrations extends EisArrayComponentTable<LandRegistration> {
  get caption () { return 'Land Registrations' }
  get componentName () { return 'land_registrations' }
  get listAddName () { return 'Land Registration' }
  get columns () {
    return ['municipal_address', 'legal_description', 'acquired', 'disposed']
  }
}

EisLandRegistrations.register()
