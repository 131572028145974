
import MediatorInterface from './MediatorInterface'

export default class ClassMediator extends MediatorInterface {
  constructor (name, model, Class) {
    super(name, model)
    Object.assign(this, { Class })
  }

  newHolderInstance () {
    return new this.Class(this.model)
  }

  toJS () { return this.value.toJS() }
  fromJS (data) { this.value.set(data) }

  projectionValue (...args) {
    if ('projectionValue' in this.value) {
      return this._callValueFn('projectionValue', ...args)
    }
    return this.toJS()
  }

  _callValueFn (fnName, ...args) {
    if (!this.value[fnName]) { return null }
    return this.value[fnName](...args)
  }

  beforeModelSave (...args) {
    return this._callValueFn('beforeModelSave', ...args)
  }

  afterModelSave (...args) {
    return this._callValueFn('afterModelSave', ...args)
  }
}
