import color from './color'

export default {
  event: {
    borderLeft: `4px solid var(--event-color, ${color.onPrimary})`,
    transition: 'background-color 80ms',
    '&:hover': {
      backgroundColor: color.gray.e,
    }
  }
}
