
import PrecedentModel from './PrecedentModel'
import WritingPanelProvider from '../WritingPanelProvider'
import WritingDocument from '../WritingDocument'

import './precedent-panel-head'

export default class PrecedentPanelProvider extends WritingPanelProvider<PrecedentModel> {
  static get Model () { return PrecedentModel }

  rightView: KnockoutObservable<JSX> = ko.observable()
  document: WritingDocument

  get panelID () { return 'precedent' }

  get topFixed () {
    const { jss } = this
    return (
      <precedent-panel-head
        class={jss.panelHead}
        panelProvider={this}
        document={this.document} />
    )
  }
}
