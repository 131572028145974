
import {
  QueryMemoryDB, AdminQueryMemoryDB, ShareQueryMemoryDB, TeamQueryMemoryDB
} from './QueryMemoryDB'

export abstract class IndexTracker {
  abstract get indexName () : string

  /**
   * @return {QueryMemoryDB}
   */
  * memoryQueries ({ authManager, accountID, accountClaims, userClaims }) : IterableIterator<QueryMemoryDB> {
    const indexName = this.indexName
    const memQueryParams = { authManager, accountID, indexName }
    if (accountClaims.admin) {
      yield new AdminQueryMemoryDB(memQueryParams)
      return
    }
    const queryGeneratorParams = {
      memQueryParams, accountClaims, userClaims
    }
    yield * this.nonAdminMemoryQueries(queryGeneratorParams)
  }

  * nonAdminMemoryQueries ({ memQueryParams, userClaims, accountClaims }) : IterableIterator<QueryMemoryDB> {
    if (accountClaims.teamFilterKeys) {
      // if there are no `teamFilterKeys` then the user is only shared.
      yield new TeamQueryMemoryDB({
        ...memQueryParams, teamFilterKey: 'user.*' })
      for (const teamFilterKey of accountClaims.teamFilterKeys || []) {
        yield new TeamQueryMemoryDB({
          ...memQueryParams, teamFilterKey })
      }
    }

    yield new ShareQueryMemoryDB({
      ...memQueryParams, email: userClaims.email
    })
  }
}

export class EntityIndexTracker extends IndexTracker {
  get indexName () { return 'entity' }
}

/**
 * Query for every user.
 */
export class UserIndexTracker extends IndexTracker {
  get indexName () { return 'user' }

  * memoryQueries ({ authManager, accountID }) {
    const indexName = this.indexName
    const memQueryParams = { authManager, accountID, indexName }
    yield new AdminQueryMemoryDB(memQueryParams)
  }
}

export class FilterIndexTracker extends IndexTracker {
  get indexName () { return 'filter' }
}

export class PrecedentIndexTracker extends IndexTracker {
  get indexName () { return 'precedent' }
}
