import { writingCommonListColumns } from 'writing/writing-views/writingCommonListColumns'
import ConfirmModelDelete from 'confirm-delete/confirm-model-delete'

type WritingModel = import('writing/WritingModel').default
type EntityDocumentView = import('./EntityDocumentsView').EntityDocumentsView

export function writingActions (row: WritingModel) {
  return [{
    label: 'Delete ...', action: () => ConfirmModelDelete.open(row),
  }]
}

export function writingListColumns (provider: EntityDocumentView) {
  return [
    ...writingCommonListColumns(provider),
  ]
}
