
import ViewComponent from 'ViewComponent'
import { buttons } from 'styles'

export default class AboutMinutebox extends ViewComponent {
  authManager: AuthManager

  constructor ({ authManager }) {
    super()
    Object.assign(this, { authManager })
  }

  diagClick () {
    window.app.modal(
      <modal-dialog modalTitle='Diagnostics'>
        <template slot='content'>
          <minutebox-diagnostics authManager={this.authManager} />
        </template>
      </modal-dialog>
    )
  }

  get template () {
    const { authManager, jss } = this
    const { ABOUT_MINUTEBOX } = window
    const fbConfig = authManager.firebaseConfig

    return (
      <div>
        <div><b>User</b> {authManager.firebaseUser().email}</div>
        <div><b>UserID</b> {authManager.firebaseUser().uid}</div>
        <hr />
        <div><b>Release</b>      {ABOUT_MINUTEBOX.FRIENDLY_RELEASE}</div>
        <div><b>Build #</b>      {ABOUT_MINUTEBOX.BUILD_NUMBER}</div>
        <div><b>Branch</b>     ️  {ABOUT_MINUTEBOX.GIT_BRANCH}</div>
        <div><b>Version</b>      {ABOUT_MINUTEBOX.GIT_REVISION}</div>
        <div><b>Compiled</b>     {ABOUT_MINUTEBOX.COMPILE_TIMESTAMP}</div>
        <div><b>Compile uname</b>     {ABOUT_MINUTEBOX.BUILD_UNAME}</div>
        <div><b>Compile Host</b>     {ABOUT_MINUTEBOX.BUILD_HOST}</div>
        <div><b>ci</b>           {ABOUT_MINUTEBOX.CI}</div>
        <hr />
        <div><b>Project</b> {fbConfig.projectId}</div>
        <div><b>Storage</b> {fbConfig.storageBucket}</div>
        <div><b>Account</b> {' '}{authManager.accountID}</div>
        <div class={jss.diagButton}
          ko-ownClick={() => this.diagClick()}>Diagnostics</div>
        <hr />
        <em>© 2018-19 MinuteBox Inc.</em>
      </div>
    )
  }

  static get css () {
    return {
      diagButton: {
        ...buttons.clean
      }
    }
  }
}

export class MinuteboxDiagnostics extends ViewComponent {
  authManager: AuthManager

  constructor ({authManager}) {
    super()
    Object.assign(this, { authManager })
  }

  static get css () {
    return {}
  }

  /**
   * FIXME: This needs to be on a per-user basis.
   */
  testFirestore () {
    const obs = ko.observable<string>('Waiting ...')
    const fn = async () => {
      const udr = this.authManager.userDocRef
      const timestamp = +new Date()
      try {
        const refRef = udr.collection('meta').doc('__reflect')
        await refRef.set({ timestamp })
        const refDoc = await refRef.get()
        if (refDoc.data().timestamp !== timestamp) {
          throw new Error(`Reflected timestamp ${timestamp} !== ${refDoc.data().timestamp}`)
        }
        console.log(`Firestore reflected.`)
        obs('ok')
      } catch (err) {
        console.error(`Firestore test failed:`, err)
        obs('Error')
      }
    }
    fn()
    return obs
  }

  async testFirebaseFunctions (fbfn: string) {
    const obs = ko.observable('Waiting...')
    const fn = async () => {
      try {
        const r = await this.authManager.firebaseFn(fbfn)
        console.log(`testFirebaseFunctions '${fbfn}' result`, r)
        obs(r.status)
      } catch (err) {
        console.error(`Firebase function error:`, err)
        obs('Error')
      }
    }
    fn()
    return obs
  }

  /**
   * TODO: Test Google Cloud Storage
   */
  // testStorage () {
  // }

  get template () {
    const { authManager } = this
    const claims = this.computed(() => JSON.stringify(
      authManager.userFirebaseClaims().accounts, null, 2))

    return (
      <div>
        <div><b>Functions:</b> {this.testFirebaseFunctions('ping')}</div>
        <div><b>Datastore:</b> {this.testFirestore()}</div>
        <div><b>Verified Claims</b>
          <q-tip name='verifiedClaims' />
          <pre>{claims}</pre>
        </div>
      </div>
    )
  }
}

AboutMinutebox.register()
MinuteboxDiagnostics.register()
