
import icons from 'icons'
import shareIcon from 'icons/light/share-square'
import removeIcon from 'icons/solid/times-circle'
import { color, buttons } from 'styles'
import { computed } from 'utils/decorator'

import RightView from './RightView'

type SharingRight = import('DataModel/SharingRight').default

/**
 *          ——— Sharing ———
 */
export default class MinuteBookRightViewSharing extends RightView {
  constructor (params) {
    const { model, bookComponent, panelProvider } = params
    super(params)
    Object.assign(this, { model, bookComponent, panelProvider })
  }

  get title () { return 'Share' }
  get body () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        <div class={jss.addShareButton}>
          <async-button action={() => this.addShareClick()}
            faceClass={jss.externalizeItem}>
            <template slot='face'>
              {icons.button(shareIcon)}
              Add Share
            </template>
          </async-button>
        </div>
        <div class={jss.sharingWith}>
          {this.sharingWithHTML}
        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.sharingWithCSS,
      layout: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - var(--head-height) - 70px)'
      },
      externalizeButtons: {
        //marginTop: 'auto'
        position: 'absolute',
        bottom: '0%',
        width: 'calc(200px + 8vw)'
      },
      externalizeItem: {
        ...buttons.clickable,
        borderTop: `1px solid ${color.gray.f}`,
        padding: '15px 10px',
        textAlign: 'center',
        backgroundColor: color.systemBackground.light.tertiary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
        },
      }
    }
  }

  @computed()
  get sharingWithHTML () {
    const { jss } = this
    return (
      <div class={jss.sharingList}>
        {this.model.sharing.visibleSharingRights.map(s => this.sharingHTML(s))}
      </div>
    )
  }

  sharingHTML (share: SharingRight) {
    const { jss } = this
    const permitNames = share.permits().map(s => s.name).join(', ')
    const removeClick = () =>
      this.model.sharing.sharingRights.remove(share)
    return (
      <div class={jss.sharing}>
        <div class={jss.sharingEmail}>{share.email}</div>
        <div class={jss.sharingAllow}>{permitNames}</div>
        <div class={jss.sharingRemove}
          ko-click={removeClick}>{icons.inline(removeIcon)}</div>
        <div class={jss.sharingExpires} ko-visible={this.computed(() => share.expires)}>
          <span class={jss.expiresLabel}>Expires:</span>
          <span class={jss.expiresDate}>{share.expires}</span>
        </div>
      </div>
    )
  }

  static get sharingWithCSS () {
    return {
      sharingList: {
        borderTop: `1px solid ${color.separator.light.opaque}`,
        'body[dark] &': { // project batman
          borderTop: `1px solid ${color.separator.dark.opaque}`,
        },
        backgroundColor: 'white',
        '&:last-child': {
          borderBottom: `1px solid ${color.separator.light.opaque}`,
          'body[dark] &': { // project batman
            borderBottom: `1px solid ${color.separator.dark.opaque}`,
          },
        }
      },
      addShareButton: {

      },
      sharing: {
        display: 'grid',
        gridTemplateAreas: `
          'email  remove'
          'allow  allow'
          'expires  .'
        `,
        gridTemplateColumns: '1fr auto auto',
        borderTop: `1px solid ${color.separator.light.opaque}`,
        backgroundColor: color.systemGroupBackground.light.tertiary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          borderTop: `1px solid ${color.separator.dark.opaque}`,
        },
        padding: '10px',
        '&:first-child': {
          borderTop: 'none'
        }
      },
      sharingEmail: {
        gridArea: 'email'
      },
      sharingRemove: {
        ...buttons.clickable,
        position: 'relative',
        top: '-6px',
        gridArea: 'remove',
        opacity: 0,
        transition: 'opacity 0.25s ease-in-out',
        '$sharing:hover &': {
          opacity: 1
        }
      },
      sharingAllow: {
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
          backgroundColor: color.fill.dark.secondary
        },
        gridArea: 'allow',
        fontSize: '0.7rem',
        marginTop: 6,
        textAlign: 'left',
        backgroundColor: color.fill.light.secondary,
        border: `1px dotted ${color.color.dark.blue}`,
        borderRadius: 3,
        padding: '5px 4px'
      },
      sharingExpires: {
        gridArea: 'expires',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '6px',
      },
      expiresLabel: {
        marginRight: '5px',
      },
      expiresDate: { position: 'static' },
    }
  }

  addShareClick () {
    this.panelProvider.addShare()
  }
}

MinuteBookRightViewSharing.register()
