import { omit } from 'lodash-es'

import DataComponent from './DataComponent'
import { makePersonRecord } from 'person'

type CrudModel = import('../CrudModel').default

export default class PersonComponent extends DataComponent {
  static get namespace () { return 'person' }
  record: KnockoutObservable<PersonRecord> = this.record

  init () {
    this.record = ko.observable()
    this.set({})
  }

  set (v: Partial<PersonRecord>) {
    this.record(makePersonRecord(v, { origin: [this.origin] }))
  }

  get () { return this.record }
  toJS () {
    return omit(this.record(), 'origin')
  }

  get personName () {
    const record = this.record()
    if (!record) { return '' }
    return record.name.slice(0, 1).pop() || ''
  }

  get origin () : PersonOrigin {
    const { record } = this
    const { title } = this.properties
    return {
      update (op) { op(record()); record.valueHasMutated() },
      model: this.model as CrudModel,
      * roles () { yield { title } }
    }
  }

  * getPersons (filter: PersonFilter) : IterableIterator<PersonRecord> {
    yield * [this.record()].filter(filter)
  }

  isBlank () : boolean {
    return Boolean(this.record()) &&
      Object.entries(this.record())
       .filter(([k,v]) => Array.isArray(v))
       .every(([k,v]) => !v.length)
  }
}

PersonComponent.register()
