
import { getUnixTime } from 'date-fns/esm'

import { columnsByPanelID } from 'Column/panelListColumns'

/**
 * Condition given rows (models) based on a set of operations on given
 * column.
 */
export default class Condition {
  constructor ({ columnID, operation, order, disjunct }, { panelID }) {
    const col = this.getColumnInstance(columnID, panelID())
    Object.assign(this, {
      panelID,
      column: ko.observable(col).extend({ rateLimit: 5 }),
      disjunct: ko.observable(disjunct), // true is —— or ——
      operation: ko.observable(this.getOperation(operation, col)),
      order: ko.observable(order)
    })
  }

  /**
   * Return the first Column instance that either matches or is filterable
   * @param {string} columnID
   * @param {string} panelID
   * @return {Column}
   */
  getColumnInstance (columnID, panelID) {
    if (!panelID) { return }
    const columns = columnsByPanelID[panelID]()
    return columns.find(c => columnID && c.columnID === columnID) ||
      columns.find(c => c.filterable)
  }

  /**
   * @param {object} params
   * @param {string} params.oparg
   * @param {string} params.opID
   * @param {string} params.title
   * @return {FilterOperation|undefined}
   */
  getOperation (params, col) {
    if (params) {
      const { oparg, opID } = params
      const opFn = col.filterOperations().find(fo => fo.opID === opID)
      if (opFn) {
        if (opFn.oparg) { opFn.oparg(oparg) }
        return opFn
      }
    }
    if (col) { return col.filterOperations()[0] }
  }

  setColumn (col) {
    this.column(col)
    this.operation(this.getOperation(null, col))
  }

  test (row) {
    const testFn = this.operation().testFn
    return !testFn || testFn(this.column().filterValue(row))
  }

  toJS () {
    return {
      ...this.asComparableJS,
      order: getUnixTime(new Date())
    }
  }

  get asComparableJS () {
    return {
      disjunct: this.disjunct() || false,
      columnID: this.column().columnID,
      operation: this.operation().toJS()
    }
  }

  /**
   * @return {Condition} clone of this
   */
  clone () {
    const panelID = this.panelID
    return new Condition(this.toJS(), { panelID })
  }

  /**
   * Convert an array of `filterDoc` snapshots into an array of Filters.
   * @param {object} filter
   * @param {Array.<Column>} possibleColumns
   * @return {Array.<Filter>}
   */
  static restore (filterObj, possibleColumns) {
    const col = possibleColumns.find(c =>
      c.columnID === filterObj.columnID)
    if (!col) {
      console.error(`Cannot filter by ${filterObj.columnID}; possible columns:`, possibleColumns.map(c => c.columnID))
    }
    const possibleFilterOps = col.makeFilterOperationFn()()

    const { title, oparg } = filterObj.operation
    const filterOp = possibleFilterOps.find(f => f.title === title)
    if (filterOp.opArg) { filterOp.oparg(oparg) }
    return new Condition(col, filterOp)
  }

  /**
   * @yield {Array.<Condition>} for every disjunction (—— or ——)
   */
  static * genConjunctiveConditions (conditions) {
    let cj = []
    for (const condition of conditions) {
      if (condition.disjunct() && cj.length) {
        yield cj
        cj = []
      }
      cj.push(condition)
    }
    yield cj
  }

  /**
   * @yield {function} that is true for a model when every condition is true
   */
  static * genConjunctions (conditions) {
    for (const conjConds of this.genConjunctiveConditions(conditions)) {
      yield model => conjConds.every(c => c.test(model))
    }
  }

  /**
   * @param {DataModel} model
   * @param {Array.<Condition>} conditions
   * @return {bool} true when the filters match
   */
  static test (model, conditions) {
    if (!conditions.length) { return true }
    return [...this.genConjunctions(conditions)].some(c => c(model))
  }
}
