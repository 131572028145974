//   ListComponent
//   ~~~~~~~~~~~~~
//
//   Like an array, but the content are primitives, that are not
//   converted to observables.
//
import { isEqual } from 'lodash-es'

import DataComponent from './DataComponent'

export default class ListComponent extends DataComponent {
  static get namespace () { return 'primitive' }

  init () {
    this.array = ko.observableArray()
    this.array.component_instance = this
  }

  set (map) {
    if (!map) {
      this.array([])
      return
    }

    if (!Array.isArray(map)) {
      eraro('ArrayComponent::set non-array value', map)
      return
    }

    this.array(map)
    return
  }

  get () { return this.array }
  toJS () { return this.array() }
  indexOf (e) { return this.array.indexOf(e) }
  getByIndex (i) { return this.array()[i] }
  is_blank () { return !this.array().some(v => v !== undefined) }
  is_equal (other) { return isEqual(this.array(), other) }

  validate (/* validator */) {
    if (this.array().length > 0) { return }
    return 'Expected at least one item.'
  }

}

//      Component
ListComponent.register()
