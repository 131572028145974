
/**
 * English is the default, "staple" langauge, that we use to
 * define all the other languages.
 *
 * There are currently three forms of i18n mapping:
 *
 * 1. A plain string e.g.
 *
 *    BOOLEAN_YES: 'yes'
 *
 *    Anyplace where `t.BOOLEAN_YES` is used, the string will
 *    be simply substituted.
 *
 * 2. A function e.g.
 *
 *    CAPITAL_SHARE_SPLIT: (classification, numerator, denominator)
 *      => `${classification} split ${numerator}:${denominator}`,
 *
 *    where `t.CAPITAL_SHARE_SPLIT('Class A', 1, 4)` will substitute
 *    into the string, accordingly, in this case producing:
 *
 *      `Class A split 1:4`
 *
 * 3. An object describing the plurality e.g.
 *
 *    CAPITAL_SHARE_ISSUE: {
 *      one: (amount, classification, person) =>
 *        `${amount} ${classification} share issued to ${person}`,
 *      two: (amount, classification, person) =>
 *        `${amount} ${classification} shares issued to ${person}`,
 *    },
 *
 *    Where the first parameter given to the function is the amount
 *    of the pluralization i.e.
 *      `t.CAPITAL_SHARE_ISSUE(25, 'Common', 'John')`
 *    becomes `25 Common shares to John`, and
 *      `t.CAPITAL_SHARE_ISSUE(1, 'Common', 'James')`
 *    becomes `1 Common share to James`
 *
 */
export default Object.freeze({
  BOOLEAN_YES: 'yes',
  BOOLEAN_NO: 'no',
  BOOLEAN_UNSET: 'not set',

  CAPITAL_ASSETS: `Capital Assets`,
  CAPITAL_AMENDED: (classification) => `${classification} share class amended`,
  CAPITAL_AUTHORIZED: (classification) => `${classification} share class authorized`,
  CAPITAL_DATE_AUTHORIZED: `Date Authorized`,
  CAPITAL_DEAUTHORIZED: (classification) => `${classification} share class deauthorized`,
  CAPITAL_HOLDER_NAME: 'Name',
  CAPITAL_MASS_CONVERSION: (classification) => `${classification} mass conversion`,
  CAPITAL_PREFIX: 'Prefix',
  CAPITAL_QUANTUM_AUTHORIZED: 'Quantum authorized',
  CAPITAL_SERIES: 'Series',
  CAPITAL_SHARE_CLASSIFICATION: 'Classification',
  CAPITAL_SHARE_SPLIT: (classification, numerator, denominator) => `${classification} split ${numerator}:${denominator}`,
  CAPITAL_SHAREHOLDERS: 'Capital Shareholders',
  CAPITAL_SHARES_HELD: 'Shares held',

  CAPITAL_INDIVIDUAL_CONVERSION:  {
    one: (amount, classification, from) =>
      `${amount} ${classification} share of ${from}`,
    two: (amount, classification, from) =>
      `${amount} ${classification} share of ${from}`,
  },

  CAPITAL_SHARE_ISSUE: {
    one: (amount, classification, person) =>
      `${amount} ${classification} share issued to ${person}`,
    two: (amount, classification, person) =>
      `${amount} ${classification} shares issued to ${person}`,
  },

  CAPITAL_SHARE_TRANSFER: {
    zero: (classification, amount, from, to) =>
      `Zero ${classification} shares transferred ${from} ${to}`,
    one: (classification, amount, from, to) =>
      `${amount} ${classification} share transferred ${from} ${to}`,
    two: (classification, amount, from, to) =>
      `${amount} ${classification} shares transferred ${from} ${to}`,
  },

  DATE_FORMAT: 'Date Format',

  INVALID_DATA: 'invalid data',

  /**
   * The DC_ * are automatically generated from DataComponent classes, with
   * the following naming convention:
   *
   *    DC _ [Component] _ [Unit View] _ [Property]
   *
   */
  DC_ARRAY_ADDRESSES_CA_ON_FORM1: 'Ontario, Canada Form 1',
  DC_ARRAY_ADDRESSES_FULLADDRESSASSTRING: 'Full Address',
  DC_ARRAY_ADDRESSES_GOOGLEPLACEID: 'Google Place ID',
  DC_ARRAY_ADDRESSES_PARTS: 'Parts',
  DC_ARRAY_ADDRESSES_PARTSCITY: 'City',
  DC_ARRAY_ADDRESSES_PARTSCOUNTRY: 'Country',
  DC_ARRAY_ADDRESSES_PARTSLINE1: 'Line 1',
  DC_ARRAY_ADDRESSES_PARTSLINE2: 'Line 2',
  DC_ARRAY_ADDRESSES_PARTSPOSTAL: 'Postal / Zip',
  DC_ARRAY_ADDRESSES_PARTSREGION: 'Region',
  DC_ARRAY_ADDRESSES_TITLE: 'Title',
  DC_ARRAY_ADDRESSES_USERPROVIDEDTEXT: 'Address Text',
  DC_ARRAY_ADDRESSES_XNAL: 'Address XNAL',

  DC_ARRAY_AUTHORIZEDTOINSTRUCT_PERSON: 'Person',

  DC_ARRAY_BUSINESSNUMBERS_NAME: 'Name',
  DC_ARRAY_BUSINESSNUMBERS_NUMBER: 'Number',

  DC_ARRAY_DIRECTORS_ELECTED: 'Elected',
  DC_ARRAY_DIRECTORS_PERSON: 'Person',
  DC_ARRAY_DIRECTORS_RESIGNED: 'Resigned',

  DC_ARRAY_DOMAINNAMES_DOMAIN: 'Domain',
  DC_ARRAY_DOMAINNAMES_EXPIRY: 'Expiry',
  DC_ARRAY_DOMAINNAMES_REGISTRAR: 'Registrar',

  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_AGENT: 'Agent',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_DEREGISTERED: 'Deregistered',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_ENTITY_NAME: 'Entity Name',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_JURISDICTION: 'Jurisdiction',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_REGISTERED: 'Registered',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_REGISTRATION_NUMBER: 'Registration Number',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_REVOKED: 'Revoked',
  DC_ARRAY_EXTRAJURISDICTIONALREGISTRATIONS_TYPE: 'Type',

  DC_ARRAY_GOVERNINGDOCUMENTS_END: 'Expired',
  DC_ARRAY_GOVERNINGDOCUMENTS_START: 'In-force',
  DC_ARRAY_GOVERNINGDOCUMENTS_TITLE: 'Title',

  DC_ARRAY_IMPORTS_DATETIME: 'Date & Time',
  DC_ARRAY_IMPORTS_FILEHASH: 'Hash',
  DC_ARRAY_IMPORTS_FILENAME: 'Filename',
  DC_ARRAY_IMPORTS_IMPORTEDBY: 'Imported By',
  DC_ARRAY_IMPORTS_ORIGIN: 'Origin',

  DC_ARRAY_INCORPORATORS_PERSON: 'Person',

  DC_ARRAY_LANDREGISTRATIONS_ACQUIRED: 'Acquired',
  DC_ARRAY_LANDREGISTRATIONS_DISPOSED: 'Disposed',
  DC_ARRAY_LANDREGISTRATIONS_LEGAL_DESCRIPTION: 'Legal Description',
  DC_ARRAY_LANDREGISTRATIONS_MUNICIPAL_ADDRESS: 'Municipal Address',
  DC_ARRAY_LANDREGISTRATIONS_PIN: 'PIN',
  DC_ARRAY_LANDREGISTRATIONS_REGISTRAR: 'Registrar',
  DC_ARRAY_LANDREGISTRATIONS_ROLL_NUMBER: 'Roll Number',

  DC_ARRAY_MUNICIPALBUSINESSLICENSES_DESCRIPTION: 'Description',
  DC_ARRAY_MUNICIPALBUSINESSLICENSES_EXPIRY: 'Expiry',
  DC_ARRAY_MUNICIPALBUSINESSLICENSES_MUNICIPALITY: 'Municipality',
  DC_ARRAY_MUNICIPALBUSINESSLICENSES_REGISTERED: 'Registered',

  DC_ARRAY_OFFICERS_ELECTED: 'Elected',
  DC_ARRAY_OFFICERS_PERSON: 'Person',
  DC_ARRAY_OFFICERS_RESIGNED: 'Resigned',
  DC_ARRAY_OFFICERS_TITLE: 'Title',

  DC_ARRAY_PATENTS_AGENT: 'Agent',
  DC_ARRAY_PATENTS_DESCRIPTION: 'Description',
  DC_ARRAY_PATENTS_EXPIRY: 'Expiry',
  DC_ARRAY_PATENTS_JURISDICTION: 'Jurisdiction',
  DC_ARRAY_PATENTS_NUMBER: 'Number',
  DC_ARRAY_PATENTS_STATUS: 'Status',

  DC_ARRAY_PPSAS_AGENT: 'Agent',
  DC_ARRAY_PPSAS_CREDITOR: 'Creditor',
  DC_ARRAY_PPSAS_DEBTOR: 'Debtor',
  DC_ARRAY_PPSAS_DESCRIPTION: 'Description',
  DC_ARRAY_PPSAS_EXPIRY: 'Expiry',
  DC_ARRAY_PPSAS_JURISDICTION: 'Jurisdiction',
  DC_ARRAY_PPSAS_NOTE: 'Note',
  DC_ARRAY_PPSAS_TYPE: 'Type',
  DC_ARRAY_PPSAS_STATUS: 'Status',

  DC_ARRAY_REGULATORYLICENSES_DESCRIPTION: 'Description',
  DC_ARRAY_REGULATORYLICENSES_EXPIRY: 'Expiry',
  DC_ARRAY_REGULATORYLICENSES_REGISTERED: 'Registered',
  DC_ARRAY_REGULATORYLICENSES_RENEWAL: 'Rewewal',

  DC_ARRAY_RESPONSIBLE_END: 'End',
  DC_ARRAY_RESPONSIBLE_PERSON: 'Person',
  DC_ARRAY_RESPONSIBLE_START: 'Start',

  DC_ARRAY_SERVICEPROVIDERS_CONTACT: 'Contact',
  DC_ARRAY_SERVICEPROVIDERS_NAME: 'Name',
  DC_ARRAY_SERVICEPROVIDERS_PROVIDES: 'Provides',

  DC_ARRAY_TRADEMARKS_AGENT: 'Agent',
  DC_ARRAY_TRADEMARKS_DESCRIPTION: 'Description',
  DC_ARRAY_TRADEMARKS_EXPIRY: 'Expiry',
  DC_ARRAY_TRADEMARKS_JURISDICTION: 'Jurisdiction',
  DC_ARRAY_TRADEMARKS_RENEWAL: 'Renewal',

  DC_ARRAY_TRADENAMES_AGENT: 'Agent',
  DC_ARRAY_TRADENAMES_EXPIRY: 'Expiry',
  DC_ARRAY_TRADENAMES_IDENTIFIER: 'Identifier',
  DC_ARRAY_TRADENAMES_JURISDICTION: 'Jurisdiction',
  DC_ARRAY_TRADENAMES_NAME: 'Name',
  DC_ARRAY_TRADENAMES_REGISTERED: 'Registered',
  DC_ARRAY_TRADENAMES_TYPE: 'Type',
  DC_ARRAY_TRADEMARKS_STATUS: 'Status',

  PERSON_EMAIL: 'Email',
  PERSON_NAME: 'Name',
  PERSON_OTHER_POSITIONS_HELD: 'Other Positions Held',
  PERSON_PHONE: 'Phone',
  PERSON_RESIDENCY: 'Residency',

  PERSON_RELATED_AGENT: 'Agent Information',
  PERSON_RELATED_CONTACT: 'Contact Person',


  TEXT_TRANSFORM_UPPERCASE: 'Uppercase',
  TEXT_TRANSFORM_LOWERCASE: 'Lowercase',
  TEXT_TRANSFORM_CAPITALIZE: 'Capitalize',

  VARIABLE_HAS_NO_VALUE: 'Not set',
})
