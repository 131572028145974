
import localForage from 'localforage'

interface LocalCacheable {
  localStorageCacheName: string
}

export class LocalImageCache {
  private cache: Record<string, string|Blob> = {}
  private loaded: KnockoutObservable<boolean> = ko.observable(false)
  private urlsToDispose: string[] = []
  private db: LocalForage

  constructor (store: LocalCacheable, key: string) {
    Object.assign(this, {
      db: localForage.createInstance({
        storeName: store.localStorageCacheName + '::' + key,
        name: 'mbLocalStorage',
        driver: localForage.INDEXEDDB,
      })
    })

    this.db.setItem('access', new Date())
    this.load()
  }

  dispose () {
    this.urlsToDispose.forEach(URL.revokeObjectURL)
  }

  async load () {
    const keys = await this.db.keys()
    const pairs = await Promise.all(keys.map(
      k => this.db.getItem(k).then(blob => [k, blob]))
    )
    this.cache = Object.fromEntries(pairs)
    this.loaded(true)
  }

  _cachePromises: Record<string, Promise<ImageURL>> = {}
  async getOrAdd (path: string, create: () => Promise<ImageURL>) {
    return this._cachePromises[path] || (this._cachePromises[path] =
      new Promise(async resolve => {
        await this.loaded.yet(false)
        if (path in this.cache) {
          const url = URL.createObjectURL(this.cache[path])
          this.urlsToDispose.push(url)
          resolve(url)
        } else {
          const blobUrl = await create()
          if (blobUrl) { this.addToCache(path, blobUrl) }
          resolve(blobUrl)
        }
      })
    )
  }

  private async addToCache (path, blobUrl) {
    fetch(blobUrl)
      .then(r => r.blob())
      .then(async b => {
        this.cache[path] = b
        this.db.setItem(path, b)
      })
      .catch(console.error)
  }

  // prune (keys) {}
}
