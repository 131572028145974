
import { buttons, dropdown, color } from 'styles'
import CommandSet from 'Command/CommandSet'

import helpIcon from 'icons/icon-help'

import icons from 'icons'
import adminIcon from 'icons/light/cog'
import signOutIcon from 'icons/light/sign-out'
import emailIcon from 'icons/light/envelope'
import phoneIcon from 'icons/light/phone'
import chatIcon from 'icons/light/comment'
import kbIcon from 'icons/light/book-open'
import moreIcon from 'icons/light/ellipsis-h'

import 'otp-generate'

import KeyboardShortcutManager from './KeyboardShortcutManager'

const { jssLib } = window

/**
 * Easy debugging
 */
if (location.hostname === 'localhost') {
  console.log(`💁‍♂️ alias "window.pp" = app.panelProvider()`)
  Object.defineProperty(window, 'pp', { get: () => window.app.panelProvider() })
}

/**
 * The PanelProvider is a base class that indicates what the content
 * of the main app panels ought to be.
 */
export default abstract class PanelProvider extends ko.LifeCycle {
  isActivePanelProvider: KnockoutObservable<boolean>

  app: import('minute-box-app').default
  get panelWindowTitle () { return `` }
  abstract get panelID () : string
  get peerPanelList (): PanelProvider[] { return [] }

  /**
   * @return {string|null} the url for the panel provider; null if this
   * panel provider should not appear in the history.
   */
  abstract get historyUrl (): MaybePromise<string>

  commandSet: CommandSet | null
  get CommandSetConstructor () : { new (p: PanelProvider): CommandSet }

  constructor ({ app }) {
    super()
    const CommandSet = this.CommandSetConstructor
    Object.assign(this, {
      app,
      activeModalClass: ko.observable(),
      // panelDragOverEvent: the ongoing DragEvent instance of a drag-over.
      panelDragOverEvent: ko.observable(false),
      shortcutManager: new KeyboardShortcutManager(),
      commandSet: CommandSet ? new CommandSet(this) : null
    })

    this.isActivePanelProvider = this.computed(() =>
      window.app.panelProvider() === this
    )
  }

  get scrollKey () : string { return this.panelID }

  static get css () {
    return {
      ...this.headMenuItemCSS,
      ...this.dialogCSS,
      head: {
        padding: '20px 20px 20px 20px',
        background: 'rgba(255,255,255,1)',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.dmgrey.dmtext,
        },
      },

      headActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '36px',
      },

      headActionsMenus: {
        display: 'flex',
        position: 'sticky',
        right: '20px',
      },

      headLogo: {
        ...buttons.clickable,
        position: 'sticky',
        left: '80px',
        background: 'center left no-repeat url("/minute-box-logo.svg")',
        minHeight: '42px',
        minWidth: '200px',
        backgroundSize: '80%'
      },

      headMenu: {
        ...dropdown.generalMenu,
        whiteSpace: 'nowrap',
        fontSize: '15px'
      },

      headButton: {
        ...buttons.clickable,
        padding: '0px 6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '--icon-color': color.systemGrey.light.three,
        'body[dark] &': { // project batman
          '--icon-color': color.systemGrey.dark.three
        },
        '&:hover': {
          '--icon-color': color.systemGrey.light.two,
          'body[dark] &': { // project batman
            '--icon-color': color.systemGrey.dark.two
          },
        },
        '& svg': {
          minHeight: '40px'
        }
      },

      topFixed: {
        position: 'sticky',
        left: 0,
        width: 'calc(100vw - 15px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(255,255,255,1)',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.dmgrey.dmtext,
        },
      },

      topYellowBorder: {
        gridArea: 'ty',
        position: 'sticky',
        top: '0px',
        zIndex: '7',
        height: '5px',
        backgroundColor: color.primary
      },

      appGrid: this.appGridClassCSS
    }
  }

  static get appGridClassCSS () {
    /**
     * NOTE: The CSS vars here apply only in `<minute-box-app>`, and not
     * to items that are injected as DOM siblings to the app, such as
     * overlays, tooltips, or (most) dropdowns.
     */
    return {
      '--panel-border': '1px solid #bababa',
      '--sticky-offset': '57px',
      '--side-panel-width': '289px',
      '--footer-height': '75px',
      '--yellow-bar-height': '4px',
      '--head-height': '84px', // read-only
      '--top-height': '57px', // read-only
      '--left-panel-default-width': '270px',

      // read-only; the main-height can be used to contain
      // scrollable items (e.g. the events list) to the
      // window height, with its own scroll-bar
      '--main-height': 'calc(100vh - var(--footer-height) - var(--head-height) - var(--top-height) - var(--yellow-bar-height))',

      '--drop-pad-border-color': '#4A90E2',
      '--drop-pad-background-color': 'hsl(0, 0%, 90%)',
      '--drop-pad-hover-background-color': 'hsl(0, 0%, 85%)',
      '--drop-pad-text-color': '#a5a5a5',

      display: 'grid',
      minHeight: '100vh',
      grid: {
        gap: '0px',
        templateColumns: 'auto 1fr auto',
        autoRows: 'auto',
        templateAreas:
          `
          'ty      ty      ty    '
          'head    head    head  '
          'top     top     top   '
          'left    main    right '
          'foot    foot    foot  '
          `
      },

      // blur under the dragPad ** BROKEN
      '& > [visible] ~ *': {
        filter: 'blur(2px)'
      }
    }
  }

  logoClick () {
    if (!this.app.rootProviders) { return }
    this.app.panelProvider(this.app.rootProviders.entityList)
  }

  get head () {
    const { jss } = this
    return (
      <div class={jss.head}>
        <div class={jss.headActions}>{this.headActions}</div>
      </div>
    )
  }

  get headHelpMenuDropdownContentHTML () {
    const { jss } = this
    const onClick = dialog => this.app.modal(dialog)
    return (
      <div class={jss.headMenu}>
        <div class={jss.headMenuItemEmail}
          ko-click={() => onClick(this.emailDialog)}>
          {icons.button(emailIcon)}
          Email support
        </div>
        <div class={jss.headMenuItemChat}
          ko-click={() => onClick(this.chatDialog)}>
          {icons.button(chatIcon)}
          Chat support
        </div>
        <div class={jss.headMenuItemPhone}
          ko-click={() => onClick(this.phoneDialog)}>
          {icons.button(phoneIcon)}
          Phone support
        </div>
        <div class={jss.headMenuItemKnol}
          ko-click={() => onClick(this.kbDialog)}>
          {icons.button(kbIcon)}
          Knowledge base
        </div>
        <div class={jss.headMenuItemAbout}
          ko-click={() => onClick(this.aboutDialog)}>
          About MinuteBox
        </div>
      </div>
    )
  }

  static get dialogCSS () {
    return {
      _contactDialog: {
        minWidth: '400px'
      },
      emailDialog: {
        extend: '_contactDialog',
      },
      chatDialog: {
        extend: '_contactDialog',
      },
      phoneDialog: {
        extend: '_contactDialog',
      },
      kbDialog: {
        extend: '_contactDialog',
      },
      helpDialog: {},
      helpButtons: {
        gridColumn: '1/-1',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10px'
      },
      helpCancelButton: {
        ...buttons.clean,
        minWidth: '90px',
        border: `1px solid ${color.onPrimary}`,
        backgroundColor: 'white'
      },
      helpActionButton: {
        ...buttons.clean,
        minWidth: '90px',
        color: 'white',
        backgroundColor: color.onPrimary,
        '&:hover': {
          boxShadow: '0px 0px 12px 2px rgba(0,0,0,0.2)'
        }
      }
    }
  }

  _helpDialog (bodyJsx, title, actionJsx) {
    const { jss } = this
    const cancelClick = () => this.app.modal(undefined)
    return (
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <div class={jss.helpDialog}>
            {bodyJsx}
            <div class={jss.helpButtons}>
              <button class={jss.helpCancelButton}
                ko-click={() => cancelClick()}>
                Cancel
              </button>
              {actionJsx}
            </div>
          </div>
        </template>
      </modal-dialog>
    )
  }

  get emailDialog () {
    const { jss } = this
    const jsx = (
      <div class={jss.emailDialog}>
        <p>You can contact MinuteBox support at anytime by emailing <a href='mailto:support@minutebox.com'>support@minutebox.com</a></p>
        <p> or by contacting us on our support page by clicking the Contact Support button below.</p>
      </div>
    )
    const action = (
      <a class={jss.helpActionButton} href='https://www.minutebox.com/contact' target="_blank">Contact Support</a>
    )
    return this._helpDialog(jsx, 'Contact MinuteBox Support', action)
  }
// need the Get Started button foir this dialog to link an anchor tag for now, just do <a href="#">Get Started</a>

  get chatDialog () {
    const { jss } = this
    const jsx = (
      <div class={jss.chatDialog}>
        <p>Need to chat with MinuteBox? We're here to help.</p><p>MinuteBox chat support is available Monday to Friday from 8am to 8pm.</p>
        <p>Don't forget – after the chat page loads, please click the small chat icon in the bottom right corner of the page.</p>
      </div>
    )
    const action = (
      <a class={jss.helpActionButton} href='https://www.minutebox.com/contact' target="_blank">Chat Now</a>
    )
    return this._helpDialog(jsx, 'Chat with MinuteBox Support', action)
  }
// need the Get Started button foir this dialog to link an anchor tag for now, just do <a href="#">Get Started</a>

  get phoneDialog () {
    const { jss } = this
    const jsx = (
      <div class={jss.phoneDialog}>
        <p>MinuteBox phone support can be reached at 1-833-4-MNTBOX</p>
        <p>1-833-466-8269</p>
      </div>
    )
    const action = (
      <a class={jss.helpActionButton} >OK</a>
    )
    return this._helpDialog(jsx, 'Call MinuteBox Support', action)
  }


  get kbDialog () {
    const { jss } = this
    const jsx = (
      <div class={jss.kbDialog}>
        <p>The MinuteBox Knowledge Base is the very best resource for all your support needs.</p>
        <p>It's loaded with tutorials, FAQs and How-To Guides.</p>
      </div>
    )
    const action = (
      <a class={jss.helpActionButton} href='https://help.minutebox.com' target='_blank'>Get Started</a>
    )
    return this._helpDialog(jsx, 'Access our Knowledge Base', action)
  }
  // need the Get Started button foir this dialog to link an anchor tag for now, just do <a href="#">Get Started</a>

  get aboutDialog () {
    const authManager = this.app.defaultAuthManager

    return (
      <modal-dialog modalTitle='About MinuteBox'>
        <template slot='content'>
          <about-minutebox authManager={authManager} />
        </template>
      </modal-dialog>
    )
  }

  static get headMenuItemCSS () {
    return {
      headMenuItem: {
        ...dropdown.item,
        display: 'block',
        color: color.text.light.primary,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.primary
        },
        '& svg': {
          marginLeft: '-10px',
          marginRight: '10px'
        }
      },
      headMenuItemEmail: {
        extend: 'headMenuItem',
      },
      headMenuItemChat: {
        extend: 'headMenuItem',
      },
      headMenuItemPhone: {
        extend: 'headMenuItem',
      },
      headMenuItemKnol: {
        extend: 'headMenuItem',
      },
      headMenuItemAbout: {
        extend: 'headMenuItem',
      },
      headMenuItemSettings: {
        extend: 'headMenuItem',
      },
      headMenuItemSignOut: {
        extend: 'headMenuItem',
        //fill: 'red'
      }
    }
  }

  get headUserMenuDropdownContentHTML () {
    const { jss } = this
    const isDemo = this.app.defaultAuthManager.accountID() === 'demo'
    const resetDemoButton = isDemo
      ? <async-button faceClass={jss.headMenuItem} action={async () => global.demoData.reset()}>
        <template slot='face'>
          Reset Demo Data
        </template>
      </async-button>
      : ''

    return (
      <div class={jss.headMenu}>
        <div class={jss.headMenuItemSettings} ko-click={() => this.adminClick()}>
          {icons.button(adminIcon)}
          Settings
        </div>
        {/* <div class={jss.headMenuItem} ko-click={() => this.settingsClick()}>
          {icons.button(settingsIcon)}
          Settings
        </div> */}
        <div class={jss.headMenuItemSignOut} ko-click={() => this.signOutClick()}>
          {icons.button(signOutIcon)}
          Sign Out
        </div>
        {resetDemoButton}
      </div>
    )
  }

  get headActions () {
    const { jss } = this

    return [
      <div class={jss.headLogo} ko-click={() => this.logoClick()} />,

      <div class={jss.headActionsMenus}>
        <drop-down my='top right' at='bottom right'>
          <template slot='anchor'>
            <div class={jss.headButton}> {helpIcon} </div>
          </template>
          <template slot='content'>
            {this.headHelpMenuDropdownContentHTML}
          </template>
        </drop-down>
        <drop-down my='top right' at='bottom right'>
          <template slot='anchor'>
            <div class={jss.headButton}> {icons.inline(moreIcon)} </div>
          </template>
          <template slot='content'>
            {this.headUserMenuDropdownContentHTML}
          </template>
        </drop-down>
      </div>
    ]
  }

  adminClick () {
    const userPanelProvider = this.app.rootProviders.users
    this.app.panelProvider(userPanelProvider)
  }

  settingsClick () {
    const currentUser = this.app.defaultAuthManager.firestoreUser
    this.app.modal(
      <modal-dialog modalTitle='OtpGenerate'>
        <template slot='content'>
          <otp-generate app={this.app} user={currentUser} />
        </template>
      </modal-dialog>
    )
  }

  show () {
    this.app.panelProvider(this)
  }

  signOutClick () {
    this.app.signOut()
  }

  get topYellowBar () { return <div class={this.jss.topYellowBorder} /> }
  get top () { return <div class={this.jss.topFixed}>{this.topFixed}</div> }
  get main () { return null }
  get left () { return null }
  get right () { return null }
  get foot () { return null }
  get footLeft () { return <div /> }
  get footRight () { return <div /> }
  // The overlay shows a fixed block over all but the header e.g. a drag/drop-pad.
  get overlay () { return null }

  get leftTitle () { return null }
  get rightTitle () { return null }

  /**
   * Fixed position top-bar
   */
  get topFixed () { return null }

  /**
   * Global event handlers.  By convention, every method of
   * PanelProvider that starts with `on` e.g. `onDrag`,
   * `onKeyDown`, ...
   */
  static get globalEventHandlers () {
    return Object.getOwnPropertyNames(this.prototype)
      .filter(name => name.startsWith('on'))
  }

  /** Note that for drag-and-drop events, the HTML attribute
   * where `draggable="true"` must be present, it must be
   * in that *exact* form.
   */
  onDragEnd (evt, app) {
    this._isOver = false
    this.panelDragOverEvent(false)
  }

  onDragEnter (evt, app) {
    const fileTypes = ['application/x-moz-file', 'Files']
    if (fileTypes.some(ft => evt.dataTransfer.types.includes(ft))) {
      this._isOver = true
      this.panelDragOverEvent(evt)
    }
  }

  onDragLeave (evt, app) {
    this._isOver = false
    clearTimeout(this._dragTimeout)
    this._dragTimeout = setTimeout(
      // FIXME: This occassionaly doesn't work in chrome causing the drop pad to flicker
      () => this._isOver || this.panelDragOverEvent(false), 50)
  }

  onDragOver (evt, app) {
    evt.preventDefault()
    this._isOver = true
  }

  onDrop (evt, app) {
    evt.preventDefault()
    evt.stopPropagation()
    this._isOver = false
    this.panelDragOverEvent(false)
  }

  /**
   * Default keyboard shortcut handler.
   * @param {KeyboardEvent} evt
   */
  onKeydown (evt, app) {
    if (this.app.modal()) {
      const modalClass = this.activeModalClass()
      if (modalClass) { modalClass.shortcutManager.onKeydown(evt) }
      return
    }
    if (!this.handlingKeydown(evt.key)) { return }
    this.shortcutManager.onKeydown(evt)
  }

  /**
   * @return {bool} true when the panel provider should be handling the
   * keydown event.
   *
   * We check if the `activeElement` is `document.body` to avoid
   * intercepting keydown events in `<input>`, `<textarea>`,
   * `[contenteditable]`, and other input/ux areas.
   */
  handlingKeydown (key) {
    return document.activeElement === document.body || key === 'Escape'
  }

  onKeyUp (evt, app) { }

  setupKeyboardShortcuts (commandSet = this.commandSet) {
    if (this._keyboardShortcuts) { return }
    if (!commandSet) { return }
    for (const args of commandSet.keyboardShortcuts) {
      this.shortcutManager.add(args)
    }
    this._keyboardShortcuts = true
  }

  dispose () {
    jssLib.removeStyleSheet(this.styles)
    this._styles = null
  }

  get styles () {
    const options = {
      classNamePrefix: `${this.constructor.name}__`,
      meta: `🖼  Panel Provider ${this.constructor.name}`,
      // link: true
    }
    return this._styles || (
      this._styles = jssLib.createStyleSheet({
        ...this.constructor.css,
        ...this.css
      }, options).attach()
    )
  }

  get jss () {
    return this.styles.classes
  }
}
