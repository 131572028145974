import { TradeName } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisTradeNames extends EisArrayComponentTable<TradeName> {
  get caption () { return 'Business Names' }
  get componentName () { return 'trade_names' }
  get listAddName () { return 'Business Name' }
  get columns () {
    return [
      this._jurisdictionColumn(),
      'name',
      'type',
      'identifier',
      'registered',
      'expiry',
      'agent',
    ]
  }
}

EisTradeNames.register()
