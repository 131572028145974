import { result } from 'utils/function'

interface CommandParameters {
  action: () => void,
  filter? : (Event) => boolean,
  help?: JSX,
  helpGroup?: string,
  helpIndex?: number
  hideIf?: () => boolean,
  title: string,
}

/**
 * UserAction is an Interface for producing actions that may be performed
 * across the system from multiple input points (keyboard, mouse).
 */
export default class Command {
  /**
   * @param {string} title
   * @param {JSX} help
   * @param {string} helpGroup
   * @param {function} action called on `::trigger`
   */
  constructor ({ title, help, helpGroup, action, filter, hideIf, helpIndex }: CommandParameters) {
    Object.assign(this, {
      _title: title,
      _help: help,
      _helpGroup: helpGroup || '',
      _action: action,
      _filter: filter,
      _hideIf: hideIf,
      _helpIndex: helpIndex,
      _shortcutKeys: [],
    })
  }

  get title () { return this._title }
  get help () { return this._help }
  get helpGroup () { return this._helpGroup }
  get helpIndex () { return this._helpIndex }

  /**
   * When `isHidden` is true this command is not displayed to the user, and
   * its `action` is not called even if it is triggered.
   */
  get isHidden () { return result(this._hideIf) }

  /**
   * The `action` is the function called when the command is triggered.
   */
  action (evt) { return this._action(evt) }

  /**
   * If a Command has a `filter` and that filter is false, the `action`
   * is never called.
   */
  filter (evt) { return !this.isHidden && (!this._filter || this._filter(evt)) }

  async click (evt) {
    evt.stopPropagation()
    evt.preventDefault()
    return this.trigger(evt)
  }

  /**
   * Trigger the event
   */
  async trigger (evt?: Event) {
    if (this.filter(evt)) { return this.action(evt) }
  }

  get shortcutKeys () {
    return this._shortcutKeys
  }

  /**
   * @param {string} key
   * @param {object} options
   * @param {bool} options.cmd true when Meta/Ctrl combo
   * @param {bool} options.propagate do not stopPropagation
   * @param {bool} options.performDefault do not preventDefault
   * @return {<object>} of params that match the `add` function of
   * `KeyboardShortcutManager`.
   */
  * keyboardShortcuts (key, options = {}) {
    this._shortcutKeys.push({ key, options })

    if (options.cmd) {
      yield * this.keyboardShortcuts(key, { meta: true })
      yield * this.keyboardShortcuts(key, { ctrl: true })
    } else {
      const { ctrl, meta, shift, alt, performDefault, propagate } = options
      const keyboardShortcut = {
        key,
        propagate,
        performDefault,
        command: this,
        modifiers: { ctrl, meta, shift, alt },
        filter: evt => this.filter(evt),
        action: evt => this.trigger(evt),
      }
      yield keyboardShortcut
    }
  }
}
