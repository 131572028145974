/**
 * Convert Uint8Array to/from Firestore Blobs
 */
import { cloneDeepWith } from 'lodash-es'

import ObjectProxy from './ObjectProxy'

export default class FirestoreBlobProxy extends ObjectProxy {
  static get proxyPropertyName () { return 'FSB' }

  /**
   * @param {Uint8Array | Object} src
   * @return {firebase.firestore.Blob | Object}
   */
  wrapValue (src) {
    return cloneDeepWith(src, v => v instanceof Uint8Array
      ? firebase.firestore.Blob.fromUint8Array(v)
      : undefined)
  }

  /**
   * @param {firebase.firestore.Blob | Object} src
   * @return {Uint8Array | Object}
   */
  static unwrapValue (src) {
    return cloneDeepWith(src, v => v instanceof firebase.firestore.Blob
      ? v.toUint8Array()
      : undefined)
  }
}
