
import { typography } from 'styles'
import { Address } from "DataModel/components/AddressesComponent"
import EisField from "./EisField";

import { ADDRESS_TITLES } from 'entity-information-summary/constants'
import { EntityModel } from 'EntityModel'

const { ko } = global

type Observable<T> = (arg0?:T) => T

export class EisAddress extends EisField {
  address: Address
  deleteFn: () => void
  title: Observable<string>
  parts: any
  _saving: Boolean = false

  constructor (params) {
    super( {...params, skipNavRegistration:true} )
    const { address, deleteFn, registerNavigation } = params.addressControl

    Object.assign(this, {
      address,
      deleteFn,
      title: ko.observable(),
      parts: {
        line1: ko.observable(),
        line2: ko.observable(),
        city: ko.observable(),
        region: ko.observable(),
        postal: ko.observable(),
        country: ko.observable(),
      }
    })

    this.inputValue(this.componentValue())

    this.componentValue.subscribe(v => {
      if (!this.editing()) { this.inputValue(v) }
    })

    if (this.isBlank()) {
      const cf = this.navigation.currentFocus()
      if (!cf || !cf.editing()) {
        setTimeout(() => this.editing(true), 50)
      }
    }

    registerNavigation(this)

    this.subscribe(this.editing, e => e || this.onCloseEdit())
  }

  get inputValue () {
    return ko.pureComputed({
      read: () => ({
        title: this.title(),
        parts:  Object.assign({},
                  ...Object.entries(this.parts)
                    .map(([k,v]:[string, Observable<string>]) =>
                      ({[k]:v()}))),
      }),
      write: ({title, parts}) => {
        this.title(title)
        for (const k in parts) {
          if (k in this.parts) { this.parts[k](parts[k]) }
        }
      },
    })
  }

  get componentValue () {
    return ko.pureComputed({
      read: () => ({
        title: this.address.title(),
        parts: this.address.parts(),
      }),
      write: v => {
        this.address.title(v.title)
        this.address.parts(v.parts)
      },
    })
  }

  static get css () {
    return {
      ...super.css,
      ...this.editCSS,
      ...this.displayCSS,
      showEdit: {
        height: 'auto',
        transition: 'height 0.2s ease-out',
      },
      title: {
        ...super.css.title,
        '[blank] &': {
          marginTop: ''
        },
      }
    }
  }

  onStartEdit () {
    this.inputValue(this.componentValue())
  }

  get hasChanges () { return false }

  get isBlank () {
    return this.computed(() => {
      let value = this.componentValue()
      value = value && value.parts
      return !value || !Object.entries(value).some(([_,v]) => v)
    })
  }

  get titleAreaHTML () { return '' }

  onSave () { this.componentValue(this.inputValue()) }

  async asyncSave () {
    this._saving = true
    await Promise.delay(450)
    this.editing(false)
    await Promise.delay(300)
    this.onSave()
    this._saving = false
    this.onCloseEdit()
  }

  onCloseEdit () {
    setTimeout(() => this._saving || !this.isBlank() || this.deleteFn(), 300)
  }

  onDiscard () {
    this.inputValue(this.componentValue())
  }

  get editGridItems () {
    const { jss } = this
    const deleteFn = () => {
      this.componentValue({})
      this.triggerDiscard()
    }
    return [
      <div class={jss.deleteButtonGridItem}>
        <async-button faceClass={jss.deleteButton} action={deleteFn}>
          <template slot='face'>Delete</template>
        </async-button>
      </div>
    ]
  }

  getPartsTitleText (part) {
    return ({
      line1: 'Street Address',
      line2: 'Street Address Line 2',
      city: 'City',
      region: 'Region',
      postal: 'Postal Code',
      country: 'Country',
    }[part])
  }

  static get displayCSS () {
    return {
      partsDisplay: {
        display: 'flex',
      },
      partsBox: {
        ...super.css.textContent,
        '& div': {
          fontSize: '1.4rem',
        },
      },
    }
  }

  get copyText () {
    const parts = this.componentValue().parts
    if (!parts) { return '' }
    const [ city, region ] = [ parts.city || '', parts.region || '' ]
    const cityRegion = `${city}${(city && region) ? ', ' : ''}${region}`
    const lines = [ parts.line1, parts.line2, cityRegion, parts.postal, parts.country ]
    return lines.filter(l => l).join('\n')
  }

  get displayHTML () { return ko.pureComputed(() => {
    const { jss } = this
    const { parts, title } = this.componentValue()
    const focused = this.computed(() => (this.focused() && !this.isBlank()) || undefined)
    if (!parts) { return '' }
    return (
      <div>
        <div class={jss.title}>
          {title}
          {this.copyIconHTML(() => this.copyText)}
        </div>
        <div class={jss.partsDisplay}>
          <div class={jss.partsBox} focused={focused}>
            <div>{parts.line1}</div>
            <div>{parts.line2}</div>
            <div>{parts.city}{(parts.city && parts.region)? ', ' : ''}{parts.region}</div>
            <div>{parts.postal}</div>
            <div>{parts.country}</div>
          </div>
        </div>
      </div>
    )
  }).extend({deferred:true})}

  static get editCSS () {
    return {
      fieldEditing: {
        ...super.css.fieldEditing,
        grid: {
          templateAreas: `
            'title    title    title        title'
            'content  content  content      content'
            '-        button1  deleteButton button2 '`,
          templateColumns: '1fr auto auto auto',
          rowGap: '5px',
        },
      },
      deleteButtonGridItem: {
        gridArea: 'deleteButton',
      },
      deleteButton: {
        ...super.css.saveButton,
      },
      partsGrid: {
        display: 'grid',
        marginTop: '30px',
        grid: {
          templateAreas: `
            'title-line1 title-line2 title-city'
            'input-line1 input-line2 input-city'
            'title-region title-postal title-country'
            'input-region input-postal input-country'`,

          templateColumns: '1fr 1fr 1fr',
          gap: '10px',
        },
      },
      inputField: {
        width: '-webkit-fill-available',
        height: 40,
        fontFamily: typography.bodyFontFamily,
        padding: 5,
        margin: 5,
        fontSize: '1rem',
        borderRadius: 2,
        backgroundColor: '#ffd502',
        border: '1px solid transparent',
        borderBottom: '1px solid black',
        transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        '&:focus': {
          outline: 'none',
          transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        }
      },
      titleInput: {
        extend: 'inputField',
        maxWidth: '300px',
      },
      partInput: {
        extend: 'inputField',
        width: 'auto',
        minWidth: '0',
      },
    }
  }


  get partsEditHTML () {
    const { jss } = this
    const koEvent = { keydown: this.inputKeyDown({ 'Tab': undefined }) }
    const titleArea = v => `grid-area: title-${v}`
    const inputArea = v => `grid-area: input-${v}`
    return Object.entries(this.parts)
      .flatMap(([k,v]) => (<>
        <span class={jss.title} part={k} style={titleArea(k)}>{this.getPartsTitleText(k)}</span>
        <input class={jss.partInput} part={k} style={inputArea(k)} type='text' ko-textInput={v} ko-event={koEvent}/>
      </>))
  }

  titlePickerModelGetter (e: EntityModel) : any {
    const addresses =  ko.unwrap(e.answerFor('addresses'))
    return addresses && addresses.map(a => a.title()).filter(t => t)
  }

  get editHTML () {
    const { jss } = this
    const openFocus = this.computed(() => this.editing())
    return (
      <div>
        <div class={jss.title}>Address Type</div>
        <model-property-picker inputClass={jss.titleInput} my='top left' at='bottom left' hasFocus={openFocus}
          value={this.title} onSelect={this.title}
          indexName='entity'
          extraChoices={ADDRESS_TITLES}
          propertyGetter={e => this.titlePickerModelGetter(e)} />
        <div class={jss.partsGrid}>
          {this.partsEditHTML}
        </div>
      </div>
    )
  }
}

EisAddress.register()
