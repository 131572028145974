type Column = import('Column').default

/**
 * Sorter has functions that provide the column value and direction of sort
 */
export default class Sorter {
  column: KnockoutObservable<Column>
  isDesc: KnockoutObservable<boolean>

  constructor (column: Column) {
    Object.assign(this, {
      column: ko.observable(column),
      isDesc: ko.observable(false)
    })
  }

  setColumn (col: Column) {
    this.column(col)
  }

  sortableValue (row) {
    return this.column().sortableValue(row)
  }

  sortableDirection () {
    return this.isDesc() ? 'desc' : 'asc'
  }
}
