
import { Transaction, CODES, ORIGIN } from './Transaction'
import { TransactionAuthorize } from './TransactionAuthorize'
import { TransactionAmend, TransactionDeauthorize } from './TransactionAmend'
import { TransactionCancel } from './TransactionCancel'
import { TransactionIndividualConversion } from './TransactionIndividualConversion'
import { TransactionIssue } from './TransactionIssue'
import { TransactionMassConversion } from './TransactionMassConversion'
import { TransactionSplit } from './TransactionSplit'
import { TransactionTest } from './TransactionTest'
import { TransactionTransfer } from './TransactionTransfer'
import { TransactionUnsupported } from './TransactionUnsupported'

export {
  Transaction, TransactionAuthorize, TransactionCancel, TransactionIndividualConversion,
  TransactionIssue, TransactionMassConversion, TransactionSplit, TransactionTransfer,
  TransactionUnsupported, TransactionAmend, TransactionDeauthorize,
  ORIGIN, CODES,
}

/**
   * TODO:
   * - Deauthorize Capital
   * - Rename Capital
   * - Rename Stakeholder
   */
// static factory (params: { code: CODES.CapitalAuthorize }) : TransactionAuthorize
// static factory (params: { code: CODES.CapitalCancel }) : TransactionCancel
// static factory (params: { code: CODES.CapitalIssue, amount: string, person: string }) : TransactionIssue
// static factory (params: { code: CODES.CapitalTransfer }) : TransactionTransfer
// static factory (params: { code: CODES.CapitalSplit }) : TransactionSplit
// static factory (params: { code: CODES.CapitalMassConvert }) : TransactionMassConversion
// static factory (params: { code: CODES.CapitalIndividualConvert }) : TransactionIndividualConversion

export function factory (params: { code: CODES, [any: string]: any }) : Transaction {
  switch (params.code) {
    case CODES.CapitalAuthorize: return new TransactionAuthorize(params)
    case CODES.CapitalAmend: return new TransactionAmend(params)
    case CODES.CapitalDeauthorize: return new TransactionDeauthorize(params)
    case CODES.CapitalCancel: return new TransactionCancel(params)
    case CODES.CapitalIndividualConvert: return new TransactionIndividualConversion(params)
    case CODES.CapitalIssue: return new TransactionIssue(params)
    case CODES.CapitalMassConvert: return new TransactionMassConversion(params)
    case CODES.CapitalSplit: return new TransactionSplit(params)
    case CODES.CapitalTransfer: return new TransactionTransfer(params)
    case CODES.Test: return new TransactionTest()
    default: return new TransactionUnsupported(params)
  }
}
