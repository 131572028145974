import { IndentBlock } from './IndentBlock'

type Block = import('./Block').default
type ArrayOrderedByIndex<T> = import('utils/ArrayOrderedByIndex').default<T>

/**
 * TODO: For formatted numbers, look at `ListMeta`
 */
export default class List extends IndentBlock {
  // TODO: overrideWithNumber: KnockoutObservable<number>
  get plainObservables () { return [...super.plainObservables, 'number'] }
  get code () { return 'l' }

  getNumber (blocks: ArrayOrderedByIndex<Block>) {
    let index = blocks.indexOf(this)
    const array = [...blocks]
    while (--index > -1) {
      const prev = array[index] as List
      const prevIsList = prev instanceof List
      if (!prevIsList || prev.depth() < this.depth()) { return 1 }
      if (prev.depth() === this.depth()) { return prev.getNumber(blocks) + 1}
    }
    return 1
  }

  getFormattedNumber (blocks) {
    const number = this.getNumber(blocks)
    return `${number}.)`
  }

  markdownLeadingChars (blocks) { return `${this.getNumber(blocks)}.`}
}
