import WritingRightViewArrayComponent from './WritingRightViewArrayComponent'
import WritingRightViewComponent from './WritingRightViewComponent'

type ArrayComponentPerson = import('../ArrayComponentVariable').ArrayComponentPerson
type ComponentPerson = import('../ModelVariable').ComponentPerson

export class WrvvArrayComponentPerson<T extends ArrayComponentPerson> extends WritingRightViewArrayComponent<T> {
  get body () {
    return (
      <>
        {super.body}
        {this.inputSection('Person Property',
          this.personPropertyPicker(this.variable.personProperty))}
      </>
    )
  }
}


export class WrvvComponentPerson<T extends ComponentPerson> extends WritingRightViewComponent<T> {
  get body () {
    return (
      <>
        {super.body}
        {this.inputSection('Person Property',
          this.personPropertyPicker(this.variable.personProperty))}
      </>
    )
  }
}


WrvvComponentPerson.register()
WrvvArrayComponentPerson.register()
