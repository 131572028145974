import t from 't'

import { AssetTransferNumber, AssetCredit, AssetDebit } from '../AssetAction'
import { CertificateIssue } from '../CertificateAction'

import { CODES } from './Transaction'
import { AssetTransaction } from './AssetTransaction'

export class TransactionIndividualConversion extends AssetTransaction {
  transferor: KnockoutObservable<string>
  targetAssetClassID: KnockoutObservable<string>
  amount: KnockoutObservable<string>
  trNumber: KnockoutObservable<number>
  certificateNumber: KnockoutObservable<number>
  certificatesCancelled: KnockoutObservableArray<number>
  residualCertificate: KnockoutObservable<number>


  constructor (params) {
    super(params)
    this.propertiesFromJS(params)
  }

  get propertiesToSerialize () { return [] }
  get code () { return CODES.CapitalIndividualConvert }

  get plainObservables () {
    return [
      ...super.plainObservables,
      'transferor', 'targetAssetClassID', 'amount', 'trNumber',
      'certificateNumber',
    ]
  }

  * yieldActions () {
    const { assetClassID, amount, targetAssetClassID } = this
    const person = this.transferor()
    yield new AssetTransferNumber(assetClassID, this.trNumber() || null)
    yield new AssetDebit(assetClassID, person, amount())
    yield new AssetCredit(targetAssetClassID(), person, amount())
    yield new CertificateIssue(
      targetAssetClassID(),
      this.certificateNumber(),
      person,
    )
  }

  shortDescription (cs: CapitalState) {
    const { amount, transferor } = this
    return t.CAPITAL_INDIVIDUAL_CONVERSION(amount, this.assetNameAt(cs), transferor)
  }
}
