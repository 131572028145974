
import { CloneEditor } from 'Editor'
import { UnitView } from 'DataModel'

export default class TableItemEditor extends CloneEditor<UnitView> {
  html: (UnitView) => any

  constructor ({ original, UnitView, parent, html, onSave }) {
    super({ original, UnitView, parent, onSave })
    Object.assign(this, { html })
  }

  cloneOrCreate (original, { UnitView, parent }) {
    return original
      ? original.clone()
      : new UnitView({}, parent)
  }

  async onSave () : Promise<any> {
    if (this.original) {
      this.original.copyFieldValues(this.clone)
    }
    super.onSave(this.original)
  }

  get questionHTML () { return this.html(this.clone) }
}

TableItemEditor.register()
