import { format } from 'date-fns'

import { fromISO8601 } from 'utils/dates'
import { computed } from 'utils/decorator'

import { Variable } from './Variable'

export default class DateVariable extends Variable {
  format: KnockoutObservable<string> = ko.observable()
  date: KnockoutObservable<string> = ko.observable(new Date().toISOString())

  get code () { return 'date' }
  get incohateText () { return '' }
  get isCohate () { return true }
  get slotGroupTitle () { return 'Date' }
  get hasSlots () { return true }
  value () { return this.date() }

  get slotTitleText () {
    const date = this.asDate()
    return date ? format(date, 'yyyy-MM-dd') : 'ø'
  }

  @computed()
  asDate (d?: Date) {
    if (d !== undefined) {
      this.date(d ? d.toISOString() : null)
      return null
    } else {
      return fromISO8601(this.date())
    }
  }

  get leadTransforms () {
    return [
      { type: 'dateFormat', parameters: { format: this.format() } },
      ...super.leadTransforms,
    ]
  }

  propagate (to: DateVariable) { to.date(this.date()) }
}
