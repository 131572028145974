import { debounce } from 'lodash-es'
import { differenceInMilliseconds as msdiff } from 'date-fns/esm'

import ViewComponent from 'ViewComponent'
import { enumerate } from 'utils/iterable'
import { arrayMove } from 'utils/array'

import Command from 'Command'
import ModelListCommandSet from './ModelListCommandSet'

import { color, buttons } from 'styles'

import icons from 'icons'
import caretUp from 'icons/solid/caret-up'
import caretDown from 'icons/solid/caret-down'

import './model-list-options'
import Sorter from './Sorter'
import Column from 'Column'
import { hidePopovers } from 'pop-over'

export { default as ModelListOptions } from './ModelListOptions'

enum GripArea { NONE = 0, LEFT, RIGHT, MOVE }
type GripEvent = {
  event: MouseEvent,
  gripAreaRef: GripArea,
}

type ColumnData = {
  column: Column,
  width: KnockoutObservable<number>
  headElement: KnockoutObservable<any>
  dragEvent: KnockoutObservable<GripEvent>
  hoverEvent: KnockoutObservable<GripEvent>
  dragOffsetX: KnockoutObservable<number>
  visibleX: KnockoutObservable<number>
}

type Row = any
type Cell = any
type columnID = string

const MIN_COL_WIDTH = 100

/**
 * ModelList is a base class for all lists of models.  It abstracts:
 *
 * 1. Pagination
 * 2. Search
 * 3. Filter
 * 4. Order
 * 5. Rendering
 *
 */
export default class ModelList extends ViewComponent {
  cellCache: Map<Row, Record<columnID, any[]>> = new Map()
  columnData: KnockoutComputed<ColumnData[]>
  columnDataRecords: Record<string, ColumnData> = {}
  columnResizeCursor: KnockoutComputed<{ cursor: string }>
  columnResizeHoverIndex: KnockoutComputed<number>
  dragAnchor: KnockoutObservable<number>
  draggingIndex: KnockoutObservable<number>
  dragWidthAnchor: KnockoutObservable<number>
  gridCells: KnockoutObservableArray<Cell> = ko.observableArray([])
  hits: KnockoutObservableArray<Row>
  leftOffsets: KnockoutComputed<number[]>
  listOptionColumns: KnockoutObservableArray<Column>
  offsetLeft: KnockoutObservable<number> = ko.observable(0)
  offsetTop: KnockoutObservable<number> = ko.observable(0)
  rowIndexMap: Map<Row, KnockoutObservable<number>> = new Map()
  sorters: KnockoutObservableArray<Sorter>
  stickyCells: KnockoutObservableArray<Cell> = ko.observableArray([])
  stickyColumns: KnockoutObservable<number>
  stickyColumnWidth: KnockoutComputed<number>
  viewportRect: KnockoutObservable<DOMRect> = ko.observable()
  visibleColumns: KnockoutComputed<ColumnData[]>
  private hoverRow: KnockoutObservable<string>
  private leftFixedColumnWidth: KnockoutComputed<number>
  private contextMenuContent: KnockoutObservable<any>
  private showingContextMenu: KnockoutComputed<boolean>
  private contextMenuEvent: KnockoutObservable<MouseEvent>
  private contextMenuForRow: (any) => any
  private selectedRow: KnockoutObservable<Row>
  private headElement: KnockoutObservable<HTMLElement> = ko.observable()

  get rowHeightPx () { return 56 }

  dispose () {
    super.dispose()
    this.cellCache.clear()
    this.rowIndexMap.clear()
    this.gridCells([])
    this.stickyCells([])
  }

  constructor ({
    hits, onSelect, columns, sorters, hoverRow,
    columnJss, storageID, stickyColumns, contextMenuForRow, commandSet }) {
    super()

    Object.assign(this, {
      hits,
      onSelect,
      sorters,
      hoverRow: hoverRow || ko.observable(),
      stickyColumns: ko.observable(stickyColumns || 1),
      columnJss: columnJss || {},
      listOptionColumns: columns,
      columnData: columns.map(c => this.makeColumnData(c, storageID)),
      dragAnchor: ko.observable(0),
      dragWidthAnchor: ko.observable(0),
      draggingIndex: ko.observable(-1),
      contextMenuContent: ko.observable(''),
      contextMenuEvent: ko.observable(null),
      contextMenuForRow,
      selectedRow: ko.observable(null),
    })

    if (commandSet instanceof ModelListCommandSet) {
      commandSet.setModelListCommands(this.makeCommands())
    }

    this.subscribe(this.hits, () => this.selectedRow(undefined))

    // Scroll to the selected row
    this.subscribe(this.selectedRow, row => {
      const { firstRow, lastRow } = this.visibleRows || {}
      if (firstRow === undefined) { return }
      const i = ko.unwrap(this.rowIndexMap.get(row))
      if (i < firstRow || i >= lastRow) {
        const scrollBy = (
          i < firstRow
            ? (i - firstRow)
            : (i - (lastRow-1))
        ) * this.rowHeightPx
        window.scrollBy({ top:scrollBy, behavior:'smooth' })
      }
    })

    this.showingContextMenu = this.computed({
      read: () => Boolean(this.contextMenuContent()),
      write: v => v || this.contextMenuContent(false)
    })

    this.visibleColumns = this.computed(() =>
      this.columnData().filter(c => c.column.show())
    ).extend({ deferred: true })

    this.leftOffsets = this.computed<number[]>(() => {
      const offsets = []
      let lastOffset = 0
      for (const col of this.visibleColumns()) {
        offsets.push(lastOffset)
        lastOffset += col.width()
      }
      return offsets
    }).extend({ deferred: true })

    this.stickyColumnWidth = this.computed(() => (
      this.visibleColumns().slice(0, this.stickyColumns())
        .reduce((acc, v) => acc + v.width(), 0)
    ))

    this.columnResizeCursor = this.computed(() => (
      this.visibleColumns().map(c => c.hoverEvent() || c.dragEvent())
        .filter(v => v)
        .some(v => v.gripAreaRef === GripArea.LEFT || v.gripAreaRef === GripArea.RIGHT)
          ? ({ cursor: 'col-resize' }) : ''
    ))

    this.columnResizeHoverIndex = this.computed<number>(() => {
      let i = this.visibleColumns().findIndex(c => c.dragEvent())
      if (i === -1) {
        i = this.visibleColumns().findIndex(c => c.hoverEvent())
        if (i === -1) return -1
      }
      const column = this.visibleColumns()[i]
      const evt = column.dragEvent() || column.hoverEvent()
      if (evt.gripAreaRef === GripArea.MOVE || evt.gripAreaRef === GripArea.NONE) { return -1 }
      return evt.gripAreaRef === GripArea.RIGHT ? i : i - 1
    }).extend({ rateLimit: 100 })

    this.leftFixedColumnWidth = this.computed(() => {
      let w = 0
      const cols = this.visibleColumns()
      for (let i=0; i<cols.length && !cols[i].column.reorderable; ++i) {
        w += cols[i].width()
      }
      return w
    })
  }

  get visibleRows () : { firstRow:number, lastRow:number } {
    const tableRect = this.viewportRect()
    const headElement = this.headElement()
    const bottomMargin = 75
    if (!tableRect || !headElement) { return null }
    const headerRect = headElement.getBoundingClientRect()
    const firstRow = Math.ceil(Math.max(0, (headerRect.bottom - tableRect.top)) / this.rowHeightPx)
    const visibleRows = Math.floor((window.innerHeight - headerRect.bottom - bottomMargin) / this.rowHeightPx) - 1
    const lastRow = firstRow + visibleRows
    return { firstRow, lastRow }
  }

  get leftEdge () {
    if (window.scrollX === 0) {
      return this.leftFixedColumnWidth()
    } else {
      return this.stickyColumnWidth()
    }
  }

  makeColumnData (c: Column, storageID: string) : ColumnData {
    const cd = this.columnDataRecords[c.columnID] || (this.columnDataRecords[c.columnID] = {
      column: c,
      width: ko.observable(c.columnWidth).extend({ localStorage: `${storageID}.${c.columnID}.width` }),
      headElement: ko.observable(null),
      dragEvent: ko.observable(null).extend({rateLimit:50}),
      hoverEvent: ko.observable(null),
      dragOffsetX: ko.observable(0),
      visibleX: ko.observable(0).extend({rateLimit:50}),
    })
    if ( !c.resizable ) { cd.width(c.columnWidth )}
    return cd
  }

  static get css () {
    const bgColorVars = color.dynamic({
      light: {
        'row-even-bg': color.systemBackground.light.primary,
        'row-odd-bg': color.systemGrey.light.seven,
        'row-hover-bg': color.hover.light.primaryOpaque,
        'row-hover-color': 'inherit',
        'shadow': color.grey.a,
        'row-selected-bg': color.hover.light.blue,
        'row-selected-color': color.text.light.altPrimary,
      },
      dark: {
        'row-even-bg': color.systemBackground.dark.primary,
        'row-odd-bg': color.systemGrey.dark.seven,
        'row-hover-bg': color.hover.dark.primaryOpaque,
        'row-hover-color': color.text.dark.primary,
        'shadow': color.dmgrey.dmbg1,
        'row-selected-bg': color.hover.dark.blue,
        'row-selected-color': color.text.dark.altPrimary,
      },
    })

    return {
      ...super.css,
      ...this.cellCSS,
      ...this.headCSS,
      ...this.stickyCSS,
      block: {
        position: 'relative',
        ...bgColorVars,
      },

      sortIndicator: {
          '--icon-color': color.label.light.secondary,
          paddingLeft: '6px',
          visibility: 'hidden',
          'body[dark] &': { // project batman
            '--icon-color': color.label.dark.secondary,
          },

        '$headCell[sorting] &': {
          visibility: 'visible'
        },

        '$headCell[column-id=select] &': {
          display: 'none',
        },
      },

      underRow: {
        gridColumn: '1/-1',
        height: '56px',
        zIndex: 0,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,

        '&[parity=even]': {
          backgroundColor: 'var(--row-even-bg)',
        },

        '&[parity=odd]': {
          backgroundColor: 'var(--row-odd-bg)',
        },

        '&[hover], &[selected]': {
          backgroundColor: 'var(--row-hover-bg)',
          color: 'var(--row-hover-color)',
        },

        '&[selected]': {
          backgroundColor: 'var(--row-selected-bg)',
          color: 'var(--row-selected-color)',
        },
      },
    }
  }

  static get stickyCSS () {
    return {
      sticky: {
        position: 'sticky',
        left: 0,
        height: 0,
        width: 0,
        display: 'grid',
        gridAutoRows: '56px',
        overflow: 'visible',
        fontSize: '15px',
        zIndex: 4,
      },

      stickyCell: {
        extend: 'cell',
        transform: '',
        '&:not([column-id=select])': {
          padding: '4px 15px 4px 30px',
        },
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        backgroundColor: color.systemBackground.light.primary,
        zIndex: 4,
        borderRight: `1px dotted ${color.separator.light.nonOpaque}`,

        '&[parity=even]': {
          backgroundColor: 'var(--row-even-bg)',
          '&[hover]': {
            backgroundColor: 'var(--row-hover-bg)',
            color: 'var(--row-hover-color)',
          },
          '&[selected]': {
            backgroundColor: 'var(--row-selected-bg)',
            color: 'var(--row-selected-color)',
          },
        },

        '&[parity=odd]': {
          backgroundColor: 'var(--row-odd-bg)',
          '&[hover]': {
            backgroundColor: 'var(--row-hover-bg)',
            color: 'var(--row-hover-color)',
          },
          '&[selected]': {
            backgroundColor: 'var(--row-selected-bg)',
            color: 'var(--row-selected-color)',
          },
        },
      },
    }
  }

  static get cellCSS () {
    return {
      cells: {
        display: 'grid',
        gridAutoRows: '56px',
        width: '100%',
        overflow: 'hidden',
        fontSize: '15px',
      },

      cell: {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '4px 14px',
        cursor: 'pointer',
        transition: '0.2s transform',
        transformOrigin: 'center',
        '&[notransition]': {
          transition: 'unset',
        },

        '&[resizing]': {
          borderRight: `1px solid ${color.separator.light.nonOpaque}`,
          marginRight: '1px',
        },
        'body[dark] &': { // project batman
          color: color.dmgrey.dmtext,
        },

        '& > a': {
          color: color.link.light.primary,
          'body[dark] &': { // project batman
            color: color.color.dark.blue,
          },
        },

        '& [actions-button]': {
          visibility: 'hidden'
        },

        '&[hover] [actions-button], &[selected] [actions-button]': {
          visibility: 'visible'
        },

        '&:hover, &[selected]': {
          zIndex: 3,
        },
      },

      cellContent: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        '& > a': {
          color: color.color.light.blue,
          'body[dark] &': { // project batman
            color: color.color.dark.blue,
          },
        },
        padding: '2px',
        borderRadius: 6,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0)',
        maxWidth: 'fit-content',
        transition: 'background-color 70ms, box-shadow 120ms',
        '$cell:not([column-id=ui]) &:hover': {
          whiteSpace: 'inherit',
          overflow: 'inherit',
          width: 'fit-content',
          boxShadow: '0px 5px 19px 8px rgba(0,0,0,0.05)',
          backgroundColor: 'var(--row-hover-bg)',
          padding: '10px 10px',
          borderRadius: 6,
          marginLeft: -10,
          transition: 'background-color 120ms, box-shadow 200ms',
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.opaque,
            color: color.dmgrey.dmtext,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0)',
            border: `1px solid ${color.separator.dark.nonOpaque}`
          },
        },
      },
    }
  }

  static get headCSS () {
    return {
      head: {
        display: 'grid',
        position: 'sticky',
        zIndex: 20,
        top: `var(--model-list-offset-top, 59px)`,
        backgroundColor: 'white',
      },

      headCell: {
        ...buttons.clickable,
        position: 'sticky',
        height: '56px',
        padding: '10px 12px',
        alignSelf: 'center',
        fontWeight: 'bold',
        backgroundColor: color.systemBackground.light.primary,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        transition: '0.2s transform',
        transform: 'translate3d(0,0,0)',

        '&[sticky=true]': {
          paddingLeft: 'calc(12px + 16px)',
        },

        '&[notransition]': {
          transition: 'unset',
        },

        '&[column-id=select]': {
          padding: '10px 16px',
        },

        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.primary,
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },

        '&[resizing]': {
          borderRight: `3px solid ${color.color.light.blue}`,
          'body[dark] &': { // project batman
            borderRight: `3px solid ${color.color.dark.blue}`,
          },
        },

        '&[grip-clone]': {
          minHeight: '100vh',
          backgroundColor: color.separator.light.nonOpaque,
          'body[dark] &': { // project batman
            backgroundColor: color.separator.dark.nonOpaque,
          },
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'start'
        },

        zIndex: 1,
      },

      gridHeadContent: {
        width: '100%',
        display: 'flex',
      },

      gridHeadText: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },

    }
  }

  /**
   * @return {JSX}
   */
  get columnHeadHtml () {
    return ko.computed(() =>
      this.visibleColumns().map((c, i) => this.columnHeaderHTML(c, i))
    )
  }

  /**
   * The main sort-by
   */
  get topSorter () {
    return this.sorters()[0]
  }

  sortIndicator (column) {
    return (
      <span class={this.jss.sortIndicator}>
        {this.computed(() => {
          return icons.inline(this.topSorter && this.topSorter.isDesc()
            ? caretDown : caretUp)
        })}
      </span>)
  }

  /**
   * The fn returned by `columnResizeGripAreaRef()` is called by the `ko-grip` binding
   * to determine if the mouse is hovering over a valid grip area. If it
   * returns truthy then a grip will be allowed and the value returned here will
   * be used as the `gripAreaRef` field of the drag event.
   */
  columnGripAreaRef (element, index) { return (evt:MouseEvent) => {
    if (!ko.unwrap(element)) { return null }
    const colAt = i => this.visibleColumns()[i]
    const rect = colAt(index).headElement().getBoundingClientRect()
    const [ left, right ] = [ evt.clientX - rect.left, rect.right - evt.clientX ]
    if (left < 20) {
      if (index !== 0 && colAt(index-1).column.resizable) {
        return GripArea.LEFT
      }
    } else if (right < 20) {
      if (colAt(index).column.resizable && (index+1 !== this.visibleColumns().length)) {
        return GripArea.RIGHT
      }
    }
    return colAt(index).column.reorderable ? GripArea.MOVE : GripArea.NONE
  }}

  /**
   * Handle column resize grip drag events generated by the
   * `ko-grip` binding. `dragEvent.gripAreaRef` is the value returned
   * by the above `columnResizeGripAreaRef()` method.
   */
  async resizeColumn (index, dragEvent) {
    if (!dragEvent) { return }
    const { event, gripAreaRef } = dragEvent
    const column = this.visibleColumns()[gripAreaRef === GripArea.RIGHT ? index : index-1]
    const desiredWidth = this.dragWidthAnchor() + ( event.clientX - this.dragAnchor() )
    const widthToSet = Math.max(MIN_COL_WIDTH, desiredWidth)
    requestAnimationFrame(() => column.width(widthToSet))
  }

  reorderDrag (column: ColumnData, dragEvent: GripEvent) {
    const { event } = dragEvent
    column.dragOffsetX(event.clientX - this.dragAnchor())
    const leftExcess = this.leftEdge - column.visibleX()
    if (leftExcess > 0) { column.dragOffsetX.modify(v => v + leftExcess) }
  }

  dragStart (column: ColumnData, dragEvent: GripEvent) {
    const { event } = dragEvent
    this.dragAnchor(event.clientX)
    const i = this.visibleColumns().findIndex(c => c === column)
    if (dragEvent.gripAreaRef === GripArea.MOVE) {
      this.draggingIndex(i)
    } else {
      const w = dragEvent.gripAreaRef === GripArea.RIGHT
        ? column.width() : this.visibleColumns()[i-1].width()
      this.dragWidthAnchor(w)
    }
  }

  dragStop (column: ColumnData) {
    if (this.draggingIndex() !== -1) {
      const fromColumn = this.visibleColumns()[this.draggingIndex()]
      const toColumn = this.visibleColumns().find(c => c.visibleX() > fromColumn.visibleX())
      const [ fromIndex, toIndex ] = [ fromColumn, toColumn ].map(col =>
        this.listOptionColumns().findIndex(c => col && c === col.column))
      this.fieldDragDrop({ fromIndex, toIndex })
    }
    setTimeout(() => {
      this.draggingIndex(-1)
      this.dragWidthAnchor(0)
      this.visibleColumns().forEach(c => c.dragOffsetX(0))
    }, 100)
  }

  windowEdgeScroll (column: ColumnData, event: MouseEvent) {
    const scrollMargin = 100
    const marginLeft =  ( event.clientX - scrollMargin ) - this.stickyColumnWidth()
    const marginRight = ( event.clientX + scrollMargin ) - window.innerWidth
    const scrollX =
      ( window.scrollX && marginLeft < 0 ) ? marginLeft : (
      marginRight > 0 ? marginRight : 0
    )
    if ( scrollX ) {
      window.scrollBy(scrollX, 0)
      this.dragAnchor.modify(v => v - scrollX)
      column.dragOffsetX.modify(v => v && (v + scrollX))
    }
  }

  dragColumn (column: ColumnData, index: number) { return dragEvent => {
    if (!dragEvent) { return }
    const { event, gripAreaRef } = dragEvent
    this.windowEdgeScroll( column, event )
    if (gripAreaRef === GripArea.MOVE) {
      return this.reorderDrag(column, dragEvent)
    } else {
      return this.resizeColumn(index, dragEvent)
    }
  }}

  columnHeadStyle (index) {
    const isSticky = () => index < this.stickyColumns()
    const column = this.visibleColumns()[index]
    return  {
      left: this.computed(() => {
        if (!isSticky()) { return null }
        const px = this.leftOffsets()[index]
        return `${px}px`
      }).extend({ deferred: true}),
      transform: this.computed(() => {
        if (this.draggingIndex() === -1) { return undefined }
        if (!this.viewportRect()) { return undefined }
        const left = this.leftOffsets()[index]
        const x = this.draggingIndex() === index
          ? column.dragOffsetX()
          : this.dragOverAdjust(column, left, index)
        return x ? `translate3d(${x}px, 0px, 0px)` : undefined
      }),
      zIndex: this.computed(() => {
        if (this.draggingIndex() === index) {
          return 10
        } else {
          return isSticky() ? 3 : 2
        }
      }).extend({ deferred: true }),
    }
  }

  /**
   * @return {JSX}
   */
  columnHeaderHTML (column: ColumnData, index: number) {
    const { jss, sorters } = this
    const sortingByThisColumn = this.computed(() =>
      sorters.length && sorters()[0].column() === column.column ? '1' : undefined
    )

    const { dragEvent, hoverEvent, headElement } = this.visibleColumns()[index]

    const resizing = this.computed(() => ( this.columnResizeHoverIndex() === index ) || undefined )
    const koGripParams = {
      dragEvent, hoverEvent,
      getGripAreaRef: this.columnGripAreaRef(headElement, index),
      onDrag: this.dragColumn(column, index),
      onDragStart: dragEvent => this.dragStart(column, dragEvent),
      onDragStop: () => this.dragStop(column),
      onClick: column.column.sortable ? () => this.setSortBy(column.column) : null,
    }

    const notransition = this.computed(() => (
      this.dragWidthAnchor() // resize in progress
      || this.draggingIndex() === index // dragging this column
      || undefined
    ))

    return (
      <div class={jss.headCell} resizing={resizing} grip=''
        column-id={column.column.columnID}
        notransition={notransition}
        ko-style={this.columnHeadStyle(index)}
        ko-grip={koGripParams}
        ko-set-node={headElement}
        ko-style-map={this.columnResizeCursor}
        sticky={this.computed(() => index < this.stickyColumns())}
        sorting={sortingByThisColumn}>
        <span class={jss.gridHeadContent}>
          <span class={jss.gridHeadText}>{column.column.titleElement}</span>
          {this.sortIndicator(column)}
        </span>
      </div>
    )
  }

  setSortBy (column: Column) {
    const { sorters, topSorter } = this
    if (topSorter && topSorter.column() === column) {
      topSorter.isDesc.modify(v => !v)
    } else {
      sorters([new Sorter(column)])
    }
  }

  dragOverAdjust (cd : ColumnData, x : number, i : number) {
    const draggingIndex = this.draggingIndex()
    if ( draggingIndex === i ) { return 0 }
    const isSticky = i < this.stickyColumns()
    if (isSticky && window.scrollX > 0) { return 0 }
    if (draggingIndex !== -1) {
      const draggingColumn = this.visibleColumns()[this.draggingIndex()]
      if (i < draggingIndex) {
        if (draggingColumn.visibleX() < x + (cd.width() * .66)) {
          return draggingColumn.width()
        }
      } else if (i > draggingIndex) {
        if ( (draggingColumn.visibleX() + draggingColumn.width()) > (x + cd.width() * 0.33)) {
          return -draggingColumn.width()
        }
      }
    }
    return 0
  }

  cellStyle (cd: ColumnData, colIndex: KnockoutObservable<number>, row: KnockoutObservable<number>) {
    const transform = this.computed(() => {
      const i = colIndex()
      const left = this.leftOffsets()[i]
      let x = cd.dragOffsetX() + left
      x += this.dragOverAdjust(cd, x, i)
      cd.visibleX(x)
      const y = row() * this.rowHeightPx
      return `translate3d(${x}px, ${y}px, 0)`
    })

    return {
      transform,
      width: this.computed(() => cd.width() + 'px'),
      height: this.rowHeightPx + 'px',
      zIndex: this.computed(() => this.draggingIndex() === colIndex() ? 2 : undefined),
      backgroundColor: this.computed(() => this.draggingIndex() === colIndex() ? 'white' : undefined),
    }
  }

  * rowsOnScreen () : IterableIterator<[number, Row]> {
    const bounds = this.viewportRect()
    if (!bounds) { return }
    const top = this.offsetTop()
    const startRow = Math.max(0, Math.floor((bounds.y + top) / this.rowHeightPx) * -1)
    const endRow = startRow + Math.ceil((window.innerHeight + top) / this.rowHeightPx)
    const slice = this.hits().slice(startRow, endRow)
    for (const [i, row] of enumerate(slice)) { yield [i + startRow, row] }
  }

  cellIsOnScreen (cd: ColumnData, colIndex, viewportWidth = window.innerWidth) : boolean {
    if (colIndex < this.stickyColumns()) { return true }
    const bounds = this.viewportRect()
    if (!bounds) { return false }
    const ol = this.offsetLeft()
    const left = this.leftOffsets()[colIndex]
    const right = left + cd.width()
    const { x } = bounds
    return (
      right + x > 0 &&
      left + x < viewportWidth + ol
    )
  }

  parityOf (rowIndex: number) : 'even' | 'odd' {
    return rowIndex % 2 ? 'even' : 'odd'
  }

  underRow (rowIndex: number, row: Row) {
    const style = `grid-row: ${rowIndex + 1} / ${rowIndex + 2}`

    const parity = this.computed(() => this.parityOf(rowIndex))
    const hoveringThisRow = this.getOrMakeHoveringRowComputed(rowIndex)
    const selected = this.computed(() => this.selectedRow() === row || undefined)
    return (
      <div class={this.jss.underRow} style={style}
        hover={hoveringThisRow}
        parity={parity}
        selected={selected} />
    )
  }

  getOrMakeHoveringRowComputed (rowIndex) : KnockoutComputed<boolean> {
    return this.computed<boolean>(() =>
        '' + ko.unwrap(rowIndex) === this.hoverRow() || undefined
    )
        .extend({ deferred: true })
  }

  * visibleCells (rowIndex: number, row: Row, fieldEvents, columns: ColumnData[]) : IterableIterator<[number, ColumnData]> {
    const oRowIndex = this.rowIndexMap.get(row) || ko.observable()
    if (!this.rowIndexMap.has(row)) { this.rowIndexMap.set(row, oRowIndex) }
    oRowIndex(rowIndex)

    yield this.underRow(rowIndex, row)

    for (const [i, columnData] of enumerate<ColumnData>(columns)) {
      if (!this.cellIsOnScreen(columnData, i)) { continue }
      yield this.htmlForCell(columnData, oRowIndex, row, fieldEvents)
    }
  }

  * genCells (enumeratedRows: IterableIterator<[number, Row]>, sticky: boolean) {
    const slice = sticky ? [0, this.stickyColumns()] : [this.stickyColumns()]
    const columns = this.visibleColumns().slice(...slice)

    const fieldEvents = row => ({
      ...(this.contextMenuForRow ? {
        contextmenu: (_, evt) => {
          hidePopovers()
          this.contextMenuEvent(evt)
          this.contextMenuContent(this.contextMenuForRow(row))
          evt.stopPropagation()
          evt.preventDefault()
        }
      } : {})
    })

    for (const [rowIndex, row] of enumeratedRows) {
      yield * this.visibleCells(rowIndex, row, fieldEvents, columns)
    }
  }

  cachedCell (cd: ColumnData, row: Row, make: () => Row) {
    const crMap = this.cellCache.get(row) || {}
    if (!this.cellCache.has(row)) { this.cellCache.set(row, crMap) }
    const columnID = cd.column.columnID
    return crMap[columnID] || (crMap[columnID] = make())
  }

  htmlForCell (cd: ColumnData, rowIndex: KnockoutObservable<number>, row: Row, fieldEvents) {
    const { jss } = this
    const { column } = cd

    const htmlMaker = () => {
      const index = this.computed<number>(() => this.visibleColumns().indexOf(cd))
        .extend({ deferred: true })

      const resizing = this.computed(() =>
        (this.columnResizeHoverIndex() === index()) || undefined )
        .extend({ deferred: true })

      const cellClass = this.computed(() => index() < this.stickyColumns()
        ? jss.stickyCell : jss.cell).extend({ deferred: true })

      const notransition = this.computed(() => (
        this.dragWidthAnchor() // resize in progress
        || this.draggingIndex() === index() // dragging this column
        || undefined
      ))
      const parity = this.computed(() => this.parityOf(rowIndex()))
        .extend({ deferred: true })

      const hoveringThisRow = this.getOrMakeHoveringRowComputed(rowIndex)

      const selected = this.computed(() => this.selectedRow() === row || undefined)

      return (
        <div class={cellClass}
          resizing={resizing}
          column-id={column.columnID}
          notransition={notransition}
          parity={parity}
          ko-style={this.cellStyle(cd, index, rowIndex)}
          ko-event={fieldEvents(row)}
          ko-click={() => this.fieldClick(row)}
          ko-attr={{ hover: hoveringThisRow }}
          row={rowIndex}
          selected={selected}>
          <div class={jss.cellContent}>{column.render(row, this.columnJss)}</div>
        </div>
      )
    }
    return this.cachedCell(cd, row, htmlMaker)
  }

  get contextMenuHTML () {
    return (
      <pop-over my='top left' at='event'
        atEvent={this.contextMenuEvent}
        showing={this.showingContextMenu}>
          <template slot='anchor'><div></div></template>
          <template slot='content'>{this.contextMenuContent}</template>
      </pop-over>
    )
  }

  fieldClick (row) {
    const dblClick = this._lastClick && (msdiff(new Date(), this._lastClick) < 500)
    const userIsSelectingText = !dblClick && !window.getSelection().isCollapsed
    if (userIsSelectingText) { return }
    if (row === this.selectedRow() || dblClick) {
      this.onSelect(row)
    } else {
      setTimeout(() => this.selectedRow(row), 300)
    }
    this._lastClick = new Date()
  }

  makeCommands () : Record<string, Command> {
    const navBy = (amount: number) => {
      const hits = this.hits()
      const indexOb = this.rowIndexMap.get(this.selectedRow())
      let i = indexOb
        ? indexOb() + amount
        : ( amount > 0 ? 0 : hits.length-1 )
      i = Math.max(0, Math.min(hits.length-1, i))
      if (!this.rowIndexMap.has(hits[i])) {
        this.rowIndexMap.set(hits[i], ko.observable(i))
      }
      this.selectedRow(hits[i])
    }
    return {
      navPrevious: new Command({
        title: 'Previous row',
        action: () => navBy(-1),
      }),
      navNext: new Command({
        title: 'Next row',
        action: () => navBy(1),
      }),
      activateCurrent: new Command({
        title: 'Activate',
        action: () => this.fieldClick(this.selectedRow()),
      }),
    }
  }

  /**
   * We subtract one column for the `pad`, and update the order property
   */
  fieldDragDrop ({ fromIndex, toIndex }) {
    const cols = this.listOptionColumns
    if (toIndex === -1) {
      cols.push(cols.splice(fromIndex, 1)[0])
    } else {
      arrayMove(cols, fromIndex, toIndex)
    }
  }

  get gridTemplateColumns () {
    const columns = this.visibleColumns()
    const lastWidth = columns.length && columns.slice(-1)[0].width()
    return [
      ...(columns.slice(0, -1)).map(c => c.width())
    ].map(c => c + 'px').join(' ') + ` minmax(${lastWidth}px, 1fr)`
  }

  updateVisibleCells (visible: Set<Cell>, toShow: Set<Cell>) {
    const toRemove = new Set([...this.gridCells].filter(c => !toShow.has(c)))
    const toAdd = new Set([...toShow].filter(c => !visible.has(c)))
    this.gridCells.remove(c => toRemove.has(c))
    this.gridCells.push(...toAdd)
    return toShow
  }

  get template () {
    const { jss } = this
    let visible = new Set<Cell>()
    this.computed(() => new Set<Cell>(
      this.genCells(this.rowsOnScreen(), false))
    )
      .extend({ rateLimit: 5, deferred: true })
      .subscribe((toShow: Set<Cell>) => {
        visible = this.updateVisibleCells(visible, toShow)
      })

    this.computed(() =>
      [...this.genCells(this.rowsOnScreen(), true)]
    )
      .extend({ rateLimit: 5, deferred: true })
      .subscribe(this.stickyCells)

    const headStyle = this.computed<{ gridTemplateColumns: string }>(() => {
      const { gridTemplateColumns } = this
      return { gridTemplateColumns }
    })

    const blockStyle = this.computed<{ height: string, gridTemplateColumns: string }>(() => {
      const height = this.hits.length * this.rowHeightPx
      const { gridTemplateColumns } = this
      return { height, gridTemplateColumns }
    })

    const mouseEvents = {
      mouseover: debounce((_, evt) => {
        const cell = evt.target.closest('[row]')
        const row = cell && cell.getAttribute('row')
        if (this.hoverRow() !== row) { this.hoverRow(row) }
      }, 20),
      mouseleave: debounce((_, evt) => {
        this.hoverRow(null)
      }, 20)
    }

    return (
      <div class={jss.block}
        ko-event={mouseEvents}>
        {this.contextMenuHTML}
        <div class={jss.head}
          ko-set-node={this.headElement}
          ko-style={headStyle}>
          {this.columnHeadHtml}
        </div>
        <div class={jss.sticky}>
          {this.stickyCells}
        </div>
        <div class={jss.cells}
          ko-scroll-observer={this.viewportRect}
          ko-style={blockStyle}
          ko-self={self => {
            this.offsetTop(self.offsetTop)
            this.offsetLeft(self.offsetLeft)
          }}>
          {this.gridCells}
        </div>
      </div>
    )
  }
}

ModelList.register()
