import { importPdfAsEntity } from './pdf'

const defaultPath = '⭐️ Uploads To Process'

export async function importFromGsUploads (startBook = 0, endBook = 10,
  path = defaultPath, bucket, authManager = global.app.defaultAuthManager
) {
  const accountID = authManager.accountID()
  bucket = bucket || authManager.firebaseConfig.storageBucket

  const { gsUrls } = await authManager.firebaseFn('getImportList', {
    accountID, path, bucket
  })

  console.info(`
    importFromGsUrls [${gsUrls.length}]:

    gsUrls:
  `, gsUrls)

  // 50 is a temporary limitation for the first client - 12 Dec 2018
  for (const url of gsUrls.slice(startBook, endBook)) {
    console.debug(`Importing PDF from: `, url)
    await importPdfAsEntity(authManager, url)
  }

  console.info(`Imports complete.`)
}
