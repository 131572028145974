
import ViewComponent from 'ViewComponent'
import { computed } from 'utils/decorator'

import { add, remove, hidePopovers, scrollPosition } from './maintenance'

/**
 *
 * Usage:
 *    <pop-over my={X} at={Y} trigger={'click'|'hover'}>
 *
 *      <template slot='anchor'> ... </template>
 *
 *      <template slot='content'> ... </template>
 *
 *    </pop-over>
 *
 * A pop-over placement somewhat follows the `my` / `at` jQueryUI position i.e.
 *
 *  my: `{top|center|bottom} {left|center|right}`
 *
 * 🚨 NOTE: These are not complete; they've been put together quickly, and
 *          some may need to be tinkered with to ensure correctness.
 */
export default class PopOver extends ViewComponent {
  showing: KnockoutObservable<boolean>
  contentNode: KnockoutObservable<HTMLElement>
  anchorNode: HTMLElement
  contentIsVisibleInDOM = ko.observable<boolean>(false)

  constructor ({ my, at, anchorClass, contentClass, showing, atEvent }) {
    super()
    Object.assign(this, {
      my,
      at,
      anchorClass,
      contentClass,
      atEvent,
      showing: showing || ko.observable(false),
      contentNode: ko.observable(),
    })
    add(this)
  }

  dispose () {
    super.dispose()
    remove(this)
    this.contentNode.yet(undefined).then(node => node.remove())
  }

  attachToDom (contentNode) {
    const alreadyInDetachedPopover = contentNode.parentElement.closest('[popover-content-node]')
    this.anchorNode = contentNode.nextElementSibling
    if (!alreadyInDetachedPopover) {
      document.body.append(contentNode)
      this.absolutePosition = true
    }
  }

  hidePopovers (except) {
    hidePopovers(except)
  }

  get anchorEvents () { return {} }
  get contentEvents () { return {} }

  static get css () {
    return {
      ...super.css,
      anchor: {
        display: 'var(--pop-over-display, block)',
        position: 'relative',
      },
      content: {
        display: 'none',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        '&[popover-open]': { display: 'block', zIndex: 52 }
      },
      transitionBlock: {
        display: 'none',
        '[popover-open] > &': { display: 'block' }
      }
    }
  }

  positionMatch (string, name, coord) {
    if (!string) {
      throw new Error(`Missing "my" and "at" parameters`)
    }
    const other = coord === 'x' ? 'y' : 'x'
    if (string.includes(name)) {
      return { [coord]: name, [other]: string.replace(name, '').trim() }
    }
  }

  /**
   * @param {string} string "[center|left|right] [center|top|bottom]"
   * @return {object} {x, y}
   */
  getPositionsFromString (string) {
    if (string === 'cursor' || string === 'event') return { x:string, y:string }

    const guesses = [
      ['left', 'x'], ['right', 'x'], ['top', 'y'], ['bottom', 'y']
    ]

    for (const guess of guesses) {
      const posMatch = this.positionMatch(string, ...guess)
      if (posMatch) { return posMatch }
    }

    const [x, y] = string.split(/\s+/)
    return { x, y }
  }

  /**
   *
   */
  xOffset (anchorLeft, anchorWidth, my, at) {
    if (at === 'event') {
      const atEvent = ko.peek(this.atEvent)
      if (!atEvent) { return 0 }
      anchorLeft = atEvent.clientX + scrollPosition.peek().scrollLeft + 2 || 0
    }
    const calc = [`${anchorLeft}px`]
    switch (my) {
      case 'right': calc.push('-100%'); break
      case 'center': calc.push('-50%'); break
      case 'left': break
      default: throw new Error(`Invalid "my" position: ${my}`)
    }
    switch (at) {
      case 'right': calc.push(`${anchorWidth}px`); break
      case 'center': calc.push(`${anchorWidth / 2}px`); break
      case 'event':
      case 'left': break
      default: throw new Error(`Invalid "at" position: ${at}`)
    }
    return `calc(${calc.join(' + ')})`
  }

  /**
   *
   */
  yOffset (anchorTop, anchorHeight, my, at) {
    if (at === 'event') {
      const atEvent = ko.peek(this.atEvent)
      if (!atEvent) { return 0 }
      anchorTop = atEvent.clientY + scrollPosition.peek().scrollTop || 0
    }
    const calc = [`${anchorTop}px`]

    if (my === 'auto') {
      if (this.visible()) {
        if (this.contentIsVisibleInDOM()) {
          const bounds = this.contentNode().getBoundingClientRect()
          const h = document.documentElement.clientHeight
          if (bounds.top > h / 2) {
            at = 'top'
            my = 'bottom'
          } else {
            my = 'top'
            at = 'bottom'
          }
        } else {
          // Defer to positioning can occur and we can test bounds.
          setTimeout(() => this.contentIsVisibleInDOM(true), 200)
          my = 'top'
        }
      } else {
        this.contentIsVisibleInDOM(false)
        my = 'top'
      }
    }

    switch (my) {
      case 'bottom': calc.push('-100%'); break
      case 'center': calc.push('-50%'); break
      case 'top': break
      default: throw new Error(`Invalid "my" position: ${my}`)
    }
    switch (at) {
      case 'bottom': calc.push(`${anchorHeight}px`); break
      case 'center': calc.push(`${anchorHeight / 2}px`); break
      case 'event':
      case 'top': break
      default: throw new Error(`Invalid "at" position: ${at}`)
    }
    return `calc(${calc.join(' + ')})`
  }

  hiddenStyle () { return { display: 'none' } }

  showingStyle () { return { display: 'block' } }

  offsetTransform () {
    const { x, y } = this.offsetXY()
    return `translate3d(${x}, ${y}, 0)`
  }

  offsetXY () {
    if (!this._nodeAttachedToBody) {
      this.attachToDom(this.contentNode())
      this._nodeAttachedToBody = true
    }

    const { top, left, width, height } = this.anchorNode.getBoundingClientRect()
    const { scrollLeft, scrollTop } = scrollPosition()
    const my = this.getPositionsFromString(this.my)
    const at = this.getPositionsFromString(this.at)
    const originX = this.absolutePosition ? left + scrollLeft : 0
    const originY = this.absolutePosition ? top + scrollTop : 0
    return {
      x: this.xOffset(originX, width, my.x, at.x),
      y: this.yOffset(originY, height, my.y, at.y)
    }
  }

  /**
   * 🚨 Danger
   * Because of some race conditions, the `offsetTransform` should only
   * be called once all the nodes and slots are bound, which is (right now)
   * guaranteed only to happen after `showing` has been set to a truthy
   * value the first time.
   */
  contentStyle () {
    if (this.showing()) {
      this._offsetTransform = this.offsetTransform()
    }
    return `transform: ${this._offsetTransform || 'unset'}`
  }

  /**
   * The anchorHTML is displayed on the page and is the element to
   * which the pop-over content is relatively positioned.
   *
   * Note that tee `anchorHTML` must be a node (i.e. not just text)
   */
  get anchorHTML () { return <slot name='anchor' /> }
  get contentHTML () { return <slot name='content' /> }

  /**
   * Ensure the popover is inside the viewport (as best we can).
   *
   * TODO:
   *  1. right-hand-side
   *  2. scroll changes (in a performant way)
   */
  boundByViewport () {
    const cn = this.contentNode()
    const bounds = cn.getBoundingClientRect()
    if (bounds.left < 0) {
      cn.style.left = bounds.left * -1 + 'px'
    } else {
      cn.style.left = `unset`
    }
  }

  // We ratelimit the `visible` computed because:
  //  1. it prevents flicker when briefly passing the mouse over tooltips
  //  2. it ensures that the first rendering has a transition
  @computed({ rateLimit: 50 })
  visible () { return this.showing() || undefined }

  get template () {
    const { jss } = this
    this.subscribe(this.visible, v => v && setTimeout(() => this.boundByViewport(), 5))
    if (this.visible()) { setTimeout(() => this.boundByViewport(), 5) }
    const contentStyle = this.computed(() => this.contentStyle())
      .extend({ deferred: true })

    return (
      <div class={this.anchorClass || jss.anchor}
        ko-event={this.anchorEvents}
        popover-anchor-node=''
        popover-open={this.visible}>

        <div class={this.contentClass || jss.content}
          style={contentStyle}
          ko-event={this.contentEvents}
          ko-descendantsComplete={n => this.contentNode(n)}
          popover-content-node=''
          popover-open={this.visible}>

          <div class={jss.transitionBlock}>
            {this.contentHTML}
          </div>
        </div>

        {this.anchorHTML}
      </div>
    )
  }
}

PopOver.register()
