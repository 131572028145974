import { ModelListOptions } from './model-list'
type EventType = import('minute-box-app/CalendarPanelProvider').EventType

export default class CalendarModelListOptions extends ModelListOptions {
  shownColumns: KnockoutObservableArray<string>
  eventTypes: Record<string, EventType> = null
  defaultColumns: KnockoutObservableArray<string>
  filterColumns: KnockoutObservable<any>

  constructor (possibleColumns, panelID, eventTypes) {
    super(possibleColumns, panelID)
    this.eventTypes = eventTypes

    if (!this.defaultColumns()) { this.defaultColumns(this.shownColumnTitles()) }
    this.filterColumns.notifySubscribers()
  }

  setShownColumns (columnTitles) {
    if (this._watchColumnTitles) { this._watchColumnTitles.dispose() }
    if (!this.eventTypes) { return }
    this.shownColumns(columnTitles.filter(v => v))
    Object.entries(this.eventTypes).forEach(([_, e]) => e.show(this.shownColumns().includes(e.title)))
    this._watchColumnTitles = ko.pureComputed(() => this.shownColumnTitles()).subscribe(this.shownColumns)
  }

  shownColumnTitles () {
    return this.eventTypes && Object.entries(this.eventTypes)
      .filter(([_, e]) => e.title)
      .map(([_, e]) => e.show() ? e.title : `(${e.title})`)
  }
}
