/**
 * ContentMediator has a set of predefined ObjectProxy classes
 * suitable for sensitive, compressed Content.
 */
import MediatorInterface from './MediatorInterface'
import {
  MultiProxy, JsonProxy, Uint8Proxy, CompressProxy, SuiteBCryptoProxy,
  FirestoreBlobProxy, FirestoreProjectionProxy
} from 'MultiProxy'
import { ContentView } from 'DataModel/components'
import Question from 'DataModel/Question'

export default class ContentMediator extends MediatorInterface {
  constructor (name, model, isProjection) {
    super(name, model)
    const contentProxy = new MultiProxy(
      new JsonProxy(),
      new Uint8Proxy(),
      new CompressProxy(),
      new SuiteBCryptoProxy({ privateKey: false }),
      new FirestoreBlobProxy(),
      new FirestoreProjectionProxy(),
    )

    Object.assign(this, {
      contentProxy,
      isProjection: isProjection || ko.observable(),
      questions: model.QUESTIONS.map(q => new Question(q, model)),
    })
  }

  newHolderInstance () {
    return ko.ignoreDependencies(() =>
      new ContentView(this.questions, this.model))
  }

  toJS () {
    return this.value.toJS()
  }

  async serialize () {
    return this.contentProxy.wrap(this.value.toJS())
  }

  async fromJS (vals) {
    const projection = this.getPossibleProjection()
    const opts = { privateKey: false, projection }
    const values = vals._sentinel_
      ? await this.contentProxy.unwrap(vals, opts)
      : vals // synchronous, useful for testing.
    this.isProjection(values.__projection__)
    return this.value.setComponentValues(values)
  }

  async beforeModelSave ({ model, before }) {
    for (const component of this.value.walkComponentInstances()) {
      await component.beforeModelSave(model, before)
    }
  }

  async afterModelSave ({ model, before, after }) {
    for (const component of this.value.walkComponentInstances()) {
      await component.afterModelSave(model, before, after)
    }
  }

  /**
   *        PROJECTIONS
   */

  /**
   * @return {object|null} passed to FirestoreProjectionProxy::unwrapValue
   */
  getPossibleProjection () {
    const { sharing, authManager } = this.model
    const email = authManager && authManager.firebaseUser().email
    if (!sharing.needsProjectionFor(email)) { return null }
    return {
      modelPath: this.model.vmFirestoreDocPath,
      firestore: this.model.authManager.firestore,
      projectionID: sharing.getProjectionID(email)
    }
  }

  componentsWithShareProperty (prop) {
    return Object.values(this.value.componentMap)
      .filter(c => c.properties.share === prop)
      .map(c => c.identifier)
  }

  /**
   * @return {Array.<string>} of component names that are never shared
   */
  get neverShare () {
    return this._neverShare || (
      this._neverShare = this.componentsWithShareProperty('never')
    )
  }

  /**
   * @return {Array.<string>} of component names that are always shared
   */
  get alwaysShare () {
    return this._alwaysShare || (
      this._alwaysShare = this.componentsWithShareProperty('always')
    )
  }

  /**
   * @param {object} given mapping components to a truthy value e.g.
   *    { legalname: true, jurisdiction: true }
   *    { eis: true }
   * @yields {string} of the component identifier
   */
  * genComponentsToShare (given) {
    yield * this.alwaysShare
    yield * Object.keys(given)
    if (given.eis) { yield * this.componentsWithShareProperty('eis') }
  }

  /**
   * @param {object} params
   */
  projectionValue (params = {}) {
    const componentMap = this.value.componentMap

    const components = [...new Set(this.genComponentsToShare(params))]

    const valueObjects = components
      .filter(c => c && !this.neverShare.includes(c) && componentMap[c])
      .map(c => ({ [c]: componentMap[c].getProjectionValue(params[c]) }))

    const value = Object.assign({
      __projection__: true
    }, ...valueObjects)
    return this.contentProxy.wrap(value)
  }
}
