import BodyBlockEditor from './BodyBlockEditor'
import { IndentBlock, MAX_DEPTH } from '../blocks/IndentBlock'

export default class IndentableBodyBlockEditor<T extends IndentBlock> extends BodyBlockEditor<T> {
  onTab (evt: KeyboardEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.block.depth.modify(d =>
      evt.shiftKey ? Math.max(0, d - 1) : Math.min(MAX_DEPTH, d + 1)
    )
  }

  get keydownHandlers () {
    return {
      ...super.keydownHandlers,
      Tab: evt => this.onTab(evt),
      'shift+Tab': evt => this.onTab(evt),
    }
  }
}
