
const APP = 'TApbe0DB39xR3WI-3fn9JoC5Q7pjMnlfJc9h0ZQjFyNH6xBs142Af-c8MBCzEsyPnPKXDAOaOWVzjcNEpdPQqeIbP2I917VAQ6yJU5TSrRTYOwSPI2W7HUntFF7MClCK10nfoByc7qkEEb62QKMqRPd6GQHmP443vOXXG4C34yidxiYOX2-wQSpyLEvVtFX2nezTojr7Hie4xEblIhrs1JC8uYOYz4x7nw7lsfMaBJn93ZnePmz-Mx4Hi1YRHzsNGCzylVmsLgT8Xp0uLoNNDjyr84kgt9FaQ5i7SrgBtAoCEgDTDEcvSdL8V1n6TsDSmkxJgzEwOlNS5V-lxev6iuuea09FcoygXtnpXZOns-redO9Qkzo2-9qB7FvKe5nD2Z2tXRjNa3ClR4aJLEXFdMQ3pM-I1d8kwia6mDVIvvIZZjrozzNX_6lVTDnq7XvpmFz4oqXq70KdN7fF0LPWMs9WW2dAsR3yxc3DOaQLhQWgI6eSH1SkeN8BpaWXeIXq07OGtLE7s7AllHyKKkRr21tSMOebsxjt5kI9nCOTwVnXCbQMmQQuuS2DqB3IVTZY'

const BETA = 'nqoU6-NLn9twkqz4paGv0hAZV53FDPL0z8Q2VvdYS-Z5-YOcURA0qohZ0uBzoSW_jimCoF_44NNVRt1WgG1ZsLnuHq8RgkRo_CKwHmlwWOTaRPQS850Yd_kB_fF3QDzabZdEL8O9GQAXGY6WWffq1Ya9pwDt5kMWKErgHuK4UCjo72v-ovv5ddc6OOoMUClrB2m2fgaum1Ya4OmYpvBjgj048klboo-dXK2WxrUiJs0swwdd0yqAXmzH00JnhD2ilfTgfW-RXa8CaHKOA0sOpkHj0IGN4EX5LZ7MYssEGbUFNM8OIFpD4klfZYCGZwG6bE5QW2hbfYw0n6BTTNFkcxy-YKJixmMLqds22FUNhSNm4m-xQx1JgsHQTVDVXNoxsBwKYmtmARqREVwoI4LZfVdGFe9j3CGfFKy5YJ-vDWHUGuVbVC1qHJwh-oAT0KUnmVlolnnpEZlH2N2437CvRS81bOWgmmgmfnM9n5HYEXI9CXuilJUTCZP7yWs_n9MkF5410M-5OpdexgDNA5U9i1AJ1PUmGYsQZYpaIrQ8_lCAxQze-IAYKZIegILH9D-ymqWg-eRMBKsMSfOpn7O8pQ=='

const DEMO4 = 'Eh3uK0HKt6K_WjvwI291leaH4H1LkugecpEAfMQ6l9JN09GRQysywLdBcNqT5IjcrM9sfFO29JFtq8GeF_o13KtRKZ2EqRHIiQyBT4eSHlWzKgpP4vnZLmJVkB2tRxGk_HSXRCzsaEqm2VrIWMrPLmGZNGwa8eZvZ2oZp0X_kVUUKKXm4dJ1ioll7foUWc6uIs-uaaTMbXbwYEfLB35bZL_fkaj_OYOLyb6yUIgowVNa0ikxXEMvOFFCfqorkiybK0b0gv6dAnLAOcdpCiDnucwldYYoXNjqpw8QlabR5s9CL0bityWGM7v6J9iQrDaNciMX_8jqAzrTCY2G5WvKZcnnXy1zPxdmvFNaP2a2ndCjUjuolbV0OuA72M98Sm-fS_H0J67gv4rGGJV1VebUqtYvEYJd0eCVKj6jVIQHpLUlmYRHsNoI2Fh4sxP8qlXFvZrsQKwx5M6AIjh9KpzF-aSiJjsgo3FJKchM_EqVVd9-CSUE0sSYPmFTVrvJWTNZ_t6Sm1KDoSSo5pfhHXwqUJs336R0p-491WgehNi3Im9JNFklAb5WcQL6h3v0HkbUQEEsKR-a_ITUMFqj85N5CQ=='

const DEV = 'HIePX06xppLUObx7uuIzmMIsLr3oTVIcgd0rD_OORogV7vjU-YG33zGmIm6UC32SS160MGwTPNCkwDDW0eZMknQpNnUfszx_fSsHf9O_UlgX6N1Cw2CzVDpq3acWYgcvn71kvKulmHanZPZqmViR_97M8MIYxlcxuL3BIaYD3NWwnorSGJjPhvPpQxf5j_WsYllbPBaAWBYLbGFDm-8q8aefQeaScjhgH8_X6O04IhjGUw7M3nsT4r7NugOkRHRznvfu7ONghFXC7suNtPy62bJ8yHv5Yvq1ifWdbkHRurO4A2JJ_rz5_4X1zaOi6dSNZqwpPS74P1dv1cz_JVQCkZFAkjXcNxOd5yqExethT-bf3fd6wOQMmNTMsgEHYzKXR-g5AYU5353B7L5nx05EMf71YtxzSyEX7r7sg5gRvVwp68uIigVgRVh4wRM1GPbr'

function licenseFor (hostname = location.hostname) {
  switch (hostname) {
    case 'app.minutebox.com': return APP
    case 'beta.minutebox.com': return BETA
    case 'minutebox-demo4.web.app': return DEMO4
    default: return DEV
  }
}

export default licenseFor()
