
import { color } from 'styles'

import WritingRightViewVar from './WritingRightViewVar'
import WritingRightViewComponent from './WritingRightViewComponent'
import WritingRightViewArrayComponent from './WritingRightViewArrayComponent'

type DateVariable = import('../DateVariable').default
type ComponentPureDate = import('../ModelVariable').ComponentPureDate
type ArrayComponentPureDate = import('../ArrayComponentVariable').ArrayComponentPureDate


export default class WrvvDate<T extends DateVariable> extends WritingRightViewVar<T> {
  get body () {
    this.monitorAndUpdatePeers(this.variable.date)

    return (
      <>
        {super.body}
        {this.inputSection('Date Format', this.dateFormattingHTML(this.variable.format))}
        {this.inputSection('Pick Date', <date-picker-button class={this.jss.datePicker} target={this.variable.asDate} />)}
        {this.inputSection('Slot', this.slotPickerHTML)}
      </>
    )
  }

  static get css () {
    return {
      ...super.css,

      datePicker: {
        height: 30,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        display: 'flex',
        alignItems: 'center',
        padding: 6,
        borderRadius: 2,
        background: color.textInput.light.primary,
        'body[dark] &': { // project batman
          background: color.textInput.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        '&:hover': {
          color: color.text.light.secondary,
          'body[dark] &': { // project batman
            color: color.text.dark.secondary,
          },
        }
      },
    }
  }
}


export class WrvvComponentPureDate<T extends ComponentPureDate> extends WritingRightViewComponent<T> {
  get body () {
    return (
      <>
        {super.body}
        {this.inputSection('Date Format', this.dateFormattingHTML(this.variable.format))}
      </>
    )
  }
}


export class WrvvArrayComponentPureDate<T extends ArrayComponentPureDate> extends WritingRightViewArrayComponent<T> {
  get body () {
    return (
      <>
        {super.body}
        {this.inputSection('Date Format', this.dateFormattingHTML(this.variable.format))}
      </>
    )
  }
}


WrvvDate.register()
WrvvComponentPureDate.register()
WrvvArrayComponentPureDate.register()
