import Command from 'Command'
import RootCommandSet from 'minute-box-app/RootCommandSet'

import PrecedentModel from './PrecedentModel'
import PrecedentPanelProvider from './PrecedentPanelProvider'

type PrecedentListPanelProvider = import('./PrecedentListPanelProvider').default

export default class PrecedentListCommandSet extends RootCommandSet<PrecedentListPanelProvider> {
  makeCommands () {
    const { app } = window

    const newPrecedent = new Command({
      title: 'Create New Precedent',
      // help: <span>Press <kbd>Shift +</kbd> to quickly create a new entity.</span>,
      action: async () => {
        const manager = await app.pickAuthManager()
        const model = new PrecedentModel(true, manager)
        app.setPanelProvider(PrecedentPanelProvider, { app, model })
      },
      hideIf: () => app.defaultAuthManager.userIsHomeless,
    })

    return {
      ...super.makeCommands(),
      newPrecedent,
    }
  }

  get keyboardShortcuts () {
    const c = this.commands
    return [
      ...super.keyboardShortcuts,
    ]
  }
}
