
import EisArrayComponentTable from './EisArrayComponentTable'
type Import = import('DataModel/components').Import

class EisImports extends EisArrayComponentTable<Import> {
  get caption () { return 'Imports' }
  get componentName () { return 'imports' }
  get listAddName () { return null }
  get columns () { return ['origin', 'filename', 'importedBy', 'datetime'] }

  deleteButtonHTML () { return null }
  editCloneHTML () { return null }
  gridClick () { return }
}

EisImports.register()
