
import { compare } from 'fast-json-patch'
import OutcomeNotification from 'notification-manager/outcome-notification'
import errorIcon from 'icons/solid/exclamation-triangle'

import { color } from 'styles'

import ViewComponent from 'ViewComponent'

const THROTTLE = { rateLimit: 375 }
let autoSaveInstances = 0

export default class AutoSave extends ViewComponent {
  constructor ({ dataModel, userFinishesEditing, rateLimit }) {
    super({})
    Object.assign(this, {
      dataModel,
      throttle: rateLimit ? { rateLimit } : THROTTLE,
      userFinishesEditing,
      saverNumber: ++autoSaveInstances,
    })

    if (dataModel) { this.startMonitoring() }
  }

  static get css () {
    return {
      errorOutcome: {
        ...OutcomeNotification.css.outcome,
        backgroundColor: 'rgba(255,188,196,0.97)',
        border: '1px solid red'
      },
      errorIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      },
      errorMessage: {
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      }
    }
  }

  dispose () {
    super.dispose()
    this._monitor.dispose()
  }

  startMonitoring () {
    if (this._monitor) { this._monitor.dispose() }
    console.info(`
      <auto-save> Monitoring [${this.dataModel.constructor.name}:${this.dataModel.id()}] for changes`, this.dataModel)
    this._monitor = this.computed(() => this.dataModel.vmCurrentRepr())
      .extend(this.throttle)
    this.subscribe(this._monitor, r => this.saveIfChanged(r))
  }

  get unableToSaveNotification () {
    const { jss } = this
    return [`Unable to save.`, {
      icon: errorIcon,
      style: {
        outcome: jss.errorOutcome,
        icon: jss.errorIcon,
        message: jss.errorMessage
      }
    }]
  }

  async saveIfChanged (currentRepr) {
    const { notifier } = global.app
    if (this._savingMutex) { return this._savingMutex }
    this.userFinishesEditing && await this.userFinishesEditing()

    const otherRepr = this.dataModel.vmSavedRepr() || {}
    const patch = compare(otherRepr, currentRepr)
    const start = new Date()
    if (!patch.length) { return }

    console.info(
      `<auto-save ${this.saverNumber}> Saving
        ${this.dataModel.constructor.name} [${this.dataModel.id()}]`, patch
    )
    try {
      this._savingMutex = this.dataModel.vmSave()
      notifier.registerActivity(
        `Saving`, `Saved`, `Cannot save`, this._savingMutex)
      await this._savingMutex
      console.log(`<auto-save ${this.saverNumber}>
        Saved ${new Date() - start}ms
        ${this.dataModel.constructor.name} [${this.dataModel.id()}]`)
    } catch (err) {
      notifier.pushOutcome(...this.unableToSaveNotification)

      switch (err.code) {
        case 'permission-denied':
          console.warn(`<auto-save> permission denied; stopping.`,
            this.dataModel)
          this._monitor.dispose()
          // Restart monitoring if the model is updated; the permissions
          // may now permit us to edit.
          this.dataModel.vmSavedRepr.once(() => this.startMonitoring())
          break
        default:
          console.error(`<auto-save> unhandled error:`, this.dataModel, err)
          break
      }
    } finally {
      this._savingMutex = false
    }
  }

  get template () { return true }
}

AutoSave.register()
