
import ViewComponent from 'ViewComponent'
import icons from 'icons'
import moreIcon from 'icons/light/ellipsis-h'
import { dropdown, color } from 'styles'

export type ActionItem = {
  label: string
  action: () => void
}

/**
 * A simple menu for selecting a label in a list.
 *
 * Usage: `<action-menu actions={ActionItem[]} />`
 */
export class ActionMenu extends ViewComponent {
  actions: ActionItem[]

  constructor ({ actions }) {
    super()
    Object.assign(this, { actions })
  }

  static get css () {
    return {
      actionsButton: {
        ...dropdown.clickable,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        marginRight: '16px',
        borderRadius: '4px',
        '--icon-color': '#aaa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //padding: '3px 0px',
        fontSize: '1.4rem',
        '&:hover': {
          borderColor: color.text.light.primary,
          '--icon-color': color.text.light.primary,
          'body[dark] &': { // project batman
            borderColor: color.text.dark.primary,
            '--icon-color': color.text.dark.primary,
          },
        },
        '& svg': {
          width: '60%',
        }
      },

    }
  }

  get actionsButtonHTML () {
    return (
      <div class={this.jss.actionsButton} actions-button=''>
        {icons.inline(moreIcon)}
      </div>
    )
  }

  get template () {
    return (
      <drop-down my='top right' at='bottom right'>
        <template slot='anchor'>
          {this.actionsButtonHTML}
        </template>
        <template slot='content'>
          <action-menu-items actions={this.actions} />
        </template>
      </drop-down>
    )
  }
}


/**
 * Action Menu Items
 */
export class ActionMenuItems extends ViewComponent {
  actions: ActionItem[]

  constructor ({ actions }) {
    super()
    Object.assign(this, { actions })
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.actionsMenu} ko-click={evt => evt.stopPropagation()}>
        {this.actions.map(a => this.actionHTML(a))}
      </div>
    )
  }

  actionHTML (item: ActionItem) {
    const { jss } = this
    return (
      <div class={jss.actionItem} ko-ownClick={() => item.action()}>
        {item.label}
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      actionsMenu: {
        ...dropdown.generalMenu,
        whiteSpace: 'nowrap',
      },

      actionItem: {
        ...dropdown.item,
      },
    }
  }
}

ActionMenu.register()
ActionMenuItems.register()
