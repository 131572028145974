
import { ExtraJurisdictionalRegistration } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisExtraJurisdictionalRegistrations extends EisArrayComponentTable<ExtraJurisdictionalRegistration> {
  get caption () { return 'Extra-Jurisdictional Registrations' }
  get componentName () { return 'extrajurisdictional_registrations' }
  get listAddName () { return 'Extra-Jurisdictional Registration' }

  get columns () {
    return [
      this.jurisdictionColumn,
      'entity_name',
      'type',
      'registration_number',
      'registered',
      'revoked',
      'deregistered',
      'agent',
    ]
  }

  get jurisdictionColumn () {
    return {
      property: 'jurisdiction',
      edit: clone => this._jurisdictionPicker(clone, 'jurisdiction'),
      view: unit => <jurisdiction-flag value={unit.jurisdiction} />,
    }
  }
}

EisExtraJurisdictionalRegistrations.register()
