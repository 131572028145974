
import IndentableBodyBlockEditor from './IndentableBodyBlockEditor'
import Bullet from '../blocks/Bullet'

export default class BlockBulletEditor extends IndentableBodyBlockEditor<Bullet> {
  static get css () {
    return {
      ...super.css,
      bullet: {
        width: '10px',
        lineHeight: '1.8em',
        marginRight: '1em',
        '&[depth="0"]': {
          '&::after': { content: '"•"', },
          marginLeft: '1rem',
        },
        '&[depth="1"]': {
          '&::after': { content: '"◦"', },
          marginLeft: '2rem',
        },
        '&[depth="2"]': {
          '&::after': { content: '"‣"', },
          marginLeft: '3rem',
        },
        '&[depth="3"]': {
          '&::after': { content: '"⁃"', },
          marginLeft: '4rem',
        },
        '&[depth="4"]': {
          '&::after': { content: '"⦾"', },
          marginLeft: '5rem',
        },
      }
    }
  }

  get internalHTML () {
    return (
      <>
        <div class={this.jss.bullet} depth={this.block.depth} />
        {super.internalHTML}
      </>
    )
  }
}

BlockBulletEditor.register()
