
import RightView from 'MinuteBookPanelProvider/right-view/RightView'
import icons from 'icons'

import boldIcon from 'icons/solid/bold'
import italicIcon from 'icons/solid/italic'
import underlineIcon from 'icons/solid/underline'
import strikethroughIcon from 'icons/solid/strikethrough'
import alignLeftIcon from 'icons/solid/align-left'
import alignCenterIcon from 'icons/solid/align-center'
import alignRightIcon from 'icons/solid/align-right'
import alignJustifyIcon from 'icons/solid/align-justify'

import { color, typography, buttons } from 'styles'
import { toggleFormat } from 'utils/editable'

type WritingCommandSet = import('../WritingCommandSet').default

export default class WritingRightViewFormatting extends RightView {
  commandSet: WritingCommandSet

  constructor (params) {
    super(params)
    const { commandSet } = params
    Object.assign(this, { commandSet })
  }

  get title () { return 'Formatting' }
  get multiTitleItems () { return null }

  static get css () {
    return {
      ...super.css,
      heading: {
        fontWeight: '600',
        padding: '10px 0 10px 40px',
      },
      list: {
        padding: '10px',
        borderRadius: 4,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        margin: '0 40px 20px 40px',
        fontSize: '1.5em',
        background: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          background: color.systemBackground.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        '&[active=true]': {

        },
      },
      _listItem: {
        padding: '2px 0px',
        cursor: 'default',
        userSelect: 'none',
        '&:active': {
          background: color.color.light.blue,
          color: color.text.dark.primary,
        },
      },

      listHeader: {
        extend: '_listItem',
      },

      listRecitals: {
        extend: '_listItem',
      },
      listOperative: {
        extend: '_listItem',
        fontSize: '0.9em',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },

      listSignature: {
        extend: '_listItem',
        fontSize: '0.9em',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },

      listSubHeader: {
        extend: '_listItem',
        fontSize: '0.9em',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },

      listParagraph: {
        extend: '_listItem',
        fontSize: '0.8em',
      },

      listNumbered: {
        extend: '_listItem',
        fontSize: '0.8em',
      },

      listList: {
        extend: '_listItem',
        fontSize: '0.8em',
      },

      listBullet: {
        extend: '_listItem',
        fontSize: '0.8em',
      },

      listQuote: {
        extend: '_listItem',
        fontSize: '0.8em',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },

      listDefinition: {
        extend: '_listItem',
        fontSize: '0.8em',
        fontFamily: typography.altFontFamily,
      },

      buttonRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        marginLeft: 43,
        marginRight: 43,
        height: 26,
        marginBottom: 24,
        borderRadius: '3px',
        background: color.systemBackground.light.primary,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          background: color.systemBackground.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      button: {
        ...buttons.clickable,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '1px 0px 0px 0px',
        '&:last-child': {
          boxShadow: 'unset',
        },
      },
    }
  }

  get body () {
    const { jss } = this
    const simpleFormat = tag => () => toggleFormat(tag)
    const justify = align => () => this.commandSet.setJustify(align)
    const blockType = code => () => this.commandSet.setBodyBlockType(code)
    const insertSignature = () => this.commandSet.commands.insertSignatureBlock.trigger()

    return (
      <div class={jss.body}>
        <div class={jss.heading}> Insert </div>
        <div class={jss.list}>
          {/* <div class={jss.listRecitals}>Recitals</div>
          <div class={jss.listOperative}>Operative Clause</div> */}
          <div class={jss.listSignature} ko-ownClick={insertSignature}>Signature</div>
        </div>
        <hr/>
        <div class={jss.heading}>Styles</div>
        <div class={jss.list}>
          <div class={jss.listHeader} ko-ownClick={blockType('h')}>Header</div>
          <div class={jss.listSubHeader} ko-ownClick={blockType('h2')}>Sub-Header</div>
          <div class={jss.listParagraph} ko-ownClick={blockType('p')}>Paragraph</div>
          <div class={jss.listNumbered} ko-ownClick={blockType('np')}>1. Numbered Paragraph</div>
          <div class={jss.listBullet} ko-ownClick={blockType('b')}>• List</div>
          <div class={jss.listList} ko-ownClick={blockType('l')}>1) Numbered List</div>
          <div class={jss.listQuote} ko-ownClick={blockType('q')}>| Quotation</div>
          {/* <div class={jss.listDefinition}>Definition</div> */}
        </div>
        <hr/>
        <div class={jss.heading}>Text</div>
        <div class={jss.buttonRow}>
          <div class={jss.button} ko-ownClick={simpleFormat('b')}>{icons.inline(boldIcon)}</div>
          <div class={jss.button} ko-ownClick={simpleFormat('i')}>{icons.inline(italicIcon)}</div>
          <div class={jss.button} ko-ownClick={simpleFormat('u')}>{icons.inline(underlineIcon)}</div>
          <div class={jss.button} ko-ownClick={simpleFormat('strike')}>{icons.inline(strikethroughIcon)}</div>
        </div>
        <div class={jss.buttonRow}>
          <div class={jss.button} ko-ownClick={justify('Left')}>{icons.inline(alignLeftIcon)}</div>
          <div class={jss.button} ko-ownClick={justify('Center')}>{icons.inline(alignCenterIcon)}</div>
          <div class={jss.button} ko-ownClick={justify('Right')}>{icons.inline(alignRightIcon)}</div>
          <div class={jss.button} ko-ownClick={justify('Justify')}>{icons.inline(alignJustifyIcon)}</div>
        </div>
      </div>
    )
  }
}

WritingRightViewFormatting.register()
