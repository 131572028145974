
/**
 * CollectionList monitors the given firebaseCollection and updates the
 * observable array on changes.
 */
export default class CollectionList {
  loaded: KnockoutObservable<boolean>
  snapshotCount: KnockoutObservable<number>
  unsubscribeWatch: () => void
  list: KnockoutObservableArray<firebase.firestore.QueryDocumentSnapshot>

  constructor (firestoreQuery: firebase.firestore.Query) {
    Object.assign(this, {
      list: ko.observableArray([]),
      snapshotCount: ko.observable(0)
    })

    this.loaded = ko.pureComputed(() => this.snapshotCount() > 0)

    this.unsubscribeWatch = firestoreQuery
      .onSnapshot(
        snap => this.onSnapshot(snap),
        err => this.onError(err, firestoreQuery)
      )
  }

  dispose () {
    this.unsubscribeWatch()
  }

  /**
   * @param {firebase.firestore.Query}
   * @return {string} identifying the query in a developer-readable way
   */
  queryLogString (query) {
    return query.path || query._query.path.segments.join('/')
  }

  /**
   * @param {firebase.firestore.QuerySnapshot} snap
   */
  onSnapshot (snap: firebase.firestore.QuerySnapshot) {
    for (const change of snap.docChanges()) {
      // change.type is one of added|modified|removed
      this[change.type](change.doc)
    }
    this.snapshotCount.modify(c => ++c)
  }

  /**
   * @param {Error} err
   * @param {firebase.firestore.Query} query
   */
  onError (err, query) {
    const path = this.queryLogString(query)
    console.error(`
      [CollectionList] Snapshot cancelled for ${path}:
    `, err, query)
  }

  /**
   * @param {firebase.firestore.QueryDocumentSnapshot} doc
   */
  added (doc) {
    this.list.push(doc)
  }

  modified (doc) {
    const indexOfDoc = this.list().findIndex(n => n.id === doc.id)
    this.list.splice(indexOfDoc, 1, doc)
  }

  removed (doc) {
    this.list.remove(n => n.id === doc.id)
  }
}
