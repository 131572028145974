
/**
 * @return {any} the result of calling `fnOrValue` if it's a function,
 *  otherwise returns `fnOrValue`
 */
export function result (fnOrValue, ...args) {
  return typeof fnOrValue === 'function' ? fnOrValue(...args) : fnOrValue
}


/** When we have tree-shaking, we can use lodash-es.debounce,
 * but until then that's a huge import for just this function.
 *
 * This is the same as `lodash`, but if this is imported into `libs.ts` it
 * doesn't import the entire `lodash` library.
 */
export const debounce = (fn, wait) => {
  let timeout
  return (...args) => {
    const later = () => {
      timeout = null
      fn.apply(null, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
