import ViewComponent from 'ViewComponent'

import { color } from 'styles'

import {
  CapitalState, Transaction
} from 'capital'

/**
 * Common functionality for organizing shareholders by class or holder
 */
export default abstract class ShareholderGridView extends ViewComponent {
  relatedGenerator: RelatedSource
  state: CapitalState
  persons: PersonRecordSet
  transactions: KnockoutObservableArray<Transaction>

  constructor (params) {
    super()
    const { relatedGenerator, state, transactions, persons } = params
    Object.assign(this, {
      relatedGenerator,
      state,
      transactions,
      persons,
    })
  }

  abstract get headingsHTML () : any[]
  abstract get gridCells () : any[]

  get template () {
    const { jss } = this
    return (
      <div class={jss.grid}>
        {this.headingsHTML}
        {this.gridCells}
      </div>
    )
  }

  get fullBreak () { return (<div class={this.jss.fullBreak}></div>) }
  get partBreak () { return (<div class={this.jss.partBreak}></div>) }

  static get css () {
    return {
      ...super.css,
      fullBreak: {
        gridColumn: '1/-1',
        borderBottom: `1px ${color.separator.light.nonOpaque} dotted`,
        'body[dark] &': { // project batman
          borderBottom: `1px ${color.separator.dark.nonOpaque} dotted`,
        },
      },
      partBreak: {
        gridColumn: '2/-1',
        borderBottom: `1px ${color.separator.light.nonOpaque} dotted`,
        'body[dark] &': { // project batman
          borderBottom: `1px ${color.separator.dark.nonOpaque} dotted`,
        },
      },
      head: {
        fontWeight: 'bold'
      },
    }
  }
}
