
import DropDown from 'drop-down'
import { input, dropdown, color } from 'styles'

/**
 * A Picker is an abstraction of the search-by-query & drop-down.
 */
export default abstract class Picker<T> extends DropDown {
  showing: KnockoutObservable<boolean>
  query: KnockoutObservable<string>
  matches: KnockoutObservableArray<T>
  hasFocus: KnockoutObservable<boolean>
  shortcutsEnabled: KnockoutObservable<boolean>
  highlightIndex: KnockoutObservable<number>
  resetQueryOnSelect: boolean
  maxMatches: number
  _focusOutTimer: NodeJS.Timeout = null
  get _maxMatches() { return this.maxMatches || 25 }
  justSelected: string

  /**
   * Initial choices shown when the query is empty.
   */
  private choicesWhenQueryIsEmpty: Array<T>

  onSelect: (v: T) => void
  filter: (v: T[], query: string) => T[]

  constructor ({ my, at, onSelect, filter, value, inputClass, hasFocus, placeholder, keydownEvents, resetQueryOnSelect, maxMatches, showInitialMatches, initialQuery, choicesWhenQueryIsEmpty, selectOnBlur, showMatchesOnFocus, disableShortcuts, showing }) {
    super({ my, at, showing })
    Object.assign(this, {
      onSelect,
      inputClass,
      maxMatches,
      choicesWhenQueryIsEmpty,
      highlightIndex: ko.observable(null),
      _placeholder: placeholder,
      keydownEvents: keydownEvents || {},
      hasFocus: hasFocus || ko.observable(false),
      shortcutsEnabled: ko.observable(!disableShortcuts),
      filter: filter || (() => true),
      query: (value || ko.observable(initialQuery || '')).extend({ rateLimit: 75 }),
      matches: ko.observableArray([]).extend({ deferred: true }),
      resetQueryOnSelect: resetQueryOnSelect || false,
    })

    this.subscribe(this.query, q => this.updateMatches(q))

    if (showInitialMatches) {
      Promise.delay(5).then(() => this.updateMatches(this.query() || ''))
    }

    if (showMatchesOnFocus) {
      this.hasFocus.subscribe(f => this.showing(f))
    }

    if (selectOnBlur) {
      this.subscribe(this.hasFocus, () => this.onSelectEvent(this.query()))
    }
  }

  selectClick (item: T) {
    this.onSelectEvent(item)
  }

  itemAsString (item: T | string) : string {
    return item.toString()
  }

  /**
   * Overload this to cast from `string` to the returned value.
   */
  selectValueOf (item: T|string) : any { return item }

  onSelectEvent (item: T|string) {
    const asString = this.itemAsString(item)
    this.query(this.resetQueryOnSelect ? '' : asString)
    this.justSelected = asString
    this.query.notifySubscribers(this.query())
    if (this.onSelect) {
      this.onSelect(this.selectValueOf(item))
    }
    this.showing(false)
  }

  onArrowDown (evt) {
    if (this.showing() && !this.onlyMatchIsSelected()) {
      evt.stopImmediatePropagation()
    }
    this.highlightIndex.modify(i => i === null ? 0
      : Math.min(++i, this.matches.length - 1, this._maxMatches - 1))
  }

  onArrowUp (evt) {
    if (this.showing() && !this.onlyMatchIsSelected()) {
      evt.stopImmediatePropagation()
    }
    this.highlightIndex.modify(i => i === null ? 0 : Math.max(0, --i))
  }

  /**
   * May be overloaded, for e.g. adding new tags.
   */
  onEnter (query: string) {
    const hi = this.highlightIndex()
    if (typeof hi === "number") {
      this.onSelectEvent(this.matches()[hi])
      return
    }
    if (!this.matches()) { return }
    if (this.matches().length === 1) {
      this.onSelectEvent(this.matches()[0])
    } else {
      this.onSelectEvent(query)
    }
  }

  updateMatches (query) {
    this.highlightIndex(null)
    if (!query) {
      if (this.choicesWhenQueryIsEmpty) {
        this.showChoicesWhenQueryIsEmpty()
        return
      }
    } else if (query !== this.justSelected) {
      this.showing(true)
    }
    this.justSelected = null
    const matches = this.choices()
      .filter(item => this.pickerFilterChoice(item, query))
      .slice(0, this._maxMatches)
    this.matches(matches)
  }

  showChoicesWhenQueryIsEmpty () {
    this.matches(ko.unwrap(this.choicesWhenQueryIsEmpty))
  }

  pickerFilterChoice (item, query) {
    return this.filter(item, query) && this.filterChoice(item, query)
  }

  abstract filterChoice (item: T, query: string) : boolean
  abstract choices () : T[]
  abstract itemHTML (item: T, index: number) : any

  static get css () {
    return {
      ...super.css,
      picker: {},
      list: {
        ...dropdown.generalMenu,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '150px',
        overflowY: 'scroll',
        overflowX: 'auto',
        maxWidth: '50vw',
        marginLeft: 0,
        backgroundColor: color.systemBackground.light.primary,
        '&:empty': {
          display: 'none',
        }
      },
      item: {
        ...dropdown.item,
        display: 'flex',
        alignItems: 'center'
      },
      highlight: {
        extend: 'item',
        backgroundColor: color.searchHighlight,
      },
      input: {
        ...input.text,
        width: '100%',
      },
      message: {
        fontSize: '0.9rem',
        color: color.text.light.secondary,
        padding: '8px 0.5rem',
        backgroundColor: color.systemBackground.light.primary,
        whiteSpace: 'nowrap',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.secondary,
        },
      },
      shortcut: {
        marginLeft: 'auto',
        paddingLeft: '12px',
        fontSize: '0.6rem',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
        '&[enabled=true]': {
          color: 'green',
        },
      }
    }
  }

  /**
   * Return true when a given item is selected (i.e. matches the
   * query) */
  itemIsSelected (t: T) : boolean {
    return this.itemAsString(t) === this.query()
  }

  get placeholderMessage () { return this._placeholder || 'Enter text' }
  get noMatchesMessage () { return 'Nothing matches' }
  get searchMessage () { return 'Type to search' }

  scrollItemIntoView (node: HTMLElement) {
    if (!node || !node.parentElement) { return }
    const { scrollTop, clientHeight } = node.parentElement
    if (node.offsetTop < scrollTop) {
      node.parentElement.scroll(0, node.offsetTop)
    } else if (node.offsetTop + node.clientHeight > scrollTop + clientHeight) {
      node.parentElement.scroll(0, node.offsetTop + node.clientHeight - clientHeight)
    }
  }

  itemOuterHTML (item, index) {
    const { jss } = this
    const node = ko.observable<HTMLElement>()
    const highlighted = this.computed(() => this.highlightIndex() === index)
    const css = this.computed(() => {
      if (!highlighted() || !node()) { return jss.item }
      this.scrollItemIntoView(node())
      return jss.highlight
    })
    return (
      <div class={css}
        ko-set-node={node}
        ko-ownClick={() => this.selectClick(item)}>
        {this.itemHTML(item, index)}
        <div class={jss.shortcut}
          enabled={this.shortcutsEnabled}>
          {index < 9 ? index + 1 : ''}
        </div>
      </div>
    )
  }

  onlyMatchIsSelected () {
    const matches = this.matches()
    if (matches.length !== 1) { return false }
    const [m0] = matches
    return this.itemIsSelected(m0)
  }

  get listHTML () {
    const { jss, matches } = this
    if (!this.matches()) { return null }
    if (this.onlyMatchIsSelected()) {
      this.showing(false)
      return null
    }
    if (matches.length) {
      return matches().map(this.itemOuterHTML.bind(this))
    }
    const message = this.query()
      ? this.noMatchesMessage
      : this.searchMessage
    return (
      <div class={jss.message}>
        {message}
      </div>
    )
  }

  /**
   * @param {Event} evt
   * @param {integer} index
   * @return {boolean} true when a selection is picked
   */
  selectChoice (evt, index) {
    const enabled = this.shortcutsEnabled()
    const matches = this.matches() || []
    const matchesIndex = matches.length > index
    if (enabled && matchesIndex) {
      evt.preventDefault()
      evt.stopPropagation()
      this.onSelectEvent(matches[index])
      return true
    }
    return false
  }

  toggleShortcuts (evt) {
    if (this.shortcutsEnabled()) {
      evt.preventDefault()
      evt.stopPropagation()
    }
    this.shortcutsEnabled.modify(v => !v || undefined)
  }

  get shortcutKeyboardShortcuts () {
    return {
      '1': evt => this.selectChoice(evt, 0),
      '2': evt => this.selectChoice(evt, 1),
      '3': evt => this.selectChoice(evt, 2),
      '4': evt => this.selectChoice(evt, 3),
      '5': evt => this.selectChoice(evt, 4),
      '6': evt => this.selectChoice(evt, 5),
      '7': evt => this.selectChoice(evt, 6),
      '8': evt => this.selectChoice(evt, 7),
      '9': evt => this.selectChoice(evt, 8),
      'shift+#': evt => this.toggleShortcuts(evt)
    }
  }

  get keyboardShortcuts () {
    return {
      Enter: () => this.onEnter(this.query()),
      ArrowDown: evt => this.onArrowDown(evt),
      ArrowUp: evt => this.onArrowUp(evt),
      ...this.shortcutKeyboardShortcuts,
      ...this.keydownEvents,
    }
  }

  get anchorEvents () {
    return {
      focusout: () => this.onFocusOut(),
      focusin: () => this.onFocusIn(),
    }
  }

  /*
   * This also triggers when the user clicks an item in the list
   * but there doesn't seem to be a way to detect that, so we
   * just have to make the timer long enough for the click event
   * to be handled. If we close too soon the click is lost.
   */
  onFocusOut () {
    this._focusOutTimer = setTimeout(() => this.showing(false), 100)
    return true
  }

  onFocusIn () {
    clearTimeout(this._focusOutTimer)
    this.showing(true)
    setTimeout(() => this.updateMatches(this.query()))
    return true
  }

  get anchorHTML () {
    const { jss } = this
    return <input type='text'
      class={this.inputClass || jss.input}
      ko-textInput={this.query}
      ko-keydown={this.keyboardShortcuts}
      ko-ownClick={() => {}}
      hasFocus={this.hasFocus}
      placeholder={this.placeholderMessage} />
  }

  get contentHTML () {
    const { jss } = this
    return (
      <div class={jss.list}>
        {this.computed(() => this.listHTML).extend({ deferred: true })}
      </div>
    )
  }
}
