import { noop } from 'lodash-es'

import {
  startOfMonth, isBefore, isSameMonth, setDay, addDays, format,
  startOfWeek, isSameDay, addMonths, addYears, startOfDay, parseISO
} from 'date-fns/esm'

import ViewComponent from 'ViewComponent'
import { buttons, color, typography } from 'styles'
import { formatForUser, safeFormat } from 'utils/dates'

import icons from 'icons'
import leftArrow from 'icons/solid/caret-left'
import rightArrow from 'icons/solid/caret-right'

const showRelated = ko.observable(true)
  .extend({ localStorage: 'mb.date.picker.showRelated' })

export default class DatePicker extends ViewComponent {
  focus: KnockoutObservable<Date>
  showRelated: KnockoutObservable<boolean>
  inputFocus: KnockoutObservable<boolean>
  inputIsBadDate: KnockoutObservable<boolean> = ko.observable(false)
  selected: Date
  relatedDates: RelatedDatesSource
  onSelect: (Date) => void
  onClear: () => void
  drawerSide: boolean

  constructor (params) {
    super()
    const given = params.given || startOfDay(new Date())
    Object.assign(this, {
      showRelated,
      relatedDates: params.relatedDates,
      onSelect: params.onSelect,
      onClear: params.onClear || noop,
      selected: given,
      inputFocus: params.inputFocus || ko.observable<boolean>(false),
      focus: params.focus || ko.observable<Date>(given),
      drawerSide: params.drawerSide || 'right'
    })

    // `this.focus` must be defined.
    this.focus.modify(d => d || new Date())
  }

  static get css () {
    return {
      layout: {
        display: 'grid',
        position: 'relative',
        gap: '3px 20px',
        /**
         * ym: year-month area
         * cd: calendar days
         * ti: time area
         * cl: clear area
         * to: today area
         * rl: related list
         */
        '&[rl=right]': {
          gridTemplateColumns: '1fr 1fr auto',
          gridTemplateAreas: `
            'ym ym rl'
            'cd cd rl'
            'i  i  rl'
            'cl to rl'
          `,
        },

        '&[rl=left]': {
          gridTemplateColumns: 'auto 1fr 1fr',
          gridTemplateAreas: `
            'rl ym ym'
            'rl cd cd'
            'rl i  i '
            'rl cl to'
          `,
        }
      },
      ...this.clearCSS,
      ...this.daysCSS,
      ...this.inputCSS,
      ...this.monthCSS,
      ...this.relatedCSS,
      ...this.timeCSS,
      ...this.todayCSS,
    }
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.layout} rl={this.drawerSide}>
        {this.monthHTML}
        {this.daysGridHTML}
        {this.timeHTML}
        {this.inputHTML}
        {this.clearHTML}
        {this.todayHTML}
        {this.relatedDatesHTML}
      </div>
    )
  }

  static get inputCSS () {
    return {
      input: {
        gridArea: 'i',
        outline: 'none',
        width: '100%',
        textAlign: 'center',
        borderRadius: 4,
        backgroundColor: color.textInput.light.primary,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        color: color.text.light.primary,
        height: '1.4rem',

        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          backgroundColor: color.textInput.dark.secondary,
          color: color.text.dark.primary
        },

        '&[bad=true]': {
          boxShadow: `0px 0px 3px red`,
        }
      }
    }
  }

  /**
   * Update the `focus` based on the string input.
   * @returns `false` if it's a bad date
   */
  inputStringToFocus (value: string) : boolean {
    if (/\d{4}-\d{2}-\d{2}/.test(value)) {
      try {
        this.focus(parseISO(value))
        return true
      } catch (err) {}
    }
    return false
  }

  get inputHTML () {
    const { jss } = this
    const input = this.computed({
      read: () => this.focus().toISOString().slice(0, 10),
      write: v => this.inputIsBadDate(!this.inputStringToFocus(v))
    })

    const focus = ko.observable(false).extend({ rateLimit: 25 })
    this.subscribe(this.inputFocus, focus)
    return (
      <input type='text' class={jss.input} bad={this.inputIsBadDate}
        ko-hasFocus={focus}
        ko-textInput={input} />
    )
  }

  static get monthCSS () {
    return {
      yearMonthArea: {
        gridArea: 'ym',
        display: 'grid',
        gridTemplateColumns: '1fr 3fr repeat(4, 1fr)',
      },
      monthName: {
        fontWeight: 'bold',
        textAlign: 'center',
      },
      _arrow: {
        ...buttons.clickable,
        padding: '0px 10px'
      },
      monthNext: {
        extend: '_arrow',
        textAlign: 'left',
      },
      monthPrev: {
        extend: '_arrow',
        textAlign: 'right',
      },
      year: {
        fontWeight: 'bold',
        textAlign: 'center',
      },
      yearNext: {
        extend: '_arrow',
        textAlign: 'left',
      },
      yearPrev: {
        extend: '_arrow',
        textAlign: 'right',
      },
    }
  }

  get monthHTML () {
    const { jss } = this
    const _iter = (by: number, fn: typeof addYears, evt: MouseEvent) => {
      evt.preventDefault()
      evt.stopPropagation()
      this.focus.modify(f => fn(f, by))
    }
    const monthPrev = _iter.bind(null, -1, addMonths)
    const monthNext = _iter.bind(null, 1, addMonths)
    const yearPrev = _iter.bind(null, -1, addYears)
    const yearNext = _iter.bind(null, 1, addYears)

    return (
      <div class={jss.yearMonthArea}>
        <div class={jss.monthPrev} ko-click={monthPrev}>
          {icons.inline(leftArrow)}
        </div>
        <div class={jss.monthName}>
          {this.computed(() => safeFormat(this.focus(), 'MMM'))}
        </div>
        <div class={jss.monthNext} ko-click={monthNext}>
          {icons.inline(rightArrow)}
        </div>
        <div class={jss.yearPrev} ko-click={yearPrev}>
          {icons.inline(leftArrow)}
        </div>
        <div class={jss.year}>
          {this.computed(() => safeFormat(this.focus(), 'yyyy'))}
        </div>
        <div class={jss.yearNext} ko-click={yearNext}>
          {icons.inline(rightArrow)}
        </div>
      </div>
    )
  }

  static get daysCSS () {
    return {
      calDays: {
        gridArea: 'cd',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridTemplateRows: 'repeat(7, 1fr)',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px'
      },
      calHead: {
        textAlign: 'center',
      },
      _calDay: {
        ...buttons.clickable,
        textAlign: 'center',
        backgroundColor: color.Calendar.dayInMonth,
        'body[dark] &': { // project batman
          backgroundColor: color.dmCalendar.dayInMonth,
        },
        borderRadius: '2px',
        transition: 'background-color 80ms ease-in-out',
        // selected is today's date
        '&[selected]': {
          backgroundColor: color.Calendar.hover,
          boxShadow: `0px 0px 4px ${color.gray.c}`,
          'body[dark] &': { // project batman
            backgroundColor: color.dmCalendar.hoverColor,
            boxShadow: 'unset',
            border: '1px solid red'
          },
        },
        // focused is any date that has been selected
        '&[focused]': {
          backgroundColor: color.color.light.red,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.red,
          },
        },
        '&:hover': {
          backgroundColor: color.Calendar.hover,
          transition: 'background-color 80ms ease-in-out',
          'body[dark] &': { // project batman
            backgroundColor: color.dmCalendar.hoverColor ,
          },
        }
      },
      calDayBeforeMonthShown: {
        extend: '_calDay',
        color: color.gray.a,
      },
      calDay: {
        extend: '_calDay',
      },
      calDayAfterMonthShown: {
        extend: '_calDay',
        color: color.gray.a,
      },
    }
  }

  get daysGridHTML () {
    const { jss } = this
    return (
      <div class={jss.calDays}>
        {this.calHead}
        {this.computed<any[]>(() => [...this.calDays(this.focus() || new Date())])}
      </div>
    )
  }

  get calHead () {
    const { jss } = this
    const d = setDay(new Date(), 0)
    return [...new Array(7)]
      .map((_, i) => <div class={jss.calHead}>{format(addDays(d, i), 'E')}</div>)
  }

  * calDays (ofDate: Date) : IterableIterator<any> {
    const { jss } = this

    const monthStart = startOfMonth(ofDate)
    let day : Date = startOfWeek(monthStart)

    while (isBefore(day, monthStart)) {
      yield this.dayHTML(day, jss.calDayBeforeMonthShown)
      day = addDays(day, 1)
    }

    while (isSameMonth(day, ofDate)) {
      yield this.dayHTML(day, jss.calDay)
      day = addDays(day, 1)
    }

    while (day.getDay()) {
      yield this.dayHTML(day, jss.calDayAfterMonthShown)
      day = addDays(day, 1)
    }
  }

  focusOrSelect (day: Date) {
    if (isSameDay(this.focus(), day)) {
      this.onSelect(startOfDay(this.focus()))
    } else {
      this.focus(day)
    }
  }

  dayHTML (day: Date, cssClass: string) {
    return (
      <div class={cssClass}
        selected={this.computed(() => isSameDay(this.selected, day) || undefined)}
        focused={this.computed(() => isSameDay(this.focus(), day) || undefined)}
        ko-ownClick={() => this.focusOrSelect(day)}>
        {day.getDate()}
      </div>
    )
  }

  static get timeCSS () {
    return {
      time: {
        gridArea: 'ti',
        marginTop: '10px'
      },
      timeInput: {
        display: 'none',
        padding: '4px',
        width: '100%',
        textAlign: 'center',
        height: 40,
        fontSize: '1em',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,0.1)',
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
          color: color.text.dark.primary
        },
      }
    }
  }

  get timeHTML () {
    return
    // const { jss } = this
    // const obs = ko.observable<string>(format(this.focus(), 'HH:mm'))

    // this.subscribe<string>(obs, time => {
    //   this.focus.modify(v => parse(time || '00:00', 'HH:mm', v))
    // })
    // return (
    //   <div class={jss.time}>
    //     <input class={jss.timeInput} type='time' ko-value={obs} />
    //   </div>
    // )
  }

  static get clearCSS () {
    return {
      clearArea: {
        ...this.todayCSS.todayArea,
        ...buttons.clickable,
        gridArea: 'cl'
      },
      cleaerButton: {
      },
    }
  }

  get clearHTML () {
    const { jss } = this
    if (!this.onClear) { return }
    return (
      <div class={jss.clearArea} ko-ownClick={() => this.onClear()}>
        <div class={jss.clearButton}>Clear</div>
      </div>
    )
  }

  static get todayCSS () {
    return {
      todayArea: {
        ...buttons.clickable,
        gridArea: 'to',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        background: '#ececec',
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
          color: color.text.dark.primary
        },
        borderRadius: 5,
      },
      todayButton: {
      }
    }
  }

  get todayHTML () {
    const { jss } = this
    const today = () => this.focus(startOfDay(new Date()))
    return (
      <div class={jss.todayArea}
        ko-ownClick={() => today()}>
        <div class={jss.todayButton}>Today</div>
      </div>
    )
  }

  static get relatedCSS () {
    return {
      relatedList: {
        gridArea: 'rl',
        position: 'absolute',
        width: '340px',
        padding: '0px 30px',
        boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
        zIndex: -1,
        backgroundColor: color.brandYellow,
        transition: '0.45s ease-in-out',

        '$layout[rl=right] &': {
          left: 'var(--related-list-horizontal, 30px)',
          top: 'var(--related-list-top, -60px)',
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          transform: 'translate3d(-100%, 0, 0)',

          '&[open=true]': {
            transform: 'translate3d(0, 0, 0)',
          },
        },

        '$layout[rl=left] &': {
          left: 'var(--related-list-horizontal, 30px)',
          top: 'var(--related-list-top, -60px)',
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
          transform: 'translate3d(-30px, 0, 0)',

          '&[open=true]': {
            transform: 'translate3d(-100%, 0, 0)',
          },
        }
      },
      relatedTab: {
        ...buttons.clickable,
        position: 'absolute',
        top: 40,
        padding: '8px 15px',
        backgroundColor: '#ffd502',
        color: '#4a4a4a',
        fontWeight: 'bold',
        cursor: 'pointer',
        outline: 'none',
        fontFamily: typography.altFontFamily,

        '$layout[rl=right] &': {
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          left: 'calc(100% - 25px)',
          transform: 'rotate(90deg)',
          boxShadow: '0px -3px 2px -2px rgba(0,0,0,0.10)',
        },

        '$layout[rl=left] &': {
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          left: 'calc(-55px)',
          transform: 'rotate(-90deg)',
          boxShadow: '0px -3px 2px -2px rgba(0,0,0,0.10)',
        },
      },
      relatedListItems: {
        overflowY: 'auto',
        height: 'var(--related-list-items-height, 370px)',
        padding: '20px',
        // Put the scroll-bar on the left-hand side.
        //direction: 'rtl',
      },
      related: {
        ...buttons.clickable,
        border: `1px solid ${color.gray.ticklerBorder}`,
        borderRadius: '4px',
        padding: '5px',
        borderLeft: `4px solid var(--event-color, #4A90E2)`,
        marginBottom: '6px',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: color.Calendar.ticklerHover
        },
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
          backgroundColor: color.systemBackground.dark.secondary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          borderLeft: `4px solid var(--event-color, #4A90E2)`,
          transition: 'background-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
          '&:hover': {
            transition: 'background-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
            backgroundColor: color.systemBackground.dark.primary,
            boxShadow: '1px 1px 7px 3px rgba(0,0,0,0.4)',
          }
        },
      },
      relatedDate: {
        color: 'black',
        fontSize: '0.9rem',
        textAlign: 'left',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      relatedReason: {
        color: color.text.light.secondary,
        fontWeight: 'lighter',
        textAlign: 'left',
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
    }
  }

  sortByDate (a, b) {
    return a.date - b.date
  }

  relatedDatesListHTML (relatedDates) {
    const list = [
      ...relatedDates.genRemindersAtDate(this.focus(), 'forever')]
    return list.sort(this.sortByDate).map(d => this.relatedHTML(d))
  }

  get relatedDatesHTML () {
    const { jss } = this
    const { relatedDates } = this
    if (!relatedDates) { return }
    const dates = this.computed<RelatedDate>(
      () => this.relatedDatesListHTML(relatedDates)
    ).extend({ rateLimit: 200, deferred: true })
    const toggle = () => this.showRelated.modify(v => !v)
    return (
      <div class={jss.relatedList} open={this.showRelated}>
        <div class={jss.relatedTab}
          ko-ownClick={toggle}>Related</div>
        <div class={jss.relatedListItems}>
          {dates}
        </div>
      </div>
    )
  }

  relatedHTML (r: RelatedDate) {
    const { jss } = this
    return (
      <div class={jss.related}
        ko-ownClick={() => this.focusOrSelect(r.date)}>
        <div class={jss.relatedDate}>{formatForUser(r.date)}</div>
        <div class={jss.relatedReason}>{r.reason}</div>
      </div>
    )
  }
}

DatePicker.register()
