
import SlotGroup from './SlotGroup'

type VariableType = import('../registry').VariableType
type Variable = import('../Variable').default

/**
 * The `SlotManager` class keeps track of the slots for variables and
 * the initial state of variables.
 */
export default class SlotManager {
  private slotValues: Record<string, string> = {}
  private groups: Record<string, SlotGroup> = {}
  private groupCount = ko.observable(0) // for computeds.

  setSlotVariable (name: string, value: string) {
    this.slotValues[name] = value
  }

  * [Symbol.iterator] () {
    this.groupCount()
    yield * Object.values(this.groups)
  }

  slotGroupFor (v: Variable): SlotGroup {
    this.groupCount()
    return this.groups[v.slotGroupCode] || this.addSlotGroup(v)
  }

  private addSlotGroup (v: Variable) {
    setTimeout(() => this.groupCount.modify(v => ++v))
    return this.groups[v.slotGroupCode] = new SlotGroup(v)
  }

  slotGroupByCode (code: string) {
    this.groupCount()
    return this.groups[code] || null
  }

  /**
   * Return the index in the variable's slot group.
   */
  indexOf (v: Variable) { return this.slotGroupFor(v).indexOf(v) }
  getVariableValue (name: string): string { return this.slotValues[name] }
  hasVariable (name: string) { return name in this.slotValues }
  get variableList (): string[] { return Object.keys(this.slotValues) }

  setIndexOf (v: Variable, i: number|string) {
    return this.slotGroupFor(v).setIndexOf(v, i)
  }

  * knownVariables (): IterableIterator<Variable|VariableType> {
    this.groupCount()
    for (const group of Object.values(this.groups)) {
      for (const slot of group) {
        for (const b of slot) { yield b as VariableType }
      }
    }
  }

  * knownBoundVariables () {
    for (const b of this.knownVariables()) {
      if (b.isAttachedToDOM) { yield b }
    }
  }

  addVariable (v: Variable) {
    return this.slotGroupFor(v).setIndexOf(v, v.slotIndex() || 0)
  }

  removeVariable (v: Variable) { this.slotGroupFor(v).removeVariable(v) }

  propagateChange (from: VariableType) {
    this.slotGroupFor(from).propagateChangesToPeers(from)
  }
}
