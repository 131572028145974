
import { color } from 'styles'

import 'modal-dialog'
import 'confirm-delete/confirm-page-delete'

import PdfViewer from './PdfViewer'
import { DisposedError } from 'pdf-engine/PDFEngine'

import BookSearchResult from './BookSearchResult'
type PSPDFKitRect = import('pdf-engine/PDFEngine').PSPDFKitRect

/**
 * <pdf-pages> shows the main pages for the PDF viewer
 */
export default class PdfPages extends PdfViewer {
  perPageHeightPx: KnockoutComputed<number>

  constructor (...args) {
    super(...args)

    Object.assign(this, {
      canvasWidth: ko.observable('60vw'),
      // canvasWidth: ko.observable(`${this.zoom() * 0.6 * window.innerWidth}px`),
      perPageHeightPx: ko.computed(() => window.innerHeight * this.zoom() * 0.6)
    })

    this.subscribe(this.zoom, zoom => this.zoomUpdateSinglePage(zoom))
    this.zoomUpdateSinglePage(this.zoom())
    this.subscribe(this.truckBounds, () => this.zoomUpdateSinglePage(this.zoom()))
    this.computed(() => this.listenForFocus())
  }

  scrollTo (y) {
    const scrollElement = ko.unwrap(this.scrollElement)
    if (scrollElement) {
      scrollElement.scrollTop = 0
      scrollElement.scrollBy(0, y)
    } else {
      window.scrollTo(0, y)
    }
  }

  listenForFocus () {
    const indexToFocus = this.bookPages().findIndex(p => p.focused())
    if (indexToFocus < 0) { return }
    this.scrollTo(this.calcPageStartPx(indexToFocus) - 200)
  }

  /**
   * Update zoom level in single page view when slider changes
   */
  zoomUpdateSinglePage (zoom) {
    const indexToFocus = this.currentPage() - 1
    requestAnimationFrame(() => {
      this.scrollTo(this.calcPageStartPx(indexToFocus) - 200)
    })
  }

  calcPageStartPx (index) {
    return this.perPageHeightPx() * index + this.heightOffsetPx
  }

  static get highlightCSS () {
    return {
      highlightOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      highlightRect: {
        position: 'absolute',
        transformOrigin: 'top left',
        color: 'transparent',
        opacity: '0.4',
        padding: '2px',
        fontSize: '12px',
        backgroundImage: 'linear-gradient(to bottom, rgba(225,225,225,1), rgba(150,150,150,1))',
        boxShadow: '0px 1px 4px 1px rgba(0,0,0,0.6)',
        '&[focused]': {
          backgroundImage: 'linear-gradient(to bottom, rgba(247,232,8,1), rgba(242,201,41,1))',
        }
      },
    }
  }

  pageSearchHighlightsHTML (index, maxHeightPx) {
    const { jss } = this
    const overlayElement = ko.observable<Element>()

    const getResults = () => {
      return this.searchData.getResultsFor(index)
    }

    let elementRatio = undefined
    const pageScale = this.computed<{x:number, y:number}>(() => {
      if (!getResults().length || !overlayElement()) { return null }
      elementRatio = elementRatio || overlayElement().clientWidth / overlayElement().clientHeight
      if (!elementRatio) { setTimeout(() => overlayElement.valueHasMutated(), 10); return null }
      const pageInfo = getResults()[0].pdfkitresult.pageInfo
      const { width, height } = pageInfo
      const x = width / (maxHeightPx() * elementRatio)
      const y = height / maxHeightPx()
      return { x, y }
    })

    const highlightRect = (rect: PSPDFKitRect, bsr: BookSearchResult) => {
      const highlightElement = ko.observable<Element>()
      const padding = 2
      const style = this.computed(() => {
        if (!highlightElement()) { return '' }
        if (!pageScale()) { return '' }
        const x = ( rect.left / pageScale().x ) - padding
        const y = ( rect.top / pageScale().y ) - padding
        const scaleX = rect.width / highlightElement().clientWidth / pageScale().x
        const scaleY = rect.height / highlightElement().clientHeight / pageScale().y
        return (`
          transform: translate3d(${x}px, ${y}px, 0) scale3d(${scaleX}, ${scaleY}, 1);`
      )})
      return (
        <div class={jss.highlightRect}
          style={style}
          ko-set-node={highlightElement}
          focused={this.computed(() => bsr.focused() || undefined)} >
            {bsr.escapedQuery}
        </div>
      )
    }

    const resultHighlights = (bsr: BookSearchResult) => (
      bsr.pdfkitresult.rectsOnPage.map(rect => highlightRect(rect, bsr))
    )

    const highlights = this.computed(() => {
      return getResults().map(bsr => resultHighlights(bsr))
    })

    return (
      <div class={jss.highlightOverlay} ko-set-node={overlayElement}>
          {highlights}
      </div>
    )
  }

  pageHTML (bookPage: BookPage, givenIndex) {
    const { jss } = this
    const index = this.computed<number>(() => this.book.getIndexOfpageID(bookPage.pageID))
    const currentPage = this.computed(p =>
      this.currentPage() === index() + 1 || undefined)
    // The current behaviour of TKO JSX is to copy the Canvas, which loses
    // all the content of the canvas.
    const pageNode = ko.observable()
    const imageSrc = ko.observable<string>()
    const textLayer = ko.observableArray<JSX>()
    const loading = ko.observable(<loading-spinner class={jss.loading} />)
    const matches = this.computed(() => this.searchData.getResultsFor(index()))

    pageNode.once(async () => {
      try {
        imageSrc(await this.pdfEngine.renderAsURL(bookPage, 1200, true))
        textLayer(await this.pdfEngine.getTextLines(bookPage)
          .then(textLines => (
            <pdf-text-layer
              textLines={textLines}
              searchMatches={matches}
              pageHeight={maxHeightPx} />
          ))
        )
        loading(null)
      }
      catch (e) {
        if (!(e instanceof DisposedError)) { throw e }
      }
    })

    this.loadOrRenderThumb(bookPage)
      .then(url => imageSrc.modify(v => v || url))

    /**
     * Montior viewport intersection. When the top of the element is within
     * a distance of half the height of a full page, either above or below,
     * from the top of the viewport then set the current page to this index.
     */
    const intersectionEntry = ko.observable()
    intersectionEntry.subscribe(entry => {
      if (!entry || !entry.intersectionRatio) { return }
      const bounds = entry.boundingClientRect
      const pageHeight = bounds.bottom - bounds.top
      const halfHeight = pageHeight / 2
      const topOffset = 93
      const minTop = topOffset - halfHeight
      const maxTop = topOffset + halfHeight
      if (minTop < bounds.top && bounds.top < maxTop) {
        this.currentPage(index() + 1)
      }
    })

    const maxHeightPx = this.computed(() => this.perPageHeightPx() - 20)
    const maxHeight = this.computed(() => `${maxHeightPx()}px`)
      .extend({ deferred: true })
    const top = this.computed(() => `${this.calcPageStartPx(index())}px`)
      .extend({ deferred: true })


    return (
      <div class={jss.mainPage}
        page-index={index}
        is-current={currentPage}
        ko-set-node={pageNode}
        ko-viewport={intersectionEntry}
        ko-scrollTo={bookPage.focused}
        ko-style-map={{ top, height: maxHeight, '--image-max-height': maxHeight }}>
        <div class={jss.canvasContainer}>
          { this.deleteHTML(bookPage) }
          { this.noteHTML(bookPage) }
          { this.bookmarkHTML(bookPage) }
          { this.sectionHTML(bookPage) }
          { this.currentPageHTML(index() + 1) }
          { this.pageSearchHighlightsHTML(index(), maxHeightPx) }
          {textLayer}
          <img src={imageSrc} class={jss.canvas} draggable='false' />
          {loading}
        </div>
      </div>
    )
  }

  /**
   * @param {int} index of the bookPage
   * @param {DOMRect} rect of the pages DOM element
   * We preload pages before and after the current page, to make scrolling
   * appear snappier.
   */
  isOnScreen (index, rect, perPageHeightPx) {
    if (!rect) { return false }
    const PAGES_BEFORE = 2
    const PAGES_AFTER = 3
    const startsAfter = (index + PAGES_AFTER) * perPageHeightPx > -1 * rect.top
    const endsBefore = (index - PAGES_BEFORE) * perPageHeightPx < -1 * rect.top + window.innerHeight * 2
    return startsAfter && endsBefore
  }

  /**
   * @return {string} the computed height of all the pages.
   * PAD is a number of pixels added to the bottom of the page
   */
  get containerHeightPx () {
    const PAD = 300
    const px = this.perPageHeightPx() * this.bookPages().length + PAD
    return `${px}px`
  }

  get pagesHTML () {
    const rect = ko.observable().extend({ rateLimit: 10 })
    const pageContainerHeight = this.computed(() =>
      this.bookPages.length ? this.containerHeightPx : undefined)

    const pagesOnScreen = ko.observableArray([])

    const currentlyOnScreen = new Set()
    this.computed(() => {
      const r = rect()
      const h = this.perPageHeightPx()
      const allPages = super.pageListHTML
      const onScreen = allPages().filter((_, i) => this.isOnScreen(i, r, h))
      this.bookPages().forEach((p, i) => p.inViewport(this.isOnScreen(i, r, h)))

      for (const page of currentlyOnScreen) {
        if (onScreen.includes(page)) { continue }
        pagesOnScreen.remove(page)
        currentlyOnScreen.delete(page)
      }
      for (const page of onScreen) {
        if (currentlyOnScreen.has(page)) { continue }
        pagesOnScreen.push(page)
        currentlyOnScreen.add(page)
      }
    }).extend({ deferred: true })

    const { scrollElement } = this
    const koScrollObserver = scrollElement ? { scrollElement, rect } : rect

    return (
      <div class={this.jss.pages}
        ko-scroll-observer={koScrollObserver}
        ko-style-map={{ height: pageContainerHeight }}>
        {pagesOnScreen}
        {this.computed(() =>
          this.bookPages.length ? undefined : this.noPagesHTML)}
      </div>
    )
  }

  /**
   * When it is in the viewport, it becomes the current page.
   */
  currentPageHTML (pageNumber) {
    return (
      <div class={this.jss.currentPage}>
        {pageNumber}
      </div>
    )
  }

  static get pdfPageCSS () {
    return {
      currentPage: {
        position: 'absolute',
        left: 'calc(100% + 10px)',
        top: '15px',
        color: color.gray.b,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
        fontSize: '14px',
        '[is-current] &': {
          color: 'black',
          'body[dark] &': { // project batman
            color: color.text.dark.primary,
          },
        }
      },
      midline: {
        position: 'absolute',
        // border: '1px solid pink',
        left: '0px',
        width: 'calc(100% + 30px)',
        top: '15vh',
        height: `calc(100% - 30vh)`,
      }
    }
  }

  get truckVars () {
    return {
      '--canvas-width': '50vw'
    }
  }

  static get iconCSS () {
    return {
      ...super.iconCSS,
      note: {
        extend: '_marker',
        '--active-color': 'navy',
        right: '5px',
        '&[exists]': {
          visibility: 'visible',
          '--icon-color': color.color.light.yellow,
          'body[dark] &': {
            '--icon-color': color.color.dark.yellow,
          }
        },
      },

      bookmark: {
        extend: '_marker',
        '--active-color': color.color.light.red,
        right: '1.55em',
        '&[exists]': {
          visibility: 'visible',
          '--icon-color': color.color.light.red,
          'body[dark] &': {
            '--icon-color': color.color.dark.red,
          }
        },
      },

      section: {
        extend: '_marker',
        '--active-color': color.color.light.blue,
        right: '2.75em',
        '&[exists]': {
          visibility: 'visible',
          '--icon-color': color.color.light.blue,
          'body[dark] &': {
            '--icon-color': color.color.dark.blue,
          }
        },
      }
    }
  }


  static get css () {
    return {
      ...super.css,
      ...this.pdfPageCSS,
      ...this.highlightCSS,
      truck: {
        ...super.css.truck,
        paddingTop: '',
      },

      mainPage: {
        ...super.css.mainPage,
        margin: '',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        margin: '10px 0px',
        alignItems: 'center',
        minWidth: '624px',
      },

      canvasContainer: {
        ...super.css.canvasContainer,
        padding: 0,
        backgroundColor: 'white',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        minHeight: 'var(--image-max-height)',
      },

      canvas: { // img.canvas
        ...super.css.canvas,
        width: 'auto',
        height: 'auto',
        maxHeight: 'var(--image-max-height)',
        minHeight: 'var(--image-max-height)',
        boxShadow: '0px 2px 15px grey',
        'body[dark] &': { // project batman
          boxShadow: 'unset',
        },
        display: 'inline-block',
        userSelect: 'none',
        backgroundColor: 'white'
      },

      loading: {
        position: 'absolute',
        top: '-10px',
        '--icon-height': '24px',
      },

      pages: {
        ...super.css.pages,
        width: '100%',
        paddingBottom: '20vh',
        transform: 'translate3d(0,0,0)'
      }
    }
  }

}

PdfPages.register()
