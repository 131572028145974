
import { DataComponent } from 'DataModel'
import { EntityModel, EntityQuestions } from 'EntityModel/EntityModel'
import { computed, memoize } from 'utils/decorator'
import t from 't'

import { Variable, SlotManager } from './Variable'
import { aliasOptions } from './aliases'

export type FirebaseKey = string
type ALIAS_TYPES = import('./aliases').ALIAS_TYPES

const MODEL_SYM = Symbol('Variable Model')

export abstract class ModelVariable extends Variable {
  modelKey: KnockoutObservable<FirebaseKey> = ko.observable()
  get model () { return this[MODEL_SYM]() }
  get modelObservable () { return this[MODEL_SYM] }
  [MODEL_SYM]: KnockoutObservable<EntityModel> = ko.observable()
  constructor (slotManager: SlotManager) {
    super(slotManager)
    this.computed(() => this.getValueOrImpliedValue('modelKey'))
      .subscribe(key => this.onModelKeyChange(key))
    this.onModelKeyChange(this.getValueOrImpliedValue('modelKey'))
    this.subscribe(this[MODEL_SYM], () => this.emitInputNotification())
  }

  async onModelKeyChange (key) {
    if (key) {
      try {
        const em = await EntityModel.vmGet(window.app.defaultAuthManager, key) as EntityModel
        if (key !== this.getValueOrImpliedValue('modelKey')) {
          return
        }
        this[MODEL_SYM](em)
      }
      catch (err) {
        console.error(`ModelVariable: Loading ${key}: `, err)
        if (key === this.getValueOrImpliedValue('modelKey')) {
          this[MODEL_SYM](null)
        }
      }
    }
    else {
      this[MODEL_SYM](null)
    }
  }

  get isCohate () { return Boolean(this.model) }
  private get isLoaded () {
    const key = this.getValueOrImpliedValue('modelKey')
    return !key || (this.model && this.model.vmFirestoreDocPath === key)
  }

  get whenLoaded () {
    return ko.pureComputed(() => this.isLoaded).when(true)
  }
}


export class ComponentVariable extends ModelVariable {
  /**
   * The `component` is the `identifier` from the `QuestionDescriptor`
   */
  component = ko.observable<string>()

  get code () { return 'cv' }
  get isCohate () { return super.isCohate && Boolean(this.component()) }
  get slotGroupCode () { return `ac:${this.component()}` }
  get slotTitleText () { return this.isCohate ? this.value() : 'ø' }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagate (to: ComponentVariable) { return }
  value () { return this.model.answerFor(this.component()) }

  @memoize()
  get componentProperties () {
    return EntityQuestions.find(q => q.identifier === this.component())
  }

  @memoize()
  get Component () {
    if (!this.componentProperties) { return null }
    return DataComponent.getComponentById(this.componentProperties.component)
  }

  get incohateText () {
    const { componentProperties } = this
    return componentProperties ? componentProperties.title : 'Missing component'
  }

  @computed()
  get slotGroupTitle () {
    const { componentProperties } = this
    return componentProperties ? componentProperties.title : 'Missing component'
  }

  /**
   * Assign the variables so the `slotGroupCode` will be complete when
   * a `slotIndex` is assigned.
   */
  assignVariables (vars: any) {
    const { component, ...copy } = vars
    if (component) { this.component(component) }
    return super.assignVariables(copy)
  }
}


export class SimpleComponentVariable extends ComponentVariable {
  alias = ko.observable<string>()

  private get aliasBy (): ALIAS_TYPES { return this.componentProperties.aliasBy }

  get code () { return 'scv' }
  get aliases () { return aliasOptions(this.aliasBy, this.unaliasedValue) }
  get unaliasedValue (): string { return ko.unwrap(super.value()) }

  get leadTransforms () {
    if (!this.isCohate) { return [] }
    switch (this.Component.canonicalName) {
      case 'bool': return [{ type: 'boolean' }]
      case 'range': return []
      case 'string': {
        return [
          { type: 'alias', parameters: { alias: this.alias(), by: this.aliasBy }},
        ]
      }
    }
    return []
  }

  get tailTransforms () {
    return [
      ...super.tailTransforms,
      { type: 'default', parameters: t.VARIABLE_HAS_NO_VALUE },
    ]
  }
}


/**
 * This is the single-component version of the ArrayComponentPureDate
 */
export class ComponentPureDate extends ComponentVariable {
  get code () { return 'cpdv' }
  format: KnockoutObservable<string> = ko.observable()
  get leadTransforms () {
    return [
      { type: 'date', parameters: { format: this.format() } },
      ...this.leadTransforms,
    ]
  }
}

export class ComponentPerson extends ComponentVariable {
  personProperty: KnockoutObservable<string> = ko.observable()
  personPropertyIndex: KnockoutObservable<string> = ko.observable()
  get isCohate () { return this.personProperty() && super.isCohate }
  get code () { return 'cpv' }
  value () {
    const pi = this.personPropertyIndex() || 0
    return ko.unwrap(super.value())[this.personProperty()][pi]
  }

  get incohateText () {
    const C = this.Component
    if (!C) { return `A data structure that is no longer used. [cp]` }
    const tkey = t.canonicalKey('PERSON', this.personProperty())
    return [super.incohateText, ko.unwrap(t.getValue(tkey))].join(' ')
  }
}
