
import ViewComponent from 'ViewComponent'
import { typography } from 'styles'

class CapTable extends ViewComponent {
  static get css () {
    return {
      ...super.css,
      grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        minWidth: '50vw',
        padding: 20,
        gridGap: '0px 4px',
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.3)',
        borderRadius: 4,
        margin: 60,
        backgroundColor: 'white',
      },
      headCell: {
        fontSize: '0.8em',
        marginTop: '-45px',
        fontWeight: 'bold',
        fontFamily: typography.bodyFontFamily,
        whiteSpace: 'nowrap',
      },
      bodyCell: {
        padding: '10px 0px',
        fontSize: '0.8em',
        fontFamily: typography.bodyFontFamily,
        backgroundColor: 'white',
       }
    }
  }

  get head () {
    const { jss } = this
    return [
      <div class={jss.col1}></div>,
      <div class={jss.col2}>Common</div>,
      <div class={jss.col3}>FD%</div>,
      <div class={jss.col4}>Capital Contribution</div>
    ]
  }

  get rows () {
    return [
      ['Alice Winters', '4,898,778', '68.22%', '$48.99'],
      ['Evert Winters', '450,000', '6.27%', '$19,998.00'],
      ['James Jones', '332,312', '4.63%', '$8,307.80'],
      ['2015 Option Plan', '1,500,000', '20.89%', '$38,750.00']
    ]
  }

  headHTML (h) {
    return <div class={this.jss.headCell}>{h}</div>
  }

  rowHTML (row) {
    return row.map(cell => this.cellHTML(cell))
  }

  cellHTML (cell) {
    return <div class={this.jss.bodyCell}>{cell}</div>
  }

  get template () {
    return (
      <div class={this.jss.grid}>
        {this.head.map(h => this.headHTML(h))}
        {this.rows.map(r => this.rowHTML(r)).flat()}
      </div>
    )
  }
}

CapTable.register()
