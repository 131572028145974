
import { add, remove } from 'pop-over/maintenance'

import FloatingToolTip from './floating-tool-tip'

export default class FloatingPopup extends FloatingToolTip {
  constructor (p: ConstructorParameters<typeof FloatingToolTip>[0]) {
    super(p)
    add(this)
  }

  dispose () {
    super.dispose()
    remove(this)
  }

  static get css () {
    return {
      tooltip: {
        ...super.css.tooltip,
        padding: '1px',
      },
    }
  }
}

FloatingPopup.register()
