
import { findLast } from 'lodash-es'

import PdfUpload from 'import/PdfUpload'
import { DataComponent } from 'DataModel/components'
import { enumerate } from 'utils/iterable'

import BookPage from './BookPage'
import Section from './Section'
import SectionTree from './SectionTree'
import { arrayMove } from 'utils/array'

const { ko } = global

/**
 * The DataComponent for the PDF pages of a MinuteBook
 */
export class BookComponent extends DataComponent {
  pages: KnockoutObservableArray<BookPage>
  sections: KnockoutObservableArray<Section>

  static get namespace () { return 'entity' }

  init () {
    /**
     * An array of `Section` instances
     */
    this.sections = ko.observableArray([])
    this.sortedSections = ko.pureComputed(() =>
      this.sections.sorted((a, b) => a.startPage() - b.startPage()))

    /**
     * An array of pageID strings
     */
    this.bookmarks = ko.observableArray([])

    /**
     * @type {ObservableArray.<Page>}
     * Created from a list of strings of [ 'char:page#' ] where page#
     * is the PDF page number.
     */
    this.pages = ko.observableArray([])

    this.length = ko.pureComputed(() => this.pages.length)

    this.cachedBookPages = new Map()
    this.sectionTree = new SectionTree(this.pages, this.sections)

    this.bookmarks.subscribe(m => this.updateBookPageBookmarks(m))
    this.sections.subscribe(s => this.updateBookPageSections(s))

    this.undoHistory = []
    this.redoHistory = []
  }

  sortSections () { this.sections(this.sortedSections()) }

  updateBookPageBookmarks (bookmarkPageIDs) {
    const marks = new Set(bookmarkPageIDs)
    for (const bookPage of this.pages()) {
      bookPage.bookmarked(marks.has(bookPage.pageID) || undefined)
    }
  }

  updateBookPageSections (sections) {
    const indexes = new Set(sections.map(s => s.pageNumber() - 1))
    for (const [index, bookPage] of enumerate(this.pages())) {
      bookPage.startsSection(indexes.has(index) || undefined)
    }
  }

  updateBookPageNotes (notes) {
    const notesPagesList = notes
      .map(n => n.data())
      .filter(n => n.pinpoint && n.pinpoint.type === 'pageID')
      .map(n => n.pinpoint.pageID)
    const notePagesSet = new Set(notesPagesList)
    for (const bookPage of this.pages()) {
      bookPage.hasNotes(notePagesSet.has(bookPage.pageID) || undefined)
    }
  }

  /**
   * This iterator yields an array of pages for each section
   *
   * @yields {BookPage[]}
   */
  * pagesBySection () {
    let i = 0
    const pages = this.pages()
    ko.unwrap(this.sections)
    if (!pages.length) { return }
    while (i < pages.length) {
      let sectionPages = [ pages[i] ]
      while ((++i < pages.length) && !pages[i].startsSection()) {
        sectionPages.push(pages[i])
      }
      yield sectionPages
    }
  }

  get () { return this }

  /**
   * @param {string} pageID
   * @return {Page}
   */
  makePage (pageID) {
    return BookPage.getOrMakeBookPage(pageID, this.model, this.cachedBookPages)
  }

  set (data) {
    this.pages((data.pages || []).map(pageID => this.makePage(pageID)))
    this.sections((data.sections || []).map(s => new Section(s, this)))
    this.bookmarks(data.bookmarks || [])
  }

  toJS () {
    return ko.toJS({
      sections: this.sections().map(s => s.toJS()),
      bookmarks: this.bookmarks(),
      pages: this.pages().map(p => p.pageID)
    })
  }

  /**
   * Insert the pages of the given PDF at `index`
   *
   * Save the pages as
   *
   *    docId/page/pageID
   *
   * with each <= ~1 MB chunk as
   *
   *   docId/page/pageID/chunk/#
   *
   * We limit the size of each chunk to 1MB b/c Datastore is a fixed-width
   * table, so it's a hard limit.
   *
   * @param {File|Blob} pdfBlob
   * @return {PdfUpload}
   */
  uploadPagesFromPdf (pdfBlob, original = {}, isCancelled = () => false) {
    return new PdfUpload({
      authManager: this.model.authManager,
      entity: this.model,
      originalOrigin: original.originalGsUrl,
      pdf: pdfBlob,
      isCancelled,
    })
  }

  uploadPagesToNewSection (newPages, name) {
    const startPage = this.pages.length + 1
    this.pages([...this.pages(), ...newPages])
    this.sections.push(new Section({ startPage, name, editing:false }))
    newPages[0].startsSection(true)
  }

  async addPagesToSection (newPages, section, atStart = false) {
    newPages = newPages.filter(bp => !this.pages().find(p => p.pageID === bp.pageID))
    if (!newPages.length) { return }

    this.pages().forEach(bp => bp.selected(false))
    if (window.app.panelProvider().showThumbnailView()) {
      newPages.forEach(bp => bp.selected(true))
    }
    newPages[0].focused(true)
    let sectionMsg = ''

    if (section) {
      /**
       * 🐉  FIXME  🏋️‍♀️  Adding pages to the tree should be in `sectionTree`,
       * with `{save|restore}TempSectionReferences`.
       */
      const sectionStart = section.startPage() - 1
      const sectionEnd = sectionStart + this.sectionTree.getSectionPageCount(section)
      const pages = this.pages()
      let sectionRefs = null, prePages = [], postPages = []
      if (atStart) {
        prePages = pages.slice(0, sectionStart)
        postPages = pages.slice(sectionStart)
      } else {
        prePages = pages.slice(0, sectionEnd)
        postPages = pages.slice(sectionEnd)
      }
      sectionRefs = this.saveTempSectionReferences()
      this.pages([...prePages, ...newPages, ...postPages])
      this.restoreTempSectionReferences(sectionRefs)
      if (atStart) {
        postPages[0].startsSection(undefined)
        newPages[0].startsSection(true)
        section.startPage(sectionStart + 1)
      }
      sectionMsg = <>to <b>{section.name()}</b></>
    } else {
      this.pages.push(...newPages)
    }
    await this.model.vmSave()
    global.app.notifier.pushOutcome(
      <span>
        Added <b>{newPages.length}</b> new page{newPages.length > 1 ? 's' : ''} {sectionMsg}
      </span>)
  }

  async deleteSelectedPages () {
    const selected = this.pages().filter(pg => pg.selected())
    if (!selected.length) { return }
    this.clearUndoHistory()
    for (let page of selected) {
      this.deletePage(page)
    }
    await this.model.vmSave()
  }

  /**
   * Remove the given page from the book.
   */
  deletePage (bookPage) {
    const bookIndex = this.pages().indexOf(bookPage)
    if (bookIndex < 0) { return }
    this.pages.splice(bookIndex, 1)
    const startPage = bookIndex + 1
    for (const section of this.sections()) {
      if (section.pageNumber() === startPage) {
        this.sections.remove(section)
      } else if (section.pageNumber() > startPage) {
        section.pageNumber.modify(p => p - 1)
      }
    }
    this.updateBookPageSections(this.sections())
  }

  /**
   * BookComponent exposes this to avoid a circular import with BookPage
   * and PdfUpload.
   */
  createBookPage (pageID) {
    return new BookPage(pageID, this.model)
  }

  moveSelectedPagesToSection (targetSection) {
    if (!targetSection) { return }
    const pages = this.pages()
    const saveUndoSectionRefs = this.saveTempSectionReferences()
    const toIndex = targetSection.startPage() - 1 + this.sectionTree.getSectionPageCount(targetSection)

    const fromIndexList = pages
      .map((bp, i) => ([bp, i]))
      .filter(([bp, i]) => bp.selected())
      .map(([bp, i]) => i)

    if (!fromIndexList.length) { return }

    // If we are moving the first page of a section we need to move the reference
    if (pages[fromIndexList[0]].startsSection()) {
      const sourceSection = this.getSectionAtPage(fromIndexList[0]+1)
      const firstPage = pages[fromIndexList[0]]
      let i = fromIndexList[0]+1
      while (i < pages.length && fromIndexList.includes(i) && !pages[i].startsSection()) { ++i } // Find new first page
      if (i === pages.length || pages[i].startsSection()) { // Moving all pages of a section
        if (sourceSection === targetSection) { return }
        firstPage.startsSection(undefined)
        const newSections = this.sections().filter(s => s !== sourceSection)
        this.sections(newSections) // Delete source section
      } else {
        firstPage.startsSection(undefined)
        pages[i].startsSection(true)
        sourceSection.pageNumber(i+1)
      }
    }

    return this.movePages(fromIndexList, toIndex, saveUndoSectionRefs)
  }

  saveTempSectionReferences () {
    const pages = this.pages()
    return this.sections().map(s =>
      ({ page: pages[s.startPage()-1], section: s }))
      .filter(({page}) => page)
  }

  restoreTempSectionReferences (savedRefs) {
    if (!savedRefs) { throw('Error: savedRefs required') }
    this.pages().forEach(p => p.startsSection(undefined))
    for (const r of savedRefs) {
      r.page.startsSection(true)
      r.section.pageNumber(this.getIndexOfpageID(r.page.pageID) + 1)
      if (!this.sections().includes(r.section)) {
        this.sections.push(r.section)
      }
    }
    this.pages.valueHasMutated()
  }

  /**
   * Move a page from one position to another; often happens with
   * drag-and-drop.
   */
  movePage (fromPage, toPage) {
    const { pages } = this
    pages()[fromPage-1].selected(true)
    arrayMove(pages, fromPage-1, toPage-1)
  }

  movePagesWithSectionUpdate ({ fromIndexList, toIndex, draggingBefore }) {
    // When toIndex is a section start page, draggingBefore is a boolean which
    // indicates if the drop should be in that section (draggingBefore=true)
    // or in the previous section (draggingBefore=false)

    const pages = this.pages()
    const toPage = pages[toIndex]
    const firstFromPage = pages[fromIndexList[0]]
    const savedSectionReferences = this.saveTempSectionReferences()

    // For each section start page that we are dragging
    for (let i of fromIndexList.filter(i => pages[i].startsSection())) {
      const sectionPage = pages[i]
      const section = this.getSectionAt(sectionPage)
      sectionPage.startsSection(undefined)

      // find first unmoved page of the section
      for (var j=i+1; fromIndexList.includes(j) && !pages[j].startsSection(); ++j);

      // If dragging to the start of this section
      if ((toIndex > i && toIndex < j)
          || ( toIndex === i && draggingBefore )
          || ( toIndex === j && !draggingBefore )) {
        // first dragged page is new section start
        firstFromPage.startsSection(true)
        section.pageNumber(fromIndexList[0]+1)
      } else {
        // if we're dragging the entire section
        if (j === pages.length || pages[j].startsSection()) {
          // delete section
          const newSections = this.sections().filter(s => s !== section)
          this.sections(newSections)
        } else {
          // else set unmoved page as new section start
          pages[j].startsSection(true)
          section.pageNumber(j+1)
        }
      }
    }

    // If we're dragging to the start of a section
    if (toPage
        && !firstFromPage.startsSection()
        && toPage.startsSection()
        && draggingBefore) {
      // set first dragged page as new section start
      const section = this.getSectionAt(toPage)
      section.pageNumber(fromIndexList[0]+1)
      toPage.startsSection(undefined)
      firstFromPage.startsSection(true)
    }

    this.movePages(fromIndexList, toIndex, savedSectionReferences)
  }

  /**
   * Move multiple, non-sequential pages to a single destination index.
   * Records an undo history.
   * @param {[int]} fromIndexList
   * @param {int} toIndex
   */
  movePages (fromIndexList, toIndex, undoSectionRefs) {
    const pages = this.pages()
    const undoItem = {
      srcIndex: undefined,
      dstIndexList: [],
      sectionRefs: undoSectionRefs,
      redo: [[...fromIndexList], toIndex, undoSectionRefs],
    }
    const savedSectionRefs = this.saveTempSectionReferences()
    const pagesToMove = pages.filter((pg, index) => {
      pg.selected(fromIndexList.includes(index))
      return pg.selected.peek()
    })
    let cascadeOffset = 0 // For caclulating changes to undo indexes caused by page moves
    const prePages = pages.slice(0, toIndex).filter((pg, index) => {
      if (pagesToMove.includes(pg)) { undoItem.dstIndexList.push(index - cascadeOffset++); return false }
      else { return true }
    })
    undoItem.srcIndex = toIndex - undoItem.dstIndexList.length
    cascadeOffset = pagesToMove.length - undoItem.dstIndexList.length - 1
    const postPages = pages.slice(toIndex).filter((pg, index) => {
      if (pagesToMove.includes(pg)) { undoItem.dstIndexList.push(toIndex + index + cascadeOffset--); return false }
      else { return true }
    })
    this.pages([...prePages, ...pagesToMove, ...postPages])
    this.restoreTempSectionReferences(savedSectionRefs)
    this.undoHistory.push(undoItem)
  }

  clearUndoHistory () {
    this.undoHistory = []
    this.redoHistory = []
  }

  undoMove () {
    if (!this.undoHistory.length) { return }
    const pages = this.pages()
    const movePage = (fromPage, toPage) => {
      pages[fromPage-1].selected(true)
      pages.splice(toPage - 1, 0, pages.splice(fromPage - 1, 1)[0])
    }
    const { srcIndex, dstIndexList, sectionRefs, redo } = this.undoHistory.pop()
    const savedSectionRefs = sectionRefs || this.saveTempSectionReferences()
    pages.forEach(pg => pg.selected(false))
    let srcIndexVal = srcIndex
    let cascadeOffset = 0
    for ( let dstIndex of dstIndexList ) {
      if ( dstIndex < srcIndex ) {
        movePage((srcIndexVal++) + 1, (dstIndex + cascadeOffset++) + 1)
      } else if (dstIndex === srcIndex) {
        srcIndexVal++
      } else {
        movePage(srcIndexVal + 1, dstIndex + 1) // src pages are sliding so we don't need to increase srcIndex
      }
    }
    this.restoreTempSectionReferences(savedSectionRefs)
    this.pages.valueHasMutated()
    this.redoHistory.push(redo)
  }

  redoMove () {
    if (!this.redoHistory.length) { return }
    this.movePages(...this.redoHistory.pop())
  }

  /**
   * @param {File} file
   * @return {FileStatus}
   */
  _startFileUpload (file) {
    const { authManager } = this.model
    const accountID = this.model.accountID()
    const metadata = {} // TODO: Add metadata for user-uploading, etc.
    return authManager.storagePut(file, metadata, accountID)
  }

  /**
   *
   * @param {int} startPage
   * @param {string} name
   */
  addOrUpdateSection (startPage, name, editing = true) {
    const existingSection = this.sections()
      .find(s => s.startPage() === startPage)
    if (existingSection) {
      existingSection.editing(editing)
    } else {
      this.sections.push(new Section({ startPage, name, editing }))
    }
  }

  addOrUpdateSectionFor (bookPage, name = '') {
    const startPage = this.getIndexOfpageID(bookPage.pageID) + 1
    this.addOrUpdateSection(startPage, name)
  }

  getSectionAt (bookPage) {
    return this.getSectionAtPage(this.getIndexOfpageID(bookPage.pageID) + 1)
  }

  getSectionAtPage (pageNumber) {
    const sections = this.sortedSections()
    if (!sections.length) { return }
    if (sections[0].startPage() > pageNumber) { return }
    return findLast(sections, s => s.startPage() <= pageNumber)
  }

  /**
   * Bookmarks
   */

  /**
   * Return the list of bookmarks for the given page.
   *
   * @param {int} pageNumber 1-offset index of the bookmark to retrieve
   * @return {Array.<Bookmark>}
   */
  getBookmarks (bookPage) {
    return this.bookmarks()
      .filter(pageID => bookPage.pageID === pageID)
  }

  /**
   * @param {string} pageID
   * @return {int}
   */
  getIndexOfpageID (pageID) {
    return this.pages().findIndex(bp => bp.pageID === pageID)
  }

  /**
   * @param {string} pageID
   * @return {int}
   */
  getPageByString (pageID) {
    return this.pages().find(bp => bp.pageID === pageID)
  }

  /**
   * Move the given section to the given index.
   * @param {int} fromIndex in `this.sections`
   * @param {int} toIndex in `this.sections`
   */
  moveSection (fromIndex, toIndex) {
    this.sortSections()
    this.sectionTree.moveSection(fromIndex, toIndex)
  }

  /**
   * Create a bookmark and add it to the array of bookmarks for this book.
   *
   * @param {string} pageID of the page to be bookmarked
   */
  addBookmark (pageID) {
    if (!this.bookmarks().includes(pageID)) {
      this.bookmarks.push(pageID)
    }
  }

  /**
   * @param {int} bookPage of the page to be bookmarked
   */
  toggleBookmark (bookPage) {
    const { pageID } = bookPage
    if (!this.bookmarks.remove(pageID).length) {
      this.bookmarks.push(pageID)
    }
  }

  /**
   * @param {object} params
   * @param {bool} params.bookmarks
   * @param {bool} params.everyPage
   * @param {string} params.pages
   * @param {Array.<string>} params.sections
   */
  getProjectionValue (params) {
    const bookmarks = params.bookmarks ? this.bookmarks() : []

    if (params.everyPage) {
      return Object.assign(this.toJS(), { bookmarks })
    }

    const values = {
      bookmarks,
      pages: [],
      sections: [],
    }

    const gen = this.genProjectionPS(params.pages, params.sections)

    for (const { pageID, section } of gen) {
      if (section) { values.sections.push(section) }
      values.pages.push(pageID)
    }

    return values
  }

  * genProjectionPS (pagesString = '', sectionsList = []) {
    let lastSectionName = ''
    let reportedSection = false
    let includedPage = 1
    for (const [i, page] of enumerate(this.pages())) {
      const startsSection = page.startsSection()
      if (startsSection) {
        lastSectionName = this.getSectionAtPage(i + 1).name()
        reportedSection = false
      }
      if (this.pageMatches(i + 1, pagesString) ||
        sectionsList.includes(lastSectionName)) {
        yield {
          pageID: page.pageID,
          section: reportedSection ? null : {
            startPage: includedPage,
            name: lastSectionName
          }
        }
        includedPage++
        reportedSection = true
      }
    }
  }

  pageMatches (pageNumber, pagesString) {
    const parts = pagesString.split(',')
    for (const part of parts) {
      if (!part.trim()) { continue }
      if (part.includes('-')) {
        const [start, end] = part.split('-').map(p => parseInt(p, 10))
        if (pageNumber >= start && pageNumber <= end) { return true }
      }
      if (pageNumber === parseInt(part, 10)) { return true }
    }
  }

}

BookComponent.register()
export default BookComponent
