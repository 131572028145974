import { PersonType } from "./interfaces"

import { isBefore, isAfter } from 'date-fns/esm'
import { formatForUser } from 'utils/dates'

type PersonBy = Record<string, PersonRecord>
type PersonRecordValue = string | AddressRecord | PersonOrigin

type CrudModel = import("DataModel").CrudModel

export function makePersonRecord (...recordParts: Partial<PersonRecord>[]) : PersonRecord {
  return Object.assign({
    id: null,
    type: null,
    name: [],
    address: [],
    email: [],
    phone: [],
    origin: [],
  }, ...recordParts)
}

function merge (id: string, by: PersonBy, record: PersonRecord) {
  if (!by[id]) {
    by[id] = makePersonRecord({ id: record.id })
  }
  if (by[id].id !== record.id) {
    throw new Error(`Attempting to merge PersonRecords with differing IDs`)
  }
  const byValue = by[id]

  for (const [prop, value] of Object.entries(record)) {
    if (Array.isArray(value)) {
      const current = byValue[prop] || []
      byValue[prop] = [...new Set([...current, ...value])]
    } else if (prop === 'type') {
      if (!byValue[prop] || byValue[prop] === PersonType.REFERENCE) {
        byValue[prop] = value
      }
    }
  }
}

export function * mergeBy (iterator: IterableIterator<PersonRecord>, by: string) {
  const peopleBy : PersonBy = {}

  for (const person of iterator) {
    const prop = person[by]
    if (!prop || prop.length === 0) {
      yield person
    } else if (Array.isArray(prop)) {
      for (const p of prop) {
        merge(p, peopleBy, person)
      }
    } else {
      merge(prop, peopleBy, person)
    }
  }

  yield * Object.values(peopleBy)
}

/**
 * Create an observable that triggers changes on an observable
 * of a PersonRecord.
 *
 * (See UnitView.test.ts for tests)
 */
export function observeProperty (record: KnockoutObservable<PersonRecord>, property: string, index = 0) : KnockoutComputed<PersonRecordValue> {
  record()[property] = record()[property] || []

  return ko.pureComputed({
    read: () => record()[property][index],
    write: v => {
      record.valueWillMutate()
      record()[property][index] = v
      record.valueHasMutated()
    }
  })
}


const IDENTIFIER_PROPERTIES = [
  'name', 'email', 'phone', // address
]

/**
 * This should return true whenever `a` and `b` may be the same person,
 * for suggestion/autocomplete purposes.
 *
 * TODO: [FIXME] Look at more than the first record item.
 */
export function maybeSamePerson (a: PersonRecord, b: PersonRecord, basedOn = IDENTIFIER_PROPERTIES) : boolean {
  if (a.id && a.id === b.id) { return true }
  if (basedOn.every(p => !a[p][0])) { return true }
  if (basedOn.every(p => !b[p][0])) { return true }
  return basedOn.some(p => a[p][0] && a[p][0] === b[p][0])
}

function blankStringField (arr: PersonRecordValue[]) {
  return !arr.length || arr.length === 1 && !arr[0]
}

export function isBlank (p: PersonRecord) {
  return blankStringField(p.name) &&
    blankStringField(p.phone) &&
    blankStringField(p.email) &&
    blankStringField(p.address)
}

export function roleDisplayTitle (role: PersonRoleRecord) {
  const now = new Date()
  if (isBefore(role.end, now)) {
    return `Previous ${role.title} (until ${formatForUser(role.end)})`
  } else if (isAfter(role.start, now)) {
    return `Future ${role.title} (from ${formatForUser(role.start)})`
  } else {
    return role.title
  }
}

export function getCrudModelForPerson (person: PersonRecord) : CrudModel {
  if (!person || person.type === PersonType.REFERENCE) { return null }
  const origin = person.origin.find(o => {
    const roles = [...o.roles()].map(r => r.title)
    return roles.includes('Entity') || roles.includes('User')
  })
  return origin && origin.model || null
}
