
/**
 * Expose a bunch of observable state related to a file upload.
 *
 * Primarily exposes:
 *    {bool} complete
 *    {Error} error
 *    {int} percent out of 100
 *    {int} bytesTransferred
 *    {int} totalBytes
 */
export class FileStatus {
  constructor (fileModel) {
    Object.assign(this, {
      storage: fileModel.authManager.storage,
      storagePath: fileModel.fmStoragePath,
      complete: ko.pureComputed(() => fileModel.isUploaded),
      bytesTransferred: fileModel.size,
      totalBytes: fileModel.size,
      sha512sum: fileModel.sha512sum(),
      error: ko.observable()
    })

    this.percent = ko.pureComputed(this.percent, this)
  }

  percent () {
    return this.bytesTransferred() / this.totalBytes() * 100
  }

  /**
   * @return {string} gs://bucket/path reference
   */
  async getGsUrl () {
    await this.complete.when(true)
    return String(this.storage.ref(this.storagePath))
  }
}

/**
 * A FileStatus for a file that is uploading.
 */
export class FileUploadStatus extends FileStatus {
  /**
   * @param {FileModel} fileModel
   * @param {firebase.storage.UploadTask} task optional
   */
  constructor (fileModel, task) {
    super(fileModel)
    Object.assign(this, {
      fileModel,
      complete: ko.observable(task.snapshot.state === 'SUCCESS'),
      bytesTransferred: ko.observable(task.snapshot.bytesTransferred),
      totalBytes: ko.observable(task.snapshot.totalBytes)
    })

    task.on(firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => this.onStateChange(snapshot),
      err => this.onError(err),
      () => this.onComplete()
    )
  }

  async updateModelStatus (statusNumber) {
    if (this.fileModel.statusNumber() === this.fileModel.COMPLETE) { return }
    this.fileModel.statusNumber(statusNumber)
    await this.fileModel.vmSave()
  }

  /**
   * @param {firebase.storage.UploadTaskSnapshot} snapshot
   */
  onStateChange (snapshot) {
    this.bytesTransferred(snapshot.bytesTransferred)
    this.totalBytes(snapshot.totalBytes)
    this.updateModelStatus(this.fileModel.UPLOADING)
  }

  /**
   * Callback when the upload completes.
   */
  onComplete () {
    this.complete(true)
    this.updateModelStatus(this.fileModel.COMPLETE)
  }

  onError (err) {
    console.error(`Error uploading: `, err)
    this.error(err)
    this.updateModelStatus(this.fileModel.ERROR)
  }
}
