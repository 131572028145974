import ViewComponent from 'ViewComponent'
import './action-menu'
import { color, buttons } from 'styles'
import { inline } from 'icons'
import chevronDown from 'icons/light/chevron-down'
import { hidePopovers } from 'pop-over'

export type Option = {
  label: string
  value: any
}

/**
 * Simple drop down menu that uses <action-menu-items> for the list box
 */
export default class SelectDropDown extends ViewComponent {
  options: Option[]
  choice: KnockoutObservable<any>

  constructor ({ choice, options }) {
    super()
    Object.assign(this, {
      choice,
      options,
    })
  }

  static get css () {
    return {
      anchor: {
        ...buttons.clickable,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${color.onPrimary}`,
        borderRadius: '10px',
        padding: '10px 20px',
      },
      anchorText: {
        color: 'black',
        '&[blank]': {
          color: 'grey',
        },
      },
      anchorIcon: { position: 'static' },
    }
  }

  get actions () {
    return this.options.map(option => ({
      label: option.label,
      action: () => {
        this.choice(option.value)
        hidePopovers()
      },
    }))
  }

  get template () {
    const { jss } = this
    const label = this.computed(() => this.options.find(o => o.value === this.choice()).label)
    return (
      <drop-down my='top right' at='bottom right'>
        <template slot='anchor'>
          <div class={jss.anchor}>
            <div class={jss.anchorText}>{label}</div>
            <div class={jss.anchorIcon}>{inline(chevronDown)}</div>
          </div>
        </template>
        <template slot='content'>
          <action-menu-items actions={this.actions} />
        </template>
      </drop-down>
    )
  }
}

SelectDropDown.register()
