import ModalDialog from "modal-dialog"
import { buttons, typography, color } from 'styles'
import { merge } from 'lodash-es'

type MinuteBoxApp = import("minute-box-app").default

export default class SetAccountNameModal extends ModalDialog {
  app: MinuteBoxApp
  authManager: AuthManager
  firmname: KnockoutObservable<string>

  constructor ({ app, account }) {
    super({ modalTitle: app.defaultAuthManager.userIsAdmin() ? 'Set Account Name' : '' })
    Object.assign(this, {
      app,
      account,
      firmname: ko.observable(''),
      authManager: app.defaultAuthManager,
    })
    this.subscribe(account.firmname, v => !v || this.close())
  }

  static get css () {
    return {
      ...super.css,
      container: {
        width: '460px',
      },

      instructions: { position:'static' },

      centered: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: '15px',
      },

      inputBlock: { extend: 'centered' },
      firmNameInput: {
        ...merge(
          color.dynamic(color.textInput, 'input'),
          color.dynamic(color.separator, 'separator'),
          color.dynamic(color.text, 'text'),
        ),
        backgroundColor: 'var(--input-primary)',
        border: `1px solid var(--separator-non-opaque)`,
        color: 'var(--text-primary)',
        borderRadius: 4,
        fontFamily: typography.bodyFontFamily,
        fontWeight: 'bold',
        padding: '10px 15px',
        fontSize: '1.2em',
        outline: 'none',
      },

      buttonBlock: { extend: 'centered' },
      updateButton: {
        ...color.dynamic(color.text, 'text'),
        ...buttons.modalOk,
        cursor: 'unset',
        backgroundColor: 'var(--text-secondary)',
        '&:hover': { },
        '&[enabled]': {
          ...buttons.modalOk,
        },
      },
    }
  }

  get contentHTML () {
    return this.authManager.userIsAdmin()
      ? this.adminContentHTML
      : this.nonAdminContentHTML
  }

  get nonAdminContentHTML () {
    const { jss } = this
    return (
      <div class={jss.container}>
        <div class={jss.instructions}>
          Your system account name has not yet been set. Please ask your administrator to set the account name.
          If you believe this is an error contact <a href='mailto:support@minutebox.com'>support@minutebox.com</a> for assistance.
        </div>
        <div class={jss.buttonBlock}>
          <div class={jss.updateButton} enabled={true}
            ko-click={() => this.app.signOut()}>
              Logout
          </div>
        </div>
      </div>
    )
  }

  get adminContentHTML () {
    const { jss } = this
    const updateButtonEnabled = this.computed(() => this.firmname() || undefined)
    return (
      <div class={jss.container}>
        <div class={jss.instructions}>
          Please set the name of your firm or company. This name will be used in all outgoing emails, such as sharing invites.
        </div>
        <div class={jss.inputBlock}>
          <input type='text' class={jss.firmNameInput} ko-textInput={this.firmname} ko-hasFocus={true}/>
        </div>
        <div class={jss.buttonBlock}>
          <div class={jss.updateButton}
            enabled={updateButtonEnabled}
            ko-click={() => !updateButtonEnabled() || this.update()}>
              Update Name
          </div>
        </div>
      </div>
    )
  }

  dismiss () { }

  update () {
    this.account.firmname(this.firmname())
    this.account.vmSave()
  }
}

SetAccountNameModal.register()
