import { yearsDaysDiffString, formatForUser } from 'utils/dates'

import EisPrimitiveComponent from './EisPrimitiveComponent'
import 'date-picker'
import { typography } from 'styles'

export default class EisDate extends EisPrimitiveComponent {
  static get css () {
    return {
      ...super.css,
      displayClass: {
        display: 'grid',
        gridTemplateColumns: '1fr auto 65px',
        alignItems: 'end',
      },
      dateArea: {
        ...super.css.textContent,
        fontSize: '1.5rem',
      },
      daysArea: {
        '&[blank]': {
          visibility: 'hidden',
        },
      },
      days: {
        ...super.css.indicator,
        backgroundColor: '#FFCD7A',
        color: '#424242',
        fontFamily: typography.bodyFontFamily,
        fontWeight: '500'
      },
      inputClass: {},
    }
  }

  onClick () {
    window.app.modal(
      <date-picker-modal
        modalTitle='Pick a date'
        target={this.componentValue}
        relatedDates={this.entity} />
    )
  }

  get componentValue () {
    return this.component.get().dateValue
  }

  copyValueFor () {
    return this.dateText
  }

  get isBlank () {
    return ko.pureComputed(() => (
      !Boolean(this.componentValue()) || undefined
    ))
  }

  get dateText () {
    const value = this.componentValue()
    const placeholder = this.constructor.displayPlaceholder[this.componentName]
    return formatForUser(value, placeholder)
  }

  get days () {
    return yearsDaysDiffString(this.componentValue()) || ''
  }

  get displayHTML () {
    const { jss } = this
    const focused = this.computed(() => this.focused() || undefined)
    const days = this.computed(() => this.days).extend({ deferred: true })
    return (
      <div class={jss.displayClass}>
        <div class={jss.dateArea} focused={focused} blank={this.isBlank}>
          {this.computed(() => this.dateText)}
        </div>
        <div class={jss.daysArea} blank={this.isBlank}>
          <div class={jss.days}>{days}</div>
        </div>
      </div>
    )
  }

  get editHTML () { return null }
  get changeButtonHTML () {
    const { jss } = this
    return (
      <div class={jss.changeButton}
        ko-ownClick={() => this.onClick()}>
        Add
      </div>
    )
 }

}

EisDate.register()
