import t from 't'

import { AssetZero, AssetConvert } from '../AssetAction'

import { MassConversion, CODES } from './Transaction'
import { AssetTransaction } from './AssetTransaction'

export class TransactionMassConversion extends AssetTransaction {
  denominator: KnockoutObservable<string>
  conversions: KnockoutObservableArray<MassConversion> = ko.observableArray([])

  get code () { return CODES.CapitalMassConvert }
  get propertiesToSerialize () { return ['conversions'] }
  get arrayObservables () { return [] }
  get plainObservables () { return [...super.plainObservables, 'denominator'] }
  constructor (params) {
    super(params)
    this.propertiesFromJS(params)
    this.conversions.push(...(params.conversions.length
      ? params.conversions.map(p => this.makeConversion(p))
      : [this.blankConversion()]))
  }

  blankConversion () {
    return this.makeConversion({ numerator: '1', targetAssetClassID: '' })
  }

  makeConversion ({ numerator, targetAssetClassID }): MassConversion {
    return {
      numerator: ko.observable(numerator || 1),
      targetAssetClassID: ko.observable(targetAssetClassID),
    }
  }

  * yieldActions () {
    const { assetClassID, denominator } = this
    for (const conversion of this.conversions) {
      const { targetAssetClassID, numerator } = ko.toJS(conversion)
      yield new AssetConvert(assetClassID, numerator, denominator(), targetAssetClassID)
    }
    yield new AssetZero(assetClassID)
  }

  shortDescription (cs: CapitalState) {
    return t.CAPITAL_MASS_CONVERSION(this.assetNameAt(cs))
  }
}
