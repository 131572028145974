
import RootPanelProvider from './RootPanelProvider'
import CommandSet from 'Command/CommandSet'
import 'views/toggle-button'
import 'views/select-drop-down'

import { color } from 'styles'
import { selected } from 'styles/theme'


export default class DisplaySettingsPanelProvider extends RootPanelProvider {
  theme: KnockoutObservable<boolean>

  /**
   * Default minute book panel provider view.
   */
  public viewOption: KnockoutObservable<string> =
    ko.observable('EntityInfoView').extend({ localStorage: `mb.setting.viewOption` })

  get panelWindowTitle () { return `Display Settings` }
  get panelID () { return 'DisplaySettings' }
  get historyUrl () { return '#display-settings' }
  get CommandSetConstructor () { return CommandSet }
  get peerPanelList () { return this.app.settingsProviderList }

  static get css () {
    return {
      ...super.css,
      ...this.themePickerCSS,
      main: {
        padding: '30px'
      },
      optionBlock: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        backgroundColor: color.systemGroupBackground.light.secondary,
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
        padding: 30,
        margin: '20px 0',
        borderRadius: '5px',
        'body[dark] &': { // project batman
          backgroundColor: color.systemGroupBackground.dark.primary,
        },
        '@media (max-width: 1130px)': {
          minWidth: '568px',
        },
      },
      viewOptions: {
        extend: 'optionBlock',
        gridTemplateColumns: '1fr minmax(300px, auto)',
      },
    }
  }

  static get appGridClassCSS () {
    return {
      ...super.appGridClassCSS,
      grid: {
        ...super.appGridClassCSS.grid,
        templateRows: 'auto auto auto auto 1fr',
        templateAreas: `
        'ty ty ty'
        'head head head'
        'top top top'
        'left main right'
        'left main right'
        `,
      }
    }
  }

  static get themePickerCSS () {
    return {
      themePicker: {
        extend: 'optionBlock',
      },
      themPickerTitle: {
        marginRight: '10px',
      },
      displayState: {
        marginLeft: 'auto',
      }
    }
  }

  get themePickerHTML () {
    const { jss } = this
    const options = [
      { text: 'Off', value: 'light' },
      { text: 'On', value: 'dark' },
      { text: 'Auto', value: undefined },
    ]

    return (
      <div class={jss.themePicker}>
        <div class={jss.themePickerTitle}>
          Dark Mode
          <q-tip name='settingsDarkMode' />
        </div>
        <toggle-button class={jss.displayState}
          options={options}
          selected={selected} />
      </div>
    )
  }

  get viewOptionsHTML () {
    const { jss } = this
    const options = [
      { label: 'Entity Information Summary', value: 'EntityInfoView' },
      { label: 'Book View', value: 'BookView' },
      { label: 'Split View', value: 'SplitView' },
      { label: 'Audit View', value: 'AuditView' },
      { label: 'Last View', value: 'lastGlobal' },
      { label: 'Last View for Entity', value: 'last' },
    ]
    return (
      <div class={jss.viewOptions}>
        <div class={jss.viewOptionsTitle}>
          Default Entity View
          <q-tip name='settingsViewOption' />
        </div>
        <select-drop-down choice={this.viewOption} options={options} />
      </div>
    )
  }

  get main () {
    return (
      <div class={this.jss.main}>
        {this.themePickerHTML}
        {this.viewOptionsHTML}
      </div>
    )
  }
}
