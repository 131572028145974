
import ViewComponent from 'ViewComponent'

import { buttons } from 'styles'
import icons from 'icons'
import backIcon from 'icons/light/long-arrow-left'

class BackButton extends ViewComponent {
  static get css () {
    return {
      ...super.css,
      button: {
        ...buttons.clean,
        '& svg': {
          height: '0.9em'
        }
      },
      responsiveHiddenWhenNarrow: {

        '@media (max-width: 1090px)': {
          display: 'none',
          padding: '4px 4px 4px 4px'
        }
      }
    }
  }

  backClick () {
    history.back()
  }

  get template () {
    const { jss } = this
    return (
      <div class={this.jss.button}
        ko-click={() => this.backClick()}>
        {icons.button(backIcon)}
        <span class={jss.responsiveHiddenWhenNarrow}>Back</span>
      </div>
    )
  }
}

BackButton.register()
