import { blobToDataURL } from 'jcrypto/blob'
type EntityModelImportData = import('EntityModel/interfaces').EntityModelImportData

/**
 * Create or update the given `entity` from the given .XC.ZIP file (from FastCo)
 */
export async function xcToEntity (file: File, authManager: AuthManager) : Promise<EntityModelImportData> {
  const url = await blobToDataURL(file)
  const bytes = url.split(',').pop()
  return await authManager.firebaseFn('convertFastco', { bytes })
}
