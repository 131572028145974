import { Responsible } from 'DataModel/components'
import { makePersonRecord } from 'person'

import { default as RightViewList } from './RightViewList'

/**
 *         ——— Responsible ———
 *
 * Usage:
 *
 *      <minute-book-right-view-responsible
 *        responsible={} />
 *
 */
export class MinuteBookRightViewResponsible extends RightViewList<Responsible> {
  personGenerator: PersonGenerator

  constructor (params) {
    const { personGenerator } = params
    super(params)
    Object.assign(this, { personGenerator })
  }

  get title () { return 'Responsible' }

  itemInnerHTML (r: Responsible) {
    const p = r.person() as PersonRecord
    return [...p.name, ...p.email].find(v => v) || '—'
  }

  get leadHTML () { return 'Colleagues assigned to this entity' }
  get pickerHTML () {
    const match = (r: Responsible, v: string) => r.person().name.includes(v)
    const addPerson = (name: string) => {
      const person = makePersonRecord({ name: [name] })
      this.list.push(new Responsible({ person }, this.personGenerator.componentFor('responsible')))
    }
    return (
      <person-picker
        my='top right'
        at='bottom right'
        searchType='includes'
        generator={this.personGenerator}
        inputClass={this.jss.input}
        filter={name => !this.list().find(r => match(r, name))}
        onSelect={name => addPerson(name)}
        resetQueryOnSelect={true}
      />
    )
  }
}

MinuteBookRightViewResponsible.register()
