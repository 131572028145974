import { noop } from 'lodash-es'

import ViewComponent from 'ViewComponent'

const BLUR_TIMEOUT_MS = 2150

/**
 * An editable line of text
 * Usage:
 *
 *      <inline-input
 *        editing {Observable.<bool>} true when editing; false when showing
 *        value {Observable.<string>} value to be modified
 *        inputClass {object} for JSS styles
 *        onChange {function} callback when complete
 */
export default class InlineInput extends ViewComponent {
  constructor ({ value, onChange, editing, dblClickToEdit, inputClass, textClass, navigation }) {
    super()
    Object.assign(this, {
      value,
      inputClass,
      textClass,
      dblClickToEdit,
      editing: editing || ko.observable(false),
      input: ko.observable(value()),
      onBlur: evt => this.startDeferredBlur(evt),
      onChange: onChange || noop,
      navigation,
    })
    if (navigation) {
      this.focused = this.editing
      navigation.append(this)
    }
  }

  startDeferredBlur (evt) {
    if (!this.editing()) { return }
    const _blurTimeout = setTimeout(
      () => this.onBlurDeferred(evt), BLUR_TIMEOUT_MS)
    const _editing = this.editing.subscribe(editing => {
      if (editing) { return }
      clearTimeout(_blurTimeout)
      _editing.dispose()
    })
  }

  // We defer here so we can test if the node was blurred b/c it was removed
  // i.e. the filter list was updated, or b/c the user has manually blurred.
  async onBlurDeferred (evt) {
    const node = evt.target
    if (document.activeElement === node) { return }
    if (node.closest('body') && this.value() !== this.input()) {
      this.value(this.input())
      this.onChange(this.input())
    }
    this.editing(false)
  }

  onEnter (evt) {
    evt.stopPropagation()
    evt.preventDefault()
    this.value(this.input())
    this.onChange(this.input())
    this.editing(false)
  }

  onEscape (evt) {
    this.input(this.value())
    this.editing(false)
  }

  onTab (evt) {
    if (this.navigation) {
      evt.stopPropagation()
      evt.preventDefault()
      this.value(this.input())
      this.onChange(this.input())
        if (evt.shiftKey) {
        this.navigation.previousFocus()
      } else {
        this.navigation.nextFocus()
      }
    }
  }

  onKeyDown (evt) {  // Because ko-keydown doesn't capture meta key combinations
    const fnName = `on${evt.key}`
    if (this[fnName] && typeof(this[fnName]) === 'function') {
      evt.stopPropagation()
      evt.preventDefault()
      this[fnName](evt)
    } else {
      return true
    }
  }

  get koEvent () {
    return {
      keydown: (_, evt) => this.onKeyDown(evt),
      blur: (_, evt) => this.onBlur(evt),
    }
  }

  get inputHTML () {
    this.input(this.value())
    return (
      <input type='text'
        class={this.inputClass}
        ko-textinput={this.input}
        ko-hasFocus={1}
        ko-select-text={1}
        ko-click={evt => { evt.preventDefault(); evt.stopPropagation() }}
        ko-event={this.koEvent}
      />
    )
  }

  get textValue () { return this.value() }

  onTextClick (evt) {
    if (!this.dblClickToEdit) {
      evt.stopPropagation()
      evt.preventDefault()
      this.editing(true)
      return
    }
    const lastClickTime = this.lastClickTime || 0
    if (new Date() - lastClickTime < 300) {
      evt.stopPropagation()
      evt.preventDefault()
      this.editing(true)
    }
    this.lastClickTime = new Date()
  }

  get textHTML () {
    return <div
      ko-click={evt => this.onTextClick(evt)}
      style='min-width: 100%; min-height: 100%'
      class={this.textClass}>{this.textValue}</div>
  }

  get template () {
    return this.computed(() =>
      this.editing() ? this.inputHTML : this.textHTML)
  }
}

InlineInput.register()
