/**
 * https://flaviocopes.com/css-system-fonts/
 * https://bitsofco.de/the-new-system-font-stack/
 * Some sample system fonts:
 *
 * —— GitHub ——
 * -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol
 *
 * —— Medium ——
 * medium-content-sans-serif-font,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif
 */
const sansFace = [
  '-apple-system',
  // 'system-ui',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Fira Sans',
  'Helvetica Neue',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'sans-serif',
].map(v => `"${v}"`).join(',')

const monoFace = [
  'SFMono-Regular',
  'Consolas',
  'Liberation Mono',
  'Menlo',
  'Courier',
  'monospace',
].map(v => `"${v}"`).join(',')

export default {
  bodyFontFamily: sansFace,
  headFontFamily: sansFace,
  altFontFamily:  `'Roboto Slab', serif`,
  mono: monoFace,
  titleFamily: sansFace,

  brand: {
    title: {
      fontFamily: sansFace,
      fontWeight: '700',
      fontStyle: 'normal',
      fontSize: '1.1rem'
    },

    text: {
      fontFamily: sansFace,
      fontWeight: '300',
      fontStyle: 'normal',
      fontSize: '0.8rem'
    },

    logo: {
      fontFamily: sansFace,
      fontWeight: '700'
    }
  },

  face: {
    headingBar: {
      fontFamily: sansFace,
      fontSize: '1.2rem',
      fontWeight: 700,
      letterSpacing: '0.1px',
    },
    title: {
      fontFamily: sansFace,
      fontWeight: 700,
      fontSize: '0.7rem',
      textTransform: 'uppercase',
      letterSpacing: '0.3px',
    },
    text: {
      fontFamily: sansFace,
      fontWeight: 300,
      fontSize: '1rem',
    },
  },

  sans: {
    fontFamily: sansFace,
    fontWeight: 'normal'
  },

  weight: {
    thin: 200,
    book: 350,
    heavy: 900
  }
}


// small caps:
// fontFeatureSettings: "'smcp'",
// '-mozFontFeatureSettings': "'smcp'",
// '-webkitFontFeatureSettings': "'smcp'",
// fontVariantCaps: 'small-caps',
