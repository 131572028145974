import { buttons, color } from 'styles'

import { SHARE_CANCEL_REASONS } from 'entity-information-summary/constants'
import TransactionEditor from './TransactionEditor'
import { TransactionCancel } from '../Transaction'
import { AssetCertificate, Stakeholder } from '../interfaces'

/**
 * A Share Issuance consists of:
 *    - ShareCredit (S.+)
 *      - person
 *      - amount
 *      - assetClassID
 *
 *   Optional
 *   - ShareTransferNumber (S.#)
 *      - number
 *      - assetClassID
 */
class CapitalShareCancelEditor extends TransactionEditor<TransactionCancel> {
  holder: KnockoutComputed<Stakeholder>
  needsResidualCertificate: KnockoutComputed<boolean>
  residual: KnockoutComputed<BigType>
  amountHeld: KnockoutComputed<BigType>
  certInput: KnockoutObservable<string>

  constructor (params) {
    super(params)
    this.holder = this.computed<Stakeholder>(this._holder.bind(this))
    this.amountHeld = this.computed<BigType>(this._amountHeld.bind(this))
    this.residual = this.computed<BigType>(this._residualAmount.bind(this))
      .extend({ deferred: true })
    this.needsResidualCertificate = this.computed<boolean>(
      () => this._needsResidualCertificate())
      .extend({ deferred: true })
    this.certInput = ko.observable<string>()
    this.certInput.subscribe(c => this.onCertInputUpdate(c))
    this.computed<AssetCertificate[]>(() => this._certsToCancel())
      .subscribe(certs => this.clone.certificatesCancelled(certs.map(c => c.number)))
      // .subscribe(certs => this.certInput(certs.join(' ')))
  }

  createTransaction ({ assetClassID }) : TransactionCancel {
    return new TransactionCancel({
      assetClassID,
      person: '', amount: 0, datetime: null, title: 'Cancel'
    })
  }

  onCertInputUpdate (input: string) {
    const cancelled = this.clone.certificatesCancelled
    const rex = /\b(\d+)\b/g
    const matches = rex.exec(input)

    if (!matches) {
      cancelled([])
    } else {
      cancelled(matches.slice(1).map(s => parseInt(s, 10)))
    }
  }

  _holder () {
    return this.stateBefore().getStakeholder(this.clone.person())
  }

  _certsToCancel () : AssetCertificate[] {
    return this.holder()
      .certificates
      .filter(c => c.assetClassID === this.clone.assetClassID)
  }

  _amountHeld () : BigType {
    return this.holder()
      .getAsset(this.clone.assetClassID)
  }

  _residualAmount () : BigType {
    return this._amountHeld().minus(this.clone.amount() || 0)
  }

  _needsResidualCertificate () {
    return !this.residual().eq(0)
  }

  _residual (title: string, amount: KnockoutObservable<BigType>) {
    const { jss } = this
    const certInputClass = ko.computed(() =>
      this.needsResidualCertificate()
        ? jss.certInputNeeded : jss.certInputIgnored)
    const currentAutoNumber = () =>
      this.stateBefore().autoNumber.get(`${this.assetClassID}.cert`)

    return (
      <>
        {this._title(title)}
        <div class={jss.residual}>
          <div class={jss.residualAmount}
            ko-ownClick={() => this.clone.amount(this.amountHeld().toString())}>
            {this.computed(() => amount().toString())}
          </div>
        </div>
        {this._title('Residual Cert.')}
        <certificate-input
          class={certInputClass}
          state={this.computed(() => this.stateBefore())}
          currentAutoNumber={currentAutoNumber}
          assetClassID={this.clone.assetClassID}
          inputClass={this.jss.input}
          value={this.clone.residualCertificate}
        />
      </>
    )
  }

  get questionHTML () {
    const { jss, clone } = this

    return [
      this._reasonPicker('Reason', clone.title, SHARE_CANCEL_REASONS),
      this._datePicker('Date', clone.datetime),
      this._personPicker('Holder', clone.person),
      this._numberInput('Amount', clone.amount),
      // this._textInput('Certificates to Cancel', this.certInput),
      this._residual('Residual', this.residual),
      this._nextTransferNumber('Transfer Number', clone.trNumber),
      this._considerationPicker('Monetary Consideration', clone.monetaryConsideration, clone.considerationIsAggregate),
      this._textInput<string>('Non-Monetary Consideration',
        clone.nonMonetaryConsideration),
    ].filter(d => d).flat()
  }

  static get css () {
    return {
      ...super.css,
      residual: {
        display: 'flex',
        gridColumn: '2/3',
        alignItems: 'center',
      },
      residualAmount: {
        ...buttons.clickable,
        padding: '0px 10px',
        minWidth: '100px',
      },
      certInputNeeded: {},
      certInputIgnored: {
        opacity: 0.5
      },

      input: {
        ...super.css.input,
        background: color.textInput.light.primary,
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          background: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },
      }
    }
  }

  isValidTransaction (args: TransactionCancel) {
    const { person, amount } = ko.toJS(args)
    return person && amount
  }
}

CapitalShareCancelEditor.register()
