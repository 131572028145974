
/**
 * @param {Array.<any>}
 * @param {any} item
 * @return {any} following `item` (cycles)
 */
export function cycleNext (array, item) {
  if (array.length === 0) { return item }
  const idx = array.indexOf(item) + 1
  return array[idx % array.length]
}

/**
 * @param {Array.<any>}
 * @param {any} item
 * @return {any} preceding `item` (cycles)
 */
export function cyclePrev (array, item) {
  if (array.length === 0) { return item }
  const idx = array.indexOf(item) + array.length - 1
  return array[idx % array.length]
}

/**
 * Move the item in `array` at `fromIndex` to `toIndex`
 */
export function arrayMove (array: Array<any>|KnockoutObservableArray<any>, fromIndex: number, toIndex: number) : void {
  if (fromIndex < toIndex) { toIndex-- }
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0])
}


/**
 * Add or set the default as an array for the object/property.
 * e.g. arrayDefault({}, 'x') adds [] to {}, returning [].
 */
export function arrayDefault<T> (obj: object, property: string): T[] {
  return obj[property] || (obj[property] = [])
}

/**
 * Move the item in the array accordingly
 */
export function move<T> (array: T[] | KnockoutObservableArray<T>, from: number, to: number) {
  if (to < 0 || to > array.length) { return }
  array.splice(to, 0, array.splice(from, 1)[0])
}
