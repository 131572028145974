/**
 * Question-Tip
 *
 * A pre-styled tooltip with an anchor that's always the (?).
 *
 * Process:
 *   1. add a message to q-tip-messages.js
 *   2. Reference the message with `<q-tip name='message-name' />`
 *
 */
import { interleave } from 'utils/iterable'

import { inline } from 'icons'
import qIcon from 'icons/light/question-circle'

import ToolTip from './tool-tip'

import * as qTips from './qtip-messages'

import { color, typography } from 'styles'

const defaultPositions = {
  my: 'top center',
  at: 'bottom center'
}

export default class QTip extends ToolTip {
  constructor (params) {
    const { name } = params
    super(Object.assign({}, defaultPositions, params))
    const content = this.getContent(name)
    if (!content) { console.error(`No QTip named ${name}`) }
    Object.assign(this, { content })
  }

  getContent (name) { return qTips[name] }

  static get css () {
    return {
      ...super.css,
      anchor: {
        ...super.css.anchor,
        display: 'inline-block',
        padding: '0px 6px'
      },

      description: {
        width: '300px',
        whiteSpace: 'normal',
        fontSize: '0.7em',
        padding: '10px',
        background: 'white',
        borderTop: '3px solid #ffd502',
        borderRadius: 2,
        color: 'black',
        margin: '5px 8px',
        boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.3)',

        '& strong': {
          fontFamily: typography.mono,
          backgroundColor: '#eff0f1',
          padding: '2px 6px 0px 6px',
          fontWeight: 600,
        },
        '& kbd': {
          fontFamily: typography.mono,
          backgroundColor: '#eff0f1',
          padding: '2px 6px 0px 6px',
          fontWeight: 600,
        }
      },

      q: {
        '--icon-color': color.label.light.quarternary,
        'body[dark] &': { // project batman
          '--icon-color': color.label.dark.quarternary,
        },

        '&:hover': {
          '--icon-color': color.label.light.secondary,
          'body[dark] &': { // project batman
            '--icon-color': color.label.dark.secondary,
          },
        }
      }
    }
  }

  get anchorHTML () {
    return <span class={this.jss.q}>{inline(qIcon)}</span>
  }

  get contentHTML () {
    switch (typeof this.content) {
      case 'string': return [...interleave(this.content.split('\n'), <br />)]
      case 'function': return this.content(this.jss)
      default: return this.content // JSX
    }
  }
}

QTip.register('q-tip')
