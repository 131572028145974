//
// PlainMediator
// ---
// Serialize and deserialize a single observable.
//

import MediatorInterface from './MediatorInterface'

type DataModel = import('../DataModel').default

export default class PlainMediator<T> extends MediatorInterface<KnockoutObservable<T>, T> {
  name: string
  model: DataModel

  newHolderInstance () { return ko.observable() }
  toJS () { return ko.toJS(this.model[this.name]()) }
  fromJS (val: T) { this.model[this.name](val) }
}
