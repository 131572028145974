import { parseISO } from 'date-fns/esm'
import { get } from 'lodash-es'

import DisplayListBaseComponent from 'DisplayListBaseComponent'
import notesIcon from 'icons/solid/pen-square'
import solidPinIcon from 'icons/solid/thumbtack'
import editIcon from 'icons/solid/pen-square'
import calendarIcon from 'icons/regular/calendar-alt'
import flagIcon from 'icons/solid/flag'
import icons from 'icons'

import { color } from 'styles'

import { format, fromUnixTime } from 'date-fns/esm'

export default class NotesList extends DisplayListBaseComponent {
  get listItemName () { return 'Note' }
  get listItemNamePlural () { return 'Notes' }
  get svgIcon () { return notesIcon }

  /**
   * @param {object} param0
   * @param {ListOfNotes} param0.listOfNotes
   */
  constructor (params, ...args) {
    super(params, ...args)
    this.listOfNotes = params.listOfNotes
  }

  static get css () {
    return {
      ...super.css,
      message: {
        marginBottom: '30px',
        color: 'rgba(0,0,0,0.8)',
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
      meta: {
        display: 'inline-grid',
        grid: {
          autoFlow: 'column',
          gap: '5px',
        },
        position: 'absolute',
        bottom: '5px',
        right: '10px',
        '& > *': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },

      icon: {
        visibility: 'hidden',
        '$details:hover &:not([show])': {
          visibility: 'visible',
          opacity: '0.6',
          color: 'black',
          '&:hover': {
            opacity: '1',
            transition: 'opacity 100ms'
          }
        },
        '&[show]': {
          visibility: 'visible',
          opacity: '1',
        },
      },

      pinIcon: {
        extend: 'icon',
      },

      editIcon: {
        extend: 'icon',
        '--icon-color': 'rgba(0,0,0,1)',
        'body[dark] &': { // project batman
          '--icon-color': 'rgba(255,255,255,1)',
        },
      },

      calendarIcon: {
        extend: 'icon',
      },

      flagIcon: {
        extend: 'icon',
        '&[show]': {
          visibility: 'visible',
          opacity: '1',
          '--icon-color': 'red',
        },
      },

      created: {
        fontSize: '0.6rem',
        color: color.text.light.secondary,
        background: color.fill.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
          background: color.fill.dark.secondary,
          textShadow: '0px 1px 0px rgba(0,0,0,1)',
        },
        padding: '5px 15px',
        borderRadius: 15,
        textShadow: '0px 1px 0px rgba(255,255,255,1)',
        '&:hover': {
          background: color.fill.light.primary,
          color: color.text.light.primary,
          'body[dark] &': { // project batman
            background: color.fill.dark.primary,
            color: color.text.dark.primary
          },
        }
      }
    }
  }

  getPageNumber (pinpoint) {
    return (pinpoint && pinpoint.type === 'pageID') ? (this.bookComponent.getIndexOfpageID(pinpoint.pageID) + 1) : 0
  }

  /**
   * NOTE: This is O(n²)
   * @param {Firestore.DocumentSnapshot} a note
   * @param {Firestore.DocumentSnapshot} b
   */
  sortByPage (a, b) {
    const aData = a.data()
    const bData = b.data()
    const aIndex = this.getPageNumber(aData.pinpoint)
    const bIndex = this.getPageNumber(bData.pinpoint)
    if (!aIndex) {
      const aCreated = get(aData, 'created.seconds', 0)
      const bCreated = get(bData, 'created.seconds', 0)
      return bIndex ? -1 : aCreated - bCreated
    }
    return aIndex - bIndex
  }

  noteClick(bookPage) {
    bookPage.scrollIntoView()
    if (this._lastClick === bookPage) {
      this.listOfNotes.addOrEditNote(bookPage)
    }
    this._lastClick = bookPage
  }

  get notesList () {
    return this.listOfNotes.list()
  }

  makeDeleteFn(note) {
    return () => note.ref.delete()
  }

  setPinnedValue (note, v) {
    note.ref.set({...note.data(), pinned:v})
  }

  makeFieldObservable (note, field) {
    const ob = ko.pureComputed({
      read: () => note.data()[field],
      write: v => note.ref.set({...note.data(), [field]:v}),
    })
    ob.modify = fn => ob(fn(ob()))
    return ob
  }

  openDateModal (reminder, editingReminder) {
    const dateStr = this.computed({
      read: () => reminder() && parseISO(reminder()),
      write: v => reminder(v ? format(v, 'yyyy-MM-dd') : null),
    })
    editingReminder(true)
    window.app.modal(
      <date-picker-modal
        modalTitle='Pick a date'
        target={dateStr}
        relatedDates={this.bookComponent.model} />
    )
    window.app.modal.when(undefined).then(() => editingReminder(false))
  }

  noteCreatedDate (created: number | { seconds: number }) : Date {
    if (!created) { return new Date() }
    return ('seconds' in created)
      ? fromUnixTime(created.seconds)
      : fromUnixTime(created)
  }

  get contentList () {
    const { jss } = this
    return this.notesList
      .sort((a, b) => this.sortByPage(a, b))
      .map(note => {
        const data = note.data()
        const createdDate = this.noteCreatedDate(data.created)
        const created = format(createdDate, 'LLLL do, yyyy HH:mm:ss')
        const bookPage = data.pinpoint && data.pinpoint.type === 'pageID'
          ? this.bookComponent.getPageByString(data.pinpoint.pageID)
          : null
        const pinned = ko.observable(data.pinned || false)
        pinned.subscribe(v => this.setPinnedValue(note, v))

        const reminder = this.makeFieldObservable(note, 'reminder')
        const showingReminder = ko.observable(false)
        const editingReminder = ko.observable(false)
        showingReminder.subscribe(() => reminder() || showingReminder(false))

        const flagged = this.makeFieldObservable(note, 'flagged')

        return {
          bookPage,
          jsx: [
            <div class={jss.message}>{data.message}</div>,
            <div class={jss.meta}>
              <span class={jss.editIcon}
                ko-click={evt => {evt.stopPropagation(); this.listOfNotes.addOrEditNote(bookPage)}}>
                {icons.inline(editIcon)}
              </span>

              <tool-tip my='top right' at='bottom right' showing={showingReminder}>
                <template slot='anchor'>
                  <span class={jss.calendarIcon}
                    show={this.computed(() => reminder() || editingReminder() || undefined)}
                    ko-ownClick={() => this.openDateModal(reminder, editingReminder)}>
                    {icons.inline(calendarIcon)}
                  </span>
                </template>
                <template slot='content'>{reminder}</template>
              </tool-tip>

              <span class={jss.flagIcon}
                show={this.computed(() => (flagged() || undefined))}
                ko-click={evt => {evt.stopPropagation(); flagged.modify(v => !v)}}>
                {icons.inline(flagIcon)}
              </span>

              <span class={jss.pinIcon}
                show={this.computed(() => (pinned() || undefined))}
                ko-click={evt => {evt.stopPropagation(); pinned.modify(v => !v)}}>
                {icons.inline(solidPinIcon)}
              </span>
              <span class={jss.created}>{created}</span>
            </div>
          ],
          onClick: () => this.noteClick(bookPage),
          delete: this.makeDeleteFn(note)
        }
      })
  }
}

NotesList.register()
