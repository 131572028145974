
import ViewComponent from 'ViewComponent'
import { buttons, typography, color } from 'styles'

import icons from 'icons'
import collapseIcon from 'icons/solid/caret-right'

type Command = import('Command').default
type ViewID = string
type JSXContent = any

export interface View {
  id: ViewID
  title: JSXContent
  active: KnockoutObservable<boolean>
  command: Command
  loading: KnockoutObservable<boolean>
  subMenuItems: JSXContent
  subMenuStyleMap: Record<string, string>
  collapsed: KnockoutObservable<boolean>
  headingStyleClass?: string
}

/**
 *             |———— ViewListPanelLeft ————|
 */
export default class ViewListPanelLeft extends ViewComponent {
  views: View[] | KnockoutObservableArray<View>
  activeViewID: KnockoutObservable<ViewID>
  showPanel: KnockoutObservable<boolean>

  constructor ({ views, activeViewID, showPanel }) {
    super()
    Object.assign(this, { views, activeViewID, showPanel })
    const active = ko.unwrap(this.views.find(v => v.active()))
    if (active) { active.collapsed(false) }
  }

  get viewListHTML () {
    const views = this.views as View[]
    return views.map(v => this.viewListItemHTML(v))
  }

  viewTitle (view) {
    return view.title
  }

  /**
   * @param {MainView} view
   */
  viewListItemHTML (view) {
    const { jss } = this
    const activeCSS = this.computed(
      () => ({ [jss.activeHead]: view.active() }))

    const collapsed = view.collapsed
    const isShown = this.computed(() => !view.command || !view.command.isHidden)

    return (
      <div class={jss.item} ko-visible={isShown} collapsed={collapsed}>
        <div class={view.headingStyleClass || jss.head}
          ko-click={evt => this.onViewClick(evt, view)}
          ko-css={activeCSS}>
          {this.viewTitle(view)}
          <div class={jss.menuCollapseIcon}
            ko-visible={this.hasSubMenuItems(view)}
            ko-ownClick={() => collapsed.modify(v => !v)}>
            {icons.inline(collapseIcon)}
          </div>
        </div>
        <div class={jss.subMenu}
          ko-collapse={collapsed}
          ko-event={{ transitionend: () => view.loading(false) }}
          ko-style-map={this.computed(() => view.active() && view.subMenuStyleMap)}>
          {this.subMenuItems(view)}
        </div>
      </div>
    )
  }

  protected subMenuItems (view: View) {
    if (Array.isArray(ko.unwrap(view.subMenuItems))) {
      return view.subMenuItems.map(i =>
        ko.ignoreDependencies(() => this.subMenuItemHTML(i))
      )
    }
    return view.subMenuItems
  }

  protected hasSubMenuItems (view) { return Boolean(view.subMenuItems) }
  protected subMenuItemHTML (item) { return item }

  onViewClick (evt, view) {
    /*
    if (view.id !== this.activeViewID()) {
      view.loading(true)
      if (!view.subMenuItems || view.subMenuItems.length === 0) {
        setTimeout(() => view.loading(false), 10)
      }
    }
    */
    if (view.command) { view.command.trigger(evt) }
  }

  get template () {
    const { jss } = this
    const panelClass = this.computed(() => (
      this.showPanel() ? jss.panelShown : jss.panelHidden
    ))
    return (
      <div class={panelClass}>
        <div class={jss.listContainer}>
          <div class={jss.list}>{this.viewListHTML}</div>
        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      head: {
        ...buttons.clickable,
        ...typography.face.title,
        color: color.gray.aa,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
        padding: '10px 0px 10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      activeHead: {
        backgroundColor: color.gray.activeHead,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.secondary,
        },
      },
      subMenu: {
        overflow: 'hidden',
        transition: '0.4s',
      },
      menuCollapseIcon: {
        display: 'inline',
        minHeight: 'inherit',
        '--icon-color': color.gray.b,
        padding: '0 15px',
        '&:hover': {
          '--icon-color': 'black',
        },
        '& svg': {
          transition: '0.4s',
          transform: 'rotate(90deg)',
          '[collapsed=true] > div > &': {
            transform: 'rotate(0deg)',
          },
        },
      },
      list: {
        height: 'calc(100vh - var(--head-height))',
        paddingTop: '12px',
        borderRight: '1px solid #bababa',
        overflowY: 'auto',
      },
      panel: {
        position: 'sticky',
        top: 'var(--head-height)',
        width: '0px',
        whiteSpace: 'nowrap',
        height: 'calc(100vh - var(--head-height))',
        '& > *': {
          width: 'var(--left-panel-default-width)',
          backgroundColor: '#fafafa',
          'body[dark] &': { // project batman
            backgroundColor: color.systemBackground.dark.secondary
          },
        }
      },
      panelShown: {
        extend: 'panel',
        transform: 'translate3d(0, 0, 0)',
        transition: 'transform 0.25s ease-in',
      },
      panelHidden: {
        extend: 'panel',
        transform: 'translate3d(calc(-1 * var(--left-panel-default-width)), 0, 0)',
        transition: 'transform 0.25s ease-out',
      },
    }
  }
}

ViewListPanelLeft.register()
