
import 'minute-book-eis-sections'
import 'entity-information-summary'
import 'model-audit'
import 'notes-list/pinned-notes'

import '../minute-book-sections-list'

type MinuteBookPanelProvider = import('../MinuteBookPanelProvider').default

/**
 *    |— MainView —| (abstract)
 */
export abstract class MainView extends ko.LifeCycle {
  panelProvider: MinuteBookPanelProvider

  constructor (panelProvider) {
    super()
    Object.assign(this, {
      panelProvider,
      hideSubMenu: ko.observable(),
      loading: ko.observable(false),
    })

    this.active = ko.pureComputed(
      () => panelProvider.activeView() === this)
  }

  get collapsed () {
    return this._collapsed || (this._collapsed = ko.observable(true))
  }

  get scrollKey () {
    return `${this.id}-##-${this.panelProvider.model.id()}`
  }

  get dropPadHTML () {
    const { jss } = this
    return (
      <div class={jss.dropPadContainer} ko-visible={this.panelProvider.panelDragOverEvent}>
        <div class={jss.mainDropPad}>Drop Files Here to Upload</div>
      </div>
    )
  }

  get id () { return this.constructor.name }
  abstract get title ()
  abstract get mainHTML ()
  get subMenuItems () { return null }
  get showEditButton () { return false }
  get showUndoButton () { return false }
  get command () {
    return this.panelProvider.commandSet.commands[this.commandName]
  }

  get main () {
    const { listOfNotes, bookComponent } = this.panelProvider
    return (
      <div class={`${this.jss.mainContainer} ${this.jss.leftcompressed}`}>
        {this.dropPadHTML}
        <pinned-notes class={this.jss.pinnedNotes} bookComponent={bookComponent} listOfNotes={listOfNotes} />
        {this.mainHTML}
      </div>
    )
  }

  static get css () {
    return {
      mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      pinnedNotes: { },
      translated: {
        transition: 'var(--right-panel-transition-out)',
        '[right-panel-open] &': {
          transition: 'var(--right-panel-transition-in)',
          transform: 'translate3d(calc(-0.5 * var(--right-panel-width)), 0, 0)'
        },
        '[left-panel-open] &': {
          transition: 'var(--right-panel-transition-in)',
          transform: 'translate3d(calc(0.5 * var(--left-panel-default-width)), 0, 0)'
        },
        '[right-panel-open][left-panel-open] &': {
          transition: 'var(--right-panel-transition-in)',
          transform: 'translate3d(calc(-0.5 * var(--right-panel-width) + 0.5 * var(--left-panel-default-width)), 0, 0)',
        },
      },

      // For views which require a width transition
      leftcompressed: {
        width: '100%',
        transition: '0.25s ease-out',
        '[left-panel-open] &': {
          transition: '0.25s ease-in',
          width: 'calc(100% - var(--left-panel-default-width))',
          transform: 'translate3d(calc(var(--left-panel-default-width)), 0, 0)',
        },
      },

      fullcompressed: {
        width: '100%',
        transition: '0.25s ease-out',
        '[right-panel-open] &': {
          width: 'calc(100% - var(--right-panel-width))',
        },
        '[left-panel-open] &': {
          transition: '0.25s ease-in',
          width: 'calc(100% - var(--left-panel-default-width))',
          transform: 'translate3d(calc(var(--left-panel-default-width)), 0, 0)',
        },
        '[right-panel-open][left-panel-open] &': {
          transition: '0.25s ease-in',
          width: 'calc(100% - var(--right-panel-width) - var(--left-panel-default-width))',
          transform: 'translate3d(var(--left-panel-default-width), 0, 0)',
        },
      }
    }
  }

  private _styles: Record<string, object>
  get styles () {
    const options = {
      classNamePrefix: `${this.constructor.name}__`,
      meta: `🛣  MinuteBook MainView ${this.constructor.name}`
    }
    return this._styles || (
      this._styles = jssLib.createStyleSheet({
        ...this.constructor.css,
        ...this.css,
      }, options).attach()
    )
  }

  dispose () { jssLib.removeStyleSheet(this.styles) }
  get jss () { return this.styles.classes }
}


