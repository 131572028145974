
import { DataComponent } from 'DataModel'

import WritingDocument from '../WritingDocument'
import { BlockRegister } from '../blocks'

interface PrecedentSerialized {
  blocks: [string, object][]
  repeat: string
}

export default class PrecedentWritingComponent extends DataComponent {
  document: WritingDocument

  static get namespace () { return 'writing' }
  init () {
    this.document = new WritingDocument()
  }

  get () { return this.document }

  set (data: PrecedentSerialized = { blocks: [], repeat: null }) {
    const blocks = data.blocks || []
    this.document.blocks.set(blocks.map(b => BlockRegister.factory(...b)))
    this.document.repeat(data.repeat)
  }

  toJS (): PrecedentSerialized {
    return {
      blocks: [...this.document.blocks].map(b => b.encoded),
      repeat: this.document.repeat(),
    }
  }

  isBlank () {
    return this.document.isBlank()
  }
}

PrecedentWritingComponent.register()
