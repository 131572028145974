type Transaction = import('./Transaction').Transaction

/**
 * An `Asset` keeps track of the properties of an class
 * of asset that people may hold of the company, such as
 * shares (stock), warrants, etc.
 */
export class Asset {
  id: string
  properties: Record<string, any>
  authorizedBy: Transaction

  constructor (id: string, authorizedBy: Transaction) {
    Object.assign(this, { id, properties: {}, authorizedBy })
  }

  get assetName () {
    const prefix = this.getProperty('prefix')
    const series = this.getProperty('series')
    return [
      this.getProperty('classification'),
      series && `Series ${series}`,
      prefix && `(${prefix})`,
    ].filter(p => p).join(' ')
  }

  get assetNameParts () {
    return {
      prefix: this.getProperty('prefix', '') as string,
      series: this.getProperty('series', '') as string,
      classification: this.getProperty('classification', '') as string,
    }
  }

  setProperty (key: string, value: KnockoutObservable<any> | any) : void {
    if (value === undefined) {
      delete this.properties[key]
    } else {
      this.properties[key] = ko.unwrap(value)
    }
  }

  getProperty (key: string, _default?: any) : any {
    return key in this.properties
      ? this.properties[key] : _default
  }

  get propertyKeys (): string[] { return Object.keys(this.properties) }

  get authorizationDate (): Date {
    if (!this.authorizedBy) { return null }
    return this.authorizedBy.datetime()
  }
}

export default Asset
