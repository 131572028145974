
import { exportDataModel } from 'DataModel'

import CommandSet from './CommandSet'
import Command from './Command'

const { pako, FileSaver } = window

export default class ModelCommandSet extends CommandSet {

  makeCommands () {
    const exportModel = new Command({
      title: 'Export',
      action: () => {
        const { model } = this.panelProvider
        const data = exportDataModel(model)
        const name = `${model.cvModelTitle}-${model.id()}.box.gz`
        const mimetype = { type: 'application/gzip' }
        console.log(data)
        const blob = new File([pako.gzip(JSON.stringify(data))], name, mimetype)
        FileSaver.saveAs(blob)
      },
    })

    return {
      ...super.makeCommands(),
      exportModel,
    }
  }

  get keyboardShortcuts () {
    const c = this.commands
    const { META, CTRL, SHIFT } = this

    return [
      ...super.keyboardShortcuts,
      ...c.exportModel.keyboardShortcuts('e', { ...META, ...SHIFT }),
      ...c.exportModel.keyboardShortcuts('e', { ...CTRL, ...SHIFT }),
    ]
  }
}
