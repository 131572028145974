import PanelHead from "MinuteBookPanelProvider/panel-head"

import { inline } from 'icons'
import editIcon from 'icons/solid/edit'
import viewIcon from 'icons/regular/window'
import chevronDown from 'icons/light/chevron-down'
import tocIcon from 'icons/custom/iconToc'
import continuousScroll from 'icons/custom/iconContinuousScroll'
import { ButtonDropDownItem } from 'views/button-drop-down'

type PPP = import('./PersonPanelProvider').default

export default class PersonPanelHead extends PanelHead<PPP> {
  entityDisplayValue : string
  editClick : (evt:MouseEvent) => any
  showEditButton : KnockoutObservable<boolean>

  constructor ({ panelProvider, entityName, editClick, showEditButton }) {
    super({ panelProvider })
    Object.assign(this, {
      entityDisplayValue: entityName,
      editClick,
      showEditButton : showEditButton || ko.observable(true),
    })
  }

  get buttonRow () {
    const { jss } = this
    return [
      <div class={jss.buttonGroup}>
        {this.viewHTML}
      </div>,
      <div class={jss.buttonGroup}>
        { /* this.undoHTML */ }
      </div>,
      <div class={jss.buttonGroup}>
        { /* <span class={jss.tags}>{this.commandButton('Tags', tagIcon, 'triggerTags')}</span> */ }
      </div>,
      <div class={jss.buttonGroup}>
        { this.computed(() => this.showEditButton() ? this.editButtonHTML : undefined) }
      </div>,
    ]
  }

  get viewHTML () {
    const { jss } = this
    const icons = [inline(viewIcon), inline(chevronDown)]
    const { showToC, showAllFields } = this.panelProvider

    const items: ButtonDropDownItem[] = [
      this.viewToggleItem('Table of Contents', tocIcon, showToC),
      this.viewToggleItem('All Fields', continuousScroll, showAllFields),
    ]
    return (
      <button-drop-down my='top left' at='bottom left'
        text='View' icon={viewIcon} items={items}
      />
    )
  }

  static get css () {
    return {
      ...super.css,
      editButton: {
        ...super.css.button,
        marginRight: '120px',
      },
    }
  }

  get editButtonHTML () {
    const { jss } = this
    return (
      <div class={jss.editButton}
        ko-click={evt => this.editClick(evt)}>
        <div class={jss.buttonIcon}>{inline(editIcon)}</div>
        <div class={jss.buttonTitle}>Edit</div>
      </div>
    )
  }

}

PersonPanelHead.register()
