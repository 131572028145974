import { batchedOperation } from 'utils/firestore'

const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue

/**
 * PermissionChange encapsulates a change in permissions to models,
 * notably the team rights.
 *
 * This is a bit hard-coded to entities now, but should be straightforward
 * to expand to model types generically.
 */
export class PermissionChange {
  constructor (memoryDB, authManager) {
    Object.assign(this, {
      memoryDB,
      authManager,
      permissionFilterModelMap: new Map(),
    })
  }

  getFilterModel (accountID, filterKey) {
    const cached = this.permissionFilterModelMap.get(filterKey)
    if (cached) { return cached }
    const model = this.memoryDB
      .getListOf('filter')()
      .find(f => f.id() === filterKey &&
        f.authManager === this.authManager &&
        f.accountID() === accountID)
    if (!model) { return }
    this.permissionFilterModelMap.set(filterKey, model)
    return model
  }

  * genModelsToUpdate (accountID) {
    for (const indexName of this.memoryDB.INDICIES) {
      yield * this.memoryDB.genAccountModels(
        this.authManager, accountID, indexName
      )
    }
  }

  updateModel (model, teamFilterKeyPermissions, batch) {
    console.debug(` 🔒  Updating ${model.cvModelTitle}`,
      teamFilterKeyPermissions)
    batch.update(model.vmFirestoreDoc(), teamFilterKeyPermissions)
  }

  mergeSentinels (filterKey, allow) {
    const permissionRequest = {}
    for (const { char } of PossiblePermissions) {
      const arrayOp = allow.includes(char) ? arrayUnion : arrayRemove
      const fieldPath = `teamFilterKeyPermissions.${char}`
      permissionRequest[fieldPath] = arrayOp(filterKey)
    }
    return permissionRequest
  }

  /**
   * @param {DataModel} model
   * @param {Array.<object>} perms
   * @param {FilterModel} perms[0].filterModel
   * @param {string} perms[0].allow
   * @return {string} stable union of unique perm-chars
   */
  unionPermChars (perms, model) {
    const allowChars = perms
      .filter(p => p.filterModel && p.filterModel.test(model))
      .map(p => p.allow)
      .join('')
      .split('')

    const uniqueAllowChars = [...new Set(allowChars)].sort()
    return ''.concat(...uniqueAllowChars)
  }

  /**
   * Run the permissions against every model for `accountID`
   * @param {string} accountID of the filter to be updated
   * @param {string} teamFilterKey for the team
   * @param {Array.<object>} perms for each matching filter
   * @param {string} perms[0].filterKey matching the entity/user/etc model
   * @param {string} perms[0].allow crudnNa firestore one-char perms
   */
  async start (accountID, teamFilterKey, perms) {
    const { firestore } = this.authManager

    const permsWithFilter = perms.map(p => Object.assign({}, p,
      { filterModel: this.getFilterModel(accountID, p.filterKey) }))

    const iterable = this.genModelsToUpdate(accountID)

    console.info(`[Permissions] Beginning Batch Operation`)
    await batchedOperation(firestore, iterable, (model, batch) => {
      const permChars = this.unionPermChars(permsWithFilter, model)
      const sentinelValues = this.mergeSentinels(teamFilterKey, permChars)
      this.updateModel(model, sentinelValues, batch)
    })
  }
}

/**
 * These are all the possible permissions for any given model.
 *
 * Some models may not have all these permissions.
 */
export const PossiblePermissions = [
  { name: 'Read', char: 'r' },
  { name: 'Create', char: 'c' },
  { name: 'Update', char: 'u' },
  { name: 'Delete', char: 'd' },
  { name: 'View Audit', char: 'a' },
  { name: 'View Notes', char: 'n' },
  { name: 'Create Notes', char: 'N' },
]
