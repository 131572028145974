/**
 * This class keeps track of a PDF upload state & status.
 */
import { sha512digest } from 'jcrypto'
import { putBlob } from 'utils/blobStore'
import { PDFEngine } from 'pdf-engine'
import { Process } from 'utils/Process'

export class PdfUploadCancelledError extends Error {
  constructor (...args) {
    super(...args)
    this.name = "PDFUploadCancelled"
    this.message = "The upload process was cancelled."
  }
}

export default class PdfUpload {
  pdf: File | Blob
  percent: KnockoutObservable<number>
  state: KnockoutObservable<string>
  complete: KnockoutObservable<boolean>
  processState: Process
  authManager: AuthManager

  /**
   * @param {AuthManager} authManager
   * @param {Blob} pdf
   * @param {EntityModel} entity
   */
  constructor ({ authManager, pdf, entity, originalOrigin, isCancelled }) {
    Object.assign(this, {
      authManager,
      pdf,
      entity,
      originalOrigin,
      isCancelled,
      state: ko.observable('Preparing file for upload'),
      processState: new Process(pdf.size),
    })
    this.percent = this.processState.percent
    this.complete = this.processState.isComplete
    this.state.subscribe(console.info)
  }

  async process () {
    if (!this.entity) {
      throw new Error('PdfUpload needs an Entity.')
    }
    const engine = new PDFEngine()
    const fileHash = await sha512digest(this.pdf)
    const [count] = await Promise.all([
      engine.getPageCount(await this.pdf.arrayBuffer()),
      this.saveOriginal(fileHash, this.pdf)
    ])
    const newPages = this.createBookPages(fileHash, count)
    this.state('Completed')
    this.complete(true)
    return newPages
  }

  async saveOriginal (fileHash, pdf) {
    this.state(`Uploading original PDF`)
    const path = this.entity.pdfPartPath(fileHash, 'original.pdf')
    const metadata = {
      ...this.pdfMetadata,
      customMetadata: {
        originalFilename: pdf.name || '',
        originalFileHash: fileHash,
        hashAlgorithm: 'SHA-512'
      }
    }
    return putBlob(this.authManager, path, pdf, metadata, this.processState)
  }

  get pdfMetadata () {
    const maxCacheAge = 60 * 60 * 24 * 7
    return {
      originalOrigin: this.originalOrigin,
      contentType: 'application/pdf',
      cacheControl: `private, max-age=${maxCacheAge}, immutable`,
    }
  }

  createBookPages (fileHash, pageCount) {
    const book = this.entity.answerFor('book')
    this.state(`Creating Book Pages`)
    return [...Array(pageCount)]
      .map((_, i) => `${fileHash}/${i}`)
      .filter(pageID => !book.pages().find(bp => bp.pageID === pageID))
      .map(pageID => book.createBookPage(pageID))
  }
}
