/**
 * A basic observable <=> JSON class.
 */
import { Cloneable } from 'Editor'

export default abstract class Serializable implements Cloneable {
  constructor (params) {
    this.propertiesFromJS(params)
  }

  /**
   * Names of properties created with ko.observable
   */
  abstract get plainObservables (): string[]
  /**
   * Names of properties created with ko.observableArray
   */
  abstract get arrayObservables (): string[]
  /**
   * Properties created by the constructor, which are unwrapped.
   */
  abstract get propertiesToSerialize (): string[]

  propertiesFromJS (params) {
    Object.assign(this, {},
      ...this.plainObservables.map(s => ({ [s]: ko.observable(params[s]) })),
      ...this.arrayObservables.map(s => ({ [s]: ko.observableArray(params[s]) }))
    )
  }

  toJS () {
    const properties = [
      ...this.propertiesToSerialize,
      ...this.plainObservables,
      ...this.arrayObservables]
    return Object.assign({},
      ...properties.map(p => ({ [p]: ko.toJS(this[p]) || null })),
    )
  }

  /** for the Cloneable interface */
  clone (): this {
    const params = this.toJS()
    return new (this.constructor as { new(params): any })(params)
  }
}
