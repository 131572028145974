
import { flatten } from 'lodash-es'
import { format, fromUnixTime, parseISO } from 'date-fns/esm'

import ViewComponent from 'ViewComponent'
import { buttons, color, typography } from 'styles'
import { auditChangeProxy } from 'DataModel/crudAudit'

import { inline } from 'icons'
import refreshIcon from 'icons/light/sync'

/**
 * The Audit structure
 *
 * @typedef {object} Audit
 * @property {string} uid user id
 * @property {object} change
 * @property {JsonPatch} change.patch
 * @property {string} change.before sha256 of the data before
 * @property {string} change.after sha256 of the data before
 * @property {Array.<object>} signatures
 * @property {Timestamp} timestamp
 */

 /**
  * JSON Patch structure from RFC 6902
  * See: http://jsonpatch.com/
  *
  * @typedef {object} JsonPatch
  *
  * @property {string} op one of {add, copy, replace, remove, move, test}
  * @property {string} path of the change
  *
  * For `add`, `replace`, and `test` also:
  * @property {string} value to be added
  *
  * For `copy` and `move`, also:
  * @property {string} from
  */

const LOAD_SIZE = 15
const keyColor = color.color.light.blue
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

export default class ModelAudit extends ViewComponent {
  constructor ({ model }, ...args) {
    super({}, ...args)

    Object.assign(this, {
      modelFirestoreDoc: model.vmFirestoreDoc(),
      authManager: model.authManager,
      moreToLoad: ko.observable(true),
      nextQuery: null,
      auditRefreshDate: ko.observable(''),
      auditHtmlList: ko.observableArray([]),
      changeProxy: auditChangeProxy({ privateKey: false })
    })

    this.refresh()
  }

  async refresh () {
    const dated = format(new Date(), 'do LLLL yyyy hh:mm:ss a')
    this.auditRefreshDate(dated)
    this.auditHtmlList([])
    const initialQuery = this.modelFirestoreDoc
      .collection('audit')
      .orderBy('timestamp', 'desc')
      .limit(LOAD_SIZE)

    await this.loadAudits(initialQuery)
  }

  async loadAudits (nextQuery) {
    if (!nextQuery) { return }
    const snapshot = await nextQuery.get()
    const { docs } = snapshot
    this.auditHtmlList
      .push(...docs.map(d => this.htmlForAudit(d.data())))

    if (snapshot.size === LOAD_SIZE) {
      const lastDoc = docs[docs.length - 1]
      this.nextQuery = nextQuery.startAfter(lastDoc)
    } else {
      this.nextQuery = null
      this.moreToLoad(false)
    }
  }

  /**
   * @param {Audit} audit
   */
  async htmlForAudit (audit) {
    const { jss } = this
    const change = await this.changeProxy.unwrap(audit.change)
    const timestamp = parseISO(change.timestamp)
    const localTime = format(timestamp, 'h:mm:ss a')
    const tzTitle = `${localTime} ${tz}`
    const userEmail = this.modelFirestoreDoc.parent.parent
      .collection('user')
      .doc(change.uid)
      .get()
      .then(u => u.data().email)
    return (
      <div class={jss.audit}>
        <div class={jss.eventId}>{audit.eventId}</div>
        <div class={jss.timestamp}>
          {format(timestamp, 'do MMMM yyyy')}
          <div title={tzTitle}>
            {localTime}
          </div>
        </div>
        <div class={jss.user}>
          {userEmail}
        </div>
        <div class={jss.patch}>
          {this.htmlForPatch(change.patch)}
        </div>
      </div>
    )
  }

  static get auditCSS () {
    return {
      audit: {
        padding: '15px 5px',
        display: 'grid',
        width: '100%',
        minWidth: '420px',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.3)',
        background: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        marginBottom: '15px',
        borderRadius: 4,
        gridGap: '5px',
        gridTemplateColumns: 'auto 1fr auto auto',
        gridTemplateAreas: `
          ' ts  .   ei  et '
          ' ts  .   u   u  '
          ' p   p   p   p  '
        `
      },
      eventId: {
        gridArea: 'ei',
        fontSize: '12px',
        color: '#aaa'
      },
      timestamp: {
        gridArea: 'ts',
        fontSize: '14px',
        textAlign: 'right',
        fontFamily: typography.altFontFamily,
        fontWeight: 600,
        padding: 15,
      },
      user: {
        gridArea: 'u',
        padding: '2px 0.5rem',
        fontSize: '0.8rem',
        borderRadius: 8,
        marginTop: 9,
        backgroundColor: '#e9eef9',
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.secondary,
        },
        marginBottom: '38px',
        marginRight: '15px'
      },
      patch: {
        gridArea: 'p',
        width: '100%'
      }
    }
  }

  /**
   * @param {Array.<JsonPatch>} patch
   */
  htmlForPatch (patch) {
    return patch.map(change => this.htmlForChange(change))
  }

  /**
   * @param {JsonPatch} change
   */
  htmlForChange (change) {
    const {jss} = this

    return (
      <div class={jss.change}>
        <div class={jss[change.op]}>
          {change.op}
        </div>
        <div class={jss.path}>
          {change.path}
        </div>
        {this.changeValueOrFromHTML(change)}
      </div>
    )
  }

  changeValueOrFromHTML (change) {
    const {jss} = this
    switch (change.op) {
      case 'add':
      case 'replace':
      case 'test':
        return (
          <div class={jss.value}>
            {this.valueAsHTML(change.value)}
          </div>
        )

      case 'copy':
      case 'move':
        return <div class={jss.from}>{change.from}</div>

      case 'remove':
        return <div class={jss.remove}>{change.from}</div>

      default:
        throw new Error(`Unrecognized RFC 6902 op: ${change.op}`)
    }
  }

  static get operationCSS () {
    return {
      op: {
        gridArea: 'op',
        '&:hover': {
          borderColor: 'black',
          boxShadow: '0 0 3px 0 rgba(0,0,0,0.7)'
        }
      },
      add: {
        extend: 'op',
        color: 'green',
        border: '1px solid #e8e8e8',
        padding: '3px 5px 1px 5px',
        fontSize: '0.8em',
        fontFamily: typography.mono,
        whiteSpace: 'nowrap',
        borderColor: 'green',
        marginRight: 4,
        borderRadius: 4,
      },
      copy: {
        extend: 'op',
        color: 'emerald',
        border: '1px solid #e8e8e8',
        padding: '3px 5px 1px 5px',
        fontSize: '0.8em',
        fontFamily: typography.mono,
        whiteSpace: 'nowrap',
        borderColor: 'emerald',
        marginRight: 4,
        borderRadius: 4,
      },
      replace: {
        extend: 'op',
        color: 'purple',
        border: '1px solid #e8e8e8',
        padding: '3px 5px 1px 5px',
        fontSize: '0.8em',
        fontFamily: typography.mono,
        whiteSpace: 'nowrap',
        borderColor: 'purple',
        marginRight: 4,
        borderRadius: 4,
      },
      remove: {
        extend: 'op',
        color: color.color.light.red,
        border: '1px solid #e8e8e8',
        padding: '3px 5px 1px 5px',
        fontSize: '0.8em',
        fontFamily: typography.mono,
        whiteSpace: 'nowrap',
        borderColor: color.color.light.red,
        'body[dark] &': { // project batman
          borderColor: color.color.dark.red,
          color: color.color.dark.red,
        },
        marginRight: 4,
        borderRadius: 4,

      },
      move: {
        extend: 'op',
        color: 'blue',
        border: '1px solid #e8e8e8',
        padding: '3px 5px 1px 5px',
        fontSize: '0.8em',
        fontFamily: typography.mono,
        whiteSpace: 'nowrap',
        borderColor: 'blue',
        marginRight: 4,
        borderRadius: 4,
      },
      test: {
        extend: 'op',
        color: 'orange',
        border: '1px solid #e8e8e8',
        padding: '3px 5px 1px 5px',
        fontSize: '0.8em',
        fontFamily: typography.mono,
        whiteSpace: 'nowrap',
        borderColor: 'orange',
        marginRight: 4,
        borderRadius: 4,
      }
    }
  }

  static get patchCSS () {
    return {
      ...this.operationCSS,
      ...this.valueCSS,

      change: {
        display: 'grid',
        fontSize: '14px',
        paddingTop: '10px',
        marginLeft: '2em',
        gridTemplateAreas: `
          ' op  path  '
          ' .   value '
        `,
        gridTemplateColumns: 'auto 1fr',
        gridGap: '2px 10px',
        borderTop: '1px solid #ccc'
      },

      path: {
        gridArea: 'path',
        fontWeight: 'bold',
        color: keyColor,
        borderRadius: 4,
        backgroundColor: '#ececec',
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
        padding: '2px 17px',
        width: 'fit-content'
      },

      valueOrFrom: {
        gridArea: 'value'
      },

      value: {
        extend: 'valueOrFrom',
        marginBottom: '10px',
        border: '1px solid #ececec',
        borderRadius: 4,
        marginTop: 10,
        backgroundColor: 'rgba(0,0,0,0.0)',
        padding: 10,
        wordBreak: 'break-all',
        width: 'fit-content',
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.1)',
        '&:hover': {
          boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)',
        },
      },

      from: {
        extend: 'valueOrFrom'
      }
    }
  }

  /**
   * @param {any} value from JSON
   */
  valueAsHTML (value) {
    const {jss} = this
    if (Array.isArray(value)) {
      const items = value.map(v =>
        <div class={jss.listItem}>{this.valueAsHTML(v)}</div>
      )
      return (
        <div class={jss.list}>{items}</div>
      )
    }

    if (typeof value === 'object' && value !== null) {
      const parts = flatten(Object.entries(value)
        .map(([key, v]) => [
          <div class={jss.key}>{key}</div>,
          <div class={jss.objectValue}>{this.valueAsHTML(v)}</div>
        ]))
      return (
        <div class={jss.object}>{parts}</div>
      )
    }

    return String(value)
  }

  static get valueCSS () {
    return {
      borderedValue: {
        borderBottom: '1px solid #aaa',
        '&:last-child': {
          borderBottom: 'none'
        }
      },

      list: {
        borderLeft: '1px solid #aaa'
      },

      listItem: {
        extend: 'borderedValue',
        padding: '0px 5px'
      },

      object: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr'
      },

      key: {
        extend: 'borderedValue',
        color: keyColor,
        'body[dark] &': { // project batman
          color: color.color.dark.blue,
        },
        fontWeight: '600',
        textAlign: 'right',
        padding: '0px 5px',
        '&:nth-last-child(2)': {
          borderBottom: 'none'
        }
      },

      objectValue: {
        extend: 'borderedValue',
        padding: '0px 5px'
      }
    }
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.container}>
        <div class={jss.intro}>
          <div class={jss.auditStart}>
            Audit as of {this.auditRefreshDate} {tz}
            <async-button
              faceClass={jss.refreshButton}
              action={() => this.refresh()}>
              <template slot='face'>
                {inline(refreshIcon)}
                {' '} Refresh
              </template>
            </async-button>
          </div>
        </div>
        {this.auditHtmlList}
        <div
          class={jss.loadMoreButton}
          ko-click={() => this.loadAudits(this.nextQuery)}
          ko-visible={this.moreToLoad}>
          Load More
        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.patchCSS,
      ...this.auditCSS,

      container: {
        padding: '30px'
      },

      intro: {
        textAlign: 'center',
        fontSize: '1.2em',
        fontWeight: 600,
        fontFamily: typography.altFontFamily,
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.3)',
        backgroundColor: '#FFD504',
        marginBottom: 20,
        borderRadius: 4,
      },

      auditStart: {
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
        },
      },

      refreshButton: {
        ...buttons.ripple,
        ...buttons.bordered,
        marginLeft: '20px',
        fontSize: '14px',
        marginTop: '10px'
      },

      loadMoreButton: {
        ...buttons.ripple,
        ...buttons.main,
        display: 'block',
        'body[dark] &': { // project batman
          boxShadow: 'unset'
        },
      }
    }
  }
}

ModelAudit.register()
