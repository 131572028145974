import { typography, input, color } from 'styles'

import EisPrimitiveComponent from './EisPrimitiveComponent'
import { ModelPropertyPicker } from 'picker';

export default class EisInput extends EisPrimitiveComponent {
  picker: ModelPropertyPicker | null

  constructor (params) {
    super(params)
    Object.assign(this, { picker: params.picker })
  }

  static get css () {
    return {
      ...super.css,
      displayClass: {
        ...super.css.textContent,
        '&:empty': { display: 'none' }
      },
      inputClass: {
        ...input.all,
        ...typography.face.text,
        fontSize: '1.5rem',
        borderBottom: '1px solid #666666',
        color: '#333333',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: color.color.light.brandYellow,
        'body[dark] &': { // project batman
          backgroundColor: color.color.dark.brandYellow,
        },
        width: '100%',
      }
    }
  }

  get displayHTML () {
    const focused = this.computed(() => this.focused() || undefined)
    return (
      <div class={this.jss.displayClass} focused={focused}
        blank={this.computed(() => this.componentValue() ? undefined : 'true')}>
        {this.displayValue}
      </div>
    )
  }

  get pickerHTML () {
    if (!this.picker) { return }
    const params = Object.assign({
      my: 'top left',
      at: 'bottom left',
      indexName: 'entity',
      value: this.inputValue,
      onSelect: this.inputValue,
      inputClass: this.jss.inputClass,
      resetQueryOnSelect: false,
    }, this.picker)
    return new ModelPropertyPicker(params).template
  }

  get editHTML () {
    return (
      this.pickerHTML || <input type='text'
        class={this.jss.inputClass}
        ko-textinput={this.inputValue}
        ko-event={{ keydown: this.inputKeyDown() }}
        ko-hasFocus={this.computed(() => this.editing())} />
    )
  }
}

EisInput.register()
