import ViewComponent from 'ViewComponent'

import icons from 'icons'

export default class ProgressNotification extends ViewComponent {
  constructor ({ progress, manager }) {
    super()
    Object.assign(this, { progress, manager })
    progress.percent.when(p => p >= 1).then(() => this.hide())
  }

  hide () {
    this.manager.hideProgress(this.progress)
  }

  static get css () {
    return {
      progress: {
        display: 'grid',
        gap: '2px',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        backgroundColor: 'rgba(247, 250, 247, 0.95)',
        border: '1px solid #8dff8d',
        marginBottom: 8,
        boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.1)',
        borderRadius: '6px',
        'body[dark] &': { // project batman
          '--icon-color': 'black',
          backgroundColor: 'rgba(44, 44, 44, 0.95)',
          border: '1px solid #008a00',
        },
      },
      icon: {
        '--icon-color': '#7ed321'
      },
      message: {

      },
      actions: {

      },
      progressBar: {
        gridColumn: '1/-1',
        width: '100%',
        '&::-webkit-progress-value': {
          transition: 'width 0.5s ease'
        }
      }
    }
  }

  get template () {
    const { jss, progress } = this
    const iconHTML = progress.icon
      ? icons.inline(progress.icon) : undefined

    return (
      <div class={jss.progress}>
        <div class={jss.icon}> {iconHTML} </div>
        <div class={jss.message}> {progress.message} </div>
        <div class={jss.actions}> {progress.actions} </div>
        <progress class={jss.progressBar} value={progress.percent} />
      </div>
    )
  }
}

ProgressNotification.register()
