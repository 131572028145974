import { BusinessNumber } from 'EntityModel/components'

import { BUSINESS_NUMBER_NAMES } from '../constants'

import EisArrayComponentTable from './EisArrayComponentTable'


class EisBusinessNumbers extends EisArrayComponentTable<BusinessNumber> {
  get caption () { return 'Business Numbers' }
  get componentName () { return 'business_numbers' }
  get listAddName () { return 'Business Number' }
  get columns () { return ['name', 'number'] }
  get nameModelPickerChoices () { return BUSINESS_NUMBER_NAMES }
}

EisBusinessNumbers.register()
