/**
 * This is a generic encapsulation mechanism for objects.  It can be
 * used with e.g.
 *
 * 1. crypto
 * 2. compression
 * 3. redirection to another firebase entity
 *
 */

/**
 * The ObjectProxy is a base class for performing these transforms.
 *
 * This is an asymmetrical class in the sense that instances are used
 * to `wrap` while static methods are used to unwrap.
 */
export default class ObjectProxy {
  static get proxyPropertyName () { return this.name.replace('Proxy', '') }
  get proxyPropertyName () { return this.constructor.proxyPropertyName }

  // We might add additional log here or these or stub these for testing.
  async wrap (...args) { return this.wrapValue(...args) }
  async unwrap (...args) { return this.constructor.unwrap(...args) }
  static async unwrap (...args) { return this.unwrapValue(...args) }

  // These are to be overwritten by subclasses.
  async wrapValue (value) { return value }
  static async unwrapValue (/* value */) { throw new Error(`Overload`) }
}
