const _head = Symbol('head')
const _tail = Symbol('tail')

export default class LinkedList {
  constructor (params = {}) {
    const { looped } = params
    Object.assign(this, {
      [_head]: null,
      [_tail]: null,
    })
    Object.defineProperty(this, 'looped', { get: () => looped })
  }

  get head () {
    return this[_head]
  }

  get tail () {
    return this[_tail]
  }

  addProperties (item) { // TODO consider making these properties private symbols to avoid conflicts
    const configurable = true
    let [ nextRef, previousRef, linkedlist ] = [ null, null, this ]
    Object.defineProperties(item, {
      next: {
        get: () => nextRef,
        set: v => nextRef = v,
        configurable,
      },
      previous: {
        get: () => previousRef,
        set: v => previousRef = v,
        configurable,
      },
      linkedlist: {
        get: () => linkedlist,
        set: v => linkedlist = v,
        configurable,
      }
    })
  }

  remove (item) {
    if (item.linkedlist !== this) {
      throw new Error('item is not a member of list')
    }
    if (this.head === item) {
      this[_head] = item.next || item.previous || null
    }
    if (this.tail === item) {
      this[_tail] = item.previous || item.next || null
    }
    if (item.previous) { item.previous.next = item.next }
    if (item.next) { item.next.previous = item.previous }
    delete item.next
    delete item.previous
    delete item.linkedlist
    return this
  }

  initItem (item) {
    if (item.linkedlist) {
      item.linkedlist.remove(item)
    }
    this.addProperties(item)
  }

  append (item) {
    if (this.tail) { return this.insertAfter (this.tail, item) }
    this.initItem(item)
    this[_head] = this[_tail] = item
    if (this.looped) {
      item.next = item.previous = item
    } else {
      item.next = item.previous = null
    }
    return this
  }

  insertAt (index, newItem) {
    let i=0
    for (let item of this) {
      if (i++ < index) { continue }
      return this.insertBefore(item, newItem)
    }
    return this.append(newItem)
  }

  insertAfter (node, item) {
    if (node.linkedlist !== this) {
      throw new Error('node is not a member of list')
    }
    this.initItem(item)
    if (node.next) { node.next.previous = item }
    item.next = node.next
    node.next = item
    item.previous = node
    if (this.tail === node) { this[_tail] = item }
    return this
  }

  insertBefore (node, item) {
    if (node.linkedlist !== this) {
      throw new Error('node is not a member of list')
    }
    this.initItem(item)
    if (node.previous) { node.previous.next = item }
    item.previous = node.previous
    node.previous = item
    item.next = node
    if (this.head === node) { this[_head] = item }
    return this
  }

  *[Symbol.iterator] () {
    if (!this.head) { return }
    let item = this.head
    yield item
    item = item.next
    while (item && item !== this.head) {
      yield item
      item = item.next
    }
  }
}
