/**
 * @param {string} panelID
 * @return {string} collectionID
 */
export default function panelToCollectionID (panelID) {
  switch (panelID) {
    case 'Calendar':
    case 'EntityList':
      return 'entity'

    case 'UserList': return 'user'
    case 'PersonList': return 'person'
    case 'PrecedentList': return 'precedent'
    case 'EntityWritingList': return 'writing'
  }
  throw new Error(`panelToCollectionID not known for "${panelID}"`)
}
