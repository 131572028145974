
interface CloseableOverlay {
  showing: KnockoutObservable<boolean>
}

/**
 * Add global events for hiding tooltips
 */
const POPOVERS = new Set<CloseableOverlay>()
export const scrollPosition = ko.observable().extend({ rateLimit: 20 })

export function add (c: CloseableOverlay) { POPOVERS.add(c) }
export function remove (c: CloseableOverlay) { POPOVERS.delete(c) }

export function hidePopovers (except = new Set<CloseableOverlay>()) {
  for (const po of POPOVERS) {
    if (except.has(po)) { continue }
    po.showing(false)
  }
}

export function aPopoverIsShowing () : boolean {
  return [...POPOVERS].some(p => p.showing())
}

function closeOnClick (evt) {
  const { target } = evt

  // Add a global handler to close a drop-down when it has a `[close-on-click]`
  // property
  if (target.hasAttribute('close-on-click')) {
    const $data = ko.dataFor(target)
    if ($data.showing) { $data.showing(false) }
  }

  if (target.closest('[popover-anchor-node]')) { return true }
  hidePopovers()
  return true
}
/**
 * Close popovers on any click outside a popover
 */
document.body.addEventListener('click', closeOnClick)
document.body.addEventListener('contextmenu', closeOnClick)


/**
 * Monitor the scroll position so we can move our pop-overs around accordingly
 */
function updateScrollPosition () {
  const { scrollTop, scrollLeft } = document.documentElement
  scrollPosition({ scrollTop, scrollLeft })
}

window.addEventListener('scroll', updateScrollPosition)
updateScrollPosition()
