
import color from './color'
import input from './input'
import typography from './typography'

const formEdge = {
  borderRadius: '5px',
  boxShadow: '0 1px 3px 0 rgba(0,0,0,0.50)',
  minHeight: '100%',
  minWidth: '100%'
}

export const tripleLayer = {
  form: {
    ...formEdge,
    display: 'grid',
    gap: '17px 50px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '43px',
    zIndex: 3,
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.primary,
    },
  },

  formU1: {
    ...formEdge,
    backgroundColor: color.brandYellow,
    transform: 'rotate(-3deg) translateX(2px) translateY(5px)',
    zIndex: 2,
  },

  formU2: {
    ...formEdge,
    backgroundColor: 'white',
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.primary,
    },
    transform: 'rotate(1deg) translateX(0px) translateY(13px)',
    zIndex: 1,
  }
}

const textInput = {
  ...input.all,
  backgroundColor: 'white',
  'body[dark] &': { // project batman
    backgroundColor: color.textInput.dark.primary,
    border: `1px solid ${color.separator.dark.nonOpaque}`,
    color: color.text.dark.primary
  },
  boxShadow: '0 1px 3px 0 rgba(0,0,0,0.5)',
  border: 0,
  minHeight: '40px',
  padding: '10px 5px 10px 20px',
  borderRadius: '5px',
  fontSize: '1rem',
  fontFamily: typography.altFontFamily,
  '&::placeholder': {}
}

const yellowBar = {
  width: '128px',
  height: '5px',
  borderRadius: '8px',
  backgroundColor: color.brandYellow
}

export default { tripleLayer, yellowBar, textInput }
