import CurrencyPickerBase from './CurrencyPickerBase'

import { tCurrency, currencyAttr } from './currency'

/**
 * The `<currency-picker>` mimics the `jurisdiction-picker` in many ways.
 * Look to that for further details.
 */
export default class CurrencyPicker extends CurrencyPickerBase {
  constructor (params) {
    const valueToUpdate = params.value
    const value = ko.observable(currencyAttr(valueToUpdate(), 'name'))
    const onSelect = (v: tCurrency | string) => {
      if (typeof v === 'string') {
        valueToUpdate(v)
      } else if (v) {
        this.query(v.name)
        valueToUpdate(v.code)
      }
    }
    super({ ...params, value, onSelect, maxMatches: 7 })
  }

  get anchorHTML () {
    const { jss } = this
    return (
      <div class={jss.inputAndCurrency}>
        {super.anchorHTML}
        {this.computed(() => currencyAttr(this.query(), 'code'))}
      </div>
    )
  }
}

CurrencyPicker.register()
