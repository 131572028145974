/**
 * Import and export all of the models in the app, in a map.
 */

import EntityModel from 'EntityModel'
import UserModel from 'UserModel'
import FilterModel from 'FilterModel'
import PrecedentModel from 'writing/precedents/PrecedentModel'

export function getClassByResourceName (name) {
  switch (name) {
    case 'entity': return EntityModel
    case 'user': return UserModel
    case 'filter': return FilterModel
    case 'precedent': return PrecedentModel
    default:
      throw new Error(`Cannot find class named ${name}`)
  }
}

export default {
  EntityModel,
  UserModel,
}
