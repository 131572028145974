
import ListEditor from 'list-editor'

import { tag, tagColor, color } from 'styles'

/**
 * Edit tags
 *
 * Usage:
 *
 *  <tags-editor
 *    tags={list}
 *    index='model-type'
 *  />
 */
class TagsEditor extends ListEditor {
  constructor ({ tags, index, inputClass }, ...args) {
    super({ list: tags }, ...args)
    Object.assign(this, { index, inputClass })
  }

  static get css () {
    return {
      ...super.css,
      item: {
        ...super.css.item,
        ...tag
      }
    }
  }

  itemHTML (t) {
    const { jss } = this
    return <span class={jss.item} style={`color: ${tagColor(t)}`}>{t}</span>
  }

  get inputHTML () {
    return (
      <tag-picker
        my='top left'
        at='top right'
        filter={tag => !this.list().includes(tag)}
        indexName={this.index}
        inputClass={this.inputClass}
        onSelect={tag => this.list().includes(tag) || this.list.push(tag)}
      />
    )
  }

  get template () {
    const { jss } = this
    return <div class={jss.list}> {this.inputHTML} {this.listHTML} </div>
  }

  get placeholderMessage () { return 'Add new tag' }
  get noItemsMessage () { return 'There are currently no tags' }
}

class TagsEditorModal extends TagsEditor {
  get inputHTML () {
    return (
      <tag-picker-modal
        my='top left'
        at='bottom left'
        filter={tag => !this.list().includes(tag)}
        indexName={this.index}
        inputClass={this.inputClass}
        onSelect={tag => this.list().includes(tag) || this.list.push(tag)}
      />
    )
  }

  static get css () {
    return {
      ...super.css,
      list: {
        ...super.css.list,
        padding: 0,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.darkElevated.primary,
        },
      }
    }
  }
}

TagsEditor.register()
TagsEditorModal.register()
