
import IndentableBodyBlockEditor from './IndentableBodyBlockEditor'
import Quote from '../blocks/Quote'

export default class BlockQuoteEditor extends IndentableBodyBlockEditor<Quote> {
  static get css () {
    return {
      ...super.css,
      input: {
        ...super.css.input,
        margin: '0px 10px',
        padding: '0px 10px',
        marginLeft: '0.25em',
        marginBottom: '2em'
      },
      quoteBar: {
        borderLeft: '2px solid black',
        minWidth: '5px',
      },
      bar0: { borderColor: 'orange', },
      bar1: { borderColor: 'red', },
      bar2: { borderColor: 'pink', },
      bar3: { borderColor: 'powderblue', },
      bar4: { borderColor: 'rosybrown', },
    }
  }

  get quoteBars () {
    const { jss } = this
    return this.computed(() => {
      const bars = []
      for (let i = 0; i <= this.block.depth(); ++i) {
        const classes = [jss.quoteBar, jss[`bar${i}`]].join(' ')
        bars.push(<div class={classes} />)
      }
      return bars
    })
  }

  get internalHTML () {
    return (
      <>
        {this.computed(() => this.quoteBars).extend({ deferred: true })}
        {super.internalHTML}
      </>
    )
  }

}

BlockQuoteEditor.register()
