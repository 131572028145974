import color from './color'
import typography from './typography'

const clickable = {
  cursor: 'pointer',
  outline: 'none',
  userSelect: 'none',
  textDecoration: 'unset',
}

const grippable = {
  ...clickable,
  cursor: 'grab'
}

const button = {
  ...clickable,
  display: 'inline-block',
  margin: '5px',
  border: 'none',
  borderRadius: '2px',
  padding: '12px 18px',
  fontSize: '16px',
  textAlign: 'center',

  '&:focus': {
    boxShadow: '0px 0px 4px orange',
    filter: 'brightness(0.8)',
  },

  '& i': {
    padding: '0px 6px'
  }
}

const formAction = {
  ...clickable,
  color: 'white',
  backgroundColor: '#4a90e2',
  boxShadow: '0 1px 3px 0 rgba(0,0,0,0.5)',
  textAlign: 'center',
  border: 0,
  minHeight: '40px',
  // padding: '10px 5px 10px 20px',
  padding: '10px',
  borderRadius: '5px',
  fontSize: '1rem',
  fontWeight: 'bold',
  fontFamily: typography.altFontFamily,
}

/**
 * Plain buttons
 */
const clean = {
  ...button,
  color: 'black',
  //letterSpacing: '0.05em',
   'body[dark] &': { // project batman
    color: color.text.dark.primary,
  },
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: color.primaryButtonHoverBg,
    'body[dark] &': { // project batman -- add new entity button is using the below style
      backgroundColor: color.fill.dark.primary,
      color: color.text.dark.primary,
    },
  }
}

/**
 * modalOk & modalCancel are re-used across e.g. sharing, add note, and
 * may be used generically elsewhere as the two primary actions in a modal.
 */
const modalOk = {
  ...clean,
  minWidth: '90px',
  fontFamily: typography.altFontFamily,
  color: 'white',
  backgroundColor: color.onPrimary,
  boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0px 0px 0px rgba(0, 0, 0, 0)',
  '&:hover': {
    backgroundColor: '#ffd502',
    color: color.grey.brand,
    'body[dark] &': { // project batman
      color: color.grey.brand,
    },
  }
}

const modalCancel = {
  ...clean,
  // border: `1px solid ${color.onPrimary}`,
  backgroundColor: color.button.light.modalCancel,
  color: color.button.light.modalText,
  'body[dark] &': { // project batman
    backgroundColor: color.button.dark.modalCancel,
    color: color.button.dark.modalText,
  },
  fontFamily: typography.altFontFamily,
  boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0px 0px 0px rgba(0, 0, 0, 0)',
  '&:hover': {
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 2px #ffd502',
    color: color.grey.brand
  }
}

export default {
  clickable,
  grippable,
  formAction,
  clean,

  modalOk,
  modalCancel,

  ripple: {
    backgroundPosition: 'center',
    transition: 'background 0s',
    '--ripple-hover-color': color.hoverColor,
    '&:hover': {
      background: 'var(--ripple-hover-color) radial-gradient(circle, transparent 0%, var(--ripple-hover-color) 1%) center/15000%',
      'body[dark] &': { // project batman elv toc hover color
        background: color.fill.dark.secondary,
      },
    },
    '&:active': {
      backgroundColor: color.activeColor,
      backgroundSize: '100%',
      transition: 'background 0s'
    }
  },

  /**
   * Main buttons are blue on white.
   */
  main: {
    ...button,
    color: 'white',
    backgroundColor: '#2196f3',
    boxShadow: '0 0 4px #999',
  },

  bordered: {
    ...button,
    border: `1px solid ` + color.actionColor,
    borderRadius: '4px'
  }
}
