import DisplayListBaseComponent from 'DisplayListBaseComponent'

import searchIcon from 'icons/light/search'
import { color } from 'styles'

class SearchResultsList extends DisplayListBaseComponent {
  get svgIcon () { return searchIcon }

  constructor (params) {
    super(params)
    const { searchData } = params
    Object.assign(this, {
      searchData,
      results: searchData.results,
    })
  }

  get noItemsHTML () {
    const { jss } = this
    return (
      <div class={jss.noItem}>
        There are no results.
      </div>
    )
  }

  get template () {
    return this.computed(() => {
      if (!this.results().length) {
        return (
          this.noItemsMessageIconHTML
        )
      } else {
        const { jss } = this
        return (
          <div class={jss.scrollcontainer}>
            {this.results().map((bsr, index) => this.resultHTML(bsr, index))}
          </div>
        )
      }
    }).extend({ deferred: true })
  }

  static get css () {
    return {
      ...super.css,
      details: {
        ...super.css.details,
        '&[focused]': {
          backgroundColor: 'var(--hover-color)',
        }
      },
      scrollcontainer: {
        overflowY: 'auto',
        height: 'calc(100vh - 220px)',
      },
      messageText: {
        ...super.css.messageText,
        '& mark': {
          backgroundColor: color.brandYellow,
          borderRadius: '1px',
        }
      },
    }
  }

  onClick (bookSearchResult, index) {
    this.searchData.resultFocus(index+1)
    bookSearchResult.scrollIntoView()
  }

  resultHTML (bookSearchResult, index) {
    const { jss }  = this

    let leaveTimer = null
    const hover = this.computed(() => ({
      mousemove: () => {
        clearTimeout(leaveTimer)
        this.searchData.pageHoverFocus(bookSearchResult.pageIndex)
      },
      mouseleave: () => {
        leaveTimer = setTimeout(() => this.searchData.pageHoverFocus(null), 50)
      }
    }))

    const isInView = ko.observable()

    const pageNumber = bookSearchResult.pageIndex + 1
    return (
      <div class={jss.details}
        ko-intersection={{
          value: isInView, root: `.${jss.scrollcontainer}`
        }}
        focused={this.computed(() => bookSearchResult.focused() || undefined )}
        ko-click={() => this.onClick(bookSearchResult, index)}
        ko-event={hover}>
        <div class={jss.pageNumberSpace}>
          { pageNumber > 0 ? ( <div class={jss.pageNumber}>{'Pg'}<br/>{`${pageNumber}`}</div> ) : '' }
        </div>
        <div>
          <div class={jss.messageText}
            ko-scrollTo={ this.computed(() => bookSearchResult.focused() && !isInView.peek()) }
            ko-html={ bookSearchResult.resultContext } />
        </div>
      </div>
    )
  }
}

SearchResultsList.register()
