/**
 * Convert a string to `Uint8Proxy`
 */
import ObjectProxy from './ObjectProxy'

const encoder = new TextEncoder()
const decoder = new TextDecoder('utf-8')

export default class Uint8Proxy extends ObjectProxy {
  static get proxyPropertyName () { return 'U8' }
  wrapValue (string) { return encoder.encode(string) }
  static unwrapValue (buffer) { return decoder.decode(buffer) }
}
