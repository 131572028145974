
import { Incorporator } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisIncorporators extends EisArrayComponentTable<Incorporator> {
  get caption () { return 'Incorporators' }
  get componentName () { return 'incorporators' }
  get listAddName () { return 'Incorporator' }
  get columns () { return ['person'] }
}

EisIncorporators.register()
