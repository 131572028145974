
import ViewComponent from 'ViewComponent'
import { typography, color } from 'styles'

export default class CapitalAssetPrefix extends ViewComponent {
  prefix: string

  constructor ({ prefix }) {
    super()
    Object.assign(this, { prefix })
  }

  static get css () {
    return {
      ...super.css,
      prefix: {
        fontWeight: 'bold',
        color: color.color.light.blue,
        'body[dark] &': { // project batman
          color: color.color.dark.blue,
          backgroundColor: color.fill.dark.primary,
        },
        fontFamily: typography.mono,
        width: '50px',
        paddingTop: '2px', // Courier Prime does not vertical-center properly
        backgroundColor: 'rgba(0,0,255,0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        WebkitClipPath: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)',
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)',
        '&:empty': { visibility: 'hidden' },
      },
    }
  }

  get template () {
    return (<span class={this.jss.prefix}>{this.prefix}</span>)
  }
}

CapitalAssetPrefix.register()
