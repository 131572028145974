
export class Process {
  taskCount: KnockoutObservable<number> = ko.observable(1)
  tasksCompleted: KnockoutObservable<number> = ko.observable(0)
  isComplete: KnockoutObservable<boolean> = ko.observable(false)
  isCancelled: KnockoutObservable<boolean> = ko.observable(false)
  isFinished: KnockoutComputed<boolean>
  percent: KnockoutComputed<number>

  constructor (taskCount = 0) {
    this.taskCount(taskCount)
    this.isFinished = ko.pureComputed(
      () => this.isComplete() || this.isCancelled())
    this.percent = ko.pureComputed(() =>
      this.taskCount() && this.tasksCompleted() / this.taskCount())
  }

  incrementTaskCount (count: number) {
    this.taskCount.modify(t => t + count)
  }

  /**
   * Mark a task as completed.
   */
  tick () {
    this.tasksCompleted.modify(t => ++t)
  }

  cancel () {
    this.isCancelled(true)
    this.taskCount(this.tasksCompleted())
  }

  finish () {
    this.isComplete(true)
  }
}

export default Process
