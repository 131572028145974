
const MODIFIERS = [
  'ctrl', 'alt', 'shift', 'meta',
]

/**
 * A single shortcut.
 * @param {object} param0
 * @param {string} title
 * @param {string} help
 * @param {bool|function} own if truthy then runs preventDefault/stopPropagation
 * @param {function} action function called with event
 * @param {string} keyComboString
 */
class Shortcut {
  constructor ({ title, help, filter, action, keyComboString, own }) {
    const ownFn = typeof own === 'function' ? own : () => own
    filter = filter || (() => (true))
    Object.assign(this, { title, help, filter, action, keyComboString, ownFn })
  }

  trigger (evt) {
    this.action(evt)
  }

  /**
   * We may overload these in future.
   */
  get preventDefault () { return this.ownFn() }
  get stopPropagation () { return this.ownFn() }
}

/**
 * KeyboardShortcutManager manages a set of keyboard shortcuts.
 *
 * Only handles keyDown right now, but more are possible.
 */
export default class KeyboardShortcutManager {
  constructor () {
    this.keyDownShortcuts = {}
  }

  /**
   * @param {string} key
   * @param {object} modifiers
   * @return {string} e.g. ctrl+k
   */
  comboAsStringIdentifier (key, modifiers = {}) {
    return [key, ...MODIFIERS.filter(m => modifiers[m])]
      .join('+')
      .toLowerCase()
  }

  comboStringForEvent (evt) {
    return [event.key, ...MODIFIERS.filter(m => evt[`${m}Key`])]
      .join('+')
      .toLowerCase()
  }

  /**
   * @param {object} param0
   * @param {string} param0.key
   * @param {string} param0.title
   * @param {string} param0.help
   * @param {function} param0.action
   * @param {object} param0.modifiers { ctrl, alt, meta, shift }
   */
  add ({ key, title, help, filter, action, modifiers, propagate, performDefault }) {
    const keyComboString = this.comboAsStringIdentifier(key, modifiers)
    const params = {
      keyComboString, title, help, filter, action, propagate, performDefault,
    }
    const shortcut = new Shortcut(params)
    if (!(keyComboString in this.keyDownShortcuts)) {
      this.keyDownShortcuts[keyComboString] = [shortcut]
    } else {
      this.keyDownShortcuts[keyComboString].push(shortcut)
    }
    return shortcut
  }

  remove (shortcut) {
    if (!(shortcut.keyComboString in this.keyDownShortcuts)) { return false }
    const shortcuts = this.keyDownShortcuts[shortcut.keyComboString]
    if (!shortcuts.includes(shortcut)) { return false }
    if (shortcuts.length === 1) {
      delete this.keyDownShortcuts[shortcut.keyComboString]
    } else {
      this.keyDownShortcuts[shortcut.keyComboString] = shortcuts.filter(s => s !== shortcut)
    }
    return true
  }

  /**
   * @param {KeyboardEvent} evt
   */
  onKeydown (evt) {
    const keyComboString = this.comboStringForEvent(evt)
    if (!(keyComboString in this.keyDownShortcuts)) { return }
    for (const shortcut of this.keyDownShortcuts[keyComboString].filter(s => s.filter(evt))) {
      if (global.debugKeyboardShortcuts) {
        console.info(`Key: ${keyComboString} Shortcut:`, shortcut)
      }
      if (!shortcut.performDefault) { evt.preventDefault() }
      if (!shortcut.propagate) { evt.stopPropagation() }
      shortcut.trigger(evt)
    }
  }
}
