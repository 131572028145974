
/**
 * UndoManager manages the undoStack, allowing users to undo and redo actions
 * within the MainViews.
 *
 * It monitors for changes in the dataModel and places a diff of the dataModel
 * state into the undoStack.
 */

import { compare, applyPatch } from 'fast-json-patch'
type Patch = import('fast-json-patch').Operation[]

import 'DataModel'
import { cloneDeep } from 'lodash-es'
import { DataModel } from 'DataModel'

const { ko } = window
const THROTTLE = { rateLimit: 200 }
const MAX_STACK_LENGTH = 100

/**
 * @class UndoManager allows undoing/redoing of actions.
 */

export default class UndoManager {
  private _monitor: any = null
  private _savingMutex = false
  private savedRepr: any = null
  private undoStack: KnockoutObservableArray<Patch> = ko.observableArray([])
  private redoStack: KnockoutObservableArray<Patch> = ko.observableArray([])
  private applyQueue = ko.observableArray<() => Promise<any>>([])

  constructor (
      private dataModel: DataModel,
      public isActive = ko.observable<boolean>(true)) {

    ko.computed(() => {
      if (isActive() && this.dataModel) {
        // When active is toggled on it means the user switched
        // views away and back so we update the saved repr.
        this.savedRepr = cloneDeep(this.dataModel.vmSavedRepr())
        if (!this._monitor) { this.startMonitoring() }
      }
    })

    this.applyQueue.subscribe(async queue => {
      while (queue.length) {
        await queue.shift()()
      }
    })
  }

  async applyChange (stack: KnockoutObservableArray<Patch>, redoStack: KnockoutObservableArray<Patch>) {
    if (!stack.length) { return }
    try {
      const currentRepr = cloneDeep(this.dataModel.vmCurrentRepr())
      const patch = stack.shift() // pop
      const newRepr = applyPatch(cloneDeep(currentRepr), patch).newDocument
      this._savingMutex = true
      await this.dataModel.vmAssignValues(cloneDeep(newRepr))
      await this.dataModel.vmSave()
      this._savingMutex = false
      this.savedRepr = cloneDeep(this.dataModel.vmSavedRepr())
      const redoPatch = compare(newRepr, currentRepr)
      if (redoPatch.length) {
        redoStack.unshift(redoPatch) // push
        const len = Math.min(redoStack.length, MAX_STACK_LENGTH)
        redoStack.splice(len, redoStack.length - len)
      }
    } catch (err) {
      // Possible problem applying patch. Reset our state.
      // FIXME: Notify user of an error
      this.undoStack([])
      this.redoStack([])
      this._savingMutex = false
      this.savedRepr = cloneDeep(this.dataModel.vmSavedRepr())
    }
  }

  async undo () { this.applyQueue.push(() => this.applyChange(this.undoStack, this.redoStack)) }
  async redo () { this.applyQueue.push(() => this.applyChange(this.redoStack, this.undoStack)) }

  get undoAvailable () { return this.undoStack.length > 0 }
  get redoAvailable () { return this.redoStack.length > 0 }

  /**
   * Tracks the changes in the dataModel with debounce
   * @param {*} throttle        - The time (ms) in which the dataModel is checked for any changes
   */
  startMonitoring (throttle: { rateLimit: number } = THROTTLE) {
    if (this._monitor) { this._monitor.dispose() }
    this._monitor = ko.computed(() => this.dataModel.vmCurrentRepr()).extend(throttle)
    this._monitor.subscribe(r => r && this.modelChanged(r))
  }

  /**
   * When the model changes push the patch on the stack and save
   * FIXME: Need to detect when the change was pushed from the server
   */
  async modelChanged (currentRepr) {
    if (this._savingMutex || this.dataModel.vmIsSaving()) { return }
    if (!currentRepr) { return }
    if (!this.isActive.peek()) { return }
    this.savedRepr = this.savedRepr || cloneDeep(this.dataModel.vmSavedRepr())
    if (!this.savedRepr) { return }
    const diff = compare(currentRepr, this.savedRepr)
    if (diff.length) {
      this.undoStack.unshift(diff) // push
      const len = Math.min(this.undoStack.length, MAX_STACK_LENGTH)
      this.undoStack.splice(len, this.undoStack.length - len)
      this.redoStack([])
      this.savedRepr = cloneDeep(currentRepr)
    }
  }

}
