import { parseISO } from 'date-fns/esm'

import { toISO8601 } from 'utils/dates'
import { toLocaleDecimal } from 'utils/number'
import { AssetTransferLine } from 'capital/interfaces'
import { Transaction } from 'capital/Transaction'

import { buttons, dropdown, color } from 'styles'
import { inline } from 'icons'
import editIcon from 'icons/solid/caret-down'
import certIcon from 'icons/light/certificate'
import undatedIcon from 'icons/solid/exclamation-triangle'

import { AssetCertificate } from '../interfaces'
import { ORIGIN } from 'capital/Transaction'

import ShareholderGridView from './ShareholderGridView'
import './capital-asset-name'
import './certificates-list'

type AmountDetails = {
  amountStyle: string
  isCredit: boolean
  isDebit: boolean
  formatted: string
}

const creditRex = /^[\d,\.]+$/
const debitRex = /^\([\d,\.]+\)$/
const numericalRex = /^\d+$/


export default class CapitalShareTransfers extends ShareholderGridView {
  datetime: KnockoutObservable<Date>

  constructor (params) {
    super(params)
    const { datetime } = params
    Object.assign(this, { datetime })
  }

  static get css () {
    return {
      ...super.css,
      ...this.certificateCSS,
      ...this.titleCSS,
      ...this.amountCSS,
      grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(8, auto)',
        gridGap: '12px 20px',
        alignItems: 'baseline',
        margin: '6px',
      },
      _cell: {
        fontSize: '0.8em',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
        '&[future]': {
          opacity: 0.5
        }
      },
      transferNumber: {
        extend: '_cell',
        gridColumnStart: 1,
        fontSize: '0.8em'
      },
      date: {
        ...buttons.clickable,
        extend: '_cell',
        gridColumnStart: 2,
        fontSize: '0.8em'
      },
      undated: {
        gridColumnStart: 2,
        '--icon-color': color.text.light.altPrimary,
        color: color.text.light.altPrimary,
        backgroundColor: color.color.light.red,
        'body[dark] &': { // project batman
          backgroundColor: color.color.dark.red,
          '--icon-color': color.text.dark.primary,
          color: color.text.dark.primary,
        },
        borderRadius: 4,
        padding: 4,
        textTransform: 'uppercase',
        fontSize: '0.7em',
        fontWeight: 700,
        width: 'fit-content',
      },
      person: {
        extend: '_cell',
        gridColumnStart: 4,
        paddingLeft: 15
      },
      cert: {
        extend: '_cell',
        gridColumnStart: 6,
      },
      assetName: {
        extend: '_cell',
        gridColumnStart: 7,
        border: `1px dashed ${color.systemGrey.light.five}`,
        'body[dark] &': { // project batman
          border: `1px dashed ${color.systemGrey.dark.five}`,
        },
        borderRadius: 2,
        padding: '5px 2px 5px 7px',
      },
      edit: {
        extend: '_cell',
        gridColumnStart: 8,
      },
      editButton: {
        ...buttons.clickable,
      },
      editMenu: {
        ...dropdown.generalMenu,
      },
      editMenuItem: {
        ...dropdown.item,
        '&[disabled]': {
          color: '#aaa',
        }
      }
    }
  }

  static get certificateCSS () {
    return {
      certificatesList: {
        fontSize: '0.75rem',
      },
      _cert: {
        display: 'inline',
        padding: '0px 2px',
        whiteSpace: 'nowrap',
      },
      certIssued: {
        extend: '_cert',
        color: 'green',
      },
      certSurrendered: {
        extend: '_cert',
        color: 'red',
      }
    }
  }

  editHTML (t: Transaction) : any {
    const { transactions } = this
    const Codes = Transaction.SerializationCodes
    switch (t.code) {
      case Codes.CapitalAuthorize:
        return <capital-authorization-editor
          persons={this.persons}
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalAmend:
        return <capital-amendment-editor
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalDeauthorize:
        return <capital-deauthorize-editor
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalCancel:
        return <capital-share-cancel-editor
          persons={this.persons}
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalIssue:
        return <capital-share-issue-editor
          persons={this.persons}
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalTransfer:
        return <capital-share-transfer-editor
          persons={this.persons}
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalIndividualConvert:
        return <capital-share-individual-conversion-editor
          persons={this.persons}
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalMassConvert:
        return <capital-share-mass-conversion-editor
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />
      case Codes.CapitalSplit:
        return <capital-share-split-editor
          relatedGenerator={this.relatedGenerator}
          original={t} transactions={transactions} />

      default:
        console.warn(`
          <capital-share-transfers>
            Unhandled Transaction (${t.constructor.name}) code "${t.code}".
        `)
    }
  }

  editLineHTML (t: Transaction) {
    const { jss } = this

    const deleteClick = () => {
      if (t.deleteable) { this.transactions.remove(t) }
    }

    return (
      <drop-down my='top right' at='bottom right'>
        <template slot='anchor'>
          <div class={jss.editButton}>
            {inline(editIcon)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.editMenu}>
            <div class={jss.editMenuItem}
              ko-ownClick={() => this.editLineClick(t)}>
              Edit
            </div>
            <div class={jss.editMenuItem} disabled={t.deleteable ? undefined : true}
              ko-ownClick={() => deleteClick()}>
              Delete
            </div>
          </div>
        </template>
      </drop-down>
    )
  }

  editLineClick (t: Transaction) {
    const html = this.editHTML(t)
    if (!html) { return }
    window.app.modal(
      <modal-dialog modalTitle={t.title}>
        <template slot='content'>
          {html}
        </template>
      </modal-dialog>
    )
  }

  static get amountCSS () {
    return {
      _amount: {
        extend: '_cell',
        gridColumnStart: 5,
        textAlign: 'right',
        fontSize: '0.8em',
        fontWeight: '700'
      },
      amountHead: {
        extend: 'head',
        textAlign: 'right',
      },
      noAmount: {
        extend: '_amount',
      },
      negativeAmount: {
        extend: '_amount',
        color: color.color.light.red,
        'body[dark] &': { // project batman
          color: color.color.dark.red,
        },
      },
      positiveAmount: {
        extend: '_amount',
        color: color.color.light.green,
        'body[dark] &': { // project batman
          color: color.color.dark.green,
        },
      },
      stringAmount: { // e.g. "unlimited"
        extend: '_amount',
        color: 'black',
        textTransform: 'uppercase'
      },
      personHead: {
        extend: 'head',
        paddingLeft: '15px'
      },
      _toFrom: {
        fontWeight: 700,
        textTransform: 'uppercase',
        background: color.fill.light.primary,
        borderRadius: 2,
        fontSize: '0.6em',
        verticalAlign: 'text-bottom',
        marginRight: 5,
        lineHeight: '1.8em',
        padding: '0 4px 0 5px',
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
        },
      },
      creditTo: {
        extend: '_toFrom'
      },
      debitFrom: {
        extend: '_toFrom'
      },
    }
  }

  static get titleCSS () {
    return {
      _capitalClass: {
        color: 'black',
        borderRadius: 4,
        textAlign: 'center',
        fontSize: '0.7em',
        textTransform: 'uppercase',
        fontWeight: 700,
        padding: '2px 6px'
      },
      _capitalTransaction: {
        color: 'black',
        borderRadius: 4,
        textAlign: 'center',
        fontSize: '0.7em',
        textTransform: 'uppercase',
        fontWeight: 700,
        padding: '2px 6px'
      },
      title: {
        extend: '_cell',
        '&[code="capital.auth"]': {
          extend: '_capitalClass',
          border: `2px solid ${color.color.light.green}`,
          'body[dark] &': { // project batman
            border: `2px solid ${color.color.dark.green}`,
          },
        },
        '&[code="capital.amend"]': {
          extend: '_capitalClass',
          border: `2px solid ${color.color.light.orange}`,
          'body[dark] &': { // project batman
            border: `2px solid ${color.color.dark.orange}`,
          },
        },
        '&[code="capital.deauth"]': {
          extend: '_capitalClass',
          border: `2px solid ${color.color.light.red}`,
          'body[dark] &': { // project batman
            border: `2px solid ${color.color.dark.red}`,
          },
        },

        '&[code="capital.issue"]': {
          extend: '_capitalTransaction',
          backgroundColor: color.color.light.green,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.green,
          },
        },
        '&[code="capital.transfer"]': {
          extend: '_capitalTransaction',
          backgroundColor: color.color.light.orange,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.orange,
          },

        },
        '&[code="capital.cancel"]': {
          extend: '_capitalTransaction',
          backgroundColor: color.color.light.red,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.red,
          },
        },

        // individual conversion:
        '&[code="capital.iconvert"]': {
          extend: '_capitalTransaction',
          backgroundColor: color.color.light.indigo,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.indigo,
          },
        },
        // mass conversion:
        '&[code="capital.mconvert"]': {
          extend: '_capitalTransaction',
          backgroundColor: color.color.light.teal,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.teal,
          },
        },
        '&[code="capital.split"]': {
          extend: '_capitalTransaction',
          backgroundColor: color.color.light.purple,
          'body[dark] &': { // project batman
            backgroundColor: color.color.dark.purple,
          },
        },
      },
    }
  }

  * lineHTML (line: AssetTransferLine) {
    const { transferNumber, datetime, transactionTitle } = line
    const { jss } = this
    const future = datetime > toISO8601(this.state.datetime) || undefined

    const setDatetime = () => {
      this.datetime(parseISO(datetime))
    }

    yield (<div class={jss.transferNumber} future={future}>{transferNumber}</div>)
    if (datetime) {
      yield (
        <div class={jss.date}
          future={future}
          ko-ownClick={() => setDatetime()}>
          {datetime}
        </div>
      )
    } else {
      yield (
        <div class={jss.undated} future={true}>
          {inline(undatedIcon)} Undated
        </div>
      )
    }
    yield (
      <div class={jss.title} future={future} code={line[ORIGIN].code}>
        {transactionTitle}
      </div>
    )

    for (const part of line.parts) {
      const certs = {
        issued: line.certificatesIssued
          .filter(c => c.person === part.person && c.assetClassID === part.assetClassID),
        surrendered: line.certificatesSurrendered
          .filter(c => c.person === part.person && c.assetClassID === part.assetClassID),
      }
      yield * this.assetTransferParts(part, certs, future)
    }

    yield this.editLineHTML(line[Transaction.ORIGIN])
  }

  * assetTransferParts (part, certs: { issued: AssetCertificate[], surrendered: AssetCertificate[] }, future : boolean) {
    const { jss } = this
    const { issued, surrendered } = certs

    const { prefix, classification, series } = part.asset

    const amountDetails = this.getAmountDetails(part.amount)
    const { isCredit, isDebit, amountStyle } = amountDetails

    yield part.person ? (
      <div class={jss.person} future={future}>
        {isCredit
          ? <span class={jss.creditTo}>to: </span>
          : isDebit ? <span class={jss.debitFrom}>from: </span>
            : null}
        {this.persons.getNameOf(part.person)}
      </div>
    ) : null

    yield (
      <div class={amountStyle} future={future}>{amountDetails.formatted}</div>
    )
    yield (
      <certificates-list
        asset={part.asset}
        issued={issued}
        surrendered={surrendered} />
    )
    yield (<div class={jss.assetName} future={future}>
      <capital-asset-name prefix={prefix} classification={classification} series={series} />
      </div>)
  }

  getAmountDetails (amount: string) : AmountDetails {
    const { jss } = this
    const noAmount = amount === null
    const isCredit = !noAmount && creditRex.test(amount)
    const isDebit = !noAmount && debitRex.test(amount)
    const isNumerical = isCredit && numericalRex.test(amount)

    const amountStyle = noAmount ? jss.noAmount
      : isCredit ? jss.positiveAmount
      : isDebit ? jss.negativeAmount
      : jss.stringAmount

    const formatted = isNumerical ? toLocaleDecimal(amount) : amount
    return { isCredit, isDebit, amountStyle, formatted }
  }

  get headingsHTML () {
    const { jss } = this
    return [
      <div class={jss.head} title='Transfer number'>#</div>,
      <div class={jss.head}>Dated</div>,
      <div class={jss.head}>Type</div>,
      <div class={jss.personHead}>Person</div>,
      <div class={jss.amountHead}>Amount</div>,
      <div class={jss.head} title='Certificate'>
        {inline(certIcon)}</div>,
      <div class={jss.head}>Asset</div>,
    ]
  }

  * cells () {
    let brk
    for (const line of this.state.shareTransferLedger(undefined, this.state.transactions)) {
      yield brk
      yield * this.lineHTML(line)
      brk = this.fullBreak
    }
  }

  get gridCells () : any[] {
    return [...this.cells()]
  }
}

CapitalShareTransfers.register()
