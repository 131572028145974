
import { Director } from 'EntityModel/components'
import { observeProperty } from 'person/utils'
import t from 't'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisDirectors extends EisArrayComponentTable<Director> {
  get caption () { return 'Directors' }
  get componentName () { return 'directors' }
  get listAddName () { return 'Director' }

  get columns () {
    return [
      'person',
      this.residencyColumn,
      'elected',
      'resigned',
      this._roleCountColumn('person'),
    ]
  }

  get residencyColumn () {
    return {
      title: t.PERSON_RESIDENCY,
      view: unit => <jurisdiction-flag
        value={this.computed(() => unit.person().residency[0])} />,
      edit: clone => this._jurisdictionPicker({
        residency: observeProperty(clone.person, 'residency', 0),
        parent: clone.parent,
      } as unknown as Director, 'residency', t.PERSON_RESIDENCY),
    }
  }
}

EisDirectors.register()
