
import ViewComponent from 'ViewComponent'
import { formatForUser } from 'utils/dates'

export default class PersonRole extends ViewComponent {
  role: PersonRoleRecord

  constructor ({ role } : { role: PersonRoleRecord }) {
    super()
    Object.assign(this, { role })
  }

  static get css () {
    return {
      role: {
        display: 'flex',
      },
      roleName: {

      },
      roleStart: {
        padding: '0px 10px',
      },
      roleEnd: {
        padding: '0px 10px',
      },
    }
  }

  get template () {
    const { role, jss } = this
    return (
      <div class={jss.role}>
        <div class={jss.roleName}>{role.title}</div>
        <div class={jss.roleStart}>
          {formatForUser(role.start)}
        </div>
        <div class={jss.roleEnd}>
          {formatForUser(role.end)}
        </div>
      </div>
    )
  }
}

PersonRole.register()
