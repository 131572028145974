
import { DomainName } from 'EntityModel/components'

import { DOMAIN_REGISTRARS } from '../constants'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisDomainNames extends EisArrayComponentTable<DomainName> {
  get caption () { return 'Domain Names' }
  get componentName () { return 'domain_names' }
  get listAddName () { return 'Domain Name' }
  get columns () { return [ 'domain', 'registrar', 'expiry' ] }
  get registrarModelPickerChoices () { return DOMAIN_REGISTRARS }
}

EisDomainNames.register()
