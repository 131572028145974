
import EisMultiComponentField from './EisMultiComponentField'

import 'jurisdictions'
import { JURISDICTIONS, colorOf, lookup, flagUrlOf } from 'jurisdictions'


import { typography, color, buttons } from 'styles'

export default class EisNameJurisdiction extends EisMultiComponentField {
  constructor (args) {
    super(args)
    this.registerNavigation()
  }

  get componentNames () { return ['legalname', 'governing_statute', 'jurisdiction' ] }

  static get css () {
    return {
      ...super.css,
      ...this.editGridCSS,
      contentArea: {
        gridArea: 'content',
        display: 'grid',
        gridTemplateColumns: '1fr auto 65px',
      },
      title1: {
        ...super.css.title,
      },
      indicatorTitle: {
        ...super.css.title,
      },
      _title: {
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
        },
      },
      titleEdit: {
        ...super.css.title,
        '& > span': {
          extend: '_title'
        }
      },
      field1Area: {
        display:'flex',
        alignItems: 'flex-end',
      },
      field1: {
        ...super.css.textContent,
        ...super.css.fieldLarge
      },
      indicator: {
        ...super.css.indicator,
        display: 'flex',
        background: 'linear-gradient(to bottom, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#000000 100%)',
        color: 'white',
        alignItems: 'center',
        padding: '0px 0px',
        '&[focused]': {
          // ...super.css._indicatorFocused
          borderRadius: 3,
          width: 'fit-content',
          color: 'black',
          background: '#ffffff',
          boxShadow: 'none',
          transition: 'box-shadow 300ms ease-out, background 100ms ease-out',
          padding: '0px 0px'
        }
      },
      jurisdictionIndicator: {
        extend: 'indicator',
        background: `var(--flag-bg-color)`,
        '&:empty': { display: 'none' },
      },
      indicatorPart: {
        padding: '3px 5px',
        color: color.text.light.altPrimary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
        '&:first-child':{
          marginRight: '5px',
          fontStyle: 'italic',
          fontWeight: 'bold'
        },
        '&[focused]': {
          ...super.css._indicatorFocused,
          padding: '3px 5px'
          // background: '#dfe3e6',
          // textDecoration: 'underline',
        },
        '&:empty': { display: 'none' },
      },

      inputField: {
        width: '100%',
        minWidth: 'fit-content',
        height: 40,
        fontFamily: typography.bodyFontFamily,
        padding: 5,
        margin: 5,
        fontSize: '1rem',
        borderRadius: 2,
        backgroundColor: color.light.eisDrawerBackground,
        border: '1px solid transparent',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
          borderBottom: `1px solid ${color.separator.dark.altPrimary}`,
        },
        transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        '&:focus': {
          outline: 'none',
          transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        }
      }
    }
  }

  get titleAreaHTML() { return '' }

  get title1HTML () {
    return (
      <div class={this.jss.title1}>
        <span>Entity Name</span>
        {this.copyIconHTML('legalname')}
      </div>
    )
  }

  get jurisdictionCopyValue () {
    return `${this.componentsMap.governing_statute()} ${this.componentsMap.jurisdiction()}`
  }

  get displayHTML () {
    const { jss } = this
    const focusAttribute = c => this.computed(() => this.inputfocus[c]() || undefined)
    const indicatorFocus = this.computed(() =>
      this.inputfocus.governing_statute() || this.inputfocus.jurisdiction() || undefined)

    const colorStyle = this.computed(() => {
      return `--flag-bg-color: ${colorOf(this.componentsMap.jurisdiction())}`
    })

    return [
      this.title1HTML,
      <div class={jss.indicatorTitleArea}>
        <div class={this.jss.indicatorTitle}>
          <span>Jurisdiction</span>
        </div>
      </div>,
      this.copyIconHTML(() => this.jurisdictionCopyValue),
      <div class={jss.field1Area}>
        <div class={jss.field1} focused={focusAttribute('legalname')}
          blank={this.computed(() => this.componentsMap.legalname() ? undefined : 'true')}>
          {this.displayValue.legalname}
        </div>
      </div>,
      <div class={jss.indicatorArea}>
        <div class={jss.jurisdictionIndicator}
          style={colorStyle}
          focused={indicatorFocus}
          jurisdiction={this.componentsMap.jurisdiction}
          statute={this.componentsMap.governing_statute}>

          <div class={jss.indicatorPart}
            focused={focusAttribute('governing_statute')}>
            {this.componentsMap.governing_statute}
          </div>

          <jurisdiction-indicator value={this.componentsMap.jurisdiction} />
        </div>
      </div>
    ]
  }

  jurisdictionHTML (focusAttribute) {
    const { jss } = this
    const { jurisdiction } = this.componentsMap
    const jurisdictionFlagURL = this.computed(() => {
      return `url("${flagUrlOf(jurisdiction()) || ''}")`
    })

    // 🚨 Using "Federal" as a jurisdiction is not forward-compatible b/c it's used by many countries.
    return [
      <div class={jss.jurisdictionFlag} ko-style={{ backgroundImage: jurisdictionFlagURL }} />,
      <div class={jss.indicatorPart}
        focused={focusAttribute('jurisdiction')}>
        {this.componentsMap.jurisdiction}
      </div>
    ]
  }

  // get jurisdictionHint () {
  //   const { jurisdiction, governing_statute } = this.inputs
  //   if (!governing_statute()) { return }
  //   if (lookup(jurisdiction())) { return }

  //   return (
  //  TODO: Look up jurisdictions by statute
  //   )
  // }

  get statuteHint () {
    const { jss } = this
    const { jurisdiction, governing_statute } = this.inputs
    const j = lookup(jurisdiction())
    if (!j || j.statutes.includes(governing_statute())) { return null }
    return j.statutes.map(s => (
      <div class={jss.statuteSuggestion}
        ko-ownClick={() => governing_statute(s)}>
        {s}?
      </div>
    ))
  }

  get editHTML () {
    const { jss } = this
    const koEvent = { keydown: this.inputKeyDown({ 'Tab': evt => this.onTabDown(evt) }) }
    const STATUTES = JURISDICTIONS.map(j => j[3]).flat()

    return (
      <div class={jss.editGrid}>
        <div class={jss.nameEditTitle}>
          <span>Entity Name</span>
        </div>
        <div class={jss.statuteEditTitle}>
          <span>Governing Statute</span>
        </div>
        <div class={jss.jurisdictionEditTitle}>
          <span>Jurisdiction</span>
        </div>
        <div class={jss.nameInputArea}>
          <input type='text' placeholder='Entity name' ko-textInput={this.inputs.legalname}
            ko-hasFocus={this.computed(() => this.editing() && this.inputfocus.legalname())}
            ko-event={koEvent} class={jss.inputField}/>
        </div>
        <div class={jss.statuteDropdown}>
          <model-property-picker my='top left' at='bottom left'
            extraChoices={STATUTES}
            indexName='entity'
            inputClass={jss.inputField}
            propertyGetter={e => e.answerFor('governing_statute')}
            value={this.inputs.governing_statute}
          />
        </div>
        <div class={jss.jurisdictionPicker}>
          <jurisdiction-picker my='top right' at='bottom right'
            inputClass={jss.inputField}
            value={this.inputs.jurisdiction} />
        </div>
        <div class={jss.statuteHint}>
          {this.computed(() => this.statuteHint)}
        </div>
        <div class={jss.jurisdictionHint}>
          {/* {this.computed(() => this.jurisdictionHint)} */}
        </div>
      </div>
    )
  }

  static get editGridCSS () {
    return {
      editGrid: {
        display: 'grid',
        padding: 10,
        gridTemplateAreas: `
          'nt st jt'
          'ne se je'
          '.  sh jh'
        `,
        gridColumnGap: '15px',
        gridTemplateColumns: '1fr 1fr 1fr',
        '[narrow] &': {
          gridTemplateColumns: 'auto 1fr 1fr',
        }
      },
      nameEditTitle: {
        extend: 'title',
        gridArea: 'nt',
      },
      statuteEditTitle: {
        extend: 'title',
        gridArea: 'st',
      },
      jurisdictionEditTitle: {
        extend: 'title',
        gridArea: 'jt',
      },
      nameEditArea: {
        gridArea: 'ne',
      },
      statuteDropdown: {
        gridArea: 'se',
      },
      jurisdictionPicker: {
        gridArea: 'je',
      },
      statuteHint: {
        gridArea: 'sh',
        height: '6px',
      },
      jurisdictionHint: {
        gridArea: 'jh',
      },
      statuteSuggestion: {
        ...buttons.clickable,
        display: 'inline',
        padding: '3px 6px',
        marginTop: 40,
        position: 'relative',
        top: 4,
        marginLeft: 10,
        userSelect: 'none',
        textDecoration: 'unset',
        background: color.fill.light.primary,
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
          color: color.text.dark.primary
        },
        boxShadow: '0px 0px 14px 3px rgba(0,0,0,0.15)',
        borderRadius: 8,
        transition: 'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transition: 'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          background: color.fill.light.secondary,
          boxShadow: '0px 0px 18px 6px rgba(0,0,0,0.2)',
          'body[dark] &': { // project batman
            background: color.fill.dark.secondary,
          },
        }
      },
    }
  }
}

EisNameJurisdiction.register()
