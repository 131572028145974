
import Picker from 'picker'
import { matchesLocalePrefix } from 'utils/string'

export default class SectionPicker extends Picker<string> {
  choices () {
    const choices = new Set<string>()
    const modelList = window.app.memoryDB.getListOf('entity')
    for (const model of modelList()) {
      const book = model.answerFor('book')
      const sectionNames = book.sections()
        .filter(s => !s.editing())
        .map(s => ko.unwrap(s.name))
      for (const name of sectionNames) { choices.add(name) }
    }
    return [...choices].sort((a, b) => a.localeCompare(b))
  }

  itemHTML (item) {
    const { jss } = this
    return <div class={jss.name}>{item}</div>
  }

  filterChoice (item, query = '') {
    return matchesLocalePrefix(item, query)
  }

  get noMatchesMessage () { return 'Nothing matches. [Enter] to add.' }
  get searchMessage () { return 'Type to search or add' }
}

/**
 * Section Picker for the Minute Book Section List
 */
export class SectionPickerSectionList extends SectionPicker {
  constructor (params) {
    super(params)
    const { onBlur, editing } = params
    Object.assign(this, { onBlur, editing })
  }

  static get css () {
    return {
      ...super.css,
      input: {
        fontSize: '1em',
        padding: '2px 5px'

      },
      list: {
        ...super.css.list,
        maxHeight: '500px',
        overflowY: 'auto'
      },
      item: {
        ...super.css.item,
        height: '27px'
      },
      name: {
        ...super.css.name
      },
      shortcut: {
        ...super.css.shortcut
      },
    }
  }

  itemHTML (item, index) {
    const { jss } = this
    return (
      <>
        <div class={jss.name}>{item}</div>
      </>
    )
  }

  selectChoice (evt, index) {
    if (super.selectChoice(evt, index)) {
      this.editing(false)
    }
  }

  get keyboardShortcuts () {
    return {
      ...super.keyboardShortcuts,
      Escape: () => this.editing(false)
    }
  }

  /**
   * Mimic the inputHTML from an inline-input
   */
  get anchorHTML () {
    const { jss } = this
    return (
      <input type='text'
        class={jss.input}
        ko-textinput={this.query}
        ko-hasFocus={1}
        ko-select-text={1}
        ko-click={evt => { evt.preventDefault(); evt.stopPropagation() }}
        ko-event={{ blur: (_, evt) => this.onBlur(evt) }}
        ko-keydown={this.keyboardShortcuts} />
    )
  }
}

SectionPickerSectionList.register()
