//
// UnitView
// --------
//
// A UnitView is a base class that provides some simple
// functions that provide some common properties for
// data models that map to/from JSON.
//
// Subclasses should provide `fields`, a map of field names to field-types.
// Types may be primitive (date, bool, etc.) or a function - when a function
// it is presumed to return a map.
//
// At its core, this ABC provides a constructor and toJS function, each of
// which uses the `fields` property to respectively deserialize/serialize
// JSON.

import { isEqual } from 'lodash-es'

import { inSameRange } from 'utils/dates'
import { isBlank } from 'person/utils'
import { PersonType } from 'person/interfaces'

import { UnitFieldMap, UnitField, DateGenerator } from './UnitField'
import DataComponent from './DataComponent'

type FieldDescription = import('./UnitField').FieldDescription
type UnlabeledFieldDescription = Omit<FieldDescription, 'label'>

export interface Reminder {
  unit: UnitView
  field: string
  date: Date
  reason: string
  show: KnockoutObservable<boolean>
  identifier?: string
}

const { ko } = window


export default class UnitView extends ko.LifeCycle {
  [x: string]: any
  isBlank: KnockoutComputed<boolean>
  isDeleted: KnockoutObservable<boolean>

  fieldOfType (v: string): UnitField<any> { return UnitFieldMap[v] }
  static fieldOfType (v: string): UnitField<any> { return UnitFieldMap[v] }
  static get fields () { return this.prototype.fields }

  static get fieldDescriptions (): UnlabeledFieldDescription[] {
    return Object.entries(this.fields)
      .map(([property, type]) => ({ property, type })) as any[]
  }

  /**
   * Overload field descriptions, used in actions in variables / writings.
   * Example: AddressComponent
   */
  static get variableFieldDescriptions (): UnlabeledFieldDescription[] {
    return this.fieldDescriptions
  }

  constructor (opts: any = {}, public parent: DataComponent) {
    super()

    // if (!parent) { throw new Error(`UnitView 'parent' param missing.`) }

    Object.assign(this, {
      isDeleted: ko.observable(false)
    })

    this.addFieldInstances(opts)
    this.isBlank = this.computed(() => this._isBlank())
  }

  // The unit is blank if all the properties are blank or equal to default.
  _isBlank () : boolean {
    return Object.entries(this.fields).every(this.fieldIsBlank, this)
  }

  fieldIsBlank ([name, field]) {
    const default_ = field.default || this.defaultTypeValues[field.type]
    const instance = this[name]
    const value = 'toJS' in instance ? instance.toJS() : ko.toJS(instance)
    return isEqual(value, default_)
  }

  is_equal (other) {
    return isEqual(this.toJS(), other)
  }

  toJS () {
    return Object.entries(this.fields)
      .reduce(this.addValueToObject.bind(this), {})
  }

  addValueToObject (acc, [name, type]) {
    const value = ko.unwrap(this[name])
    acc[name] = ko.unwrap(this.fieldValue(value, this.fieldDefn(type)))
    return acc
  }

  /**
   * @param {any} value
   * @param {string} fieldType
   * @return {any} value to be saved
   */
  fieldValue (value, { type }) {
    const unitField = UnitFieldMap[type]
    if (unitField) {
      return unitField.toJSON(value)
    }

    console.error(`
        UnitView::fieldValue, bad type:
          type: ${type}
          value: ${value}
          parent:
        `, this.parent)
    throw new Error('UnitView::fieldValue Bad type.')
  }

  shouldBeSaved (v) {
    return !v.isBlank() && !v.isDeleted()
  }

  /**
   * @param {string|object}
   * @return {object} { type, default, ... }
   */
  fieldDefn (type) {
    return typeof type === 'string' ? { type } : type
  }

  addFieldInstances (values) {
    for (const [name, type] of Object.entries(this.fields)) {
      if (name in this) {
        throw new Error(`UnitView::addFieldInstances .${name} exists.`)
      }
      const defn = this.fieldDefn(type)
      const given = name in values
        ? values[name]
        : 'default' in defn
          ? defn.default
          : this.defaultTypeValues[defn.type]

      this[name] = this.fieldObservable(given, defn)
    }
  }

  fieldObservable (given, { type, Ctr, factory }) {
    const unitField = UnitFieldMap[type]
    if (unitField) {
      return unitField.fromJSON(given, this, Ctr || factory)
    }

    console.error(`UnitView - Bad type ${type}`, type, Ctr, given, this.parent)
    throw new Error(`UnitView::fieldObservable Error`)
  }

  // If we are not given a value in the opts, or an explicit default value
  // we use these defaults.
  get defaultTypeValues () {
    return {
      bool: null,     // falsy, but not explicitly set.
      boolean: null,
      class: null,
      date: null,
      map: [],
      number: 0,
      int: 0,
      integer: 0,
      primitive: '',
      string: '',
      object: {}
    }
  }

  get fields () : Record<string, string> {
    throw new Error('UnitView: overload `fields`.')
  }

  clone () : UnitView {
    const View = this.constructor as any
    return new View(this.toJS(), this.parent)
  }

  /**
   * Copy the Field values (by reference).
   */
  copyFieldValues (origin: UnitView) {
    for (const [name, type] of Object.entries(this.fields)) {
      const field = UnitFieldMap[type]
      field.copyValue(origin[name], this[name])
    }
  }

  * genRemindersAtDate (given: Date, range: string = 'day') : IterableIterator<Partial<EventRecord>> {
    for (const [name, fieldType] of Object.entries(this.fields)) {
      const unitField = this.fieldOfType(fieldType) as DateGenerator
      if (!unitField.reminderDate) { continue }
      const asDate = unitField.reminderDate(ko.unwrap(this[name]))
      if (!asDate) { continue }
      if (!given || !inSameRange(asDate, given, range)) { continue }
      yield {
        unit: this,
        date: asDate,
        reason: unitField.reminderText(name, this),
      }
    }
  }

  /**
   * Return any `PersonRecord`s  associated with this UnitView
   */
  * getPersons (filter: PersonFilter): IterableIterator<PersonRecord> {
    const unit = this
    for (const [prop, fieldType] of Object.entries(this.fields)) {
      if (fieldType !== 'person') { continue }
      const obs = this[prop]
      const v = obs()
      if (!filter(v) || isBlank(v)) { continue }

      const origin = [{
        update (op) { op(obs()); obs.notifySubscribers(obs()) },
        model: this.parent.model,
        * roles () { yield * unit.getRoles(prop) }
      }] as PersonOrigin[]

      yield { ...v, type:PersonType.REFERENCE, origin }
    }
  }

  personWithOrigin (prop) : PersonRecord {
    const unit = this
    const obs = this[prop]
    const v = obs()
    const origin = [{
      update (op) { op(obs()); obs.notifySubscribers(obs()) },
      model: this.parent.model,
      * roles () { yield * unit.getRoles(prop) },
    }] as PersonOrigin[]
    return { ...v, type:PersonType.REFERENCE, origin }
  }

  /**
   * isSamePerson is `true` when:
   * 1. the persons are both "soft" (no ids) but have the same name; or
   * 2. the persons have the same id.
   */
  isSamePerson (field: string, them: PersonRecord) : boolean {
    const us = this[field]()
    if (us.id || them.id) { return us.id === them.id }
    const ourName = (us.name || [''])[0]
    return them.name.includes(ourName)
  }

  roleIsFromThisUnit (property: string, r: PersonRoleRecord) {
    return [...this.getRoles(property)].some(ur => isEqual(ur, r))
  }

  /**
   * Yield all the roles of the given `person` field.
   * Since a UnitView could have multiple `person` fields, the property
   * name is passed in to disambiguate.
   */
  * getRoles (property?: string) : IterableIterator<PersonRoleRecord> {}
}
