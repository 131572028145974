
import 'minute-box-app'

const {
  GIT_REVISION,
  GIT_BRANCH,
  GIT_COMMIT_COUNT,
  BUILD_UNAME,
  BUILD_HOST,
  COMPILE_TIMESTAMP,
  CI,
  FIREBASE_FN_VERSION,
} = WEBPACK_ABOUT_MINUTEBOX

/**
 * Increment this build number to indicate that the app has been
 * updated and there are changes that the user may read about in
 * <things-have-changed-modal>.
 */
const BUILD_NUMBER = 8
const FRIENDLY_RELEASE = `3.0.0.${GIT_COMMIT_COUNT}`

console.log(`
  MinuteBox:

  Release    🎇  ${FRIENDLY_RELEASE}

  Major #    🏗  ${BUILD_NUMBER}
  Minor #    💯  ${GIT_COMMIT_COUNT}
  Compiled   ⏱  ${COMPILE_TIMESTAMP}

  Branch     🌿️  ${GIT_BRANCH}
  Version    📦  ${GIT_REVISION}

  fbfn-ver   🔨  ${FIREBASE_FN_VERSION}

  ci         💉  ${CI}
  uname -a   🖥  ${BUILD_UNAME}
  hostname   🌍  ${BUILD_HOST}
`)

window.ABOUT_MINUTEBOX = {
  FRIENDLY_RELEASE,
  BUILD_NUMBER,
  ...WEBPACK_ABOUT_MINUTEBOX }
window.FBFN_VER = WEBPACK_ABOUT_MINUTEBOX.FIREBASE_FN_VERSION

ko.applyBindings()
console.log('MinuteBox bound to DOM.')
