
import CURRENCIES from './_currencies'

export type tCurrency = {
  name: string,
  code: string,
  symbol: string,
}

function deLaconic (c: string[]) {
  return {
    code: c[0],
    name: c[1],
    symbol: c[2],
  }
}

const codeToCurrency : Record<string, tCurrency> = Object.assign({},
  CURRENCIES.reduce((o, c) => (o[c[0]] = deLaconic(c), o), {}))
const nameToCurrency : Record<string, tCurrency> = Object.assign({},
  CURRENCIES.reduce((o, c) => (o[c[1]] = deLaconic(c), o), {}))

export const currencyList = CURRENCIES.map(deLaconic) as tCurrency[]

export function lookup (codeOrName: string) : tCurrency {
  return codeToCurrency[codeOrName] || nameToCurrency[codeOrName] || null
}

export function currencyAttr (codeOrName: string, attr: string) : string {
  const v = lookup(codeOrName)
  return v && v[attr] || null
}

/**
 * Converts a string into `{ amount: string, currency: string }`.
 * If the string has no digits, then `{ amount: null, currency: string }`
 */
export function parseCurrencyAmount (v: CurrencyAmountString) : CurrencyAmount {
  if (/\d/.test(v)) {
    const [amount, currency] = (v || '').split(' ')
    return { amount, currency }
  }
  return { amount: null, currency: v }
}
