
import { sortBy } from 'lodash-es'

import { toISO8601, formatForUser } from 'utils/dates'
import { typography } from 'styles'

import ViewComponent from 'ViewComponent'

import { Transaction, CapitalActionClass } from 'capital'

export default class CapitalTransactionsAdvanced extends ViewComponent {
  transactions: KnockoutObservableArray<Transaction>

  constructor (params) {
    super()
    Object.assign(this, {
      transactions: params.transactions
    })
  }

  static get css () {
    return {
      ...super.css,
      list: {},
      transaction: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        borderBottom: '1px solid gray'
      },
      opcode: {
        color: 'red',
        display: 'inline-block',
        padding: '4px 10px',
      },
      datetime: {
        backgroundColor: 'aliceblue',
        width: 'fit-content',
        height: 'fit-content',
        padding: '5px 10px',
        borderRadius: 20,
        margin: 5,
      },
      actions: {
        gridColumn: '1 / -1',
      },
      title: {
        fontWeight: 700,
        justifySelf: 'center',
      },

      description: {
        fontFamily: typography.mono,
        lineHeight: '2em',
      },
      deleteButton: {
        width: 'fit-content',
        fontFamily: typography.titleFamily,
        boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
        borderRadius: 4,
        padding: '5px 5px',
        margin: '10px 0px',
        justifySelf: 'end',
        cursor: 'pointer'
      }
    }
  }

  actionHTML (action: CapitalActionClass) {
    const  { jss } = this
    return (
      <div class={jss.description}>
        <div class={jss.opcode}>{action.constructor.name}</div>
        {action.description}
      </div>
    )
  }

  // <input type='date'
  //   ko-value={tr.datetime} />
  // <input type='text'
  //   placeholder='Title'
  //   ko-textInput={tr.title} />

  transactionHTML (tr: Transaction) {
    const { jss } = this

    return (
      <div class={jss.transaction}>
        <div class={jss.datetime}>{formatForUser(tr.datetime())}</div>
        <div class={jss.title}>{tr.title}</div>
        <div class={jss.deleteButton}
          ko-click={() => this.transactions.remove(tr)}>
          DELETE
        </div>
        <div class={jss.actions}>
          {tr.actions().map(a => this.actionHTML(a))}
        </div>
      </div>
    )
  }

  get transactionsHTML () {
    const order = (tr: Transaction) => toISO8601(tr.datetime())
    return sortBy(this.transactions(), order)
      .map(tr => this.transactionHTML(tr))
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.list}>
        {this.computed(() => this.transactionsHTML)}
      </div>
    )
  }
}

CapitalTransactionsAdvanced.register()
