
import ViewComponent from 'ViewComponent'

import { currentOrLastEditableRange } from 'utils/editable'
import { computed } from 'utils/decorator'
import 'tool-tip/floating-popup'
import { hidePopovers } from 'pop-over/maintenance'

import { blockEditorSwitch } from '../block-views'
import WritingDocument from '../WritingDocument'
import {
  Block, getBlockInstanceForSelection, BlockRegister,
} from '../blocks'
import { factoryVariableView } from '../variables/views'

import { WritingDocumentEvents } from './WritngDocumentEvents'

type WritingCommandSet = import('../WritingCommandSet').default
type CommandMenu = import('../commands').CommandMenu

interface VariableFloatingMenu {
  rect: ClientRect|DOMRect
  variable: WritingVariable
}

export default class WritingDocumentEditor extends ViewComponent {
  document: WritingDocument
  blockMap: WeakMap<Block, JSX> = new WeakMap()
  formattingBarEvent: KnockoutObservable<FakePopoverEvent> = ko.observable()
  commandSet: WritingCommandSet
  commandKeyDown: KnockoutObservable<KeyboardEvent> = ko.observable()
  rightView: KnockoutObservable<JSX>
  selectionStartIndex: number = null
  private eventHandler = new WritingDocumentEvents(this)
  variableFloatingMenu = ko.observable<VariableFloatingMenu>()
  commandMenu: CommandMenu
  commandFilter: KnockoutObservable<string>

  constructor ({ document, rightView, commandSet }) {
    super()
    const { commandMenu, commandFilter } = commandSet
    Object.assign(this, {
      document, rightView, commandSet, commandMenu, commandFilter,
    })
    this.subscribe(this.commandMenuSlashEvent,
      () => this.commandSet.commandFilter(''))
  }

  get isSelecting () { return this.document.blocks.some(b => b.isSelected()) }
  get commandMenuSlashEvent () { return this.document.commandMenuSlashEvent }

  static get css () {
    return {
      ...super.css,
      canvas: {
        padding: '40px',
        '--canvas-height': 'calc(100vh - var(--head-height))',
        '--panel-left-width': '160px',
        '--panel-right-width': '160px',
        minHeight: 'calc(100vh - var(--top-height))',
        width: 'calc(100% - var(--right-panel-width) - var(--left-panel-width))',
        boxShadow: '0 11px 15px rgba(0, 0, 0, 0.05)',
        backgroundColor: 'white',
        margin: 'auto',
        'body[narrow] &': {
          width: 'unset',
        }
      },
      blockEditor: {
        display: 'block',
      },

      // Styles for writing Section Editor on drag event
      // blockEditor: {
      //   '&:hover': {
      //     boxShadow: 'rgba(0,0,0,0.05) 1px 0px 24px 10px, rgba(0,0,255,1) 0px -3px 0px 0px',
      //     transition: 'box-shadow 120ms ease-in'
      //   }
      // },

      slotPickerFloat: {
        '--tool-tip-padding': 0,
        marginTop: '2px',
      },
    }
  }

  blockHTML (block: Block) {
    const html = this.blockMap.get(block)
    if (!html) {
      const index = this.computed(() => this.document.blocks.indexOf(block))
        .extend({ deferred: true })
      const wse = blockEditorSwitch(
        block, this.document.blocks, this.jss.blockEditor, index, this)
      this.blockMap.set(block, wse)
      return wse
    }
    return html
  }

  getSelectionFakeEventBounds (allowCollapsed = false) : DOMRect {
    const r = currentOrLastEditableRange()
    if (!r) { return null }
    if (allowCollapsed || !r.collapsed) {
      const rangeBounds = r.getBoundingClientRect() as DOMRect
      if (rangeBounds.x === 0) {
        // workaround bug where the range is in an empty div
        const container = r.startContainer as Element
        return container.getBoundingClientRect() as DOMRect
      }
      return rangeBounds
    }
    return null
  }

  onFormattingBarTriggeringEvent () {
    if (this.isSelecting) { return }
    const bounds = this.getSelectionFakeEventBounds()
    this.formattingBarEvent(bounds
      ? { clientX: bounds.left + (bounds.width / 2), clientY: bounds.top }
      : null)
  }

  get formattingBarPopoverHTML () {
    return (
      <pop-over my='bottom center' at='event'
        atEvent={this.formattingBarEvent}
        showing={this.computed(() => Boolean(this.formattingBarEvent()))}>
        <template slot='anchor'><div></div></template>
        <template slot='content'>
          <writing-formatting-bar commandSet={this.commandSet} />
        </template>
      </pop-over>
    )
  }

  moveUp () {
    const block = getBlockInstanceForSelection()
    const index = this.indexOf(block)
    this.document.blocks.swap(index, index - 1)
    block.setFocus()
  }

  moveDown () {
    const block = getBlockInstanceForSelection()
    const index = this.indexOf(block)
    this.document.blocks.swap(index, index + 1)
    block.setFocus()
  }

  indexOf (block): number { return this.document.blocks.indexOf(block) }

  onForwardSlash (evt: KeyboardEvent) {
    if (this.commandMenuSlashEvent()) { return }
    const bounds = this.getSelectionFakeEventBounds(true)
    this.commandMenuSlashEvent(bounds
      ? { clientX: bounds.left, clientY: bounds.top + bounds.height }
      : null)
  }

  showRootMenu () {
    const bounds = this.getSelectionFakeEventBounds(true)
    this.commandMenuSlashEvent(bounds
      ? { clientX: bounds.left, clientY: bounds.top + bounds.height }
      : null)
  }

  private blockByOffsetFromCurrent (filter: (b: Block) => boolean, offset: number) {
    const blocks = this.document.blocks.filter(filter)
    const index = blocks.indexOf(getBlockInstanceForSelection())
    return blocks[index + offset]
  }

  nextBlock (filter = (b: Block) => true) {
    return this.blockByOffsetFromCurrent(filter, 1)
  }

  prevBlock (filter = (b: Block) => true) {
    return this.blockByOffsetFromCurrent(filter, -1)
  }

  currentBlock () { return getBlockInstanceForSelection() }

  onCanvasClick (evt: MouseEvent) {
    const target = evt.target as HTMLElement
    hidePopovers()

    if (target.hasAttribute('canvas')) {
      if (this.isSelecting) { return false }
      const blocks = this.document.blocks
      if (!blocks.length) {
        blocks.push(BlockRegister.factory('p'))
      }
      blocks.slice(-1).pop().setFocus()
    } else if (target.hasAttribute('var')) {
      const { variable } = ko.dataFor(target)
      this.rightView(factoryVariableView(variable))
    }
    this.commandMenuSlashEvent(null)
    return true
  }

  onGripEnd ({ fromIndexList, toIndex }) {
    const { blocks } = this.document
    const blocksMoving = fromIndexList.map(i => blocks.at(i))
    blocks.move(toIndex, ...blocksMoving)
  }

  get commandMenuPopoverHTML () {
    return (
      <pop-over my='auto left' at='event'
        atEvent={this.commandMenuSlashEvent}
        showing={this.computed(() => this.commandMenuSlashEvent())}>
        <template slot='anchor'><div></div></template>
        <template slot='content'>
          <writing-command-menu wde={this} />
        </template>
      </pop-over>
    )
  }

  clearSelection () { this.setSelectedBlocks(-1, -1) }
  setSelectedBlocks (a = this.selectionStartIndex, b = 0) {
    if (a === b) {
      return this.document.blocks.forEach(b => b.isSelected(false))
    }
    const [start, end] = [a, b].sort((a, b) => a - b)
    for (const block of this.document.blocks) {
      const afterStart = block.orderIndex() >= start
      const beforeEnd = block.orderIndex() <= end
      block.isSelected(afterStart && beforeEnd)
    }
  }

  indexOfMouseOver (target: EventTarget) {
    const $data = ko.dataFor(target as HTMLElement)
    return $data && $data.block && $data.block.orderIndex()
  }

  @computed({}) // {} disables deferred
  private blockListHTML () {
    return this.document.blocks
      .map(s => ko.ignoreDependencies(() => this.blockHTML(s)))
  }

  get slotPickerPopoverHTML () {
    const { jss } = this
    const rect = this.computed(() => this.variableFloatingMenu()?.rect)
    const choices = this.computed(() => this.variableFloatingMenu()
      && <slot-choices variable={this.variableFloatingMenu().variable} />)

    const showing = this.computed({
      read: () => Boolean(this.variableFloatingMenu()),
      write: v => v || this.variableFloatingMenu(null),
    })

    return (
      <floating-popup my='top left' at='bottom left' class={jss.slotPickerFloat}
        showing={showing}
        anchor={rect}
        content={choices} />
    )
  }

  get template () {
    const { jss } = this
    const after = () => setTimeout(() => [...this.document.blocks][0].setFocus(), 5)

    return (
      <div class={jss.canvas}
        canvas=''
        ko-descendantsComplete={after}
        ko-event={this.eventHandler.handlers}
        ko-ownClick={evt => this.onCanvasClick(evt)}
        ko-grip-area={{ onItemMove: (args) => this.onGripEnd(args)}}>
        {this.formattingBarPopoverHTML}
        {this.commandMenuPopoverHTML}
        {this.slotPickerPopoverHTML}
        {this.blockListHTML}
      </div>
    )
  }
}

WritingDocumentEditor.register()
