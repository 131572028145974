
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class ExtraJurisdictionalRegistration extends UnitView {
  get elvValue () {
    return `${this.entity_name()} ${this.type()} ${this.jurisdiction()}`
  }

  get fields () {
    return {
      jurisdiction: 'string',
      entity_name: 'string',
      type: 'string',
      registration_number: 'string',
      agent: 'person',
      registered: 'pureDate',
      revoked: 'pureDate',
      deregistered: 'pureDate',
      // annual report due
    }
  }

  * getRoles (prop) {
    if (prop === 'agent') {
      yield { title: 'Extra-Jurisdictional Registration Agent' }
    }
  }
}

export default class ExtraJurisdictionalRegistrationsComponent extends ArrayComponent {
  get ItemClass () { return ExtraJurisdictionalRegistration }
}

ExtraJurisdictionalRegistrationsComponent.register()
