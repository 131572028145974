
export default class Notifier {
  constructor () {
    Object.assign(this, {
      outcomeList: ko.observableArray([]),
      progressList: ko.observableArray([]),
      alertList: ko.observableArray([]),
      activityInProgress: ko.observable(),
      activity: ko.observable()
    })

    window.addEventListener('beforeunload', evt => this.beforeUnload(evt))
  }

  /**
   * Capture before the window/document unload events and if there are
   * unsaved changes or possibility of dataloss, indicate this to the user.
   *
   * We can check `activityInProgress` and `progressList` to determine
   * if something is working.
   *
   * Note that we cannot customize the string, as documented here:
   *
   * https://developers.google.com/web/updates/2016/04/chrome-51-deprecations?hl=en#remove_custom_messages_in_onbeforeunload_dialogs
   */
  beforeUnload (evt) {
    // ⭐️ this message will only show in older browsers.
    if (this.progressList.length || this.activityInProgress()) {
      evt.preventDefault()
      const oldBrowserMessage = `Changes you made may not be saved.`
      return (evt.returnValue = oldBrowserMessage)
    }
  }

  /**
   * Outcomes are messages that dismiss automatically.
   * @param {String|Observable.<String>} message
   */
  pushOutcome (message, options = {}) {
    const { icon, actions, style } = options
    this.outcomeList.push({ message, icon, actions, style })
  }

  /**
   * Progress indicators show percentage complete; dismisses when pct >= 1.
   * @param {String|Observable.<String>} message
   * @param {Observable.<Number>} percent 0-1 (1=100%)
   */
  pushProgress (message, percent, options = {}) {
    const { icon, actions } = options
    this.progressList.push({ message, percent, icon, actions })
  }

  /**
   * Alerts must be dismissed manually.
   * They are only here to capture the concept
   * @param {String|Observable.<String>} message
   */
  pushAlert (message, { icon, actions }) {
    /* ——— TODO ——— */
  }

  /**
   * Indicate to the user that there is background activity occurring.
   * @param {String} working message to show when working
   * @param {String} complete message to show when complete
   * @param {String} fail message to show when an error occurs
   * @param {Promise} promise when complete change the message to `complete`
   */
  registerActivity (working, complete, fail, promise) {
    this.activityInProgress(true)
    this.activity(working)
    promise
      .then(() => {
        this.activity(complete)
        Promise.delay(2000).then(() => this.activity(''))
      })
      .catch(() => this.activity(fail))
      .finally(() => this.activityInProgress(false))
  }

  /**
   * Visually indicate that a blocking activity is occurring e.g. a database
   * rewrite. Typical message would be "Don't close the browser ..."
   * @param {Promise} promise
   * @param {string} title
   * @param {jsx-able} content of the modal
   *
   * NOTE: This used to be in `<minute-box-app>` but it was broken out here
   * for future use in anticipation that it might be a precedent for a
   * future case.
   */
  // async indicateBlockingActivity (promise, title = '', content = '') {
  //   const { jss } = this
  //   window.app.modal(
  //     <modal-dialog modalTitle={title}>
  //       <template slot='content'>
  //         <loading-spinner class={jss.loadingIndicator} />
  //         {content}
  //       </template>
  //     </modal-dialog>
  //   )

  //   try {
  //     return await promise
  //   } finally {
  //     window.app.modal(undefined)
  //   }
  // }
}
