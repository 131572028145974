
type DataModel = import('./DataModel').default

interface DataModelExport {
  exportVersion: string
  resource: string
  project: string
  id: string
  userAccountID: string
  modelAccountID: string
  dated: iso8601
  data: any
}

const exportVersion = '1.0.0'

export function exportDataModel<T extends DataModel> (model: T): DataModelExport {
  const am = window.app.defaultAuthManager
  return {
    exportVersion,
    resource: model.vmResourceName,
    project: am.projectID,
    id: model.id(),
    modelAccountID: model.accountID(),
    userAccountID: am.accountID(),
    dated: new Date().toISOString(),
    data: model.vmCurrentRepr(),
  }
}

