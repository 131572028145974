
import ViewComponent from 'ViewComponent'

import headingIcon from 'icons/light/heading'
import moveDownIcon from 'icons/light/level-down-alt'
import moveUpIcon from 'icons/light/level-up-alt'
import orderedListIcon from 'icons/light/list-ol'
import paragraphIcon from 'icons/light/paragraph'
import quoteIcon from 'icons/light/quote-right'
import selectIcon from 'icons/light/i-cursor'
import subHeadingIcon from 'icons/light/h2'
import unorderedListIcon from 'icons/light/list-ul'

import { buttons, theme, color } from 'styles'
import { stringCommandAsRange } from 'utils/editable'
import { computed } from 'utils/decorator'

import { getBlockInstanceForSelection } from '../blocks'
import { possibleVariables } from '../variables'
import { signatureCommandSubmenu } from '../commands/signatureCommands'
import { precedentCommandSubmenu } from '../commands/precedentCommands'
import { Command, CommandMenu } from '../commands'
import { ActionCommand, TitleCommand } from '../commands/Command'

type BodyBlock = import('../blocks/BodyBlock').default
type Block = import('../blocks/Block').default
type WritingDocumentEditor = import('./writing-document-editor').default


export default class WritingCommandMenu extends ViewComponent {
  wde: WritingDocumentEditor
  menu: CommandMenu
  commandFilter: KnockoutObservable<string>
  commandKeyUp: KnockoutObservable<KeyboardEvent>

  constructor ({ wde }) {
    super()
    const { commandFilter, commandMenu, commandKeyDown } = wde
    Object.assign(this, {
      wde,
      commandFilter,
      commandKeyDown,
    })
    this.subscribe(this.commandMenuSlashEvent, this.onCommandMenuSlashEvent)
    this.subscribe(commandKeyDown, this.handleCommandKeyDown)

    this.menu = commandMenu
    if (!this.menu.size) { this.menu.add(...this.rootMenuItems) }
    this.menu.anchorTo(this)
  }

  get commandMenuSlashEvent () { return this.wde.commandMenuSlashEvent }
  onCommandMenuSlashEvent () { this.menu.resetToTopMenu() }
  hideCommandMenu () { this.commandMenuSlashEvent(null) }

  handleCommandKeyDown (evt: KeyboardEvent) {
    const { key } = evt
    if (key === null) { return }
    if (key.length === 1) {
      this.commandFilter.modify(f => f + key)
      return
    }
    switch (key) {
      case 'Enter':
        this.onEnter(evt)
        break
      case 'Backspace':
        if (this.commandFilter().length) {
          this.commandFilter.modify(f => f.slice(0, -1))
        } else {
          this.hideCommandMenu()
        }
        break
      case 'Escape':
        this.hideCommandMenu()
        evt.preventDefault()
        evt.stopPropagation()
        break
      case 'ArrowDown':
        this.selectNext()
        evt.preventDefault()
        evt.stopImmediatePropagation()
        break
      case 'ArrowUp':
        this.selectPrev()
        evt.preventDefault()
        evt.stopImmediatePropagation()
        break
      case 'ArrowRight':
        this.onArrowRight(evt)
        break
      case 'ArrowLeft':
        this.onArrowLeft(evt)
        break
    }
  }

  onArrowRight (evt: KeyboardEvent) {
    if (this.menu.triggerOnArrowRight()) {
      evt.preventDefault()
      evt.stopPropagation()
    }
  }

  onArrowLeft (evt: KeyboardEvent) {
    if (this.menu.popSubmenu()) {
      evt.preventDefault()
      evt.stopPropagation()
    } else {
      this.hideCommandMenu()
    }
  }

  onEnter (evt: KeyboardEvent) { this.triggerCommand(evt) }

  private triggerCommand (evt: MouseEvent | KeyboardEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    const range = stringCommandAsRange()
    const cmd = this.menu.triggerSelected()
    if (cmd) {
      evt.preventDefault()
      evt.stopPropagation()
      if (cmd.triggerHidesMenu) { this.wde.document.clearSlashCommand(range) }
    }
  }

  selectNext () { this.menu.selectNext() }
  selectPrev () { this.menu.selectPrev() }

  static get css () {
    return {
      ...super.css,
      commandMenu: {
        backgroundColor: color.systemBackground.light.primary,
        display: 'flex',
        flexDirection: 'column',
        margin: '2px',
        borderRadius: 3,
        maxWidth: 'calc(100vw - 24px)',
        minWidth: '270px',
        boxShadow: 'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
        maxHeight: '280px',
        overflowY: 'scroll',
      },
      noCommandItems: {
        fontSize: '90%',
        padding: '12px',
      },
      commandGroup: {
        backgroundColor: color.systemBackground.light.primary,
        textTransform: 'uppercase',
        boxShadow: '0px 1px 0 0 rgba(0,0,0,0.1), 0px -1px 0 0 rgba(0,0,0,0.1)',
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 5,
        marginBottom: 5,
        fontSize: '0.8em',
        fontWeight: 600,
      },
      fillIn: {
        ...buttons.clickable,
        display: 'grid',
        gridTemplateColumns: '26px auto auto 5px',
        padding: '5px 10px',
        borderRadius: 3,
        alignItems: 'center',
        border: '1px dashed rgba(0,0,0,0.5)',
        margin: '3px 6px',
        backgroundColor: 'rgba(0,0,0,0.05)',
        transition: 'background-color 120ms ease-in 0s',
        '&[selected=true]': {
          backgroundColor: 'aquamarine',
          transition: 'background-color 120ms ease-in 0s',
        },
      },
      commandItem: {
        '&[selected=true]': {
          backgroundColor: '#4a90e2',
          color: 'white',
        },
        ...buttons.clickable,
        display: 'flex',
        padding: '5px 10px',
        borderRadius: 0,
        alignItems: 'center',
      },
      commandIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '25px',
      },
      commandText: {
        composes: [theme.primaryText],
        textTransform: 'capitalize',
        '& u': {
          background: '#00fa9a',
          textDecoration: 'none',
          fontWeight: 500,
        },
      },
      commandKey: {
        composes: [theme.tertiaryText],
        fontSize: '0.7em',
        paddingLeft: 15,
      },
      fillInMore: {
        marginLeft: 'auto',
      },
    }
  }

  get rootMenuItems (): Command[] {
    return [
      this.commandGroup('Block Options'),
      this.commandItem(selectIcon, 'Select'),
      this.commandItem(moveUpIcon, 'Move Section Up', `⌥ + ↑`,
        () => this.wde.moveUp()),
      this.commandItem(moveDownIcon, 'Move Section Down', `⌥ + ↓`,
        () => this.wde.moveDown()),
      this.commandGroup('Styles'),
      this.commandNewBlock(paragraphIcon, 'Paragraph', 'p'),
      this.commandNewBlock(orderedListIcon, 'Numbered paragraph', 'np'),
      this.commandNewBlock(unorderedListIcon, 'List', 'l'),
      this.commandNewBlock(unorderedListIcon, 'Bullet', 'b'),
      this.commandNewBlock(headingIcon, 'Heading', 'h'),
      this.commandNewBlock(subHeadingIcon, 'Sub-heading', 'h2'),
      // this.commandNewBlock(definitionIcon, 'Definition'),
      this.commandNewBlock(quoteIcon, 'Quotation', 'q'),
      this.commandGroup('Fill-Ins'),
      ...possibleVariables(),
      precedentCommandSubmenu(this.wde.document),
      signatureCommandSubmenu(this.wde.document),
    ]
  }

  highlightedText (cmd: Command, filterString: string): string | JSX {
    const matches = cmd.matches(filterString)
    return matches
      ? matches.map((t, i) => i % 2 ? <u>{t}</u> : t)
      : cmd.text
  }

  menuItemHTML (cmd: Command): JSX   {
    const { jss } = this
    const scrollTo = this.computed(() => this.menu.isSelecting(cmd)
      ? ({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
      : null)
      // .extend({ rateLimit: 15 })
      .extend({ deferred: true })

    const html = cmd.html(jss, this.highlightedText(cmd, this.commandFilter()))

    return (
      <div class={cmd.commandClass(jss)}
        selected={this.computed(() => this.menu.isSelecting(cmd)).extend({ deferred: true })}
        ko-event={{
          mousemove: () => this.menu.setSelection(cmd),
          mousedown: (_, evt) => this.triggerCommand(evt),
        }}
        ko-scrollTo={scrollTo}>
        {html}
      </div>
    )
  }


  commandItem (icon, text, key = '', action = () => {}) {
    return new ActionCommand(text, icon, action)
  }

  commandGroup (title): Command {
    return new TitleCommand(title)
  }

  commandNewBlock (icon, text: string, code: string, key = '') {
    const action = () => {
      const block = getBlockInstanceForSelection() as Block|BodyBlock
      if (!block.body() || block.body() === `/${this.commandFilter()}`) {
        this.wde.document.changeBlockTo(code)
      } else {
        this.wde.document.addBlockAfterCurrent(code)
      }
    }
    return this.commandItem(icon, text, key, action)
  }

  @computed()
  commandItemsMatching (): JSX {
    const items = this.menu.visibleCommands()
    return items.length
      ? items.map(cmd => ko.ignoreDependencies(() => this.menuItemHTML(cmd)))
      : this.noCommandItemsHTML
  }

  get noCommandItemsHTML () {
    return (
      <div class={this.jss.noCommandItems}>No items match</div>
    )
  }

  get template () {
    const { jss } = this
    const items = this.computed(() => this.commandItemsMatching())
    return (
      <div class={jss.commandMenu}>
        {items.extend({ deferred: true })}
      </div>
    )
  }
}

WritingCommandMenu.register()
