
import ViewComponent from 'ViewComponent'
import { list, color, buttons } from 'styles'
import 'inline-section'
import 'confirm-delete/confirm-section-delete'
import icons from 'icons'
import gripIcon from 'icons/icon-handle'
import hellip from 'icons/light/ellipsis-h'
import inferredConfirmIcon from 'icons/light/check-circle'
import inferredIgnoreIcon from 'icons/light/times-circle'
import { SectionInference } from 'inferences/SectionInferenceGenerator'
import { computed } from 'utils/decorator'

type Section = import('EntityModel/components/Section').default

class MinuteBookSectionsList extends ViewComponent {
  inferredSections: KnockoutObservableArray<SectionInference>
  sections: KnockoutObservableArray<Section>
  bookComponent: BookComponent
  jumpToPage: (number) => void

  constructor ({
    bookComponent, uploadAction, downloadSection, shareSection,
    sections, onSectionMove, jumpToPage, sectionOfCurrentPage,
    showSectionPageNumbers, panelProvider, inferredSections,
  }) {
    super()
    Object.assign(this, {
      bookComponent, panelProvider,
      uploadAction, downloadSection, shareSection,
      onSectionMove, sections, jumpToPage, sectionOfCurrentPage, showSectionPageNumbers, inferredSections,
    })
  }

  static get css () {
    return {
      ...super.css,
      ...list.grippy,
      ...this.inferredCSS,
      ...this.sectionCSS,
    }
  }

  static get sectionCSS () {
    return {
      listItem: {
        ...list.grippy.listItem,
        margin: '1px',
        fontWeight: '400',
      },

      pageNumber: {
        fontSize: '0.1rem',
        backgroundColor: color.gray.d,
        color: color.grey.a,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.tertiary,
          color: color.text.dark.primary,
        },
        padding: '2px 7px',
        borderRadius: 9,
        marginLeft: '10px',
        '[show-section-page-numbers=false] &': {
          display: 'none'
        }
      },

      action: {
        ...list.grippy.action,
        marginLeft: 0,
      },

      noSectionsHTML: {
        ...buttons.clickable,
        fontSize: '0.9rem',
        textAlign: 'center'
      },

      sectionText: {
        width: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    }
  }


  static get inferredCSS () {
    return {
      inferredListItem: {
        ...this.sectionCSS.listItem,
        paddingLeft: '30px',
      },
      inferredHoverable: {
        ...list.grippy.hoverable,
        backgroundColor: 'pink',
        '&:hover': {
          backgroundColor: 'orange',
        },
      },
      inferredTitle: {
        ...list.grippy.title,
      },
      inferredPageNumber: {
        ...this.sectionCSS.pageNumber,
      },
      _inferredButton: {
        padding: '0px 6px',
      },
      inferredIgnore: {
        extend: '_inferredButton',
        '--icon-color': 'yellow',
        '&:hover': {
          '--icon-color': 'red',
        },
      },
      inferredConfirm: {
        extend: '_inferredButton',
        '--icon-color': 'yellow',
        '&:hover': {
          '--icon-color': 'red',
        },
      },
    }
  }

  inferredSectionHTML (section: SectionInference) {
    const { jss } = this
    const confirmInference = () => {
      this.bookComponent.addOrUpdateSection(section.pageNumber, section.sectionTitleText)
      this.inferredSections.remove(section)
    }

    return (
      <div class={jss.inferredListItem}>
        <div class={jss.inferredHoverable}
          ko-ownClick={() => this.jumpToPage(section.pageNumber)}
        >
          <div class={jss.inferredTitle}>
            {section.sectionTitleText}
          </div>
          <div class={jss.inferredPageNumber}> {section.pageNumber} </div>
          <div class={jss.inferredIgnore}
            ko-ownClick={() => this.inferredSections.remove(section)}>
            {icons.inline(inferredIgnoreIcon)}
          </div>
          <div class={jss.inferredConfirm}
            ko-ownClick={confirmInference}>
            {icons.inline(inferredConfirmIcon)}
          </div>
        </div>
      </div>
    )
  }

  sectionHTML (section: Section | SectionInference) {
    const { jss } = this
    if ('pageID' in section) { return this.inferredSectionHTML(section) }
    const isSelected = this.computed(() => (this.sectionOfCurrentPage() === section) || undefined)

    return (
      <div class={jss.listItem} grip-selected={isSelected}>
        <div class={jss.grip} grip=''>{ gripIcon }</div>
        <div class={jss.hoverable}
          ko-click={() => this.jumpToPage(section.startPage())}
          ko-dblclick={() => section.editing(true)}>
          <div class={jss.title}>
            <inline-section
              value={section.name}
              inputClass={''}
              textClass={jss.sectionText}
              dblClickToEdit={1}
              editing={section.editing} />
          </div>
          <div class={jss.pageNumber}> {section.pageNumber} </div>
          <div class={jss.action}>
            {this.actionMenuHTML(section, section.editing)}
          </div>
        </div>
      </div>
    )
  }

  deleteSection (section) {
    global.app.modal(
      <modal-dialog modalTitle={`Delete section ${section.name()}`}>
        <template slot='content'>
          <confirm-section-delete
            section={section}
            onConfirm={() => this.sections.remove(section)}
            onFinish={() => global.app.modal(undefined)} />
        </template>
      </modal-dialog>
    )
  }

  openUploadModal (section) {
    window.app.modal(
      <pdf-upload-modal
        book={this.bookComponent}
        section={section}
        uploadAction={this.uploadAction}
        cancelAction={this.uploadCancelAction}
      />)
  }

  actionMenuHTML (section, renaming) {
    const { jss } = this
    const isESR = this.computed(() => this.panelProvider.model.isProjection() || undefined)
    return (
      <drop-down my='left top' at='top right'>
        <template slot='anchor'>
          <div class={jss.actionButton}>
            {icons.inline(hellip)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.actionMenu}>
            <div class={jss.actionItem} ko-hidden={isESR} ko-click={() => renaming(true)}>
              Rename
            </div>
            <div class={jss.actionItem} ko-hidden={isESR} ko-click={() => this.openUploadModal(section)}>
              Upload...
            </div>
            <div class={jss.actionItem} ko-hidden={isESR} ko-click={() => this.shareSection(section)}>
              Share...
            </div>
            <div class={jss.actionItem} ko-click={() => this.downloadSection(section)}>
              Download
            </div>
            <async-button faceClass={jss.actionItem} ko-hidden={isESR}
              action={() => this.deleteSection(section)}>
              <template slot='face'>
                <div class={jss.actionItemFace}>
                  Delete...
                </div>
              </template>
            </async-button>
          </div>
        </template>
      </drop-down>
    )
  }

  get noSectionsHTML () {
    return (
      <div class={this.jss.noSectionsHTML} />
    )
  }

  realAndInferredSections () {
    const sections = this.sections()
    const confirmed = new Set(sections.map(p => p.pageNumber()))
    return [
      ...sections,
      ...this.inferredSections().filter(i => !confirmed.has(i.pageNumber)),
    ].sort((a, b) => ko.unwrap(a.pageNumber) - ko.unwrap(b.pageNumber))
  }

  @computed()
  listItems () {
    return this.sections.length
      ? this.realAndInferredSections().map(s => this.sectionHTML(s))
      : this.noSectionsHTML
  }

  /**
   * There are two moves here:
   * 1. the sections move from the fromIndex => toIndex
   * 2. the pages of the sections move accordingly as well
   */
  onDragDrop ({ fromIndex, toIndex }) {
    this.onSectionMove(fromIndex, toIndex)
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.list}
        show-section-page-numbers={this.showSectionPageNumbers}
        ko-grip-area={result => this.onDragDrop(result)}>
        {this.listItems}
      </div>
    )
  }
}

MinuteBookSectionsList.register()
