
import { isSameDay } from 'date-fns/esm'

import ViewComponent from 'ViewComponent'
import { buttons, calendar, color } from 'styles'
import { hashHSL } from 'utils/string'

class CalendarDay extends ViewComponent {
  date: Date

  constructor ({ date, events }) {
    super()
    Object.assign(this, {
      date,
      events
    })
  }

  static get css () {
    return {
      monthDayEvent: {
        ...buttons.clickable,
        ...calendar.event,
        borderRadius: '2px',
        padding: '0px 4px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        overflow: 'hidden',
        marginBottom: 2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        boxShadow: '10px 4px 16px transparent',
        transition: 'box-shadow 0.2s ease-in-out',
        '&:hover': {
          transition: 'box-shadow 0.2s ease-in-out',
          boxShadow: '4px 4px 16px rgba(0,0,0,0.2)',
          whiteSpace: 'normal'
        },
      },
      monthDayDate: {
        display: 'flex',
        justifySelf: 'flex-start',
        fontSize: '0.7rem',
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
        position: 'relative'
      },
      today: {
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
        color: 'black',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      object: {
        color: 'black',
        display: 'inline',
        paddingRight: '4px',
        fontSize: '0.6em',
        position: 'relative',
        top: '-6px',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      reason: {
        fontSize: '0.7em',
        fontWeight: '300',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      event0: {

      },
      restList: {
        backgroundColor: color.systemBackground.light.primary,
        padding: 6,
        borderRadius: '4px',
        color: color.text.light.primary,
      },
      more: {
        fontSize: '0.5rem',
        color: color.text.light.primary,
        borderRadius: 12,
        textShadow: '0px 1px 0px white',
        backgroundColor: '#aee9f782',
        width: 50,
        textAlign: 'center',
        marginLeft: 'auto',
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
          textShadow: '0px 1px 0px transparent',
        },
      }
    }
  }

  moreHTML (count) {
    return count > 0
      ? <div class={this.jss.more}>+{count} more</div>
      : null
  }

  dayEvent (e) {
    const { jss } = this
    const setColor = id => ({ '--event-color': hashHSL(id) })

    return (
      <div class={jss.monthDayEvent}
        title={e.object + ' / ' + e.reason}
        column-id={e.identifier}
        ko-style-map={setColor(e.identifier)}
        ko-ownClick={e._openObject}>
        <div class={jss.reason}>{e.reason}</div>
        <div class={jss.object}>{e.object}</div>
      </div>
    )
  }

  get dayEvents () {
    const { jss } = this
    const [e0, ...erest] = this.events
    const e0elv = this.computed(() => e0 && this.dayEvent(e0))
    const restList = this.computed(() => erest.length
      ? (
        <div class={jss.restList}>
          {erest.map(e => this.dayEvent(e))}
        </div>
      )
      : null
    ).extend({ deferred: true })

    return (
      <tool-tip my='top right' at='bottom right'>
        <template slot='anchor'>
          <div class={jss.event0}>
            {e0elv} {this.moreHTML(erest.length)}
          </div>
        </template>
        <template slot='content'>
          {restList}
        </template>
      </tool-tip>
    )
  }

  get template () {
    const { jss } = this
    const eventItems = this.computed(() => this.dayEvents)
      .extend({ deferred: true })

    const today = isSameDay(this.date, new Date()) ? 'Today' : ''

    return (
      <div>
        <div class={jss.monthDayDate}>
          {'' + this.date.getDate()}
          <span class={jss.today}>{today}</span>
        </div>
        {eventItems}
      </div>
    )
  }
}

CalendarDay.register()
