
type DataModel = import('../DataModel').default

//
// MediatorInterface
// ---
//
export default abstract class MediatorInterface<T, U, Z = U> {
  /**
   *
   * @param {string} name
   * @param {DataModel} Model
   */
  constructor (public name: string, public model: DataModel) { }

  abstract newHolderInstance (): T
  abstract fromJS (value: U)
  // toJS synchronously returns values for comparing against prior changes.
  abstract toJS (): U

  // Serialize is an asynchronous serializer for generating the values
  // for saving.
  serialize (): MaybePromise<Z> { return this.toJS() as Z }
  get value () { return ko.unwrap(this.model[this.name]) }
  get ignore () { return false }

  /**
   * @param {object} params
   * @return {any} value of this object.
   */
  async projectionValue () { return this.serialize() }

  /**
   * Possibly async hook called from `vmSave` before the model saves.
   * @param {object} params
   * @param {object} params.before serializable value since last save
   * @param {CrudModel} params.model
   * @return {undefined|Promise}
   */
  beforeModelSave (params) { return }
  /**
   * Possibly async hook called from `vmSave` after the model has saved.
   * @param {object} params
   * @param {object} params.before serializable value since last save
   * @param {object} params.after current serializable value
   * @param {CrudModel} params.model
   * @return {undefined|Promise}
   */
  afterModelSave (params) { return }
}
