import PanelHead from 'MinuteBookPanelProvider/panel-head'
import 'views/button-over-text'
import viewIcon from 'icons/regular/window'
import formatIcon from 'icons/solid/font'
import fillinsIcon from 'icons/solid/magic'
import exportIcon from 'icons/solid/file-download'
import docIcon from 'icons/solid/file'
import slotIcon from 'icons/solid/bullseye'


import tocIcon from 'icons/custom/iconToc'

type PrecedentPanelProvider = import('./PrecedentPanelProvider').default
type ButtonDropDownItem = import('views/button-drop-down').ButtonDropDownItem

export default class PrecedentPanelHead extends PanelHead<PrecedentPanelProvider> {
  get entityDisplayValue () {
    return `Precedents / ${this.panelProvider.document.implicitTitle}`
  }

  get buttonRow () {
    const { commands } = this.panelProvider.commandSet
    return (
      <>
        <button-drop-down my='top left' at='bottom left'
          text='View' icon={viewIcon} items={this.viewItems}
        />
        <div style='display: flex'>
          <button-over-text icon={formatIcon} text='Format' iconColor='orange'
            action={() => commands.toggleFormatting.trigger()}
          />
          <button-over-text icon={fillinsIcon} text='Fill-ins' iconColor='red'
            action={() => commands.toggleFillins.trigger()}
          />
          <button-over-text icon={slotIcon} text='Slots'
            action={() => commands.toggleSlots.trigger()} />
        </div>
        <div style='display: flex; justify-self: flex-end'>
          <button-over-text icon={docIcon} text='Document'
            action={() => commands.toggleDocOptions.trigger()} />
          {this.exportButtonsHTML}
        </div>
      </>
    )
  }


  get exportButtonsHTML () {
    const { commands } = this.panelProvider.commandSet
    const items: ButtonDropDownItem[] = [
      { text: 'Microsoft Word (.docx)', action: () => commands.convertToDocx.trigger() },
      { text: 'PDF Document (.pdf)', action: () => commands.convertToPdf.trigger() },
      { text: 'Markdown (.md)', action: () => commands.convertToMarkdown.trigger() },
    ]
    return (
      <button-drop-down my='top right' at='bottom right'
        icon={exportIcon} text='Export' items={items}
      />
    )
  }

  get viewItems (): ButtonDropDownItem[] {
    const { showToC } = this.panelProvider
    return [
      this.viewToggleItem('Table of Contents', tocIcon, showToC),
    ]
  }
}

PrecedentPanelHead.register()
