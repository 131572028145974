/**
 * This is a single place to edit the text that is displayed on the calendar
 * event elements for the generic array component types.
 */

type ReminderTextMap = Record<string, (any) => Record<string, string>>

const personName = (person: PersonRecord) => person.name[0]

export default {
  alternate_names: () => ({
    registered: `Alias registered`,
    expiry: `Alias expires`,
  }),
  'extrajurisdictional_registrations': ({jurisdiction, expiry, identifier, agent, registered}) =>
    ({
      registered: `Extra-Juristictional Name for ${jurisdiction} registered`,
      expiry: `Extra-Juristictional Name for ${jurisdiction} expires`,
    }),

  'trade_names': ({name, identifier, expiry, jurisdiction, registered}) =>
    ({
      registered: `Trade Name ${name} registered`,
      expiry: `Trade Name ${name} expires`,
    }),

  'regulatory_licenses': ({description, expiry, registered, renewal}) =>
    ({
      registered: `Regulatory License registered`,
      renewal: `Regulatory License renewal`,
      expiry: `Regulatory License expiry`,
    }),

  'trademarks': ({description, status, agent, expiry, renewal, jurisdiction}) =>
    ({
      expiry: `Trademark expires`,
      renewal: `Trademark renewal`,
    }),

  'patents': ({description, number, status, agent, expiry, jurisdiction}) =>
    ({
      expiry: `Patent ${number} expires`,
    }),

  'land_registrations': ({aquired, disposed, legal_description, municipal_address, registrar, pin, roll_number}) =>
    ({
      acquired: `Land registration acquired`,
      disposed: `Land registration disposed`,
    }),

  'municipal_licenses': ({municipality, description, expiry, registered, renewal}) =>
    ({
      registered: `Municipal business license registered`,
      renewal: `Municipal busniess license renewal`,
    }),

  'ca_ppsas': ({description, type, creditor, debtor, note, expiry, jurisdiction}) =>
    ({
      expiry: `PPSA expiry`,
    }),

  'domain_names': ({domain, registrar, expiry}) =>
    ({
      expiry: `Domain Name expiry`,
    }),

  'directors': ({person, residency, elected, resigned}) =>
    ({
      elected: `Director ${personName(person)} elected`,
      resigned: `Director ${personName(person)} resigned`,
    }),

  'officers': ({person, title, residency, elected, resigned}) =>
    ({
      elected: `Officer ${personName(person)} elected`,
      resigned: `Officer ${personName(person)} resigned`,
    }),

  governing_documents: ({title, start, end}) => ({
    start: `${title} in force`,
    end: `${title} expired`,
  }),

  'imports': ({origin, date}) =>
    ({
      date: `Import date`,
    }),
} as ReminderTextMap
