import { Heading, SubHeading } from './Heading'
import Bullet from './Bullet'
import List from './List'
import NumberedParagraph from './NumberedParagraph'
import Paragraph from './Paragraph'
import Quote from './Quote'
import { ArrayComponentSignatures, CapitalSignatures } from './Signatures'

import { BlockConstructor } from './Block'

export type BlockTypes = Bullet | Heading | List | NumberedParagraph | Paragraph | Quote | SubHeading | ArrayComponentSignatures | CapitalSignatures


const Blocks = [
  Bullet,
  Heading,
  List,
  NumberedParagraph,
  Paragraph,
  Quote,
  SubHeading,
  ArrayComponentSignatures,
  CapitalSignatures,
]

const blocksByCode = Blocks.map(B => ({ [B.prototype.code]: B }))

export const blockRegister = Object.assign({}, ...blocksByCode) as Record<string, BlockConstructor>

export type TYPES = keyof typeof blockRegister

export function getBlockClass (type: string): BlockConstructor {
  return blockRegister[type]
}

export function factory (type: TYPES, data = {}) {
  return new blockRegister[type](data)
}
