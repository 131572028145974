
import DataComponent from './DataComponent'

export default class DeprecatedComponent extends DataComponent {
  _value: any
  static get namespace() { return '' }
  get () {
    console.error(`The property "${this.identifier}" is deprecated.`)
  }
  set (value) { this._value = value }
  toJS () { return this._value }
}

DeprecatedComponent.register()
