/**
 * Detection routines for authentication management
 */
import { DRIVERS } from 'projects/projects'

import AuthManager from './AuthManager'

// console.log(`drivers`, DRIVERS)

export function getPossibleAuthManagers () {
  try {
    return [new AuthManager(DRIVERS[window.location.hostname])]
  } catch (err) {
    console.error(`

        Error configuring project settings for ${window.location.hostname}.

    `, err)
    throw err
  }
}
