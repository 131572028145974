import { default as RightView } from './RightView'

/**
 *          ——— Notes ———
 */
export default class MinuteBookRightViewNotes extends RightView {
  bookComponent: import('EntityModel').BookComponent
  listOfNotes: import('notes-list/ListOfNotes').default

  constructor (params) {
    const { bookComponent, listOfNotes } = params
    super(params)
    Object.assign(this, { bookComponent, listOfNotes })
  }

  get title () { return 'Notes' }
  get body () {
    return (
      <notes-list
        bookComponent={this.bookComponent}
        listOfNotes={this.listOfNotes} />
    )
  }
}
MinuteBookRightViewNotes.register()
