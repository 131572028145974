import { debounce } from 'lodash-es'

import { color } from 'styles'

import sanitize from 'utils/sanitize'
import { setCaret,focusLastEditableRange } from 'utils/editable'

import BodyBlock from '../blocks/BodyBlock'
import { bindVariables } from '../blocks/bindings'
import { BLOCK_SYM } from '../blocks/Block'
import { MenuTriggerEvent } from '../writing-views/events'

import BlockEditor from './BlockEditor'

import './binding-wvar'

type Block = import('../blocks').Block

/**
 * A <block-editor...> type that contains `ContentEditable` content.
 *
 */
export default abstract class BodyBlockEditor<T extends BodyBlock> extends BlockEditor<T> {

  static get css () {
    return {
      ...super.css,
      input: {
        margin: '4px 4px 1em 4px',
        lineHeight: '1.5em',
        minHeight: '1.5em',
        outline: 'none',
        display: 'block',
        width: '100%',
        '&[text-align=left]': {
          textAlign: 'left',
        },
        '&[text-align=right]': {
          textAlign: 'right',
        },
        '&[text-align=center]': {
          textAlign: 'center',
        },
        '&[text-align=justify]': {
          textAlign: 'justify',
          //text-justify
        },

        '&:focus::before': {
          display: 'block',
          content: `var(--body-block-placeholder)`,
          color: color.text.light.tertiary,
        },

        /**
         * @daniel
         * Until we solve the cursor-hidden-behind-variable problem, this
         * makes it easier to find the cursor.
         * @brian fine on local but we cannot commit this as we have demos to show
         */
        '&:focus': {
          //backgroundColor: '#EEE',
        },
      },
    }
  }

  onMenuTrigger () { this.editor.showRootMenu() }

  private processingInputMutex = false
  makeEditable () {
    const { jss } = this

    const input = debounce((_, evt) => this.onNeedsSave(evt), 5)

    const updateText = v => {
      if (this.processingInputMutex) { return }
      this.editableNode().innerHTML = v || ''
      this.bindBodyVariables()
    }

    this.computed(() => {
      const node = this.editableNode()
      if (!node) { return }
      const { wantsFocus } = this.block
      if (!wantsFocus) { return }
      node.scrollIntoView({ block: 'center', behavior: 'smooth' })
      setTimeout(() => {
        setCaret(node, wantsFocus.atEnd)
        this.block.isFocused()
      }, 5)
    })

    this.subscribe(this.hasFocus, v => this.block.onFocus(v))

    this.editableNode
      .yet(undefined)
      .then(node => {
        node[BLOCK_SYM] = this.block
        updateText(this.block.body())
        this.block.body.subscribe(updateText)
      })

    const style = this.computed(() =>
      `--body-block-placeholder: "${this.block.body() ? ``: this.block.placeholderText}"`,
    )

    return (
      <div contenteditable='true' class={jss.input}
        style={style}
        text-align={this.block.align}
        ko-set-node={this.editableNode}
        ko-event={{
          input,
          needssave: (_, evt) => this.onNeedsSave(evt),
          variableinjection: () => this.onVariableInjection(),
          menutrigger: (_, evt) => this.onMenuTrigger(evt),
        }}
        ko-hasFocus={this.hasFocus}
      />
    )
  }

  onNeedsSave (evt: KeyboardEvent | MouseEvent) {
    const element = evt.target as Element
    if (!element) { return }
    sanitize(element, true)
    this.processingInputMutex = true
    this.block.body(element.innerHTML)
    this.processingInputMutex = false
  }

  onVariableInjection () {
    this.bindBodyVariables()
    this.editableNode().dispatchEvent(new Event('input'))
  }

  bindBodyVariables () {
    bindVariables(this.editableNode(), this.slotManager, this.editor)
    focusLastEditableRange()
  }

  get internalHTML () {
    return this.makeEditable()
  }
}
