import ViewComponent from 'ViewComponent'

import icons from 'icons'
import squareIcon from 'icons/light/square'
import checkSquareIcon from 'icons/light/check-square'
import eyeSlashIcon from 'icons/light/eye-slash'
import filterIcon from 'icons/light/filter'
import sortIcon from 'icons/light/sort-amount-down'
import fileIcon from 'icons/light/file-alt'
import wrenchIcon from 'icons/light/wrench'
import printSearch from 'icons/light/print-search'

import { buttons, color, dropdown, typography } from 'styles'
import './model-list-conditions'
import './model-sorters'
import handleIcon from 'icons/icon-handle'
import { noop } from 'rxjs';
import Column from 'Column'
import { arrayMove } from 'utils/array'

/**
 * <model-list-options> is the UI for modifying a list of models, including:
 *
 * - sort order
 * - filtering
 * - fields/columns hidden
 * - report generation.
 */
export default class ModelListOptions extends ViewComponent {
  showHideFilterText: KnockoutObservable<string>
  hideMenuItems: Column[]
  visibleItems: KnockoutComputed<Column[]>
  private generateCSV: () => void

  constructor ({ options, hideMenuItems, savedFilters, generateReportClick, generateCSV }, ...args) {
    super({}, ...args)
    Object.assign(this, {
      generateReportClick,
      generateCSV,
      hideMenuItems: hideMenuItems || options.columns,
      savedFilters,
      conditions: options.conditions,
      sorters: options.sorters,
      columns: options.columns,
      panelID: options.panelID,
      nuansName: ko.observable(''),
      nuansResults: ko.observable(''),
      showHideFilterText: ko.observable<string>(),
    })

    this.visibleItems = this.computed(() => (
      [...this.hideMenuItems]
      .filter(c => c.title)
      .filter(c =>
        !this.showHideFilterText()
        || c.title.toLowerCase().includes(this.showHideFilterText().toLowerCase()))
    ))
  }

  button (icon: string, text: string, isActive?: KnockoutObservable<boolean>|KnockoutComputed<boolean>) {
    const { jss } = this
    const buttonCss = isActive ? this.computed(
      () => isActive() ? jss.buttonActive : jss.button) : jss.button
    return (
      <div class={buttonCss}>
        <span class={jss.iconResponsiveHiddenWhenNarrow}>{icons.button(icon)}</span>
        <span class={jss.responsiveHiddenWhenNarrow}>{ text }</span>
      </div>
    )
  }

  static get buttonCSS () {
    return {
      button: {
        ...dropdown.button,
        width: 'max-content',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
          fill: color.dark.toolBarIcon
        },
        '&:hover': {
          backgroundColor: color.fill.light.primary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.primary,
            color: color.text.dark.primary,
          },
        }
      },
      buttonActive: {
        ...dropdown.button,
        backgroundColor: color.primaryButtonHoverBg,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.secondary,
          color: color.text.dark.primary,
        },
      },
      buttonsList: {
        display: 'flex',
      }
    }
  }

  get showHideMenuTopHTML () {
    const { jss } = this
    return (
      <div class={jss.showHideMenuTop} ko-click={evt => evt.stopPropagation()}>
        <div class={jss.showHideSearch}>
          <input type='search' ko-textinput={this.showHideFilterText} placeholder='Find a field' />
        </div>
      </div>
    )
  }

  get showHideMenuBottomHTML () {
    const { jss } = this
    const showHideAll = v => () => {
      this.visibleItems().forEach(c => c.show(v))
    }
    const allVisibleAre = v => (
      this.visibleItems().every(c => c.show() === v)
    )
    const classesFor = v => this.computed(() => (
      `${jss.button} ${allVisibleAre(v) ? jss.buttonActive : ''}`
    ))
    return (
      <div class={jss.showHideMenuBottom}>
        <div class={classesFor(false)} ko-ownClick={showHideAll(false)}>
          Hide All
        </div>
        <div class={classesFor(true)} ko-ownClick={showHideAll(true)}>
          Show All
        </div>
      </div>
    )
  }

  get showHideMenu () {
    const { jss } = this
    const columnSelectors = this.computed(() => (
      this.visibleItems().map(c => this.showHideSelectorHTML(c))
    ))
    return (
      <div class={jss.showHideMenu}>
        {this.showHideMenuTopHTML}
        <div class={jss.showHideMenuList}
          ko-grip-area={results => this.onFieldDragDrop(results)}>
          {columnSelectors}
        </div>
        {this.showHideMenuBottomHTML}
      </div>
    )
  }

  onFieldDragDrop ({ fromIndex, toIndex }) {
    arrayMove(this.hideMenuItems, fromIndex, toIndex)
  }

  /**
   * @param {Column} column
   */
  showHideSelectorHTML (column, index) {
    const { jss } = this

    const color = this.computed(() =>
      column.color
        ? {
          '--selector-color': column.color,
          '--border-color': '#dbdbdb',
        } : {} )

    const selected = this.computed(() => column.show()
      ? jss.showHideShowing
      : jss.showHideHiding)

    const icon = this.computed(() => icons.button(column.show()
      ? checkSquareIcon : squareIcon))

    const toggle = evt => {
      evt.stopPropagation()
      evt.preventDefault()
      column.show.modify(v => !v)
    }

    return (
      <div class={jss.showHideSelector} ko-click={toggle}
        ko-style-map={color}>
        <div class={jss.showHideHandleIcon} grip=''>
          {handleIcon}
        </div>
        <div class={jss.showHideSelectorIndicator}>
          <div class={selected}> {icon} </div>
        </div>
        <div class={jss.showHideSelectorName}>{column.title}</div>
      </div>
    )
  }

  static get showHideMenuCSS () {
    return {
      showHideMenu: {
        ...dropdown.functionalMenu,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0px',
        backgroundColor: 'white',
        minWidth: '250px',
      },

      showHideMenuList: {
        maxHeight: '65vh',
        overflow: 'auto',
    },

      showHideMenuTop: {
        display: 'flex',
        alignItems: 'center',
      },

      showHideSearch: {
        padding: '5px 15px',
        flexGrow: 1,
        '& > input': {
          height: 36,
          width: '100%',
          borderRadius: '4px',
          backgroundColor: color.grey.searchbg,
          border: 'unset',
          fontFamily: typography.bodyFontFamily,
          fontSize: '14px',
          padding: '0px 10px',
          '&:focus': {
            outline: 'none',
          },
          'body[dark] &': { // project batman
            backgroundColor: color.textInput.dark.secondary,
            border: `0.5px solid ${color.separator.dark.nonOpaque}`,
            color: color.text.dark.secondary,
          },
        }
      },

      showHideMenuBottom: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
          flexGrow: 1,
        },
      },

      showHideSelector: {
        ...dropdown.item,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '3px 15px 3px 5px',
        transition: '160ms',
        '&:hover': {
          backgroundColor: 'white',
          '& $showHideSelectorName': {
            backgroundColor: color.rowHoverColor,
            'body[dark] &': { // project batman
              backgroundColor: color.dark.rowHoverColor,
            },
          }
        },
        '&[gripping]': {
          opacity: 0
        },
      },

      showHideSelectorIndicator: {
        padding: '0px 2px'
      },

      showHideHandleIcon: {
        '--icon-color': color.secondaryButton,
        visibility: 'hidden',
        padding: '0px 8px',
        cursor: 'grab',
        '$showHideSelector:hover &': {
          visibility: 'visible'
        }
      },

      showHideIcon: { },

      showHideShowing: {
        extend: 'showHideIcon',
        color: 'black'
      },

      showHideHiding: {
        extend: 'showHideIcon',
        color: 'black'
      },

      showHideSelectorName: {
        marginLeft: '12px',
        width: '100%',
        padding: '4px 6px 4px 8px',
        borderRadius: '4px',
        border: '1px solid var(--border-color, rgba(0,0,0,0))',
        borderLeft: '4px solid var(--selector-color, rgba(0,0,0,0))',
      },

      // attempt to make SVG have responsive padding
      iconResponsiveHiddenWhenNarrow: {

        '@media (max-width: 820px)': {
          paddingRight: '0px',
          '& svg': {
            paddingRight: '0px',
            color: 'red'
          }
        }
      },

      responsiveHiddenWhenNarrow: {

        '@media (max-width: 820px)': {
          display: 'none',
        }
      }
    }
  }

  /**
   * Shows all the options within the tools menu
   * @readonly
   * @memberof ModelListOptions
   */
  get showToolsMenu () {
    const { notifier } = global.app

    const nuansAction = evt => {
      evt.stopPropagation()
      evt.preventDefault()
      try {
        this.nuansHTML()
      } catch (err) { noop() }
    }

    const genReports = async () => {
      const upload = ''
      var progress = ko.observable(0)

      const message = ko.pureComputed(() => (
        <span>
          <span>Generating report for download.</span>
          <upload-progress upload={upload} />
        </span>
      ))
      notifier.pushProgress(message, progress)
      await this.generateReportClick()
      progress(1)
    }

    const { jss } = this
    return (
      <div class={jss.showToolsMenu} ko-grip-area={''}>
        <div class={jss.toolsPointer} ko-click={nuansAction}><span>{icons.button(printSearch)}</span>Name Search</div>
        <div class={jss.toolsPointer} ko-click={genReports}><span>{icons.button(fileIcon)}</span>Generate Report</div>
        <div class={jss.toolsPointer} ko-click={this.generateCSV}><span>{icons.button(fileIcon)}</span>Generate CSV</div>
      </div>
    )
  }

  static get showToolsMenuCSS () {
    return {
      showToolsMenu: {
        ...dropdown.functionalMenu,
        padding: '0',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        marginTop: '0px',
        backgroundColor: 'white',
      },
      toolsPointer: {
        ...dropdown.item,
        display: 'grid',
        gridTemplateColumns: '30px 1fr',
        padding: '6px 25px 6px 15px',
        '&:hover': {
          backgroundColor: color.rowHoverColor,
          'body[dark] &': { // project batman
            backgroundColor: color.dark.rowHoverColor,
          }
        },
      },
      showHideSelector: {
        ...dropdown.item,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '3px 15px 3px 5px',
        transition: '160ms',
        '&:hover': {
          backgroundColor: 'white',
          '& $showHideSelectorName': {
            backgroundColor: color.rowHoverColor,
            'body[dark] &': { // project batman
              backgroundColor: color.dark.rowHoverColor,
            },
          }
        },
        '&[gripping]': {
          opacity: 0
        },
      },
      showToolsSelectorName: {
        marginLeft: '12px',
        width: '100%',
        padding: '4px 6px 4px 8px',
        borderRadius: '4px',
        border: '1px solid var(--border-color, rgba(0,0,0,0))',
        borderLeft: '4px solid var(--selector-color, rgba(0,0,0,0))',
        cursor: 'pointer',

      },

      // attempt to make SVG have responsive padding
      iconResponsiveHiddenWhenNarrow: {

        '@media (max-width: 820px)': {
          paddingRight: '0px',
          '& svg': {
            paddingRight: '0px',
            color: 'red'
          }
        }
      },

      responsiveHiddenWhenNarrow: {

        '@media (max-width: 820px)': {
          display: 'none',
        }
      }
    }
  }

  /**
   * Calls the nuans search firebase function
   * returns the {{ limit }} results of the nuans search (if exists)
   * if there is an exact/uppercase match of the direct term, it puts it on top of the output
   * Delay allows enough time for the nuansName() observable to be updated
   */
  async nuansSearch () {
    await Promise.delay(250)
    var output = ''
    var i = 0
    var limit = 7
    var nuansName = this.nuansName()

    var nuansList = await window.app.defaultAuthManager.firebaseFn('nuansSearch', { name: nuansName })
    var indexFound = nuansList.indexOf(nuansName)
    if (indexFound !== -1) {
      output += nuansList[indexFound] + '\n'
      nuansList = nuansList.slice(0, indexFound).concat(nuansList.slice(indexFound + 1))
      limit -= 1
    } else {
      indexFound = nuansList.indexOf(nuansName.toUpperCase())
      if (indexFound !== -1) {
        output += nuansList[indexFound] + '\n'
        nuansList = nuansList.slice(0, indexFound).concat(nuansList.slice(indexFound + 1))
        limit -= 1
      }
    }

    while (i < limit && nuansList.length > i) {
      output += nuansList[i] + ' \n'
      i++
    }
    if (output.length === 0) { output = 'No results for ' + nuansName }
    return output
  }

  /**
   * HTML for Nuans Name search, press enter to search
   * showResults -> if the user has searched something, it will show the results in seperate container underneath
   * showLoading -> while the get request/parsing is happening it shows the loading icon
   */
  get nuansHTML () {
    const { jss } = this
    var working = ko.observable(false)
    var showResults = this.computed(() => (this.nuansResults() && this.nuansResults() !== ' ') ? jss.nuansShowResults : jss.nuansHideResults)
    const nuansSearchClick = async (test) => {
      this.nuansResults(' ')
      var call = await this.nuansSearch()
      this.nuansResults(call)
      working(false)
    }
    const nuansClose = async () => {
      working(false)
      this.nuansResults(' ')
      window.app.modal(undefined)

    }
    const workingButton = async () => {
      working(true)
      nuansSearchClick()
    }

    return (
      window.app.modal(
        <modal-dialog modalTitle='Name Search'>
          <template slot='content'>
            <div class={jss.showNuans}>
              <div>
                <input autofocus={1}
                  class={jss.searchInput}
                  type='text'
                  ko-value={this.nuansName}
                  ko-keydown={{ Enter: workingButton }}
                />
                <div class={jss.buttonContainer}>
                  <async-button faceClass={jss.cancelButton}
                    action={nuansClose}>
                    <template slot='face'>
                      <div class={jss.actionItemFace}>
                        Cancel
                      </div>
                    </template>
                  </async-button>
                  <async-button faceClass={jss.searchButton}
                    action={nuansSearchClick}
                    working={working}>
                    <template slot='face'>
                      <div class={jss.actionItemFace}>
                        Search
                      </div>
                    </template>
                  </async-button>
                </div>
              </div>
            </div>
            <div class={showResults}>
              <pre class={jss.nuansResultsText}>{this.nuansResults}</pre>
            </div>
          </template>
        </modal-dialog>
      )
    )
  }

  static get showNuansCSS () {
    return {
      showNuans: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        backgroundColor: 'white',
        height: '145px',
        width: '450px',
        textAlign: 'center',
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
          borderBottom: '1px solid black',
          color: 'white',
        },
      },
      searchInput: {
        width: '400px',
        height: '40px',
        textAlign: 'center',
        margin: '0 auto',
        borderRadius: 0,
        border: 0,
        outline: 'none',
        borderBottom: '1px solid black',
        fontSize: '1.1em',
        backgroundColor: 'inherit',
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
          borderBottom: '1px solid white',
          color: 'white',
        },
      },
      nuansShowResults: {
        width: '430px',
        margin: '10px 0 0 10px',
        position: 'absolute',
        backgroundColor: '#ffd502',
        height: '190px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        transition: 'all 0.2s ease-in',
        overflow: 'auto',
      },
      nuansHideResults: {
        width: '430px',
        margin: '10px 0 0 10px',
        position: 'absolute',
        backgroundColor: '#ffd502',
        height: '0px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        transition: 'all 0.2s ease-out',
      },
      nuansResultsText: {
        marginLeft: '30px',
        fontFamily: 'roboto-slab, open-sans, sans-serif',
        fontWeight: '700',
        color: 'black',
        transition: 'all 0.5s ease-in',
      },
      buttonContainer: {
        gridColumn: '1/-1',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10px'
      },
      searchButton: {
        ...buttons.modalOk,
      },
      cancelButton: {
        ...buttons.modalCancel,
      },
    }
  }

  get template () {
    const { jss } = this

    return (
      <div class={jss.buttonsList}>
        {this.listOfButtonsHTML}
      </div>
    )
  }

  get listOfButtonsHTML () {
    return [
      this.commonButtonsHTML,
      this.toolsButtonHTML,
    ].flat()
  }

  get commonButtonsHTML () {
    const hideCount = this.computed(() => (
      this.hideMenuItems.filter(c => c.title && !c.show()).length
    ))
    const hideText = this.computed<string>(() => {
      return hideCount()
        ? `${hideCount()} Hidden Field${hideCount() > 1 ? 's' : ''}`
        : 'Hide Fields'
    })

    return (
      <>
        <drop-down my='top left' at='bottom left' button='mlo-hide-fields'>
          <template slot='anchor'>
            {this.button(eyeSlashIcon, hideText, () => hideCount())}
          </template>
          <template slot='content'>{this.showHideMenu}</template>
        </drop-down>
        <drop-down my='top left' at='bottom left'
          button='mlo-filter-fields'>
          <template slot='anchor'>
            {this.button(filterIcon, 'Filter Fields',
              () => this.conditions().length)}
          </template>
          <template slot='content'>
            <model-list-conditions
              columns={this.columns}
              panelID={this.panelID}
              conditions={this.conditions}
              savedFilters={this.savedFilters} />
          </template>
        </drop-down>
        <drop-down my='top left' at='bottom left'
          button='mlo-sort-fields'>
          <template slot='anchor'>
            {this.button(sortIcon, 'Sort Fields',
              () => this.sorters().length > 1)}
          </template>
          <template slot='content'>
            <model-sorters
              columns={this.columns}
              sorters={this.sorters} />
          </template>
        </drop-down>
      </>
    )
  }

  get toolsButtonHTML () {
    return (
      <drop-down my='top left' at='bottom left'
        button='mlo-tools-fields'>
        <template slot='anchor'>
          {this.button(wrenchIcon, 'Tools')}
        </template>
        <template slot='content'>{this.showToolsMenu}</template>
      </drop-down>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.buttonCSS,
      ...this.showHideMenuCSS,
      ...this.showToolsMenuCSS,
      ...this.showNuansCSS,
    }
  }
}

ModelListOptions.register()
