
const MAX_BATCH_SZ = 500

/**
 * Iterate over the given iterator, yielding the batch that
 * each item may belong to.  This works around the 500-item
 * limit Firestore imposes on batched commits.
 *
 * The consumer must commit the batches.  Example:
 *
 *   batches = new Set()
 *   for (const [value, batch] of batched(iter)) {
 *      batches.add(batch)
 *      batch.set(...)
 *   }
 *   for (const b of batches) { await b.commit() }
 *
 */
export function * batched (firestore, iterator) {
  let count = 0
  let batch = firestore.batch()
  for (const v of iterator) {
    yield [v, batch]
    if (++count >= MAX_BATCH_SZ) {
      count = 0
      batch = firestore.batch()
    }
  }
}

let globalOpId = 0
/**
 * Perform `operation` on every `iterable` with a Firestore batch
 * that is not maxed out (i.e. > the 500 Firestore operation limit).
 *
 * @param {Firestore} firestore
 * @param {Iterable} iterable
 * @param {operation}
 *
 * ! This function expects `operation` to perform at-most one
 *   batch operation
 */
export async function batchedOperation (firestore, iterable, operation) {
  const opId = ++globalOpId
  console.groupCollapsed(`Batch Operation #${opId} 🔽🔽🔽`)

  const batchIter = batched(firestore, iterable)

  const opFutures = []
  const batches = new Set()
  for (const [value, batch] of batchIter) {
    opFutures.push(operation(value, batch))
    batches.add(batch)
  }

  console.groupEnd()
  const opMsg = `Batch Operation #${opId}: ${opFutures.length} ops, ${batches.size} batches.`

  if (opFutures.length !== 0) {
    console.debug(`⏰ ${opMsg}: Awaiting Operations.`)

    try {
      await Promise.all(opFutures)
    } catch (err) {
      console.error(`🚨 ${opMsg} Operation failure:`, err)
      throw err
    }

    console.debug(`⏰ ${opMsg}: Awaiting Commits.`)

    try {
      await Promise.all([...batches].map(b => b.commit()))
    } catch (err) {
      console.error(`🚨 ${opMsg} Commit fail:`, err)
      throw err
    }
  }

  console.info(`🏁 ${opMsg}: Complete.`)
}

/**
 * Convert the given parts into a Firstore Key Path e.g.
 *  `keyPathForModel('demo', 'abc', 'entity')` =>
 *    `/accounts/demo/entity/abc`
 *
 * Use `intra` for sub-parts e.g.
 *    `/accounts/demo/entity/abc/writing/123`
 */
export function keyPathForModel (accountID: string, keyID: string, ...intra: string[]): string {
  if (!intra.length) {
    throw new Error(`keyPathForModel expects at least a collection argument.`)
  }
  return _joinNonEmpty('accounts', accountID, ...intra, keyID)
}

export function keyPathForCollection (accountID: string, ...intra: string[]): string {
  return _joinNonEmpty('accounts', accountID, ...intra)
}


function _joinNonEmpty (...parts) {
  if (parts.some(p => !p)) {
    throw new Error(`Cannot join non-null parts: [${parts.join('|')}].`)
  }
  return parts.join('/')
}
