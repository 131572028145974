import BigInteger from 'big-integer'

/**
 * @param {string} b64 Base64 (URL variant)
 * @return {BigInteger}
 * See:
 *    https://tools.ietf.org/html/rfc4648#section-4
 *
 * Note padding re. implicit `=`:
 *    https://tools.ietf.org/html/rfc7515#appendix-C
 */
export function toBigInteger (b64: string) : BigIntType {
  const arr = [...atob(b64.replace(/_/g, '/').replace(/-/g, '+'))]
    .map(c => c.charCodeAt(0))
  return BigInteger.fromArray(arr, 256)
}

/**
 * @param {BigInteger} bi
 * @return {string} Base64 (URL variant)
 *
 * See: https://stackoverflow.com/questions/9267899
 */
export function fromBigInteger (bi: BigIntType, pad = 0): string {
  const arr = bi.toArray(256).value
  if (arr.length < pad) { arr.unshift(...Array(pad - arr.length).fill(0)) }
  return btoa(String.fromCharCode(...Uint8Array.from(arr)))
    .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

/**
 * Encode an ArrayBuffer to Base64url.
 * @param v
 * @param pad
 */
export function encodeToBase64 (v: ArrayBuffer, pad = 0): string {
  const arr = Array.from(new Uint8Array(v))
  if (arr.length < pad) { arr.unshift(...Array(pad - arr.length).fill(0)) }
  return btoa(String.fromCharCode(...Uint8Array.from(arr)))
    .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export function decodeFromBase64 (v: string) : Uint8Array {
  const arr = [...atob(v.replace(/_/g, '/').replace(/-/g, '+'))]
  return new Uint8Array(arr.map(c => c.charCodeAt(0)))
}

/**
 * Convert to a JSON-serializable object.
 * @param v any
 */
export function encodeObject (v: any) {
  if (ArrayBuffer.isView(v)) { return encodeObject(v.buffer) }
  if (Array.isArray(v)) { return v.map(v => encodeObject(v)) }
  if (v instanceof ArrayBuffer) { return encodeToBase64(v) }
  if (v instanceof Object) {
    const clone = {}
    for (const key in v) {
      clone[key] = encodeObject(v[key])
    }
    return clone
  }
  return v
}
