// import { ecGeneratePrivateKey } from 'utils/jwk'

const { subtle } = global.crypto

/**
 * The KeyMaster holds the users' keys.  It is also responsible
 * for selecting a key for decryption.
 */
export default class KeyMaster {
  constructor (userPublicKey) {
    Object.assign(this, { userPublicKey })
  }

  /**
   * @param {CryptoKey} privateKey for the user
   * @param {CryptoKey} publicKey for the secret
   */
  async deriveSharedKey ({ privateKey, publicKey }) {
    return subtle.deriveKey(
      { name: 'ECDH', public: publicKey },
      privateKey,
      { name: 'AES-KW', length: 256 },
      true,
      ['wrapKey', 'unwrapKey']
    )
  }

  // async deriveEncryptKey ({ privateKey, publicKey, plainKey }) {

  // }

  // async deriveDecryptKey ({ privateKey, publicKey, cipherkey }) {

  // }

  // /**
  //  * @param {Array.<key>} keySet the set of keys that can be use to get
  //  *  the content key.
  //  */
  // decryptDecryptionKey (keySet) {

  // }

  // /**
  //  * @param {model}
  //  */
  // publicKeysFor (model) {

  // }

  // generateUserKey () {

  // }

  // /**
  //  * @param {int} iterations
  //  * @param {string} secret
  //  * @return {CryptoKey} The `privateKey`
  //  */
  // async _adminMakeDerivationPrivateKey (d) {
  //   const key = await ecGeneratePrivateKey(d)
  //   return (await subtle.importKey('jwk', key)).privateKey
  // }

  // static get failsafeKeys () {

  // }

  // /**
  //  * @param {object} keys jwt-encoded keys.
  //  */
  // static set failsafeKeys (keys) {
  //   if (keys.length < 2) {
  //     throw new Error(`There must be 2 failsafe keys.`)
  //   }
  //   for (const key of keys) {

  //   }
  // }
}
