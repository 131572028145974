
type WritingDocument = import('./WritingDocument').default
type BodyBlock = import('./blocks/BodyBlock').default
type WritingModel = import('./WritingModel').default
type PrecedentModel = import('./precedents/PrecedentModel').default

export function wordCount (d: WritingDocument) {
  return d.blocks
    .filter(b => 'body' in b)
    .reduce((count, block: BodyBlock) =>
      count += block.asText.split(/\s+/).filter(f => f).length, 0)
}

export function charCount (d: WritingDocument) {
  return d.blocks
    .filter(b => 'body' in b)
    .reduce((count, block: BodyBlock) =>
      count += block.asText.length, 0)
}

export function modelTitle (model: PrecedentModel | WritingModel): string {
  const title = model.answerFor('title')()
  if (title) { return title }
  const writing = model.answerFor('writing') as WritingDocument
  return writing.implicitTitle || ''
}
