
import MinuteBookPanelProvider from 'MinuteBookPanelProvider'

import CommonModelColumns from 'Column/CommonModelColumns'
import { columnsByPanelID } from 'Column/panelListColumns'

import { color } from 'styles'
import icons from 'icons'
import faPlusSquare from 'icons/light/plus-square'

import 'drop-down'
import 'filters-list'

import RootListPanelProvider from './RootListPanelProvider'
import EntityListCommandSet from './EntityListCommandSet'

export default class EntityListPanelProvider extends RootListPanelProvider {
  get panelWindowTitle () { return 'Entity List' }
  get panelID () { return 'EntityList' }
  get historyUrl () { return '#entities' }
  get collectionID () { return 'entity' }
  get CommandSetConstructor () { return EntityListCommandSet }
  get peerPanelList () { return this.app.rootProviderList }
  actionsContentHTML (row) { return CommonModelColumns.entityActionsHTML(row, this.jss, this.app) }

  get foot () {
    const { jss } = this

    const hits = this.computed(() =>
      this.criteria.nbHits() === undefined ? 'Loading ' : ' ' + this.criteria.nbHits())
    const entities = this.computed(() =>
      this.criteria.nbHits() === 1 ? 'Entity' : 'Entities')

    return (
      <div class={jss.footContainer}>
        <div class={jss.footFlexBox}>
          <div class={jss.footContent}>
            <div class={jss.foot}>
              {hits}{' '}
              {entities}
            </div>
          </div>
        </div>
      </div>
    )
  }

  get addModelButton () {
    const { jss } = this
    return (
      <div class={jss.rootTopButton} ko-click={() => this.newEntityClick()}>
        {icons.button(faPlusSquare)}
        <span class={jss.responsiveHiddenWhenNarrow}>Add New Entity</span>
      </div>
    )
  }

  get possibleColumns () {
    return columnsByPanelID[this.panelID](this)
  }

  entityRow (entity) {
    const { jss } = this
    const entityType = entity.answerFor('entity_type')
    // const entityColor = tagColor(ko.unwrap(entityType))
    return (
      <div class={jss.entityType}>{entityType}</div>
    )
  }

  get nothingMatchesHTML () {
    const { jss } = this
    const query = this.query()
    const queryText = query ? `“${query}”` : ''
    const hasFilters = this.listOptions.conditions.length
    const filterText = hasFilters ? `the given filters` : ''
    const matchText = [queryText, filterText].filter(t => t).join(' and ')
    return (
      <div class={jss.nothingMatches}>
        {`Nothing matches ${matchText}.`}
      </div>
    )
  }

  get loadingHTML () {
    const { jss } = this
    return (
      <div class={jss.loadingEntities}>
        <loading-spinner />
      </div>
    )
  }

  get noEntitiesHTML () {
    const { jss } = this
    return (
      <div class={jss.noEntities}>
        <div class={jss.noEntitiesLead}>
          Say goodbye to physical minute books.
        </div>
        <div class={jss.noEntitiesToAdd}>
          To add your first Minute Book simply drag a PDF on here or click “Add New Entity” above.
        </div>
      </div>
    )
  }

  static get noTeamsCSS () {
    return {
      noTeams: {
        extend: '_mainMessage',
        padding: '30px'
      },
      noTeamsHeader:{
        fontSize: '2.6rem',
        fontWeight: 'bold',
        padding: '40px'
      },
      noTeamsSub: {
        fontSize: '1.3rem',
        textAlign: 'center',
        maxWidth: '500px'
      },
      noTeamsSub2: {
        fontSize: '1rem',
        textAlign: 'center',
        maxWidth: 'none',
        paddingTop: '20px'
      }

    }
  }

  /**
   * This is shown when
   *  1. the user has an account;
   *  2. the user is not an admin; and
   *  3. the user is not on any teams.
   */
  get noTeamsHTML () {
    const { jss } = this
    return (
      <div class={jss.noTeams}>
        <div class={jss.noTeamsHeader}>
          Expecting to see some entities?
        </div>
        <div class={jss.noTeamsSub}>
          You are not on any teams or have not been given permission to view any entities.
        </div>
        <div class={jss.noTeamsSub2}>
        Please contact your administrator and ask them to add you to a team that can view entities. If you believe this is an error, please contact <a href='mailto:support@minutebox.com'>MinuteBox support</a>.
        </div>
      </div>
    )
  }

  /**
   * There are a few reasons the entity list may be empty,
   * including:
   *  1. the user is homeless and not an admin
   *  2. nothing matches the current search query
   *  3. there are no entities in the database.
   */
  get noHitsHTML () {
    const authManager = this.app.defaultAuthManager
    return authManager.userIsHomeless
      ? this.noTeamsHTML
      : (this.query() || this.listOptions.conditions.length)
        ? this.nothingMatchesHTML
        : this.noEntitiesHTML
  }

  static get mainCSS () {
    return {
      _mainMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        height: 'var(--main-height)'
      },
      nothingMatches: {
        extend: '_mainMessage',
        fontStyle: 'italic',
        fontSize: '1.5rem',
        color: '#424242'
      },
      noEntities: {
        extend: '_mainMessage',
      },
      noEntitiesLead: {
        fontSize: '2.6rem',
        fontWeight: 'bold',
        padding: '40px'
      },
      noEntitiesToAdd: {
        color: color.gray.b,
        fontSize: '1.3rem',
        textAlign: 'center',
        maxWidth: '500px'
      },
      responsiveHiddenWhenNarrow: {
        '@media (max-width: 1000px)': {
          display: 'none',
          padding: '4px 4px 4px 4px'
        }
      }
    }
  }

  get main () {
    const { memoryDB } = this.app
    // Make it a bool so it doesn't re-render on hit-length change.
    const isLoading = this.computed(() => !memoryDB.indexIsLoaded('entity'))
      .extend({ rateLimit: 115 })
    // To style `loadingHTML`, uncomment the following line:
    // .extend({ rateLimit: 500000 })
    const isEmpty = this.computed(() => !this.criteria.hits.length)
    return this.computed(() => isLoading()
      ? this.loadingHTML
      : isEmpty()
        ? this.noHitsHTML
        : <model-list
          columnJss={this.jss}
          storageID={`panel.${this.panelID}`}
          class={this.jss.modelList}
          style='--model-list-offset-top: 59px'
          sorters={this.listOptions.sorters}
          columns={this.listOptions.columns}
          hits={this.criteria.hits}
          onSelect={e => this.selectEntity(e)}
          contextMenuForRow={row => this.actionsContentHTML(row)}
          commandSet={this.commandSet}
          hoverRow={this.hoverRow} />)
  }

  static get css () {
    return {
      ...super.css,
      ...CommonModelColumns.css,
      ...this.mainCSS,
      ...this.noTeamsCSS,
      ...this.fyeColumnCSS,
      ...this.elvCSS,
      entityType: {
        fontSize: '0.8rem',
        color: 'black',
        padding: '2px 0.5rem',
        borderRadius: '8px',
        backgroundColor: color.color.light.blueGrey,
        '&:empty': {
          display: 'none'
        },
        'body[dark] &': { // project batman
          backgroundColor: color.color.dark.blueGrey,
          color: color.text.dark.altPrimary
        },
      },
      footContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        left: 'calc(0px - var(--left-panel-default-width))',
      },
      footFlexBox: {
        display: 'flex',
        '--right-edge-allowance': '300px',
        width: 'calc(100vw - var(--right-edge-allowance))',
        height: '100%',
        position: 'sticky',
        left: '0px',
        alignItems: 'center',
        justifyContent: 'center',
      },
      footContent: {
        position: 'relative',
        left: 'calc(var(--right-edge-allowance) / 2)',
      },
      modelList: {
        /**
         * Style columns based on the "column-id" attribute of the
         * list of entities.
         *
         * To get a list of entity columns, in DevTools run:
         * >>> new Set([...document.querySelectorAll('[column-id]')].map(d => d.getAttribute('column-id')).sort())
         */
        '& [column-id=incorporation_date]': {
          color: color.gray.aa
        }
      },
      loadingEntities: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '30vh',
        '--icon-height': '48px'
      }
    }
  }

  static get fyeColumnCSS () {
    return {
      _fyeText: {
        color: color.text.light.primary,
        fontSize: '1em',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      fyeImmediate: {
        extend: '_fyeText',
      },
      fyeSoon: {
        extend: '_fyeText',
      },
      fyeLater: {
        extend: '_fyeText',
      },
      _label: {
        width: 60,
        height: 30,
        borderRadius: 6,
        display: 'flex',
        fontSize: '0.8em',
        justifyContent: 'center',
        alignItems: 'center',
        '&:empty': {
          display: 'none'
        }
      },
      daysToFyeImmediate: {
        extend: '_label',
        color: color.text.light.altPrimary,
        background: color.color.light.red,
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
          background: color.color.dark.red,
        },
      },
      daysToFyeSoon: {
        extend: '_label',
        color: color.text.light.primary,
        background: color.color.light.yellow,
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
          background: color.color.dark.yellow,
        },
      },
      daysToFyeLater: {
        extend: '_label',
        color: color.text.light.altPrimary,
        background: color.color.light.green,
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
          background: color.color.dark.green,
        },
      },
    }
  }

  /**
   * @param {EntityModel} entity
   */
  selectEntity (entity) {
    const app = this.app
    app.panelProvider(new MinuteBookPanelProvider({ app, model: entity }))
  }

  onFilterDelete (filterModel) {
    super.onFilterDelete(filterModel)
    // TODO: Update Team Filters here.
  }

  static get elvCSS () {
    return {
      elvMatterNumber: {
        background: '#4a90e2',
        padding: '3px 7px',
        fontSize: '0.8em',
        minWidth: 80,
        textAlign: 'center',
        borderRadius: 3,
        color: color.text.light.altPrimary,
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
          backgroundColor: color.color.dark.blue
        },
        '&:empty': {
          display: 'none'
        }
      }
    }
  }
}
