/**
 * PDF Engine for getting info from and manipulating PDFs.
 *
 * See: https://github.com/NetPleadings/MinuteBox/blob/8260e198fb4eb930a350a8b07ad3412877c51098/js.packages/pdf/kit/PDFKitEngine.ts
 */
import { set, get, groupBy } from 'lodash-es'
import { Mutexer, some } from 'utils/iterable'
import blankPDF from './blank'
import licenseKey from './licenseKey'

export class DisposedError extends Error { }

export interface PSPDFKitRect {
  top: number,
  left: number,
  bottom: number,
  right: number,
  width: number,
  height: number,
}

export interface PSPDFKitPageInfo {
  label: string,
  height: number,
  width: number,
}

export interface PSPDFKitSearchResult {
  pageIndex: number, // source PDF page number
  pageInfo: PSPDFKitPageInfo,
  rectsOnPage: Array<PSPDFKitRect>,
  previewText: string,
  lengthInPreview: number,
  locationInPreview: number,
}

type PDFUrlID = string
type PageNumber = number

type SearchCache = {
  query: string,
  results: Record<PDFUrlID, Record<PageNumber, Array<PSPDFKitSearchResult>>>
}

declare const PSPDFKit: any
type PSPDFKitInstance = {
  totalPageCount: number
  renderPageAsImageURL: (any) => any
}
type KitCallback<T> = (PSPDFKitInstance) => T

type ImportOperation = {
  type: "importDocument"
  afterPageIndex: number
  treatImportedDocumentAsOnePage: boolean
  document: Blob
}

/**
 * Number of concurrent instances of PSPDFKit to allow.
 */
const hardwareConcurrency = navigator.hardwareConcurrency || 1
const PARALLEL = Math.max(1, hardwareConcurrency - 1)
const renderMutex = new Mutexer<void>(PARALLEL)

const KitOptions = {
  licenseKey,
  headless: true,
  standaloneInstancesPoolSize: PARALLEL,
}

type QueuedKitFunction<T> = {
  fn: KitCallback<T>
  result: KnockoutObservable<T>
}
const QUEUE_DELAY = 50

if (window.PSPDFKit) {
  console.info(`Setting up ${PARALLEL} PDF worker processes.`)
  PSPDFKit.preloadWorker(KitOptions)
}


/**
 * The low-level PDF rendering and manipulation API.  Delegates to a
 * third party library.
 */
export class PDFEngine {
  bufferGetterMap: Record<string, Promise<ArrayBuffer>> = {}
  blobUrlsToDispose: Array<ImageURL> = []
  urlCache: Record<string, Record<number | string, Promise<ImageURL>>> = {}
  searchCache: SearchCache = { query: '', results: {} }
  functionsBatchedByBuffer: Map<ArrayBuffer, QueuedKitFunction<any>[]> = new Map()
  _flushMutex: boolean
  _disposed: boolean

  dispose () {
    this._disposed = true
    this.bufferGetterMap = null
    renderMutex.clear()
    this.blobUrlsToDispose.forEach(URL.revokeObjectURL)
    this.blobUrlsToDispose.length = 0
    this.urlCache = {}
    this.functionsBatchedByBuffer.clear()
  }

  async processBufferQueue (buffer: ArrayBuffer, queued: QueuedKitFunction<any>[]) {
    if (this._disposed || !queued.length) { return }
    console.log(`PDFEngine:
      Queued ${queued.length} PDF functions for buffer with ${buffer.byteLength} bytes`)
    const pdf = buffer.slice(0)
    const kit = await PSPDFKit.load({ pdf, ...KitOptions })
    let item
    while ((item = queued.shift())) {
      const { fn, result } = item
      if (this._disposed) { break }
      try {
        await Promise.resolve(fn(kit)).then(result)
      } catch (err) {
        console.error(`PSPDFKit Error:`, err, 'calling', fn.toString())
      }
    }
    PSPDFKit.unload(kit)
  }

  private async flushQueue () {
    if (this._flushMutex) { return }
    if (this._disposed) { return }
    this._flushMutex = true

    const kits = []
    const entries = [...this.functionsBatchedByBuffer.entries()]

    for (const [buffer, queued] of entries) {
      const isFinished = ko.observable(false)
      kits.push(isFinished)
      renderMutex.add(() =>
        this.processBufferQueue(buffer, queued).finally(() => isFinished(true)))
    }
    await Promise.all(kits.map(k => k.when(true)))
    this._flushMutex = false

    if (some(this.functionsBatchedByBuffer.values(), v => v.length)) {
      this.flushQueue()
    }
  }

  private async withKit<T> (buffer: ArrayBuffer, fn: KitCallback<T>, priority = false) : Promise<T> {
    const fns = this.functionsBatchedByBuffer.get(buffer) || []
    if (!this.functionsBatchedByBuffer.has(buffer)) {
      this.functionsBatchedByBuffer.set(buffer, fns)
    }
    const result = ko.observable<T>()
    fns[priority ? 'unshift' : 'push']({ fn, result })
    Promise.delay(QUEUE_DELAY).then(() => this.flushQueue())
    return result.yet(undefined)
  }

  async getPageCount (pdf) : Promise<number> {
    return this.withKit<number>(pdf, (k) => k.totalPageCount)
  }

  async getPdfForPageID (page: BookPage) : Promise<ArrayBuffer> {
    if (this._disposed) { throw new DisposedError() }
    const urlID = page.idUniqueToDownloadURL
    const buffer = urlID in this.bufferGetterMap
      ? this.bufferGetterMap[urlID]
      : (this.bufferGetterMap[urlID] = page.getPDFArrayBuffer())
    return buffer
  }

  async renderAsURL (page: BookPage, width: number = 1200, priority = false) : Promise<ImageURL> {
    return this.setOrGetCache<ImageURL>(page, 'w' + width, async () => {
      const url = ko.observable<ImageURL>(null)
      // console.log(`Rendering: ${page.pageID} [${width}]`)
      const pdf = await this.getPdfForPageID(page)
      const pageNo = page.pageNumberOfPDF
      this.withKit<ImageURL>(pdf,
        kit => kit.renderPageAsImageURL({ width }, pageNo).then(url), priority)
      const u = await url.yet(null)
      this.blobUrlsToDispose.push(u)
      return u
    })
  }

  /**
   * Extract the text content of the given BookPage.
   * @param page whose text to get.
   */
  async getTextLines (page: BookPage) : Promise<PDFTextLine[]> {
    return this.setOrGetCache<PDFTextLine[]>(page, 'textLines', async () => {
      const pdf = await this.getPdfForPageID(page)
      const lines = ko.observable<PDFTextLine[]>(null)
      this.withKit<any>(pdf,
        kit => kit.textLinesForPageIndex(page.pageNumberOfPDF)
          .then(l => this.kitLinesToPDFTextLines(l, page,
            kit.pageInfoForIndex(page.pageNumberOfPDF)))
          .then(lines)
      )
      return lines.yet(null)
    })
  }

  private kitLinesToPDFTextLines (lines: PDFKitTextLines, page: BookPage, pageInfo: any) {
    return [...lines.values()]
      .map(v => ({
        contents: v.contents,
        boundingBox: v.boundingBox,
        pageIndex: v.pageIndex,
        id: v.id,
        pageInfo,
        page
      }))
  }

  private async setOrGetCache<T> (page: BookPage, type: string, create: () => Promise<T> | T) {
    const cacheKeys = [page.pageID, type]
    const cache = get(this.urlCache, cacheKeys)
    if (cache) { return cache }

    const value = await create()
    set(this.urlCache, cacheKeys, value)
    return value
  }

  /**
   *
   * @param pages
   */
  async getText (page: BookPage) : Promise<string> {
    return this.setOrGetCache<string>(page, 'text', async () => {
      const lines = await this.getTextLines(page)
      return lines.map(l => l.contents).join(' ')
    })
  }

  /**
   * Return a PDF containing only the given pageIDs
   *
   * Documentation on PSPDFKit document editing:
   * - https://pspdfkit.com/guides/web/current/features/document-editor/
   * - https://pspdfkit.com/api/web/PSPDFKit.DocumentOperation.html
   */
  async compile (pages: BookPage[], process: Process) : Promise<Blob> {
    const stub = new TextEncoder().encode(blankPDF)
    const importOps = await this.pagesAsImportOperations(pages, process)
    const ops = async kit => kit.exportPDFWithOperations([
      ...importOps,
      { type: "removePages", pageIndexes: [0] },
    ]) as Promise<PDFArrayBuffer>

    try {
      const buffer = ko.observable<ArrayBuffer>(null)
      this.withKit<Promise<PDFArrayBuffer>>(stub.buffer, ops)
          .then(buffer)
      await buffer.yet(null)
      return new Blob([buffer()], { type: 'application/pdf' })
    } catch (err) {
      console.error(`Unable to compile PDF`, err)
      throw new Error(`Unable to compile.`)
    }
  }

  private async pagesAsImportOperations (pages: BookPage[], process: Process) : Promise<Array<ImportOperation>> {
    const bufferList = []

    for (const page of pages) {
      const buffer = await this.getPdfForPageID(page)
      const exportOp = {
        type: 'keepPages', pageIndexes: [page.pageNumberOfPDF],
      }
      const pageBuffer = ko.observable<ArrayBuffer>(null)
      const opFn = kit => {
        if (process.isCancelled()) { throw new Error(`Cancelled`) }
        return kit.exportPDFWithOperations([exportOp])
          .then(pageBuffer)
          .then(() => process.tick())
      }
      this.withKit<ArrayBuffer>(buffer, opFn)
      bufferList.push(pageBuffer)
    }

    await Promise.all(bufferList.map(b => b.yet(null)))
    return bufferList.map((buffer, afterPageIndex) => ({
      type: "importDocument",
      afterPageIndex,
      treatImportedDocumentAsOnePage: false,
      document: new Blob([buffer()]),
    }))
  }

  // Search in 100 page batches and cache the results
  async search (page: BookPage, query: string) : Promise<Array<PSPDFKitSearchResult>> {
    const cache = this.searchCache
    const batchSize = 100
    if (cache.query !== query) {
      cache.query = query
      cache.results = {}
    }
    let results = get(cache.results, [page.idUniqueToDownloadURL, page.pageNumberOfPDF])
    if (!results) {
      const startPageIndex = page.pageNumberOfPDF
      const endPageIndex = page.pageNumberOfPDF + batchSize
      const fullResults = await this._search(page, query, startPageIndex, endPageIndex)
      if (query !== this.searchCache.query) { return [] }
      const groupByPage = groupBy(fullResults, r => r.pageIndex)
      for (const [pageNumber, results] of Object.entries(groupByPage)) {
        set(cache.results, [page.idUniqueToDownloadURL, pageNumber], results)
      }
      for (let i = startPageIndex; i < endPageIndex; i++) {
        if (!get(cache.results, [page.idUniqueToDownloadURL, i]))
        set(cache.results, [page.idUniqueToDownloadURL, i], [])
      }
      results = get(cache.results, [page.idUniqueToDownloadURL, page.pageNumberOfPDF])
    }
    return results || []
  }

  async _search (page: BookPage, query: string, startPageIndex, endPageIndex) : Promise<Array<PSPDFKitSearchResult>> {
    const pdf = await this.getPdfForPageID(page)
    if (query !== this.searchCache.query) { return [] }
    const results = await this.withKit(pdf, async kit => kit.search(query, { startPageIndex, endPageIndex }))
    if (query !== this.searchCache.query) { return [] }
    return this.withKit(pdf, kit => (
      results.toArray().map(result => {
        const pageInfo = kit.pageInfoForIndex(result.pageIndex)
        const { pageIndex, previewText, lengthInPreview, locationInPreview } = result
        const rectsOnPage = result.rectsOnPage.toArray()
        return {
          pageIndex,
          previewText,
          lengthInPreview,
          locationInPreview,
          pageInfo,
          rectsOnPage,
        }
      })
    ))
  }

}
