
import CapitalState from './CapitalState'
import { Transaction } from './Transaction'

/**
 * A CapitalAction is an atomic operation on `capital`.
 */
export abstract class CapitalAction {
  static assetClass : string

  abstract applyTo (capital: CapitalState, transaction: Transaction)

  get description () : string {
    return `${this.constructor.name}`
  }
}

export default CapitalAction
