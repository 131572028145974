import { makePersonRecord } from './utils'

type PersonRecord = import('./interfaces').PersonRecord
type oPersonRecord = KnockoutObservable<PersonRecord>

/**
 * Keep a dynamic map of person records, from their id or name to
 * the respective PersonRecord.
 *
 * Use by Capital components.
 */
export default class PersonRecordSet {
  records: KnockoutObservableArray<oPersonRecord> = ko.observableArray([])

  constructor (persons: PersonRecord[] = []) {
    this.set(persons)
  }

  set (persons: PersonRecord[] = []) {
    for (const p of persons) { this.addRecord(p) }
  }

  createBlankRecord (initialValues) {
    return makePersonRecord(initialValues)
  }

  filter (pf: PersonFilter) : oPersonRecord[] {
    return this.records().filter(p => pf(p()))
  }

  getByName (name: string) : oPersonRecord[] {
    return this.filter(p => p.name.includes(name))
  }

  getByID (id: string) : oPersonRecord {
    return this.records().find(p => p().id === id)
  }

  addRecord (p: PersonRecord) : oPersonRecord {
    const ro = ko.observable(p)
    this.records.push(ro)
    return ro
  }

  /**
   * Return the first name of the given person.
   */
  getNameOf (idOrName) : string {
    const p = this.getByID(idOrName) || this.getByName(idOrName)[0]
    return p ? p().name[0] : idOrName
  }
}
