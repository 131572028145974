export default (
  <svg width='303px' height='303px' viewBox='0 0 303 303' version='1.1'>
    <title>MinuteBox is loading...</title>
    <desc>Please wait.</desc>
    <defs>
      <polygon id='path-1' points='0.176630435 112.115416 0.176630435 103.414625 15.8707298 101.383634 15.8707298 12.4932588 0.176630435 10.607339 0.176630435 1.76147732 1.05706522 0.891052817 31.7115683 2.34176032 30.6843944 57.0092549 31.7115683 111.680203 1.05706522 113.130911' />
      <polygon id='path-3' points='32.3412267 1.76147732 32.3412267 10.4622683 16.6471273 12.4932588 16.6471273 101.383634 32.3412267 103.269554 32.3412267 112.115416 31.4607919 112.98584 0.80628882 111.535133 1.82996894 56.8641841 0.80628882 2.19668957 31.4607919 0.745982067' />
    </defs>
    <g id='mb-loader' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
      <g id='mb-chat-bubble'>
        <g id='chat-bubble'>
          <circle id='Oval-2' fill='#FFD502' cx='151.40625' cy='151.40625' r='151.40625' />
          <g id='Group' transform='translate(102.000000, 95.000000)'>
            <g id='right-bracket' transform='translate(67.727582, 0.844584)'>
              <mask id='mask-2' fill='white'>
                <use href='#path-1' />
              </mask>
              <g id='Clip-8' />
              <polygon right-bracket='' id='Fill-7' fill='#FFFFFF' mask='url(#mask-2)' points='0.0962732919 113.227625 31.9596273 113.227625 31.9596273 0.486927155 0.0962732919 0.486927155' />
            </g>
            <g id='left-bracket' transform='translate(0.240780, 0.844584)'>
              <mask id='mask-4' fill='white'>
                <use href='#path-3' />
              </mask>
              <g id='Clip-5' />
              <polygon left-bracket='' id='Fill-4' fill='#FFFFFF' mask='url(#mask-4)' points='0.537267081 113.227625 32.4006211 113.227625 32.4006211 0.486927155 0.537267081 0.486927155' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
