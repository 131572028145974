import ViewComponent from "ViewComponent"
import { typography } from 'styles'

type JSXContent = any

export type Tab = {
  name : string
  content : JSXContent
}

export default class TabContainer extends ViewComponent {
  tabs : KnockoutObservableArray<Tab>
  activeTab : KnockoutObservable<Tab>

  constructor (params) {
    super()
    const { tabs } = params
    Object.assign(this, {
      tabs,
      activeTab: ko.observable(tabs[0])
    })
  }

  static get css () {
    return {
      grid: {
        display: 'grid',
        gridColumnGap: '6px',
      },
      tabspacer: { position: 'static' },
      tabCell: {
        '&:nth-last-child(2)': {
          marginRight: '20px',
        },
      },
      tab: {
        fontFamily: typography.titleFamily,
        padding: '5px 1em',
        color: '#4a4a4a',
        backgroundColor: 'white',
        borderRadius: '5px 5px 0 0',
        cursor: 'pointer',
        '--bshadow': '0px -3px 2px -2px rgba(0,0,0,0.1)',
        '$tabCell[active] &': {
          fontWeight: 'bold',
          position: 'relative',
          zIndex: '0',
          boxShadow: 'var(--bshadow)',
        },
        '&:hover': {
          boxShadow: 'var(--bshadow)',
        },
      },
      contentCell: { position: 'static' },
    }
  }

  makeTabHTML(tab : Tab) : JSXContent {
    const { jss } = this
    return (
      <div class={jss.tabCell}
        active={this.computed(() => this.activeTab() === tab || undefined)}
        ko-click={() => this.activeTab(tab)}>
          <div class={jss.tab}>{tab.name}</div>
      </div>
    )
  }

  get template () : JSXContent {
    const { jss } = this
    const gridStyle = this.computed(() =>
      `grid-template-columns: 1fr repeat(${this.tabs.length}, auto)`
    )
    const contentStyle = this.computed(() =>
      `grid-column: span ${this.tabs.length + 1}`
    )
    return (
      <div class={jss.grid} style={gridStyle}>
        <div class={jss.tabspacer} />
        { this.tabs.map(tab => this.makeTabHTML(tab)) }
        <div class={jss.contentCell} style={contentStyle}>
          { this.computed(() => this.activeTab().content) }
        </div>
      </div>
    )
  }
}

TabContainer.register()
