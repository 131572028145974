
import 'minute-box-app/panel-left/section-list-panel-left'

import { JSXContent, ViewID } from './types'
type LeftPanelSection = import('./LeftPanelSection').default
type PanelProvider = import('PanelProvider').default

export default abstract class LeftPanelView {
  abstract get id () : ViewID
  abstract get title () : JSXContent
  abstract get main () : JSXContent
  abstract get sections () : KnockoutObservableArray<LeftPanelSection>

  active: KnockoutObservable<boolean> = ko.observable(true)
  loading: KnockoutObservable<boolean> = ko.observable(false)

  command: any = { // TODO Stub for actual Command
    trigger: evt => this.panelProvider.activeView && this.panelProvider.activeView(this)
  }

  get subMenuItems () {
    return this.sections ? <section-list-panel-left sections={this.sections} /> : undefined
  }

  get collapsed () {
    return this._collapsed || (this._collapsed = ko.observable(true))
  }

  subMenuStyleMap: Record<string, string> = null
  jss: {
    subMenuShown: any,
    subMenuHidden: any,
  } & any

  constructor (public panelProvider : PanelProvider) {
    this.jss = panelProvider.jss
    panelProvider.computed(() => {
      const av = ko.unwrap(panelProvider.activeView)
      return !av || !av.id || av.id === this.id
    }).subscribe(this.active)
  }
}
