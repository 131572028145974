/**
 * Slurp from the database and search records in-memory.
 *
 * We somewhat mimic the Algolia API so that we retain compatibility with that
 * option.
 */
import { orderBy } from 'lodash-es'

type Sorter = import('model-list/Sorter').default

const DEBOUNCE = { rateLimit: { timeout: 150, method: 'notifyWhenChangesStop ' } }

/**
 *      MemorySearchCriteria
 *
 *      Search all the in-memory indexes.
 */
export default class MemorySearchCriteria<T = any> {
  query: KnockoutObservable<any>
  sorters: KnockoutObservableArray<Sorter>
  hits: KnockoutObservableArray<T>
  modelList: KnockoutComputed<T>

  // For future pagination.
  page: KnockoutObservable<number>
  nbHits: KnockoutObservable<number>
  nbPages: KnockoutObservable<number>
  pageSize: number

  constructor (memoryDB, indexName) {
    Object.assign(this, {
      page: ko.observable(0),
      hits: ko.observableArray([])
        .extend({ inAnimationFrame: true })
        .extend({ proxy: 'length' }),
      nbHits: ko.observable(),
      exhaustiveNbHits: ko.observable(true),
      nbPages: ko.observable(),
      modelList: this.makeModelList(memoryDB, indexName),
      query: ko.observable().extend(DEBOUNCE),
      sorters: ko.observableArray().extend({ rateLimit: 25 }),
      pageSize: 100000
    })
    ko.computed(() => this.computeHits())
      .subscribe(this.hits)
  }

  makeModelList (memoryDB, indexName) { return memoryDB.getListOf(indexName) }

  normalizedQuery (unnormalized) {
    if (Array.isArray(unnormalized)) { // AND
      return m => unnormalized.every(u => !u || this.normalizedQuery(u)(m))
    }
    if (!unnormalized) { return () => true }
    if (typeof unnormalized === 'function') { return unnormalized }

    throw new Error(`MemorySearchCriteria: Invalid type of query.`)
  }

  makeFilteredSortedList (models, filters = () => true, sorters = []) {
    const orderFns = sorters.map(s => m => s.sortableValue(m))
    const orderOrders = sorters.map(s => s.isDesc() ? 'desc' : 'asc')
    return orderBy(models.filter(filters), orderFns, orderOrders)
  }

  getPage (list, pageNumber) {
    const page = this.page()
    const begin = page * this.pageSize
    const end = (page + 1) * this.pageSize - 1
    return list.slice(begin, end)
  }

  computeHits () {
    const list = this.makeFilteredSortedList(
      this.modelList(),
      this.normalizedQuery(this.query()),
      this.sorters()
    )

    this.nbHits(list.length)
    this.nbPages(Math.ceil(list.length / this.pageSize))

    return this.getPage(list, this.page())
  }

  /**
   * @param {string} query
   */
  async search (query, params?) {
    this.query(query)
  }
}
