import List from '../blocks/List'

import IndentableBodyBlockEditor from './IndentableBodyBlockEditor'

export default class BlockListEditor extends IndentableBodyBlockEditor<List> {
  static get css () {
    return {
      ...super.css,
      number: {
        color: '#888',
        width: '10px',
        lineHeight: '2.2em',
        marginRight: '1em',
        '&[depth="0"]': { marginLeft: '1rem', },
        '&[depth="1"]': { marginLeft: '2rem', },
        '&[depth="2"]': { marginLeft: '3rem', },
        '&[depth="3"]': { marginLeft: '4rem', },
        '&[depth="4"]': { marginLeft: '5rem', },
      }
    }
  }


  get internalHTML () {
    const number = this.computed(() => this.block.getFormattedNumber(this.blocks))
      .extend({ deferred: true })

    return (
      <>
        <div class={this.jss.number} depth={this.block.depth}>
          {number}
        </div>
        {super.internalHTML}
      </>
    )
  }
}

BlockListEditor.register()
