
import { getUrlFile } from 'request/file'

/**
 * The details of a PdfPage that are passed around.
 *
 * Note that we may see a performance increase if we use WebWorkers to
 * create the thumbnails and SVGs in a background thread.
 */
export default class BookPage {
  pageID: string
  entity: import('EntityModel').default
  focused: KnockoutObservable<boolean>
  selected: KnockoutObservable<boolean>
  inViewport: KnockoutObservable<boolean>
  bookmarked: KnockoutObservable<boolean>
  startsSection: KnockoutObservable<boolean>
  hasNotes: KnockoutObservable<boolean>

  /**
   * @param {PDFProxy} pdfPagePromise
   */
  constructor (pageID, entity) {
    Object.assign(this, {
      pageID,
      entity,
      focused: ko.observable(false),
      lores: ko.observable(),
      hires: ko.observable(),
      selected: ko.observable(false),
      inViewport: ko.observable(false),
      bookmarked: ko.observable(undefined),
      startsSection: ko.observable(undefined),
      hasNotes: ko.observable(undefined),
    })
  }

  /**
   * Trigger the `scrollTo` binding, then reset.
   */
  scrollIntoView () {
    this.focused(true)
    this.focused(null)
  }

  /**
   * Return the page of the downloading PDF that
   * corresponds to this book page.
   */
  get pageNumberOfPDF () : number {
    return this.pageID.includes('/')
      ? parseInt(this.pageID.split(/[\/#]/).pop(), 10)
      : 0
  }

  /**
   * This is a string unique to the download URL fordpl
   * the page, but unlike `getDownloadURL`, it's
   * provided synchronously.
   */
  get idUniqueToDownloadURL () {
    return this.pageID.split(/[/#]/).shift()
  }

  /**
   * @return {bool} true if the pageID is a URL e.g. /demo.pdf#12;
   *  false means pageID is a reference to
   *  GCS entity/filehash/index/pdf
   */
  get isUrlPageID () {
    return this.pageID.includes('#')
      || this.pageID.startsWith('/')
      || this.pageID.startsWith('http:')
      || this.pageID.startsWith('https:')
  }

  async getDownloadURL () {
    if (this.isUrlPageID) { return this.pageID }
    const [fileHash] = this.pageID.split('/')
    const path = this.entity.pdfPartPath(fileHash, 'original.pdf')
    return this.entity.authManager.storage
      .ref(path)
      .getDownloadURL()
  }

  async getPDFArrayBuffer () : Promise<ArrayBuffer> {
    return getUrlFile(await this.getDownloadURL(), 'ArrayBuffer')
  }

  /**
   * Return a BookPage corresponding to this PDF.
   * @param {string} pageID
   * @param {AuthManager} authManager
   * @param {Map} cachedBookPages so any refreshes do not re-render
   */
  static getOrMakeBookPage (pageID, entity, cachedBookPages) {
    const { projectID } = entity.authManager
    const cacheBookPageID = `${projectID}::${pageID}`
    if (cachedBookPages.has(cacheBookPageID)) {
      return cachedBookPages.get(cacheBookPageID)
    }
    const newBook = new BookPage(pageID, entity)
    cachedBookPages.set(cacheBookPageID, newBook)
    return newBook
  }
}
