
import PanelProvider from 'PanelProvider'

import { color } from 'styles'

export default class AppLoadingPanelProvider extends PanelProvider {
  get panelWindowTitle () { return `Loading...` }

  get historyUrl () { return null }

  get top () {}
  get head () {
    const { jss } = this
    return (
      <>
        {super.head}
        <div class={jss.loading}>
          <loading-spinner class={jss.indicator} />
        </div>
      </>
    )
  }
  get main () {
    const { jss } = this
    const bodyObs = ko.observable(
      <loading-spinner class={jss.indicator} />)
    return (
      <div class={jss.wrapper}>
        <div class={jss.header}></div>
        <div class={jss.tools}></div>
        <div class={jss.toc}></div>
        <div class={jss.mainArea}></div>
        <div class={jss.footer}></div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 82px)',
        position: 'fixed',
        width: '100%',
      },
      indicator: {
        maxWidth: '50px',
        '--icon-height': '54px',
      },
      wrapper: {
        display: 'grid',
        gridTemplateColumns: '252px auto',
        gridTemplateRows: '1px 58px auto 75px',
        height: 'calc(100vh - var(--head-height))',
        gridTemplateAreas:`
        'header header'
        'tools tools'
        'toc mainArea'
        'footer footer'
        `
      },
      header: {
        gridArea: 'header',
        backgroundColor: color.systemBackground.light.primary,
        borderBottom: '1px solid #d0d0d0',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
      },
      tools: {
        gridArea: 'tools',
        backgroundColor: color.systemBackground.light.primary,
        borderBottom: '1px solid #d0d0d0',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
      },
      toc: {
        gridArea: 'toc',
        backgroundColor: color.systemBackground.light.secondary,
        borderBottom: '1px solid #d0d0d0',
        borderRight: '1px solid #d0d0d0',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
        },
      },
      mainArea: {
        gridArea: 'mainArea',
        backgroundColor: color.systemBackground.light.secondary,
        borderBottom: '1px solid #d0d0d0',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
        },
      },
      footer: {
        gridArea: 'footer',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
      }
    }
  }
}
