import { Process } from 'utils/Process'

export abstract class Inference {
}


export abstract class InferenceGenerator<T extends Inference> {
  process = new Process()
  abstract async generate (): Promise<T[]>
}
