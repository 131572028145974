
import { TOP_MARGIN } from './consts'

/**
 * The scrollTo and intersection are relative to the window, so we
 * have to adjust for the head and top-bars.  We may need to adjust
 * accordingly.
 */
export class EisSectionNode {
  scrollTo: any

  constructor ({ title, scrollTo, inView, hide }) {
    Object.assign(this, { title, scrollTo, inView, hide })
  }

  scrollIntoView () {
    this.scrollTo(-TOP_MARGIN) // var(--head-height)
  }
}

export default EisSectionNode
