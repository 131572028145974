import { endOfMonth } from 'date-fns/esm'

import MonthDayComponent from './MonthDayComponent'

export default class FiscalYearEndMDComponent extends MonthDayComponent {
  static get namespace ()  { return 'date' }

  getSearchIndexDates () {
    return this.getSearchDate({ code: 'corp__FYE', reason: 'Fiscal year end ' })
  }

  setLastDayOfMonth () {
    if (!Number.isInteger(this.M())) { return }
    this.d(endOfMonth(new Date([2014, this.M(), 1])))
  }

  /**
   * Return the reminder dates, specifically for this year.
   */
  reminderDates (given: Date) {
    return super.makeReminderDate(given.getFullYear())
  }

  /**
   * Indexed as Month:Day
   */
  get asIndexValue () {
    return this.M() * 100 + this.d()
  }
}

FiscalYearEndMDComponent.register()
