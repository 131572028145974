import { buttons, color, typography, formBox } from 'styles'
import PanelProvider from 'PanelProvider'

import { getPossibleAuthManagers } from 'auth'

import imageOr from 'icons/imageOr'

import './webauth-challenge-modal'

/**
 * Panels for getting the authentication credentials from the user.
 */
export default class UserLoginPanelProvider extends PanelProvider {
  private _sendingEmailMutex: boolean
  formHTML: KnockoutObservable<any>
  email: KnockoutObservable<string>
  authManager: AuthManager

  get panelWindowTitle () { return `Login` }
  get welcomeMessage () { return 'Welcome back. Login below.' }

  constructor ({ app, email }) {
    super({ app })
    Object.assign(this, {
      email: ko.observable().extend({ localStorage: 'mb.userEmail' }),
      authManager: app.defaultAuthManager,
      formHTML: ko.observable(
        <loading-spinner class={this.jss.loadingSpinner} />),
    })

    if (email) { this.email(email) }

    try {
      this.consumeTokenOrShowSignIn()
    } catch (err) {
      console.error(`[UserLogin] Unable to consume token: `, err)
      if (window.Sentry) { window.Sentry.captureException(err) }
      throw err
    }
  }

  get historyUrl () { return null }

  /**
   * Detect which authentication manager to use.  We can do this by:
   *  1. If only one is available, use that
   *  2. User prior choices
   *  3. Geographical access (detect IP connecting)
   */
  detectAuthManager () {
    const possibleAuthManagers = getPossibleAuthManagers()
    if (possibleAuthManagers.length === 1) {
      return possibleAuthManagers[0]
    }
  }

  get top () {}
  get topYellowBar () { }
  get head () { }

  static get css () {
    return {
      ...super.css,
      ...this.introCSS,
      ...this.formCSS,
      ...this.marketingCSS,
      ...this.otpCSS,
      ...this.challengerCSS,
      layout: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: '1fr 1fr',
      },
      pad: {
        ...typography.brand.text,
        display: 'grid',
        gap: '12px',
        maxWidth: '620px',
        gridTemplateColumns: '400px',
        gridTemplateAreas: `
          'logo'
          'welcome'
          'form'
        `,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        '@media (max-width: 1000px)': {
          gridRow: '1/2',
          gridColumn: '1/3',
          padding: '100px 0px'
          //marginTop: '-100px'

        }
      }
    }
  }

  static get introCSS () {
    return {
      logo: {
        background: 'center center no-repeat url("/minute-box-logo.svg")',
        minHeight: '42px',
        minWidth: '200px',
        backgroundSize: '200px',
      },
      welcome: {
        ...typography.brand.title,
        textAlign: 'center',
        paddingBottom: '38px'
      },
      home: {
        display: 'block',
        margin: '0 0 0 100px',
        textAlign: 'center',
        height: '42px',
        width: '200px'
      }
    }
  }

  get main () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        <div class={jss.pad}>
          <div class={jss.logo}>
            <a class={jss.home} href="https://www.minutebox.com" />
          </div>
          <div class={jss.welcome}>
            {this.welcomeMessage}
          </div>
          <div class={jss.form}>{this.formHTML}</div>
          <div class={jss.formU1} />
          <div class={jss.formU2} />
        </div>
        <div class={jss.marketingPanel}>
          {this.marketingPanelHTML}
        </div>
      </div>
    )
  }

  get signInFormEmailInputHTML () {
    const { jss } = this
    return this.signInFormHTML(
      <input class={jss.emailInput}
        type='text'
        autofocus
        placeholder='Enter Email'
        ko-textInput={this.email}
        ko-keydown={{ Enter: () => this.emailAuthClick() }} />,
      <async-button faceClass={jss.emailButton}
        action={() => this.emailAuthClick()}
        ko-keydown={{ Enter: () => this.emailAuthClick() }}>
        <template slot='face'>
          Sign in with Email
        </template>
      </async-button>
    )
  }

  get signInCheckYourEmailHTML () {
    const { jss } = this
    return this.signInFormHTML(
      <div class={jss.checkEmailMessage}>
        Check your <b>{this.email()}</b> email for a sign-in link.
      </div>
    )
  }

  static get checkEmailCSS () {
    return {
      checkEmailMessage: {
        fontSize: '1rem',
        textAlign: 'center',
        maxWidth: '320px'
      }
    }
  }

  signInFormHTML (...inputHTML) {
    const { jss } = this
    return (
      <>
        {inputHTML}
        <div class={jss.or}>
          {imageOr}
        </div>
        <button class={jss.googleButton} ko-click={() => this.googleAuthClick()}>
          Sign in with Google
        </button>
        <div class={jss.hbar} />
        <a href='https://www.minutebox.com/contact' class={jss.noAccount}>
          Don't have an account? Sign up.
        </a>
        <div class={jss.help}>
          Questions? Send us an email to <a href='mailto:support@minutebox.com'>support@minutebox.com</a> or call 1-833-4-MNTBOX.
        </div>
      </>
    )
  }

  static get formCSS () {
    return {
      ...this.checkEmailCSS,
      form: {
        ...formBox.tripleLayer.form,
        gridArea: 'form',
        padding: '43px',
        gridAutoColumns: 'max-content'
      },
      formU1: {
        ...formBox.tripleLayer.formU1,
        gridArea: 'form'
      },
      formU2: {
        ...formBox.tripleLayer.formU2,
        gridArea: 'form'
      },
      googleButton: {
        ...buttons.formAction,
        width: '250px',
        margin: 'auto'
      },
      or: {
        textAlign: 'center'
      },
      emailInput: {
        ...formBox.textInput
      },
      emailButton: {
        ...buttons.formAction
      },
      hbar: {
        ...formBox.yellowBar,
        margin: 'auto'
      },
      noAccount: {
        textDecoration: 'none',
        fontWeight: 'bold',
        textAlign: 'center',
        'body[dark] &': { // project batman
          color: color.color.dark.blue
        },
      },
      help: {
        textAlign: 'center',
        maxWidth: '350px',
        '& > a': {
          'body[dark] &': { // project batman
            color: color.color.dark.blue,
          },
        }
      },
      loadingSpinner: {
        maxWidth: '50px'
      }
    }
  }

  static get appGridClassCSS () {
    return {
      ...super.appGridClassCSS,
      grid: {
        ...super.appGridClassCSS.grid,
        '@media (max-width: 375x)': {
          ...super.appGridClassCSS.grid,
          templateRows: 'auto auto auto auto 1fr',
          templateAreas: `
          'ty ty ty'
          'head head head'
          'top top top'
          'left main right'
          'left main right'
        `
        }
      }
    }
  }

  static get marketingCSS () {
    return {
      marketingPanel: {
        backgroundColor: color.brandYellow,
        height: '100vh',
        display: 'grid',
        overflow: 'hidden',
        gridColumn: '2/3',
        '@media (max-width: 1000px)': {
          gridRow: '2/3',
          gridColumn: '1/3',
          height: 'auto',
          maxHeight: '550px',
          minHeight: '350px'
        }
      },
      marketingHead: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        height: '45vh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10vw',
        '@media (max-width: 1000px)': {
          height: 'auto'
        }
      },
      marketingTitle: {
        ...typography.brand.title,
        fontSize: '1.4rem',
        color: color.gray.brand,
        paddingBottom: '20px'
      },
      marketingText: {
        ...typography.brand.text,
        fontSize: '1rem',
        color: color.gray.brand,
        textAlign: 'justify'
      },

      marketingFolderArea: {
        transform: 'rotate(14deg)',
        width: '45vw',
        overflow: 'visible',
        position: 'relative'
      },

      marketingTabs: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: '41px',
        '@media (max-width: 1000px)': {
          justifyContent: 'flex-start',
        }
      },

      marketingTab: {
        ...typography.brand.text,
        fontWeight: 'bold',
        fontSize: '0.7rem',
        borderRadius: '8px',
        boxShadow: '0px 2px 7px 0px rgba(134, 134, 134, 0.5)',
        backgroundColor: 'white',
        padding: '12px 20px',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        marginLeft: '-5px',
        color: color.grey.brand
      },

      marketingFolder: {
        backgroundColor: '#3E2723',
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        left: '-100px',
        top: '34px',
        '@media (max-width: 1000px)': {
          width: '120vw'
        }
      }
    }
  }

  get marketingPanelHTML () {
    const { jss } = this
    return [
      <div class={jss.marketingHead}>
        <div class={jss.marketingTitle}>
          Accessible Everywhere.
        </div>
        <div class={jss.marketingText}>
          MinuteBox provides access to your firm’s data on any device from anywhere in the world. Need access to a client’s corporate records on the go? It’s all at your fingertips to view, edit and share securely.
        </div>
      </div>,

      <div class={jss.marketingFolderArea}>
        <div class={jss.marketingTabs}>
          <div class={jss.marketingTab}>Articles</div>
          <div class={jss.marketingTab}>By Laws</div>
          <div class={jss.marketingTab}>Resolutions</div>
          <div class={jss.marketingTab}>Share Register</div>
          <div class={jss.marketingTab}>Director Register</div>
        </div>

        <div class={jss.marketingFolder} />
      </div>
    ]
  }

  get fireAuth () {
    return this.authManager.firebase.auth()
  }

  async consumeTokenOrShowSignIn () {
    const params = new URL(location.href).searchParams
    const uid = params.get('u')
    const emailToken = params.get('t')
    if (!await this.consumePossibleEmailAuthToken({ uid, emailToken })) {
      this.formHTML(this.signInFormEmailInputHTML)
    }
  }

  /**
   * Authenticate with the given custom token, possibly requiring 2FA.
   * @param {string} uid
   * @param {string} token
   */
  async consumePossibleEmailAuthToken (mfaParams) {
    if (!mfaParams.uid) { return }

    const r = await this.app
      .callFirebaseFunction('multiFactorAuthenticate', mfaParams)
    console.debug(`🌐  MultiFactorAuthenticate response`, r.status)

    if (r.status === 'ok') {
      await this.fireAuth.signInWithCustomToken(r.token)
      window.history.replaceState({}, document.title, '/')
      return true
    }
    if (r.code === 'mfa/bad-otp') {
      this.formHTML(this.otpInputHTML(mfaParams))
      return true
    }
    return false
  }

  static get otpCSS () {
    return {
      otpBlock: {},
      otpInput: {
        height: 40,
        border: '1px solid rgba(0,0,0,0.3)',
        padding: '0px 24px',
        fontSize: '1em',
        textAlign: 'center',
        borderRadius: 2,
      },
      otpVerifyButton: {
        ...buttons.clickable,
        color: 'white',
        cursor: 'pointer',
        border: 0,
        outline: 'none',
        padding: 10,
        fontSize: '1rem',
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.5)',
        textAlign: 'center',
        minHeight: 40,
        userSelect: 'none',
        fontWeight: 'bold',
        fontFamily: typography.altFontFamily,
        borderRadius: 5,
        textDecoration: 'unset',
        backgroundColor: '#4a90e2',
        marginTop: '15px'
      },
      loginEmail: {
        background: 'white',
        color: 'black',
        padding: '5px 5px',
        borderRadius: 4,
        textAlign: 'center',
        boxShadow: '0px -3px 0px 1px #ffd502',
        fontWeight: 600,
        marginTop: 10,
        cursor: 'pointer'
      }
    }
  }

  otpInputHTML (mfaParams) {
    const { jss } = this
    const otp = ko.observable()
    const verifyClick = () => {
      this.formHTML(<loading-spinner class={this.jss.loadingSpinner} />)
      return this.consumePossibleEmailAuthToken({ ...mfaParams, otpToken: otp() })
    }

    return (
      <div class={jss.otpBlock}>
        <input class={jss.otpInput}
          ko-textInput={otp} />
        <async-button faceClass={jss.otpVerifyButton} action={verifyClick}>
          <template slot='face'>
            Verify
          </template>
        </async-button>
      </div>
    )
  }

  /**
   * Consume a possible link that authenticates to Firebase.
   *
   * Note that right now we don't handle multi-device auth very well; when
   * a user opens a window with a proper auth link we ought to invite them
   * to enter their email address.
   */
  consumePossibleEmailAuthLink () : boolean{
    const href = window.location.href
    if (!this.fireAuth.isSignInWithEmailLink(href)) { return false }
    const params = new URLSearchParams(location.search)

    const authManager = this.authManager
    if (!authManager) { return false }

    const { apiKey } = this.authManager.firebaseConfig
    if (params.get('apiKey') !== apiKey) { return false }

    this.attemptSignIn(href)
    return true
  }

  async attemptSignIn (href) {
    try {
      console.debug(`{signInWithEmailLink} ${this.email()} => ${href}`)
      await this.fireAuth.signInWithEmailLink(this.email(), href)
      window.history.replaceState(null, null, window.location.pathname)
    } catch (err) {
      console.error('Unable to log in:', err)
    }
  }

  async emailAuthClick () {
    this.formHTML(this.challengingAuthenticatorHTML)
    if (this._sendingEmailMutex) { return }
    this._sendingEmailMutex = true
    const email = this.email()
    try {
      const verificationResult = ko.observable()

      window.app.modal(
        <webauth-challenge-modal
          authManager={this.authManager}
          email={email}
          verificationResult={verificationResult} />
      )

      await verificationResult.yet(undefined)
      if (verificationResult()) {
        this.onVerification(verificationResult())
      } else {
        await this.sendLoginEmail(email)
      }
    } finally {
      this._sendingEmailMutex = false
    }
  }

  async sendLoginEmail (email) {
    window.app.modal(null)
    this.formHTML(this.signInCheckYourEmailHTML)
    const r = await this.app.callFirebaseFunction('sendLoginEmail', { to: email })
    if (!r) {
      this.formHTML(this.signInFormEmailInputHTML)
    }
  }

  async onVerification (result) {
    if ('uid' in result) {
      await this.fireAuth.signInWithCustomToken(result.token)
      window.app.modal(null)
    } else {
      this.formHTML(this.signInFormEmailInputHTML)
    }
  }

  get challengingAuthenticatorHTML () {
    const { jss } = this
    return (
      <div>
        Please activate your authenticator to complete your login.
        <div class={jss.loginEmail}
          ko-ownClick={() => this.sendLoginEmail(this.email())}>
          Login with Email
        </div>
      </div>
    )
  }

  async signInWithPopup (provider) {
    await this.fireAuth.signInWithPopup(provider)
  }

  getAuthProvider (name) {
    return new window.firebase.auth[name]()
  }

  async googleAuthClick () {
    const provider = this.getAuthProvider('GoogleAuthProvider')
    provider.addScope('email')
    await this.signInWithPopup(provider)
  }

  async twitterAuthClick () {
    const provider = this.getAuthProvider('TwitterAuthProvider')
    await this.signInWithPopup(provider)
  }

  async facebookAuthClick () {
    const provider = this.getAuthProvider('FacebookAuthProvider')
    await this.signInwithPopup(provider)
  }
}
