import { fuzzyMatch } from 'utils/string'
import { currentOrLastEditable } from 'utils/editable'
import icons from 'icons'
import nextIcon from 'icons/solid/caret-right'


export type Variable = import('../variables/Variable').default
type CommandMenu = import('./CommandMenu').default

export interface VariableCommand extends Command {
  variableSlotCode: string
}

export abstract class Command {
  public text: string
  constructor (text: MaybeObservable<string>, public icon: JSX) {
    Object.assign(this, { text: ko.unwrap(text) })
  }

  abstract html (jss, highlightedText: string | JSX, key?) : JSX
  abstract trigger (menu: CommandMenu): void
  matches (needle: string) { return fuzzyMatch(needle, ko.unwrap(this.text)) }
  get isSelectable () { return true }
  get triggerOnArrowRight () { return false }
  get triggerHidesMenu () { return true }
  commandClass (jss) { return jss.commandItem }
}

export class ActionCommand extends Command {
  constructor (text: string, icon: JSX, private actionFn: () => void) {
    super(text, icon)
  }

  trigger () { this.actionFn() }
  html (jss, highlightedText, key) : JSX {
    return (
      <>
        <div class={jss.commandIcon}>{icons.inline(this.icon)}</div>
        <div class={jss.commandText}>{highlightedText}</div>
        <div class={jss.commandKey}>{key}</div>
      </>
    )
  }
}

/**
 * SubmenuCommand is a special variant of commands.
 */
export class SubmenuCommand extends Command {
  constructor (text: string, icon: JSX, public submenu: CommandMenu) {
    super(text, icon)
  }

  get triggerOnArrowRight () { return true }
  get triggerHidesMenu () { return false }

  html (jss, highlightedText, key) : JSX {
    return (
      <>
        <div class={jss.commandIcon}>{icons.inline(this.icon)}</div>
        <div class={jss.commandText}>{highlightedText}</div>
        <div class={jss.commandKey}>{key}</div>
        <div class={jss.fillInMore}>{icons.inline(nextIcon)}</div>
      </>
    )
  }

  trigger (menu: CommandMenu) {
    const e = new CustomEvent('menutrigger')
    currentOrLastEditable().dispatchEvent(e)
    menu.setSubmenu(this)
  }
}

export class SubmenuVariableCommand extends SubmenuCommand implements VariableCommand {
  constructor (text: string, icon: JSX, submenu: CommandMenu, private slotCode: string) { super(text, icon, submenu) }
  get variableSlotCode () { return this.slotCode }
}


export class TitleCommand extends Command {
  constructor (title: string) { super(title, null) }
  trigger () { return }
  commandClass (jss) { return jss.commandGroup }
  html (jss, highlightedText: JSX) {
    return (
      highlightedText
    )
  }
  get isSelectable () { return false }
}


export default Command
