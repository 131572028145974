/**
 * An EntityModel tracks a given Entity.
 */
import QuestionModel from 'DataModel/QuestionModel'
import 'DateComponent'

import './components'
import { PlainMediator } from 'DataModel'

import { QUESTIONS } from './entity-questions.yaml'
import { AddressesComponent, Address } from 'DataModel/components'
import { makePersonRecord } from 'person'
import { PersonType } from 'person/interfaces'

export { QUESTIONS as EntityQuestions }

export class EntityModel extends QuestionModel implements PersonGenerator {
  companyStatus: KnockoutObservable<string>

  get cvModelTitle () { return this.answerFor('legalname')() }

  get QUESTIONS () {
    return QUESTIONS
  }

  /**
   * @param {string} shasum of the file
   * @param {string|number} index optional 0-offset of the PDF page
   * @param {type} the optional name of the file
   */
  pdfPartPath (shasum, index, name = null) {
    const accountID = this.accountID()
    const entityID = this.id()
    if (!accountID) { throw new Error(`No AccountID`) }
    if (!entityID) { throw new Error(`No entityID`) }
    const path = [index, name]
      .filter(s => s !== null && s !== undefined)
      .join('/')
    const prefix = `/accounts/${accountID}/entity/${entityID}`
    return `${prefix}/${shasum}/${path}`
  }

  pdfUploadPart (blob, shasum, index, name) {
    const path = this.pdfPartPath(shasum, index, name)
    return this.authManager.storage.ref(path).put(blob)
  }

  getDownloadURL (shasum, index, name) {
    const path = this.pdfPartPath(shasum, index, name)
    return this.authManager.storage.ref(path).getDownloadURL()
  }

  vmCreateMediatorInstances () {
    return [
      ...super.vmCreateMediatorInstances(),
      new PlainMediator('companyStatus', this),
    ]
  }

  get selfPerson () : PersonRecord {
    const addressComponent = this.componentFor('addresses') as AddressesComponent
    const addresses = addressComponent && addressComponent.array() as Address[]
    return makePersonRecord({
      id: `entity:${this.id()}`,
      type: PersonType.ENTITY,
      name: this.cvModelTitle ? [this.cvModelTitle] : [],
      address: addresses ? addresses.map(a => a.asAddressRecord()) : [],
      origin: [{
        update: () => null, // FIXME
        model: this,
        * roles () { yield * [{ title: 'Entity' }]}
      }]
    })
  }

  * generatePersons (filter: (person: PersonRecord) => boolean = () => true) {
    yield * super.generatePersons(filter)
    yield * [this.selfPerson].filter(filter)
  }

  normalizePerson (person: PersonRecord) : void {
    super.normalizePerson(person)
    if (!person.id && person.name.includes(this.cvModelTitle)) {
      person.id = `entity:${this.id()}`
      person.type = PersonType.ENTITY
    }
  }

}

EntityModel.register()

export default EntityModel
