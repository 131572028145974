import { parseISO } from 'date-fns/esm'

import { formatLongForUser } from 'utils/dates'

import { Column, BooleanColumn } from './Column'

/**
 *
 */
export class InviteSentColumn extends Column {
  renderValue (row) {
    const invite = ko.unwrap(row.invite)
    if (!invite) { return '—' }
    const sent = parseISO(invite.created)
    return formatLongForUser(sent)
  }

  get columnID () { return 'invite_created' }
}

export class InviteAcceptedColumn extends Column {
  renderValue (row) {
    const invite = ko.unwrap(row.invite)
    if (!invite || !invite.accepted) { return '—' }
    const accepted = parseISO(invite.accepted)
    return formatLongForUser(accepted)
  }

  get columnID () { return 'invite_accepted' }
}

export class TeamsColumn extends Column {
  renderValue (row) {
    const filterTitles = row.teamFilterKeys()
      .map(key => window.app.memoryDB.getModel('filter', key))
      .filter(fm => fm)
      .map(fm => fm.title())
    return filterTitles.join(', ')
  }

  get filterable () { return false }
  get sortable () { return false }
  get columnID () { return 'teamFilterKeys' }
}


/**
 * Two-Factor Auth User List View Column
 *
 */
type UID = string
type OTPMap = Map<UID, boolean>

export class OTPAuthColumn extends BooleanColumn {
  otpMap : KnockoutObservable<OTPMap> = ko.observable(new Map())
  query : Promise<OTPMap> = null

  static get immutableID () { return 'otp' }
  constructor () {
    super('Two-Factor Authentication', 'otp', {
      getter: row => this.getValue(row),
  })
  }

  async runQuery () : Promise<OTPMap> {
    const authManager = window.app.defaultAuthManager
    const accountID = authManager.accountID()
    const otpUserData =
      (await authManager.firebaseFn('userMetaData', { accountID }))
        .map(([id, data]) => [id, data.otp])
    return new Map(otpUserData)
  }

  getValue (row) {
    if (this.query === null) {
      (this.query = this.runQuery()).then(this.otpMap)
    }
    return this.otpMap().get(row.id())
  }
}
