
import Picker from 'picker'

import { UserModel } from 'DataModel'

import { color } from 'styles'

export default class UserPicker extends Picker<UserModel> {
  constructor(params) {
    super({...params, resetQueryOnSelect: true })
  }

  choices () {
    const users = window.app.memoryDB.getListOf('user')
    return users()
  }

  onEnter (query) {
    this.onSelect({ name: query })
  }

  static get css () {
    return {
      ...super.css,
      name: {
        fontSize: '0.9rem',
        marginRight: '8px'

      },
      email: {
        fontSize: '0.9rem',
        color: 'gray'
      }
    }
  }

  filterChoice (item, query) {
    const q = (query || '').toLowerCase()
    return [item.name, item.email()]
      .filter(s => s)
      .map(s => s.toLowerCase())
      .some(s => s.includes(q))
  }

  itemHTML (user) {
    const { jss } = this
    return [
      <div class={jss.name}>{user.name}</div>,
      <div class={jss.email}>{user.email}</div>
    ]
  }
}

UserPicker.register()
