
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'

export class PPSA extends UnitView {
  get elvValue () {
    return [this.type(), this.description()].join(' - ')
  }

  get fields () {
    return {
      description: 'string',
      type: 'string', // Registration (Initial); Amendment; Renewal; Discharge/Partial Discharge
      creditor: 'string',
      debtor: 'string',
      status: 'string',
      // Status: None; Preliminary Search; Application filed; TMO Examination; Publication of Application; Receipt of Opposition; Registered
      agent: 'person',
      expiry: 'pureDate',
      jurisdiction: 'string',
    }
  }

  * getRoles (): IterableIterator<PersonRoleRecord> {
    yield { title: 'PPSA Agent' }
  }
}

export default class PPSAsComponent extends ArrayComponent {
  get ItemClass () { return PPSA }
}

PPSAsComponent.register()
