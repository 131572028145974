/**
 * Base class for panel provider with lists
 */
import { entityFromPdfBlob } from 'import/pdf'
import { MemorySearchCriteria } from 'search'
import { ModelListOptions } from 'model-list'
import FilterModel from 'FilterModel'
import ModelList from 'ModelList'
import { EntityModel } from 'EntityModel'
import PdfUpload from 'import/PdfUpload'
import Column from 'Column'

import 'upload-progress-to-entity'
import 'entity-editor'

import { color, input, key, typography } from 'styles'

import RootPanelProvider from './RootPanelProvider'
import { importFileUX } from 'import/importFileUx'

import icons from 'icons'
import searchIcon from 'icons/light/search'


const rateLimit = { timeout: 250, method: 'notifyWhenChangesStop' }

export default abstract class RootListPanelProvider extends RootPanelProvider {
  criteria: MemorySearchCriteria

  constructor (args) {
    super(args)

    Object.assign(this, {
      query: ko.observable('').extend({ rateLimit }),
      searchInputFocus: ko.observable(),
      criteria: this.makeCriteria(),
      listOptions: this.makeModelListOptions(),
      hoverRow: ko.observable(),
    })

    this.computed(() => this.makeSearchQueries())
      .subscribe(queries => this.criteria.search(queries))
    this.listOptions.sorters.subscribe(this.criteria.sorters)
    this.criteria.search(this.makeSearchQueries())
    this.criteria.sorters(this.listOptions.sorters())
  }

  makeModelListOptions () {
    return new ModelListOptions(this.possibleColumns, this.panelID)
  }

  show () {
    this.listOptions.clearFilters()
    super.show()
  }

  /**
   * @return {Model} currently hovered by the mouse, or undefined
   */
  get hoveredRowModel () {
    const { hoverRow, criteria } = this
    const row = parseInt(hoverRow())
    if (Number.isNaN(row)) { return }
    return criteria.hits()[row]
  }

  /**
   * @return {ModelList} of the filters for this
   */
  makeFiltersModelList () {
    const authManager = this.app.defaultAuthManager
    if (!authManager.accountID()) {
      return new ModelList(FilterModel, authManager, null)
    }
    const userId = authManager.firebaseUser().uid
    const filtersCollection = authManager
      .firestoreCollection('filter')
      .where('createdByUid', '==', userId)
      .where('panelID', '==', this.panelID)
      // .where('deleted', '!=', true)
    return new ModelList(FilterModel, authManager, filtersCollection)
  }

  /**
   * @return {FilterModel} filter for everything
   */
  async everythingFilter () {
    if (this._everythingFilter) {
      return this._everythingFilter
    }
    const authManager = this.app.defaultAuthManager
    const everythingID = `${this.collectionID}.*`
    const keyPath = authManager
      .firestoreCollection('filter')
      .doc(everythingID)
      .path

    const accountID = authManager.accountID()
    const { collectionID } = this
    const title = `Every ${this.collectionID}`
    const createData = {
      title,
      accountID,
      immutable: true,
      collectionID,
      id: everythingID,
    }

    const ef = authManager.userIsHomeless
      ? new FilterModel(createData, authManager)
      : await FilterModel.vmGetOrCreate(authManager, keyPath, createData)

    // 🔧 Repair filters that lacked an `accountID`
    //    for legacy data as of 19 Jun 2019 (< Release 1.4)
    if (!ef.accountID()) {
      ef.accountID(accountID)
      if (!authManager.userIsHomeless) { await ef.vmSave() }
    }

    return (this._everythingFilter = ef)
  }

  /**
   * Load and compute all the possible entity list providers.
   * @return {Computed.<Array.FilterModel>}
   */
  get modelFilters () {
    if (this._modelFilters) { return this._modelFilters }
    const everythingFilter = ko.observable()
    this.everythingFilter().then(everythingFilter)
    return (this._modelFilters = ko.computed(() => [
      everythingFilter(),
      ...this.filtersModelList.list
    ].filter(f => f)))
  }

  /**
   * Lazy getter b/c app.defaultAuthManager is not setup when this
   * class is constructed.
   * @return {CollectionList}
   */
  get filtersModelList () {
    Object.defineProperty(this, 'filtersModelList', {
      value: this.makeFiltersModelList()
    })
    return this.filtersModelList
  }

  /**
   * @param {DataModel} row
   * @return {bool} true when the search query matches the given row
   */
  searchQueryMatches (row) {
    return !this.query() ||
      this.listOptions.columns()
        .filter(c => c.searchable && c.show())
        .some(c => c.searchMatches(row, this.query()))
  }

  makeSearchQueries () {
    return [
      row => this.searchQueryMatches(row),
      this.listOptions.queryFilter()
    ]
  }

  makeCriteria () {
    return new MemorySearchCriteria(this.app.memoryDB, this.collectionID)
  }

  abstract get possibleColumns () : Column[]

  get menuSubItems () {
    const currentConditions = this.app.panelProvider() === this
      ? this.listOptions.conditions : null
    return (
      <filters-list
        everythingFilter={this.everythingFilter()}
        colList={this.filtersModelList}
        currentConditions={currentConditions}
        onDelete={filter => this.onFilterDelete(filter)}
        onSelect={filterDoc => this.onFilterSelect(filterDoc)} />
    )
  }

  /**
   * Overload with e.g. "add a new entity"
   */
  get addModelButton () { }

  /**
   * @param {FilterModel}
   * If the filter being deleted was selected, remove all its conditions
   * i.e. switch to the "Every {collectionID}" filter
   */
  onFilterDelete (filterModel) {
    const current = this.app.panelProvider().listOptions.conditions
    if (filterModel.isEquivalentTo(current())) { current([]) }
  }

  /**
   * @param {FilterModel} filterModel
   */
  onFilterSelect (filterModel) {
    this.listOptions.restoreSavedFilters(filterModel)
    if (this.app.panelProvider() !== this) {
      this.app.panelProvider(this)
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.searchCSS
    }
  }

  get hideMenuItems () {}

  get rootTopLeft () {
    const { jss } = this
    return (
      <div class={jss.rootTopLeft}>
        <model-list-options
          options={this.listOptions}
          hideMenuItems={this.hideMenuItems}
          savedFilters={this.filtersModelList.list}
          generateCSV={() => this.commandSet.commands.generateCSV.action()}
          generateReportClick={() => this.generateReportClick(this.listOptions)} />
      </div>
    )
  }

  get searchInputKeys () {
    // Unfocus input and let events bubble up to panel provider
    return {
      'ArrowDown': () => this.searchInputFocus(false),
      'ArrowUp': () => this.searchInputFocus(false),
    }
  }

  get rootTopRight () {
    const { jss } = this
    return (
      <div class={jss.rootTopRight}>
        <div class={jss.searchBar} >
          <div class={jss.searchContainer}>
            {icons.inline(searchIcon)}
            <input class={jss.searchInput}
              type='search'
              placeholder='Search'
              ko-textinput={this.query}
              ko-keydown={this.searchInputKeys}
              ko-hasfocus={this.searchInputFocus} />
            <span class={jss.searchKey}>/</span>
          </div>
        </div>
        {this.addModelButton}
      </div>
    )
  }

  get topFixed () {
    return (
      <>
        {this.rootTopLeft}
        {this.rootTopMiddle}
        {this.rootTopRight}
      </>
    )
  }

  static get searchCSS () {
    return {
      searchBar: {
        position: 'relative',
        minWidth: '200px',
        minHeight: '50px',
        alignSelf: 'stretch',
        margin: '0px 2px',
        transition: 'min-width 100ms',
        '@media (max-width: 900px)': {
          minWidth: '120px'
        }
      },

      searchContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        color: 'grey',
        '--icon-color': 'grey',
        borderRadius: '4px',
        backgroundColor: color.grey.searchbg,
        width: '100%',
        maxWidth: '80vw',
        maxHeight: 36,
        height: 36,
        top: '10px',
        right: 0,
        padding: '5px 5px 5px 15px',
        transition: 'width 0.2s',
        '&:focus-within': {
          '--icon-height': '0px',
        },
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.secondary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.secondary,
          '--icon-color': color.text.dark.secondary,
        },
      },

      searchInput: {
        ...input.all,
        outline: 'none',
        border: 'unset',
        width: 'calc(100% - 30px)',
        backgroundColor: 'transparent',
        right: '4px',
        height: '100%',
        textAlign: 'left',
        fontSize: '14px',
        fontFamily: typography.bodyFontFamily,
        paddingLeft: '4px',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
        paddingTop: '2px',
        '&::placeholder': {
          color: color.actionColor,
          'body[dark] &': { // project batman
            color: color.text.dark.primary,
          },
        },
        '&:focus::placeholder': {
          ...input.all['&:focus::placeholder'],
          color: color.onPrimary,
          'body[dark] &': { // project batman
            color: color.text.dark.primary,
          },
        }
      },

      searchKey: {
        ...key,
      },
    }
  }

  /**
   * Upload a file.
   *
   * For some of the absurity of drag events, see e.g.
   *   - https://stackoverflow.com/questions/3144881
   *
   * @param {MinuteBoxApp} app
   * @param {DragEvent} evt
   */
  async onDrop (evt: DragEvent, app) {
    super.onDrop(evt, app)

    evt.dataTransfer.dropEffect = 'copy'
    const files = evt.dataTransfer.files

    if (!files.length) { return }

    const authManager = await app.pickAuthManager()

    const pdfs = [...evt.dataTransfer.files]
      .filter(f => f.name.toLowerCase().endsWith('.pdf'))
    const imports = [...evt.dataTransfer.files]
      .filter(f =>
        f.name.toLowerCase().endsWith('.xc.zip') ||
        f.name.toLowerCase().endsWith('.enact')
      )

    for (const file of pdfs) {
      await this.handlePdfDrop(file, authManager)
    }

    const multiImport = imports.length > 1
    const percent = ko.observable(0)
    if (multiImport) {
      const message = `Importing ${evt.dataTransfer.files.length} entities.`
      this.app.notifier.pushProgress(message, percent)
    }

    for (const file of imports) {
      if (multiImport) {
        importFileUX(file, authManager)
          .then(e => e.vmSave())
          .then(() => percent.modify(p => p + 1 / imports.length))
      } else {
        window.app.modal(
          <modal-dialog modalTitle='Importing ...'>
            <template slot='content'><loading-spinner style='--icon-height: 45px' /></template>
          </modal-dialog>)
        const entity = await importFileUX(file, authManager)
        window.app.modal(this.appModalHTML(entity))
      }
    }
  }

  async handlePdfDrop (file: File, authManager: AuthManager) {
    const entity = ko.observable() as KnockoutObservable<EntityModel>
    const upload = new PdfUpload({ authManager, pdf: file })

    const message = <upload-progress-to-entity upload={upload}
      entity={entity} />
    this.app.notifier.pushProgress(message, upload.percent)
    entity(await entityFromPdfBlob(authManager, file))
    upload.entity = entity()

    this.app.modal(this.appModalHTML(entity()))
    const bookPages = await upload.process()
    entity()
      .answerFor('book')
      .pages
      .push(...bookPages)
    await this.app.modal.when(undefined)
    // Update entity only if user didn't cancel i.e. hit "save".
    entity().id.yet(undefined).then(() => entity().vmSave())
  }

  async appModalHTML (entity: EntityModel) {
    const valuesBeforeEditing = entity.vmCurrentReprToSave()
    const isNew = (!entity.id() || entity.__isNew)
    const title = `${isNew ? 'Add' : 'Update'} Entity`
    const reset = () => entity.vmAssignValues(valuesBeforeEditing)
    const save = () => entity.vmSave()
    return (
      <modal-dialog modalTitle={title}
        onDismissal={reset}>
        <template slot='content'>
          <entity-editor
            entity={entity}
            okText={title}
            onSave={save}
            onCancel={reset}
            onFinish={() => window.app.modal(undefined)} />
        </template>
      </modal-dialog>
    )
  }

  async newEntityClick () {
    return this.commandSet.commands.newEntity.action()
  }

  /**
   * Request a report of the current data from Google.
   */
  async generateReportClick () {
    return this.commandSet.commands.generateReport.action()
  }
}
