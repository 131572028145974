/**
 * An EntityModel tracks a given Entity.
 */
import CrudModel from 'DataModel/CrudModel'
import {
  PlainMediator
} from 'DataModel/mediators'
import {FileStatus, FileUploadStatus} from 'request/FileStatus'

const STATUS = [
  'New',
  'Complete',
  'Uploading',
  'Generating',
  'Cancelled',
  'Deleted',
  'Error'
]

const STATUS_ENUMS = {
  NEW: 0,
  COMPLETE: 1,
  UPLOADING: 2,
  GENERATING: 3,
  CANCELLED: 4,
  DELETED: 5,
  ERROR: 6
}

/**
 * Keep track of a file.
 */
export class FileModel extends CrudModel {
  get QUESTIONS () { return [] }

  vmDefineProperties () {
    this.statusName = ko.pureComputed(this.statusName, this)
  }

  statusName () { return STATUS[this.statusNumber() || 0] }
  get isUploaded () { return this.statusNumber() === 1 }

  get cvModelTitle () { return this.name() }

  vmCreateMediatorInstances () {
    return [
      ...super.vmCreateMediatorInstances(),
      new PlainMediator('name', this),
      new PlainMediator('sha512sum', this),
      new PlainMediator('statusNumber', this),
      new PlainMediator('size', this),
      new PlainMediator('mimeType', this)
    ]
  }

  /**
   * @return {string} the Firebase Storage path where the file will be kept.
   */
  get fmStoragePath () {
    const ready = this.accountID() && this.sha512sum()
    if (!ready) {
      throw new Error('Incohate fmStoragePath.')
    }
    return `/accounts/${this.accountID()}/sha512/${this.sha512sum()}`
  }

  /**
   * @return {string} The URL to download the file.
   */
  async fmStorageUrl () {
    return this.authManager.storage
      .ref(this.fmStoragePath)
      .getDownloadURL()
  }

  /**
   * Upload the given file.
   * @return {UploadStatus}
   */
  async fmUpload (file, metadata) {
    if (this.isUploaded) { return new FileStatus(this) }
    console.log(`[FileModel] Writing file: ${this.fmStoragePath}`, file, metadata)
    const putTask = this.authManager.storage
      .ref(this.fmStoragePath)
      .put(file, metadata)
    return new FileUploadStatus(this, putTask)
  }

  static fmIdForSha512 (sha512sum) {
    return `sha512:${sha512sum}`
  }

  static async fromGsUrl (authManager, gsUrl) {
    const [accountID, _, sha512sum] = gsUrl.split('/').slice(-3)
    if (!sha512sum.match(/[a-z0-9]{128}/i)) {
      throw new Error(`FileModel.fromGsUrl: gsUrl not valid sha512: ${gsUrl}`)
    }
    const modelID = this.fmIdForSha512(sha512sum)
    const firestoreKey = FileModel.vmFirestoreDocPath(modelID, accountID)
    return FileModel.vmGet(authManager, firestoreKey)
  }
}

Object.assign(FileModel, STATUS_ENUMS)
Object.assign(FileModel.prototype, STATUS_ENUMS)

FileModel.register()

export default FileModel
