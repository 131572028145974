import { inline } from 'icons'
import { dropdown } from 'styles'
import { computed } from 'utils/decorator'
import chevronDown from 'icons/light/chevron-down'
import chevronUp from 'icons/light/chevron-up'

import ButtonOverText from './button-over-text'

export type ButtonDropDownItem = {
  icon?: JSX
  iconColor?: string
  text: string | KnockoutObservable<string>
  action: (evt: MouseEvent) => void
}

type Item = ButtonDropDownItem | KnockoutObservable<ButtonDropDownItem>

export default class ButtonDropDown extends ButtonOverText {
  my: string
  at: string
  items: Item[]
  showing: KnockoutObservable<boolean>

  constructor ({ icon, text, iconColor, my, at, items }) {
    const showing = ko.observable(false)
    const action = () => showing.modify(v => !v)
    super({ icon, text, iconColor, action })
    Object.assign(this, { my, at, items, showing })
  }

  static get css () {
    return {
      ...super.css,
      icon: {
        ...super.css.icon,
        display: 'flex',
        justifyContent: 'space-evenly',
      },
      menu: {
        ...dropdown.generalMenu,
        whiteSpace: 'nowrap',
        fontSize: '15px',
        marginTop: '3px',
      },
      item: {
        ...dropdown.item,
        display: 'flex',
        alignItems: 'center',
      },
      menuIcon: {
        height: '1.9em',
        marginLeft: '-10px',
        marginRight: 10,
        '--icon-height': '100%',
        display: 'flex',
        alignItems: 'center',
      },
      menuText: {},
    }
  }

  @computed()
  iconToShow () {
    return this.showing()
      ? [inline(this.icon), inline(chevronUp)]
      : [inline(this.icon), inline(chevronDown)]
  }

  itemIconHTML (item: ButtonDropDownItem) {
    const { jss } = this
    if (!item.icon) { return }
    return (
      <div class={jss.menuIcon} style={this.iconColorStyle(item.iconColor)}>
        {inline(item.icon)}
      </div>
    )
  }

  itemHTML (item: ButtonDropDownItem) {
    const { jss } = this
    const action = evt => {
      this.showing(false)
      item.action(evt)
    }
    return (
      <div class={jss.item} ko-ownClick={action}>
        {this.itemIconHTML(item)}
        <div class={jss.menuText}>{item.text}</div>
      </div>
    )
  }

  get menuItemsHTML () {
    const { jss } = this
    return (
      <div class={jss.menu}>
        {this.items.map(i => this.itemHTML(ko.unwrap(i)))}
      </div>
    )
  }

  get template () {
    return (
      <drop-down my={this.my} at={this.at} showing={this.showing}>
        <template slot='anchor'>{super.template}</template>
        <template slot='content'>{this.menuItemsHTML}</template>
      </drop-down>
    )
  }
}


ButtonDropDown.register()
