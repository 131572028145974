import trashIcon from 'icons/solid/trash'
import errorIcon from 'icons/solid/exclamation-triangle'

import { buttons, color } from 'styles'

import ConfirmDelete from './confirm-delete'
import OutcomeNotification from 'notification-manager/outcome-notification'

export default class ConfirmModelDelete extends ConfirmDelete {
  constructor (params) {
    super(params)
    const { model } = params
    Object.assign(this, { model })
  }

  static get css () {
    return {
      ...super.css,
      deletedOutcome: {
        ...OutcomeNotification.css.outcome
      },
      deletedIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      },
      deletedMessage: {},
      errorOutcome: {
        ...OutcomeNotification.css.outcome,
        backgroundColor: 'rgba(255,188,196,0.97)',
        border: '1px solid red'
      },
      errorIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      },
      errorMessage: {},
      metaContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr'
      },
      messageContainer: {
        gridColumn: '1'
      },
      clickToViewContainer: {
        gridColumn: '2',
        display: 'flex',
        justifyContent: 'flex-end'
      },
      clickToView: {
        ...buttons.clickable,
        padding: '2px 10px',
        color: color.text.light.secondary,
        backgroundColor: color.fill.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
          backgroundColor: color.fill.dark.secondary,
        },
        borderRadius: 4,
        fontSize: '0.8em',
        '&:hover': {
          color: 'rgba(0,0,0,0.9)',
          backgroundColor: 'rgba(0,0,0,0.2)',
          textDecoration: 'underline'
        }
      }
    }
  }

  get deleteNotification () {
    const { jss } = this
    return [<span>Success: <strong>{this.model.cvModelTitle}</strong> deleted.</span>, {
      icon: trashIcon,
      style: {
        outcome: jss.deletedOutcome,
        icon: jss.deletedIcon,
        message: jss.deletedMessage
      }
    }]
  }

  get unableToDeleteNotification () {
    const { jss } = this
    const onClick = () => {
      global.app.rootProviders.entityList.selectEntity(this.model)
    }

    const clickToView = this.model.vmResourceName === 'entity'
      ? (
        <span class={jss.clickToView}
          ko-click={onClick}>View Entity</span>
      ) : undefined

    return [
      <div class={jss.metaContainer}>
        <span class={jss.messageContainer}>
          Unable to delete <strong>{this.model.cvModelTitle}</strong>.
        </span>
        <span class={jss.clickToViewContainer}>
          {clickToView}
        </span>
      </div>, {
        icon: errorIcon,
        style: {
          outcome: jss.errorOutcome,
          icon: jss.errorIcon,
          message: jss.errorMessage
        }
      }]
  }

  async confirmAction () {
    const { notifier } = global.app
    try {
      await this.model.vmFirestoreDoc().delete()
      notifier.pushOutcome(...this.deleteNotification)
    } catch (err) {
      console.error(`Unable to delete user ${this.model.cvModelTitle}`, err)
      notifier.pushOutcome(...this.unableToDeleteNotification)
    }
    await super.confirmAction()
  }

  get messageHTML () {
    return (
      <div>
        <p>
          Please confirm if you would like to delete <b>{this.model.cvModelTitle}</b> and all of its contents.
        </p>
        <p>If you delete the item it will not be recoverable.</p>
      </div>
    )
  }

  static open (model) {
    window.app.modal(
      <modal-dialog modalTitle='Confirm Delete'>
        <template slot='content'>
          <confirm-model-delete
            model={model}
            onFinish={() => window.app.modal(undefined)} />
        </template>
      </modal-dialog>
    )
  }
}

ConfirmModelDelete.register()
