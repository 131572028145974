
import { EntityQuestions } from 'EntityModel/EntityModel'
import { DataComponent } from 'DataModel/components'
import signatureIcon from 'icons/light/pen-alt'

import { SubmenuCommand, Command, TitleCommand } from './Command'
import { CommandMenu } from './CommandMenu'


type WritingDocument = import('../WritingDocument').default
type FieldDescription = import('DataModel/components/UnitField').FieldDescription
type QuestionDeclaration = import('DataModel/Question').QuestionDeclaration
type SignaturesBlock<T> = import('../blocks/Signatures').SignaturesBlock<T>
type ArrayComponentSignatures = import('../blocks/Signatures').ArrayComponentSignatures
type CapitalSignatures = import('../blocks/Signatures').CapitalSignatures

type AddBlockParams = [string, any]

export abstract class InsertSignatureCommand<T extends SignaturesBlock<any>> extends Command {
  constructor (private document: WritingDocument, title: string) {
    super(title, null)
  }

  get triggerHidesMenu () { return true }
  abstract get newBlockData (): AddBlockParams

  html (jss, highlightedText: JSX) {
    return (
      <>
        <div class={jss.commandText}>{highlightedText}</div>
        <div class={jss.commandKey}></div>
      </>
    )
  }

  trigger () { this.document.addBlockAfterCurrent(...this.newBlockData) as T }
}

export class InsertArrayComponentSignatureCommand extends InsertSignatureCommand<ArrayComponentSignatures> {
  constructor (w: WritingDocument, private question: QuestionDeclaration, private field: FieldDescription) {
    super(w, `${question.title} ${field.label}`)
  }

  get newBlockData (): AddBlockParams {
    const component = this.question.identifier
    const property = this.field.property
    return ['s', { component, property, slotIndex: 0 }]
  }
}


export class InsertCapitalSignatureCommand extends InsertSignatureCommand<CapitalSignatures> {
  constructor (w: WritingDocument, private component: string, title: string, private assetClassID: string) {
    super(w, title)
  }

  get newBlockData (): AddBlockParams {
    const { component, assetClassID } = this
    return ['sc', { assetClassID, component, slotIndex: 0 }]
  }
}



export function signatureCommandSubmenu (wd: WritingDocument, source = EntityQuestions) {
  const sm = new CommandMenu(null)
  sm.add(
    new TitleCommand('Insert Signatures'),
    ...signatureCommands(wd, source),
  )
  return new SubmenuCommand('Insert Signatures', signatureIcon, sm)
}

function * signatureCommands (wd: WritingDocument, source) {
  for (const q of source) {
    const c = DataComponent.getComponentById(q.component)
    switch (c.namespace) {
      case 'array':
        for (const f of c.fieldDescriptions) {
          if (f.type === 'person') {
            yield new InsertArrayComponentSignatureCommand(wd, q, f)
          }
        }
        break
      case 'entity':
        if (q.component === 'entity.capital') {
          yield new InsertCapitalSignatureCommand(wd, q.identifier, 'Capital share holders', null)
        }
        break
    }
  }
}

