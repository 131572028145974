
import { getClassByResourceName } from 'models'
import { QuestionModel, QuestionDescriptor } from 'DataModel/QuestionModel'
import ModelKeyMediator from 'DataModel/mediators/ModelKeyMediator'
import { memoize } from 'utils/decorator'
import { keyPathForModel } from 'utils/firestore'

import { wordCount, charCount, modelTitle } from './utils'
import './precedents/PrecedentWritingComponent'

type WritingDocument = import('./WritingDocument').default

const questions: QuestionDescriptor[] = [
  { title: 'Title', identifier: 'title', component: 'primitive.string' },
  { title: 'Content', identifier: 'writing', component: 'writing.precedentwriting' },
]

export default class WritingModel extends QuestionModel {
  parentKey: KnockoutObservable<[string, string]>

  get cvModelTitle () { return modelTitle(this) }
  get wordCount (): number { return wordCount(this.answerFor('writing')) }
  get charCount (): number { return charCount(this.answerFor('writing')) }
  get QUESTIONS () { return questions }

  vmDefineProperties () {
    this.parentKey.subscribe(k => this.assignParentKeyToWriting(...k))
  }

  /**
   * If we know the associated EntityModel, we can use that as the substitution
   * source for the `modelKey` of existing variables.
   */
  assignParentKeyToWriting (type, key) {
    const w = this.answerFor('writing') as WritingDocument
    const mk = ['accounts', this.accountID(), type, key].join('/')
    w.slotManager.setSlotVariable('modelKey', mk)
  }

  @memoize()
  get parent (): KnockoutObservable<CrudModel> {
    const [collection, keyID] = this.parentKey() || []
    if (!collection) { return null }
    const Cls = getClassByResourceName(collection)
    const o = ko.observable()
    const accountID = this.authManager.accountID()
    const key = keyPathForModel(accountID, keyID, collection)
    Cls.vmGet(this.authManager, key).then(o)
    return o
  }

  /**
   * Returns e.g. `[entity, abc, writing]`
   */
  get vmFirestoreIntra () {
    return [...this.parentKey(), ...super.vmFirestoreIntra]
  }

  vmCreateMediatorInstances () {
    return [
      ...super.vmCreateMediatorInstances(),
      new ModelKeyMediator('parentKey', this),
    ]
  }
}

WritingModel.register()
