/**
 * 🚨 - WARNING -
 * We need to come up with unique identifiers for the jurisidctions since
 * e.g. `Federal` is used by numerous states.
 */
import './amount-of-currency-input'
import './currency-picker'
import './jurisdiction-flag'
import './jurisdiction-picker'
import './jurisdiction-indicator'

import { lookup, JURISDICTIONS, flagUrlOf } from './lookup'


export { JURISDICTIONS, lookup, flagUrlOf }
export { colorOf } from './utils'
