
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class Trademark extends UnitView {
  get elvValue () { return ko.unwrap(this.description) }

  get fields () {
    return {
      description: 'string',
      status: 'string',
      // Status: None; Preliminary Search; Application filed; TMO Examination; Publication of Application; Receipt of Opposition; Registered
      agent: 'person',
      expiry: 'pureDate',
      renewal: 'pureDate',
      jurisdiction: 'string'
    }
  }

  * getRoles () : IterableIterator<PersonRoleRecord> {
    yield { title: 'Trademark Agent' }
  }
}

export default class TrademarksComponent extends ArrayComponent {
  get ItemClass () { return Trademark }
}

TrademarksComponent.register()
