/**
* ————————————————————————————————————————————————————
* THIS FILE IS COMPILED WITH 'rebuild.js'. DO NOT EDIT
* ————————————————————————————————————————————————————
*
* Last updated: Mon Aug 19 2019 11:17:04 GMT-0400 (Eastern Daylight Time)
*/
export default [
  ["Andorra","AD","",[]],
  ["United Arab Emirates","AE","",[]],
  ["Afghanistan","AF","",[]],
  ["Antigua and Barbuda","AG","",[]],
  ["Anguilla","AI","",[]],
  ["Albania","AL","",[]],
  ["Armenia","AM","",[]],
  ["Angola","AO","",[]],
  ["Antarctica","AQ","",[]],
  ["Argentina","AR","",[]],
  ["American Samoa","AS","",[]],
  ["Austria","AT","",[]],
  ["Australia","AU","",[]],
  ["Azerbaijan","AZ","",[]],
  ["Bosnia and Herzegovina","BA","",[]],
  ["Barbados","BB","",[]],
  ["Bangladesh","BD","",[]],
  ["Belgium","BE","",[]],
  ["Burkina Faso","BF","",[]],
  ["Bulgaria","BG","",[]],
  ["Bahrain","BH","",[]],
  ["Burundi","BI","",[]],
  ["Benin","BJ","",[]],
  ["Bermuda","BM","",[]],
  ["Brunei","BN","",[]],
  ["Bolivia","BO","",[]],
  ["Caribbean Netherlands","BQ","",[]],
  ["Brazil","BR","",[]],
  ["The Bahamas","BS","",[]],
  ["Bhutan","BT","",[]],
  ["Botswana","BW","",[]],
  ["Belarus","BY","",[]],
  ["Belize","BZ","",[]],
  ["Canada","CA","",["CBCA"],1],
  ["Democratic Republic of the Congo","CD","",[]],
  ["Central African Republic","CF","",[]],
  ["Republic of the Congo","CG","",[]],
  ["Switzerland","CH","",[]],
  ["Ivory Coast","CI","",[]],
  ["Cook Islands","CK","",[]],
  ["Chile","CL","",[]],
  ["Cameroon","CM","",[]],
  ["China","CN","",[]],
  ["Colombia","CO","",[]],
  ["Costa Rica","CR","",[]],
  ["Cuba","CU","",[]],
  ["Cape Verde","CV","",[]],
  ["Cyprus","CY","",[]],
  ["Czech Republic","CZ","",[]],
  ["Germany","DE","",[]],
  ["Djibouti","DJ","",[]],
  ["Denmark","DK","",[]],
  ["Dominica","DM","",[]],
  ["Dominican Republic","DO","",[]],
  ["Algeria","DZ","",[]],
  ["Ecuador","EC","",[]],
  ["Estonia","EE","",[]],
  ["Egypt","EG","",[]],
  ["Eritrea","ER","",[]],
  ["Spain","ES","",[]],
  ["Ethiopia","ET","",[]],
  ["Finland","FI","",[]],
  ["Fiji","FJ","",[]],
  ["Falkland Islands","FK","",[]],
  ["Federated States of Micronesia","FM","",[]],
  ["Faroe Islands","FO","",[]],
  ["France","FR","",[]],
  ["Gabon","GA","",[]],
  ["United Kingdom","GB","",[]],
  ["Grenada","GD","",[]],
  ["Georgia","GE","",[]],
  ["French Guiana","GF","",[]],
  ["Guernsey","GG","",[]],
  ["Ghana","GH","",[]],
  ["Gibraltar","GI","",[]],
  ["Greenland","GL","",[]],
  ["The Gambia","GM","",[]],
  ["Guinea","GN","",[]],
  ["Guadeloupe","GP","",[]],
  ["Equatorial Guinea","GQ","",[]],
  ["Greece","GR","",[]],
  ["South Georgia and the South Sandwich Islands","GS","",[]],
  ["Guatemala","GT","",[]],
  ["Guam","GU","",[]],
  ["Guinea-Bissau","GW","",[]],
  ["Guyana","GY","",[]],
  ["Hong Kong","HK","",[]],
  ["Honduras","HN","",[]],
  ["Croatia","HR","",[]],
  ["Haiti","HT","",[]],
  ["Hungary","HU","",[]],
  ["Dutch East Indies","ID","",[]],
  ["Ireland","IE","",[]],
  ["Israel","IL","",[]],
  ["Isle of Man","IM","",[]],
  ["India","IN","",[]],
  ["British Indian Ocean Territory","IO","",[]],
  ["Iraq","IQ","",[]],
  ["Iran","IR","",[]],
  ["Iceland","IS","",[]],
  ["Italy","IT","",[]],
  ["Jersey","JE","",[]],
  ["Jamaica","JM","",[]],
  ["Jordan","JO","",[]],
  ["Japan","JP","",[]],
  ["Kenya","KE","",[]],
  ["Kyrgyzstan","KG","",[]],
  ["Cambodia","KH","",[]],
  ["Kiribati","KI","",[]],
  ["Comoros","KM","",[]],
  ["Saint Kitts and Nevis","KN","",[]],
  ["Joseon","KP","",[]],
  ["Korea","KR","",[]],
  ["Kuwait","KW","",[]],
  ["Cayman Islands","KY","",[]],
  ["Kazakhstan","KZ","",[]],
  ["Laos","LA","",[]],
  ["Lebanon","LB","",[]],
  ["Saint Lucia","LC","",[]],
  ["Liechtenstein","LI","",[]],
  ["Sri Lanka","LK","",[]],
  ["Liberia","LR","",[]],
  ["Lesotho","LS","",[]],
  ["Lithuania","LT","",[]],
  ["Luxembourg","LU","",[]],
  ["Latvia","LV","",[]],
  ["Libya","LY","",[]],
  ["Morocco","MA","",[]],
  ["Monaco","MC","",[]],
  ["Moldova","MD","",[]],
  ["Montenegro","ME","",[]],
  ["Madagascar","MG","",[]],
  ["Marshall Islands","MH","",[]],
  ["Republic of Macedonia","MK","",[]],
  ["Mali","ML","",[]],
  ["Burma","MM","",[]],
  ["Mongolia","MN","",[]],
  ["Macau","MO","",[]],
  ["Northern Mariana Islands","MP","",[]],
  ["Martinique","MQ","",[]],
  ["Mauritania","MR","",[]],
  ["Montserrat","MS","",[]],
  ["Malta","MT","",[]],
  ["Mauritius","MU","",[]],
  ["Maldives","MV","",[]],
  ["Malawi","MW","",[]],
  ["Mexico","MX","",[]],
  ["Malaysia","MY","",[]],
  ["Mozambique","MZ","",[]],
  ["Namibia","NA","",[]],
  ["Niger","NE","",[]],
  ["Nigeria","NG","",[]],
  ["Nicaragua","NI","",[]],
  ["Netherlands","NL","",[]],
  ["Norway","NO","",[]],
  ["Nepal","NP","",[]],
  ["Nauru","NR","",[]],
  ["Niue","NU","",[]],
  ["New Zealand","NZ","",[]],
  ["Oman","OM","",[]],
  ["Panama","PA","",[]],
  ["Peru","PE","",[]],
  ["Papua New Guinea","PG","",[]],
  ["Philippines","PH","",[]],
  ["Pakistan","PK","",[]],
  ["Poland","PL","",[]],
  ["Saint Pierre and Miquelon","PM","",[]],
  ["Pitcairn Islands","PN","",[]],
  ["Puerto Rico","PR","",[]],
  ["Palestine","PS","",[]],
  ["Portugal","PT","",[]],
  ["Palau","PW","",[]],
  ["Paraguay","PY","",[]],
  ["Qatar","QA","",[]],
  ["Réunion","RE","",[]],
  ["Romania","RO","",[]],
  ["Serbia","RS","",[]],
  ["Russia","RU","",[]],
  ["Rwanda","RW","",[]],
  ["Saudi Arabia","SA","",[]],
  ["Solomon Islands","SB","",[]],
  ["Seychelles","SC","",[]],
  ["Sudan","SD","",[]],
  ["South Sudan","SS","",[]],
  ["Sweden","SE","",[]],
  ["Singapore","SG","",[]],
  ["Saint Helena","SH","",[]],
  ["Slovenia","SI","",[]],
  ["Slovakia","SK","",[]],
  ["Sierra Leone","SL","",[]],
  ["San Marino","SM","",[]],
  ["Senegal","SN","",[]],
  ["Somalia","SO","",[]],
  ["Suriname","SR","",[]],
  ["São Tomé and Príncipe","ST","",[]],
  ["El Salvador","SV","",[]],
  ["Syria","SY","",[]],
  ["Swaziland","SZ","",[]],
  ["Turks and Caicos Islands","TC","",[]],
  ["Chad","TD","",[]],
  ["French Southern and Antarctic Lands","TF","",[]],
  ["Togo","TG","",[]],
  ["Thailand","TH","",[]],
  ["Tajikistan","TJ","",[]],
  ["Tokelau","TK","",[]],
  ["East Timor","TL","",[]],
  ["Turkmenistan","TM","",[]],
  ["Tunisia","TN","",[]],
  ["Tonga","TO","",[]],
  ["Turkey","TR","",[]],
  ["Trinidad and Tobago","TT","",[]],
  ["Tuvalu","TV","",[]],
  ["Taiwan","TW","",[]],
  ["Tanzania","TZ","",[]],
  ["Ukraine","UA","",[]],
  ["Uganda","UG","",[]],
  ["United States Minor Outlying Islands","UM","",[]],
  ["United States","US","",[],2],
  ["Uruguay","UY","",[]],
  ["Uzbekistan","UZ","",[]],
  ["Vatican City","VA","",[]],
  ["Saint Vincent and the Grenadines","VC","",[]],
  ["Venezuela","VE","",[]],
  ["British Virgin Islands","VG","",[]],
  ["United States Virgin Islands","VI","",[]],
  ["Vietnam","VN","",[]],
  ["Vanuatu","VU","",[]],
  ["Wallis and Futuna","WF","",[]],
  ["Samoa","WS","",[]],
  ["Yemen","YE","",[]],
  ["Mayotte","YT","",[]],
  ["South Africa","ZA","",[]],
  ["Zambia","ZM","",[]],
  ["Zimbabwe","ZW","",[]],
  ["Sant Julià de Lòria","AD.06","AD",[]],
  ["Ordino","AD.05","AD",[]],
  ["La Massana","AD.04","AD",[]],
  ["Encamp","AD.03","AD",[]],
  ["Canillo","AD.02","AD",[]],
  ["Andorra la Vella","AD.07","AD",[]],
  ["Escaldes-Engordany","AD.08","AD",[]],
  ["Umm al-Quwain","AE.UQ","AE",[]],
  ["Ras al-Khaimah","AE.RK","AE",[]],
  ["Dubai","AE.DU","AE",[]],
  ["Sharjah","AE.SH","AE",[]],
  ["Fujairah","AE.FU","AE",[]],
  ["Ajman","AE.AJ","AE",[]],
  ["Abu Dhabi","AE.AZ","AE",[]],
  ["Zabul Province","AF.ZAB","AF",[]],
  ["Maidan Wardak Province","AF.WAR","AF",[]],
  ["Takhar Province","AF.TAK","AF",[]],
  ["Sar-e Pol Province","AF.SAR","AF",[]],
  ["Samangan Province","AF.SAM","AF",[]],
  ["Parwan Province","AF.PAR","AF",[]],
  ["Paktika Province","AF.PKA","AF",[]],
  ["Paktia Province","AF.PIA","AF",[]],
  ["Oruzgan","AF.ORU","AF",[]],
  ["Urozgan Province","AF.URU","AF",[]],
  ["Nimruz Province","AF.NIM","AF",[]],
  ["Nangarhar Province","AF.NAN","AF",[]],
  ["Logar Province","AF.LOG","AF",[]],
  ["Laghman Province","AF.LAG","AF",[]],
  ["Kunduz Province","AF.KDZ","AF",[]],
  ["Kunar Province","AF.KNR","AF",[]],
  ["Kapisa Province","AF.KAP","AF",[]],
  ["Kandahar Province","AF.KAN","AF",[]],
  ["Kabul Province","AF.KAB","AF",[]],
  ["Jowzjan Province","AF.JOW","AF",[]],
  ["Herat Province","AF.HER","AF",[]],
  ["Helmand Province","AF.HEL","AF",[]],
  ["Ghōr Province","AF.GHO","AF",[]],
  ["Ghazni Province","AF.GHA","AF",[]],
  ["Faryab Province","AF.FYB","AF",[]],
  ["Farah Province","AF.FRA","AF",[]],
  ["Bamyan Province","AF.BAM","AF",[]],
  ["Balkh Province","AF.BAL","AF",[]],
  ["Baghlan Province","AF.BGL","AF",[]],
  ["Badghis Province","AF.BDG","AF",[]],
  ["Badakhshan Province","AF.BDS","AF",[]],
  ["Khost Province","AF.KHO","AF",[]],
  ["Nuristan Province","AF.NUR","AF",[]],
  ["Daykundi Province","AF.DAY","AF",[]],
  ["Panjshir Province","AF.PAN","AF",[]],
  ["Saint Philip Parish, Antigua and Barbuda","AG.08","AG",[]],
  ["Saint Peter Parish, Antigua and Barbuda","AG.07","AG",[]],
  ["Saint Paul Parish, Antigua and Barbuda","AG.06","AG",[]],
  ["Saint Mary Parish, Antigua and Barbuda","AG.05","AG",[]],
  ["Saint John Parish, Antigua and Barbuda","AG.04","AG",[]],
  ["Saint George Parish, Antigua and Barbuda","AG.03","AG",[]],
  ["Redonda","AG.11","AG",[]],
  ["Barbuda","AG.10","AG",[]],
  ["Berat","AL.BR","AL",[]],
  ["Diber","AL.DI","AL",[]],
  ["Elbasan","AL.EL","AL",[]],
  ["Gjirokaster","AL.GJ","AL",[]],
  ["Korçë County","AL.06","AL",[]],
  ["Kukës County","AL.07","AL",[]],
  ["Durrës County","AL.02","AL",[]],
  ["Fier County","AL.04","AL",[]],
  ["Lezhe","AL.LE","AL",[]],
  ["Shkodër County","AL.10","AL",[]],
  ["Tirana County","AL.11","AL",[]],
  ["Vlore","AL.VL","AL",[]],
  ["Ararat Province","AM.AR","AM",[]],
  ["Syunik Province","AM.SU","AM",[]],
  ["Vayots Dzor Province","AM.VD","AM",[]],
  ["Yerevan","AM.ER","AM",[]],
  ["Aragatsotn Province","AM.AG","AM",[]],
  ["Armavir Province","AM.AV","AM",[]],
  ["Gegharkunik Province","AM.GR","AM",[]],
  ["Kotayk Province","AM.KT","AM",[]],
  ["Lori Province","AM.LO","AM",[]],
  ["Shirak Province","AM.SH","AM",[]],
  ["Tavush Province","AM.TV","AM",[]],
  ["Lunda Sul Province","AO.LSU","AO",[]],
  ["Lunda Norte Province","AO.LNO","AO",[]],
  ["Moxico Province","AO.MOX","AO",[]],
  ["Cuando Cubango Province","AO.CCU","AO",[]],
  ["Zaire Province","AO.ZAI","AO",[]],
  ["Uíge Province","AO.UIG","AO",[]],
  ["Malanje Province","AO.MAL","AO",[]],
  ["Luanda Province","AO.LUA","AO",[]],
  ["Cuanza Norte Province","AO.CNO","AO",[]],
  ["Cabinda Province","AO.CAB","AO",[]],
  ["Bengo Province","AO.BGO","AO",[]],
  ["Namibe Province","AO.NAM","AO",[]],
  ["Huíla Province","AO.HUI","AO",[]],
  ["Huambo Province","AO.HUA","AO",[]],
  ["Cunene Province","AO.CNN","AO",[]],
  ["Cuanza Sul Province","AO.CUS","AO",[]],
  ["Bié Province","AO.BIE","AO",[]],
  ["Benguela Province","AO.BGU","AO",[]],
  ["Misiones Province","AR.N","AR",[]],
  ["Formosa Province","AR.P","AR",[]],
  ["Buenos Aires","AR.C","AR",[]],
  ["Entre Ríos Province","AR.E","AR",[]],
  ["Corrientes Province","AR.W","AR",[]],
  ["Buenos Aires Province","AR.B","AR",[]],
  ["Tucumán Province","AR.T","AR",[]],
  ["Tierra del Fuego Province, Argentina","AR.V","AR",[]],
  ["Santiago del Estero Province","AR.G","AR",[]],
  ["Santa Fe Province","AR.S","AR",[]],
  ["Santa Cruz Province, Argentina","AR.Z","AR",[]],
  ["San Luis Province","AR.D","AR",[]],
  ["San Juan Province, Argentina","AR.J","AR",[]],
  ["Salta Province","AR.A","AR",[]],
  ["Río Negro Province","AR.R","AR",[]],
  ["Neuquén Province","AR.Q","AR",[]],
  ["Mendoza Province","AR.M","AR",[]],
  ["La Rioja Province, Argentina","AR.F","AR",[]],
  ["La Pampa Province","AR.L","AR",[]],
  ["Jujuy Province","AR.Y","AR",[]],
  ["Córdoba Province, Argentina","AR.X","AR",[]],
  ["Chubut Province","AR.U","AR",[]],
  ["Chaco Province","AR.H","AR",[]],
  ["Catamarca Province","AR.K","AR",[]],
  ["Western District","AS.W","AS",[]],
  ["Eastern District","AS.E","AS",[]],
  ["Manu'a","AS.M","AS",[]],
  ["Vienna","AT.9","AT",[]],
  ["Vorarlberg","AT.8","AT",[]],
  ["Tyrol","AT.7","AT",[]],
  ["Styria","AT.6","AT",[]],
  ["Salzburg","AT.5","AT",[]],
  ["Upper Austria","AT.4","AT",[]],
  ["Lower Austria","AT.3","AT",[]],
  ["Kärnten","AT.2","AT",[]],
  ["Burgenland","AT.1","AT",[]],
  ["Western Australia","AU.WA","AU",[]],
  ["South Australia","AU.SA","AU",[]],
  ["Northern Territory","AU.NT","AU",[]],
  ["Victoria","AU.VIC","AU",[]],
  ["Tasmania","AU.TAS","AU",[]],
  ["Queensland","AU.QLD","AU",[]],
  ["New South Wales","AU.NSW","AU",[]],
  ["Australian Capital Territory","AU.ACT","AU",[]],
  ["Beylagan District","AZ.BEY","AZ",[]],
  ["Zangilan District","AZ.ZAN","AZ",[]],
  ["Yardymli District","AZ.YAR","AZ",[]],
  ["Shusha District","AZ.SUS","AZ",[]],
  ["Salyan District, Azerbaijan","AZ.SAL","AZ",[]],
  ["Sabirabad District","AZ.SAB","AZ",[]],
  ["Saatly District","AZ.SAT","AZ",[]],
  ["Bilasuvar District","AZ.BIL","AZ",[]],
  ["Neftchala District","AZ.NEF","AZ",[]],
  ["Nakhchivan Autonomous Republic","AZ.NX","AZ",[]],
  ["Masally District","AZ.MAS","AZ",[]],
  ["Lerik District","AZ.LER","AZ",[]],
  ["Lankaran","AZ.LA","AZ",[]],
  ["Lachin District","AZ.LAC","AZ",[]],
  ["Qubadli District","AZ.QBI","AZ",[]],
  ["Imishli District","AZ.IMI","AZ",[]],
  ["Fuzuli District","AZ.FUZ","AZ",[]],
  ["Jabrayil District","AZ.CAB","AZ",[]],
  ["Jalilabad District","AZ.CAL","AZ",[]],
  ["Astara District","AZ.AST","AZ",[]],
  ["Khojaly District","AZ.XCI","AZ",[]],
  ["Aghjabadi District","AZ.AGC","AZ",[]],
  ["Agdam District","AZ.AGM","AZ",[]],
  ["Shirvan","AZ.SR","AZ",[]],
  ["Lankaran District","AZ.LAN","AZ",[]],
  ["Shusha District","AZ.SUS","AZ",[]],
  ["Tartar District","AZ.TAR","AZ",[]],
  ["Stepanakert","AZ.XA","AZ",[]],
  ["Khojavend District","AZ.XVD","AZ",[]],
  ["Zardab District","AZ.ZAR","AZ",[]],
  ["Zaqatala District","AZ.ZAQ","AZ",[]],
  ["Yevlakh District","AZ.YEV","AZ",[]],
  ["Oghuz District","AZ.OGU","AZ",[]],
  ["Ujar District","AZ.UCA","AZ",[]],
  ["Tovuz District","AZ.TOV","AZ",[]],
  ["Shamakhi District","AZ.SMI","AZ",[]],
  ["Shaki, Azerbaijan","AZ.SA","AZ",[]],
  ["Shamkir District","AZ.SKR","AZ",[]],
  ["Kurdamir District","AZ.KUR","AZ",[]],
  ["Qabala District","AZ.QAB","AZ",[]],
  ["Qusar District","AZ.QUS","AZ",[]],
  ["Quba District","AZ.QBA","AZ",[]],
  ["Goygol District","AZ.GYG","AZ",[]],
  ["Khachmaz District","AZ.XAC","AZ",[]],
  ["Kalbajar District","AZ.KAL","AZ",[]],
  ["Qazakh District","AZ.QAZ","AZ",[]],
  ["Goranboy District","AZ.GOR","AZ",[]],
  ["Qakh District","AZ.QAX","AZ",[]],
  ["Ismailli District","AZ.ISM","AZ",[]],
  ["Goychay District","AZ.GOY","AZ",[]],
  ["Shabran District","AZ.SBN","AZ",[]],
  ["Dashkasan District","AZ.DAS","AZ",[]],
  ["Balakan District","AZ.BAL","AZ",[]],
  ["Barda District","AZ.BAR","AZ",[]],
  ["Baku","AZ.BA","AZ",[]],
  ["Absheron District","AZ.ABS","AZ",[]],
  ["Agsu District","AZ.AGU","AZ",[]],
  ["Agdash District","AZ.AGS","AZ",[]],
  ["Gadabay District","AZ.GAD","AZ",[]],
  ["Agstafa District","AZ.AGA","AZ",[]],
  ["Ganja, Azerbaijan","AZ.GA","AZ",[]],
  ["Mingachevir","AZ.MI","AZ",[]],
  ["Naftalan","AZ.NA","AZ",[]],
  ["Gobustan District","AZ.QOB","AZ",[]],
  ["Samukh District","AZ.SMX","AZ",[]],
  ["Shaki District","AZ.SAK","AZ",[]],
  ["Siazan District","AZ.SIY","AZ",[]],
  ["Sumqayit","AZ.SM","AZ",[]],
  ["Khizi District","AZ.XIZ","AZ",[]],
  ["Yevlakh","AZ.YE","AZ",[]],
  ["Hajigabul District","AZ.HAC","AZ",[]],
  ["Nakhchivan","AZ.NV","AZ",[]],
  ["Federation of Bosnia and Herzegovina","BA.BIH","BA",[]],
  ["Republika Srpska","BA.SRP","BA",[]],
  ["Brčko District","BA.BRC","BA",[]],
  ["Saint Thomas, Barbados","BB.11","BB",[]],
  ["Saint Philip","BB.10","BB",[]],
  ["Saint Peter","BB.09","BB",[]],
  ["Saint Michael, Barbados","BB.08","BB",[]],
  ["Saint Lucy, Barbados","BB.07","BB",[]],
  ["Saint Joseph, Barbados","BB.06","BB",[]],
  ["Saint John","BB.05","BB",[]],
  ["Saint James, Barbados","BB.04","BB",[]],
  ["Saint George","BB.03","BB",[]],
  ["Saint Andrew, Barbados","BB.02","BB",[]],
  ["Christ Church, Barbados","BB.01","BB",[]],
  ["Rajshahi Division","BD.E","BD",[]],
  ["Dhaka Division","BD.C","BD",[]],
  ["Chittagong Division","BD.B","BD",[]],
  ["Khulna Division","BD.D","BD",[]],
  ["Barisal Division","BD.A","BD",[]],
  ["Sylhet Division","BD.G","BD",[]],
  ["Rangpur Division","BD.F","BD",[]],
  ["Mymensingh","BD.H","BD",[]],
  ["Brussels","BE.BRU","BE",[]],
  ["Wallonia","BE.WAL","BE",[]],
  ["Flemish Region","BE.VLG","BE",[]],
  ["Boucle du Mouhoun Region","BF.01","BF",[]],
  ["Cascades Region","BF.02","BF",[]],
  ["Centre Region, Burkina Faso","BF.03","BF",[]],
  ["Centre-Est Region","BF.04","BF",[]],
  ["Centre-Nord Region","BF.05","BF",[]],
  ["Centre-Ouest Region","BF.06","BF",[]],
  ["Centre-Sud Region","BF.07","BF",[]],
  ["Est Region, Burkina Faso","BF.08","BF",[]],
  ["Hauts-Bassins Region","BF.09","BF",[]],
  ["Nord Region, Burkina Faso","BF.10","BF",[]],
  ["Plateau-Central Region","BF.11","BF",[]],
  ["Sahel Region","BF.12","BF",[]],
  ["Sud-Ouest Region","BF.13","BF",[]],
  ["Razgrad Province","BG.17","BG",[]],
  ["Montana Province","BG.12","BG",[]],
  ["Vratsa Province","BG.06","BG",[]],
  ["Varna Province","BG.03","BG",[]],
  ["Dobrich Province","BG.08","BG",[]],
  ["Sofia Province","BG.23","BG",[]],
  ["Ruse Province","BG.18","BG",[]],
  ["Plovdiv Province","BG.16","BG",[]],
  ["Pleven Province","BG.15","BG",[]],
  ["Pernik Province","BG.14","BG",[]],
  ["Pazardzhik Province","BG.13","BG",[]],
  ["Lovech Province","BG.11","BG",[]],
  ["Haskovo Province","BG.26","BG",[]],
  ["Sofia","BG.22","BG",[]],
  ["Burgas Province","BG.02","BG",[]],
  ["Blagoevgrad Province","BG.01","BG",[]],
  ["Gabrovo Province","BG.07","BG",[]],
  ["Kardzhali Province","BG.09","BG",[]],
  ["Kyustendil Province","BG.10","BG",[]],
  ["Shumen Province","BG.27","BG",[]],
  ["Silistra Province","BG.19","BG",[]],
  ["Sliven Province","BG.20","BG",[]],
  ["Smolyan Province","BG.21","BG",[]],
  ["Stara Zagora Province","BG.24","BG",[]],
  ["Targovishte Province","BG.25","BG",[]],
  ["Veliko Tarnovo Province","BG.04","BG",[]],
  ["Vidin Province","BG.05","BG",[]],
  ["Yambol Province","BG.28","BG",[]],
  ["Muharraq Governorate","BH.15","BH",[]],
  ["Capital Governorate, Bahrain","BH.13","BH",[]],
  ["Northern Governorate","BH.17","BH",[]],
  ["Northern","BH.19","BH",[]],
  ["Makamba Province","BI.MA","BI",[]],
  ["Bururi Province","BI.BR","BI",[]],
  ["Muramvya Province","BI.MU","BI",[]],
  ["Gitega Province","BI.GI","BI",[]],
  ["Ruyigi Province","BI.RY","BI",[]],
  ["Cankuzo Province","BI.CA","BI",[]],
  ["Karuzi Province","BI.KR","BI",[]],
  ["Bubanza Province","BI.BB","BI",[]],
  ["Cibitoke Province","BI.CI","BI",[]],
  ["Ngozi Province","BI.NG","BI",[]],
  ["Kayanza Province","BI.KY","BI",[]],
  ["Muyinga Province","BI.MY","BI",[]],
  ["Kirundo Province","BI.KI","BI",[]],
  ["Rutana Province","BI.RT","BI",[]],
  ["Mwaro Province","BI.MW","BI",[]],
  ["Bujumbura","BI.BM","BI",[]],
  ["Bujumbura Rural Province","BI.BL","BI",[]],
  ["Rumonge","BI.RM","BI",[]],
  ["Zou Department","BJ.ZO","BJ",[]],
  ["Ouémé Department","BJ.OU","BJ",[]],
  ["Mono Department","BJ.MO","BJ",[]],
  ["Borgou Department","BJ.BO","BJ",[]],
  ["Atlantique Department","BJ.AQ","BJ",[]],
  ["Atakora Department","BJ.AK","BJ",[]],
  ["Alibori Department","BJ.AL","BJ",[]],
  ["Collines Department","BJ.CO","BJ",[]],
  ["Kouffo Department","BJ.KO","BJ",[]],
  ["Donga Department","BJ.DO","BJ",[]],
  ["Littoral Department","BJ.LI","BJ",[]],
  ["Plateau Department","BJ.PL","BJ",[]],
  ["Warwick","BM.WA","BM",[]],
  ["Southampton","BM.SH","BM",[]],
  ["Smith's Parish","BM.SM","BM",[]],
  ["Sandys","BM.SA","BM",[]],
  ["Saint George's Parish","BM.SG","BM",[]],
  ["Saint George","BM.SGE","BM",[]],
  ["Pembroke","BM.PEM","BM",[]],
  ["Paget","BM.PG","BM",[]],
  ["Hamilton","BM.HA","BM",[]],
  ["Hamilton city","BM.HAM","BM",[]],
  ["Devonshire","BM.DS","BM",[]],
  ["Tutong District","BN.TU","BN",[]],
  ["Temburong District","BN.TE","BN",[]],
  ["Brunei-Muara District","BN.BM","BN",[]],
  ["Belait District","BN.BE","BN",[]],
  ["Tarija Department","BO.T","BO",[]],
  ["Santa Cruz","BO.S","BO",[]],
  ["Potosí Department","BO.P","BO",[]],
  ["Pando Department","BO.N","BO",[]],
  ["Oruro Department","BO.O","BO",[]],
  ["La Paz Department","BO.L","BO",[]],
  ["Cochabamba Department","BO.C","BO",[]],
  ["Chuquisaca Department","BO.H","BO",[]],
  ["Beni Department","BO.B","BO",[]],
  ["Bonaire","BQ.BO","BQ",[]],
  ["Saba","BQ.SA","BQ",[]],
  ["Sint Eustatius","BQ.SE","BQ",[]],
  ["Rio Grande do Norte","BR.RN","BR",[]],
  ["Piauí","BR.PI","BR",[]],
  ["Pernambuco","BR.PE","BR",[]],
  ["Paraíba","BR.PB","BR",[]],
  ["Pará","BR.PA","BR",[]],
  ["Maranhão","BR.MA","BR",[]],
  ["Ceará","BR.CE","BR",[]],
  ["Amapá","BR.AP","BR",[]],
  ["Alagoas","BR.AL","BR",[]],
  ["Sergipe","BR.SE","BR",[]],
  ["São Paulo","BR.SP","BR",[]],
  ["Santa Catarina","BR.SC","BR",[]],
  ["Rio Grande do Sul","BR.RS","BR",[]],
  ["Rio de Janeiro","BR.RJ","BR",[]],
  ["Paraná","BR.PR","BR",[]],
  ["Minas Gerais","BR.MG","BR",[]],
  ["Mato Grosso do Sul","BR.MS","BR",[]],
  ["Mato Grosso","BR.MT","BR",[]],
  ["Goiás","BR.GO","BR",[]],
  ["Federal District","BR.DF","BR",[]],
  ["Espírito Santo","BR.ES","BR",[]],
  ["Bahia","BR.BA","BR",[]],
  ["Tocantins","BR.TO","BR",[]],
  ["Roraima","BR.RR","BR",[]],
  ["Amazonas","BR.AM","BR",[]],
  ["Acre","BR.AC","BR",[]],
  ["Rondônia","BR.RO","BR",[]],
  ["San Salvador Island","BS.SS","BS",[]],
  ["Ragged Island","BS.RI","BS",[]],
  ["Berry Islands","BS.BY","BS",[]],
  ["New Providence","BS.NP","BS",[]],
  ["Mayaguana","BS.MG","BS",[]],
  ["Long Island, Bahamas","BS.LI","BS",[]],
  ["Inagua","BS.IN","BS",[]],
  ["Harbour Island, Bahamas","BS.HI","BS",[]],
  ["Freeport, Bahamas","BS.FP","BS",[]],
  ["Exuma","BS.EX","BS",[]],
  ["Cat Island, Bahamas","BS.CI","BS",[]],
  ["Bimini","BS.BI","BS",[]],
  ["Acklins","BS.AK","BS",[]],
  ["Black Point, Bahamas","BS.BP","BS",[]],
  ["Central Abaco","BS.CO","BS",[]],
  ["Central Andros","BS.CS","BS",[]],
  ["Central Eleuthera","BS.CE","BS",[]],
  ["Crooked Island, Bahamas","BS.CK","BS",[]],
  ["East Grand Bahama","BS.EG","BS",[]],
  ["Grand Cay","BS.GC","BS",[]],
  ["Hope Town","BS.HT","BS",[]],
  ["Mangrove Cay","BS.MC","BS",[]],
  ["Moore's Island","BS.MI","BS",[]],
  ["North Abaco","BS.NO","BS",[]],
  ["North Andros","BS.NS","BS",[]],
  ["North Eleuthera","BS.NE","BS",[]],
  ["Rum Cay","BS.RC","BS",[]],
  ["South Abaco","BS.SO","BS",[]],
  ["South Andros","BS.SA","BS",[]],
  ["South Eleuthera","BS.SE","BS",[]],
  ["Spanish Wells","BS.SW","BS",[]],
  ["West Grand Bahama","BS.WG","BS",[]],
  ["Bumthang District","BT.33","BT",[]],
  ["Chukha District","BT.12","BT",[]],
  ["Dagana District","BT.22","BT",[]],
  ["Tsirang District","BT.21","BT",[]],
  ["Sarpang District","BT.31","BT",[]],
  ["Haa District","BT.13","BT",[]],
  ["Lhuntse District","BT.44","BT",[]],
  ["Mongar District","BT.42","BT",[]],
  ["Paro District","BT.11","BT",[]],
  ["Pemagatshel District","BT.43","BT",[]],
  ["Punakha District","BT.23","BT",[]],
  ["Samtse District","BT.14","BT",[]],
  ["Samdrup Jongkhar District","BT.45","BT",[]],
  ["Zhemgang District","BT.34","BT",[]],
  ["Trashigang District","BT.41","BT",[]],
  ["Thimphu District","BT.15","BT",[]],
  ["Trongsa District","BT.32","BT",[]],
  ["Wangdue Phodrang District","BT.24","BT",[]],
  ["Gasa District","BT.GA","BT",[]],
  ["Trashiyangtse District","BT.TY","BT",[]],
  ["Southern District","BW.SO","BW",[]],
  ["South-East District","BW.SE","BW",[]],
  ["North-East District","BW.NE","BW",[]],
  ["North-West District","BW.NW","BW",[]],
  ["Kweneng District","BW.KW","BW",[]],
  ["Kgatleng District","BW.KL","BW",[]],
  ["Kgalagadi District","BW.KG","BW",[]],
  ["Ghanzi District","BW.GH","BW",[]],
  ["Central District","BW.CE","BW",[]],
  ["Vitebsk Region","BY.VI","BY",[]],
  ["Mogilev Region","BY.MA","BY",[]],
  ["Minsk Region","BY.MI","BY",[]],
  ["Minsk","BY.HM","BY",[]],
  ["Grodno Region","BY.HR","BY",[]],
  ["Gomel Region","BY.HO","BY",[]],
  ["Brest Region","BY.BR","BY",[]],
  ["Toledo District","BZ.TOL","BZ",[]],
  ["Stann Creek District","BZ.SC","BZ",[]],
  ["Orange Walk District","BZ.OW","BZ",[]],
  ["Corozal District","BZ.CZL","BZ",[]],
  ["Cayo District","BZ.CY","BZ",[]],
  ["Belize","BZ.BZ","BZ",[]],
  ["Alberta","CA.AB","CA",["ABCA"],1],
  ["British Columbia","CA.BC","CA",["BCBCA"],1],
  ["Manitoba","CA.MB","CA",["MCA"],1],
  ["New Brunswick","CA.NB","CA",["NBBCA"],1],
  ["Northwest Territories","CA.NT","CA",["NWTBCA"],1],
  ["Nova Scotia","CA.NS","CA",["NSCA"],1],
  ["Nunavut","CA.NU","CA",[],1],
  ["Ontario","CA.ON","CA",["OBCA"],1],
  ["Prince Edward Island","CA.PE","CA",["PEIBCA"],1],
  ["Quebec","CA.QC","CA",["QBCA"],1],
  ["Saskatchewan","CA.SK","CA",["SBCA"],1],
  ["Yukon","CA.YT","CA",["YBCA"],1],
  ["Newfoundland and Labrador","CA.NL","CA",["NLCA"],1],
  ["Tshuapa","CD.TU","CD",[]],
  ["Tshopo","CD.TO","CD",[]],
  ["Tanganyika","CD.TA","CD",[]],
  ["South Kivu","CD.SK","CD",[]],
  ["Sankuru","CD.SA","CD",[]],
  ["North Kivu","CD.NK","CD",[]],
  ["Mongala","CD.MO","CD",[]],
  ["Maniema","CD.MA","CD",[]],
  ["Kasaï Central","CD.KC","CD",[]],
  ["Kasaï-Oriental","CD.KE","CD",[]],
  ["Kasaï","CD.KS","CD",[]],
  ["Ituri","CD.IT","CD",[]],
  ["Haut-Uélé","CD.HU","CD",[]],
  ["Haut-Lomami","CD.HL","CD",[]],
  ["Équateur","CD.EQ","CD",[]],
  ["Bas-Uélé","CD.BU","CD",[]],
  ["Lualaba","CD.LU","CD",[]],
  ["Mai-Ndombe","CD.MN","CD",[]],
  ["Kwilu","CD.KL","CD",[]],
  ["Kwango","CD.KG","CD",[]],
  ["Kinshasa","CD.KN","CD",[]],
  ["Kongo Central","CD.BC","CD",[]],
  ["Haut-Katanga","CD.HK","CD",[]],
  ["Lomami","CD.LO","CD",[]],
  ["Nord-Ubangi","CD.NU","CD",[]],
  ["Sud-Ubangi","CD.SU","CD",[]],
  ["Vakaga","CF.VK","CF",[]],
  ["Ouaka","CF.UK","CF",[]],
  ["Mbomou","CF.MB","CF",[]],
  ["Haut-Mbomou","CF.HM","CF",[]],
  ["Haute-Kotto","CF.HK","CF",[]],
  ["Basse-Kotto","CF.BK","CF",[]],
  ["Bamingui-Bangoran","CF.BB","CF",[]],
  ["Sangha-Mbaéré","CF.SE","CF",[]],
  ["Ouham-Pendé","CF.OP","CF",[]],
  ["Ouham","CF.AC","CF",[]],
  ["Ombella-M'Poko","CF.MP","CF",[]],
  ["Nana-Mambéré","CF.NM","CF",[]],
  ["Lobaye","CF.LB","CF",[]],
  ["Kémo","CF.KG","CF",[]],
  ["Mambéré-Kadéï","CF.HS","CF",[]],
  ["Nana-Grébizi","CF.KB","CF",[]],
  ["Bangui","CF.BGF","CF",[]],
  ["Sangha Department","CG.13","CG",[]],
  ["Pool Department","CG.12","CG",[]],
  ["Plateaux Department","CG.14","CG",[]],
  ["Niari Department","CG.9","CG",[]],
  ["Likouala Department","CG.7","CG",[]],
  ["Lékoumou Department","CG.2","CG",[]],
  ["Kouilou Department","CG.5","CG",[]],
  ["Cuvette Department","CG.8","CG",[]],
  ["Bouenza Department","CG.11","CG",[]],
  ["Brazzaville","CG.BZV","CG",[]],
  ["Cuvette-Ouest Department","CG.15","CG",[]],
  ["Pointe-Noire","CG.16","CG",[]],
  ["Canton of Zurich","CH.ZH","CH",[]],
  ["Canton of Zug","CH.ZG","CH",[]],
  ["Vaud","CH.VD","CH",[]],
  ["Valais","CH.VS","CH",[]],
  ["Canton of Uri","CH.UR","CH",[]],
  ["Ticino","CH.TI","CH",[]],
  ["Thurgau","CH.TG","CH",[]],
  ["Canton of Solothurn","CH.SO","CH",[]],
  ["Canton of Schwyz","CH.SZ","CH",[]],
  ["Canton of Schaffhausen","CH.SH","CH",[]],
  ["Canton of St. Gallen","CH.SG","CH",[]],
  ["Obwalden","CH.OW","CH",[]],
  ["Nidwalden","CH.NW","CH",[]],
  ["Canton of Neuchâtel","CH.NE","CH",[]],
  ["Canton of Lucerne","CH.LU","CH",[]],
  ["Canton of Jura","CH.JU","CH",[]],
  ["Graubünden","CH.GR","CH",[]],
  ["Canton of Glarus","CH.GL","CH",[]],
  ["Canton of Geneva","CH.GE","CH",[]],
  ["Canton of Fribourg","CH.FR","CH",[]],
  ["Canton of Bern","CH.BE","CH",[]],
  ["Basel-Stadt","CH.BS","CH",[]],
  ["Basel-Landschaft","CH.BL","CH",[]],
  ["Appenzell Ausserrhoden","CH.AR","CH",[]],
  ["Appenzell Innerrhoden","CH.AI","CH",[]],
  ["Aargau","CH.AG","CH",[]],
  ["Yamoussoukro","CI.YM","CI",[]],
  ["Bas-Sassandra","CI.BS","CI",[]],
  ["Comoé","CI.CM","CI",[]],
  ["Denguélé","CI.DN","CI",[]],
  ["Gôh-Djiboua","CI.GD","CI",[]],
  ["Lacs","CI.LC","CI",[]],
  ["Lagunes","CI.LG","CI",[]],
  ["Montagnes","CI.MG","CI",[]],
  ["Sassandra-Marahoué","CI.SM","CI",[]],
  ["Savanes","CI.SV","CI",[]],
  ["Vallée du Bandama","CI.VB","CI",[]],
  ["Woroba","CI.WR","CI",[]],
  ["Zanzan","CI.ZZ","CI",[]],
  ["Abidjan","CI.AB","CI",[]],
  ["Valparaíso Region","CL.VS","CL",[]],
  ["Tarapacá Region","CL.TA","CL",[]],
  ["Santiago Metropolitan Region","CL.RM","CL",[]],
  ["Maule Region","CL.ML","CL",[]],
  ["Los Lagos Region","CL.LL","CL",[]],
  ["Libertador General Bernardo O'Higgins Region","CL.LI","CL",[]],
  ["Coquimbo Region","CL.CO","CL",[]],
  ["Bío Bío Region","CL.BI","CL",[]],
  ["Atacama Region","CL.AT","CL",[]],
  ["Araucanía Region","CL.AR","CL",[]],
  ["Antofagasta Region","CL.AN","CL",[]],
  ["Aysén Region","CL.AI","CL",[]],
  ["Magallanes y la Antártica Chilena Region","CL.MA","CL",[]],
  ["Arica y Parinacota Region","CL.AP","CL",[]],
  ["Los Ríos Region","CL.LR","CL",[]],
  ["Southwest Region","CM.SW","CM",[]],
  ["South Region","CM.SU","CM",[]],
  ["West Region","CM.OU","CM",[]],
  ["Northwest Region","CM.NW","CM",[]],
  ["North Region, Cameroon","CM.NO","CM",[]],
  ["Littoral Region","CM.LT","CM",[]],
  ["Far North Region","CM.EN","CM",[]],
  ["East Region","CM.ES","CM",[]],
  ["Centre","CM.CE","CM",[]],
  ["Adamawa Region","CM.AD","CM",[]],
  ["Tibet Autonomous Region","CN.54","CN",[]],
  ["Qinghai","CN.63","CN",[]],
  ["Xinjiang","CN.65","CN",[]],
  ["Zhejiang","CN.33","CN",[]],
  ["Yunnan","CN.53","CN",[]],
  ["Tianjin","CN.12","CN",[]],
  ["Sichuan","CN.51","CN",[]],
  ["Shanxi","CN.14","CN",[]],
  ["Shanghai","CN.31","CN",[]],
  ["Shandong","CN.37","CN",[]],
  ["Shaanxi","CN.61","CN",[]],
  ["Ningxia","CN.64","CN",[]],
  ["Jiangxi","CN.36","CN",[]],
  ["Jiangsu","CN.32","CN",[]],
  ["Hunan","CN.43","CN",[]],
  ["Hubei","CN.42","CN",[]],
  ["Henan","CN.41","CN",[]],
  ["Hebei","CN.13","CN",[]],
  ["Hainan","CN.46","CN",[]],
  ["Guizhou","CN.52","CN",[]],
  ["Guangxi","CN.45","CN",[]],
  ["Guangdong","CN.44","CN",[]],
  ["Gansu","CN.62","CN",[]],
  ["Fujian","CN.35","CN",[]],
  ["Chongqing","CN.50","CN",[]],
  ["Anhui","CN.34","CN",[]],
  ["Inner Mongolia","CN.15","CN",[]],
  ["Liaoning","CN.21","CN",[]],
  ["Jilin","CN.22","CN",[]],
  ["Heilongjiang","CN.23","CN",[]],
  ["Beijing","CN.11","CN",[]],
  ["Vichada department","CO.VID","CO",[]],
  ["Vaupés department","CO.VAU","CO",[]],
  ["Valle del Cauca department","CO.VAC","CO",[]],
  ["Tolima department","CO.TOL","CO",[]],
  ["Sucre department","CO.SUC","CO",[]],
  ["Santander department","CO.SAN","CO",[]],
  ["Archipelago of San Andrés, Providencia and Santa Catalina","CO.SAP","CO",[]],
  ["Risaralda department","CO.RIS","CO",[]],
  ["Quindío department","CO.QUI","CO",[]],
  ["Putumayo department","CO.PUT","CO",[]],
  ["Norte de Santander department","CO.NSA","CO",[]],
  ["Nariño department","CO.NAR","CO",[]],
  ["Meta department","CO.MET","CO",[]],
  ["Magdalena department","CO.MAG","CO",[]],
  ["La Guajira department","CO.LAG","CO",[]],
  ["Huila department","CO.HUI","CO",[]],
  ["Guaviare department","CO.GUV","CO",[]],
  ["Guainía department","CO.GUA","CO",[]],
  ["Cundinamarca department","CO.CUN","CO",[]],
  ["Córdoba department","CO.COR","CO",[]],
  ["Chocó department","CO.CHO","CO",[]],
  ["Cesar department","CO.CES","CO",[]],
  ["Cauca department","CO.CAU","CO",[]],
  ["Casanare department","CO.CAS","CO",[]],
  ["Caquetá department","CO.CAQ","CO",[]],
  ["Caldas department","CO.CAL","CO",[]],
  ["Boyacá department","CO.BOY","CO",[]],
  ["Bolívar department","CO.BOL","CO",[]],
  ["Bogotá","CO.DC","CO",[]],
  ["Atlántico department","CO.ATL","CO",[]],
  ["Arauca department","CO.ARA","CO",[]],
  ["Antioquia department","CO.ANT","CO",[]],
  ["Amazonas","CO.AMA","CO",[]],
  ["San José Province","CR.SJ","CR",[]],
  ["Puntarenas Province","CR.P","CR",[]],
  ["Limón Province","CR.L","CR",[]],
  ["Heredia Province","CR.H","CR",[]],
  ["Guanacaste Province","CR.G","CR",[]],
  ["Cartago Province","CR.C","CR",[]],
  ["Alajuela Province","CR.A","CR",[]],
  ["Villa Clara Province","CU.05","CU",[]],
  ["Santiago de Cuba Province","CU.13","CU",[]],
  ["Sancti Spíritus Province","CU.07","CU",[]],
  ["Pinar del Río Province","CU.01","CU",[]],
  ["Matanzas Province","CU.04","CU",[]],
  ["Las Tunas Province","CU.10","CU",[]],
  ["Isla de la Juventud","CU.99","CU",[]],
  ["Holguín Province","CU.11","CU",[]],
  ["Guantánamo Province","CU.14","CU",[]],
  ["Granma Province","CU.12","CU",[]],
  ["Havana","CU.03","CU",[]],
  ["Cienfuegos Province","CU.06","CU",[]],
  ["Ciego de Ávila Province","CU.08","CU",[]],
  ["Camagüey Province","CU.09","CU",[]],
  ["Artemisa","CU.AR","CU",[]],
  ["Mayabeque","CU.MA","CU",[]],
  ["Tarrafal, Cape Verde","CV.TA","CV",[]],
  ["São Vicente, Cape Verde","CV.SV","CV",[]],
  ["Santa Catarina","CV.CA","CV",[]],
  ["Sal, Cape Verde","CV.SL","CV",[]],
  ["Ribeira Grande, Cape Verde","CV.RG","CV",[]],
  ["Praia, Cape Verde","CV.PR","CV",[]],
  ["Paul, Cape Verde","CV.PA","CV",[]],
  ["Maio, Cape Verde","CV.MA","CV",[]],
  ["Brava, Cape Verde","CV.BR","CV",[]],
  ["Boa Vista, Cape Verde","CV.BV","CV",[]],
  ["Mosteiros, Cape Verde","CV.MO","CV",[]],
  ["Santa Cruz","CV.CR","CV",[]],
  ["São Domingos, Cape Verde","CV.SD","CV",[]],
  ["São Filipe, Cape Verde","CV.SF","CV",[]],
  ["São Miguel, Cape Verde","CV.SM","CV",[]],
  ["Porto Novo, Cape Verde","CV.PN","CV",[]],
  ["Ribeira Brava, Cape Verde","CV.RB","CV",[]],
  ["Santa Catarina do Fogo, Cape Verde","CV.CF","CV",[]],
  ["São Salvador do Mundo, Cape Verde","CV.SS","CV",[]],
  ["Tarrafal de São Nicolau, Cape Verde","CV.TS","CV",[]],
  ["São Lourenço dos Órgãos, Cape Verde","CV.SO","CV",[]],
  ["Ribeira Grande de Santiago, Cape Verde","CV.RS","CV",[]],
  ["Paphos District","CY.05","CY",[]],
  ["Nicosia District","CY.01","CY",[]],
  ["Limassol District","CY.02","CY",[]],
  ["Larnaca District","CY.03","CY",[]],
  ["Kyrenia District","CY.06","CY",[]],
  ["Famagusta District","CY.04","CY",[]],
  ["Prague","CZ.PR","CZ",[]],
  ["South Moravian Region","CZ.JM","CZ",[]],
  ["South Bohemian Region","CZ.JC","CZ",[]],
  ["Vysočina Region","CZ.VY","CZ",[]],
  ["Karlovy Vary Region","CZ.KA","CZ",[]],
  ["Hradec Králové Region","CZ.KR","CZ",[]],
  ["Liberec Region","CZ.LI","CZ",[]],
  ["Olomouc District","CZ.712","CZ",[]],
  ["Moravian-Silesian Region","CZ.MO","CZ",[]],
  ["Pardubice Region","CZ.PA","CZ",[]],
  ["Plzeň Region","CZ.PL","CZ",[]],
  ["Central Bohemian Region","CZ.ST","CZ",[]],
  ["Ústí nad Labem Region","CZ.US","CZ",[]],
  ["Zlín","CZ.72","CZ",[]],
  ["Thuringia","DE.TH","DE",[]],
  ["Schleswig-Holstein","DE.SH","DE",[]],
  ["Saxony-Anhalt","DE.ST","DE",[]],
  ["Saxony","DE.SN","DE",[]],
  ["Saarland","DE.SL","DE",[]],
  ["Rhineland-Palatinate","DE.RP","DE",[]],
  ["North Rhine-Westphalia","DE.NW","DE",[]],
  ["Lower Saxony","DE.NI","DE",[]],
  ["Mecklenburg-Vorpommern","DE.MV","DE",[]],
  ["Hesse","DE.HE","DE",[]],
  ["Hamburg","DE.HH","DE",[]],
  ["Bremen","DE.HB","DE",[]],
  ["Brandenburg","DE.BB","DE",[]],
  ["Berlin","DE.BE","DE",[]],
  ["Bavaria","DE.BY","DE",[]],
  ["Baden-Württemberg","DE.BW","DE",[]],
  ["Tadjourah Region","DJ.TA","DJ",[]],
  ["Obock Region","DJ.OB","DJ",[]],
  ["Djibouti","DJ.DJ","DJ",[]],
  ["Dikhil Region","DJ.DI","DJ",[]],
  ["Ali Sabieh Region","DJ.AS","DJ",[]],
  ["Arta Region","DJ.AR","DJ",[]],
  ["Capital Region of Denmark","DK.84","DK",[]],
  ["Central Denmark Region","DK.82","DK",[]],
  ["North Denmark Region","DK.81","DK",[]],
  ["Region Zealand","DK.85","DK",[]],
  ["Region of Southern Denmark","DK.83","DK",[]],
  ["Saint Peter","DM.11","DM",[]],
  ["Saint Paul","DM.10","DM",[]],
  ["Saint Patrick Parish, Dominica","DM.09","DM",[]],
  ["Saint Mark Parish, Dominica","DM.08","DM",[]],
  ["Saint Luke Parish, Dominica","DM.07","DM",[]],
  ["Saint Joseph","DM.06","DM",[]],
  ["Saint John","DM.05","DM",[]],
  ["Saint George","DM.04","DM",[]],
  ["Saint David Parish, Dominica","DM.03","DM",[]],
  ["Saint Andrew","DM.02","DM",[]],
  ["Valverde Province","DO.27","DO",[]],
  ["Santiago Rodríguez Province","DO.26","DO",[]],
  ["Santiago Province","DO.25","DO",[]],
  ["San Pedro de Macorís Province","DO.23","DO",[]],
  ["San Juan","DO.22","DO",[]],
  ["San Cristóbal Province","DO.21","DO",[]],
  ["Sánchez Ramírez Province","DO.24","DO",[]],
  ["Samaná Province","DO.20","DO",[]],
  ["Hermanas Mirabal","DO.19","DO",[]],
  ["Puerto Plata Province","DO.18","DO",[]],
  ["Peravia Province","DO.17","DO",[]],
  ["Pedernales Province","DO.16","DO",[]],
  ["Distrito Nacional","DO.01","DO",[]],
  ["Monte Plata Province","DO.29","DO",[]],
  ["Monte Cristi Province","DO.15","DO",[]],
  ["Monseñor Nouel Province","DO.28","DO",[]],
  ["María Trinidad Sánchez Province","DO.14","DO",[]],
  ["La Vega Province","DO.13","DO",[]],
  ["La Romana Province","DO.12","DO",[]],
  ["La Altagracia Province","DO.11","DO",[]],
  ["Independencia Province","DO.10","DO",[]],
  ["Hato Mayor Province","DO.30","DO",[]],
  ["Espaillat Province","DO.09","DO",[]],
  ["El Seibo Province","DO.08","DO",[]],
  ["Elías Piña Province","DO.07","DO",[]],
  ["Duarte Province","DO.06","DO",[]],
  ["Dajabón Province","DO.05","DO",[]],
  ["Barahona Province","DO.04","DO",[]],
  ["Baoruco","DO.03","DO",[]],
  ["Azua Province","DO.02","DO",[]],
  ["San José de Ocoa Province","DO.31","DO",[]],
  ["Santo Domingo Province","DO.32","DO",[]],
  ["Tlemcen Province","DZ.13","DZ",[]],
  ["Tizi Ouzou Province","DZ.15","DZ",[]],
  ["Tissemsilt Province","DZ.38","DZ",[]],
  ["Tipaza Province","DZ.42","DZ",[]],
  ["Tindouf Province","DZ.37","DZ",[]],
  ["Tiaret Province","DZ.14","DZ",[]],
  ["Tébessa Province","DZ.12","DZ",[]],
  ["Tamanrasset Province","DZ.11","DZ",[]],
  ["Souk Ahras Province","DZ.41","DZ",[]],
  ["Skikda Province","DZ.21","DZ",[]],
  ["Sidi Bel Abbès Province","DZ.22","DZ",[]],
  ["Sétif Province","DZ.19","DZ",[]],
  ["Saïda Province","DZ.20","DZ",[]],
  ["Relizane Province","DZ.48","DZ",[]],
  ["Oum El Bouaghi Province","DZ.04","DZ",[]],
  ["Ouargla Province","DZ.30","DZ",[]],
  ["Oran Province","DZ.31","DZ",[]],
  ["Naâma Province","DZ.45","DZ",[]],
  ["M'Sila Province","DZ.28","DZ",[]],
  ["Mostaganem Province","DZ.27","DZ",[]],
  ["Mila Province","DZ.43","DZ",[]],
  ["Médéa Province","DZ.26","DZ",[]],
  ["Mascara Province","DZ.29","DZ",[]],
  ["Laghouat Province","DZ.03","DZ",[]],
  ["Khenchela Province","DZ.40","DZ",[]],
  ["Jijel Province","DZ.18","DZ",[]],
  ["Illizi Province","DZ.33","DZ",[]],
  ["Guelma Province","DZ.24","DZ",[]],
  ["Ghardaïa Province","DZ.47","DZ",[]],
  ["El Taref Province","DZ.36","DZ",[]],
  ["El Oued Province","DZ.39","DZ",[]],
  ["El Bayadh Province","DZ.32","DZ",[]],
  ["Djelfa Province","DZ.17","DZ",[]],
  ["Constantine Province","DZ.25","DZ",[]],
  ["Chlef Province","DZ.02","DZ",[]],
  ["Boumerdès Province","DZ.35","DZ",[]],
  ["Bouïra Province","DZ.10","DZ",[]],
  ["Bordj Bou Arréridj Province","DZ.34","DZ",[]],
  ["Blida Province","DZ.09","DZ",[]],
  ["Biskra Province","DZ.07","DZ",[]],
  ["Béjaïa Province","DZ.06","DZ",[]],
  ["Béchar Province","DZ.08","DZ",[]],
  ["Batna Province","DZ.05","DZ",[]],
  ["Annaba Province","DZ.23","DZ",[]],
  ["Algiers Province","DZ.16","DZ",[]],
  ["Aïn Témouchent Province","DZ.46","DZ",[]],
  ["Aïn Defla Province","DZ.44","DZ",[]],
  ["Adrar Province","DZ.01","DZ",[]],
  ["Zamora-Chinchipe Province","EC.Z","EC",[]],
  ["Tungurahua Province","EC.T","EC",[]],
  ["Pichincha Province","EC.P","EC",[]],
  ["Pastaza Province","EC.Y","EC",[]],
  ["Napo Province","EC.N","EC",[]],
  ["Morona-Santiago Province","EC.S","EC",[]],
  ["Manabí Province","EC.M","EC",[]],
  ["Los Rios","EC.R","EC",[]],
  ["Loja Province","EC.L","EC",[]],
  ["Imbabura Province","EC.I","EC",[]],
  ["Guayas Province","EC.G","EC",[]],
  ["Galápagos Province","EC.W","EC",[]],
  ["Esmeraldas Province","EC.E","EC",[]],
  ["El Oro Province","EC.O","EC",[]],
  ["Cotopaxi Province","EC.X","EC",[]],
  ["Chimborazo Province","EC.H","EC",[]],
  ["Carchi Province","EC.C","EC",[]],
  ["Cañar Province","EC.F","EC",[]],
  ["Bolivar","EC.B","EC",[]],
  ["Azuay Province","EC.A","EC",[]],
  ["Sucumbíos Province","EC.U","EC",[]],
  ["Orellana Province","EC.D","EC",[]],
  ["Santo Domingo de los Tsáchilas Province","EC.SD","EC",[]],
  ["Santa Elena Province","EC.SE","EC",[]],
  ["Võru County","EE.86","EE",[]],
  ["Viljandi County","EE.84","EE",[]],
  ["Valga County","EE.82","EE",[]],
  ["Tartu County","EE.78","EE",[]],
  ["Saare County","EE.74","EE",[]],
  ["Rapla County","EE.70","EE",[]],
  ["Põlva County","EE.65","EE",[]],
  ["Pärnu County","EE.67","EE",[]],
  ["Lääne-Viru County","EE.59","EE",[]],
  ["Lääne County","EE.57","EE",[]],
  ["Jõgeva County","EE.49","EE",[]],
  ["Järva County","EE.51","EE",[]],
  ["Ida-Viru County","EE.44","EE",[]],
  ["Hiiu County","EE.39","EE",[]],
  ["Harju County","EE.37","EE",[]],
  ["Sohag Governorate","EG.SHG","EG",[]],
  ["North Sinai Governorate","EG.SIN","EG",[]],
  ["Qena Governorate","EG.KN","EG",[]],
  ["Matrouh Governorate","EG.MT","EG",[]],
  ["Kafr el-Sheikh Governorate","EG.KFS","EG",[]],
  ["South Sinai Governorate","EG.JS","EG",[]],
  ["Damietta Governorate","EG.DT","EG",[]],
  ["Port Said Governorate","EG.PTS","EG",[]],
  ["Beni Suef Governorate","EG.BNS","EG",[]],
  ["Asyut Governorate","EG.AST","EG",[]],
  ["Aswan Governorate","EG.ASN","EG",[]],
  ["Suez Governorate","EG.SUZ","EG",[]],
  ["Al Sharqia Governorate","EG.SHR","EG",[]],
  ["New Valley Governorate","EG.WAD","EG",[]],
  ["Qalyubia Governorate","EG.KB","EG",[]],
  ["Cairo Governorate","EG.C","EG",[]],
  ["Minya Governorate","EG.MN","EG",[]],
  ["Monufia Governorate","EG.MNF","EG",[]],
  ["Giza Governorate","EG.GZ","EG",[]],
  ["Al Ismāʿīlīyyah","EG.IS","EG",[]],
  ["Alexandria Governorate","EG.ALX","EG",[]],
  ["Gharbia Governorate","EG.GH","EG",[]],
  ["Faiyum Governorate","EG.FYM","EG",[]],
  ["Beheira Governorate","EG.BH","EG",[]],
  ["Red Sea Governorate","EG.BA","EG",[]],
  ["Dakahlia Governorate","EG.DK","EG",[]],
  ["Luxor Governorate","EG.LX","EG",[]],
  ["Anseba Region","ER.AN","ER",[]],
  ["Debub Region","ER.DU","ER",[]],
  ["Southern Red Sea Region","ER.DK","ER",[]],
  ["Gash-Barka Region","ER.GB","ER",[]],
  ["Maekel Region","ER.MA","ER",[]],
  ["Northern Red Sea Region","ER.SK","ER",[]],
  ["Region of Murcia","ES.MU","ES",[]],
  ["Ceuta","ES.CE","ES",[]],
  ["Balears","ES.PM","ES",[]],
  ["Andalusia","ES.AN","ES",[]],
  ["Canary Islands","ES.CN","ES",[]],
  ["Castilla-La Mancha","ES.CM","ES",[]],
  ["Extremadura","ES.EX","ES",[]],
  ["Valencian Community","ES.VC","ES",[]],
  ["Asturias","ES.AS","ES",[]],
  ["Navarre","ES.NC","ES",[]],
  ["Community of Madrid","ES.MD","ES",[]],
  ["La Rioja","ES.LO","ES",[]],
  ["Cantabria","ES.CB","ES",[]],
  ["Aragon","ES.AR","ES",[]],
  ["Castile and León","ES.CL","ES",[]],
  ["Catalonia","ES.CT","ES",[]],
  ["Galicia","ES.GA","ES",[]],
  ["Basque Country","ES.PV","ES",[]],
  ["Melilla","ES.ML","ES",[]],
  ["Addis Ababa","ET.AA","ET",[]],
  ["Afar Region","ET.AF","ET",[]],
  ["Amhara Region","ET.AM","ET",[]],
  ["Benishangul-Gumuz Region","ET.BE","ET",[]],
  ["Dire Dawa","ET.DD","ET",[]],
  ["Gambela Region","ET.GA","ET",[]],
  ["Harari Region","ET.HA","ET",[]],
  ["Oromia Region","ET.OR","ET",[]],
  ["Somali Region","ET.SO","ET",[]],
  ["Tigray Region","ET.TI","ET",[]],
  ["Southern Nations, Nationalities, and Peoples' Region","ET.SN","ET",[]],
  ["Lapland","FI.10","FI",[]],
  ["Kainuu","FI.05","FI",[]],
  ["Northern Ostrobothnia","FI.14","FI",[]],
  ["Central Ostrobothnia","FI.07","FI",[]],
  ["Ostrobothnia","FI.12","FI",[]],
  ["Southern Ostrobothnia","FI.03","FI",[]],
  ["Central Finland","FI.08","FI",[]],
  ["North Karelia","FI.13","FI",[]],
  ["Northern Savonia","FI.15","FI",[]],
  ["Southern Savonia","FI.04","FI",[]],
  ["South Karelia","FI.02","FI",[]],
  ["Kymenlaakso","FI.09","FI",[]],
  ["Pirkanmaa","FI.11","FI",[]],
  ["Tavastia Proper","FI.06","FI",[]],
  ["Southwest Finland","FI.19","FI",[]],
  ["Uusimaa","FI.18","FI",[]],
  ["Päijänne Tavastia","FI.16","FI",[]],
  ["Satakunta","FI.17","FI",[]],
  ["Western Division, Fiji","FJ.W","FJ",[]],
  ["Northern Division, Fiji","FJ.N","FJ",[]],
  ["Central Division, Fiji","FJ.C","FJ",[]],
  ["Eastern Division, Fiji","FJ.E","FJ",[]],
  ["Rotuma","FJ.R","FJ",[]],
  ["Yap State","FM.YAP","FM",[]],
  ["Pohnpei State","FM.PNI","FM",[]],
  ["Kosrae","FM.KSA","FM",[]],
  ["Chuuk State","FM.TRK","FM",[]],
  ["Vagar","FO.VG","FO",[]],
  ["Nordoyar","FO.NO","FO",[]],
  ["Provence-Alpes-Côte d'Azur","FR.U","FR",[]],
  ["Pays de la Loire","FR.R","FR",[]],
  ["Île-de-France","FR.J","FR",[]],
  ["Corsica","FR.H","FR",[]],
  ["Centre Region, France","FR.F","FR",[]],
  ["Brittany","FR.E","FR",[]],
  ["Bourgogne-Franche-Comté","FR.BFC","FR",[]],
  ["Nouvelle-Aquitaine","FR.NAQ","FR",[]],
  ["Normandie","FR.NOR","FR",[]],
  ["Grand Est","FR.GES","FR",[]],
  ["Occitanie","FR.OCC","FR",[]],
  ["Hauts-de-France","FR.HDF","FR",[]],
  ["Auvergne-Rhône-Alpes","FR.ARA","FR",[]],
  ["Woleu-Ntem Province","GA.9","GA",[]],
  ["Ogooué-Maritime Province","GA.8","GA",[]],
  ["Ogooué-Lolo Province","GA.7","GA",[]],
  ["Ogooué-Ivindo Province","GA.6","GA",[]],
  ["Nyanga Province","GA.5","GA",[]],
  ["Ngounié Province","GA.4","GA",[]],
  ["Moyen-Ogooué Province","GA.3","GA",[]],
  ["Haut-Ogooué Province","GA.2","GA",[]],
  ["Estuaire Province","GA.1","GA",[]],
  ["Wales","GB.WLS","GB",[]],
  ["Scotland","GB.SCT","GB",[]],
  ["Northern Ireland","GB.NIR","GB",[]],
  ["England","GB.ENG","GB",[]],
  ["Saint Patrick","GD.06","GD",[]],
  ["Saint Mark","GD.05","GD",[]],
  ["Saint John","GD.04","GD",[]],
  ["Saint George","GD.03","GD",[]],
  ["Saint David","GD.02","GD",[]],
  ["Saint Andrew","GD.01","GD",[]],
  ["Carriacou and Petite Martinique","GD.10","GD",[]],
  ["Tbilisi","GE.TB","GE",[]],
  ["Adjara","GE.AJ","GE",[]],
  ["Kvemo Kartli","GE.KK","GE",[]],
  ["Kakheti","GE.KA","GE",[]],
  ["Guria","GE.GU","GE",[]],
  ["Imereti","GE.IM","GE",[]],
  ["Shida Kartli","GE.SK","GE",[]],
  ["Mtskheta-Mtianeti","GE.MM","GE",[]],
  ["Racha-Lechkhumi and Kvemo Svaneti","GE.RL","GE",[]],
  ["Samegrelo-Zemo Svaneti","GE.SZ","GE",[]],
  ["Samtskhe-Javakheti","GE.SJ","GE",[]],
  ["Abkhazia","GE.AB","GE",[]],
  ["Western","GH.WP","GH",[]],
  ["Volta Region","GH.TV","GH",[]],
  ["Upper West Region","GH.UW","GH",[]],
  ["Upper East Region","GH.UE","GH",[]],
  ["Northern","GH.NP","GH",[]],
  ["Greater Accra Region","GH.AA","GH",[]],
  ["Eastern","GH.EP","GH",[]],
  ["Central","GH.CP","GH",[]],
  ["Brong-Ahafo Region","GH.BA","GH",[]],
  ["Ashanti Region","GH.AH","GH",[]],
  ["Qaasuitsup Kommunia","GL.QA","GL",[]],
  ["Kommune Kujalleq","GL.KU","GL",[]],
  ["Qeqqata Kommunia","GL.QE","GL",[]],
  ["Kommuneqarfik Sermersooq","GL.SM","GL",[]],
  ["Western","GM.W","GM",[]],
  ["Upper River Division","GM.U","GM",[]],
  ["North Bank Division","GM.N","GM",[]],
  ["Central River Division","GM.M","GM",[]],
  ["Lower River Division","GM.L","GM",[]],
  ["Banjul","GM.B","GM",[]],
  ["Conakry","GN.C","GN",[]],
  ["Boké Region","GN.B","GN",[]],
  ["Faranah Region","GN.F","GN",[]],
  ["Kankan Region","GN.K","GN",[]],
  ["Kindia Region","GN.D","GN",[]],
  ["Labe","GN.LA","GN",[]],
  ["Mamou Region","GN.M","GN",[]],
  ["Nzérékoré Region","GN.N","GN",[]],
  ["Annobón Province","GQ.AN","GQ",[]],
  ["Bioko Norte Province","GQ.BN","GQ",[]],
  ["Bioko Sur Province","GQ.BS","GQ",[]],
  ["Centro Sur Province","GQ.CS","GQ",[]],
  ["Kié-Ntem Province","GQ.KN","GQ",[]],
  ["Litoral Province","GQ.LI","GQ",[]],
  ["Wele-Nzas Province","GQ.WN","GQ",[]],
  ["Mount Athos","GR.69","GR",[]],
  ["Attica","GR.I","GR",[]],
  ["Central Greece","GR.H","GR",[]],
  ["Central Macedonia","GR.B","GR",[]],
  ["Crete","GR.M","GR",[]],
  ["East Macedonia and Thrace","GR.A","GR",[]],
  ["Epirus","GR.D","GR",[]],
  ["Ionian Islands","GR.F","GR",[]],
  ["North Aegean","GR.K","GR",[]],
  ["Peloponnese","GR.J","GR",[]],
  ["South Aegean","GR.L","GR",[]],
  ["Thessaly","GR.E","GR",[]],
  ["West Greece","GR.G","GR",[]],
  ["West Macedonia","GR.C","GR",[]],
  ["Zacapa Department","GT.ZA","GT",[]],
  ["Totonicapán Department","GT.TO","GT",[]],
  ["Suchitepéquez Department","GT.SU","GT",[]],
  ["Sololá Department","GT.SO","GT",[]],
  ["Santa Rosa Department, Guatemala","GT.SR","GT",[]],
  ["San Marcos Department","GT.SM","GT",[]],
  ["Sacatepéquez Department","GT.SA","GT",[]],
  ["Retalhuleu Department","GT.RE","GT",[]],
  ["Quiché Department","GT.QC","GT",[]],
  ["Quetzaltenango Department","GT.QZ","GT",[]],
  ["Petén Department","GT.PE","GT",[]],
  ["Jutiapa Department","GT.JU","GT",[]],
  ["Jalapa Department","GT.JA","GT",[]],
  ["Izabal Department","GT.IZ","GT",[]],
  ["Huehuetenango Department","GT.HU","GT",[]],
  ["Guatemala Department","GT.GU","GT",[]],
  ["Escuintla Department","GT.ES","GT",[]],
  ["El Progreso Department","GT.PR","GT",[]],
  ["Chiquimula Department","GT.CQ","GT",[]],
  ["Chimaltenango Department","GT.CM","GT",[]],
  ["Baja Verapaz Department","GT.BV","GT",[]],
  ["Alta Verapaz Department","GT.AV","GT",[]],
  ["Santa Rita","GU.SR","GU",[]],
  ["Sinajana","GU.SJ","GU",[]],
  ["Talofofo","GU.TF","GU",[]],
  ["Tamuning","GU.TM","GU",[]],
  ["Umatac","GU.UM","GU",[]],
  ["Yigo","GU.YG","GU",[]],
  ["Yona","GU.YN","GU",[]],
  ["Merizo","GU.ME","GU",[]],
  ["Agat","GU.AT","GU",[]],
  ["Dededo","GU.DD","GU",[]],
  ["Inarajan","GU.IN","GU",[]],
  ["Tombali Region","GW.TO","GW",[]],
  ["Quinara Region","GW.QU","GW",[]],
  ["Oio Region","GW.OI","GW",[]],
  ["Gabú Region","GW.GA","GW",[]],
  ["Cacheu Region","GW.CA","GW",[]],
  ["Bolama Region","GW.BL","GW",[]],
  ["Bissau","GW.BS","GW",[]],
  ["Biombo Region","GW.BM","GW",[]],
  ["Bafatá Region","GW.BA","GW",[]],
  ["Upper Takutu-Upper Essequibo","GY.UT","GY",[]],
  ["Upper Demerara-Berbice","GY.UD","GY",[]],
  ["Potaro-Siparuni","GY.PT","GY",[]],
  ["Pomeroon-Supenaam","GY.PM","GY",[]],
  ["Mahaica-Berbice","GY.MA","GY",[]],
  ["Essequibo Islands-West Demerara","GY.ES","GY",[]],
  ["East Berbice-Corentyne","GY.EB","GY",[]],
  ["Demerara-Mahaica","GY.DE","GY",[]],
  ["Cuyuni-Mazaruni","GY.CU","GY",[]],
  ["Barima-Waini","GY.BA","GY",[]],
  ["Kowloon City","HK.KKC","HK",[]],
  ["Yoro Department","HN.YO","HN",[]],
  ["Valle Department","HN.VA","HN",[]],
  ["Santa Bárbara","HN.SB","HN",[]],
  ["Olancho Department","HN.OL","HN",[]],
  ["Ocotepeque Department","HN.OC","HN",[]],
  ["Lempira Department","HN.LE","HN",[]],
  ["La Paz","HN.LP","HN",[]],
  ["Bay Islands Department","HN.IB","HN",[]],
  ["Intibucá Department","HN.IN","HN",[]],
  ["Gracias a Dios Department","HN.GD","HN",[]],
  ["Francisco Morazán Department","HN.FM","HN",[]],
  ["El Paraíso Department","HN.EP","HN",[]],
  ["Cortés Department","HN.CR","HN",[]],
  ["Copán Department","HN.CP","HN",[]],
  ["Comayagua Department","HN.CM","HN",[]],
  ["Colón Department","HN.CL","HN",[]],
  ["Choluteca Department","HN.CH","HN",[]],
  ["Atlántida Department","HN.AT","HN",[]],
  ["Bjelovar-Bilogora County","HR.07","HR",[]],
  ["Brod-Posavina County","HR.12","HR",[]],
  ["Dubrovnik-Neretva County","HR.19","HR",[]],
  ["Istria County","HR.18","HR",[]],
  ["Karlovac County","HR.04","HR",[]],
  ["Koprivnica-Križevci County","HR.06","HR",[]],
  ["Krapina-Zagorje County","HR.02","HR",[]],
  ["Lika-Senj County","HR.09","HR",[]],
  ["Međimurje County","HR.20","HR",[]],
  ["Osijek-Baranja County","HR.14","HR",[]],
  ["Požega-Slavonia County","HR.11","HR",[]],
  ["Primorje-Gorski Kotar County","HR.08","HR",[]],
  ["Šibenik-Knin County","HR.15","HR",[]],
  ["Sisak-Moslavina County","HR.03","HR",[]],
  ["Split-Dalmatia County","HR.17","HR",[]],
  ["Varaždin County","HR.05","HR",[]],
  ["Vukovar-Syrmia County","HR.16","HR",[]],
  ["Zadar County","HR.13","HR",[]],
  ["Zagreb County","HR.01","HR",[]],
  ["Zagreb","HR.21","HR",[]],
  ["Virovitica-Podravina County","HR.10","HR",[]],
  ["Sud-Est","HT.SE","HT",[]],
  ["Sud","HT.SD","HT",[]],
  ["Ouest","HT.OU","HT",[]],
  ["Nord-Ouest","HT.NO","HT",[]],
  ["Nord-Est","HT.NE","HT",[]],
  ["Nord","HT.ND","HT",[]],
  ["Grand’Anse","HT.GA","HT",[]],
  ["Centre","HT.CE","HT",[]],
  ["Artibonite","HT.AR","HT",[]],
  ["Nippes","HT.NI","HT",[]],
  ["Szabolcs-Szatmár-Bereg County","HU.SZ","HU",[]],
  ["Jász-Nagykun-Szolnok County","HU.JN","HU",[]],
  ["Heves County","HU.HE","HU",[]],
  ["Hajdú-Bihar County","HU.HB","HU",[]],
  ["Csongrád County","HU.CS","HU",[]],
  ["Borsod-Abaúj-Zemplén County","HU.BZ","HU",[]],
  ["Békés County","HU.BE","HU",[]],
  ["Zala County","HU.ZA","HU",[]],
  ["Veszprém County","HU.VE","HU",[]],
  ["Vas County","HU.VA","HU",[]],
  ["Tolna County","HU.TO","HU",[]],
  ["Somogy County","HU.SO","HU",[]],
  ["Pest County","HU.PE","HU",[]],
  ["Nógrád County","HU.NO","HU",[]],
  ["Komárom-Esztergom County","HU.KE","HU",[]],
  ["Győr-Moson-Sopron County","HU.GS","HU",[]],
  ["Fejér County","HU.FE","HU",[]],
  ["Budapest","HU.BU","HU",[]],
  ["Baranya County","HU.BA","HU",[]],
  ["Bács-Kiskun County","HU.BK","HU",[]],
  ["North Sumatra","ID.SU","ID",[]],
  ["Aceh","ID.AC","ID",[]],
  ["Special Region of Yogyakarta","ID.YO","ID",[]],
  ["South Sumatra","ID.SS","ID",[]],
  ["West Sumatra","ID.SB","ID",[]],
  ["North Sulawesi","ID.SA","ID",[]],
  ["Southeast Sulawesi","ID.SG","ID",[]],
  ["Central Sulawesi","ID.ST","ID",[]],
  ["South Sulawesi","ID.SN","ID",[]],
  ["Riau","ID.RI","ID",[]],
  ["East Nusa Tenggara","ID.NT","ID",[]],
  ["West Nusa Tenggara","ID.NB","ID",[]],
  ["Maluku","ID.MA","ID",[]],
  ["Lampung","ID.LA","ID",[]],
  ["East Kalimantan","ID.KI","ID",[]],
  ["Central Kalimantan","ID.KT","ID",[]],
  ["South Kalimantan","ID.KS","ID",[]],
  ["West Kalimantan","ID.KB","ID",[]],
  ["East Java","ID.JI","ID",[]],
  ["Central Java","ID.JT","ID",[]],
  ["West Java","ID.JB","ID",[]],
  ["Jambi","ID.JA","ID",[]],
  ["DKI Jakarta","ID.JK","ID",[]],
  ["Papua","ID.PA","ID",[]],
  ["Bengkulu","ID.BE","ID",[]],
  ["Bali","ID.BA","ID",[]],
  ["Banten","ID.BT","ID",[]],
  ["Gorontalo","ID.GO","ID",[]],
  ["Kepulauan Bangka Belitung","ID.BB","ID",[]],
  ["North Maluku","ID.MU","ID",[]],
  ["West Sulawesi","ID.SR","ID",[]],
  ["Riau Islands Province","ID.KR","ID",[]],
  ["Kalimantan Utara","ID.KU","ID",[]],
  ["Connacht","IE.C","IE",[]],
  ["Leinster","IE.L","IE",[]],
  ["Munster","IE.M","IE",[]],
  ["Ulster","IE.U","IE",[]],
  ["Jerusalem District","IL.JM","IL",[]],
  ["Tel Aviv District","IL.TA","IL",[]],
  ["Haifa District","IL.HA","IL",[]],
  ["Northern District","IL.Z","IL",[]],
  ["Central District","IL.M","IL",[]],
  ["Southern District","IL.D","IL",[]],
  ["West Bengal","IN.WB","IN",[]],
  ["Uttar Pradesh","IN.UP","IN",[]],
  ["Tripura","IN.TR","IN",[]],
  ["Telangana","IN.TG","IN",[]],
  ["Tamil Nadu","IN.TN","IN",[]],
  ["Sikkim","IN.SK","IN",[]],
  ["Rajasthan","IN.RJ","IN",[]],
  ["Punjab, India","IN.PB","IN",[]],
  ["Puducherry","IN.PY","IN",[]],
  ["Odisha","IN.OR","IN",[]],
  ["Nagaland","IN.NL","IN",[]],
  ["Mizoram","IN.MZ","IN",[]],
  ["Meghalaya","IN.ML","IN",[]],
  ["Manipur","IN.MN","IN",[]],
  ["Maharashtra","IN.MH","IN",[]],
  ["Madhya Pradesh","IN.MP","IN",[]],
  ["Lakshadweep","IN.LD","IN",[]],
  ["Kerala","IN.KL","IN",[]],
  ["Karnataka","IN.KA","IN",[]],
  ["Jammu and Kashmir","IN.JK","IN",[]],
  ["Himachal Pradesh","IN.HP","IN",[]],
  ["Haryana","IN.HR","IN",[]],
  ["Gujarat","IN.GJ","IN",[]],
  ["Daman and Diu","IN.DD","IN",[]],
  ["Goa","IN.GA","IN",[]],
  ["Delhi","IN.DL","IN",[]],
  ["Dadra and Nagar Haveli","IN.DN","IN",[]],
  ["Chandigarh","IN.CH","IN",[]],
  ["Bihar","IN.BR","IN",[]],
  ["Assam","IN.AS","IN",[]],
  ["Arunachal Pradesh","IN.AR","IN",[]],
  ["Andhra Pradesh","IN.AP","IN",[]],
  ["Andaman and Nicobar Islands","IN.AN","IN",[]],
  ["Chhattisgarh","IN.CT","IN",[]],
  ["Jharkhand","IN.JH","IN",[]],
  ["Uttarakhand","IN.UL","IN",[]],
  ["Basra Governorate","IQ.BA","IQ",[]],
  ["Wasit Province","IQ.WA","IQ",[]],
  ["Saladin Province","IQ.SD","IQ",[]],
  ["Nineveh Province","IQ.NI","IQ",[]],
  ["Maysan Governorate","IQ.MA","IQ",[]],
  ["Karbala Governorate","IQ.KA","IQ",[]],
  ["Arbil Province","IQ.AR","IQ",[]],
  ["Diyala Governorate","IQ.DI","IQ",[]],
  ["Dhi Qar Governorate","IQ.DQ","IQ",[]],
  ["Dohuk Governorate","IQ.DA","IQ",[]],
  ["Baghdad Governorate","IQ.BG","IQ",[]],
  ["Babil Governorate","IQ.BB","IQ",[]],
  ["Kirkuk Governorate","IQ.KI","IQ",[]],
  ["Sulaymaniyah Governorate","IQ.SU","IQ",[]],
  ["Najaf Governorate","IQ.NA","IQ",[]],
  ["Al-Qādisiyyah Governorate","IQ.QA","IQ",[]],
  ["Muthanna Province","IQ.MU","IQ",[]],
  ["Al Anbar Governorate","IQ.AN","IQ",[]],
  ["Tehran Province","IR.07","IR",[]],
  ["Zanjan Province","IR.11","IR",[]],
  ["Yazd Province","IR.25","IR",[]],
  ["Semnan Province","IR.12","IR",[]],
  ["Mazandaran Province","IR.21","IR",[]],
  ["Markazi Province","IR.22","IR",[]],
  ["Lorestan Province","IR.20","IR",[]],
  ["Kurdistan Province","IR.16","IR",[]],
  ["Kohgiluyeh and Boyer-Ahmad Province","IR.18","IR",[]],
  ["Khuzestan Province","IR.10","IR",[]],
  ["Kermanshah Province","IR.17","IR",[]],
  ["Kerman Province","IR.15","IR",[]],
  ["Ilam Province","IR.05","IR",[]],
  ["Hormozgan Province","IR.23","IR",[]],
  ["Hamadan Province","IR.24","IR",[]],
  ["Gilan Province","IR.19","IR",[]],
  ["Fars Province","IR.14","IR",[]],
  ["Chaharmahal and Bakhtiari Province","IR.08","IR",[]],
  ["Bushehr Province","IR.06","IR",[]],
  ["East Azerbaijan Province","IR.01","IR",[]],
  ["West Azerbaijan Province","IR.02","IR",[]],
  ["Ardabil Province","IR.03","IR",[]],
  ["Isfahan Province","IR.04","IR",[]],
  ["Golestan Province","IR.27","IR",[]],
  ["Qazvin Province","IR.28","IR",[]],
  ["Qom Province","IR.26","IR",[]],
  ["Sistan and Baluchestan Province","IR.13","IR",[]],
  ["South Khorasan Province","IR.29","IR",[]],
  ["Razavi Khorasan Province","IR.30","IR",[]],
  ["North Khorasan Province","IR.31","IR",[]],
  ["Alborz","IR.44","IR",[]],
  ["Northwestern Region","IS.5","IS",[]],
  ["Northeastern Region","IS.6","IS",[]],
  ["Eastern Region","IS.7","IS",[]],
  ["Southern Region","IS.8","IS",[]],
  ["Capital Region","IS.1","IS",[]],
  ["Southern Peninsula","IS.2","IS",[]],
  ["Western Region","IS.3","IS",[]],
  ["Westfjords","IS.4","IS",[]],
  ["Sicily","IT.82","IT",[]],
  ["Sardinia","IT.88","IT",[]],
  ["Calabria","IT.78","IT",[]],
  ["Veneto","IT.34","IT",[]],
  ["Aosta Valley","IT.23","IT",[]],
  ["Umbria","IT.55","IT",[]],
  ["Trentino-Alto Adige/Südtirol","IT.32","IT",[]],
  ["Tuscany","IT.52","IT",[]],
  ["Apulia","IT.75","IT",[]],
  ["Piedmont","IT.21","IT",[]],
  ["Molise","IT.67","IT",[]],
  ["Marche","IT.57","IT",[]],
  ["Lombardy","IT.25","IT",[]],
  ["Liguria","IT.42","IT",[]],
  ["Lazio","IT.62","IT",[]],
  ["Friuli-Venezia Giulia","IT.36","IT",[]],
  ["Emilia-Romagna","IT.45","IT",[]],
  ["Campania","IT.72","IT",[]],
  ["Basilicata","IT.77","IT",[]],
  ["Abruzzo","IT.65","IT",[]],
  ["Westmoreland Parish","JM.10","JM",[]],
  ["Trelawny Parish","JM.07","JM",[]],
  ["St. Thomas","JM.03","JM",[]],
  ["St. Mary","JM.05","JM",[]],
  ["Saint James Parish, Jamaica","JM.08","JM",[]],
  ["Saint Elizabeth Parish","JM.11","JM",[]],
  ["Saint Catherine Parish","JM.14","JM",[]],
  ["Saint Ann Parish","JM.06","JM",[]],
  ["St. Andrew","JM.02","JM",[]],
  ["Portland Parish","JM.04","JM",[]],
  ["Manchester Parish","JM.12","JM",[]],
  ["Kingston Parish","JM.01","JM",[]],
  ["Hanover Parish","JM.09","JM",[]],
  ["Clarendon Parish, Jamaica","JM.13","JM",[]],
  ["Ma'an Governorate","JO.MN","JO",[]],
  ["Irbid Governorate","JO.IR","JO",[]],
  ["Zarqa Governorate","JO.AZ","JO",[]],
  ["Tafilah Governorate","JO.AT","JO",[]],
  ["Amman Governorate","JO.AM","JO",[]],
  ["Mafraq Governorate","JO.MA","JO",[]],
  ["Karak Governorate","JO.KA","JO",[]],
  ["Balqa Governorate","JO.BA","JO",[]],
  ["Ajloun Governorate","JO.AJ","JO",[]],
  ["Jerash Governorate","JO.JA","JO",[]],
  ["Aqaba Governorate","JO.AQ","JO",[]],
  ["Madaba Governorate","JO.MD","JO",[]],
  ["Yamanashi Prefecture","JP.19","JP",[]],
  ["Yamaguchi Prefecture","JP.35","JP",[]],
  ["Wakayama Prefecture","JP.30","JP",[]],
  ["Toyama Prefecture","JP.16","JP",[]],
  ["Tottori Prefecture","JP.31","JP",[]],
  ["Tokyo","JP.13","JP",[]],
  ["Tokushima Prefecture","JP.36","JP",[]],
  ["Tochigi Prefecture","JP.09","JP",[]],
  ["Shizuoka Prefecture","JP.22","JP",[]],
  ["Shimane Prefecture","JP.32","JP",[]],
  ["Shiga Prefecture","JP.25","JP",[]],
  ["Saitama Prefecture","JP.11","JP",[]],
  ["Saga Prefecture","JP.41","JP",[]],
  ["Osaka Prefecture","JP.27","JP",[]],
  ["Okinawa Prefecture","JP.47","JP",[]],
  ["Okayama Prefecture","JP.33","JP",[]],
  ["Ōita Prefecture","JP.44","JP",[]],
  ["Niigata Prefecture","JP.15","JP",[]],
  ["Nara Prefecture","JP.29","JP",[]],
  ["Nagasaki Prefecture","JP.42","JP",[]],
  ["Nagano Prefecture","JP.20","JP",[]],
  ["Miyazaki Prefecture","JP.45","JP",[]],
  ["Mie Prefecture","JP.24","JP",[]],
  ["Kyoto Prefecture","JP.26","JP",[]],
  ["Kumamoto Prefecture","JP.43","JP",[]],
  ["Kōchi Prefecture","JP.39","JP",[]],
  ["Kanagawa Prefecture","JP.14","JP",[]],
  ["Kagoshima Prefecture","JP.46","JP",[]],
  ["Kagawa Prefecture","JP.37","JP",[]],
  ["Ishikawa Prefecture","JP.17","JP",[]],
  ["Hyōgo Prefecture","JP.28","JP",[]],
  ["Hiroshima Prefecture","JP.34","JP",[]],
  ["Gunma Prefecture","JP.10","JP",[]],
  ["Gifu Prefecture","JP.21","JP",[]],
  ["Fukuoka Prefecture","JP.40","JP",[]],
  ["Fukui Prefecture","JP.18","JP",[]],
  ["Ehime Prefecture","JP.38","JP",[]],
  ["Aichi Prefecture","JP.23","JP",[]],
  ["Yamagata Prefecture","JP.06","JP",[]],
  ["Miyagi Prefecture","JP.04","JP",[]],
  ["Iwate Prefecture","JP.03","JP",[]],
  ["Ibaraki Prefecture","JP.08","JP",[]],
  ["Fukushima Prefecture","JP.07","JP",[]],
  ["Chiba Prefecture","JP.12","JP",[]],
  ["Akita Prefecture","JP.05","JP",[]],
  ["Hokkaido","JP.01","JP",[]],
  ["Aomori Prefecture","JP.02","JP",[]],
  ["West Pokot","KE.47","KE",[]],
  ["Wajir","KE.46","KE",[]],
  ["Uasin Gishu","KE.44","KE",[]],
  ["Turkana","KE.43","KE",[]],
  ["Trans Nzoia","KE.42","KE",[]],
  ["Tharaka-Nithi","KE.41","KE",[]],
  ["Tana River","KE.40","KE",[]],
  ["Siaya","KE.38","KE",[]],
  ["Samburu","KE.37","KE",[]],
  ["Nairobi City","KE.30","KE",[]],
  ["Murang'a","KE.29","KE",[]],
  ["Mombasa","KE.28","KE",[]],
  ["Meru","KE.26","KE",[]],
  ["Marsabit","KE.25","KE",[]],
  ["Mandera","KE.24","KE",[]],
  ["Laikipia","KE.20","KE",[]],
  ["Kwale","KE.19","KE",[]],
  ["Kitui","KE.18","KE",[]],
  ["Kisumu","KE.17","KE",[]],
  ["Kisii","KE.16","KE",[]],
  ["Kirinyaga","KE.15","KE",[]],
  ["Kilifi","KE.14","KE",[]],
  ["Kiambu","KE.13","KE",[]],
  ["Kericho","KE.12","KE",[]],
  ["Kakamega","KE.11","KE",[]],
  ["Isiolo","KE.09","KE",[]],
  ["Garissa","KE.07","KE",[]],
  ["Embu","KE.06","KE",[]],
  ["Busia","KE.04","KE",[]],
  ["Bungoma","KE.03","KE",[]],
  ["Baringo","KE.01","KE",[]],
  ["Nyandarua","KE.35","KE",[]],
  ["Vihiga","KE.45","KE",[]],
  ["Lamu","KE.21","KE",[]],
  ["Machakos","KE.22","KE",[]],
  ["Makueni","KE.23","KE",[]],
  ["Elgeyo/Marakwet","KE.05","KE",[]],
  ["Taita/Taveta","KE.39","KE",[]],
  ["Kajiado","KE.10","KE",[]],
  ["Nyeri","KE.36","KE",[]],
  ["Homa Bay","KE.08","KE",[]],
  ["Bomet","KE.02","KE",[]],
  ["Migori","KE.27","KE",[]],
  ["Nakuru","KE.31","KE",[]],
  ["Narok","KE.33","KE",[]],
  ["Nyamira","KE.34","KE",[]],
  ["Nandi","KE.32","KE",[]],
  ["Osh Province","KG.O","KG",[]],
  ["Batken","KG.B","KG",[]],
  ["Talas","KG.T","KG",[]],
  ["Naryn","KG.N","KG",[]],
  ["Ysyk-Köl","KG.Y","KG",[]],
  ["Bishkek","KG.GB","KG",[]],
  ["Jalal-Abad","KG.J","KG",[]],
  ["Chü","KG.C","KG",[]],
  ["Osh","KG.GO","KG",[]],
  ["Pursat Province","KH.15","KH",[]],
  ["Battambang Province","KH.2","KH",[]],
  ["Takéo Province","KH.21","KH",[]],
  ["Svay Rieng Province","KH.20","KH",[]],
  ["Stung Treng Province","KH.19","KH",[]],
  ["Oddar Meanchey Province","KH.22","KH",[]],
  ["Siem Reap Province","KH.17","KH",[]],
  ["Ratanakiri Province","KH.16","KH",[]],
  ["Prey Veng Province","KH.14","KH",[]],
  ["Preah Vihear Province","KH.13","KH",[]],
  ["Phnom Penh","KH.12","KH",[]],
  ["Pailin","KH.24","KH",[]],
  ["Mondulkiri Province","KH.11","KH",[]],
  ["Kratié Province","KH.10","KH",[]],
  ["Kep Province","KH.23","KH",[]],
  ["Koh Kong Province","KH.9","KH",[]],
  ["Kandal Province","KH.8","KH",[]],
  ["Kampot Province","KH.7","KH",[]],
  ["Kampong Cham Province","KH.6","KH",[]],
  ["Kampong Speu Province","KH.5","KH",[]],
  ["Kampong Chhnang Province","KH.4","KH",[]],
  ["Kampong Thom Province","KH.3","KH",[]],
  ["Sihanoukville","KH.18","KH",[]],
  ["Banteay Meanchey Province","KH.1","KH",[]],
  ["Tbong Khmum","KH.25","KH",[]],
  ["Gilbert Islands","KI.G","KI",[]],
  ["Line Islands","KI.L","KI",[]],
  ["Phoenix Islands","KI.P","KI",[]],
  ["Mohéli","KM.M","KM",[]],
  ["Grande Comore","KM.G","KM",[]],
  ["Anjouan","KM.A","KM",[]],
  ["Trinity Palmetto Point Parish","KN.15","KN",[]],
  ["Saint Thomas Middle Island Parish","KN.13","KN",[]],
  ["Saint Thomas Lowland Parish","KN.12","KN",[]],
  ["Saint Peter Basseterre Parish","KN.11","KN",[]],
  ["Saint Paul Charlestown Parish","KN.10","KN",[]],
  ["Saint Paul Capisterre Parish","KN.09","KN",[]],
  ["Saint Mary Cayon Parish","KN.08","KN",[]],
  ["Saint John Figtree Parish","KN.07","KN",[]],
  ["Saint John Capisterre Parish","KN.06","KN",[]],
  ["Saint James Windward Parish","KN.05","KN",[]],
  ["Saint George Gingerland Parish","KN.04","KN",[]],
  ["Saint George Basseterre Parish","KN.03","KN",[]],
  ["Saint Anne Sandy Point Parish","KN.02","KN",[]],
  ["Christ Church Nichola Town Parish","KN.01","KN",[]],
  ["Pyongyang","KP.01","KP",[]],
  ["South Pyongan Province","KP.02","KP",[]],
  ["North Pyongan Province","KP.03","KP",[]],
  ["Kangwon Province","KP.07","KP",[]],
  ["South Hwanghae Province","KP.05","KP",[]],
  ["North Hwanghae Province","KP.06","KP",[]],
  ["South Hamgyong Province","KP.08","KP",[]],
  ["Ryanggang Province","KP.10","KP",[]],
  ["North Hamgyong Province","KP.09","KP",[]],
  ["Chagang Province","KP.04","KP",[]],
  ["Rason","KP.13","KP",[]],
  ["Ulsan","KR.31","KR",[]],
  ["Daejeon","KR.30","KR",[]],
  ["Daegu","KR.27","KR",[]],
  ["Seoul","KR.11","KR",[]],
  ["Busan","KR.26","KR",[]],
  ["North Gyeongsang Province","KR.47","KR",[]],
  ["Gyeonggi Province","KR.41","KR",[]],
  ["Gwangju","KR.29","KR",[]],
  ["Gangwon Province","KR.42","KR",[]],
  ["Incheon","KR.28","KR",[]],
  ["South Chungcheong Province","KR.44","KR",[]],
  ["North Chungcheong Province","KR.43","KR",[]],
  ["South Jeolla Province","KR.46","KR",[]],
  ["North Jeolla Province","KR.45","KR",[]],
  ["Jeju Province","KR.49","KR",[]],
  ["South Gyeongsang Province","KR.48","KR",[]],
  ["Sejong-si","KR.22","KR",[]],
  ["Hawalli Governorate","KW.HA","KW",[]],
  ["Al Asimah Governorate","KW.KU","KW",[]],
  ["Al Jahra Governorate","KW.JA","KW",[]],
  ["Al Farwaniyah Governorate","KW.FA","KW",[]],
  ["Al Ahmadi Governorate","KW.AH","KW",[]],
  ["Mubarak Al-Kabeer Governorate","KW.MU","KW",[]],
  ["Batys Qazaqstan oblysy","KZ.ZAP","KZ",[]],
  ["Mangghystaū oblysy","KZ.MAN","KZ",[]],
  ["Atyraū oblysy","KZ.ATY","KZ",[]],
  ["Aqtöbe oblysy","KZ.AKT","KZ",[]],
  ["Shyghys Qazaqstan oblysy","KZ.VOS","KZ",[]],
  ["Aqmola oblysy","KZ.AKM","KZ",[]],
  ["Soltüstik Qazaqstan oblysy","KZ.SEV","KZ",[]],
  ["Pavlodar oblysy","KZ.PAV","KZ",[]],
  ["Qyzylorda oblysy","KZ.KZY","KZ",[]],
  ["Qostanay oblysy","KZ.KUS","KZ",[]],
  ["Qaraghandy oblysy","KZ.KAR","KZ",[]],
  ["Zhambyl oblysy","KZ.ZHA","KZ",[]],
  ["Ongtüstik Qazaqstan oblysy","KZ.YUZ","KZ",[]],
  ["Almaty","KZ.ALA","KZ",[]],
  ["Almaty oblysy","KZ.ALM","KZ",[]],
  ["Bayqongyr","KZ.BAY","KZ",[]],
  ["Astana","KZ.AST","KZ",[]],
  ["Xiangkhouang Province","LA.XI","LA",[]],
  ["Sainyabuli Province","LA.XA","LA",[]],
  ["Vientiane Province","LA.VI","LA",[]],
  ["Savannakhet Province","LA.SV","LA",[]],
  ["Salavan Province","LA.SL","LA",[]],
  ["Phongsaly Province","LA.PH","LA",[]],
  ["Oudomxay Province","LA.OU","LA",[]],
  ["Luang Prabang Province","LA.LP","LA",[]],
  ["Luang Namtha Province","LA.LM","LA",[]],
  ["Khammouane Province","LA.KH","LA",[]],
  ["Houaphanh Province","LA.HO","LA",[]],
  ["Champasak Province","LA.CH","LA",[]],
  ["Attapeu Province","LA.AT","LA",[]],
  ["Sekong Province","LA.XE","LA",[]],
  ["Bokeo Province","LA.BK","LA",[]],
  ["Bolikhamsai Province","LA.BL","LA",[]],
  ["Vientiane Prefecture","LA.VT","LA",[]],
  ["Xaisômboun","LA.XS","LA",[]],
  ["Mount Lebanon Governorate","LB.JL","LB",[]],
  ["Beirut Governorate","LB.BA","LB",[]],
  ["North Governorate","LB.AS","LB",[]],
  ["South Governorate","LB.JA","LB",[]],
  ["Beqaa Governorate","LB.BI","LB",[]],
  ["Nabatieh Governorate","LB.NA","LB",[]],
  ["Aakkâr","LB.AK","LB",[]],
  ["Baalbek-Hermel","LB.BH","LB",[]],
  ["Vieux Fort Quarter","LC.11","LC",[]],
  ["Soufrière Quarter","LC.10","LC",[]],
  ["Micoud Quarter","LC.08","LC",[]],
  ["Laborie Quarter","LC.07","LC",[]],
  ["Gros Islet Quarter","LC.06","LC",[]],
  ["Dennery Quarter","LC.05","LC",[]],
  ["Choiseul Quarter","LC.03","LC",[]],
  ["Castries Quarter","LC.02","LC",[]],
  ["Anse la Raye Quarter","LC.01","LC",[]],
  ["Canaries","LC.12","LC",[]],
  ["Vaduz","LI.11","LI",[]],
  ["Triesenberg","LI.10","LI",[]],
  ["Triesen","LI.09","LI",[]],
  ["Schellenberg","LI.08","LI",[]],
  ["Schaan","LI.07","LI",[]],
  ["Ruggell","LI.06","LI",[]],
  ["Planken","LI.05","LI",[]],
  ["Mauren","LI.04","LI",[]],
  ["Gamprin","LI.03","LI",[]],
  ["Eschen","LI.02","LI",[]],
  ["Balzers","LI.01","LI",[]],
  ["Western Province, Sri Lanka","LK.1","LK",[]],
  ["Uva Province","LK.8","LK",[]],
  ["Southern Province, Sri Lanka","LK.3","LK",[]],
  ["Sabaragamuwa Province","LK.9","LK",[]],
  ["North Western Province, Sri Lanka","LK.6","LK",[]],
  ["North Central Province, Sri Lanka","LK.7","LK",[]],
  ["Central Province, Sri Lanka","LK.2","LK",[]],
  ["Northern Province, Sri Lanka","LK.4","LK",[]],
  ["Eastern Province, Sri Lanka","LK.5","LK",[]],
  ["Sinoe County","LR.SI","LR",[]],
  ["Nimba County","LR.NI","LR",[]],
  ["Montserrado County","LR.MO","LR",[]],
  ["Maryland County","LR.MY","LR",[]],
  ["Lofa County","LR.LO","LR",[]],
  ["Grand Gedeh County","LR.GG","LR",[]],
  ["Grand Cape Mount County","LR.CM","LR",[]],
  ["Grand Bassa County","LR.GB","LR",[]],
  ["Bong County","LR.BG","LR",[]],
  ["Bomi County","LR.BM","LR",[]],
  ["Grand Kru County","LR.GK","LR",[]],
  ["Margibi County","LR.MG","LR",[]],
  ["Rivercess County","LR.RI","LR",[]],
  ["Gbarpolu County","LR.GP","LR",[]],
  ["River Gee County","LR.RG","LR",[]],
  ["Thaba-Tseka District","LS.K","LS",[]],
  ["Quthing District","LS.G","LS",[]],
  ["Qacha's Nek District","LS.H","LS",[]],
  ["Mokhotlong District","LS.J","LS",[]],
  ["Mohale's Hoek District","LS.F","LS",[]],
  ["Maseru District","LS.A","LS",[]],
  ["Mafeteng District","LS.E","LS",[]],
  ["Leribe District","LS.C","LS",[]],
  ["Butha-Buthe District","LS.B","LS",[]],
  ["Berea District","LS.D","LS",[]],
  ["Alytus County","LT.AL","LT",[]],
  ["Kaunas County","LT.KU","LT",[]],
  ["Klaipėda County","LT.KL","LT",[]],
  ["Marijampolė County","LT.MR","LT",[]],
  ["Panevėžys County","LT.PN","LT",[]],
  ["Šiauliai County","LT.SA","LT",[]],
  ["Tauragė County","LT.TA","LT",[]],
  ["Telšiai County","LT.TE","LT",[]],
  ["Utena County","LT.UT","LT",[]],
  ["Vilnius County","LT.VL","LT",[]],
  ["Wiltz","LU.WI","LU",[]],
  ["Vianden","LU.VD","LU",[]],
  ["Remich","LU.RM","LU",[]],
  ["Redange","LU.RD","LU",[]],
  ["Mersch","LU.ME","LU",[]],
  ["Luxembourg","LU.LU","LU",[]],
  ["Grevenmacher","LU.GR","LU",[]],
  ["Esch-sur-Alzette","LU.ES","LU",[]],
  ["Echternach","LU.EC","LU",[]],
  ["Diekirch","LU.DI","LU",[]],
  ["Clervaux","LU.CL","LU",[]],
  ["Capellen","LU.CA","LU",[]],
  ["Ventspils Municipality","LV.106","LV",[]],
  ["Ventspils","LV.VEN","LV",[]],
  ["Valmiera","LV.VMR","LV",[]],
  ["Valka Municipality","LV.101","LV",[]],
  ["Tukums Municipality","LV.099","LV",[]],
  ["Talsi Municipality","LV.097","LV",[]],
  ["Saldus Municipality","LV.088","LV",[]],
  ["Riga","LV.RIX","LV",[]],
  ["Rēzekne Municipality","LV.077","LV",[]],
  ["Rēzekne","LV.REZ","LV",[]],
  ["Preiļi Municipality","LV.073","LV",[]],
  ["Ogre Municipality","LV.067","LV",[]],
  ["Madona Municipality","LV.059","LV",[]],
  ["Ludza Municipality","LV.058","LV",[]],
  ["Limbaži Municipality","LV.054","LV",[]],
  ["Liepāja","LV.LPX","LV",[]],
  ["Kuldīga Municipality","LV.050","LV",[]],
  ["Krāslava Municipality","LV.047","LV",[]],
  ["Jūrmala","LV.JUR","LV",[]],
  ["Jelgava Municipality","LV.041","LV",[]],
  ["Jelgava","LV.JEL","LV",[]],
  ["Jēkabpils Municipality","LV.042","LV",[]],
  ["Gulbene Municipality","LV.033","LV",[]],
  ["Dobele municipality","LV.026","LV",[]],
  ["Daugavpils Municipality","LV.025","LV",[]],
  ["Daugavpils","LV.DGV","LV",[]],
  ["Cēsis Municipality","LV.022","LV",[]],
  ["Bauska Municipality","LV.016","LV",[]],
  ["Balvi Municipality","LV.015","LV",[]],
  ["Alūksne Municipality","LV.007","LV",[]],
  ["Aizkraukle Municipality","LV.002","LV",[]],
  ["Dundaga Municipality","LV.027","LV",[]],
  ["Alsunga municipality","LV.006","LV",[]],
  ["Pāvilosta Municipality","LV.071","LV",[]],
  ["Nīca Municipality","LV.066","LV",[]],
  ["Rucava Municipality","LV.081","LV",[]],
  ["Grobiņa Municipality","LV.032","LV",[]],
  ["Durbe Municipality","LV.028","LV",[]],
  ["Aizpute Municipality","LV.003","LV",[]],
  ["Priekule Municipality","LV.074","LV",[]],
  ["Vaiņode Municipality","LV.100","LV",[]],
  ["Skrunda Municipality","LV.093","LV",[]],
  ["Brocēni Municipality","LV.018","LV",[]],
  ["Roja Municipality","LV.079","LV",[]],
  ["Kandava Municipality","LV.043","LV",[]],
  ["Auce Municipality","LV.010","LV",[]],
  ["Jaunpils Municipality","LV.040","LV",[]],
  ["Engure Municipality","LV.029","LV",[]],
  ["Tērvete Municipality","LV.098","LV",[]],
  ["Ozolnieki Municipality","LV.069","LV",[]],
  ["Rundāle Municipality","LV.083","LV",[]],
  ["Babīte Municipality","LV.012","LV",[]],
  ["Mārupe Municipality","LV.062","LV",[]],
  ["Olaine Municipality","LV.068","LV",[]],
  ["Iecava municipality","LV.034","LV",[]],
  ["Ķekava Municipality","LV.052","LV",[]],
  ["Salaspils Municipality","LV.087","LV",[]],
  ["Baldone Municipality","LV.013","LV",[]],
  ["Stopiņi municipality","LV.095","LV",[]],
  ["Carnikava Municipality","LV.020","LV",[]],
  ["Ādaži Municipality","LV.011","LV",[]],
  ["Garkalne Municipality","LV.031","LV",[]],
  ["Vecumnieki Municipality","LV.105","LV",[]],
  ["Ķegums Municipality","LV.051","LV",[]],
  ["Lielvārde Municipality","LV.053","LV",[]],
  ["Skrīveri Municipality","LV.092","LV",[]],
  ["Jaunjelgava Municipality","LV.038","LV",[]],
  ["Nereta Municipality","LV.065","LV",[]],
  ["Viesīte Municipality","LV.107","LV",[]],
  ["Sala Municipality, Latvia","LV.085","LV",[]],
  ["Jēkabpils","LV.JKB","LV",[]],
  ["Aknīste Municipality","LV.004","LV",[]],
  ["Ilūkste Municipality","LV.036","LV",[]],
  ["Vārkava Municipality","LV.103","LV",[]],
  ["Līvāni Municipality","LV.056","LV",[]],
  ["Varakļāni Municipality","LV.102","LV",[]],
  ["Viļāni Municipality","LV.109","LV",[]],
  ["Riebiņi Municipality","LV.078","LV",[]],
  ["Aglona Municipality","LV.001","LV",[]],
  ["Cibla municipality","LV.023","LV",[]],
  ["Zilupe municipality","LV.110","LV",[]],
  ["Viļaka Municipality","LV.108","LV",[]],
  ["Baltinava Municipality","LV.014","LV",[]],
  ["Dagda Municipality","LV.024","LV",[]],
  ["Kārsava Municipality","LV.044","LV",[]],
  ["Rugāji Municipality","LV.082","LV",[]],
  ["Cesvaine Municipality","LV.021","LV",[]],
  ["Lubāna Municipality","LV.057","LV",[]],
  ["Krustpils Municipality","LV.049","LV",[]],
  ["Pļaviņas Municipality","LV.072","LV",[]],
  ["Koknese Municipality","LV.046","LV",[]],
  ["Ikšķile Municipality","LV.035","LV",[]],
  ["Ropaži Municipality","LV.080","LV",[]],
  ["Inčukalns Municipality","LV.037","LV",[]],
  ["Krimulda Municipality","LV.048","LV",[]],
  ["Sigulda Municipality","LV.091","LV",[]],
  ["Līgatne Municipality","LV.055","LV",[]],
  ["Mālpils Municipality","LV.061","LV",[]],
  ["Sēja Municipality","LV.090","LV",[]],
  ["Saulkrasti Municipality","LV.089","LV",[]],
  ["Salacgrīva Municipality","LV.086","LV",[]],
  ["Aloja Municipality","LV.005","LV",[]],
  ["Naukšēni Municipality","LV.064","LV",[]],
  ["Rūjiena Municipality","LV.084","LV",[]],
  ["Mazsalaca Municipality","LV.060","LV",[]],
  ["Burtnieki Municipality","LV.019","LV",[]],
  ["Pārgauja Municipality","LV.070","LV",[]],
  ["Kocēni Municipality","LV.045","LV",[]],
  ["Amata Municipality","LV.008","LV",[]],
  ["Priekuļi Municipality","LV.075","LV",[]],
  ["Rauna Municipality","LV.076","LV",[]],
  ["Strenči Municipality","LV.096","LV",[]],
  ["Beverīna Municipality","LV.017","LV",[]],
  ["Smiltene Municipality","LV.094","LV",[]],
  ["Jaunpiebalga Municipality","LV.039","LV",[]],
  ["Ērgļi Municipality","LV.030","LV",[]],
  ["Vecpiebalga Municipality","LV.104","LV",[]],
  ["Ape Municipality","LV.009","LV",[]],
  ["Mērsrags Municipality","LV.063","LV",[]],
  ["Derna District","LY.DR","LY",[]],
  ["Benghazi","LY.BA","LY",[]],
  ["Marj District","LY.MJ","LY",[]],
  ["Kufra District","LY.KF","LY",[]],
  ["Jabal al Akhdar","LY.JA","LY",[]],
  ["Tripoli District, Libya","LY.TB","LY",[]],
  ["Sirte District","LY.SR","LY",[]],
  ["Sabha District","LY.SB","LY",[]],
  ["Nalut District","LY.NL","LY",[]],
  ["Murzuq District","LY.MQ","LY",[]],
  ["Misrata District","LY.MI","LY",[]],
  ["Ghat District","LY.GT","LY",[]],
  ["Zawiya District","LY.ZA","LY",[]],
  ["Wadi al Shatii District","LY.WS","LY",[]],
  ["Jufra District","LY.JU","LY",[]],
  ["Nuqat al Khams","LY.NQ","LY",[]],
  ["Butnan District","LY.BU","LY",[]],
  ["Jabal al Gharbi District","LY.JG","LY",[]],
  ["Jafara","LY.JI","LY",[]],
  ["Murqub District","LY.MB","LY",[]],
  ["Al Wahat District","LY.WA","LY",[]],
  ["Wadi al Hayaa District","LY.WD","LY",[]],
  ["Tangier-Tetouan","MA.01","MA",[]],
  ["Gharb-Chrarda-Béni Hssen","MA.02","MA",[]],
  ["Taza-Al Hoceima-Taounate","MA.03","MA",[]],
  ["Oriental","MA.04","MA",[]],
  ["Fès-Boulemane","MA.05","MA",[]],
  ["Meknès-Tafilalet","MA.06","MA",[]],
  ["Rabat-Salé-Zemmour-Zaer","MA.07","MA",[]],
  ["Grand Casablanca","MA.08","MA",[]],
  ["Chaouia-Ouardigha","MA.09","MA",[]],
  ["Doukkala-Abda","MA.10","MA",[]],
  ["Marrakesh-Tensift-El Haouz","MA.11","MA",[]],
  ["Tadla-Azilal","MA.12","MA",[]],
  ["Edineț County","MD.ED","MD",[]],
  ["Ungheni District","MD.UN","MD",[]],
  ["Telenești District","MD.TE","MD",[]],
  ["Taraclia District","MD.TA","MD",[]],
  ["Ștefan Vodă District","MD.SV","MD",[]],
  ["Strășeni District","MD.ST","MD",[]],
  ["Soroca District","MD.SO","MD",[]],
  ["Rîșcani District","MD.RI","MD",[]],
  ["Rezina District","MD.RE","MD",[]],
  ["Orhei District","MD.OR","MD",[]],
  ["Ocnița District","MD.OC","MD",[]],
  ["Anenii Noi District","MD.AN","MD",[]],
  ["Nisporeni District","MD.NI","MD",[]],
  ["Leova District","MD.LE","MD",[]],
  ["Sîngerei District","MD.SI","MD",[]],
  ["Criuleni District","MD.CR","MD",[]],
  ["Ialoveni District","MD.IA","MD",[]],
  ["Chișinău","MD.CU","MD",[]],
  ["Căușeni District","MD.CS","MD",[]],
  ["Cantemir District","MD.CT","MD",[]],
  ["Călărași District","MD.CL","MD",[]],
  ["Cahul District","MD.CA","MD",[]],
  ["Glodeni District","MD.GL","MD",[]],
  ["Florești District","MD.FL","MD",[]],
  ["Fălești District","MD.FA","MD",[]],
  ["Dubăsari District","MD.DU","MD",[]],
  ["Drochia District","MD.DR","MD",[]],
  ["Dondușeni District","MD.DO","MD",[]],
  ["Cimișlia District","MD.CM","MD",[]],
  ["Briceni District","MD.BR","MD",[]],
  ["Basarabeasca District","MD.BS","MD",[]],
  ["Hîncești District","MD.HI","MD",[]],
  ["Șoldănești District","MD.SD","MD",[]],
  ["Transnistria Autonomous Territorial Unit with Special Legal Status","MD.SN","MD",[]],
  ["Gagauzia","MD.GA","MD",[]],
  ["Bender, Moldova","MD.BD","MD",[]],
  ["Bălți","MD.BA","MD",[]],
  ["Rožaje Municipality","ME.17","ME",[]],
  ["Žabljak Municipality","ME.21","ME",[]],
  ["Ulcinj Municipality","ME.20","ME",[]],
  ["Tivat Municipality","ME.19","ME",[]],
  ["Podgorica Municipality","ME.16","ME",[]],
  ["Šavnik Municipality","ME.18","ME",[]],
  ["Plužine Municipality","ME.15","ME",[]],
  ["Pljevlja Municipality","ME.14","ME",[]],
  ["Plav Municipality","ME.13","ME",[]],
  ["Nikšić Municipality","ME.12","ME",[]],
  ["Mojkovac Municipality","ME.11","ME",[]],
  ["Kotor Municipality","ME.10","ME",[]],
  ["Kolašin Municipality","ME.09","ME",[]],
  ["Berane Municipality","ME.03","ME",[]],
  ["Herceg Novi","ME.08","ME",[]],
  ["Danilovgrad Municipality","ME.07","ME",[]],
  ["Cetinje Municipality","ME.06","ME",[]],
  ["Budva Municipality","ME.05","ME",[]],
  ["Bijelo Polje Municipality","ME.04","ME",[]],
  ["Bar Municipality","ME.02","ME",[]],
  ["Andrijevica Municipality","ME.01","ME",[]],
  ["Gusinje","ME.22","ME",[]],
  ["Petnjica","ME.23","ME",[]],
  ["Mahajanga Province","MG.M","MG",[]],
  ["Antsiranana Province","MG.D","MG",[]],
  ["Ailinglaplap Atoll","MH.ALL","MH",[]],
  ["Ailuk Atoll","MH.ALK","MH",[]],
  ["Arno Atoll","MH.ARN","MH",[]],
  ["Aur Atoll","MH.AUR","MH",[]],
  ["Ebon Atoll","MH.EBO","MH",[]],
  ["Enewetak Atoll","MH.ENI","MH",[]],
  ["Jaluit Atoll","MH.JAL","MH",[]],
  ["Kwajalein Atoll","MH.KWA","MH",[]],
  ["Lae Atoll","MH.LAE","MH",[]],
  ["Likiep Atoll","MH.LIK","MH",[]],
  ["Majuro","MH.MAJ","MH",[]],
  ["Maloelap Atoll","MH.MAL","MH",[]],
  ["Mili Atoll","MH.MIL","MH",[]],
  ["Namdrik Atoll","MH.NMK","MH",[]],
  ["Namu Atoll","MH.NMU","MH",[]],
  ["Rongelap Atoll","MH.RON","MH",[]],
  ["Ujae Atoll","MH.UJA","MH",[]],
  ["Utirik Atoll","MH.UTI","MH",[]],
  ["Wotho Atoll","MH.WTH","MH",[]],
  ["Wotje Atoll","MH.WTJ","MH",[]],
  ["Jabat Island","MH.JAB","MH",[]],
  ["Kili Island","MH.KIL","MH",[]],
  ["Lib Island","MH.LIB","MH",[]],
  ["Mejit Island","MH.MEJ","MH",[]],
  ["Valandovo Municipality","MK.10","MK",[]],
  ["Resen","MK.66","MK",[]],
  ["Kratovo Municipality","MK.43","MK",[]],
  ["Municipality of Pehčevo","MK.60","MK",[]],
  ["Novo Selo Municipality","MK.56","MK",[]],
  ["Bosilovo Municipality","MK.07","MK",[]],
  ["Vasilevo Municipality","MK.11","MK",[]],
  ["Dojran Municipality","MK.26","MK",[]],
  ["Bogdanci Municipality","MK.05","MK",[]],
  ["Konče Municipality","MK.41","MK",[]],
  ["Makedonska Kamenica Municipality","MK.51","MK",[]],
  ["Zrnovci Municipality","MK.33","MK",[]],
  ["Karbinci Municipality","MK.37","MK",[]],
  ["Demir Kapija Municipality","MK.24","MK",[]],
  ["Rosoman Municipality","MK.67","MK",[]],
  ["Gradsko Municipality","MK.20","MK",[]],
  ["Lozovo Municipality","MK.49","MK",[]],
  ["Municipality of Češinovo-Obleševo","MK.81","MK",[]],
  ["Novaci Municipality","MK.55","MK",[]],
  ["Berovo Municipality","MK.03","MK",[]],
  ["Bitola Municipality","MK.04","MK",[]],
  ["Mogila Municipality","MK.53","MK",[]],
  ["Municipality of Aračinovo","MK.02","MK",[]],
  ["Bogovinje Municipality","MK.06","MK",[]],
  ["Brvenica Municipality","MK.08","MK",[]],
  ["Cair","MK.79","MK",[]],
  ["Čaška Municipality","MK.80","MK",[]],
  ["Centar","MK.77","MK",[]],
  ["Centar Župa Municipality","MK.78","MK",[]],
  ["Municipality of Čučer-Sandevo","MK.82","MK",[]],
  ["Debar Municipality","MK.21","MK",[]],
  ["Municipality of Delčevo","MK.23","MK",[]],
  ["Demir Hisar Municipality","MK.25","MK",[]],
  ["Dolneni Municipality","MK.27","MK",[]],
  ["Opstina Gjorce Petrov","MK.29","MK",[]],
  ["Drugovo Municipality","MK.28","MK",[]],
  ["Gazi Baba","MK.17","MK",[]],
  ["Gevgelija Municipality","MK.18","MK",[]],
  ["Gostivar Municipality","MK.19","MK",[]],
  ["Ilinden Municipality","MK.34","MK",[]],
  ["Jegunovce Municipality","MK.35","MK",[]],
  ["Karpos","MK.38","MK",[]],
  ["Kavadarci Municipality","MK.36","MK",[]],
  ["Kičevo Municipality","MK.40","MK",[]],
  ["Kisela Voda","MK.39","MK",[]],
  ["Municipality of Kočani","MK.42","MK",[]],
  ["Kriva Palanka Municipality","MK.44","MK",[]],
  ["Municipality of Krivogaštani","MK.45","MK",[]],
  ["Municipality of Kruševo","MK.46","MK",[]],
  ["Kumanovo Municipality","MK.47","MK",[]],
  ["Lipkovo Municipality","MK.48","MK",[]],
  ["Makedonski Brod Municipality","MK.52","MK",[]],
  ["Negotino Municipality","MK.54","MK",[]],
  ["Ohrid Municipality","MK.58","MK",[]],
  ["Oslomej","MK.57","MK",[]],
  ["Petrovec Municipality","MK.59","MK",[]],
  ["Plasnica Municipality","MK.61","MK",[]],
  ["Prilep Municipality","MK.62","MK",[]],
  ["Municipality of Probištip","MK.63","MK",[]],
  ["Radoviš Municipality","MK.64","MK",[]],
  ["Rankovce Municipality","MK.65","MK",[]],
  ["Municipality of Mavrovo and Rostuša","MK.50","MK",[]],
  ["Saraj","MK.68","MK",[]],
  ["Municipality of Sopište","MK.70","MK",[]],
  ["Municipality of Staro Nagoričane","MK.71","MK",[]],
  ["Municipality of Štip","MK.83","MK",[]],
  ["Struga Municipality","MK.72","MK",[]],
  ["Strumica Municipality","MK.73","MK",[]],
  ["Municipality of Studeničani","MK.74","MK",[]],
  ["Suto Orizari","MK.84","MK",[]],
  ["Sveti Nikole Municipality","MK.69","MK",[]],
  ["Tearce Municipality","MK.75","MK",[]],
  ["Tetovo Municipality","MK.76","MK",[]],
  ["Veles Municipality","MK.13","MK",[]],
  ["Vevčani Municipality","MK.12","MK",[]],
  ["Vinica Municipality, Macedonia","MK.14","MK",[]],
  ["Vraneštica Municipality","MK.15","MK",[]],
  ["Municipality of Vrapčište","MK.16","MK",[]],
  ["Zajas Municipality","MK.31","MK",[]],
  ["Zelenikovo Municipality","MK.32","MK",[]],
  ["Municipality of Želino","MK.30","MK",[]],
  ["Aerodrom Municipality","MK.01","MK",[]],
  ["Butel","MK.09","MK",[]],
  ["Debarca Municipality","MK.22","MK",[]],
  ["Tombouctou Region","ML.6","ML",[]],
  ["Sikasso Region","ML.3","ML",[]],
  ["Ségou Region","ML.4","ML",[]],
  ["Mopti Region","ML.5","ML",[]],
  ["Koulikoro Region","ML.2","ML",[]],
  ["Kayes Region","ML.1","ML",[]],
  ["Gao Region","ML.7","ML",[]],
  ["Bamako","ML.BKO","ML",[]],
  ["Kidal Region","ML.8","ML",[]],
  ["Tanintharyi Region","MM.05","MM",[]],
  ["Shan State","MM.17","MM",[]],
  ["Sagaing Region","MM.01","MM",[]],
  ["Yangon Region","MM.06","MM",[]],
  ["Rakhine State","MM.16","MM",[]],
  ["Bago Region","MM.02","MM",[]],
  ["Mon State","MM.15","MM",[]],
  ["Mandalay Region","MM.04","MM",[]],
  ["Magway Region","MM.03","MM",[]],
  ["Kayah State","MM.12","MM",[]],
  ["Kayin State","MM.13","MM",[]],
  ["Kachin State","MM.11","MM",[]],
  ["Ayeyarwady Region","MM.07","MM",[]],
  ["Chin State","MM.14","MM",[]],
  ["Nay Pyi Taw","MM.18","MM",[]],
  ["Uvs Province","MN.046","MN",[]],
  ["Khovd Province","MN.043","MN",[]],
  ["Govi-Altai Province","MN.065","MN",[]],
  ["Zavkhan Province","MN.057","MN",[]],
  ["Bayan-Ölgii Province","MN.071","MN",[]],
  ["Bayankhongor Province","MN.069","MN",[]],
  ["Ulan Bator","MN.1","MN",[]],
  ["Töv Province","MN.047","MN",[]],
  ["Sükhbaatar Province","MN.051","MN",[]],
  ["Selenge Province","MN.049","MN",[]],
  ["Övörkhangai Province","MN.055","MN",[]],
  ["Ömnögovi Province","MN.053","MN",[]],
  ["Khövsgöl Province","MN.041","MN",[]],
  ["Khentii Province","MN.039","MN",[]],
  ["Dundgovi Province","MN.059","MN",[]],
  ["Dornogovi Province","MN.063","MN",[]],
  ["Dornod Province","MN.061","MN",[]],
  ["Bulgan Province","MN.067","MN",[]],
  ["Arkhangai Province","MN.073","MN",[]],
  ["Darkhan-Uul Province","MN.037","MN",[]],
  ["Govisümber Province","MN.064","MN",[]],
  ["Orkhon Province","MN.035","MN",[]],
  ["Rota","MP.100","MP",[]],
  ["Saipan","MP.110","MP",[]],
  ["Tinian","MP.120","MP",[]],
  ["Northern Islands","MP.085","MP",[]],
  ["Trarza Region","MR.06","MR",[]],
  ["Tiris Zemmour Region","MR.11","MR",[]],
  ["Tagant Region","MR.09","MR",[]],
  ["Inchiri Region","MR.12","MR",[]],
  ["Hodh El Gharbi Region","MR.02","MR",[]],
  ["Hodh Ech Chargui Region","MR.01","MR",[]],
  ["Guidimaka Region","MR.10","MR",[]],
  ["Gorgol Region","MR.04","MR",[]],
  ["Dakhlet Nouadhibou Region","MR.08","MR",[]],
  ["Brakna Region","MR.05","MR",[]],
  ["Assaba Region","MR.03","MR",[]],
  ["Adrar","MR.07","MR",[]],
  ["Nouakchott Ouest","MR.13","MR",[]],
  ["Nouakchott Nord","MR.14","MR",[]],
  ["Nouakchott Sud","MR.15","MR",[]],
  ["Attard","MT.01","MT",[]],
  ["Balzan","MT.02","MT",[]],
  ["Birgu","MT.03","MT",[]],
  ["Birkirkara","MT.04","MT",[]],
  ["Birżebbuġa","MT.05","MT",[]],
  ["Cospicua","MT.06","MT",[]],
  ["Dingli","MT.07","MT",[]],
  ["Fgura","MT.08","MT",[]],
  ["Floriana","MT.09","MT",[]],
  ["Fontana, Gozo","MT.10","MT",[]],
  ["Għajnsielem","MT.13","MT",[]],
  ["Għarb","MT.14","MT",[]],
  ["Għargħur","MT.15","MT",[]],
  ["Għasri","MT.16","MT",[]],
  ["Għaxaq","MT.17","MT",[]],
  ["Gudja","MT.11","MT",[]],
  ["Gżira","MT.12","MT",[]],
  ["Ħamrun","MT.18","MT",[]],
  ["Iklin","MT.19","MT",[]],
  ["Mdina","MT.29","MT",[]],
  ["Mġarr","MT.31","MT",[]],
  ["Mqabba","MT.33","MT",[]],
  ["Msida","MT.34","MT",[]],
  ["Imtarfa","MT.35","MT",[]],
  ["Senglea","MT.20","MT",[]],
  ["Kalkara","MT.21","MT",[]],
  ["Kerċem","MT.22","MT",[]],
  ["Ħal Kirkop","MT.23","MT",[]],
  ["Lija","MT.24","MT",[]],
  ["Luqa","MT.25","MT",[]],
  ["Marsa, Malta","MT.26","MT",[]],
  ["Marsaskala","MT.27","MT",[]],
  ["Marsaxlokk","MT.28","MT",[]],
  ["Mellieħa","MT.30","MT",[]],
  ["Mosta","MT.32","MT",[]],
  ["Munxar","MT.36","MT",[]],
  ["Nadur","MT.37","MT",[]],
  ["Naxxar","MT.38","MT",[]],
  ["Paola, Malta","MT.39","MT",[]],
  ["Pembroke, Malta","MT.40","MT",[]],
  ["Pietà, Malta","MT.41","MT",[]],
  ["Qala, Malta","MT.42","MT",[]],
  ["Qormi","MT.43","MT",[]],
  ["Qrendi","MT.44","MT",[]],
  ["Rabat, Malta","MT.46","MT",[]],
  ["mt","MT.45","MT",[]],
  ["Ħal Safi, Malta","MT.47","MT",[]],
  ["Saint John","MT.49","MT",[]],
  ["St. Julian's","MT.48","MT",[]],
  ["Saint Lawrence","MT.50","MT",[]],
  ["Santa Luċija, Malta","MT.53","MT",[]],
  ["St. Paul's Bay","MT.51","MT",[]],
  ["Santa Venera","MT.54","MT",[]],
  ["Sannat","MT.52","MT",[]],
  ["Siġġiewi","MT.55","MT",[]],
  ["Sliema","MT.56","MT",[]],
  ["Swieqi","MT.57","MT",[]],
  ["Ta' Xbiex","MT.58","MT",[]],
  ["Tarxien","MT.59","MT",[]],
  ["Xagħra","MT.61","MT",[]],
  ["Xewkija","MT.62","MT",[]],
  ["Xgħajra","MT.63","MT",[]],
  ["Żabbar","MT.64","MT",[]],
  ["Żebbuġ, Malta","MT.66","MT",[]],
  ["Iz-Zebbug","MT.65","MT",[]],
  ["Żejtun","MT.67","MT",[]],
  ["Żurrieq","MT.68","MT",[]],
  ["Valletta","MT.60","MT",[]],
  ["Agaléga","MU.AG","MU",[]],
  ["Savanne District","MU.SA","MU",[]],
  ["Rivière du Rempart District","MU.RR","MU",[]],
  ["Port Louis District","MU.PL","MU",[]],
  ["Plaines Wilhems District","MU.PW","MU",[]],
  ["Pamplemousses District","MU.PA","MU",[]],
  ["Moka District","MU.MO","MU",[]],
  ["Grand Port District","MU.GP","MU",[]],
  ["Flacq District","MU.FL","MU",[]],
  ["Rivière Noire District","MU.BL","MU",[]],
  ["St. Brandon","MU.CC","MU",[]],
  ["Rodrigues","MU.RO","MU",[]],
  ["Vaavu Atoll","MV.04","MV",[]],
  ["Thaa Atoll","MV.08","MV",[]],
  ["Shaviyani Atoll","MV.24","MV",[]],
  ["Seenu","MV.01","MV",[]],
  ["Raa Atoll","MV.13","MV",[]],
  ["Noonu Atoll","MV.25","MV",[]],
  ["Gnaviyani Atoll","MV.29","MV",[]],
  ["Meemu Atoll","MV.12","MV",[]],
  ["Lhaviyani Atoll","MV.03","MV",[]],
  ["Laamu Atoll","MV.05","MV",[]],
  ["Kaafu Atoll","MV.26","MV",[]],
  ["Haa Dhaalu Atoll","MV.23","MV",[]],
  ["Haa Alif Atoll","MV.07","MV",[]],
  ["Gaafu Dhaalu Atoll","MV.28","MV",[]],
  ["Gaafu Alif Atoll","MV.27","MV",[]],
  ["Faafu Atoll","MV.14","MV",[]],
  ["Dhaalu Atoll","MV.17","MV",[]],
  ["Baa Atoll","MV.20","MV",[]],
  ["Alif Alif Atoll","MV.02","MV",[]],
  ["Alif Dhaal Atoll","MV.00","MV",[]],
  ["Southern Region, Malawi","MW.S","MW",[]],
  ["Northern Region, Malawi","MW.N","MW",[]],
  ["Central Region, Malawi","MW.C","MW",[]],
  ["Yucatán","MX.YUC","MX",[]],
  ["Veracruz","MX.VER","MX",[]],
  ["Tlaxcala","MX.TLA","MX",[]],
  ["Tamaulipas","MX.TAM","MX",[]],
  ["Tabasco","MX.TAB","MX",[]],
  ["Quintana Roo","MX.ROO","MX",[]],
  ["Querétaro","MX.QUE","MX",[]],
  ["Puebla","MX.PUE","MX",[]],
  ["Oaxaca","MX.OAX","MX",[]],
  ["Nuevo León","MX.NLE","MX",[]],
  ["Morelos","MX.MOR","MX",[]],
  ["State of Mexico","MX.MEX","MX",[]],
  ["Hidalgo","MX.HID","MX",[]],
  ["Guerrero","MX.GRO","MX",[]],
  ["Mexico City","MX.DIF","MX",[]],
  ["Chiapas","MX.CHP","MX",[]],
  ["Campeche","MX.CAM","MX",[]],
  ["Zacatecas","MX.ZAC","MX",[]],
  ["Sonora","MX.SON","MX",[]],
  ["Sinaloa","MX.SIN","MX",[]],
  ["San Luis Potosí","MX.SLP","MX",[]],
  ["Nayarit","MX.NAY","MX",[]],
  ["Michoacán","MX.MIC","MX",[]],
  ["Jalisco","MX.JAL","MX",[]],
  ["Guanajuato","MX.GUA","MX",[]],
  ["Durango","MX.DUR","MX",[]],
  ["Colima","MX.COL","MX",[]],
  ["Coahuila","MX.COA","MX",[]],
  ["Chihuahua","MX.CHH","MX",[]],
  ["Baja California Sur","MX.BCS","MX",[]],
  ["Baja California","MX.BCN","MX",[]],
  ["Aguascalientes","MX.AGU","MX",[]],
  ["Malacca","MY.04","MY",[]],
  ["Terengganu","MY.11","MY",[]],
  ["Selangor","MY.10","MY",[]],
  ["Sarawak","MY.13","MY",[]],
  ["Sabah","MY.12","MY",[]],
  ["Perlis","MY.09","MY",[]],
  ["Perak","MY.08","MY",[]],
  ["Pahang","MY.06","MY",[]],
  ["Negeri Sembilan","MY.05","MY",[]],
  ["Kelantan","MY.03","MY",[]],
  ["Kuala Lumpur","MY.14","MY",[]],
  ["Penang","MY.07","MY",[]],
  ["Kedah","MY.02","MY",[]],
  ["Johor","MY.01","MY",[]],
  ["Labuan","MY.15","MY",[]],
  ["Putrajaya","MY.16","MY",[]],
  ["Zambezia Province","MZ.Q","MZ",[]],
  ["Tete Province","MZ.T","MZ",[]],
  ["Sofala Province","MZ.S","MZ",[]],
  ["Niassa Province","MZ.A","MZ",[]],
  ["Nampula Province","MZ.N","MZ",[]],
  ["Maputo Province","MZ.L","MZ",[]],
  ["Manica Province","MZ.B","MZ",[]],
  ["Inhambane Province","MZ.I","MZ",[]],
  ["Gaza Province","MZ.G","MZ",[]],
  ["Cabo Delgado Province","MZ.P","MZ",[]],
  ["Maputo","MZ.MPM","MZ",[]],
  ["Zambezi Region","NA.CA","NA",[]],
  ["Khomas Region","NA.KH","NA",[]],
  ["Erongo Region","NA.ER","NA",[]],
  ["Hardap Region","NA.HA","NA",[]],
  ["ǁKaras Region","NA.KA","NA",[]],
  ["Kunene Region","NA.KU","NA",[]],
  ["Ohangwena Region","NA.OW","NA",[]],
  ["Omaheke Region","NA.OH","NA",[]],
  ["Omusati Region","NA.OS","NA",[]],
  ["Oshana Region","NA.ON","NA",[]],
  ["Oshikoto Region","NA.OT","NA",[]],
  ["Otjozondjupa Region","NA.OD","NA",[]],
  ["Kavango East","NA.KE","NA",[]],
  ["Kavango West","NA.KW","NA",[]],
  ["Zinder Region","NE.7","NE",[]],
  ["Tahoua Region","NE.5","NE",[]],
  ["Maradi Region","NE.4","NE",[]],
  ["Dosso Region","NE.3","NE",[]],
  ["Diffa Region","NE.2","NE",[]],
  ["Agadez Region","NE.1","NE",[]],
  ["Tillabéri Region","NE.6","NE",[]],
  ["Niamey","NE.8","NE",[]],
  ["Sokoto State","NG.SO","NG",[]],
  ["Rivers State","NG.RI","NG",[]],
  ["Plateau","NG.PL","NG",[]],
  ["Oyo State","NG.OY","NG",[]],
  ["Ondo State","NG.ON","NG",[]],
  ["Ogun State","NG.OG","NG",[]],
  ["Niger State","NG.NI","NG",[]],
  ["Lagos State","NG.LA","NG",[]],
  ["Kwara State","NG.KW","NG",[]],
  ["Katsina State","NG.KT","NG",[]],
  ["Kano State","NG.KN","NG",[]],
  ["Kaduna State","NG.KD","NG",[]],
  ["Imo State","NG.IM","NG",[]],
  ["Cross River State","NG.CR","NG",[]],
  ["Borno State","NG.BO","NG",[]],
  ["Benue State","NG.BE","NG",[]],
  ["Bauchi State","NG.BA","NG",[]],
  ["Anambra State","NG.AN","NG",[]],
  ["Akwa Ibom State","NG.AK","NG",[]],
  ["Federal Capital Territory","NG.FC","NG",[]],
  ["Abia State","NG.AB","NG",[]],
  ["Delta State","NG.DE","NG",[]],
  ["Adamawa State","NG.AD","NG",[]],
  ["Edo State","NG.ED","NG",[]],
  ["Enugu State","NG.EN","NG",[]],
  ["Jigawa State","NG.JI","NG",[]],
  ["Bayelsa State","NG.BY","NG",[]],
  ["Ebonyi State","NG.EB","NG",[]],
  ["Ekiti State","NG.EK","NG",[]],
  ["Gombe State","NG.GO","NG",[]],
  ["Nasarawa State","NG.NA","NG",[]],
  ["Zamfara State","NG.ZA","NG",[]],
  ["Kebbi State","NG.KE","NG",[]],
  ["Kogi State","NG.KO","NG",[]],
  ["Osun State","NG.OS","NG",[]],
  ["Taraba State","NG.TA","NG",[]],
  ["Yobe State","NG.YO","NG",[]],
  ["Rivas Department","NI.RI","NI",[]],
  ["Río San Juan Department","NI.SJ","NI",[]],
  ["Nueva Segovia Department","NI.NS","NI",[]],
  ["Matagalpa Department","NI.MT","NI",[]],
  ["Masaya Department","NI.MS","NI",[]],
  ["Managua Department","NI.MN","NI",[]],
  ["Madriz Department","NI.MD","NI",[]],
  ["León Department","NI.LE","NI",[]],
  ["Jinotega Department","NI.JI","NI",[]],
  ["Granada Department","NI.GR","NI",[]],
  ["Estelí Department","NI.ES","NI",[]],
  ["Chontales Department","NI.CO","NI",[]],
  ["Chinandega Department","NI.CI","NI",[]],
  ["Carazo Department","NI.CA","NI",[]],
  ["Boaco Department","NI.BO","NI",[]],
  ["Región Autónoma del Atlántico Norte","NI.AN","NI",[]],
  ["Región Autónoma del Atlántico Sur","NI.AS","NI",[]],
  ["South Holland","NL.ZH","NL",[]],
  ["Zeeland","NL.ZE","NL",[]],
  ["Utrecht","NL.UT","NL",[]],
  ["Overijssel","NL.OV","NL",[]],
  ["North Holland","NL.NH","NL",[]],
  ["North Brabant","NL.NB","NL",[]],
  ["Limburg","NL.LI","NL",[]],
  ["Groningen","NL.GR","NL",[]],
  ["Gelderland","NL.GE","NL",[]],
  ["Friesland","NL.FR","NL",[]],
  ["Drenthe","NL.DR","NL",[]],
  ["Flevoland","NL.FL","NL",[]],
  ["Finnmark","NO.20","NO",[]],
  ["Vestfold","NO.07","NO",[]],
  ["Vest-Agder","NO.10","NO",[]],
  ["Troms","NO.19","NO",[]],
  ["Telemark","NO.08","NO",[]],
  ["Sør-Trøndelag","NO.16","NO",[]],
  ["Sogn og Fjordane","NO.14","NO",[]],
  ["Rogaland","NO.11","NO",[]],
  ["Østfold","NO.01","NO",[]],
  ["Oslo","NO.03","NO",[]],
  ["Oppland","NO.05","NO",[]],
  ["Nord-Trøndelag","NO.17","NO",[]],
  ["Nordland","NO.18","NO",[]],
  ["Møre og Romsdal","NO.15","NO",[]],
  ["Hordaland","NO.12","NO",[]],
  ["Hedmark","NO.04","NO",[]],
  ["Buskerud","NO.06","NO",[]],
  ["Aust-Agder","NO.09","NO",[]],
  ["Akershus","NO.02","NO",[]],
  ["Far Western","NP.FR","NP",[]],
  ["Mid Western","NP.MR","NP",[]],
  ["Central Region","NP.CR","NP",[]],
  ["Eastern Region","NP.ER","NP",[]],
  ["Western Region","NP.WR","NP",[]],
  ["Yaren District","NR.14","NR",[]],
  ["Uaboe District","NR.13","NR",[]],
  ["Nibok District","NR.12","NR",[]],
  ["Meneng District","NR.11","NR",[]],
  ["Ijuw District","NR.10","NR",[]],
  ["Ewa District, Nauru","NR.09","NR",[]],
  ["Denigomodu District","NR.08","NR",[]],
  ["Buada District","NR.07","NR",[]],
  ["Boe District","NR.06","NR",[]],
  ["Baiti District","NR.05","NR",[]],
  ["Anibare District","NR.04","NR",[]],
  ["Anetan District","NR.03","NR",[]],
  ["Anabar District","NR.02","NR",[]],
  ["Aiwo District","NR.01","NR",[]],
  ["Wellington Region","NZ.WGN","NZ",[]],
  ["Manawatu-Wanganui","NZ.MWT","NZ",[]],
  ["Waikato","NZ.WKO","NZ",[]],
  ["Tasman District","NZ.TAS","NZ",[]],
  ["Taranaki","NZ.TKI","NZ",[]],
  ["Southland","NZ.STL","NZ",[]],
  ["Bay of Plenty region","NZ.BOP","NZ",[]],
  ["Northland region","NZ.NTL","NZ",[]],
  ["Marlborough Region","NZ.MBH","NZ",[]],
  ["Hawke's Bay region","NZ.HKB","NZ",[]],
  ["Gisborne region","NZ.GIS","NZ",[]],
  ["West Coast","NZ.WTC","NZ",[]],
  ["Canterbury","NZ.CAN","NZ",[]],
  ["Auckland Region","NZ.AUK","NZ",[]],
  ["Nelson","NZ.NSN","NZ",[]],
  ["Otago","NZ.OTA","NZ",[]],
  ["West Coast","NZ.WTC","NZ",[]],
  ["Ad Dakhiliyah Governorate","OM.DA","OM",[]],
  ["Janūb al Bāţinah","OM.BJ","OM",[]],
  ["Al Wusta Governorate","OM.WU","OM",[]],
  ["Janūb ash Sharqīyah","OM.SJ","OM",[]],
  ["Ad Dhahirah Governorate","OM.ZA","OM",[]],
  ["Muscat Governorate","OM.MA","OM",[]],
  ["Musandam Governorate","OM.MU","OM",[]],
  ["Dhofar Governorate","OM.ZU","OM",[]],
  ["Al Buraimi Governorate","OM.BU","OM",[]],
  ["Shamāl ash Sharqīyah","OM.SS","OM",[]],
  ["Shamāl al Bāţinah","OM.BS","OM",[]],
  ["Veraguas Province","PA.9","PA",[]],
  ["Guna Yala","PA.KY","PA",[]],
  ["Panamá Province","PA.8","PA",[]],
  ["Los Santos Province","PA.7","PA",[]],
  ["Herrera Province","PA.6","PA",[]],
  ["Darién Province","PA.5","PA",[]],
  ["Colon","PA.3","PA",[]],
  ["Coclé Province","PA.2","PA",[]],
  ["Chiriquí Province","PA.4","PA",[]],
  ["Bocas del Toro Province","PA.1","PA",[]],
  ["Comarca Emberá-Wounaan","PA.EM","PA",[]],
  ["Ngöbe-Buglé","PA.NB","PA",[]],
  ["Panamá Oeste","PA.10","PA",[]],
  ["Ucayali Region","PE.UCA","PE",[]],
  ["Tumbes Region","PE.TUM","PE",[]],
  ["San Martín Region","PE.SAM","PE",[]],
  ["Piura Region","PE.PIU","PE",[]],
  ["Loreto Region","PE.LOR","PE",[]],
  ["Lambayeque Region","PE.LAM","PE",[]],
  ["La Libertad Region","PE.LAL","PE",[]],
  ["Huánuco Region","PE.HUC","PE",[]],
  ["Cajamarca Region","PE.CAJ","PE",[]],
  ["Ancash Region","PE.ANC","PE",[]],
  ["Amazonas Region","PE.AMA","PE",[]],
  ["Tacna Region","PE.TAC","PE",[]],
  ["Puno Region","PE.PUN","PE",[]],
  ["Pasco Region","PE.PAS","PE",[]],
  ["Moquegua Region","PE.MOQ","PE",[]],
  ["Madre de Dios Region","PE.MDD","PE",[]],
  ["Lima Province","PE.LMA","PE",[]],
  ["Lima Region","PE.LIM","PE",[]],
  ["Junín Region","PE.JUN","PE",[]],
  ["Ica Region","PE.ICA","PE",[]],
  ["Huancavelica Region","PE.HUV","PE",[]],
  ["Cusco Region","PE.CUS","PE",[]],
  ["Callao","PE.CAL","PE",[]],
  ["Ayacucho Region","PE.AYA","PE",[]],
  ["Arequipa Region","PE.ARE","PE",[]],
  ["Apurímac Region","PE.APU","PE",[]],
  ["West New Britain Province","PG.WBK","PG",[]],
  ["Western Province","PG.WPD","PG",[]],
  ["Western Highlands Province","PG.WHM","PG",[]],
  ["Southern Highlands Province","PG.SHM","PG",[]],
  ["Sandaun Province","PG.SAN","PG",[]],
  ["Bougainville","PG.NSA","PG",[]],
  ["Oro Province","PG.NPP","PG",[]],
  ["New Ireland Province","PG.NIK","PG",[]],
  ["Port Moresby","PG.NCD","PG",[]],
  ["Morobe Province","PG.MPL","PG",[]],
  ["Manus Province","PG.MRL","PG",[]],
  ["Madang Province","PG.MPM","PG",[]],
  ["Gulf Province","PG.GPK","PG",[]],
  ["Enga Province","PG.EPW","PG",[]],
  ["East Sepik Province","PG.ESW","PG",[]],
  ["East New Britain Province","PG.EBR","PG",[]],
  ["Eastern Highlands Province","PG.EHG","PG",[]],
  ["Chimbu Province","PG.CPK","PG",[]],
  ["Milne Bay Province","PG.MBA","PG",[]],
  ["Central Province","PG.CPM","PG",[]],
  ["Hela","PG.HLA","PG",[]],
  ["Jiwaka","PG.JWK","PG",[]],
  ["Autonomous Region in Muslim Mindanao","PH.14","PH",[]],
  ["Northern Mindanao","PH.10","PH",[]],
  ["MIMAROPA","PH.41","PH",[]],
  ["Cagayan Valley","PH.02","PH",[]],
  ["SOCCSKSARGEN","PH.12","PH",[]],
  ["Caraga","PH.13","PH",[]],
  ["Cordillera Administrative Region","PH.15","PH",[]],
  ["Ilocos Region","PH.01","PH",[]],
  ["CALABARZON","PH.40","PH",[]],
  ["Western Visayas","PH.06","PH",[]],
  ["Central Luzon","PH.03","PH",[]],
  ["Central Visayas","PH.07","PH",[]],
  ["Eastern Visayas","PH.08","PH",[]],
  ["Zamboanga Peninsula","PH.09","PH",[]],
  ["Davao Region","PH.11","PH",[]],
  ["Bicol Region","PH.05","PH",[]],
  ["Metro Manila","PH.00","PH",[]],
  ["Islamabad Capital Territory","PK.IS","PK",[]],
  ["Sindh","PK.SD","PK",[]],
  ["Punjab","PK.PB","PK",[]],
  ["Khyber Pakhtunkhwa","PK.KP","PK",[]],
  ["Gilgit-Baltistan","PK.NA","PK",[]],
  ["Federally Administered Tribal Areas","PK.TA","PK",[]],
  ["Balochistan, Pakistan","PK.BA","PK",[]],
  ["Azad Kashmir","PK.JK","PK",[]],
  ["Lublin Voivodeship","PL.LU","PL",[]],
  ["Lesser Poland Voivodeship","PL.MA","PL",[]],
  ["Masovian Voivodeship","PL.MZ","PL",[]],
  ["Podkarpackie Voivodeship","PL.PK","PL",[]],
  ["Podlaskie Voivodeship","PL.PD","PL",[]],
  ["Skierniewice Voivodeship","PL.SK","PL",[]],
  ["Warmian-Masurian Voivodeship","PL.WN","PL",[]],
  ["Lower Silesian Voivodeship","PL.DS","PL",[]],
  ["Łódź Voivodeship","PL.LD","PL",[]],
  ["Lubusz Voivodeship","PL.LB","PL",[]],
  ["Opole Voivodeship","PL.OP","PL",[]],
  ["Pomeranian Voivodeship","PL.PM","PL",[]],
  ["Słupsk Voivodeship","PL.SL","PL",[]],
  ["Greater Poland Voivodeship","PL.WP","PL",[]],
  ["West Pomeranian Voivodeship","PL.ZP","PL",[]],
  ["Kuyavian-Pomeranian Voivodeship","PL.KP","PL",[]],
  ["Adjuntas","PR.001","PR",[]],
  ["Aguada","PR.003","PR",[]],
  ["Aguadilla","PR.005","PR",[]],
  ["Aguas Buenas","PR.007","PR",[]],
  ["Aibonito","PR.009","PR",[]],
  ["Anasco","PR.011","PR",[]],
  ["Arecibo","PR.013","PR",[]],
  ["Arroyo","PR.015","PR",[]],
  ["Barceloneta","PR.017","PR",[]],
  ["Barranquitas","PR.019","PR",[]],
  ["Cabo Rojo","PR.023","PR",[]],
  ["Caguas","PR.025","PR",[]],
  ["Camuy","PR.027","PR",[]],
  ["Canovanas","PR.029","PR",[]],
  ["Carolina","PR.031","PR",[]],
  ["Catano","PR.033","PR",[]],
  ["Cayey","PR.035","PR",[]],
  ["Ceiba","PR.037","PR",[]],
  ["Ciales","PR.039","PR",[]],
  ["Cidra","PR.041","PR",[]],
  ["Coamo","PR.043","PR",[]],
  ["Comerio","PR.045","PR",[]],
  ["Corozal","PR.047","PR",[]],
  ["Culebra","PR.049","PR",[]],
  ["Dorado","PR.051","PR",[]],
  ["Fajardo","PR.053","PR",[]],
  ["Florida","PR.054","PR",[]],
  ["Guanica","PR.055","PR",[]],
  ["Guayama","PR.057","PR",[]],
  ["Guayanilla","PR.059","PR",[]],
  ["Guaynabo","PR.061","PR",[]],
  ["Gurabo","PR.063","PR",[]],
  ["Hatillo","PR.065","PR",[]],
  ["Hormigueros","PR.067","PR",[]],
  ["Humacao","PR.069","PR",[]],
  ["Isabela","PR.071","PR",[]],
  ["Municipio de Jayuya","PR.073","PR",[]],
  ["Juana Diaz","PR.075","PR",[]],
  ["Municipio de Juncos","PR.077","PR",[]],
  ["Lajas","PR.079","PR",[]],
  ["Lares","PR.081","PR",[]],
  ["Las Marias","PR.083","PR",[]],
  ["Las Piedras","PR.085","PR",[]],
  ["Loiza","PR.087","PR",[]],
  ["Luquillo","PR.089","PR",[]],
  ["Manati","PR.091","PR",[]],
  ["Maricao","PR.093","PR",[]],
  ["Maunabo","PR.095","PR",[]],
  ["Mayaguez","PR.097","PR",[]],
  ["Moca","PR.099","PR",[]],
  ["Morovis","PR.101","PR",[]],
  ["Naguabo","PR.103","PR",[]],
  ["Naranjito","PR.105","PR",[]],
  ["Orocovis","PR.107","PR",[]],
  ["Patillas","PR.109","PR",[]],
  ["Penuelas","PR.111","PR",[]],
  ["Ponce","PR.113","PR",[]],
  ["Rincon","PR.117","PR",[]],
  ["Quebradillas","PR.115","PR",[]],
  ["Rio Grande","PR.119","PR",[]],
  ["Sabana Grande","PR.121","PR",[]],
  ["Salinas","PR.123","PR",[]],
  ["San German","PR.125","PR",[]],
  ["San Juan","PR.127","PR",[]],
  ["San Lorenzo","PR.129","PR",[]],
  ["San Sebastian","PR.131","PR",[]],
  ["Santa Isabel Municipio","PR.133","PR",[]],
  ["Toa Alta","PR.135","PR",[]],
  ["Toa Baja","PR.137","PR",[]],
  ["Trujillo Alto","PR.139","PR",[]],
  ["Utuado","PR.141","PR",[]],
  ["Vega Alta","PR.143","PR",[]],
  ["Vega Baja","PR.145","PR",[]],
  ["Villalba","PR.149","PR",[]],
  ["Yabucoa","PR.151","PR",[]],
  ["Yauco","PR.153","PR",[]],
  ["Vieques","PR.147","PR",[]],
  ["Gaza Strip","PS.GZZ","PS",[]],
  ["West Bank","PS.WBK","PS",[]],
  ["Setúbal District","PT.15","PT",[]],
  ["Santarém District","PT.14","PT",[]],
  ["Portalegre District","PT.12","PT",[]],
  ["Lisboa","PT.11","PT",[]],
  ["Leiria District","PT.10","PT",[]],
  ["Faro District","PT.08","PT",[]],
  ["Évora District","PT.07","PT",[]],
  ["Castelo Branco District","PT.05","PT",[]],
  ["Beja District","PT.02","PT",[]],
  ["Madeira","PT.30","PT",[]],
  ["Viseu District","PT.18","PT",[]],
  ["Vila Real District","PT.17","PT",[]],
  ["Viana do Castelo District","PT.16","PT",[]],
  ["Porto District","PT.13","PT",[]],
  ["Guarda District","PT.09","PT",[]],
  ["Coimbra District","PT.06","PT",[]],
  ["Bragança District","PT.04","PT",[]],
  ["Braga District","PT.03","PT",[]],
  ["Aveiro District","PT.01","PT",[]],
  ["Azores","PT.20","PT",[]],
  ["Ngatpang","PW.224","PW",[]],
  ["Sonsorol","PW.370","PW",[]],
  ["Kayangel","PW.100","PW",[]],
  ["Hatohobei","PW.050","PW",[]],
  ["Aimeliik","PW.002","PW",[]],
  ["Airai","PW.004","PW",[]],
  ["Angaur","PW.010","PW",[]],
  ["Koror","PW.150","PW",[]],
  ["Melekeok","PW.212","PW",[]],
  ["Ngaraard","PW.214","PW",[]],
  ["Ngchesar","PW.226","PW",[]],
  ["Ngarchelong","PW.218","PW",[]],
  ["Ngardmau","PW.222","PW",[]],
  ["Ngeremlengui","PW.227","PW",[]],
  ["Ngiwal","PW.228","PW",[]],
  ["Peleliu","PW.350","PW",[]],
  ["San Pedro Department, Paraguay","PY.2","PY",[]],
  ["Presidente Hayes Department","PY.15","PY",[]],
  ["Paraguarí","PY.9","PY",[]],
  ["Ñeembucú Department","PY.12","PY",[]],
  ["Misiones","PY.8","PY",[]],
  ["Itapúa Department","PY.7","PY",[]],
  ["Guaira","PY.4","PY",[]],
  ["Cordillera","PY.3","PY",[]],
  ["Concepción Department","PY.1","PY",[]],
  ["Central","PY.11","PY",[]],
  ["Canindeyú Department","PY.14","PY",[]],
  ["Caazapá Department","PY.6","PY",[]],
  ["Caaguazú Department","PY.5","PY",[]],
  ["Amambay Department","PY.13","PY",[]],
  ["Alto Paraná Department","PY.10","PY",[]],
  ["Alto Paraguay Department","PY.16","PY",[]],
  ["Asunción","PY.ASU","PY",[]],
  ["Boquerón department","PY.19","PY",[]],
  ["Madinat ash Shamal","QA.MS","QA",[]],
  ["Al Khor","QA.KH","QA",[]],
  ["Umm Salal","QA.US","QA",[]],
  ["Ar Rayyān","QA.RA","QA",[]],
  ["Doha","QA.DA","QA",[]],
  ["Al Wakrah","QA.WA","QA",[]],
  ["Al Daayen","QA.ZA","QA",[]],
  ["Vrancea County","RO.VN","RO",[]],
  ["Vâlcea County","RO.VL","RO",[]],
  ["Vaslui County","RO.VS","RO",[]],
  ["Tulcea County","RO.TL","RO",[]],
  ["Timiș County","RO.TM","RO",[]],
  ["Teleorman County","RO.TR","RO",[]],
  ["Suceava County","RO.SV","RO",[]],
  ["Sibiu County","RO.SB","RO",[]],
  ["Satu Mare County","RO.SM","RO",[]],
  ["Sălaj County","RO.SJ","RO",[]],
  ["Prahova County","RO.PH","RO",[]],
  ["Olt County","RO.OT","RO",[]],
  ["Neamț County","RO.NT","RO",[]],
  ["Mureș County","RO.MS","RO",[]],
  ["Mehedinți County","RO.MH","RO",[]],
  ["Maramureș County","RO.MM","RO",[]],
  ["Iași County","RO.IS","RO",[]],
  ["Ialomița County","RO.IL","RO",[]],
  ["Hunedoara County","RO.HD","RO",[]],
  ["Harghita County","RO.HR","RO",[]],
  ["Gorj County","RO.GJ","RO",[]],
  ["Giurgiu County","RO.GR","RO",[]],
  ["Galați County","RO.GL","RO",[]],
  ["Dolj County","RO.DJ","RO",[]],
  ["Dâmbovița County","RO.DB","RO",[]],
  ["Covasna County","RO.CV","RO",[]],
  ["Constanța County","RO.CT","RO",[]],
  ["Cluj County","RO.CJ","RO",[]],
  ["Caraș-Severin County","RO.CS","RO",[]],
  ["Călărași County","RO.CL","RO",[]],
  ["Buzău County","RO.BZ","RO",[]],
  ["Bucharest","RO.B","RO",[]],
  ["Brașov County","RO.BV","RO",[]],
  ["Brăila County","RO.BR","RO",[]],
  ["Botoșani County","RO.BT","RO",[]],
  ["Bistrița-Năsăud County","RO.BN","RO",[]],
  ["Bihor County","RO.BH","RO",[]],
  ["Bacău County","RO.BC","RO",[]],
  ["Argeș County","RO.AG","RO",[]],
  ["Arad County","RO.AR","RO",[]],
  ["Alba County","RO.AB","RO",[]],
  ["Ilfov County","RO.IF","RO",[]],
  ["Vojvodina","RS.VO","RS",[]],
  ["Central Serbia","RS.SE","RS",[]],
  ["Yaroslavl Oblast","RU.YAR","RU",[]],
  ["Voronezh Oblast","RU.VOR","RU",[]],
  ["Vologda Oblast","RU.VLG","RU",[]],
  ["Volgograd Oblast","RU.VGG","RU",[]],
  ["Ulyanovsk Oblast","RU.ULY","RU",[]],
  ["Udmurtia","RU.UD","RU",[]],
  ["Tver Oblast","RU.TVE","RU",[]],
  ["Tula Oblast","RU.TUL","RU",[]],
  ["Tatarstan","RU.TA","RU",[]],
  ["Tambov Oblast","RU.TAM","RU",[]],
  ["Stavropol Krai","RU.STA","RU",[]],
  ["Smolensk Oblast","RU.SMO","RU",[]],
  ["Saratov Oblast","RU.SAR","RU",[]],
  ["Samara Oblast","RU.SAM","RU",[]],
  ["Ryazan Oblast","RU.RYA","RU",[]],
  ["Rostov Oblast","RU.ROS","RU",[]],
  ["Pskov Oblast","RU.PSK","RU",[]],
  ["Perm Krai","RU.PER","RU",[]],
  ["Penza Oblast","RU.PNZ","RU",[]],
  ["Oryol Oblast","RU.ORL","RU",[]],
  ["Orenburg Oblast","RU.ORE","RU",[]],
  ["Novgorod Oblast","RU.NGR","RU",[]],
  ["Severnaya Osetiya-Alaniya, Respublika","RU.SE","RU",[]],
  ["Nenets Autonomous Okrug","RU.NEN","RU",[]],
  ["Murmansk Oblast","RU.MUR","RU",[]],
  ["Moscow","RU.MOW","RU",[]],
  ["Moscow Oblast","RU.MOS","RU",[]],
  ["Mordovia","RU.MO","RU",[]],
  ["Mari El","RU.ME","RU",[]],
  ["Lipetsk Oblast","RU.LIP","RU",[]],
  ["Leningrad Oblast","RU.LEN","RU",[]],
  ["Saint Petersburg","RU.SPE","RU",[]],
  ["Kursk Oblast","RU.KRS","RU",[]],
  ["Krasnodar Krai","RU.KDA","RU",[]],
  ["Kostroma Oblast","RU.KOS","RU",[]],
  ["Komi Republic","RU.KO","RU",[]],
  ["Kirov Oblast","RU.KIR","RU",[]],
  ["Kareliya, Respublika","RU.KR","RU",[]],
  ["Karachay-Cherkessia","RU.KC","RU",[]],
  ["Kaluga Oblast","RU.KLU","RU",[]],
  ["Kalmykia","RU.KL","RU",[]],
  ["Kaliningrad Oblast","RU.KGD","RU",[]],
  ["Kabardino-Balkaria","RU.KB","RU",[]],
  ["Ivanovo Oblast","RU.IVA","RU",[]],
  ["Ingushetia","RU.IN","RU",[]],
  ["Nizhny Novgorod Oblast","RU.NIZ","RU",[]],
  ["Dagestan","RU.DA","RU",[]],
  ["Chuvashia","RU.CU","RU",[]],
  ["Chechnya","RU.CE","RU",[]],
  ["Bryansk Oblast","RU.BRY","RU",[]],
  ["Belgorod Oblast","RU.BEL","RU",[]],
  ["Bashkortostan","RU.BA","RU",[]],
  ["Astrakhan Oblast","RU.AST","RU",[]],
  ["Arkhangelsk Oblast","RU.ARK","RU",[]],
  ["Adygea","RU.AD","RU",[]],
  ["Vladimir Oblast","RU.VLA","RU",[]],
  ["Yamalo-Nenets Autonomous Okrug","RU.YAN","RU",[]],
  ["Tyumen Oblast","RU.TYU","RU",[]],
  ["Tuva","RU.TY","RU",[]],
  ["Tomsk Oblast","RU.TOM","RU",[]],
  ["Sverdlovsk Oblast","RU.SVE","RU",[]],
  ["Omsk Oblast","RU.OMS","RU",[]],
  ["Novosibirsk Oblast","RU.NVS","RU",[]],
  ["Kurgan Oblast","RU.KGN","RU",[]],
  ["Krasnoyarsk Krai","RU.KYA","RU",[]],
  ["Khanty-Mansi Autonomous Okrug","RU.KHM","RU",[]],
  ["Khakassia","RU.KK","RU",[]],
  ["Kemerovo Oblast","RU.KEM","RU",[]],
  ["Altai Republic","RU.AL","RU",[]],
  ["Chelyabinsk Oblast","RU.CHE","RU",[]],
  ["Altai Krai","RU.ALT","RU",[]],
  ["Sakha Republic","RU.SA","RU",[]],
  ["Primorsky Krai","RU.PRI","RU",[]],
  ["Khabarovsk Krai","RU.KHA","RU",[]],
  ["Irkutsk Oblast","RU.IRK","RU",[]],
  ["Jewish Autonomous Oblast","RU.YEV","RU",[]],
  ["Amur Oblast","RU.AMU","RU",[]],
  ["Buryatia","RU.BU","RU",[]],
  ["Sakhalin Oblast","RU.SAK","RU",[]],
  ["Magadan Oblast","RU.MAG","RU",[]],
  ["Kamchatka Krai","RU.KAM","RU",[]],
  ["Chukotka Autonomous Okrug","RU.CHU","RU",[]],
  ["Zabaykalsky Krai","RU.ZAB","RU",[]],
  ["Eastern Province","RW.02","RW",[]],
  ["Kigali","RW.12","RW",[]],
  ["Northern Province","RW.03","RW",[]],
  ["Western Province","RW.04","RW",[]],
  ["Southern Province","RW.05","RW",[]],
  ["Tabuk Region","SA.07","SA",[]],
  ["Najran Region","SA.10","SA",[]],
  ["Makkah Region","SA.02","SA",[]],
  ["Jizan Region","SA.09","SA",[]],
  ["Ha'il Region","SA.06","SA",[]],
  ["'Asir Region","SA.14","SA",[]],
  ["Eastern Province","SA.04","SA",[]],
  ["Riyadh Province","SA.01","SA",[]],
  ["Al-Qassim Region","SA.05","SA",[]],
  ["Al Madinah Region","SA.03","SA",[]],
  ["Al Jawf Region","SA.12","SA",[]],
  ["Northern Borders Region","SA.08","SA",[]],
  ["Al Bahah Region","SA.11","SA",[]],
  ["Western Province","SB.WE","SB",[]],
  ["Malaita Province","SB.ML","SB",[]],
  ["Isabel Province","SB.IS","SB",[]],
  ["Guadalcanal Province","SB.GU","SB",[]],
  ["Central Province","SB.CE","SB",[]],
  ["Temotu Province","SB.TE","SB",[]],
  ["Makira-Ulawa Province","SB.MK","SB",[]],
  ["Choiseul","SB.CH","SB",[]],
  ["Rennell and Bellona Province","SB.RB","SB",[]],
  ["Honiara","SB.CT","SB",[]],
  ["Takamaka, Seychelles","SC.23","SC",[]],
  ["Saint Louis, Seychelles","SC.22","SC",[]],
  ["Port Glaud","SC.21","SC",[]],
  ["Pointe La Rue","SC.20","SC",[]],
  ["Plaisance, Seychelles","SC.19","SC",[]],
  ["Mont Fleuri","SC.18","SC",[]],
  ["Mont Buxton","SC.17","SC",[]],
  ["La Rivière Anglaise","SC.16","SC",[]],
  ["La Digue","SC.15","SC",[]],
  ["Grand'Anse Mahé","SC.13","SC",[]],
  ["Grand'Anse Praslin","SC.14","SC",[]],
  ["Glacis, Seychelles","SC.12","SC",[]],
  ["Cascade, Seychelles","SC.11","SC",[]],
  ["Bel Ombre, Seychelles","SC.10","SC",[]],
  ["Bel Air, Seychelles","SC.09","SC",[]],
  ["Beau Vallon, Seychelles","SC.08","SC",[]],
  ["Baie Sainte Anne","SC.07","SC",[]],
  ["Baie Lazare","SC.06","SC",[]],
  ["Anse Royale","SC.05","SC",[]],
  ["Anse Etoile","SC.03","SC",[]],
  ["Anse Boileau","SC.02","SC",[]],
  ["Anse-aux-Pins","SC.01","SC",[]],
  ["Les Mamelles","SC.24","SC",[]],
  ["Roche Caiman","SC.25","SC",[]],
  ["Au Cap","SC.04","SC",[]],
  ["Northern State","SD.NO","SD",[]],
  ["Khartoum","SD.KH","SD",[]],
  ["Red Sea","SD.RS","SD",[]],
  ["Al Jazirah","SD.GZ","SD",[]],
  ["Al Qadarif","SD.GD","SD",[]],
  ["White Nile","SD.NW","SD",[]],
  ["Blue Nile","SD.NB","SD",[]],
  ["West Darfur","SD.DW","SD",[]],
  ["Gharb Kurdufān","SD.GK","SD",[]],
  ["South Darfur","SD.DS","SD",[]],
  ["South Kordofan","SD.KS","SD",[]],
  ["Kassala","SD.KA","SD",[]],
  ["River Nile","SD.NR","SD",[]],
  ["North Darfur","SD.DN","SD",[]],
  ["North Kurdufan","SD.KN","SD",[]],
  ["Sennar","SD.SI","SD",[]],
  ["East Darfur","SD.DE","SD",[]],
  ["Central Darfur","SD.DC","SD",[]],
  ["Jonglei","SS.JG","SS",[]],
  ["Norrbotten County","SE.BD","SE",[]],
  ["Västmanland County","SE.U","SE",[]],
  ["Västernorrland County","SE.Y","SE",[]],
  ["Västerbotten County","SE.AC","SE",[]],
  ["Värmland County","SE.S","SE",[]],
  ["Uppsala County","SE.C","SE",[]],
  ["Stockholm County","SE.AB","SE",[]],
  ["Södermanland County","SE.D","SE",[]],
  ["Östergötland County","SE.E","SE",[]],
  ["Örebro County","SE.T","SE",[]],
  ["Kronoberg County","SE.G","SE",[]],
  ["Dalarna County","SE.W","SE",[]],
  ["Kalmar County","SE.H","SE",[]],
  ["Jönköping County","SE.F","SE",[]],
  ["Jämtland County","SE.Z","SE",[]],
  ["Halland County","SE.N","SE",[]],
  ["Gotland County","SE.I","SE",[]],
  ["Gävleborg County","SE.X","SE",[]],
  ["Blekinge County","SE.K","SE",[]],
  ["Skåne County","SE.M","SE",[]],
  ["Västra Götaland County","SE.O","SE",[]],
  ["Central Singapore Community Development Council","SG.01","SG",[]],
  ["North East","SG.02","SG",[]],
  ["South East","SG.04","SG",[]],
  ["South West Community Development Council","SG.05","SG",[]],
  ["North West","SG.03","SG",[]],
  ["Ascension Island","SH.AC","SH",[]],
  ["Tristan da Cunha","SH.TA","SH",[]],
  ["Saint Helena","SH.HL","SH",[]],
  ["Žalec","SI.190","SI",[]],
  ["Municipality of Zagorje ob Savi","SI.142","SI",[]],
  ["Municipality of Vrhnika","SI.140","SI",[]],
  ["Tržič","SI.131","SI",[]],
  ["Municipality of Trebnje","SI.130","SI",[]],
  ["Trbovlje","SI.129","SI",[]],
  ["Municipality of Tolmin","SI.128","SI",[]],
  ["Velenje","SI.133","SI",[]],
  ["Šmarje pri Jelšah","SI.124","SI",[]],
  ["Slovenske Konjice","SI.114","SI",[]],
  ["Slovenska Bistrica","SI.113","SI",[]],
  ["City Municipality of Slovenj Gradec","SI.112","SI",[]],
  ["Municipality of Škofja Loka","SI.122","SI",[]],
  ["Municipality of Sežana","SI.111","SI",[]],
  ["Municipality of Sevnica","SI.110","SI",[]],
  ["Šentjur","SI.120","SI",[]],
  ["Municipality of Ribnica","SI.104","SI",[]],
  ["Municipality of Radovljica","SI.102","SI",[]],
  ["Municipality of Radlje ob Dravi","SI.101","SI",[]],
  ["Ptuj","SI.096","SI",[]],
  ["Municipality of Postojna","SI.094","SI",[]],
  ["Piran","SI.090","SI",[]],
  ["Ormož","SI.087","SI",[]],
  ["City Municipality of Novo Mesto","SI.085","SI",[]],
  ["Nova Gorica","SI.084","SI",[]],
  ["Murska Sobota","SI.080","SI",[]],
  ["Mozirje","SI.079","SI",[]],
  ["Metlika","SI.073","SI",[]],
  ["City Municipality of Maribor","SI.070","SI",[]],
  ["Municipality of Logatec","SI.064","SI",[]],
  ["Municipality of Ljutomer","SI.063","SI",[]],
  ["Litija","SI.060","SI",[]],
  ["Municipality of Lenart","SI.058","SI",[]],
  ["Municipality of Laško","SI.057","SI",[]],
  ["Municipality of Krško","SI.054","SI",[]],
  ["Kranj","SI.052","SI",[]],
  ["Koper","SI.050","SI",[]],
  ["Municipality of Kočevje","SI.048","SI",[]],
  ["Municipality of Kamnik","SI.043","SI",[]],
  ["Municipality of Jesenice","SI.041","SI",[]],
  ["Izola","SI.040","SI",[]],
  ["Municipality of Ilirska Bistrica","SI.038","SI",[]],
  ["Municipality of Idrija","SI.036","SI",[]],
  ["Municipality of Hrastnik","SI.034","SI",[]],
  ["Municipality of Grosuplje","SI.032","SI",[]],
  ["Municipality of Gornja Radgona","SI.029","SI",[]],
  ["Municipality of Dravograd","SI.025","SI",[]],
  ["Municipality of Domžale","SI.023","SI",[]],
  ["Municipality of Črnomelj","SI.017","SI",[]],
  ["Municipality of Cerknica","SI.013","SI",[]],
  ["City Municipality of Celje","SI.011","SI",[]],
  ["Municipality of Brežice","SI.009","SI",[]],
  ["Municipality of Ajdovščina","SI.001","SI",[]],
  ["Hrpelje-Kozina","SI.035","SI",[]],
  ["Municipality of Divača","SI.019","SI",[]],
  ["Municipality of Pivka","SI.091","SI",[]],
  ["Municipality of Loška Dolina","SI.065","SI",[]],
  ["Municipality of Loški Potok","SI.066","SI",[]],
  ["Osilnica","SI.088","SI",[]],
  ["Municipality of Velike Lašče","SI.134","SI",[]],
  ["Škofljica","SI.123","SI",[]],
  ["Municipality of Ig","SI.037","SI",[]],
  ["Municipality of Brezovica","SI.008","SI",[]],
  ["Municipality of Borovnica","SI.005","SI",[]],
  ["Municipality of Vipava","SI.136","SI",[]],
  ["Municipality of Komen","SI.049","SI",[]],
  ["Miren-Kostanjevica","SI.075","SI",[]],
  ["Municipality of Brda","SI.007","SI",[]],
  ["Kanal","SI.044","SI",[]],
  ["Žiri","SI.147","SI",[]],
  ["Municipality of Cerkno","SI.014","SI",[]],
  ["Municipality of Železniki","SI.146","SI",[]],
  ["Municipality of Gorenja Vas–Poljane","SI.027","SI",[]],
  ["Municipality of Dobrova–Polhov Gradec","SI.021","SI",[]],
  ["Municipality of Kobarid","SI.046","SI",[]],
  ["Municipality of Bovec","SI.006","SI",[]],
  ["Municipality of Bohinj","SI.004","SI",[]],
  ["Municipality of Bled","SI.003","SI",[]],
  ["Municipality of Naklo","SI.082","SI",[]],
  ["Municipality of Kranjska Gora","SI.053","SI",[]],
  ["Municipality of Preddvor","SI.095","SI",[]],
  ["Municipality of Cerklje na Gorenjskem","SI.012","SI",[]],
  ["Municipality of Šenčur","SI.117","SI",[]],
  ["Vodice","SI.138","SI",[]],
  ["Medvode","SI.071","SI",[]],
  ["Municipality of Mengeš","SI.072","SI",[]],
  ["Municipality of Dol pri Ljubljani","SI.022","SI",[]],
  ["Moravče, Moravče","SI.077","SI",[]],
  ["Municipality of Gornji Grad","SI.030","SI",[]],
  ["Luče","SI.067","SI",[]],
  ["Ravne na Koroškem","SI.103","SI",[]],
  ["Mežica","SI.074","SI",[]],
  ["Muta","SI.081","SI",[]],
  ["Vuzenica","SI.141","SI",[]],
  ["Municipality of Črna na Koroškem","SI.016","SI",[]],
  ["Ljubno","SI.062","SI",[]],
  ["Municipality of Šoštanj","SI.126","SI",[]],
  ["Šmartno ob Paki","SI.125","SI",[]],
  ["Municipality of Lukovica","SI.068","SI",[]],
  ["Radeče","SI.099","SI",[]],
  ["Ivančna Gorica","SI.039","SI",[]],
  ["Municipality of Dobrepolje","SI.020","SI",[]],
  ["Municipality of Semič","SI.109","SI",[]],
  ["Šentjernej","SI.119","SI",[]],
  ["Municipality of Škocjan","SI.121","SI",[]],
  ["Štore","SI.127","SI",[]],
  ["Municipality of Vojnik","SI.139","SI",[]],
  ["Vitanje","SI.137","SI",[]],
  ["Zreče","SI.144","SI",[]],
  ["Mislinja","SI.076","SI",[]],
  ["Municipality of Ruše","SI.108","SI",[]],
  ["Municipality of Kungota","SI.055","SI",[]],
  ["Municipality of Šentilj","SI.118","SI",[]],
  ["Municipality of Pesnica","SI.089","SI",[]],
  ["Municipality of Duplek","SI.026","SI",[]],
  ["Municipality of Rače–Fram","SI.098","SI",[]],
  ["Starše","SI.115","SI",[]],
  ["Municipality of Kidričevo","SI.045","SI",[]],
  ["Municipality of Majšperk","SI.069","SI",[]],
  ["Municipality of Videm","SI.135","SI",[]],
  ["Rogaška Slatina","SI.106","SI",[]],
  ["Municipality of Rogatec","SI.107","SI",[]],
  ["Podčetrtek","SI.092","SI",[]],
  ["Kozje","SI.051","SI",[]],
  ["Municipality of Gorišnica","SI.028","SI",[]],
  ["Zavrč","SI.143","SI",[]],
  ["Municipality of Dornava","SI.024","SI",[]],
  ["Juršinci","SI.042","SI",[]],
  ["Sveti Jurij","SI.116","SI",[]],
  ["Radenci","SI.100","SI",[]],
  ["Puconci","SI.097","SI",[]],
  ["Rogašovci","SI.105","SI",[]],
  ["Kuzma","SI.056","SI",[]],
  ["Municipality of Gornji Petrovci","SI.031","SI",[]],
  ["Municipality of Moravske Toplice","SI.078","SI",[]],
  ["Kobilje","SI.047","SI",[]],
  ["Municipality of Beltinci","SI.002","SI",[]],
  ["Municipality of Turnišče","SI.132","SI",[]],
  ["Odranci","SI.086","SI",[]],
  ["Municipality of Črenšovci","SI.015","SI",[]],
  ["Municipality of Nazarje","SI.083","SI",[]],
  ["City Municipality of Ljubljana","SI.061","SI",[]],
  ["Municipality of Žirovnica","SI.192","SI",[]],
  ["Municipality of Jezersko","SI.163","SI",[]],
  ["Solčava","SI.180","SI",[]],
  ["Municipality of Komenda","SI.164","SI",[]],
  ["Municipality of Horjul","SI.162","SI",[]],
  ["Šempeter-Vrtojba","SI.183","SI",[]],
  ["Municipality of Bloke","SI.150","SI",[]],
  ["Sodražica","SI.179","SI",[]],
  ["Trzin","SI.186","SI",[]],
  ["Prevalje","SI.175","SI",[]],
  ["Vransko","SI.189","SI",[]],
  ["Tabor, Tabor","SI.184","SI",[]],
  ["Municipality of Braslovče","SI.151","SI",[]],
  ["Polzela","SI.173","SI",[]],
  ["Municipality of Prebold","SI.174","SI",[]],
  ["Municipality of Kostel","SI.165","SI",[]],
  ["Žužemberk","SI.193","SI",[]],
  ["Municipality of Dolenjske Toplice","SI.157","SI",[]],
  ["Mirna Peč","SI.170","SI",[]],
  ["Municipality of Bistrica ob Sotli","SI.149","SI",[]],
  ["Municipality of Dobje","SI.154","SI",[]],
  ["Municipality of Dobrna","SI.155","SI",[]],
  ["Oplotnica","SI.171","SI",[]],
  ["Podvelka","SI.093","SI",[]],
  ["Ribnica na Pohorju","SI.177","SI",[]],
  ["Municipality of Lovrenc na Pohorju","SI.167","SI",[]],
  ["Selnica ob Dravi","SI.178","SI",[]],
  ["Municipality of Hoče–Slivnica","SI.160","SI",[]],
  ["Municipality of Miklavž na Dravskem Polju","SI.169","SI",[]],
  ["Municipality of Hajdina","SI.159","SI",[]],
  ["Žetale","SI.191","SI",[]],
  ["Podlehnik","SI.172","SI",[]],
  ["Markovci","SI.168","SI",[]],
  ["Municipality of Destrnik","SI.018","SI",[]],
  ["Municipality of Trnovska Vas","SI.185","SI",[]],
  ["Municipality of Sveti Andraž v Slovenskih Goricah","SI.182","SI",[]],
  ["Municipality of Cerkvenjak","SI.153","SI",[]],
  ["Municipality of Benedikt","SI.148","SI",[]],
  ["Sveta Ana v Slovenskih Goricah","SI.181","SI",[]],
  ["Municipality of Križevci","SI.166","SI",[]],
  ["Municipality of Veržej","SI.188","SI",[]],
  ["Velika Polana","SI.187","SI",[]],
  ["Lendava","SI.059","SI",[]],
  ["Municipality of Dobrovnik","SI.156","SI",[]],
  ["Tišina","SI.010","SI",[]],
  ["Municipality of Cankova","SI.152","SI",[]],
  ["Municipality of Grad","SI.158","SI",[]],
  ["Municipality of Hodoš","SI.161","SI",[]],
  ["Municipality of Razkrižje","SI.176","SI",[]],
  ["Šmartno pri Litiji","SI.194","SI",[]],
  ["Šalovci","SI.033","SI",[]],
  ["Municipality of Apače","SI.195","SI",[]],
  ["Municipality of Kostanjevica na Krki","SI.197","SI",[]],
  ["Municipality of Cirkulane","SI.196","SI",[]],
  ["Log-Dragomer","SI.208","SI",[]],
  ["Makole","SI.198","SI",[]],
  ["Mokronog-Trebelno","SI.199","SI",[]],
  ["Poljčane","SI.200","SI",[]],
  ["Rečica ob Savinji","SI.209","SI",[]],
  ["Municipality of Renče–Vogrsko","SI.201","SI",[]],
  ["Središče ob Dravi","SI.202","SI",[]],
  ["Municipality of Straža","SI.203","SI",[]],
  ["Sveta Trojica v Slovenskih Goricah","SI.204","SI",[]],
  ["Municipality of Sveti Tomaž","SI.205","SI",[]],
  ["Šentrupert","SI.211","SI",[]],
  ["Municipality of Šmarješke Toplice","SI.206","SI",[]],
  ["Municipality of Sveti Jurij v Slovenskih Goricah","SI.210","SI",[]],
  ["Municipality of Gorje","SI.207","SI",[]],
  ["Ankaran","SI.213","SI",[]],
  ["Mirna","SI.212","SI",[]],
  ["Košice Region","SK.KI","SK",[]],
  ["Prešov Region","SK.PV","SK",[]],
  ["Žilina Region","SK.ZI","SK",[]],
  ["Banská Bystrica Region","SK.BC","SK",[]],
  ["Bratislava Region","SK.BL","SK",[]],
  ["Nitra Region","SK.NI","SK",[]],
  ["Trenčín Region","SK.TC","SK",[]],
  ["Trnava Region","SK.TA","SK",[]],
  ["Western Area","SL.W","SL",[]],
  ["Southern Province","SL.S","SL",[]],
  ["Northern Province","SL.N","SL",[]],
  ["Eastern Province","SL.E","SL",[]],
  ["Serravalle","SM.09","SM",[]],
  ["Chiesanuova","SM.02","SM",[]],
  ["City of San Marino","SM.07","SM",[]],
  ["Acquaviva","SM.01","SM",[]],
  ["Borgo Maggiore","SM.06","SM",[]],
  ["Domagnano","SM.03","SM",[]],
  ["Faetano","SM.04","SM",[]],
  ["Fiorentino","SM.05","SM",[]],
  ["Montegiardino","SM.08","SM",[]],
  ["Ziguinchor Region","SN.ZG","SN",[]],
  ["Thiès Region","SN.TH","SN",[]],
  ["Tambacounda Region","SN.TC","SN",[]],
  ["Saint-Louis Region","SN.SL","SN",[]],
  ["Matam Region","SN.MT","SN",[]],
  ["Louga Region","SN.LG","SN",[]],
  ["Kolda Region","SN.KD","SN",[]],
  ["Kaolack Region","SN.KL","SN",[]],
  ["Fatick Region","SN.FK","SN",[]],
  ["Diourbel Region","SN.DB","SN",[]],
  ["Dakar Region","SN.DK","SN",[]],
  ["Kaffrine Region","SN.KA","SN",[]],
  ["Kédougou Region","SN.KE","SN",[]],
  ["Sédhiou Region","SN.SE","SN",[]],
  ["Woqooyi Galbeed","SO.WO","SO",[]],
  ["Togdheer","SO.TO","SO",[]],
  ["Lower Shebelle","SO.SH","SO",[]],
  ["Middle Shebelle","SO.SD","SO",[]],
  ["Sanaag","SO.SA","SO",[]],
  ["Nugal, Somalia","SO.NU","SO",[]],
  ["Mudug","SO.MU","SO",[]],
  ["Lower Juba","SO.JH","SO",[]],
  ["Middle Juba","SO.JD","SO",[]],
  ["Hiran, Somalia","SO.HI","SO",[]],
  ["Gedo","SO.GE","SO",[]],
  ["Galguduud","SO.GA","SO",[]],
  ["Bay, Somalia","SO.BY","SO",[]],
  ["Bari, Somalia","SO.BR","SO",[]],
  ["Banaadir","SO.BN","SO",[]],
  ["Bakool","SO.BK","SO",[]],
  ["Awdal","SO.AW","SO",[]],
  ["Sool","SO.SO","SO",[]],
  ["Wanica District","SR.WA","SR",[]],
  ["Sipaliwini District","SR.SI","SR",[]],
  ["Saramacca District","SR.SA","SR",[]],
  ["Paramaribo District","SR.PM","SR",[]],
  ["Para District","SR.PR","SR",[]],
  ["Nickerie District","SR.NI","SR",[]],
  ["Marowijne District","SR.MA","SR",[]],
  ["Coronie District","SR.CR","SR",[]],
  ["Commewijne District","SR.CM","SR",[]],
  ["Brokopondo District","SR.BR","SR",[]],
  ["São Tomé Province","ST.S","ST",[]],
  ["Príncipe Province","ST.P","ST",[]],
  ["Usulután Department","SV.US","SV",[]],
  ["Sonsonate Department","SV.SO","SV",[]],
  ["San Vicente","SV.SV","SV",[]],
  ["Santa Ana Department","SV.SA","SV",[]],
  ["San Salvador","SV.SS","SV",[]],
  ["San Miguel Department","SV.SM","SV",[]],
  ["Morazán Department","SV.MO","SV",[]],
  ["La Unión Department","SV.UN","SV",[]],
  ["La Paz","SV.PA","SV",[]],
  ["La Libertad","SV.LI","SV",[]],
  ["Cuscatlán Department","SV.CU","SV",[]],
  ["Chalatenango Department","SV.CH","SV",[]],
  ["Cabañas Department","SV.CA","SV",[]],
  ["Ahuachapán Department","SV.AH","SV",[]],
  ["Tartus Governorate","SY.TA","SY",[]],
  ["Damascus Governorate","SY.DI","SY",[]],
  ["Idlib Governorate","SY.ID","SY",[]],
  ["Homs Governorate","SY.HI","SY",[]],
  ["Hama Governorate","SY.HM","SY",[]],
  ["Aleppo Governorate","SY.HL","SY",[]],
  ["Rif Dimashq Governorate","SY.RD","SY",[]],
  ["Deir ez-Zor Governorate","SY.DY","SY",[]],
  ["Daraa Governorate","SY.DR","SY",[]],
  ["As-Suwayda Governorate","SY.SU","SY",[]],
  ["Ar-Raqqah Governorate","SY.RA","SY",[]],
  ["Quneitra Governorate","SY.QU","SY",[]],
  ["Latakia Governorate","SY.LA","SY",[]],
  ["Al-Hasakah Governorate","SY.HA","SY",[]],
  ["Shiselweni District","SZ.SH","SZ",[]],
  ["Manzini District","SZ.MA","SZ",[]],
  ["Lubombo District","SZ.LU","SZ",[]],
  ["Hhohho District","SZ.HH","SZ",[]],
  ["Salamat Region","TD.SA","TD",[]],
  ["Ouaddaï Region","TD.OD","TD",[]],
  ["Wadi Fira Region","TD.WF","TD",[]],
  ["Tandjilé Region","TD.TA","TD",[]],
  ["Moyen-Chari Region","TD.MC","TD",[]],
  ["Mayo-Kebbi Est Region","TD.ME","TD",[]],
  ["Logone-Oriental","TD.LR","TD",[]],
  ["Logone-Occidental","TD.LO","TD",[]],
  ["Lac","TD.LC","TD",[]],
  ["Kanem Region","TD.KA","TD",[]],
  ["Guéra","TD.GR","TD",[]],
  ["Chari-Baguirmi Region","TD.CB","TD",[]],
  ["Batha Region","TD.BA","TD",[]],
  ["Borkou","TD.23","TD",[]],
  ["Hadjer-Lamis Region","TD.HL","TD",[]],
  ["Mandoul Region","TD.MA","TD",[]],
  ["Mayo-Kebbi Ouest Region","TD.MO","TD",[]],
  ["N'Djamena","TD.ND","TD",[]],
  ["Bahr el Gazel","TD.BG","TD",[]],
  ["Sila Region","TD.SI","TD",[]],
  ["Tibesti Region","TD.TI","TD",[]],
  ["Ennedi-Ouest","TD.EO","TD",[]],
  ["Ennedi-Est","TD.EE","TD",[]],
  ["Crozet","TF.X2~","TF",[]],
  ["Kerguelen","TF.X3~","TF",[]],
  ["Saint-Paul-et-Amsterdam","TF.X1~","TF",[]],
  ["Iles Eparses","TF.X4~","TF",[]],
  ["Savanes Region, Togo","TG.S","TG",[]],
  ["Plateaux","TG.P","TG",[]],
  ["Maritime Region","TG.M","TG",[]],
  ["Centrale","TG.C","TG",[]],
  ["Kara Region","TG.K","TG",[]],
  ["Uthai Thani Province","TH.61","TH",[]],
  ["Trang Province","TH.92","TH",[]],
  ["Tak Province","TH.63","TH",[]],
  ["Surat Thani Province","TH.84","TH",[]],
  ["Sukhothai Province","TH.64","TH",[]],
  ["Ratchaburi Province","TH.70","TH",[]],
  ["Ranong Province","TH.85","TH",[]],
  ["Prachuap Khiri Khan Province","TH.77","TH",[]],
  ["Phuket Province","TH.83","TH",[]],
  ["Phetchaburi Province","TH.76","TH",[]],
  ["Phang Nga Province","TH.82","TH",[]],
  ["Mae Hong Son Province","TH.58","TH",[]],
  ["Lamphun Province","TH.51","TH",[]],
  ["Lampang Province","TH.52","TH",[]],
  ["Krabi Province","TH.81","TH",[]],
  ["Kanchanaburi Province","TH.71","TH",[]],
  ["Kamphaeng Phet Province","TH.62","TH",[]],
  ["Chumphon Province","TH.86","TH",[]],
  ["Chiang Rai Province","TH.57","TH",[]],
  ["Chiang Mai Province","TH.50","TH",[]],
  ["Yasothon Province","TH.35","TH",[]],
  ["Yala Province","TH.95","TH",[]],
  ["Uttaradit Province","TH.53","TH",[]],
  ["Trat Province","TH.23","TH",[]],
  ["Surin Province","TH.32","TH",[]],
  ["Suphan Buri Province","TH.72","TH",[]],
  ["Songkhla Province","TH.90","TH",[]],
  ["Sisaket Province","TH.33","TH",[]],
  ["Sing Buri Province","TH.17","TH",[]],
  ["Satun Province","TH.91","TH",[]],
  ["Saraburi Province","TH.19","TH",[]],
  ["Samut Songkhram Province","TH.75","TH",[]],
  ["Samut Sakhon Province","TH.74","TH",[]],
  ["Samut Prakan Province","TH.11","TH",[]],
  ["Sakon Nakhon Province","TH.47","TH",[]],
  ["Roi Et Province","TH.45","TH",[]],
  ["Rayong Province","TH.21","TH",[]],
  ["Phra Nakhon Si Ayutthaya Province","TH.14","TH",[]],
  ["Phrae Province","TH.54","TH",[]],
  ["Phitsanulok Province","TH.65","TH",[]],
  ["Phichit Province","TH.66","TH",[]],
  ["Phetchabun Province","TH.67","TH",[]],
  ["Phayao Province","TH.56","TH",[]],
  ["Phatthalung Province","TH.93","TH",[]],
  ["Pattani Province","TH.94","TH",[]],
  ["Pathum Thani Province","TH.13","TH",[]],
  ["Nonthaburi Province","TH.12","TH",[]],
  ["Nong Khai Province","TH.43","TH",[]],
  ["Narathiwat Province","TH.96","TH",[]],
  ["Nan Province","TH.55","TH",[]],
  ["Nakhon Si Thammarat Province","TH.80","TH",[]],
  ["Nakhon Sawan Province","TH.60","TH",[]],
  ["Nakhon Ratchasima Province","TH.30","TH",[]],
  ["Nakhon Phanom Province","TH.48","TH",[]],
  ["Nakhon Pathom Province","TH.73","TH",[]],
  ["Nakhon Nayok Province","TH.26","TH",[]],
  ["Mukdahan Province","TH.49","TH",[]],
  ["Maha Sarakham Province","TH.44","TH",[]],
  ["Lopburi Province","TH.16","TH",[]],
  ["Loei Province","TH.42","TH",[]],
  ["Bangkok","TH.10","TH",[]],
  ["Khon Kaen Province","TH.40","TH",[]],
  ["Kalasin Province","TH.46","TH",[]],
  ["Chonburi Province","TH.20","TH",[]],
  ["Chanthaburi Province","TH.22","TH",[]],
  ["Chaiyaphum Province","TH.36","TH",[]],
  ["Chainat Province","TH.18","TH",[]],
  ["Chachoengsao Province","TH.24","TH",[]],
  ["Buriram Province","TH.31","TH",[]],
  ["Ang Thong Province","TH.15","TH",[]],
  ["Udon Thani Province","TH.41","TH",[]],
  ["Prachinburi Province","TH.25","TH",[]],
  ["Ubon Ratchathani Province","TH.34","TH",[]],
  ["Amnat Charoen Province","TH.37","TH",[]],
  ["Nong Bua Lamphu Province","TH.39","TH",[]],
  ["Sa Kaeo Province","TH.27","TH",[]],
  ["Bueng Kan","TH.38","TH",[]],
  ["Sughd Province","TJ.SU","TJ",[]],
  ["Gorno-Badakhshan Autonomous Province","TJ.GB","TJ",[]],
  ["Khatlon Province","TJ.KT","TJ",[]],
  ["Districts of Republican Subordination","TJ.RA","TJ",[]],
  ["Dushanbe","TJ.DU","TJ",[]],
  ["Viqueque District","TL.VI","TL",[]],
  ["Manufahi District","TL.MF","TL",[]],
  ["Manatuto District","TL.MT","TL",[]],
  ["Liquiçá District","TL.LI","TL",[]],
  ["Lautém District","TL.LA","TL",[]],
  ["Cova Lima District","TL.CO","TL",[]],
  ["Ermera District","TL.ER","TL",[]],
  ["Dili District","TL.DI","TL",[]],
  ["Bobonaro District","TL.BO","TL",[]],
  ["Baucau District","TL.BA","TL",[]],
  ["Oecusse District","TL.OE","TL",[]],
  ["Ainaro District","TL.AN","TL",[]],
  ["Aileu District","TL.AL","TL",[]],
  ["Balkan Province","TM.B","TM",[]],
  ["Ahal Province","TM.A","TM",[]],
  ["Ashgabat","TM.S","TM",[]],
  ["Daşoguz Province","TM.D","TM",[]],
  ["Mary Province","TM.M","TM",[]],
  ["Lebap Province","TM.L","TM",[]],
  ["Zaghouan Governorate","TN.22","TN",[]],
  ["Tunis Governorate","TN.11","TN",[]],
  ["Tozeur Governorate","TN.72","TN",[]],
  ["Tataouine Governorate","TN.83","TN",[]],
  ["Sousse Governorate","TN.51","TN",[]],
  ["Siliana Governorate","TN.34","TN",[]],
  ["Sidi Bouzid Governorate","TN.43","TN",[]],
  ["Sfax Governorate","TN.61","TN",[]],
  ["Kebili Governorate","TN.73","TN",[]],
  ["Gafsa Governorate","TN.71","TN",[]],
  ["Gabès Governorate","TN.81","TN",[]],
  ["Nabeul Governorate","TN.21","TN",[]],
  ["Medenine Governorate","TN.82","TN",[]],
  ["Jendouba Governorate","TN.32","TN",[]],
  ["Ben Arous Governorate","TN.13","TN",[]],
  ["Bizerte Governorate","TN.23","TN",[]],
  ["Béja Governorate","TN.31","TN",[]],
  ["Ariana Governorate","TN.12","TN",[]],
  ["Kairouan Governorate","TN.41","TN",[]],
  ["Kasserine Governorate","TN.42","TN",[]],
  ["Monastir Governorate","TN.52","TN",[]],
  ["Mahdia Governorate","TN.53","TN",[]],
  ["Kef Governorate","TN.33","TN",[]],
  ["Manouba Governorate","TN.14","TN",[]],
  ["Vavaʻu","TO.05","TO",[]],
  ["Tongatapu","TO.04","TO",[]],
  ["Haʻapai","TO.02","TO",[]],
  ["ʻEua","TO.01","TO",[]],
  ["Niuas","TO.03","TO",[]],
  ["Yozgat Province","TR.66","TR",[]],
  ["Van Province","TR.65","TR",[]],
  ["Uşak Province","TR.64","TR",[]],
  ["Şanlıurfa Province","TR.63","TR",[]],
  ["Tunceli Province","TR.62","TR",[]],
  ["Sivas Province","TR.58","TR",[]],
  ["Siirt Province","TR.56","TR",[]],
  ["Niğde Province","TR.51","TR",[]],
  ["Nevşehir Province","TR.50","TR",[]],
  ["Muş Province","TR.49","TR",[]],
  ["Muğla Province","TR.48","TR",[]],
  ["Mardin Province","TR.47","TR",[]],
  ["Manisa Province","TR.45","TR",[]],
  ["Malatya Province","TR.44","TR",[]],
  ["Kütahya Province","TR.43","TR",[]],
  ["Konya Province","TR.42","TR",[]],
  ["Kırşehir Province","TR.40","TR",[]],
  ["Kayseri Province","TR.38","TR",[]],
  ["Kahramanmaraş Province","TR.46","TR",[]],
  ["İzmir Province","TR.35","TR",[]],
  ["Isparta Province","TR.32","TR",[]],
  ["Mersin Province","TR.33","TR",[]],
  ["Hatay Province","TR.31","TR",[]],
  ["Hakkâri Province","TR.30","TR",[]],
  ["Gaziantep Province","TR.27","TR",[]],
  ["Eskişehir Province","TR.26","TR",[]],
  ["Erzurum Province","TR.25","TR",[]],
  ["Erzincan Province","TR.24","TR",[]],
  ["Elazığ Province","TR.23","TR",[]],
  ["Diyarbakır Province","TR.21","TR",[]],
  ["Denizli Province","TR.20","TR",[]],
  ["Burdur Province","TR.15","TR",[]],
  ["Bitlis Province","TR.13","TR",[]],
  ["Bingöl Province","TR.12","TR",[]],
  ["Bilecik Province","TR.11","TR",[]],
  ["Balıkesir Province","TR.10","TR",[]],
  ["Aydın Province","TR.09","TR",[]],
  ["Antalya Province","TR.07","TR",[]],
  ["Ankara Province","TR.06","TR",[]],
  ["Ağrı Province","TR.04","TR",[]],
  ["Afyonkarahisar Province","TR.03","TR",[]],
  ["Adıyaman Province","TR.02","TR",[]],
  ["Adana Province","TR.01","TR",[]],
  ["Osmaniye Province","TR.80","TR",[]],
  ["Iğdır Province","TR.76","TR",[]],
  ["Aksaray Province","TR.68","TR",[]],
  ["Batman Province","TR.72","TR",[]],
  ["Karaman Province","TR.70","TR",[]],
  ["Kırıkkale Province","TR.71","TR",[]],
  ["Şırnak Province","TR.73","TR",[]],
  ["Kilis Province","TR.79","TR",[]],
  ["Zonguldak Province","TR.67","TR",[]],
  ["Trabzon Province","TR.61","TR",[]],
  ["Tokat Province","TR.60","TR",[]],
  ["Tekirdağ Province","TR.59","TR",[]],
  ["Sinop Province","TR.57","TR",[]],
  ["Samsun Province","TR.55","TR",[]],
  ["Sakarya Province","TR.54","TR",[]],
  ["Rize Province","TR.53","TR",[]],
  ["Ordu Province","TR.52","TR",[]],
  ["Kocaeli Province","TR.41","TR",[]],
  ["Kırklareli Province","TR.39","TR",[]],
  ["Kastamonu Province","TR.37","TR",[]],
  ["Kars Province","TR.36","TR",[]],
  ["Istanbul Province","TR.34","TR",[]],
  ["Gümüşhane Province","TR.29","TR",[]],
  ["Giresun Province","TR.28","TR",[]],
  ["Edirne Province","TR.22","TR",[]],
  ["Çorum Province","TR.19","TR",[]],
  ["Çankırı Province","TR.18","TR",[]],
  ["Çanakkale Province","TR.17","TR",[]],
  ["Bursa Province","TR.16","TR",[]],
  ["Bolu Province","TR.14","TR",[]],
  ["Artvin Province","TR.08","TR",[]],
  ["Amasya Province","TR.05","TR",[]],
  ["Bartın Province","TR.74","TR",[]],
  ["Karabük Province","TR.78","TR",[]],
  ["Yalova Province","TR.77","TR",[]],
  ["Ardahan Province","TR.75","TR",[]],
  ["Bayburt Province","TR.69","TR",[]],
  ["Düzce Province","TR.81","TR",[]],
  ["Tobago","TT.TOB","TT",[]],
  ["San Fernando, Trinidad and Tobago","TT.SFO","TT",[]],
  ["Port of Spain","TT.POS","TT",[]],
  ["Rio Claro-Mayaro Regional Corporation","TT.MRC","TT",[]],
  ["Arima","TT.ARI","TT",[]],
  ["Chaguanas","TT.CHA","TT",[]],
  ["Couva-Tabaquite-Talparo Regional Corporation","TT.CTT","TT",[]],
  ["Diego Martin Regional Corporation","TT.DMN","TT",[]],
  ["Penal-Debe Regional Corporation","TT.PED","TT",[]],
  ["Princes Town Regional Corporation","TT.PRT","TT",[]],
  ["Point Fortin","TT.PTF","TT",[]],
  ["Sangre Grande Regional Corporation","TT.SGE","TT",[]],
  ["Siparia Regional Corporation","TT.SIP","TT",[]],
  ["San Juan-Laventille Regional Corporation","TT.SJL","TT",[]],
  ["Tunapuna-Piarco Regional Corporation","TT.TUP","TT",[]],
  ["Nui","TV.NIU","TV",[]],
  ["Nanumea","TV.NMA","TV",[]],
  ["Funafuti","TV.FUN","TV",[]],
  ["Niutao","TV.NIT","TV",[]],
  ["Nanumanga","TV.NMG","TV",[]],
  ["Vaitupu","TV.VAI","TV",[]],
  ["Nukufetau","TV.NKF","TV",[]],
  ["Nukulaelae","TV.NKL","TV",[]],
  ["Changhua","TW.CHA","TW",[]],
  ["Chiayi","TW.CYI","TW",[]],
  ["Chiayi","TW.CYQ","TW",[]],
  ["Hsinchu","TW.HSZ","TW",[]],
  ["Hsinchu","TW.HSQ","TW",[]],
  ["Hualien","TW.HUA","TW",[]],
  ["Kaohsiung","TW.KHH","TW",[]],
  ["Keelung","TW.KEE","TW",[]],
  ["Kinmen","TW.KIN","TW",[]],
  ["Lienchiang","TW.LIE","TW",[]],
  ["Miaoli","TW.MIA","TW",[]],
  ["Nantou","TW.NAN","TW",[]],
  ["New Taipei","TW.NWT","TW",[]],
  ["Penghu","TW.PEN","TW",[]],
  ["Pingtung","TW.PIF","TW",[]],
  ["Taichung","TW.TXG","TW",[]],
  ["Tainan","TW.TNN","TW",[]],
  ["Taipei","TW.TPE","TW",[]],
  ["Taitung","TW.TTT","TW",[]],
  ["Taoyuan","TW.TAO","TW",[]],
  ["Yilan","TW.ILA","TW",[]],
  ["Yunlin","TW.YUN","TW",[]],
  ["Kagera Region","TZ.05","TZ",[]],
  ["Mjini Magharibi Region","TZ.15","TZ",[]],
  ["Zanzibar North Region","TZ.07","TZ",[]],
  ["Unguja South Region","TZ.11","TZ",[]],
  ["Tanga Region","TZ.25","TZ",[]],
  ["Tabora Region","TZ.24","TZ",[]],
  ["Singida Region","TZ.23","TZ",[]],
  ["Shinyanga Region","TZ.22","TZ",[]],
  ["Rukwa Region","TZ.20","TZ",[]],
  ["Pwani Region","TZ.19","TZ",[]],
  ["Pemba South Region","TZ.10","TZ",[]],
  ["Pemba North Region","TZ.06","TZ",[]],
  ["Mwanza Region","TZ.18","TZ",[]],
  ["Morogoro Region","TZ.16","TZ",[]],
  ["Mbeya Region","TZ.14","TZ",[]],
  ["Mara Region","TZ.13","TZ",[]],
  ["Lindi Region","TZ.12","TZ",[]],
  ["Kilimanjaro Region","TZ.09","TZ",[]],
  ["Kigoma Region","TZ.08","TZ",[]],
  ["Iringa Region","TZ.04","TZ",[]],
  ["Dodoma Region","TZ.03","TZ",[]],
  ["Dar es Salaam Region","TZ.02","TZ",[]],
  ["Arusha Region","TZ.01","TZ",[]],
  ["Manyara Region","TZ.26","TZ",[]],
  ["Ruvuma Region","TZ.21","TZ",[]],
  ["Mtwara Region","TZ.17","TZ",[]],
  ["Simiyu","TZ.30","TZ",[]],
  ["Geita","TZ.27","TZ",[]],
  ["Katavi","TZ.28","TZ",[]],
  ["Simiyu","TZ.30","TZ",[]],
  ["Zhytomyr Oblast","UA.18","UA",[]],
  ["Zaporizhia Oblast","UA.23","UA",[]],
  ["Zakarpattia Oblast","UA.21","UA",[]],
  ["Volyn Oblast","UA.07","UA",[]],
  ["Vinnytsia Oblast","UA.05","UA",[]],
  ["Ternopil Oblast","UA.61","UA",[]],
  ["Sumy Oblast","UA.59","UA",[]],
  ["Sevastopol","UA.40","UA",[]],
  ["Rivne Oblast","UA.56","UA",[]],
  ["Poltava Oblast","UA.53","UA",[]],
  ["Odessa Oblast","UA.51","UA",[]],
  ["Mykolaiv Oblast","UA.48","UA",[]],
  ["Lviv Oblast","UA.46","UA",[]],
  ["Luhansk Oblast","UA.09","UA",[]],
  ["Kiev Oblast","UA.32","UA",[]],
  ["Kiev","UA.30","UA",[]],
  ["Autonomous Republic of Crimea","UA.43","UA",[]],
  ["Kirovohrad Oblast","UA.35","UA",[]],
  ["Khmelnytskyi Oblast","UA.68","UA",[]],
  ["Kherson Oblast","UA.65","UA",[]],
  ["Kharkiv Oblast","UA.63","UA",[]],
  ["Ivano-Frankivsk Oblast","UA.26","UA",[]],
  ["Donetsk Oblast","UA.14","UA",[]],
  ["Dnipropetrovsk Oblast","UA.12","UA",[]],
  ["Chernivtsi Oblast","UA.77","UA",[]],
  ["Chernihiv Oblast","UA.74","UA",[]],
  ["Cherkasy Oblast","UA.71","UA",[]],
  ["Central Region","UG.C","UG",[]],
  ["Eastern Region","UG.E","UG",[]],
  ["Northern Region","UG.N","UG",[]],
  ["Western Region","UG.W","UG",[]],
  ["Wake Island","UM.79","UM",[]],
  ["Navassa Island","UM.76","UM",[]],
  ["Baker Island","UM.81","UM",[]],
  ["Howland Island","UM.84","UM",[]],
  ["Jarvis Island","UM.86","UM",[]],
  ["Johnston Atoll","UM.67","UM",[]],
  ["Kingman Reef","UM.89","UM",[]],
  ["Midway Atoll","UM.71","UM",[]],
  ["Palmyra Atoll","UM.95","UM",[]],
  ["Arkansas","US.AR","US",[],2],
  ["District of Columbia","US.DC","US",[],2],
  ["Delaware","US.DE","US",[],2],
  ["Florida","US.FL","US",[],2],
  ["Georgia","US.GA","US",[],2],
  ["Kansas","US.KS","US",[],2],
  ["Louisiana","US.LA","US",[],2],
  ["Maryland","US.MD","US",[],2],
  ["Missouri","US.MO","US",[],2],
  ["Mississippi","US.MS","US",[],2],
  ["North Carolina","US.NC","US",[],2],
  ["Oklahoma","US.OK","US",[],2],
  ["South Carolina","US.SC","US",[],2],
  ["Tennessee","US.TN","US",[],2],
  ["Texas","US.TX","US",[],2],
  ["West Virginia","US.WV","US",[],2],
  ["Alabama","US.AL","US",[],2],
  ["Connecticut","US.CT","US",[],2],
  ["Iowa","US.IA","US",[],2],
  ["Illinois","US.IL","US",[],2],
  ["Indiana","US.IN","US",[],2],
  ["Maine","US.ME","US",[],2],
  ["Michigan","US.MI","US",[],2],
  ["Minnesota","US.MN","US",[],2],
  ["Nebraska","US.NE","US",[],2],
  ["New Hampshire","US.NH","US",[],2],
  ["New Jersey","US.NJ","US",[],2],
  ["New York","US.NY","US",[],2],
  ["Ohio","US.OH","US",[],2],
  ["Rhode Island","US.RI","US",[],2],
  ["Vermont","US.VT","US",[],2],
  ["Wisconsin","US.WI","US",[],2],
  ["California","US.CA","US",[],2],
  ["Colorado","US.CO","US",[],2],
  ["New Mexico","US.NM","US",[],2],
  ["Nevada","US.NV","US",[],2],
  ["Utah","US.UT","US",[],2],
  ["Arizona","US.AZ","US",[],2],
  ["Idaho","US.ID","US",[],2],
  ["Montana","US.MT","US",[],2],
  ["North Dakota","US.ND","US",[],2],
  ["Oregon","US.OR","US",[],2],
  ["South Dakota","US.SD","US",[],2],
  ["Washington","US.WA","US",[],2],
  ["Wyoming","US.WY","US",[],2],
  ["Hawaii","US.HI","US",[],2],
  ["Alaska","US.AK","US",[],2],
  ["Kentucky","US.KY","US",[],2],
  ["Massachusetts","US.MA","US",[],2],
  ["Pennsylvania","US.PA","US",[],2],
  ["Virginia","US.VA","US",[],2],
  ["Treinta y Tres Department","UY.TT","UY",[]],
  ["Tacuarembó Department","UY.TA","UY",[]],
  ["Soriano Department","UY.SO","UY",[]],
  ["San Jose","UY.SJ","UY",[]],
  ["Salto Department","UY.SA","UY",[]],
  ["Rocha Department","UY.RO","UY",[]],
  ["Rivera Department","UY.RV","UY",[]],
  ["Rio Negro","UY.RN","UY",[]],
  ["Paysandú Department","UY.PA","UY",[]],
  ["Montevideo Department","UY.MO","UY",[]],
  ["Maldonado Department","UY.MA","UY",[]],
  ["Lavalleja Department","UY.LA","UY",[]],
  ["Florida","UY.FD","UY",[]],
  ["Flores Department","UY.FS","UY",[]],
  ["Durazno Department","UY.DU","UY",[]],
  ["Colonia Department","UY.CO","UY",[]],
  ["Cerro Largo Department","UY.CL","UY",[]],
  ["Canelones Department","UY.CA","UY",[]],
  ["Artigas Department","UY.AR","UY",[]],
  ["Karakalpakstan","UZ.QR","UZ",[]],
  ["Surxondaryo Province","UZ.SU","UZ",[]],
  ["Samarqand Province","UZ.SA","UZ",[]],
  ["Qashqadaryo Province","UZ.QA","UZ",[]],
  ["Bukhara Province","UZ.BU","UZ",[]],
  ["Tashkent Province","UZ.TO","UZ",[]],
  ["Tashkent","UZ.TK","UZ",[]],
  ["Sirdaryo Province","UZ.SI","UZ",[]],
  ["Navoiy Province","UZ.NW","UZ",[]],
  ["Namangan Province","UZ.NG","UZ",[]],
  ["Xorazm Province","UZ.XO","UZ",[]],
  ["Jizzakh Province","UZ.JI","UZ",[]],
  ["Fergana Province","UZ.FA","UZ",[]],
  ["Andijan Province","UZ.AN","UZ",[]],
  ["Saint Patrick","VC.05","VC",[]],
  ["Saint George","VC.04","VC",[]],
  ["Saint David","VC.03","VC",[]],
  ["Saint Andrew","VC.02","VC",[]],
  ["Grenadines Parish","VC.06","VC",[]],
  ["Charlotte Parish, Saint Vincent and the Grenadines","VC.01","VC",[]],
  ["Zulia","VE.V","VE",[]],
  ["Yaracuy","VE.U","VE",[]],
  ["Trujillo, Venezuela","VE.T","VE",[]],
  ["Táchira","VE.S","VE",[]],
  ["Sucre","VE.R","VE",[]],
  ["Portuguesa, Venezuela","VE.P","VE",[]],
  ["Nueva Esparta","VE.O","VE",[]],
  ["Monagas","VE.N","VE",[]],
  ["Miranda","VE.M","VE",[]],
  ["Mérida, Venezuela","VE.L","VE",[]],
  ["Lara, Venezuela","VE.K","VE",[]],
  ["Guárico","VE.J","VE",[]],
  ["Federal Dependencies of Venezuela","VE.W","VE",[]],
  ["Capital","VE.A","VE",[]],
  ["Falcón","VE.I","VE",[]],
  ["Delta Amacuro","VE.Y","VE",[]],
  ["Cojedes, Venezuela","VE.H","VE",[]],
  ["Carabobo","VE.G","VE",[]],
  ["Bolivar","VE.F","VE",[]],
  ["Barinas, Venezuela","VE.E","VE",[]],
  ["Aragua","VE.D","VE",[]],
  ["Apure","VE.C","VE",[]],
  ["Anzoátegui","VE.B","VE",[]],
  ["Amazonas","VE.Z","VE",[]],
  ["Vargas, Venezuela","VE.X","VE",[]],
  ["Saint Croix Island","VI.C","VI",[]],
  ["Saint John Island","VI.J","VI",[]],
  ["Saint Thomas Island","VI.T","VI",[]],
  ["Nghệ An Province","VN.22","VN",[]],
  ["Ninh Bình Province","VN.18","VN",[]],
  ["Ninh Thuận Province","VN.36","VN",[]],
  ["Sóc Trăng Province","VN.52","VN",[]],
  ["Trà Vinh Province","VN.51","VN",[]],
  ["Tuyên Quang Province","VN.07","VN",[]],
  ["Vĩnh Long Province","VN.49","VN",[]],
  ["Yên Bái Province","VN.06","VN",[]],
  ["Lào Cai Province","VN.02","VN",[]],
  ["Tiền Giang Province","VN.46","VN",[]],
  ["Thừa Thiên–Huế Province","VN.26","VN",[]],
  ["Kon Tum Province","VN.28","VN",[]],
  ["Thanh Hóa Province","VN.21","VN",[]],
  ["Thái Bình Province","VN.20","VN",[]],
  ["Tây Ninh Province","VN.37","VN",[]],
  ["Sơn La Province","VN.05","VN",[]],
  ["Quảng Trị Province","VN.25","VN",[]],
  ["Quảng Ninh Province","VN.13","VN",[]],
  ["Quảng Ngãi Province","VN.29","VN",[]],
  ["Quảng Bình Province","VN.24","VN",[]],
  ["Phú Yên Province","VN.32","VN",[]],
  ["Hòa Bình Province","VN.14","VN",[]],
  ["Long An Province","VN.41","VN",[]],
  ["Lạng Sơn Province","VN.09","VN",[]],
  ["Lâm Đồng Province","VN.35","VN",[]],
  ["Lai Châu Province","VN.01","VN",[]],
  ["Kiên Giang Province","VN.47","VN",[]],
  ["Khánh Hòa Province","VN.34","VN",[]],
  ["Ho Chi Minh City","VN.SG","VN",[]],
  ["Hà Tĩnh Province","VN.23","VN",[]],
  ["Hà Giang Province","VN.03","VN",[]],
  ["Gia Lai Province","VN.30","VN",[]],
  ["Ha Noi","VN.64","VN",[]],
  ["Can Tho","VN.48","VN",[]],
  ["Hai Phong","VN.62","VN",[]],
  ["Bình Thuận Province","VN.40","VN",[]],
  ["Đồng Tháp Province","VN.45","VN",[]],
  ["Đồng Nai Province","VN.39","VN",[]],
  ["Đắk Lắk Province","VN.33","VN",[]],
  ["Bà Rịa–Vũng Tàu Province","VN.43","VN",[]],
  ["Cao Bằng Province","VN.04","VN",[]],
  ["Bình Định Province","VN.31","VN",[]],
  ["Bến Tre Province","VN.50","VN",[]],
  ["An Giang Province","VN.44","VN",[]],
  ["Đắk Nông Province","VN.72","VN",[]],
  ["Điện Biên Province","VN.71","VN",[]],
  ["Bắc Ninh Province","VN.56","VN",[]],
  ["Bắc Giang Province","VN.54","VN",[]],
  ["Da Nang","VN.60","VN",[]],
  ["Bình Dương Province","VN.57","VN",[]],
  ["Bình Phước Province","VN.58","VN",[]],
  ["Thái Nguyên Province","VN.69","VN",[]],
  ["Quảng Nam Province","VN.27","VN",[]],
  ["Phú Thọ Province","VN.68","VN",[]],
  ["Nam Định Province","VN.67","VN",[]],
  ["Hà Nam Province","VN.63","VN",[]],
  ["Bc Kn Province","VN.53","VN",[]],
  ["Bạc Liêu Province","VN.55","VN",[]],
  ["Cà Mau Province","VN.59","VN",[]],
  ["Hải Dương Province","VN.61","VN",[]],
  ["Hưng Yên Province","VN.66","VN",[]],
  ["Vĩnh Phúc Province","VN.70","VN",[]],
  ["Hậu Giang Province","VN.73","VN",[]],
  ["Tafea Province","VU.TAE","VU",[]],
  ["Sanma Province","VU.SAM","VU",[]],
  ["Torba Province","VU.TOB","VU",[]],
  ["Malampa Province","VU.MAP","VU",[]],
  ["Penama Province","VU.PAM","VU",[]],
  ["Shefa Province","VU.SEE","VU",[]],
  ["Uvea","WF.UV","WF",[]],
  ["Sigave","WF.SG","WF",[]],
  ["Alo","WF.AL","WF",[]],
  ["Vaisigano","WS.VS","WS",[]],
  ["Va'a-o-Fonoti","WS.VF","WS",[]],
  ["Tuamasaga","WS.TU","WS",[]],
  ["Satupa'itea","WS.SA","WS",[]],
  ["Palauli","WS.PA","WS",[]],
  ["Gaga'ifomauga","WS.GI","WS",[]],
  ["Gaga'emauga","WS.GE","WS",[]],
  ["Fa'asaleleaga","WS.FA","WS",[]],
  ["Atua","WS.AT","WS",[]],
  ["Aiga-i-le-Tai","WS.AL","WS",[]],
  ["A'ana","WS.AA","WS",[]],
  ["Ta'izz Governorate","YE.TA","YE",[]],
  ["Shabwah Governorate","YE.SH","YE",[]],
  ["Sana'a","YE.SA","YE",[]],
  ["Saada Governorate","YE.SD","YE",[]],
  ["Raymah Governorate","YE.RA","YE",[]],
  ["Ma'rib Governorate","YE.MA","YE",[]],
  ["Al Mahwit Governorate","YE.MW","YE",[]],
  ["Al Jawf","YE.JA","YE",[]],
  ["Hadhramaut Governorate","YE.HD","YE",[]],
  ["Dhamar Governorate","YE.DH","YE",[]],
  ["Al Mahrah Governorate","YE.MR","YE",[]],
  ["Al Hudaydah Governorate","YE.HU","YE",[]],
  ["Al Bayda' Governorate","YE.BA","YE",[]],
  ["'Adan Governorate","YE.AD","YE",[]],
  ["Abyan Governorate","YE.AB","YE",[]],
  ["Ad Dali' Governorate","YE.DA","YE",[]],
  ["'Amran Governorate","YE.AM","YE",[]],
  ["Hajjah Governorate","YE.HJ","YE",[]],
  ["Ibb Governorate","YE.IB","YE",[]],
  ["Lahij Governorate","YE.LA","YE",[]],
  ["Sana'a","YE.SA","YE",[]],
  ["Free State","ZA.FS","ZA",[]],
  ["KwaZulu-Natal","ZA.ZN","ZA",[]],
  ["Eastern Cape","ZA.EC","ZA",[]],
  ["Gauteng","ZA.GT","ZA",[]],
  ["Mpumalanga","ZA.MP","ZA",[]],
  ["Northern Cape","ZA.NC","ZA",[]],
  ["Limpopo","ZA.LP","ZA",[]],
  ["North West","ZA.NW","ZA",[]],
  ["Western Cape","ZA.WC","ZA",[]],
  ["Western","ZM.01","ZM",[]],
  ["Southern","ZM.07","ZM",[]],
  ["Northwestern Province, Zambia","ZM.06","ZM",[]],
  ["Northern","ZM.05","ZM",[]],
  ["Lusaka Province","ZM.09","ZM",[]],
  ["Luapula Province","ZM.04","ZM",[]],
  ["Eastern","ZM.03","ZM",[]],
  ["Copperbelt Province","ZM.08","ZM",[]],
  ["Central","ZM.02","ZM",[]],
  ["Muchinga","ZM.10","ZM",[]],
  ["Midlands Province","ZW.MI","ZW",[]],
  ["Matabeleland South Province","ZW.MS","ZW",[]],
  ["Matabeleland North Province","ZW.MN","ZW",[]],
  ["Masvingo Province","ZW.MV","ZW",[]],
  ["Mashonaland West Province","ZW.MW","ZW",[]],
  ["Mashonaland East Province","ZW.ME","ZW",[]],
  ["Mashonaland Central Province","ZW.MC","ZW",[]],
  ["Manicaland Province","ZW.MA","ZW",[]],
  ["Bulawayo","ZW.BU","ZW",[]],
  ["Harare Province","ZW.HA","ZW",[]],
]
