
import { inline } from 'icons'
import caretDownIcon from 'icons/solid/caret-down'

import { SlotIndicator } from './slot-indicator'

type Variable = import('../variables').Variable


export class VariableIndicator extends SlotIndicator {
  variable: Variable
  noCaret: boolean

  constructor ({ variable, onClick, noCaret }) {
    super({
      slot: variable.slot,
      state: variable.uxState,
      onClick,
    })
    Object.assign(this, { variable, noCaret })
  }

  onMouseOver () { super.onMouseOver(); this.variable.onHoverChange(false) }
  onMouseOut () { super.onMouseOut(); this.variable.onHoverChange(true) }

  get valueHTML () { return this.variable.transformedResult }
  get slotHTML () {
    if (!this.variable.hasSlots) { return null }
    return (
      <>
        {super.slotHTML}
        {this.slotCaretHTML}
      </>
    )
  }

  private get slotCaretHTML () {
    return this.noCaret ? null : inline(caretDownIcon)
  }

  /**
   * ! Purely by convention the `parentElement` must be `this.boundToElement`
   *   in `binding-wvar` but not elsewhere
   */
  disconnectedCallback (node: HTMLElement) {
    this.variable.disconnectFromDOM(node.parentElement)
  }
}

class VariableIndicatorRightView extends VariableIndicator {
  static get css () {
    return {
      ...super.css,
      value: {
        ...super.css.value,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }
  }
}

VariableIndicatorRightView.register()

VariableIndicator.register()
