import DataComponent from './DataComponent'

export default class RangeComponent extends DataComponent {
  min = ko.observable<string|number>()
  max = ko.observable<string|number>()

  static get namespace () { return 'primitive' }
  get componentName () { return 'range' }

  set (v = {}) {
    this.min(v.min)
    this.max(v.max)
  }

  get () {
    const { min, max } = this.toJS()
    if (!min && !max) { return '' }
    if (min === max) { return '' + min }
    return `${min}-${max}`
  }

  toJS () { return { min: this.min(), max: this.max() } }

  is_blank () { return this.is_equal({ min: undefined, max: undefined }) }

  is_equal (other) {
    return other.min === this.min() && other.max === this.max()
  }
}

RangeComponent.register()
