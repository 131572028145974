/**
 * Persons
 */
export type PersonOriginModel = import('DataModel/CrudModel').default
export type PersonFilter = (p: PersonRecord) => boolean

export interface PersonGenerator {
  getPersons (filter: PersonFilter) : IterableIterator<PersonRecord>
}

enum PersonType {
  REFERENCE = 'Reference',
  ENTITY = 'Entity',
  USER = 'User',
  INDIVIDUAL = 'Individual',
}
export { PersonType }

/**
 * Everything that will eventually be in a "PersonModel"
 */
export type PersonRecord = Record<string, string[]> | {
  id: string
  type: PersonType
  name: string[]
  email: string[]
  phone: string[]
  address: AddressRecord[]
  origin: PersonOrigin[]
}

export interface PersonOrigin {
  update (operator: (p: PersonRecord) => void)
  model : PersonOriginModel
  roles () : IterableIterator<PersonRoleRecord>
}

export interface PersonRoleRecord {
  title: string
  start?: Date
  end?: Date
}
