
import { formatForUser } from 'utils/dates'

import ViewComponent from 'ViewComponent'

import { buttons } from 'styles'

export default class DatePickerButton extends ViewComponent {
  target: KnockoutObservable<Date>
  relatedDates: RelatedDatesSource
  picker: any

  constructor (params) {
    super()
    const { target, relatedDates } = params
    Object.assign(this, {
      target,
      relatedDates,
    })
  }

  static get css () {
    return {
      display: {
        ...buttons.clickable,
      }
    }
  }

  get template () {
    const { jss } = this
    const show = (evt: MouseEvent) => {
      evt.stopPropagation()
      evt.preventDefault()
      window.app.modal(
        <date-picker-modal
          modalTitle='Pick a date'
          target={this.target} 
          relatedDates={this.relatedDates} />
      )
    }
    return (
      <div class={jss.display}
        ko-click={show}>
        {this.computed(() => this.dateHTML)}
      </div>
    )
  }

  get dateHTML () {
    return formatForUser(this.target())
  }
}

DatePickerButton.register()
