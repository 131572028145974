
import CollectionList from 'CollectionList'

/**
 * Track the singleton models.
 */
const modelMap = new Map()

export default class ModelList<T> {
  list: KnockoutObservableArray<T>
  loaded: KnockoutObservable<boolean>
  collection: CollectionList

  constructor (Model, authManager, firestoreQuery) {
    Object.assign(this, {
      Model,
      authManager,
      loaded: ko.observable(false),
      list: ko.observableArray([])
    })

    if (firestoreQuery) {
      this.collection = new CollectionList(firestoreQuery)
      this._subs = ko.computed(() => [...this.genModelItems()])
        .extend({ rateLimit: 25 })
        .subscribe(this.updateList.bind(this))
    } else {
      this.loaded(true)
    }
  }

  /**
   * Update the list with the new models from the collection, and
   * set `this.loaded` accordingly.
   * @param {Array.<Model>} models
   */
  updateList (models) {
    this.list(models)
    this.collection.loaded.when(true).then(this.loaded)
  }

  /**
   * @param {firestore.DocumentSnapshot} docSnap
   */
  getOrMakeModel (docSnap) {
    const key = this.Model.vmUniqueFirestoreURI(
      this.authManager, docSnap.ref)
    const model = modelMap.get(key)
    if (model) { return model }
    // Always ensure we have an `id` and `accountID` for the model based
    // on the actual `id` and `accountID` from the firestoreKey.
    const forcedIdAccount = {
      id: docSnap.id,
      accountID: docSnap.ref.path.split('/')[1]
    }
    const modelData = Object.assign({}, docSnap.data(), forcedIdAccount)
    const newModel = new this.Model(modelData, this.authManager)
    newModel.vmAddToCache()
    modelMap.set(key, newModel)
    return newModel
  }

  * genModelItems () {
    this.collection.loaded() // Create dependency.
    if (!this.authManager.firebaseUser()) { return }
    for (const doc of this.collection.list()) {
      yield this.getOrMakeModel(doc)
    }
  }

  dispose () {
    this._subs.dispose()
    this.collection.dispose()
  }
}
