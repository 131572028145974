
import ModalDialog from 'modal-dialog'

import { buttons, typography, color } from 'styles'

import icons from 'icons'
import undo from 'icons/solid/undo-alt'
import pin from 'icons/solid/thumbtack'
import thumbnail from 'icons/solid/th'
import chevronRight from 'icons/solid/chevron-right'

import { updateUserBuildNumber } from './sessions'

export default class ThingsHaveChangedModal extends ModalDialog {
  constructor ({ app, userID, currentBuild }) {
    super({ modalTitle: `What's New in MinuteBox` })
    Object.assign(this, { app, userID, currentBuild })
  }

  static get css () {
    return {
      ...super.css,
      layout: {
        width: '460px',
        display: 'grid',
        marginTop: 10,
        gridTemplateColumns: '100px 1fr',
        gridTemplateAreas: `
        'updateIcon1 update1'
        'updateIcon2 update2'
        'updateIcon3 update3'
        'learn learn'
        'buttons buttons'
        `,
        gridRowGap: '15px',
      },
      _updateIcon: {
        justifySelf: 'center',
        alignSelf: 'center',
        '--icon-height': '2em',
      },
      _updateImg: {
        width: '75px'
      },
      updateImg1: {
        gridArea: 'updateIcon1',
        extend: '_updateImg',

      },
      updateImg2: {
        gridArea: 'updateIcon2',
        extend: '_updateImg',
      },
      updateImg3: {
        gridArea: 'updateIcon3',
        extend: '_updateImg',
      },
      updateIcon: {
        width: '70px'
      },
      header:{
        fontWeight: 'bold',
        clear: 'right',
        display: 'flex',
        },
      update1: {
        gridArea: 'update1',
      },
      update2: {
        gridArea: 'update2',
      },
      update3: {
        gridArea: 'update3',
      },
      link: {
        ...buttons.clickable,
        display: 'block',
      },
      buttons: {
        display: 'flex',
        justifyContent: 'center',
        gridArea: 'buttons'
      },
      okButton: {
        ...buttons.modalOk,
        width: '210px',
      },
      learn: {
        gridArea: 'learn',
        marginTop: 10
      },
      learnMore: {
        color: '#4a90e2',
        cursor: 'pointer',
        display: 'block',
        fontSize: 16,
        textAlign: 'center',
        userSelect: 'none',
        fontFamily: typography.titleFamily,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        },
        '& svg':{
          fill: '#4a90e2'
        }
      }
    }
  }

  get contentHTML () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        {/* <div class={jss.heading}>
          <strong>What's New in  MinuteBox 1.2.</strong>
        </div> */}
        {/* <div class={jss.updateIcon1}>{icons.inline(pin)}</div>
        <div class={jss.updateIcon2}>{icons.inline(thumbnail)}</div>
        <div class={jss.updateIcon3}>{icons.inline(undo)}</div> */}
        <div class={jss.updateImg1}><img class={jss.updateIcon} src='https://minutebox.media/app/icons/2_1icons/iconPeople@2x.png' /></div>
        <div class={jss.updateImg2}><img class={jss.updateIcon} src='https://minutebox.media/app/icons/2_1icons/iconSplitView@2x.png' /></div>
        <div class={jss.updateImg3}><img class={jss.updateIcon} src='https://minutebox.media/app/icons/2_1icons/iconNameSearch@2x.png' /></div>
        <div class={jss.update1}>
          <span class={jss.header}>People View</span>
          <span>A brand new People View to quickly view, find and track every person across all your entities.</span>
        </div>
        <div class={jss.update2}>
          <span class={jss.header}>Split View</span>
          <span>View a minute book and the entity information summary side by side.</span>
        </div>
        <div class={jss.update3}>
          <span class={jss.header}>Name Search</span>
          <span>Quickly run a preliminary name search directly within MinuteBox.</span>
        </div>
        <div class={jss.learn}>
          <a class={jss.learnMore} href='https://help.minutebox.com/article/61-whats-new-in-minutebox' target='minutebox-whats-new'>
            See everything new in MinuteBox in 90 seconds {icons.inline(chevronRight)}
          </a>
        </div>
        <div class={jss.buttons}>
            <div class={jss.okButton} ko-click={() => this.closeClick()}>
              Continue
            </div>
        </div>
      </div>
    )
  }

  async dismiss () {
    super.dismiss()
    if (this.userID !== true) {
      await updateUserBuildNumber(this.app, this.userID)
    }
  }

  async closeClick () {
    this.dismiss()
    this.app.modal(undefined)
  }
}

ThingsHaveChangedModal.register()
