import { MainView } from './MainView'
/**
 *     |———— EntityInfoView ————|
 */
export class EntityInfoView extends MainView {
  private _collapsed: KnockoutObservable<boolean>

  get commandName () { return 'navEis' }

  get title () { return 'Entity Information Summary' }

  get showUndoButton () { return true }

  get collapsed () {
    // There are no menu items until the first time the <entity-information-summary>
    // is rendered so we have to wait for that before we can animate the list
    if (this._collapsed) {
      return this._collapsed
    }
    const obs = ko.observable(true)
    this._collapsed = ko.pureComputed({
      read: obs,
      write: v => {
        if (!v && this.panelProvider.eisSectionNodes.length === 0) {
          this.panelProvider.eisSectionNodes
            .when(v => v.length)
            .then(() => obs(false))
          this.panelProvider.activeViewID(this.id)
        }
        else {
          obs(v)
        }
      },
    })
    this._collapsed.modify = op => this._collapsed(op(obs()))
    return this._collapsed
  }

  get subMenuItems () {
    const { model, eisSectionNodes, showAllEisFields } = this.panelProvider
    return (<minute-book-eis-sections entity={model} showAllEisFields={showAllEisFields} eisSectionNodes={eisSectionNodes} />)
  }

  get mainHTML () {
    const { model, eisSectionNodes, eisNavigation, scrollToComponent, showAllEisFields } = this.panelProvider
    return (<entity-information-summary entity={model} showAllEisFields={showAllEisFields} eisSectionNodes={eisSectionNodes} navigation={eisNavigation} scrollToComponent={scrollToComponent} />)
  }

  static get css () {
    return {
      ...super.css,
      mainContainer: {
        ...super.css.mainContainer,
      },
    }
  }
}
