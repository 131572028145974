
import ViewComponent from 'ViewComponent'
import { color } from 'styles'

import './person-details'
import { tooltip } from 'tool-tip/tool-tip'

export default class PersonIndicator extends ViewComponent {
  person: PersonRecord | KnockoutObservable<PersonRecord> | KnockoutComputed<PersonRecord>
  suggestedPerson: PersonRecord | KnockoutObservable<PersonRecord> | KnockoutComputed<PersonRecord>
  valueGetter: (person: PersonRecord) => any
  onClick: (evt: MouseEvent) => void
  noDetailsOnHover: boolean

  constructor ({ person, suggestedPerson, valueGetter, onClick, noDetailsOnHover }) {
    super()
    Object.assign(this, {
      person,
      suggestedPerson: suggestedPerson || person,
      valueGetter: valueGetter || (p => p.name.find(v => v) || p.email.find(v => v) || ''),
      onClick: onClick || (evt => this.defaultOnClick(evt)),
      noDetailsOnHover,
    })
  }

  static get css () {
    return {
      name: {},

      indicator: {
        ...color.dynamic({
          light: { 'shadow': color.grey.contextShadow },
          dark: { 'shadow': color.dmgrey.contextShadow },
        }),
        display: 'inline',
        cursor: 'pointer',
        borderRadius: 5,
        transition: '150ms ease-in-out',
        '&:hover':{
          cursor: 'pointer',
          background: 'var(--shadow)',
          boxShadow: `
            0px 0px 0px 3px var(--shadow),
            -3px 0px 0px 3px var(--shadow),
            3px 0px 0px 3px var(--shadow)`,
        },
      },
    }
  }

  get detailsHTML () {
    return <person-details person={this.person} suggestedPerson={this.suggestedPerson} />
  }

  defaultOnClick (evt:MouseEvent) {
    evt.stopPropagation()
    evt.preventDefault()
    window.app.viewPerson(this.suggestedPerson)
  }

  get template () {
    const { jss } = this
    const person = this.computed<PersonRecord>(() => ko.unwrap(this.person))
    const personType = this.computed(() => person() && person().type)
    const value = this.computed(() => this.valueGetter(person()))
    const anchor = (
      <span class={jss.indicator} person-type={personType} ko-click={this.onClick}>
        <span class={jss.name}>{value}</span>
      </span>
    )
    return tooltip(
      !this.noDetailsOnHover && this.detailsHTML,
      { my: 'top left', at: 'bottom left' },
      anchor,
    )
  }
}

PersonIndicator.register()
