
import ViewComponent from 'ViewComponent'
import { sortBy, noop } from 'lodash-es'

import { list } from 'styles'
import 'inline-input'

import icons from 'icons'
import gripIcon from 'icons/icon-handle'
import hellip from 'icons/light/ellipsis-h'
import filterIcon from 'icons/light/filter'
import solidFilterIcon from 'icons/solid/filter'

/**
 * A list of filters; at this time, appears only in the sub-menu for the
 * root panel providers.
 *
 * Note: REFACTOR w/ model-list-options show-hide-selector list
 */
export default class FiltersList extends ViewComponent {
  constructor (params, ...args) {
    super({}, ...args)
    const {
      colList, currentConditions, onSelect, onDelete, everythingFilter
    } = params

    Object.assign(this, {
      colList,
      currentConditions,
      onSelect,
      onDelete: onDelete || noop,
      everythingFilterHTML: ko.observable()
    })

    everythingFilter
      .then(fm => this.filterHTML(fm))
      .then(this.everythingFilterHTML)
  }

  get list () {
    return this._list || (
      this._list = this.computed(() =>
        this.sortedList()).extend({ rateLimit: { timeout: 50, method: "notifyWhenChangesStop" } })
    )
  }

  /**
   * @param {Iterable.<FilterModel>}
   * @return {Array.<FilterModel>}
   */
  sortedList (list = this.colList.list) {
    return sortBy([...list], fm => fm.order())
  }

  static get css () {
    return {
      ...super.css,
      ...list.grippy
    }
  }

  selectedIcon (/* filter */) { return solidFilterIcon }
  unselectedIcon (/* filter */) { return filterIcon }

  filterHTML (filterModel) {
    const editing = ko.observable(filterModel.isNew())
    const isSelected = this.computed(() =>
      (this.currentConditions &&
      filterModel.isEquivalentTo(this.currentConditions())) || undefined
    )
    const { jss } = this

    const onFilterClick = evt => {
      evt.stopPropagation()
      evt.preventDefault()
      if (!isSelected()) {
        this.onSelect(filterModel)
      }
    }

    const onTitleChange = async () => {
      filterModel.isNew(false)
      await filterModel.vmSave()
    }

    const possiblySelectedfilterIcon = ko.pureComputed(() =>
      icons.inline(isSelected()
        ? this.selectedIcon(filterModel)
        : this.unselectedIcon(filterModel)))

    return (
      <div class={jss.listItem} selected={isSelected}
        ko-dblclick={() => editing(true)}>
        <div class={jss.grip} grip=''>
          {gripIcon}
        </div>
        <div class={jss.hoverable} ko-click={onFilterClick}>
          <div class={jss.itemIcon}>
            {possiblySelectedfilterIcon}
          </div>
          <div class={jss.title}>
            <inline-input
              value={filterModel.title}
              editing={editing}
              dblClickToEdit={1}
              inputClass={jss.titleEditInput}
              onChange={onTitleChange} />
          </div>
          <div class={jss.action}>
            {this.actionMenuHTML(filterModel, editing)}
          </div>
        </div>
      </div>
    )
  }

  actionItems (filterModel, editing) {
    const { jss } = this
    if (filterModel.immutable()) { return [] }
    return [
      <div class={jss.actionItem}
        ko-click={() => editing(true)}>
        Rename
      </div>,
      <async-button faceClass={jss.actionItem}
        action={() => this.deleteFilter(filterModel)}>
        <template slot='face'>
          <div class={jss.actionItemFace}>
            Delete
          </div>
        </template>
      </async-button>
    ]
  }

  actionMenuHTML (filterModel, editing) {
    const { jss } = this
    return (
      <drop-down my='left top' at='top right'>
        <template slot='anchor'>
          <div class={jss.actionButton}>
            {icons.inline(hellip)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.actionMenu}>
            {this.actionItems(filterModel, editing)}
          </div>
        </template>
      </drop-down>
    )
  }

  async deleteFilter (filterModel) {
    await this.onDelete(filterModel)
    // return filterModel.set({ deleted: true }, { merge: true })
    return filterModel.vmDelete()
  }

  get listItemsHTML () {
    return this.computed(() => this.list().map(f => this.filterHTML(f)))
  }

  get loadingIndicatorHTML () {
    return this.computed(() => this.colList.loaded()
      ? null
      : <small>&nbsp;Loading...</small>)
  }

  get template () {
    const { jss } = this
    const koGrip = { onItemMove: (...args) => this.onGripEnd(...args), noReset: true }
    return (
      <div class={jss.list} ko-grip-area={koGrip}>
        {this.everythingFilterHTML}
        {this.listItemsHTML}
        {this.loadingIndicatorHTML}
      </div>
    )
  }

  async onGripEnd ({ fromIndex, toIndex }) {
    if (fromIndex === -1 || toIndex === -1) { return }

    // Reduce by one to account for the static 'Every Entity' filter
    fromIndex = Math.max(0, fromIndex-1)
    toIndex = Math.max(0, toIndex-1)

    const sortedListCopy = [...this.sortedList()]
    if (fromIndex < toIndex) { toIndex-- }
    sortedListCopy
      .splice(toIndex, 0, sortedListCopy.splice(fromIndex, 1).pop())

    sortedListCopy.forEach((filterModel, i) => {
      filterModel.vmFirestoreDoc().update({ order: i })
    })

    this.colList.list(sortedListCopy)
  }
}

FiltersList.register()
