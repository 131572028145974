import { sortBy } from 'lodash-es'

import TransactionEditor from './TransactionEditor'

import { TransactionMassConversion } from 'capital/Transaction'
import { Asset } from 'capital/Asset'

import { color, buttons } from 'styles'
import icons from 'icons'

import removeIcon from 'icons/solid/times-circle'

type MassConversion = import('capital/Transaction/Transaction').MassConversion

export default class CapitalShareMassConversionEditor extends TransactionEditor<TransactionMassConversion> {
  assetChoices: KnockoutComputed<Asset[]>

  constructor (params) {
    super(params)
    this.assetChoices = this.computed<Asset[]>(() => this.computeAssetChoices())
      .extend({ deferred: true })
  }

  createTransaction ({ assetClassID }) {
    return new TransactionMassConversion({
      assetClassID,
      datetime: null,
      title: 'Conversion',
      conversions: [],
    })
  }

  /**
   * Compute the asset choices for conversion.
   *
   * NOTE: Exclude the source origin because it's zeroed after the
   * conversion anyway.
   */
  computeAssetChoices () : Asset[] {
    const datetime = this.clone.datetime()
    if (!datetime ) { return [] }
    const { authorized } = this.stateBefore()
    return sortBy(Object.values(authorized), 'assetName')
      .filter((a: Asset) => a.id !== this.clone.assetClassID)
  }

  get questionHTML () {
    const { jss } = this
    const args = this.clone
    const conversions = this.computed(() =>
      args.conversions().map(t => this.targetHTML(t))
    ).extend({ deferred: true })
    const assetName = this.computed(() =>
      this.stateBefore().getAsset(this.assetClassID).assetName
      || <i>Pick a date</i>)
    return (
      <div class={jss.block}>
        <div class={jss.conversionTitle}>Date</div>
        <div class={jss.date}>
          <date-picker-popover
            relatedDateGenerator={this.relatedDateGenerator}
            value={args.datetime}
            my='top left' at='bottom left' />
        </div>
        {this._horizRule()}
        <div class={jss.conversionTitle}>Convert Every</div>
        <div class={jss.firstShare}>
          <input type='text' class={jss.firstShare}
            ko-textInput={args.denominator} />
          {assetName}
        </div>
        <div class={jss.conversionTitle}>Convert To</div>
        {conversions}
        {this._horizRule()}
        {this.addTargetButtonHTML}
      </div>
    )
  }

  targetHTML (target: MassConversion) {
    const { jss } = this
    const { targetAssetClassID, numerator } = target
    const removeClick = (evt: MouseEvent) => {
      evt.stopPropagation()
      evt.preventDefault()
      this.clone.conversions.remove(target)
    }

    return (
      <>
        <div class={jss.numerator}>
          <input type='text' ko-textInput={numerator} />
          <select class={jss.targetClass}
            ko-value={targetAssetClassID}>
            {this.assetSelectHTML}
          </select>
        </div>

        <div class={jss.targetRemoveButton}
          ko-click={removeClick}
          ko-if={this.computed(() => this.clone.conversions.length > 1)}>
          {icons.inline(removeIcon)}
        </div>
      </>
    )
  }

  get assetSelectHTML () {
    return [
      <option value=''>Choose another asset</option>,
      ...this.assetChoices()
        .map(a => this.assetOptionHTML(a))
        .filter(h => h)
    ]
  }

  get addTargetButtonHTML () {
    const { jss } = this
    const addTargetClick = (evt: MouseEvent) => {
      evt.stopPropagation()
      evt.preventDefault()
      this.clone.conversions.push(this.clone.blankConversion())
    }

    return (
      <div class={jss.addTargetButton}
        ko-click={addTargetClick}>
        Add another class
      </div>
    )
  }

  static get targetCSS () {
    return {
      numerator: {
        gridColumn: '2/3',
        background: color.fill.light.tertiary,
        'body[dark] &': { // project batman
          background: color.fill.dark.tertiary,
        },
        padding: 3,
        borderRadius: 3,
        '& > input': {
          height: '30px',
          width: '50px',
          fontSize: '1em',
          textAlign: 'center',
          marginRight: 5,
          borderRadius: 4,
          background: color.textInput.light.primary,
          border: `1px solid ${color.separator.light.nonOpaque}`,
          'body[dark] &': { // project batman
            border: `1px solid ${color.separator.dark.nonOpaque}`,
            background: color.textInput.dark.primary,
            color: color.text.dark.primary
          },
        }
      },

      targetClass: {
        height: '29px',
        fontSize: '1rem',
      },
      addTargetButton: {
        cursor: 'pointer',
        padding: '7px 30px',
        userSelect: 'none',
        borderRadius: 20,
        gridColumn: '2/3',
        width: 'fit-content',
        justifySelf: 'end',
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
      },
      targetRemoveButton: {
        ...buttons.clickable,
        gridColumn: '-2/-1',
        alignSelf: 'center',
        '&:hover': {
          '--icon-color': color.icon.light.tertiary,
          'body[dark] &': { // project batman
            '--icon-color': color.icon.dark.tertiary,
          },
        }
      },
      conversionTitle: {
        gridColumn: '1/2',
        fontWeight: 'bold',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',
      }
    }
  }

  assetOptionHTML (asset: Asset) {
    return <option value={asset.id}>{asset.assetName}</option>
  }

  static get css () {
    return {
      ...super.css,
      ...this.targetCSS,
      layout: {
        ...super.css.layout,
        gridTemplateColumns: 'auto',
        minWidth: 450,
      },
      block: {
        display: 'grid',
        padding: 10,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        gridTemplateColumns: 'auto auto auto',
        gridGap: '10px',
        borderRadius: 5,
        gridTemplateAreas: `
        ' date date .'
        ' horizontalRule horizontalRule horizontalRule'
        ' title input remove'
        `
      },
      date: {

        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        '& > input': {
          height: '30px',
          width: '50px',
          boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
          fontSize: '1em',
          textAlign: 'center',
          outline: 'none',
          borderRadius: 6,
          border: `1px solid ${color.separator.light.nonOpaque}`,
          'body[dark] &': { // project batman
            border: `1px solid ${color.separator.dark.nonOpaque}`,
          },
        }
      },
      firstShare: {
        background: color.fill.light.tertiary,
        'body[dark] &': { // project batman
          background: color.fill.dark.tertiary,
        },
        padding: 3,
        borderRadius: 3,
        '& > input': {
          height: '30px',
          width: '50px',
          marginRight: 5,
          fontSize: '1em',
          textAlign: 'center',
          background: color.textInput.light.primary,
          borderRadius: 4,
          border: `1px solid ${color.separator.light.nonOpaque}`,
          'body[dark] &': { // project batman
            border: `1px solid ${color.separator.dark.nonOpaque}`,
            background: color.textInput.dark.primary,
            color: color.text.dark.primary
          },
        }
      }
    }
  }

  isValidTransaction (args) {
    return (
      args.denominator() &&
      args.conversions()
        .filter(t => t.numerator() && t.targetAssetClassID())
        .length
    )
  }
}

CapitalShareMassConversionEditor.register()
