
import UserLoginPanelProvider from './UserLoginPanelProvider'

/**
 * The `LoggedOutPanelProvider` shows the form for logging in,
 * with the message that the user has been logged out.
 */
export default class LoggedOutPanelProvider extends UserLoginPanelProvider {
  static get urlHash () { return 'loggedOut' }
  get panelWindowTitle () { return `Logged Out` }
  get historyUrl () { return null }

  get welcomeMessage () {
    return 'You have been logged out.  Log in again below.'
  }
  /**
   * The Logged Out panel provider doesn't attempt to do a login
   */
  consumeTokenOrShowSignIn () {
    this.formHTML(this.signInFormEmailInputHTML)
  }
}
