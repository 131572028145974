import { escapeRegExp, escape } from 'lodash-es'
import { PSPDFKitSearchResult } from 'pdf-engine/PDFEngine'

export default class BookSearchResult {
  query: string
  escapedQuery: string
  pageText: string
  // character offset in the search result string
  offset: number
  focused: KnockoutObservable<boolean>
  pdfkitresult: PSPDFKitSearchResult

  /**
   * @param {string} query
   * @param {BookPage} bookPage
   * @param {int} offset The offset within the page text content
   * @param {int} pageIndex
   * @param {Observable} focused (optional)
   */
  constructor(params) {
    const { pdfkitresult, query, bookPage, pageIndex, focused } = params
    Object.assign(this, {
      pdfkitresult,
      query,
      escapedQuery: escapeRegExp(query),
      bookPage,
      pageIndex,
      focused: focused || ko.observable(false),
    })
  }

  get resultContext () {
    const re = new RegExp(this.escapedQuery.replace(/ /g, ' +'), 'gi')
    return `... ${this.pdfkitresult.previewText.replace(re, m => `<mark>${escape(m)}</mark>`)} ...`
  }

  scrollIntoView () { this.bookPage.scrollIntoView() }
}
