import BodyBlock from './BodyBlock'

type Block = import('./Block').default
type ArrayOrderedByIndex<T> = import('utils/ArrayOrderedByIndex').default<T>

export default class NumberedParagraph extends BodyBlock {
  get code () { return 'np' }
  get isParagraph () { return true }
  markdownLeadingChars (blocks) { return `[${this.getNumber(blocks)}]` }
  getNumber (inBlocks: ArrayOrderedByIndex<Block>) {
    return inBlocks
      .filter(b => b instanceof NumberedParagraph)
      .indexOf(this) + 1
  }
}
