/**
  DataComponent
  ~~~~~~~~~~~~~
  The DataComponent is based on the `ComponentType` from Nassau.

  It represents a unit of data in a ContentView.

  DataComponent types are used both here to determine
  any processing for loading, access, saving, and any other uses.

  */
import { get } from 'lodash-es'

import { inSameRange } from 'utils/dates'
import t from 't'

import { Reminder } from './UnitView'

type DataModel = import('../DataModel').default

const REGISTRY = {}

//  The DataComponent is the base class for all other classes.
export default abstract class DataComponent {
  model: DataModel
  identifier: string
  title: string
  properties: any
  display: KnockoutComputed<boolean>

  /**
   *
   * @param {{properties: Object, key: string, peers: Object, model: DataModel, title: string}} param0
   */
  constructor ({ properties, identifier, questionnaire, title, model }) {
    Object.assign(this, {
      properties,
      identifier,
      questionnaire,
      model,
      title,
      // validation
    })

    this.display = ko.computed({
      read: this.computeDisplay,
      owner: this,
      deferEvaluation: true
    })

    this.init(properties)
  }

  /**
   * Post-construction initialization functions
   * @param {Object} properties
   */
  init (properties: any) { }

  get default () {
    if (this.properties.default) { return this.properties.default }
    const meta = ko.unwrap(get(this, 'model.meta'))
    return meta ? meta[this.identifier] : undefined
  }

  abstract get ()
  abstract set (value: any) : void
  abstract toJS () : any
  is_equal (other: any) : boolean
  getProjectionValue (params: any) : any { return this.toJS() }

  /**
   * Merge from another model; sometimes we do not want to copy everything
   * so we make this overloadable.  e.g. When copying Minute Books we
   * do not merge the file_keys.
   */
  merge (...args) { this.set(...args) }

  /**
   * isBlank is used by the ContentView to determine if something needs to
   * be saved.  It ought to be true when a component is the default value.
   * @return Boolean True when the component doesn't need to save.
   */
  isBlank () : boolean { return this.is_blank(true) }
  is_blank (ignoreWarning) {
    if (!ignoreWarning) {
      console.warn('is_blank is deprecated; prefer isBlank.')
    }
    return this.isBlank()
  }

  /**
   * has_value is used by the Interpretation to determine whether the
   * component provides a useful value for the Schema.
   * @return Boolean True when the component provides a useful value.
   */
  has_value () { return !this.is_blank() }

  /**
   * computeDisplay calcs whether to show the component.
   * By default always show the component. May be overloaded.
   */
  computeDisplay () {
    const { filter } = this.properties
    if (!filter)  { return true }
    // console.log(`qq`, this.questionnaire)
    const [subject, op, value] = filter.split(/\s+/)
    // console.debug(`Splitting filter ${filter}`, {subject, op, value})
    const lookupValue = this.questionnaire.qLookup(subject)
    const subjectValue = ko.unwrap(lookupValue)

    switch (op) {
      case '=':
      return String(subjectValue).toLowerCase() === value.toLowerCase()
      case '==':
        return subjectValue === value
      default:
        console.error(`Bad filter operation: ${filter}: ${op}`)
    }
  }

  /**
   *  This is passed to ContentView's componentToPrintJS.
   *  It can be overloaded where the values passed to printing are not
   *  the same as those for `toJS` (e.g. if there is some compilation step).
   *  The return value can be a Promise.
   */
  toPackagePrintJS () { return this.toJS() }

  /**
   * Used when performing lookups of ContentView values in Interpretation instances.
   * May be proxied / remapped.
   */
  schemaAPI () { return this.get() }

  /**
   * Much like to PackagePrintJS, a Component may return something different
   * from what is saved, for the purpose of publication (to e.g. a virtual
   * minute book)
   */
  toPublishableJS () { return this.toJS() }

  /**
   * Convert the given value to what is used to index searches, esp. for
   * QuestionView::vm_save.
   */
  get asIndexValue () { return this.toJS() }

  /**
   * Return or yield the text so it's searchable
   */
  toFullText () {}

  // skip - called when a component is defined in the KoD.contains, but
  // but its value was not given to the ContentView::update_component_values
  skip (/* all_content_values */) {}

  /**
   * This is a hook triggers when the containing ModelView saves.
   */
  async beforeModelSave (/* model, param */) {}
  async afterModelSave (/* model, param */) {}

  /**
   *
   * @return {[{name, date}]} Dates to be added to the `event` list
   *
   * Where the date is specified by `date` of one of the following forms
   *    - ymd is an int in the form YYYYMMDD,
   *    - monthday is an int in the form MDD
   *    - unix is the unix timestamp (must be after 1970)
   *
   * E.g. [
   *   { reason: 'Fiscal year end',   date: 711 },
   *   { reason: 'Trade name expiry', date: 20190505 },
   *   { reason: 'Meeting closes',    date: 1532602141 }
   * ]
   *
   * May return undefined, a single item, or an array.
   */
  reminderDates (given: Date, range?: string) : Reminder[] | IterableIterator<Reminder> { return [] }
  static get hasReminderDates () { return false }

  /**
   * Yield the possible signatories for this component.
   */
  * signatoryOrigins (): IterableIterator<SignaturesOrigin> {}

  /**
   * @param {Date} given date to match
   * @param {string} range is the search specificity
   * @return {Array}
   */
  * genRemindersAtDate (given, range = 'day') : IterableIterator<EventRecord> {
    const { model } = this
    const component = this
    const reminders = this.reminderDates(given, range) || []
    for (const { reason, date, identifier } of reminders) {
      if (inSameRange(date, given, range)) {
        yield { reason, identifier, component, model, date }
      }
    }
  }

  * getPersons (filter: PersonFilter) : PersonRecord[] | IterableIterator<PersonRecord> {}

  /**
   * Return a license that indicates whether printing is permitted.
   * For example { corp: true, stripe_charge_id } will be added
   * as the `license` parameter to Edition:tunnel, indicating that
   * we should remove the DRAFT watermark.
   *
   * See e.g. corp.WatermarkComponent
   */
  getLicense () {}

  /**
   * Specify the template for previewing merge/values.
   *
   * If falsy is returned, the merge process shall indicate that the field
   * is not mergeable.
   * @return {string} template-id
   */
  get mergeTemplateId () {
    if (this.properties.unmergeable) { return '' }
    return this.properties.mergeTemplate ||
      this.properties.preview_template ||
      'preview__default'
  }

  /**
   *           🏭     🏭     🏭
   *
   *    Global DataComponent Registry
   *
   */
  i18nValueFor (p: string): LANGUAGE_VALUE { return this.constructor.i18nValueFor(p) }
  i18nStringFor (p) { return ko.unwrap(this.i18nValueFor(p)) }
  static i18nStringFor (p: string) { return ko.unwrap(this.i18nValueFor(p)) }
  static i18nValueFor (property: string): LANGUAGE_VALUE {
    const key = t.canonicalKey('DC', this.namespace, this.canonicalName, property)
    return t.getValue(key)
  }

  static get namespace () { return 'not-defined' }
  static get componentName () { return this.name }
  static get typeName () { return `${this.namespace}.${this.canonicalName}` }
  static get registry (): Record<string, typeof DataComponent> { return REGISTRY }
  static get canonicalName () {
    return this.componentName
      .toLowerCase()
      .replace(/(data)?component/, '')
  }

  static unregister (component) {
    delete DataComponent.registry[component.typeName]
  }

  static register (component = this) {
    const { typeName } = component
    if (typeName in DataComponent.registry) {
      console.error(`Component '${typeName}' already registered`,
        component)
      throw new Error('Component::factory - already registered')
    }

    DataComponent.registry[typeName] = component
  }

  static getComponentById (name): typeof DataComponent {
    const id = ko.unwrap(name).toLowerCase()
    const Component = DataComponent.registry[id]
    if (!Component) {
      console.log(`
        🚨  Cannot find component ${name}.
        Registered components:
        ${Object.keys(DataComponent.registry).join(' ')}
      `)
    }
    return Component
  }
}

