
import BodyBlockEditor from './BodyBlockEditor'
import { SubHeading } from '../blocks/Heading'

export default class BlockSubheadingEditor extends BodyBlockEditor<SubHeading> {
  static get css () {
    return {
      ...super.css,
      input: {
        ...super.css.input,
        fontSize: '1.25em',
        fontWeight: 'bold',
        marginBottom: '0.25em',
        border: 'unset'
      }
    }
  }
}

BlockSubheadingEditor.register()
