
import Command from 'Command'
import ModelCommandSet from 'Command/ModelCommandSet'

import { cycleNext, cyclePrev } from 'utils/array'

import WritingModel from 'writing/WritingModel'
import WritingPanelProvider from 'writing/WritingPanelProvider'

import { RightViewTabGroup } from  './right-view'
import MinuteBookRightViewBookmarks from './right-view/minute-book-right-view-bookmarks'
import MinuteBookRightViewNotes from './right-view/minute-book-right-view-notes'
import { MinuteBookRightViewResponsible } from './right-view/minute-book-right-view-responsible'
import { MinuteBookRightViewTags } from './right-view/minute-book-right-view-tags'


type MinuteBookPanelProvider = import('./MinuteBookPanelProvider').default
const DEFAULT_VIEW_ID = 'BookView'

/**
 * Trigger the action only if the `activeViewID` matches the views
 * this command is for.
 */
abstract class ActiveViewCommand extends Command {
  async trigger (evt: Event) {
    const activeViewID = this.activeViewID() || DEFAULT_VIEW_ID
    if (this.forViewID.includes(activeViewID)) {
      super.trigger(evt)
    }
  }

  abstract get forViewID ()
  abstract get activeViewID ()
}

export default class MinuteBookCommandSet extends ModelCommandSet {
  /**
   * @param {Array.<string>} viewIDs
   * @param {object} params arguments passed up to the `Command`
   */
  commandForView (viewIDs, params) {
    const { activeViewID } = this.panelProvider
    return new class extends ActiveViewCommand {
      get activeViewID () { return activeViewID }
      get forViewID () { return viewIDs }
    }(params)
  }

  /**
   * @return `Command` instance specific to the given viewIDs
   */
  eisCommand (params) { return this.commandForView(['EntityInfoView', 'SplitView'], params) }
  bookCommand (params) { return this.commandForView(['BookView', 'SplitView'], params) }
  thumbnailCommand (params) {
    return this.commandForView(['BookView', 'SplitView'], {
      ...params,
      filter: () => this.panelProvider.showThumbnailView()
        && (!params.filter || params.filter()),
    })
  }

  pdfCommand (params) { return this.commandForView(['ThumbnailView', 'BookView'], params) }
  docListCommand (params) { return this.commandForView(['EntityDocumentsView'], params) }

  get rightViews () {
    if (this._rightViews) { return this._rightViews }
    const mbpp = this.panelProvider
    return (this._rightViews = this.makeRightViews(mbpp))
  }

  makeRightViews (mbpp) {
    const { model } = mbpp
    const nbGroup = new RightViewTabGroup(mbpp.rightView)
    const trGroup = new RightViewTabGroup(mbpp.rightView)

    const bookmarks = new MinuteBookRightViewBookmarks({
      tabGroup: nbGroup,
      bookComponent: model.answerFor('book'),
      pdfEngine: mbpp.pdfEngine,
    })

    const notes = new MinuteBookRightViewNotes({
      tabGroup: nbGroup,
      bookComponent: model.answerFor('book'),
      listOfNotes: mbpp.listOfNotes,
    })

    const responsible = new MinuteBookRightViewResponsible({
      tabGroup: trGroup,
      personGenerator: mbpp.model,
      list: model.answerFor('responsible'),
    })

    const tags = new MinuteBookRightViewTags({
      tabGroup: trGroup,
      list: model.tags,
    })

    return {
      responsible: responsible.template,
      tags: tags.template,
      bookmarks: bookmarks.template,
      notes: notes.template,
      sharing: <minute-book-right-view-sharing commandSet={this}
        model={model}
        panelProvider={mbpp}
        bookComponent={model.answerFor('book')} />,
      search: <minute-book-right-view-search commandSet={this}
        searchData={mbpp.searchData} />,
    }
  }

  makeCommands () {
    const mbpp = this.panelProvider
    const newSection = new Command({
      title: 'Create New Section',
      helpGroup: 'Minute Book Editing',
      help: <span>Press <kbd>S</kbd> when viewing a minute book PDF to add a <strong>section tab</strong> to the current page you are viewing. You can then immediately type in the section name and press the <kbd>Return</kbd> key to accept the changes.</span>,
      hideIf: mbpp.model.isProjection,
      action: () => mbpp.bookComponent
        .addOrUpdateSection(mbpp.currentPage(), 'New section')
    })

    const deleteSelectedPages = this.thumbnailCommand({
      title: 'Delete Selected Pages',
      helpGroup: 'Minute Book Editing',
      help: <span>Press the <kbd>Delete</kbd> key when you are in <strong>Thumbnail View</strong> and in editing mode (by pressing the <strong>Edit</strong> button in the toolbar)to delete any pages that are currently selected. Note that deleting pages cannot be undone.</span>,
      hideIf: mbpp.model.isProjection,
      action: () => mbpp.deleteSelectedPages()
    })

    const undoCommand = new Command({
      title: ' Undo',
      helpGroup: 'Viewing Shortcuts',
      help: (
        <span> Undo actions that were done in the <strong> Entity Information Summary</strong>. </span>
      ),
      hideIf: mbpp.model.isProjection,
      action: () => mbpp.undoManager().undo(),
    })
    const redoCommand = new Command({
      title: ' Redo',
      helpGroup: 'Viewing Shortcuts',
      help: (
        <span> Redo an action that was previously undone in the <strong> Entity Information Summary</strong>. </span>
      ),
      hideIf: mbpp.model.isProjection,
      action: () => mbpp.undoManager().redo(),
    })

    return {
      ...super.makeCommands(),
      newSection,
      deleteSelectedPages,
      undoCommand,
      redoCommand,
      ...this.makeMainViewCommands(mbpp),
      ...this.makeRightViewCommands(mbpp),
      ...this.makeExtraBookCommands(mbpp),
      ...this.makeEISCommands(mbpp),
      ...this.makeDocListCommands(mbpp),
    }
  }

  get keyboardShortcuts () {
    const c = this.commands
    const { META, CTRL, SHIFT } = this
    return [
      ...super.keyboardShortcuts,

      ...c.newSection.keyboardShortcuts('s'),

      ...c.undoCommand.keyboardShortcuts('undo'),
      ...c.undoCommand.keyboardShortcuts('Z', CTRL),
      ...c.undoCommand.keyboardShortcuts('Z', META),

      ...c.redoCommand.keyboardShortcuts('redo'),
      ...c.redoCommand.keyboardShortcuts('Y', META),
      ...c.redoCommand.keyboardShortcuts('Y', CTRL),
      ...c.redoCommand.keyboardShortcuts('Z', { ...CTRL, ...SHIFT }),
      ...c.redoCommand.keyboardShortcuts('Z', { ...META, ...SHIFT }),

      ...c.deleteSelectedPages.keyboardShortcuts('delete'),
      ...c.deleteSelectedPages.keyboardShortcuts('backspace'),

      ...this.rightViewKeyboardShortcuts,
      ...this.mainViewKeyboardShortcuts,
      ...this.extraBookKeyboardShortcuts,
      ...this.makeEISKeyboardShortcuts,
    ]
  }

  /**
   * @param {string} permit
   */
  userCanAccessContent (permit: PERMISSION_AUTHORITY) {
    const { panelProvider } = this
    const { model, app } = panelProvider
    const { currentUserEmail } = app

    const p = app.defaultAuthManager.userPermit(model, currentUserEmail, permit)
    if (typeof p === 'boolean') { return p }
    return p.params[permit]
  }

  makeDocListCommands (mbpp: MinuteBookPanelProvider) {
    const { app } = window
    const { activeViewID } = mbpp
    return {
      newWriting: this.docListCommand({
        activeViewID,
        title: 'New Document',
        help: 'Create a new document',
        action: async () => {
          const model = new WritingModel(true, mbpp.model.authManager)
          model.parentKey(['entity', mbpp.model.id()])
          app.setPanelProvider(WritingPanelProvider, { app, model })
        },
      }),
    }
  }

  makeEISCommands (mbpp) {
    const { eisNavigation, activeViewID } = mbpp
    const cf = () => eisNavigation.currentFocus()
    const isEditing = () => cf() && cf().editing()
    return {
      nextInput: this.eisCommand({
        activeViewID,
        title: 'Next Field',
        helpGroup: 'Entity Information Navigation',
        help: 'Move focus to the next input field',
        helpIndex: 0,
        action: () => eisNavigation.nextFocus(),
      }),
      previousInput: this.eisCommand({
        activeViewID,
        title: 'Previous Field',
        helpGroup: 'Entity Information Navigation',
        help: 'Move focus to the previous input field',
        helpIndex: 1,
        action: () => eisNavigation.previousFocus(),
      }),
      activateEdit: this.eisCommand({
        activeViewID,
        title: 'Open field editor',
        helpGroup: 'Entity Information Navigation',
        help: (<span>While a field is focused press <kbd>Enter</kbd> to open the change area to edit the value.</span>),
        helpIndex: 2,
        filter: () => cf() && !isEditing(),
        action: () => cf().editing(true),
      }),

      // For EIS components without actual input elements we need to handle save and discard as well.
      // FIXME: Need to trigger save() and discard() handlers. Let's add those after we
      //   actually have components that need them.
      discardEdit: this.eisCommand({
        activeViewID,
        title: 'Discard changes',
        helpGroup: 'Entity Information Navigation',
        help: (<span>While editing a field press <kbd>Escape</kbd> to discard the changes and close the editor.</span>),
        helpIndex: 5,
        filter: () => isEditing(),
        action: () => cf().triggerDiscard(),
      }),
      saveEdit: this.eisCommand({
        activeViewID,
        title: 'Save changes',
        helpGroup: 'Entity Information Navigation',
        help: (<span>While editing a field press <kbd>Enter</kbd> to save the changes and close the editor.</span>),
        helpIndex: 4,
        filter: () => isEditing(),
        action: () => cf().triggerSave(),
      }),

      activateHoveredField: this.eisCommand({
        activeViewID,
        title: 'Open field editor',
        helpGroup: 'Entity Information Navigation',
        help: (<span>While hovering the mouse over a field press <kbd>E</kbd> to open the change area to edit the value.</span>),
        helpIndex: 3,
        action: () => eisNavigation.eis.activateHoveredField(),
      }),

    }
  }

  get makeEISKeyboardShortcuts () {
    const c = this.commands
    const { SHIFT } = this
    return [
      ...c.nextInput.keyboardShortcuts('tab'),
      ...c.previousInput.keyboardShortcuts('tab', SHIFT),
      ...c.discardEdit.keyboardShortcuts('escape'),
      ...c.saveEdit.keyboardShortcuts('enter'),
      ...c.activateEdit.keyboardShortcuts('enter'),
      ...c.activateHoveredField.keyboardShortcuts('e'),
    ]
  }

  /**
   *      Main View
   */
  makeMainViewCommands (mbpp) {
    const { activeViewID, activeView, showContextView, showToC } = mbpp
    const isDemo = global.app.defaultAuthManager.accountID() === 'demo'

    return {
      navEis: new Command({
        title: 'Go to Entity Information Summary',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the <strong>Entity Information Summary</strong> (EIS) by pressing <kbd>1</kbd>. You can repeatedly press <kbd>1</kbd> to cycle through sections in the EIS
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('eis'),
        action: this.setValueOrCall(activeViewID, 'EntityInfoView',
          evt => evt.shiftKey
            ? mbpp.jumpToPrevEisCategory()
            : mbpp.jumpToNextEisCategory())
      }),

      navBook: new Command({
        title: 'Go to Minute Book',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the <strong>Minute Book</strong> view by pressing <kbd>2</kbd>. You can repeatedly press <kbd>2</kbd> to cycle through sections in the minute book.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('book'),
        action: this.setValueOrCall(activeViewID, 'BookView',
          evt => evt.shiftKey
            ? mbpp.jumpToPrevSection()
            : mbpp.jumpToNextSection())
      }),

      navThumbnails: new Command({
        title: 'Go to Thumbnails',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the <strong>Thumbnail View</strong> view by pressing <kbd>3</kbd>.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('book'),
        action: this.setValueOrCall(activeViewID, 'ThumbnailView',
          evt => evt.shiftKey
            ? mbpp.jumpToPrevSection()
            : mbpp.jumpToNextSection())
      }),

      navAudit: new Command({
        title: 'Go to Audit Trail',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the <strong>Audit Trail</strong> view by pressing <kbd>3</kbd>.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('audit'),
        action: () => activeViewID('AuditView')
      }),

      navDocuments: new Command({
        title: 'Go to Documents',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the <strong>Documents</strong> list by pressing <kbd>3</kbd>.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('documents'),
        action: () => activeViewID('EntityDocumentsView'),
      }),

      navOrgChart: new Command({
        title: 'Go to Organizational Chart',
        hideIf: !isDemo,
        action: () => activeViewID('OrganizationalChartView')
      }),

      navCapTable: new Command({
        title: 'Go to Capitalization Table',
        hideIf: !isDemo,
        action: () => activeViewID('CapTableView')
      }),

      toggleContextView: new Command({
        title: 'Show/Hide Context View',
        helpGroup: 'Viewing Shortcuts',
        hideIf: () => !this.userCanAccessContent('book'),
        action: () => showContextView.modify(v => !v)
      }),

      toggleToC: new Command({
        title: 'Show/Hide Table of Contents',
        helpGroup: 'Viewing Shortcuts',
        hideIf: () => !this.userCanAccessContent('book'),
        action: () => showToC.modify(v => !v)
      }),

      toggleEditing: this.thumbnailCommand({
        title: 'Edit thumbnails',
        helpGroup: 'Minute Book Editing',
        help: (
          <span>
            Quickly enter <strong>Thumbnail Editing Mode</strong> when in <strong>Thumbnail View</strong> by pressing<kbd>e</kbd>.
          </span>
        ),
        hideIf: mbpp.model.isProjection,
        action: () => mbpp.editing.modify(e => !e)
      }),

      zoomIn: this.pdfCommand({
        title: 'Zoom In',
        helpGroup: 'Viewing Shortcuts',
        help: (
          <span>
            Zoom in when in <strong>Minute Book View</strong> and <strong>Thumbnail View</strong>.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('book'),
        filter: () => 'pdfSettings' in activeView(),
        action: () => activeView().pdfSettings.zoom.modify(z => Math.min(2, z + 0.1))
      }),

      zoomOut: this.pdfCommand({
        title: 'Zoom Out',
        helpGroup: 'Viewing Shortcuts',
        help: (
          <span>
            Zoom out when in <strong>Minute Book View</strong> and <strong>Thumbnail View</strong>.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('book'),
        filter: () => 'pdfSettings' in activeView(),
        action: () => activeView().pdfSettings.zoom.modify(z => Math.max(0.1, z - 0.1))
      })

    }
  }

  get mainViewKeyboardShortcuts () {
    const {
      navEis, navBook, navAudit, toggleContextView, toggleToC, zoomIn, zoomOut, toggleEditing
    } = this.commands
    const { SHIFT } = this

    return [
      ...navEis.keyboardShortcuts('1'),
      ...navEis.keyboardShortcuts('!', SHIFT),
      ...navBook.keyboardShortcuts('2'),
      ...navBook.keyboardShortcuts('@', SHIFT),
      ...navAudit.keyboardShortcuts('3'),
      ...toggleContextView.keyboardShortcuts('c'),
      ...toggleToC.keyboardShortcuts('z'),
      ...toggleEditing.keyboardShortcuts('e'),
      ...zoomIn.keyboardShortcuts('='),
      ...zoomOut.keyboardShortcuts('-'),
    ]
  }

  /**
   *        Right View
   */
  makeRightViewCommands (mbpp) {
    const { rightViews } = this
    const { rightView, bookComponent } = mbpp
    const closeRightView = () => rightView(undefined)
    const toggleRightView = view =>
      rightView.modify(v => v === view ? undefined : view)

    return {
      closeRight: new Command({
        title: 'Hide Right Panel',
        helpGroup: 'Viewing Shortcuts',
        help: (
          <span>
            Quickly hide any <strong>Right Panel</strong> by pressing <kbd>`</kbd>. Note that the <kbd>`</kbd> key will not close the right panel if you are typing in an input field, e.g. if you are in Search.
          </span>
        ),
        action: closeRightView
      }),

      triggerBookmarks: new Command({
        title: 'Open Bookmarks Panel',
        helpGroup: 'Toolbar Shortcuts',
        help: (
          <span>
            Quickly open the <strong>Bookmarks Panel</strong> by pressing <kbd>B</kbd>. <strong>Tip:</strong> Press the <kbd>B</kbd> key while viewing any page in the <strong>Minute Book View</strong> to also quickly add or remove a bookmark on that page.
          </span>
        ),
        hideIf: () => !this.userCanAccessContent('bookmarks'),
        action: this.setValueOrCall(rightView, rightViews.bookmarks,
          this.triageByEvent({
            click: closeRightView,
            keydown: () => bookComponent.toggleBookmark(mbpp.bookPageAt())
          })
        )
      }),

      triggerNotes: new Command({
        title: 'Open Notes Panel',
        helpGroup: 'Toolbar Shortcuts',
        help: (
          <span>
            Quickly open the <strong>Notes Panel</strong> by pressing <kbd>N</kbd>. <strong>Tip:</strong> Press the <kbd>N</kbd> key while viewing any page in the <strong>Minute Book View</strong> to also quickly add a note on that page.
          </span>
        ),
        hideIf: mbpp.model.isProjection,
        action: this.setValueOrCall(rightView, rightViews.notes,
          this.triageByEvent({
            click: closeRightView,
            keydown: () => mbpp.addNoteClick(mbpp.bookPageAt())
          })
        )
      }),

      triggerResponsible: new Command({
        title: 'Open Responsible Panel',
        helpGroup: 'Toolbar Shortcuts',
        help: (
          <span>
            Quickly open the <strong>Responsible Panel</strong> by pressing <kbd>r</kbd>. You can hide the <strong>Responsible Panel</strong> by pressing <kbd>`</kbd>.
          </span>
        ),
        hideIf: mbpp.model.isProjection,
        action: () => toggleRightView(rightViews.responsible)
      }),

      triggerSearch: this.bookCommand({
        title: 'Open Search Panel',
        helpGroup: 'Search Shortcuts',
        help: (
          <span>
            Open the search panel to start searching a minute book. Note that your minute book PDFs must be OCR'ed in order to be searchable.
          </span>
        ),
        action: () => toggleRightView(rightViews.search)
      }),

      nextResult: this.bookCommand({
        title: 'Next search result',
        helpGroup: 'Search Shortcuts',
        help: (
          <span>
            Jump to the next search result.
          </span>
        ),
        filter: () => mbpp.rightView() === this._rightViews.search,
        action: () => mbpp.searchData.nextResult()
      }),

      previousResult: this.bookCommand({
        title: 'Previous search result',
        helpGroup: 'Search Shortcuts',
        help: (
          <span>
            Jump to the previous search result.
          </span>
        ),
        filter: () => mbpp.rightView() === this._rightViews.search,
        action: () => mbpp.searchData.previousResult()
      }),

      triggerSharing: new Command({
        title: 'Open Sharing Panel',
        helpGroup: 'Toolbar Shortcuts',
        help: (
          <span>
            Quickly open the <strong>Sharing Panel</strong> by pressing <kbd>v</kbd>. <strong>Tip:</strong> Press the <kbd>v</kbd> key while the <strong>Sharing Panel</strong> is open to  quickly begin adding a share.
          </span>
        ),
        hideIf: mbpp.model.isProjection,
        action: this.setValueOrCall(rightView, rightViews.sharing,
          this.triageByEvent({
            click: closeRightView,
            keydown: () => mbpp.addShare()
          })
        )
      }),

      triggerTags: new Command({
        title: 'Open Tags Panel',
        helpGroup: 'Toolbar Shortcuts',
        help: (
          <span>
            Quickly open the <strong>Tags Panel</strong> by pressing <kbd>t</kbd>. You can hide the <strong>Tags Panel</strong> by pressing <kbd>`</kbd>.
          </span>
        ),
        hideIf: mbpp.model.isProjection,
        action: () => toggleRightView(rightViews.tags)
      })
    }
  }

  get rightViewKeyboardShortcuts () {
    const c = this.commands
    const { META, CTRL, SHIFT } = this

    return [
      ...c.closeRight.keyboardShortcuts('`'),
      ...c.triggerBookmarks.keyboardShortcuts('b'),
      ...c.triggerNotes.keyboardShortcuts('n'),
      ...c.triggerResponsible.keyboardShortcuts('r'),
      ...c.triggerSharing.keyboardShortcuts('v'),
      ...c.triggerTags.keyboardShortcuts('t'),

      // Search
      ...c.triggerSearch.keyboardShortcuts('/'),
      ...c.triggerSearch.keyboardShortcuts('f', META),
      ...c.triggerSearch.keyboardShortcuts('f', CTRL),

      ...c.nextResult.keyboardShortcuts('ArrowRight'),
      ...c.nextResult.keyboardShortcuts('g', META),
      ...c.nextResult.keyboardShortcuts('g', CTRL),

      ...c.previousResult.keyboardShortcuts('ArrowLeft'),
      ...c.previousResult.keyboardShortcuts('g', {...META, ...SHIFT}),
      ...c.previousResult.keyboardShortcuts('g', {...CTRL, ...SHIFT}),
    ]
  }

  /**
   *    Extra-book navigation
   *    e.g. navigate to next / prev book.
   */
  makeExtraBookCommands (mbpp) {
    const { entityList } = mbpp.app.rootProviders
    const entitiesInList = entityList.criteria.hits

    return {
      nextBook: new Command({
        title: 'Next Minute Book',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the next minute book.
          </span>
        ),
        action: () => entityList.selectEntity(cycleNext(entitiesInList(), mbpp.model))
      }),
      prevBook: new Command({
        title: 'Previous Minute Book',
        helpGroup: 'Navigation',
        help: (
          <span>
            Quickly navigate to the previous minute book.
          </span>
        ),
        action: () => entityList.selectEntity(cyclePrev(entitiesInList(), mbpp.model))
      })
    }
  }

  get extraBookKeyboardShortcuts () {
    const { SHIFT } = this
    const { prevBook, nextBook } = this.commands
    return [
      ...prevBook.keyboardShortcuts('<', SHIFT),
      ...nextBook.keyboardShortcuts('>', SHIFT)
    ]
  }
}
