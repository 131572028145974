
import PdfRenderer from './PdfRenderer'

import { color, buttons, typography, dropdown } from 'styles'
import OutcomeNotification from 'notification-manager/outcome-notification'

import icons from 'icons'
import trashIcon from 'icons/solid/trash'
import burgerIcon from 'icons/solid/ellipsis-v'

/**
 * Base class for <pdf-thumbnails> and <pdf-pages>
 */
export default class PdfViewer extends PdfRenderer {
  constructor (...args) {
    super(...args)

    Object.assign(this, {
      display: ko.observable('width'),
      canvasRatio: ko.observable(0),
      canvasWidth: ko.observable(''),
      colCount: ko.observable(0),
      heightOffsetPx: 0
    })

    this.triggerRender = ko.observable('')
      .extend({ rateLimit: { timeout: 100, method: 'notifyWhenChangesStop' } })
      .extend({ notify: 'always' })

    this.immediateTrigger = ko.observable().extend({ notify: 'always' })
    this.computed(() => this.triggerRender(this.immediateTrigger()))
  }

  get mainViewElement () {
    const { jss } = this
    return document.querySelector('.' + jss.pages)
  }

  get mainPageElement () {
    const { jss } = this
    return document.querySelector('.' + jss.mainPage)
  }

  static get iconCSS () {
    return {
      ...super.iconCSS,
      _marker: {
        ...super.iconCSS._marker,
        fontSize: '1.4rem',
        transform: 'scale3d(1, 0, 1)',
        transition: '0.100s ease-in',
        transformOrigin: '0px 6px',
        visibility: 'visible',
        '&[exists]': {
          transform: 'scale3d(1, 1, 1)',
          '--icon-color': 'var(--active-color)',
          'body[dark] &': { // project batman
            '--icon-color': 'black',
          },
        },
        '$mainPage:hover &': {
          transform: 'scale3d(1, 1, 1)',
        },
        '$mainPage &:hover': {
          transform: 'scale3d(1.05, 1.05, 1.05)',
          filter: `drop-shadow(0px 0px 3px rgba(0,0,0,0.1))`,
        },
      },
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.pageMenuCSS,
      ...this.notificationCSS,
      truck: {
        display: 'grid',
        grid: {
          gap: '15px',
          templateColumns: '1fr auto',
          templateRows: 'auto',
          templateAreas:
                  `'main context '`
        },
        paddingTop: '10px',
        '--height-offset': `${this.heightOffsetPx}px`
      },
      canvasContainer: {
        ...super.css.canvasContainer,
        position: 'relative'
      },
      canvas: {},
      pages: {
        gridArea: 'main',
        minHeight: 'calc(100vh - var(--height-offset))'
      },
      hideBkMrk: {
        opacity: '0',
        '&:hover': {
          opacity: '0.5'
        }
      },

      mainPage: {
        position: 'relative',
        verticalAlign: 'bottom',
        margin: '5px'
      },

      noPages: {
        display: 'grid',
        alignItems: 'center',
        gridColumn: '1/3',
        justifyContent: 'center',
        margin: '40px auto 0 auto',
        width: '750px'
      },

      noPagesHeading: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '40px',
        color: color.grey.a,
        fontFamily: typography.altFontFamily,
        fontSize: '2em',
        textAlign: 'center'
      },

      noPagesSubHeading: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        color: color.grey.c,
        fontFamily: typography.bodyFontFamily,
        marginBottom: 30,
        fontSize: '1em',
        textAlign: 'center'
      },

      noPagesImage: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 'auto',
        border: `12px solid black`,
        borderRadius: 10
      },
    }
  }


  deleteHTML (bookPage) {
    const { jss } = this
    const addShare = () => {
      if (!this.panelProvider.showThumbnailView()) {
        this.bookPages().forEach(bp => bp.selected(bp === bookPage))
      }
      this.panelProvider.addShare()
    }
    return (
      <drop-down my='top left' at='bottom left'
        class={jss.pageMenuDropdown}>
        <template slot='anchor'>
          <div class={jss.pageMenuButton}>
            {icons.inline(burgerIcon)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.pageMenuList}>
            <div class={jss.pageMenuItem}
              ko-click={() => this.panelProvider.downloadPages([bookPage])}>
              Download
            </div>
            <div class={jss.pageMenuItem}
              ko-click={addShare}>
              Share...
            </div>
            <div class={jss.pageMenuItem}
              ko-click={() => this.deleteClick(bookPage)}>
              Delete...
            </div>
          </div>
        </template>
      </drop-down>
    )
  }

  static get notificationCSS () {
    return {
      noticeOutcome: {
        ...OutcomeNotification.css.outcome
      },
      noticeMessage: {
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      noticeIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      }
    }
  }

  get deletePageNotification () {
    const { jss } = this
    return [`Success: The page has been deleted.`, {
      icon: trashIcon,
      style: {
        outcome: jss.noticeOutcome,
        message: jss.noticeMessage,
        icon: jss.noticeIcon
      }
    }]
  }

  deleteClick (bookPage) {
    const { app } = global
    const deletePage = async () => {
      this.book.deletePage(bookPage)
      await this.panelProvider.model.vmSave()
      app.notifier.pushOutcome(...this.deletePageNotification)
    }
    app.modal(
      <modal-dialog modalTitle={`Delete Page`}>
        <template slot='content'>
          <confirm-page-delete
            onConfirm={deletePage}
            onFinish={() => app.modal(undefined)} />
        </template>
      </modal-dialog>
    )
  }

  static get pageMenuCSS () {
    return {
      pageMenuDropdown: {
        extend: '_marker',
        left: 10
      },
      pageMenuButton: {
        ...buttons.clickable
      },
      pageMenuList: {
        ...dropdown.generalMenu
      },
      pageMenuItem: {
        ...dropdown.item
      }
    }
  }


  get noPagesHTML () {
    const { jss } = this
    const modelTitle = this.panelProvider.model.cvModelTitle
    const titleDiv = modelTitle
      ? <div class={jss.noPagesHeading}>The minute book for <b>{modelTitle}</b> is currently empty.</div>
      : <div class={jss.noPagesHeading}>The minute book is empty</div>

    return (
      <div class={jss.noPages}>
        {titleDiv}
        <div class={jss.noPagesSubHeading}>You can add documents to your minute book by clicking the insert button above and then choosing “PDF…” <strong>or</strong> by simply dragging and dropping a file here from your desktop.</div>
        <img class={jss.noPagesImage} src='https://www.minutebox.com/assets/gifs/mbGif-insertPDF.gif' />

      </div>

    )
  }

  get mainViewJSX () {
    const { jss } = this
    return (
      <div class={jss.mainview}>{this.pagesHTML()}</div>
    )
  }

  sectionClick (bookPage) { this.addSection(bookPage) }
  noteClick (bookPage) { this.addNoteClick(bookPage) }
  bookmarkClick (bookPage) { this.toggleBookmark(bookPage) }
}
