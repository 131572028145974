import { color } from 'styles'
import ViewComponent from 'ViewComponent'
import { computed } from 'utils/decorator'

import { UX_STATE } from '../variables/slots/interfaces'

type Slot = import('../variables/slots').Slot

/**
 * <slot-indicator slot={SLOT} state={STATE} value={VALUE} />
 */
export class SlotIndicator extends ViewComponent {
  slot: Slot | KnockoutObservable<Slot>
  state: string | KnockoutObservable<string>
  onClick: (evt: MouseEvent) => void

  constructor ({ slot, state, onClick }) {
    super()
    Object.assign(this, { slot, state, onClick })
  }

  static get css () {
    return {
      ...super.css,
      ...this.uxCSS,
      ...this.slotCSS,

      _text: {
        display: 'inline',
        padding: '0px 10px',
      },

      value: {
        extend: '_text',
      },

      incohate: {
        extend: '_text',
        fontStyle: 'italic',
      }
    }
  }

  static get uxCSS () {
    return {
      _ux: {
        display: 'var(--slot-indicator-display, inline-flex)',
        alignItems: 'center',
        // padding: '4px 0px',
        borderRadius: 3,
        margin: '0px 3px',
        fontSize: '0.7rem',
        opacity: 0.95,
        cursor: 'pointer',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        userSelect: 'text',
        backgroundColor: `var(--slot-bg-color)`,
        border: `1px solid transparent`,
        filter: 'opacity(0.7)',
        padding: '0px 0px',
        minHeight: '3ex',

        '&:hover': {
        },

        '&:empty': {
          minHeight: '1ex',
          minWidth: '70px',
        },
      },

      /**
       * The basic, dormant state: Not hovered, selected, etc.
       */
      uxDormant: {
      },

      uxHover: {
        filter: 'brightness(1.1)',
        transition: 'border 250ms ease-in-out, box-shadow 250ms ease-in-out',
        border: `1px solid var(--slot-border-color)`,
        boxShadow: '1px 5px 10px 3px rgba(0,0,0,0.2)',
      },

      /**
       * @daniel this style is applied to all variables in the same slot as
       * the variable currently under the mouse cursor.
       */
      uxPeerHover: {
        filter: 'brightness(1.1)',
        transition: 'border 250ms ease-in-out, box-shadow 250ms ease-in-out',
        // border: `1px solid var(--slot-border-color)`,
        boxShadow: '1px 5px 10px 3px rgba(0,0,0,0.2)',
      },

      /**
       * Not yet implemented
       */
      uxSelected: {
        border: `2px solid var(--slot-border-color)`, // @daniel
        boxShadow: '0px -5px 25px 7px rgba(0,0,0,0.2)',
        transition: 'border 150ms ease-in, box-shadow 2000ms cubic-bezier(.17,.67,.83,.67)',
        filter: 'brightness(1.15)',
      },

      uxPeerSelected: {
      },
    }
  }

  stateClass (state) {
    const { jss } = this
    const classes = [this.jss._ux]

    if (!state) { classes.push(jss.uxDormant) }
    if (state & UX_STATE.hover) { classes.push(jss.uxHover) }
    if (state & UX_STATE.peerHover) { classes.push(jss.uxPeerHover) }
    if (state & UX_STATE.selected) { classes.push(jss.uxSelected) }
    if (state & UX_STATE.peerSelected) { classes.push(jss.uxPeerSelected) }

    return classes.join(' ')
  }

  static get slotCSS () {
    return {
      slotValue: {
        padding: '4px 0px',
        borderRadius: 3,
        margin: '0px 3px',
        fontSize: '0.7rem',
        opacity: 0.95,
        cursor: 'pointer',
        display: 'inline',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        userSelect: 'none',
        backgroundColor: `var(--slot-bg-color)`,
        border: `1px solid transparent`,
        filter: 'opacity(0.7)',

        '&:hover': { },

        '&:empty': {
          minHeight: '1ex',
          minWidth: '70px',
        },
      },

      slot: {
        extend: '_text',
        color: color.text.light.secondary,
        fontWeight: '700',
        textTransform: 'uppercase',
        backgroundColor: color.fill.light.primary,
        userSelect: 'none',
        verticalAlign: 1,
        fontSize: '0.8rem',
        transition: 'background-color 120ms ease-in-out, color 120ms ease-in',
        textOverflow: '',
        overflow: '',
        padding: '0px 7px',

        '&:empty': {
          display: 'none',
        },

        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
          color: color.text.dark.secondary,
        },

        '&:hover': {
          backgroundColor: color.fill.light.quarternary,
          color: color.text.light.primary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.quarternary,
            color: color.text.dark.primary,
          },
          cursor: 'pointer',
        },
      },
    }
  }

  get valueHTML () { return ko.unwrap(this.slot).slotTitle }
  get slotHTML () { return ko.unwrap(this.slot).indexText }

  onMouseOver () { ko.unwrap(this.slot).onHoverChange(false) }
  onMouseOut () { ko.unwrap(this.slot).onHoverChange(true) }

  @computed()
  indicatorHTML () {
    const { jss } = this
    return (
      <>
        <wvar-slot class={jss.slot} do-not-print={1}>{this.slotHTML}</wvar-slot>
        <wvar-span class={jss.value}>{this.valueHTML}</wvar-span>
      </>
    )
  }

  get template () {
    const css = this.computed(() => this.stateClass(ko.unwrap(this.state)))

    return (
      <wvar-span class={css}
        ko-event={{
          mouseover: (_, evt) => this.onMouseOver(),
          mouseout: (_, evt) => this.onMouseOut(),
        }}
        ko-ownClick={evt => this.onClick(evt)}
      >
        {this.indicatorHTML}
      </wvar-span>
    )
  }
}


SlotIndicator.register()
