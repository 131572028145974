import ViewComponent from 'ViewComponent'
import Section from 'EntityModel/components/Section'
import BookComponent from 'EntityModel/components/BookComponent'

import { inline } from 'icons'
import { buttons, color, typography } from 'styles'
import { merge } from 'lodash-es'

import chevronDown from 'icons/light/chevron-down'

export default class SelectSection extends ViewComponent {
  book: BookComponent
  targetSection: KnockoutObservable<Section>
  maxHeight: string
  private newSection: KnockoutObservable<string> = ko.observable(undefined)

  constructor ({ book, targetSection, newSection, maxHeight }) {
    super()
    Object.assign(this, { book, targetSection, newSection, maxHeight })
    this.subscribe(targetSection, ts => !ts || this.newSection(undefined))
  }

  static get css () {
    return {
      dropAnchor: {
        ...buttons.clickable,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${color.onPrimary}`,
        borderRadius: '10px',
        padding: '10px 20px',
      },
      dropAnchorText: {
        color: 'black',
        '&[blank]': {
          color: 'grey',
        },
      },
      anchorInput: {
        outline: 'none',
        font: 'inherit',
        border: 'none',
        backgroundColor: 'inherit',
        color: 'inherit',
        padding: 0,
        margin: 0,
      },
      dropAnchorIcon: { position: 'static' },
      dropList: {
        backgroundColor: 'white',
        border: `1px solid ${color.onPrimary}`,
        borderRadius: '2px',
        boxShadow: '0 8px 20px 0 rgba(0,0,0,0.12)',
        minWidth: '230px',
        maxHeight: 'var(--max-height)',
        fontFamily: typography.altFontFamily,
        overflowY: 'auto',
      },
      dropItem: {
        ...buttons.clickable,
        ...merge(
          color.dynamic(color.text, 'text'),
          color.dynamic(color.systemBackground, 'background'),
          color.dynamic(color.hover, 'hover')
        ),
        padding: '5px',
        color: 'var(--text-primary)',
        backgroundColor:  'var(--background-primary)',
        '&:hover': {
          backgroundColor: 'var(--hover-blue)',
        },
      },
      newDropItem: {
        extend: 'dropItem',
        fontStyle: 'italic',
      },
    }
  }

  get sectionListHTML () {
    const { jss } = this
    const newClick = () => {
      this.targetSection(null)
      this.newSection("New Section")
    }
    return [
      <div class={jss.newDropItem} ko-click={newClick}>New Section</div>,
      ...this.book.sections.map(s => (
        <div class={jss.dropItem} ko-click={() => this.targetSection(s)}>{s.name}</div>
      )),
    ]
  }

  get template () {
    const { jss } = this
    const sectionName = this.computed(() =>
      this.targetSection() ? ko.unwrap(this.targetSection().name) : "Select section" )
    const isBlank = this.computed(() => (
      ( !this.targetSection() && (!this.newSection() || this.newSection() === "New Section" ))
      || undefined ))
    const maxHeight = { '--max-height': this.maxHeight }
    const isNew = this.computed(() => this.newSection() ? true : false)
    return (
      <drop-down my='top right' at='bottom right'>
        <template slot='anchor'>
          <div class={jss.dropAnchor}>
            <div class={jss.dropAnchorText} blank={isBlank}>
              { this.computed(() => isNew()
                ? <input type='text'
                  class={jss.anchorInput}
                  ko-textInput={this.newSection}
                  ko-hasFocus={true}
                  ko-select-text={true}
                  placeholder='New section' />
                : sectionName )}
            </div>
            <div class={jss.dropAnchorIcon}>{inline(chevronDown)}</div>
          </div>
        </template>
        <template slot='content'>
          <div class={jss.dropList} ko-style-map={maxHeight}>
            { this.sectionListHTML }
          </div>
        </template>
      </drop-down>
    )
  }
}

SelectSection.register()

