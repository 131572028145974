
import { inline } from 'icons'
import addIcon from 'icons/light/plus-circle'

import EisCategory from './EisCategory'
import EntityModel from 'EntityModel'
import { Address } from 'DataModel/components/AddressesComponent'

import './eis-address'
import Navigation from './Navigation';

const { ko } = global

export class EisAddressCategory extends EisCategory {
  entity: EntityModel
  navigation: Navigation
  navigationIndex: any
  registerQueue: any
  registered: any[]

  constructor ({ eis, navigationIndex }) {
    super(eis, 'Addresses')
    Object.assign(this, {
      eis,
      entity: eis.entity,
      navigation: eis.navigation,
      navigationIndex,
      registerQueue: ko.observableArray([]),
      registered: [],
    })
    this.parts = this.categoryParts

    if (navigationIndex) {
      this.registerQueue.subscribe(queue => {
        while (this.registered.length) { this.navigation.remove(this.registered.pop()) }
        let lastContol = null
        for (const control of queue) {
          if (!control) { continue }
          if (!lastContol) {
            this.navigation.insertAt(navigationIndex, control)
          } else {
            this.navigation.insertAfter(lastContol, control)
          }
          this.registered.push(control)
          lastContol = control
        }
      })
    }
  }

  get component () {
    return this.entity.componentFor('addresses')
  }

  get addresses () {
    return this.component.array
  }

  get addressHTML () {
    return this.addresses
      .map((address,i) => ({
        address,
        deleteFn: () => {
          this.addresses.remove(address)
          if (this.navigationIndex) {
            this.navigation.remove(this.registered[i])
            this.registered.splice(i,1)
          }
        },
        registerNavigation: navControl => {
          if (!this.navigationIndex) { return }
          this.registerQueue()[i] = navControl
          this.registerQueue(this.registerQueue())
        },
      }))
      .map(control => (
        <eis-address eis={this.eis} addressControl={control} />
      ))
  }

  get haveBlankAddress () {
    return this.addresses().some(a => !a.parts() || !Object.entries(a.parts()).some(([_,v]) => v))
  }

  addButtonHTML () {
    const { jss } = this.eis
    if (this.entity.isProjection()) { return null }

    const addNewAddressClick = evt => {
      this.addresses.push(new Address({}, this.component))
      evt.stopPropagation()
      evt.preventDefault()
    }

    if (this.addresses().length) {
      return this.haveBlankAddress ? ''
        : <div class={jss.addressAddButton} ko-click={addNewAddressClick}>Add</div>
    }
    return (
      <div class={jss.fieldContainer} blank='true'>
        <div class={jss.displayArea}>
          <div class={jss.addressBlankTitle}>Addresses</div>
          <div class={jss.addressAddButton} ko-click={addNewAddressClick}>Add</div>
        </div>
      </div>
    )
  }

  get categoryParts () {
    return (
      <>
        {this.addressHTML}
        {this.eis.computed(() => this.addButtonHTML())}
      </>
    )
  }
}

export default EisAddressCategory

