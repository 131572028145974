
import { cycleNext, cyclePrev } from 'utils/array'
import { computed } from 'utils/decorator'

import { Command, SubmenuCommand } from './Command'

export class CommandMenu extends ko.LifeCycle {
  private commandDeck: Array<Command[]> = []
  public filterStringDeck: Array<string> = []
  private deckMenuCommands: Array<Command> = []
  private commands: KnockoutObservableArray<Command> = ko.observableArray()
  private selectedCommand: KnockoutObservable<Command> = ko.observable()

  constructor (private filterString: KnockoutObservable<string>) { super() }
  add (...commands: Command[]) { this.commands.push(...commands) }
  setSelection (command: Command) { this.selectedCommand(command) }
  get size () { return this.commands.length }

  selectNext () {
    const vc = this.visibleCommands().filter(c => c.isSelectable)
    this.selectedCommand(
      cycleNext(vc, vc.find(c => this.isSelecting(c))))
  }

  selectPrev () {
    const vc = this.visibleCommands().filter(c => c.isSelectable)
    this.selectedCommand(cyclePrev(vc, vc.find(c => this.isSelecting(c))))
  }

  isSelecting (cmd: Command) { return this.selectedCommand() === cmd}
  hasSelection () { return Boolean(this.selectedCommand()) }

  private applicableFilterString (): string {
    return this.filterString().slice(this.filterStringDeck.join('').length)
  }

  private get isInSubmenu () { return this.commandDeck.length !== 0 }

  private matchingCommands (commands: Command[] = this.commands(), text: string = this.applicableFilterString()): Command[] {
    if (!text) { return commands }
    const filtered = commands.filter(c => c.matches(text))
    if (this.isInSubmenu) { return filtered.length >= 1 ? filtered : commands }
    return filtered
  }

  @computed()
  visibleCommands (): Command[] {
    const vc = this.matchingCommands()
      .sort((a, b) => this.startsWithFilter(a, b))
    const topCommand = vc.filter(c => c.isSelectable)[0]
    if (topCommand) { this.selectedCommand(topCommand) }
    return vc
  }

  startsWithFilter (a: Command, b: Command): 1 | -1 | 0 {
    const fs = this.applicableFilterString().toLowerCase()
    const aStartsWith = a.text.toLowerCase().startsWith(fs)
    const bStartsWith = b.text.toLowerCase().startsWith(fs)
    if (aStartsWith === bStartsWith) { return 0 }
    return aStartsWith ? -1 : 1
  }

  /**
   * Returns `true` when the action is run, false otherwise.
   */
  triggerSelected (cmd = this.selectedCommand()): Command {
    if (cmd) {
      cmd.trigger(this)
      this.selectedCommand(null)
      return cmd
    }
    return null
  }

  triggerOnArrowRight (cmd = this.selectedCommand()): Command {
    if (cmd && cmd.triggerOnArrowRight) {
      return this.triggerSelected(cmd)
    }
    return null
  }

  selectTopMenuItem () {
    this.selectedCommand(this.commands()[0])
  }

  setSubmenu (submenuCommand: SubmenuCommand) {
    const submenu = submenuCommand.submenu
    this.deckMenuCommands.push(submenuCommand)
    this.commandDeck.push(this.commands())
    this.commands(submenu.commands())
    this.filterStringDeck.push(this.filterString())
    this.selectTopMenuItem()
  }

  popSubmenu () {
    if (this.commandDeck.length) {
      this.commands(this.commandDeck.pop())
      this.filterStringDeck.pop()
      this.selectedCommand(this.deckMenuCommands.pop())
      return true
    }
    return false
  }

  resetToTopMenu () {
    if (this.commandDeck.length) {
      this.commands(this.commandDeck.shift())
      this.commandDeck.length = 0
      this.filterStringDeck.length = 0
      this.deckMenuCommands.length = 0
      this.selectTopMenuItem()
    }
  }

  findCommandByText (t: string) {
    return this.commands().find(c => c.text === t)
  }
}

export default CommandMenu
