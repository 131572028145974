
import {
  startOfMonth, startOfWeek, isBefore, isSameMonth, addDays, format,
  addMonths
} from 'date-fns/esm'

import { buttons, color } from 'styles'
import { hashHSL } from 'utils/string'

import MinuteBookPanelProvider from 'MinuteBookPanelProvider'
import { DataComponent } from 'DataModel/components'

import { QUESTIONS } from 'EntityModel/entity-questions.yaml'

import 'calendar-tickler'

import RootListPanelProvider from './RootListPanelProvider'
import './calendar-day'
import CalendarCommandSet from './CalendarCommandSet'
import { columnsByPanelID } from 'Column/panelListColumns'

import icons from 'icons'
import faPlusSquare from 'icons/light/plus-square'
import caretLeft from 'icons/solid/caret-left'
import caretRight from 'icons/solid/caret-right'

import { sharingEventIdentifier } from 'DataModel'
import { eventIdentifier as noteEventIdentifier } from 'notes-list'
import CalendarModelListOptions from 'model-list/CalendarModelListOptions'

export type EventType = {
  title: string
  color: string
  show: KnockoutObservable<boolean>
}

export default class CalendarPanelProvider extends RootListPanelProvider {
  private _eventTypes: Record<string, EventType>
  targetDate: KnockoutObservable<Date>
  eventCount: KnockoutObservable<number>
  days: KnockoutComputed<Date[]>

  get panelWindowTitle () { return 'Calendar' }
  get panelID () { return 'Calendar' }
  get collectionID () { return 'entity' }
  get peerPanelList () { return this.app.rootProviderList }

  get CommandSetConstructor () { return CalendarCommandSet }

  constructor (params) {
    super(params)

    const targetDate = ko.observable(new Date())
    const period = ko.observable('month')

    Object.assign(this, {
      period,
      targetDate,
      eventCount: ko.observable(0),
    })

    this.days = ko.computed(() => [...this.genDays()])
      .extend({ deferred: true })
  }

  get eventTypes () : Record<string, EventType> {
    if (!this._eventTypes) { this._eventTypes = this._makeEventTypes() }
    return this._eventTypes
  }

  _makeEventTypes () : Record<string, EventType> {
    const componentEventTypes = Object.assign({},
      ...QUESTIONS
        .filter(q => DataComponent.getComponentById(q.component).hasReminderDates)
        .map(q => ({ [q.identifier]: {
          title: q.title,
          color: hashHSL(q.identifier),
          show: ko.observable(true),
        }})))

    return {
      ...componentEventTypes,
      [sharingEventIdentifier]: {
        title: 'Sharing',
        color: hashHSL(sharingEventIdentifier),
        show: ko.observable(true),
      },
      [noteEventIdentifier]: {
        title: 'Notes',
        color: hashHSL(noteEventIdentifier),
        show: ko.observable(true),
      }
    }
  }

  makeModelListOptions () {
    return new CalendarModelListOptions(this.possibleColumns, this.panelID, this.eventTypes)
  }

  get historyUrl () { return '#calendar' }

  get hideMenuItems () {
    return Object.entries(this.eventTypes).map(([identifier, eventType]) => eventType)
  }

  get possibleColumns () {
    return columnsByPanelID[this.panelID](this)
  }

  get menuSubItems () {
    return super.menuSubItems
  }

  get addModelButton () {
    const { jss } = this
    return (
      <div class={jss.rootTopButton} ko-click={() => this.newEntityClick()}>
        {icons.button(faPlusSquare)}
        <span class={jss.responsiveHiddenWhenNarrow}>Add New Entity</span>
      </div>
    )
  }

  /**
   *
   */
  makeEventsByDate (date: Date, models) {
    if (!date) { return [] }

    return models.flatMap(model =>
      [...model.genRemindersAtDate(date, 'day')]
        .filter(event => this.eventTypes[event.identifier].show())
        .map(event => ({
          ...event,
          object: model.cvModelTitle,
          _openObject: () => this.showPanelFromEvent(model, event),
        })))
  }

  async showPanelFromEvent (model, event) {
    const { app } = this
    app.panelProvider(new MinuteBookPanelProvider({
      app,
      model,
      activeViewID: 'EntityInfoView',
      scrollToComponent: event.identifier,
    }))
  }

  static get css () {
    return {
      ...super.css,
      rightEventsList: {
        minWidth: 'var(--side-panel-width)',
        maxHeight: 'var(--main-height)',
        padding: '4px',
        overflow: 'auto',
        backgroundColor: color.systemBackground.light.secondary,
        maxWidth: 289,
        textAlign: 'center',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
          backgroundColor: color.systemBackground.dark.secondary
        },
      },
      topFixed: {
        ...super.css.topFixed,
        '& [button=mlo-generate-report]': {
          display: 'none',
        }
      },
      // rootTopLeft: {
      //   ...super.css.rootTopLeft,
      // },
      monthCalendar: {
        display: 'grid',
        padding: '15px',
        height: 'var(--main-height)',
        grid: {
          gap: '4px',
          templateColumns: 'repeat(7, 1fr)',
          templateRows: '30px 30px',
          autoRows: '1fr'
        }
      },
      backToToday: {
        ...buttons.clickable,
        gridColumn: '7/8',
        alignSelf: 'center',
        textAlign: 'center'
      },
      backToTodayPill: {
        display: 'inline-block',
        backgroundColor: color.fill.light.secondary,
        padding: '4px 12px',
        borderRadius: '15px',
        fontSize: '13px',
        fontWeight: '600',
        'body[dark] &': { // project batman
          color: color.dmgrey.dmtext,
          backgroundColor: color.fill.dark.tertiary
        },
      },
      monthTitle: {
        gridColumn: '3/6',
        justifySelf: 'center',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        '--icon-color': '#aaa',
        '& svg': {
          cursor: 'pointer',
          '&:hover': {
            '--icon-color': 'black'
          }
        }
      },
      monthName: {
        minWidth: '150px',
        display: 'inline-block',
        'body[dark] &': { // project batman
          color: color.dmgrey.dmtext,
        },
      },
      monthDay: {
        display: 'grid',
        grid: {
          autoRows: 'max-content',
          templateColumns: '100%'
        },
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '4px',
        minWidth: 80,
        minHeight: 70,
        transition: 'background-color 80ms ease-in-out',
        '&:hover': {
          backgroundColor: color.hoverColor,
          transition: 'background-color 80ms ease-in-out',
          'body[dark] &': { // project batman
            backgroundColor: color.dmCalendar.hoverColor,
          },
        }
      },
      dayBeforeMonth: {
        extend: 'monthDay',
        background: color.fill.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.dmCalendar.dayBeforeMonth,
        },
      },
      dayInMonth: {
        extend: 'monthDay',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.dmCalendar.dayInMonth,
          border: '1px solid #404040'
        },
      },
      dayAfterMonth: {
        extend: 'monthDay',
        background: color.fill.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.dmCalendar.dayAfterMonth,
        },
      },
      daySelected: {
        extend: 'monthDay',
        background: 'lightgray'
      },
      dayHead: {
        fontWeight: 'bold',
        alignSelf: 'center',
        justifySelf: 'center',
        'body[dark] &': { // project batman
          color: color.dmgrey.dmtext,
        },
      },
      footContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        left: 'calc(0px - var(--left-panel-default-width))',
      },
      footFlexBox: {
        display: 'flex',
        '--right-edge-allowance': '300px',
        width: 'calc(100vw - var(--right-edge-allowance))',
        height: '100%',
        position: 'sticky',
        left: '0px',
        alignItems: 'center',
        justifyContent: 'center',
      },
      footContent: {
        position: 'relative',
        left: 'calc(var(--right-edge-allowance) / 2)',
      },
      responsiveHiddenWhenNarrow: {

        '@media (max-width: 1000px)': {
          display: 'none',
          padding: '4px 4px 4px 4px'
        }
      },
      responsiveTextHiddenWhenNarrow: {

        '@media (max-width: 1380px)': {
          display: 'none'
        }
      }
    }
  }

  day (day, style) {
    const dayClone = new Date(day.getTime())
    const events = this.makeEventsByDate(day, this.criteria.hits())
    this.eventCount(this.eventCount() + events.length)
    return (
      <calendar-day class={style}
        date={dayClone}
        events={events}
        ko-ownClick={() => this.targetDate(dayClone)} />
    )
  }

  * genDayHeads (css) {
    const days = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ]
    for (const day of days) {
      yield (
        <div class={css.dayHead}>{day}</div>
      )
    }
  }

  * genDays () : IterableIterator<Date> {
    if (!this.isActivePanelProvider()) { return }
    const { jss } = this
    this.eventCount(0)

    const ofDate = this.targetDate()
    const monthStart = startOfMonth(ofDate)
    let day : Date = startOfWeek(monthStart)
    yield * this.genDayHeads(jss)

    while (isBefore(day, monthStart)) {
      yield this.day(day, jss.dayBeforeMonth)
      day = addDays(day, 1)
    }

    while (isSameMonth(day, ofDate)) {
      yield this.day(day, jss.dayInMonth)
      day = addDays(day, 1)
    }

    while (day.getDay()) {
      yield this.day(day, jss.dayAfterMonth)
      day = addDays(day, 1)
    }
  }

  get main () {
    const {jss} = this
    const td = this.targetDate
    const dateName = ko.computed(() => format(td(), 'LLLL yyyy'))

    return (
      <div class={jss.monthCalendar}>
        <div class={jss.monthTitle}>
          <span ko-ownClick={() => td(addMonths(td(), -1))}>
            {icons.inline(caretLeft)}
          </span>
          <div class={jss.monthName}>{dateName}</div>
          <span ko-ownClick={() => td(addMonths(td(), 1))}>
            {icons.inline(caretRight)}
          </span>
        </div>
        <div class={jss.backToToday}>
          <div class={jss.backToTodayPill}
            ko-click={() => td(new Date())}>
            <span class={jss.responsiveTextHiddenWhenNarrow}>Back to </span><span>Today</span>
          </div>
        </div>
        {this.days}
      </div>
    )
  }

  get right () {
    const { jss } = this
    return (
      <div class={jss.rightEventsList}>
        <calendar-tickler
          dateToShow={this.targetDate}
          eventTypes={this.eventTypes}
          panelProvider={this}
          entityList={this.criteria.hits} />
      </div>
    )
  }

  get foot () {
    const { jss } = this
    const eventName = this.computed(() =>
      this.eventCount() === 1 ? 'Event' : 'Events')

    return ko.computed(() => (
      <div class={jss.footContainer}>
        <div class={jss.footFlexBox}>
          <div class={jss.footContent}>
            <div class={jss.foot}>
              {'' + this.eventCount()} {eventName}
            </div>
          </div>
        </div>
      </div>
    ))
  }
}
