
import ConfirmDelete from './confirm-delete'

class ConfirmPageDelete extends ConfirmDelete {
  constructor (params) {
    super(params)
    const { section } = params
    Object.assign(this, { section })
  }

  static get css () {
    return {
      ...super.css,
      sectionName: {}
    }
  }

  get messageHTML () {
    return (
      <p>
        This action will delete the current page. <strong>This cannot be undone</strong>.
      </p>
    )
  }
}

ConfirmPageDelete.register()
