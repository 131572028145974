
export default (
  <svg width='9px' height='14px' viewBox='0 0 9 14' version='1.1'>
    <g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
      <g id='Hide-fields-story' transform='translate(-301.000000, -230.000000)' fill='var(--icon-color)'>
        <g id='icon-reorder' transform='translate(301.000000, 230.000000)'>
          <g transform='translate(4.500000, 7.000000) rotate(90.000000) translate(-4.500000, -7.000000) translate(-2.000000, 3.000000)'>
            <circle id='Oval' cx='1.5' cy='1.5' r='1.5' />
            <circle id='Oval-Copy' cx='6.5' cy='1.5' r='1.5' />
            <circle id='Oval-Copy-2' cx='11.5' cy='1.5' r='1.5' />
            <circle id='Oval-Copy-5' cx='1.5' cy='6.5' r='1.5' />
            <circle id='Oval-Copy-4' cx='6.5' cy='6.5' r='1.5' />
            <circle id='Oval-Copy-3' cx='11.5' cy='6.5' r='1.5' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
