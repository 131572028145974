import { fromISO8601 as parseISO } from 'utils/dates'

import { inSameRange } from 'utils/dates'
import { PersonOrigin, PersonRoleRecord } from '../person/interfaces'

// Used as the event type for reminder dates
export const eventIdentifier = 'sharing right'

interface SharingPermit {
  name: string
  target: string
  params: object
}

/**
 * A SharingRight is the set of rights for an single recipient.
 */
export default class SharingRight {
  person: PersonRecord
  expires: iso8601
  permits: KnockoutObservableArray<SharingPermit>

  constructor ({ email, personID, permits, expires }) { // Must accecpt object generated by toJS()
    Object.assign(this, {
      person: this.makePerson(email, personID),
      permits: ko.observableArray(permits),
      expires,
    })
  }

  get email () { return this.person.email[0] }

  makePerson (email: string, id: string = null) : PersonRecord {
    const role : PersonRoleRecord = {
      title: 'Sharee',
      end: this.expires && parseISO(this.expires)
    }
    const origin : PersonOrigin = {
      update: op => op(this.person),
      * roles () { yield role }
    }
    return {
      id,
      name: [],
      email: [email],
      phone: [],
      address: [],
      origin: [origin],
    } as PersonRecord
  }

  toJS () {
    return {
      email: this.email,
      personID: this.person.id,
      expires: this.expires || null,
      permits: this.permits()
    }
  }

  * genRemindersAtDate (given: Date, range) : IterableIterator<RelatedDate> {
    if (!this.expires) { return }
    const expiryDate = parseISO(this.expires)
    if (inSameRange(given, expiryDate, range)) {
      yield {
        date: expiryDate,
        reason: `Share to ${this.email} expires`,
        identifier: eventIdentifier,
      }
    }
  }

  /**
   * @return {bool} true when the user only has access to a projection
   */
  get isDirect () {
    const [p0] = this.permits()
    return p0 && p0.target === '*'
  }

  /**
   * A projection is needed when the user only has access to this model
   * because of the share, and the share is not "direct".
   *
   * If they have access to the model because of a filter or they are an
   * admin, then the permit is not needed.
   *
   * @return {bool} true when the user only has access to a projection
   */
  get needsProjection () {
    return !this.isDirect
  }

  get hardCodedPermits () {
    return [
      { target: 'sharing' },
      { target: 'accountID' },
      { target: 'id' }
    ]
  }

  /**
   * @param {CrudModel} model
   * @return {object} with the projection values
   */
  async generateProjection (model) {
    const permits = [...this.permits(), ...this.hardCodedPermits]
    const permitObjects = await Promise.all(permits
      .map(p => this.dataForPermit(p, model, this.email))
      .filter(v => v))
    return Object.assign(...permitObjects)
  }

  /**
   * @param {object} param
   * @param {string} param.target name of a `Mediator` on `model`
   * @param {any} params passed to `param.target.projectionValue`
   * @param {CrudModel} model
   * @return {null|object}
   */
  async dataForPermit ({ target, params }, model, email) {
    const mediator = model.vmMediatorsList.find(m => m.name === target)
    if (!mediator) {
      console.error(`No mediator for: ${target}`, model)
      return null
    }

    const projectionValue = await mediator.projectionValue(params, email)
    return projectionValue ? { [target]: projectionValue } : null
  }
}
