
import ViewComponent from 'ViewComponent'
import OutcomeNotification from 'notification-manager/outcome-notification'
import { buttons } from 'styles'

import icons from 'icons'
import cogIcon from 'icons/solid/circle-notch'
import warningIcon from 'icons/light/exclamation-triangle'
import errorIcon from 'icons/solid/exclamation-triangle'

/**
 * Given an async `action` function, after a click indicate visually when
 * that function is working (unresolved) or errored (rejected).
 *
 * Usage:
 *
 *    <async-button action={function.<Promise>} enable={bool}>
 *        <template slot='face'></template>
 *        [<template slot='working'></template>]
 *    </async-button>
 */
export default class AsyncButton extends ViewComponent {
  static get elementName () { return 'async-button' }
  static get element () { return 'async-button' }

  constructor ({ action, enable, faceClass, eventEmitter, face, working }) {
    super()
    if (typeof action !== 'function') {
      throw new Error('<async-button> needs a function-`action`')
    }

    Object.assign(this, {
      action,
      faceClass,
      face,
      enable: enable || true,
      working: working || ko.observable(),
      error: ko.observable(),
    })
    this.ready = this.computed(() => !this.working() && !this.error())
    if (eventEmitter) {
      eventEmitter.on('activate', () => this.activate())
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.errorNotificationCSS,
      button: {
        ...buttons.clickable,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },

      face: {
        opacity: 1,
        transition: 'opacity 0.2s'
      },

      ready: {
        extend: 'face'
      },

      hideFace: {
        extend: 'face',
        opacity: 0.25
      },

      working: {
        position: 'absolute',
        zIndex: 2,
        '--icon-color': '#fff',
        '& svg': {
          animation: 'spin 2s infinite linear',
          filter: 'drop-shadow(0px 0px 3px black)'
        }
      },
      error: {
        position: 'absolute',
        zIndex: 3
      }
    }
  }

  ready () {
    return !this.working() && !this.error()
  }

  async activate () {
    const enabled = ko.unwrap(this.enable)
    if (!enabled || this.working()) { return }
    this.working(true)

    try {
      this.error(undefined)
      await this.action(this)
    } catch (e) {
      console.error(`<async-button> error:`, e)
      this.error(e.message)
      global.app.notifier.pushOutcome(
        ...this.genericErrorNotification(e)
      )
      throw e
    } finally {
      this.working(false)
    }
  }

  async onKeydown (evt) {
    if (evt.key === 'Enter') {
      evt.stopPropagation()
      evt.preventDefault()
      this.activate()
    }
  }

  async onClick (evt) {
    evt.stopPropagation()
    evt.preventDefault()
    this.activate()
  }

  static get errorNotificationCSS () {
    return {
      fbFnErrorMessage: {},
      errorOutcome: {
        ...OutcomeNotification.css.outcome,
        backgroundColor: 'rgba(255,188,196,0.97)',
        border: '1px solid red'
      },
      errorIcon: {},
      errorMessage: {
      }
    }
  }

  genericErrorNotification (e) {
    const { jss } = this
    return [
      <div class={jss.fbFnErrorMessage}>
        <strong title={e.message}>Something went wrong:</strong> This action could not be performed. Please contact <a href="mailto:support@minutebox.com">support@minutebox.com</a>.
      </div>, {
        icon: errorIcon,
        style: {
          outcome: jss.errorOutcome,
          icon: jss.errorIcon,
          message: jss.errorMessage
        }
      }
    ]
  }

  get faceHTML () {
    return this.face || <slot name='face' />
  }

  get template () {
    const { jss } = this
    const faceCss = this.computed(() => this.ready() ? jss.ready : jss.hideFace)

    return (
      <div class={this.faceClass || jss.button}
        tabindex='0'
        ko-click={evt => this.onClick(evt)}
        ko-event={{ keydown: (self, evt) => this.onKeydown(evt) }}>

        <div class={jss.working} ko-if={this.working}>
          {icons.inline(cogIcon)}
        </div>
        <div class={jss.error} ko-if={this.error} title={this.error}>
          {icons.inline(warningIcon)}
          Retry
        </div>
        <div class={faceCss}>
          {this.faceHTML}
        </div>
      </div>
    )
  }
}

AsyncButton.register()
