/**
 * Addresses are one of the more complex data items to handle.
 *
 * These are addresses suitable for the postal system.
 *
 * Our general philosophy is to keep the address as text-based as possible
 * and reduce it to parts suitable for forms only when those forms are
 * asked for.
 */
import ArrayComponent from './ArrayComponent'
import UnitView from './UnitView'

type FieldDescription = import('./UnitField').FieldDescription

/**
 * An AddressesComponent has a number of "interpretations" of the address.
 *
 * Addresses are inscrutable, so we try to be as dynamic here as possible.
 * In general there will be a lot of "mix-and-match" with addresses,
 * where forms and regulations require variants of the information to suite
 * their (typically arbitrary) requirements for piecemealing the address
 * into individual named components (e.g. 'street', 'city', etc.).
 *
 * Note:
 *
 *   https://stackoverflow.com/questions/310540
 *   https://stackoverflow.com/questions/1445463
 *   https://stackoverflow.com/questions/307027
 *   http://xml.coverpages.org/xnal.html
 *   https://www.drupal.org/project/addressfield
 *   http://www.columbia.edu/~fdc/postal/
 *
 * These could potentially include:
 *
 *  - userProvidedText: an address entered by the user
 *
 *  - googlePlaceID: From the Google Geocoding API:
 *    https://developers.google.com/maps/documentation/geocoding
 *
 * Others, structured into parts such as:
 * https://developers.google.com/maps/documentation/geocoding/intro#Types
 *
 *  - xnal:
 *    - country => Country (always required, 2 character ISO code)
 *    - name_line => Full name (default name entry)
 *    - first_name => First name
 *    - last_name => Last name
 *    - organisation_name => Company
 *    - administrative_area =>
 *      State / Province / Region (ISO code when available)
 *    - sub_administrative_area => County / District (unused)
 *    - locality => City / Town
 *    - dependent_locality => Dependent locality (unused)
 *    - postal_code => Postal code / ZIP Code
 *    - thoroughfare => Street address
 *    - premise => Apartment, Suite, Box number, etc.
 *    - sub_premise => Sub premise (unused)
 *
 *  - "Traditional" / US-Canada-post parts:
 *    - street address
 *    - street address 2
 *    - city
 *    - region
 *    - postal code
 *    - country
 *
 *  - ca.on.form1Parts: The address broken out into parts consistent with
 *    the "Form 1" used by the Government of Ontario:
 *      - c/o (sometimes)
 *      - street #
 *      - street name
 *      - suite
 *      - city
 *      - postal
 *      - country (sometimes)
 *
 */
 export class Address extends UnitView {
  get elvValue () { return this.title }

  get fields () {
    return {
      title: 'string',
      userProvidedText: 'string',
      googlePlaceID: 'string',
      parts: 'object',
      xnal: 'object',
      'ca.on.form1': 'object'
      // equivalences: [
      //   { parts: 'street', 'ca.on.form1': 'street_name' }
      // ]
    }
  }

  asAddressRecord () {
    return Object.assign({},
      ...Object.entries(this.fields)
        .map(([key,_]) => ({[key]:ko.unwrap(this[key])})))
  }

  get fullAddressAsString () {
    return ko.unwrap(this.userProvidedText) || partsToString(this.parts())
  }

  private _getPart (s: string) {
    const parts = this.parts() || ''
    return parts[s] || ''
  }

  get partsLine1 () { return this._getPart('line1') }
  get partsLine2 () { return this._getPart('line2') }
  get partsCity () { return this._getPart('city') }
  get partsRegion () { return this._getPart('region') }
  get partsPostal () { return this._getPart('postal') }
  get partsCountry () { return this._getPart('country') }

  static get variableFieldDescriptions (): Omit<FieldDescription, 'label'>[] {
    return [
      { property: 'title', type: 'string' },
      { property: 'fullAddressAsString', type: 'string' },
      { property: 'partsLine1', type: 'string' },
      { property: 'partsLine2', type: 'string' },
      { property: 'partsCity', type: 'string' },
      { property: 'partsRegion', type: 'string' },
      { property: 'partsPostal', type: 'string' },
      { property: 'partsCountry', type: 'string' },
    ]
  }
}

export type AddressRecord = {
  title: string
  userProvidedText: string
  googlePlaceID: string
  parts: any
  xnal: any
  'ca.on.form1': any
}

export function partsToString (parts) {
  if (!parts) { return '' }
  const [ city, region ] = [ parts.city || '', parts.region || '' ]
  const cityRegion = `${city}${(city && region) ? ', ' : ''}${region}`
  const lines = [ parts.line1, parts.line2, cityRegion, parts.postal, parts.country ]
  return lines.filter(l => l).join('\n')
}

export function partsToHTML (parts) {
  if (!parts) { return '' }
  return (<>
    <div>{parts.line1}</div>
    <div>{parts.line2}</div>
    <div>{parts.city}{(parts.city && parts.region)? ', ' : ''}{parts.region}</div>
    <div>{parts.postal}</div>
    <div>{parts.country}</div>
  </>)
}

/**
 * A list of Address instances.
 */
export default class AddressesComponent extends ArrayComponent {
  static get componentName () { return 'addresses' }
  get ItemClass () { return Address }
}

AddressesComponent.register()
