import { sortBy } from 'lodash-es'

import Picker from 'picker'

import { isoList, tJurisdiction, nameOf, PCT } from './lookup'

/**
 * The `<jurisdiction-picker>` is special in that it selects from the ISO
 * objects, is given and saves the `iso` code, but queries items based
 * on the `area`.
 *
 * To accommodate this, the picker hijacks the `value` and `onSelect`
 * parameters.
 *
 * We prefer to load/save the `iso` instead of the jurisdiction name
 * because the `iso` code:
 *
 * 1. is unambiguous
 * 2. survives renaming/regime changes e.g. Burma => Myanmar
 *
 */
export default class JurisdictionPicker extends Picker<tJurisdiction> {
  constructor (params) {
    const valueToUpdate = params.value
    const value = ko.observable(nameOf(valueToUpdate()))
    const onSelect = (v: tJurisdiction | string) =>
      valueToUpdate(typeof v === 'string' ? v : v.iso)
    super({ ...params, value, onSelect, maxMatches: 10 })
  }

  choices () { return sortBy(isoList(), ['priority', 'area']) }

  filterChoice (item, query) {
    const q = (query || '').toLowerCase()
    return item.area.toLowerCase().includes(q)
  }

  itemAsString (item) { return item.area || item }

  itemHTML (item) {
    const { jss } = this
    return (
      <div class={jss.item}>
        <jurisdiction-flag value={item.iso} />
        <div class={jss.area}>{item.area}</div>
        <div class={jss.state}>{item.state}</div>
      </div>
    )
  }

  /**
   * Return either the ISO code or the query name (for user-entered,
   * non-standard jurisdictions).
   */
  selectValueOf (item) {
    return item ? (item.iso || item) : this.query()
  }

  get anchorHTML () {
    const { jss } = this
    return (
      <div class={jss.inputAndFlag}>
        {super.anchorHTML}
        <jurisdiction-flag value={this.query} />
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      inputAndFlag: {
        display: 'flex',
        alignItems: 'center',
      },
      item: {
        ...super.css.item,
        padding: '6px 5px',
      },
      area: {
      },
      state: {
        padding: '0px 15px',
        color: '#aaa',
      }
    }
  }
}

export class JurisdictionPickerModal extends JurisdictionPicker {
  static get css () {
    return {
      ...super.css,
    }
  }
}

export class JurisdictionPickerPatents extends JurisdictionPicker {
  choices () {
    return [PCT, ...super.choices()]
  }
}

JurisdictionPicker.register()
JurisdictionPickerModal.register()
JurisdictionPickerPatents.register()
