import { omit } from 'lodash-es'

import { DataComponent } from 'DataModel'
import { toLocaleDecimal } from 'utils/number'
import { inSameRange, endOfTime } from 'utils/dates'
import { PersonRecordSet } from 'person'

import { Transaction, CODES, factory } from './Transaction'
import CapitalState from './CapitalState'

type CrudModel = import('DataModel/CrudModel').default

/**
 * Capital is a curious thing.  It's very complex to make it simple.
 */
export default class CapitalComponent extends DataComponent {
  transactions: KnockoutObservableArray<Transaction>
  persons: PersonRecordSet

  static get namespace () { return 'entity' }
  static get hasReminderDates () { return true }

  constructor (params) {
    super(params)
    this.transactions = ko.observableArray([])
    this.persons = new PersonRecordSet([])
  }

  get () { return this }
  set (data) {
    if (!data) { data = {} }
    const trans = data.transactions || []
    this.persons.set(data.persons)
    this.transactions(trans.map(factory))
    // 🐫 2.0 => 2.1 —— Generate this.persons
    if (trans.length && !data.persons) { [...this.getPersons()] }
  }

  toJS () {
    const persons = [...this.getPersons()]
      .map(p => omit(p, 'origin'))

    return {
      persons,
      transactions: this.transactions().map(t => t.toJS()),
    }
  }

  isBlank () : boolean {
    return !this.transactions.length
  }

  state (asOf: Date = endOfTime()): CapitalState {
    const transactions = this.transactions()
    return new CapitalState({ transactions, datetime: asOf })
  }

  * relatedPeople (): IterableIterator<RelatedPerson> {
    console.warn(`relatedPeople is deprecated.`)
    const cs = this.state()
    for (const [name, stakes] of Object.entries(cs.stakeholders)) {
      yield * Object.entries(stakes.assets)
        .filter(([asset, amount]) => !amount.eq(0))
        .map(([asset, amount]) => ({
          name,
          role: `Holds ${toLocaleDecimal(amount)} ${cs.getAsset(asset).assetName}`,
          start: null,
          end: null,
          origin: this,
        }))
    }
  }

  * getPersons (filter: PersonFilter = () => true) : IterableIterator<PersonRecord> {
    const { persons } = this
    const model = this.model as CrudModel
    const cs = this.state()

    for (const holder of Object.values(cs.stakeholders)) {
      const obs = persons.getByID(holder.idOrName)
        || persons.getByName(holder.name)[0]
        || persons.addRecord(persons.createBlankRecord({ name: [holder.name] }))

      const v = obs()
      if (!filter(v)) { continue }

      const origin = [{
        model,
        update (op) { op(obs()); obs.notifySubscribers(obs()) },
        * roles () { yield * holder.roles(cs) }
      }] as PersonOrigin[]

      yield { ...v, origin } as PersonRecord
    }
  }

  * reminderDates (given, range): IterableIterator<EventRecord> {
    const cs = new CapitalState()
    for (const tr of this.transactions) {
      tr.applyTo(cs)
      const date = tr.datetime()
      if (!given || !inSameRange(date, given, range)) { continue }
      const reason = tr.shortDescription(cs)
      if (!reason) { continue }
      yield {
        identifier: 'capital', // TODO: tr.code
        model: this.model,
        date,
        reason,
        component: this as unknown as DataComponent,
      }
    }
  }
}

CapitalComponent.register()
