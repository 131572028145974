import { groupBy } from 'lodash-es'

import { buttons, color } from 'styles'
import { ShareholderLedger } from 'capital/interfaces'

import ShareholdersBy from './ShareholdersBy'
import './capital-holder-ledger'
import './capital-asset-name'
import { AssetCertificate } from '../interfaces'
import { formatForUser } from 'utils/dates'

export class ShareholdersByHolder extends ShareholdersBy {
  get groupBy () { return 'person' }

  static get css () {
    return {
      ...super.css,
      ...this.ledgerCSS,
      assetName: {
        gridColumnStart: 2
      },
      holderName: {
        fontWeight: '500',
      },
    }
  }

  get headingsHTML () {
    const { jss } = this
    return (
      <>
        <div class={jss.head}>Holder</div>
        <div class={jss.head}>Classification</div>
        <div class={jss.head}>Amount</div>
        <div class={jss.head}></div>
      </>
    )
  }

  * groupLine (ledger: ShareholderLedger, balance: string, percent: string) {
    const { jss } = this
    const asset = this.state.getAsset(ledger.assetClassID)
    const { prefix, classification, series } = asset.assetNameParts

    yield (<div class={jss.assetName}>
      <capital-asset-name prefix={prefix} classification={classification} series={series} />
    </div>)
    yield (<div class={jss.balance} title={`${percent}%`}>{balance}</div>)
  }

  * certificatesList (certs: AssetCertificate[]) {
    const byAsset = groupBy(certs, 'assetClassID') as Record<string, AssetCertificate[]>
    for (const [assetClassID, certs] of Object.entries(byAsset)) {
      const asset = this.state.getAsset(assetClassID)
      yield <certificates-list
        asset={asset.assetNameParts}
        issued={certs}
        surrendered={[]} />
    }
  }

  groupName (groupName, ledgers) {
    const { jss } = this
    const certs = this.state.getStakeholder(groupName).certificates
    return (
      <div class={jss.holderName}>
        {this.persons.getNameOf(groupName)}
        <div class={jss.groupNameLink}
          ko-click={evt => this.ledgersClick(evt, groupName, ledgers)}>ledgers</div>
        <div class={jss.certs}>
          {this.certificatesList(certs)}
        </div>
      </div>
    )
  }

  sortedKeys (byGroup: Record<string, ShareholderLedger[]>) : string[] {
    return Object.keys(byGroup).sort()
  }

  static get ledgerCSS () {
    return {
      ledger: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px'
      },
      certs: {
        display: 'none'
      },
      ledgerTable: {
        padding: '15px',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        borderTop: 'none',
      },
      ledgerAssetName: {
        textAlign: 'center',
        fontWeight: 'bold',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        background: color.systemBackground.light.secondary,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          background: color.systemBackground.dark.primary,
        },
        padding: '10px 15px',
      },
      ledgerButtons: {
        display: 'flex'
      },
      ledgerAsOf: {
        textAlign: 'center',
        display: 'none'
      },
      ledgerCloseButton: {
        ...buttons.modalOk,
        marginLeft: 'auto',
        marginTop: '40px'
      },
      groupNameLink: {
        ...super.css.groupNameLink,
        borderRadius: 4,
        marginLeft: 15,
        fontSize: '0.7rem',
        padding: '1px 5px',
      },
    }
  }

  ledgerModalHTML (ledger: ShareholderLedger) {
    const { jss } = this
    const { prefix, classification, series } = this.state.getAsset(ledger.assetClassID).assetNameParts
    return (
      <div class={jss.ledger}>
        <div class={jss.ledgerAssetName}>
          <capital-asset-name prefix={prefix} classification={classification} series={series} />
        </div>
        <capital-holder-ledger class={jss.ledgerTable} ledger={ledger} state={this.state} />
      </div>
    )
  }

  ledgersClick (evt: Event, idOrName: string, ledgers: ShareholderLedger[]) {
    const { jss } = this
    evt.preventDefault()
    evt.stopPropagation()
    const title = `Ledgers For ${this.persons.getNameOf(idOrName)}`
    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <div class={jss.ledgerAsOf}>as of {formatForUser(this.state.datetime)}</div>
          {ledgers
            .filter(ledger => ledger.lines.length)
            .map(ledger => this.ledgerModalHTML(ledger))}
          <div class={jss.ledgerButtons}>
            <div class={jss.ledgerCloseButton}
              ko-click={() => window.app.modal(undefined)}>Close</div>
          </div>
        </template>
      </modal-dialog>
    )
  }
}

ShareholdersByHolder.register()
