/**
 * A <command-area> takes a command and shows its shortcuts and a message
 * with a global "alt" keypress.
 *
 */
import QTip from './q-tip'
import { typography } from 'styles'

import * as commandMessages from './command-area-messages'

const altDown = ko.observable(false)

const ALT_ALT_DELAY = 750

let lastPress = 0
document.body.addEventListener('keydown', evt => {
  if (evt.key !== 'Alt') { return }
  if (new Date() - lastPress < ALT_ALT_DELAY) { altDown(true) }
  lastPress = new Date()
})

document.body.addEventListener('keyup', evt => {
  if (evt.key === 'Alt') { altDown(false) }
})

/**
 * A `<command-area>` is similar to a `<q-tip>`, in that it displays a message
 * from `command-area-messages`.
 */
export default class CommandArea extends QTip {
  constructor (params) {
    super(params)
    const { anchor, command } = params
    Object.assign(this, { anchor, name, command })
    this.subscribe(altDown, this.showing)
  }

  getContent (name) { return commandMessages[name] }

  get anchorHTML () { return this.anchor }

  static get css () {
    return {
      ...super.css,
      area: {
      },
      commandTitle: {},
      help: {
        fontSize: '1rem',
        whiteSpace: 'pre-line',
        border: '1px solid #d6dadc',
        margin: 5,
        padding: '6px 10px',
        boxShadow: '0 2px 3px rgba(0,0,0,.1)',
        borderBottom: '4px solid #e8e8e8',
        borderRadius: 5,
        textTransform: 'uppercase',
        backgroundColor: '#fff',
        fontFamily: typography.altFontFamily,
        fontWeight: '400',
        color: 'black'

      },
      keys: {}
    }
  }

  /**
   * Temporarily disabled
   */
  get anchorEvents () { return {} }

  keyHTML ({ key, options }) {
    const { jss } = this
    return (
      <div class={jss.key}>
      </div>
    )
  }

  get contentHTML () {
    const { jss } = this
    return (
      <div class={jss.area}>
        {/* <div class={jss.commandTitle}>
          {this.command.title}
        </div> */}
        <div class={jss.help}>
          {super.contentHTML}
        </div>
        <div class={jss.keys}>
          {this.command.shortcutKeys.map(sc => sc.key).join(', ')}
        </div>
      </div>
    )
  }
}

CommandArea.register()
