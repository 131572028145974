
import EisMultiComponentField from './EisMultiComponentField'

import { inline } from 'icons'
import chevronDown from 'icons/light/chevron-down'

import { typography, color } from 'styles'
import { ENTITY_TYPES, BUSINESS_TYPES } from '../constants'

export default class EisBusinessEntity extends EisMultiComponentField {
  constructor(args) {
    super(args)
    this.registerNavigation()
  }

  get componentNames () { return ['business_type', 'entity_type'] }

  static get css () {
    return {
      ...super.css,
      contentArea: {
        gridArea: 'content',
        display: 'grid',
        gridTemplateColumns: '1fr auto 65px',
      },
      title1: {
        ...super.css.title,
      },
      field1Area: {
        display: 'flex',
        alignItems: 'flex-end',
      },
      field1: {
        ...super.css.textContent,
      },
      field2: {
        ...super.css.indicator,
        '&:empty': {
          display: 'none'
        }
      },
      editControls: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        padding: 10,
      },
      // dropAnchor: {
      //   backgroundColor: 'white',
      //   border: '1px solid grey',
      //   padding: '0px 5px',
      // },
      // dropList: {
      //   backgroundColor: 'white',
      //   border: '1px solid grey',
      //   padding: '5px',
      // },
      // dropItem: {
      //   cursor: 'pointer',
      //   '&:hover': {
      //     color: 'grey',
      //   },
      // },
      dropAnchor: {
        backgroundColor: color.light.eisDrawerBackground,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          backgroundColor: color.dark.eisDrawerBackground,
          borderBottom: `1px solid ${color.separator.light.nonOpaque}`
        },
        padding: '0px 5px',
        height: 40,
        margin: 5,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 20px',
        //transition: 'box-shadow 200ms ease-in-out',
        '&:hover': {
          //boxShadow: '0 4px 10px 0 rgba(0,0,0,0.12)',
          //transition: 'box-shadow 200ms ease-in-out'
        },
      },
      dropList: {
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.dark.eisEditingDropList,
        },
        padding: '5px',
        boxShadow: '0 8px 20px 0 rgba(0,0,0,0.12)',
        borderTop: '1px solid rgba(0,0,0,0.4)',
        minWidth: '230px',
        borderBottomRightRadius: 2,
        borderBottomLeftRadius: 2,
      },
      dropInput: {
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'italic',
      },
      dropIcon: {
        display: 'flex',
        alignItems: 'center',
      },
      dropItem: {
        cursor: 'pointer',
        '&:hover': {
          color: color.text.light.altPrimary,
          backgroundColor: color.color.light.brandBlue,
          'body[dark] &': { // project batman
            color: color.dark.eisEditingDropItemText,
            backgroundColor: color.dark.eisEditingDropItemBackground,
          },
        },
      },
      inputFieldMeta: {
        gridColumn: '1',
        gridRow: '2'
      },
      inputFieldMeta2: {
        gridColumn: '2',
        gridRow: '2'
      },
      entityTitleEdit: {
        ...super.css.title,
        gridColumn: '1'
      },
      typeTitleEdit: {
        ...super.css.title,
        gridColumn: '2'
      },
      inputField: {
        width: '-webkit-fill-available',
        height: 40,
        fontFamily: typography.bodyFontFamily,
        margin: 5,
        fontSize: '1rem',
        borderRadius: 2,
        backgroundColor: color.light.eisDrawerBackground,
        border: '1px solid transparent',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
          backgroundColor: color.dark.eisDrawerBackground,
        },
        transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        '&:focus': {
          outline: 'none',
          transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        }
      }
    }
  }

  get titleAreaHTML() {}

  get title1HTML () {
    return (
      <div class={this.jss.title1}>
        <span>Business Type</span>
        {this.copyIconHTML('business_type')}
      </div>
    )
  }

  get displayHTML () {
    const { jss } = this
    const businessTypeFocus = this.computed(() => this.inputfocus.business_type() || undefined)
    const entityTypeFocus = this.computed(() => this.inputfocus.entity_type() || undefined)
    return [
      this.title1HTML,
      <div class={jss.indicatorTitleArea}>
        <div class={jss.title}>
          <span>Entity Type</span>
        </div>
      </div>,
      this.copyIconHTML('entity_type'),
      <div class={jss.field1Area}>
        <div class={jss.field1} focused={businessTypeFocus}
          blank={this.computed(() => this.componentsMap.business_type() ? undefined : 'true')}>
          {this.displayValue.business_type}
        </div>
      </div>,
      <div class={jss.indicatorArea}>
        <div class={jss.field2} focused={entityTypeFocus}>
          {this.componentsMap.entity_type}
        </div>
      </div>,
    ]
  }

  get dummyEntityTypes () {
    return ENTITY_TYPES
  }

  get entityTypeItemsHTML () {
    const click = v => () => this.inputs.entity_type(v)
    return this.dummyEntityTypes.map(v => (
      <div class={this.jss.dropItem} ko-click={click(v)}>{v}</div>
    ))
  }

  get editHTML () {
    const { jss } = this
    const koEvent = { keydown: this.inputKeyDown({ 'Tab': evt => this.onTabDown(evt) }) }

    return (
      <div class={jss.editControls}>
        <div class={this.jss.entityTitleEdit}>
          <span>Business Type</span>
        </div>
        <div class={this.jss.typeTitleEdit}>
          <span>Enity Type</span>
        </div>
        <div class={jss.inputFieldMeta}>
          <model-property-picker my='top left' at='bottom left'
            extraChoices={BUSINESS_TYPES}
            hasFocus={this.computed(() => this.editing() && this.inputfocus.business_type())}
            indexName='entity'
            inputClass={jss.inputField}
            onSelect={this.inputs.business_type}
            propertyGetter={e => e.answerFor('business_type')}
            value={this.inputs.business_type}
          />
            {/* ko-event={koEvent} class={jss.inputField} /> */}
        </div>

        <div class={jss.inputFieldMeta2}>
          <model-property-picker my='top left' at='bottom left'
            indexName='entity'
            propertyGetter={e => e.answerFor('entity_type')}
            onSelect={this.inputs.entity_type}
            value={this.inputs.entity_type}
            inputClass={jss.inputField}
            hasFocus={this.computed(() => this.editing() && this.inputfocus.entity_type())}
            extraChoices={ENTITY_TYPES}
          />
        </div>
      </div>
    )
  }
}

EisBusinessEntity.register()
