
import { kebabCase, merge } from 'lodash-es'

const tag = {
  backgroundColor: '#f9f9f9',
  borderColor: '#e8e8e8',

  '&:hover': {
    borderColor: '#3D70B2'
  }
}

const brandYellow = '#ffd502'

const grey = {
  aa: '#333',
  a: '#737473',
  b: '#979797',
  c: '#b4b4b4',
  d: '#dbdbdb',
  e: '#ececec',
  f: '#f2f2f2',
  g: '#fafafa',
  brand: '#424242',
  activeHead: '#ececec',
  contextShadow: '#ececec',
  searchbg: '#f2f2f2',
  // app loader
  appLoaderMain: 'white',
  appLoaderSub: 'orange',
  ticklerBorder: '#dbdbdb'
}

const dmgrey = {
  dmbg1: '#1E1E1E',
  dmbg2: '#282828',
  dmbg3: '#777777',
  dmtext: 'white',
  dmhoverbg: '#76397C',
  iconcolor: 'white',
  toolBarIconBackground: '#76397C',
  toolBarIconBackgroundBorder: 'purple',
  contextShadow: '#777777',
  searchbg: '#6A6D70',
  //app loader
  appLoaderMain: '#1E1E1E',
  appLoaderSub: '#282828',
}

const systemBackground = {

  // System Backgrounds
    light: {
      primary: '#ffffff',
      secondary: '#EFEFF4',
      tertiary: '#ffffff',
    },
    dark: {
      primary: '#000000',
      secondary: '#1C1C1E',
      tertiary: '#2C2C2E',
    },
    darkElevated: {
      primary: '#1C1C1E',
      secondary: '#2C2C2E',
      tertiary: '#3A3A3C'
    }
}

const systemGroupBackground = {
  light: {
    primary: '#EFEFF4',
    secondary: '#ffffff',
    tertiary: '#EFEFF4',
  },
  dark: {
    primary: '#000000',
    secondary: '#1C1C1E',
    tertiary: '#2C2C2E',
  },
  darkElevated: {
    primary: '#1C1C1E',
    secondary: '#000000',
    tertiary: '#2C2C2E',
    quarternary: '#3A3A3C'
  }
}

const text = {

  light: {
    primary: '#000000',
    secondary: 'rgba(60,60,67,0.6)',
    tertiary: 'rgba(60,60,67,0.3)',
    quarternary: 'rgba(60,60,67,0.18)',
    altPrimary: '#ffffff',
    altSecondary: 'rgba(255,255,255,0.8)'
  },
  dark: {
    primary: '#ffffff',
    secondary: 'rgba(235,235,245,0.6)',
    tertiary: 'rgba(235,235,245,0.3)',
    quarternary: 'rgba(235,235,245,0.18)',
    altPrimary: '#000000',
    altSecondary: 'rgba(0,0,0,0.8)'
  },
}

const textInput = {
  light: {
    primary: 'rgba(255,255,255,1)',
    secondary: 'rgba(0,0,0,0.22)',
    tertiary: 'rgba(0,0,0,0.11)'
  },
  dark: {
    primary: 'rgba(255,255,255,0.05)',
    secondary: 'rgba(255,255,255,0.11)',
    tertiary: 'rgba(255,255,255,0.22)'
  }
}

const fill = {
  light: {
    primary: 'rgba(120,120,128,0.2)',
    secondary: 'rgba(120,120,128,0.16)',
    tertiary: 'rgba(118,118,128,0.12)',
    quarternary: 'rgba(116,116,128,0.08)',
    opaque: 'rgba(40,40,48,1)',
    active: '#969696'
  },
  dark: {
    primary: 'rgba(120,120,128,0.36)',
    secondary: 'rgba(120,120,128,0.32)',
    tertiary: 'rgba(118,118,128,0.24)',
    quarternary: 'rgba(116,116,128,0.18)',
    active: 'rgba(255,255,255,0.67)',
    opaque: 'rgba(40,40,48,1)'
  }
}

const link = {
  light: {
    primary: 'blue'
  },
  dark: {
    // 📌note this is currently not being used but should be referencing the dark version of blue up above
    //primary: color.dark.blue
  }
}

const separator = {
  light: {
    opaque: '#C6C6C8',
    nonOpaque: 'rgba(60,60,67,0.29)',
    altPrimary: '#ffffff'
  },
  dark: {
    opaque: '#38383A',
    nonOpaque: 'rgba(84,84,88,65)',
    altPrimary: '#000000'
  },
}

const color = {
  light: {
    red: '#FF3B30',
    orange: '#FF9500',
    yellow: '#FFCC00',
    green: '#34C759',
    teal: '#5AC8FA',
    blue: '#007AFF',
    indigo: '#5856D6',
    purple: '#AF52DE',
    pink: '#FF2D55',
    black: '#000000',
    blueGrey: '#e9eef9',
    brandYellow: '#ffd502',
    brandBlue: '#4a90e2'
  },
  dark: {
    red: '#FF453A',
    orange: '#FF9F0A',
    yellow: '#FFD60A',
    green: '#32D74B',
    teal: '#64D2FF',
    blue: '#0A84FF',
    indigo: '#5E5CE6',
    purple: '#BF5AF2',
    pink: '#FF375F',
    black: '#000000',
    blueGrey: '#558aff',
    brandYellow: '#e0bb00',
    brandBlue: '#4a90e2'
  },
}

const systemGrey = {
  light: {
    one: 'rgba(142,142,147,1)',
    two: 'rgba(174,174,178,1)',
    three: 'rgba(199,199,204,1)',
    four: 'rgba(209,209,214,1)',
    five: 'rgba(229,229,234,1)',
    six: 'rgba(242,242,247,1)',
    seven: 'rgba(247,247,252,1)',
    iconOne: '#737473'
  },
  dark: {
    one: 'rgba(142,142,147,1)',
    two: 'rgba(99,99,102,1)',
    three: 'rgba(72,72,74,1)',
    four: 'rgba(58,58,60,1)',
    five: 'rgba(44,44,46,1)',
    six: 'rgba(28,28,30,1)',
    seven: 'rgba(12,12,16,1)',
    iconOne: '#ffffff'
  },
}

const hover = {
  light: {
    primary: 'rgba(0,0,0,0.05)',
    primaryOpaque: 'rgba(243,243,243)',
    grey: 'rgba(0,0,0,0.4)',
    blue: '#0075DB',
    blueGradient: 'linear-gradient(#6CB3FA, #057EFF)'
  },
  dark: {
    primary: 'rgba(255,255,255,0.05)',
    primaryOpaque: 'rgba(98,28,30)',
    grey: '#666666',
    blue: '#0062CC',
    blueGradient: 'linear-gradient(#1768E5, #145CCC)'
  }
}
const shortcutModal = {
  light: {
    primary: '#ebeef0',
    secondary: '#777777'
  },
  dark: {
    primary: systemBackground.dark.secondary,
    secondary: systemBackground.dark.tertiary
  },
}

const dropdown = {
  light: {
    primary: 'rgba(248,248,248)'
  },
  dark: {
    primary: 'rgba(255,255,255,0.05)'
  }
}

const button = {
  light: {
    modalText: '#000000',
    modalCancel: '#ffffff',
    modalOk: '#4a90e2'
  },
  dark: {
    modalText: '#ffffff',
    modalCancel: 'rgba(255,255,255,0.25)',
    modalOk: '#4a90e2'
  }
}

const label = {
  light: {
    primary: '#000000',
    secondary: 'rgba(60,60,67,0.6)',
    tertiary: 'rgba(60,60,67,0.3)',
    quarternary: 'rgba(60,60,67,0.18)',
  },
  dark: {
    primary: '#ffffff',
    secondary: 'rgba(235,235,245,0.6)',
    tertiary: 'rgba(235,235,245,0.30)',
    quarternary: 'rgba(235,235,245,0.18)',
  }
}

const icon = {
  light: {
    primary: '#000000',
    secondary: 'rgba(60,60,67,0.6)',
    tertiary: 'rgba(60,60,67,0.3)',
    quarternary: 'rgba(60,60,67,0.18)',
    altPrimary: '#ffffff'
  },
  dark: {
    primary: '#ffffff',
    secondary: 'rgba(235,235,245,0.6)',
    tertiary: 'rgba(235,235,245,0.30)',
    quarternary: 'rgba(235,235,245,0.18)',
    altPrimary: '#000000'
  }
}

// 🚨🚨🚨 the colors below are deprecated and will be removed in due course. it is preferable not to use these color references 🚨🚨🚨
const light = {



  // EIS
  eisChangeButtonHover: '#333',
  eisTableAddButton: '#4a90e2',
  eisTableCaption: '#666666',

  //EIS editing drawer
  saveButtonHoverText: '#424242',

  //EIS Right View
 multiTitlePartUnselected: '#f1f1f1',

  //Keyboard Shortcut Modals
  keyBorder: '#d6dadc',
  keyBorderBottom: '#e8e8e8',
}

const dark = {

  // headButton refers to the ? and ••• buttons in the minutebox panel head
  headButton: 'white',
  headButtonHover: '#2E67C1',
  headMenuItemBackground: '#1E1E1E',

  //funcational menus refer to toolbar menues i.e. filter, hide fields etc
  functionalMenuItemBackground: '#1E1E1E',

  // moreMenu refers to ToC menus (in ELV and EIS) that come from •••
  moreMenuBackground: '#1E1E1E',
  rowHoverColor: 'blue',

  //ELV tool bar icons
  toolBarIcon: 'white',

  //ELV menu item
  rootMenuItem: 'white',

  //Entity Detail View toolbar items
  toolBarIcon: 'white',
  toolBarIconBackground: '#72737A',
  toolBarIconBackgroundBorder: '#72737A',
  toolBarHelpIcon: 'white',
  toolBarHelpIconBorderLeft: 'white',
  headLogoHover: 'red',
  headLogoActive: 'blue',

  //EDV RightView
  megaIcon: 'rgba(0,0,0,0.2)',

  //EDV Context View
  contextViewSectionOverlay: 'purple',
  contextViewMarkerImage: 'red',

  // EIS
  eisCategoryLabel: brandYellow,
  eisCategoryLabelMedia: brandYellow,
  //eisDisplayAreaBackground: '#5B5E62',
  eisTextContent: '#5B5E62',
  eisTextContentFocused: 'purple',
  eisChangeButton: '#4a90e2',
  eisChangeButtonHover: 'purple',
  eisTableAddButton: 'purple',
  eisTableAddButtonHover: 'orange',


  //EIS Indicators
  indicatorText: 'black',
  indicatorFocusedText: 'blue',
  indicatorFocusedBackground: 'orange',

  //EIS editing drawer
  eisDrawerBackground: 'grey',
  saveButtonHoverBackground: 'black',
  saveButtonHoverText: 'white',
  eisDrawerInputBorder: 'white',
  eisEditingDropList: 'red',
  eisEditingDropItemBackground: 'purple',
  eisEditingDropItemText: 'white',

  //Borders
  eisDottedBorder: 'white',

  //Keyboard Shortcut Modals
  keyBackground: dmgrey.dmbg2,
  keyBorder: dmgrey.dmbg1,
  keyBorderBottom: dmgrey.dmbg1,


  //tag
  tagBackground: '#181818'
}

const Calendar = {
  dayInMonth: '#f4f4f4',
  dayAfterMonth: 'lightgray',
  dayBeforeMonth: 'lightgray',
  hover: 'rgba(255, 226, 165)',
  ticklerHover: '#ececec',
}
const dmCalendar = {
  ticklerBackground: '#1E1E1E',
  ticklerBorder: 'white',
  dayInMonth: '#24282E',
  dayAfterMonth: '#2B2E32',
  dayBeforeMonth: '#2B2E32',
  pillButtonBackgroundColor: '#1E1E1E',
  monthDayEventBackground: 'black',
  hoverColor: 'rgba(255, 255, 255,0.1)'
}

/**
 * dynamic
 *
 * This helper function creates dynamic CSS variables from the themed
 * light and dark color sets.
 *
 * To use it you pass an object (imported from this file usually) which
 * has properties named 'light' and 'dark' which each contain lists of
 * identically named colors. This function will produce CSS vars from those
 * colors which will react to 'body[dark]'
 *
 * Example using the 'systemBackground' colors (see line 50):
 *
 *   `color.dynamic(color.systemBackground, prefix)`
 *
 * returns the object:
 *
 *   {
 *     [`--${prefix}-primary`]: '#ffffff',
 *     [`--${prefix}-secondary`]: '#EFEFF4',
 *     [`--${prefix}-tertiary`]: '#ffffff',
 *
 *     'body[dark] &' : {
 *       [`--${prefix}-primary`]: '#000000',
 *       [`--${prefix}-secondary`]: '#1C1C1E',
 *       [`--${prefix}-tertiary`]: '#2C2C2E',
 *     },
 *   }
 *
 */
type ColorSet = Record<'light'|'dark'|string, Record<string, string>>
function dynamic (obj: ColorSet, prefix = "") {
  function makeVars (theme: 'light' | 'dark') {
    return Object.assign({}, ...Object.entries(obj)
      .filter(([key]) => key === theme)
      .flatMap(([key, value]) => (
        Object.entries(value)
          .map(([name, color]) => ({
            [`--${prefix}${prefix ? '-' : ''}${kebabCase(name)}`] : color
          }))
      )))
  }
  return {
    ...makeVars('light'),
    'body[dark] &' : makeVars('dark'),
  }
}

// Merge multiple dynamic color sets
function dynamicSet (sets: Record<string, ColorSet>) {
  return merge(...Object.entries(sets).map(([prefix, obj]) => dynamic(obj, prefix)))
}

export default {
  dynamic,
  dynamicSet,
  tag,

  primary: '#ffe45c',
  secondary: '#727677',
  onPrimary: '#4A90E2',
  onSecondary: '#8743aa',
  actionColor: '#3D70B2',
  hoverColor: 'rgba(255, 226, 165)',
  inputColor: '#FFFFFF',
  activeColor: '#aee9f7',
  searchHighlight: 'rgba(247,232,8,1)',
  transparentBlue: 'rgba(74,144,226,0.2)',

  // Use below for colors-to-use & naming conventions.

  secondaryButton: '#d8d8d8',
  secondaryButtonHover: '#979797',

  primaryButtonHoverBg: '#f2f2f2',

  menuBorderColor: '#dfe3e6',

  lineSeparator: '#bababa',

  rowHoverColor: '#f0f0f0',
  tocHoverColor: '#f0f0f0',
  textInputBackgroundColor: '#f2f2f2',
  switchInputBackgroundColor: '#f2f2f2',
  selectedSwitchInputBackgroundColor: '#a2a2a2',
  pillButtonBackgroundColor: '#f2f2f2',
  focusedInputBorderColor: `#a6a6a6`,

  tooltipBackground: 'rgba(72,72,72,0.95)',
  tooltipForeground: 'white',

  brandYellow,

  brand: {
    yellow: brandYellow,
    grey: grey.brand,
    gray: grey.brand
  },

  grey,
  gray: grey,
  dmgrey,
  dmgray: dmgrey,
  light,
  dark,
  dmCalendar,
  Calendar,
  systemBackground,
  text,
  separator,
  color,
  systemGrey,
  textInput,
  shortcutModal,
  fill,
  link,
  systemGroupBackground,
  hover,
  dropdown,
  button,
  label,
  icon

}
