import WritingRightViewArrayComponent from './WritingRightViewArrayComponent'

type ArrayComponentVariable = import('../ArrayComponentVariable').ArrayComponentVariable

export default class WrvvArrayComponentText<T extends ArrayComponentVariable> extends WritingRightViewArrayComponent<T> {
  get body () {
    return (
      <>
        {super.body}
      </>
    )
  }
}

WrvvArrayComponentText.register()
