
import FastPatch from 'fast-json-patch'
import { sha256digest } from 'jcrypto'
import {
  MultiProxy, JsonProxy, Uint8Proxy, CompressProxy, SuiteBCryptoProxy,
  FirestoreBlobProxy
} from 'MultiProxy'

type CrudModel = import('./CrudModel').default

const { serverTimestamp } = firebase.firestore.FieldValue

async function makeAuditRecord (model, before = {}, after = {}) {
  const patch = FastPatch.compare(before, after)
  const { uid } = model.authManager.firebaseUser()
  const changeProxy = auditChangeProxy({ privateKey: false })

  const change = await changeProxy.wrap({
    uid,
    patch,
    before: await sha256digest(before),
    after: await sha256digest(after),
    timestamp: new Date().toISOString()
  })

  // The audit can be signed by:
  //  a. the user; and
  //  b. the machine.
  const signatures = []

  return {
    change,
    signatures,
    timestamp: serverTimestamp()
  }
}

/**
 * @param {CrudModel} model
 * @param {Object} vmReprBefore values of the model before current changes
 * Create and save the audit for the given change.
 */
export default async function crudAudit (model: CrudModel, vmReprBefore, after) {
  const record = await makeAuditRecord(model, vmReprBefore, after)
  try {
    await model.vmFirestoreDoc()
      .collection('audit')
      .add(record)
  } catch (err) {
    console.error(`
      crudAudit
         ${model.constructor.name}:${model.id()}
         at: ${model.vmFirestoreDocPath}
         Cannot save audit.
    `, err)
    if (window.Sentry) { window.Sentry.captureException(err) }
  }
}

export function auditChangeProxy ({ privateKey }) {
  return new MultiProxy(
    new JsonProxy(),
    new Uint8Proxy(),
    new CompressProxy(),
    new SuiteBCryptoProxy({ privateKey }),
    new FirestoreBlobProxy()
  )
}
