/**
 * DataModel is the base class for storing, editing, and retrieving information.
 *
 * It manages the observables, change tracking, and data-side requests such
 * as saving.
 *
 * This class is based on ModelView from Conductor/Nassau.
 */
import { uuidv4 } from 'utils/string'
import {
  isObject, isEmpty, cloneDeep, isEqual
} from 'lodash-es'

import EventEmitter from 'EventEmitter'

import { isEqualModel } from './isEqualModel'
import ModelCache from './ModelCache'
import {
  keyPathForModel, keyPathForCollection
} from 'utils/firestore'

import {
  PlainMediator, ReadOnlyMediator, LastModifiedMediator,
} from './mediators'

type MediatorInterface = import('./mediators/MediatorInterface').default<any, any>

const modelCaches = new WeakMap()
const { FieldValue } = window.firebase.firestore

export default class DataModel extends EventEmitter {
  private _authManager: import('auth').AuthManager
  private _vmFirestoreUnsubscribe: () => void | null
  private _vmKey: [string, string]
  private transactionsSeen: Set<string> = new Set<string>()
  private vmMediatorsList: MediatorInterface[]

  accountID: KnockoutObservable<string>
  created: KnockoutObservable<string | Date>
  createdByUid: KnockoutObservable<string>
  deleted: KnockoutObservable<boolean>
  id: KnockoutObservable<string>
  modified: KnockoutObservable<string | Date>
  vmAssigningValues: KnockoutObservable<boolean>
  vmIsLoaded: KnockoutObservable<boolean>
  vmIsSaving: KnockoutObservable<boolean>
  vmLastRefreshed: Date
  vmLastSaved: KnockoutObservable<Date>
  vmResaveRequest: boolean
  vmSavedRepr: KnockoutObservable<any>
  vmSavePromise: Promise<void> | null

  get serverModelName () { return this.constructor.serverModelName }
  get vmResourceName () { return this.constructor.vmResourceName }
  static get serverModelName () { return this.name.replace('Model', '') }
  static get vmResourceName () { return this.serverModelName.toLowerCase() }

  static get SAVE_INTERVAL () { return 100 }
  static get MAX_RETRIES () { return 10 }

  constructor (possibleValues, authManager) {
    super()
    // console.log(`[+] ${this.constructor.name}`)

    if (!possibleValues) {
      throw new Error(`
        DataModel constructor requires either an object or the 'true' value
        for a new model.
      `)
    }

    this.vmMediatorsList = this.vmCreateMediatorInstances()

    Object.assign(this, {
      _authManager: authManager,
      vmIsSaving: ko.observable(false),
      vmIsLoaded: ko.observable(false),
      vmHasValuesAssigned: ko.observable(false),
      vmSavedRepr: ko.observable(),
      vmLastSaved: ko.observable(),
      vmAssigningValues: ko.observable(false),
      // We add exach mediator to this class instance, referenceable by
      // its name i.e. { name: mediator-instance, ... }
      ...this.vmMediatorsByName()
    })

    this.vmDefineProperties()

    if (isObject(possibleValues) && !isEmpty(possibleValues)) {
      this.vmAssignValues(possibleValues)
    } else if (possibleValues === true) {
      this.accountID(authManager && authManager.accountID())
      this.vmOnLoad()
    }

    this._dependencies = this.vmAwaitDependencies()
  }

  /**
   * Manages the credentials needed to make server requests.
   * @param {AuthManager} authManager
   */
  set authManager (authManager) { this._authManager = authManager }
  get authManager () { return this._authManager }

  /**
   * Return the per-authManager cache (since each auth manager
   * will correspond to a separate server/set of resources)
   */
  get vmCache () { return this.constructor.vmCacheFor(this.authManager) }
  static vmCacheFor (authManager) {
    if (!modelCaches.has(authManager)) {
      modelCaches.set(authManager, new ModelCache(authManager))
    }
    return modelCaches.get(authManager)
  }

  async vmAwaitDependencies () {
    const dependencies = this.vmDependencies || []
    await this.vmHasValuesAssigned.when(true)
    for (const dependency of dependencies) {
      await DataModel.vmDependencyAsPromise(dependency)
    }
    return this.vmOnLoad()
  }

  /**
   * Track when this dependency has completed.
   * @param {varies} dep
   */
  static async vmDependencyAsPromise (dep) {
    if (dep.fk) {
      if (dep.fk.is_defined()) { return dep.fk.promise_model() }
      return
    }
    // ForeignKeyMap
    if (dep.fkm) { return dep.fkm.is_map_loaded.when(true).catch(eraro) }
    if (dep.is_loaded) { return dep.is_loaded.when(true) }
    if (dep.when_loaded) { return dep.when_loaded.catch(eraro) }
    if (dep.then) { return dep }
    console.log("DataModel.vmDependencyAsPromise - Bad dependency", dep)
    eraro.raise(new Error("DataModel.vmDependencyAsPromise - Bad dependency."))
  }

  /**
   * Called when the dependencies are loaded.
   */
  vmOnLoad () {
    this.vmSavedRepr(cloneDeep(this.vmCurrentRepr()))
    this.vmIsLoaded(true)
    this.emit('load')
    Promise.all([
      this.id.yet(undefined),
      this.accountID.yet(undefined),
    ]).then(() => this.vmStartMonitoringSnapshots())
    return this
  }

  /**
   * Monitor Firestore for updates to this model.
   *
   * We ignore the first call since it can break assignments
   * that occur after loading but before this snapshot completes.
   */
  vmStartMonitoringSnapshots () {
    if (this._vmFirestoreUnsubscribe) { return }
    let firstCall = true
    this._vmFirestoreUnsubscribe = this.vmFirestoreDoc()
      .onSnapshot(
        snap => {
          if (!firstCall) { this.vmOnSnapshot(snap) }
          firstCall = false
        },
        err => this.vmOnSnapshotError(err, this.vmFirestoreDoc())
      )
  }

  vmOnSnapshotError (err, doc) {
    console.error(`
      [${this.constructor.name}:${this.id()} at ${this.vmFirestoreDocPath}] Snapshot cancelled:'
    `, this, doc, err)
  }

  vmStopMonitoringSnapshots () {
    if (!this._vmFirestoreUnsubscribe) { return }
    this._vmFirestoreUnsubscribe()
    this._vmFirestoreUnsubscribe = null
  }

  /**
   * @param {snap} firebase.firestore.DocumentSnapshot
   */
  vmOnSnapshot (snap) {
    const data = snap.data()
    if (!data) { return }
    const { uniqueTransactionID } = data
    if (uniqueTransactionID && this.transactionsSeen.has(uniqueTransactionID)) {
      return
    }
    const saved = this.vmSavedRepr()
    const current = this.vmCurrentRepr()
    const patch = Object.assign({},
      ...this.vmGenSnapshotPatch(snap.data(), saved, current)
    )
    // console.debug(`Updating snapshot:\n`, snap, `\n  🚜  Patch:\n`, patch)
    this.vmAssignValues(patch)
  }

  /**
   * This is the conflict resolution algorithm.
   *
   * Right now all differences to the last saved version will be overwritten,
   * including any changes by the current user.  Alternative strategies include
   * ignoring the changes that conflict with what the current user has entered,
   * or prompting the user to reconcile them.
   *
   * The advantage of overwriting is that it makes the conflict explicit, and
   * the user whose changes are overwritten is likely best positioned to
   * resolve the conflict.
   *
   * A better long-term solution would be some sort of conflict resolution.
   *
   * @param {object} newData the values from Firestore
   * @param {object} saved the values since the last save/snaphsot
   * @param {object} current the values modified by the user since the last save
   */
  * vmGenSnapshotPatch (newData, saved, current) {
    if (!newData) { return } // delete
    for (const [key, value] of Object.entries(newData)) {
      const unchanged = isEqual(saved[key], value)
      if (unchanged) { continue }
      yield { [key]: value }
    }
  }

  /**
   * We get the model name by class_name convention.
   * The key returned from here will be strictly equal to successive and
   * future calls from this model.
   * @return {[string, string]} the (ndb-flat) key for this model
   *  i.e. ["ModelName", "id"].
   */
  get vmDatabaseKeyPair () {
    return this._vmKey || (this._vmKey = this.vmMakeKeyPair())
  }

  /**
   *
   */
  // This may be overloaded.  When used for informational purposes, the
  // vm_make_key should be used; when used for strict identification, then
  // `vm_key` should be used, since it always returns the same element.
  vmMakeKeyPair () : [string, string] {
    if (this.id() === undefined) {
      throw new Error('vmMakeKey called before `id` was set.')
    }
    return [this.serverModelName, this.id()]
  }

  /** May be overloaded */
  vmIsReadyToSave () { return true }

  * vmGenMediatorHolders () {
    for (const mediator of this.vmMediatorsList) {
      yield { [mediator.name]: mediator.newHolderInstance(this) }
    }
  }

  vmMediatorsByName () {
    return Object.assign({}, ...this.vmGenMediatorHolders())
  }

  /**
   * Create instances of mediators for this model.
   */
  vmCreateMediatorInstances () {
    return [
      new PlainMediator('accountID', this),
      new PlainMediator('deleted', this),
      new PlainMediator('id', this),
      new ReadOnlyMediator('created', this),
      new ReadOnlyMediator('createdByUid', this),
      new LastModifiedMediator('modified', this),
    ]
  }

  /**
   * Called after construction and mediators are assigned, but
   * before values are assigned.
   */
  vmDefineProperties () {}

  vmUpdateRepr () {
    // deprecated
    console.log(`Use vmCurrentRepr instead of vmUpdateRepr`)
    return this.vmCurrentRepr()
  }

  * vmGenMediatorValues (defaults) {
    for (const mediator of this.vmMediatorsList) {
      if (mediator.ignore) { continue }
      try {
        const value = mediator.toJS() || defaults[mediator.name]
        if (value === undefined) { continue }
        yield { [mediator.name]: value }
      } catch (err) {
        console.error(`[${this.constructor.name}::*vmGenMediatorValues]
          Exception with "${mediator.name}" toJS: ${err}
        `)
        throw err
      }
    }
  }

  /**
   * Return an object with the current state of the model.
   *
   * @return {Object} Current state of the model.
   */
  vmCurrentRepr () {
    if (!this.vmIsReadyToSave()) {
      // log "\t\t 🌘  Not saving #{@vm_key()} because stage is not complete."
      return this.vmSavedRepr.peek()
    }

    return Object.assign(
      { last_modified_by: this.getUid() },
      ...this.vmGenMediatorValues(this._vmModelDefaults),
      this.vmSerialize())
  }

  /**
   * This is a workaround for:
   *  https://github.com/firebase/firebase-functions/issues/300
   *
   * The last-modified-by ought to be done by the server.
   */
  getUid () {
    try {
      return this.authManager.firebase.auth().currentUser.uid
    } catch (err) {
      // testing
      return null
    }
  }

  /**
   * @return {CollectionReference} from firestore
   */
  static vmFirestoreCollection (authManager) {  // 🏤
    throw new Error(`This should not longer be called.`)
  }

  /**
   * @return {firestore.DocumentReference} from firestore
   */
  static vmFirestoreDoc (authManager, idOrKey, accountID) {  // 🏤
    throw new Error(`This should not longer be called.`)
  }

  static vmFirestoreDocPath (firestoreKey, accountID) {  // 🏤
    throw new Error(`This should not longer be called.
      Use 'keyPathForModel(accountID, entityData.id, [MODEL].vmResourceName)'
    `)
  }

  /**
   * Return parts that form part of the key-chain e.g. for
   *    `/accounts/demo/entity/abc/writing/123`
   * the intra-parts are
   *    `['entity', 'abc']`
   */
  protected get vmFirestoreIntra (): string[] {
    return [this.vmResourceName]
  }

  get vmFirestoreDocPath () {
    return keyPathForModel(this.accountID(), this.id(), ...this.vmFirestoreIntra)
  }

  /**
   * @return {CollectionReference} from firestore
   */
  vmFirestoreCollection () {
    const path = keyPathForCollection(this.accountID(), ...this.vmFirestoreIntra)
    return this.authManager.firestore.collection(path)
  }


  /**
   * @return {firestore.DocumentReference} from firestore
   */
  vmFirestoreDoc () {
    return this.authManager.firestore.doc(this.vmFirestoreDocPath)
  }

  /**
   * @param {AuthManager} authManager
   * @param {firestore.DocumentReference} docRef for the document
   */
  static vmUniqueFirestoreURI (authManager, docRef) {
    const uid = authManager.firebaseUser().uid
    const path = encodeURIComponent(docRef.path)
    return `firestore://${uid}@${authManager.projectID}/${path}`
  }

  /**
   * @return {string} unique to this model.
   */
  get vmUniqueFirestoreURI () {
    return this.constructor.vmUniqueFirestoreURI(
      this.authManager, this.vmFirestoreDoc())
  }

  private static _vmModelDefaults (authManager) {
    const { firebase } = window
    const user = authManager && authManager.firebaseUser()
    const userUid = user ? user.uid : null
    const accountID = authManager && authManager.accountID()
    return {
      accountID,
      created: FieldValue.serverTimestamp(),
      createdByUid: userUid,
      ownedByUid: userUid,
    }
  }

  private get _vmModelDefaults () {
    return this.constructor._vmModelDefaults(this.authManager)
  }

  /**
   * Create a new DataModel instance.
   * @param {AuthManager} authManager
   * @param {object} opts data for the model
   * @param {string} id of the model, or falsy to create a new id.
   */
  static async vmCreate (authManager, opts, firestoreKey = null) {
    let docRef
    Object.assign(opts, this._vmModelDefaults(authManager))
    if (!firestoreKey && opts.id) {
      throw new Error(`DataModel.vmCreate cannot have opts.id without firestoreKey`)
    }
    try {
      if (firestoreKey) {
        const docSnap = await authManager.firestore.doc(firestoreKey).get()
        docRef = docSnap.ref
        await authManager.firestore.doc(firestoreKey).set(opts)
      } else {
        docRef = await authManager.firestoreCollection(this.vmResourceName).add(opts)
      }
    } catch (err) {
      console.error(`
        [${this.constructor.name}:${opts.id}]: Cannot vmCreate:
      `, err)
      throw err
    }
    const id = docRef.id
    this.vmStatusReport(`Created (id: ${id}).`)
    return this.vmGet(authManager, docRef.path)
  }

  /**
   * Get the model with the given ID of this entity type.
   * @param {AuthManager} authManager
   * @param {string} firestoreKey e.g. /accounts/{accountID}/users/{userID}
   * @return {DataModel}
   */
  static async vmGet (authManager, firestoreKey) {
    const cache = this.vmCacheFor(authManager)
    const cachedModel = await cache.get(this, firestoreKey)
    if (cachedModel) { return cachedModel }
    try {
      const doc = await authManager.firestore.doc(firestoreKey).get()
      if (!doc.exists) {
        throw new Error(`Document at ${firestoreKey} does not exist.`)
      }
      const data = {
        id: firestoreKey.split('/').pop(),
        ...doc.data()
      }
      const model = new this(data, authManager)
      await model.vmAssigningValues.yet(true)
      model.vmAddToCache()
      return model
    } catch (err) {
      return this.vmOnGetError(err, firestoreKey, authManager)
    }
  }

  static vmOnGetError (err, firestoreKey, authManager) {
    console.error(`DataModel.vmGet(${firestoreKey}) failed:`, err)
    throw err
  }

  /**
   * Get a model, or create it if it doesn't exist.
   * @param {AuthManager} authManager
   * @param {string} firestoreKeyPath
   * @param {Object} optsForCreate initial values when creating (nothing is set
   * if the object exists)
   */
  static async vmGetOrCreate (authManager, firestoreKeyPath, optsForCreate = {}) {
    const docRef = await authManager.firestore.doc(firestoreKeyPath).get()
    return docRef.exists
      ? this.vmGet(authManager, firestoreKeyPath)
      : this.vmCreate(authManager, optsForCreate, firestoreKeyPath)
  }

  async vmDelete () {
    return this.vmFirestoreDoc().delete()
  }

  /**
   * The ModelType that is to be used for the given data.
   * @param {Object} data
   */
  static vmTrueModelType (data) { return this }

  /**
   * @return {Boolean} True when the model has changes that ought to
   * be saved
   */
  vmIsModelChanged () {
    if (!this.vmIsLoaded()) { return false }
    const latest = this.vmCurrentRepr()
    return !isEqualModel(latest, this.vmSavedRepr())
  }

  /**
   * Save the model, creating if necessary
   * @return {DataModel} this
   */
  async vmSave (onlyIfChanged = false) {
    if (this.vmSavePromise) {
      if (this.vmIsModelChanged()) { this.vmResaveRequest = true }
      return this.vmSavePromise
    }

    await this.vmIsLoaded.when(true)
    await this.vmAssigningValues.when(false)

    // Don't save until we've selected an account.
    await this.accountID.when(act => act)
    const isModelChanged = this.vmIsModelChanged()

    if (onlyIfChanged && !isModelChanged) { return }

    this.vmIsSaving(true)

    const reprToSave = this.vmCurrentRepr()

    try {
      return await (this.vmSavePromise = this.vmSaveStart(reprToSave))
    } catch (err) {
      console.error(`Error saving ${this.vmFirestoreCollection().path}[${this.id()}]:`,
        err, '\n\t\tData:\n', reprToSave)
      await this.vmOnSaveFailure(err)
    } finally {
      this.vmSavePromise = null
    }
  }

  /**
   * @param {object} reprValues JSON-able values that we convert with
   *  async transforms such as those in ObjectProxy.
   */
  async vmCurrentReprToSave (currentRepr = this.vmCurrentRepr()) {
    const uniqueTransactionID = uuidv4()
    const reprClone = Object.assign({
      uniqueTransactionID
    }, currentRepr)
    this.transactionsSeen.add(uniqueTransactionID)

    for (const mediator of this.vmMediatorsList) {
      if (mediator.ignore) {
        delete reprClone[mediator.name]
        continue
      }
      const value = await mediator.serialize()
      if (value === undefined || typeof value === 'symbol') {
        delete reprClone[mediator.name]
        continue
      }
      reprClone[mediator.name] = value
    }

    return reprClone
  }

  async vmSaveStart (repr) {
    const reprToSave = await this.vmCurrentReprToSave(repr)
    reprToSave.modified = FieldValue.serverTimestamp()
    if (!this.created()) {
      reprToSave.created = FieldValue.serverTimestamp()
    }

    if (!this.id()) {
      const docRef = await this.vmFirestoreCollection().add(reprToSave)
      this.vmStatusReport(`Created (id: ${docRef.id}).`)
      this.id(docRef.id)
      await docRef.update({ id: docRef.id })
      repr.id = docRef.id
      this.emit('create', this)
    } else {
      try {
        await this.vmFirestoreDoc().set(reprToSave, { merge: true })
      } catch (err) {
        console.error(`Error saving: `, err, '\n\n\t', repr, reprToSave)
        throw err
      }
      this.modified(new Date())
    }

    this.vmStatusReport(` 🌱   Saved.`)

    this.vmLastSaved(new Date())
    this.vmSavedRepr(cloneDeep(repr))
    this.vmLastRefreshed = new Date()

    this.emit('save', this)

    if (this.vmResaveRequest) {
      this.vmResaveRequest = false
      await this.vmSaveStart(this.vmCurrentRepr())
    }

    this.vmIsSaving(false)
    this.constructor.onSave(this)

    return repr
  }

  async vmOnSaveFailure (reason) {
    const delay = 10000
    this.vmStatusReport(`
       🥀  Unable to save: ${reason}.  Retrying in ${delay}.
    `)
    this.vmSavePromise = null
    if (reason.code === 'permission-denied') {
      throw new Error(`Unable to save: Permission denied.`)
    } else {
      await Promise.delay(delay)
      await this.vmSave()
    }
  }

  vmSaveAll () { return DataModel.vmSaveAll() }

  //
  //   vmAssignValues
  //   ~~~~~~~~~~~~~~~~
  //
  // Copy the values from 'data' for this model's observables.
  // Usually vmAssignValues would be called by `vmLoad`, but it could
  // also come from e.g. the clientSearch binding
  //
  async vmAssignValues (dataOrFuture) {
    const data = await dataOrFuture
    this.transactionsSeen.add(data.uniqueTransactionID)
    this.vmAssigningValues(true)
    const mediatorFutures = []
    try {
      for (const mediator of this.vmMediatorsList) {
        if (mediator.name in data) {
          mediatorFutures.push(mediator.fromJS(data[mediator.name]))
        }
      }
      await Promise.all(mediatorFutures)
      this.vmHasValuesAssigned(true)
    } catch (err) {
      console.error(`${this.constructor.name} [${this.id()}] Error: ${err}.\n`, err)
      throw err
    }

    if (data.modified) { this.vmLastSaved(new Date(data.modified)) }

    this.vmDeserialize(data)
    this.vmSavedRepr(cloneDeep(this.vmCurrentRepr()))
    this.vmLastRefreshed = new Date()
    this.vmAssigningValues(false)
  }

  //   vmHandleCollision
  //   ~~~~~~~~~~~~~~~~~
  // How to handle a collision i.e. the model is already loaded but
  // we are now getting new data from the server (or elsewhere).
  // This may be overloaded, for those cases where collisions are treated
  // differently from loading.
  vmHandleCollision (data) {
    const thisIsNewer = ko.unwrap(data.modified) <= ko.unwrap(this.modified)
    if (this.vmIsModelChanged() || thisIsNewer) {
      return this
    }
    this.vm_assign_values(data)
    return this
  }

  // vmReload
  // ~~~~~~~~
  //
  // This will almost invariablly (unless the model is removed from the
  // cache) call vm_handle_collision
  //
  // reload_count serves two purposes:
  //   1. it tells the server how many times we've requested this model,
  //   which may be useful for the server; and
  //   2. it forces AJAX to make another request (otherwise it
  //   just uses the cached version)
  vmReload () { return this.vmCache.reload(this) }

  //     vm_add_to_cache
  //     ~~~~~~~~~~~~~~~
  //
  //     Add the current model to the cache. Used in testing.
  //
  vmAddToCache () {
    return this.vmCache.insert(this)
  }

  //   Refresh
  //   ~~~~~~~
  // Refresh the model if the modified date is > STALE_DURATION milliseconds ago.
  static vmRefreshIfStale (model) {
    if (model && model.modified && model.vm_is_stale()) {
      return this.vmCache.reload(model)
    }
    return model
  }

  /**
   * Overload with serializable properties that aren't part of the model.
   */
  vmSerialize () { return {} }
  vmDeserialize (data) {}

  toJS () { return this.vmCurrentRepr() }

  /**   LOGGING    **/
  vmStatusReport (msg, level='info') {
    this.constructor.vmStatusReport(msg, this.id && this.id(), level)
  }

  static vmStatusReport (msg, id, level='info') {
    Sentry.addBreadcrumb({
      data: { msg, id: id || '*', level },
      category: 'DataModel',
    })
  }

  /**
   * Register the current model.
   */
  static register () {
    DataModel.MODEL_MAP[this.vmResourceName] = this
  }

  static onSave () {  /* no-op unless overloaded */}
}

DataModel.MODEL_MAP = {}

/** Keep track of models that are currently in the process of saving. */
DataModel.vmModelsSaving = new Set()
