/**
 * Serialize an object with JSON to a string.
 */
import ObjectProxy from './ObjectProxy'

export default class JsonProxy extends ObjectProxy {
  static get proxyPropertyName () { return 'J' }
  wrapValue (object) { return JSON.stringify(object) }
  static unwrapValue (string) { return JSON.parse(string) }
}
