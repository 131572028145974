import { Transaction, CODES } from './Transaction'

/**
 * A `TransactionUnsupported` is a transaction whose type is not yet
 * handled by this system.    It should be idempotent saving/loading
 * and deletable.
 */
export class TransactionUnsupported extends Transaction {
  params: any;
  code: CODES;
  toJS () { return this.params }
  get propertiesToSerialize () { return [] }
  * yieldActions () { }
  constructor (params) {
    // console.warn(` 🚧  TransactionUnsupported: ${params.code} `, params)
    super(params)
    Object.assign(this, { params, code: params.code })
  }
}
