
import ViewComponent from 'ViewComponent'

import { list, color } from 'styles'

import LeftPanelSection from './LeftPanelSection'

const NEG_V_OFFSET = -100
const TOP_MARGIN = 100

class SectionListPanelLeft extends ViewComponent {
  sections: LeftPanelSection[] | KnockoutObservableArray<LeftPanelSection>

  constructor ({ sections }) {
    super()
    Object.assign(this, { sections })
  }

  static get css () {
    return {
      ...super.css,
      ...list.grippy,
      listItem: {
        ...list.grippy.listItem,
        margin: '1px',
        marginLeft: 20,
        fontWeight: '400',
        '&[visible=true]': {
          backgroundColor: color.tocHoverColor,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary, // 🔥
          },
          borderRadius: '10px'
        },
        '[visible=true] + &[visible=true]': {
          backgroundColor: 'inherit',
          'body[dark] &': { // project batman
            backgroundColor: 'inherit',
          },
        }
      },
      title: {
        ...list.grippy.title,
        padding: '6px 0px'
      },
      grip: {
        ...list.grippy.grip,
        display: 'none'
      }
    }
  }

  sectionGroupHTML (section : LeftPanelSection) {
    const { jss } = this
    const click = evt => {
      section.scrollTo(NEG_V_OFFSET)
      section.scrollTo(-TOP_MARGIN)
      if (section.onSelect) { return section.onSelect(evt) }
    }

    return (
      <div class={jss.listItem} visible={section.visible}>
        <div class={jss.grip} />
        <div class={jss.hoverable}
          ko-ownClick={evt => click(evt)}>
          <div class={jss.title}>{section.title}</div>
        </div>
      </div>
    )
  }

  get listItems () {
    if (!this.sections) { return [] }
    return this.sections
      .filter(s => s.fields().length)
      .map(s => this.sectionGroupHTML(s))
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.list}>
        {this.listItems}
      </div>
    )
  }
}

SectionListPanelLeft.register()
