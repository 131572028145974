//
// ArrayMediator
// ---
// Serialize and deserialize an observable array of plain values.
//
import MediatorInterface from './MediatorInterface'

export default class KeyArrayMediator extends MediatorInterface {
  constructor (name, model, View) {
    super(name, model)
    Object.assign(this, {View})
  }
  newHolderInstance () {
    return ko.observableArray([]).extend({ foreignKeyMap: this.View })
  }
  toJS () { return this.model[this.name]() }
  fromJS (val) {
    if (!Array.isArray(val)) { return }
    this.model[this.name](val)
  }
}
