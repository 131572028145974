import Picker from 'picker'

import { tCurrency, currencyList } from './currency'

/**
 * The `<currency-picker>` mimics the `jurisdiction-picker` in many ways.
 * Look to that for further details.
 */
export default class CurrencyPickerBase extends Picker<tCurrency> {
  choices () { return currencyList }
  itemHTML (item: tCurrency) {
    const { jss } = this
    return (
      <>
        <div class={jss.name}>{item.name}</div>
        <div class={jss.symbol}>{item.symbol}</div>
        <div class={jss.code}>{item.code}</div>
      </>
    )
  }

  itemAsString (item) { return item.code || item }

  filterChoice (item, query) {
    const q = query.toLowerCase()
    return item.name.toLowerCase().includes(q)
      || item.code.toLowerCase().includes(q)
  }

  static get css () {
    return {
      ...super.css,
      inputAndCurrency: {
        display: 'flex',
      },
      item: {
        ...super.css.item,
        display: 'grid',
        gridTemplateColumns:'1fr auto auto auto'
      },
      name: {
      },
      code: {
        padding: '0px 8px',
        color: '#aaa',
      },
      symbol: {
        padding: '0px 8px',
        color: '#aaa',
      },
    }
  }
}

