// import ModelListOptions from 'model-list/ModelListOptions'
import 'views/action-menu'
import { Column } from 'Column'
import { theme, buttons } from 'styles'
import ConfirmModelDelete from 'confirm-delete/confirm-model-delete'
import ModelList from 'ModelList'
import ModelListOptions from 'model-list/ModelListOptions'
import WritingModel from 'writing/WritingModel'
import WritingPanelProvider from 'writing/WritingPanelProvider'
import { inline } from 'icons'
import newDocIcon from 'icons/light/plus-square'

import {
  writingListColumns, writingActions,
} from './writingListColumns'

import { MainView } from './MainView'

type MinuteBookPanelProvider = import('MinuteBookPanelProvider').default
type EntityModel = import('EntityModel').default

export class EntityDocumentsView extends MainView {
  listOptions: ModelListOptions = new ModelListOptions(this.possibleColumns, 'EntityWritingList')
  list: ModelList<EntityModel>
  hoverRow: KnockoutObservable<WritingModel>

  constructor (mbpp: MinuteBookPanelProvider) {
    super(mbpp)
    const { authManager } = mbpp.model
    const query = mbpp.model.vmFirestoreDoc().collection('writing')

    Object.assign(this, {
      list: new ModelList(WritingModel, authManager, query),
      hoverRow: ko.observable(),
    })
  }

  dispose () {
    super.dispose()
    this.list.dispose()
  }

  docActions (row: WritingModel) {
    const del = {
      label: 'Delete ...', action: () => ConfirmModelDelete.open(row),
    }
    return ( <action-menu actions={[del]} /> )
  }

  get possibleColumns (): Column[] {
    return [
      ...writingListColumns(this),
    ]
  }

  get commandName () { return 'navDocuments' }

  get title () { return 'Documents' }

  newWriting (evt) {
    this.panelProvider.commandSet.commands.newWriting.trigger(evt)
  }

  selectEntity (model) {
    const app = window.app
    app.setPanelProvider(WritingPanelProvider, { app, model })
  }

  static get css () {
    return {
      ...super.css,
      toolBar: {
        composes: [theme.primaryColor],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '3px double gray',
        minHeight: '45px',
      },
      newButton: {
        ...buttons.clickable,
        padding: '0px 10px',
        marginLeft: 'auto',
      },
    }
  }

  get toolbarHTML () {
    const { jss } = this
    return (
      <div class={jss.toolBar}>
        {/* model-list-options
      options={this.listOptions}
      savedFilters={this.filtersModelList.list}
      /> */}

        <div class={jss.newButton}
          ko-ownClick={evt => this.newWriting(evt)}>
          {inline(newDocIcon)}{' '}
          New Document
        </div>
      </div>
    )
  }

  get mainHTML () {
    const { jss } = this
    const columns = ko.observableArray(this.possibleColumns)
    return (
      <>
        {this.toolbarHTML}
        <model-list
          style='--model-list-offset-top: 59px'
          storageID={`panel.EntityWritingList`}
          sorters={ko.observableArray([])}
          columns={columns}
          hits={this.list.list}
          onSelect={e => this.selectEntity(e)}
          contextMenuForRow={row => this.actionsContentHTML(row)}
          hoverRow={this.hoverRow}
        />
      </>
    )
  }

  actionsContentHTML (row) {
    return <action-menu-items actions={writingActions(row)} />
  }
}
