//
// ClassArrayMediator
// ---
// Serialize and deserialize an array of Classes.
//
// Classes are expected to have a `toJS` instance. They ought to be
// instantiable with the params returned from `toJS`, isomorphically.

import {
  invokeMap, map
} from 'lodash-es'

import MediatorInterface from './MediatorInterface'

const {ko} = global

export default class ClassArrayMediator extends MediatorInterface {
  constructor (name, model, Constructor) {
    super(name, model)
    this.Constructor = Constructor
  }

  newHolderInstance () {
    const array = ko.observableArray([])
    array.mediator = this
    return array
  }

  toJS () {
    return invokeMap(this.model[this.name](), 'toJS')
  }

  fromJS (vals) {
    this.model[this.name](
      map(vals, params => new this.Constructor(params, this.model)))
  }

  add (data) {
    this.model[this.name].push(new this.Constructor(data, this.model))
  }
}
