/**
 * MinuteBox is the top-level Knockout component.
 */
import { getUnixTime, fromUnixTime } from 'date-fns/esm'

import PanelProvider from 'PanelProvider'
import ViewComponent from 'ViewComponent'
import {
  AuthManager, getPossibleAuthManagers,
} from 'auth'
import MemoryDB from 'MemoryDB'

// Knockout components
import 'back-button'
import 'model-list'
import 'models'
import 'notification-manager'
import 'shortcut-help'
import 'tool-tip/q-tip'
import 'tool-tip/command-area'
import Notifier from 'notification-manager/Notifier'
import OutcomeNotification from 'notification-manager/outcome-notification'
import 'utils/promises'
import { typography, color, buttons, highlights } from 'styles'
import { chosen } from 'styles/theme'
import { memoize } from 'utils/decorator'
import errorIcon from 'icons/solid/exclamation-triangle'
import { windowScrollPosition, whenHeightStabilizes } from 'utils/dom'
import { PersonListPanelProvider, PersonPanelProvider } from 'person/view-components'
import {PrecedentListPanelProvider} from 'writing/precedents'
import { WritingPanelProvider, PrecedentPanelProvider } from 'writing'
import { possiblyShowModalOnLogin } from 'first-login-modals'
import { Transition, all as Transitions } from 'transitions'
import MinuteBookPanelProvider from 'MinuteBookPanelProvider'
import t from 't'

import AccountPanelProvider from './AccountPanelProvider'
import AppLoadingPanelProvider from './AppLoadingPanelProvider'
import CalendarPanelProvider from './CalendarPanelProvider'
import EntityListPanelProvider from './EntityListPanelProvider'
import InvitePanelProvider from './InvitePanelProvider'
import LoggedOutPanelProvider from './LoggedOutPanelProvider'
import SecurityPanelProvider from './SecurityPanelProvider'
import TermsOfServicePanelProvider from './TermsOfServicePanelProvider'
import UserLoginPanelProvider from './UserLoginPanelProvider'
import UserPanelProvider from './UserPanelProvider'
import DisplaySettingsPanelProvider from './DisplaySettingsPanelProvider'

import './about-minutebox'

const { ko } = window

const scrollPositions = new Map<string, number>()

export default class MinuteBoxApp extends ViewComponent {
  authManagers: AuthManager[]
  modal: KnockoutObservable<any>
  memoryDB: MemoryDB
  notifier: Notifier
  panelProvider: KnockoutObservable<PanelProvider>
  priorPanelProvider: PanelProvider
  panelProviderLock: KnockoutObservable<Boolean>
  showDragPad: KnockoutObservable<boolean>
  theme: KnockoutObservable<string> = chosen
  t = t

  constructor (...params) {
    super()

    // Assign for debugging.
    window.app = this
    const historyState = panelProvider => panelProvider.historyUrl

    this.panelProviderLock = ko.observable(false)

    Object.assign(this, {
      authManagers: getPossibleAuthManagers(),
      entityId: ko.observable(),
      panels: ko.observableArray([]),
      panelProvider: ko.observable().extend({ historyState, lockWhile: this.panelProviderLock }),
      modal: ko.observable(),
      notifier: new Notifier(),
      authManagersLoaded: ko.observable(),
    })

    this.setBodyVars()

    this.showDragPad = this.computed(() => this.panelProvider() &&
      this.panelProvider().overlay &&
      this.panelProvider().panelDragOverEvent())

    this.subscribe(this.panelProvider, pp => this.onPanelProviderChange(pp))
    this.setPanelProvider(AppLoadingPanelProvider)

    this.firebaseUserChangeSubscription = this.defaultAuthManager.firebaseUser
      .subscribe(u => this.onFirebaseUserChange(u))

    this.demandTermsOfServiceAgreement()

    this.defaultAuthManager.userFirebaseClaims
      .yet(undefined)
      .then(claims => this.noteFirebaseChangeWithSentry(claims))

    this.memoryDB = new MemoryDB(this.authManagers)

    this.modal.subscribe(m => this.onModalChange(m))

    this.computed(() => (document.title = this.windowTitle))

    const scrollKey = this.computed<string>(() => this.panelProvider().scrollKey)
    let watchScroll = null
    this.computed(() => {
      if (watchScroll) { watchScroll.dispose() }
      const scrollY = scrollPositions.get(scrollKey()) || 0
      whenHeightStabilizes(document.body).then(() => {
        window.scrollTo(0, scrollY)
        watchScroll = windowScrollPosition.subscribe(v => {
          scrollPositions.set(scrollKey(), v)
        })
      })
    })

    document.body.classList.add(this.jss.body)
  }

  /**
   * @return {String} to be used for `document.title`
   */
  get windowTitle () {
    const title = ko.unwrap(this.panelProvider().panelWindowTitle)
    return [title, `MinuteBox`].join(' — ')
  }

  /**
   * To sign out, we de-authenticate from every AuthManager's Firebase instance,
   * then we re-load since that is the only way at-present to clear the memoryDB.
   */
  async signOut () {
    document.cookie = '__session=; Domain=.minutebox.com; Max-Age=0;';
    document.cookie = '__session=; Domain=.minutebox.xyz; Max-Age=0;';

    this.memoryDB.dispose()
    await Promise.all(this.authManagers.map(am => am.firebase.auth().signOut()))
    location.hash = LoggedOutPanelProvider.urlHash
    location.reload()
  }

  /**
   * Construct and set the given panel provider
   * @param {Class.<PanelProvider>} PanelClass
   * @param {object} params
   * @constructs {PanelProvider} PanelClass
   */
  setPanelProvider (PanelClass, params = {}) {
    this.panelProvider(new PanelClass({ app: this, ...params }))
  }

  /**
   * Get the email address from the verified claims of the current Firebase
   * user.
   * @throws {TypeError} when the claims are not yet loaded or are empty
   * @returns {string} email address
   */
  get currentUserEmail () { return this.defaultAuthManager.userEmail }

  /**
   * The interface will block on the `TermsOfServicePanelProvider`
   * until the user has agreed to the LATEST_TERMS_OF_SERVICE
   *
   * We do not ask ToS of users that are "homeless" i.e. have neither
   * access to a team nor are administrators i.e. are purely share
   * recipients.
   *
   * FIXME: The `userFirebaseClaims` are only updated after
    // this demand is made; need to rework/refactor.
    // await this.defaultAuthManager.userFirebaseClaims.yet(undefined)
    // if (this.defaultAuthManager.userIsHomeless) {
    //   console.debug(`ToS Not required: Sharee only.`)
    //   return
    // }
   */
  async demandTermsOfServiceAgreement () {
    const tos = await this.defaultAuthManager.termsOfServiceDate.yet(undefined)
    const { data } = await this.defaultAuthManager.firebaseFn('termsOfService', { dataOnly: true })
    const latestTOS = data && data.last_updated
    if (!latestTOS || tos < latestTOS) {
      console.debug(`ToS: Required.`)
      const currentPanel = this.panelProvider()
      this.setPanelProvider(TermsOfServicePanelProvider)
      this.panelProviderLock(true)
      this.panelProvider(currentPanel)
      await this.defaultAuthManager.termsOfServiceDate.yet(tos)
      this.panelProviderLock(false)
      console.info(`User Agreed Terms of Service on Date: ${this.defaultAuthManager.termsOfServiceDate()}`)
    } else {
      console.debug(`ToS prompt not required, '${tos}' < '${latestTOS}'`)
    }
    this.defaultAuthManager.latestTermsOfServiceAgreed(true)
  }

  /**
   * This either sets the panelProvider to the login or invite, bootstraps, or
   * reloads — depending on the current state and given user.
   * @param {FirebaseUser} user
   *
   * We need to log out and reload in several scenarios:
   *
   * 1. Logout in another window
   * 2. Share/login claim emails differ from current users'
   * 3. Current user ID differs from a new user (login in different window)
   * 4. User is invited to an account (FIXME: replay causing logouts)
   */
  async onFirebaseUserChange (user) {
    const { _currentUserID } = this
    const { hash } = location
    const isInvite = hash.startsWith('#invite=')
    const loginClaim = hash.startsWith('#loginClaim=')
    const isShare = hash.startsWith(`#shareTo=`)
    const hashValue = (hash || '').split('=').pop()
    const hashProvidesEmail = loginClaim || isShare

    const thisWindowNeedsLogout = _currentUserID && (
      !user || // 1.
      _currentUserID !== user.uid // 3.
    )

    const otherWindowsNeedLogout = user && (
      isInvite || // 4.
      (hashProvidesEmail && user.email !== hashValue) // 2.
    )

    if (otherWindowsNeedLogout || thisWindowNeedsLogout) {
      this.firebaseUserChangeSubscription.dispose()
      return this.signOut()
    }

    if (!user && hash.slice(1) === LoggedOutPanelProvider.urlHash) {
      this.setPanelProvider(LoggedOutPanelProvider)
    } else if (isInvite) {
      location.hash = '#invite'
      this.setPanelProvider(InvitePanelProvider, { jwt: hashValue })
    } else if (!user) {
      const email = ((loginClaim || isShare) && hashValue) || undefined
      this.setPanelProvider(UserLoginPanelProvider, { email })
    } else if (!_currentUserID) {
      const userID = user.uid
      await this.bootstrapForUser(userID)
      this._currentUserID = userID
    }
  }

  /**
   * @param {object} claims of the user
   */
  noteFirebaseChangeWithSentry (claims) {
    const { Sentry } = window
    if (!Sentry || !claims) { return }
    Sentry.configureScope(scope => {
      scope.setUser({
        id: claims.user_id,
        email: claims.email,
      })
      scope.setTag('user.email', claims.email)
      scope.setExtra('login',
        fromUnixTime(claims.auth_time).toLocaleString())
      scope.setExtra('iat',
        fromUnixTime(claims.iat).toLocaleString())
      scope.setExtra('otp', claims.otp)
      scope.setExtra('accounts', claims.accounts)
      scope.setExtra('about', window.ABOUT_MINUTEBOX)
    })
  }

  @memoize()
  get rootProviders () {
    return {
      account: new AccountPanelProvider({ app }),
      calendar: new CalendarPanelProvider({ app }),
      entityList: new EntityListPanelProvider({ app }),
      security: new SecurityPanelProvider({ app }),
      users: new UserPanelProvider({ app }),
      displaySettings: new DisplaySettingsPanelProvider({ app }),
      personList: new PersonListPanelProvider({ app }),
      precedentList: new PrecedentListPanelProvider({ app }),
    }
  }

  async bootstrapForUser (userID) {
    const app = this

    const accountIDs = this.defaultAuthManager.availableAccountIDs
    await accountIDs.yet(list => !list.length)
    if (this.defaultAuthManager.userIsAdmin()) {
      await this.onAdminLogin(this.defaultAuthManager)
    }

    await possiblyShowModalOnLogin(this, userID)

    this.panelProvider(await this.providerFromHistoryHash())
    this.addGlobalEventHandlers()
    window.addEventListener('hashchange', evt => this.onHashChange(evt))
  }

  /**
   * Load the PersonPanelProvider and display the given person record.

   * This is needed because importing PersonPanelProvider will cause
   * circular imports in many places.
   */
  viewPerson (person: PersonRecord | KnockoutObservable<PersonRecord> ) {
    this.panelProvider(new PersonPanelProvider({ app:this, person }))
  }

  async onAdminLogin (authManager) {
    const accountModel = await authManager.getAccountModel()
    await Transition.runAll([
      new Transitions.IsActiveTransition(this, authManager, accountModel),
    ])
  }

  async providerFromHistoryHash () {
    const hash = location.hash
    if (!hash) { return this.rootProviders.entityList }

    for (const pp of Object.values(this.rootProviders)) {
      if (await pp.historyUrl === hash) { return pp }
    }

    try {
      if (hash.startsWith('#entity--')) {
        const entityId = decodeURIComponent(hash.substr(9))
        return MinuteBookPanelProvider.createForId(this,
          this.defaultAuthManager, entityId)
      } else if (hash.startsWith('#person--')) {
        return new PersonPanelProvider({app:this, hashKey:hash, person:null})
      } else if (hash.startsWith('#precedent--')) {
        const pKey = decodeURIComponent(hash.substr(12))
        return PrecedentPanelProvider.createForId(this,
          this.defaultAuthManager, pKey)
      } else if (hash.startsWith('#writing--')) {
        const pKey = decodeURIComponent(hash.substr(10))
        return WritingPanelProvider.createForId(this, this.defaultAuthManager, pKey)
      }
    } catch (e) {
      console.warn(`Error loading history hash ${hash}`, e)
    }

    console.warn(`providerFromHistoryHash unable to resolve: ${hash}`)

    return this.rootProviders.entityList
  }

  /**
   * This is the *secondary* history tracking mechanism, after the
   * `popstate` handler in the `historyState` API.
   *
   * The historyState API is preferred because it preserves the state of
   * the observables i.e. the state of whatever was happening in the
   * panel provider.
   *
   * This fallback is used if the user navigates the history after
   * refreshing the window.
   */
  async onHashChange (evt) {
    await Promise.delay(100)
    if (location.hash === await this.panelProvider().historyUrl) { return }
    this.panelProvider(await this.providerFromHistoryHash())
  }

  get rootProviderList () {
    const {
      entityList, calendar, personList, precedentList
    } = this.rootProviders
    return [ entityList, calendar, personList, precedentList ]
  }

  get settingsProviderList () {
    const { rootProviders } =  this
    return [
      rootProviders.account,
      rootProviders.users,
      rootProviders.security,
      rootProviders.displaySettings,
    ]
  }

  /**
   * Add event handlers that apply to the root document node.
   *
   * For each of these events, a `on{Event}` will be called on
   * the current panel provider.
   *
   * The methodName is a camel-case version e.g. `onDragOver`
   * where `dragover` is the event listened for.
   */
  addGlobalEventHandlers () {
    for (const methodName of PanelProvider.globalEventHandlers) {
      const eventName = methodName.toLowerCase().slice(2)
      document.addEventListener(eventName, evt =>
        this.panelProvider()[methodName](evt, this))
    }
    // Ensure we prevent default window drop events
    window.addEventListener('drop', evt => { evt.preventDefault() })
  }

  /**
   *
   * @param {PanelProvider} panelProvider
   * @param {string} panel
   */
  * genPanelFromProvider (panelProvider, panel) {
    const { jss } = this
    const panelTemplate = panelProvider[panel]
    const panelCss = jss[panel]
    if (!panelTemplate) { return }
    yield (
      <div class={panelCss}
        panel-name={panel}
        ko-style={{'filter: blur(4px)': this.showDragPad()}}>
        {panelTemplate}
      </div>
    )
  }

  /**
   *
   * @param {PanelProvider} panelProvider
   */
  * genPanelsFromProvider (panelProvider) {
    const { jss } = this
    const dragPadClass = this.computed(() => this.showDragPad() ? 'block' : 'none')
    const main = ko.observable(
      <loading-spinner class={jss.main} />
    ).extend({ inAnimationFrame: true })

    yield panelProvider.topYellowBar
    yield (
      <div class={jss.overlay} ko-style={{ display: dragPadClass }} ko-attr={{ visible: this.showDragPad }}>
        {panelProvider.overlay}
      </div>
    )
    yield * this.genPanelFromProvider(panelProvider, 'head')
    yield * this.genPanelFromProvider(panelProvider, 'top')
    yield * this.genPanelFromProvider(panelProvider, 'left')
    yield main

    yield * this.genPanelFromProvider(panelProvider, 'right')
    yield * this.genPanelFromProvider(panelProvider, 'foot')

    yield this.modal
    yield (<notification-manager notifier={this.notifier} />)

    setTimeout(
      () => main([...this.genPanelFromProvider(panelProvider, 'main')])
    )
  }

  /**
   * Set any CSS variables on `<body>` that are global in scope i.e.
   * apply not only to the `<minute-box-app>` but also to overlays,
   * draggables, drop-downs, etc.
   */
  setBodyVars () {
    const { body } = document
    const bodyVars = {
      '--grip-clone-z-index': '200'
    }

    for (const [k, v] of Object.entries(bodyVars)) {
      body.style.setProperty(k, v)
    }

    if (location.hostname === 'localhost') {
      body.setAttribute('localhost', '')
    }


    this.defaultAuthManager.userIsAdmin.subscribe(admin => {
      body.toggleAttribute('admin', admin)
    })

    this.computed<boolean>(() => this.defaultAuthManager.userIsHomeless)
      .subscribe(homeless => body.toggleAttribute('homeless', homeless))

    if (body && body.toggleAttribute) {
      body.toggleAttribute('dark', this.theme() === 'dark')
      this.theme.subscribe(t => body.toggleAttribute('dark', t === 'dark'))
      body.toggleAttribute('homeless', this.defaultAuthManager.userIsHomeless)
    }

    this.monitorMediaQueries()
  }

  /**
   * Monitor the media query, and add/remove classes from the `<body>` tag
   * depending on the query matches.
   */
  monitorMediaQueries () {
    if (!window.matchMedia) { return }
    const { body } = document
    const toggle = (query, fn) => {
      const mq = window.matchMedia(query)
      mq.addListener(mql => fn(mql.matches))
      fn(mq.matches)
    }

    toggle('(max-width: 1250px)', v => body.toggleAttribute('narrow', v))
  }

  /**
   * Change the visible panels, and attach the panel provider's lifecycle to
   * the nodes being added, so when they are removed/cleaned the panelProvider
   * has any ephemeral values cleaned up.
   * @param {PanelProvider} panelProvider
   */
  onPanelProviderChange (panelProvider) {
    if (this.priorPanelProvider) { this.priorPanelProvider.dispose() }
    this.priorPanelProvider = panelProvider

    const panels = [...this.genPanelsFromProvider(panelProvider)]
    this.panels(panels)
    panelProvider.anchorTo(panels.find(p => p))
    panelProvider.setupKeyboardShortcuts()
    this.updateGlobalSession()
  }

  /**
   * Indicate in `/sessions/{userID}` that the user has been "seen",
   * so we can track activity across the system.
   */
  async updateGlobalSession () {
    const authManager = global.app.defaultAuthManager
    const claims = authManager.userFirebaseClaims()
    if (!claims) { return }
    const docRef = authManager.firestore.doc(`/sessions/${claims.user_id}`)
    const update = {
      email: claims.email,
      seen: getUnixTime(new Date())
    }
    await docRef.set(update, { merge: true })
  }

  /**
   * Some re-usuable styles for applying to small JSX snippets throughout the app
   */
  static get globalCSS () {
    return {
      ...highlights,
      buttonClean: buttons.clean,
    }
  }

  static get css () {
    const panelBorder = '1px solid #bababa'

    return {
      ...super.css,
      ...this.fbFnErrorCSS,
      ...this.globalCSS,

      /**
       * These styles apply to everything inside the <body> tag.
       */
      body: {
        color: color.text.light.primary,
        '--icon-color': color.text.light.primary,
        '&[dark]': {
          color: color.text.dark.primary,
          '--icon-color': color.text.dark.primary,
        },
        margin: 0,
        padding: 0,
        fontFamily: typography.bodyFontFamily,
        fontKerning: 'normal',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontSmooth: 'antialiased',

        '& *': {
          'box-sizing': 'border-box',
        },

        '&[localhost] async-button[class]::after': {
          position: 'absolute',
          content: 'Use `faceClass` on <async-button> instead of `class`',
          border: '6px dotted red',
          backgroundColor: 'yellow',
          padding: 4,
        },

        '&[noscroll]': {
          overflowY: 'hidden',
        },
      },

      /**
       * These styles apply to everything inside the app, but unlike
       * the `body` style above, they may not apply to tooltips,
       * popovers, and dialogs.
       */
      app: {
      },

      main: {
        gridArea: 'main',
        borderLeft: panelBorder,
        borderRight: '1px solid transparent',
        backgroundColor: color.systemBackground.light.secondary,
        minHeight: 'var(--main-height)',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
        },
      },
      head: {
        gridArea: 'head',
        zIndex: 6, // needs to be > topCommon for the `...` dropdown.
        position: 'relative'
      },
      top: {
        position: 'sticky',
        top: '5px',
        zIndex: 5,
        borderBottom: panelBorder,
        backgroundColor: 'white',
        gridArea: 'top',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.dmgrey.dmtext,
        },
      },
      left: {
        gridArea: 'left',
        transform: 'translate3d(0,0,0)',
        zIndex: 1,
        backgroundColor: color.gray.g,
        position: 'relative',
        '& > *': {
          width: 'var(--left-panel-default-width)'
        },
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
          color: color.text.dark.primary,
        },
      },
      right: {
        gridArea: 'right',
        borderLeft: '1px solid #bababa',
        backgroundColor: color.systemBackground.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
        },
      },

      footCommon: {
        borderTop: panelBorder,
        backgroundColor: 'rgba(255,255,255,1)',
        position: 'sticky',
        bottom: 0,
        zIndex: 15,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.dmgrey.dmtext,
        },
      },

      foot: {
        extend: 'footCommon',
        gridArea: 'foot',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        minHeight: 'var(--footer-height)'
      },

      footLeft: {
        extend: 'footCommon',
        gridArea: 'fl'
      },

      footRight: {
        extend: 'footCommon',
        gridArea: 'fr'
      },

      overlay: {
        position: 'fixed',
        left: '16px',
        top: '98px',
        gridArea: '2/1/-1/-1',
        opacity: '0.9',
        fontSize: '28px',
        border: '6px solid #ffe45c',
        borderRadius: '29px',
        backgroundColor: 'rgba(255,228,92,0.80)',
        width: 'calc(100% - 44px)',
        height: 'calc(100vh - 128px)',
        margin: 'auto',
        zIndex: 3
      },

      logo: {
        height: 'auto',
        width: 'auto'
      },

      bodyWhenModalShowing: {
        overflow: 'hidden',
        '& [modal-shade]': { zIndex: 50 },
        '& [modal-container]': { zIndex: 51 }
      },

      loadingIndicator: {
        display: 'block',
        textAlign: 'center',
        padding: 25,
        '& svg': {
          maxWidth: '35px',
          maxHeight: '35px'
        }
      }
    }
  }

  get template () {
    const appGrid = this.computed(() => [this.panelProvider().jss.appGrid, this.jss.app].join(' '))
    return (
      <div class={appGrid}>
        {this.panels}
      </div>
    )
  }

  /**
   * Choose the authentication manager for the user, when for-example
   * creating a new DataModel. The AuthManager instance will chose
   * where the data will be saved.
   *
   * If there is only one AuthManager, it will be used.  IF a user
   * has more than one, we have to decide the UX for choosing.
   *
   * This is asynchronous while it awaits user input.
   *
   * FIXME: This uses our default auth manager until we complete
   * multi-region.
   *
   * @return {AuthManager}
   */
  async pickAuthManager () {
    return this.defaultAuthManager
  }

  /**
   * Return the authentication manager that the user defaults to.  This is
   * important in cases where the jurisdiction of processing and transmission
   * are of concern.
   *
   * @return {AuthManager}
   */
  get defaultAuthManager () {
    return this.authManagers[0]
  }

  onModalChange (modal) {
    const force = Boolean(modal)
    document.body.classList.toggle(this.jss.bodyWhenModalShowing, force)
  }

  showHelpFor (keyboardShortcuts) {
    window.app.modal(
      <shortcut-help shortcuts={keyboardShortcuts} />
    )
  }

  /**
   * Call the given firebase function, capturing any errors with
   * an OutcomeNotification.
   * @param {string} name of the function
   * @param {any} params
   * @param {AuthManager} am
   */
  async callFirebaseFunction (name, params, am = this.defaultAuthManager) {
    try {
      return await this._callFirebaseFn(name, params, am)
    } catch (err) {
      console.error(`Firebase function ${name} error:`, err)
      Sentry.captureException(err)
      this.notifier.pushOutcome(...this.fbFnErrorHTML)
    }
  }

  static get fbFnErrorCSS () {
    return {
      fbFnErrorMessage: {},
      errorOutcome: {
        ...OutcomeNotification.css.outcome,
        backgroundColor: 'rgba(255,188,196,0.97)',
        border: '1px solid red'
      },
      errorIcon: {},
      errorMessage: {
      }
    }
  }

  get fbFnErrorHTML () {
    const { jss } = this
    return [
      <div class={jss.fbFnErrorMessage}>
        <strong>Something went wrong:</strong> This action could not be performed. Please contact <a href="mailto:support@minutebox.com">support@minutebox.com</a>.
      </div>, {
        icon: errorIcon,
        style: {
          outcome: jss.errorOutcome,
          icon: jss.errorIcon,
          message: jss.errorMessage
        }
      }
    ]
  }

  /**
   * Trigger the firebase function, creating a synthethic error
   * when the return value is falsy.
   * @param {*} name
   * @param {*} params
   * @param {AuthManager} am
   */
  async _callFirebaseFn (name, params, am) {
    const data = await am.firebaseFn(name, params)
    if (data && typeof data === 'object' && data.status === 'error') {
      throw new Error(`Firebase function ${name} failed.`)
    }
    return data
  }
}

MinuteBoxApp.register()
