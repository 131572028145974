import { getClassByResourceName } from 'models'
import ModelList from 'ModelList'

/**
 * A single query of the index via the authManager for the given accountID
 */
export class QueryMemoryDB {
  constructor ({ authManager, accountID, indexName }) {
    Object.assign(this, { authManager, accountID, indexName })
  }

  /**
   * @return {string} the name of the index to query
   */
  get queryIndexName () { return this.indexName }

  /**
   * @return {Array.<string>} that when combined uniquely identify the
   * query.
   */
  get identifierParts () {
    return [
      this.authManager.projectID, this.accountID, this.queryIndexName
    ]
  }

  /**
   * @return {string} uniquely identifies the query
   */
  get stringIdentifier () {
    return this.identifierParts.join('/')
  }

  query () {
    return this.authManager.firestore
      .collection(`/accounts/${this.accountID}/${this.queryIndexName}`)
  }

  makeModelList () {
    const Model = getClassByResourceName(this.indexName)
    return new ModelList(Model, this.authManager, this.query())
  }
}

/**
 *      AdminQueryMemoryDB
 */
export class AdminQueryMemoryDB extends QueryMemoryDB {}

/**
 *      TeamQueryMemoryDB
 */
export class TeamQueryMemoryDB extends QueryMemoryDB {
  constructor (params) {
    const { teamFilterKey } = params
    super(params)
    Object.assign(this, { teamFilterKey })
  }

  get identifierParts () {
    return [...super.identifierParts, 'team=' + this.teamFilterKey]
  }

  query () {
    return super.query()
      .where('teamFilterKeyPermissions.r', 'array-contains',
        this.teamFilterKey)
  }
}

/**
 *      ShareQueryMemoryDB
 */
export class ShareQueryMemoryDB extends QueryMemoryDB {
  constructor (params) {
    const { email } = params
    super(params)
    Object.assign(this, { email })
  }

  get identifierParts () {
    return [...super.identifierParts, `sharing.email=${this.email}`]
  }

  query () {
    return super.query()
      .where('sharing.email', 'array-contains', this.email)
  }
}
