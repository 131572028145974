
import { Trademark } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisTrademarks extends EisArrayComponentTable<Trademark> {
  get caption () { return 'Trademarks' }
  get componentName () { return 'trademarks' }
  get listAddName () { return 'Trademark' }
  get columns () {
    return [
      this._jurisdictionColumn(),
      'description',
      'status',
      'renewal',
      'expiry',
      'agent',
    ]
  }
}

EisTrademarks.register()
