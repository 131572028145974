
import Command from 'Command'
import CommandSet from 'Command/CommandSet'
import EntityModel from 'EntityModel'
import { formatLongForUser } from 'utils/dates'
import { cycleNext } from 'utils/array'

type PanelProvider = import('PanelProvider')

export default class RootCommandSet<P extends PanelProvider> extends CommandSet {
  panelProvider: P

  makeCommands () {
    const { panelProvider } = this
    const { app } = panelProvider
    const { entityList, calendar, personList } = app.rootProviders

    const focusSearch = new Command({
      title: 'Search Entities',
      help: <span>Press <kbd>/</kbd> to quickly start searching when you are in the <strong>Entity List View</strong>.</span>,
      filter: () => !panelProvider.searchInputFocus(),
      action: () => panelProvider.searchInputFocus(true),
    })

    const cycleEntityFilters = new Command({
      title: 'Go to Entity List',
      action: () => this.cycleNextEntityFilter(entityList),
      helpGroup: 'Navigation',
      help: (
        <span>
          Navigate to the Entity List. <strong>Tip:</strong> you can quickly cycle between all filters in the Entity List by repeatedly pressing <kbd>1</kbd>.
        </span>
      ),
    })

    const showCalendar = new Command({
      title: 'Go to Calendar',
      action: () => app.panelProvider(calendar),
      helpGroup: 'Navigation',
      help: (
        <span>
          Navigate to the Calendar.
        </span>
      ),
    })

    const showPersonList = new Command({
      title: 'Go to Persons',
      action: () => app.panelProvider(personList),
      helpGroup: 'Navigation',
      help: (
        <span>
          Navigate to the Persons View.
        </span>
      ),
    })

    const newEntity = new Command({
      title: 'Create New Entity',
      help: <span>Press <kbd>Shift +</kbd> to quickly create a new entity.</span>,
      action: async () => {
        const manager = await app.pickAuthManager()
        const model = new EntityModel(true, manager)
        app.modal(panelProvider.appModalHTML(model))
      },
      hideIf: () => app.defaultAuthManager.userIsHomeless,
    })

    const generateReport = new Command({
      title: 'Generate Report',
      help: <span>Press <kbd>R</kbd> while in the <strong>Entity List View</strong> to quickly generate a report of the current entities in the list view.</span>,
      action: () => this.generateReport(),
    })

    const editModelUnderMouse = new Command({
      title: 'Edit Entity',
      help: <span>To quickly edit an entity, press <kbd>E</kbd> while your mouse cursor is hovering over any entity in the <i>Entity List View</i>. You can press <strong>Esc</strong> to dismiss the modal.</span>,
      action: () => this.editModelUnderMouse(),
    })

    return {
      ...super.makeCommands(),
      focusSearch,
      cycleEntityFilters,
      showCalendar,
      showPersonList,
      newEntity,
      generateReport,
      editModelUnderMouse,
    }
  }

  editModelUnderMouse () {
    const { panelProvider } = this
    const { app, hoveredRowModel } = panelProvider
    if (!hoveredRowModel) { return }
    app.modal(panelProvider.appModalHTML(hoveredRowModel))
  }

  async generateReport () {
    const { panelProvider } = this
    const { app } = panelProvider
    const authManager = app.defaultAuthManager

    const cols = panelProvider.listOptions
      .columns()
      .filter(c => c.show() && c.reportable)
    const head = cols.map(c => c.title)
    const rows = panelProvider.criteria.hits()
      .map(r => cols.map(c => String(c.reportValue(r) || '')))
    const cells = [head, ...rows]

    const r = await authManager
      .callCloudFunction('generateReport', { cells })
    const blob = await r.blob()

    const date = formatLongForUser(new Date())
    const filename = `MinuteBox Report at ${date}.pdf`
    window.FileSaver.saveAs(blob, filename)
  }

  async cycleNextEntityFilter (entityListProvider) {
    const { app } = window
    if (app.panelProvider() !== entityListProvider) {
      app.panelProvider(entityListProvider)
      return
    }
    const { listOptions } = entityListProvider
    const everythingFilter = await entityListProvider.everythingFilter()
    const filters = [
      everythingFilter,
      ...entityListProvider.filtersModelList.list(),
    ]
    const { conditions } = listOptions
    const filter = filters.find(f => f.isEquivalentTo(conditions()))
    const next = cycleNext(filters, filter || everythingFilter)
    listOptions.restoreSavedFilters(next)
  }

  get keyboardShortcuts () {
    const c = this.commands
    return [
      ...super.keyboardShortcuts,
      ...c.focusSearch.keyboardShortcuts('/'),
      ...c.cycleEntityFilters.keyboardShortcuts('1'),
      ...c.showCalendar.keyboardShortcuts('2'),
      ...c.showPersonList.keyboardShortcuts('3'),
      ...c.newEntity.keyboardShortcuts('+', this.SHIFT),
      ...c.generateReport.keyboardShortcuts('r'),
      ...c.editModelUnderMouse.keyboardShortcuts('e'),
    ]
  }
}
