
import { columnsByPanelID } from 'Column/panelListColumns'
import { dropdown, color, adminOnly } from 'styles'

import 'modal-dialog'
import 'user-invite'
import 'user-editor'

import icons from 'icons'
import circleIcon from 'icons/solid/circle'
import caretDownIcon from 'icons/solid/caret-down'
import faPlusSquare from 'icons/light/plus-square'

import CommonModelColumns from 'Column/CommonModelColumns'
import AdminPanelProvider from './AdminPanelProvider'
import UserListCommandSet from './UserListCommandSet'

import 'confirm-delete/confirm-user-delete'

/**
 *                   UserPanelProvider
 *                    ———————————————
 */
export default class UserPanelProvider extends AdminPanelProvider {
  get panelWindowTitle () { return `User List` }
  get panelID () { return 'UserList' }
  get listOf () { return 'Users' }
  get itemName () { return 'User' }
  get historyUrl () { return '#admin-users' }
  get collectionID () { return 'user' }
  get CommandSetConstructor () { return UserListCommandSet }
  get hideForHomeless () { return true }

  get addModelButton () {
    const { jss } = this
    return (
      <span class={jss.addNewUser}>
        <div class={jss.rootTopButton} ko-click={() => this.newUserClick()}>
              {icons.button(faPlusSquare)}
              <span class={jss.responsiveHiddenWhenNarrow}>Add New User</span>
          </div>
      </span>
    )
  }

  async newUserClick () {
    const { teamFilters } = this.app.defaultAuthManager
    await teamFilters.loaded.yet(undefined)

    this.app.modal(
      <modal-dialog modalTitle='Invite User'>
        <template slot='content'>
          <user-invite
            app={this.app}
            teamFilters={teamFilters.list} />
        </template>
      </modal-dialog>
    )
  }

  async modelEditHTML (user) {
    const { teamFilters } = this.app.defaultAuthManager
    const valuesBeforeEditing = user.vmCurrentReprToSave()
    await teamFilters.loaded.yet(undefined)

    return (
      <user-editor model={user}
        teamFilters={teamFilters.list}
        possibleColumns={this.possibleColumns}
        onCancel={() => user.vmAssignValues(valuesBeforeEditing)}
        onFinish={() => this.app.modal(undefined)} />
    )
  }

  /**
   * Called by `RootCommandSet::editModelUnderMouse`
   */
  appModalHTML (userModel) {
    const valuesBeforeEditing = userModel.vmCurrentRepr()
    return (
      <modal-dialog modalTitle='Edit User'
        onDismissal={() => userModel.vmAssignValues(valuesBeforeEditing)}>
        <template slot='content'>
          {this.modelEditHTML(userModel)}
        </template>
      </modal-dialog>
    )
  }

  get possibleColumns () {
    return columnsByPanelID[this.panelID](this)
  }

  async onFilterDelete (filter) {
    await super.onFilterDelete(filter)
    filter.authManager.teamFilters.clearTeamFilter(this.app, filter)
  }

  renderStatusName (user) {
    const { jss } = this
    const iconColor = ko.pureComputed(
      () => `--icon-color: ${user.enabled() ? 'green' : 'red'}`)
    const activeStatusClass = ko.pureComputed(
      () => user.enabled() ? jss.enabledStatusItem : jss.statusItem
    )
    const disabledStatusClass = ko.pureComputed(
      () => user.enabled() ? jss.statusItem : jss.enabledStatusItem
    )

    const setEnabled = value => {
      user.enabled(value)
      user.vmSave()
    }

    return (
      <drop-down class={jss.statusDropdown} my='top center' at='bottom center'>
        <template slot='anchor'>
          <div class={jss.statusButton}>
            <span style={iconColor} class={jss.activeStatusIcon}>{icons.button(circleIcon)}</span>
            {user.statusName}
            {icons.caret(caretDownIcon)}
          </div>
        </template>
        <template slot='content'>
          <div class={jss.statusMenu} enabled={user.enabled}>
            <div class={activeStatusClass}
              ko-click={() => setEnabled(true)}>
              <div class={jss.activeStatusIcon}>{icons.button(circleIcon)}</div>
              Active
            </div>
            <div class={disabledStatusClass}
              ko-click={() => setEnabled(false)}>
              <div class={jss.disabledStatusIcon}>{icons.button(circleIcon)}</div>
              Disabled
            </div>
          </div>
        </template>
      </drop-down>
    )
  }

  deleteClick (evt, model) {
    evt.stopPropagation()
    evt.preventDefault()
    CommonModelColumns.hideDropdownMenu()
    window.app.modal(
      <modal-dialog modalTitle='Confirm Delete'>
        <template slot='content'>
          <confirm-user-delete
            model={model}
            onFinish={() => window.app.modal(undefined)} />
        </template>
      </modal-dialog>
    )
  }

  actionsContentHTML (user) {
    const { jss } = this
    return (
      <div class={jss.actionsMenu} ko-click={evt => evt.stopPropagation()}>
        <div class={jss.actionItem} ko-click={_ => this.onSelect(user)}>
          Edit...
        </div>
        <div class={jss.actionItem} ko-click={evt => this.deleteClick(evt, user)}>
          Delete...
        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.footCSS,
      statusDropdown: {

      },
      statusMenu: {
        ...dropdown.generalMenu
      },
      statusButton: {
      },
      statusItem: {
        ...dropdown.item,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      enabledStatusItem: {
        extend: 'statusItem',
        backgroundColor: color.rowHoverColor
      },
      _statusIconCommon: {
        '& svg': { height: '12px' }
      },
      activeStatusIcon: {
        extend: '_statusIconCommon',
        '--icon-color': 'green'
      },
      disabledStatusIcon: {
        extend: '_statusIconCommon',
        '--icon-color': 'red'
      },
      addNewUser: {
        ...adminOnly
      },
      responsiveHiddenWhenNarrow: {
        '@media (max-width: 1090px)': {
          display: 'none',
          padding: '4px 4px 4px 4px'
        }
      }
    }
  }

  static get footCSS () {
    return {
      foot: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: '25px',
        left: 0,
        width: '100%'
      },
      footContent: {
        fontWeight: 'bold'
      }
    }
  }

  get foot () {
    const { jss } = this
    const { criteria } = this
    const hits = this.computed(() =>
      (criteria.exhaustiveNbHits() ? '' : 'approx.') + criteria.nbHits()
    )

    return (
      <div class={jss.foot}>
        <div class={jss.footContent}>
          {hits} {this.listOf}
        </div>
      </div>
    )
  }

}
