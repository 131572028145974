
import ViewComponent from 'ViewComponent'

type BookSearchResult = import('./BookSearchResult').default
type pixels = number

export class PdfTextLayer extends ViewComponent {
  pageHeight: KnockoutObservable<pixels>
  searchMatches: KnockoutComputed<BookSearchResult[]>
  lines: PDFTextLine[]

  constructor (params) {
    super()
    const { textLines, pageHeight, searchMatches } = params
    Object.assign(this, {
      pageHeight,
      searchMatches,
      lines: textLines
    })
  }

  lineAsHTML (line: PDFTextLine) {
    const node = ko.observable<HTMLElement>()
    const style = this.computed<string>(() => {
      const pageHeight = this.pageHeight() || 0
      const ratio = pageHeight / line.pageInfo.height
      const { width, height, top, left } = line.boundingBox.scale(ratio, ratio)
      const n = node()
      if (!n) { return '' }
      const scaleX = width / n.clientWidth
      const scaleY = height / n.clientHeight
      return [
        `left: ${left}px`,
        `top: ${top}px`,
        `transform: scale(${scaleX}, ${scaleY})`
      ].join(';')
    }).extend({ deferred: true })
    return (
      <div class={this.jss.line} style={style} ko-set-node={node}>
        {line.contents}
      </div>
    )
  }

  get template () {
    return (
      <div class={this.jss.layer}>
        {this.lines.map(this.lineAsHTML.bind(this))}
      </div>
    )
  }

  static get css () {
    return {
      layer: {
      },
      line: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        color: 'transparent',
        fontSize: '12px',
        transformOrigin: '0 0',
      },
    }
  }
}

PdfTextLayer.register()
