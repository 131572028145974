
import {
  View, default as ViewListPanelLeft,
} from 'minute-box-app/panel-left/view-list-panel-left'
import { color } from 'styles'

import { inline } from 'icons'
import viewIcon from 'icons/solid/file'
// import { SlotManager } from '../variables/slots'

type WritingCommandSet = import('../WritingCommandSet').default


type WritingDocument = import('../WritingDocument').default
type Heading = import('../blocks/Heading').Heading
type SubHeading = import('../blocks/Heading').SubHeading
export type HeadingBlockType = Heading | SubHeading


export default class WritingViewListPanelLeft extends ViewListPanelLeft {
  document: WritingDocument
  commandSet: WritingCommandSet

  constructor (params) {
    super(params)
    const { document, commandSet } = params
    Object.assign(this, { document, commandSet })
  }

  static get css () {
    return {
      ...super.css,
      ...this.headingCSS,
      head: {
        ...super.css.head,
        fontSize: '0.9rem',
        textTransform: 'none',
        letterSpacing: 'unset',
        fontWeight: 'unset',
        background: '#377BFF',
        borderRadius: 4,
        margin: '5px 10px',
        color: color.text.light.altPrimary,
        '--icon-color': 'white',

        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
          '--icon-color': 'black',
        },

        display: 'grid',
        gridTemplateColumns: '20px 1fr auto',
      },
      fileIcon: {
      },
      documentTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      menuCollapseIcon: {
        ...super.css.menuCollapseIcon,
      }
    }
  }

  headingStyleOf (b: HeadingBlockType) {
    return this.jss[`${b.code}Block`]
  }

  hasSubMenuItems () { return true }
  subMenuItems (view) {
    return this.computed(() =>
      ko.unwrap(view.subMenuItems).map(v => this.subMenuItemHTML(v)))
  }

  subMenuItemHTML (b: HeadingBlockType) {
    const style = this.headingStyleOf(b)
    const text = b.asText
    if (!text) { return null }

    return text
      ? <div class={style} ko-ownClick={() => b.setFocus()}>{text}</div>
      : null
  }

  static get headingCSS () {
    return {
      _tocSubItem: {
        padding: '7px 16px 7px 52px',
        marginTop: '1px',
        fontSize: '85%',
        userSelect: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
          background: color.hover.light.primary,
          'body[dark] &': { // project batman
            background: color.hover.dark.primary,
          },
        },
        '&:active': {
          color: color.text.light.secondary,
          'body[dark] &': { // project batman
            color: color.text.dark.secondary,
          },
        }
      },
      hBlock: {
        extend: '_tocSubItem',
        fontWeight: 'bold',
      },
      h2Block: {
        extend: '_tocSubItem',
      },
    }
  }


  viewTitle (view: View) {
    const { commands } = this.commandSet
    return (
      <>
        <div class={this.jss.fileIcon}
          ko-ownClick={() => commands.toggleDocOptions.trigger()}>
          {inline(viewIcon)}
        </div>
        <div class={this.jss.documentTitle}> {view.title} </div>
      </>
    )
  }
}

WritingViewListPanelLeft.register()
