
import icons from 'icons'
import collapseIcon from 'icons/solid/caret-right'

import { color, buttons } from 'styles'
import PanelProvider from 'PanelProvider'
import dropIcon from 'icons/light/inbox-in'


import 'modal-dialog'

const COLLAPSED = new WeakMap<PanelProvider, KnockoutObservable<boolean>>()

export default abstract class RootPanelProvider extends PanelProvider {
  // Root panel providers are never disposed when the panel nodes are removed,
  // unlike other PanelProvider instances, which have a life-cycle attached
  // to the nodes (making `this.addEventHandler` and `this.computed` sensible).
  dispose () { }

  get hideForHomeless () { return false }

  static get css () {
    return {
      ...super.css,

      foot: {
        fontWeight: 'bold'
      },

      topFixed: {
        ...super.css.topFixed,
        borderTop: `1px solid ${color.gray.c}`
      },

      rootMenuItem: {
        ...buttons.clean,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '50px',
        minWidth: '190px',
        padding: '0px 0px 0px 15px',
        fontWeight: '700',
        borderRadius: '0px',
        margin: '5px 0px',
        'body[dark] &': { // project batman
          color: color.dark.rootMenuItem,
        },
      },

      selectedRootMenuItem: {
        extend: 'rootMenuItem',
        backgroundColor: color.gray.e,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
        },
      },

      rootMenuItemContainer: {
        '&[collapsed=true]': {
        }
      },

      rootMenuCollapseIcon: {
        minHeight: 'inherit',
        display: 'flex',
        alignItems: 'center',
        '--icon-color': color.gray.b,
        padding: '0 15px',
        '&:hover': {
          '--icon-color': 'black',
        },
        '& svg': {
          transition: '0.4s',
          transform: 'rotate(90deg)',
          '[collapsed=true] > div > &': {
            transform: 'rotate(0deg)',
          },
        }
      },

      rootMenuItemList: {
        overflow: 'hidden',
        transition: '0.4s',
      },

      dragPad: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      },

      rootTopLeft: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start'
      },

      rootTopLeftSimpleTitle: {
        height: 56,
        maxHeight: '56px',
        visibility: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        display: 'flex',
        paddingLeft: 40,
      },

      rootTopRight: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        marginLeft: 'auto',
        justifyContent: 'flex-end',
        '& i': {
          color: color.actionColor,
          'body[dark] &': { // project batman
            color: color.text.dark.primary,
          },
        }
      },

      rootTopButton: {
        ...buttons.clean,
        width: 'max-content',
        'body[homeless] &': {
          display: 'none'
        }
      },

      rootLeft: {
        position: 'sticky',
        top: 'calc(var(--sticky-offset) + 6px)',
        maxHeight: 'calc(100vh - var(--head-height) - var(--footer-height))',
        overflowY: 'auto',
        marginBottom: '15px',
      }
    }
  }

  get leftTitle () {
    return 'Views'
  }

  get topFixed () {
    const { jss } = this
    return (
      <div class={jss.rootTopLeft}>
        <div class={jss.rootTopLeftSimpleTitle}>
          {this.panelWindowTitle}
        </div>
      </div>
    )
  }

  get menuSubItems () { return }

  providerMenuToggle (provider) {
    const collapsed = COLLAPSED.get(provider) || ko.observable(undefined)
    if (!COLLAPSED.has(provider)) { COLLAPSED.set(provider, collapsed) }
    return collapsed
  }

  providerToMenuItem (provider) {
    const { jss } = this
    const menuClass = this.computed(
      () => provider === this.app.panelProvider()
        ? jss.selectedRootMenuItem : jss.rootMenuItem
    )
    const collapsed = this.providerMenuToggle(provider)
    const showCollapseIcon = this.computed(() => Boolean(provider.menuSubItems))

    return (
      <div class={jss.rootMenuItemContainer} collapsed={collapsed}>
        <div class={menuClass} ko-ownClick={() => provider.show()}>
          {provider.panelWindowTitle} {provider.titleSupplement}
          <div class={jss.rootMenuCollapseIcon} ko-visible={showCollapseIcon}
            ko-ownClick={() => collapsed.modify(v => !v)}>
            {icons.inline(collapseIcon)}
          </div>
        </div>
        <div class={jss.rootMenuItemList} ko-collapse={collapsed}>
          {provider.menuSubItems}
        </div>
      </div>
    )
  }

  get left () {
    const { jss } = this
    const isHomeless = p =>
      p.hideForHomeless && window.app.defaultAuthManager.userIsHomeless
    return (
      <div class={jss.rootLeft}>
        {this.peerPanelList
          .filter(p => !isHomeless(p))
          .map(p => this.providerToMenuItem(p))}
      </div>
    )
  }

  get overlay () {
    const { jss } = this
    return (
      <div class={jss.dragPad}>
        {icons.inline(dropIcon)}
        <span> Drop your PDFs here</span>
      </div>
    )
  }
}
