
import PdfViewer from './PdfViewer'

import { color } from 'styles'

const MIN_IMG_WIDTH = 50
const MAX_IMG_WIDTH = 200

/**
 * Usage:
 *
 *    <pdf-thumbnails pdfSettings={PdfSettings} />
 */
class PdfThumbnails extends PdfViewer {
  constructor (params, ...args) {
    super(params, ...args)
    Object.assign(this, {
      onPageMove: params.movePage,
    })
    this.computed(() => {
      if (!this.editing()) {
        this.selectedPages().forEach(bp => bp.selected(false))
      }
    })
    this.book._shiftClickAnchor = undefined
  }

  dispose (...args) {
    window.removeEventListener('click', this._globalClickHandler)
    super.dispose(...args)
  }

  canvasContainerClick (evt, bookPage) {
    evt.preventDefault()
    evt.stopPropagation()
    if (new Date() - this._lastMouseDown > 250) { return } // drag
    if (new Date() - this._lastCanvasClick < 250) { // double-click
      this.currentPage(this.book.pages().indexOf(bookPage) + 1)
      this.activeViewID('BookView')
      this.panelProvider.showThumbnailView(false)
      setTimeout(() => bookPage.scrollIntoView(), 300)
      return
    }
    if (!this._selectOnMouseDown) {
      this.selectBookPage(evt, bookPage)
    }
    this._lastCanvasClick = new Date()
  }

  selectedPages () {
    return this.bookPages().filter(bp => bp.selected())
  }

  onMouseDown (evt, bookPage) {
    /*
      If this page isn't selected yet then we select it now so that it will
      be included if this is the start of a drag event.

      If this page is already selected then we must wait until the click because
      we don't know yet if this is a click or a drag. On a drag this page
      must stay seleced but on a click it might be unselected based on modifiers.
    */
    this._lastMouseDown = new Date()
    if (!bookPage.selected()) {
      this.selectBookPage(evt, bookPage)
      this._selectOnMouseDown = true
    } else {
      this._selectOnMouseDown = false
    }
  }

  selectBookPage (evt, bookPage) {
    this.currentPage(this.book.pages().indexOf(bookPage) + 1)
    const shiftClickAnchor = this.book._shiftClickAnchor
    if (evt.shiftKey && shiftClickAnchor) {
      const thisClick = this.book.pages().indexOf(bookPage)
      const anchor = this.book.pages().indexOf(shiftClickAnchor)
      this.book.pages().forEach((bp, index) => {
        bp.selected( (index >= anchor && index <= thisClick) || (index <= anchor && index >= thisClick) )
      })
    } else if (evt.ctrlKey || evt.metaKey || evt.shiftKey) {
      bookPage.selected.modify(v => !v)
      if (bookPage.selected()) {
        this.book._shiftClickAnchor = bookPage
      } else {
        this.book._shiftClickAnchor = undefined
      }
    } else {
      bookPage.selected(true)
      this.book._shiftClickAnchor = bookPage
      this.book.pages().filter(bp => bp !== bookPage).forEach(bp => bp.selected(false))
    }
  }

  pageHTML (bookPage: BookPage, index: number) {
    const { jss } = this
    const imageSrc = ko.observable()
    const section = this.book.getSectionAt(bookPage)
    const startsSection = this.computed(() => (bookPage.startsSection() || undefined))
    this.loadOrRenderThumb(bookPage).then(imageSrc)

    return (
      <div grip='' class={jss.canvasContainer}
        grip-item={index}
        section={section ? section.name : ''}
        starts-section={startsSection}
        ko-click={evt => this.canvasContainerClick(evt, bookPage)}
        ko-event={{ mousedown: (self, evt) => this.onMouseDown(evt, bookPage) }}
        grip-selected={bookPage.selected}
        ko-scrollTo={bookPage.focused}>
        <div class={jss.imageSelectionOverlay} />
        <div class={jss.sectionOverlay} />
        { this.deleteHTML(bookPage) }
        { this.noteHTML(bookPage) }
        { this.bookmarkHTML(bookPage) }
        { this.sectionHTML(bookPage) }
        <img class={jss.canvasImage} src={imageSrc} />
        <span class={jss.pageNumber}>{index + 1}</span>
      </div>
    )
  }

  computeImageWidth () {
    return MIN_IMG_WIDTH + (this.zoom() / 2 * MAX_IMG_WIDTH)
  }

  get truckVars () {
    return this.computed(() => {
      return {
        '--canvas-cursor': this.editing() ? 'grab' : ''
      }
    })
  }

  static get css () {
    return {
      ...super.css,
      canvasContainer: {
        ...super.css.canvasContainer,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '6px 10px',
        cursor: 'var(--canvas-cursor)',
      },
      canvasImage: {
        maxWidth: '100%',
        boxShadow: '0px 0px 4px rgba(0,0,0,0.5)'
      },
      imageSelectionOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: 'calc(100% - 26px)',
        display: 'none',
        backgroundColor: 'rgba(54,146,239,0.2)',
        border: '1px solid #3692ef',
        '$canvasContainer[grip-selected="true"] &': {
          display: 'block'
        }
      },
      sectionOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: 'calc(100% - 26px)',
        display: 'none',
        backgroundColor: '#ffd502',
        opacity: '0.5',
        '$canvasContainer[starts-section] &': {
          display: 'block',
        },
      },
      pageNumber: {
        padding: '2px 6px',
        marginTop: '5px',
        fontSize: '0.8rem',
        color: 'gray',
        borderRadius: '6px',
        '$canvasContainer[grip-selected="true"] &': {
          backgroundColor: '#3692ef',
          color: 'white'
        }
      },
    }
  }

  static get iconCSS () {
    return {
      _marker: {
        ...super.iconCSS._marker,
        fontSize: '1rem',
        '$canvasContainer:hover &': {
          transform: 'scale3d(1, 1, 1)',
        },
        '$canvasContainer &:hover': {
          transform: 'scale3d(1.05, 1.05, 1.05)',
          filter: `drop-shadow(0px 0px 3px rgba(0,0,0,0.1))`,
        },
      },
      note: {
        ...super.iconCSS.note,
        '&[exists]': {
          '--icon-color': color.color.light.yellow,
          'body[dark] &': { '--icon-color': color.color.dark.yellow }
        },
      },
      section: {
        ...super.iconCSS.section,
        '&[exists]': {
          '--icon-color': color.color.light.blue,
          'body[dark] &': { '--icon-color': color.color.dark.blue }
        },
      },
      bookmark: {
        ...super.iconCSS.bookmark,
        '&[exists]': {
          '--icon-color': color.color.light.red,
          'body[dark] &': { '--icon-color': color.color.dark.red }
        },
      },
    }
  }

  get css () {
    const gridTemplateColumns = this.computed(() =>
      `repeat(auto-fit, ${this.computeImageWidth()}px)`)
    return {
      ...super.css,
      pages: {
        display: 'grid',
        gridTemplateColumns,
        justifyContent: 'space-around',
        userSelect: 'none',
        alignItems: 'flex-end',
        '[thumbnail-sections] &': {
          justifyContent: 'flex-start',
        },
      }
    }
  }

}

PdfThumbnails.register()
