import { parseISO } from 'date-fns/esm'

import CollectionList from 'CollectionList'
import { inSameRange } from 'utils/dates'
import { memoize } from 'utils/decorator'

export const eventIdentifier = 'notes'

export default class ListOfNotes {
  list: KnockoutComputed<firebase.firestore.QueryDocumentSnapshot[]>
  model: import('EntityModel').default

  constructor (model) {
    this.model = model
    this.list = ko.pureComputed(() => this.computeList())
  }

  computeList () {
    const { model } = this
    if (model.isProjection() || !model.vmIsLoaded()) {
      return []
    } else {
      return this.collection.list()
    }
  }

  @memoize()
  get collection () { return new CollectionList(this.collectionQuery) }

  private get collectionQuery () {
    const { model } = this
    const { authManager } = model
    const query = model.vmFirestoreDoc().collection('notes')
    return authManager.userCan(this.model, 'n')
      ? query
      : query.where(`createdByUid`, '==', authManager.userID)
  }

  dispose () { if (this.collection) { this.collection.dispose() } }

  * genRemindersAtDate (given, range = 'day') : IterableIterator<RelatedDate> {
    const { model } = this
    for (const note of this.list()) {
      const { reminder } = note.data()
      if (!reminder) { continue }
      const reminderDate = parseISO(reminder)
      if (inSameRange(reminderDate, given, range)) {
        yield {
          reason: note.data().message,
          identifier: eventIdentifier,
          model,
          date: reminderDate,
        }
      }
    }
  }

  addOrEditNote (bookPage) {
    const { authManager } = this.model
    const uid = authManager.firebaseUser().uid

    const existingNotes = this.list()
      .filter(n => n.data().pinpoint.pageID === bookPage.pageID)

    const getFirestoreDoc = existingNotes.length
      ? () => existingNotes[0].ref
      : () => authManager.firestore.collection(this.model.vmFirestoreDoc().path + '/notes').doc()

    const message = existingNotes.length
      ? existingNotes[0].data().message
      : ''

    const pinned = (existingNotes.length > 0) && existingNotes[0].data().pinned
    const existingNote = existingNotes.length && existingNotes[0]

    // In future we might pin notes to:
    //  1. PDF pages
    //  2. sections
    //  3. the top of book
    //  4. DataComponents
    const getPinpoint = () => ({
      type: 'pageID',
      pageID: bookPage.pageID
    })
    global.app.modal(
      <modal-dialog modalTitle={message ? 'Edit Note' : 'Add Note'}>
        <template slot='content'>
          <note-editor
            uid={uid}
            model={this.model}
            existingNote={existingNote}
            getFirestoreDoc={getFirestoreDoc}
            getPinpoint={getPinpoint}
            message={message}
            pinned={pinned}/>
        </template>
      </modal-dialog>
    )
  }
}
