
import { memoize } from 'utils/decorator'
import 'global-goto-dialog'
import { hidePopovers } from 'pop-over'

import Command from './Command'

type PanelProvider = import('PanelProvider').default

/**
 * Store a set of commands and their respective keyboard shortcuts (if any).
 */
export default class CommandSet<T extends PanelProvider = PanelProvider> {

  constructor (public panelProvider: T) {}

  get SHIFT () { return { shift: 1 } }
  get META () { return { meta: 1 } }
  get ALT () { return { alt: 1 } }
  get CTRL () { return { ctrl: 1 } }

  @memoize()
  get commands () { return this.makeCommands() }

  get keyboardShortcuts () {
    const { SHIFT } = this
    const {
      shortcutHelp, aboutMinuteBox, kbHelp, globalGoto
    } = this.commands

    return [
      ...globalGoto.keyboardShortcuts('g'),
      ...shortcutHelp.keyboardShortcuts('/', SHIFT),
      ...shortcutHelp.keyboardShortcuts('?', SHIFT),
      ...kbHelp.keyboardShortcuts('h'),
      ...aboutMinuteBox.keyboardShortcuts('I', SHIFT),
      ...this.hiddenShortcuts,
    ]
  }

  get hiddenShortcuts () {
    return [
      { key: 'Escape', action: evt => this.globalEscape(evt), filter: () => true }
    ]
  }

  globalEscape (evt) {
    hidePopovers()
    this.blurInputOnEscape(evt)
  }

  blurInputOnEscape (evt) {
    if (document.activeElement !== document.body) {
      evt.preventDefault()
      evt.stopPropagation()
      document.activeElement.blur()
    }
  }

  /**
   * @return {object} mapping names to `Command` instances.
   */
  makeCommands () : Record<string, Command> {
    const { panelProvider } = this
    const { app } = panelProvider

    return {
      globalGoto: new Command({
        title: 'Go to',
        action: () => app.modal(<global-goto-dialog />),
        helpGroup: 'Global Keys',
        help: (
          <span>
            Quickly navigate to various places all around MinuteBox by pressing <kbd>G</kbd> and then the key for where you want to go. For example, press <kbd>G</kbd> then <kbd>L</kbd> to navigate to the <strong>List View</strong> or <kbd>G</kbd> then <kbd>E</kbd> to navigate to the <strong>Entity List View</strong>.
          </span>
        ),
      }),

      kbHelp: new Command({
        title: `Show Knowledge Base Help`,
        action: () => app.modal(panelProvider.kbDialog),
        helpGroup: 'Global Keys',
      }),

      shortcutHelp: new Command({
        title: 'Show Keyboard Shortcuts',
        action: () => app
          .showHelpFor(panelProvider.commandSet.keyboardShortcuts),
        helpGroup: 'Global Keys',
      }),

      aboutMinuteBox: new Command({
        title: 'Show "About MinuteBox"',
        action: () => app.modal(panelProvider.aboutDialog),
        helpGroup: 'Global Keys',
      })
    }
  }

  /**
   * Utility function for setting an observable or triggering a callback
   * if that value is already set.
   * @param {tko.Obseravble} obs
   * @param {any} value
   * @param {Function} fn
   * @return {Function}
   */
  setValueOrCall (obs, value, fn) {
    return (...args) => obs() === value ? fn(...args) : obs(value)
  }

  /**
   * Perform different actions depending on where the event came from.
   * @param {function} keyboard
   * @param {function} click
   * @param {function} synthetic called when no event is given.
   * @return {function}
   */
  triageByEvent ({ keydown, click, synthetic }) {
    return evt => {
      if (!evt) { return synthetic(evt) }
      switch (evt.type) {
        case 'keydown': return keydown(evt)
        case 'click': return click(evt)
      }
      console.error(`Untriagable event:`, evt)
      throw new Error(`Cannot triage`)
    }
  }
}
