
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class Officer extends UnitView {
  constructor (params, parent) {
    // EIS 2.0 => 2.1 🐫 transition strategy
    params.person = params.person || {
      name: [params.name],
      residency: [params.residency],
    }
    super(params, parent)
  }

  get elvValue () { return `${this.person().name.slice(0,1).pop()} — ${this.title()}` }

  get fields () {
    return {
      person: 'person',
      title: 'string',
      elected: 'pureDate',
      resigned: 'pureDate'
    }
  }

  * getRoles () : IterableIterator<PersonRoleRecord> {
    const title = this.title() || 'Officer'
    yield { title, start: this.elected(), end: this.resigned() }
  }
}

export default class OfficersComponent extends ArrayComponent {
  get ItemClass () { return Officer }
}

OfficersComponent.register()
