import ViewComponent from 'ViewComponent'
import { color } from 'styles'
import icons from 'icons'
import deleteIcon from 'icons/light/times'

/**
 * Base class for the notes-list and bookmarks-list components
 */
export default class DisplayListBaseComponent extends ViewComponent {
  get listItemName () { throw new Error('Overload `listItemName`') }
  get svgIcon () { throw new Error(`Overload.`) }

  constructor ( params, ...args ) {
    super({}, ...args)
    Object.assign(this, {
      bookComponent: params.bookComponent,
    })
  }

  static get css () {
    return {
      details: {
        '--hover-color': 'hsla(41, 100%, 95%, 0.9)',
        '--button-opacity': '0',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        margin: '0px 0px',
        color: color.text.light.secondary,
        borderBottom: `1px solid ${color.separator.light.opaque}`,
        borderRadius: '0px',
        padding: '5px 10px',
        backgroundColor: color.systemBackground.light.tertiary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          color: color.text.dark.secondary,
          borderBottom: `1px solid ${color.separator.dark.opaque}`
        },
        '&:hover': {
          backgroundColor: 'var(--hover-color)',
          'body[dark] &': { // project batman
            backgroundColor: color.hover.dark.primary,
          },

          cursor: 'pointer',
          '& > div:nth-child(2) > i': {
            opacity: '0.7',
          },

          '--button-opacity': '1',
        },
        '& > div': {
          display: 'inline-block',
          whiteSpace: 'normal',
        },
      },
      pageNumberSpace: {
        minWidth: '40px'
      },
      pageNumber: {
        width: '40px',
      },
      messageText: {
        fontSize: 'small',
      },
      deleteButton: {
        position: 'absolute',
        transition: 'opacity 0.25s',
        right: '5px',
        top: '5px',
        opacity: 'var(--button-opacity, 1)',
      },
      noItem: {
        textAlign: 'center',
        padding: '24px 10px',
        fontSize: '1.1rem',
        color: '#aaa',
        whiteSpace: 'initial'
      },
      scrollContainer: {
        height: 'calc(100vh - 130px)',
        overflowY: 'auto',
      },
      megaIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& svg': {
          marginTop: 70,
          height: 145,
          fill: color.systemGrey.light.four,
          'body[dark] &': { // project batman
            fill: color.systemGrey.dark.four,
          },
        }
      }
    }
  }

  onItemClick ({ onClick, bookPage }) {
    if ( onClick ) {
      onClick()
    } else if ( bookPage ) {
      bookPage.scrollIntoView()
    }
  }

  itemHTML (item) {
    const { jss } = this
    const pageNumber = item.bookPage ? (this.bookComponent.getIndexOfpageID(item.bookPage.pageID) + 1) : 0
    const deleteHTML = item.delete
      ? (
        <div class={jss.deleteButton} ko-ownClick={() => item.delete()}>
          {icons.inline(deleteIcon)}
        </div>
      ) : null
    return (
      <div class={`${jss.details} ${item.classes || ''}`} ko-click={() => this.onItemClick(item)}>
        <div class={jss.pageNumberSpace}>
          { pageNumber > 0 ? ( <div class={jss.pageNumber}>{'Pg'}<br/>{`${pageNumber}`}</div> ) : '' }
        </div>
        <div>
          {deleteHTML}
          <div class={jss.messageText}>{item.jsx}</div>
        </div>
      </div>
    )
  }

  get noItemsHTML () {
    const { jss } = this
    return (
      <div class={jss.noItem}>
        <p> There are no {this.listItemNamePlural}. </p>

        Click the “{this.listItemName}” icon at the top of any page to add a {this.listItemName}.
      </div>
    )
  }

  get noItemsMessageIconHTML () {
    const { jss } = this
    return [
      this.noItemsHTML,
      <div class={jss.megaIcon}>
        {icons.inline(this.svgIcon)}
      </div>
    ]
  }

  sorter (p1, p2) {
    const pg1 = p1 && this.bookComponent.getIndexOfpageID(p1.pageID)
    const pg2 = p2 && this.bookComponent.getIndexOfpageID(p2.pageID)
    return pg1 - pg2
  }

  get contentListHTML () {
    if (!this.contentList.length) {
      return this.noItemsMessageIconHTML
    }
    return (
      <div class={this.jss.scrollContainer}>
        { this.contentList
          .sort((a,b) => this.sorter(a.bookPage, b.bookPage))
          .map(item => this.itemHTML(item)) }
      </div>
    )
  }

  get template () {
    return this.computed(() => this.contentListHTML)
  }

  /**
   * @return { [ { pageNumber, jsx, classes, delete(), onClick() }, ...] }
  */
  get contentList () { throw { message: 'NotImplemented' }}

}
