
import ViewComponent from 'ViewComponent'
import { input, typography, color } from 'styles'

export class ShareEditorInput extends ViewComponent {
  constructor ({ permit, classes }) {
    super()
    Object.assign(this, { permit })
    Object.assign(this.jss, classes || {})
  }

  checkboxHTML (title, observable) {
    const { jss } = this
    return (
      <label class={jss.checkbox}>
        <input type='checkbox' class={jss.checkboxInput} ko-checked={observable} />
        {title}
      </label>
    )
  }
}

/**
 *      - Share Expiry Date -
 */
export class ShareEditorExpiry extends ShareEditorInput {
  constructor ({value, dateGenerator, classes}) {
    super({classes})
    Object.assign(this, {value, dateGenerator})
  }

  get template () {
    const { jss, value } = this
    const displayValue = ko.observable(value())
    const checked = ko.observable(false)
    const showing = ko.observable(false)
    ko.computed(() => value(checked() ? displayValue() : null))
    checked.subscribe(v => { if (!displayValue()) { showing(v) }})
    showing.subscribe(sh => { if (!sh && !displayValue()) { checked(false) }})
    displayValue.subscribe(() => checked(true))
    return (
      <div class={jss.expireOption} checked={this.computed(() => checked() || undefined)}>
        <input type='checkbox' ko-checked={checked}
          ko-click={evt => evt.stopPropagation()}/>
        <date-picker-popover
          showing={showing}
          value={displayValue}
          relatedDateGenerator={this.dateGenerator} />
      </div>
    )
  }
}

ShareEditorExpiry.register()

/**
 *      - Everything -
 */
export class ShareEditorEverything extends ShareEditorInput {
  get template () {
    return this.checkboxHTML('Everything (i.e. Entity Information Summary, Every Page, Bookmarks)', this.permit.value)
  }
}

ShareEditorEverything.register()

/**
 *    - Entity Information Summary -
 */
export class ShareEditorEis extends ShareEditorInput {
  get template () {
    return this.checkboxHTML('Entity Information Summary', this.permit.value)
  }
}

ShareEditorEis.register()

/**
 *    - Every Page -
 */
export class ShareEditorEveryPage extends ShareEditorInput {
  constructor ({everyPage, classes}) {
    super({classes})
    Object.assign(this, {everyPage})
  }
  get template () {
    return this.checkboxHTML('Every page', this.everyPage)
  }
}

ShareEditorEveryPage.register()

/**
 *      - Book -
 */
export class ShareEditorBook extends ShareEditorInput {
  constructor ({ permit, model, everyPage, classes, sections }) {
    super({ permit, classes })
    const value = permit.value() || {}
    Object.assign(this, {
      bookmarks: ko.observable(value.bookmarks || false),
      everyPage,
      pages: ko.observable(value.pages || ''),
      sectionListOptions: this.makeSectionListOptions(model)
    })
    this.computed(() => this.computeValue()).subscribe(permit.value)
    this.computed(() => [...this.genNameParts()].join(', '))
      .subscribe(permit.name)
    if (sections) {
      const sectionNames = sections.map(s => ko.unwrap(s.name))
      this.sectionListOptions
        .forEach(s => s.selected(sectionNames.includes(ko.unwrap(s.name))))
    }
  }

  makeSectionListOptions (model) {
    const book = model.componentFor('book')
    return book.sections()
      .map(s => ({
        name: s.name,
        selected: ko.observable(false)
      }))
  }

  /**
   * @return {Array.<string>}
   */
  selectedSectionNames () {
    return [...this.sectionListOptions]
      .filter(s => s.selected())
      .map(s => ko.unwrap(s.name))
  }

  computeValue () {
    return {
      sections: this.selectedSectionNames(),
      bookmarks: this.bookmarks(),
      pages: this.pages(),
      everyPage: this.everyPage()
    }
  }

  * genNameParts () {
    if (this.everyPage()) {
      yield 'Every page'
    } else {
      if (this.pages()) { yield `Pages ${this.pages()}` }
      const sectionNames = this.selectedSectionNames()
      if (sectionNames.length) {
        yield `Sections (${sectionNames.join(', ')})`
      }
    }
  }

  sectionItemHTML (section) {
    const { jss } = this
    const { name, selected } = section
    return (
      <label class={jss.checkbox}>
        <input type='checkbox' class={jss.checkboxInput}
          disabled={this.computed(() => this.everyPage() || undefined)}
          ko-checked={selected} />
        {name}
      </label>
    )
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.shareEditorBook} disabled={this.computed(() => this.everyPage() || undefined)}>
        <div class={jss.optionLabel} alignself><span>Pages:</span></div>
        <div class={jss.shareOption}>
          <input type='text'
            disabled={this.computed(() => this.everyPage() || undefined)}
            class={jss.pageListInput}
            placeholder='e.g. 1-10, 15, 20-30'
            ko-textInput={this.pages} />
        </div>

        <div class={jss.optionLabel}><span>Sections:</span></div>
        <div class={jss.shareOption}>
          <div class={jss.checkboxList}>
            {this.sectionListOptions.map(s => this.sectionItemHTML(s))}
          </div>
        </div>

        <div class={jss.optionLabel} alignself><span>Other:</span></div>
        <div class={jss.shareOption}>
          <div class={jss.checkboxList}>
            {this.checkboxHTML('Bookmarks', this.bookmarks)}
          </div>
        </div>
      </div>
    )
  }
}

ShareEditorBook.register()
