
import { TransactionTransfer } from 'capital/Transaction'

import TransactionEditor from './TransactionEditor'

import { typography, color } from 'styles'
import { toLocaleDecimal } from 'utils/number'

/**
 * A Share Issuance consists of:
 *    - ShareCredit (S.+)
 *      - person
 *      - amount
 *      - assetClassID
 *
 *   Optional
 *   - ShareTransferNumber (S.#)
 *      - number
 *      - assetClassID
 */
class CapitalShareTransferEditor extends TransactionEditor<TransactionTransfer> {
  createTransaction ({ assetClassID }) {
    return new TransactionTransfer({
      assetClassID,
      transferor: '',
      transferee: '',
      amount: 0,
      datetime: null,
      title: 'Transfer'
    })
  }

  get questionHTML () {
    const { jss } = this
    const args = this.clone

    return (
      <div class={jss.transfer}>
        {this._datePicker('Date', args.datetime)}
        {this._horizRule()}

{/* start transferor meta section */}
        {this._personPicker('Transferor Name', args.transferor)}
        <div class={jss.availableAmount}>
          {this.computed(() => this.availableAmount())}
          {' '}
          Available Shares
        </div>
        {this._numberInput('Amount', args.amount)}

{/* end transferor meta section */}
        {this._horizRule()}
        {this._personPicker('Transferee Name', args.transferee)}
        {this._certificatePicker('Residual Certificate', args.residualCertificate)}
        {this._horizRule()}

        {this._certificatePicker('Transfer Certificate', args.transfereeCertificate)}

        {this._nextTransferNumber('Transfer Number', args.trNumber)}

        {this._considerationPicker('Monetary Consideration', args.monetaryConsideration, args.considerationIsAggregate)}
        {this._textInput<string>('Non-Monetary Consideration', args.nonMonetaryConsideration)}
        {this._proxyPicker('Voting Proxy', args.votingProxy)}
        {this._textInput<string>('Ledger Note', args.ledgerNote)}
      </div>
    )
  }

  availableAmount () {
    const { jss } = this

    const { transferor, assetClassID } = this.clone
    const { stakeholders } = this.stateBefore()
    const holder = stakeholders[transferor()]
    if (!holder) {
      return <span class={jss.notFound}>No</span>
    }
    return toLocaleDecimal(holder.getAsset(assetClassID))
  }

  isValidTransaction (args) {
    const { transferor, transferee, amount } = ko.toJS(args)
    return transferor && transferee && amount
  }

  static get css () {
    return {
      ...super.css,
      layout: {
        ...super.css.layout,
        gap: '10px 10px',
      },
      transfer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridTemplateAreas: `
        'date . .'
        'transferFrom . transferTo'
        'transferNumber . .'
        `,
        gap: '10px 15px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        borderRadius: 5,
        padding: 10,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      _inputStyle: {
        border: '1px solid transparent',
          padding: '8px 20px',
          fontSize: 15,
          boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
          borderRadius: 6,
          marginBottom: 10,
          marginLeft: 10,
          width: '185px',
          'body[dark] &': { // project batman
            backgroundColor: color.textInput.dark.primary,
            color: color.text.dark.primary,
            border: `1px solid ${color.separator.dark.nonOpaque}`
          },
      },
      _backgroundStyle: {
        background: 'rgba(0,0,0,0.1)',
        borderRadius: '5',
        padding: 10,
      },
      _labelStyle: {
        fontFamily: typography.titleFamily,
        fontWeight: '600',
        fontSize: '0.8em',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      date: {
        extend: '_labelStyle',
        padding: 10,
        borderRadius: '5px',
      },
      dateInput: {
        '& > input': {
        extend: '_inputStyle'
        }
      },
      recipient: {
        extend: '_labelStyle',
        gridArea: 'transferTo'
      },

      transferor: {
        extend: '_labelStyle',
      },
      transferee: {
        extend: '_labelStyle',
      },
      personInput: {
        '& > input': {
          extend: '_inputStyle'
          }
      },
      trNumber: {
        extend: '_labelStyle',
        padding: 10,
        borderRadius: '5px',
        marginTop: '10px'
      },
      trNumberInput: {
        '& > input': {
          extend: '_inputStyle'
          }
      },
      availableAmount: {
        gridColumn: '2/-1',
        fontSize: '0.8rem',
      },
      input: {
        ...super.css.input,
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
      }
    }
  }
}

CapitalShareTransferEditor.register()
