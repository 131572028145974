/**
 * Based on the `cache` from NetPleadings.
 */


export default class ModelCache {
  constructor (authManager) {
    Object.assign(this, {
      authManager,
      modelClassMap: new Map(),
    })
  }

  idPromiseMap (modelClass) {
    if (!this.modelClassMap.has(modelClass)) {
      const newMap = {}
      this.modelClassMap.set(modelClass, newMap)
      return newMap
    }
    return this.modelClassMap.get(modelClass)
  }

  /**
   * @param {string} keyOrId `id` or a keypath
   *        `/accounts/{accountID}/entity/id`
   */
  async get (modelClass, keyOrId) {
    const id = keyOrId.split('/').slice(-1).pop()
    const promiseMap = this.idPromiseMap(modelClass)
    const cachedModel = promiseMap[id]
    return cachedModel
  }

  async insert (modelInstance) {
    const modelClass = modelInstance.constructor
    const promiseMap = this.idPromiseMap(modelClass)
    const id = modelInstance.id()
    promiseMap[id] = Promise.resolve(modelInstance)
  }
}
