
import ModelPanelProvider from 'ModelPanelProvider'
import { computed } from 'utils/decorator'
import { View } from 'panel-left/view-list-panel-left'

import { color } from 'styles'

import UndoManager from '../PanelProvider/UndoManager'

import './writing-views/writing-panel-head'
import './writing-views/writing-document-editor'
import './writing-views/writing-command-menu'
import './writing-views/writing-formatting-bar'
import WritingViewListPanelLeft from './writing-views/writing-view-list-panel-left'


import WritingDocument from './WritingDocument'
import WritingCommandSet from './WritingCommandSet'
import WritingModel from './WritingModel'


type QuestionModel = import('DataModel/QuestionModel').default
type HeadingBlockType = import('./writing-views/writing-view-list-panel-left').HeadingBlockType
// type Slot = import('./variables/slots').Slot
type IterableComponentVariable = import('./variables/IterableComponentVariable').IterableComponentVariable<any>

export default class WritingPanelProvider<T extends QuestionModel = WritingModel> extends ModelPanelProvider<T> {
  static get Model () { return WritingModel }

  rightView: KnockoutObservable<JSX> = ko.observable()
  document: WritingDocument
  showToC: KnockoutObservable<boolean>
  undoManager: UndoManager

  constructor ({ app, model }) {
    super({ app, model })
    Object.assign(this, {
      showToC: ko.observable(true).extend({ localStorage: `wpp:showToC` }),
      document: model.answerFor('writing'),
      undoManager: new UndoManager(model),
    })
  }

  get CommandSetConstructor () { return WritingCommandSet }
  get panelID () { return 'writing' }

  dispose () {
    super.dispose()
    if (!this.model.isBlank()) {
      this.model.vmSave(true)
    }
  }

  static get css () {
    const panelVars = {
      '--right-panel-width': '280px',
      '--left-panel-width': '280px',
    }

    return {
      ...super.css,
      editor: {
        ...panelVars,
        display: 'block',
        minHeight: 'calc(100vh - var(--top-height))',
      },
      panelHead: {
        width: '100%',
      },
      panelRight: {
        ...panelVars,
        '--right-panel-transition-in': 'transform 0.25s ease-in',
        '--right-panel-transition-out': 'transform 0.25s ease-out',
        display: 'block',
        position: 'fixed',
        left: '100%',
        zIndex: 20,
        height: 'calc(100vh - var(--head-height))'
      },

      phantomRepeatHeading: {
        ...WritingViewListPanelLeft.css.head,
        opacity: '1',
        borderRadius: '2',
        padding: '7px 16px 7px 10px',
        margin: '5px 11px 0px 19px',
        fontSize: '90%',
        userSelect: 'none',
        border: `1px dotted ${color.separator.light.nonOpaque} `,
        color: color.text.light.primary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: color.hover.light.primary,
        '--icon-color': 'black',
        'body[dark] &': { // project batman
          background: color.hover.dark.primary,
          border: `1px dotted ${color.separator.dark.nonOpaque} `,
          '--icon-color': 'white',
        },
        '&:hover': {
          background: color.hover.light.blue,
          color: color.text.light.altPrimary,
          'body[dark] &': { // project batman
            background: color.hover.dark.blue,
            color: color.text.dark.altPrimary,
          },
        },

        '&:active': {
          color: color.text.light.primary,
          'body[dark] &': { // project batman
            color: color.text.dark.primary
          },
        },
        '& > svg': {
          fill: color.icon.light.primary,
          'body[dark] &': { // project batman
            fill: color.icon.dark.primary,
          },
        },
      }
    }
  }

  get head () { return <auto-save dataModel={this.model} rateLimit={5000} /> }

  get topFixed () {
    const { jss } = this
    return (
      <writing-panel-head
        class={jss.panelHead}
        panelProvider={this}
        document={this.document} />
    )
  }

  get right () {
    const { jss } = this
    return (
      <panel-right class={jss.panelRight} view={this.rightView} />
    )
  }

  get main () {
    const { jss } = this
    return (
      <writing-document-editor class={jss.editor}
        commandSet={this.commandSet}
        document={this.document}
        rightView={this.rightView} />
    )
  }

  get left () {
    return (
      <writing-view-list-panel-left
        document={this.document}
        commandSet={this.commandSet}
        activeViewID={this.activeViewID}
        views={this.views}
        showPanel={this.showToC}
      />
    )
  }

  documentSubMenuItems (d: WritingDocument): KnockoutComputed<HeadingBlockType[]> {
    return d.blocks.filter(b => b.code === 'h' || b.code === 'h2')
  }

  documentViewMenuItem (doc: WritingDocument): View {
    return {
      id: 'to-do',
      title: this.computed(() => doc.implicitTitle),
      active: ko.observable(false),
      loading: ko.observable(false),
      subMenuItems: this.documentSubMenuItems(doc),
      subMenuStyleMap: {},
      collapsed: ko.observable(false),
    }
  }

  * repeatedDocumentViewMenuItems (doc: WritingDocument): IterableIterator<View> {
    yield this.documentViewMenuItem(doc)
    const slot = doc.repeatedSlot()
    if (!slot) { return }
    const variable = slot.first() as IterableComponentVariable
    if (!variable || !variable.model) { return }
    // FIXME: Do not modify in-document variable
    const indexWas = variable.iterationIndex()
    for (const i of variable.iterationKeys()) {
      variable.iterationIndex(i)
      yield {
        id: 'to-do-2',
        title: variable.slotTitleText,
        active: ko.observable(false),
        loading: ko.observable(false),
        subMenuItems: [],
        subMenuStyleMap: {},
        collapsed: ko.observable(false),
        headingStyleClass: this.jss.phantomRepeatHeading,
        command: { trigger: () => { console.log(`setting index`, i); variable.iterationIndex(i) } },
      }
    }
    variable.iterationIndex(indexWas)
  }

  @computed({ arrayProperties: true, deferred: true, rateLimit: 20 })
  views () {
    return this.document.repeat()
      ? [...this.repeatedDocumentViewMenuItems(this.document)]
      : [this.documentViewMenuItem(this.document)]
  }
}
