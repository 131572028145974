import { formatLongForUser } from 'utils/dates'

import RootCommandSet from './RootCommandSet'
import Command from 'Command'
type PanelProvider = import("PanelProvider").default

export default class RootListCommandSet extends RootCommandSet {
  panelProvider: PanelProvider

  makeCommands () {
    return {
      ...super.makeCommands(),
      generateReport: new Command({
        title: 'Generate Report',
        help: <span>Press <kbd>R</kbd> while in the <strong>Entity List View</strong> to quickly generate a report of the current entities in the list view.</span>,
        action: () => this.generateReport(),
      }),
      generateCSV: new Command({
        title: 'Generate CSV',
        action: () => this.generateCSV(),
      }),
    }
  }

  get keyboardShortcuts () {
    return this._shortcuts || (this._shortcuts = [
      ...super.keyboardShortcuts,
      ...this.commands.generateReport.keyboardShortcuts('r'),
    ])
  }

  getReportData () {
    const { panelProvider } = this

    const cols = panelProvider.listOptions
      .columns()
      .filter(c => c.show() && c.reportable)
    const head = cols.map(c => c.title)
    const rows = panelProvider.criteria.hits()
      .map(r => cols.map(c => String(c.reportValue(r) || '')))

    return [head, ...rows]
  }

  async generateCSV () {
    const cells = this.getReportData()
    const text = cells
      .map(row => row
        .map(field => `"${field.replace(/"/g, '""')}"`)
        .join(','))
      .join('\n')
    const date = formatLongForUser(new Date())
    const filename = `MinuteBox Report at ${date}.csv`

    const el = document.createElement('a')
    el.setAttribute('href', 'data:application/octet-stream;charset=utf-16le,' + encodeURIComponent(text))
    el.setAttribute('download', filename)
    el.style.display = 'none'
    document.body.appendChild(el)
    el.click()
    document.body.removeChild(el)
  }

  async generateReport () {
    const authManager = this.panelProvider.app.defaultAuthManager
    const cells = this.getReportData()

    const r = await authManager
      .callCloudFunction('generateReport', { cells })
    const blob = await r.blob()

    const date = formatLongForUser(new Date())
    const filename = `MinuteBox Report at ${date}.pdf`
    global.FileSaver.saveAs(blob, filename)
  }
}
