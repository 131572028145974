
import ViewComponent from 'ViewComponent'
import { buttons, color } from 'styles'
import { inline } from 'icons'
import downIcon from 'icons/solid/caret-down'
import upIcon from 'icons/solid/caret-up'

type Variable = import('../variables').Variable

/**
 * Pick the slot for a variable
 */
export default class SlotPicker extends ViewComponent {
  variable: Variable
  showing = ko.observable(false)
  noCaret: boolean

  constructor (params) {
    super()
    const { variable, noCaret } = params
    Object.assign(this, { variable, noCaret })
  }

  get manager () { return this.variable.slotManager }
  get anchorHTML () {
    return (
      <div>
        {this.variable.slotIndex()}
      </div>
    )
  }

  static get choiceCSS () {
    return {
      slot: {
        display: 'flex',
      },
      slotIndex: {},
      slotTitle: {},

      slotChoices: {
        position: 'absolute',
        width: '240px',
        minWidth: '100%',
        top: 'calc(100% + 5px)',
        borderRadius: 2,
        border: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },

        '&:empty': { display: 'none' },
      },

    }
  }

  possibleChoicesHTML () {
    const { jss } = this
    return (
      <slot-choices class={jss.slotChoices}
        variable={this.variable}
        showing={this.showing} />
    )
  }

  static get displayCSS () {
    return {
      display: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: 'minmax(10px, 1fr) auto',
        alignItems: 'center',
        gap: '6px 0px',
        whiteSpace: 'nowrap',

        '--slot-indicator-display': 'flex',
      },

      indicator: {
        ...buttons.clickable,
        padding: '0px 4px',
      }
    }
  }

  get template () {
    const { jss } = this
    const toggle = () => this.showing.modify(e => !e)

    return (
      <drop-down my='top left' at='bottom left' showing={this.showing}>
        <template slot='anchor'>
          <div class={jss.display}>
            <variable-indicator
              noCaret={this.noCaret}
              variable={this.variable}
              onClick={toggle} />
            <div class={jss.indicator} ko-ownClick={toggle}>
              {this.computed(() => inline(this.showing() ? upIcon : downIcon))}
            </div>
          </div>
        </template>
        <template slot='content'>
          {this.computed(() => this.possibleChoicesHTML())}
        </template>
      </drop-down>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.displayCSS,
      ...this.choiceCSS,
    }
  }
}

/**
 *
 *        Slot Choices
 *        ————————
 *
 */
export class SlotChoices extends ViewComponent {
  variable: Variable
  showing: undefined | KnockoutObservable<boolean>

  constructor ({variable, showing}) {
    super()
    Object.assign(this, {variable, showing})
  }

  static get css () {
    return {
      ...super.css,

      list: {
        backgroundColor: 'white',
        gridColumn: '1/-1',
        display: 'grid',
        gap: '6px',
        padding: '4px',
        whiteSpace: 'nowrap',
        color: 'black',
      },

      slotChoice: {
        width: '215px',
        border: '1px transparent',
        borderRadius: 2,
        '--slot-indicator-display': 'flex',
      },

      slotChoiceSelected: {
        extend: 'slotChoice',
        border: '2px solid var(--slot-border-color)',
        filter: 'brightness(1.15)',
      },

      slotAdd: {
        ...buttons.clickable,
        padding: '3px 12px',
        display: 'flex',
        background: color.fill.light.primary,
        borderRadius: 3,
        width: 'fit-content',
        justifySelf: 'end',
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
        },
        '&:hover': {
          background: color.fill.light.secondary,
          'body[dark] &': { // project batman
            background: color.fill.dark.secondary,
          },
        },
      },
    }
  }

  * makeSlotChoicesHTML () {
    const { jss } = this
    const current = this.variable.getSlot()
    const group = current.slotGroup
    for (const s of group) {
      const css = s === current ? jss.slotChoiceSelected : jss.slotChoice
      yield <slot-indicator slot={s} class={css}
        onClick={() => {
          this.variable.setSlot(s)
          this.showing?.(false)
        }} /> }
    yield this.computed(() => this.variable.getSlot().slotGroup.hasEmptyAutoSlot
      ? null : (
        <div class={jss.slotAdd}
          ko-ownClick={() => this.addClick()}>
          New Slot...
        </div>
      ))
  }

  addClick () {
    this.variable.setSlot(this.variable.slotGroup.addNewSlot())
    this.showing?.(false)
  }

  get template () {
    const { jss } = this
    const v = this.variable
    const style = [
      `--slot-bg-color: ${v.color.bg}`,
      `--slot-border-color: ${v.color.border}`,
    ].join(';')

    return (
      <div class={jss.list} style={style}>
        {this.computed(() => [...this.makeSlotChoicesHTML()])}
      </div>
    )
  }
}


SlotChoices.register()
SlotPicker.register()
