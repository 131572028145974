import {
  fromPairs, identity
} from 'lodash-es'

//     --- Promises ---
// delay/timeout Promise
export function delayPromise (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// A touch of evil: Modify the Promise class & prototype
function finallyPolyfill (cb) {
  return this.then(
    (value) => Promise.resolve(cb()).then(() => value),
    (reason) => Promise.resolve(cb()).then(() => { throw reason })
  )
}

function delayPolyfill (millis) {
  var self = this
  return new Promise((resolve) =>
    setTimeout(() => resolve(self), millis)
  )
}

// Add .debounce/.throttle functions, so we can trace our Promise mutex in
// testing
function throttle (fn, millis) {
  var _throttling
  return function () {
    if (_throttling) { return }
    _throttling = true
    Promise.delay(millis)
      .then(function () {
        _throttling = false
        fn()
      })
  }
}

function debounce (fn, millis) {
  var timer, promise, resolver
  return function() {
    if (timer) { clearTimeout(timer) }
    if (!promise) { promise = new Promise((res) => resolver = res) }
    timer = setTimeout(() => resolver(fn()), millis)
    return promise
  }
}


function upon (evtName) {
  return this.then((emitter) =>
      new Promise((res) => emitter.on(evtName, res))
    )
}

/**
 * Return a promise that resolves an objects values.
 * e.g. {a: 1, b: Promise.resolve('BB')} => {a: 1, b: 'BB'}
 */
export function transform (obj, fn = identity) {
  const resolutions = _(obj)
    .toPairs()
    .map(([key, value]) => Promise.resolve(fn(value)).then(w => [key, w]))
    .value()
  return Promise.all(resolutions)
    .then(fromPairs)
}

// (evil, but convenient) Add to the Promise class.
export function extend(PromiseClass) {
  // See eg https://github.com/matthew-andrews/Promise.prototype.finally
  PromiseClass.prototype.finally = finallyPolyfill
  PromiseClass.prototype.delay = delayPolyfill
  PromiseClass.prototype.upon = upon
  PromiseClass.debounce = debounce
  PromiseClass.throttle = throttle
  PromiseClass.delay = delayPromise
}

// Extend the default class, so we can e.g. extend the promise polyfill/eventer
// we use for testing.
extend(Promise)


export function timeoutPromise(promise, timeout_p) {
  var timeout = timeout_p || 250
  var p = new Promise(function (resolve, reject) {
    var timeout_id = setTimeout(reject, timeout, `Time out of ${timeout}ms`)
    return p.then(function() { clearTimeout(timeout_id) })
  })
  return p
}


//  resolve when
export function resolve_when (obs, test_p) {
  var p
  var test = test_p || function (t) { return Boolean(t) }
  if (test(obs())) {
    p = Promise.resolve(obs)
  } else {
    p = new Promise(function (resolve) {
      var subs = obs.subscribe(function(v) {
        if (!test(v)) { return }
        subs.dispose()
        resolve(obs)
      })
    })
    // p.catch(eraro)
  }
  return p
}

// next value
 export function next_value (obs) {
  return new Promise(function(resolve) {
    var subscription
    var on_call = function () {
      subscription.dispose()
      resolve(obs())
    }
    subscription = obs.extend({notify: 'always'})
      .subscribe(on_call)
  })
}
