import { isEqual, has } from 'lodash-es'

const { ko } = window

type DataComponent = import('./DataComponent').default

export default class ContentView {
  componentMap: Record<string, DataComponent>

  /**
   *
   * @param {Question[]} questions
   * @param {Object} questionnaire
   */
  constructor (questions = []) {
    Object.assign(this, { questions })

    this.componentMap = Object.assign({}, ...questions.map(
      q => ({ [q.identifier]: q.makeComponent() })
    ))
  }

  /**
   * API call for getting a component "value"; returns the
   * result of `component.get()`.
   *
   * @param {(string|Symbol)} key
   * @return {DataComponent}
   */
  getValueByKey (key) {
    return this.componentMap[key].get()
  }

  /**
   * API call for getting a component class instance.
   * @return `component`
   */
  getComponentByKey (key) {
    return this.componentMap[key]
  }

  /**
   * Set the content values (or call `skip` when no value is given for the
   * component)
   * @param {Object} content
   */
  setComponentValues (content) {
    for (const [key, component] of Object.entries(this.componentMap)) {
      if (has(content, key)) {
        const newValue = content[key]
        if (isEqual(component.toJS(), newValue)) { continue }
        try {
          component.set(newValue, content)
        } catch (err) {
          console.error(`Component [${key}]: ${err}\n`, err)
          throw err
        }
      } else {
        component.skip(content)
      }
    }
  }

  toJS () {
    return Object.assign({},
      ...Object.entries(this.componentMap).map(
        ([identifier, component]) => ({[identifier]: component.toJS()})))
  }

  isBlank () {
    return Object.values(this.componentMap)
      .every((c: DataComponent) => c.isBlank())
  }

  /**
   * Return all components, included nested components.
   * Depth first.
   * @param {ContentView|Iterable.<ContentView>}
   * @yield {Component}
   */
  * walkComponentInstances (componentArrayOrMap = this.componentMap) {
    componentArrayOrMap = ko.unwrap(componentArrayOrMap)
    if (!componentArrayOrMap) { return }

    if (Symbol.iterator in componentArrayOrMap) {
      yield * componentArrayOrMap.map(c => this.cvWalkComponentInstances(c))
      return
    }

    for (const component of Object.values(componentArrayOrMap)) {
      if (!component) { continue }
      yield component
      // yield * this.cvWalkComponentInstances(component.content || null)
    }
  }
}
