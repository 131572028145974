/**
 * Encrypt the data for the given proxy.
 *
 * The algorithms are chosen to meet the NSA Suite B criteria, superceded
 * by CNSA.
 *
 * For a useful discussion see:
 *
 *  - Why is the P-521 elliptic curve not in Suite B if AES-256 is?
 *    https://crypto.stackexchange.com/questions/9901
 *  - Why not use larger cipher keys?
 *    https://security.stackexchange.com/questions/25375
 *
 * Note that the NSA algorithms top out at 192 bits, since that's the ECDH
 * curve - i.e. adding more bits doesn't improve the mathematical security.
 * Generally, the bit size is not the weakness of these primitives.
 *
 * The Suite B (suitable for Top Secret) set of primitives includes:
 *
 *      Key Exchange:   ECDH      P-384
 *      Symmetric:      AES-GCM   (192 bits)
 *      Key Wrap        AES-KW    (192 bits)
 *
 */
import ObjectProxy from './ObjectProxy'

const { crypto } = window
const { subtle } = crypto

export default class SuiteBCryptoProxy extends ObjectProxy {
  static get proxyPropertyName () { return 'CSB' }

  constructor ({ privateKey }) {
    super()
    Object.assign(this, { privateKey })
  }

  /**
   * @param {Uint8Array} plaintext
   * @return {object} text: Uint8Array, iv: Uint8Array
   */
  async wrapValue (plaintext) {
    const { privateKey } = this
    if (privateKey === false) { return { text: plaintext } }
    if (!privateKey) {
      throw new Error(`
        [SuiteBCryptoProxy]::wrap Private document key not set.
        Set the 'privateKey = false' to not perform local encryption.
      `)
    }
    const iv = crypto.getRandomValues(new Uint8Array(12))
    const opts = { name: 'AES-GCM', tagLength: 128, iv }
    const buffer = await subtle.encrypt(opts, privateKey, plaintext.buffer)
    const text = new Uint8Array(buffer)
    return { iv, text }
  }

  /**
   * @param {object} container
   * @param {Uint8Array} container.text
   * @param {Uint8Array} container.iv
   * @return {Uint8Array} plaintext
   */
  static async unwrapValue (container, options = {}) {
    const { text, iv } = container || {}
    const { privateKey } = options
    if (privateKey === false || !iv) { return text }
    if (!privateKey) {
      throw new Error(`
        [SuiteBCryptoProxy].unwrap Private document key not set.
        Set the 'privateKey = false' to not perform local decryption.
      `)
    }
    const cipherOpts = { iv, name: 'AES-GCM' }
    const buffer = await subtle.decrypt(cipherOpts, privateKey, text.buffer)
    return new Uint8Array(buffer)
  }
}
