
import { Heading } from '../blocks/Heading'

import BodyBlockEditor from './BodyBlockEditor'

export default class BlockHeadingEditor extends BodyBlockEditor<Heading> {
  static get css () {
    return {
      ...super.css,
      input: {
        ...super.css.input,
        fontSize: '2em',
        fontWeight: 'bold',
        marginBottom: '0.5em',
        border: 'unset'
      }
    }
  }
}

BlockHeadingEditor.register()
