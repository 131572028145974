import BookSearchResult from './BookSearchResult';
import { PDFEngine } from 'pdf-engine'

/**
 * This class stores a collection of observables used in relation to the PDF
 * viewer search function.
 */

export default class SearchData extends ko.LifeCycle {
  bookPages: KnockoutObservableArray<BookPage>
  pageResults: KnockoutObservableArray<Array<BookSearchResult>>
  query: KnockoutComputed<string>
  isSearching: KnockoutComputed<boolean>
  searchSemaphore: KnockoutObservable<number>
  pdfEngine: PDFEngine

  constructor (bookPages, pdfEngine) {
    super()
    Object.assign(this, {
      bookPages,
      pdfEngine,
      pageResults: ko.observableArray([]).extend({ rateLimit: 50 }),
      searchSemaphore: ko.observable(0),
      immediateQuery: ko.observable(),
      resultFocus: ko.observable(0),
      pageHoverFocus: ko.observable(), // page index of result user is hovering on search list
      showHighlights: ko.observable(true),
    })

    this.isSearching = this.computed(() => this.searchSemaphore() > 0)

    this.query = this.computed(() => {
      const q = this.immediateQuery()
      return (!q || q.length < 3) ? '' : q
    }).extend({ rateLimit: { timeout: 300, method: 'notifyWhenChangesStop' }})

    this.query.subscribe(() => {
      this.pageResults([])
      this.resultFocus(0)
    }, 'beforeChange')

    const allPages = this.bookPages.map((page,i) => ([page, i]))
    const pagesInView = allPages.filter(([p, _]) => p.inViewport())

    this.query.subscribe(q => this.runSearch(q, pagesInView()))
    this.query.subscribe(q => this.runSearch(q, allPages()))

    this.results = this.computed(() => this.pageResults().flat())
    this.resultCount = this.computed(() => this.results().length)

    this.computed(() => {
      if ( !this.resultCount() ) {
        this.resultFocus(0)
      } else if ( !this.resultFocus() || this.resultFocus() > this.resultCount() ) {
        this.resultFocus(1)
      }
    })
    this.computed(() => this.results()
      .forEach((result, index) => result.focused(index+1 === this.resultFocus()))
    )
  }

  getResultsFor (index) : Array<BookSearchResult> {
    return this.pageResults()[index] || []
  }

  async runSearch(query: string, pages: Array<[BookPage, number]>) {
    if (!query) {
      this.pageResults([])
      return
    }
    this.searchSemaphore.modify(v => ++v)
    try {
      for (const [page, i] of pages) {
        const pageresults = await this.pdfEngine.search(page, query)
        if (this.query() !== query) { return }
        const results = pageresults.map(r =>
          new BookSearchResult({
            pdfkitresult: r,
            query,
            bookPage: page,
            pageIndex: i,
          }))
        this.pageResults()[i] = results
        this.pageResults.valueHasMutated()
      }
    } finally {
      this.searchSemaphore.modify(v => --v)
    }
  }

  _navigateBy (amount) {
    const current = this.resultFocus()
    const newFocus = Math.max(1, Math.min(this.resultCount(), current + amount))
    this.resultFocus(newFocus)
    const result = this.results()[newFocus - 1]
    if (result) { result.scrollIntoView() }
  }

  nextResult () { return this._navigateBy(+1) }
  previousResult () { return this._navigateBy(-1) }
 }
