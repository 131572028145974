import { PPSA } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisPpsaRegistrations extends EisArrayComponentTable<PPSA> {
  get caption () { return 'PPSA Registrations'}
  get componentName () { return 'ca_ppsas' }
  get listAddName () { return 'PPSA' }
  get columns () {
    return [
      'description',
      'type',
      'creditor',
      'debtor',
      'status',
      'expiry',
      'agent',
      this._jurisdictionColumn(),
    ]
  }
}

EisPpsaRegistrations.register()
