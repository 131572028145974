
import UserModel from 'UserModel'
import UserEditor from 'user-editor'
import OutcomeNotification from 'notification-manager/outcome-notification'

import { input, color } from 'styles'
import envelopeIcon from 'icons/solid/envelope'

/**
 * A <user-invite> is a degenerate <user-editor>.
 */
export default class UserInvite extends UserEditor {
  constructor (params, ...args) {
    const { app } = params
    const model = new UserModel(true, app.defaultAuthManager)
    super({ ...params, model, okText: 'Send invitation email' }, ...args)
    Object.assign(this, { app })
    this.user.accountID(app.defaultAuthManager.accountID())
  }

  async saveClick () {
    const { notifier } = global.app
    if (!this.user.email().includes('@')) { return }
    this.user.teamFilterKeys(this.teams().map(t => t.id()))
    console.log(`Sending an email to:`, this.user.email())

    const resp = await this.app
      .callFirebaseFunction('inviteByEmail', {
        ...await this.user.vmCurrentReprToSave(),
        host: location.host
      })
    console.log(`Invitation response: `, resp)
    if (!resp.uid) {
      console.error(`Invitation did not have a 'uid'.`)
      throw new Error(`Invitation failed.`)
    }
    this.model().id(resp.uid)
    notifier.pushOutcome(...this.showInviteNotification(this.user.email()))
    await super.saveClick()
  }

  showInviteNotification (email) {
    const { jss } = this
    return [
      <span>
        The user <b>{email}</b> has been invited to join your team.
      </span>, {
        icon: envelopeIcon,
        style: {
          outcome: jss.noticeOutcome,
          message: jss.noticeMessage,
          icon: jss.noticeIcon
        }
      }
    ]
  }

  static get css () {
    return {
      ...super.css,
      emailInput: {
        border: '1px solid transparent',
        padding: '8px 20px',
        fontSize: 15,
        minWidth: 250,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary
        },
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        '&:focus, &:active': {
          outline: 'none',
          borderLeft: '3px solid #ffd502',
          borderRadius: '3px'
        }
      },
      noticeOutcome: {
        ...OutcomeNotification.css.outcome
      },
      noticeMessage: {
      },
      noticeIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },

      }
    }
  }

  get questionHTML () {
    const { user, jss } = this
    return [
      ...this.textInputQuestion('Email', this.user.email, jss.emailInput),
      ...this.makeQuestion('Role', this.roleEditorHTML(user.role)),
      ...this.makeQuestion('Office', this.officeEditorHTML(user.office)),
      ...this.makeQuestion('Teams', this.matchingTeamsHTML())
    ]
  }
}

UserInvite.register()
