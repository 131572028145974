import ViewComponent from 'ViewComponent'

import './outcome-notification'
import './progress-notification'

export default class NotificationManager extends ViewComponent {
  constructor ({ notifier }) {
    super()
    Object.assign(this, {
      notifier,
      locked: false,
      hideDelayMS: 1450,
      barClass: ko.observable()
    })
  }

  computeBarClass () {
    const { jss } = this
    const { outcomeList, progressList } = this.notifier
    const notifyCount = outcomeList.length + progressList.length
    return notifyCount > 0 ? jss.barShowing : jss.barHidden
  }

  static get barCSS () {
    const showTransform = 'translateX(-50%) translateY(0%)'
    const hideTransform = 'translateX(-50%) translateY(150%)'
    return {
      _bar: {
        position: 'fixed',
        display: 'grid',
        gridGap: '4px',
        zIndex: '20',
        left: '50%',
        bottom: '15px',
        width: '70%',
        transform: showTransform
      },
      barShowing: {
        extend: '_bar',
        transition: 'transform 0.4s ease-in',
        transform: showTransform
      },
      barHidden: {
        extend: '_bar',
        transition: 'transform 1s 0.45s ease-out',
        transform: hideTransform
      },
      barImmediateHide: {
        extend: '_bar',
        transition: 'transform 0.25s ease-out',
        transform: hideTransform
      },
    }
  }

  static get css () {
    return {
      ...this.barCSS,
      outcome: {

      },
      progress: {

      }
    }
  }

  get template () {
    const { outcomeList, progressList } = this.notifier
    this.barClass(this.computeBarClass())
    this.computed(() => this.computeBarClass()).subscribe(this.barClass)

    const barEvents = {
      contextmenu: (_, evt) => {
        if (!evt.metaKey) { return true }
        evt.preventDefault()
        this.locked = !this.locked
        console.info(`Setting Notification lock state to ${this.locked}`)
        return false
      }
    }

    return (
      <div class={this.barClass} ko-event={barEvents}>
        {outcomeList.map(n => <outcome-notification
          manager={this} notification={n} />)}
        {progressList.map(p => <progress-notification
          manager={this} progress={p} />)}
      </div>
    )
  }

  get currentNotificationCount () {
    const { outcomeList, progressList } = this.notifier
    return outcomeList.length + progressList.length
  }

  async removeWithAnimation (notification, fromList) {
    if (this.locked) { return }
    if (this.currentNotificationCount === 1) {
      this.barClass(this.jss.barHidden)
      await Promise.delay(this.hideDelayMS)
    }
    fromList.remove(notification)
  }

  hideProgress (progress) {
    this.removeWithAnimation(progress, this.notifier.progressList)
  }

  hideOutcome (outcome) {
    this.removeWithAnimation(outcome, this.notifier.outcomeList)
  }

  hideOutcomeImmediately (outcome) {
    const { jss } = this
    if (this.currentNotificationCount === 0) {
      this.barClass(jss.barImmediateHide)
    }
    this.notifier.outcomeList.remove(outcome)
  }
}

NotificationManager.register()
