import { addYears, parseISO, isBefore, isSameDay, format } from 'date-fns/esm'

import ViewComponent from 'ViewComponent'

import { toISO8601, formatForUser } from 'utils/dates'
import { buttons, color, calendar } from 'styles'
import { hashHSL } from 'utils/string'

/**
 * <calendar-tickler criteria={SearchCriteria} />
 */
export default class CalendarTickler extends ViewComponent {
  eventsListHTML: KnockoutObservableArray<any>
  dateToShow: KnockoutObservable<Date>

  constructor ({ panelProvider, dateToShow, eventTypes, entityList }, ...args) {
    super()

    Object.assign(this, {
      dateToShow,
      eventTypes,
      eventsListHTML: ko.observableArray([]),
    })

    entityList.subscribe(models =>
      this.populateEventsMap(panelProvider, models))
    this.eventsListHTML(this.loadingHTML)
    this.populateEventsMap(panelProvider, entityList)
  }

  async populateEventsMap (panelProvider, models: RelatedDatesSource[]) {
    const { jss } = this
    this.eventsListHTML(this.loadingHTML)
    const end = addYears(new Date(), 5)
    const eventsByDay = {}
    let year = addYears(new Date(), -2)

    while (isBefore(year, end)) {
      for (const model of ko.unwrap(models)) {
        const events = [...model.genRemindersAtDate(year, 'year')]
        for (const event of events) {
          const isoDate = toISO8601(event.date)

          if (!eventsByDay[isoDate]) { eventsByDay[isoDate] = [] }

          eventsByDay[isoDate]
            .push(Object.assign({}, event, {
              object: model.cvModelTitle,
              _openObject: () => panelProvider.showPanelFromEvent(event.model, event),
            }))
        }
      }
      year = addYears(year, 1)
    }

    this.eventsListHTML([])
    const dates = Object.keys(eventsByDay).sort()
    const today = toISO8601(new Date())
    let todayAdded = false
    const jumpToToday = ko.observable(false)

    for (const date of dates) {
      if (!todayAdded && date >= today) {
        this.eventsListHTML.push(
          <div class={jss.today} today=''
            isodate={toISO8601(new Date())}
            ko-scrollTo={jumpToToday}><span>Today</span></div>
        )
        todayAdded = true
      }
      this.eventsListHTML.push(this.dateHTML(parseISO(date), eventsByDay[date]))
      this.eventsListHTML.push(
        ...eventsByDay[date].map(e => this.eventHTML(e)))
    }

    jumpToToday(true)
  }

  get loadingHTML () {
    const { jss } = this
    return (
      <div class={jss.loading}>Loading. Please wait...</div>
    )
  }

  dateHTML (date, events) {
    const { jss } = this

    const show = this.computed(() =>
      events.some(e => this.eventTypes[e.identifier].show()))

    return (
      <div class={jss.date} isodate={toISO8601(date)}
        ko-visible={show}>
        {formatForUser(date)}
      </div>
    )
  }

  eventHTML (event) {
    const { jss } = this
    const setColor = { '--event-color': hashHSL(event.identifier) }
    return (
      <div class={jss.event}
        ko-visible={this.eventTypes[event.identifier].show}
        column-id={event.identifier}
        ko-style-map={setColor}
        ko-click={() => event._openObject()}>
        <div class={jss.object}>{event.object}</div>
        <div class={jss.reason}>{event.reason}</div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      list: {

      },
      date: {
        padding: '20px 0px 10px 10px',
        color: 'black',
        fontSize: '0.7rem',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        fontWeight: '600',
        'body[dark] &': { // project batman
          color: color.color.dark.indigo
        },
      },
      today: {
        padding: '17px',
        '& span': {
          display: 'block',
          textAlign: 'center',
          color: 'black',
          backgroundColor: color.fill.light.primary,
          borderRadius: '16px',
          padding: '6px',
          'body[dark] &': { // project batman
            color: color.dmgrey.dmtext,
            backgroundColor: color.systemBackground.dark.tertiary
          },
        }
      },
      event: {
        ...buttons.clickable,
        ...calendar.event,
        display: 'grid',
        grid: {
          templateAreas: `
            'date   subject'
            '.      object'
          `,
          templateColumns: 'auto 1fr',
          gap: '0px 4px'
        },
        border: `1px solid ${color.gray.ticklerBorder}`,
        borderRadius: '4px',
        backgroundColor: color.systemBackground.light.tertiary,
        padding: '5px',
        marginBottom: '6px',
        'body[dark] &': { // project batman
          color: color.dmgrey.dmtext,
          backgroundColor: color.dmCalendar.ticklerBackground,
          border: `1px solid ${color.dmCalendar.ticklerBorder}`,
          ...calendar.event,
          transition: 'background-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
          '&:hover': {
            transition: 'background-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
            backgroundColor: '#000000',
            boxShadow: '1px 1px 7px 3px rgba(0,0,0,0.4)',
          }
        },
      },
      object: {
        gridArea: 'object',
        color: 'black',
        fontSize: '0.9rem',
        textAlign: 'left',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      reason: {
        gridArea: 'subject',
        color: color.text.light.secondary,
        fontWeight: 'lighter',
        textAlign: 'left',
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
      eventNoEvents: {
        height: 'calc(var(--main-height) - 8px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }

  get noEventsHTML () {
    const { jss } = this
    return (
      <div class={jss.eventNoEvents}>
        Add dates to any of your entities to see them in the Calendar.
      </div>
    )
  }

  get noEventsComputed () {
    return this.computed(() =>
      this.eventsListHTML.length ? '' : this.noEventsHTML)
  }

  get template () {
    const { jss } = this
    const dateSelector = `'[isodate^="'yyyy-MM'"]'`
    const isoDateToShowSelector = ko.observable()
    this.subscribe(this.dateToShow, toShow => {
      isoDateToShowSelector(undefined) // erase to reset & trigger next update
      const newSelector = isSameDay(new Date(), toShow)
        ? '[today]' : format(toShow, dateSelector)
      isoDateToShowSelector(newSelector)
    })

    return (
      <div class={jss.list}
        ko-scrollTo={isoDateToShowSelector}>
        {this.eventsListHTML}
        {this.noEventsComputed}
      </div>
    )
  }
}

CalendarTickler.register()
