
import ViewComponent from 'ViewComponent'

import Asset from '../Asset'

import './capital-asset-prefix'

export default class CapitalAssetName extends ViewComponent {
  classification: string
  series: string
  prefix: string
  
  constructor ({ prefix, classification, series }) {
    super()
    Object.assign(this, { prefix, classification, series })
  }

  static get css () {
    return {
      ...super.css,
      line: {
        display: 'flex',
      },
      classification: {
        display: 'inline'
      },
      series: {
        display: 'inline',
        padding: '0 0.5em'
      },
      prefix: {
        display: 'inline'
      },
    }
  }

  get template () {
    const { jss, classification, series, prefix } = this

    return (
      <div class={jss.line}>
        <span class={jss.classification}>{classification}</span>
        <span class={jss.series}>{series}</span>
        <capital-asset-prefix class={jss.prefix} prefix={prefix} />
      </div>
    )
  }
}

CapitalAssetName.register()
