
import RightView from 'MinuteBookPanelProvider/right-view/RightView'
import { computed } from 'utils/decorator'
import { color } from 'styles'

type SlotManager = import('../variables/slots').SlotManager
type SlotGroup = import('../variables/slots').SlotGroup
type Slot = import('../variables/slots').Slot
type Variable = import('../variables').Variable

export default class WritingRightViewSlots extends RightView {
  slotManager: SlotManager
  rightView: KnockoutObservable<JSX>

  constructor (params) {
    super(params)
    const { slotManager, rightView } = params
    Object.assign(this, { slotManager, rightView })
  }

  get title () { return 'Slots' }
  get multiTitleItems () { return null }

  static get css () {
    return {
      ...super.css,
      ...this.groupCSS,
      ...this.slotCSS,
      ...this.variableCSS,
      manager: {
        padding: '10px 40px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 20px - var(--head-height))',
      },
    }
  }

  static get variableCSS () {
    return {
      slotItem: {
        display: 'block',
        margin: '7px 0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        '--slot-indicator-display': 'flex',
      }
    }
  }

  slotItemHTML (v: Variable) {
    const { jss } = this
    const style=[
      `--slot-bg-color: ${v.color.bg}`,
      `--slot-border-color: ${v.color.border}`,
    ].join(';')
    const scrollParams = {
      behavior: 'smooth', block: 'center', inline: 'nearest',
    } as ScrollIntoViewOptions

    return ko.ignoreDependencies(() => (
      <variable-indicator-right-view class={jss.slotItem} style={style}
        variable={v}
        noCaret={true}
        onClick={() => v.boundToElement.scrollIntoView(scrollParams)} />
    ))
  }

  private _comesBeforeInDOM (a: Variable, b: Variable) {
    const cmp = a.boundToElement.compareDocumentPosition(b.boundToElement)
    return (cmp & Node.DOCUMENT_POSITION_PRECEDING) ? 1 : -1
  }

  static get slotCSS () {
    return {
      slot: {
        color: 'blue',
        display: 'grid',
        gridTemplateAreas: `
          'st sc'
          'sl sl'
        `,
        gridTemplateColumns: `1fr 1fr`,
      },
      slotTitle: {
        gridArea: `st`,
        background: color.fill.light.secondary,
        borderRadius: 10,
        width: 55,
        marginLeft: 4,
        fontSize: '88%',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'default',
        alignItems: 'center',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
          background: color.fill.dark.secondary,
        },
      },
      slotSize: {
        gridArea: `sc`,
        fontWeight: 'bold',
        marginRight: 7,
        cursor: 'default',
        fontSize: '80%',
        textAlign: 'center',
        marginLeft: 'auto',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
      slotItems: {
        gridArea: `sl`,
      },
    }
  }

  slotGroupHTML (s: Slot, slotTitle: string) {
    const { jss } = this
    const items = ([...s] as Variable[])
      .filter(v => document.contains(v.boundToElement))
      .sort(this._comesBeforeInDOM)
      .map(v => this.slotItemHTML(v as Variable))

    return (
      <div class={jss.slot}>
        <div class={jss.slotTitle}>Slot "{slotTitle}"</div>
        <div class={jss.slotSize}>{s.length} fill-ins</div>
        <div class={jss.slotItems}>{items}</div>
      </div>
    )
  }

  static get groupCSS () {
    return {
      group: {
        display: 'grid',
        gridTemplateAreas: `
          'gt gc'
          'gs gs'
        `,
        gridTemplateColumns: '1fr auto',
        color: color.text.light.primary,
        padding: '6px 0px',
        marginBottom: 10,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      groupTitle: {
        gridArea: 'gt',
        textTransform: 'capitalize',
        fontWeight: '600',
        marginBottom: 2,
      },
      groupCount: {
        gridArea: 'gc',
        fontSize: '80%',
        marginRight: 3,
        cursor: 'default'
      },
      groupSlots: {
        gridArea: 'gs',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        background: color.systemBackground.light.primary,
        padding: '5px 5px',
        borderRadius: 2,
        marginBottom: 5,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          background: color.systemBackground.dark.primary,
        },
      },
    }
  }

  groupCount (g: SlotGroup) {
    return [...g].reduce((c, s) => c + s.length, 0)
  }

  groupHTML (g: SlotGroup) {
    const { jss } = this
    const count = this.groupCount(g)
    const slots = [...g]
      .filter(s => s.length)
      .map(s => this.slotGroupHTML(s, g.indexTextOfSlot(s)))

    return count > 0 ? (
      <div class={jss.group}>
        <div class={jss.groupTitle}>{g.groupTitle}</div>
        <div class={jss.groupCount}>{count} fill-ins</div>
        <div class={jss.groupSlots}>{slots}</div>
      </div>
    ): null
  }

  @computed()
  groupsListHTML () {
    return [...this.slotManager]
      .sort((a, b) => a.groupTitle.localeCompare(b.groupTitle))
      .map(g => this.groupHTML(g))
  }

  get body () {
    return (
      <div class={this.jss.manager}>
        {this.groupsListHTML}
      </div>
    )
  }
}

WritingRightViewSlots.register()
