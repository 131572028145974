
import color from './color'

/**
 * See e.g. https://stackoverflow.com/questions/6250394
 * The gradient % must be the backgroundSize w/h
 * e.g. 20% must match the 5px/1px
 */
export const dottedBottom = {
  backgroundImage: `linear-gradient(to right, ${color.systemGrey.light.one} 20%, rgba(255,255,255,0) 0%)`,
  'body[dark] &': { // project batman
    backgroundImage: `linear-gradient(to right, ${color.systemGrey.dark.one} 20%, rgba(255,255,255,0) 0%)`,
  },
  backgroundPosition: 'bottom',
  backgroundRepeat: 'repeat-x',
  backgroundSize: '5px 1px'
}

export default { dottedBottom }
