import { Variable } from '../Variable'

import './wrvv-array-component-text'
import './wrvv-capital'
import './wrvv-component-var'
import './wrvv-dates'
import './wrvv-person'
import './wrvv-text'
import './wrvv-time'


export function factoryVariableView (v: Variable): JSX {
  switch (v.code) {
    case 'acp': return <wrvv-array-component-person variable={v} />
    case 'acpd': return <wrvv-array-component-pure-date variable={v} />
    case 'acv': return <wrvv-array-component-text variable={v} />
    case 'ccav': return <wrvv-capital-asset variable={v} />
    case 'ccsv': return <wrvv-capital-holder variable={v} />
    case 'cpdv': return <wrvv-component-pure-date variable={v} />
    case 'cpv': return <wrvv-component-person variable={v} />
    case 'cv': return <wrvv-component-text variable={v} />
    case 'scv': return <wrvv-component-text variable={v} />
    case 'date': return <wrvv-date variable={v} />
    case 'text': return <wrvv-text variable={v} />
    case 'time': return <wrvv-time variable={v} />
  }
  console.error(`Bad variable code: {v.code}`)
  return <div>`Bad Variable code: {v.code}`</div>
}
