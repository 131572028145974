
import { blobToArrayBuffer } from 'jcrypto/blob'
type EntityModelImportData = import('EntityModel/interfaces').EntityModelImportData

/**
 * Create or update the given `entity` from the `.enact` format.
 *
 * The `.enact` format is created by our `enact2mb.ps` script, being a
 * UTF (with BOM) JSON encoded file.
 */
export async function enactToEntity (file: File) : Promise<EntityModelImportData> {
  const te = new TextDecoder()
  const text = te.decode(await blobToArrayBuffer(file)).replace(/[\r\n]/g, '')
  const data = JSON.parse(text)

  const ent = data.entity[0]
  const cf = data.corporationfile[0]

  return {
    id: ent.ENTITYID,
    tags: ['import', 'enact'],
    content: {
      legalname: ent.COMPLETENAME,
    }
  }
}
