import Picker from './picker'

const DEFAULT_AT = {
  my: 'top left',
  at: 'bottom left',
}

export default class PersonPicker extends Picker<string> {
  generator: PersonGenerator
  searchType: 'startsWith' | 'includes'
  suggestProperty: string

  constructor (params) {
    super(Object.assign({}, DEFAULT_AT, params))
    console.warn(`<person-picker> is deprecated. Use <person-record-picker>.`)
    const { generator, searchType, suggestProperty } = params
    Object.assign(this, {
      generator,
      searchType,
      suggestProperty: suggestProperty || 'name'
    })
  }

  choices () : string[] {
    const { memoryDB } = window.app
    const entityList = memoryDB.getListOf('entity')
    const userList = memoryDB.getListOf('user')
    const names = new Set<string>()
    for (const generator of [...entityList(), ...userList()]) {
      for (const person of generator.getPersons()) {
        for (const name of person.name) {
          if (!name) { continue }
          names.add(name)
        }
      }
    }
    return [...names].sort()
  }

  onSelectEvent (value) {
    if (!value.trim()) { return }
    return super.onSelectEvent(value)
  }

  filterChoice (item, query = '') {
    const canonStr = (item || '').toLowerCase().trim()
    return canonStr[this._searchType]((query || '').toLowerCase())
  }

  itemHTML (value: string) {
    const { jss } = this
    return <div class={jss.itemValue}>{value}</div>
  }

  get _searchType () { return this.searchType || 'includes' }
  get noMatchesMessage () { return 'Nothing matches. [Enter] to add.' }
  get searchMessage () { return 'Type to search or add' }

  static get css () {
    return {
      ...super.css,
      anchor: {
        ...super.css.anchor,
        width: '100%',
      },
    }
  }
}

PersonPicker.register()
