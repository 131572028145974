
import { isIndexInteger } from 'utils/number'

import Slot from './Slot'
import { SlotVariable } from './interfaces'

const MAX_AUTO_SLOTS = 50

/**
 * A `SlotGroup` represents a set of slots, and corresponds to the
 * `code` of a Variable, namely by Variable or Component type.
 */
export default class SlotGroup {
  private autoSlots: KnockoutObservableArray<Slot> = ko.observableArray([])

  constructor (private slotVar: SlotVariable) {}

  get groupTitle () { return this.slotVar.slotGroupTitle || '' }
  get hasEmptyAutoSlot () { return this.autoSlots().some(s => s.isEmpty) }
  * [Symbol.iterator] () { yield * this.autoSlots }
  get isEmpty () { return this.autoSlots().every(s => s.isEmpty) }

  removeVariable (v: SlotVariable) {
    for (const s of this.safeOrderedSlots) { s.remove(v) }
  }

  indexOf (v: SlotVariable) {
    const slots = this.safeOrderedSlots
    const slot = slots.find(s => s.contains(v))
    return slot ? slot.identifier || slots.indexOf(slot) : 0
  }

  indexOfSlot (s: Slot) {
    return s.identifier || this.autoSlots.peek().indexOf(s)
  }

  indexTextOfSlot (s: Slot): string {
    if (s.identifier) { return s.identifier }
    const index = this.autoSlots.peek().indexOf(s)
    return `${index + 1} ${s.isEmpty ? '(Empty)' : ''}`
  }

  /**
   * Add the variable to the `Slot` at the given index, otherwise to the
   * first `autoSlot` (in cases where e.g. a named slot has disappeared).
   */
  setIndexOf (v: SlotVariable, i: string|number): Slot {
    if (i === -1) {
      console.error(`<SlotGroup> Bad index: -1.`)
      return this.setIndexOf(v, 0)
    }
    const slot = this.getSlotAtIndex(i) || this.getSlotAtIndex(0)
    if (this.indexOf(v) !== i) { this.removeVariable(v) }
    slot.add(v)
    return slot
  }

  addNewSlot () {
    const s = new Slot(this)
    this.autoSlots.push(s)
    return s
  }

  getSlotFor (v: SlotVariable) {
    return this.safeOrderedSlots.find(s => s.contains(v))
  }

  propagateChangesToPeers (from: SlotVariable) {
    const slot = this.safeOrderedSlots.find(s => s.contains(from))
    if (slot) { slot.propagateChanges(from) }
  }

  /**
   * Ensure we have a flush array of slots.
   */
  getSlotAtIndex (indexOrIdentifier: string | number) {
    // Fix for legacy/testing "All" slot
    if (!isIndexInteger(indexOrIdentifier)) { indexOrIdentifier = 0 }
    const index = indexOrIdentifier as number
    const slots = this.autoSlots.peek()
    while (slots.length <= Math.min(index, MAX_AUTO_SLOTS)) {
      slots.push(new Slot(this))
    }
    return slots[index]
  }

  /**
   * Get the ordered list but without any KO dependencies (hence "safe").
   */
  private get safeOrderedSlots (): Slot[] {
    return [...this.autoSlots.peek()]
  }

  toString () {
    const slots = this.safeOrderedSlots
    return `<SlotGroup "${this.slotVar.slotGroupCode}" ${slots.map(s => ''+s).join(', ')}>`
  }
}
