
/**
 * Some examples.
 */
export const testText = `Some basic text.`
export const testNewlines = `Some basic text

That spans.

Multiple lines.`
export const testSpan = <span>A span, with <b>bold</b>.</span>

/**
 * About MinuteBox
 */
export const verifiedClaims = `This information is important to provide to support.`
//
//
//
/**
 * Global Go To
 */
export const globalGoTo = jss =>
  <div class={jss.description}>

    Quickly navigate to the <strong>Entity List</strong>, the <strong>Calendar</strong> and to <strong>Settings</strong> from anywhere in MinuteBox by pressing <kbd>G</kbd> and then the corresponding key listed above. For example, when viewing an entity, press <kbd>G then L</kbd> to return to List View.

  </div>
//
//
/**
 * EIS elements
 */

export const eisLegalName = jss =>
  <div class={jss.description}>

    The <strong>Entity Name</strong> is the <strong>Legal Name</strong> of an entity.

  </div>

export const eisMatterNumber = jss =>
  <div class={jss.description}>

  The <strong>Matter Number</strong> is sometimes referred to as the <strong>Retainer Number</strong> or <strong>Client Number</strong>of a client. It is the record number that your firm uses to refer to a legal matter. A <strong>Matter Number</strong> may be any combination of alphanumeric characters. Matter Number is only available to users of your MinuteBox account, <b>it is not shared</b> when the minute book is shared.
  </div>

export const eisClientStanding = jss =>
  <div class={jss.description}>
    <strong>Client Standing</strong> can be used for internal tracking of the status of a client. For example, you can indicate if a file is <strong>active</strong> or <strong>closed</strong>. Client Standing is only available to users of your MinuteBox account, <b>it is not shared</b> when the minute book is shared.
  </div>

export const eisAccountingNumber = jss =>
  <div class={jss.description}>
    <strong>Accounting Number</strong> is your firm's internal accounting or billing number associated with this client or entity.
  </div>

// export const eisResponsible = jss =>
//   <div class={jss.description}>
//     <strong>Responsible</strong> are the individual(s) in your firm that have carriage of this file. Keeping track of who is <strong>Responsible</strong> for a file is helpful when adding <strong>Filters</strong> in the <strong>Entity List</strong> or <strong>Calendar</strong>.

// </div>

// export const eisAuthorizedToInstruct = jss =>
//   <div class={jss.description}>


//   </div>


/**
 * Settings
 */

export const settingsSecondFactor = jss =>
  <div class={jss.description}>

  Enable Two Factor Authentication (2FA) for enhanced security while using MinuteBox. Once enabled, you will be required to input a secure code generated on your mobile device each time you login with your email. Enabling Two Factor Authentication will require you to download a free security application to your phone.


  </div>

export const settingsHardwareKey = jss =>
<div class={jss.description}>

Enable Hardware Key or Biometric Authentication for even greater security while using MinuteBox. Connect a USB authenticator, or make sure your device has a built in one like TouchID and then click "Add".


</div>

export const settingsDarkMode = jss =>
<div class={jss.description}>

Enabling Dark Mode will turn the background color of almost every element in MinuteBox to dark grey or black, giving a different experience to using MinuteBox. When set to "Auto" Dark Mode will automatically toggle on and off depending on your computer's Dark Mode setting. The "Auto" setting will also require a browser that supports auto-detecting system settings.


</div>

export const settingsAccountName = jss =>
<div class={jss.description}>

To change the account name, simply click the text box above, enter the name of your firm or company and press <kbd>Return</kbd> or <kbd>Enter</kbd> on your keyboard. Note that the account name will be used in outgoing emails from this account, such as sharing emails. Only administrators can change the account name.

</div>

export const settingsViewOption = jss =>
<div class={jss.description}>

Select the default view mode to use when viewing an entity.

</div>

export const settingsDocStyle = jss =>
<div class={jss.description}>

Download the Reference or Current template to change the default styles for exported Microsoft Word files. To upload your new styles, simply save the document once you edit it and drag and drop it onto the browser
window.

</div>
