import PdfSettings from 'pdf-viewer/PdfSettings'
import { MainView } from './MainView'

/**
 *    |– PDFView –| (abstract)
 */
export class PDFView extends MainView {
  constructor (panelProvider, params = {}) {
    super(panelProvider, params)
    let { zoom, scrollElement } = params
    zoom = zoom || ko.observable(1)
      .extend({ localStorage: `${this.constructor.name}.zoom` })
    Object.assign(this, {
      pdfSettings: new PdfSettings(this.panelProvider, zoom, scrollElement),
      zoom,
    })
  }

  get mainHTML () {
    const { jss } = this
    return (
      <div class={jss.container}>
        {this.pdfHtml}
      </div>
    )
  }

  get pdfHtml () { throw new Error('ø') }

  static get css () {
    return {
      ...super.css,
      container: {},
      dropPadContainer: {
        ...super.css.fullcompressed,
        position: 'sticky',
        pointerEvents: 'none',
        zIndex: '1',
        top: 'var(--main-sticky-offset)',
      },
      mainDropPad: {
        position: 'absolute',
        top: '-26px',
        height: 'calc(100vh - 88px)',
        width: '100%',
        //margin: '5px',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderRadius: '12px',
        border: `6px dashed var(--drop-pad-border-color)`,
        backgroundColor: 'rgba(0,0,0,0.1)',
        paddingBottom: '45px',
        fontSize: 'xx-large',
        color: 'white',
        fontWeight: 'bold',
        textShadow: '0 0 2px rgba(0, 0, 0, 1), 1px 1px 3px grey',
      },
    }
  }
}
