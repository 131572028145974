/**
 * These are styles common to the project.
 *
 * For a great JSS guide, see:
 *
 * https://medium.freecodecamp.org/css-in-javascript-the-future-of-component-based-styling-70b161a79a32
 */

import { hashHSL } from 'utils/string'

import color from './color'
import typography from './typography'

export { default as theme } from './theme'
export { default as border } from './border'
export { default as buttons } from './buttons'
export { default as dropdown } from './dropdown'
export { default as formBox } from './formBox'
export { default as input } from './input'
export { default as list } from './list'
export { default as calendar } from './calendar'

export { color, typography }

export const adminOnly = {
  'body:not([admin]) &': {
    display: 'none'
  }
}

export const hideIfHomeless = {
  'body[homeless] &': {
    display: 'none',
  },
}

export const heading = {
  fontFamily: typography.headFontFamily
}

export const tag = {
  ...color.tag,
  padding: '3px 5px 1px 5px',
  fontFamily: typography.mono,
  marginRight: '4px',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  borderRadius: '4px',
  transition: '0.3s',
  border: '1px solid #e8e8e8',
  textShadow: '0 0 0.1px #888',
  'body[dark] &': { // project batman
    backgroundColor: color.dark.tagBackground,
    filter: 'brightness(250%)',
  },
}

const TagSatLumen = {
  s: [50, 75],
  l: [10, 40],
}
export const tagColor = (tag: string) => {
  return hashHSL(tag, TagSatLumen)
}

// Indicate keyboard shortcut
export const key = {
  border: `1px solid ${color.actionColor}`,
  borderRadius: '6px',
  color: color.actionColor,
  padding: '2px 8px',
  fontSize: '14px',
  display: 'none' // TEMP; use e.g. "ALT" to show
}

export const highlights = {
  highlight1: {
    color: 'black',
    fontWeight: '600',
    fontSize: '85%',
    borderRadius: 3,
    backgroundColor: 'rgba(27,31,35,0.05)',
    padding: '.2em .4em'
  }
}
