import TurndownService from 'turndown'

import { unwrap } from 'utils/dom'

import Block from './Block'
import { bindVariables } from './bindings'

type SlotManager = import('../variables/slots').SlotManager

interface BodyBlockConstructor {
  new ({ body, align }): BodyBlock
}

const turndown = new TurndownService()
const NBSP = new RegExp(/\u00a0/g)

export default abstract class BodyBlock extends Block {
  body: KnockoutObservable<string>
  align: KnockoutObservable<string>

  get plainObservables () { return ['body', 'align'] }
  get isBlank () { return !this.body() }
  get isFocusable () { return true }
  get placeholderText () {
    return `Type here or press '/' for more options`
  }

  /**
   * Used for word/char count.
   */
  get asText (): string {
    const body = this.body() || ''
    const div = document.createElement('div')
    div.innerHTML = body
    this._removeWrappers(div)
    return div.textContent
  }

  _removeWrappers (node) {
    for (const child of node.children) {
      if (child.hasAttribute('var')) {
        child.before(' ')
        child.after(' ')
        unwrap(child)
      }
    }

    for (const child of [...node.children]) {
      if (child.hasAttribute('do-not-print')) {
        child.remove()
      } else {
        this._removeWrappers(child)
      }
    }

    for (const child of node.childNodes) {
      if (child instanceof Text) {
        child.nodeValue.replace(NBSP, ' ')
      }
    }
  }

  async bodyWithInterpolation (sm: SlotManager) {
    const div = document.createElement('div')
    div.innerHTML = this.body() || ''
    const vars = bindVariables(div, sm, null)
    await Promise.all(vars.map(v => v.whenLoaded))
    for (const v of vars) { if (v) { sm.removeVariable(v) } }
    this._removeWrappers(div)
    // We clone the node so the bindings don't update during any async ops.
    return div.cloneNode(true)
  }

  makeNextBlock (body: string) {
    const ThisCtor = this.constructor as BodyBlockConstructor
    return new ThisCtor({ body, align: this.align() })
  }


  abstract markdownLeadingChars (blocks: Block[]): string
  /**
   * Convert outer and inline tags to Markdown-flavoured styles.
   * See e.g. https://github.com/domchristie/turndown
   *
   * This is a dragon's nest of problems, often surrounding the white-space
   * relations i.e. HTML has tags that encompass
   */
  async asMarkdown (blocks: Block[], sm: SlotManager) {
    const chars = this.markdownLeadingChars(blocks)
    const div = await this.bodyWithInterpolation(sm)
    const body = turndown.turndown(div)
    return [chars, body].filter(f => f).join(' ')
  }

  setVariables (sm: SlotManager) {
    const div = document.createElement('div')
    div.innerHTML = this.body() || ''
    const vkeys = sm.variableList
    for (const v of div.querySelectorAll('[data-bind=wvar]')) {
      const json = v.getAttribute('var') || '{}'
      const c = JSON.parse(json)
      for (const key of vkeys) {
        c[key] = sm.getVariableValue(key)
      }
      v.setAttribute('var', JSON.stringify(c))
    }
    this.body(div.innerHTML)
  }

  // Propagate values to the variables, overwriting the `var="{...}"`.
  // propagateValues (values: object, filter: (v) => boolean) {
  //   const div = document.createElement('div')
  //   div.innerHTML = this.body() || ''
  //   const vkeys = Object.keys(values)
  //   for (const v of div.querySelectorAll('[data-bind=wvar]')) {
  //     const json = v.getAttribute('var') || '{}'
  //     const c = JSON.parse(json)
  //     if (!filter(c)) { continue }
  //     for (const key of vkeys) { c[key] = values[key] }
  //     v.setAttribute('var', JSON.stringify(c))
  //   }
  //   this.body(div.innerHTML)
  // }
}
