import {
  fromUnixTime, differenceInCalendarDays, parseISO
} from 'date-fns/esm'

/**
 * FilterOperation are performed on the models.
 *
 * Each operation has its `testFn` run against filterValue of
 * the given column of a given row.
 *
 * The `title` of the FilterOperation additionally used as an
 * identifier used for saving.  If the title changes, existing
 * filters may become invalid.
 */
export default class FilterOperation {
  /**
   * @param {string} title
   * @param {function} testFn
   * @param {JSX|null} inputJsx any JSX that comes after this question
   */
  constructor (opID, title, testFn, inputJsx = null, oparg) {
    Object.assign(this, { opID, title, testFn, inputJsx, oparg })
  }

  toJS () {
    return {
      opID: this.opID,
      oparg: ko.toJS(this.oparg) || ''
    }
  }
}

export function stringFilterOperations () {
  return () => {
    const input = ko.observable()
      .extend({ castRead: v => (v || '').toLowerCase() })
    const inputJsx = <input type='text' ko-textinput={input} />

    return [
      new FilterOperation('sw', 'starts with', f => f.startsWith(input()), inputJsx, input),
      new FilterOperation('ew', 'ends with', f => f.endsWith(input()), inputJsx, input),
      new FilterOperation('c', 'contains', f => f.includes(input()), inputJsx, input),
      new FilterOperation('!c', 'does not contain', f => !input() || !f.includes(input()), inputJsx, input),
      new FilterOperation('=', 'is', f => !input() || f === input(), inputJsx, input),
      new FilterOperation('!=', 'is not', f => f !== input(), inputJsx, input),
      new FilterOperation('ø', 'is empty', f => !f),
      new FilterOperation('!ø', 'is not empty', f => Boolean(f))
    ]
  }
}

export function boolFilterOperations () {
  return () => {
    return [
      new FilterOperation('ø', 'is true', f => Boolean(f)),
      new FilterOperation('!ø', 'is not true', f => !f)
    ]
  }
}

export function noteFilterOperations () {
  const noteCount = listOfNotes => listOfNotes.list().length
  const flagged = listOfNotes => listOfNotes.list().some(note => note.data().flagged)
  return () => {
    return [
      new FilterOperation('ø', 'is flagged', f => flagged(f)),
      new FilterOperation('!ø', 'is not flagged', f => !flagged(f)),
      ...intFilterOperations({ titlePrefix: 'count ', accessor: noteCount })()
    ]
  }
}

export function intFilterOperations ({ accessor } = {}) {
  accessor = accessor || (v => v)
  return () => {
    const input = ko.observable()
      .extend({ castRead: v => parseInt(v, 10) || '' })
    const inputJsx = <input type='text' ko-textinput={input} />

    return [
      new FilterOperation('=', 'is', f => accessor(f) === input(), inputJsx, input),
      new FilterOperation('<', 'is less than', f => accessor(f) < input(), inputJsx, input),
      new FilterOperation('>', 'is more than', f => accessor(f) > input(), inputJsx, input),
      new FilterOperation('<=', 'is equal or less than', f => accessor(f) <= input(), inputJsx, input),
      new FilterOperation('>=', 'is equal or more than', f => accessor(f) >= input(), inputJsx, input)
    ]
  }
}

/**
 * Filter based on {Firebase.Firestore.Timestamp}, which has a `seconds`
 * and `nanoseconds` property.
 */
export function timestampFilterOperations () {
  return () => {
    const input = ko.observable().extend({ rateLimit: 250 })
    const inputJsx = <input type='date' ko-textInput={input} />
    function timestampCmpFn (cmpFn) {
      return f => {
        if (!input()) { return }
        const modelDate = fromUnixTime((f || {}).seconds)
        const inputDate = parseISO(input())
        return cmpFn(differenceInCalendarDays(modelDate, inputDate))
      }
    }
    return [
      new FilterOperation('=', 'is', timestampCmpFn((v) => v === 0), inputJsx, input),
      new FilterOperation('<', 'is before', timestampCmpFn(v => v < 0), inputJsx, input),
      new FilterOperation('>', 'is after', timestampCmpFn(v => v > 0), inputJsx, input),
      new FilterOperation('<=', 'is on or before', timestampCmpFn(v => v <= 0), inputJsx, input),
      new FilterOperation('>=', 'is on or after', timestampCmpFn(v => v >= 0), inputJsx, input)
    ]
  }
}


/**
 * Filter an array of strings
 *
 * - has any of / has all of / is exactly / has none of / is empty / is not empty
 */
export function stringArrayFilterOperations () {
  return () => {
    const input = ko.observable()
      .extend({ castRead: v => (v || '').toLowerCase() })
    const inputJsx = <input type='string' ko-textInput={input} />
    return [
      new FilterOperation('any', 'has any of', f => f.some(s => s && s.toLowerCase().includes(input())), inputJsx, input),
      // new FilterOperation('has all of', f => f.every(s => s.includes(input())), inputJsx),
      new FilterOperation('!any', 'does not have', f => !f.some(s => s && s.toLowerCase().includes(input())), inputJsx, input),
      new FilterOperation('ø', 'is empty', f => !f.length),
      new FilterOperation('!ø', 'is not empty', f => f.length)
    ]
  }
}

export function tagFilterOperations (index) {
  return () => {
    const list = ko.observableArray([])
    const tagsEditor = <tags-editor index={index} tags={list} />

    return [
      new FilterOperation('any', 'has any of', f => !list.length || list().some(s => f.includes(s)), tagsEditor, list),
      new FilterOperation('all', 'has all of', f => !list.length || list().every(s => f.includes(s)), tagsEditor, list),
      new FilterOperation('!any', 'does not have', f => !list.length || !list().some(s => f.includes(s)), tagsEditor, list),
      new FilterOperation('ø', 'is empty', f => !f.length),
      new FilterOperation('!ø', 'is not empty', f => f.length)
    ]
  }
}

// export class DateOperation extends SelectOperation {
//   constructor () {
//     super()
//     Object.assign(this, {
//       withinOp: new DateWithinOperation(),
//       exactDateOp: new DateExactOperation()
//     })
//   }

//   get next () {
//     return this.selected() === 'is within' ? this.withinOp : this.exactDateOp
//   }

//   get options () {
//     return {
//       'is': f => this.exactDateOp.value().isSame(f, 'day'),
//       'is within': null,
//       'is before': null,
//       'is after': null,
//       'is on or before': null,
//       'is on or after': null,
//       'is not ': null,
//       'is empty': null,
//       'is not empty': null
//     }
//   }
// }

// export class DateWithinOperation extends SelectOperation {
//   get options () {
//     return {
//       'the past week': null,
//       'the past month': null,
//       'the past year': null,
//       'the next week': null,
//       'the next month': null,
//       'the next year': null,
//       'the next number of days': null,
//       'the past number of days': null
//     }
//   }
// }

// export class DateExactOperation extends SelectOperation {
//   constructor () {
//     super()
//     Object.assign(this, {
//       datePickerOp: new DatePickerOperation(),
//       dayCount: new IntegerPickerOperation()
//     })
//   }

//   value () {
//     return moment.utc().add(...this.getSelectedValue())
//   }

//   get options () {
//     return {
//       'today': [0, 'day'],
//       'tomorrow': [1, 'day'],
//       'yesterday': [-1, 'day'],
//       'one week ago': [-1, 'week'],
//       'one week from now': [1, 'week'],
//       'one month ago': [-1, 'month'],
//       'one month from now': [1, 'month'],
//       'number of days ago': () => [-1 * this.dayCount.number(), 'day'],
//       'number of days from now': [this.dayCount.number(), 'day'],
//       'exact date': [moment.utc().diff(this.datePickerOp.date(), 'day'), 'day']
//     }
//   }
// }
