import ViewComponent from 'ViewComponent'
import { color } from 'styles'

export default class DatePickerInline extends ViewComponent {
  drawerSide: boolean
  onSelect: (value: Date) => void
  onClear: () => void
  relatedDateGenerator: RelatedDatesSource
  value: KnockoutObservable<Date>

  constructor ({ relatedDateGenerator, value, classes, onSelect, onClear, drawerSide }) {
    super()
    Object.assign(this, {
      relatedDateGenerator,
      value: value || ko.observable(),
      drawerSide,
      onSelect,
      onClear,
    })
    Object.assign(this.jss, classes)
  }

  onDatePickerSelect (date: Date) {
    this.onSelect && this.onSelect(date)
    this.value(date)
  }

  static get css () {
    return {
      picker: {
        padding: '15px',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
        border: '1px solid grey',
        boxShadow: '2px 2px 21px 7px rgba(0,0,0,0.2)',
        borderRadius: '3px',
        '--related-list-top': '-12px',
        '--related-list-horizontal': '14px',
        '--related-list-items-height': '280px',
      },
    }
  }


  get template () {
    const { jss, value } = this
    return (
      <div class={jss.picker} ko-ownClick={() => {}}>
        <date-picker
          drawerSide={this.drawerSide}
          relatedDates={this.relatedDateGenerator}
          onSelect={(v: Date) => this.onDatePickerSelect(v)}
          onClear={() => this.onClear()}
          focus={value} />
      </div>
    )
  }
}

DatePickerInline.register()
