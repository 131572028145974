import ViewComponent from 'ViewComponent'
import { typography } from 'styles'

import { flagUrlOf, nameOf } from './lookup'
import { colorOf } from './utils'

export default class JurisdictionIndicator extends ViewComponent {
  value: KnockoutObservable<string>

  constructor ({ value }) {
    super()
    Object.assign(this, { value })
  }

  templateHTML (value : KnockoutObservable<string>|string) {
    const { jss } = this
    const v = ko.unwrap(value)
    const jurisdictionFlagURL = `url("${flagUrlOf(v) || ''}")`
    const colorStyle = `--flag-bg-color: ${colorOf(v)}`

    return (
      <div class={jss.indicator}
        style={colorStyle}
        jurisdiction={value} >
        <div class={jss.flag}
          ko-style={{ backgroundImage: jurisdictionFlagURL }} />
        <div class={jss.name}>{nameOf(v)}</div>
      </div>
    )
  }

  get template () {
    return this.computed(() => this.templateHTML(this.value))
      .extend({ deferred: true })
  }

  static get css () {
    return {
      ...super.css,
      ...this.indicatorsCSS,
      flag: {
        width: '30px',
        minWidth: '25px',
        height: '16px',
        margin: 4,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        '[jurisdiction=""] &': {
          display: 'none'
        },
      },
      name: {
        padding: '3px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:first-child':{
          marginRight: '5px',
          fontStyle: 'italic',
          fontWeight: 'bold'
        },
        '&[focused]': {
          ...super.css._indicatorFocused,
          padding: '3px 5px',
          // background: '#dfe3e6',
          // textDecoration: 'underline',
        },
        '&:empty': { display: 'none' },
      },
    }
  }

  static get indicatorsCSS () {
    return {
      indicator: {
        display: 'flex',
        // background: 'linear-gradient(to bottom, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#000000 100%)',
        background: `var(--flag-bg-color)`,
        color: 'white',
        alignItems: 'center',
        borderRadius: 3,
        fontSize: '0.6em',
        fontFamily: typography.bodyFontFamily,
        fontWeight: '400',
        textTransform: 'uppercase',
        letterSpacing: 0.8,
        padding: '0px 5px 0px 5px',
        '&[focused]': {
          // ...super.css._indicatorFocused
          borderRadius: 3,
          width: 'fit-content',
          color: 'black',
          background: '#ffffff',
          boxShadow: 'none',
          transition: 'box-shadow 300ms ease-out, background 100ms ease-out',
          padding: '0px 0px'
        },
        '&[jurisdiction=""], &:not([jurisdiction])': {
          display: 'none'
        },
      }
    }
  }
}

JurisdictionIndicator.register()
