
import EisMultiComponentField from './EisMultiComponentField'

import { typography } from 'styles'

import { CLIENT_STANDING } from '../constants'

export default class EisStandingAccounting extends EisMultiComponentField {
  constructor(args) {
    super(args)
    this.registerNavigation()
  }

  get componentNames () { return ['client_standing', 'accounting_number'] }

  static get css () {
    return {
      ...super.css,
      contentArea: {
        gridArea: 'content',
        display: 'grid',
        gridTemplateColumns: '1fr auto 65px',
      },
      standingTitle: {
        ...super.css.title,
      },
      standingTitleEdit: {
        ...super.css.title,
        gridColumn: '1'
      },
      accountingTitle: {
        ...super.css.title,
      },
      accountingTitleEdit: {
        ...super.css.title,
        gridColumn: '3',
      },
      field1Area: {
        display: 'flex',
        alignItems: 'flex-end',
      },
      standing: {
        ...super.css.textContent,
        '[blank] &': { display: 'none' },
      },
      accountingNumber: {
        ...super.css.indicator,
        '[blank] &': { display: 'none' },
      },
      editControls: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        padding: 10
      },
      inputFieldMeta: {
        gridColumn: '1',
        gridRow: '2'
      },
      inputFieldMeta2: {
        gridColumn: '3',
        gridRow: '2'
      },
      inputField: {
        width: '-webkit-fill-available',
        height: 40,
        fontFamily: typography.bodyFontFamily,
        padding: 5,
        margin: 5,
        fontSize: '1rem',
        borderRadius: 2,
        backgroundColor: '#ffd502',
        border: '1px solid transparent',
        borderBottom: '1px solid black',
        transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        '&:focus': {
          outline: 'none',
          transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        }
      }
    }
  }

  get titleAreaHTML() {}

  get standingTitleHTML () {
    return (
      <div class={this.jss.standingTitle}>
        <span>Client Standing</span>
        <q-tip class={this.jss.qtip} name={'eisClientStanding'} />
        {this.copyIconHTML('client_standing')}
      </div>
    )
  }

  get displayHTML () {
    const { jss } = this
    const standingFocus = this.computed(() => this.inputfocus.client_standing() || undefined)
    const accountingFocus = this.computed(() => this.inputfocus.accounting_number() || undefined)
    return [
      this.standingTitleHTML,
      <div class={jss.indicatorTitleArea}>
        <div class={jss.accountingTitle}>
          <span>Accounting Number</span>
        </div>
      </div>,
      <div class={jss.indicatorTitleExtras}>
        <q-tip class={jss.qtip} name={'eisAccountingNumber'} />
        {this.copyIconHTML('accounting_number')}
      </div>,
      <div class={jss.field1Area}>
        <div class={jss.standing} focused={standingFocus}
          blank={this.computed(() => this.componentsMap.client_standing() ? undefined : 'true')}>
          {this.displayValue.client_standing}
        </div>
      </div>,
      <div class={jss.indicatorArea}>
        <div class={jss.accountingNumber} focused={accountingFocus}
          blank={this.computed(() => this.componentsMap.accounting_number() ? undefined : 'true')}>
          {this.displayValue.accounting_number}
        </div>
      </div>,
    ]
  }

  get editHTML () {
    const { jss } = this
    const koEvent = { keydown: this.inputKeyDown({ 'Tab': evt => this.onTabDown(evt) }) }
    return (
      <div class={jss.editControls}>
        <div class={this.jss.standingTitleEdit}>
          <span>Client Standing</span>
          <q-tip class={this.jss.qtip} name={'eisClientStanding'} />
        </div>
        <div class={this.jss.accountingTitleEdit}>
          <span>Accounting Number</span>
          <q-tip class={this.jss.qtip} name={'eisAccountingNumber'} />
        </div>
        <div class={jss.inputFieldMeta}>
          <model-property-picker my='top left' at='bottom left'
            inputClass={jss.inputField}
            indexName='entity'
            hasFocus={this.computed(() => this.editing() && this.inputfocus.client_standing())}
            value={this.inputs.client_standing}
            propertyGetter={e => e.answerFor('client_standing')}
            showMatchesOnFocus={true}
            extraChoices={CLIENT_STANDING}
            placeholder='Client standing' />
        </div>
        <div class={jss.inputFieldMeta2}>
          <input type='text' placeholder='Accounting number' ko-textInput={this.inputs.accounting_number}
            ko-hasFocus={this.computed(() => this.editing() && this.inputfocus.accounting_number())}
            ko-event={koEvent} class={jss.inputField} />
        </div>
      </div>
    )
  }
}

EisStandingAccounting.register()
