import MinuteBoxApp from "minute-box-app"
import AccountModel from 'AccountModel'

export default abstract class Transition {
  constructor (public app: MinuteBoxApp,
    public authManager: AuthManager,
    public accountModel: AccountModel) {}

  abstract get implementationDate () : iso8601
  abstract get transitionName () : string
  abstract async isNeeded () : Promise<boolean>
  abstract run () : void

  get transitionID () {
    return `${this.implementationDate}-${this.transitionName}`
  }

  static async runAll (transitions: Transition[]) {
    for (const transition of transitions) {
      if (!await transition.isNeeded()) { continue }
      console.log(` ❄️️  Running transition ${transition.transitionID}`)
      await transition.run()
      console.log(` ❄️  Transition ${transition.transitionID} complete.`)
    }
  }
}
