
import {
  isString, isDate, isObject, isFinite, isEqual
} from 'lodash-es'
import { isValid } from 'date-fns/esm'

import { DataComponent } from 'DataModel/components'
import { fromISO8601, fromObject, MONTHS, LEAP_YEAR } from 'utils/dates'

//
// Date parts
// Creates object as an argument (i.e. {y:, M:, d:}.
//
export default class DateParts extends DataComponent {
  y: KnockoutObservable<number|string> = this.y
  M: KnockoutObservable<number|string> = this.M // indexed from 0
  d: KnockoutObservable<number|string> = this.d
  dateValue: KnockoutComputed<Date|null> = this.dateValue
  MONTHS: string[] = this.MONTHS

  static get namespace () { return 'date' }

  init () {
    this.y = ko.observable(null)
    this.M = ko.observable(null)
    this.d = ko.observable(null)
    this.dateValue = ko.pureComputed({
      read: () => this.asDateOrNull(),
      write: v => this.set(v)
    }).extend({ deferred: true })

    this.component_instance = this
    this.MONTHS = MONTHS
  }

  get () { return this }

  setValues (y, M, d) {
    this.y(y)
    this.M(M)
    this.d(d)
  }

  asISOStringOrNull () : iso8601 | null {
    const parts = this.d() || this.M() || this.y()
    if (parts === null) { return null }
    const fullISOString = fromObject(this.toJS()).toISOString() || ''
    return fullISOString.slice(0, 10) || null
  }

  asDateOrNull () : Date | null {
    return fromISO8601(this.asISOStringOrNull())
  }

  set (m: string | Date | DateObject) {
    if (m === null || m === '') {
      this.setValues(null, null, null)
    } else if (isString(m) || isDate(m)) {
      const date = typeof m === 'string' ? fromISO8601(m) : m
      if (!isValid(date)) {
        throw new Error(`Invalid date assignment '${m}'`)
      }
      this.setValues(date.getFullYear(), date.getMonth(), date.getDate())
    } else if (m && isObject(m)) {
      let M
      M = parseInt(ko.unwrap(m.M), 10)
      this.setValues(
        ko.unwrap(m.y) || null,
        isFinite(M) ? M : null,
        ko.unwrap(m.d) || null)
    } else if (m) {
      throw new Error(`DateParts:: set` + m)
    }
  }

  toJS() {
    var M = parseInt(this.M(), 10)
    return {
      d: parseInt(this.d(), 10) || null,
      M: isFinite(M) ? M : null,
      y: parseInt(this.y(), 10) || null
    }
  }

  is_blank() {
    return this.d() === null && this.M() === null && this.y() === null
  }

  is_equal(other) {
    return isEqual(other, this.toJS())
  }

  validate () {
    // Like any other date Feb 29 is valid, w/o year.
    var y = this.y() || LEAP_YEAR
    var m = this.M()
    var d = this.d()
    var ymd = [y]
    if (m) {
      ymd.push(m) // Y + M
      if (d) { ymd.push(d) } // Y + M + D
    } else if (d && d > 31) {
      // Y + D
      return "The day is too high"
    }
    if (!isValid(fromObject(ymd))) { return "Not a valid date" }
  }

  static get hasReminderDates () { return true }

  get reminderDateReason () { return this.title }
  isValidReminderDate (...args) {
    return [...args].every(n => isFinite(ko.unwrap(n)))
  }

  reminderDates (given) {
    return this.makeReminderDate()
  }

  makeReminderDate (y = this.y(), M = this.M(), d = this.d()) : RelatedDate[] {
    if (!this.isValidReminderDate(y, M, d)) { return null }
    return [{
      date: this.asDateOrNull(),
      reason: this.reminderDateReason,
      identifier: this.identifier,
      component: this,
      model: this.model
    }]
  }

  get mergeTemplateId () { return 'preview__object-table' }
  static get MONTHS () { return MONTHS }
}

DateParts.register()
