import Picker from './picker'

const DEFAULT_AT = {
  my: 'top left',
  at: 'bottom left',
}

type EntityModel = import('EntityModel').default

export default class EntityPicker extends Picker<EntityModel> {
  searchType: 'startsWith' | 'includes'
  suggestProperty: string

  constructor (params) {
    super(Object.assign({}, DEFAULT_AT, params))
    const {  searchType, suggestProperty } = params
    Object.assign(this, {
      searchType,
      suggestProperty: suggestProperty || 'name'
    })
  }

  choices () : EntityModel[] {
    const { memoryDB } = window.app
    return [...memoryDB.getListOf('entity')]
  }

  filterChoice (item: EntityModel, query = '') {
    const itemStr = item.cvModelTitle
    const canonStr = (itemStr || '').toLowerCase().trim()
    return canonStr[this._searchType]((query || '').toLowerCase())
  }

  itemHTML (value: EntityModel) {
    const { jss } = this
    return <div class={jss.itemValue}>{value.cvModelTitle}</div>
  }

  get _searchType () { return this.searchType || 'includes' }
  get noMatchesMessage () { return 'Nothing matches. [Enter] to add.' }
  get searchMessage () { return 'Type to search or add' }

  static get css () {
    return {
      ...super.css,
      anchor: {
        ...super.css.anchor,
        width: '100%',
      },
    }
  }
}

EntityPicker.register()
