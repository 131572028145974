
import ArrayComponent from './ArrayComponent'
import UnitView from './UnitView'


export class Responsible extends UnitView {
  get elvValue () { return this.person().name.slice(0, 1).pop() }

  get fields () {
    return {
      person: 'person',
      start: 'pureDate',
      end: 'pureDate'
    }
  }

  * getRoles () : IterableIterator<PersonRoleRecord> {
    yield { title: 'Responsible', start: this.start(), end: this.end() }
  }
}

export default class ResponsibleComponent extends ArrayComponent {
  // EIS 2.0 => 2.1 🐫 for EntityModel
  private transition (value: string | object) : { person: Partial<PersonRecord> } {
    if (typeof value !== 'string') { return value as { person: PersonRecord } }
    if (value.includes('@')) { return { person: { email: [value] } } }
    return { person: { name: [value] } }
  }

  set (values = []) {
    super.set(values.map(this.transition))
  }

  get ItemClass () { return Responsible }
}

ResponsibleComponent.register()
