
import icons from 'icons'
import { lastEditableRange } from 'utils/editable'
import { containerElement } from 'utils/range'

import { Command, Variable } from './Command'

type SlotManager = import('../variables/slots').SlotManager
type SlotFactory = (sm: SlotManager) => Variable
type VariableCommand = import('./Command').VariableCommand

export class InjectVariableCommand extends Command implements VariableCommand {
  constructor (text: string, icon: JSX, private vfn: SlotFactory, private slotCode: string = null) {
    super(text, icon)
  }

  get variableSlotCode () { return this.slotCode }

  html (jss, highlightedText): JSX {
    return (<>
      <div class={jss.commandIcon}>{icons.inline(this.icon)}</div>
      <div class={jss.commandText}>{highlightedText}</div>
      <div class={jss.commandKey}></div>
    </>)
  }

  trigger () {
    /**
     * Clear any contents of the current selectiona and gather needed
     * references.
     */
    const r = lastEditableRange()
    r.deleteContents()
    const cac = containerElement(r)
    const sm = ko.dataFor(cac.closest('[editor]'))
      .document
      .slotManager as SlotManager

    /**
     * Build the <span> for injection
     */
    const span = document.createElement('span')
    span.setAttribute('data-bind', `wvar`)
    span.setAttribute('contentEditable', 'false')
    span.setAttribute('var', JSON.stringify(this.vfn(sm).serialize()))

    /**
     * Insert the node and scroll it into view.
     */
    const cv = cac.closest('[contenteditable=false]')
    if (cv) {
      r.selectNode(cv)
      r.collapse(false)
    }
    r.insertNode(span)
    r.collapse(false)
    const ce = span.closest('[contenteditable=true]')
    ce.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })

    /**
     * Dispatch an event to tell the `BodyBlockEditor` that a variable
     * has been injected.
     */
    ce.dispatchEvent(new CustomEvent('variableinjection'))

    /**
     * Select the current range.
     */
    const s = window.getSelection()
    s.removeAllRanges()
    const r2 = new Range()
    r2.selectNode(span)
    r2.collapse(false)
    s.addRange(r2)
  }
}
