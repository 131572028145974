
import ConfirmDelete from './confirm-delete'

class ConfirmSectionDelete extends ConfirmDelete {
  constructor (params) {
    super(params)
    const { section } = params
    Object.assign(this, { section })
  }

  static get css () {
    return {
      ...super.css,
      sectionName: {
        fontWeight: '600'
      }
    }
  }

  get messageHTML () {
    return [
      <p>
        Are you sure you want to delete the section <span class={this.jss.sectionName}>{this.section.name()}</span>?
      </p>,
      <p>This will <span class={this.jss.sectionName}>only delete the section reference</span>. This action will not delete any pages.</p>
    ]
  }
}

ConfirmSectionDelete.register()
