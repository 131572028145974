
import ViewComponent from 'ViewComponent'

import { buttons, color } from 'styles'

import './capital-share-mass-conversion-editor'
import './capital-share-individual-conversion-editor'

export default class CapitalConversionTypeChoice extends ViewComponent {
  showing: (v?: any) => any
  relatedGenerator: RelatedSource
  transactions: any[]
  assetClassID: string
  persons: PersonRecordSet
  private _mc: any
  private _ic: any

  constructor (params) {
    super()
    const {
      assetClassID, transactions, relatedGenerator, persons,
    } = params
    Object.assign(this, {
      assetClassID,
      transactions,
      relatedGenerator,
      persons,
      showing: ko.observable()
    })

    this.massClick() // show mass convert by default.
  }

  static get css () {
    return {
      ...super.css,
      layout: {},
      choices: {
        display: 'flex',
        justifyContent: 'space-evenly',
      },
      _choice: {
        padding: '10px',
        marginBottom: '20px',
        textAlign: 'center',
        '&[selected=true]': {
          borderBottom: `6px solid ${color.brandYellow}`
        },
        '&[selected=false]': {
          color: color.text.light.secondary,
          'body[dark] &': { // project batman
            color: color.text.dark.secondary,
          },
          '&:hover': {
            borderBottom: `6px solid ${color.brandYellow}`
          }
        }
      },
      mass: {
        extend: '_choice',
        ...buttons.clickable,
      },
      individual: {
        extend: '_choice',
        ...buttons.clickable,
      },
    }
  }

  massClick () {
    this._mc = this._mc || <capital-share-mass-conversion-editor
      relatedGenerator={this.relatedGenerator}
      assetClassID={this.assetClassID}
      transactions={this.transactions} />
    this.showing(this._mc)
  }

  individualClick () {
    this._ic = this._ic || <capital-share-individual-conversion-editor
      persons={this.persons}
      relatedGenerator={this.relatedGenerator}
      assetClassID={this.assetClassID}
      transactions={this.transactions} />
    this.showing(this._ic)
  }

  get choiceHTML () {
    const { jss } = this
    return (
      <div class={jss.choices}>
        <div class={jss.mass}
          selected={this.computed(() => this.showing() === this._mc)}
          ko-ownClick={() => this.massClick()}>
          Everyone
        </div>
        <div class={jss.individual}
          selected={this.computed(() => this.showing() === this._ic)}
          ko-ownClick={() => this.individualClick()}>
          Individual
        </div>
      </div>
    )
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        {this.choiceHTML}
        {this.showing}
      </div>
    )
  }
}

CapitalConversionTypeChoice.register()
