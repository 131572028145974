
import { variableFactory, Variable } from '../variables'
type SlotManager = import('../variables/slots').SlotManager
type WritingDocumentEditor = import('../writing-views').WritingDocumentEditor

/**
 * @return a list of variables.
 */
export function bindVariables (node: Element, slotManager: SlotManager, editor: WritingDocumentEditor): Variable[] {
  const vars = []
  const wvars = node.querySelectorAll('[data-bind=wvar]')
  for (const node of wvars) {
    const data = ko.dataFor(node)
    if (data) {
      vars.push(data.variable)
      continue
    }
    const varStr = node.getAttribute('var')
    if (!varStr) { continue }
    try {
      const variable = variableFactory(JSON.parse(varStr), slotManager)
      vars.push(variable)
      ko.applyBindings({ variable, editor }, node)
    } catch (err) {
      console.error(`Binding error over JSON:`, varStr)
      console.error(`Unable to bind variables`, err)
    }
  }
  return vars
}
