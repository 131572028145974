
/**
* Seedable pseudo-random number generator
* Generates floats between 0 and 1.
*
* From https://stackoverflow.com/a/47593316
*/
export function * PRNG (seedStr = String(new Date())) {
  let seed = 0x6a09e667 ^ seedStr.length
  for (let i = 0; i < seedStr.length; i++) {
    seed = Math.imul(seed ^ seedStr.charCodeAt(i), 0xcc9e2d51)
    seed = seed << 13 | seed >>> 19
  }
  seed = Math.imul(seed ^ seed >>> 16, 0x85ebca6b)
  seed = Math.imul(seed ^ seed >>> 13, 0xc2b2ae35)
  seed = (seed ^= seed >>> 16) >>> 0
  while (true) {
    let t = seed += 0x6D2B79F5
    t = Math.imul(t ^ t >>> 15, t | 1)
    t ^= t + Math.imul(t ^ t >>> 7, t | 61)
    yield ((t ^ t >>> 14) >>> 0) / 0x100000000
  }
}
