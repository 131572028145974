import { get } from 'lodash-es'
import { lookup } from 'jurisdictions/lookup'

export type ALIAS_TYPES = 'jurisdiction' | 'statute'

function jurisdictionToOption (j) {
  return j ? {
    'full': [j.area, j.state].join(', '),
    'state': j.state,
    'area': j.area,
    'fullISO': j.iso,
    'stateISO': j.stateISO,
  } : null
}


const jurisdiction = {
  options (v) { return jurisdictionToOption(lookup(v)) },
  get (v, alias) { return get(jurisdiction.options(v), [alias], v) },
}


const statute = {
  CBCA: {
    common: "the “Act”",
    initials: "C.B.C.A.",
    short: "Canada Business Corporations Act",
    cite: "Canada Business Corporations Act, R.S.C., 1985, c. C-44",
  },

  OBCA: {
    common: "the “Act”",
    initials: "O.B.C.A.",
    short: "Ontario Business Corporations Act",
    cite: "Ontario Business Corporations Act, RSO 1990",
  },

  options (v) { return get(statute, [v]) },
  get (v, alias) { return get(statute, [v, alias], v) },
}

const by = {
  statute, jurisdiction,
}


export function aliasOptions (aliasBy: ALIAS_TYPES, value: string) {
  return aliasBy && by[aliasBy].options(value)
}

export function aliasLookup (aliasBy: ALIAS_TYPES, chosenAlias: string, value: string) {
  return aliasBy ? by[aliasBy].get(value, chosenAlias) : value
}
