
import { QuestionModel, QuestionDescriptor } from 'DataModel/QuestionModel'

import { wordCount, charCount, modelTitle } from '../utils'

import './PrecedentWritingComponent'

const questions: QuestionDescriptor[] = [
  { title: 'Title', identifier: 'title', component: 'primitive.string' },
  { title: 'Content', identifier: 'writing', component: 'writing.precedentwriting' },
]

export default class PrecedentModel extends QuestionModel {
  get cvModelTitle () { return modelTitle(this) }
  get wordCount (): number { return wordCount(this.answerFor('writing')) }
  get charCount (): number { return charCount(this.answerFor('writing')) }
  get QUESTIONS () { return questions }
}

PrecedentModel.register()
