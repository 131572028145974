import ViewComponent from "ViewComponent"

import { typography } from 'styles'
import EisTable from 'entity-information-summary/tables/EisTable'
import { JSXContent } from "minute-box-app/panel-left"

export type TableRow = JSXContent[]

export default class BasicTable extends ViewComponent {
  title : JSXContent
  headers : string[] | KnockoutObservableArray<string>
  rows? : KnockoutObservableArray<TableRow>
  columns? : JSXContent[] | KnockoutObservableArray<JSXContent[]>

  constructor (params) {
    super()
    const { name, headers, rows, columns } = params
    Object.assign(this, { title:name, headers, rows, columns })
    if (columns) {
      if (!this.rows) { this.rows = ko.observableArray([]) }
      this.computed(() => { this.rows([...this.makeRowsFromColumns()]) })
    }
  }

  *makeRowsFromColumns () : IterableIterator<TableRow> {
    const columns = ko.unwrap(this.columns)
    if (!columns || !columns.length) { return }
    const rowCount = columns[0].length
    for (let i=0; i<rowCount; i++) {
      yield columns.map(col => col[i])
    }
  }

  static get css () {
    return {
      ...EisTable.css,
      table: { extend: 'grid' },
      title: {
        marginBottom: '10px',
      },
      tableHeaderCell: {
        fontFamily: typography.bodyFontFamily,
        fontSize: '0.8em',
        fontWeight: 'bold',
      },
      tableHeader: { position: 'static' },
      tableValueCell: {
        fontFamily: typography.bodyFontFamily,
        fontSize: '0.8em',
      },
      tableValue: {
        margin: '0.8em 0',
      },
      tableDashedLine: {
        '&:not(:last-child)': {
          extend: 'gridBorder',
        },
      },
    }
  }

  get template () {
    const { jss } = this
    const headers = this.headers as string[]
    const columnsStyle = this.computed(() => (
      `grid-template-columns: repeat(${headers.length}, 1fr)`
    ))
    const titleStyle = this.title && this.computed(() => (
      `grid-column: 1/${headers.length + 1}`
    ))
    return (
      <div class={jss.table} style={columnsStyle}>
        { this.title ? (
          <div class={jss.title} style={titleStyle}>{this.title}</div>
        ) : '' }
        { headers.map(header => (
          <div class={jss.tableHeaderCell}>
            <span class={jss.tableHeader}>{header}</span>
          </div>
        ))}
        { this.rows.flatMap(row => [
          ...row.map(value => (
            <div class={jss.tableValueCell}>
              <div class={jss.tableValue}>{value}</div>
            </div>
          )),
          <div class={jss.tableDashedLine} />
        ])}
      </div>
    )
  }
}

BasicTable.register()
