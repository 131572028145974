import { format } from 'date-fns'

import { isInteger } from 'utils/number'
import { computed } from 'utils/decorator'

import { aliasOptions } from './aliases'
import { IterableComponentVariable } from './IterableComponentVariable'

type UnitView = import('DataModel/components').UnitView

/**
 * A variable on a `UnitView` on an `ArrayComponent`.
 */
export class ArrayComponentVariable extends IterableComponentVariable<UnitView[]> {
  index = ko.observable<string|number>()
  property = ko.observable<string>()
  alias = ko.observable<string>()
  Component: ArrayComponentStatic

  get code () { return 'acv' }
  get hasSlots () { return true }
  get isCohate () { return super.isCohate && this.property() && true }
  get iterationChosen () { return this.index() === '*' }
  get slotIterTitleText () { return this.componentValue[this.index()].elvValue }
  unitValue (c: UnitView) { return ko.unwrap(c[this.property()]) }
  chosenValue () { return this.unitValue(this.componentValue[this.index()]) }
  propagate (to: ArrayComponentVariable) { to.index(this.index()) }
  * iterations () { yield * this.componentValue.map(c => this.unitValue(c)) }
  get iterationIndex () { return this.index }
  * iterationKeys () { yield * Object.keys(this.componentValue) }
  get isValidIndex () {
    return isInteger(this.index()) && this.componentValue.length > this.index()
  }

  get incohateText () {
    const C = this.Component
    if (!C) { return `A data structure that is no longer used.[acv]` }
    return super.incohateText + ` ${C.i18nStringFor(this.property())}`
  }

  /**
   * This is purely (poorly) based on naming convention.
   */
  get isJurisdiction () { return this.property() === 'jurisdiction' }

  @computed()
  get aliases () {
    return this.isJurisdiction
      && this.isCohate
      && aliasOptions('jurisdiction', this.value())
  }

  get leadTransforms () {
    if (!this.isJurisdiction) { return super.leadTransforms }
    return [
      { type: 'alias', parameters: { alias: this.alias(), by: 'jurisdiction' }},
      ...super.leadTransforms,
    ]
  }
}


/**
 * A `pureDate` on a `UnitView` on an `ArrayComponent`
 */
export class ArrayComponentPureDate extends ArrayComponentVariable {
  format: KnockoutObservable<string> = ko.observable()

  get code () { return 'acpd' }
  get safeFormat () { return this.format() || 'yyyy-MM-dd' }
  get isCohate () { return super.isCohate && this.value() && true }
  get emptyValueHTML () { return 'Date not set' }
  get isJurisdiction () { return false }
  unitValue (c: UnitView) {
    try {
      const scv = ko.unwrap(super.unitValue(c))
      return scv ? format(scv, this.safeFormat) : this.emptyValueHTML
    } catch (err) {
      console.error(`Error`, err)
      return null
    }
  }
}

/**
 * A `person` on a `UnitView` on an `ArrayComponent`
 */
export class ArrayComponentPerson extends ArrayComponentVariable {
  personProperty = ko.observable<string>()
  personPropertyIndex = ko.observable<string>()
  alias = ko.observable<string>()

  get code () { return 'acp' }
  get isCohate () { return this.personProperty() && super.isCohate }
  get isJurisdiction () { return this.personProperty() === 'residency' }
  get incohateText () {
    return super.incohateText + ` ${this.personProperty() || 'ø'}`
  }

  unitValue (c: UnitView) {
    const pi = this.personPropertyIndex() || 0
    return super.unitValue(c)[this.personProperty()][pi]
  }
}
