import { sortBy } from 'lodash-es'

import selectIcon from 'icons/light/i-cursor'

import { Command, TitleCommand, SubmenuCommand } from './Command'
import { CommandMenu } from './CommandMenu'

type PrecedentModel = import('writing/precedents/PrecedentModel').default
type WritingDocument = import('writing/WritingDocument').default

export class InsertPrecedentCommand extends Command {
  constructor (private p: PrecedentModel, private w: WritingDocument) {
    super(p.cvModelTitle, null)
  }

  trigger () { this.w.insertPrecedent(this.p) }

  html (jss, highlightedText: JSX) {
    return (
      <>
        <div class={jss.commandText}>{highlightedText}</div>
        <div class={jss.commandKey}></div>
      </>
    )
  }
}



export function precedentCommandSubmenu (wd: WritingDocument) {
  const p = sortBy(
    window.app.memoryDB.getListOf('precedent')().filter(p => p.cvModelTitle),
    'cvModelTitle')
  const sm = new CommandMenu(null)
  sm.add(
    new TitleCommand('Insert Precedent'),
    ...p.map(p => new InsertPrecedentCommand(p, wd)))
  return new SubmenuCommand('Insert Precedent', selectIcon, sm)
}
