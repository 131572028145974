import ViewComponent from 'ViewComponent'
import { color } from 'styles'
import { inline } from 'icons'
import extLinkIcon from 'icons/regular/external-link-square'

export default class PersonDetails extends ViewComponent {
  person: PersonRecord | KnockoutObservable<PersonRecord> | KnockoutComputed<PersonRecord>
  suggestedPerson: PersonRecord | KnockoutObservable<PersonRecord> | KnockoutComputed<PersonRecord>

  constructor ({ person, suggestedPerson }) {
    super()
    Object.assign(this, {
      person,
      suggestedPerson: suggestedPerson || person,
    })
  }

  static get css () {
    return {
      container: {
        position: 'relative',
        ...color.dynamicSet({
          'bg': color.systemBackground,
          'text': color.text,
        }),
        backgroundColor: 'var(--bg-primary)',
        color: 'var(--text-primary)',
        borderRadius: '2px',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
        padding: '2px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      field: {
        '&[suggestion]': {
          color: 'var(--text-secondary)',
        },
      },
      fieldLine: {
        margin: '5px 10px',
      },
      name: {
        extend: 'fieldLine',
        marginRight: '30px',
        fontSize: 'large',
        fontWeight: 'bold',
      },
      linkIcon: {
        position: 'absolute',
        right: '5px',
        top: '5px',
        '--icon-height': '20px',
      },
    }
  }

  get fieldListHTML () {
    const { jss } = this
    const fields = ['email', 'phone', 'address']
    const person = ko.unwrap(this.person)
    const mPerson = ko.unwrap(this.suggestedPerson)
    const isSuggestion = (f, v) => (
      !person[f] || !person[f].find(_v => _v === v) || undefined
    )
    return fields
      .filter(f => mPerson[f] && mPerson[f].some(v => v))
      .map(f => mPerson[f]
        .filter(v => v)
        .map(v =>
          <span class={jss.field} suggestion={isSuggestion(f,v)}>{v}</span> )
        .flatMap(v => ([v, ', ']))
        .slice(0, -1))
      .map(v => <div class={jss.fieldLine}>{v}</div>)
  }

  get template () {
    const { jss } = this
    const name = this.computed(() => {
      const person = ko.unwrap(this.person)
      return person && person.name && person.name.find(v => v)
    })
    return (
      <div class={jss.container} ko-ownClick={() => window.app.viewPerson(this.suggestedPerson)}>
        <div class={jss.name}>{name}</div>
        <div class={jss.linkIcon}>{inline(extLinkIcon)}</div>
        {this.computed(() => this.fieldListHTML)}
      </div>
    )
  }

}

PersonDetails.register()
