import { UX_STATE } from './interfaces'

type SlotVariable = import('./interfaces').SlotVariable
type SlotGroup = import('./SlotGroup').default

const HOVERED_SLOTS = []

export default class Slot {
  private items: KnockoutObservableArray<SlotVariable> = ko.observableArray([])

  constructor (private group: SlotGroup, private _identifier: string = null) {}

  * [Symbol.iterator] () { yield * this.items }
  contains (v: SlotVariable) { return this.items().includes(v) }
  first () { return this.items()[0] || null }
  get identifier () { return this._identifier }
  get isEmpty () { return !this.items.length }
  get length () { return this.items.length }
  remove (v: SlotVariable) { this.items.remove(v) }
  get index () { return this.group.indexOfSlot(this) }
  get indexText () { return this.group.indexTextOfSlot(this) }
  get slotGroup () { return this.group }
  * peers () { yield * [...this.slotGroup].filter(v => v !== this) }

  add (v: SlotVariable) {
    const [i0] = this.items()

    if (!this.items().includes(v)) {
      for (const p of this.peers()) { p.remove(v) }
      this.items.push(v)
    }

    if (i0) { i0.propagateTo(v) }
    return this
  }

// placeholder text for an empty blank slot ø

  get slotTitle () {
    if (this.isEmpty) { return 'empty' }
    const [v0] = this.items()
    return v0.slotTitleText
  }

  /**
   * Copy the variables from the `from` to the `to`.
   * Return `true` if the `to` has changed.
   */
  propagateChanges (from: SlotVariable) {
    from.propagateTo(...this.items.filter(v => v !== from))
  }

  private get hovered () { return HOVERED_SLOTS }
  onHoverChange (out = false) {
    const { hovered } = this
    hovered.forEach(v => v.changeUxState(UX_STATE.peerHover, false))
    hovered.length = 0
    if (out) { return }
    for (const v of this) {
      v.changeUxState(UX_STATE.peerHover, true)
      hovered.push(v)
    }
  }

  /**
   * For ease of debugging.
   */
  toString () {
    const id = this.identifier ? `"${this.identifier}" ` : ''
    return `<Slot ${id}[${this.items.length}]>`
  }
}
