import { uniq } from 'lodash-es'
import { isValid } from 'date-fns/esm'

import { keyPathForModel } from 'utils/firestore'
import { sha256digest } from 'jcrypto/digest'
import { EntityModel } from 'EntityModel'

import { xcToEntity } from './fastco-xc'
import { enactToEntity } from './enact'

type EntityModelImportData = import('EntityModel/interfaces').EntityModelImportData

const extensions = {
  enact: enactToEntity,
  xc: xcToEntity,
} as Record<string, (file: File, am: AuthManager) => Promise<EntityModelImportData>>

const origins = {
  enact: 'EnAct',
  xc: 'FastCo',
}

interface ImportedNote {
  created: firebase.firestore.Timestamp
  createdByUid: string
  flagged: boolean
  message: string
  pinned: false
  pinpoint: null
  reminder: null
  id: string
}

async function saveNote (entity: EntityModel, noteFromImport: Partial<ImportedNote>) {
  await entity.vmLastSaved.when(v => v && isValid(v))
  const id = noteFromImport.id
  await entity.created.yet(undefined)

  const note: ImportedNote = Object.assign({
    created: window.firebase.firestore.FieldValue.serverTimestamp(),
    createdByUid: window.app.defaultAuthManager.firebaseUser().uid,
    flagged: false,
    pinned: false,
    pinpoint: {},
    reminder: null,
    message: '',
    id: null,
  }, noteFromImport)
  // see ListOfNotes.
  console.log(`Adding note for entity`, entity.id(), note)
  const col = entity.vmFirestoreDoc().collection('notes')
  if (id) {
    await col.doc(id).set(note)
  } else {
    await col.add(note)
  }
}

export async function importFileUX (file: File, authManager: AuthManager) : Promise<EntityModel> {
  const ext = file.name.replace(/.zip/i, '').split('.').pop().toLowerCase()
  const strategy = extensions[ext]
  if (!strategy) { return null }

  const entityData = await strategy(file, authManager)
  const { __notes__ } = entityData
  console.info(`Importing Data:`, entityData)

  const key = keyPathForModel(authManager.accountID(), entityData.id, EntityModel.vmResourceName)
  const entity = await EntityModel.vmGetOrCreate(authManager, key)
  await entity.vmAssigningValues.when(false)
  entity.accountID.modify(v => v || authManager.accountID())

  await entity.vmAssignValues({
    id: entityData.id,
    tags: uniq(['import', ...entity.tags, ...entityData.tags]),
    content: entityData.content,
  })

  if (__notes__) { __notes__.forEach(n => saveNote(entity, n)) }

  const imports = entity.componentFor('imports')
  imports.push({
    origin: origins[ext],
    filename: file.name,
    filehash: await sha256digest(file),
    importedBy: window.app.defaultAuthManager.userEmail,
    datetime: new Date().toISOString(),
  })

  return entity
}
