import { Variable } from './Variable'

export default class TimeVariable extends Variable {
  time: KnockoutObservable<string> = ko.observable('')

  get code () { return 'time' }
  get incohateText () { return 'Time of day' }
  get isCohate () { return Boolean(this.time()) }
  get slotGroupTitle () { return 'Time' }
  get slotTitleText () { return this.value() }
  get hasSlots () { return true }

  value () { return ko.unwrap(this.time) }
  propagate (to: TimeVariable) { to.time(this.time()) }
}
