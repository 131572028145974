
import { sortBy } from 'lodash-es'
import Big from 'big.js'

import { toISO8601, endOfTime } from 'utils/dates'

import { ORIGIN } from './Transaction/symbols'
import Stakeholder from './Stakeholder'
import { Asset } from './Asset'

import { CertificateIssue } from './CertificateAction'
import './CapitalAction'

type AssetAction = import('./AssetAction').AssetAction
type ShareholderLedger = import('./interfaces').ShareholderLedger
type AssetTransferLine = import('./interfaces').AssetTransferLine
type LedgerAction = import('./interfaces').LedgerAction
type AssetCertificate = import('./interfaces').AssetCertificate
type Transaction = import('./Transaction').Transaction


export interface CapitalStateParams {
  datetime?: Date
  transactions: Array<Transaction>
}

class AutoNumber {
  private byString: Record<string, number> = {}
  get (id: string) {
    if (!this.byString[id]) { this.byString[id] = 1 }
    return this.byString[id]
  }

  bump (id: string, manualNumber: number) {
    if (manualNumber === -1) { return } // un-numbered
    const current = this.get(id)
    const next = manualNumber === null
      ? current + 1
      : manualNumber > current
        ? manualNumber + 1
        : current
    this.byString[id] = next
  }
}


/**
 * A `CapitalState` represents the status of the capital at the given `datetime`.
 */
export class CapitalState {
  stakeholders: Record<string, Stakeholder>
  authorized: Record<string, Asset>
  transactions: Array<Transaction>
  datetime: Date
  autoNumber = new AutoNumber()

  /**
   * @param {string} datetime ISO8601 Date for UTC datetime of the transaction
   * @param {Array.<Transaction>} transactions to process
   */
  constructor (params: CapitalStateParams = { datetime: endOfTime(), transactions: []}) {
    const { datetime, transactions } = params
    Object.assign(this, {
      transactions: transactions || [],
      datetime: datetime || endOfTime(),
      authorized: {},
      stakeholders: {},
    })

    this.calcState(datetime, transactions)
  }

  /**
   * @yield {Array.<CapitalAction>} that apply on the given date/time
   */
  * transactionsAtOrBefore (transactions: Array<Transaction>, datetime: Date): IterableIterator<Transaction> {
    for (const tr of transactions) {
      const trDt = ko.unwrap(tr.datetime)
      if (trDt && datetime < trDt) { continue }
      yield tr
    }
  }

  /**
   * The `calcState` operates on a rolling-state; each Transaction (part) applies
   * to the state as it existed as of the prior Transaction
   */
  calcState (datetime: Date, transactions: Array<Transaction> = this.transactions): void {
    for (const tr of this.sortedTransactions(datetime, transactions)) {
      tr.applyTo(this)
    }
  }

  sortedTransactions (datetime: Date = this.datetime, transactions: Array<Transaction> = this.transactions.filter(tr => tr.datetime())): Array<Transaction> {
    const unsorted = [...this.transactionsAtOrBefore(transactions, datetime)]
    return sortBy(unsorted, tr => ko.unwrap(tr.datetime))
  }

  /**
   * @param {string} name of the person
   * @return {Stakeholder}
   */
  getStakeholder (name: string): Stakeholder {
    return this.stakeholders[name] || (
      this.stakeholders[name] = new Stakeholder({ name }))
  }

  hasStakeholder (name: string): boolean {
    return name in this.stakeholders[name]
  }

  hasAsset (assetClassID: string) { return assetClassID in this.authorized }

  * shareTransferLedger (datetime: Date = endOfTime(), transactions: Array<Transaction> = this.transactions): IterableIterator<AssetTransferLine> {
    const interimState = new CapitalState({
      datetime: endOfTime(), transactions: [],
    })

    for (const tr of this.sortedTransactions(datetime, transactions)) {
      const line = tr.shareTransferLine(interimState)
      tr.applyTo(interimState)
      if (!line) { continue }
      yield line
    }
  }

  /**        🌒                                                          🌘
   * FIXME:  🌔 We want to yield only and all authorized share classes.  🌖
   *         🌒                                                          🌘
   */
  * shareAssetClassIDs (): IterableIterator<string> {
    const seen = new Set()

    for (const tr of this.sortedTransactions()) {
      for (const action of tr.actions()) {
        const { assetClassID } = action as AssetAction
        if (!assetClassID || seen.has(assetClassID)) { continue }
        seen.add(assetClassID)
        yield assetClassID
      }
    }
  }

  /**
   * Compare to ensure A & B are the same certificate.
   */
  private certDiff (left: AssetCertificate[], right: AssetCertificate[]) {
    return left.filter(b => !right
      .find(a => a.number === b.number && a.assetClassID === b.assetClassID))
  }

  * holderLedgerLines (person, assetClassID) {
    const { datetime } = this
    const transactions = []
    const interimState = new CapitalState({ datetime, transactions })
    let balance = new Big(0)

    for (const tr of this.sortedTransactions()) {
      const ledgerLine = tr.holderLedgerLine(person, assetClassID, balance, interimState)
      const holder = interimState.getStakeholder(person)
      const certsBefore = [...holder.certificates]
        .filter(c => c.assetClassID === assetClassID)

      tr.applyTo(interimState)

      if (!ledgerLine) { continue }

      const certsAfter =  [...holder.certificates]
        .filter(c => c.assetClassID === assetClassID)
      const surrendered = this.certDiff(certsBefore, certsAfter)
      const issued = this.certDiff(certsAfter, certsBefore)

      ledgerLine.certificatesIssued.push(...issued)
      ledgerLine.certificatesSurrendered.push(...surrendered)
      balance = ledgerLine.balance
      yield ledgerLine
    }
  }

  * shareholderLedgers (): IterableIterator<ShareholderLedger> {
    for (const assetClassID of this.shareAssetClassIDs()) {
      const stakeholders = Object.values(this.stakeholders)
      const assetName = this.getAsset(assetClassID).assetName
      const assetPrefix = this.getAsset(assetClassID).getProperty('prefix', '')

      for (const stakeholder of stakeholders) {
        const person = stakeholder.idOrName

        yield {
          assetClassID,
          assetName,
          assetPrefix,
          person,
          lines: [
            ...this.holderLedgerLines(person, assetClassID)],
        }
      }

      if (!stakeholders.length) {
        yield {
          assetClassID,
          assetName,
          assetPrefix,
          person: '',
          lines: [],
        }
      }
    }
  }

  /**
   * The `authorizeAsset` enables a new asset class (in
   * two senses), whose properties can now be tracked.
   * @param classID
   */
  authorizeAsset (classID: string, authorizedBy: Transaction): void {
    this.authorized[classID] = new Asset(classID, authorizedBy)
  }

  deauthorizeAsset (assetClassID: string): void {
    const asset = this.getAsset(assetClassID)
    asset.setProperty('deauthorized', true)
    asset.setProperty('quantumAuthorized', null)
  }

  getAsset (classID: string): Asset {
    return this.authorized[classID] || (
      this.authorized[classID] = new Asset(classID, null)
    )
  }

  /**
   * The `setAssetProperty` assigns a value to the asset
   * class, which may include the asset name/title, prefix,
   * quantum authorized, and any other types of properties that
   * might apply to any asset.
   *
   * @param classID
   * @param key
   * @param value
   */
  setAssetProperty (classID: string, key: string, value: any): void {
    this.authorized[classID].setProperty(key, value)
  }

  nextCertificateNumber (assetClassID: string): number {
    const otherCertificateNumbers = this.transactions
      .map(tr => tr.actions())
      .flat()
      .filter((a: AssetAction) => a instanceof CertificateIssue && a.assetClassID === assetClassID)
      .map((a: CertificateIssue) => a.number || 0)
    return Math.max(0, ...otherCertificateNumbers) + 1
  }

  /**
   * Generate the `AssetTransferLine` for the given `actions` originating
   * from `origin`.
   */
  assetTransferLine (actions: LedgerAction[], origin: Transaction): AssetTransferLine | null {
    if (!actions.length) { return null }

    const transferLine: AssetTransferLine = {
      datetime: toISO8601(ko.unwrap(origin.datetime)),
      transactionTitle: ko.unwrap(origin.title),
      parts: [],
      transferNumber: null,
      certificatesIssued: [],
      certificatesSurrendered: [],
      [ORIGIN]: origin,
    }

    for (const action of actions) {
      action.updateTransferLine(transferLine, this)
    }

    return transferLine
  }
}

export default CapitalState
