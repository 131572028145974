import { typography, color } from 'styles'
import { inline } from 'icons'
import magicIcon from 'icons/solid/magic'
import spinIcon from 'icons/light/atom'
import acceptIcon from 'icons/solid/check-circle'
import cancelIcon from 'icons/solid/times-circle'
import { computed } from 'utils/decorator'
import {
  SectionInferenceGenerator, SectionInference,
} from 'inferences/SectionInferenceGenerator'

import { PDFView } from './PDFView'


/**
 *     |———— BookView ————|
 */
export class BookView extends PDFView {
  constructor (panelProvider, params = {}) {
    super(panelProvider, params)
    const { pagesClass } = params
    const bookZoom = ko.observable(1).extend({ localStorage: `${this.constructor.name}.bookZoom` })
    const thumbZoom = ko.observable(1).extend({ localStorage: `${this.constructor.name}.thumbZoom` })
    ko.computed(() => (this.panelProvider.showThumbnailView()
      ? this.zoom(thumbZoom())
      : this.zoom(bookZoom())))
    this.zoom.subscribe(z => (this.panelProvider.showThumbnailView()
      ? thumbZoom(z)
      : bookZoom(z)))
    Object.assign(this, {
      pagesClass: pagesClass || this.jss.pages,
    })
  }

  get commandName () { return 'navBook' }

  get title () {
    const { jss } = this
    return (
      <span class={jss.title}>
        Minute Book
        {this.sectionTitleIcon}
      </span>
    )
  }

  @computed()
  sectionTitleIcon () {
    const { jss } = this
    return this.magicInferredSections.length
      ? this.magicInferenceButtonsHTML
      : this.magicIsWorking()
        ? (
          <span class={jss.magicStatus}>
            {this.magicProgressPercent}
            <span class={jss.magicIsWorking}>
              {inline(spinIcon)}
            </span>
          </span>
        ) : (
          <span class={jss.magicButton}
            ko-ownClick={() => this.magicSectionClick()}>
            {inline(magicIcon)}
          </span>
        )
  }

  magicIsWorking = ko.observable<boolean>(false)
  magicProgressPercent = ko.observable<string>()
  magicInferredSections = ko.observableArray<SectionInference>([])

  get magicInferenceButtonsHTML () {
    const { jss } = this
    const book = this.panelProvider.bookComponent
    const confirmAll = () => {
      for (const s of this.magicInferredSections) {
        const { pageNumber, sectionTitleText } = s
        book.addOrUpdateSection(pageNumber, sectionTitleText, false)
      }
      this.magicInferredSections([])
    }
    const cancelAll = () => this.magicInferredSections([])
    return (
      <>
        <span class={jss.magicButton} ko-ownClick={() => cancelAll()}>
          {inline(cancelIcon)}
        </span>
        <span class={jss.magicButton} ko-ownClick={() => confirmAll()}>
          {inline(acceptIcon)}
        </span>
      </>
    )
  }

  async magicSectionClick () {
    this.magicProgressPercent('Loading… ')
    const sig = new SectionInferenceGenerator(
      this.panelProvider.bookPages(),
      this.panelProvider.pdfEngine)
    this.subscribe(sig.process.percent,
      p => this.magicProgressPercent(`${Math.round(p * 100)}%`))
    this.magicIsWorking(true)
    this.magicInferredSections(await sig.generate())
    this.magicIsWorking(false)
    this.magicProgressPercent('')
  }

  static get magicCSS () {
    return {
      title: {
        display: 'flex',
        width: '100%',
      },


      magicButton: {
        marginLeft: 'auto',
        '--icon-color': color.icon.light.tertiary,
        '& ~ &': {
          marginLeft: '10px',
        },
        '&:hover': {
          '--icon-color': color.icon.light.secondary,
        },
      },

      magicIsWorking: {
        //  see index.html for other animations e.g. loading-spinner
        animation: 'spin 4s linear infinite',
        marginLeft: '5px',
      },

      magicStatus: {
        display: 'flex',
        marginLeft: 'auto',
        color: '#aaa',
      },
    }
  }

  get thumbnailView () { return this.panelProvider.showThumbnailView() }
  get showEditButton () { return this.thumbnailView }
  get showUndoButton () { return true }

  jumpToThumbnailSection (pn) {
    if (pn in this.thumbScrollIndex) {
      this.thumbScrollIndex[pn]({
        alignToTop: 'true',
        offset: '-90',
      })
    }
  }

  thumbnailSectionHTML (pages) {
    const { jss } = this
    const gripSection = this.bookComponent.getIndexOfpageID(pages[0].pageID)
    const section = this.bookComponent.getSectionAt(pages[0])
    const scrollTo = section && (this.thumbScrollIndex[section.startPage()] || ko.observable().extend({ notify: 'always' }))
    if (section) {
      this.thumbScrollIndex[section.startPage()] = scrollTo
    }
    const sectionName = section ? section.name : this.panelProvider.model.componentFor('legalname').get()
    return [
      <div class={jss.sectionNameArea} ko-scrollTo={scrollTo}>
        <span class={jss.sectionName}>{sectionName}</span>
      </div>,
      <pdf-thumbnails grip-section={gripSection} pdfSettings={this.pdfSettings} bookPages={ko.observableArray(pages)} />,
    ]
  }

  get thumbnailPdfHtml () {
    const { bookComponent } = this.panelProvider
    this.bookComponent = bookComponent
    this.thumbScrollIndex = {}
    const sectionView = ko.computed(() => (bookComponent.sections.length > 0) || undefined)
    const moveSectionPages = sectionView()
      ? args => bookComponent.movePagesWithSectionUpdate(args)
      : null
    const customDelta = (from, to) => {
      const fromBottom = from.offsetTop + from.clientHeight
      const toBottom = to.offsetTop + to.clientHeight
      const x = to.offsetLeft - from.offsetLeft
      const y = toBottom - fromBottom
      return { x, y }
    }
    return (<div class={this.jss.layout} thumbnail-sections={sectionView} ko-grip-area={{ onItemMove: moveSectionPages, customDelta }}>
      {ko.computed(() => sectionView()
        ? [...bookComponent.pagesBySection()].map(v => this.thumbnailSectionHTML(v))
        : (<pdf-thumbnails pdfSettings={this.pdfSettings} movePage={({ fromIndexList, toIndex }) => bookComponent.movePages(fromIndexList, toIndex)} />)).extend({ deferred: true })}
    </div>)
  }

  get subMenuItems () {
    const { bookComponent, sections, sectionOfCurrentPage, showSectionPageNumbers } = this.panelProvider
    // The following fixes the case where no `transitionend` is triggered
    // i.e. when there are no sub-menu items.
    Promise.delay(500).then(() => this.loading(false))
    const jumpToPage = pn => {
      if (this.thumbnailView) {
        this.jumpToThumbnailSection(pn)
      }
      else {
        this.panelProvider.setAndScrollToPage(pn)
      }
    }

    // Force view list to open when editing sections
    this.panelProvider.computed(() => {
      if (sections().some(s => s.editing())) {
        this.collapsed(false)
      }
    })

    return (
      <minute-book-sections-list
        panelProvider={this.panelProvider}
        bookComponent={bookComponent}
        uploadAction={files => this.panelProvider.uploadPdfMultipleFiles(files)}
        uploadCancelAction={() => this.panelProvider.cancelUpload()}
        shareSection={section => this.panelProvider.addShare([section])}
        downloadSection={section => this.panelProvider.downloadSection(section)}
        onSectionMove={(f, t) => bookComponent.moveSection(f, t)}
        sections={sections}
        sectionOfCurrentPage={sectionOfCurrentPage}
        showSectionPageNumbers={showSectionPageNumbers}
        jumpToPage={jumpToPage}
        inferredSections={this.magicInferredSections}
      />
    )
  }

  get subMenuStyleMap () {
    return {
      'max-height': `${this.panelProvider.bookComponent.sections().length * 40}px`,
    }
  }

  normalizedSectionName (name) {
    return name
      .toLowerCase()
      // https://stackoverflow.com/questions/4328500
      .replace(/[\W_]/g, ' ')
      .replace(/\s+/g, ' ')
      .trim()
  }

  get pdfHtml () {
    if (this.thumbnailView) {
      return this.thumbnailPdfHtml
    }
    const { pdfSettings, jss } = this
    const { showContextView } = this.panelProvider
    // Delay the contex view b/c we want to prioritize the on-screen pages.
    const contextRect = ko.observable().extend({ rateLimit: 100 })
    const contextView = <pdf-context class={jss.context} ko-scroll-observer={contextRect} boundingRect={contextRect} pdfSettings={pdfSettings} />
    const context = ko.pureComputed(() => (showContextView() && !this.panelProvider.rightView())
      ? contextView
      : undefined)
    return (<div class={jss.sideBySide}>
      <div class={jss.mainPagesContainer}>
        <pdf-pages class={this.pagesClass} pdfSettings={pdfSettings} />
      </div>
      {context}
    </div>)
  }

  unselectPages (evt) {
    if (this.bookComponent) {
      this.bookComponent.pages().forEach(bp => bp.selected(false))
      this.bookComponent._shiftClickAnchor = undefined
    }
  }

  get main () {
    const { jss } = this
    const { listOfNotes, bookComponent } = this.panelProvider
    return (
      <div class={jss.mainContainer} ko-click={evt => this.unselectPages(evt)}>
        {this.dropPadHTML}
        <pinned-notes class={jss.pinnedNotes} bookComponent={bookComponent} listOfNotes={listOfNotes} />
        {this.pdfHtml}
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.thumbnailCss,
      ...this.magicCSS,
      sideBySide: {
        display: 'flex',
        position: 'relative',
      },
      mainPagesContainer: {
        overflowX: 'hidden',
        display: 'grid',
        width: '100%',
      },
      pages: {
        width: '100%',
        ...super.css.translated,
      },
      pinnedNotes: {
        ...super.css.pinnedNotes,
        ...super.css.fullcompressed,
      },
      context: {
        position: 'absolute',
        right: '0',
        height: '100%',
        transition: 'var(--right-panel-transition-out)',
        '[right-panel-open] &': {
          transition: 'var(--right-panel-transition-in)',
          transform: 'translate3d(calc(-1 * var(--right-panel-width)), 0, 0)',
        },
      },
    }
  }

  static get thumbnailCss () {
    return {
      layout: {
        ...super.css.leftcompressed,
        '&[thumbnail-sections]': {
          display: 'grid',
          padding: 10,
          gridGap: '25px 40px',
          gridTemplateColumns: 'minmax(185px, max-content) auto',
          '@media (max-width: 900px)': {
            gridTemplateColumns: 'auto',
          },
        },
      },
      sectionNameArea: {
        fontSize: '1.2rem',
        fontFamily: typography.bodyFontFamily,
        fontWeight: 700,
        gridColumn: '1/2',
        letterSpacing: 0.1,
        paddingBottom: 30,
        position: 'sticky',
        maxWidth: '15vw',
      },
      sectionName: {
        top: 'calc(var(--head-height) + 10px)',
        color: '#333',
        height: 57,
        display: 'flex',
        position: 'sticky',
        fontSize: '0.7em',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
        alignItems: 'center',
        fontFamily: typography.altFontFamily,
        borderRadius: 4,
        letterSpacing: 0.6,
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        fontVariantLigatures: 'none',
        padding: '0px 10px',
      },
    }
  }
}
