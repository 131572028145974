/**
 * Utilities related to `contentEditable` DOM nodes.
 * See:
 * - nassau/packages/np.utils/src/jquery-editable.js
 *
 * For debugging the selection changes:
 *  * document.addEventListener('selectionchange', console.trace)
 */
import { debounce } from 'lodash-es'

import { containerElement } from './range'

/**
 * The `lastEditableRange` keeps track of the editable that was most recently
 * the caret position inside a `contenteditable`.
 */
export const lastEditableRange = ko.observable<Range>()

document.addEventListener('selectionchange', debounce(() => {
  const r0 = selectionContainerElement()
  if (r0) { lastEditableRange(window.getSelection().getRangeAt(0)) }
}, 50))

export function focusLastEditableRange () {
  if (!lastEditableRange() || selectionContainerElement()) { return }
  const s = window.getSelection()
  s.removeAllRanges()
  s.addRange(lastEditableRange())
}

// See: http://stackoverflow.com/questions/7451468
export function isCaretAtStartOfNode (node: Element, range: Range) {
  const preRange = document.createRange()
  preRange.selectNodeContents(node)
  preRange.setEnd(range.startContainer, range.startOffset)
  return preRange.toString().length === 0
}

export function isCaretAtEndOfNode (node: Element, range: Range) {
  const postRange = document.createRange()
  postRange.selectNodeContents(node)
  postRange.setStart(range.endContainer, range.endOffset)
  return postRange.toString().length === 0
}


//  Get/set the character- offset
// See: http://stackoverflow.com/questions/13949059
export function getEditableCaretOffset (node: Element = selectionContainerElement()): EditableSelection {
  const sel = window.getSelection()
  if (sel.rangeCount === 0) { return { start: 0, end: 0 } }
  const range = sel.getRangeAt(0)

  if (!node || !node.contains(range.commonAncestorContainer)) {
    return null
  }

  const preSelRange = range.cloneRange()
  preSelRange.selectNodeContents(node)
  preSelRange.setEnd(range.startContainer, range.startOffset)

  const start = preSelRange.toString().length
  return {
    start,
    end: start + range.toString().length,
  }
}

export function setEditableCaretOffset (es: EditableSelection, container: Element = selectionContainerElement()) {
  if (!es) { return }
  const range = document.createRange()
  const nodeStack = [container]
  const textLen = container.textContent.length + 1
  let { start, end } = es
  let node, nextIndex, i
  let index = 0
  let foundStart = false
  let stop = false

  if (start === null) {
    start = 0
  } else if (start > textLen) {
    start = textLen
  }

  if (end === null) {
    end = start
  } else if (end > textLen) {
    end = textLen
  }

  if (start === 0) {
    range.setStart(container, start)
  }

  while (!stop && (node = nodeStack.pop())) {
    if (node.nodeType === document.TEXT_NODE) {
      nextIndex = index + node.length
      if (!foundStart && index <= start && start <= nextIndex) {
        range.setStart(node, start - index)
        foundStart = true
      }
      if (foundStart && index <= end && end <= nextIndex) {
        range.setEnd(node, end - index)
        stop = true
      }
      index = nextIndex
    } else {
      i = node.childNodes.length
      while (i--) {
        nodeStack.push(node.childNodes[i])
      }
    }
  }

  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

export function setCaret (node: Element, atEnd: boolean = false) {
  var range = document.createRange()
  range.selectNodeContents(node)
  range.collapse(!atEnd)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

export function currentOrLastEditableRange (): Range {
  return selectionContainerElement()
    ? window.getSelection().getRangeAt(0)
    : lastEditableRange()
}

export function currentOrLastEditable (): Element {
  return selectionContainerElement(currentOrLastEditableRange())
}

export function editableRange () : Range {
  // Return the range for the selection
  const sel = window.getSelection()
  if (sel.rangeCount <= 0) { return null }
  return sel.getRangeAt(0)
}

export function caretAtStart (node: Element) {
  const range = editableRange()
  if (!range || !editableIsCaret()) { return false }
  return isCaretAtStartOfNode(node, range)
}

export function caretAtEnd (node: Element) {
  const range = editableRange()
  if (!range || !editableIsCaret()) { return false }
  return isCaretAtEndOfNode(node, range)
}

function editableIsCaret () {
  return window.getSelection().type === 'Caret'
  // alt test:
  // return sel.rangeCount == 1 and sel.getRangeAt(0).collapsed
}

function editableSelectionBounds () {
  const range = editableRange()
  if (!range) { return null }
  const editable = selectionContainerElement()
  if (!editable) { return null }
  return {
    editable: editable.getBoundingClientRect(),
    selection: range.getBoundingClientRect(),
  }
}

/**
 * CARET_GIVE is aomes
 */
const CARET_GIVE = 10

export function caretStartsOnFirstLine () {
  const bounds = editableSelectionBounds()
  if (!bounds) { return false }
  const { editable, selection } = bounds
  return selection.height === 0 || editable.top >= selection.top - CARET_GIVE
}

export function caretEndsOnLastLine () {
  const bounds = editableSelectionBounds()
  if (!bounds) { return false }
  const { editable, selection } = bounds
  const editableEnd = editable.top + editable.height
  const selectionEnd = selection.top + selection.height
  return selection.height === 0 || editableEnd <= selectionEnd + CARET_GIVE
}

// Generic full save/restore
// See e.g. http://stackoverflow.com/questions/5951886
export function getSelection () {
  const sel = window.getSelection()
  const ranges = []
  if (sel.rangeCount) {
    for (let i = 0, j = sel.rangeCount; i < j; ++i) {
      ranges.push(sel.getRangeAt(i))
    }
  }
  return ranges
}

export function setSelection(ranges) {
  const sel = window.getSelection()
  sel.removeAllRanges()
  for (const range of ranges) {
    sel.addRange(range)
  }
}

export function lengthOfText (html: string) {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent.length
}

export function insertNodeAtCaret (node) {
  editableRange().insertNode(node)
}

export function appendChildNodesToEditableSelection (from: Element) {
  const nodes = [...from.childNodes]
  selectionContainerElement().append(...nodes)
}
/**
 * Return the `contentEditable` around the first range of the
 * current window selection
 */
export function selectionContainerElement (range?: Range): Element {
  if (!range) {
    const s = window.getSelection()
    if (s.rangeCount === 0) { return null }
    range = s.getRangeAt(0)
  }
  const e = containerElement(range)
  if (e === document) { return null }
  return e.closest('[contenteditable=true]')
}


/**
 * Split the [contentEditable] at the caret (deleting any selection),
 * returning the `DocumentFragment` for the balance of the node.
 */
export function splitEditableAtSelection (): DocumentFragment {
  const editable = selectionContainerElement()
  const range = window.getSelection().getRangeAt(0)
  range.extractContents() // erase any selection

  const balance = new Range()
  balance.selectNode(editable)
  balance.setStart(range.startContainer, range.startOffset)
  const contents = balance.extractContents()

  editable.dispatchEvent(new Event('input'))
  return contents
}

/**
 * Return the current `/some-command` text from the given `Text`.
 * If it cannot be determined, return an empty range.
 */
export function stringCommandAsRange (): Range {
  const range = window.getSelection().getRangeAt(0).cloneRange()
  const node = range.endContainer as Text
  if (!range.collapsed) { return new Range() }
  if (node.nodeType !== document.TEXT_NODE) { return new Range() }
  const commandStartsAt = range.startContainer.nodeValue
    .slice(0, range.startOffset)
    .lastIndexOf('/')
  if (commandStartsAt === -1) { return new Range() }
  range.setStart(range.startContainer, commandStartsAt)
  return range
}

/**
 * Wrap the selections in the given tag.
 *
 * Uses `document.execCommand` for nested unwrapping.
 *
 * Notes:
 *  1. The `execCommand` will only apply to styles that are inside a
 *     `contenteditable=true`, so we temporarily remove the
 *     `contenteditable=false` attribute so that the styles will apply
 *      properly.
 *
 *     This doesn't work b/c it inserts tags inside the `<span>` which are
 *     overwritten by the `wvar` binding, plus it breaks many of our mouse
 *     -click events
 */
const _tagToCommand = {
  b: 'bold',
  i: 'italic',
  u: 'underline',
  strike: 'strikethrough',
}

export function toggleFormat (tag: string) {
  const command = _tagToCommand[tag]
  if (!command) { throw new Error(`Bad toggleFormat: ${tag}`) }
  // const se = selectionContainerElement()
  // const editablesToTemporarilyEnable = se.querySelectorAll('[contenteditable=false]')
  // for (const e of editablesToTemporarilyEnable) { // 1.
  //   e.removeAttribute('contenteditable')
  // }
  document.execCommand(command)
  // for (const e of editablesToTemporarilyEnable) {
  //   e.setAttribute('contenteditable', 'false')
  // }
}

/**
 * The problem with the following is that going the opposite direction
 * i.e. unwrapping, is not obvious.
 */
// export function _toggleFormat (tag: 'b' | 'i' | 'u' | 'strike') {
//   getSelection().filter(r => !r.collapsed)
//     .forEach(r => _wrap(r, tag))
// }

// function _wrap (range: Range, tagName: string) {
//   const node = document.createElement(tagName)
//   node.appendChild(range.extractContents())
//   range.insertNode(node)
// }

/**
 * Return the Element immediately prior to the caret (if it's adjacent to one).
 */
export function elementPrecedingCaret (): Node {
  const s = window.getSelection()
  if (s.rangeCount === 0) { return null }
  const r = window.getSelection().getRangeAt(0)
  if (!r.collapsed) { return null }
  const c = r.commonAncestorContainer
  if (c.nodeType === document.TEXT_NODE) {
    return r.startOffset === 0 ? c.previousSibling : null
  }
  if (r.startOffset === 0) { return null }
  return (c as Element).childNodes[r.startOffset - 1]
}


