

import { ComponentVariable } from './ModelVariable'

export abstract class IterableComponentVariable<T> extends ComponentVariable {
  get componentValue (): T { return ko.unwrap(super.value()) }

  protected get leadTransforms () {
    return this.iterationChosen ? [{ type: 'empty' }, { type: 'join' }] : []
  }


  * [Symbol.iterator] () { if (this.isCohate) { yield * this.iterations() } }
  get isCohate () {
    return super.isCohate && (this.iterationChosen || this.isValidIndex)
  }

  /**
   * The `true` here is used by some components to determine if the
   * value is cohate.  By default we *may* want it to be false when
   * iterationChosen is true but there are no items, particularly when
   * looking to more complex if-then logic.
   */
  value () { return this.iterationChosen ? true : this.chosenValue() }

  get slotTitleText () {
    if (!this.isCohate) { return `(${this.slotGroupTitle})` }
    return this.iterationChosen
      ? `All ${this.slotGroupTitle}`
      : this.slotIterTitleText
  }

  abstract iterationKeys (): IterableIterator<string|number>
  abstract get iterationIndex (): KnockoutObservable<string|number>
  protected abstract get iterationChosen (): boolean
  protected abstract get isValidIndex (): boolean
  protected abstract iterations (): IterableIterator<string>
  protected abstract chosenValue (): string
  protected abstract get slotIterTitleText (): string
}
