/**
* ————————————————————————————————————————————————————
* THIS FILE IS COMPILED WITH 'rebuild.js'. DO NOT EDIT
* ————————————————————————————————————————————————————
*
* Last updated: Mon Aug 19 2019 11:17:04 GMT-0400 (Eastern Daylight Time)
*/
export default [
["ADA","Cardano",null],
["AED","UAE Dirham","د.إ"],
["AFN","Afghani","؋"],
["ALL","Lek","L"],
["AMD","Armenian Dram","֏"],
["ANG","Netherlands Antillean Guilder","ƒ"],
["AOA","Kwanza","Kz"],
["ARS","Argentine Peso","$"],
["AUD","Australian Dollar","$"],
["AWG","Aruban Florin","ƒ"],
["AZN","Azerbaijan Manat","₼"],
["BAM","Convertible Mark","KM"],
["BBD","Barbados Dollar","$"],
["BCH","Bitcoin Cash",null],
["BDT","Taka","৳"],
["BGN","Bulgarian Lev","лв"],
["BHD","Bahraini Dinar",".د.ب"],
["BIF","Burundi Franc","FBu"],
["BMD","Bermudian Dollar","$"],
["BND","Brunei Dollar","$"],
["BOB","Boliviano","$b"],
["BOV","Mvdol",null],
["BRL","Brazilian Real","R$"],
["BSD","Bahamian Dollar","$"],
["BTC","Bitcoin","฿"],
["BTN","Ngultrum","Nu."],
["BWP","Pula","P"],
["BYN","Belarusian Ruble","Br"],
["BZD","Belize Dollar","BZ$"],
["CAD","Canadian Dollar","$"],
["CDF","Congolese Franc","FC"],
["CHE","WIR Euro",null],
["CHF","Swiss Franc","CHF"],
["CHW","WIR Franc",null],
["CLF","Unidad de Fomento",null],
["CLP","Chilean Peso","$"],
["CNY","Yuan Renminbi","¥"],
["COP","Colombian Peso","$"],
["COU","Unidad de Valor Real",null],
["CRC","Costa Rican Colon","₡"],
["CUC","Peso Convertible","$"],
["CUP","Cuban Peso","₱"],
["CVE","Cabo Verde Escudo","$"],
["CZK","Czech Koruna","Kč"],
["DASH","Dash",null],
["DJF","Djibouti Franc","Fdj"],
["DKK","Danish Krone","kr"],
["DOP","Dominican Peso","RD$"],
["DZD","Algerian Dinar","دج"],
["EGP","Egyptian Pound","£"],
["EOS","EOS",null],
["ERN","Nakfa","Nfk"],
["ETB","Ethiopian Birr","Br"],
["ETH","Ethereum","Ξ"],
["EUR","Euro","€"],
["FJD","Fiji Dollar","$"],
["FKP","Falkland Islands Pound","£"],
["GBP","Pound Sterling","£"],
["GEL","Lari","₾"],
["GHS","Ghana Cedi","GH₵"],
["GIP","Gibraltar Pound","£"],
["GMD","Dalasi","D"],
["GNF","Guinean Franc","FG"],
["GTQ","Quetzal","Q"],
["GYD","Guyana Dollar","$"],
["HKD","Hong Kong Dollar","$"],
["HNL","Lempira","L"],
["HRK","Kuna","kn"],
["HTG","Gourde","G"],
["HUF","Forint","Ft"],
["IDR","Rupiah","Rp"],
["ILS","New Israeli Sheqel","₪"],
["INR","Indian Rupee","₹"],
["IQD","Iraqi Dinar","ع.د"],
["IRR","Iranian Rial","﷼"],
["ISK","Iceland Krona","kr"],
["JMD","Jamaican Dollar","J$"],
["JOD","Jordanian Dinar","JD"],
["JPY","Yen","¥"],
["KES","Kenyan Shilling","KSh"],
["KGS","Som","лв"],
["KHR","Riel","៛"],
["KMF","Comorian Franc ","CF"],
["KPW","North Korean Won","₩"],
["KRW","Won","₩"],
["KWD","Kuwaiti Dinar","KD"],
["KYD","Cayman Islands Dollar","$"],
["KZT","Tenge","лв"],
["LAK","Lao Kip","₭"],
["LBP","Lebanese Pound","£"],
["LKR","Sri Lanka Rupee","₨"],
["LRD","Liberian Dollar","$"],
["LSL","Loti","M"],
["LTC","Litecoin","Ł"],
["LYD","Libyan Dinar","LD"],
["MAD","Moroccan Dirham","MAD"],
["MDL","Moldovan Leu","lei"],
["MGA","Malagasy Ariary","Ar"],
["MKD","Denar","ден"],
["MMK","Kyat","K"],
["MNT","Tugrik","₮"],
["MOP","Pataca","MOP$"],
["MRU","Ouguiya","UM"],
["MUR","Mauritius Rupee","₨"],
["MVR","Rufiyaa","Rf"],
["MWK","Malawi Kwacha","MK"],
["MXN","Mexican Peso","$"],
["MXV","Mexican Unidad de Inversion (UDI)",null],
["MYR","Malaysian Ringgit","RM"],
["MZN","Mozambique Metical","MT"],
["NAD","Namibia Dollar","$"],
["NEO","NEO",null],
["NGN","Naira","₦"],
["NIO","Cordoba Oro","C$"],
["NOK","Norwegian Krone","kr"],
["NPR","Nepalese Rupee","₨"],
["NZD","New Zealand Dollar","$"],
["OMR","Rial Omani","﷼"],
["PAB","Balboa","B/."],
["PEN","Sol","S/."],
["PGK","Kina","K"],
["PHP","Philippine Piso","₱"],
["PKR","Pakistan Rupee","₨"],
["PLN","Zloty","zł"],
["PYG","Guarani","Gs"],
["QAR","Qatari Rial","﷼"],
["RON","Romanian Leu","lei"],
["RSD","Serbian Dinar","Дин."],
["RUB","Russian Ruble","₽"],
["RWF","Rwanda Franc","R₣"],
["SAR","Saudi Riyal","﷼"],
["SBD","Solomon Islands Dollar","$"],
["SCR","Seychelles Rupee","₨"],
["SDG","Sudanese Pound","ج.س."],
["SEK","Swedish Krona","kr"],
["SGD","Singapore Dollar","$"],
["SHP","Saint Helena Pound","£"],
["SLL","Leone","Le"],
["SOS","Somali Shilling","S"],
["SRD","Surinam Dollar","$"],
["SSP","South Sudanese Pound","£"],
["STN","Dobra","Db"],
["SVC","El Salvador Colon","$"],
["SYP","Syrian Pound","£"],
["SZL","Lilangeni","E"],
["THB","Baht","฿"],
["TJS","Somoni","SM"],
["TMT","Turkmenistan New Manat","T"],
["TND","Tunisian Dinar","د.ت"],
["TOP","Pa'anga","T$"],
["TRY","Turkish Lira","₺"],
["TTD","Trinidad and Tobago Dollar","TT$"],
["TWD","New Taiwan Dollar","NT$"],
["TZS","Tanzanian Shilling","TSh"],
["UAH","Hryvnia","₴"],
["UGX","Uganda Shilling","USh"],
["USD","US Dollar","$"],
["USN","US Dollar (Next day)",null],
["UYI","Uruguay Peso en Unidades Indexadas (URUIURUI)",null],
["UYU","Peso Uruguayo","$U"],
["UZS","Uzbekistan Sum","лв"],
["VEF","Bolívar","Bs"],
["VND","Dong","₫"],
["VUV","Vatu","VT"],
["WST","Tala","WS$"],
["XAF","CFA Franc BEAC","FCFA"],
["XAG","Silver",null],
["XAU","Gold",null],
["XBA","Bond Markets Unit European Composite Unit (EURCO)",null],
["XBB","Bond Markets Unit European Monetary Unit (E.M.U.-6)",null],
["XBC","Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",null],
["XBD","Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",null],
["XCD","East Caribbean Dollar","$"],
["XDR","SDR (Special Drawing Right)",null],
["XMR","Monero",null],
["XOF","CFA Franc BCEAO","CFA"],
["XPD","Palladium",null],
["XPF","CFP Franc","₣"],
["XPT","Platinum",null],
["XRP","Ripple",null],
["XSU","Sucre",null],
["XTS","Codes specifically reserved for testing purposes",null],
["XUA","ADB Unit of Account",null],
["XXX","The codes assigned for transactions where no currency is involved",null],
["YER","Yemeni Rial","﷼"],
["ZAR","Rand","R"],
["ZEC","Zcash",null],
["ZMW","Zambian Kwacha",null],
["ZWL","Zimbabwe Dollar",null]
]