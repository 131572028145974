import Big from 'big.js'

import { parseISO } from 'date-fns/esm'
import { toISO8601 } from 'utils/dates'
import Serializable from 'Serializable'

import CapitalAction from '../CapitalAction'
import CapitalState from '../CapitalState'

// import { AssetDeauthorize} from '../AssetAction'

import {
  AssetTransferLine, AssetLedgerLine, LedgerAction,
} from '../interfaces'

import { ORIGIN } from './symbols'
export { ORIGIN }

export enum CODES {
  CapitalAuthorize = 'capital.auth',
  CapitalAmend = 'capital.amend',
  CapitalDeauthorize = 'capital.deauth',
  CapitalIssue = 'capital.issue',
  CapitalTransfer = 'capital.transfer',
  CapitalCancel = 'capital.cancel',
  CapitalSplit = 'capital.split',
  CapitalMassConvert = 'capital.mconvert',
  CapitalIndividualConvert = 'capital.iconvert',
  Test = 'test'
}

/**
 * A `Transaction` represents a set of operations (actions) upon
 * a `CapitalState`.
 */
export abstract class Transaction extends Serializable {
  actions: KnockoutComputed<CapitalAction[]>
  datetime: KnockoutObservable<Date>
  title: KnockoutObservable<string>
  ledgerNote: KnockoutObservable<string>

  abstract get code () : CODES
  static get ORIGIN () { return ORIGIN }
  abstract yieldActions () : IterableIterator<CapitalAction>
  /**
   * A Transaction instance may not be `deleteable` if, for example, there
   * are other transactions that depend on it (e.g. Share Class Authorizations)
   */
  get deleteable () { return true }
  get plainObservables () : string[]  { return ['title', 'ledgerNote'] }
  get arrayObservables () : string[] { return [] }

  constructor (params = { datetime: null, title: '' }) {
    super(params)
    const { datetime } = params
    Object.assign(this, {
      datetime: ko.observable(datetime ? parseISO(datetime) : null),
    })

    this.actions = ko.pureComputed({
      read: () => [...this.yieldActions()],
      deferEvaluation: true,
    }).extend({ arrayProperties: true })
  }

  /**
   * Return `null` in place of `undefined` to prevent Firestore from breaking.
   */
  private jsValueOf (property: string) {
    const value = ko.toJS(this[property]) as any
    return value === undefined ? null : value
  }

  toJS () {
    return {
      ...super.toJS(),
      code: this.code,
      datetime: toISO8601(this.datetime()),
    }
  }

  applyTo (state: CapitalState) : CapitalState {
    for (const action of this.actions()) {
      action.applyTo(state, this)
    }
    return state
  }

  /**
   * Calculate the `CapitalState` immediately prior to this transaction
   * being applied
   */
  stateBefore (allTransactions: Transaction[]) : CapitalState {
    const datetime = this.datetime()
    const isInAll = allTransactions.includes(this)
    const filter = t => t.datetime() <= datetime && t !== this
      && (!isInAll || allTransactions.indexOf(t) < allTransactions.indexOf(this))
    const transactions = allTransactions.filter(filter)
    return new CapitalState({ datetime, transactions })
  }

  /**
   * Calculate the `CapitalState` after this transaction is applied.
   */
  stateAfter (transactions: Transaction[]) : CapitalState {
    return this.applyTo(this.stateBefore(transactions))
  }

  /**
   * `shareTransferLine` returns null or a `AssetTranferLine` that for the
   * Share Transfer Register.
   */
  shareTransferLine (state: CapitalState) : AssetTransferLine | null {
    const actions = this.actions()
      .filter((a: LedgerAction) => a.updateTransferLine) as LedgerAction[]

    return state.assetTransferLine(actions, this)
  }

  /**
   * `holderLedgerLine` is a line-item for this transaction
   * on the person + class ledger.
   *
   * @param holder the identity of the shareholder in question
   * @param interimState a CapitalState as of completion of the transaction immediately prior to the application of the transaction for this ledger line.
   */
  holderLedgerLine (holder: string, assetClassID: string, balance: Bigable, interimState: CapitalState) : AssetLedgerLine | null {
    const actions = this.actions()
      .filter((a: LedgerAction) => a.updateLedgerLine) as LedgerAction[]

    const affectsHolder = actions.some(a => a.affects(holder, interimState))
    const involvesAsset = actions.some(a => a.involves(assetClassID))
    if (!affectsHolder || !involvesAsset) { return null }

    const asset = interimState.getAsset(assetClassID)

    const transferLine: AssetLedgerLine = {
      holder,
      assetClassID,
      datetime: toISO8601(this.datetime()),
      transferNumber: null,
      transactionTitle: ko.unwrap(this.title),
      certificatesIssued: [],
      certificatesSurrendered: [],
      delta: new Big(0),
      balance: new Big(balance),
      counterParties: new Set<string>(),
      asset: {
        classification: asset.getProperty('classification'),
        prefix: asset.getProperty('prefix'),
        series: asset.getProperty('series'),
      },
      [ORIGIN]: this,
    }

    for (const action of actions) {
      try {
        action.updateLedgerLine(transferLine, interimState)
      } catch (err) {
        console.warn(`Action failed: `, action, err)
      }
    }

    return transferLine
  }

  /**
   * A short string describing the transaction
   * (for e.g. reminder dates.)
   */
  shortDescription (cs: CapitalState): string { return null }

  static get SerializationCodes () { return CODES }
}

export default Transaction


export const WITH_CONSIDERATION = ['monetaryConsideration', 'nonMonetaryConsideration', 'votingProxy', 'considerationIsAggregate']
export interface CapitalTransactionWithConsideration {
  considerationIsAggregate: KnockoutObservable<boolean>
  monetaryConsideration: KnockoutObservable<CurrencyAmount>
  nonMonetaryConsideration: KnockoutObservable<string>
  votingProxy: KnockoutObservable<string>
}

export interface MassConversion {
  numerator: KnockoutObservable<string>
  targetAssetClassID: KnockoutObservable<string>
}

