/**
 * blobStore is our mechanism for storing/loading blobs from
 * a binary-store e.g. Firebase Storage / Google Cloud Storage.
 *
 * Paths are relative to the `/accounts/{accountID}/...`
 */

export async function putBlob (authManager: AuthManager, path: string, blob: Blob, metadata = {}, process?: Process) {
  console.info(`Writing blob ${path}`, blob)

  const task = authManager.storage
    .ref(path)
    .put(blob, metadata)

  if (process) {
    process.taskCount(blob.size)
    process.isCancelled.once(() => task.cancel())
    task.on('state_changed', p => process.tasksCompleted(p.bytesTransferred))
  }

  return task
}
