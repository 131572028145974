
import ViewComponent from 'ViewComponent'
import { buttons, color } from 'styles'

import { toggleFormat } from 'utils/editable'

import icons from 'icons'
import boldIcon from 'icons/light/bold'
import italicsIcon from 'icons/light/italic'
import underlineIcon from 'icons/solid/underline'
import strikethroughIcon from 'icons/solid/strikethrough'
import alignLeftIcon from 'icons/solid/align-left'
import alignCenterIcon from 'icons/solid/align-center'
import alignRightIcon from 'icons/solid/align-right'
import alignJustifyIcon from 'icons/solid/align-justify'

type QuestionModel = import('DataModel/QuestionModel').default
type WritingCommandSet = import('../WritingCommandSet').default<QuestionModel>

export default class WritingFormattingBar extends ViewComponent {
  commandSet: WritingCommandSet

  constructor ({ commandSet }) {
    super()
    Object.assign(this, { commandSet })
  }

  static get css () {
    return {
      formattingBar: {
        display: 'grid',
        gridAutoFlow: 'column',
        backgroundColor: color.systemBackground.dark.secondary,
        color: color.text.dark.primary,
        height: 32,
        overflow: 'hidden',
        fontSize: 14,
        marginBottom: 10,
        lineHeight: 1.2,
        borderRadius: 3,
        boxShadow: 'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
      },
      formattingBarButton: {
        ...buttons.clickable,
        padding: '0px 15px',
        boxShadow: 'rgba(55, 53, 47, 0.09) 1px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '1px',
        transition: 'background-color 120ms ease-in 0s',
        '--icon-color': 'white',
        '&:hover': {
          backgroundColor: color.hover.dark.primary
        }
      },
      formattingBarButtonBold: {
        extend: '_formattingBarButton',
        fontWeight: 'bold'
      },
      formattingBarButtonItalics: {
        extend: '_formattingBarButton',
        fontStyle: 'italic'
      }
    }
  }

  get template () {
    const { jss } = this
    const simpleFormat = tag => () => toggleFormat(tag)
    const justify = align => () => this.commandSet.setJustify(align)

    return (
      <div class={jss.formattingBar}>
        <div class={jss.formattingBarButton} ko-ownClick={simpleFormat('b')}>
          {icons.inline(boldIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={simpleFormat('i')}>
          {icons.inline(italicsIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={simpleFormat('u')}>
          {icons.inline(underlineIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={simpleFormat('strike')}>
          {icons.inline(strikethroughIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={justify('Left')}>
          {icons.inline(alignLeftIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={justify('Center')}>
          {icons.inline(alignCenterIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={justify('Right')}>
          {icons.inline(alignRightIcon)}
        </div>
        <div class={jss.formattingBarButton} ko-ownClick={justify('Justify')}>
          {icons.inline(alignJustifyIcon)}
        </div>
      </div>
    )
  }
}

WritingFormattingBar.register()
