import { color } from 'styles'
import icons from 'icons'
import arrowLeft from 'icons/light/arrow-left'
import arrowRight from 'icons/light/arrow-right'

import RightView from './RightView'
import './search-results-list'

class MinuteBookRightViewSearch extends RightView {
  searchData: import('pdf-viewer/SearchData').default
  /**
   * @param {SearchData} searchData
   */
  constructor (params) {
    const { searchData } = params
    super(params)
    Object.assign(this, {
      searchData
    })
  }

  get title () { return 'Search' }

  get body () {
    const { jss } = this
    const sd = this.searchData
    const spinner = this.computed(() => this.searchData.isSearching()
      ? <loading-spinner class={this.jss.spinner} /> : null)

    return [
      <div class={jss.searchInput}>
        <input type='search'
          ko-hasFocus={1}
          ko-keydown={{
            ArrowDown: () => sd.nextResult(),
            ArrowUp: () => sd.previousResult(),
          }}
          placeholder='Search minute book'
          ko-textinput={sd.immediateQuery} />
        {spinner}
      </div>,
      <div class={jss.searchNavigation} ko-visible={sd.immediateQuery}>
        <span class={jss.searchCounts}>
          { this.computed(() => `${sd.resultFocus()} of ${sd.resultCount()}`) }
        </span>
        <span class={jss.searchNavigationButtons}>
          <span ko-ownClick={() => sd.previousResult()}>
            {icons.inline(arrowLeft)}
          </span>
          <span ko-ownClick={() => sd.nextResult()}>
            {icons.inline(arrowRight)}
          </span>
        </span>
      </div>,
      <search-results-list searchData={ sd } />
    ]
  }


  static get css () {
    return {
      ...super.css,
      searchInput: {
        display: 'flex',
        position: 'relative',
        height: '40px',
        color: color.text.light.secondary,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: `0.5px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.secondary,
        },
        borderRadius: '0px',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        padding: '0px 10px',
        '& i': {
          color: color.text.light.secondary
        },
        '& input': {
          border: '1px solid #dbdbdb',
          padding: '7px 10px 7px 20px',
          margin: 'auto',
          color: color.text.light.primary,
          width: '100%',
          borderRadius: '4px',
          minWidth: '210px',
          backgroundColor: color.textInput.light.primary,
          'body[dark] &': { // project batman
            backgroundColor: color.textInput.dark.primary,
            color: color.text.dark.primary
          },
        },
        '& input:focus': {
          outline: 'none'
        }
      },

      searchNavigation: {
        backgroundColor: color.systemBackground.light.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '0.1em',
        width: '100%',
        color: color.text.light.secondary,
        height: '50px',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.secondary
        },
      },

      searchCounts: {
        fontSize: '0.9em',
        minWidth: '75px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        borderRadius: '25px',
        padding: '5px 7px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: color.systemBackground.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },

      searchNavigationButtons: {
        '--icon-color': color.text.light.secondary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.secondary,
        },
        '& svg': {
          marginRight: '0.7em',
          padding: '0.4em',
          height: '1.8em',
          '&:hover': {
            backgroundColor: color.systemBackground.light.secondary,
            'body[dark] &': { // project batman
              backgroundColor: color.systemBackground.dark.secondary,
            },
            borderRadius: '3px',
            boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.1)',
            '&:active': {
              borderRadius: '3px',
              boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.3)',
            }
          },
        }
      },

      spinner: {
        position: 'absolute',
        '--icon-height': '24px',
        alignSelf: 'center',
        margin: '2px',
        right: '40px',
        width: '36px',
      },

    }
  }
}

MinuteBookRightViewSearch.register()
