import ViewComponent from 'ViewComponent'
import { toLocaleDecimal } from 'utils/number'

import { color } from 'styles'

import {
  CapitalState, ShareholderLedger, AssetLedgerLine
} from 'capital'

export class CapitalHolderLedger extends ViewComponent {
  state: CapitalState
  ledger: ShareholderLedger

  constructor (params) {
    super()
    const { ledger, state } = params
    Object.assign(this, { ledger, state })
  }

  static get css () {
    return {
      ...super.css,
      ...this.certificateCSS,
      grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(8, auto)',
        alignItems: 'center',
      },
      header: {
        extend: '_gridStyle',
        fontWeight: 'bold',
        backgroundColor: color.fill.light.primary,
        borderTop: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
          borderTop: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        // '&: first-child': {
        // borderLeft: '1px solid black'
        // }
      },
      _gridStyle: {
        // 🧙‍♂️ Vertical borders are more challenging;
        //     prefer horizontal-only.  @daniel let me
        //     know if any vertical borders are particularly
        //     desirable. - @brian 4 May 2019
        // borderBottom: '1px solid black',
        // borderRight: '1px solid black',
        padding: '10px 10px'
      },
      transferNumber: {
        gridColumnStart: 1,
        extend: '_gridStyle',
        // borderLeft: '1px solid black'
      },
      date: {
        gridColumnStart: 2,
        extend: '_gridStyle'
      },
      title: {
        gridColumnStart: 3,
        extend: '_gridStyle'
      },
      credit: {
        gridColumnStart: 4,
        textAlign: 'right',
        extend: '_gridStyle'
      },
      debit: {
        gridColumnStart: 5,
        textAlign: 'right',
        extend: '_gridStyle'
      },
      counterParties: {
        gridColumnStart: 7,
        extend: '_gridStyle'
      },
      balance: {
        gridColumnStart: 8,
        textAlign: 'right',
        extend: '_gridStyle'
      },
      gridLine: {
        gridColumn: '1/-1',
        borderBottom: '1px solid #aaa',
        '&:last-child': {
          display: 'none'
        }
      }
    }
  }

  get headColumns () {
    const { jss } = this
    return [
      <div class={jss.header}>#</div>,
      <div class={jss.header}>Date</div>,
      <div class={jss.header}>Action</div>,
      <div class={jss.header}>Credit</div>,
      <div class={jss.header}>Debit</div>,
      <div class={jss.header}>Certificates</div>,
      <div class={jss.header}>Party</div>,
      <div class={jss.header}>Balance</div>,
    ]
  }

  ledgerNumber (number: Big, cssClass: string) {
    const amount = toLocaleDecimal(Math.abs(number))
    return (
      <div class={cssClass}>
        {number.lt(0) ? `(${amount})` : amount}
      </div>
    )
  }

  /**
   * @yield the columns of the ledger line
   */
  * ledgerLineHTML (line: AssetLedgerLine) {
    const { jss } = this

    yield (
      <div class={jss.transferNumber}>{line.transferNumber}</div>
    )
    yield (<div class={jss.date}>{line.datetime}</div>)
    yield (<div class={jss.title}>{line.transactionTitle}</div>)

    const deltaClass = line.delta.lt(0) ? jss.debit : jss.credit
    yield this.ledgerNumber(line.delta, deltaClass)
    yield this.certificatesOf(line)

    yield (
      <div class={jss.counterParties}>
        {[...line.counterParties].join(', ')}
      </div>
    )
    yield this.ledgerNumber(line.balance, jss.balance)
    yield this.gridLine
  }

  static get certificateCSS () {
    return {
      certList: {
        gridColumn: 6,
        display: 'inline',
        justifySelf: 'flex-end',
      },
      _cert: {
        fontSize: '0.75rem',
      },
      certIssue: {
        extend: '_cert',
        color: 'green',
      },
      certSurrender: {
        extend: '_cert',
        color: 'red',
      }
    }
  }

  certificatesOf (line: AssetLedgerLine) {
    const { jss } = this
    return (
      <certificates-list class={jss.certList}
        asset={line.asset}
        issued={line.certificatesIssued}
        surrendered={line.certificatesSurrendered} />
    )
  }

  get gridCells () : any[] {
    return this.ledger.lines
      .map(line => [...this.ledgerLineHTML(line)])
      .flat()
  }

  get gridLine () :any {
    return (
      <div class={this.jss.gridLine} />
    )
  }

  get template () {
    const { jss } = this

    return (
      <div class={jss.grid}>
        {this.headColumns}
        {this.computed(() => this.gridCells)}
      </div>
    )
  }
}

CapitalHolderLedger.register()
