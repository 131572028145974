
import ViewComponent from 'ViewComponent'

import { typography, color, buttons } from 'styles'

type RightViewTabGroup = import('./RightViewTabGroup').default

/**
 * RightView is an abstract base-class for Knockout Components.
 */
export default abstract class RightView extends ViewComponent {
  tabGroup: RightViewTabGroup

  constructor ({ tabGroup }) {
    super()
    if (tabGroup) { tabGroup.add(this) }
    Object.assign(this, { tabGroup })
  }

  abstract get title (): string
  abstract get body (): any

  get template () {
    const { jss } = this
    return (
      <div class={jss.view}>
        <div class={jss.title}>
          {this.titleOrMultiTitle}
        </div>
        <div class={jss.body}>
          {this.body}
        </div>
      </div>
    )
  }

  get titleOrMultiTitle () {
    const { jss } = this
    return this.tabGroup
      ? <div class={jss.multiTitle}>{this.multiTitle}</div>
      : <div class={jss.singleTitle}>{this.title}</div>
  }

  multiTitlePart ({ title, click }) {
    const { jss } = this
    return title === this.title
      ? <div class={jss.multiTitlePartSelected}>{title}</div>
      : <div class={jss.multiTitlePart}
        ko-ownClick={() => click()}>
        {title}
      </div>
  }

  get multiTitle () {
    return [...this.tabGroup].map(t => this.multiTitlePart(t))
  }

  static get css () {
    return {
      ...super.css,
      view: {
        '--right-panel-pad': 'calc(15px + 1vw)',
        backgroundColor: color.gray.g,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
        },
        width: 'var(--right-panel-width)',
        height: '100%'
      },
      singleTitle: {
        padding: '8px 0 9px 0px',
        textAlign: 'center',
        borderBottom: `1px solid ${color.gray.c}`,
        fontFamily: typography.bodyFontFamily,
        fontWeight: '600',
        fontSize: '0.7rem',
        textTransform: 'uppercase',
        letterSpacing: '0.3px',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      multiTitle: {
        display: 'grid',
        grid: {
          autoColumns: '1fr',
          autoFlow: 'column'
        },
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      },
      _multiTitleCommon: {
        // These are styles common to multiTitlePart and ...PartSelected
        padding: '8px 0 9px 0',
        fontWeight: 600,
        fontSize: '0.8em',
      },
      multiTitlePart: {
        extend: '_multiTitleCommon',
        // This is the unselected multi-tab
        ...buttons.clickable,
        color: color.gray.a,
        backgroundColor: color.light.multiTitlePartUnselected,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          color: color.dark.text40
        },
        borderBottom: '1px solid #d9d9d9',
        '& + $multiTitlePart, & + $multiTitlePartSelected': {
          borderLeft: '1px solid #d9d9d9'
        }
      },
      multiTitlePartSelected: {
        extend: '_multiTitleCommon',
        color: '#333333',
        borderBottom: '1px solid transparent',
        '& + $multiTitlePart, & + $multiTitlePartSelected': {
          borderLeft: '1px solid #d9d9d9'
        },
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      body: {
        ...typography.sans,
        fontSize: '12px',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      }
    }
  }
}
