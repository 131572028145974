

import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class LandRegistration extends UnitView {
  get elvValue () { return this.municipal_address }

  get fields () {
    return {
      acquired: 'pureDate',
      disposed: 'pureDate',
      legal_description: 'string',
      municipal_address: 'string',
      registrar: 'string',
      pin: 'string',
      roll_number: 'string'
    }
  }
}

export default class LandRegistrationsComponent extends ArrayComponent {
  get ItemClass () { return LandRegistration }
}

LandRegistrationsComponent.register()
