import FirstLoginModal from './FirstLoginModal'

export default class FirstUserShareLoginModal extends FirstLoginModal {
  constructor () {
    super({ modalTitle: 'Welcome to MinuteBox' })
  }

  static get css () {
    return {
      ...super.css,
      container: {
      },
      frame: {
        /**
         * These should be the same heights/widths as in
         *    `first-sharee-login-video.html`
         */
        width: '640px',
        height: '360px',
        border: 'none',
      }
    }
  }

  get contentHTML () {
    const { jss } = this
    return (
      <div class={jss.container}>
        <iframe class={jss.frame}
          src='/first-sharee-login-video.html' />
      </div>
    )
  }
}

FirstUserShareLoginModal.register()
