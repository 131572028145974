
/**
 * Return the first n items generated by n
 * @param {int} n
 * @param {iterable} iter
 */
export function * first (n, iter) {
  while (n--) {
    const next = iter.next()
    if (next.done) { return next.value }
    yield next.value
  }
}

/**
 * @yield the first `n` values of the iterable
 */
export async function * firstAsync (n, asyncIter) {
  for await (const value of asyncIter) {
    yield value
    if (!n--) { return }
  }
}

/**
 * @param {Iterable} iter
 * @yield {Array} of [index, value] from the given iterable
 */
export function * enumerate<T> (iter: T[] | IterableIterator<T>): IterableIterator<[number, T]> {
  let i = 0
  for (const value of iter) {
    yield [i++, value]
  }
}

/**
 * Yield an array with `sep` between each item yielded from `iter`
 * e.g.  `interleave([1, 2, 3], sep)` generates `[1, sep, 2, sep, 3]`
 * @param {Iterable} iter
 * @param {any} sep
 */
export function * interleave (iter, sep) {
  let first = true
  for (const i of iter) {
    if (!first) { yield sep }
    first = false
    yield i
  }
}

export function * unique<T> (iter : Iterable<T>) : IterableIterator<T> {
  const items = new Set<T>()
  for (const item of iter) {
    if (!items.has(item)) {
      items.add(item)
      yield item
    }
  }
}

/**
 * True when `fn(item)` is true for some `item of iter`.
 */
export function some<T> (iter: Iterable<T>, fn: (T) => boolean) {
  for (const item of iter) { if (fn(item)) { return true } }
  return false
}

/**
 * True when every `fn(item)` is true for every `item of iter`
 */
export function every<T> (iter: Iterable<T>, fn: (T) => boolean) {
  for (const item of iter) { if (!fn(item)) { return false } }
  return true
}

/**
 * How many processors is the browser reporting that won't
 * slow down the main thread (or, 1, if there's one processor).
 */
const spareProcs = Math.max(navigator.hardwareConcurrency - 1, 1)

/**
 * Run up to `parallel` async processes, queued.
 *
 * Example:
 * for (let i = 0; i < 10; ++i) {
 *    fifo.add(() =>
 *      Promise.delay(1200)
 *        .then(() => console.info(i, i % 3)))
 * }
 *
 * With 4 processors this will output in groups of 3 i.e.
 * 0 1 2... 3 4 5... 6 7 8... 9
 */
export class Mutexer<T> {
  private running: KnockoutObservableArray<Promise<T>> = ko.observableArray([])
  private queue: Array<() => T> = []

  constructor (public parallel: number = spareProcs) {
    this.running
      .extend({ rateLimit: 5 })
      .subscribe(() => this.runQueued())
  }

  /**
   * Run or queue the given function, to be run in one of the
   * spare processors (or the main thread if there's only one
   * processor).
   * @param fn to be called in order
   * @param priority if true, then add to the front of the queue
   */
  add (fn: () => T, priority = false) {
    if (this.running.length < this.parallel) {
      this.run(fn)
    } else {
      this.queue[priority ? 'unshift' : 'push'](fn)
    }
  }

  clear () {
    this.running([])
    this.queue.length = 0
  }

  private run (fn: () => T) : Promise<T> {
    const p = new Promise<T>(resolve => resolve(fn()))
      .finally(() => this.running.remove(p))
    this.running.push(p)
    return p
  }

  private runQueued () {
    const { queue, running, parallel } = this
    if (queue.length && running.length < parallel) {
      this.run(this.queue.shift())
    }
  }
}
