
import BodyBlockEditor from './BodyBlockEditor'
import Paragraph from '../blocks/Paragraph'

export default class BlockParaEditor extends BodyBlockEditor<Paragraph> {
  static get css () {
    return {
      ...super.css,
      input: {
        ...super.css.input,
        lineHeight: '1.5em',
      }
    }
  }
}

BlockParaEditor.register()
