import { findLast } from 'lodash-es'
import { format as dateFormat } from 'date-fns/esm'

import { cycleNext, cyclePrev } from 'utils/array'
import trashIcon from 'icons/solid/trash'

import 'async-button'
import 'bookmarks-list'
import 'loading-spinner'
import 'sharing-editor'
import 'note-editor'
import 'notes-list'
import 'pdf-viewer'
import 'responsible'
import 'tags-editor'
import 'views/auto-save'
import 'confirm-delete/confirm-multiple-page-delete'
import 'upload-progress-to-entity'
import './pdf-upload-modal'

import ModelPanelProvider from 'ModelPanelProvider'
import EntityModel from 'EntityModel'
import { PDFEngine } from 'pdf-engine'
import { importFileUX } from 'import/importFileUx'
import { LocalImageCache } from 'utils/LocalImageCache'
import { Process } from 'utils/Process'
import { sendToDocuSign } from 'signatures'

import { color } from 'styles'

import ListOfNotes from 'notes-list/ListOfNotes'
import OutcomeNotification from 'notification-manager/outcome-notification'

import 'panels'
import 'minute-box-app/panel-left/view-list-panel-left'
import './minute-book-panel-head'
import { EntityDocumentsView } from "./main-view/EntityDocumentsView"
// import { CapTableView } from "./main-view/CapTableView"
// import { OrganizationalChartView } from "./main-view/OrganizationalChartView"
import { AuditView } from "./main-view/AuditView"
import { BookView } from "./main-view/BookView"
import { EntityInfoView } from "./main-view/EntityInfoView"
import { SplitView } from "./main-view/SplitView"
import MinuteBookCommandSet from './MinuteBookCommandSet'

import SearchData from 'pdf-viewer/SearchData'
import UndoManager from '../PanelProvider/UndoManager'
import Navigation from '../entity-information-summary/Navigation'

const HEAD_HEIGHT = `82px`
const MAIN_HEIGHT = `calc(100vh - ${HEAD_HEIGHT})`
const undoStackMap = new Map()


export default class MinuteBookPanelProvider extends ModelPanelProvider<EntityModel> {
  pdfEngine: import('pdf-engine').PDFEngine
  bookComponent: BookComponent
  undoManager: KnockoutObservable<UndoManager>

  get panelID () { return 'entity' }
  get panelWindowTitle () {
    return this.model.answerFor('legalname') || 'Entity'
  }

  static get Model () { return EntityModel }
  get CommandSetConstructor () { return MinuteBookCommandSet }

  /**
   * @param {BookComponent} book
   */
  constructor (params) {
    super(params)

    // Book is a BookComponent
    const book = this.model.content.getValueByKey('book') as BookComponent
    const entityName = this.model.content.getValueByKey('legalname')
    const pdfEngine = new PDFEngine()

    Object.assign(this, {
      bookSeries: book.bookSeries,
      pages: book.pages,
      sections: book.sections,
      bookmarks: book.bookmarks,
      entityName,
      bookComponent: book,
      leftPanelChange: ko.observable().extend({ notify: 'always' }),
      rightPanelChange: ko.observable().extend({ notify: 'always' }),
      currentPage: ko.observable(1)
        .extend({ localStorage: `mbpp:${this.model.id()}:currentPage` }),
      quickPick: ko.observable(),
      searchData: new SearchData(book.pages, pdfEngine),
      bookmarkDisplay: ko.observable(''),
      listOfNotes: new ListOfNotes(this.model),
      bookPages: book.pages,
      pdfEngine,
      thumbCache: new LocalImageCache(this, 'thumbs'),
      eisSectionNodes: ko.observableArray([]),
      rightView: ko.observable(),
      showContextView: ko.observable(true).extend({ localStorage: `mbpp:showContextView` }),
      showToC: ko.observable(true).extend({ localStorage: `mbpp:showToC` }),
      showSectionPageNumbers: ko.observable(false).extend({ localStorage: `mbpp:showSectionPageNumbers` }),
      showEditButton: ko.observable(false),
      eisNavigation: new Navigation(),
      showUndoButton: ko.observable(false),
      undoManager: ko.observable<UndoManager>(),
      showThumbnailView: ko.observable(false).extend({ localStorage: `mbpp:showThumbnailView` }),
      scrollToComponent: params.scrollToComponent,
      showAllEisFields: ko.observable(true),
      showSplitView: ko.observable(false).extend({ localStorage: `mbpp:showSplit` }),
    })

    // TODO Disabling the `editing` property. Eventually remove completely if not needed.
    this.editing = ko.computed({read: () => true, write: () => {}})
    this.editing.modify = () => {}

    this.subscribe(this.showAllEisFields, showAll => {
      if (showAll) {
        this.eisSectionNodes().forEach(sn => sn.hide(false))
      } else {
        const firstInView = this.eisSectionNodes().find(s => s.inView())
        this.eisSectionNodes().forEach(sn => {
          sn.hide(sn !== firstInView)
          sn.inView(sn === firstInView)
        })
      }
    })

    this.sectionOfCurrentPage = this.computed('sectionOfCurrentPage')
      .extend({ rateLimit: 125 })

    this.views = [
      new EntityInfoView(this),
      new BookView(this),
      new EntityDocumentsView(this),
      new SplitView(this),
      new AuditView(this),
      // new OrganizationalChartView(this),
      // new CapTableView(this),
    ]

    const settingsPP = window.app.rootProviders['displaySettings']
    const viewOption: string = settingsPP && ko.unwrap(settingsPP.viewOption) || 'lastGlobal'
    const storageKey =
      viewOption === 'last'
        ? `mbpp:${this.model.id()}:activeViewID`
        : `mbpp:global:activeViewID`
    this.activeViewID = ko.observable().extend({ localStorage: storageKey })

    if (params.activeViewID) {
      this.activeViewID(params.activeViewID)
    } else if (!viewOption.startsWith('last')) {
      this.activeViewID(viewOption)
    }
    this.showSplitView(this.activeViewID() === 'SplitView')

    this.views.forEach(view => this.addDisposable(view))

    this.activeView = this.computed(() =>
      this.views.find(v => v.id === this.activeViewID()) || this.views[1])
      .extend({ rateLimit: 10 })

    let unsplitView = 'BookView'
    this.computed(() => {
      if (this.showSplitView()) {
        unsplitView = ko.peek(this.activeViewID)
        if (unsplitView === 'SplitView') { unsplitView = 'BookView' }
        this.activeViewID('SplitView')
      } else if (this.activeViewID() === 'SplitView') {
        unsplitView = unsplitView || 'BookView'
        this.activeViewID(unsplitView)
      }
    })

    // Check permitted ESR views
    // FIXME: The view objects should provide a version of the `userCanAccess` method
    this.computed(() => {
      const views = []
      if (this.commandSet.userCanAccessContent('book')) { views.push('BookView') }
      if (this.commandSet.userCanAccessContent('eis')) { views.push('EntityInfoView') }
      if (this.commandSet.userCanAccessContent('audit')) { views.push('AuditView') }
      if (this.commandSet.userCanAccessContent('documents')) { views.push('EntityDocumentsView') }
      if (views.includes('BookView') && views.includes('EntityInfoView')) { views.push('SplitView') }
      if (!views.includes(this.activeViewID())) {
        this.activeViewID(views.length && views[0])
      }
    })

    this.subscribe(this.activeView, () => this.rightView(undefined))
    ko.computed(() => this.showEditButton(this.activeView().showEditButton))
    this.subscribe(this.activeView, () => this.editing(false))
    this.subscribe(this.editing, () => this.bookComponent.clearUndoHistory())

    ko.computed(() => this.showUndoButton(this.activeView().showUndoButton))

    // Update Quick Pick selection when page changes
    ko.computed(() => {
      const sections = this.sections()
      const currentSection = this.sectionOfCurrentPage()
      if (currentSection) { this.quickPick(currentSection.name) } else if (sections.length) { this.quickPick(sections[0].name) }
    })

    this.subscribe(this.panelDragOverEvent, evt => this.onDragging(evt))
    this.subscribe(this.listOfNotes.list, n => book.updateBookPageNotes(n))

    // Manage undo manager
    this.computed(() => {
      const undoKey = `${this.model.id()}:${this.activeViewID()}`
      if (!undoStackMap.has(undoKey)) {
        const manager = new UndoManager(this.model)
        undoStackMap.set(undoKey, manager)
        this.undoManager(manager)
        ko.computed(() => { // ko.computed because undoStackMap is global
          const pp = window.app.panelProvider()
          const activeManager = ko.unwrap(pp.undoManager)
          manager.isActive(activeManager === manager)
        })
      } else {
        this.undoManager(undoStackMap.get(undoKey))
      }
    })

    this.showThumbnailView.subscribe(v => v || this.pages().forEach(p => p.selected(false)))
  }

  get scrollKey () {
    return this.activeView().scrollKey
  }

  get localStorageCacheName () {
    return `entity/${this.model.accountID()}/${this.model.id()}`
  }

  dispose () {
    this.searchData.dispose()
    this.pdfEngine.dispose()
    this.thumbCache.dispose()
    super.dispose()
  }

  setAndScrollToPage (pageNumber) {
    pageNumber = ko.unwrap(pageNumber)
    this.currentPage(pageNumber)
    this.bookPages()[pageNumber - 1].scrollIntoView()
  }

  jumpToNextSection () {
    const sections = this.sections()
    if (!sections.length) { return }
    const currentSection = this.sectionOfCurrentPage()
    const nextSection = cycleNext(sections, currentSection)
    this.setAndScrollToPage(nextSection.pageNumber())
  }

  jumpToPrevSection () {
    const sections = this.sections()
    if (!sections.length) { return }
    const currentSection = this.sectionOfCurrentPage()
    if (this.currentPage() !== currentSection.pageNumber()) {
      this.setAndScrollToPage(currentSection.pageNumber())
      return
    }
    const prevSection = cyclePrev(sections, currentSection)
    this.setAndScrollToPage(prevSection.pageNumber())
  }

  jumpToNextEisCategory () {
    const nodes = this.eisSectionNodes()
    if (nodes.slice(-1).pop().inView()) {
      nodes[0].scrollIntoView() // cycle to top
    } else {
      cycleNext(nodes, nodes.find(n => n.inView())).scrollIntoView()
    }
  }

  jumpToPrevEisCategory () {
    const nodes = this.eisSectionNodes()
    cyclePrev(nodes, nodes.find(n => n.inView())).scrollIntoView()
  }

  get currentEisSection () {
    const nodes = ko.unwrap(this.eisSectionNodes)
    return nodes && nodes.find(({inView}) => ko.unwrap(inView))
  }

  /**
   * @param {int} currentPage
   * @return {BookPage|undefined}
   * Without any args, returns the book page of the current page.
   */
  bookPageAt (pageNumber = this.currentPage()) {
    return this.bookComponent.pages()[pageNumber - 1]
  }

  sectionOfCurrentPage () {
    const sections = this.sections()
    if (!sections.length) { return }
    if (sections[0].startPage() > this.currentPage()) { return }
    return findLast(sections, s => s.startPage() <= this.currentPage())
  }

  get head () { return <auto-save dataModel={this.model} /> }

  get topFixed () {
    return (
      <minute-book-panel-head
        style='width: 100%'
        book={this.model.answerFor('book')}
        currentPage={this.currentPage}
        rightView={this.rightView}
        panelProvider={this} />
    )
  }

  static get leftPanelCSS () {
    return {
      leftPanelHead: {
        display: 'flex',
        alignItems: 'center',
        padding: '15px 20px',
        margin: '2px',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'background 0.8s',
        '&:hover': {
          backgroundColor: '#D8D8D8'
        }
      },
      activeView: {
        borderLeft: '5px solid #3D70B2',
        paddingLeft: '15px',
        backgroundColor: 'hsla(0, 0%, 93%, 1)'
      },
      newTabBlock: {
        position: 'fixed',
        bottom: '75px',
        borderTop: '1px solid #bababa',
        backgroundColor: 'white',
        height: '75px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }

  static get css () {
    const rightPanelVars = {
      '--right-panel-width': 'calc(200px + 8vw)',
      '--right-panel-transition-in': 'transform 0.25s ease-in',
      '--right-panel-transition-out': 'transform 0.25s ease-out'
    }

    return {
      ...super.css,
      ...this.leftPanelCSS,
      ...this.loadingCSS,
      ...this.permissionDeniedCSS,
      ...this.pageDeleteNotificationCSS,

      dragOver: {
        '--drop-pad-background-color': 'var(--drop-pad-hover-background-color)'
      },

      main: {
        ...rightPanelVars,
        '--main-sticky-offset': 'calc(var(--sticky-offset) + 57px)',
        minHeight: MAIN_HEIGHT,
        backgroundColor: color.gray.e,
        transform: 'translate3d(0,0,0)',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
          color: color.text.dark.primary,
        },
      },

      panelRight: {
        ...rightPanelVars,
        display: 'block',
        position: 'fixed',
        left: '100%',
        zIndex: 20,
        height: 'calc(100vh - var(--head-height))'
      }
    }
  }

  addNoteClick (bookPage) {
    return this.listOfNotes.addOrEditNote(bookPage)
  }

  addShare (sections = null) {
    window.app.modal(
      <modal-dialog modalTitle='Sharing'>
        <template slot='content'>
          <sharing-editor model={this.model} sections={sections}/>
        </template>
      </modal-dialog>
    )
  }

  static get loadingCSS () {
    return {
      ...super.css,
      loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh'
      },
      indicator: {
        maxWidth: '50px'
      }
    }
  }

  get loadingHTML () {
    const { jss } = this
    return (
      <div class={jss.loading}>
        <loading-spinner class={jss.indicator} />
      </div>
    )
  }

  static get permissionDeniedCSS () {
    return {
      permissionDenied: {
        paddingTop: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }

  get permissionDeniedHTML () {
    // TODO: Something like the following, to test when the model
    //       permissions have been restored.
    // Note that the following design suffers some fatal race conditions.
    // const { model } = this
    // const { memoryDB } = this.app
    // const index = model.vmResourceName
    // const id = model.id()
    // const watching = this.computed(() => !!memoryDB.getModel(index, id))
    // watching.when(true).then(() => {
    //   // Permission has been restored.
    //   model.cmPermissionDenied(false)
    //   model.vmStartMonitoringSnapshots()
    //   watching.dispose()
    // })
    return (
      <div class={this.jss.permissionDenied}>Permission Denied</div>
    )
  }

  /**
   * For an overview of the ideas for the main template, see e.g.
   *    https://stackoverflow.com/c/conductor/questions/21
   */
  get main () {
    return (
      <div class={this.jss.main}
        right-panel-open={this.rightView}
        left-panel-open={this.computed(() => this.showToC() || undefined)}>
        { this.computed(() => this.mainViewHTML) }
      </div>
    )
  }

  get mainViewHTML () {
    if (this.model.cmPermissionDenied()) {
      return this.permissionDeniedHTML
    }
    if (this.activeView().loading()) { return this.loadingHTML }
    return this.activeView().main
  }

  get left () {
    return <view-list-panel-left
      activeViewID={this.activeViewID}
      views={this.views}
      showPanel={this.showToC} />
  }

  get right () {
    return (
      <panel-right
        class={this.jss.panelRight}
        view={this.rightView} />
    )
  }

  /**
   * Drag & Drop Handling
   * @param {DragEvent} evt
   * @param {MinuteBoxApp} app
   */
  onDrop (evt, app) {
    super.onDrop(evt, app)

    for (const file of evt.dataTransfer.files) {
      const lcFilename = file.name.toLowerCase()
      if (lcFilename.endsWith('.pdf')) {
        const panel = evt.target.closest('[panel-name]').getAttribute('panel-name')
        const handler = this[`${panel}Drop`]
        if (handler) { handler.call(this, evt, app) }
      } else if (lcFilename.endsWith('.xc.zip')) {
        this.handleXcDrop(file)
      }
    }
  }

  cancelUpload () {
    this._cancelUpload = true
  }

  handleXcDrop (file: File) {
    const process = async () => {
      const entity = await importFileUX(file, this.model.authManager)
      await entity.vmSave()
      // if (entity.id !== this.model.id()) {  }
    }
    window.app.notifier.registerActivity('Converting data', 'Complete', 'Fail', process())
  }

  mainDrop (evt) {
    window.app.modal(
      <pdf-upload-modal
        book={this.bookComponent}
        dropEvt={evt}
        uploadAction={files => this.uploadPdfMultipleFiles(files)}
        cancelAction={() => this.cancelUpload()}
      />)
  }

  async uploadPdfMultipleFiles (files: File[]) {
    const { bookComponent } = this
    this.editing(true)
    const newPages = new Map()
    for (const file of files) {
      const upload = bookComponent.uploadPagesFromPdf(file, {}, () => this._cancelUpload)
      const { processState } = upload
      const cancelButton = (
        <div class={window.app.jss.buttonClean}
          ko-ownClick={() => processState.cancel()}>
          Cancel
        </div>
      )
      const message = (
        <upload-progress-to-entity upload={upload} entity={upload.entity} />
      )
      this.app.notifier.pushProgress(message, upload.percent, { actions: cancelButton })
      await Promise.delay(50)
      const filePages = await upload.process()
      if (processState.isCancelled()) { return [] }
      filePages.forEach(bp => newPages.set(bp.pageID, bp))
    }
    return [...newPages.values()]
  }

  async sendForSig (pages: BookPage[]) {
    if (!pages || !pages.length) { pages = this.bookComponent.pages() }
    return sendToDocuSign(pages, this.pdfEngine)
  }

  async downloadPages (pages = [], filename = '') {
    if (!pages.length) {
      pages = this.bookComponent.pages()
      filename = filename ||
        `${this.entityName()} minute book ${dateFormat(new Date(),"yyyy-MM-dd")}.pdf`
    } else {
      filename = filename ||
        `${this.entityName()} minute book excerpt ${dateFormat(new Date(),"yyyy-MM-dd")}.pdf`
    }

    const { jss } = window.app
    const process = new Process(pages.length)

    const message = ko.pureComputed(() => process.isCancelled()
      ? 'Cancelled'
      : (
        <span>
          Compiling
          <span class={jss.highlight1}>{filename}</span>
          for download.
        </span>
      )
    )

    const cancelButton = (
      <div class={jss.buttonClean}
        ko-click={() => process.cancel()}>
        Cancel
      </div>
    )

    this.app.notifier.pushProgress(message, process.percent,
      { actions: cancelButton })

    const pdf = await this.pdfEngine.compile(pages, process)
    if (process.isCancelled()) { return }
    process.isComplete(true)
    return window.saveAs(pdf, filename)
  }

  async downloadSection (section) {
    const filename = `${this.entityName()} ${section.name()} ${dateFormat(new Date(),"yyyy-MM-dd")}.pdf`
    return this.downloadPages(this.bookComponent.sectionTree.getPagesForSection(section), filename)
  }

  leftDrop (evt, app) {
    console.debug('Drop onto the left-panel / sections')
  }

  rightDrop (evt, app) {
  }

  /**
   * Respond to dragging by indicating the UX
   * @param {DragEvent} evt
   *
   * When the user is dragging:
   * 1. show the outline that indicates that the PDF can be dropped
   * 2.
   */
  onDragging (evt) {
    const { jss } = this
    if (!evt) { return }
    if (evt.dataTransfer) { evt.dataTransfer.dropEffect = 'copy' }

    // Show the drop-zones/overlays
    document.querySelectorAll(`.${jss.dragOver}`)
      .forEach(e => e.classList.remove(jss.dragOver))
    const panelEl = evt.target.closest && evt.target.closest('[panel-name]')
    if (panelEl) {
      const panel = panelEl.getAttribute('panel-name')
      if (panel !== 'right') {
        panelEl.classList.add(jss.dragOver)
      }
    }
  }

  static get pageDeleteNotificationCSS () {
    return {
      pageDeleteOutcome: {
        ...OutcomeNotification.css.outcome
      },
      pageDeleteMessage: {},
      pageDeleteIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      }
    }
  }

  pageDeleteNotification (pageCount) {
    const { jss } = this
    return [
      <span>
        Success: Deleted <b>{pageCount}</b> page{pageCount > 1 ? 's' : ''}.
      </span>,
      {
        icon: trashIcon,
        style: {
          outcome: jss.pageDeleteOutcome,
          message: jss.pageDeleteMessage,
          icon: jss.pageDeleteIcon,
        },
      },
    ]
  }

  deleteSelectedPages () {
    const { notifier } = this.app
    const selected = this.bookComponent.pages().filter(pg => pg.selected())
    if (!selected.length) { return }
    const pageCount = selected.length
    const deletePages = async () => {
      this.bookComponent.deleteSelectedPages()
      await this.model.vmSave()
      notifier.pushOutcome(...this.pageDeleteNotification(pageCount))
    }
    global.app.modal(
      <modal-dialog modalTitle={`Delete Selected Pages`}>
        <template slot='content'>
          <confirm-multiple-page-delete
            pageCount={pageCount}
            onConfirm={deletePages}
            onFinish={() => global.app.modal(undefined)} />
        </template>
      </modal-dialog>
    )
  }
}
