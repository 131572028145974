
type Block = import('../blocks/Block').default
type WritingDocumentEditor = import('../writing-views/writing-document-editor').default
type ArrayOrderedByIndex<T> = import('utils/ArrayOrderedByIndex').default<T>

export function blockEditorSwitch (block: Block, blocks: ArrayOrderedByIndex<Block>, cssClass, index, editor: WritingDocumentEditor) {
  switch (block.code) {
    case 'p':
      return (
        <block-para-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 'h':
      return (
        <block-heading-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 'h2':
      return (
        <block-subheading-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )

    case 'np':
      return (
        <block-numbered-para-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 'q':
      return (
        <block-quote-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 'l':
      return (
        <block-list-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 'b':
      return (
        <block-bullet-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 's':
      return (
        <block-array-component-signatures-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
    case 'sc':
      return (
        <block-capital-signatures-editor class={cssClass}
          grip-item={index}
          grip-selected={block.isSelected}
          editor={editor}
          block={block}
          blocks={blocks} />
      )
  }
  throw new Error(`blockEditorSwitch: Unknown code: ${block.code}`)
}

