import { format } from 'date-fns/esm'

import { batchedOperation } from 'utils/firestore'
import SharingRight from './SharingRight'
import { CrudModel } from '.';
export { eventIdentifier } from './SharingRight'

export default class Sharing implements PersonGenerator {
  sharingRights : KnockoutObservableArray<SharingRight>
  model: CrudModel

  constructor (model) {
    Object.assign(this, {
      model,
      sharingRights: ko.observableArray([]),
    })
  }

  newHolderInstance () { return this }

  set (values) {
    this.sharingRights(values.sharingRights.map(v => new SharingRight(v)))
  }

  toJS () {
    const rights = this.sharingRights()
    return {
      sharingRights: rights.map(sr => sr.toJS()),
      email: rights.map(sr => sr.email),
    }
  }

  * genRemindersAtDate (given, range) {
    for (const right of this.sharingRights()) {
      yield * right.genRemindersAtDate(given, range)
    }
  }

  get visibleSharingRights () {
    const { accountID } = window.app.defaultAuthManager
    if (this.model.accountID() !== accountID()) { return [] }
    return this.sharingRights()
  }

  /**
   * @param {string} email
   * @return {bool} true when the user needs a projection
   */
  needsProjectionFor (email) {
    const rights = this.getRightsOf(email)
    return rights
      ? rights.needsProjection
      : this.model.accountID() !== window?.app?.defaultAuthManager?.accountID()
  }

  /**
   * @param {string} email
   * @return {SharingRight}
   */
  getRightsOf (email) {
    return this.sharingRights().find(sr => sr.email === email)
  }

  /**
   * @param {string} email
   * @param {Array.<object>} permits
   * @param {string} permits[].name showing in the list
   * @param {string} permits[].target of the permission e.g. component name
   * @param {string} permits[].param to give to the target
   */
  addOrUpdate (email, permits, expiryDate) {
    const existing = this.getRightsOf(email)
    const expires = expiryDate ? format(expiryDate, 'yyyy-MM-dd') : null
    if (existing) {
      existing.permits(permits)
      existing.expires = expires
    } else {
      this.sharingRights.push(new SharingRight({ email, permits, expires }))
    }
  }

  /**
   * @return {string} the Firestore path to the projection.
   */
  projectionPath (model, email) {
    return [
      model.vmFirestoreDocPath, 'projection', this.getProjectionID(email)
    ].join('/')
  }

  /**
   * @return {string} for Firestore projection ID.
   * We add a prefix to account for the possibility that in future we may
   * have sharing that isn't just to email.
   */
  getProjectionID (email) {
    return `e:${email}`
  }

  /**
   * Save all the projections.
   * TODO: prune projections that no longer exist.
   * Note: We are currently limited to 500 projections b/c of the Firestore
   *       batch limit.
   */
  async afterModelSave ({ model }) {
    const { firestore } = model.authManager

    const iterable = [...model.sharing.sharingRights]
      .filter(r => r.needsProjection)

    console.info(`[Sharing] Beginning Batch Operation`)
    batchedOperation(firestore, iterable, async (rights, batch) => {
      const projectionPath = this.projectionPath(model, rights.email)
      const projDoc = firestore.doc(projectionPath)
      console.info(
        `[Sharing] Saving projection
          Model: ${model.cvModelTitle}
          User:  ${rights.email}
          Path:  ${projectionPath}`)

      const projValues = await rights.generateProjection(model)
      batch.set(projDoc, projValues)
    })
  }

  /**
   * @return {bool}|undefined `false` if the model is a projection,
   * thereby preventing saving.
   */
  beforeModelSave ({ model }) {
    const email = model.authManager.firebaseUser().email
    if (this.needsProjectionFor(email)) { return false }
  }

  /**
   * ⚠️ This is used by Firestore rules.
   *
   * The projection.sharing.recipient will be the email of the recipient,
   * which can be used for simplified Firestore rules.
   */
  projectionValue (_, email) {
    const expires = this.getRightsOf(email).expires
    return {
      recipient: email,
      expires: expires ? new Date(expires).getTime() : null,
    }
  }

  * getPersons (filter?: PersonFilter) : IterableIterator<PersonRecord> {
    for (const sr of this.sharingRights()) {
      sr.person.origin.forEach(o => o.model = this.model)
      if (!filter || filter(sr.person)) { yield sr.person }
    }
  }
}
