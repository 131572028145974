
import color from './color'
import buttons from './buttons'
import dropdown from './dropdown'
import typography from './typography'

/**
 * Grippy lists
 *
 * Used by e.g.
 *  <filters-list>
 *  <minute-book-sections-list>
 */
export const grippy = {
  list: {
    position: 'relative',
    marginLeft: '5px',
    backgroundColor: color.gray.g,
    '--icon-color': 'black',
    color: color.text.light.primary,
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.secondary,
      '--icon-color': color.text.dark.primary,
      color: color.text.dark.primary,
    },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '0.7rem',
    fontFamily: typography.bodyFontFamily,
    fontWeight: '600',
    marginRight: '20px',
    marginBottom: '2px',
    transitionDuration: '0.160s',
    '&:hover': {
      '& $grip, & $action': {
        visibility: 'visible'
      },
      '& $hoverable': { extend: 'hovered' }
    },
    '&[grip-selected="true"] $hoverable': {
      extend: 'hovered'
    },
    '&[gripping]': {
      opacity: 0
    },
    '&[grip-clone]': {
      '& $hoverable': { extend: 'hovered' }
    }
  },
  hoverable: {
    ...buttons.clickable,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 0px',
    width: '100%'
  },
  hovered: {
    extend: 'hoverable',
    backgroundColor: color.rowHoverColor,
    'body[dark] &': { // project batman
      backgroundColor: color.fill.dark.secondary,
    },
  },
  _button: {
    visibility: 'hidden',
    padding: '5px 10px'
  },
  itemIcon: {
    margin: '0px 10px',
    fontSize: '0.7rem'
  },
  grip: {
    extend: '_button',
    ...buttons.grippable,
    '--icon-color': color.secondaryButton,
    backgroundColor: color.gray.g,
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.secondary,
      '--icon-color': color.text.dark.primary,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '22px',
    marginRight: 'auto',
    borderRadius: '4px',
    marginLeft: '10px',
    '$itemIcon + &': {
      margin: 0
    }
  },
  action: {
    extend: '_button',
    ...buttons.clickable,
    padding: '0px 0px 0px 10px',
    marginLeft: 'auto',
  },
  actionButton: {
    padding: '0px 1rem',
    fontSize: '1.2rem',
    '--icon-color': '#a9a9a9',
    '&:hover': {
      '--icon-color': 'black'
    }
  },
  actionMenu: {
    ...dropdown.generalMenu,
    display: 'grid',
    alignItems: 'baseline',
    backgroundColor: 'white'
  },
  actionItem: {
    ...dropdown.item,
    fontWeight: 'normal',
    fontSize: '0.9rem',
    display: 'block',
    textAlign: 'left',
    width: '100%'
  }
}

export default { grippy }
