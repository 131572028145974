//
// ComputedMediator
// ---
// Serialize and deserialize computed values.
//
// The `options` can be an object, string or function.
//
// An object will be passed to ko.computed.  A 'read_method' option passed
// to the constructor options shall be used as the method name on the model
// that is called as the compute function.
//
// The computeds are read-only.
//
// Computed values will not be returned until the model has finished loading
// and a value has been set by the server.
//
import {
  isObject
} from 'lodash-es'

import MediatorInterface from './MediatorInterface'

export default class ComputedMediator extends MediatorInterface {
  constructor(name, model, options) {
    super(name, model)
    this.options = options
    this.knows_value_from_server = ko.observable(false)

    if (isObject(options)) {
      // nop.
    } else if (typeof options === 'function') {
      this.options = { read: options }
    } else if (typeof options === 'string') {
      this.options = { read_method: options }
    } else {
      eraro.raise("ComputedMediator - bad options type.", options)
    }
  }

  newHolderInstance() {
    var options = { owner: this.model }
    var method = this.options.read_method ?
      this.model[this.options.read_method].bind(this.model) :
      this.options.read

    Object.assign(options, this.options, {
      read: method,
      deferEvaluation: true
    })

    return ko.computed(options) //.extend({ rateLimit: RATE_LIMIT })
  }

  toJS() {
    // We only start returning the generated version once the model has
    // completed loading (lest we save inchoate values), plus we have
    // received a value from the server – so that what we return shall
    // be what was loaded.
    if (this.model.vm_is_loaded() && this.knows_value_from_server()) {
      return this.model[this.name]()
    }
    return this.loaded_value
  }

  fromJS(value) {
    this.knows_value_from_server(true)
    this.loaded_value = value
  }
}
