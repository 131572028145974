import DateVariable from './DateVariable'
import TimeVariable from './TimeVariable'
import TextVariable from './TextVariable'

import {
  ComponentVariable, SimpleComponentVariable, ComponentPureDate, ComponentPerson,
} from './ModelVariable'
import {
  ArrayComponentVariable, ArrayComponentPerson,  ArrayComponentPureDate,
} from './ArrayComponentVariable'
import {
  CapitalAssetVariable, CapitalHolderVariable,
} from './CapitalVariable'

type SlotManager = import('./slots').SlotManager
type VariableConstructors = typeof ArrayComponentPerson
  | typeof ArrayComponentPureDate
  | typeof ArrayComponentVariable
  | typeof CapitalAssetVariable
  | typeof CapitalHolderVariable
  | typeof ComponentPerson
  | typeof ComponentPureDate
  | typeof ComponentVariable
  | typeof DateVariable
  | typeof SimpleComponentVariable
  | typeof TextVariable
  | typeof TimeVariable

export type VariableType = ComponentVariable
  | ArrayComponentPerson
  | ArrayComponentVariable
  | ArrayComponentPureDate
  | CapitalAssetVariable
  | CapitalHolderVariable
  | ComponentPureDate
  | ComponentPerson
  | DateVariable
  | SimpleComponentVariable
  | TextVariable
  | TimeVariable


const registry: Record<string, VariableConstructors> = {}

type Serialized = {
  code: string
  [b: string]: any
}

export function factory (serialized: Serialized, slotManager: SlotManager) {
  const { code, ...rest } = serialized
  const Ctor = registry[code]
  if (!Ctor) {
    throw new Error(`Variable Registry bad code: "${code}".`)
  }
  return new Ctor(slotManager).assignVariables(rest)
}

function register (Var: VariableConstructors) {
  const code = Var.prototype.code
  if (registry[code]) { throw new Error(`Duplicate code: "${code}"`) }
  registry[code] = Var
}

register(ArrayComponentPerson)
register(ArrayComponentPureDate)
register(ArrayComponentVariable)
register(CapitalAssetVariable)
register(CapitalHolderVariable)
register(ComponentPerson)
register(ComponentPureDate)
register(ComponentVariable)
register(DateVariable)
register(SimpleComponentVariable)
register(TextVariable)
register(TimeVariable)
