
import EisMultiComponentField from './EisMultiComponentField'

import { typography, color } from 'styles'
import { observeProperty } from 'person'

export default class EisClientMatter extends EisMultiComponentField {
  constructor(args) {
    super(args)
    this.registerNavigation()
  }

  get componentNames () { return ['client', 'matter_number'] }

  get componentsMap () {
    // For 'client' we only want the first name value from the PersonRecord
    if (this._componentMap) { return this._componentMap }
    const cMap = super.componentsMap
    cMap['client'] = observeProperty(cMap['client'], 'name', 0)
    return this._componentMap = cMap
  }

  static get css () {
    return {
      ...super.css,
      contentArea: {
        gridArea: 'content',
        display: 'grid',
        gridTemplateColumns: '1fr auto 65px',
      },
      title1: {
        ...super.css.title,
      },
      indicatorTitle: {
        ...super.css.title,
      },
      indicatorTitleExtras: {
      },
      field1Area: {
        display: 'flex',
        alignItems: 'flex-end',
      },
      field1: {
        ...super.css.textContent,
        ...super.css.fieldLarge
      },
      indicator: {
        ...super.css.indicator,
        margin: '4px 0 4px 4px',
      },
      editControls: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        padding: 10
      },
      _title: {
        'body[dark] &': { // project batman
          color: color.text.dark.altPrimary,
        },
      },
      clientTitleEdit: {
        ...super.css.title,
        gridColumn: '1',
        '& > span': {
          extend: '_title'
        }
      },
      matterTitleEdit: {
        ...super.css.title,
        gridColumn: '3',
        '& > span': {
          extend: '_title'
        }
      },
      inputFieldMeta: {
        gridColumn: '1',
        gridRow: '2'
      },
      inputFieldMeta2: {
        gridColumn: '3',
        gridRow: '2'
      },
      inputField: {
        width: '-webkit-fill-available',
        height: 40,
        fontFamily: typography.bodyFontFamily,
        padding: 5,
        margin: 5,
        fontSize: '1rem',
        borderRadius: 2,
        backgroundColor: color.light.eisDrawerBackground,
        border: '1px solid transparent',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
          borderBottom: `1px solid ${color.separator.dark.altPrimary}`,
        },
        transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        '&:focus': {
          outline: 'none',
          transition: 'box-shadow 200ms ease-in-out, border-left 200ms ease-in-out, border-radius 200ms ease-in-out',
        }
      }

    }
  }

  get titleAreaHTML() {}

  get title1HTML () {
    return (
      <div class={this.jss.title1}>
        <span>Client Name</span>
        {this.copyIconHTML(() => this.displayValue.client())}
      </div>
    )
  }

  get displayHTML () {
    const { jss } = this
    const clientNameFocus = this.computed(() => this.inputfocus.client() || undefined)
    const matterNumberFocus = this.computed(() => this.inputfocus.matter_number() || undefined)
    return [
      this.title1HTML,
      <div class={jss.indicatorTitleArea}>
        <div class={jss.indicatorTitle}>
          <span>Matter Number</span>
        </div>
      </div>,
      <div class={jss.indicatorTitleExtras}>
        <q-tip class={this.jss.qtip} name={'eisMatterNumber'} />
        {this.copyIconHTML('matter_number')}
      </div>,

      <div class={jss.field1Area}>
        <div class={jss.field1} focused={clientNameFocus}
          blank={this.computed(() => this.componentsMap.client() ? undefined : 'true')}>
          {this.displayValue.client}
        </div>
      </div>,
      <div class={jss.indicatorArea}>
        <div class={jss.indicator} focused={matterNumberFocus}
          blank={this.computed(() => this.componentsMap.matter_number() ? undefined : 'true')}>
          {this.displayValue.matter_number}
        </div>
      </div>,
    ]
  }

  get editHTML () {
    const { jss } = this
    const koEvent = { keydown: this.inputKeyDown({ 'Tab': evt => this.onTabDown(evt) }) }
    const hasFocus = this.computed(() => this.editing() &&
      this.inputfocus.client())
    const onSelect = v => {
      this.inputs.client(v)
      // this.triggerSave() FIXME -- closes when user use the keyboard to
      // select the input.
    }
    return (
      <div class={jss.editControls}>
        <div class={this.jss.clientTitleEdit}>
          <span>Client Name</span>
        </div>
        <div class={this.jss.matterTitleEdit}>
          <span>Matter Number</span>
          <q-tip class={this.jss.qtip} name={'eisMatterNumber'} />
        </div>
        <div class={jss.inputFieldMeta}>
          <client-picker
            my='top left'
            at='bottom left'
            hasFocus={hasFocus}
            inputClass={jss.inputField}
            onSelect={onSelect}
            placeholder='Client name'
            value={this.inputs.client}
            extraChoices={[...this.entity.getPersons()].map(p => p.name).flat()}
            keydownEvents={{
              Tab: evt => this.onTabDown(evt),
            }}
            />
        </div>
        <div class={jss.inputFieldMeta2}>
          <input type='text' placeholder='Matter number' ko-textInput={this.inputs.matter_number}
            ko-hasFocus={this.computed(() => this.editing() && this.inputfocus.matter_number())}
            ko-event={koEvent} class={jss.inputField} />
        </div>
      </div>
    )
  }

  onSave () {
    if (this.componentsMap.client() !== this.inputs.client()) {
      const { record } = this.entity.componentFor('client')
      record({ ...record(), id: null })
    }
    super.onSave()
  }
}

EisClientMatter.register()
