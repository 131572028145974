/**
 * Sanitization DOM nodes
 */
import { unwrap } from './dom'

const { TEXT_NODE, ELEMENT_NODE, COMMENT_NODE } = document

type AttrTest = (attr: Attr) => boolean
type AttrMatch = { [attributeName: string]: true | AttrTest }

const WHITELIST = {} as { [tagName: string]: AttrMatch }

export function whiteList (tagName: string, attrs: AttrMatch = {}) {
  WHITELIST[tagName.toUpperCase()] = attrs
}

const BASIC_ATTRS = [
  'b', 'u', 'i', 'strike', 'em', 'strong', 's', 'del', 'ins',
]
BASIC_ATTRS.forEach(tagName => whiteList(tagName))

/**
 * Sanitize on input.  This is a fairly customizable node-tag and attribute
 * taint-checking.
 */
export default function sanitize (node: Element, anyTagname = false) {
  const children = [...node.childNodes]
  const attrMatch = WHITELIST[node.tagName]
  if (!anyTagname && !attrMatch) { unwrap(node) }

  for (const child of children) {
    switch (child.nodeType) {
      case TEXT_NODE:
        sanitizeTextNode(child as Text)
        break
      case ELEMENT_NODE:
        sanitizeElement(child as Element)
        break
      case COMMENT_NODE:
        break
      default:
        console.warn(`Removing node of type ${child.nodeType}`, child)
        child.remove()
    }
  }
}

/**
 * Normalize the space; reduce multiple spaces to one.
 * See http://stackoverflow.com/questions/1495822
 * TODO:
 *  - To prevent Selection Range being reset, use: CharacterData.replaceData()
 *    (instead of node.nodeValue = node.nodeValue.replace(MULTI_SPACE_REX, ' '))
 */
function sanitizeTextNode (node: Text) {
}

function isDynamic (node: Element) { return Boolean(ko.contextFor(node)) }

/**
 * Several cleanups:
 * 1. remove empty nodes
 * 2. remove attributes
 * 3. merge nodes with identical peers
 * 4. normalize text content
 * 5. recurse sanitization over children
 */
function sanitizeElement (node: Element) {
  if (!node.textContent && !isDynamic(node)) { // 1.
    node.remove()
    return
  }

  const attrMatch = WHITELIST[node.tagName] || {}
  for (const attr of [...node.attributes]) { // 2.
    const attrTest = attrMatch[attr.name]
    if (attrTest === true || attrTest && attrTest(attr)) { continue }
    node.removeAttribute(attr.name)
  }

  possiblyMergeWithNextSibling(node) // 3.
  node.normalize() // 4.
  sanitize(node) // 5.
}

/**
 * If the next sibling identical, merge them together into one.
 */
function possiblyMergeWithNextSibling (node: Element) {
  const next = node.nextSibling as Element
  if (!next) { return }
  if (next.tagName === node.tagName && !isDynamic(node)) {
    while (next.firstChild) { node.appendChild(next.firstChild) }
    next.remove()
  }
}
