
import ViewComponent from 'ViewComponent'

import { buttons, input, color } from 'styles'
import icons from 'icons'
import deleteIcon from 'icons/light/times'

/**
 * Base class for adding items to a list.
 */
export default class ListEditor extends ViewComponent {
  constructor ({ list, inputClass }) {
    super({})
    Object.assign(this, {
      list,
      inputClass,
      newValue: ko.observable('')
    })
  }

  get listHTML () {
    const { jss, list } = this
    return (
      <div class={jss.lines}>
        {this.computed(() =>
          list().length ? list().map(t => this.lineHTML(t))
            : <div class={jss.noItems}>{this.noItemsMessage}</div>)}
      </div>
    )
  }

  get inputHTML () {
    const { jss } = this
    return (
      <div class={jss.newBlock}>
        <input type='text'
          class={jss.input}
          placeholder={this.placeholderMessage}
          ko-textInput={this.newValue}
          ko-keydown={{ Enter: () => this.addEvent() }} />
      </div>
    )
  }

  lineHTML (item) {
    const { jss } = this
    return (
      <div class={jss.line}>
        {this.itemHTML(item)}
        <span class={jss.deleteButton}
          ko-ownClick={() => this.list.remove(item)}>
          {icons.inline(deleteIcon)}
        </span>
      </div>
    )
  }

  get template () {
    const { jss } = this
    return <div class={jss.list}> {this.listHTML} {this.inputHTML} </div>
  }

  static get css () {
    return {
      ...super.css,
      list: {
        backgroundColor: 'white',
        padding: '6px 10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
      },

      lines: {
        padding: 10,
        borderRadius: 3,
        border: '1px solid #4A90E2',
        borderStyle: 'dotted',
        marginTop: 7,
        width: 'min-content',
        backgroundColor: 'rgba(74,144,226, 0.2)',
        minWidth: '250px'
      },

      line: {
        margin: '4px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        '&:hover $deleteButton': {
          visibility: 'visible'
        }
      },
      item: {},
      newBlock: {
        marginTop: '10px'
      },
      input: {
        ...input.text
      },
      noItems: {
        color: 'green',
        fontSize: '0.8em'

      },
      deleteButton: {
        ...buttons.clickable,
        visibility: 'hidden',
        marginLeft: 'auto'
      }
    }
  }

  addEvent () {
    const item = this.newValue()
    if (!this.list().includes(item)) {
      this.list.push(item)
    }
    this.newValue('')
  }
}
