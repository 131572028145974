import { noop } from 'lodash-es'

import ViewComponent from 'ViewComponent'
import EventEmitter from 'EventEmitter'

import 'animate-height'
import eisCss from './eisCss'

import { hidePopovers } from 'pop-over'
import { inline } from 'icons'
import copyIcon from 'icons/solid/copy'
import searchIcon from 'icons/solid/search'

/**
 * A `Field` is a base-class representation of the simplest of
 * input areas.  It is intended to represent all the permutations
 * of UI/UX for a given field, with straightforward options to extend.
 */
export default abstract class EisField extends ViewComponent {
  qtip: any
  entity: import('EntityModel').default
  editing: KnockoutObservable<boolean>
  focused: KnockoutObservable<boolean>
  hover: KnockoutObservable<boolean | null>
  navigation: import('entity-information-summary/Navigation').default
  private _hasBeenEdited: boolean

  constructor ({ eis, qtip, classes, skipNavRegistration }) {
    super()
    Object.assign(this.jss, classes)
    Object.assign(this, {
      qtip,
      entity: eis.entity,
      navigation: eis.navigation,
      editing: ko.observable(false),
      focused: ko.observable(false),
      hover: ko.observable(null),
      _eventEmitter: new EventEmitter(),
      _hasBeenEdited: false,
    })
    this.hover.subscribe(h => eis.fieldHover(h ? this : null))
    this.focused.subscribe(f => {
      if (f) { return }
      this.focused(this.changedSinceEdit)
      this.editing(this.changedSinceEdit)
    })
    this.editing.subscribe(e => !e || (this.focused(true) && this.onStartEdit()))
    this.editing.subscribe(e => e || hidePopovers())
    if (!skipNavRegistration) { this.navigation.append(this) }
    this.editing.yet(false).then(() => this._hasBeenEdited = true)
  }

  /**
   * Generator
   */
  get relatedGenerator () {
    return this.entity
  }

  static get css () {
    return {
      ...super.css,
      ...eisCss
    }
  }

  static get displayPlaceholder () {
    return {
      'clientname': 'Client Name',
      'matter_number': 'Matter Number',
      'client_standing': 'Client Standing',
      'accounting_number': 'Accounting Number',
      'legalname': 'Entity Name',
    }
  }

  onStartEdit() {}

  get isBlank () { return () => false }
  get hasChanges () { return false }

  get changedSinceEdit () {
    return this._hasBeenEdited && this.hasChanges
  }

  onClick () {
    if (this.entity.isProjection()) { return }

    if (this.focused()) {
      this.editing(!this.editing() || this.changedSinceEdit)
    } else {
      const cf = this.navigation.currentFocus()
      this.focused(!cf || !cf.changedSinceEdit)
    }
  }

  get template () {
    const { jss } = this
    const isBlank = this.computed(() => this.isBlank() || undefined)
    const element = ko.observable(null)
    const scrollTo = this.computed(() => {
      if (!this.focused() || !element()) { return null }
      const topOffset = 90
      const box = element().getBoundingClientRect()
      if ((box.top - topOffset) < 0) {
        return { block: 'start', offset: '-90' }
      } else if (box.bottom > window.innerHeight) {
        return { block: 'end', offset: '30' }
      } else {
        return null
      }
    })

    return (
      <div class={jss.fieldContainer}
        blank={isBlank}
        focused={this.computed(() => this.focused() || undefined)}
        ko-ownClick={() => this.onClick()}
        ko-hover={this.hover}
        ko-set-node={element}
        ko-scrollTo={scrollTo}
        eis-field
        read-only={this.entity.isProjection}>
        {this.displayAreaHTML}

        <div class={jss.changeArea}>
          {this.changeAreaHTML}
        </div>

      </div>
    )
  }

  get displayAreaHTML () {
    const { jss } = this
    return (
      <div class={jss.displayArea}>
        {this.titleAreaHTML}
        <div class={jss.contentArea}>{this.displayHTML}</div>
        {this.changeButtonHTML}
        <div class={jss.displayAreaBorder} />
      </div>
    )
  }

  copyValueFor (componentName) {
    return ko.unwrap(this.entity.componentFor(componentName).get())
  }

  get titleAreaHTML () {
    const { jss } = this
    return (
      <div class={jss.titleArea}>
        {this.titleHTML}
        {this.tooltipHTML}
        {this.copyIconHTML(() => this.copyValueFor(this.componentName))}
      </div>
    )
  }

  get editTitleAreaHTML () {
    if (!this.titleAreaHTML) { return "" }
    return (
      <div class={this.jss.titleEdit}>
        {this.titleHTML}
        {this.tooltipHTML}
      </div>
    )
  }

  get tooltipHTML () {
    return this.qtip
      ? <q-tip class={this.jss.qtip} name={this.qtip} /> : undefined
  }

  get changeButtonHTML () {
    const { editing, jss } = this

    const changeText = this.computed(() =>
      editing()
        ? 'Cancel'
        : ( this.isBlank() ? 'Add' : 'Change' ))
    const onClick = evt => {
      evt.stopPropagation()
      if (this.editing()) {
        this.triggerDiscard()
      } else {
        const cf = this.navigation.currentFocus()
        this.editing(this.focused() || !cf || !cf.changedSinceEdit)
      }
    }
    return (
      <div class={jss.changeButton}
        ko-click={evt => onClick(evt)}>
        {changeText}
      </div>
    )
  }

  async triggerSave () {
    this._eventEmitter.emit('activate')
    await this.editing.yet(true)
  }

  triggerDiscard () {
    this.onDiscard()
    this.editing(undefined)
  }

  inputKeyDown (usermap) {
    const keymap = {
      Enter: () => this.triggerSave(),
      Escape: () => this.triggerDiscard(),
      Tab: async evt => {
        await this.triggerSave()
        evt.shiftKey
          ? this.navigation.previousFocus()
          : this.navigation.nextFocus()
      },
      ...usermap
    }
    return (self, evt) => {
      if (keymap[evt.key]) {
        keymap[evt.key](evt)
        evt.stopPropagation()
        evt.preventDefault()
      } else {
        return true
      }
    }
  }

  async asyncSave () {
    this.onSave()
    await this.entity.vmSave()
    this.editing(false)
  }

  get changeAreaHTML () {
    const { jss } = this
    const { hideEdit, showEdit } = this.constructor.css
    const focused = this.computed(() => this.focused() || undefined)
    const editing = this.computed(() => this.editing() || undefined)

    const discard = evt => {
      this.triggerDiscard()
    }

    const styleMap = this.computed(() => editing() ? showEdit : hideEdit)

    const lazyEditHTML = ko.observable()
    this.editing
      .when(true)
      .then(() => lazyEditHTML(this.editHTML))

    return [
      <animate-height styleMap={styleMap}>
        <template slot='content'>
          <div class={jss.fieldEditing} editing={editing} ko-ownClick={noop}>
            {this.editTitleAreaHTML}
            <div class={jss.contentArea}>{lazyEditHTML}</div>
            <div class={jss.discardButton} ko-ownClick={discard}>Cancel</div>
            <div class={jss.saveButtonGridItem}>
              <async-button faceClass={jss.saveButton} eventEmitter={this._eventEmitter} action={() => this.asyncSave()}>
                <template slot='face'>Save</template>
              </async-button>
            </div>
            {this.editGridItems}
          </div>
        </template>
      </animate-height>,
      <div class={jss.peek} focused={focused} editing={editing} />
    ]
  }

  searchIconHTML (component) {
    const pp = window.app.panelProvider()
    const onClick = evt => {
      const value = typeof component == 'function'
        ? component()
        : ko.unwrap(this.entity.componentFor(component).get())
      pp.rightView(pp.commandSet.rightViews.search)
      pp.searchData.immediateQuery(value)
      evt.preventDefault()
      evt.stopPropagation()
    }
    return (
      <span class={this.jss.copyIcon} ko-click={onClick}>
        {inline(searchIcon)}
      </span>
    )
  }

  copyIconHTML (component) {
    if (!window.navigator) {
      /* Not supported by IE or Edge.
         There is an old pita alternative using document.execCommand that I
         hope we don't have to use. */
      if (!window.clipboardWarningShown) {
        console.warn('Clipboard access is not supported in this browser')
        window.clipboardWarningShown = true
      }
      return ""
    }
    const onClick = evt => {
      const value = typeof component == 'function'
        ? component()
        : ko.unwrap(this.entity.componentFor(component).get())
      window.navigator.clipboard.writeText(value)
        .catch(() => console.error('Insufficient permissions to copy to clipboard'))
      evt.preventDefault()
      evt.stopPropagation()
    }
    return (
      <span class={this.jss.copyIcon} ko-click={onClick}>
        {inline(copyIcon)}
      </span>
    )
  }

  abstract get titleHTML ()
  abstract get editHTML ()
  abstract get displayHTML ()
  abstract get indicatorsHTML ()

  onSave () {}
  onDiscard () {}
}
