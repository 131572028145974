import 'widgets'

import EisMultiComponentField from './EisMultiComponentField'
import * as constants from '../constants'

export default class EisGovernance extends EisMultiComponentField {
  get componentNames () {
    return [
      'director_quorum',
      'shareholder_quorum',
      'chair_vote',
      'audit_exemption',
      'contract_authority',
    ]
  }

  static get css () {
    return {
      ...super.css,
      ...this.booleanCSS,
      displayArea: {
        ...super.css.displayArea,
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gap: '10px 20px',
        justifyContent: 'unset'
      },
      displayTitle: {
        display: 'flex',
        alignItems: 'center'
      },
      changeButton: {
        ...super.css.changeButton,
        gridArea: '',
        gridColumn: '2/-1',
        alignSelf: 'flex-end',
      },
      changeButtonFace: {
      }
    }
  }

  get isBlank () { return () => false }

  get displayAreaHTML () {
    const { jss } = this
    return (
      <div class={jss.displayArea}>
        <div class={jss.displayTitle}>
          Director Quorum
        </div>
        <model-property-picker my='top right' at='bottom right'
          indexName='entity'
          value={this.inputs.director_quorum}
          extraChoices={constants.DIRECTOR_QUORUM}
          disableShortcuts={true}
          propertyGetter={e => e.answerFor('director_quorum')}
          />
        <div class={jss.displayTitle}>
          Shareholder Quorum
        </div>
        <model-property-picker my='top right' at='bottom right'
          indexName='entity'
          value={this.inputs.shareholder_quorum}
          extraChoices={constants.SHAREHOLDER_QUORUM}
          disableShortcuts={true}
          propertyGetter={e => e.answerFor('shareholder_quorum')}
          />
        <div class={jss.displayTitle}>
          Chair Vote
        </div>
        {this.booleanChoice('chair_vote')}
        <div class={jss.displayTitle}>
          Audit Exemption
        </div>
        {this.booleanChoice('audit_exemption')}
        <div class={jss.displayTitle}>
          Contract Authority
        </div>
        <model-property-picker my='top right' at='bottom right'
          indexName='entity'
          value={this.inputs.contract_authority}
          extraChoices={constants.CONTRACT_AUTHORITY}
          propertyGetter={e => e.answerFor('contract_authority')}
          />

        {this.changeButtonHTML}
      </div>
    )
  }

  static get booleanCSS () {
    return {
      boolean: {
        marginLeft: 'auto',
      }
    }
  }

  booleanChoice (property: string) {
    return (
      <yes-no class={this.jss.boolean}
        value={this.inputs[property]} />
    )
  }

  get changeButtonHTML () {
    const { jss } = this
    return this.computed(() => this.hasChanges
      ? (
        <async-button
          class={jss.changeButton}
          faceClass={jss.changeButtonFace}
          action={() => this.asyncSave()}
          face='Save'>
        </async-button>
      ) : null)
  }
  get changeAreaHTML () { return null }
}

EisGovernance.register()
