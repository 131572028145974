
import ViewComponent from 'ViewComponent'

import { buttons, color } from 'styles'
import icons from 'icons'
import bookmarkIcon from 'icons/solid/bookmark'
import noteIcon from 'icons/solid/pen-square'
import sectionIcon from 'icons/solid/list-ul'
import { DisposedError } from 'pdf-engine/PDFEngine'

/**
 * Base class for the PdfViewer (<pdf-thumbnails> & <pdf-pages>),
 * and the context view (<pdf-context>)
 */
export default abstract class PdfRenderer extends ViewComponent {
  thumbCache: import('utils/LocalImageCache').LocalImageCache
  pdfEngine: import('pdf-engine').PDFEngine
  currentPage: KnockoutObservable<number>
  book: BookComponent
  bookPages: KnockoutObservableArray<BookPage>

  constructor ({ pdfSettings, bookPages }) {
    super()

    Object.assign(this, {
      ...pdfSettings.vars,
      bookPages: bookPages || pdfSettings.vars.bookPages,
      truckBounds: ko.observable(),
      truckElement: ko.observable()
    })

    this.truckElement.subscribe(truck => this.waitForTruckHeight(truck))

    this.computed(() => {
      ko.unwrap(this.rightPanelChange)
      ko.unwrap(this.leftPanelChange)
      ko.unwrap(this.panelProvider.listOfNotes.list)
      const truck = this.truckElement(); if (!truck) return
      this.truckBounds(truck.getBoundingClientRect())
    })

    this.panelProvider.showToC.subscribe(async () => {
      await Promise.delay(260)
      const truck = this.truckElement(); if (!truck) return
      this.truckBounds(truck.getBoundingClientRect())
    })
  }

  /**
   * Use this to cache to the thumbnails
   */
  async loadOrRenderThumb (page: BookPage, width = 400, priority = false) {
    return this.thumbCache.getOrAdd(page.pageID, async () => {
      try {
        return await this.pdfEngine.renderAsURL(page, width, priority)
      } catch (e) {
        if (!(e instanceof DisposedError)) { throw e }
        return undefined
      }
    })
  }

  /**
   * @param {HTMLElement} element
   */
  async waitForTruckHeight (element) {
    const bounds = element.getBoundingClientRect()
    if (bounds.height) {
      this.truckBounds(bounds)
      return
    }
    window.requestAnimationFrame(() => this.waitForTruckHeight(element))
  }

  /**
   * @param {BookPage} page
   * @param {int} index
   */
  abstract pageHTML (page: BookPage, index: number) : JSX

  private pageCache: Record<string, JSX> = {}
  get pageListHTML () {
    return this.bookPages.map(bp => ko.ignoreDependencies(() =>
      this.pageCache[bp.pageID] || (this.pageCache[bp.pageID] = this.pageHTML(bp, this.book.getIndexOfpageID(bp.pageID)))
    )).extend({ deferred: true })
  }

  get pagesHTML () {
    return (
      <div class={this.jss.pages}
        ko-grip-area={this.onPageMove}>
        {this.pageListHTML}
        {this.computed(() =>
          this.bookPages.length ? undefined : this.noPagesHTML)}
      </div>
    )
  }

  get noPagesHTML () {}
  get rootHTML () {}

  get template () {
    const { jss } = this
    return (<>
      {this.rootHTML}
      <div class={jss.truck}
        ko-style-map={this.truckVars}
        ko-set-node={this.truckElement}
        ko-resize-observer={this.truckBounds}>
        {this.pagesHTML}
      </div>
    </>)
  }

  /**
   * Overload with styles that apply to the top-level truck.
   * @return {object}
   */
  get truckVars () {}

  /**
   * In thumbnail view, this will respect moves to pages and update the
   * BookComponent
   */
  onPageMove (/* { fromIndex, toIndex } */) { }

  /**
   * @param {BookPage} bookPage
   */
  bookmarkHTML (bookPage) {
    const { jss } = this
    return <span class={jss.bookmark} exists={bookPage.bookmarked}
      ko-click={() => this.bookmarkClick(bookPage)}>
      {icons.inline(bookmarkIcon)}
    </span>
  }

  /**
   * @param {BookPage} bookPage
   */
  noteHTML (bookPage) {
    const { jss } = this
    return (
      <span class={jss.note} exists={bookPage.hasNotes}
        ko-click={() => this.noteClick(bookPage)}>
        {icons.inline(noteIcon)}
      </span>
    )
  }

  sectionHTML (bookPage) {
    const { jss } = this
    return (
      <div class={jss.section} exists={bookPage.startsSection}
        ko-click={() => this.sectionClick(bookPage)}>
        {icons.inline(sectionIcon)}
      </div>
    )
  }

  noteClick (bookPage) {}
  bookmarkClick (bookPage) {}
  sectionClick (bookPage) {}

  static get iconCSS () {
    return {
      // 📒 ✏️ 📝📕
      _marker: {
        ...buttons.clickable,
        '--icon-color': `${color.gray.c}`,
        position: 'absolute',
        zIndex: 1,
        top: '0px',
        visibility: 'hidden',
        '&[exists]': {
          visibility: 'visible',
          '--icon-color': 'var(--active-color)'
        }
      },

      note: {
        extend: '_marker',
        '--active-color': 'hsla(60, 100%, 45%, 1)',
        right: '5px'
      },

      bookmark: {
        extend: '_marker',
        '--active-color': 'rgb(215, 90, 74)',
        right: '1.55em',
      },

      section: {
        extend: '_marker',
        '--active-color': 'rgb(74, 144, 226)',
        right: '2.75em',
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.iconCSS,
      truck: { /* Overload */},
      pages: { /* Overload */},

      gripCloneStyle: {
        opacity: '0.8'
      },

      canvasContainer: {
        position: 'relative',
        // for grips
        transitionDuration: '0.100s',
        '&[gripping]': {
          opacity: 0
        }
      },
    }
  }
}
