/**
 * Capital variables cover the gamut of capital elements, notably the
 * assets (`Asset`) and holders (`Stakeholder`) of those assets.
 *
 * Capital is entirely constructed from a series of transactions, and the
 * `CapitalState` is a point-in-time (i.e. as-of a transaction) representation
 * of the capital of the company.
 *
 * The variables pulled are therefore entirely synthetic as there may at any
 * time not be any assets or holders, given properties may exist only at
 * certain times, and various other ephemeral events.
 *
 * Notes — as-of 26 Nov 2019/BH
 *
 * Capital variables are therefore more complex than the other types.
 *
 * Here are some use-cases:
 *
 * 1. capital has multiple constructed, repeating properties that interrelate,
 *    so for example one could achieve the exact same list with:
 *
 *    ∀ assets
 *      ∀ holders
 *        holder name + asset name + asset amount
 *
 *    ∀ holders
 *      ∀ assets
 *        holder name + asset name + asset amount
 *
 * 2. The filters for lists are rather generic, e.g.
 *  all "Common" shareholders
 *  all shareholders with/out a "voting" or other property
 *  all assets with/out a "voting" or other property
 *
 * 3. Most of the information we want to get is iterated upon e.g. holder
 *  assets
 *
 * 4. A user may choose a specific asset or stakeholder and obtain properties
 *   * Those properties may not be UX-ready e.g. boolean asset properties
 *   * The properties may be iterable e.g. assets/holding dates
 *   * The assets may require intermediary computation e.g. asset names for
 *     a shareholder.
 *
 * 5. Holders may be selected as by entity-id+name or id; it's not clear
 *  if they have the PersonRecord available.
 */
import CapitalComponent from 'capital/CapitalComponent'
import { toLocaleDecimal } from 'utils/number'
import t from 't'

import { IterableComponentVariable } from './IterableComponentVariable'

export abstract class CapitalVariable extends IterableComponentVariable<CapitalComponent> {
  assetClassID = ko.observable<string>()
  get slotGroupCode () { return super.slotGroupCode + ':' + this.code }
  get asset () { return this.capitalState.getAsset(this.assetClassID()) }
  get capitalState () { return super.componentValue.state() }
  get hasSlots () { return true }
  abstract get capitalAssetVariableName (): string
  abstract get capitalAssetVariableType (): string
  get incohateText () {
    return [
      super.incohateText,
      this.capitalAssetVariableType,
      this.capitalAssetVariableName,
    ].join(' ')
  }

  variablePropertyText (p: string) {
    return this.constructor.VARIABLE_PROPERTIES
      .find(({ property }) => property === p)
      ?.text
  }
}

export class CapitalAssetVariable extends CapitalVariable {
  assetPropertyKey = ko.observable<string>()
  dateFormat = ko.observable<string>(null)

  get code () { return 'ccav' }
  get capitalAssetVariableType () { return 'asset' }
  get iterationChosen () { return this.assetClassID() === '*' }
  get isValidIndex () { return this.capitalState.hasAsset(this.assetClassID()) }
  get slotIterTitleText () { return this.asset.assetName }
  propagate (to: CapitalAssetVariable) { to.assetClassID(this.assetClassID()) }
  chosenValue () { return this.assetPropertyValue() }
  get capitalAssetVariableName () { return this.variablePropertyText(this.assetPropertyKey()) }
  get iterationIndex () { return this.assetPropertyKey }
  * iterationKeys () { yield * Object.keys(this.capitalState.authorized) }
  get isDateValue () { return this.assetPropertyKey() === '-dateAuthorized' }

  get leadTransforms () {
    if (!this.isCohate || !this.isDateValue) { return super.leadTransforms }
    return [
      { type: 'dateFormat', parameters: { format: this.dateFormat() } },
      ...super.leadTransforms,
    ]
  }

  * iterations () {
    const assets = Object.values(this.capitalState.authorized)
    yield * assets.map(a => this.assetPropertyValue(a))
  }

  get isCohate () {
    return Boolean(super.isCohate
      && this.assetClassID()
      && this.assetPropertyKey()
      && this.assetPropertyValue)
  }

  assetPropertyValue (asset = this.asset) {
    const key = this.assetPropertyKey()

    if (!asset || typeof key !== 'string') { return null }

    switch (key) {
      case '-dateAuthorized': return asset.authorizedBy.datetime()
      default: return asset.getProperty(key)
    }
  }

  static get VARIABLE_PROPERTIES () {
    return [
      { text: t.CAPITAL_SHARE_CLASSIFICATION, property: 'classification' },
      { text: t.CAPITAL_QUANTUM_AUTHORIZED, property: 'quantumAuthorized' },
      { text: t.CAPITAL_SERIES, property: 'series' },
      { text: t.CAPITAL_PREFIX, property: 'prefix' },
      { text: t.CAPITAL_DATE_AUTHORIZED, property: '-dateAuthorized' },
    ]
  }
}


export class CapitalHolderVariable extends CapitalVariable {
  /**
   * Identifier of the stakeholder.
   */
  holderProperty = ko.observable<'-classification'|'-held'|'name'>()
  idOrName = ko.observable<string>()

  get code () { return 'ccsv' }
  get capitalAssetVariableType () { return 'holder' }
  get iterationChosen () { return this.idOrName() === '*' }
  get isValidIndex () { return Boolean(this.holder) }
  get holder () { return this.capitalState.getStakeholder(this.idOrName()) }
  get slotIterTitleText () { return this.holder.idOrName }
  get iterationIndex () { return this.idOrName }
  * iterationKeys () { yield * Object.keys(this.capitalState.stakeholders) }
  get capitalAssetVariableName () { return this.variablePropertyText(this.holderProperty()) }

  chosenValue () { return this.holderPropertyValue }
  propagate (to: CapitalAssetVariable) { to.idOrName(this.idOrName()) }
  * iterations () {
    const holders = Object.values(this.capitalState.stakeholders)
    yield * holders.map(h => h.idOrName)
  }

  get isCohate () {
    return super.isCohate
      && this.holderProperty()
      && (!this.needsAsset || this.assetClassID())
      && true
  }

  get needsAsset () {
    return ['-held', '-classification'].includes(this.holderProperty())
  }

  get holderPropertyValue () {
    const { holder } = this
    const key = this.holderProperty()
    if (!holder || typeof key !== 'string') { return null }

    if (key === '-held') {
      const amount = this.assetClassID()
        && holder.getAsset(this.assetClassID())
        || new Big(0)
      return toLocaleDecimal(amount)
    }

    if (key === '-classification') { return this.asset.assetName }

    return holder[key]
  }

  static get VARIABLE_PROPERTIES () {
    return [
      { text: t.CAPITAL_HOLDER_NAME, property: 'name' },
      { text: t.CAPITAL_SHARES_HELD, property: '-held' },
      { text: t.CAPITAL_SHARE_CLASSIFICATION, property: '-classification' },
    ]
  }
}
