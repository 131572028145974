
import { addMonths } from 'date-fns/esm'

import Command from 'Command'
import RootListCommandSet from './RootListCommandSet'

export default class CalendarCommandSet extends RootListCommandSet {
  makeCommands () {
    const { panelProvider } = this
    const arrowAction = evt => {
      if (evt.target instanceof HTMLInputElement) { return }
      const sign = evt.key === 'ArrowRight' ? 1 : -1

      const newDate = addMonths(panelProvider.targetDate(), sign)
      panelProvider.targetDate(newDate)
    }

    return {
      ...super.makeCommands(),
      nextMonth: new Command({
        title: 'Next month',
        action: arrowAction
      }),
      lastMonth: new Command({
        title: 'Previous month',
        action: arrowAction
      })
    }
  }

  get keyboardShortcuts () {
    const c = this.commands
    return [
      ...super.keyboardShortcuts,

      ...c.lastMonth.keyboardShortcuts('ArrowLeft'),
      ...c.nextMonth.keyboardShortcuts('ArrowRight')
    ]
  }
}
