import dateIcon from 'icons/light/calendar'
import textIcon from 'icons/light/text-width'

import { InjectVariableCommand } from "../commands/InjectVariableCommand"

import DateVariable from './DateVariable'
import TextVariable from './TextVariable'
import TimeVariable from './TimeVariable'

type SlotManager = import('./slots').SlotManager

export function staticVariables () {
  return [
    new InjectVariableCommand('Date', dateIcon, (sm: SlotManager) => new DateVariable(sm), `date`),
    new InjectVariableCommand('Text', textIcon, (sm: SlotManager) => new TextVariable(sm), `text`),
    new InjectVariableCommand('Time', textIcon, (sm: SlotManager) => new TimeVariable(sm), `time`),
  ]
}
