
import TransactionEditor from './TransactionEditor'

import { TransactionIndividualConversion } from 'capital/Transaction'
import { Asset } from 'capital/Asset'

import { toLocaleDecimal } from 'utils/number'

import { color } from 'styles'

/**
 * A Share Issuance consists of:
 *    - ShareCredit (S.+)
 *      - person
 *      - amount
 *      - assetClassID
 *
 *   Optional
 *   - ShareTransferNumber (S.#)
 *      - number
 *      - assetClassID
 */
class CapitalShareIndividualConversionEditor extends TransactionEditor<TransactionIndividualConversion> {
  assetChoices: KnockoutComputed<Asset>

  createTransaction ({ assetClassID }) {
    return new TransactionIndividualConversion({
      assetClassID, datetime: null, title: 'Convert',
    })
  }

  get questionHTML () {
    const { jss } = this
    const args = this.clone
    this.clone.targetAssetClassID.modify(a => a || '')
    return (
      <div class={jss.transfer}>
        {this._datePicker('Date', args.datetime)}

        {/* start transferor meta section */}
        {this._horizRule()}
        {this._personPicker('Transferor Name', args.transferor)}
        {this._title('Available Shares')}
        <div class={jss.availableAmount}>
          {this.computed(() => this.availableAmount())}
        </div>
        {this._numberInput('Amount', args.amount)}

        {/* start conversion details */}
        {this._horizRule()}
        {this._nextTransferNumber('Transfer Number', args.trNumber)}
        {this._assetPicker('Convert to Class', args.targetAssetClassID, args.datetime)}
      </div>
    )
  }

  availableAmount () {
    const { jss } = this
    const { transferor } = this.clone
    const { stakeholders } = this.stateBefore()
    const holder = stakeholders[transferor()]
    if (!holder) {
      return <span class={jss.notFound}>N/A</span>
    }
    return toLocaleDecimal(holder.getAsset(this.assetClassID))
  }

  isValidTransaction (args) {
    const { transferor, targetAssetClassID, amount } = ko.toJS(args)
    return transferor && targetAssetClassID && amount
  }

  static get css () {
    return {
      ...super.css,
      layout: {
        ...super.css.layout,
        gridTemplateColumns: 'auto',
        minWidth: 450,
      },
      transfer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridTemplateAreas: `
        'date . .'
        'transferFrom . transferTo'
        'transferNumber . .'
        `,
        gap: '10px 15px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        borderRadius: 5,
        padding: 10,
      },
      input: {
        ...super.css.input,
        background: color.textInput.light.primary,
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          background: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },
      }
    }
  }
}

CapitalShareIndividualConversionEditor.register()
