import ViewComponent from 'ViewComponent'
import { flagUrlOf, nameOf } from './lookup'

export default class JurisdictionFlag extends ViewComponent {
  value: KnockoutObservable<string>

  constructor ({ value }) {
    super()
    Object.assign(this, { value })
  }

  get template () {
    const { jss, value } = this


    return this.computed(() => {
      const v = ko.unwrap(value)
      const name = nameOf(v)
      const flagUrl = `url("${flagUrlOf(v) || ''}")`
      return (
        <div class={jss.flag}
          title={name}
          ko-style={{ backgroundImage: flagUrl }} />
      )
    }).extend({ deferred: true })
  }

  static get css () {
    return {
      ...super.css,
      flag: {
        width: '30px',
        height: '16px',
        backgroundSize: 'contain',
        margin: 4,
        backgroundRepeat: 'no-repeat',
        '[jurisdiction=""] &': {
          display: 'none'
        },
      },
    }
  }
}

JurisdictionFlag.register()
