
import ArrayComponent from './ArrayComponent'
import UnitView from './UnitView'

export class Import extends UnitView {
  get fields () {
    return {
      origin: 'string', // e.g. FastCo, CyberBahn, Enact, etc.
      filename: 'string',
      filehash: 'string',
      importedBy: 'string', // person email
      datetime: 'datetime',
    }
  }
}

export default class ImportsComponent extends ArrayComponent {
  get ItemClass () { return Import }
}

ImportsComponent.register()
