
import './first-user-account-login-modal'
import './first-user-share-login-modal'
import './things-have-changed-modal'
import './set-account-name-modal'
import { updateUserBuildNumber, buildNumber } from './sessions'

/**
 * @return {boolean} true when the app build number has incremented
 * since the users' last session.
 */
export async function thingsChanged (authManager) {
  const currentBuild = buildNumber()
  const { lastSessionBuildNumber } = authManager
  return !lastSessionBuildNumber || lastSessionBuildNumber < currentBuild
}

/**
 * Check if the user knows about any updates since their last session.
 */
export async function showAppUpdatesSinceLastSession (app, uidOrForce) {
  const currentBuild = buildNumber()
  console.info(`
    ℹ️  There have been changes since the user last logged in.
        Current build number: ${currentBuild}.
  `)
  app.modal(
    <things-have-changed-modal
      app={app}
      userID={uidOrForce}
      currentBuild={currentBuild} />
  )
}

/**
 * Detect whether we need to show the user an update-modal.
 * For example:
 *
 * 1. It's the first time a user with an account has logged in
 * 2. It's the first time a user receiving a share has logged in
 * 3. The app has been updated since the user last logged in.
 *
 */
export async function possiblyShowModalOnLogin (app, userID) {
  const authManager = app.defaultAuthManager as AuthManager
  await authManager.latestTermsOfServiceAgreed.when(true)
  if (authManager.isFirstSession) {
    showAppUpdatesSinceLastSession(app, userID)
    //
    // 🔧 FIXME: Add authManager."userIsSharee", which is
    //      distinct from userIsHomeless.  A user is homeless
    //      when first added to the system; a user is a sharee
    //      when there is no /account/X/user/Y entity.
    //
    if (authManager.userIsHomeless) {
      app.modal(<first-user-share-login-modal />)
    }
    // app.modal(authManager.userIsHomeless
    //   ? <first-user-account-login-modal />
    //   : <first-user-share-login-modal />)
    updateUserBuildNumber(app, userID)
  } else if (await thingsChanged(authManager)) {
    showAppUpdatesSinceLastSession(app, userID)
  }
  if (authManager.accountID()) {
    const account = await authManager.getAccountModel()
    if (!account.firmname()) {
      console.info(`ℹ️  Account name is not set. Loading set-account-name-modal.`)
      app.modal(
        <set-account-name-modal app={app} account={account}/>
      )
    }
  }
}
