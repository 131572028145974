

import { tag, dropdown, color } from 'styles'
import icons from 'icons'
import moreIcon from 'icons/light/ellipsis-h'
import 'sharing-editor'

import ConfirmModelDelete from 'confirm-delete/confirm-model-delete'

import { Column, EntityTagsColumn, UserTagsColumn } from './Column'

// Hide the dropdown menu
function hideDropdownMenu () {
  document.body.click()
}

function actionsHTML (model, jss, app, content) {
  return (
    <drop-down my='top right' at='bottom right'>
      <template slot='anchor'>
        <div class={jss.actionsButton} actions-button=''>
          {icons.inline(moreIcon)}
        </div>
      </template>
      <template slot='content'>
        { content }
      </template>
    </drop-down>
  )
}

function shareClick (evt, model) {
  evt.stopPropagation()
  evt.preventDefault()
  hideDropdownMenu()
  openAccessModal(model)
}

function deleteClick (evt, model) {
  ConfirmModelDelete.open(model)
}

function openAccessModal (model) {
  window.app.modal(
    <modal-dialog modalTitle='Sharing'>
      <template slot='content'>
        <sharing-editor model={model} />
      </template>
    </modal-dialog>,
  )
}

async function showMinuteBook (evt, model, app, activeViewID) {
  const mbi = await import('MinuteBookPanelProvider')
  const MinuteBookPanelProvider = mbi.default
  evt.stopPropagation()
  evt.preventDefault()
  app.panelProvider(
    new MinuteBookPanelProvider({ app, model, activeViewID }),
  )
}

async function editMinuteBook (evt, entity) {
  evt.stopPropagation()
  evt.preventDefault()
  hideDropdownMenu()
  const valuesBeforeEditing = await entity.vmCurrentReprToSave()
  window.app.modal(
    <modal-dialog modalTitle='Edit Entity'
      onDismissal={() => entity.vmAssignValues(valuesBeforeEditing)}>
      <template slot='content'>
        <entity-editor entity={entity}
          okText='Save Changes'
          onFinish={() => global.app.modal(undefined)} />
      </template>
    </modal-dialog>,
  )
}

async function cloneClick (evt, model) {
  const newModel = await model.clone()
  return editMinuteBook(evt, newModel)
}

function entityActionsHTML (model, jss, app) {
  return (
    <div class={jss.actionsMenu} ko-click={evt => evt.stopPropagation()}>
      <div class={jss.actionItem} ko-click={evt => showMinuteBook(evt, model, app)}>
        View minute book
      </div>
      { ko.unwrap(model.isProjection) ? '' :
        <>
          <div class={jss.actionItem} ko-click={evt => shareClick(evt, model)}>
            Share minute book...
          </div>
          <div class={jss.actionItem}
            ko-click={evt => showMinuteBook(evt, model, app, 'AuditView')}>
            View audit trail
          </div>
          <div class={jss.actionItem} ko-click={evt => editMinuteBook(evt, model, app)}>
            Edit...
          </div>
          <div class={jss.actionItem} ko-click={evt => cloneClick(evt, model)}>
            Duplicate...
          </div>
          <div class={jss.actionItem} ko-ownClick={evt => deleteClick(evt, model)}>
            Delete...
          </div>
        </>
      }
    </div>
  )
}

function actionColumn (panelProvider) {
  if (!panelProvider) { return [] }
  const { jss, app } = panelProvider
  const content = (row) => panelProvider.actionsContentHTML
    ? panelProvider.actionsContentHTML(row)
    : entityActionsHTML(row, jss, app)
  return [
    Column.create('ui', '', 'ui', row => actionsHTML(row, jss, app, content(row))),
  ]
}

export function makeColumns (panelProvider, tagsColumnID) {
  const tagsColumn = tagsColumnID === 'tags'
    ? new EntityTagsColumn(tagsColumnID, panelProvider)
    : new UserTagsColumn(tagsColumnID, panelProvider)
  return [
    tagsColumn,
    Column.create('timestamp', 'Modified', 'modified'),
    Column.create('timestamp', 'Created', 'created'),
    ...actionColumn(panelProvider),
  ]
}

export const css = {
  actionsButton: {
    ...dropdown.clickable,
    border: `1px solid ${color.separator.light.nonOpaque}`,
    'body[dark] &': { // project batman
      border: `1px solid ${color.separator.dark.nonOpaque}`,
    },
    marginRight: '16px',
    borderRadius: '4px',
    '--icon-color': '#aaa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //padding: '3px 0px',
    fontSize: '1.4rem',
    '&:hover': {
      borderColor: color.text.light.primary,
      '--icon-color': color.text.light.primary,
      'body[dark] &': { // project batman
        borderColor: color.text.dark.primary,
        '--icon-color': color.text.dark.primary,
      },
    },
    '& svg': {
      width: '60%',
    },
  },

  actionsMenu: {
    ...dropdown.generalMenu,
    whiteSpace: 'nowrap',
  },

  actionItem: {
    ...dropdown.item,
  },

  tag,
}

export default { css, makeColumns, hideDropdownMenu, entityActionsHTML }
