
import { color, buttons } from 'styles'

import ModelOptionsEditor from './ModelOptionsEditor'

import Sorter from './Sorter'

export default class ModelSorters extends ModelOptionsEditor {
  newModifierClass () { return new Sorter() }
  get noModifiersText () { return 'No sorting is applied.' }
  get addText () { return 'Add a new sort' }
  modifierLeadHTML () { return 'Sort by' }
  appliesToCol (column) { return column.sortable }

  constructor ({ sorters, columns }, ...args) {
    super({ modifierArray: sorters, columns }, ...args)
  }

  orderClick (evt, modifier) {
    evt.preventDefault()
    evt.stopPropagation()
    modifier.isDesc.modify(v => !v)
  }

  modifierParamHTML (modifier) {
    const { jss } = this
    return [
      <div class={jss.by}> By </div>,

      <div class={jss.sortOrder} isdesc={modifier.isDesc}
        ko-click={evt => this.orderClick(evt, modifier)}>
        <div class={jss.sortOrderItem} asc=''> A → Z </div>
        <div class={jss.sortOrderItem} desc=''> Z → A </div>
      </div>
    ]
  }

  static get sortOrderItemCSS () {
    return {
      sortOrder: {
        display: 'flex',
        padding: '4px 8px',
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
        borderRadius: '6px',
        '&[isdesc="false"] [asc]': {
          backgroundColor: color.fill.light.primary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.primary,
          },
        },
        '&[isdesc="true"] [desc]': {
          backgroundColor: color.fill.light.secondary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary,
          },
        }
      },
      sortOrderItem: {
        ...buttons.clickable,
        padding: '4px 16px',
        margin: '0px 4px',
        borderRadius: '6px'
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.sortOrderItemCSS,
      modifier: {
        ...super.css.modifier,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': {
          backgroundColor: color.systemBackground.dark.secondary,
        }
      }
    }
  }
}

ModelSorters.register()
