
import { GoverningDocument } from 'EntityModel/components'

import { GOVERNING_DOCUMENT_TITLES } from '../constants'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisGoverningDocuments extends EisArrayComponentTable<GoverningDocument> {
  get caption () { return 'Governing Documents' }
  get componentName () { return 'governing_documents' }
  get listAddName () { return 'Document' }
  get gridTemplateColumns () { return `3fr 1fr 1fr auto` }
  get columns () { return [this.titleColumn, 'start', 'end'] }
  get titleColumn () {
    return {
      property: 'title',
      edit: clone => (
        <>
          {this._simpleTitle(null, 'title')}
          <governing-document-picker
            inputClass={this.jss.input}
            my='top left'
            at='bottom left'
            value={clone.title}
            resetQueryOnSelect={false}
            extraChoices={GOVERNING_DOCUMENT_TITLES}
            navigation={this.tableNavigation}
          />
        </>
      ),
    }
  }
}

EisGoverningDocuments.register()
