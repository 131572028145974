
/**
 * These settings are to pass common options to <pdf-pages>, <pdf-thumbnails>,
 * and <pdf-context>
 */
export default class PdfSettings {
  constructor (panelProvider, zoom, scrollElement) {
    Object.assign(this, {
      panelProvider,
      zoom,
      scrollElement
    })
  }

  get vars () {
    const book = this.panelProvider.bookComponent
    const { zoom, scrollElement } = this
    const {
      currentPage, panelDragOverEvent, rightPanelChange,
      leftPanelChange, bookPages, searchData, activeViewID, editing,
      pdfEngine, thumbCache,
    } = this.panelProvider
    return {
      book,
      zoom,
      rightPanelChange,
      leftPanelChange,
      bookPages,
      activeViewID,
      thumbCache,
      dragging: panelDragOverEvent,
      sections: book.sections,
      currentPage,
      searchData,
      editing,
      pdfEngine,
      scrollElement,
      panelProvider: this.panelProvider,
      bookmarkAt: bookPage => book.getBookmarks(bookPage).length > 0,
      toggleBookmark: bookPage => book.toggleBookmark(bookPage),
      onDrop: (index, evt) => this.panelProvider.onDropIntoPdfViewer(index, evt),
      addSection: bookPage => book.addOrUpdateSectionFor(bookPage),
      addNoteClick: bookPage => this.panelProvider.addNoteClick(bookPage)
    }
  }
}
