import MemorySearchCriteria from './MemorySearchCriteria'
import { mergeBy } from 'person/utils'
import { groupBy } from 'lodash-es'

type MemoryDB = import('MemoryDB').default

export default class PersonSearchCriteria extends MemorySearchCriteria {
  modelList : KnockoutObservableArray<PersonRecord> = this.modelList

  makeModelList (memoryDB: MemoryDB, indexName) {
    const records = ko.observableArray([])
    const entityList = memoryDB.getListOf('entity')
    const userList = memoryDB.getListOf('user')
    ko.computed(() => {
      const persons = [...entityList(), ...userList()]
        .flatMap(e => ([...e.getPersons()]))
      const hasID = groupBy(persons, p => Boolean(p.id))
      records([
        ...mergeBy(hasID[true]||[], 'id'),
        ...hasID[false]||[],
      ])
    })
    return records
  }
}
