
import PopOver from 'pop-over'

/**
 * Create a drop-down menu
 *
 * Usage:
 *
 *   <drop-down my='top left' at='bottom right'>
 *      <template slot='anchor'>...</template>
 *      <template slot='content'>
 *        ... menu-items ...
 *      </template>
 *   </drop-down>
 *
 */
export default class DropDown extends PopOver {
  static get css () {
    return {
      ...super.css,
      transitionBlock: {
        opacity: 0,
        transform: 'scale3d(1, 0, 1)',
        transition: '0.2s opacity, 0.6s transform',
        transformOrigin: 'top',

        '[popover-open] > &': {
          opacity: 1,
          transform: 'scale3d(1, 1, 1)'
        }
      }
    }
  }

  getParentDropdowns (node) {
    const sentinels = new Set()
    while (node) {
      const possiblePopover = ko.dataFor(node)
      if (possiblePopover instanceof PopOver) {
        sentinels.add(possiblePopover)
      }
      node = node.parentNode
    }
    return sentinels
  }

  /**
   * Trigger this popover on click.
   *
   * Edge cases to watch out for:
   * 1. Clicking inside the drop-down
   * 2. Clicking inside nested drop-downs
   * 3. Clicking the open-button inside a drop-down
   */
  get anchorEvents () {
    return {
      click: (_, evt) => {
        const { target } = evt
        const sentinels = this.getParentDropdowns(evt.target)
        const nearest = target.closest(
          '[popover-content-node],[popover-anchor-node]')
        if (nearest && nearest.hasAttribute('popover-content-node')) { return }
        evt.stopPropagation()
        evt.preventDefault()
        this.showing.modify(v => !v)
        this.hidePopovers(sentinels)
      }
    }
  }
}

DropDown.register()
