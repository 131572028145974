
import ViewComponent from 'ViewComponent'
import { color, typography, buttons, dropdown } from 'styles'
import { ButtonDropDownItem } from 'views/button-drop-down'
import 'select-section'

import { inline } from 'icons'
import chevronDown from 'icons/light/chevron-down'
import cogIcon from 'icons/solid/circle-notch'
import helpIcon from 'icons/icon-help'
import redoIcon from 'icons/regular/redo'
import undoIcon from 'icons/regular/undo'

type UndoManager = import('PanelProvider').UndoManager
type PanelProvider = import('PanelProvider').default

export default abstract class PanelHead<T extends PanelProvider> extends ViewComponent {
  public panelProvider: T

  constructor ({ panelProvider }) {
    super()
    Object.assign(this, { panelProvider })
  }

  button (title, icon, action, kocss) {
    const { jss } = this
    return (
      <div class={jss.button} ko-click={action} ko-css={kocss}>
        <div class={jss.buttonIcon}>{icon}</div>
        <div class={jss.buttonTitle}>{title}</div>
      </div>
    )
  }

  commandButton (title, icon, commandName) {
    const { jss } = this
    const { commands } = this.panelProvider.commandSet
    const command = commands[commandName]

    const anchor = (
      <button-over-text class={jss.button} icon={icon} text={title}
        action={evt => command.click(evt)} />
    )

    return (
      <command-area command={command} name={commandName}
        ko-visible={this.computed(() => !command.isHidden)}
        anchor={anchor} />
    )
  }

  static get buttonCSS () {
    return {
      ...this.undoCSS,
      button: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 0.5vw',
        textAlign: 'center'
      },

      buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
          justifyContent: 'flex-end'
        }
      },

      buttonIcon: {
        ...buttons.clickable,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '37px',
        height: '22px',
        borderRadius: '4px',
        border: `1px solid ${color.fill.light.primary}`,
        backgroundColor: color.fill.light.primary,


        '--icon-color': color.gray.a,
        'body[dark] &': { // project batman
          '--icon-color': color.dark.toolBarIcon,
          backgroundColor: color.fill.dark.primary,
          border: `1px solid ${color.fill.dark.primary}`,
        },
        '& > :nth-child(2)': {
          width: '12px',
          paddingLeft: '4px'
        }

      },

      insetButton: {
        //fontWeight: 'bold',
        '& $buttonIcon': {
          backgroundColor: color.grey.a,
          border: `1px solid ${color.gray.a}`
        },
        '& svg': {
          fill: '#fff'
        }
      },

      buttonTitle: {
        marginTop: '6px',
        visibility: 'visible',
        opacity: '1',
        transition: 'visibility 150ms, opacity 150ms ease-in-out',
        '@media (max-width: 760px)': {
          visibility: 'hidden',
          opacity: '0'
        }
      },

      buttonDropdownMenu: {
        ...dropdown.generalMenu,
        whiteSpace: 'nowrap',
        fontSize: '15px',
        marginTop: '3px'
      },

      buttonDropdownMenuItem: {
        ...dropdown.item,
      },
    }
  }

  viewToggleItem (text, icon, variable): ButtonDropDownItem {
    const toggle = () => requestAnimationFrame(() => variable.modify(v => !v))
    return {
      text: this.computed<string>(() => `${variable() ? `Hide` : 'Show'} ${text}`),
      icon, action: () => toggle(),
    }
  }

  abstract get viewHTML ()

  static get viewCSS () {
    return {
      viewItem: {
        ...dropdown.item,
        display: 'flex',
        alignItems: 'center',

        '& svg': {
          marginLeft: '-10px',
          marginRight: '10px',
          height: '1.9em',
          boxSizing: 'border-box',
          borderRadius: 9,
        },
      },

      viewMenu: {
        ...dropdown.generalMenu,
        marginTop: '3px'
      }
    }
  }

  get undoHTML () {
    const { jss } = this
    const undoManager: UndoManager = ko.unwrap(this.panelProvider.undoManager)
    const undoCss = this.computed(() => undoManager.undoAvailable  ? jss.undoAvailable : jss.undoUnavailable)
    const redoCss = this.computed(() => undoManager.redoAvailable ? jss.undoAvailable : jss.undoUnavailable)
    return (
      <drop-down my='top left' at='bottom left' class={jss.button}>
        <template slot='anchor'>
          <div class={jss.undoIcons}>
            <div class={undoCss} ko-click={() => undoManager.undo()}>{undoIcon} </div>
            <div class={jss.undoList} > &nbsp; </div>
            <div class={redoCss} ko-click={() => undoManager.redo()}>{redoIcon}</div>
          </div>
          <div class={jss.buttonTitle}>Undo &nbsp;&nbsp;&nbsp;Redo</div>
        </template>
      </drop-down>
    )
  }

  static get undoCSS () {
    return {
      undoIcons: {
        ...buttons.clickable,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '22px',
        padding: '0px 4px',
        borderRadius: '4px',
        border: `1px solid ${color.fill.light.primary}`,
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          border: `1px solid ${color.fill.dark.primary}`,
          backgroundColor: color.fill.dark.primary,
        },

        '& svg': {
          fill: color.systemGrey.light.iconOne,
          height: '16px',
          paddingTop: '4px',
          'body[dark] &': { // project batman
            fill: color.systemGrey.dark.iconOne,
          },
        }
      },
      undoList: {
        fontSize: '0.8rem',
        padding: '0px 4px',
        minWidth: '3.2em',
        textAlign: 'center'
      },
      undoAvailable: {
        opacity: 1,
      },
      undoUnavailable: {
        opacity: 0.4,
      }
    }
  }



  // View - Zoom --  Insert --- Tags --- Bookmark - Notes -- Share - Search
  abstract get buttonRow ()


  get template () {
    const { jss } = this

    const headStyle =
      `grid-template-columns: repeat(${Math.max(3,this.buttonRow.length)}, auto)`

    return (
      <div class={jss.head} style={headStyle}>
        {this.headRow}
        {this.computed(() => this.buttonRow)}
      </div>
    )
  }

  get headActivity () {
    const { jss } = this
    const { notifier } = this.panelProvider.app
    return (
      <div class={jss.headActivityArea}>
        <div class={jss.headActivitySpinner}
          ko-if={notifier.activityInProgress}>
          {inline(cogIcon)}
        </div>
        <div class={jss.headActivityMessage}>
          {notifier.activity}
        </div>
      </div>
    )
  }

  static get headActivityCSS () {
    return {
      headActivity: {
        padding: '0px 25px',
        position: 'relative'
      },
      headActivityArea: {
        display: 'flex',
        fontWeight: 'normal',
        color: color.gray.c
      },
      headActivitySpinner: {
        margin: '0px 5px',
        '--icon-color': color.gray.c,
        '& svg': {
          animation: 'spin 2s infinite linear'        }
      },
      headActivityMessage: {}
    }
  }

  abstract get entityDisplayValue ()

  get headRow () {
    const { jss } = this
    const { app } = window
    const onEntityClick = () =>
      app.panelProvider(app.rootProviders.entityList)
    const onCalendarClick = () =>
      app.panelProvider(app.rootProviders.calendar)
    const onPersonClick = () =>
      app.panelProvider(app.rootProviders.personList)
    const onPrecedentClick = () =>
      app.panelProvider(app.rootProviders.precedentList)
    const claims = app.defaultAuthManager.userFirebaseClaims
    const userName = this.computed(() => claims()
      ? claims().email : 'Anonymous')

    return [
      <div class={jss.headBrand}>
        <div class={jss.headMinuteBox} ko-click={onEntityClick}>
          [MinuteBox]
        </div>
        <drop-down class={jss.headLogo} my='top left' at='bottom left' contentClass={jss.mbMenu}>
          <template slot='anchor'>
            <div>
              &nbsp;
              {inline(chevronDown)}
            </div>
          </template>
          <template slot='content'>
            <div class={jss.headLogoMenu}>
              <div class={jss.headLogoMenuItem} ko-click={onEntityClick}>
                Entity List
              </div>
              <div class={jss.headLogoMenuItem} ko-click={onCalendarClick}>
                Calendar
              </div>
              <div class={jss.headLogoMenuItem} ko-click={onPersonClick}>
                People
              </div>
              <div class={jss.headLogoMenuItem} ko-click={onPrecedentClick}>
                Precedents
              </div>
            </div>
          </template>
        </drop-down>
      </div>,
      <div class={jss.headEntity}>
        {this.computed(() => this.entityDisplayValue)}
        <div class={jss.headActivity}>{this.headActivity}</div>
      </div>,
      <div class={jss.headUser}>
        <div class={jss.headUser2}>
          <drop-down my='top right' at='bottom right'>
            <template slot='anchor'>
              <span class={jss.headUserIdentifier}>{userName}</span>
              {inline(chevronDown)}
            </template>
            <template slot='content'>
              {this.panelProvider.headUserMenuDropdownContentHTML}
            </template>
          </drop-down>
        </div>
        <drop-down my='top right' at='bottom right'>
          <template slot='anchor'>
            <span class={jss.helpIcon}>{helpIcon}</span>
          </template>
          <template slot='content'>
            {this.panelProvider.headHelpMenuDropdownContentHTML}
          </template>
        </drop-down>
      </div>
    ]
  }

  static get headCSS () {
    return {
      head: {
        display: 'grid',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridTemplateRows: '21px 57px',
        gridTemplateColumns: 'repeat(5, auto)',
        padding: '0px 20px 0px 10px',
        width: '100%',
        backgroundColor: 'white',
        fontSize: '12px',
        ...typography.sans,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.dmgrey.dmtext,
        },
      },
      _headDropdown: {
        ...buttons.clickable,
        '& > svg': {
          padding: '2px 3px'
        }
      },
      headBrand: {
        gridColumn: '1/2',
        display: 'flex'
      },
      headMinuteBox: {
        ...buttons.clickable,
        ...typography.brand.logo,
        marginRight: 10,
        '&:hover': {
          color: color.light.headLogoHover,
          'body[dark] &': { // project batman
            color: color.text.dark.secondary,
          },
        },
        '&:active': {
          color: color.light.headLogoActive,
          'body[dark] &': { // project batman
            color: color.dark.headLogoActive,
          },
        }
      },
      headLogo: {
        extend: '_headDropdown',
        '&:hover': {
          color: 'rgba(0,0,0,0.5)',
          fill: 'rgba(0,0,0,0.5)'
        }
      },
      headLogoMenu: {
        ...dropdown.generalMenu
      },
      headLogoMenuItem: {
        ...dropdown.item
      },
      headEntity: {
        gridColumn: '2/-2',
        display: 'flex',
        justifyContent: 'center',
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
        fontWeight: '700'
      },
      headUser: {
        extend: '_headDropdown',
        gridColumn: '-2/-1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'

      },

      headUser2: {
        extend: '_headDropdown',
        gridColumn: '-2/-1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&:hover': {
          color: color.light.toolBarUserHover,
          fill: color.light.toolBarUserHover,
          'body[dark] &': { // project batman
            color: color.text.dark.secondary,
            fill: color.text.dark.secondary,
          },
        }
      },

      headUserIdentifier: {
        padding: '0px 6px'
      },

      helpIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '6px',
        marginLeft: '6px',
        borderLeft: `1px solid ${color.text.light.primary}`,
        '--icon-color': `${color.icon.light.primary}`,
        'body[dark] &': { // project batman
          borderLeft: `1px solid ${color.text.dark.primary}`,
          '--icon-color': `${color.icon.dark.primary}`,
        },
        '&:hover': {
          '--icon-color': color.icon.light.secondary,
          'body[dark] &': { // project batman
            '--icon-color': color.icon.dark.secondary,
          },
        },
        '& svg': {
          height: '17px'
        }
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.buttonCSS,
      ...this.headCSS,
      ...this.headActivityCSS,
      ...this.viewCSS,
    }
  }
}

PanelHead.register()
