
import FilterModel from 'FilterModel'
import ModelList from 'ModelList'
import { PossiblePermissions, PermissionChange } from 'Permissions'
import { keyPathForModel } from 'utils/firestore'

/**
 * Used in AuthManager to list all the team filters for a user on the
 * given Google Cloud project.
 */
export default class TeamFilterList extends ModelList<FilterModel> {
  authManager: AuthManager

  constructor (authManager) {
    const teamFilterQuery = authManager
      .firestoreCollection('filter')
      .where('isTeam', '==', true)

    super(FilterModel, authManager, teamFilterQuery)
  }

  /**
   * @param {DataModel} model
   * @return {object} teamFilterKeyPermissions {c/r/u/d/...} e.g.
   *  { r: ['entity.*', 'team-key-1'], u: ['team-key-1'], d: [], ... }
   */
  async makeTeamFilterKeysFor (model) {
    const teamFilterKeys = Object.assign({},
      ...PossiblePermissions.map(p => ({ [p.char]: [] })))

    await this.loaded.yet(false)

    for (const teamFilter of this.list()) {
      const accountID = teamFilter.accountID()
      for (const { filterKey, allow } of teamFilter.perms()) {
        const filterCollectionID = FilterModel.vmResourceName
        const fsKey = keyPathForModel(accountID, filterKey, filterCollectionID)
        try {
          const filterModel = await FilterModel.vmGet(this.authManager, fsKey)
          if (!filterModel.test(model)) { continue }
        } catch (err) {
          console.error(`A TeamFilterList filter key does not exist: ${fsKey} on model`, model.vmFirestoreDocPath)
          continue
        }
        for (const permChar of allow) {
          teamFilterKeys[permChar].push(teamFilter.id())
        }
    }
    }

    return teamFilterKeys
  }

  /**
   * Wipe a team FilterModel from existence, meaning:
   *   1. delete teamFilterKeyPermissions references to it in every model;
   *   2. remove its presence from teamFilterKeys in every user.
   */
  async clearTeamFilter (app, teamFilterModel) {
    const { memoryDB } = app
    const { authManager, accountID, id } = teamFilterModel
    const pc = new PermissionChange(memoryDB, authManager)
    return pc.start(accountID(), id(), [])
  }
}
