
/**
 * @param {Blob} blob
 * @return {Promise.<ArrayBuffer>}
 */
export function blobToArrayBuffer (blob) : Promise<ArrayBuffer> {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}

export function blobToDataURL (blob) : Promise<string> {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

/**
 * @param {ArrayBuffer} buffer
 * @param {int} chunkSize
 * @yield {Uint8Array} for each chunk
 */
export function * asUint8Chunks (buffer, chunkSize) {
  let nextSize = buffer.byteLength
  let i = 0
  while (nextSize > 0) {
    const partSize = Math.min(chunkSize, nextSize)
    yield new Uint8Array(buffer, i * chunkSize, partSize)
    nextSize -= chunkSize
  }
}
