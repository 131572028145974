
import { color, buttons } from 'styles'

import PdfRenderer from './PdfRenderer'

/**
 * Tiny thumbnails on the left-hand-side of the PDF viewer
 */
export class PdfContext extends PdfRenderer {
  pageHover: KnockoutObservable<number>
  boundingRect: KnockoutObservable<any>
  currentPageElement: KnockoutObservable<Element>
  zoomImageSrc: KnockoutObservable<any>
  hoverElement: KnockoutObservable<Element>
  translateX: KnockoutObservable<number>

  constructor (args) {
    super(args)
    Object.assign(this, {
      boundingRect: args.boundingRect,
      pageHover: ko.observable(),
      itemHeight: this.computed(() => this.computeItemHeight()),
      currentPageElement: ko.observable(),
      zoomImageSrc: ko.observable(),
      hoverElement: ko.observable(),
      translateX: ko.observable(0).extend({ rateLimit: 50 }),
    })
  }

  /**
   * @return {int} pixels for each context item
   */
  computeItemHeight () {
    const bounds = this.truckBounds() // DOMRect
    if (!bounds) { return '0px' }
    const minContextPxPerPage = 12
    const pixelPadding = 2
    const pageCount = this.bookPages.length + 1
    const { height } = bounds || { height: 1 }
    const columns = Math.ceil(minContextPxPerPage * pageCount / height)
    const paddedPageCount = pageCount + (pageCount % columns)
    const pixelsPerContextPage = Math.min(50, height * columns / paddedPageCount - pixelPadding || 0)
    return `${pixelsPerContextPage}px`
  }

  get truckHeightMod10 () {
    return ko.pureComputed(() => {
      const rect = this.truckBounds()
      return rect ? `${rect.height % 10}px` : '0px'
    }).extend({readInAnimationFrame:true})
  }

  get truckVars () {
    return {
      '--item-height': this.itemHeight,
      '--height-mod-10': this.truckHeightMod10,
    }
  }

  static get css () {
    return {
      ...super.css,
      truck: {
        gridArea: 'context',
        position: 'sticky',
        top: 'calc(var(--sticky-offset) + 71px)',
        height: 'calc(100vh - var(--height-offset))',
        marginRight: '10px',
        '--height-offset': '215px',
        maxWidth: '265px',
        overflow: 'hidden',
      },

      pages: {
        ...super.css.pages,
        display: 'grid',
        justifyContent: 'flex-end',
        maxHeight: 'calc(100vh - var(--height-offset) - var(--height-mod-10))',
        width: 'fit-content',
        padding: '8px',
        transition: 'transform ease-in-out 100ms',
        grid: {
          gap: '2px',
          templateRows: 'repeat(auto-fit, var(--item-height))',
          autoColumns: '40px',
          autoFlow: 'column'
        },

      },

      canvasContainer: {
        ...super.css.canvasContainer,

        '&:hover,&[hover-outline]': {
          transition: 'outline 0s',
          outline: `2px solid rgb(155, 198, 243)`,
        },
      },

      thumbnailImage: {
        ...buttons.clickable,
        display: 'block',
        width: '100%',
        height: 'var(--item-height)',
        margin: 0,
        padding: 0
      },

      searchResultHighlight: {
       // outline: `3px solid ${color.searchHighlight}`,
        outline: `3px solid ${color.onPrimary}`,
        outlineOffset: '-5px',
      },

      zoomImage: {
        position: 'absolute',
        width: 0,
        zIndex: '2',
        visibility: 'hidden',
        top: '-999px',
        boxShadow: '0 0 2px grey',
        transition: '0s 0s',
        '&[show]': {
          width: '180px',
          visibility: 'visible',
        },
      },

      highlightStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      contextPageHighlight: {
        '& $highlightStyles': {
          zIndex: '1',
          outline: `2px solid ${color.primary}`,
          boxShadow: '0 0 5px 5px rgb(155, 198, 243)',
          'body[dark] &': { // project batman
            boxShadow: '0 0 5px 5px #333333',
          },
        },
        '& $thumbnailImage:not($searchResultHighlight)': {
          outline: '3px solid rgba(0,0,0,0.8)',
          outlineOffset: '-2px'
        },
      },
    }
  }

  static get iconCSS () {
    return {
      _marker: {
        ...super.iconCSS._marker,
        fontSize: '0.4rem',
        top: '0px',
        '& > svg': {
          filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.8))',
          'body[dark] &': { // project batman
            filter: 'unset'
          },
        },
      },

      note: {
        ...super.iconCSS.note,
        '&[exists]': {
          '--icon-color': color.color.light.yellow,
          'body[dark] &': { // project batman
            '--icon-color': color.color.dark.yellow,
          },
        },
      },

      bookmark: {
        ...super.iconCSS.bookmark,
        '&[exists]': {
          '--icon-color': color.color.light.red,
          'body[dark] &': { // project batman
            '--icon-color': color.color.dark.red,
          },
        },
      },

      section: {
        ...super.iconCSS.section,
        '&[exists]': {
          left: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0.8,
          backgroundColor: `yellow`,
          'body[dark] &': { // project batman
            backgroundColor: color.dark.contextViewSectionOverlay,
          },
          '& svg': {
            fill: color.color.light.blue,
            'body[dark] &': { // project batman
              fill: color.color.dark.blue,
            },
            opacity: '0',
            marginLeft: '2px'
          }
        }
      }
    }
  }

  get pagesHTML () {
    const { translateX } = this
    const pagesElement = ko.observable<Element>()

    this.currentPageElement.subscribe(el => {
      if (!el) { return }
      const rect = this.truckBounds()
      if (!rect) { return }
      if (!pagesElement()) { return }
      const width = pagesElement().clientWidth
      const leftMargin = 49
      const rightMargin = 98
      const currentX = translateX.peek()
      const selectedOffset = el.offsetLeft + currentX
      if (selectedOffset < leftMargin) {
        translateX.modify(v => v + (leftMargin - selectedOffset))
      } else if (selectedOffset > (rect.width - rightMargin)) {
        translateX.modify(v => v - (selectedOffset - (rect.width - rightMargin)))
      }
      translateX.modify(v => Math.min(Math.max(v, -(width-rect.width)), 0))
      if (translateX.peek() !== currentX) {
        setTimeout(() => this.currentPageElement.valueHasMutated(), 100)
      }
    })

    const translateStyle = this.computed(() => (
      `transform: translate3d(${translateX()}px, 0, 0)`
    )).extend({readInAnimationFrame:true})

    return (
      <div class={this.jss.pages}
        style={translateStyle}
        ko-set-node={pagesElement}>
          {this.pageListHTML}
      </div>
    )
  }

  get rootHTML () {
    return this.zoomImageHTML
  }

  get zoomImageHTML () {
    const imgWidth = 180
    const style = this.computed(() => {
      const el = this.hoverElement()
      const truck = this.truckElement()
      const tX = this.translateX()
      const rect = this.boundingRect()
      if (!el || !truck || !rect) { return '' }
      const isLow = el.offsetTop > window.innerHeight * 0.5
      const top = isLow ? '' : `top: ${el.offsetTop + truck.offsetTop}px;`
      const bottom = isLow ? `
        top: unset;
        bottom: ${rect.height - truck.offsetTop - el.offsetTop - el.clientHeight}px;
      ` : ''
      const left = `left: ${el.offsetLeft + tX - imgWidth - 4}px;`
      return `${top} ${bottom} ${left}`
    }).extend({readInAnimationFrame:true})

    return <img class={this.jss.zoomImage}
      src={this.zoomImageSrc}
      style={style}
      show={this.zoomImageSrc} />
  }

  contextHighlightCheck (page) {
    const { jss } = this
    const cph = this.computed(() => this.currentPage() === page)
      .extend({ deferred: true, readInAnimationFrame: true })
    return { [jss.contextPageHighlight]: cph }
  }

  pageHTML (bookPage, givenIndex) {
    const { jss } = this
    const index = this.computed<number>(() => this.book.getIndexOfpageID(bookPage.pageID))

    const imageSrc = ko.observable()
    const canvasContainerElement = ko.observable()
    const boundsReset = this.computed(() => {
      const bounds = this.truckBounds()
      const el = canvasContainerElement()
      if (!el) return { bounds, img:null }
      const img = el.querySelector(`.${jss.zoomImage}`)
      if (img) img.style.bottom = ''
      return { bounds, img }
    })
    this.computed(() => {
      const { bounds, img } = boundsReset()
      if (!img || this.pageHover() !== index()) { return }
      if (img && img.getBoundingClientRect().bottom > bounds.bottom) {
        img.style.bottom = '0'
      }
    })

    // const priority = bookPage.startsSection()
    this.loadOrRenderThumb(bookPage).then(imageSrc)

    const searchResultHighlight = this.computed(() => {
      const results = this.searchData.pageResults()[index()]
      return { [jss.searchResultHighlight]: results && results.length }
    })

    this.computed(() => {
      if (this.currentPage() === index() + 1) {
        this.currentPageElement(canvasContainerElement())
      }
    })

    this.pageHover(undefined)
    const hoverEvt = ko.observable<Event>()
    let hoverTimer = null
    hoverEvt.subscribe(evt => {
      clearTimeout(hoverTimer)
      if (evt) {
        hoverTimer = setTimeout(() => {
          this.pageHover(index())
          this.zoomImageSrc(imageSrc())
          this.hoverElement(canvasContainerElement())
        }, this.hoverElement() ? 0 : 200)
      } else {
        hoverTimer = setTimeout(() => {
          if (this.pageHover() === index()) {
            this.pageHover(undefined)
            this.zoomImageSrc(undefined)
            this.hoverElement(undefined)
          }
        }, 50)
      }
    })

    return (
      <div class={jss.canvasContainer}
        ko-hover={hoverEvt}
        ko-css={this.contextHighlightCheck(index() + 1)}
        ko-click={() => bookPage.scrollIntoView()}
        ko-set-node={canvasContainerElement}
        hover-outline={this.computed(() => ((this.searchData.pageHoverFocus() === index()) || undefined))}>
        { this.sectionHTML(bookPage) }
        { this.noteHTML(bookPage) }
        { this.bookmarkHTML(bookPage) }
        <div class={jss.highlightStyles} />
        <img class={jss.thumbnailImage}
          src={imageSrc}
          draggable='false'
          ko-css={searchResultHighlight}/>
      </div>
    )
  }
}

PdfContext.register()
