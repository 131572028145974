
import ViewComponent from 'ViewComponent'

import { color } from 'styles'

/**
 * Pair with e.g.
 *
 *
 *   css {
 *      '--right-panel-width': 'calc(200px + 8vw)',
 *     '--right-panel-transition-in': 'transform 0.25s ease-in',
 *     '--right-panel-transition-out': 'transform 0.25s ease-out'
 *       display: 'block',
 *       position: 'fixed',
 *       left: '100%',
 *       zIndex: 20,
 *       height: 'calc(100vh - var(--head-height))'
 *   }
 *
 */
export default class PanelRight extends ViewComponent {
  private visual: KnockoutObservable<JSX> = ko.observable()
  private view: KnockoutObservable<JSX>

  constructor ({ view }) {
    super()
    Object.assign(this, {
      view,
    })
    // Keep content on the screen while hiding.
    this.subscribe(view, v => v && this.visual(v as JSX))
    this.visual(view())
  }

  static get css () {
    return {
      _main: {
        borderLeft: `1px solid ${color.gray.c}`,
        width: '0',
        overflow: 'visible',
        height: '100%',
        transform: 'translate3d(0, 0, 0)',
        '& > *': {
          width: 'var(--right-panel-width)'
        }
      },
      shown: {
        extend: '_main',
        transition: 'var(--right-panel-transition-in)',
        transform: 'translate3d(calc(-1 * var(--right-panel-width)), 0, 0)'
      },
      hidden: {
        extend: '_main',
        transition: 'var(--right-panel-transition-out)'
      }
    }
  }

  get template () {
    const mainClass = this.computed(() =>
      this.view() ? this.jss.shown : this.jss.hidden)
    return <div class={mainClass}>{this.visual}</div>
  }
}

PanelRight.register()
