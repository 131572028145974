
import { format } from 'date-fns/esm'

import ViewComponent from 'ViewComponent'
import { buttons } from 'styles'
import { MONTHS } from 'utils/dates'

/**
 * <month-day-input month={m} day={d} />
 */
export default class MonthDayInput extends ViewComponent {
  constructor ({ month, day, classes, editing }, ...args) {
    super({}, ...args)
    Object.assign(this.jss, classes)
    Object.assign(this, {
      month,
      day,
      editing: editing || ko.observable(false)
    })
  }

  get monthsOptionsHTML () {
    return [
      <option value={''}>-</option>,
      ...MONTHS
        .map((name, i) => <option value={i}>{name}</option>)
    ]
  }

  static get css () {
    return {
      ...super.css,
      ...this.inputCSS,
      text: {}
    }
  }

  updateIfValid (m, d) {
    if (isNaN(m) || isNaN(d)) {
      this.month(null)
      this.day(null)
    } else {
      this.month(Number.parseInt(m, 10))
      this.day(Number.parseInt(d, 10))
    }
  }

  editHTML (editing) {
    const { jss } = this
    const m = ko.observable(String(this.month()))
    const d = ko.observable(this.day())
    const update = () => {
      this.updateIfValid(m(), d())
      editing(false)
    }

    const cancel = () => editing(false)
    const clear = () => {
      this.month(null)
      this.day(null)
      editing(false)
    }

    return [
      <div class={jss.monthDayInput}>
        <select
          class={jss.selectMonth}
          ko-value={m}>
          {this.monthsOptionsHTML}
        </select>
        <input
          type='text'
          class={jss.dayInput}
          ko-textInput={d}
          ko-keydown={{
            Enter: update
          }} />
        <div class={jss.cancel} ko-click={cancel}>Cancel</div>
        <div class={jss.ok} ko-click={update}>Save</div>
        <div class={jss.clear} ko-click={clear}>Clear</div>
      </div>
    ]
  }

  static get inputCSS () {
    return {
      monthDayInput: {
        display: 'flex',
        alignItems: 'center'
      },
      dayInput: {
        textAlign: 'center',
        width: '40px'
      },
      selectMonth: {},
      ok: {
        ...buttons.clickable,
        cursor: 'pointer',
        outline: 'none',
        color: 'white',
        border: '1px solid black',
        userSelect: 'none',
        textDecoration: 'unset',
        borderRadius: 3,
        fontSize: '0.8em',
        margin: '0 0px 0 6px',
        backgroundColor: '#4A90E2',
        padding: '0 3px 1px 3px'
      },
      cancel: {
        ...buttons.clickable,
        cursor: 'pointer',
        outline: 'none',
        color: 'black',
        border: '1px solid #4A90E2',
        userSelect: 'none',
        textDecoration: 'unset',
        borderRadius: 3,
        fontSize: '0.8em',
        margin: '0 0px 0 6px',
        backgroundColor: 'white',
        padding: '0 3px 1px 3px'
      },
      clear: {
        ...buttons.clickable,
        cursor: 'pointer',
        outline: 'none',
        color: 'black',
        userSelect: 'none',
        textDecoration: 'unset',
        fontSize: '0.8em',
        margin: '0 0px 0 6px',
        fontWeight: '600',
        padding: '0 3px 1px 3px'
      }
    }
  }

  dateString (month, day) {
    if (isNaN(month) || isNaN(day) || month === null || day === null) {
      return `Not set`
    }
    return format(new Date([2016, month, day]), 'LLLL do')
  }

  textHTML (editing) {
    const { jss } = this
    return (
      <div
        class={jss.text}
        ko-unless={editing}
        ko-click={() => editing(true)}>
        {this.computed(() => this.dateString(this.month(), this.day()))}
      </div>
    )
  }

  stopClick (evt) {
    evt.preventDefault()
    evt.stopPropagation()
  }

  get template () {
    const { editing } = this
    this.addEventListener(document.body, 'click', () => editing(false))
    return (
      <div ko-click={this.stopClick}>
        {this.computed(() => editing()
          ? this.editHTML(editing)
          : this.textHTML(editing))}
      </div>
    )
  }
}

MonthDayInput.register()
