import t from 't'

import { AssetSplit } from '../AssetAction'

import { CODES } from './Transaction'
import { AssetTransaction } from './AssetTransaction'

export class TransactionSplit extends AssetTransaction {
  numerator: KnockoutObservable<string>
  denominator: KnockoutObservable<string>

  constructor (params) {
    super(params)
    this.propertiesFromJS(params)
  }

  get code () { return CODES.CapitalSplit }
  get propertiesToSerialize () { return [] }
  get plainObservables () {
    return [
      ...super.plainObservables,
      'numerator', 'denominator',
    ]
  }

  * yieldActions () {
    const { assetClassID, numerator, denominator } = this
    yield new AssetSplit(assetClassID, numerator(), denominator())
  }

  shortDescription (cs: CapitalState) {
    const { numerator, denominator } = this
    return t.CAPITAL_SHARE_SPLIT(this.assetNameAt(cs), numerator, denominator)
  }
}
