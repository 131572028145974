
type RightView = import('./RightView').default

type RightViewTabGroupItem = { title: string, click: () => void }

export default class RightViewTabGroup {
  private views = new Set<RightView>()
  constructor (private view: KnockoutObservable<JSX>) {}

  add (rv: RightView) { this.views.add(rv) }

  * [Symbol.iterator] (): IterableIterator<RightViewTabGroupItem> {
    yield * [...this.views]
      .map(rv => ({ title: rv.title, click: () => this.view(rv.template) }))
  }
}
