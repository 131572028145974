
import PanelProvider from 'PanelProvider'
import UserLoginPanelProvider from './UserLoginPanelProvider'

import { typography, buttons, formBox } from 'styles'

export default class InvitePanelProvider extends PanelProvider {
  get panelWindowTitle () { return `Invite` }

  get historyUrl () {
    return `#invited`
  }

  constructor ({ app, jwt }) {
    super({ app })

    Object.assign(this, {
      inviteJWT: jwt,
      firstname: ko.observable(''),
      lastname: ko.observable(''),
      phone: ko.observable(''),
      email: ko.observable(''),
      failMessage: ko.observable('')
    })
  }

  async joinClick () {
    this.failMessage('')
    const params = {
      firstname: this.firstname(),
      lastname: this.lastname(),
      phone: this.phone(),
      email: this.email(),
      signature: this.inviteJWT
    }
    console.log(`Invitation params to server: `, params)
    const resp = await this.app.callFirebaseFunction('inviteValidation', params)
    console.log(`Results of invite:`, resp)
    if (resp.status === 'fail') {
      this.failMessage('The invitation failed.')
      return
    }
    this.showUserLoginPanelProvider(this.email())
  }

  showUserLoginPanelProvider (email) {
    const { app } = this
    this.app.panelProvider(new UserLoginPanelProvider({ app, email }))
  }

  inputHTML (type, css, placeholder, obs) {
    return <input type={type}
      class={css}
      placeholder={placeholder}
      ko-textInput={obs} />
  }

  get top () {}
  get main () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        <div class={jss.pad}>
          <div class={jss.message}>
            <div class={jss.header}>
              Welcome to MinuteBox. Please complete your registration.
            </div>
            <div class={jss.subheader}>
              You’ve been invited to join a MinuteBox team. For security purposes please re-enter the email where you received this invitation and complete your contact information below.
            </div>
          </div>
          <div class={jss.messageU1} />

          <div class={jss.form}>

            {this.inputHTML('text', jss.firstname, 'First name', this.firstname)}
            {this.inputHTML('text', jss.lastname, 'Last name', this.lastname)}
            {this.inputHTML('email', jss.email, 'Email', this.email)}
            {this.inputHTML('tel', jss.phone, 'Phone', this.phone)}

            <async-button faceClass={jss.join}
              action={() => this.joinClick()}>
              <template slot='face'>Join my team</template>
            </async-button>
            <div class={jss.brand}>
              <div class={jss.yellowBar} />
              <div class={jss.seeWhy}>
                See why law firms are saying goodbye to physical minute books
              </div>
              <div class={jss.seeWhyText}>
                Complete the information on the left to begin using MinuteBox.
              </div>
              <div class={jss.failMessage}>
                {this.failMessage}
              </div>
            </div>
          </div>
          <div class={jss.formU1} />
          <div class={jss.formU2} />
        </div>
      </div>
    )
  }

  static get mainCSS () {
    return {
      layout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '4vh'
      },
      pad: {
        maxWidth: '620px',
        display: 'grid',
        gap: '35px',
        gridTemplateAreas: `
          'message'
          'form'
        `,
        ...typography.brand.text
      },
      ...this.messageCSS,
      ...this.formCSS,
      ...this.inputCSS,
      ...this.brandCSS
    }
  }

  static get messageCSS () {
    return {
      _messageBox: {
        gridArea: 'message',
        borderRadius: '5px',
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.50)'
      },
      message: {
        extend: '_messageBox',
        display: 'grid',
        gridTemplateRows: 'auto',
        gap: '10px',
        padding: '30px',
        backgroundColor: '#424242',
        color: 'white',
        textAlign: 'center'
      },
      messageU1: {
        extend: '_messageBox',
        backgroundColor: 'white',
        zIndex: -1,
        transform: 'rotate(-1deg) translateX(6px)'
      },
      header: {
        ...typography.brand.title
      },
      subheader: {
      }
    }
  }

  static get formCSS () {
    return {
      ...formBox.tripleLayer,
      form: {
        ...formBox.tripleLayer.form,
        gridArea: 'form',
        display: 'grid',
        gap: '17px 50px',
        gridTemplateColumns: '1.618fr 1fr',
        gridTemplateAreas: `
          'firstname  brand'
          'lastname   brand'
          'email      brand'
          'phone      brand'
          'join       brand'
        `,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        padding: '43px'
      },
      formU1: {
        gridArea: 'form',
        ...formBox.tripleLayer.formU1
      },
      formU2: {
        gridArea: 'form',
        ...formBox.tripleLayer.formU2
      }
    }
  }

  static get inputCSS () {
    return {
      _input: {
        ...formBox.textInput
      },
      firstname: {
        extend: '_input',
        gridArea: 'firstname'
      },
      lastname: {
        extend: '_input',
        gridArea: 'lastname'
      },
      email: {
        extend: '_input',
        gridArea: 'email'
      },
      phone: {
        extend: '_input',
        gridArea: 'phone'
      },
      join: {
        gridArea: 'join',
        ...buttons.formAction
      }
    }
  }

  static get brandCSS () {
    return {
      brand: {
        gridArea: 'brand',
        display: 'grid',
        gap: '12px',
      },
      yellowBar: {
        ...formBox.yellowBar
      },
      seeWhy: {
        ...typography.brand.title,
        fontSize: '1rem',
        color: '#424242'
      },
      seeWhyText: {},
      failMessage: {
        color: 'red',
        fontSize: '1.1rem',
        fontWeight: '700'
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.mainCSS
    }
  }
}
