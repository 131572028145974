
import { JSXContent } from './types'
type PanelProvider = import('PanelProvider').default

export default abstract class LeftPanelSection {
  abstract get title () : JSXContent
  abstract get fields () : KnockoutObservableArray<JSXContent>

  get isEmpty () { return !this.fields() || !this.fields().length }
  scrollTo : KnockoutObservable<number> = ko.observable().extend({notify: 'always'})
  visible : KnockoutObservable<boolean> = ko.observable(true)
  showField : KnockoutObservable<boolean> = ko.observable(true)
  onSelect (evt:Event) : void { this.showField(true) }

  jss : any
  constructor (public panelProvider : PanelProvider) {
    this.jss = panelProvider.jss
  }
}
