import RootListCommandSet from "minute-box-app/RootListCommandSet"
import Command from "Command"

type PanelProvider = import("PanelProvider").default

export default class ModelListCommandSet extends RootListCommandSet {
  panelProvider: PanelProvider
  private modelListCommands: KnockoutObservable<any> = ko.observable()
  private _shortcuts: any[]

  setModelListCommands (commands) { this.modelListCommands(commands) }

  makeCommands () {
    return {
      ...super.makeCommands(),
      ...this.makeProxyCommands(),
    }
  }

  makeProxyCommands () {
    const mlc = this.modelListCommands
    return {
      navPrevious: new Command({
        title: 'Previous row',
        action: () => mlc() && mlc().navPrevious.action(),
      }),
      navNext: new Command({
        title: 'Next row',
        action: () => mlc() && mlc().navNext.action(),
      }),
      activateCurrent: new Command({
        title: 'Activate',
        action: () => mlc() && mlc().activateCurrent.action(),
      }),
    }
  }

  get keyboardShortcuts () {
    const { navPrevious, navNext, activateCurrent } = this.commands
    return this._shortcuts || (this._shortcuts = [
      ...super.keyboardShortcuts,
      ...navPrevious.keyboardShortcuts('ArrowUp'),
      ...navNext.keyboardShortcuts('ArrowDown'),
      ...activateCurrent.keyboardShortcuts('Enter'),
    ])
  }
}
