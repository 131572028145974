//
// Question
// --------
//
// A Question instance identifies a single unit of information that will
// be used as a variable to be interpolated into a document.
//
// A Question is named (title), has the identifier used for interpolation,
// and contains the `autoform` and `content` elements that would
// occur in a KoD.
//
// It may optionally include validation parameters and parameters to the
// autoform template.
//
// Examples include
// - Signatories (selected from e.g. Shareholders/Directors/etc.)
// - Corporate Dividend recipients & amounts
// - Corporation References
//
import {
  DataComponent,
} from './components'

import {
  isNil, omitBy,
} from 'lodash-es'

const { ko } = window

export type QuestionDeclaration = {
  title: string
  identifier: string
  component: string
  share: string
}

export default class Question {
  /**
   *
   * @param {{component: DataComponent, title: string, identifer: string, {validation: Object}, contains: Object, index: Object}} params
   * @param {{contains: ComponentView[]}} questionnaire
   */
  constructor (params = {}, questionnaire) {
    Object.assign(this, {
      title: params.title,
      component: params.component,
      identifier: params.identifier,
      questionnaire: questionnaire,
      properties: params,
      searchIndexes: params.index
    })

    if (!this.title) {
      throw new Error(`Component missing title.`)
    }

    if (!this.component) {
      throw new Error(`Component missing component.`)
    }

    this.identifierNameOrSymbol = params.identifier ?
      ko.unwrap(this.identifier) : Symbol(`Anonymous ${this.component}: ${this.title}`)
  }

  makeComponent () {
    const ComponentClass = DataComponent.getComponentById(this.component)
    if (!ComponentClass) {
      throw new Error(`ComponentClass not found: ${this.component}`)
    }
    const {identifier, properties, title} = this
    const model = this.questionnaire
    return new ComponentClass({
      identifier, properties, title, model, questionnaire: model
    })
  }

  //  -- toJS --
  toJS () {
    return omitBy({
      component: this.component(),
      identifier: this.identifier(),
      title: this.title(),
      validation: this.validation(),
      contains: this.containsExtra
    }, isNil)
  }
}
