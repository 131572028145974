import removeIcon from 'icons/solid/times-circle'
import { color, buttons } from 'styles'
import icons from 'icons'

import RightView from './RightView'


export default abstract class RightViewList<T> extends RightView {
  list: KnockoutObservableArray<T>

  constructor (params) {
    const { list } = params
    super(params)
    Object.assign(this, { list })
  }

  static get css () {
    return {
      ...super.css,
      grid: { },
      lead: {
        margin: '16px 0px 8px 35px',
      },
      list: {
        width: 'auto',
        minHeight: '300px',
        border: `1px solid ${color.gray.d}`,
        margin: '8px 35px 8px 35px',
        backgroundColor: 'white',
        borderRadius: '3px',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
        },
      },
      item: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 8px',
        '&:hover $removeItemButton': {
          opacity: 1,
        },
        '&:active': {
          backgroundColor: 'antiquewhite'
        },
      },
      removeItemButton: {
        ...buttons.clickable,
        marginLeft: 'auto',
        opacity: 0,
        transition: 'opacity 0.15s ease-in-out'
      },
      input: {
        marginLeft: '35px',
        width: '100%',
        maxWidth: '260px',
        border: '1px solid #dbdbdb',
        outline: 'none',
        padding: '5px 1em',
        borderRadius: 4,
        backgroundColor: '#ffffff',
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          color: color.text.dark.primary
        },
      }
    }
  }

  get body () {
    const { jss } = this
    return (
      <div class={jss.grid}>
        <div class={jss.lead}>
          {this.leadHTML}
        </div>
        <div class={jss.list}>
          {this.computed(() => this.list().map(n => this.itemHTML(n)))}
        </div>
        {this.pickerHTML}
      </div>
    )
  }

  abstract get leadHTML () : any
  abstract get pickerHTML () : any

  itemHTML (string) {
    const { jss } = this
    return (
      <div class={jss.item}>
        {this.itemInnerHTML(string)}
        <div class={jss.removeItemButton}
          ko-click={() => this.list.remove(string)}>
          {icons.inline(removeIcon)}
        </div>
      </div>
    )
  }
  itemInnerHTML (string) { return string }
}
