
import 'monthday-input'
import EisPrimitiveComponent from './EisPrimitiveComponent'
import { formatDistanceStrict, format, isValid, endOfMonth, startOfDay } from 'date-fns/esm'

import { color, buttons, typography } from 'styles'
import { fromObject, MONTHS, LEAP_YEAR } from 'utils/dates'


export default class EisMonthDay extends EisPrimitiveComponent {
  day: KnockoutObservable<number>
  month: KnockoutObservable<number>
  inputValue: KnockoutComputed<Date>

  constructor (params) {
    super(params)

    const value = this.componentValue()
    Object.assign(this, {
      month: ko.observable(value && isValid(value) && value.getMonth()),
      day: ko.observable(value && isValid(value) && value.getDate()),
    })

    this.inputValue = this.computed({
      write: m => {
        this.month(m && isValid(m) && m.getMonth())
        this.day(m && isValid(m) && m.getDate())
      },
      read: () => this.readValue
    })
  }

  get componentValue () {
    return this.component.get().dateValue
  }

  private get readValue () {
    const { month, day } = this
    if (isFinite(month()) && day()) {
      return fromObject({ M: month(), d: day() })
    }
    return null
  }

  static get css () {
    return {
      ...super.css,
      contentArea: {
        ...super.css.contentArea,
        display: 'grid',
        gridTemplateColumns: '1fr auto 65px',
        alignItems: 'end',
      },
      monthDayArea: {
        ...super.css.textContent,
        fontSize: '1.5rem',
      },
      monthDay: {
        color: color.gray.aa,
        fontStyle: 'normal',
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      indicatorArea: {
        '&[blank=true]': {
          visibility: 'hidden',
        },
      },

      _daysRemaining: {
        ...super.css.indicator,
        ...color.dynamic({
          'light': {
            ...color.color.light,
            ...color.text.light,
            'soonPrimary': color.text.light.primary
          },
          'dark': {
            ...color.color.dark,
            ...color.text.dark,
            'soonPrimary': color.text.dark.altPrimary
          },
        }, 'dynamic'),
        fontFamily: typography.bodyFontFamily,
        fontWeight: '500',
      },
      fyeImmediate: {
        extend: '_daysRemaining',
        color: 'var(--dynamic-altPrimary)',
        backgroundColor: 'var(--dynamic-red)'
      },
      fyeSoon: {
        extend: '_daysRemaining',
        color: 'var(--dynamic-soonPrimary)',
        backgroundColor: 'var(--dynamic-yellow)'
      },
      fyeLater: {
        extend: '_daysRemaining',
        color: 'var(--dynamic-altPrimary)',
        backgroundColor: 'var(--dynamic-green)'
      },

      disabled: {
        visibility: 'hidden',
      },
      monthDayInput: {
        display: 'grid',
        gridTemplateColumns: 'min-content min-content auto',
        alignItems: 'center'
      },
      selectMonth: {
        height: 40,
        margin: 5,
        padding: '0px 5px',
        display: 'block',
        fontSize: '1.1em',
        width: '150px',
        alignItems: 'center',
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        backgroundColor: 'inherit',
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
        },
        gridTemplateColumns: 'auto 20px',
        '-webkit-appearance': 'button',
      },
      dayInput: {
        width: '40px',
        height: '40px',
        textAlign: 'center',
        borderRadius: 0,
        border: 0,
        outline: 'none',
        borderBottom: '1px solid black',
        fontSize: '1.1em',
        backgroundColor: 'inherit',
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
          borderBottom: '1px solid black'
        },
      },
      suggestedDay: {
      ...buttons.clickable,
      display: 'inline',
      padding: '3px 6px',
      width: 'fit-content',
      marginLeft: 10,
      userSelect: 'none',
      textDecoration: 'unset',
      background: color.fill.light.primary,
      'body[dark] &': { // project batman
        background: color.fill.dark.primary,
        color: color.text.dark.primary
      },
      boxShadow: '0px 0px 14px 3px rgba(0,0,0,0.08)',
      borderRadius: 8,
      transition: 'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      '&:hover': {
        transition: 'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        background: color.fill.light.secondary,
        boxShadow: '0px 0px 18px 6px rgba(0,0,0,0.12)',
        'body[dark] &': { // project batman
          background: color.fill.dark.secondary,
        },
      }
      }
    }
  }

  copyValueFor () {
    return this.displayText()
  }

  get isBlank () {
    return ko.pureComputed(() => !isValid(this.componentValue()))
  }

  get hasChanges () : boolean {
    const cv = this.componentValue()
    const unchanged = cv
      ? { day: cv.getDate(), month: cv.getMonth() }
      : { day: null, month: null }
    return unchanged.day !== this.day()
      || unchanged.month !== this.monthNumberOrNull
  }

  get monthNumberOrNull () {
    return Number.isInteger(this.month()) ? this.month() : null
  }

  get displayText () {
    return ko.pureComputed(() => (
      !this.isBlank()
        ? format(this.componentValue(), 'LLLL do')
        : this.constructor.displayPlaceholder[this.componentName]
    ))
  }

  daysRemaining () {
    const date = this.componentValue() as Date
    if (!isValid(date)) { return '' }
    const now = startOfDay(new Date())
    if (now > date) { date.setFullYear(date.getFullYear() + 1) }
    return formatDistanceStrict(now, date, { unit: 'day' })
  }

  get displayHTML () {
    const { jss } = this
    const focused = this.computed(() => this.focused() || undefined)
    const daysRemainingClass = this.computed(() => {
      const days = this.component.daysFromNow()
      return (
        days < 60 ? jss.fyeImmediate :
        days < 120 ? jss.fyeSoon :
        jss.fyeLater
      )
    })
    return (
      <>
        <div class={jss.monthDayArea}
          focused={focused}
          blank={this.isBlank}>
          <span class={jss.monthDay}>{this.displayText}</span>
        </div>
        <div class={jss.indicatorArea}
          blank={this.isBlank}>
          <div class={daysRemainingClass}>
            {this.computed(() => this.daysRemaining())}
          </div>
        </div>
      </>
    )
  }

  get monthsOptionsHTML () {
    return [
      <option value={''}>-</option>,
      ...MONTHS.map((name, i) => <option value={i}>{name}</option>)
    ]
  }

  get editHTML () {
    const { jss } = this
    const monthStr = this.computed({
      read: () => String(this.month()),
      write: v => this.month(Number.parseInt(v, 10)),
    })

    return (
      <div class={jss.monthDayInput}>
        <select class={jss.selectMonth} ko-value={monthStr}>
          {this.monthsOptionsHTML}
        </select>
        <input type='text' class={jss.dayInput}
          ko-textInput={this.day}
          ko-event={{ keydown: this.inputKeyDown() }} />
        {this.computed(() => this.suggestedDayHTML())}
      </div>
    )
  }

  suggestedDayHTML () {
    const { jss } = this
    const month = this.month()
    if (!Number.isInteger(month)) { return null }
    const suggest = endOfMonth(new Date(LEAP_YEAR, month, 1)).getDate()
    if (suggest === this.day()) { return null }
    return (
      <div class={jss.suggestedDay} ko-click={() => this.day(suggest)}>
        {suggest}?
      </div>
    )
  }
}

EisMonthDay.register()
