
import ModelPropertyPicker from './model-property-picker'

import { tag, tagColor, color } from 'styles'

export default class TagPicker extends ModelPropertyPicker {
  constructor (params) {
    super({...params, resetQueryOnSelect: true })
  }

  static get css () {
    return {
      ...super.css,
      tag: {
        ...tag
      }
    }
  }

  choices () {
    const choices = new Set<string>()
    const modelList = window.app.memoryDB.getListOf(this._indexName)
    for (const model of modelList()) {
      for (const tag of model.tags) { choices.add(tag) }
    }
    return [...choices]
  }

  itemHTML (item) {
    const { jss } = this
    return (
      <div class={jss.tag} style={`color: ${tagColor(item)}`}>{item}</div>
    )
  }

  filterChoice (item, query = '') {
    return item.toLowerCase().startsWith(query.toLowerCase())
  }

  get noMatchesMessage () { return 'Nothing matches. [Enter] to add.' }
  get searchMessage () { return 'Type to search or add' }
}

export class TagPickerModal extends TagPicker {
  static get css () {
    return {
      ...super.css,
      input: {
        fontSize: 15,
        padding: '8px 20px',
        borderRadius: 6,
        border: '1px solid transparent',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        marginBottom: '7px',
        minWidth: '250px',
        color: color.text.light.primary,
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          border: color.separator.dark.nonOpaque,
          color: color.text.dark.primary
        },
      },

      list: {
        ...super.css.list,
      }
    }
  }
}
TagPicker.register()
TagPickerModal.register()
