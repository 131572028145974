import { get } from 'lodash-es'

import ConfirmModelDelete from 'confirm-delete/confirm-model-delete'
import { Column } from 'Column'

type PrecedentModel = import('../precedents/PrecedentModel').default

interface JSSProvider { jss: Record<string, string> }

export function precedentActions (row: PrecedentModel) {
  return [{
    label: 'Delete ...', action: () => ConfirmModelDelete.open(row),
  }]
}

export function writingCommonListColumns (provider: JSSProvider): Column[] {
  return [
    Column.create('string', 'Title', 'title', {
      getter: row => row.cvModelTitle,
    }),
    Column.create('timestamp', 'Modified', 'modified'),
    Column.create('timestamp', 'Created', 'created'),
    Column.create('int', 'Word count', 'wordCount'),
    Column.create('int', 'Character count', 'charCount'),
    Column.create('string', 'Created by', 'createdBy', {
      getter: row => get(row.createdBy(), ['name', 0], ''),
    }),
    Column.create('ui', '', 'ui',
      row => <action-menu actions={precedentActions(row)} />),
  ]
}
