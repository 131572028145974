//
// ReadOnlyMediator
// ---
// Values will be read from the server, but changes ought not be saved.
// --
// Note that the server may fail or accept but ignore any read-only value
// passed.
//

import PlainMediator from './PlainMediator'

// The skip symbol can be useful for debugging/tracking.
const SKIP_SYMBOL = Symbol('ReadOnlyMediator skip')

export default class ReadOnlyMediator extends PlainMediator {
  get ignore () { return true }
  toJS () { return SKIP_SYMBOL }
  projectionValue () { return this.value }
}
