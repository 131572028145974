/**
 * The following are constants that appear as
 * `extraChoices` for various respective pickers.
 */
import {
  CapitalAssetPropertyType, CapitalAssetPropertyChoice,
} from 'capital/interfaces'

export const BUSINESS_NUMBER_NAMES : string[] = [
  'HST',
  'QST',
  'GST',
  'PST',
  'NAICS',
  'BN (CRA)',
  'BIN (Ontario)',
]

export const BUSINESS_TYPES : string[] = [
  'Agriculture, Forestry, Fishing and Hunting',
  'Mining, Quarrying, and Oil and Gas Extraction',
  'Utilities',
  'Construction',
  'Manufacturing',
  'Wholesale Trade',
  'Retail Trade',
  'Transportation and Warehousing',
  'Information',
  'Finance and Insurance',
  'Real Estate and Rental and Leasing',
  'Professional, Scientific, and Technical Services',
  'Management of Companies and Enterprises',
  'Administrative and Support and Waste Management and Remediation Services',
  'Educational Services',
  'Health Care and Social Assistance',
  'Arts, Entertainment, and Recreation',
  'Accommodation and Food Services',
  'Other Services (except Public Administration)',
  'Public Administration',
]

export const CLIENT_STANDING : string[] = [
  'Retained',
  'Closed',
  'In Arrears',
]

export const CONTRACT_AUTHORITY : string [] = [
  'President',
  'Any Officer',
  'Treasurer',
]

export const DIRECTOR_QUORUM : string [] = [
  'A majority of the directors',
  '2/3 of the directors',
  '3/4 of the directors',
]

export const DOMAIN_REGISTRARS : string [] = [
  'BlueHost',
  'Domain.com',
  'DreamHost',
  'EasyDNS',
  'GoDaddy',
  'HostGator',
  'Hover',
  'InfinityWS',
  'Namecheap',
  'Shopify',
  'Wix',
]

export const ENTITY_CREATED_BY : string[] = [
  'Incorporation',
  'Amalgamation',
  'Statute',
  'Continuance',
  'Letters patent',
  'Other',
]

export const ENTITY_TYPES : string[] = [
  'C-Corp',
  'Charity',
  'Co-Operative',
  'Corporation',
  'Corporation (Not-For-Profit)',
  'General Partnership',
  'Limited Liability Company',
  'Limited Liability Partnership',
  'Limited Partnership',
  'Non-Share Capital Corporation',
  'Other',
  'Professional Corporation',
  'REIT',
  'Sole Proprietorship',
  'Trust',
  'Unlimited Liability Company',
]

export const GOVERNING_DOCUMENT_TITLES : string [] = [
  'Shareholder Agreement',
  'Unanimous Shareholder Agreement',
  'Shareholder Declaration',
]

export const OFFICER_TITLES : string[] = [
  'President',
  'Chief Executive Officer',
  'Secretary',
  'Treasurer',
]

export const SERVICE_PROVIDER_PROVIDES : string [] = [
  'Bank',
  'Accountant',
  'Auditor',
  'Lawyer',
]

export const SHARE_CANCEL_REASONS = [
  'Cancel',
  'Gift',
  'Redeem',
  'Repurchase',
  'Retract',
]

export const SHARE_CLASSIFICATION : string [] = [
  'Common',
  'Preferred',
]

export const SHARE_SERIES : string [] = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'Seed',
  'Pre-Seed',
  'Bridge',
]

export const SHAREHOLDER_QUORUM : string [] = [
  'A majority of the shareholders',
  '2/3 of the shareholders',
  '3/4 of the shareholders',
]

export const CAPITAL_PROPERTY_TYPES = [
  { key: 'voting',
    title: 'Voting',
    default: true,
    type: CapitalAssetPropertyType.bool },
  { key: 'retractable',
    title: 'Retractable',
    default: true,
    type: CapitalAssetPropertyType.bool },
  { key: 'redeemable',
    title: 'Redeemable',
    default: true,
    type: CapitalAssetPropertyType.bool },
  { key: 'certificated',
    title: 'Certificated',
    default: true,
    type: CapitalAssetPropertyType.bool },
  { key: 'cumulative',
    title: 'Cumulative',
    default: true,
    type: CapitalAssetPropertyType.bool },
  { key: 'proxy-voting',
    title: 'Proxy Voting',
    default: true,
    type: CapitalAssetPropertyType.bool },

  { key: 'votes-per-share',
    title: 'Votes per Share',
    default: '1',
    type: CapitalAssetPropertyType.numeric },

  { key: 'par-value',
    title: 'Par Value',
    default: '0.01 CAD',
    type: CapitalAssetPropertyType.money },

  { key: 'deauthorized',
    title: 'Deauthorized',
    default: true,
    type: CapitalAssetPropertyType.flag,
  },
] as CapitalAssetPropertyChoice[]

export const ADDRESS_TITLES : string [] = [
  'Registered Address',
  'Mailing Address',
]
