/*
  Base class for our models that are accessed with our CRUD
  convention.
 */
import { groupBy } from 'lodash-es'

import { mergeBy } from 'person/utils'
import { ListOfNotes } from 'notes-list'
import { PersonType } from 'person/interfaces'
import { computed } from 'utils/decorator'

import DataModel from './DataModel'
import Sharing from './Sharing'
import {
  PlainMediator, ArrayMediator, ContentMediator, ClassMediator,
} from './mediators'
import crudAudit from './crudAudit'

/**
 * CrudModel is like DataModel but contain additional information.
 */
export default class CrudModel extends DataModel implements RelatedSource {
  cmPermissionDenied: KnockoutObservable<boolean>
  // See https://github.com/babel/babel/issues/8280
  // and https://github.com/microsoft/TypeScript/issues/12437
  // and https://github.com/tc39/proposal-class-fields/issues/242
  isProjection: KnockoutObservable<boolean | null>
  tags: KnockoutObservableArray<string>
  sharing: Sharing
  visibility: KnockoutObservable<string>
  content: import('./components/ContentView').default
  listOfNotes: ListOfNotes
  teamFilterKeyPermissions: KnockoutObservable<Record<PERMISSION_CHARACTER, Array<string>>>

  constructor (possibleValues, authManager) {
    super(possibleValues, authManager)
    Object.assign(this, {
      cmPermissionDenied: ko.observable(false),
    })

    // See https://github.com/tc39/proposal-class-fields/issues/242
  }

  static get crudUriPrefix () { return '/api/' + this.crudName.toLowerCase() }
  static get resource () { return this.crudName }
  static get crudName () { return this.name.replace('Model', '') } // FIXME #1026
  get crudName () { return this.constructor.crudName }
  get memoryIndexName () { return this.constructor.vmResourceName }
  get auditable () { return true }

  /**
   * @return {string} the user-facing name of this model instance.
   */
  get cvModelTitle () { return `"${this.constructor.name} ${this.id()}"` }

  @computed()
  createdBy () {
    const uid = this.createdByUid()
    const model = window.app.memoryDB.getModel('user', uid)
    return model ? model.selfPerson : null
  }

  /**
   * For the CrudModel we save the model, plus common search indexes, call
   * data component hooks.
   */
  async vmSaveStart (repr) {
    if (this.cmPermissionDenied()) {
      throw new Error(`CrudModel::vmSave Cannot save ${this.cvModelTitle}:
        In a permission-denied state.`)
    }

    const mediators = this.vmMediatorsList
    const before = this.vmSavedRepr()
    const hookParams = { model: this, before }

    // hook -> mediator::beforeModelSave
    const beforeSaves = await Promise.all(
      mediators.map(m => m.beforeModelSave(hookParams)))
    if (beforeSaves.some(b => b === false)) {
      console.info(`Not saving because a "beforeModelSave" returned false.`)
      return
    }

    const { teamFilters } = this.authManager
    await teamFilters.loaded.yet(undefined)

    this.teamFilterKeyPermissions(
      await teamFilters.makeTeamFilterKeysFor(this))

    // Audit
    if (this.auditable) {
      const after = this.vmCurrentRepr() || {}
      this.id.yet(undefined).then(() => crudAudit(this, before, after))
    }

    // hook -> mediator::afterModelSave
    // ---
    // Note that this is not strictly "after" the save finishes, but only
    // after it starts.
    hookParams.after = this.vmSavedRepr()
    await Promise.all(mediators.map(m => m.afterModelSave(hookParams)))

    /**
     * This is a poor-man's `batch`; the correct behaviour here would be to
     * have a `firebase.firestore.WriteBatch` (i.e. `firestore.batch()`)
     * that is used to save this model alongside any other concurrent
     * save transactions.
     *
     * This basically solves the race-condition of projections not being
     * updated because the save of the model can complete before the
     * projections finish saving — which means the projection-clients reload
     * the firestore model (this model) but get an old projection.
     *
     * The following save triggers the needed change.
     */
    return super.vmSaveStart(repr)
  }

  /**
   * @param {Date} given date
   * @param {string} range to search e.g. 'day', 'month', 'week', 'year'
   * @yield {object}
   */
  * genRemindersAtDate (given: Date, range) {
    for (const component of Object.values(this.content.componentMap)) {
      yield * component.genRemindersAtDate(given, range)
    }

    yield * [...this.sharing.genRemindersAtDate(given, range)].map(r => ({ ...r, model:this }))

    if (!this.listOfNotes) { this.listOfNotes = new ListOfNotes(this) }
    yield * this.listOfNotes.genRemindersAtDate(given, range)
  }

  /**
   * Yield the "hard" references (people with UID's) joined together,
   * plus the "soft" references of this model by name.
   */
  * getPersons (filter: (person: PersonRecord) => boolean = () => true) {
    const persons = [...this.generatePersons(filter)] as PersonRecord[]
    persons.forEach(p => this.normalizePerson(p))

    const hasID = groupBy(persons, p => Boolean(p.id))
    yield * mergeBy(hasID[true] || [], 'id')
    yield * mergeBy(hasID[false] || [], 'name')
  }

  /**
   * Modify the `person` to add/change/delete any properties.
   */
  normalizePerson (person: PersonRecord) : void {
    if (!person.type || !person.type.length) { person.type = PersonType.REFERENCE }
    // 🐫 "Individual" -> "Reference"
    if (person.type === PersonType.INDIVIDUAL) {
      person.origin.forEach(o => o.update(p => p.type = PersonType.REFERENCE))
    }
  }

  * generatePersons (filter: (person: PersonRecord) => boolean = () => true) {
    for (const component of Object.values(this.content.componentMap)) {
      yield * component.getPersons(filter)
    }
    yield * this.sharing.getPersons(filter)
  }

  vmCreateMediatorInstances () {
    this.isProjection = ko.observable(null)

    return [
      ...super.vmCreateMediatorInstances(),

      new ArrayMediator('tags', this),
      /**
       * Access - controls exposure of content to shares
       * --
       * Edited with `<sharing-editor model={model}/>`
       */
      new ClassMediator('sharing', this, Sharing),

      /**
       * 🔒  Maps { permChar: [filterKeys] }
       */
      new PlainMediator('teamFilterKeyPermissions', this),
      /**
       * Visibility can be one of:
       * '*' meaning everyone,
       * 'team' meaning,
       * 'responsible' meaning the individuals responsible
       */
      new PlainMediator('visibility', this),
      // new KeyArrayMediator('follower_keys', this, $models.UserProfileView)

      new ContentMediator('content', this, this.isProjection)
    ]
  }

  /**
   * A "basic" permit means the user can read this model or a projection
   * of it.
   *
   * If a user is looking at a model and their permissions are revoked,
   * this can be monitored.
   *
   * @return {bool} true when one of:
   *  1. the user is an admin for this models' account;
   *  2. the user is on a team with `r` access to this model; or
   *  3. the user is shared this model.
   */
  cmUserHasBasicPermit (claims = this.authManager.userFirebaseClaims()) {
    const modelAccount = this.accountID()
    const { admin, teamFilterKeys } = claims.accounts[modelAccount]

    // 1.
    if (admin) { return true }

    // 2.
    const { r } = this.teamFilterKeyPermissions() || { r: [] }
    if (teamFilterKeys.some(t => r.includes(t))) { return true }

    // 3.
    const shared = this.sharing.sharingRights
      .find(sr => sr.email === claims.email)
    if (shared) { return true }

    return false
  }

  vmOnSnapshotError (err, doc) {
    if (err.code !== 'permission-denied') {
      return super.vmOnSnapshot(err, doc)
    }
    this.cmPermissionDenied(true)
  }

  /**
   * If a model has permission-denied, we want to return a stub model that
   * monitors for updates, since that permission could be granted.
   */
  static vmOnGetError (err, firestoreKey, authManager) {
    if (err.code === 'permission-denied') {
      const [__, accountID, ___, id] = firestoreKey.split('/').filter(s => s)
      console.log(`Permission denied for  ${firestoreKey}.
        Stubbing model ${accountID}/${id}`)
      return new this({ id, accountID }, authManager)
    }
    super.vmOnGetError(err, firestoreKey, authManager)
  }
}
