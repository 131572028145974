import ModalDialog from 'modal-dialog'

import 'select-section'

import { inline } from 'icons'
import { buttons, color, typography } from 'styles'

import plusIcon from 'icons/regular/plus-circle'
import chevronDown from 'icons/light/chevron-down'
import fileOutlineIcon from 'icons/light/file'
import fileSolidIcon from 'icons/solid/file'

export class PdfUploadModal extends ModalDialog {
  uploadToStart = ko.observable<boolean>(false)

  constructor (params) {
    super({ title:'Upload', ...params })
    const { book, dropEvt, section, uploadAction, cancelAction } = params
    Object.assign(this, {
      book,
      uploadAction,
      cancelAction,
      dropData: ko.observable(null),
      targetSection: ko.observable(section),
      newSection: ko.observable(),
      browseInputElement: ko.observable(null),
      uploadedPages: ko.observable(null),
    })
    this.subscribe(this.dropData, files => {
      if (!files) { return }
      this.uploadAction(files)
        .then(this.uploadedPages)
        .catch(e => {
          this.uploadedPages(e)
          if (e.name !== "PDFUploadCancelled") { throw e }
        })
    })
    if (dropEvt) {
      this.onDrop(dropEvt)
    }
  }

  onDrop (evt) {
    const files = [...evt.dataTransfer.files].filter(f => f.type === 'application/pdf')
    if (!files.length) {
      console.warn('Drag & drop upload only supports PDF files'); return
    }
    this.dropData(files)
  }

  cancel () {
    if (this.cancelAction) { this.cancelAction() }
    window.app.modal(undefined)
  }

  async updateEntity () {
    window.app.modal(undefined)
    await this.uploadedPages.yet(null)
    if (this.uploadedPages() instanceof Error) { return }
    if (this.newSection()) {
      this.book.uploadPagesToNewSection(this.uploadedPages(), this.newSection())
    } else {
      this.book.addPagesToSection(this.uploadedPages(), this.targetSection(), this.uploadToStart())
    }
  }

  static get css () {
    return {
      ...super.css,
      contentContainer: {
        marginTop: '10px',
      },
      contentGrid: {
        display: 'grid',
        grid: {
          templateColumns: 'auto 400px',
          gap: '20px',
        },
        paddingRight: '30px',
      },
      labelArea: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      label: {
        fontFamily: typography.altFontFamily,
      },
      filesLabelArea: {
        extend: 'labelArea',
      },
      filesLabel: {
        extend: 'label',
      },
      sectionLabelArea: {
        extend: 'labelArea',
        alignItems: 'center',
      },
      sectionLabel: {
        extend: 'label',
      },
      filesArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
      dropArea: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
        width: '100%',
        borderRadius: '10px',
        marginBottom: '10px',
        border: `1px solid ${color.color.light.blue}`,
        backgroundColor: '#f1fbe7',
        'body[dark] &': { // project batman
          border: `1px solid ${color.color.dark.blue}`,
          backgroundColor: color.fill.dark.secondary,
        },
        '$contentContainer[dropped] &': {
          border: `1px solid ${color.onPrimary}`,
          backgroundColor: '#dbe9f9',
          'body[dark] &': { // project batman
            border: `1px solid ${color.color.dark.blue}`,
            backgroundColor: color.fill.dark.primary,
          },
        },
      },
      dropAreaIcon: {
        '--icon-color': '#d8f2bd',
        fontSize: '50px',
      },
      dropAreaTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '10px',
        width: '100%',
      },
      dropAreaText: {
        color: 'grey',
      },
      browseButton: {
        ...buttons.clean,
        border: '1px solid',
        borderRadius: '5px',
        padding: '8px 45px',
        color: 'grey',
        fontSize: '0.9em',
      },
      buttonList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '10px',
      },
      cancelButton: {
        ...buttons.modalCancel,
        margin: '10px',
        padding: '15px 40px',
      },
      uploadButton: {
        ...buttons.modalOk,
        margin: '10px',
        padding: '15px 40px',
      },

      sectionSelectArea: {
        position: 'static',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        gridGap: 15,
      },

      startOrEnd: {
        ...buttons.clickable,
      },

      droppedIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10
      },
      droppedFileIcons: {
        position: 'relative',
        '--icon-font-size': '50px',
        '--file-icon-outline-color': 'black',
        '--file-icon-interior-color': 'white',
      },
      fileOutlineIcon: {
        '--icon-color': 'var(--file-icon-outline-color)',
        position: 'relative',
        zIndex: '1',
      },
      fileSolidIcon: {
        '--icon-color': 'var(--file-icon-interior-color)',
        position: 'absolute',
        top: '0px',
        left: '0px',
      },
      multiIcon1: {
        position: 'relative',
        zIndex: 2,
      },
      multiIcon2: {
        position: 'absolute',
        top: '-5px',
        left: '5px',
        zIndex: 1,
      },
      multiIcon3: {
        position: 'absolute',
        top: '-10px',
        left: '10px',
        zIndex: 0,
      },
      droppedText: {
        position: 'static',
        textAlign: 'center'
      },

    }
  }

  get dropAreaWaitingHTML () {
    const { jss } = this
    return <>
      <div class={jss.dropAreaIcon}>
        {inline(plusIcon)}
      </div>
      <div class={jss.dropAreaTextContainer}>
        <span class={jss.dropAreaText}>
          Drag &amp; drop here to upload
        </span>
      </div>
    </>
  }

  get singleFileIconHTML () {
    const { jss } = this
    // To color the interior of an FA icon you need to stack two separate icons
    return <>
      <div class={jss.fileOutlineIcon}>{inline(fileOutlineIcon)}</div>
      <div class={jss.fileSolidIcon}>{inline(fileSolidIcon)}</div>
    </>
  }

  get multiFileIconHTML () {
    const { jss } = this
    return <>
      <div class={jss.multiIcon1}>{this.singleFileIconHTML}</div>
      <div class={jss.multiIcon2}>{this.singleFileIconHTML}</div>
      <div class={jss.multiIcon3}>{this.singleFileIconHTML}</div>
    </>
  }

  get dropAreaDroppedHTML () {
    const { jss } = this
    const multiDrop = this.computed(() => this.dropData().length > 1)
    const text = this.computed(() => (
      multiDrop()
        ? 'Multiple Files Selected'
        : this.dropData()[0].name
    ))
    return (
      <div class={jss.droppedIconContainer}>
        <div class={jss.droppedFileIcons}>
          {this.computed(() => multiDrop() ? this.multiFileIconHTML : this.singleFileIconHTML)}
        </div>
        <div class={jss.droppedText}>{text}</div>
      </div>
    )
  }

  get hiddenBrowseInputHTML () {
    const onChange = { change: () => {
      const filelist = this.browseInputElement().files
      if (filelist.length) { this.dropData(filelist) }
    }}
    return (
      <input type="file" multiple accept="application/pdf"
        style="display: none"
        ko-set-node={this.browseInputElement}
        ko-event={onChange} />
    )
  }

  get koDropTarget () { return {
    dragEvt: () => undefined,
    onDrop: () => undefined,
  }}

  get contentHTML () {
    const { jss } = this
    const dropped = this.computed(() => this.dropData() ? '' : undefined)
    return (
      <div class={jss.contentContainer} dropped={dropped}>
        <div class={jss.contentGrid}>
          <div class={jss.filesLabelArea}>
            <span class={jss.filesLabel}>Select Files</span>
          </div>
          <div class={jss.filesArea}>
            <div class={jss.dropArea} ko-drop-target={{onDrop: evt => this.onDrop(evt)}}>
              {this.computed(() => this.dropData() ? this.dropAreaDroppedHTML : this.dropAreaWaitingHTML)}
            </div>
            <div class={jss.browseButton} ko-click={() => this.browseInputElement().click()}>
              {this.hiddenBrowseInputHTML}
              Browse For Files
            </div>
          </div>
          <div class={jss.sectionLabelArea}
            ko-visible={this.book.sections.length}>
            <span class={jss.sectionLabel}>Upload To</span>
          </div>
          <div class={jss.sectionSelectArea}
            ko-visible={this.book.sections.length}>
            <div class={jss.startOrEnd}
              ko-ownClick={() => this.uploadToStart.modify(v => !v)}>
              {this.computed(() => this.uploadToStart() ? 'Start': 'End')} of
            </div>
            <select-section book={this.book}
              targetSection={this.targetSection}
              newSection={this.newSection}
              maxHeight={'calc(50vh - 138px - 6.5ex)'} />
          </div>
        </div>
        <div class={jss.buttonList}>
          <div class={jss.cancelButton} ko-click={() => this.cancel()}>Cancel</div>
          <async-button faceClass={jss.uploadButton} action={() => this.updateEntity()}>
            <template slot='face'>Confirm</template>
          </async-button>
        </div>
      </div>
    )
  }
}

PdfUploadModal.register()
