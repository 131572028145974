
import Condition from 'FilterModel/Condition'
import panelToCollectionID from 'utils/panelToCollectionID'

import { color, buttons, dropdown } from 'styles'
import { interleave } from 'utils/iterable'

import icons from 'icons'
import caretDown from 'icons/solid/caret-down'

import ModelOptionsEditor from './ModelOptionsEditor'
import FilterModel from 'FilterModel'

/**
 * A mechanism for editing and saving the filters being applied to
 * a given Model, organized by PanelID and userID.
 */
export default class ModelListConditions extends ModelOptionsEditor {
  newModifierClass (opts) {
    const params = opts || { disjunct: true }
    return new Condition(params, { panelID: () => this.panelID })
  }
  get noModifiersText () { return 'No filters are applied.' }
  appliesToCol (column) { return column.filterable }
  get addText () {
    return this.computed(() => this.modifierArray.length
      ? 'Combine with another filter'
      : 'Add a new filter')
  }

  constructor ({ savedFilters, conditions, columns, panelID }, ...args) {
    super({ modifierArray: conditions, columns }, ...args)
    Object.assign(this, {
      savedFilters,
      conditions,
      panelID
    })
  }

  modifierLeadHTML (modifier) {
    const { jss } = this
    const isFirst = this.modifierArray().indexOf(modifier) === 0
    const isOr = modifier.disjunct
    if (isFirst) { return <div class={jss.where}>Where</div> }
    if (isOr()) { return <div class={jss.or}>where</div> }
    return <div class={jss.and}>... and where</div>
  }

  get gripHTML () {}

  static get modifierCSS () {
    return {
      ...super.modifierCSS,
      modifier: {
        ...super.modifierCSS.modifier,
        gridColumn: '1/3',
        backgroundColor: 'unset',
        padding: '4px 0px',
        gridTemplateColumns: 'auto 90px 1fr 1fr auto',
        gridGap: '0px'
      },
      where: {
        width: '65px'
      },
      and: {
        width: 'auto'
      },
      orText: {
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
        textAlign: 'right',
        padding: '4px 10px',
        borderRadius: 14,
        marginLeft: 20
      },
      orLine: {
        height: '1px',
        margin: '5px 15px',
        background: color.separator.light.nonOpaque,
        'body[dark] &': { // project batman
          background: color.separator.dark.nonOpaque,
        },
      },
      orBreak: {
        display: 'grid',
        alignItems: 'center',
        margin: '5px 0px',
        grid: {
          templateRows: '1fr',
          templateColumns: 'auto 1fr',
          templateAreas: `
            't .'
            't l'
            't .'
            `
        }
      },

      modifierGroup: {
        display: 'grid',
        grid: {
          templateColumns: '1fr auto'
        },
        padding: '10px 20px 10px 10px',
        backgroundColor: color.systemBackground.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemGroupBackground.darkElevated.secondary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        border: `1px solid ${color.separator.light.nonOpaque}`,
        borderRadius: '10px',
        margin: '5px 0 5px 0'
      },
      '&:hover': {
        boxShadow: `1px 1px 0px 0px black`,
      },

      //"add another rule to this filter"
      addCondition: {
        ...buttons.clickable,
        marginTop: '10px',
        gridColumn: '2/3',
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
        padding: '7px 30px',
        borderRadius: 20,
        '&:hover': {
          backgroundColor: color.fill.light.secondary,
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.05)',
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary,
          },
        }

      }
    }
  }

  get orHTML () {
    const { jss } = this
    return (
      <div class={jss.orBreak}>
        <div class={jss.orText}>OR</div>
        <div class={jss.orLine} />
      </div>
    )
  }

  addConditionHTML (list) {
    const addClick = evt => {
      const index = this.modifierArray().indexOf(list.slice(-1).pop())
      this.addClick(evt, {}, index + 1)
    }
    return (
      <div class={this.jss.addCondition}
        ko-click={addClick}>
        Add another rule to this filter
      </div>
    )
  }

  modifierGroupHTML (list) {
    const { jss } = this
    return list.length ? (
      <div class={jss.modifierGroup}>
        {list.map(f => this.modifierHTML(f))}
        {this.addConditionHTML(list)}
      </div>
    ) : undefined
  }

  * genModifierGroups () {
    let current = []
    for (const filter of this.modifierArray) {
      if (filter.disjunct() && current.length) {
        yield current
        current = []
      }
      current.push(filter)
    }
    if (current.length) { yield current }
  }

  get modifierListHTML () {
    const { jss } = this
    const modifiersList = [...this.genModifierGroups()]
    return modifiersList.length
      ? [...interleave(modifiersList
        .filter(g => g.length)
        .map(g => this.modifierGroupHTML(g)), this.orHTML)]
      : <div class={jss.noModifiers}>{this.noModifiersText}</div>
  }

  operationClick (evt, filter, o) {
    evt.stopPropagation()
    evt.preventDefault()
    filter.operation(o)
  }

  operationsHTML (filter) {
    const { jss } = this
    return (
      <div class={jss.operationTitles}>
        {filter.column().filterOperations().map(o =>
          <div class={jss.operationTitleMenuItem}
            ko-click={evt => this.operationClick(evt, filter, o)}>
            {o.title}
          </div>
        )}
      </div>
    )
  }

  static get operationCSS () {
    return {
      operationTitles: {
        ...dropdown.generalMenu,
        backgroundColor: 'white'
      },
      operationTitle: {
        ...buttons.clickable,
        padding: '4px 10px',
        borderRadius: '4px',
        textAlign: 'right',
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: color.fill.light.secondary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary,
          },
        }
      },
      operationTitleMenuItem: {
        ...dropdown.item
      },
      operationInput: {
        padding: '0px 5px'
      }
    }
  }

  /**
   * @param {Condition} condition
   * @return {JSX}
   */
  modifierParamHTML (condition) {
    const { jss } = this
    return [
      <drop-down my='top center' at='bottom center'>
        <template slot='anchor'>
          <div class={jss.operationTitle}>
            {condition.operation().title}
            {icons.caret(caretDown)}
          </div>
        </template>
        <template slot='content'> {this.operationsHTML(condition)} </template>
      </drop-down>,
      <div class={jss.operationInput}>
        {condition.operation().inputJsx}
      </div>
    ]
  }

  alreadyExists () {
    return this.savedFilters && this.savedFilters()
      .find(f => f.isEquivalentTo(this.modifierArray()))
  }

  get otherButtonsHTML () {
    const { jss } = this
    const alreadyExistsTitle = this.computed(() => this.alreadyExists()
      ? `This filter already exists as "${this.alreadyExists().title()}"` : undefined)
      .extend({ rateLimit: 50 })

    return (
      <async-button faceClass={jss.save}
        action={() => this.saveClick()}
        exists={alreadyExistsTitle}
        title={alreadyExistsTitle}>
        <template slot='face'>
          Save Filter...
        </template>
      </async-button>
    )
  }

  get isSaveable () {
    return this.conditions.length && !this.alreadyExists()
  }

  async saveClick () {
    if (!this.isSaveable) { return }
    const authManager = window.app.defaultAuthManager
    const listOptions = app.panelProvider().listOptions

    // createdByUid is used in the firestore rules
    const createdByUid = authManager.firebaseUser().uid
    const saveData = {
      createdByUid,
      panelID: this.panelID,
      accountID: authManager.accountID(),
      collectionID: panelToCollectionID(this.panelID),
      title: 'New filter',
      isNew: true,
      conditions: this.conditions().map(f => f.toJS()),
      columns: listOptions.shownColumns(),
      created: firebase.firestore.FieldValue.serverTimestamp(),
      modified: firebase.firestore.FieldValue.serverTimestamp()
    }

    const model = await FilterModel.vmCreate(authManager, saveData)
    listOptions.restoreSavedFilters(model)

    // Hide the modal.
    document.body.click()

    console.log(`[+] /filter/${model.id()} `, saveData)
  }

  static get modifierListCSS () {
    return {
      ...super.modifierListCSS,
      save: {
        ...buttons.clickable,
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
        padding: '6px 1rem',
        borderRadius: '6px',
        '[modifier-count="0"] &, &[exists]': {
          color: color.text.light.tertiary,
          'body[dark] &': { // project batman
            color: color.text.dark.tertiary,
          },
        },
        'body[homeless] &': {
          display: 'none',
        },
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.operationCSS
    }
  }
}

ModelListConditions.register()
