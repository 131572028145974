
import ViewComponent from 'ViewComponent'
import * as styles from 'styles'
import { color } from 'styles'

import icons from 'icons'
import checkIcon from 'icons/light/check'

/**
 * Editor is the base class for the Knockout component for
 * editing models.
 */
export default abstract class Editor extends ViewComponent {
  okText: string
  _onFinish: (...args) => Promise<void>
  _onSave: (...args) => Promise<void>
  _onCancel: (...args) => Promise<void>

  async onSave (...args) : Promise<void> {
    this._onSave && await this._onSave(...args)
  }
  async onCancel (...args) : Promise<void> {
    this._onCancel && await this._onCancel(...args)
  }
  /**
   * By default, onFinish closes the modal.
   */
  async onFinish (...args) : Promise<void> {
    this._onFinish && await this._onFinish(...args)
    global.app.modal(undefined)
  }

  constructor ({ onFinish, onSave, onCancel, okText }) {
    super()

    Object.assign(this, {
      okText: okText || 'Save'
    })

    if (onSave) { this._onSave = onSave }
    if (onCancel) { this._onCancel = onCancel }
    if (onFinish) { this._onFinish = onFinish}
  }

  /**
   * callbackArgs are passed to `onSave`, `onCancel` and `onFinish`.
   */
  get callbackArgs () : any[] { return [] }

  async saveClick () {
    await this.onSave(...this.callbackArgs)
    await this.onFinish(...this.callbackArgs)
  }

  async cancelClick () {
    await this.onCancel(...this.callbackArgs)
    await this.onFinish(...this.callbackArgs)
  }

  /**
   * @return {JSX|Array.<JSX>}
   */
  abstract get questionHTML () : any

  get buttonsHTML () {
    const { jss } = this
    return (
      <div class={jss.buttons}>
        <div class={jss.cancelButton} ko-click={() => this.cancelClick()}>
          Cancel
        </div>
        <async-button faceClass={jss.saveButton} action={() => this.saveClick()}>
          <template slot='face'>
            {this.okText}
          </template>
        </async-button>
      </div>
    )
  }

  static get buttonCSS () {
    return {
      buttons: {
        gridColumn: '1/-1',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10px'
      },
      saveButton: {
        ...styles.buttons.modalOk,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '90px',
        color: 'white',
        backgroundColor: color.onPrimary
      },
      cancelButton: {
        ...styles.buttons.modalCancel,
        minWidth: '90px',
        //border: `1px solid ${color.onPrimary}`,
        backgroundColor: 'white'
      }
    }
  }

  onLayoutClick (evt: Event) : void {}

  /**    ----    TEMPLATE   ----   **/
  get template () {
    const { jss } = this
    return (
      <div class={jss.layout}
        ko-click={evt => this.onLayoutClick(evt)}>
        {this.questionHTML}
        {this.buttonsHTML}
      </div>
    )
  }

  static get templateCSS () {
    return {
      layout: {
        display: 'grid',
        maxWidth: '900px',
        margin: 'auto',
        padding: '10px',
        grid: {
          templateColumns: '1fr',
          gap: '15px 30px'
        },
        '&:first-child': {
          paddingTop: '0px'
        }
      },
      question: {
        justifySelf: 'end',
        alignSelf: 'start',
        fontWeight: 'bold'
      },
      field: {
        maxWidth: '500px'
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.templateCSS,
      ...this.questionTypesCSS,
      ...this.buttonCSS
    }
  }

  /**   ----    QUESTION    ----    **/

  /**
   * The questionHTML should return an array of JSX/HTML nodes, which may be
   * created by `makeQuestion`
   * @return {Array.<JSX>}
   */
  makeQuestion (title, questionJsx) {
    const { jss } = this
    return [
      <div class={jss.question}>
        {title}
      </div>,
      <div class={jss.field}>
        {questionJsx}
      </div>
    ]
  }

  /** ----------  Specific, re-usable question types ----------- */

  static get questionTypesCSS () {
    return {
      ...this.inputCSS,
      ...this.boolCSS
    }
  }

  boolInputQuestion (title, bool) {
    const { jss } = this
    const cssClass = this.computed(
      () => bool() ? jss.boolButtonYes : jss.boolButtonNo)
    return [
      <div class={jss.question}>{title}</div>,
      <div class={jss.field}>
        <button type='text' class={cssClass}
          ko-click={() => bool.modify(v => !v)}>
          {icons.inline(checkIcon)}
        </button>
      </div>
    ]
  }

  static get boolCSS () {
    return {
      _boolButton: {
        fontSize: '1rem',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        outline: 'none',
        backgroundColor: color.textInput.light.primary,
        border: 0,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
        },
        '&:active': {
          outline: 'none',
          boxShadow: 'inset 0 1px 2px 1px rgba(0,0,0,0.4)'
        }
      },
      boolButtonYes: {
        extend: '_boolButton',
        '--icon-color': 'green'
      },
      boolButtonNo: {
        extend: '_boolButton',
        '& svg': { opacity: 0 }
      }
    }
  }

  textInputQuestion (title, observable, css = this.jss.input) {
    return this.makeQuestion(title,
      <input type='text' class={css} ko-textInput={observable} />
    )
  }

  static get inputCSS () {
    return {
      textInput: {
        ...styles.input.text
      }
    }
  }

  tagsEditorQuestion (title, tags, index) {
    const { jss } = this
    return this.makeQuestion(title,
      <tags-editor-modal class={jss.editArea} tags={tags} index={index} />
    )
  }

  selectQuestion (title, observable, options, css = this.jss.input) {
    return this.makeQuestion(title,
      <select class={css} ko-value={observable}>
        {options}
      </select>
    )
  }
}
