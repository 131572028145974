import { typography, input } from 'styles'

import EisPrimitiveComponent from './EisPrimitiveComponent'

export default class EisSelect extends EisPrimitiveComponent {
  options: string[]

  constructor (params) {
    super(params)
    const { options } = params
    Object.assign(this, { options: ['', ...options] })
  }

  static get css () {
    return {
      ...super.css,
      displayClass: {
        ...super.css.textContent,
      },
      select: {
        ...input.all,
        ...typography.face.text,
        fontSize: '1.5rem',
        color: '#333333',
        width: '300px',
        height: '40px',
        border: 'none'
      },
      option: {}
    }
  }
  get displayHTML () {
    const focused = this.computed(() => this.focused() || undefined)
    return (
      <div class={this.jss.displayClass} focused={focused}
        blank={this.computed(() => this.componentValue() ? undefined : 'true')}>
        {this.displayValue}
      </div>
    )
  }

  optionHTML (o) {
    return (<option class={this.jss.option}>{o}</option>)
  }

  get editHTML () {
    return (
      <select class={this.jss.select}
        ko-value={this.inputValue}
        ko-hasFocus={this.computed(() => this.editing())}>
        {this.options.map(o => this.optionHTML(o))}
      </select>
    )
  }
}

EisSelect.register()
