/**
 * Users permissions editor
 */
import { isEqual } from 'lodash-es'

import Editor from 'Editor'
import { PossiblePermissions } from 'Permissions'

import icons from 'icons'
import plusIcon from 'icons/light/plus'
import yesIcon from 'icons/solid/check-circle'
import noIcon from 'icons/light/circle'
import removeButton from 'icons/light/times'

import { buttons, typography } from 'styles'

/**
 * <users-perm-editor> modifies the filter that grants permissions to
 * users that match.
 *
 * The `perms` property saved to a Filter looks like this:
 *
 *    filter.perms = [
 *       filterKey, allow: 'permChars'
 *    ]
 */
class UsersPermEditor extends Editor {
  /**
   * The teamFilterModel is a filter of users (i.e. a "team") to which a
   * set of permissions to access the system apply.
   * @param {object} param0
   * @param {firestore.DocumentSnapshot} param0.teamFilterModel
   * @param {Array.<firestore.DocumentSnapshot>} param0.entityFilters
   */
  constructor (params, ...args) {
    const { teamFilterModel, entityFilters, permissionChange, teamFilters } = params
    super(params, ...args)
    Object.assign(this, {
      teamFilters,
      teamFilterModel,
      permissionChange,
      entityFilters,
      permsBeforeChange: { ...teamFilterModel.perms() },
      newEntityFilterKey: ko.observable('')
    })

    this.unselectedEntities = this.computed(() =>
      this.computeUnselectedEntities())
  }

  /**
   * @return {Array} of permissions
   */
  get teamPerms () {
    return this.teamFilterModel.perms() || []
  }

  /**
   * @return {Array.<FilterModel>} for Entities
   */
  computeUnselectedEntities () {
    const perms = this.teamPerms
    return [
      { id: '', title: 'Select an entity filter' },
      ...this.entityFilters()
        .filter(f => !perms.find(p => p.filterKey === f.id()))
    ]
  }

  static get possiblePermissions () { return PossiblePermissions }
  get possiblePermissions () { return PossiblePermissions }

  async updateAffectedModels () {
    const permsBefore = this.permsBeforeChange
    const permsNow = this.teamPerms
    const unchanged = isEqual(permsNow, permsBefore)
    if (unchanged) { return }
    const accountID = this.teamFilterModel.accountID()
    const teamFilterKey = this.teamFilterModel.id()
    await this.permissionChange.start(accountID, teamFilterKey, permsNow)
    this.permsBeforeChange = permsNow
  }

  /**
   * Compare all the user teamFilterKeys to make sure they are current,
   * and update if they are not.
   *
   * TODO: Use a firestore batch.
   */
  async updateUsersTeams () {
    const users = [...window.app.memoryDB.getListOf('user')]
    const futures = []
    for (const user of users) {
      const currentKeys = user.teamFilterKeys()
      const newKeys = user.matchingTeamFilterKeys(this.teamFilters)
      if (isEqual(currentKeys, newKeys)) { continue }
      user.teamFilterKeys(newKeys)
      futures.push(user.vmSave())
    }
    await Promise.all(futures)
  }

  async saveClick () {
    const isTeam = Boolean(this.teamPerms.length)
    this.teamFilterModel.isTeam(isTeam)
    console.log(`<users-perm-editor> Saving `)
    // TODO Save the filter model, but note that it's not applied to all
    await this.updateUsersTeams()
    await this.updateAffectedModels()
    // TODO Save the filter model with the state indicating that all models
    //    have been updated.
    await this.teamFilterModel.vmSave()
    await super.saveClick()
  }

  get css () {
    const permCount = this.possiblePermissions.length
    return {
      ...super.css,
      permGrid: {
        display: 'grid',
        gridTemplateColumns: `auto repeat(${permCount}, 1fr) auto`
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      permHead: {
        fontWeight: 'bold',
        padding: '5px 10px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        textAlign: 'center',
        fontSize: '0.7em',
        borderTop: '1px solid rgba(0,0,0,0.1)',
      },
      permEntityFilterTitle: {
        padding: '5px',
        gridColumn: '1/2',
        fontSize: '0.7em',
        marginLeft: 5,
        borderRight: '1px solid rgba(0,0,0,0.1)'
      },
      permItem: {
        ...buttons.clickable,
        padding: '5px',
        textAlign: 'center'
      },
      entityFilterSelect: {
        gridColumn: '1/-4',
        height: '38px',
        border: '1px solid rgba(0,0,0,0.1)',
        '&:active, &:focus': {
          outline: 'none'
        }
      },
      entityFilterAddButton: {
        ...buttons.clickable,
        textAlign: 'center',
        gridColumn: '-4/-1',
        background: 'rgba(0,0,0,0.05)',
        borderRadius: 4,
        marginLeft: 5,
        padding: '10px 3px',
        fontSize: '0.8em',
        '&:hover': {
          background: 'rgba(0,0,0,0.1)'
        }
      },
      code: {
        fontFamily: typography.mono,
        padding: '4px 5px',
        whiteSpace: 'normal',
        backgroundColor: '#eff0f1'
      },
      createFilter: {
        marginRight: '5px'
      },
      description: {
        padding: 10,
        gridRow: '1/2',
        fontSize: '0.7em',
        margin: '5px 0 0px 0',
        background: 'white',
        borderTop: '3px solid #ffd502',
        gridColumn: '1/-1',
        borderRadius: 2,
        boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.05)',
        '&:hover': {
          boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1)'
        }
      },
      stepNumber: {
        gridColumn: '1/-1',
        margin: '20px 0px 6px 0px',
        background: 'rgba(0,0,0,0.05)',
        padding: '5px 12px',
        width: 'fit-content',
        borderRadius: 11,
        fontWeight: 600,
        fontSize: '0.8em',
      },
      removeButton: {
        ...buttons.clickable,
        padding: '8px',
        textAlign: 'center',
        fill: 'rgba(0,0,0,0.2)',
        '&:hover': {
          fill: 'rgba(0,0,0,1)'
        }
      }
    }
  }

  get permHeadHTML () {
    const { jss } = this
    return [
      <div class={jss.permHead}>Entity Filter Title</div>,
      ...this.possiblePermissions.map(ph =>
        <div class={jss.permHead}>{ph.name}</div>
      )
    ]
  }

  /**
   * @param {object} p
   * @param {string} p.allow list of 1-char permissions ('r', 'w', ...)
   * @param {string} p.filterKey for the entity filter
   */
  permHTML (p) {
    const { jss } = this
    const entityFilter = this.entityFilters()
      .find(f => f.id() === p.filterKey)
    const title = entityFilter ? entityFilter.title() : '[DELETED]'
    return [
      <div class={jss.permEntityFilterTitle}>{title}</div>,
      ...this.possiblePermissions.map(perm =>
        <div class={jss.permItem} ko-click={() => this.permissionClick(p, perm)}>
          {p.allow.includes(perm.char)
            ? icons.inline(yesIcon) : icons.inline(noIcon)}
        </div>
      ),
      <div class={jss.removeButton}
        ko-click={() => this.teamFilterModel.perms.remove(p)}>
        {icons.inline(removeButton)}
      </div>
    ]
  }

  permissionClick (filter, perm) {
    const { char } = perm
    const { allow } = filter
    filter.allow = allow.includes(char) ? allow.replace(char, '') : allow + char

    // Trigger refresh.
    this.teamFilterModel.perms.valueHasMutated()
  }

  get permListHTML () {
    return this.computed(() => this.teamPerms.map(f => this.permHTML(f)))
  }

  /**
   * @param {firebase.firestore.DocumentSnapshot} entity
   * Note that 'entity.*' is a synthetic filter, corresponding to `Every entity`
   */
  entityOptionHTML (entityFilterModel) {
    const id = ko.unwrap(entityFilterModel.id)
    const currentId = this.newEntityFilterKey()
    const selected = currentId === id ? 'selected' : undefined
    const hidden = !id || undefined
    return (
      <option value={id}
        selected={selected}
        hidden={hidden}>
        {entityFilterModel.title}
      </option>
    )
  }

  get filterSelectHTML () {
    const { jss } = this
    const options = this.computed(() => this.unselectedEntities()
      .map(e => this.entityOptionHTML(e)))

    return (
      <select class={jss.entityFilterSelect}
        ko-value={this.newEntityFilterKey}>
        {options}
      </select>
    )
  }

  async selectEntityFilterHTML () {
    const { jss } = this
    await this.entityFilters.when(ef => ef.length > 0)
    return [
      <div class={jss.description}>
        <p><strong>Setting Permissions for a group of users is very easy.</strong></p>
        <p><strong>Step 1)</strong> Select the entities you want to apply permissions to by choosing an <span class={jss.code}>Entity Filter</span> from the list below. If you have not created any Entity Filters simply return to the <span class={jss.code}><a href="/#entities">List View</a></span> and create a filter that contains the entities that you want to set permissions for. Return here and select the Filter below in <strong>Step 1</strong> and press the <span class={jss.code}>Set Permissions for this Filter</span> button.</p>
        <p><strong>Step 2)</strong> Pick the permissions you want to grant to the group of users. For example, to make a group of entities <strong>read only</strong> (so they cannot be edited or deleted), ensure that only the <strong>Read</strong> permission is selected.</p>
      </div>,
      <div class={jss.stepNumber}>
        Step 1: Choose from an existing Entity Filter.
      </div>,
      this.filterSelectHTML,
      <div class={jss.entityFilterAddButton}
        ko-click={evt => this.addEntityFilterClick()}>
        <span class={jss.createFilter}>Set Permissions for this Filter</span>
        {icons.inline(plusIcon)}
      </div>,
      <div class={jss.stepNumber}>
        Step 2: Choose permissions for the entities.
      </div>
    ]
  }

  addEntityFilterClick () {
    const { perms } = this.teamFilterModel
    const filterKey = this.newEntityFilterKey()
    if (!filterKey || perms().find(p => p.filterKey === filterKey)) {
      return
    }
    this.newEntityFilterKey('')
    perms.push({ filterKey, allow: '' })
  }

  get questionHTML () {
    const { jss } = this
    return (
      <div class={jss.permGrid}>
        {this.selectEntityFilterHTML()}
        {this.permHeadHTML}
        {this.permListHTML}
      </div>
    )
  }
}

UsersPermEditor.register()
