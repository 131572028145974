
import PageNumberMarker from './PageNumberMarker'

/**
 * A section is a contiguous range of pages under the section name.
 */
export default class Section extends PageNumberMarker {
  /**
   * Indicate the pages of a section in the book.
   */
  constructor ({ startPage, name, editing }) {
    super(startPage)
    Object.assign(this, {
      name: ko.observable(name),
      editing: ko.observable(editing)
    })
  }

  toJS () { return { startPage: this.startPage(), name: this.name() } }
  startPage () { return this.pageNumber() }
}
