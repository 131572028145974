
import Serializable from 'Serializable'

let _mostRecentBlock: Block = null
export type FocusPosition = { atEnd: false | true }
type SlotManager = import('../variables/slots').SlotManager

export abstract class Block extends Serializable {
  private focusDemand: KnockoutObservable<FocusPosition> = ko.observable(null)
  public isSelected = ko.observable<boolean>(false)
  public orderIndex = ko.observable<number>(null)
  asText?: string

  get arrayObservables () { return [] }
  get propertiesToSerialize () { return [] }
  get plainObservables () { return ['orderIndex'] }
  get encoded (): [string, any] { return [this.code, this.toJS()] }

  /**
   * Focus the given edit field, default at the start,
   * unless `atEnd` is truthy.
   */
  setFocus (atEnd = false) {
    this.focusDemand({ atEnd })
    return this.hasFocused
  }

  get wantsFocus () { return this.focusDemand() }
  onFocus (focused: boolean) { if (focused) { _mostRecentBlock = this } }
  hasFocused () { return this.focusDemand.when(null).then(() => true) }
  isFocused () { this.focusDemand(null) }

  get collapseToParagraphOnEnterWhenEmpty () { return false }
  get isParagraph () { return false }
  abstract get code (): string
  abstract get isBlank (): boolean
  abstract makeNextBlock (contents: string) : Block
  abstract async asMarkdown (blocks: Block[], sm: SlotManager): Promise<string>

  /**
   * setVariables is called to push variables from the current slot manager
   * to a precedent being inserted.
   */
  abstract setVariables (sm: SlotManager)

  abstract get isFocusable(): boolean
}

// From: https://stackoverflow.com/a/38642922
export interface BlockConstructor {
  new (params): Block
}

export const BLOCK_SYM = Symbol('Block instance')

/**
 * Return the most recently selected block.
 */
export function getBlockInstanceForSelection (): Block {
  return _mostRecentBlock
}

export default Block
