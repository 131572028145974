
import { enumerate } from 'utils/iterable'

import { border, color, typography } from 'styles'

import eisCSS from '../eisCss'

import EisSingleComponentField from '../EisSingleComponentField'

export default abstract class EisTable extends EisSingleComponentField {
  get columnCount () { return this.columnTitles.length }
  get gridTemplateColumns () {
    return [...Array(this.columnCount)].map(() => '1fr').join(' ')
  }
  abstract get columnTitles () : string[]
  abstract get listAddName () : string

  static get css () {
    return {
      ...super.css,
      grid: {
        ...eisCSS.fieldContainer,
        display: 'grid',
        minWidth: '600px',

        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.primary
        },
        borderRadius: '4px',
        padding: '20px',
        marginBottom: '40px',
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.3)',
        gridGap: '0px 20px',
        '&[focused]': {
          opacity: '1',
          transition: 'opacity 100ms',
        },
        '&[blank=true]': {
          padding: '20px 20px 0px 20px',
          marginBottom: '-10px'
        }
      },
      gridCaption: {
        gridColumn: '1 / -1',
        fontSize: '0.7rem',
        fontStyle: 'italic',
        fontFamily: typography.bodyFontFamily,
        fontWeight: 400,
        textTransform: 'none',
        letterSpacing: 0.6,
        marginBottom: 20,
        color: 'inherit',
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
          color: 'color.text.dark.secondary'
        },
        '--icon-font-size': '1rem'
      },
      gridHeadItem: {
        fontWeight: 'bold',
        fontSize: '0.8em',
        fontFamily: typography.bodyFontFamily,
        // marginTop: '-45px',
        whiteSpace: 'nowrap',
        '[blank] &': { display: 'none' },
      },
      gridBodyItem: {
        backgroundColor: 'inherit',
        'body[dark] &': { // project batman
          backgroundColor: 'inherit',
        },

        padding: '10px 0px',
        fontFamily: typography.bodyFontFamily,
        fontSize: '0.8em'
      },
      gridBorder: {
        ...border.dottedBottom,
        gridColumn: '1/-1',
        height: '1px',
        marginBottom: '1px'
      },
      gridFoot: {
        gridColumn: '1/-1',
        position: 'relative',
      },
      addButton: {
        ...super.css.changeButton,
        position: 'absolute',
        right: '0px',
        top: '40px',

        '$grid[blank=true] &': {
          top: '-36px',
        },

        '[eis-field][read-only] &': {
          display: 'none',
        }
      }
    }
  }

  gridCaptionHTML () {
    const caption = this.caption
    return caption
      ? <div class={this.jss.gridCaption}>{caption}{this.tooltipHTML}</div>
      : undefined
  }

  gridHeadItem (t) {
    return <div class={this.jss.gridHeadItem}>{t}</div>
  }

  gridHeadHTML () {
    return this.columnTitles.map(t => this.gridHeadItem(t))
  }

  * genBodyHTML () {
    const { jss } = this
    let first = true
    for (const [i, row] of enumerate(this.rows)) {
      if (!first) { yield (<div class={jss.gridBorder} />) }
      for (const cell of row) {
        yield (
          <div row={i} class={jss.gridBodyItem}>
            {cell}
          </div>
        )
      }
      first = false
    }
  }

  gridBodyHTML () {
    return this.computed(() => [...this.genBodyHTML()])
  }

  gridFootHTML () {
    const { jss } = this
    if (!this.listAddName) { return }
    return (
      <div class={jss.gridFoot}>
        <div class={jss.addButton} ko-click={evt => this.addClick(evt)}>
          Add
        </div>
      </div>
    )
  }

  makeScrollTo (element) {
    return this.computed(() => {
      if (!this.focused() || !element()) { return null }
      const topOffset = 90
      const box = element().getBoundingClientRect()
      if ((box.top - topOffset) < 0) {
        return { block: 'start', offset: '-90' }
      } else if (box.bottom > window.innerHeight) {
        return { block: 'end', offset: '30' }
      } else {
        return null
      }
    })
  }

  gridClick (target: EventTarget) {}

  get template () {
    const { jss } = this
    const gridStyle = this.computed(
      () => `grid-template-columns: ${this.gridTemplateColumns}`)
    const isBlank = this.computed(() => this.isBlank() || undefined)
    const element = ko.observable(null)
    const focused = this.computed(() => this.focused() || undefined)
    return (
      <div class={jss.grid}
        style={gridStyle}
        blank={isBlank}
        ko-set-node={element}
        ko-scrollTo={this.makeScrollTo(element)}
        ko-click={(evt: MouseEvent) => this.gridClick(evt.target)}
        focused={focused}
        eis-field
        read-only={this.entity.isProjection}>
        {this.gridCaptionHTML()}
        {this.gridHeadHTML()}
        {this.gridBodyHTML()}
        {this.gridFootHTML()}
      </div>
    )
  }

  abstract get rows () : any[][]
}
