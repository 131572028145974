
import { formatForUser } from 'utils/dates'
import { unique }  from 'utils/iterable'
import { roleDisplayTitle } from 'person/utils'

import { nameOf } from 'jurisdictions/lookup'
import { precedentListColumns } from 'writing/precedents/precedentListColumns'
import { writingListColumns } from 'MinuteBookPanelProvider/main-view/writingListColumns'

import { Column, SelectColumn, SharingRecipients } from './Column'
import CommonModelColumns from './CommonModelColumns'
import {
  InviteSentColumn, InviteAcceptedColumn, TeamsColumn, OTPAuthColumn,
} from './UserListColumns'
import {
  FiscalYearEndColumn, PageCountColumn, SectionCountColumn, NotesColumn,
  ShareAssetColumn, ShareholderColumn,
} from './EntityListColumns'


function arrayTableGetter (getter) {
  return { getter: row => getter(row).map(v => ({ elvValue:v })) }
}

export function personListColumns (panelProvider) : Column[] {
  /**
   * This function is called from the `<model-list>` *or* from the
   * `Condition` class; in the latter case it doesn't have a panelProvider.
   */
  const selectColumn = panelProvider
    ? [new SelectColumn(panelProvider.selected, panelProvider)] : []
  return [
    ...selectColumn,
    Column.create('table', 'Name', 'name', arrayTableGetter(row => row.name)),

    Column.create('string', 'Type', 'type', {
      getter: (row:PersonRecord) => row.type,
      renderValue: (row, jss) => (
        <div class={jss.typeCell} value={row.type.toLowerCase()}>{row.type}</div>
      ),
    }),

    Column.create('table', 'Email', 'email', arrayTableGetter(row => row.email)),
    Column.create('table', 'Phone', 'phone', arrayTableGetter(row => row.phone)),

    Column.create('table', 'Address', 'address', {
      getter: (row:PersonRecord) => (
        row.address.map(addr => addr.fullAddressAsString)
          .map(v => ({ elvValue:v }))
      ),
    }),

    Column.create('table', 'Roles', 'roles', {
      getter: (row:PersonRecord) => {
        const roles = row.origin
          .flatMap(origin => (
            [...origin.roles()]
              .map(r => roleDisplayTitle(r))))
        return (
          [...unique(roles)]
            .map(role => ({ elvValue: role })))
      }
    }),

    Column.create('table', 'Affiliations', 'affiliations', {
      getter: (row:PersonRecord) => (
        row.origin.flatMap(origin => {
          return [...origin.roles()].map(role => (
            { elvValue: `${roleDisplayTitle(role)} - ${origin.model.cvModelTitle}` }
          ))
        })
       )
    }),

    Column.create('table', 'Entities', 'entities', {
      getter: (row: PersonRecord) =>
        [...new Set(row.origin
          .filter(o => o.model.vmResourceName === 'entity')
          .map(o => o.model.cvModelTitle)
          )
        ].map(elvValue => ({ elvValue }))
    }),
  ]
}

/**
 * @param {EntityListPanelProvider} entityListPanelProvider [optional]
 */
export function entityListColumns (entityListPanelProvider) : Column[] {
  return [
    Column.create('string', 'Entity Name', 'legalname', { columnWidth: 220 }),
    Column.create('string', 'Entity Group', 'entity_group'),
    Column.create('string', 'Business Type', 'business_type'),
    Column.create('string', 'Client Name', 'client', {
      getter: (row) => row.componentFor('client').personName,
    }),
    Column.create('string', 'Matter Number', 'matter_number', {
      renderValue: (row, jss) => (
        <div class={jss.elvMatterNumber}>
          {row.answerFor('matter_number')}
        </div>
      ),
    }),
    Column.create('string', 'Jurisdiction', 'jurisdiction', {
      renderValue: row =>
        <jurisdiction-indicator value={row.answerFor('jurisdiction')} />,
      reportValue: row =>(nameOf(ko.unwrap(row.answerFor('jurisdiction'))) || ''),
      sortableValue: row =>
        (nameOf(ko.unwrap(row.answerFor('jurisdiction'))) || '').toLowerCase(),
      searchMatches: (row, query) =>
        (nameOf(ko.unwrap(row.answerFor('jurisdiction'))) || '').toLowerCase().includes(query)
    }),
    Column.create('string', 'Incorporation Date', 'incorporation_date', {
      renderValue: row => formatForUser(row.answerFor('incorporation_date').dateValue(), '—'),
      sortableValue: row => row.answerFor('incorporation_date').asISOStringOrNull() || '',
      reportValue: row => row.answerFor('incorporation_date').asISOStringOrNull() || ''
    }),
    new FiscalYearEndColumn('Fiscal Year End', 'fiscalyearend'),
    Column.create('string', 'Entity Type', 'entity_type', {
      getter: row => row.answerFor('entity_type'),
      renderValue: (e) => entityListPanelProvider.entityRow(e) }),
    Column.create('int', 'Days to FYE', 'computed_days_to_fye', {
      getter: row => row.answerFor('fiscalyearend').daysFromNow(),
      renderValue: (row, jss) => {
        const fye = row.answerFor('fiscalyearend')
        const html = entityListPanelProvider.computed(() => {
          const count = fye.daysFromNow()
          const countClass = count < 60
            ? jss.daysToFyeImmediate
            : count < 120 ? jss.daysToFyeSoon : jss.daysToFyeLater
          return ( <div class={countClass}>{count}</div> )
        })
        return (
          <tool-tip my='top right' at='bottom right'>
            <template slot='anchor'>
              {html}
            </template>
            <template slot='content'>
              <small>{row.answerFor('fiscalyearend').asString()}</small>
            </template>
          </tool-tip>
        )
      },
    }),
    Column.create('table', 'Responsible', 'responsible'),
    Column.create('string', 'Status', 'companyStatus', {
      renderValue: row =>
        <company-status status={row.companyStatus} />
    }),
    new PageCountColumn('Page Count', 'book'),
    new SectionCountColumn('Section Count', 'book'),
    // Column.create('idem', 'Shared With', 'shared_with',
    //   { getter:
    //     row => row.access()
    //       .map(a => `${a.to} (${a.perm})`)
    //       .join(', ')
    //   }),
    new NotesColumn('Notes', 'notes'),
    new ShareAssetColumn('Share Classes', 'capital'),
    new ShareholderColumn('Shareholders', 'capital'),
    Column.create(`table`, 'Directors', 'directors'),
    Column.create(`table`, 'Officers', 'officers'),
    Column.create(`table`, 'Incorporators', 'incorporators'),
    Column.create(`table`, 'Authorized to Instruct', 'authorized_to_instruct'),
    Column.create(`table`, 'Business Numbers', 'business_numbers'),
    Column.create(`table`, 'Services Providers', 'service_providers'),
    Column.create(`table`, 'Domain Names', 'domain_names'),
    Column.create(`table`, 'Governing Documents', 'governing_documents'),
    Column.create(`table`, 'Land Registrations', 'land_registrations'),
    Column.create(`table`, 'Extra-Jurisdictional Registrations', 'extrajurisdictional_registrations'),
    Column.create(`table`, 'Business Names', 'trade_names'),
    Column.create(`table`, 'Municipal Business Licenses', 'municipal_licenses'),
    Column.create(`table`, 'Regulatory Licenses', 'regulatory_licenses'),
    Column.create(`table`, 'Trademarks', 'trademarks'),
    Column.create(`table`, 'Patents', 'patents'),
    Column.create('sharing', 'Sharing', 'sharing'),
    new SharingRecipients(),
    ...CommonModelColumns.makeColumns(entityListPanelProvider, 'tags')
  ]
}

function adminColumn (column) {
  return window.app.defaultAuthManager.userIsAdmin() ? [column] : []
}

/**
 * @param {UserListPanelProvider} userListPanelProvider [optional]
 */
export function userListColumns (userListPanelProvider) : Column[] {
  return [
    Column.create('email', 'Email', 'email'),
    Column.create('string', 'First Name', 'firstname'),
    Column.create('string', 'Last Name', 'lastname'),
    Column.create('bool', 'Admin', 'admin'),
    Column.create('string', 'Phone', 'phone'),
    Column.create('string', 'Fax', 'fax'),
    Column.create('string', 'Office', 'office'),
    Column.create('string', 'Role', 'role'),
    new InviteSentColumn('Invite Sent'),
    new InviteAcceptedColumn('Invite Accepted'),
    new TeamsColumn('Teams'),
    ...adminColumn(new OTPAuthColumn()),
    Column.create('string', 'Status', 'statusName',
      { renderValue: row => userListPanelProvider.renderStatusName(row) }),
    ...CommonModelColumns.makeColumns(userListPanelProvider, 'tags.user'),
  ]
}

/**
 * Attach the columns to the respective PanelProvider.
 */
function lifeCycle (fn) : (v) => Column[] {
  return (panelProvider?) => {
    const columns = fn(panelProvider)
    if (panelProvider) { columns.forEach(c => c.anchorTo(panelProvider)) }
    return columns
  }
}

export const columnsByPanelID = {
  EntityList: lifeCycle(entityListColumns),
  Calendar: lifeCycle(entityListColumns),
  UserList: lifeCycle(userListColumns),
  PersonList: lifeCycle(personListColumns),
  PrecedentList: lifeCycle(precedentListColumns),
  EntityWritingList: lifeCycle(writingListColumns),
} as Record<string, (panelProvider) => Column[]>
