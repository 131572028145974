
import buttons from './buttons'
import color from './color'

/**
 * Exposed for <drop-down>
 */
export default {
  button: {
    ...buttons.clickable,
    ...buttons.clean,
    padding: '10px',
  },

  /**
   * General menu includes e.g. the top-bar help
   * and admin drop-downs.
   */
  generalMenu: {
    borderRadius: '3px',
    border: `1px solid ${color.separator.dark.nonOpaque}`,
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    'body[dark] &': { // project batman
      border: `1px solid ${color.separator.dark.nonOpaque}`,
    },
  },

  /**
   * The functional menus are "inside" the system,
   * such as hide-fields, filter-fields, etc.
   */
  functionalMenu: {
    backgroundColor: color.systemBackground.light.primary,
    border: '1px solid grey',
    borderRadius: '2px',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 1rem',
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.secondary,
      color: color.text.dark.primary
    },
  },

  item: {
    ...buttons.clickable,
    backgroundColor: color.systemBackground.light.primary,
    'body[dark] &': { // project batman
      backgroundColor: color.systemBackground.dark.primary,
      color: color.text.dark.primary
    },
    minWidth: '60px',
    padding: '6px 25px',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: color.rowHoverColor,
      'body[dark] &': { // project batman
        backgroundColor: color.dark.rowHoverColor,
      },
    },

    // '&:first-child': {
    //   borderTopLeftRadius: '6px',
    //   borderTopRightRadius: '6px',
    // },

    // '&:last-child': {
    //   borderBottomLeftRadius: '6px',
    //   borderBottomRightRadius: '6px',
    // }
  }
}
