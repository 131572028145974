import { computed, memoize } from 'utils/decorator'
import { buttons, color } from 'styles'

import { inline } from 'icons'
import signatureIcon from 'icons/solid/signature'
import deleteIcon from 'icons/light/times'

import './slot-picker'
import { ArrayComponentPerson, CapitalHolderVariable } from '../variables'
import { factoryVariableView } from '../variables/views'
import { UX_STATE } from '../variables/slots/interfaces'

import BlockEditor from './BlockEditor'

type SignaturesBlock = import('../blocks/Signatures').SignaturesBlock
type ArrayComponentSignature = import('../blocks/Signatures').ArrayComponentSignatures
type CapitalSignature = import('../blocks/Signatures').CapitalSignatures
type Variable = import('../variables').Variable
type UnitView = import('DataModel').UnitView

export abstract class BlockSignaturesEditor<S extends SignaturesBlock, V extends Variable> extends BlockEditor<S> {
  variable: V

  link (prop: string) {
    this.subscribe(this.variable[prop], v => this.block[prop](v))
    this.subscribe(this.block[prop], v => this.variable[prop](v))
  }

  static get css () {
    return {
      ...super.css,
      ...this.uxStateCSS,

      signatureArea: {
        display: 'flex',
        width: '100%',
      },

      signature: {
        display: 'flex',
        alignItems: 'center',
        background: 'var(--slot-bg-color)',
        borderBottom: '2px solid var(--slot-border-color)',
        padding: '4px',
        opacity: '1',
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',

        '&:first-child': {
          opacity: 1,
        },

        '& + $signature': {
          marginTop: '17px',
        },

        '&:hover': {
          opacity: 1,
        },
      },

      signatureIcon: {
        marginRight: 6,
        opacity: 0.8,
      },

      slotNumber: {
        ...buttons.clickable,
        cursor: 'pointer',
        outline: 'none',
        userSelect: 'none',
        padding: '9px',
        textDecoration: 'unset',
        textTransform: 'uppercase',
        fontWeight: 600,
        marginRight: 6,
        color: color.text.light.secondary,
        backgroundColor: color.fill.light.primary,
        transition: 'background-color 120ms ease-in-out, color 120ms ease-in',
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
          backgroundColor: color.fill.dark.primary,
        },
        '&:hover':{
          backgroundColor: color.fill.light.quarternary,
          color: color.text.light.primary,
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.quarternary,
            color: color.text.dark.primary,
          },
        },
      },

      signatureMissingText: {
        fontStyle: 'italic',
        userSelect: 'none',
        cursor: 'default'
      },

      signatureComponentTitle: {
        textTransform: 'capitalize',
        background: color.fill.light.primary,
        color: color.text.light.secondary,
        padding: 4,
        borderRadius: 4,
        margin: '0px 4px',
        fontSize: '85%',
        fontWeight: 600,
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
          color: color.text.dark.secondary,
        },
      },

      entityTitle: {
        /**
         * Add a space before the entity title.
         */
        '&::before': { content: '" "' },
      },

      noSignature: {
        extend: 'signature',
      },

      deleteButton: {
        ...buttons.clickable,
        marginTop: '20px',
        padding: '0px 10px',
        visibility: 'hidden',
        '$signatureArea:hover &': {
          visibility: 'visible',
        },
      },
    }
  }

  static get uxStateCSS () {
    return {
      signatures: {
        marginLeft: '38.19%',
        marginTop: '17px',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        alignItems: 'baseline',
        border: '1px solid transparent',
        width: '100%',
      },

      uxDormant: {
      },

      uxHover: {
        filter: 'brightness(1.1)',
        transition: 'border 250ms ease-in-out, box-shadow 250ms ease-in-out',
        border: `1px solid var(--slot-border-color)`,
        boxShadow: '1px 5px 10px 3px rgba(0,0,0,0.2)',
      },

      /**
       * @daniel this style is applied to all variables in the same slot as
       * the variable currently under the mouse cursor.
       */
      uxPeerHover: {
        filter: 'brightness(1.1)',
        transition: 'border 250ms ease-in-out, box-shadow 250ms ease-in-out',
        // border: `1px solid var(--slot-border-color)`,
        boxShadow: '1px 5px 10px 3px rgba(0,0,0,0.2)',
      },

      /**
       * Not yet implemented
       */
      uxSelected: {
        boxShadow: '0px 3px 22px 9px rgba(0,0,0,0.13)',
        transition: 'box-shadow 150ms ease-in',
      },

      uxPeerSelected: {
      },
    }
  }

  get stateClass () {
    const { jss } = this
    const state = this.variable.uxState()
    const classes = [this.jss.signatures]
    if (!state) { classes.push(jss.uxDormant) }
    if (state & UX_STATE.hover) { classes.push(jss.uxHover) }
    if (state & UX_STATE.peerHover) { classes.push(jss.uxPeerHover) }
    if (state & UX_STATE.selected) { classes.push(jss.uxSelected) }
    if (state & UX_STATE.peerSelected) { classes.push(jss.uxPeerSelected) }
    return classes.join(' ')
  }

  private get slotHTML () {
    const { jss } = this
    return (
      <div class={jss.slotNumber}>
        {this.computed(() => this.variable.slotIndexText)}
      </div>
    )
  }

  private get componentTitle () {
    const cp = this.variable.componentProperties
    return cp ? cp.title : 'individuals'
  }

  private get noModelHTML () {
    const { jss } = this
    return (
      <div class={jss.signature}>
        {this.slotHTML}
        <div class={jss.signatureIcon}> {inline(signatureIcon)} </div>
        <div class={jss.signatureMissingText}>
          This is a signatures block that
          <span class={jss.signatureComponentTitle}>
            {this.componentTitle}
          </span>
          will sign.
        </div>
      </div>
    )
  }

  private get noSignatureHTML () {
    const { jss } = this
    return (
      <div class={jss.signature}>
        {this.slotHTML}
        <div class={jss.signatureIcon}> {inline(signatureIcon)} </div>
        <div class={jss.signatureMissingText}>
          There are no
          <span class={jss.signatureComponentTitle}>
            {this.componentTitle}
          </span>
          signatures for
          <span class={jss.entityTitle}>
            {this.variable.model.cvModelTitle}
          </span>
        </div>
      </div>
    )
  }

  @computed()
  signaturesListHTML () {
    const v = this.variable
    if (!this.variable.model) { return this.noModelHTML }
    if (!v.isCohate) { return this.noSignatureHTML }
    return this.signaturesToShowHTML(v)
  }

  abstract signaturesToShowHTML (v: V): JSX

  protected signatureHTML (name: string) {
    const { jss } = this
    return (
      <div class={jss.signature}>
        {this.slotHTML}
        <div class={jss.signatureIcon}>
          {inline(signatureIcon)}
        </div>
        {name}
      </div>
    )
  }

  @memoize()
  get rightView () { return factoryVariableView(this.variable) }

  @computed()
  style () {
    const v = this.variable
    return [
      `--slot-bg-color: ${v.color.bg}`,
      `--slot-border-color: ${v.color.border}`,
    ].join(';')
  }

  private openRightViewClick () {
    this.editor.rightView(this.rightView)
  }

  onMouseOver () { return this.variable.onHoverChange(false) }
  onMouseOut () { return this.variable.onHoverChange(true) }

  get internalHTML () {
    const { jss } = this
    const sigCss = this.computed(() => this.stateClass)

    return (
      <div class={jss.signatureArea} style={this.style}
        ko-self={e => this.variable.onBindingToDOM(e)}
        ko-event={{
          mouseover: (_, evt) => this.onMouseOver(),
          mouseout: (_, evt) => this.onMouseOut(),
        }}>
        <div class={sigCss}
          ko-ownClick={() => this.openRightViewClick()}>
          {this.signaturesListHTML}
        </div>
        <div class={jss.deleteButton}
          ko-ownClick={() => this.onDeleteClick()}>
          {inline(deleteIcon)}
        </div>
      </div>
    )
  }

  onDeleteClick () { this.blocks.remove(this.block) }

  disconnectedCallback (/* node: HTMLElement */) {
    this.variable.disconnectFromDOM(this.variable.boundToElement)
  }
}

export class BlockArrayComponentSignaturesEditor extends BlockSignaturesEditor<ArrayComponentSignature, ArrayComponentPerson> {
  variable = new ArrayComponentPerson(this.slotManager)

  constructor (params) {
    super(params)

    this.variable.personProperty('name')
    this.variable.assignVariables({
      personProperty: 'name',
      ...this.block.toJS(),
    })

    this.link('modelKey')
    this.link('component')
    this.link('slotIndex')
    this.link('property')
    this.link('index')

    this.subscribe(this.variable.slotIndex, si => {
      this.variable.setSlotByIndex(si)
    })
  }

  signaturesToShowHTML (v = this.variable) {
    return v.index() === '*'
      ? [...v].map(name => this.signatureHTML(name))
      : this.signatureHTML(v.value())
  }
}

/**
 * BlockCapitalSignaturesEditor
 */
export class BlockCapitalSignaturesEditor extends BlockSignaturesEditor<CapitalSignature, CapitalHolderVariable> {
  variable = new CapitalHolderVariable(this.slotManager)

  constructor (params) {
    super(params)
    this.variable.holderProperty('name')
    this.variable.assignVariables({
      holderProperty: 'name',
      ...this.block.toJS(),
      component: 'capital',
    })

    this.link('modelKey')
    this.link('component')
    this.link('slotIndex')
    this.link('assetClassID')
    this.link('idOrName')

    this.subscribe(this.variable.slotIndex, si => {
      this.variable.setSlotByIndex(si)
    })
  }

  signaturesToShowHTML (v = this.variable) {
    return v.idOrName() === '*'
      ? [...v].map(name => this.signatureHTML(name))
      : this.signatureHTML(v.holder.idOrName)
  }
}


BlockArrayComponentSignaturesEditor.register()
BlockCapitalSignaturesEditor.register()
