
import StableStringify from 'fast-json-stable-stringify'

import { blobToArrayBuffer } from './blob'
import './jwk'

const crypto = window.crypto
const subtle = crypto.subtle

export type Digestable = Uint8Array | ArrayBuffer | Blob | File | string | object

async function hexDigest (param: Digestable, alg : string) : Promise<string> {
  if (param instanceof Blob) {
    param = await blobToArrayBuffer(param)
  }
  if (typeof param === 'object' && param.constructor.name === 'Object') {
    param = StableStringify(param)
  }
  if (typeof param === 'string') {
    param = new TextEncoder().encode(param)
  }
  return bufferToHex(await crypto.subtle.digest(alg, param))
}

/**
 * @param {object | string | ArrayBuffer} param
 * @return {string} hex digest
 */
export async function sha512digest (param: Digestable) : Promise<string> {
  return hexDigest(param, 'SHA-512')
}

export async function sha256digest (param: Digestable) : Promise<string> {
  return hexDigest(param, 'SHA-256')
}

/**
 * Convert to a canonical hex-digest
 * @param {ArrayBuffer} buffer
 *
 * From https://stackoverflow.com/questions/40031688
 */
export function bufferToHex (buffer: ArrayBuffer) : string {
  return [...new Uint8Array(buffer)]
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')
}



/**
 * The SHA-25 hex digest of a Blob (or File).
 * DEPRECATED — Use `shaXXXdigest`
 * @param {Blob} blob
 */
export async function fileSha512HexDigest (blob: Blob | File) : Promise<string> {
  try {
    const blobBuffer = await blobToArrayBuffer(blob)
    const digestBuffer = await subtle.digest('SHA-512', blobBuffer)
    return bufferToHex(digestBuffer)
  } catch (e) {
    throw e
  }
}
