
import ViewComponent from 'ViewComponent'
import { hidePopovers, aPopoverIsShowing } from 'pop-over'
import { firstInputElement, nextTabbable, prevTabbable } from 'utils/dom'

import { color, typography } from 'styles'

import KeyboardShortcutManager from 'PanelProvider/KeyboardShortcutManager'

/**
 * Usage:
 *
 *    <modal-dialog modalTitle='some heading'>
 *      <template slot='content'>...</template>
 *    </modal-dialog>
 *
 */
export default class ModalDialog extends ViewComponent {
  title: string
  contentClass: string
  active: KnockoutObservable<boolean>
  shortcutManager: KeyboardShortcutManager
  _node: HTMLElement

  constructor (params?) {
    const { title, modalTitle, onDismissal, contentClass } = params || {} as any
    super()

    hidePopovers()

    Object.assign(this, {
      onDismissal,
      contentClass,
      title: title || modalTitle || this.modalTitle,
      active: ko.observable(false),
      shortcutManager: new KeyboardShortcutManager()
    })

    for (const args of this.keyboardShortcuts) {
      this.shortcutManager.add(args)
    }
  }

  get modalTitle () : string { return '' }

  get keyboardShortcuts () {
    return [
      { key: 'Escape', action: () => aPopoverIsShowing() || this.dismiss() },
      { key: 'Tab', action: () => this.onTab(nextTabbable) },
      { key: 'Tab', action: () => this.onTab(prevTabbable), modifiers: ['shift'] },
      { key: 'ArrowDown', action: () => this.onTab(nextTabbable) },
      { key: 'ArrowUp', action: () => this.onTab(prevTabbable) },
    ]
  }

  onTab (iterator) {
    const { _node } = this
    if (!_node.contains(document.activeElement)) {
      firstInputElement(_node).focus()
    } else {
      const next = iterator(_node) || firstInputElement(_node)
      next.focus()
    }
  }

  /**
   * Cancel is when the user commits an action indicating they want to
   * close the modal without saving changes e.g. hitting `esc` or
   * clicking the backdrop.
   *
   * In subclasses it might not be possible to dismiss a modal.
   */
  dismiss () {
    this.close()
    this.onDismissal && this.onDismissal()
  }

  close () {
    window.app.modal(undefined)
  }

  static get css () {
    return {
      _shade: {
        background: 'white',
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 50,
        backgroundColor: 'black',
        transform: 'translate3d(0, 0, 0)',
        transition: '0.5s ease-in-out' // 🎬
      },
      inactiveShade: {
        extend: '_shade',
        opacity: 0, // 🎬
      },
      activeShade: {
        extend: '_shade',
        opacity: 0.3, // 🎬
      },
      _container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inactiveContainer: {
        extend: '_container',
        // 🎬
        transition: 'opacity 0.2s linear, transform 0.4s ease-in-out',
        opacity: 0,
        transform: 'translate3d(0, -3%, 0)',
      },
      activeContainer: {
        extend: '_container',
        // 🎬
        transition: 'opacity 0.1s linear, transform 0.4s ease-in-out',
        opacity: 1,
        transform: `translate3d(0, 0, 0)`
      },
      content: {
        border: '1px solid transparent',
        borderRadius: '6px',
        padding: '20px 30px 10px 30px',
        margin: 'auto',
        color: color.text.light.primary,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.darkElevated.primary,
          color: color.text.dark.primary
        },
        boxShadow: '2px 2px 21px 7px rgba(0,0,0,0.2)',
        borderTop: '4px solid #ffd502',
        // display: 'grid',
        gridTemplateColumns: '10px auto 10px'
      },
      title: {
        fontWeight: 'bold',
        fontFamily: typography.titleFamily,
        fontSize: '1.7rem',
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      titleContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto'
      },
      yellowBar: {
        width: 128,
        height: 5,
        borderRadius: 8,
        marginTop: 7,
        backgroundColor: '#ffd502',
        marginBottom: 15,
      }

    }
  }

  async boundToDOM (node) {
    this._node = node
    window.app.panelProvider().activeModalClass(this)
    await Promise.delay(50)
    this.active(true)
    // const input = firstInputElement(node)
  }

  get contentHTML () {
    return <slot name='content' />
  }

  contentClick (evt) {
    hidePopovers()
    evt.stopPropagation()
  }

  get containerEvents () {
    return { }
  }

  get koDropTarget () { return null }

  get template () {
    const { jss } = this
    const shadeClass = this.computed(() => this.active()
      ? jss.activeShade : jss.inactiveShade)
    const containerClass = this.computed(() => this.active()
      ? jss.activeContainer : jss.inactiveContainer)
    const contentClasses = [jss.content, this.contentClass]
      .filter(v => v)
      .join(' ')

    return [
      <div class={shadeClass}
        modal-shade=''
        ko-click={() => this.dismiss() }/>,
      <div class={containerClass}
        modal-container=''
        ko-event={this.containerEvents}
        ko-descendantsComplete={node => this.boundToDOM(node)}
        ko-click={() => this.dismiss()}
        ko-drop-target={this.koDropTarget}>
        <div class={contentClasses}
          ko-click={evt => this.contentClick(evt)}>
          <div class={jss.titleContainer}>
            <div class={jss.title}>
              {this.title}
            </div>
            <div class={jss.yellowBar} ko-visible={this.title} />
          </div>
          {this.contentHTML}
        </div>
      </div>
    ]
  }
}

ModalDialog.register()
