import { Transaction } from './Transaction'

export abstract class AssetTransaction extends Transaction {
  assetClassID: string

  constructor ({ assetClassID, datetime, title }) {
    super({ datetime, title })
    Object.assign(this, { assetClassID })
  }

  toJS () { return { ...super.toJS(), assetClassID: this.assetClassID }}

  protected assetNameAt (cs: CapitalState) {
    return cs.getAsset(this.assetClassID).assetName
  }
}
