
import Picker from './picker'

import { DataModel } from 'DataModel'
import { color, typography } from 'styles';

export default class ModelPropertyPicker extends Picker<string> {
  indexName: string
  searchType: 'startsWith' | 'includes'
  propertyGetter: (v: DataModel) => any
  extraChoices: string[]

  constructor (params) {
    super(params)
    const {
      indexName, propertyGetter, searchType, resetQueryOnSelect, extraChoices
    } = params
    Object.assign(this, {
      indexName,
      propertyGetter,
      searchType,
      resetQueryOnSelect,
      extraChoices,
    })
  }

  get _indexName () { return this.indexName }
  get _searchType () { return this.searchType || 'includes' }
  _propertyGetter (model: DataModel) { return this.propertyGetter(model) }

  // onEnter (value: string) { this.onSelectEvent(value.trim()) }

  onSelectEvent (value) {
    if (!value.trim()) { return }
    return super.onSelectEvent(value)
  }

  filterChoice (item, query) {
    return (item || '')
      .toLowerCase()
      .trim()[this._searchType]((query || '').toLowerCase())
  }

  choices () : string[] {
    const choices = new Set<string>(this.extraChoices || null)
    const modelList = window.app.memoryDB.getListOf(this._indexName)
    for (const model of modelList()) {
      const value = ko.unwrap(this._propertyGetter(model))
      if (!value) { continue }
      if (typeof value === 'string') {
        choices.add(value)
      } else if (Symbol.iterator in value) {
        for (const v of value) { choices.add(v) }
      } else {
        console.error(`Bad property picker type:`, value, model)
        throw new Error(`<model-property-picker> bad value type.`)
      }
    }
    return [...choices].filter(c => c).sort()
  }

  itemHTML (value: string) {
    const { jss } = this
    return <div class={jss.itemValue}>{value}</div>
  }

  get noMatchesMessage () { return 'Nothing matches. [Enter] to add.' }
  get searchMessage () { return 'Type to search or add' }

  static get css () {
    return {
      ...super.css,
      input: {
        ...super.css.input,
        fontSize: '1em',
        fontFamily: typography.bodyFontFamily,
        fontWeight: 'normal',
        backgroundColor: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
          color: color.text.dark.primary
        },
      },
      anchor: {
        ...super.css.anchor,
        width: '100%',
      },
    }
  }
}

ModelPropertyPicker.register()
