import Big from 'big.js'
import { groupBy, sortBy } from 'lodash-es'

import { buttons, color, typography } from 'styles'

import { ShareholderLedger } from 'capital'

import ShareholderGridView from './ShareholderGridView'

import './capital-share-transfer-editor'
import { toLocaleDecimal } from 'utils/number'

/**
 * Common functionality for organizing shareholders by class or holder
 */
export default abstract class ShareholdersBy extends ShareholderGridView {
  static get css () {
    return {
      ...super.css,
      grid: {
        display: 'grid',
        gridTemplateColumns: '1fr minmax(245px, auto) auto auto',
        gridGap: '4px',
        alignItems: 'baseline',
        margin: '6px'
      },
      prefix: {
        padding: '0px 10px'
      },
      balance: {
        fontFamily: typography.mono,
        textAlign: 'right'
      },
      groupNameCell: {
        gridColumnStart: 1,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
      },
      groupNameLink: {
        ...buttons.clickable,
        display: 'inline',
        color: color.link.light.primary,
        opacity: '0.4',
        visibility: 'visible',
        fontSize: '0.8rem',
        padding: '0px 1rem',
        background: 'white',
        '$groupNameCell:hover &': {
          transition: 'opacity 0.2s ease-in-out',
          visibility: 'visible',
          opacity: '1',
        }
      },
      groupTotal: {
        fontFamily: typography.mono,
        gridColumn: '1/-1',
        textAlign: 'right',
        fontWeight: 'bold'
      }
    }
  }

  abstract get groupBy () : string
  abstract groupName (name: string, ledgers: ShareholderLedger[]) : any
  abstract groupLine (ledger: ShareholderLedger, balance: string, percent: string)
  abstract sortedKeys (byGroup: Record<string, ShareholderLedger[]>) : string[]

  getAssetName (assetClassID) : string {
    return this.state.getAsset(assetClassID).assetName
  }

  * groupCells (ledgers: ShareholderLedger[]) {
    const { jss } = this
    const total = ledgers
      .filter(ledger => ledger.lines.length)
      .map(ledger => ledger.lines.slice(-1).pop())
      .reduce((pv, line) => pv.add(line.balance), new Big(0))

    for (const ledger of sortBy(ledgers, 'assetName')) {
      if (!ledger.lines.length) { continue }
      const balance = ledger.lines.slice(-1).pop().balance
      if (balance.eq(0)) { continue }
      const percent = total.eq(0) ? ''
        : new Big(balance)
          .div(total)
          .times(100)
          .toFixed(2)

      /**
       * FIXME:
      const asset = this.state.getAsset(ledger.assetClassID)
      if (asset.getProperty('deauthorized')) { continue }
       */

      yield * this.groupLine(ledger, toLocaleDecimal(balance), percent)
      yield this.partBreak
    }

    yield <div class={jss.groupTotal}>{toLocaleDecimal(total)}</div>
  }

  * ledgersCells (byGroup: Record<string, ShareholderLedger[]>) {
    let first = true
    for (const groupName of this.sortedKeys(byGroup)) {
      if (!groupName) { continue }
      const ledgersForGroup = byGroup[groupName]
      if (first) {
        first = false
      } else {
        yield this.fullBreak
      }
      yield (
        <div class={this.jss.groupNameCell}>
          {this.groupName(groupName, ledgersForGroup)}
        </div>
      )
      yield * this.groupCells(ledgersForGroup)
    }
  }

  get gridCells () : any[] {
    const ledger = [...this.state.shareholderLedgers()]
    return [...this.ledgersCells(groupBy(ledger, this.groupBy))]
  }

  transferClick (evt: Event, assetClassID: string) : void {
    const { jss } = this
    evt.preventDefault()
    evt.stopPropagation()
    const assetName = this.getAssetName(assetClassID)
    const title = `Transfer ${assetName}`
    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <capital-share-transfer-editor class={jss.transferEditor}
            persons={this.persons}
            relatedGenerator={this.relatedGenerator}
            stakeholders={this.state.stakeholders}
            assetClassID={assetClassID}
            transactions={this.transactions} />
        </template>
      </modal-dialog>
    )
  }
}
