/**
 * This is based on `QuestionView` from Nassau.
 */
import { cloneDeep } from 'lodash-es'
import CrudModel from './CrudModel'

enum CloneStrategy {
  INCLUDE = 'include',
  IGNORE = 'ignore',
  PRIMARY_NAME = 'primary_name'
}

export interface QuestionDescriptor {
  title: string
  identifier: string
  component: string
  share?: string
  clone_strategy?: CloneStrategy
}

export abstract class QuestionModel extends CrudModel {
  abstract get QUESTIONS (): QuestionDescriptor[]
  static get isAbstract () { return true }

  vmDefineProperties () {
    this.questions = this.content.questions
  }

  componentFor (name) {
    return this.content.componentMap[name]
  }

  answerFor (name, deflt = '') {
    try {
      return this.componentFor(name).get()
    } catch (e) {
      return deflt
    }
  }

  isBlank () {
    return Boolean(this.content.isBlank())
  }

  /**
   * Return the value from the questionnaire.
   */
  qLookup (at) {
    return this.answerFor(at)
  }

  async clone () {
    const ignoredQuestions = this.QUESTIONS.filter(q => q.clone_strategy === CloneStrategy.IGNORE)
    const props = cloneDeep(await this.vmCurrentRepr())
    delete props.id
    ignoredQuestions.forEach(q => delete props.content[q.identifier])
    const nameProperty = this.QUESTIONS.find(q => q.clone_strategy === CloneStrategy.PRIMARY_NAME).identifier
    props.content[nameProperty] = `${props.content[nameProperty]} - COPY`
    props.accountID = window.app.defaultAuthManager.accountID()
    props.sharing = { sharingRights: [], email: [] }
    return new this.constructor(props, this.authManager)
  }

}

export default QuestionModel
