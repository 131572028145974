
import icons from 'icons'
import dropIcon from 'icons/light/inbox-in'
import { color, adminOnly, typography, buttons } from 'styles'
import CommandSet from 'Command/CommandSet'
import AccountModel from 'AccountModel'
import { encodeToBase64, decodeFromBase64 } from 'jcrypto'
import { computed } from 'utils/decorator'

import docIcon from 'icons/solid/file-word'
import docDownload from 'icons/solid/file-download'

import { PANDOC_LS_KEY } from 'writing/WritingDocument'

import RootPanelProvider from './RootPanelProvider'

export default class AccountPanelProvider extends RootPanelProvider {
  accountModel: KnockoutObservable<AccountModel>
  hasReferenceDoc = ko.observable<boolean>(!!localStorage.getItem(PANDOC_LS_KEY))

  get panelWindowTitle () { return `Account` }
  get panelID () { return 'Account' }
  get historyUrl () { return '#account' }
  get CommandSetConstructor () { return CommandSet }
  get peerPanelList () { return this.app.settingsProviderList }
  get hideForHomeless () { return true }

  constructor ({ app }) {
    super({ app })
    Object.assign(this, {
      accountModel: ko.observable(),
    })

    this.loadAccount()
  }

  async loadAccount () {
    const authManager = this.app.defaultAuthManager
    if (!authManager.userIsHomeless) {
      this.accountModel(await authManager.getAccountModel())
    }
  }

  static get appGridClassCSS () {
    return {
      ...super.appGridClassCSS,
      grid: {
        ...super.appGridClassCSS.grid,
        templateRows: 'auto auto auto auto 1fr',
        templateAreas: `
        'ty ty ty'
        'head head head'
        'top top top'
        'left main right'
        'left main right'
        `,
      },
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.loadingCSS,
      ...this.settingsCSS,
      ...this.firmNameCSS,
      ...this.referenceDocxCSS,
      main: {
        padding: '30px',
      },
    }
  }

  static get firmNameCSS () {
    return {
      firmNameArea: {
        display: 'grid',
        grid: {
          gap: '20px',
          templateColumns: 'auto 1fr',
        },
        padding: 30,
        background: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          background: color.systemGroupBackground.dark.primary,
        },
        borderRadius: 5,
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
      },
      settingTitle: {
        alignSelf: 'center',
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      firmNameText: {
        backgroundColor: color.systemBackground.light.tertiary,
        minWidth: '100%',
        minHeight: '100%',
        borderRadius: 4,
        fontWeight: 'bold',
        fontSize: '1.2em',
        padding: '10px 15px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        fontFamily: typography.bodyFontFamily,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.tertiary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.secondary,
        },
      },
      firmNameInput: {
        backgroundColor: color.textInput.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        minWidth: '100%',
        minHeight: '100%',
        borderRadius: 4,
        fontFamily: typography.bodyFontFamily,
        fontWeight: 'bold',
        padding: '10px 15px',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        fontSize: '1.2em',
        outline: 'none',
        color: color.text.light.primary,
      },
      description: {
        ...adminOnly,
        gridRow: '2',
        gridColumn: '2/3',
        fontSize: '0.7em',
        padding: '10px',
        background: 'white',
        borderTop: '3px solid #ffd502',
        borderRadius: 2,

        '& kbd': {
          fontFamily: typography.mono,
          backgroundColor: '#eff0f1',
          padding: '2px 6px 0px 6px',
          textTransform: 'uppercase',
          fontWeight: 600,
        },
      },
    }
  }

  get firmNameInputHTML () {
    const { jss, app } = this
    const { firmname } = this.accountModel()
    return app.defaultAuthManager.userIsAdmin()
      ? <inline-input value={firmname}
        inputClass={jss.firmNameInput}
        textClass={jss.firmNameText} />
      : <div class={jss.firmNameText}>{firmname}</div>
  }

  get firmNameHTML () {
    const { jss } = this
    return (
      <div class={jss.firmNameArea}>
        <div class={jss.settingTitle}>
          Account Name
          <q-tip name='settingsAccountName' />
        </div>
        <div>
          {this.computed(() => this.firmNameInputHTML)}
        </div>
      </div>
    )
  }

  /**
   *        Reference DOCX
   */
  static get referenceDocxCSS () {
    return {
      referenceDocxArea: {
        display: 'grid',
        grid: {
          gap: '20px',
          templateColumns: 'auto max-content max-content',
          templateAreas: `
          'name template reference'
          `
        },
        '@media (max-width: 1130px)': {
          minWidth: '568px',
          gridTemplateColumns: 'auto auto',
          gridTemplateRows: '1fr 1fr',
          gridTemplateAreas: `
          'name reference '
          'name template'
          `
        },
        padding: 30,
        background: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          background: color.systemGroupBackground.dark.primary,
        },
        borderRadius: 5,
        alignItems: 'center',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
      },

      _documentButton: {
        width: 'fit-content',
        padding: '5px 15px',
        borderRadius: 4,
        fontWeight: '600',
        textDecoration: 'none',
        background: color.fill.light.primary,
        justifySelf: 'flex-end',
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
          color: color.text.dark.primary,
        },
        '&:hover': {
          background: color.fill.light.secondary,
          color: color.text.light.primary,
          'body[dark] &': { // project batman
            background: color.fill.dark.secondary,
            color: color.text.dark.primary,
          },
        },
        '& > svg': {
          marginRight: 10
        }
      },

      currentReferenceDocxDownload: {
        ...buttons.clickable,
        gridArea: 'reference',
        extend: '_documentButton'
      },
      vanillaReferenceDocxDownload: {
        ...buttons.clickable,
        gridArea: 'template',
        extend: '_documentButton'
      },
      noReferenceDocx: {},
    }
  }

  async onDrop (evt: DragEvent, app) {
    super.onDrop(evt, app)
    const f = evt.dataTransfer.files.item(0)
    if (!f.name.endsWith('.docx')) {
      throw new Error(`File must be .docx, got "${f.name}"`)
    }
    localStorage.setItem(PANDOC_LS_KEY, encodeToBase64(await f.arrayBuffer()))
    this.hasReferenceDoc(true)
  }

  get overlay () {
    const { jss } = this
    return (
      <div class={jss.dragPad}>
        {icons.inline(dropIcon)}
        <span> Drop your DocX Reference Styles here</span>
      </div>
    )
  }

  private downloadCurrentDocxClick () {
    const data = decodeFromBase64(localStorage.getItem(PANDOC_LS_KEY))
    const now = new Date().toISOString().slice(0, 10)
    const filename = `MinuteBox Reference Styles ${now}.docx`
    window.saveAs(new Blob([data]), filename)
    console.log(`data`, data)
  }

  @computed()
  private get downloadCurrentReferenceDocxHTML () {
    const { jss } = this
    return this.hasReferenceDoc()
      ? (
        <a ko-ownClick={() => this.downloadCurrentDocxClick()}
          class={jss.currentReferenceDocxDownload}
          download>
          {icons.inline(docDownload)}
          Download Current Template
        </a>
      ) : (<div class={jss.noReferenceDocx}>Upload a reference .docx</div>)
  }

  get referenceDocxHTML () {
    const { jss } = this


    return (
      <div class={jss.referenceDocxArea}>
       <div class={jss.settingTitle}>
        Document Styles
          <q-tip name='settingsDocStyle' />
        </div>
          {this.downloadCurrentReferenceDocxHTML}
        <a href='/custom-reference.docx'
          class={jss.vanillaReferenceDocxDownload}
          download='custom-reference.docx'>
          {icons.inline(docIcon)}
          Download Reference Template
        </a>
      </div>
    )
  }

  async downloadReferenceTemplate () {
    console.log(`downloading`)
  }


  /**
   *        LOADING
   */

  static get loadingCSS () {
    return {
      loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '60vh',
      },
      loadingSpinner: {
        '--icon-height': '48px',
      },
    }
  }

  get loadingHTML () {
    const { jss } = this
    return (
      <div class={jss.loading}>
        <loading-spinner class={jss.loadingSpinner} />
      </div>
    )
  }

  static get settingsCSS () {
    return {
      settings: {
        margin: '20px 0px',
        minWidth: '500px',
        '@media (max-width: 800px)': {

        },
      },
    }
  }

  get settingsHTML () {
    const { jss } = this
    return (
      <>
        <auto-save dataModel={this.accountModel()} />
        <div class={jss.settings}>
          {this.firmNameHTML}
        </div>
        <div class={jss.settings}>
          {this.referenceDocxHTML}
        </div>
      </>
    )
  }

  get main () {
    return (
      <div class={this.jss.main}>
        {this.computed(() => this.accountModel()
          ? this.settingsHTML
          : this.loadingHTML)}
      </div>
    )
  }
}
