import ModalDialog from 'modal-dialog'

import {
  signChallenge, verifyChallengeSignature, createPublicKey,
  encodeObject
} from 'jcrypto'
import { inline } from 'icons'
import motionIcon from 'icons/solid/circle'
import completeIcon from 'icons/solid/check-circle'

enum WAITING_FOR {
  Challenge = 1,
  Authenticator,
  Verification,
  Complete,
  Error,
}

const STATUS_TEXT = {
  [WAITING_FOR.Challenge]: 'Waiting for server to send challenge',
  [WAITING_FOR.Authenticator]: 'Waiting for authenticator activation',
  [WAITING_FOR.Verification]: 'Waiting for server to verify',
  [WAITING_FOR.Complete]: 'Success',
  [WAITING_FOR.Error]: 'An Error Occurred',
} as Record<WAITING_FOR, string>

const STATUS_WAIT_ICON = {
  [WAITING_FOR.Challenge]: 'server',
  [WAITING_FOR.Authenticator]: 'authenticator',
  [WAITING_FOR.Verification]: 'server',
  [WAITING_FOR.Complete]: 'browser',
} as Record<WAITING_FOR, string>

abstract class WebauthProcessModal extends ModalDialog {
  state: KnockoutObservable<WAITING_FOR>
  iconStyle: KnockoutObservable<string>
  verificationResult: KnockoutObservable<any>
  abMotion: KnockoutObservable<any>
  bsMotion: KnockoutObservable<any>
  _serverState: JSX
  _authenticatorState: JSX

  icons: {
    authenticator: KnockoutObservable<any>
    browser: KnockoutObservable<any>
    server: KnockoutObservable<any>
  }

  _priorStateVisual: Promise<void>

  constructor ({ authManager, email, verificationResult }) {
    super()

    Object.assign(this, {
      verificationResult,
      state: ko.observable(),
      iconStyle: ko.observable(),
      abMotion: ko.observable(),
      bsMotion: ko.observable(),
      icons: {
        authenticator: ko.observable(),
        browser: ko.observable(),
        server: ko.observable(),
      }
    })

    this.begin(authManager, email).catch(err => this.onError(err))
  }

  onError (err) {
    console.error(`Webauth challenge error`, err)
    this.state(WAITING_FOR.Error)
  }

  clearAnimations () {
    this.abMotion(undefined)
    this.bsMotion(undefined)
    this.icons.authenticator(this._authenticatorState)
    this.icons.browser(undefined)
    this.icons.server(this._serverState)
  }

  get completeIcon () {
    return (
      <div class={this.jss.completeIcon}>{inline(completeIcon)}</div>
    )
  }

  async animate (iconObservable: KnockoutObservable<JSX>, reverse: boolean = false, waitingFor?: WAITING_FOR) {
    const { jss } = this
    const [start, end] = reverse ? [1, 0] : [0, 1]
    const style = ko.observable(`flex-grow: ${start}`)
    const animationComplete = ko.observable(false)

    this.clearAnimations()
    if (waitingFor) {
      this.state(waitingFor)
    }

    iconObservable(
      <>
        <div class={jss.spacer}
          ko-event={{ transitionend: () => animationComplete(true) }}
          style={style} />
        {inline(motionIcon)}
      </>
    )
    await Promise.delay(345)
    style(`flex-grow: ${end}`)
    await animationComplete.yet(false)
    iconObservable(undefined)
    if (waitingFor) { this.icons[STATUS_WAIT_ICON[waitingFor]](<loading-spinner />) }
  }

  async abAnimate (reverse, waitAt?) {
    return this.animate(this.abMotion, reverse, waitAt)
  }

  async bsAnimate (reverse, waitAt?) {
    return this.animate(this.bsMotion, reverse, waitAt)
  }

  challengerStateStepIcon () {
    return (
      <div class={this.jss.challengerProgressIcon}
        style={this.iconStyle} />
    )
  }

  get contentHTML () {
    const { jss } = this
    return (
      <>
        <div class={jss.challengerSteps}>
          <div class={jss.authenticatorIcon} />
          <div class={jss.abArea}>
            <div class={jss.abLine}>{this.abMotion}</div>
          </div>
          <div class={jss.browserIcon} />
          <div class={jss.bsArea}>
            <div class={jss.abLine}>{this.bsMotion}</div>
          </div>
          <div class={jss.serverIcon} />
          <div class={jss.authenticatorStatusIcon}>
            {this.icons.authenticator}
          </div>
          <div class={jss.browserStatusIcon}>
            {this.icons.browser}
          </div>
          <div class={jss.serverStatusIcon}>
            {this.icons.server}
          </div>
        </div>
        <div class={jss.status}>
          Status: {this.computed(() => STATUS_TEXT[this.state()])}
        </div>
      </>
    )
  }

  static get css () {
    return {
      ...super.css,
      content: {
        ...super.css.content,
        minWidth: '700px'
      },
      challengerSteps: {
        display: 'grid',
        gridTemplateColumns: '20% 1fr 20% 1fr 20%',
        gridTemplateRows: '80px 40px',
        gridTemplateAreas: `
          'a  ab  b  bs  s'
          'A  .   B  .   S'
          '.  M   M  M   .'
        `,
        minHeight: '100px',
        minWidth: '400px',
        padding: '20px 0px',
        position: 'relative',
      },

      challengerProgressIcon: {
        background: 'url(https://minutebox.media/app/authentication/progress.svg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '20px',
        width: '20px',
        position: 'absolute',
        zIndex: -1,
        transition: `2s`,
        left: '10%',
      },

      status: {
        gridArea: 'M',
        padding: '20px',
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },

      _area: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      abArea: {
        gridArea: 'ab',
        extend: '_area',
      },
      bsArea: {
        gridArea: 'bs',
        extend: '_area',
      },

      _line: {
        display: 'flex',
        alignItems: 'center',
        height: '1px',
        width: '100%',
        border: '1px solid black',
        '--icon-color': 'red',
        '--icon-height': '16px',
        transition: 'all 0.8s',
      },
      abLine: {
        extend: '_line',
      },
      bsLine: {
        extend: '_line',
      },
      spacer: {
        transition: '0.6s',
      },

      _icon: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },

      authenticatorIcon: {
        gridArea: 'a',
        extend: '_icon',
        background: 'url(https://minutebox.media/app/authentication/authenticator.svg)',
      },

      browserIcon: {
        gridArea: 'b',
        extend: '_icon',
        background: 'url(https://minutebox.media/app/authentication/browser.svg)',
      },

      serverIcon: {
        gridArea: 's',
        extend: '_icon',
        background: 'url(https://minutebox.media/app/authentication/relying.svg)',
      },

      _statusIcon: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        '--icon-height': '25px',
      },

      authenticatorStatusIcon: {
        gridArea: 'A',
        extend: '_statusIcon',
      },

      browserStatusIcon: {
        gridArea: 'B',
        extend: '_statusIcon',
      },

      serverStatusIcon: {
        gridArea: 'S',
        extend: '_statusIcon',
      },

      completeIcon: {
        extend: '_statusIcon',
        '--icon-color': 'green',
        '--icon-height': '25px',
      }
    }
  }

  abstract get modalTitle () : string
  abstract async getServerChallenge (authManager: AuthManager, args: any) : Promise<any>
  abstract async signChallenge (challengeResponse, email) : Promise<any>
  abstract async verifySignature (authManager, signed, args) : Promise<any>

  async begin (authManager: AuthManager, email: string) {
    const [challengeResponse] = await Promise.all([
      this.getServerChallenge(authManager, { email }),
      this.bsAnimate(false, WAITING_FOR.Challenge),
    ])

    await this.bsAnimate(true)

    if (!challengeResponse.challenge) {
      this.verificationResult(false)
    }

    await this.abAnimate(true, WAITING_FOR.Authenticator)
    const signed = await this.signChallenge(challengeResponse, email)
    this._authenticatorState = this.completeIcon

    await this.abAnimate(false)

    const [result] = await Promise.all([
      this.verifySignature(authManager, signed, email),
      this.bsAnimate(false, WAITING_FOR.Verification),
    ])
    this._serverState = this.completeIcon

    await this.bsAnimate(true, WAITING_FOR.Complete)

    this.verificationResult(result)
  }
}

export class WebauthRegisterModal extends WebauthProcessModal {
  get modalTitle () { return 'Registering New Authenticator' }
  async getServerChallenge (authManager) {
    return authManager.firebaseFn('registrationChallenge')
  }

  async signChallenge (challengeResponse, email) {
    const { challenge, userId } = challengeResponse
    const publicKey = createPublicKey(challenge, userId, email)
    return navigator.credentials.create({ publicKey })
  }

  async verifySignature (authManager: AuthManager, pkc: PublicKeyCredential) {
    const r = pkc.response as AuthenticatorAttestationResponse
    const response = encodeObject({
      id: pkc.id,
      attestationObject: r.attestationObject,
      clientDataJSON: r.clientDataJSON,
    })
    return authManager.firebaseFn('registerAuthenticator', response)
  }
}

export class WebauthChallengeModal extends WebauthProcessModal {
  get modalTitle () { return 'Authenticating' }
  async getServerChallenge (authManager, args) {
    return authManager.firebaseFn('authenticatorChallenge', args)
  }

  async signChallenge (challengeResponse) {
    const { challenge, allowCredentials } = challengeResponse
    return signChallenge(challenge, allowCredentials)
  }

  async verifySignature (authManager, signed, email) {
    return verifyChallengeSignature(authManager, signed, { email })
  }
}


WebauthRegisterModal.register()
WebauthChallengeModal.register()
