
import { capitalize } from 'lodash-es'

import { joinAnd } from 'utils/string'
import { safeFormat, fromISO8601 } from 'utils/dates'
import t from 't'

import { aliasLookup } from './aliases'

type Val = string | boolean
type Variable = import('./Variable').default
type TransformFunction = (s?: Val, v?: Variable, vt?: VariableTransform) => string

export interface VariableTransform {
  type: string
  parameters?: any
}

interface IterableVariable extends Variable {
  [Symbol.iterator] (): IterableIterator<string>
}

const SAFE_FORMAT = 'yyyy-MM-dd'

function alias (unaliasedValue, _, { parameters: { alias, by }}) {
  return by ? aliasLookup(by, alias, unaliasedValue) : unaliasedValue
}

// (v) { return v === true ? t.BOOLEAN_YES : v === false }
function boolean (s: boolean) {
  const bool = s as unknown as boolean
  if (bool === true) { return t.BOOLEAN_YES }
  if (bool === false) { return t.BOOLEAN_NO }
  if (bool === undefined || bool === null) { return t.BOOLEAN_UNSET }
  return t.INVALID_DATA
}

function dateFormat (date: string | Date, _, vt: VariableTransform) {
  if (!date) { return '' }
  try {
    const d = date instanceof Date ? date : fromISO8601(date)
    return safeFormat(d, vt.parameters.format || SAFE_FORMAT)
  } catch (err) {
    console.error(`[Variable Transform] ${date} => ${vt.parameters.format}`, err)
    return ''
  }
}

export const transforms = {
  alias,
  boolean: (s: boolean) => ko.unwrap(boolean(s)),
  dateFormat,
  default (s, _, vt) { return s || vt.parameters },
  empty () { return '' },
  join (s, v, vt) { return joinAnd(v as IterableVariable) },
  capitalize (s: string) { return capitalize(s) },
  lowercase (s: string) { return (s || '').toLowerCase() },
  uppercase (s: string) { return (s || '').toUpperCase() },
} as Record<string, TransformFunction>
