
/**
 * Return a comma-separated (or otherwise localized) decimal number.
 * e.g.
 *  - 1.0 => "1.0"
 *  - 1000 => "1,000"
 *  - "10000" => "10,000"
 */
export function toLocaleDecimal (value: any): any {
  return isNumeric(value)
    ? Number(value).toLocaleString()
    : value
}

/**
 * Determine if a value is numeric.
 *
 * Per Angular 4.3 https://stackoverflow.com/a/1830844
 */
export function isNumeric (value: any): boolean {
  return !isNaN(value - parseFloat(value))
}

/**
 * '1' => true
 * 1 => true
 * '1.1' => false
 * 1.1 = > false
 */
export function isInteger (value: string | number): boolean {
  if (typeof value === 'number') { return Number.isInteger(value) }
  return isInteger(Number.parseFloat(value))
}

/**
 * Integers 0 - N
 */
const INDEX_REX = /\d+/
export function isIndexInteger (value: string | number) {
  if (typeof value === 'number') {
    return Number.isInteger(value) && value >= 0
  }
  return INDEX_REX.test(value) && isIndexInteger(parseInt(value, 10))
}
