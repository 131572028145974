
import ViewComponent from 'ViewComponent'

export default class PersonRoleCountPopover extends ViewComponent {
  filter: PersonFilter
  skip: (r: PersonRoleRecord) => boolean
  source: PersonGenerator

  constructor ({ filter, skip, source }) {
    super()
    Object.assign(this, { filter, skip, source })
  }

  static get css () {
    return {
      count: {
        display: 'flex',
      },
      roleCount: {
      },
    }
  }

  * roles () {
    for (const person of this.source.getPersons(this.filter)) {
      for (const origin of person.origin) {
        for (const role of origin.roles()) {
          if (this.skip && this.skip(role)) { continue }
          yield role
        }
      }
    }
  }

  get template () {
    const { jss } = this

    const roles = this.computed(() => {
      return [...this.roles()].map(r => <person-role role={r} />)
    }).extend({ deferred: true }) as KnockoutComputed<any[]>

    const roleCount = this.computed(() => roles().length)
      .extend({ deferred: true })

    return (
      <tool-tip my='top right' at='bottom right'>
        <template slot='anchor'>
          <div class={jss.roleCount}>{roleCount}</div>
        </template>
        <template slot='content'>{roles}</template>
      </tool-tip>
    )
  }
}

PersonRoleCountPopover.register()
