
import ObjectProxy from './ObjectProxy'
const { pako } = global

export default class CompressProxy extends ObjectProxy {
  static get proxyPropertyName () { return 'Z' }
  /**
   * @param {Uint8Array | Array | string} object
   * @return {Uint8Array}
   */
  wrapValue (value) { return pako.deflate(value) }

  /**
   * @param {Uint8Array | Array | string} input
   * @return {Uint8Array}
   */
  static unwrapValue (input) { return pako.inflate(input) }
}
