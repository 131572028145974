import { DataModel } from 'DataModel'

/**
 * Create a `list` for the index `indexName` that contains a unique
 * copy of all models, from every source.
 */
export default class MemoryDbAggregator {
  modelLists: KnockoutObservableArray<any[]>
  list: KnockoutObservableArray<any[]>
  private _generator: KnockoutComputed<DataModel[]>


  constructor (indexName) {
    Object.assign(this, {
      indexName,
      modelLists: ko.observableArray([]),
      list: ko.observableArray([])
    })

    this._generator = ko.computed(() => [...this.genModels()])
      .extend({ rateLimit: 25 })
    this._generator.subscribe(this.list)
  }

  dispose () {
    this.modelLists([])
    this.list([])
    this._generator.dispose()
  }

  addQueryParams (qmdb) {
    this.modelLists.push(qmdb.makeModelList())
  }

  /**
   * @yield {firestore.DocumentSnapshot}
   */
  * genModels () {
    const seen = new Set()
    const lists = this.modelLists()
    const agg = lists.reduce((c, l) => l.list.length + c, 0)
    if (agg === 0) { return }
    let count = 0
    console.groupCollapsed(`  🔥💼  ${this.indexName} [${agg}]`)
    for (const { list } of lists) {
      for (const model of list()) {
        const uri = model.vmUniqueFirestoreURI
        // console.log(`  [+] `, uri)
        if (seen.has(model)) { continue }
        count++
        yield model
        seen.add(model)
      }
    }
    console.info(`${this.indexName} docs loaded: ${count}`)
    console.groupEnd()
  }
}
