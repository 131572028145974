
import ModelListOptions from 'model-list/model-list-options'
import { uuidv4 } from 'utils/string'

import crystallizeIcon from 'icons/light/user-friends'

export default class PersonListOptions extends ModelListOptions {
  getSelectedPersons: () => PersonRecord[]

  constructor (params) {
    super(params)
    const { getSelectedPersons } = params
    Object.assign(this, { getSelectedPersons })
  }

  get listOfButtonsHTML () {
    return [
      this.commonButtonsHTML,
      this.crystallizeButtonHTML,
    ].flat()
  }

  get crystallizeButtonHTML () {
    const isActive = this.computed<boolean>(() => this.getSelectedPersons().length > 1)
      .extend({ rateLimit: 100 })
    return (
      <div ko-ownClick={() => this.crystallizeSelected()}>
        {this.button(crystallizeIcon, 'Merge', isActive)}
      </div>
    )
  }

  crystallizeSelected () {
    const persons = this.getSelectedPersons()
    const idSet = new Set(persons.map(p => p.id).filter(id => id))
    if (idSet.size > 1) {
      console.warn(`Merging PersonRecord ids:`, [...idSet].join(' '))
      // throw new Error(`Cannot merge multiple ids into one (yet)`)
    }
    const id = [...idSet].pop() || uuidv4()

    for (const person of persons) {
      for (const origin of person.origin) {
        origin.update(p => p.id = id)
        origin.model.vmSave()
      }
    }
  }
}

PersonListOptions.register()
