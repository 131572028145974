
import ModelPropertyPicker from './model-property-picker'

export default class GoverningDocumentPicker extends ModelPropertyPicker {
  get _indexName () { return 'entity' }
  _propertyGetter (entity) {
    return [...entity.answerFor('governing_documents')].map(g => g.title())
  }
}

GoverningDocumentPicker.register()
