import BodyBlock from './BodyBlock'
import Paragraph from './Paragraph'

export class Heading extends BodyBlock {
  makeNextBlock (body) { return new Paragraph({ body }) }
  get code () { return 'h' }
  markdownLeadingChars () { return '#' }
}

export class SubHeading extends Heading {
  get code () { return 'h2' }
  markdownLeadingChars () { return '##' }
}
