
import { AuthorizedToInstruct } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisAuthorizedToInstruct extends EisArrayComponentTable<AuthorizedToInstruct> {
  get componentName () { return 'authorized_to_instruct' }
  get listAddName () { return 'Person Authorized to Instruct' }
  get caption () { return 'Authorized To Instruct' }

  get columns () { return ['person'] }
}

EisAuthorizedToInstruct.register()
