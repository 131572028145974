import { AssetDebit } from '../AssetAction'
import { CapitalUnilateralTransfer } from './CapitalUnilateralTransfer'
import { CODES } from './Transaction'
import { CertificateIssue, CertificateSurrender } from '../CertificateAction';

export class TransactionCancel extends CapitalUnilateralTransfer {
  certificatesCancelled: KnockoutObservableArray<number>
  residualCertificate: KnockoutObservable<number>

  get arrayObservables () {
    return [...super.arrayObservables, 'certificatesCancelled']}
  get plainObservables () {
    return [...super.plainObservables, 'residualCertificate']
  }

  get code () { return CODES.CapitalCancel }
  get ActionClass () { return AssetDebit }

  * yieldActions () {
    yield * super.yieldActions()

    yield * [...this.certificatesCancelled]
      .map(c => new CertificateSurrender(this.assetClassID, c, this.person()))

    yield new CertificateIssue(
      this.assetClassID,
      this.residualCertificate(),
      this.person(),
    )
  }
}
