import { tag, tagColor } from 'styles'

import { default as RightViewList } from './RightViewList'

/**
 *        ——— Tags ———
 *
 *      <minute-book-right-view-tags
 *          tags={} />
 */
export class MinuteBookRightViewTags extends RightViewList<string> {
  get title () { return 'Tags' }

  get pickerHTML () {
    return (
      <tag-picker
        my='top left'
        at='bottom left'
        inputClass={this.jss.input}
        filter={tag => !this.list().includes(tag)}
        indexName='entity'
        list={this.list}
        onSelect={tag => this.list().includes(tag) || this.list.push(tag)}
      />
    )
  }

  itemInnerHTML (tag) {
    return (
      <span class={this.jss.tag}
        style={`color: ${tagColor(tag)}`}>
        {tag}
      </span>
    )
  }

  static get css () {
    return {
      ...super.css,
      tag: {
        ...tag,
        maxWidth: 'calc(100% - 22px)',
      },
    }
  }
}

MinuteBookRightViewTags.register()
