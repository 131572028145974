//
// Primitive Component Types
// ---
//  These save as non-json values, e.g. strings, dates, etc.

import {
  isDate, isUndefined, isString, isNumber, isBoolean, isEqual, parseInt
} from 'lodash-es'
import {
  isValid, isSameDay
} from 'date-fns/esm'

import DataComponent from './DataComponent'

const {ko} = global

// The SettingDataComponent is a way to pass settings on the
// "contains" KoD part.
export class SettingDataComponent extends DataComponent {
  static get namespace () { return 'primitive' }
  get() { return this.properties }
  is_blank () { return true }
}


// The NoneDataComponent is used where an invalid component type
// has been selected.  It will load and save values (so there is no
// data loss if the data type changes). The content is not editable.
export class NoneDataComponent extends DataComponent {
  static get namespace () { return 'primitive' }
  set (v) { this.v = v }
  get () { return this.v }
  toJS () { return this.v }
  is_blank () { return Boolean(this.v) }
}


export class PrimitiveDataComponent extends DataComponent {
  static get namespace () { return 'primitive' }

  init () {
    this._observable = ko.observable()
    this.value = this._observable
      // Strict type checking.
      .extend({ castWrite: this.writeIfValidType.bind(this) })
    const default_ = this.default
    if (default_ !== undefined) { this.value(default_) }
  }

  cast(v) { return v }

  // Return the observable (not its value)
  get() {  return this.value }

  // Set the observable's value
  set(v) { this.value(isUndefined(v) ? ko.unwrap(this.default) : v) }

  // Return the value, one that can be converted to JSON
  toJS() { return this.cast(this.value()) }

  is_equal(other) { return this.value() === other }

  is_blank() {
    const v = this.value()
    return v === undefined || v === null || v === ko.unwrap(this.default)
  }

  has_value() {
    return this.value() !== undefined
  }

  writeIfValidType(v) {
    if (this.properties.looseChecking ||
        this.typeCheck(this.cast(v)) ||
        v === null || v === undefined) { return v }
    eraro(`${this.constructor.name}[${this.property_key}]:
      Attempt to write invalid type `, v)
    return
  }
}


export class BoolDataComponent extends PrimitiveDataComponent {
  get mergeTemplateId () { return "preview__boolean" }

  typeCheck(v) { return isBoolean(v) }
}


export class StringDataComponent extends PrimitiveDataComponent {
  init() {
    super.init()
  }

  is_blank() {
    const blank = 'default' in this.properties ? this.properties.default : ''
    return this.value() === blank || super.is_blank()
  }

  has_value() {
    return this.value() !== '' && super.has_value()
  }

  cast(v) {
    return isString(v) ? v
      : isNumber(v) ? v.toString()
      : v || ''
  }

  is_equal(other_) {
    // Normalize undefined & null.
    var other = other_ === undefined ? null : other_
    var our = this.value()
    our = our === undefined ? null : our
    return our === other
  }

  toPackagePrintJS() {
    return dust.asyncRenderSource(this.value(), this.peers)
  }

  typeCheck(v) { return isString(v) }
}


export class IntegerDataComponent extends PrimitiveDataComponent {
  cast (v) { return parseInt(v) }

  /**
   * Numerical equality.  This is (evidently) much trickier than one might
   * expect, given the prevalence of weirdness in Javascript primitives, notably
   * the possibility of null, undefined, Infinity, NaN, strings and numbers
   * that are semantically equivalent.
   *
   * @param  {int|string}  other Value to be compared
   * @return {Boolean}           True when both are finite numbers and equal,
   *                             or true when both are not finite numbers.
   */
  is_equal (other) {
    const [v, o] = [this.value(), other].map(parseInt)
    const [vIsFinite, oIsFinite] = [v, o].map(Number.isFinite)
    if (vIsFinite && oIsFinite) { return isEqual(v, o) }
    const onlyOneIsFinite = vIsFinite ^ oIsFinite
    return !onlyOneIsFinite
  }

  typeCheck (v) { return Number.isSafeInteger(v) }
}


export class DateDataComponent extends PrimitiveDataComponent {
  // Converts to a date value automagically.
  set(v) {
    this.value(parseISO(v) || ko.unwrap(this.default))
  }

  toJS() {
    const v = this.value()
    if (!v) { return null }
    if (isString(v)) { return v }
    if (!isValid(v)) { return null }
    if (v.toISOString) { return v.toISOString() }
    return null
  }

  get mergeTemplateId () { return "preview__date" }

  is_equal(other) {
    var v = this.value()
    if (!v) { return !other }
    return isSameDay(v, other)
  }

  is_blank() {
    return [null, undefined, ko.unwrap(this.default)].includes(this.value())
  }

  registry_dates() {
    return [{date: this.value(), property_key: this.property_key}]
  }

  typeCheck(v) { return isString(v) || isDate(v) }
}

/**
 * KeyDataComponent, for storing a single key.
 */
export class KeyDataComponent extends PrimitiveDataComponent {
  typeCheck (v) { return isString(v) || isNumber(v) }
}


const COMPONENTS = [
  BoolDataComponent,
  SettingDataComponent,
  NoneDataComponent,
  DateDataComponent,
  IntegerDataComponent,
  KeyDataComponent,
  StringDataComponent
]

COMPONENTS.forEach(c => c.register())
