import { format } from 'date-fns'

import RightView from 'MinuteBookPanelProvider/right-view/RightView'
import { computed } from 'utils/decorator'
import { color, buttons } from 'styles'
import { inline } from 'icons'
import removeIcon from 'icons/light/times'
import t from 't'

import { Variable } from '../Variable'

type SlotManager = import('../slots').SlotManager

export default abstract class WritingRightViewVar<T extends Variable> extends RightView {
  variable: T
  slotManager: SlotManager

  get title () { return 'Fill-in Options' }
  get multiTitleItems () { return null }

  constructor (params) {
    super(params)
    Object.assign(this, {
      variable: params.variable,
      slotManager: params.variable.slotManager,
    })
  }

  monitorAndUpdatePeers (v: KnockoutObservable<any>) {
    this.subscribe(v, () => this.slotManager.propagateChange(this.variable))
  }

  static get css () {
    return {
      ...super.css,
      ...this.sectionCSS,
      ...this.dateFormattingCSS,
      ...this.transformCSS,
      input: {
        width: '100%',
        fontSize: '0.8rem',
        height: 31,
        outline: 'none',
        padding: '1px 6px',
        borderRadius: 2,
        border: `1px solid ${color.separator.light.nonOpaque} `,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque} `,
        },
      },
      entityName: {
        width: '100%',
        fontSize: '0.8rem',
        height: 31,
        padding: '1px 6px',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${color.separator.light.nonOpaque} `,
        background: color.textInput.light.primary,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque} `,
          background: color.textInput.dark.primary
        },
      },
      entityNameInner: {
        fontSize: '85%',
        cursor: 'pointer',
        '--icon-color': color.icon.light.secondary,
        'body[dark] &': { // project batman
          '--icon-color': color.icon.dark.secondary,
        },
        '&:hover': {
          '--icon-color': color.icon.light.tertiary,
          'body[dark] &': { // project batman
            '--icon-color': color.icon.dark.secondary,
          },
        }
      },

      heading: {
        padding: '10px 0 10px 40px',
        fontWeight: 600,
      },
      options: {
        margin: '0 40px 0 40px'
      },

      slotGroupTitle: {
        padding: '5px 0px 5px 20px',
        textAlign: 'left',
        background: color.fill.light.quarternary,
        fontWeight: 600,
        'body[dark] &': { // project batman
          background: color.fill.dark.quarternary,
        },
      },
    }
  }

  static get sectionCSS () {
    return {
      section: {
        margin: '10px',
        padding: '10px',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      sectionTitle: {
        textAlign: 'left',
        fontWeight: 600,
        marginBottom: 7,
        textTransform: 'uppercase'
      },
      _select: {
        height: '30px',
        maxWidth: '100%',
        minWidth: '100%',
      },
      sectionBody: {
      },
    }
  }

  inputSection (title, body) {
    const { jss } = this
    return (
      <div class={jss.section}>
        <div class={jss.sectionTitle}>{title}</div>
        <div class={jss.sectionBody}>{body}</div>
      </div>
    )
  }

  static get dateFormattingCSS () {
    return {
      dateFormatSelect: {
        extend: '_select',
      },
    }
  }

  /**
   * Dates are formatted according to the Unicode UTS#35 standard:
   * See:
   *  - https://date-fns.org/v2.4.1/docs/format
   *  - https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   */
  dateFormattingHTML (v: KnockoutObservable<string>) {
    const { jss } = this
    const today = new Date()
    const formatOption = (fmt, explanation=null) => (
      <option value={fmt}>{format(today, fmt)} {explanation}</option>
    )

    return (
      <select class={jss.dateFormatSelect} ko-value={v}>
        <option disabled >Format of the date</option>
        <optgroup label='Full date'>
          {formatOption('yyyy-MM-dd')}
          {formatOption('LLL. do, yyyy')}
          {formatOption('LLLL do, yyyy')}
          {formatOption(`do 'day of' MMMM, yyyy`)}
        </optgroup>
        <optgroup label='Part of the date'>
          {formatOption('yyyy', '(year)')}
          {formatOption('LLLL')}
          {formatOption('MM', '(month)')}
          {formatOption('d', '(day)')}
          {formatOption('do', '(day th)')}
        </optgroup>
      </select>
    )
  }

  personPropertyPicker (v: KnockoutObservable<string>) {
    const { jss } = this
    return (
      <select class={jss.dateFormatSelect} ko-value={v}>
        <option value='name'>{t.PERSON_NAME}</option>
        <option value='phone'>{t.PERSON_PHONE}</option>
        <option value='email'>{t.PERSON_EMAIL}</option>
        <option value='residency'>{t.PERSON_RESIDENCY}</option>
      </select>
    )
  }

  get slotPickerHTML () {
    return <slot-picker variable={this.variable} noCaret={true} />
  }

  static get transformCSS () {
    return {
      transforms: {
        display: 'grid',
        gap: '1px',
        gridTemplateColumns: '1fr 1fr 1fr 20px',
        alignItems: 'center',
      },
      transformOption: {
        ...buttons.clickable,
        display: 'flex',
        justifyContent: 'center',
        padding: '6px 3px',

        '&:hover':{
          fontWeight: '500',
          background: color.fill.light.tertiary,
          borderRadius: 10,
        },
        '&[selected=true]': {
          fontWeight: '500',
          background: color.fill.light.primary,
          borderRadius: 10,
        },
      },

    }
  }

  @computed()
  transformsHTML () {
    const { jss } = this
    const transforms = this.variable.transforms()
    const types = ['uppercase', 'lowercase', 'capitalize']
    const upper = Boolean(transforms.find(v => v.type === 'uppercase'))
    const lower = Boolean(transforms.find(v => v.type === 'lowercase'))
    const caps = Boolean(transforms.find(v => v.type === 'capitalize'))
    const clear = () => this.variable.transforms
      .remove(v => types.includes(v.type))
    const set = type => {
      clear()
      this.variable.transforms.push({ type })
    }

    return (
      <div class={jss.transforms}>
        <div class={jss.transformOption}
          selected={upper}
          ko-ownClick={() => set('uppercase')}>{t.TEXT_TRANSFORM_UPPERCASE}</div>
        <div class={jss.transformOption}
          selected={lower}
          ko-ownClick={() => set('lowercase')}>{t.TEXT_TRANSFORM_LOWERCASE}</div>
        <div class={jss.transformOption}
          selected={caps}
          ko-ownClick={() => set('capitalize')}>{t.TEXT_TRANSFORM_CAPITALIZE}</div>
        <div class={jss.transformOption}
          ko-visible={upper || lower || caps}
          ko-ownClick={clear}>
          {inline(removeIcon)}
        </div>
      </div>
    )
  }

  @computed()
  possibleAliasesHTML () {
    const aliases = ko.unwrap(this.variable.aliases)
    return aliases
      ? this.inputSection('Aliases', this.aliasChoicesHTML(aliases))
      : null
  }

  aliasOptions (aliases) {
    if (!aliases) { return [] }
    const options = Object.entries(aliases)
      .map(([key, value]) => value && <option value={key}>{value}</option>)
      .filter(v => v)
    return [
      <option value=''>{this.variable.unaliasedValue}</option>,
      ...options,
    ]
  }

  aliasChoicesHTML (aliases) {
    return (
      <select class={this.jss._select} ko-value={this.variable.alias}>
        {this.computed(() => this.aliasOptions(aliases))}
      </select>
    )
  }


  get body (): JSX {
    const { jss } = this
    return (
      <>
        <div class={jss.slotGroupTitle}>{this.variable.slotGroupTitle}</div>
        {this.inputSection('Text transform', this.transformsHTML)}
        {this.possibleAliasesHTML}
      </>
    )
  }
}

