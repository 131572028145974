
import Command from 'Command'

import RootCommandSet from './RootCommandSet'

export default class UserListCommandSet extends RootCommandSet {
  makeCommands () {
    const { panelProvider } = this

    const editUserUnderMouse = new Command({
      title: 'Edit Entity',
      help: <span>To quickly edit a user, press <kbd>E</kbd> while your mouse cursor is hovering over any entity in the <i>User List View</i>. You can press <strong>Esc</strong> to dismiss the modal.</span>,
      action: () => this.editModelUnderMouse()
    })

    return {
      ...super.makeCommands(),
      editUserUnderMouse
    }
  }

  // get keyboardShortcuts () {
  //   const c = this.commands
  //   return [
  //     ...super.keyboardShortcuts,
  //   ]
  // }
}
