import PanelHead from 'MinuteBookPanelProvider/panel-head'

import docIcon from 'icons/solid/file'
import formatIcon from 'icons/solid/font'
import fillinsIcon from 'icons/solid/magic'
import exportIcon from 'icons/solid/file-download'
import viewIcon from 'icons/regular/window'
import slotIcon from 'icons/solid/bullseye'
import tocIcon from 'icons/custom/iconToc'

import 'views/button-over-text'
import { buttons } from 'styles'

type WritingPanelProvider = import('../WritingPanelProvider').default
type ButtonDropDownItem = import('views/button-drop-down').ButtonDropDownItem

export default class WritingPanelHead extends PanelHead<WritingPanelProvider> {
  get buttonRow () {
    const { commands } = this.panelProvider.commandSet

    return (
      <>
        <button-drop-down my='top left' at='bottom left'
          text='View' icon={viewIcon} items={this.viewItems}
        />

        {this.undoHTML}

        <div style='display: flex'>
          <button-over-text icon={formatIcon} text='Format' iconColor='orange'
            action={() => commands.toggleFormatting.trigger()}
          />
          <button-over-text icon={fillinsIcon} text='Fill-ins' iconColor='red'
            action={() => commands.toggleFillins.trigger()}
          />
          <button-over-text icon={slotIcon} text='Slots'
            action={() => commands.toggleSlots.trigger()} />
        </div>
        <div style='display: flex; justify-self: flex-end'>
          <button-over-text icon={docIcon} text='Document'
            action={() => commands.toggleDocOptions.trigger()} />

          {this.exportButtonsHTML}
        </div>
      </>
    )
  }

  get exportButtonsHTML () {
    const { commands } = this.panelProvider.commandSet
    const items: ButtonDropDownItem[] = [
      { text: 'Microsoft Word (.docx)', action: () => commands.convertToDocx.trigger() },
      { text: 'PDF Document (.pdf)', action: () => commands.convertToPdf.trigger() },
      { text: 'Markdown (.md)', action: () => commands.convertToMarkdown.trigger() },
    ]
    return (
      <button-drop-down my='top right' at='bottom right'
        icon={exportIcon} text='Export' items={items}
      />
    )
  }

  get viewItems (): ButtonDropDownItem[] {
    const { showToC } = this.panelProvider
    return [
      this.viewToggleItem('Table of Contents', tocIcon, showToC),
    ]
  }

  async convertToDocx () {
    await this.panelProvider.document.convertTo('docx')
  }

  get viewHTML () { return null }

  get entityDisplayValue () {
    const { document } = this.panelProvider
    return (
      <>
        {this.parentModelTitle}
        <div class={this.jss.slashInHead}>/</div>
        {document.implicitTitle}
      </>
    )
  }

  get parentModelTitle () {
    const { model } = this.panelProvider
    const parent = model.parent()
    if (!parent) { return null }

    return (
      <div class={this.jss.parentTitleInHead}
        ko-ownClick={() => this.onParentClick(parent)}>
        {parent.cvModelTitle}
      </div>
    )
  }

  async onParentClick (model: CrudModel) {
    const { app } = window
    const MBPP = (await import('MinuteBookPanelProvider')).default
    app.panelProvider(new MBPP({ app, model }))
  }

  static get css () {
    return {
      ...super.css,
      parentTitleInHead: {
        ...buttons.clickable,
        display: 'inline',
        fontWeight: 'normal',
      },
      slashInHead: {
        display: 'inline',
        padding: '0px 1em',
        fontWeight: 'normal',
      },
    }
  }
}

WritingPanelHead.register()
