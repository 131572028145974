
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class Director extends UnitView {
  constructor (params, parent) {
    // EIS 2.0 => 2.1 🐫 transition strategy
    params.person = params.person || {
      name: [params.name],
      residency: [params.residency],
    }
    super(params, parent)
  }

  get elvValue () { return this.person().name.slice(0,1).pop() }

  get fields () {
    return {
      person: 'person',
      elected: 'pureDate',
      resigned: 'pureDate',
    }
  }

  * getRoles (): IterableIterator<PersonRoleRecord> {
    yield { title: 'Director', start: this.elected(), end: this.resigned() }
  }
}

export default class DirectorsComponent extends ArrayComponent {
  get ItemClass () { return Director }
}

DirectorsComponent.register()
