
import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class BusinessNumber extends UnitView {
  get elvValue () { return `${this.name()}: ${this.number()}` }

  get fields () {
    return {
      name: 'string',
      number: 'string',
    }
  }
}

export default class BusinessNumbersComponent extends ArrayComponent {
  get ItemClass () { return BusinessNumber }
}

BusinessNumbersComponent.register()
