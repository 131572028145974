
import EisSectionNode from './EisSectionNode'
import { EntityInformationSummary } from './entity-information-summary'
import { TOP_MARGIN } from './consts'

const { ko } = global

export default class EisCategory {
  eis: EntityInformationSummary
  title: string
  parts: any
  scrollTo: KnockoutObservable<number>
  inView: KnockoutObservable<boolean>
  hide: KnockoutObservable<boolean>

  constructor (eis, title, ...parts) {
    const scrollTo = ko.observable()
    const inView = ko.observable()
    const hide = ko.observable(false)

    Object.assign(this, {
      eis,
      title,
      parts,
      scrollTo,
      inView,
      hide,
    })
    this.eis.eisSectionNodes.push(
      new EisSectionNode({ title, scrollTo, inView, hide })
    )
  }

  get html () {
    const { jss } = this.eis
    const options = { rootMargin: `-${TOP_MARGIN}px 0px -30px 0px` }

    return this.hide() ? [] : [
      <div class={jss.categoryTitle}
        style={`--category-row-count: ${this.parts.length + 1}`}
        ko-intersection={{ value: this.inView, options }}
        ko-scrollTo={this.scrollTo}>
        <div class={jss.categoryLabel}>{this.title}</div>
      </div>,
      ...this.parts,
    ]
  }
}
