
import JURISDICTIONS from './_jurisdictions'

const FLAG_URL_BASE = 'https://minutebox.media/app/flags/svg/'

export type tJurisdiction = {
  area: string
  flagURL: string
  iso: string
  state: string
  stateISO: string
  statutes: string[]
  priority?: number
}

// We use a "laconic" version to reduce the built files' size.
type tJurisdictionLaconic = [
  string, // 0 area
  string, // 1 iso (country or area)
  string, // 2 country (or '')
  string[], // 3 statutes
  number?, // priority
]

export { JURISDICTIONS }

export const PCT = {
  area: 'Patent Cooperation Treaty (PCT)',
  iso: 'un.wipo.pct',
  stateISO: '',
  statutes: [],
  flagURL: 'un',
  priority: 1,
} as tJurisdiction

const SPECIAL = [PCT] as tJurisdiction[]

const byArea = Object.assign({},
  ...JURISDICTIONS.map(j => ({ [j[0]]: j })),
  ...SPECIAL.map(j => ({ [j.area]: j })),
) as Record<string, tJurisdictionLaconic>

const byISO = Object.assign({},
  ...JURISDICTIONS.map(j => ({ [j[1]]: j })),
  ...SPECIAL.map(j => ({ [j.iso]: j })),
) as Record<string, tJurisdictionLaconic>

function deLaconic (j: tJurisdiction | tJurisdictionLaconic) : tJurisdiction {
  return Array.isArray(j) ? {
    area: j[0],
    flagURL: j[1].split('.').filter(c => c).join('/').toLowerCase(),
    iso: j[1],
    state: j[2] ? byISO[j[2]][0] : null,
    stateISO: j[2],
    statutes: j[3],
    priority: j[4] || 9,
  } : j ? j : null
}

/**
 * Find the jursdiction by its area name or iso identifier.
 */
export function lookup (isoOrName: string) : tJurisdiction {
  return deLaconic(byISO[isoOrName] || byArea[isoOrName])
}

export function isoList () : tJurisdiction[] {
  return JURISDICTIONS.map(deLaconic)
}

export function flagUrlOf (isoOrName: string) : string {
  const j = lookup(isoOrName)
  return j ? FLAG_URL_BASE + j.flagURL + '.svg' : null
}

export function nameOf (isoOrName: string) : string {
  const j = lookup(isoOrName)
  return j ? j.area : isoOrName
}

export function isoOf (isoOrName: string) : string {
  const j = lookup(isoOrName)
  return j ? j.iso : isoOrName
}
