
import ViewComponent from 'ViewComponent'
import { computed } from 'utils/decorator'

import { inline } from 'icons'
import waitIcon from 'icons/light/hourglass'
import { buttons, color } from 'styles'

export default class ButtonOverText extends ViewComponent {
  text: string | KnockoutComputed<string>
  icon: JSX
  iconColor: string
  action: (evt: MouseEvent) => void
  working: KnockoutObservable<boolean> = ko.observable(false)

  constructor ({ text, icon, action, iconColor }) {
    super()
    Object.assign(this, { text, icon, iconColor, action })
  }

  @computed()
  iconToShow (): JSX | JSX[] {
    return inline(this.working() ? waitIcon : this.icon)
  }

  iconColorStyle (color = this.iconColor) {
    return color ? `--icon-color: ${color}` : ''
  }

  async trigger (evt) {
    if (this.working()) { return }
    this.working(true)
    try {
      await this.action(evt)
    } finally {
      this.working(false)
    }
  }

  static get css () {
    return {
      area: {
        ...buttons.clickable,
        margin: '0px 0.5vw',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        userSelect: 'none',
      },
      icon: {
        border: '1px solid rgba(120,120,128,0.2)',
        height: 22,
        display: 'flex',
        outline: 'none',
        minWidth: 37,
        alignItems: 'center',
        IconColor: '#737473',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center',
        textDecoration: 'unset',
        backgroundColor: 'rgba(120,120,128,0.2)',
        'body[dark] &': { // project batman
          '--icon-color': color.dark.toolBarIcon,
          backgroundColor: color.fill.dark.primary,
          border: `1px solid ${color.fill.dark.primary}`,
        },
      },
      text: {
        opacity: 1,
        marginTop: 6,
        transition: 'visibility 150ms, opacity 150ms ease-in-out',
        visibility: 'visible',
        '@media (max-width: 760px)': {
          visibility: 'hidden',
          opacity: '0',
        }
      },
    }
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.area} ko-ownClick={evt => this.trigger(evt)}>
        <div class={jss.icon} style={this.iconColorStyle}>
          {this.iconToShow}
        </div>
        <div class={jss.text}>
          {this.text}
        </div>
      </div>
    )
  }
}


ButtonOverText.register()
