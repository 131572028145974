

import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'


export class MunicipalBusinessLicense extends UnitView {
  get elvValue () { return `${this.description()} ${this.municipality()}` }
  get fields () {
    return {
      municipality: 'string',
      description: 'string',
      expiry: 'pureDate',
      registered: 'pureDate',
    }
  }
}

export default class MunicipalBusinessLicensesComponent extends ArrayComponent {
  get ItemClass () { return MunicipalBusinessLicense }
}

MunicipalBusinessLicensesComponent.register()
