import t from 't'

import { AssetTransferNumber, AssetCredit, AssetDebit } from '../AssetAction'
import { CertificateIssue, CertificateSurrender } from '../CertificateAction'

import { CapitalTransactionWithConsideration, CODES, WITH_CONSIDERATION } from './Transaction'
import { AssetTransaction } from './AssetTransaction'


export class TransactionTransfer extends AssetTransaction implements CapitalTransactionWithConsideration {
  amount: KnockoutObservable<string>
  transferee: KnockoutObservable<string>
  transferor: KnockoutObservable<string>
  transfereeCertificate: KnockoutObservable<number>
  residualCertificate: KnockoutObservable<number>
  cancelledCertificates: KnockoutObservableArray<number>
  trNumber: KnockoutObservable<number>
  considerationIsAggregate: KnockoutObservable<boolean>
  monetaryConsideration: KnockoutObservable<CurrencyAmount>
  nonMonetaryConsideration: KnockoutObservable<string>
  votingProxy: KnockoutObservable<string>

  constructor (params) {
    super(params)
    this.propertiesFromJS(params)
  }

  get code () { return CODES.CapitalTransfer }
  get propertiesToSerialize () { return [] }
  get arrayObservables () {
    return [...super.arrayObservables, 'cancelledCertificates']
  }

  get plainObservables () {
    return [
      ...super.plainObservables,
      ...WITH_CONSIDERATION,
      'transferor', 'transferee', 'amount', 'trNumber', 'transfereeCertificate',
      'residualCertificate',
    ]
  }

  * yieldActions () {
    const { trNumber, transferor, transferee, amount, assetClassID } = this
    yield new AssetTransferNumber(assetClassID, trNumber() || null)
    yield new AssetDebit(assetClassID, transferor(), amount())
    yield new AssetCredit(assetClassID, transferee(), amount())
    yield new CertificateIssue(
      this.assetClassID,
      this.residualCertificate(),
      this.transferor(),
    )
    yield new CertificateIssue(
      this.assetClassID,
      this.transfereeCertificate(),
      this.transferee(),
      this.residualCertificate() ? 0 : 1,
    )
    yield * [...this.cancelledCertificates]
      .map(c => new CertificateSurrender(this.assetClassID, c, this.transferor()))
  }

  shortDescription (cs: CapitalState) {
    const {amount, transferor, transferee} = this
    return t.CAPITAL_SHARE_TRANSFER(amount, this.assetNameAt(cs), transferor, transferee)
  }
}
