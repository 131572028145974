import { inline } from 'icons'
import unlinkIcon from 'icons/solid/unlink'

import {
  LeftPanelSection, LeftPanelView,
  JSXContent, ViewID
} from 'minute-box-app/panel-left'

import { makeLink } from './makeLink'

import './basic-table'
import { TableRow } from './basic-table'
import './tab-container'
import GenericSection from 'minute-box-app/panel-left/GenericSection'
import { partsToHTML, partsToString } from 'DataModel/components/AddressesComponent'
import { mergeBy, roleDisplayTitle } from './utils'
import { PersonOriginModel } from './interfaces'

type PersonPanelProvider = import('./PersonPanelProvider').default

type ValueOrigins<T> = {
  value: T,
  origins: PersonOrigin[],
}

export default class PersonSummaryView extends LeftPanelView {
  get id() : ViewID { return 'person' }
  get title() : JSXContent { return 'Person Information Summary' }

  person : PersonRecord | KnockoutObservable<PersonRecord>

  private _sections : KnockoutObservableArray<LeftPanelSection> = ko.observableArray()
  get sections () { return this._sections }

  constructor (public panelProvider : PersonPanelProvider) {
    super(panelProvider)
    this.person = panelProvider.person
    panelProvider.computed(() => {
      this.sections(this.makeSections())
    })
  }

  get computedPerson () : KnockoutComputed<PersonRecord> {
    return this._computedPerson || (this._computedPerson = this.panelProvider.computed(() => {
      const person = ko.unwrap(this.person)
      if (!person) { return null }
      const isUsFilter = person.id
        ? (p:PersonRecord) => p.id === person.id
        : (p:PersonRecord) => !p.id && p.name.some(n => person.name.includes(n))
      const personGen = function * () {
        for (const origin of person.origin) {
          yield * origin.model.getPersons(isUsFilter)
        }
      }
      const merged = [...mergeBy(personGen(), person.id ? 'id' : 'name')]
      if (merged.length !== 1) {
        console.warn(`Expected a single merged person for id=${person.id} but got ${merged.length}`)
      }
      return merged.pop()
    }))
  }

  /**
   * Re-query each model to build a list of origins specific to each value of the
   * specified PersonRecord field.
   *
   */
  getValueOriginsFor<T> (prop : keyof PersonRecord,
      valueAccessor = value => value,
      valueEquals = (a, b) => a === b) : ValueOrigins<T>[] {

    const person = ko.unwrap(this.person)
    prop = prop.toLowerCase()

    const isUsFilter = person.id
      ? (p:PersonRecord) => p.id === person.id
      : (p:PersonRecord) => !p.id && p[prop].some(n => person[prop].includes(n))

    const personsByModel  =
      person.origin
        .map(origin => ({
          origin,
          persons: [...origin.model.getPersons()].filter(isUsFilter)
        }))
        .filter(({persons}) => persons.length)

    const fields : ValueOrigins<T>[] =
      person[prop].map(value => {
        const set = new Set<string>()
        const allOrigins = personsByModel
          .filter(({persons}) => (persons.some(p => p[prop].some(v => valueEquals(v,value)))))
          .map(({origin}) => origin)
        const selfOrigins = allOrigins
          .filter(o => [...o.roles()].find(r => r.title === 'Entity' || r.title === 'User'))
          .filter(o => set.has(o.model.cvModelTitle) ? false : set.add(o.model.cvModelTitle))
        const otherOrigins = allOrigins
          .filter(o => set.has(o.model.cvModelTitle) ? false : set.add(o.model.cvModelTitle))
        return {
          value: valueAccessor(value),
          origins: [...selfOrigins, ...otherOrigins],
        } as ValueOrigins<T>
      })

    return fields
  }

  unlinkPerson (origin: PersonOrigin) {
    ko.unwrap(this.person).origin
      .filter(o => o.model === origin.model)
      .forEach(o => {
        o.update(p => p.id = null)
      })
    origin.model.vmSave()
  }

  makeOriginsHTML (origins : PersonOrigin[]) : JSXContent {
    const { app, jss } = this.panelProvider
    const person = ko.unwrap(this.person)
    const link = o => {
      const fn = makeLink(app, o)
      return () => fn()
    }
    const showUnlink = (o:PersonOrigin) =>
      Boolean(person.id) &&
      ![...o.roles()].find(r => r.title === 'Entity' || r.title === 'User')
    return (
      origins.flatMap(o => (
        <div class={jss.tableItem}>
          <div class={jss.originLink} ko-click={link(o)}>
            {o.model.cvModelTitle}
          </div>
          <div class={jss.originUnlink}
            ko-visible={showUnlink(o)}
            ko-click={() => this.unlinkPerson(o)}>
            {inline(unlinkIcon)}
          </div>
        </div>
      ))
    )
  }

  makeBasicValueOriginsTableHTML<T> (header : string, fields : ValueOrigins<T>[], title = null) : JSXContent {
    if (!fields.length) { return null }
    const valueColumn = fields.map(f => f.value)
    const sourceColumn = fields.map(f => this.makeOriginsHTML(f.origins))
    return <basic-table headers={[header, 'Source']} columns={[valueColumn, sourceColumn]} name={title}/>
  }

  makeNameSection () : LeftPanelSection {
    const name = 'Name'
    const { jss } = this.panelProvider
    const fields = ko.observableArray([])
    this.panelProvider.computed(() => {
      fields([])
      const person = ko.unwrap(this.person)
      if (!person) { return }
      const title = (
        <div class={jss.indicator}>{person.type}</div>
      )
      const tableHTML = this.makeBasicValueOriginsTableHTML(name, this.getValueOriginsFor('name'), title)
      if (tableHTML) { fields.push(tableHTML) }
    })
    return new GenericSection(this.panelProvider, name, fields)
  }

  makeBasicValueOriginsSection (prop : keyof PersonRecord) : LeftPanelSection {
    const name = prop[0].toUpperCase() + prop.slice(1)
    const tables = ko.observableArray([])
    this.panelProvider.computed(() => {
      const tableHTML = this.makeBasicValueOriginsTableHTML(name, this.getValueOriginsFor(prop))
      tables(tableHTML ? [tableHTML] : [])
    })
    return new GenericSection(this.panelProvider, name, tables)
  }

  makeAddressTableHTML () : JSXContent {
    const addressEquals : (a:AddressRecord, b:AddressRecord) => boolean = (a, b) => partsToString(a) === partsToString(b)
    const valueOrigins = this.getValueOriginsFor<AddressRecord>('address', v => v, addressEquals)
    if (!valueOrigins.length) { return null }
    const typeColumn = valueOrigins.map(vo => vo.value.title)
    const addressColumn = valueOrigins.map(vo => partsToHTML(vo.value.parts))
    const sourceColumn = valueOrigins.map(vo => this.makeOriginsHTML(vo.origins))
    const headers = ['Type', 'Address', 'Source']
    return <basic-table headers={headers} columns={[typeColumn, addressColumn, sourceColumn]} />
  }

  makeContactSection () {
    const tables : KnockoutObservableArray<JSXContent> = ko.observableArray([])
    this.panelProvider.computed(() => {
      tables([
        this.makeBasicValueOriginsTableHTML('Phone', this.getValueOriginsFor('phone')),
        this.makeAddressTableHTML(),
      ].filter(v => v))
    })
    return new GenericSection(this.panelProvider, 'Contact', tables)
  }

  makeRoleFields () : ValueOrigins<string>[] {
    const person = ko.unwrap(this.computedPerson)
    const roleSet = new Set<string>()
    const roles = person.origin
      .flatMap(origin => [...origin.roles()])
      .filter(role => roleSet.has(role.title) ? false : roleSet.add(role.title))
    return roles.map(role => {
      const originSet = new Set<PersonOriginModel>()
      return {
        value: roleDisplayTitle(role),
        origins: person.origin
          .filter(origin => (
            [...origin.roles()].some(r => r.title === role.title)
          ))
          .filter(origin => originSet.has(origin.model) ? false : originSet.add(origin.model))
      } as ValueOrigins<string>
    })
  }

  makeAffiliationsTableRows () : TableRow[] {
    const person = ko.unwrap(this.computedPerson)
    if (!person) { return [] }
    const { jss } = this.panelProvider

    const originSet = new Set<string>()
    const uniqueOrigins = person.origin.filter(o =>
      originSet.has(o.model.cvModelTitle) ? false : originSet.add(o.model.cvModelTitle))

    return uniqueOrigins.map(origin => {
      const set = new Set<string>()
      return [
        this.makeOriginsHTML([origin]),
        person.origin
          .filter(o => (o.model.cvModelTitle === origin.model.cvModelTitle))
          .flatMap(o => ([...o.roles()]))
          .map(r => roleDisplayTitle(r))
          .filter(t => set.has(t)? false : set.add(t))
          .map(t => (
            <div class={jss.tableItem}>{t}</div>
          )),
      ]
    })
  }

  makeAffiliationsTableHTML () : JSXContent {
    const headers = [ 'Entity', 'Roles' ]
    const rows = ko.observableArray(this.makeAffiliationsTableRows())
    this.panelProvider.computed(() => this.makeAffiliationsTableRows()).subscribe(rows)
    return <basic-table headers={headers} rows={rows} />
  }

  makeRolesSection () {
    const tabs = [
      { name: 'Roles', content: this.makeBasicValueOriginsTableHTML('Roles', this.makeRoleFields()) },
      { name: 'Affiliations', content: this.makeAffiliationsTableHTML() },
    ]
    const tabContainer = <tab-container tabs={tabs} />
    return new GenericSection(this.panelProvider, 'Relationships', ko.observableArray([tabContainer]))
  }

  makeSections () {
    const person = ko.unwrap(this.computedPerson)
    if (!person) { return [] }

    return [
      this.makeNameSection(),
      this.makeContactSection(),
      this.makeBasicValueOriginsSection('email'),
      this.makeRolesSection(),
    ]
  }

  get main () {
    const { person, app, showAllFields } = this.panelProvider
    return (
      <person-information-summary
        model={person}
        app={app}
        showAllFields={showAllFields}
        sections={this.sections} />
    )
  }
}
