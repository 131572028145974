//
// ArrayMediator
// ---
// Serialize and deserialize an observable array of plain values.
//
import MediatorInterface from './MediatorInterface'


export default class ArrayMediator extends MediatorInterface {
  newHolderInstance() { return ko.observableArray([]) }
  toJS() { return ko.toJS(this.model[this.name]) }
  fromJS(val) {
    this.model[this.name](val || [])
  }
}
