
type Slot = import('./Slot').default

export enum UX_STATE {
  dormant = 0x00,
  hover = 0x01,
  peerHover = 0x02,
  selected = 0x04,
  peerSelected = 0x08,
}

/**
 * A `SlotVariable`
 */
export interface SlotVariable {
  propagateTo: (...v: SlotVariable[]) => void
  /**
   * Slots needed by identity are e.g. `all` or `document`.
   */
  uxState: KnockoutObservable<UX_STATE>
  slotIndex: KnockoutObservable<number|string>
  slotGroupTitle: string
  slotTitleText: string
  isInSlot: (s: Slot) => boolean
  changeUxState: (state: UX_STATE, toggle: boolean) => void
}
