
import PersonPicker from './person-picker'

export default class ClientPicker extends PersonPicker {
  _observedName: KnockoutComputed<string>

  static get css () {
    return {
      ...super.css,
      itemValue: {},
      shortcut: {
        display: 'none',
      },
    }
  }

  get shortcutKeyboardShortcuts () { return {} }
}

export class ClientPickerModal extends ClientPicker {
  static get css () {
    return {
      ...super.css,
      input: {
        fontSize: 15,
        padding: '8px 20px',
        borderRadius: 6,
        minWidth: '250px',
        border: '1px solid transparent',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)'
      },
      '&:focus, &:active': {
        outline: 'none',
        borderLeft: '3px solid #ffd502',
        borderRadius: '3px'
      },
    }
  }
}

ClientPicker.register()
ClientPickerModal.register()
