/**
 * Elliptical Curve Cryptography
 * ---
 *
 * These are some utility functions in lieu of:
 *
 *    https://stackoverflow.com/questions/53565921
 *
 * The curves follow the form:
 *
 *      y² = x³ + ax + b
 *
 * From http://www.secg.org/SEC2-Ver-1.0.pdf
 *  and https://www.ietf.org/rfc/rfc4754.txt
 *  and https://tools.ietf.org/html/rfc5903#page-5
 *  and http://www-cs-students.stanford.edu/~tjw/jsbn/ecdh.html
 *  aka https://username1565.github.io/ECDH/
 *
 */

import BigInteger from 'big-integer'

export type BigPoint = {
  x: BigIntType,
  y: BigIntType,
}
// Object.assign(global, { BigInteger })

export interface Curve {
  name: string
  bits: number
  p: BigIntType
  a: BigIntType
  b: BigIntType
  g: BigIntType
  gx: BigIntType
  gy: BigIntType

}

export const p256 : Curve = {
  name: 'P-256',
  bits: 256,
  p: BigInteger('FFFFFFFF00000001000000000000000000000000FFFFFFFFFFFFFFFFFFFFFFFF', 16),
  a: BigInteger(-3),
  b: BigInteger('5AC635D8AA3A93E7B3EBBD55769886BC651D06B0CC53B0F63BCE3C3E27D2604B', 16),
  g: BigInteger('FFFFFFFF00000000FFFFFFFFFFFFFFFFBCE6FAADA7179E84F3B9CAC2FC632551', 16),
  gx: BigInteger('6B17D1F2E12C4247F8BCE6E563A440F277037D812DEB33A0F4A13945D898C296', 16),
  gy: BigInteger('4FE342E2FE1A7F9B8EE7EB4A7C0F9E162BCE33576B315ECECBB6406837BF51F5', 16)
}

export const p384 = {
  name: 'P-384',
  bits: 384,
  p: BigInteger('FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEFFFFFFFF0000000000000000FFFFFFFF', 16),
  b: BigInteger('B3312FA7E23EE7E4988E056BE3F82D19181D9C6EFE8141120314088F5013875AC656398D8A2ED19D2A85C8EDD3EC2AEF', 16),
  g: BigInteger('FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFC7634D81F4372DDF581A0DB248B0A77AECEC196ACCC52973', 16)
}

/**
 * Return the positive i, 0 <= n < p
 */
function absMod (n: BigIntType, p: BigIntType) : BigIntType {
  return n.lt(0) ? n.mod(p).plus(p) : n.mod(p)
}

/**
 * Modular inverse, using Fermat's Little Theorem
 */
function modInverse (n: BigIntType, p: BigIntType) : BigIntType {
  return n.modInv(p)
}

// The following are some Elliptical Curve point manipulations.
// https://en.wikipedia.org/wiki/Elliptic_curve_point_multiplication
export function pointAdd (xp: BigIntType, yp: BigIntType, xq: BigIntType, yq: BigIntType, p: BigIntType): BigPoint {
  const numer = yq.minus(yp)
  const denom = modInverse(xq.minus(xp), p)
  const lambda = (numer.multiply(denom)).mod(p)
  const x = absMod(lambda.pow(2).minus(xp).minus(xq), p)
  const y = absMod(lambda.multiply(xp.minus(x)).minus(yp), p)
  return { x, y }
}

export function pointDouble (xp: BigIntType, yp: BigIntType, a: BigIntType, p: BigIntType) : BigPoint {
  const numer = xp.pow(2).multiply(3).plus(a)
  const denom = modInverse(yp.multiply(2), p)
  const lambda = (numer.multiply(denom)).mod(p)
  const x = absMod(lambda.pow(2).minus(xp.multiply(2)), p)
  const y = absMod(lambda.multiply(xp.minus(x)).minus(yp), p)
  return { x, y }
}

export function pointMultiply (d: BigIntType, xp: BigIntType, yp: BigIntType, a: BigIntType, p: BigIntType) : BigPoint {
  const add = (xp, yp, { x, y }) => pointAdd(xp, yp, x, y, p)
  const double = (x, y) => pointDouble(x, y, a, p)
  const recur = ({ x, y }, n) => {
    if (n.eq(0)) { return { x: BigInteger(0), y: BigInteger(0) } }
    if (n.eq(1)) { return { x, y } }
    if (n.mod(2).eq(1)) { return add(x, y, recur({ x, y }, n.minus(1))) }
    return recur(double(x, y), n.divide(2))
  }
  return recur({ x: xp, y: yp }, d)
}
