
import ViewComponent from 'ViewComponent'

import { buttons } from 'styles'
import icons from 'icons'

const HIDE_AFTER_MS = 5 * 1000

export default class OutcomeNotification extends ViewComponent {
  constructor ({ notification, manager }, ...args) {
    super({}, ...args)
    Object.assign(this, {
      manager,
      notification
    })
  }

  static get css () {
    return {
      ...super.css,
      outcome: {
        display: 'grid',
        gap: '10px',
        gridTemplateColumns: 'auto 1fr auto auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        backgroundColor: 'rgba(247, 250, 247, 0.95)',
        border: '1px solid #8dff8d',
        boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.1)',
        borderRadius: '6px',
        'body[dark] &': { // project batman
          '--icon-color': 'black',
          backgroundColor: 'rgba(44, 44, 44, 0.95)',
          border: '1px solid #008a00',
        },
      },
      icon: {
        '--icon-color': '#7ed321'
      },
      message: {},
      actions: {},
      close: {
        ...buttons.clickable,
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    }
  }

  hide () {
    this.manager.hideOutcomeImmediately(this.notification)
  }

  stopAutoHide () {
    clearTimeout(this._hideTimeout)
  }

  async startAutoHide () {
    this._hideTimeout = setTimeout(() => {
      this.manager.hideOutcome(this.notification)
    }, global.OUTCOME_NOTIFICATON_HIDE_AFTER_MS || HIDE_AFTER_MS)
  }

  get template () {
    const { jss, notification } = this
    this.startAutoHide()
    const iconHTML = notification.icon
      ? icons.inline(notification.icon) : undefined
    const overEvent = {
      mouseenter: () => this.stopAutoHide(),
      mouseleave: () => this.startAutoHide()
    }

    const style = { ...jss, ...notification.style }

    return (
      <div class={style.outcome} ko-event={overEvent}>
        <div class={style.icon}> {iconHTML} </div>
        <div class={style.message}> {notification.message} </div>
        <div class={style.actions}> {notification.actions} </div>
        <div class={style.close} ko-click={() => this.hide()}> Close </div>
      </div>
    )
  }
}

OutcomeNotification.register()
