
import { ServiceProvider } from 'EntityModel/components'

import { SERVICE_PROVIDER_PROVIDES } from '../constants'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisServiceProviders extends EisArrayComponentTable<ServiceProvider> {
  get caption () { return 'Service Providers' }
  get componentName () { return 'service_providers' }
  get listAddName () { return 'Service Provider' }
  get columns () { return ['name', 'provides', 'contact'] }
  get providesModelPickerChoices () { return SERVICE_PROVIDER_PROVIDES }
}

EisServiceProviders.register()
