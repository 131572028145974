/**
 * mbAdmin is a global for accessing administrative functions.
 */

import { importPdfAsEntity, importFromGsUploads } from 'import'
import initializeAccount from './initializeAccount'

import 'first-login-modals'

/**
 * Outcome Design items
 */
import ConfirmModelDelete from 'confirm-delete/confirm-model-delete'
import AutoSave from 'views/auto-save'
import MinuteBookPanelProvider from 'MinuteBookPanelProvider'
import AppLoadingPanelProvider from 'minute-box-app/AppLoadingPanelProvider'
import TermsOfServicePanelProvider from 'minute-box-app/TermsOfServicePanelProvider'

import ModelEditor from 'ModelEditor'
import PdfPages from 'pdf-viewer/pdf-pages'
import UserInvite from 'user-invite'

async function renderAllPdfImages () {
  const { entityList } = window.app.rootProviders
  for (const entity of entityList.criteria.hits()) {
    const legalName = entity.answerFor('legalname')()
    console.log(legalName, `Rendering started`)
    const pages = entity.answerFor('book').pages()
    const renderingPromises = pages.map(p => p.getOrBuildHires())
    console.info(legalName, `Rendering queued, ${pages.length} pages`)
    await Promise.all(renderingPromises)
    console.info(legalName, `Rendering complete`)
  }
  console.log(`Completed rendering all pages.`)
}

async function designOutcomeNotifications () {
  const { notifier } = window.app
  notifier.hideDelayMS = 10 ** 9
  window.OUTCOME_NOTIFICATON_HIDE_AFTER_MS = 10 ** 9

  const as = new AutoSave({})
  notifier.pushOutcome(...as.unableToSaveNotification)

  const model = { cvModelTitle: '{Model-Title}' }
  const cmd = new ConfirmModelDelete({ model })
  notifier.pushOutcome(...cmd.deleteNotification)
  notifier.pushOutcome(...cmd.unableToDeleteNotification)

  notifier.pushOutcome(
    ...MinuteBookPanelProvider.prototype.pageDeleteNotification(10))

  notifier.pushOutcome(
    ...ModelEditor.prototype.newEntityNotification(model))
  notifier.pushOutcome(
    ...ModelEditor.prototype.unableToNotification('save', model))

  notifier.pushOutcome(...PdfPages.prototype.deletePageNotification)

  console.log(`UI`, UserInvite.prototype.showInviteNotification)

  notifier.pushOutcome(
    ...UserInvite.prototype.showInviteNotification('name@domain.com'))

}

export default {
  initializeAccount,
  importPdfAsEntity,
  importFromGsUploads,
  renderAllPdfImages,
  designOutcomeNotifications,
  showLoadingPanel () {
    app.setPanelProvider(AppLoadingPanelProvider)
  },
  design: {
    outcomeNotifications () {
      designOutcomeNotifications()
    },

    thingsHaveChanged () {
      window.app.modal(
        <things-have-changed-modal
          app={window.app}
          userID={true}
          currentBuild={window.ABOUT_MINUTEBOX.BUILD_NUMBER} />
      )
    },

    firstAccountLoginModal () {
      window.app.modal(<first-user-account-login-modal />)

    },

    firstShareLoginModal () {
      window.app.modal(<first-user-share-login-modal />)
    },

    termsOfService () {
      window.app.setPanelProvider(TermsOfServicePanelProvider)
    },
  }
}
