import { AssetTransferNumber, AssetCredit, AssetDebit } from '../AssetAction'
import { CapitalTransactionWithConsideration, WITH_CONSIDERATION } from './Transaction'
import { AssetTransaction } from './AssetTransaction'
import CapitalAction from '../CapitalAction'

export abstract class CapitalUnilateralTransfer extends AssetTransaction implements CapitalTransactionWithConsideration {
  amount: KnockoutObservable<string>
  person: KnockoutObservable<string>
  trNumber: KnockoutObservable<number>
  considerationIsAggregate: KnockoutObservable<boolean>
  monetaryConsideration: KnockoutObservable<CurrencyAmount>
  nonMonetaryConsideration: KnockoutObservable<string>
  votingProxy: KnockoutObservable<string>

  constructor (params) {
    super(params)
    this.propertiesFromJS(params)
  }

  get plainObservables () {
    return [
      ...super.plainObservables,
      ...WITH_CONSIDERATION,
      'person', 'amount', 'trNumber', 'monetaryConsideration', 'nonMonetaryConsideration',
      'votingProxy', 'considerationIsAggregate',
    ]
  }

  get propertiesToSerialize () { return [] }

  * yieldActions () : IterableIterator<CapitalAction> {
    const { assetClassID } = this
    yield new this.ActionClass(assetClassID, this.person(), this.amount())
    const trNumber = this.trNumber()
    yield new AssetTransferNumber(assetClassID, trNumber || null)
  }

  abstract get ActionClass(): typeof AssetCredit | typeof AssetDebit;
}
