import LeftPanelSection from './LeftPanelSection'
import { JSXContent } from "./types"

type PanelProvider = import("PanelProvider").default

export default class GenericSection extends LeftPanelSection {
  constructor (
    public panelProvider : PanelProvider,
    public title : JSXContent,
    public fields: KnockoutObservableArray<JSXContent>) {
      super(panelProvider)
    }
}
