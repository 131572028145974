
const WIN_KEY = '❖' // ⊞ or ❖
const CMD_KEY = '⌘'

export function isMac () {
  return navigator.platform.includes('Mac')
}

export function metaKey () {
  return isMac() ? CMD_KEY : WIN_KEY
}

/**
 * Expose online/offline events for debugging (and eventual UX):
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events
 */
export const online = ko.observable()

const updateOnlineStatus = () => online.modify(() => navigator.onLine)
window.addEventListener('load', updateOnlineStatus)
window.addEventListener('offline', updateOnlineStatus)
window.addEventListener('online', updateOnlineStatus)

online.subscribe(s =>
  console.info(`  🌐  Network status: ${s ? 'online' : 'offline'}`)
)
