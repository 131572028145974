
/**
 * Create a new account for the given admin-user.
 */
export default async function initializeAccount (accountAdminEmail, authManager = window.app.defaultAuthManager) {
  console.log(`  ℹ️  Using authManager project`, authManager.projectID)
  const accountID = await authManager.firebaseFn('setupAccount')
  console.log(` ✅  Created account`, accountID)

  const userID = await authManager.firebaseFn('setupAdminUser',
    { email: accountAdminEmail, accountID })

  console.log(` ✅  Created user: `, userID)
}
