
import EisField from './EisField'
import { whenHeightStabilizes } from 'utils/dom'
type DataComponent = import('DataModel').DataComponent

export default class EisSingleComponentField<T extends DataComponent = DataComponent> extends EisField {
  private _componentName: string

  constructor (params) {
    super(params)
    this._componentName = params.componentName
    const { eis } = params
    if (eis.scrollToComponent) {
      const name = this.componentName
      if (name === eis.scrollToComponent) {
        whenHeightStabilizes(document.body).then(() => {
          window.scrollTo(0, document.body.scrollHeight)
          this.focused(true)
        })
      }
    }
  }

  get fieldTitle () { return this.component.title }

  get isBlank () {
    return ko.pureComputed(() => this.component.isBlank())
  }

  get componentName () { return this._componentName }

  get component () : T {
    return this.componentName
      ? this.entity.componentFor(this.componentName) as T
      : null
  }

  get titleHTML () {
    return (<span>{this.fieldTitle}</span>)
  }
}
