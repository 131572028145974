import t from 't'

import { AssetZero, AssetDeauthorize } from '../AssetAction'

import { CODES } from './Transaction'
import { TransactionAuthorize } from './TransactionAuthorize'

export class TransactionAmend extends TransactionAuthorize {
  get deleteable () { return true }
  get code () { return CODES.CapitalAmend }
  shortDescription () { return t.CAPITAL_AMENDED(this.classification) }
}


export class TransactionDeauthorize extends TransactionAmend {
  get code () { return CODES.CapitalDeauthorize }
  shortDescription () { return t.CAPITAL_DEAUTHORIZED(this.classification) }
  * yieldActions () {
    yield new AssetZero(this.assetClassID)
    yield new AssetDeauthorize(this.assetClassID)
  }

}
