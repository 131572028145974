
import { min } from 'lodash-es'
import { fromUnixTime, isDate } from 'date-fns/esm'

import 'tool-tip'
import { tagColor } from 'styles'

import { inline } from 'icons'
import yesIcon from 'icons/solid/check-circle'
import checkedIcon from 'icons/light/check-square'
import uncheckedIcon from 'icons/light/square'

import './table-column'

import { formatForUser, formatLongForUser } from 'utils/dates'

import {
  stringFilterOperations, intFilterOperations, timestampFilterOperations,
  stringArrayFilterOperations, tagFilterOperations, boolFilterOperations,
} from './FilterOperation'

const columnConstructors = {}

/**
 * @interface ColumnInterface sets the properties needed for a Column
 */
export abstract class ColumnInterface extends ko.LifeCycle {
  columnWidth: number
  show: KnockoutObservable<boolean>

  /**
   * Return a short identifier for this filter that's saved
   * to the database and used to identify the column type when
   * loaded.
   *
   * ☢️ Once data is saved to the database (especially in
   *    production) these IDs should never be changed.
   */
  static get immutableID (): string { return '' }

  constructor (title, givenColumnID) {
    super()
    Object.assign(this, {
      title,
      givenColumnID,
      show: ko.observable(true),
    })
  }

  get immutableID (): string { return this.constructor.immutableID }
  abstract get columnID (): string
  abstract get reportable (): boolean
  abstract get sortable (): boolean
  abstract get filterable (): boolean
  abstract get searchable (): boolean
  abstract get resizable (): boolean
  abstract get reorderable (): boolean
  abstract get defaultColumnWidth (): number
  abstract renderValue (row, jss): any
  abstract reportValue (row): any
  abstract sortableValue (row): any
  abstract filterValue (row): any
  abstract get titleElement (): any
  searchMatches (row, query): any {}

  /**
   * Render the given row.
   *
   * Leave this abstraction open for future uses, including overloading
   * for special types of columns.
   * @return {JSX}
   */
  render (row, jss) {
    return this.computed(() => this.renderValue(row, jss))
  }
}

/**
 * A Column with all the typical features of builtin Columns.
 */
export abstract class Column extends ColumnInterface {
  /**
   * @param {string} title the column
   * @param {object} options that may overwrite the class properties e.g.
   *  `show` may be a computed; renderValue/etc., may be customized.
   */
  constructor (title, columnID, options) {
    super(title, columnID)

    Object.assign(this, options)

    if (!this.columnWidth) { this.columnWidth = this.defaultColumnWidth }
    this.filterOperations = this.filterable ? this.makeFilterOperations() : []
  }

  /**
   * @return {function|primitive|array} Sortable value
   */
  sortableValue (row) { }

  /**
   * The value for generated PDF reports, passed to the server-size
   * Python function `generateReports`.
   * @return {string}
   */
  reportValue (row) { }

  /**
   * @return {bool} true when this column can be part of a report (and .show is true)
   *
   * This is falsy for e.g. the Actions columns which just have UI values.
   */
  get reportable () { return true }
  get sortable () { return true }
  get filterable () { return false }
  get titleElement () { return this.title }

  /**
   * @return {string|JSX} to be rendered
   */
  renderValue (row, jss) { }

  /**
   * @return {Array.<FilterOperation>}
   */
  makeFilterOperations () { return [] }
  filterValue (row) { return this.sortableValue(row) }

  get resizable () { return true }
  get reorderable () { return true }

  /**
   * The default column width.
   */
  get defaultColumnWidth () { return 180 }

  /**
   * Return a static short identifier for this filter.
   */
  static get immutableID (): string { return '' }
  get immutableID () { return this.constructor.immutableID }

  /**
   * The Column ID is used for loading/saving; if we change
   * the class names in the future, we'll have to overload the
   * columnID to preserve filter values.
   */
  get columnID () {
    if (!this.givenColumnID) {
      throw new Error(`Column: No givenColumnID ${this.title}`)
    }
    return this.givenColumnID
  }

  /**
   * @return {Constructor.Column}
   */
  static ctrFor (immutableID) {
    const Ctr = columnConstructors[immutableID]
    if (!Ctr) {
      throw new Error(`Column type unknown: ${immutableID}.`)
    }
    return Ctr
  }

  /**
   * @constructs Column of the given type
   * @return {Column}
   */
  static create (type, title, columnID, ...params) {
    const ColumnCtr = this.ctrFor(type)
    return new ColumnCtr(title, columnID, ...params)
  }

  static register () {
    columnConstructors[this.immutableID] = this
  }
}

/**
 * Idempotent columns have basic values e.g. strings.  They are sorted,
 * reported, and rendered using just that value.
 */
export class IdempotentColumn extends Column {
  static get immutableID () { return 'idem' }
  /**
   * @param {string} title
   * @param {function|string} getterStringOrFunction that gets the value from
   * the row.  As a string it will get it from either the model with:
   *    row[getterStringOrFunction])
   * or from the content with:
   *    row.answerFor(getterStringOrFunction)
   * @param {object} options
   */
  constructor (title, columnID, options = {}) {
    super(title, columnID, options)
    const getterStringOrFn = options.getter || columnID
    const getter = typeof getterStringOrFn === 'string'
      ? row => row[getterStringOrFn] || row.answerFor(getterStringOrFn)
      : getterStringOrFn
    Object.assign(this, { getter })
  }

  reportValue (row) { return ko.unwrap(this.getter(row)) }
  renderValue (row) { return ko.unwrap(this.getter(row)) || '—' }
  sortableValue (row) { return ko.unwrap(this.getter(row)) }
  get filterable () { return this.makeFilterOperationFn }
  makeFilterOperations () { return this.makeFilterOperationFn() }
  get makeFilterOperationFn () { return null }
  get searchable () { return true }
  searchMatches (row, query) {
    return String(ko.unwrap(this.getter(row)) || '')
      .toLowerCase().includes(query.toLowerCase())
  }
}

IdempotentColumn.register()

/**
 *    StringColumn
 */
class StringColumn extends IdempotentColumn {
  static get immutableID () { return 'string' }
  sortableValue (row) { return (super.sortableValue(row) || '').toLowerCase() }
  get makeFilterOperationFn () { return stringFilterOperations }
}

StringColumn.register()

/**
 *    BooleanColumn
 */
export class BooleanColumn extends IdempotentColumn {
  static get immutableID () { return 'bool' }
  get makeFilterOperationFn () { return boolFilterOperations }
  renderValue (row) {
    return ko.unwrap(this.getter(row)) ? inline(yesIcon) : '—'
  }
}

BooleanColumn.register()

class SharingColumn extends BooleanColumn {
  static get immutableID () { return 'sharing' }
  constructor (title, columnID, options) {
    super(title, columnID, {
      getter: row => row.sharing.sharingRights().length > 0,
      ...options,
    })
  }

  renderValue (row) {
    return (
      this.getter(row) ?
        <tool-tip my='top right' at='bottom right'>
          <template slot='anchor'>
            <div>{super.renderValue(row)}</div>
          </template>
          <template slot='content'>
            {row.sharing.sharingRights.map(sr => <div>{sr.email}</div>)}
          </template>
        </tool-tip> :
        super.renderValue(row)
    )
  }
}
SharingColumn.register()

/**
 *    LinkColumn
 */
class LinkColumn extends StringColumn {
  static get immutableID () { return 'link' }
  href (value) { return value }
  renderValue (row) {
    const value = this.getter(row)
    const href = this.href(value)
    return <a href={href}>{value}</a>
  }
}

LinkColumn.register()

/**
 *    EmailColumn
 */
class EmailColumn extends LinkColumn {
  static get immutableID () { return 'email' }
  href (value) { return `mailto:${value}` }
}

EmailColumn.register()

/**
 *    IntegerColumn
 */
class IntegerColumn extends IdempotentColumn {
  static get immutableID () { return 'int' }
  sortableValue (row) { return parseInt(super.sortableValue(row), 10) }
  get makeFilterOperationFn () { return intFilterOperations }
}

IntegerColumn.register()

/**
 * Column for an array of primitives.
 */
class ArrayColumn extends IdempotentColumn {
  static get immutableID () { return 'array' }

  get makeFilterOperationFn () { return stringArrayFilterOperations }
  array (row) { return ko.unwrap(this.getter(row)) || [] }
  reportValue (row) { return this.array(row).join(', ') }
  sortableValue (row) { return min(this.array(row)) }
  filterValue (row) { return this.array(row) }
  get filterable () { return true }
  get reportable () { return true }
  searchMatches (row, q) { return this.array(row).some(t => t.startsWith(q)) }
  renderValue (row) {
    const items = this.array(row).map(elvValue => ({ elvValue }))
    return <table-column items={items} />
  }
}

ArrayColumn.register()

const BLANK_ELV_VALUE = { elvValue: '' }
export class TableColumn extends IdempotentColumn {
  static get immutableID () { return 'table' }
  get makeFilterOperationFn () { return stringArrayFilterOperations }

  itemsForRow (row) {
    const seen = new Set()
    return (ko.unwrap(this.getter(row)) || []).filter(v => {
      if (seen.has(v.elvValue)) { return false }
      seen.add(v.elvValue)
      return true
    })
  }

  filterValue (row) {
    return this.itemsForRow(row).map(v => ko.unwrap(v.elvValue))
  }

  renderValue (row) {
    return <table-column items={this.itemsForRow(row) || []} />
  }

  sortableValue (row) {
    const items = this.itemsForRow(row)
    return items.length ? items[0].elvValue : BLANK_ELV_VALUE
  }

  searchMatches (row, query) {
    return (this.itemsForRow(row) || [])
      .some(r => (ko.unwrap(r.elvValue) || '')
        .toLowerCase().includes(query.toLowerCase()))
  }

  reportValue (row) { return this.itemsForRow(row).map(r => r.elvValue).join(', ') }
  get reportable () { return true }
  get searchable () { return true }
}

TableColumn.register()


abstract class TagsColumn extends ArrayColumn {
  constructor (columnID, private panelProvider) { super('Tags', columnID) }
  style (t) { return `color: ${tagColor(t)}` }
  array (row) { return row.tags() }
  renderValue (row) {
    const { jss } = this.panelProvider
    const tags = row.tags().map(t => ({
      htmlValue: <span class={jss.tag} style={this.style(t)}>{t}</span>,
      elvValue: t,
    }))
    return <table-column items={tags} />
  }
}

export class EntityTagsColumn extends TagsColumn {
  /**
   * For legacy data (pre Apr 2019), tags were added and applied
   * only to entities.
   */
  static get immutableID () { return 'tags' }
  get makeFilterOperationFn () {
    return tagFilterOperations.bind(null, 'entity')
  }
}
EntityTagsColumn.register()

export class UserTagsColumn extends TagsColumn {
  static get immutableID () { return 'tags.user' }
  get makeFilterOperationFn () {
    return tagFilterOperations.bind(null, 'user')
  }
}
UserTagsColumn.register()

/**
 * Timestamp
 */
class TimestampColumn extends IdempotentColumn {
  static get immutableID () { return 'timestamp' }

  get makeFilterOperationFn () { return timestampFilterOperations }

  dateFromRow (row) {
    const ts = ko.unwrap(this.getter(row))
    if (!ts) { return '' }
    if (isDate(ts)) { return ts }
    // Returned from server as "Timestamp"
    //   i.e. {seconds: number, nanoseconds: number}
    const { seconds } = ts
    return fromUnixTime(seconds)
  }

  /**
   * @param {firestore.FieldValue.Timestamp} ts
   * @param {int} ts.seconds
   * @param {int} ts.nanoseconds
   */
  renderValue (row) {
    const mv = this.dateFromRow(row)
    if (!mv || isNaN(+mv)) { return <small>—</small> }
    return (
      <tool-tip my='top right' at='bottom right'>
        <template slot='anchor'>
          <small>{formatForUser(mv)}</small>
        </template>
        <template slot='content'>
          <small>{formatLongForUser(mv)}</small>
        </template>
      </tool-tip>
    )
  }

  reportValue (row) {
    return formatLongForUser(this.dateFromRow(row), '—')
  }

  searchMatches (row, query) {
    return this.reportValue(row)
      .toLowerCase()
      .includes(query.toLowerCase())
  }
}

TimestampColumn.register()

export class SharingRecipients extends ArrayColumn {
  static get immutableID () { return 'sharing.recipients' }
  constructor () { super('Sharing Recipients', 'sharing.recipients') }
  array (row) { return row.sharing.sharingRights().map(sr => sr.email) }
}
SharingRecipients.register()

/**
 * InterfaceColumn is for user-interface HTML
 */
class InterfaceColumn extends Column {
  static get immutableID () { return 'ui' }
  constructor (title, columnID, jsxGeneratorFn, options) {
    super(title, columnID, options)
    Object.assign(this, { jsxGeneratorFn, show: () => true })
  }

  renderValue (row) {
    return this.jsxGeneratorFn(row)
  }

  get reportable () { return false }
  get sortable () { return false }
  get filterable () { return false }
  get searchable () { return false }
  get resizable () { return false }
  get reorderable () { return false }
  get defaultColumnWidth () { return 75 }
}

InterfaceColumn.register()


export class SelectColumn extends Column {
  selected: KnockoutObservableArray<any>
  selectAll: KnockoutObservable<boolean>

  constructor (selected, panelProvider) {
    super('', 'selected', null)
    Object.assign(this, {
      selected,
      panelProvider,
      selectAll: ko.observable(false),
    })
    this.show = this.computed({
      read: () => true,
      write: () => undefined,
    })
  }

  toggle (row) { this.selected.remove(row).length || this.selected.push(row) }
  toggleAll () {
    this.selectAll.modify(v => !v)
    this.selected(this.selectAll() ? [...this.panelProvider.criteria.hits()] : [])
  }

  static get immutableID () { return 'select' }
  get searchable () { return false }
  get reportable () { return false }
  get filterable () { return false }
  get resizable () { return false }
  get reorderable () { return false }
  get columnID () { return 'select' }
  get defaultColumnWidth () { return 50 }
  get titleElement () {
    return (
      <div class={this.panelProvider.jss.selectColumnButton}
        ko-ownClick={() => this.toggleAll()}
        title='Select All' >
        {this.panelProvider.computed(() =>
          inline(this.selectAll() ? checkedIcon : uncheckedIcon))}
      </div>
    )
  }

  renderValue (row) {
    return (
      <div class={this.panelProvider.jss.selectColumnButton}
        ko-ownClick={() => { this.toggle(row); this.selectAll(false) }}
        title={row.id} >
        {this.panelProvider.computed(() =>
          inline(this.selected().includes(row) ? checkedIcon : uncheckedIcon))}
      </div>
    )
  }
}

export default Column
