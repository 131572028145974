
import BodyBlock from './BodyBlock'

export type IndentCount = 0 | 1 | 2 | 3  | 4
export const MAX_DEPTH = 4

interface IndentBlockConstructor {
  new ({ body, align, depth }): IndentBlock
}

export abstract class IndentBlock extends BodyBlock {
  depth: KnockoutObservable<IndentCount>
  get plainObservables () { return [...super.plainObservables, 'depth']}
  get collapseToParagraphOnEnterWhenEmpty () { return true }

  constructor (params) {
    super(params)
    this.depth.modify(d => d || 0)
  }

  makeNextBlock (body: string) : IndentBlock {
    const Ctor = this.constructor as IndentBlockConstructor
    const depth = this.depth()
    const align = this.align()
    return new Ctor({ body, depth, align })
  }
}

export default IndentBlock
