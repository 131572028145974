import Transition from './Transition'

/**
 * This is a simple transition to make accounts `isActive` property
 * true, which affects services such as backups.
 */
export default class IsActiveTransition extends Transition {
  get implementationDate () { return '2019-09-28' }
  get transitionName () { return 'isActive' }
  async isNeeded () {
    return this.accountModel.isActive() === undefined
  }
  async run () {
    this.accountModel.isActive(true)
    await this.accountModel.vmSave()
  }
}
