import { sortBy } from 'lodash-es'

import { Asset, CapitalState } from 'capital'
import { TransactionAmend, TransactionDeauthorize } from 'capital/Transaction'

import { formatForUser } from 'utils/dates'
import { toLocaleDecimal } from 'utils/number'

// import { inline } from 'icons'
// import yesIcon from 'icons/light/check'
// import noIcon from 'icons/light/times'
import { dropdown, buttons, color, typography } from 'styles'
import { inline } from 'icons'
import menuIcon from 'icons/solid/caret-down'

import EisCapital from './EisCapital'

import './capital-asset-prefix'
import './capital-authorization-editor'

export default abstract class EisAuthorizedCapital extends EisCapital {
  capitalState: CapitalState
  persons: PersonRecordSet

  constructor (params) {
    super(params)
    Object.assign(this, {
      persons: this.component.persons,
    })
  }

  get initialDateTime () { return new Date(9999, 0) }

  get fieldTitle () : string { return 'Authorized Capital' }

  static get css () {
    return {
      ...super.css,
      ...this.menuCSS,
      ...this.editCSS,
      ...this.propertiesCSS,
      changeButton: {
        ...super.css.changeButton,
        gridArea: '',
      },
    }
  }

  static get editCSS () {
    return {
      contentArea: {
        gridArea: 'content',
        gridColumn: '1/-1',
        marginBottom: 25,
        marginTop: 15
      },
      header: {
        fontWeight: 'bold',
        fontSize: '0.8em',
        whiteSpace: 'nowrap',
        paddingBottom: '20px'
      },
      assets: {
        display: 'grid',
        alignItems: 'start',
        marginBottom: 5,
        gridGap: '10px 10px',
        gridTemplateColumns: 'repeat(4,auto) min-content 40px',
        //gridTemplateColumns: 'minmax(80px, auto) minmax(70px, 130px) minmax(90px, 100px) 100px 90px repeat(4, auto) 9px',
        paddingBottom: 5,
        '& [future=true]': {
          opacity: '0.5',
        }
      },
      seriesName: {
        fontSize: '1rem',
      },
      assetName: {
        gridColumn: '1',
        fontSize: '1rem',
        fontWeight: '600'
      },
      authorizedDate: {

      },
      quantumAuthorized: {

      },
      assetPrefix: {
      },
      moreMenu: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: '5px',
        padding: '0px 20px',
        '--icon-color': color.text.light.secondary,
        '&:hover': {
          '--icon-color': color.text.light.primary,
        },
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.secondary,
          '&:hover': {
            '--icon-color': color.text.dark.primary,
          },
        },
      },
      noName: {
        color: 'gray'
      },
      fullBreak: {
        gridColumn: '1/-1',
        borderBottom: `1px ${color.separator.light.nonOpaque} dotted`,
        'body[dark] &': { // project batman
          borderBottom: `1px ${color.separator.dark.nonOpaque} dotted`,
        },
      },
    }
  }

  get fullBreak () { return (<div class={this.jss.fullBreak}></div>) }

  get headerHTML () {
    const { jss } = this
    return (
      <>
        <div class={jss.header}>Classification</div>
        <div class={jss.header}>Series</div>
        <div class={jss.header}>Auth. Date</div>
        <div class={jss.header}># Authorized</div>
        <div class={jss.header}>Prefix</div>
        <div />
      </>
    )
  }

  assetHTML (asset: Asset) {
    const { jss } = this
    const noName = <span class={jss.noName}>Unnamed</span>
    const future = asset.authorizationDate > new Date()
    return (
        <>
          <div class={jss.assetName} future={future}>
            {asset.getProperty('classification', noName)}
          </div>
          <div class={jss.seriesName} future={future}>
            {asset.getProperty('series')}
          </div>
          <div class={jss.authorizedDate} future={future}>
            {formatForUser(asset.authorizationDate)}
          </div>
          <div class={jss.quantumAuthorized} future={future}>
            {toLocaleDecimal(asset.getProperty('quantumAuthorized'))}
          </div>
          <div class={jss.assetPrefix} future={future}>
            <capital-asset-prefix prefix={asset.getProperty('prefix')} />
          </div>
          <div class={jss.moreMenu}>
            <drop-down my='top right' at='bottom right'>
              <template slot='anchor'>
                <div class={jss.buttonIcon}>
                  {inline(menuIcon)}
                </div>
              </template>
              <template slot='content'>
                <div class={jss.menu}>
                  <div class={jss.editButton}
                    ko-ownClick={() => this.onIssueClick(asset)}>
                    Issue
                  </div>
                  <div class={jss.issueButton}
                    ko-ownClick={() => this.openEditDialog(asset)}>
                    Edit
                  </div>
                  <div class={jss.issueButton}
                    ko-ownClick={() => this.openAmendDialog(asset)}>
                    Amend
                  </div>
                  <div class={jss.issueButton}
                    ko-ownClick={() => this.openDeauthorizeDialog(asset)}>
                    Deauthorize
                  </div>
                  <div class={jss.removeButton}
                    ko-ownClick={() => this.onRemoveClick(asset)}>
                    Remove
                  </div>
                </div>
              </template>
            </drop-down>
          </div>
          <div class={jss.properties} future={future}>
            {[...this.propertiesHTML(asset)]}
          </div>
          {this.fullBreak}
        </>
    )
  }

  static get menuCSS () {
    return {
      menu: {
        ...dropdown.generalMenu
      },
      buttonIcon: {
        ...buttons.clickable,
        '&:hover':{
        background: color.fill.light.primary,
        padding: '0px 7px',
        marginRight: '-7px',
        borderRadius: 5,
        }
      },
      _buttons: {
        ...dropdown.item,
        color: '#4A90E2',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        fontSize: '0.7em',
        transition: 'color 100ms',
        userSelect: 'none',
        fontWeight: 'bold',
        fontFamily: typography.bodyFontFamily,
        lineHeight: '1.8em',
        alignItems: 'flex-end',
        textDecoration: 'unset',
        justifyContent: 'flex-end',
      },
      removeButton: {
        extend: '_buttons'
      },
      issueButton: {
        extend: '_buttons'
      },
      editButton: {
        extend: '_buttons'
      },
    }
  }

  static get propertiesCSS () {
    return {
      properties: {
        gridColumn: '1/-2',
        display: 'grid',
        gap: '6px',
        gridTemplateColumns: 'repeat(auto-fill, 150px)',
        alignItems: 'center',
        justifyContent: 'left',
        marginBottom: '20px',
        //borderBottom: '1px dashed #aaa',
        padding: '15px 10px',
        borderRadius: '5px',
        background: color.systemGrey.light.seven,
        '&:empty': { display: 'none'},
        'body[dark] &': { // project batman
          background: color.systemGrey.dark.seven,
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },
      },
      property: {
        display: 'flex',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        fontSize: '0.7em',
        borderRadius: 15,
        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0)',
        cursor: 'default',
        transition: 'box-shadow 150ms ease-in-out',
        padding: '2px 5px',
        textAlign: 'center',
        fontWeight: 600,
        width: 'fit-content',
        border: `1px solid ${color.separator.light.nonOpaque}`,
        '&:hover':{
          boxShadow: '0px 2px 10px 1px rgba(0,0,0,0.1)',
          transition: 'box-shadow 150ms ease-in-out'
        },

        //'--icon-height': '20px', -- add back in if icon is added back in

        '&[value="true"]': {
          border: 'unset',
          backgroundColor: 'rgba(0,255,0,0.2)',
        },

        // example of how to have a distinct color for one property
        // '&[key="voting"][value="true"]': {
        //   borderColor: 'yellow',
        //   backgroundColor: 'rgba(0,255,0,0.2)',
        // },

        '&[value="false"]': {
          color: 'white',
          backgroundColor: 'red',
          border: 'unset'
        },

        // example of including a false value for display
        // '&[key="voting"][value="false"]': {
        //   borderColor: 'yellow',
        //   backgroundColor: 'red',
        // },

      },
    }
  }

  * propertiesHTML (asset: Asset) {
    const { jss } = this
    const ignore = [
      'series', 'classification', 'prefix', 'quantumAuthorized'
    ]

    for (const key of asset.propertyKeys.sort()) {
      const value = asset.getProperty(key)
      if (ignore.includes(key)) { continue }

      if (typeof value === 'boolean') {
        yield (
          <div class={jss.property} key={key} value={value}>
            {key}
          </div>
        )
      } else {
        yield (
          <div class={jss.property} key={key} value={value}>
            {key}: {value}
          </div>
        )
      }
    }
  }

  onRemoveClick (asset: Asset) {
    const blockers = new Set()
    for (const tr of this.component.transactions) {
      if (tr.code === 'capital.auth') { continue }
      for (const action of tr.actions) {
        if (action.involves(asset.id)) {
          blockers.add(tr)
        }
      }
    }

    if (blockers.size) {
      window.app.modal(
        <ok-modal-dialog
          modalTitle={`Cannot Delete ${asset.assetName}`}
          onOk={() => window.app.modal(undefined)}>
          <template slot='content'>
            This capital is referred to by {blockers.size} transactions.
            You must delete those before removing this authorized capital.
          </template>
        </ok-modal-dialog>
      )
    } else {
      this.component.transactions.remove(asset.authorizedBy)
    }
  }


  onIssueClick (asset: Asset) : void {
    const { jss } = this
    const assetName = asset.assetName
    const title = `Issue ${assetName}`
    window.app.modal(
      <modal-dialog modalTitle={title}>
        <template slot='content'>
          <capital-share-issue-editor class={jss.issueEditor}
            persons={this.persons}
            relatedGenerator={this.relatedGenerator}
            assetClassID={asset.id}
            transactions={this.component.transactions} />
        </template>
      </modal-dialog>
    )
  }

  /**
   * The `Add` button a the bottom of the table, for adding new classes
   * of authorized shares.
   */
  get changeButtonHTML () {
    const { jss } = this
    return (
      <div class={jss.changeButton}
        ko-ownClick={() => this.openEditDialog()}>
        Add
      </div>
    )
  }

  openEditDialog (asset?: Asset) {
    window.app.modal(
      <modal-dialog modalTitle='Authorize Share Capital'>
        <template slot='content'>
          <capital-authorization-editor
            relatedGenerator={this.relatedGenerator}
            original={asset && asset.authorizedBy}
            transactions={this.component.transactions} />
        </template>
      </modal-dialog>
    )
  }

  openAmendDialog (asset: Asset) {
    const amendmentCopy = new TransactionAmend(asset.authorizedBy.toJS())
    amendmentCopy.datetime(null)
    amendmentCopy.title('Amendment')
    window.app.modal(
      <modal-dialog modalTitle='Amend Share Capital'>
        <template slot='content'>
          <capital-amendment-editor
            relatedGenerator={this.relatedGenerator}
            original={amendmentCopy}
            transactions={this.component.transactions} />
        </template>
      </modal-dialog>
    )
  }

  openDeauthorizeDialog (asset: Asset) {
    const copy = new TransactionDeauthorize(asset.authorizedBy.toJS())
    copy.datetime(null)
    copy.title('Deauthorize')
    window.app.modal(
      <modal-dialog modalTitle='Deauthorize Share Capital Class'>
        <template slot='content'>
          <capital-deauthorize-editor
            relatedGenerator={this.relatedGenerator}
            original={copy}
            transactions={this.component.transactions} />
        </template>
      </modal-dialog>
    )
  }

  get editHTML () { return }

  get displayHTML () : any {
    const { jss } = this
    const list = this.computed<JSX[]>(() => {
      return sortBy(
        Object.values(this.state().authorized), a => a.assetName
      ).map(asset => this.assetHTML(asset))
    })

    return (
      <div class={jss.assets}>
        {this.computed(() => list().length ? this.headerHTML : null)}
        {list}
      </div>
    )
  }
}

EisAuthorizedCapital.register()
