import { Responsible } from 'DataModel/components'

import ListEditor from 'list-editor'
import 'user-picker'

import { color } from 'styles'
import { makePersonRecord } from 'person'

/**
 * Edit users responsible for a model.
 *
 * Usage:
 *
 *    <responsible-editor
 *      list={users}
 *      />
 */
export default class ResponsibleEditor extends ListEditor {
  personGenerator: PersonGenerator

  constructor (params) {
    super(params)
    const { personGenerator } = params
    Object.assign(this, { personGenerator })
  }

  static get css () {
    return {
      ...super.css,
      list: {
        ...super.css.list,
        padding: 0,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.darkElevated.primary,
        },
      }
    }
  }

  itemHTML (r: Responsible) {
    return (
      <responsible-indicator responsible={r} />
    )
  }

  get template () {
    const { jss } = this
    return <div class={jss.list}> {this.inputHTML} {this.listHTML} </div>
  }

  get inputHTML () {
    /// [FIXME] This dupes `minute-book-right-view-responsible`
    const match = (r: Responsible, v: string) => r.person().name.includes(v)

    const addPerson = (name: string) => {
      const person = makePersonRecord({ name: [name] })
      this.list.push(new Responsible({ person }, this.personGenerator.componentFor('responsible')))
    }

    return (
      <person-picker
        my='top left'
        at='bottom left'
        searchType='includes'
        generator={this.personGenerator}
        inputClass={this.inputClass}
        filter={name => !this.list().find(r => match(r, name))}
        onSelect={name => addPerson(name)}
        resetQueryOnSelect={true}
      />
    )
  }

  get placeholderMessage () { return 'Add a responsible user' }
  get noItemsMessage () { return 'Add a name to make someone responsible.' }
}

ResponsibleEditor.register()
