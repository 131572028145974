/**
 * MemoryDB is our in-memory representation of the data stored on the
 * servers.
 *
 * We perform, track, aggregate, and convert firestore DocumentSnapshots
 * to our DataModel for every index of every team of every accountID of
 * every authManager.
 *
 * Whenever the team or accountID updates, the tracked snapshots update.
 */
import MemoryDbAggregator from './MemoryDbAggregator'
import {
  EntityIndexTracker, UserIndexTracker, FilterIndexTracker,
  PrecedentIndexTracker, IndexTracker,
} from './IndexTracker'

type DataModel = import('DataModel').DataModel
type EntityModel = import('EntityModel').EntityModel
type UserModel = import('UserModel').UserModel
type PrecedentModel = import('writing/precedents/PrecedentModel').default

const EVERYONE_TRACKERS = [
  new EntityIndexTracker(),
]

const USER_TRACKERS = [
  new UserIndexTracker(),
  new PrecedentIndexTracker(),
  new FilterIndexTracker(),
]

const TRACKERS = [...EVERYONE_TRACKERS, ...USER_TRACKERS]

/**
 * @class MemoryDB copies the database.
 */
export default class MemoryDB {
  authManagers: AuthManager[]
  aggregatorsByIndexName: Record<string, IndexTracker>

  get INDICIES () { return TRACKERS.map(t => t.indexName) }

  /** @param {Array.<AuthManager>} authManagers */
  constructor (authManagers) {
    Object.assign(this, {
      authManagers,
      queriesByOrigin: {},
      aggregatorsByIndexName: this.makeAggregatorsByIndexName()
    })

    ko.computed(() => [...this.genAllQueryParams()])
      .subscribe(u => this.onQueryParamsUpdate(u))
  }

  dispose () {
    Object.values(this.makeAggregatorsByIndexName)
      .forEach(agg => agg.dispose())
  }

  /**
   * @return {object} mapping index to MemoryDbAggregator instances e.g.
   * { entity: MemoryDbAggregator('entity'), user: ... }
   */
  makeAggregatorsByIndexName () {
    return Object.assign({}, ...this.INDICIES
      .map(i => ({ [i]: new MemoryDbAggregator(i) })))
  }

  /**
   * Update the queriesByOrigin and aggregatorsByIndex whenever a user
   * has more / fewer access rights to e.g. accounts/teams/admin.
   * @param {Array.<QueryMemoryDB} allMemoryQueries
   *
   * TODO: Remove stale / deprivileged elements.
   */
  onQueryParamsUpdate (allMemoryQueries) {
    for (const qp of allMemoryQueries) {
      if (qp.stringIdentifier in this.queriesByOrigin) { continue }
      this.queriesByOrigin[qp.stringIdentifier] = qp
      this.aggregatorsByIndexName[qp.indexName].addQueryParams(qp)
    }
  }

  /**
   * We want to prevent a user authenticated with a pass
   */
  verifyIdentity (claims) {
    switch (claims.firebase.sign_in_provider) {
      case 'google.com': return true
      case 'custom': return true
      case 'password': return claims.email_verified === true
    }
    return false
  }

  /**
   * @yield {QueryMemoryDB}
   */
  * genAllQueryParams () {
    for (const authManager of this.authManagers) {
      const userClaims = authManager.userFirebaseClaims()

      if (!userClaims) { continue }

      if (!this.verifyIdentity(userClaims)) {
        console.warn(`

          The user ${userClaims.email} has logged in with a password, but
          their email address has not been verified.

        `, userClaims)
      }

      for (const accountID of authManager.availableAccountIDs()) {
        const accountClaims = userClaims.accounts[accountID]
        if (!accountClaims) { continue }
        const trackerParams = {
          authManager, accountID, accountClaims, userClaims
        }
        yield * this.runTrackerQueries(EVERYONE_TRACKERS, trackerParams)
        if (accountClaims.admin || accountClaims.teamFilterKeys) {
          yield * this.runTrackerQueries(USER_TRACKERS, trackerParams)
        }
      }
    }
  }

  * runTrackerQueries(trackers: IndexTracker[], trackerParams) {
    for (const tracker of trackers) {
      yield * tracker.memoryQueries(trackerParams)
    }
  }

  /**
   * @param {string} indexName
   * @return {ObservableArray} of all the models of the given type.
   */
  getListOf (indexName: 'entity') : KnockoutObservableArray<EntityModel>
  getListOf (indexName: 'user') : KnockoutObservableArray<UserModel>
  getListOf (indexName: 'precedent') : KnockoutObservableArray<PrecedentModel>
  getListOf (indexName) : KnockoutObservableArray<DataModel> {
    return this.aggregatorsByIndexName[indexName].list
  }

  /**
   * @param {string} indexName
   * @return {bool} true when the index is loaded
   */
  indexIsLoaded (indexName) {
    const { modelLists } = this.aggregatorsByIndexName[indexName]
    return modelLists().every(ml => ml.loaded())
  }

  /**
   * @param {string} indexName
   * @param {string} id firestoreRef.id
   * @return {DataModel}
   */
  getModel (indexName, id) {
    return this.getListOf(indexName)().find(m => m.id() === id)
  }

  * genAccountModels (authManager, accountID, indexName) {
    const list = ko.unwrap(this.getListOf(indexName))
    for (const model of list) {
      if (model.authManager !== authManager) { continue }
      if (model.accountID() !== accountID) { continue }
      yield model
    }
  }
}
