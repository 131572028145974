import NotesList from './notes-list'

import { color } from 'styles'

export default class PinnedNotes extends NotesList {

  static get css () {
    return {
      ...super.css,

      scrollContainer: {
        display: 'grid',
        gridGap: '5px',
        gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
        margin: '20px 20px 40px 20px',
      },

      details: {
        '--hover-color': 'hsla(41, 100%, 95%, 0.9)',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        margin: '0px 0px',
        color: 'grey',
        padding: '5px 10px',
        '&:hover': {
          backgroundColor: 'var(--hover-color)',
          cursor: 'pointer',
          boxShadow: '0px 2px 6px 2px rgba(0,0,0,0.3)',
          '& > div:nth-child(2) > i': {
            opacity: '0.7',
          },
        },
        borderTop: '10px solid #FFEA3D',
        backgroundColor: '#FEF49C',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        boxShadow: '0px 2px 6px 2px rgba(0,0,0,0.1)',
      },

      messageText: {
        ...super.css.messageText,
        gridColumn: '1/3',
      },

      message: {
        ...super.css.message,
        marginBottom: '10px',
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.altSecondary,
        },
      },

      pageNumberSpace: {
        ...super.css.pageNumberSpace,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: '0.7em',
        color: 'rgba(0,0,0,0.8)',
        textShadow: '0px 1px 0px rgba(255,255,255,1)'
      },

      pageNumber: {
        fontWeight: '600'
      },

      metaGridItem: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      },

      created: {
        color: 'rgba(0,0,0,0.8)',
        fontSize: '0.7rem',
        textShadow: '0px 1px 0px rgba(255,255,255,1)'
      },
      pinIcon: {
        opacity: 1,
        filter: 'drop-shadow( 0px 1px 0px white)',
        visibility: 'visible',
        '& svg': {
          fill: color.icon.light.primary,
          'body[dark] &': { // project batman
            fill: color.icon.dark.altPrimary,
          },
          opacity: '0.9',
          height: '0.6em',
          display: 'inline-block',
          overflow: 'visible',
          fontSize: 'inherit',
          verticalAlign: '-.125em',
        },
        '&:hover': {
          fill: 'red',
          'body[dark] &': { // project batman
            '--icon-color': 'green',
          },
          opacity: '0.9'
        }
      },
      editIcon: {
        visibility: 'visible',
        '--icon-color': color.icon.light.primary,
        '&:hover': {
          '--icon-color': color.icon.light.secondary,
        },
        filter: 'drop-shadow( 0px 1px 0px white)',
        '& svg': {
          opacity: '0.9',
          height: '0.8em',
          fontSize: 'inherit',
        },
        'body[dark] &': { // project batman
          '--icon-color': color.icon.dark.altPrimary,
          '&:hover': {
            '--icon-color': color.icon.dark.altSecondary,
          },
        },
      },
      calendarIcon: {
        extend: 'icon',
        '--icon-color': color.icon.light.primary,
        '&:hover': {
          '--icon-color': color.icon.light.secondary,
        },
        filter: 'drop-shadow( 0px 1px 0px white)',
        '& svg': {
          opacity: '0.9',
          fontSize: 'inherit',
          height: '0.8em',
          verticalAlign: '0em',
        },
        'body[dark] &': { // project batman
          '--icon-color': color.icon.dark.altPrimary,
          '&:hover': {
            '--icon-color': color.icon.dark.altSecondary,
          },
        },
      },
      flagIcon: {
        extend: 'icon',
        '--icon-color': color.icon.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.icon.dark.altPrimary,
          '&:hover': {
            '--icon-color': color.icon.dark.altSecondary,
          },
        },
        '& svg': {
          opacity: '0.9',
          height: '0.7em',
          fontSize: 'inherit',
          verticalAlign: '-.125em',
        },
        filter: 'drop-shadow( 0px 1px 0px white)',
        '&[show]': {
          visibility: 'visible',
          opacity: '1',
          '--icon-color': 'red',
          'body[dark] &': { // project batman
            '--icon-color': 'red',
            },
          },
        },

      meta: {
        ...super.css.meta,
        position: 'static',
        right: undefined,
        bottom: undefined,
      }
    }
  }

  itemHTML (item) {
    const { jss } = this
    const pageNumber = item.bookPage ? (this.bookComponent.getIndexOfpageID(item.bookPage.pageID) + 1) : 0
    const messageHTML = item.jsx[0]
    const metaHTML = item.jsx[1]
    return (
      <div class={jss.details} ko-click={() => this.onItemClick(item)}>
          <div class={jss.messageText}>{messageHTML}</div>
          <div class={jss.pageNumberSpace}>
            {pageNumber > 0 ?
              <div class={jss.pageNumber}>Pg {pageNumber}</div>
              : ''}
          </div>
          <div class={jss.metaGridItem}>{metaHTML}</div>
      </div>
    )
  }

  get notesList () {
    return this.listOfNotes.list().filter(n => n.data().pinned)
  }

  get noItemsMessageIconHTML () { }

  makeDeleteFn() { }

  noteClick(bookPage) {
    bookPage.scrollIntoView()
  }
}

PinnedNotes.register()
