
import LinkedList from 'utils/LinkedList'

export default class Navigation extends LinkedList {
  constructor () {
    super({looped:true})
    this._currentFocus = ko.observable(null)
    this._currentFocus.subscribe(cf => {
      cf.focused(true)
      for (let item of this) {
        item === cf || item.focused(false)
      }
    })
  }

  get currentFocus () {
    return ko.pureComputed(() =>
      (this._currentFocus() && this._currentFocus().focused() ? this._currentFocus() : null)
    )
  }

  append (item) {
    super.append(item)
    item.focused.subscribe(f => !f || this._currentFocus(item))
  }

  insertAfter (node, item) {
    super.insertAfter(node, item)
    item.focused.subscribe(f => !f || this._currentFocus(item))
  }

  insertBefore (node, item) {
    super.insertBefore(node, item)
    item.focused.subscribe(f => !f || this._currentFocus(item))
  }

  nextFocus () {
    if (this._currentFocus()) { this._currentFocus(this._currentFocus().next) }
  }

  previousFocus () {
    if (this._currentFocus()) { this._currentFocus(this._currentFocus().previous) }
  }
}
