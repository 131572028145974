import base64 from 'base64-js'
import { isBefore } from 'date-fns/esm'

import Process from "utils/Process"
import { PDFEngine } from 'pdf-engine'

export async function sendToDocuSign (pages: BookPage[], pdfEngine: PDFEngine) {
  const { app } = window
  const { jss } = app
  try {
    if (!pages || !pages.length) { throw new Error('No pages provided') }
    const process = new Process(pages.length + 2)
    const state = ko.observable('Compiling')
    const message = ko.pureComputed(() => {
      if (process.isCancelled()) {
        return 'Cancelled'
      } else if (state() === 'Compiling') {
        return (
          <span>
            Compiling PDF for transfer.
          </span>)
      } else if (state() === 'Uploading') {
        return (
          <span>
            Uploading PDF to DocuSign.
          </span>)
      }
    })
    const cancelButton = (
      <div class={jss.buttonClean}
        ko-click={() => process.cancel()}>
        Cancel
      </div>
    )
    app.notifier.pushProgress(message, process.percent, { actions: cancelButton })
    const pdfBlob = await pdfEngine.compile(pages, process)
    if (process.isCancelled()) { return }
    const pdfArryBuffer = await pdfBlob.arrayBuffer()
    process.tick(); if (process.isCancelled()) { return }
    const pdfUint8Array = new Uint8Array(pdfArryBuffer)
    const pdfBase64 = base64.fromByteArray(pdfUint8Array)
    console.log(`Sending page to docusign`)
    state('Uploading')

    const authManager = app.defaultAuthManager
    const { status, url } = await authManager.firebaseFn('sendToDocuSign') // auth check

    if (status === 'ok') {
      const { status, url } = await authManager.firebaseFn('sendToDocuSign', { pdfBase64 })
      if (status === 'ok') {
        console.log(`DocuSign: url:${url}`)
        window.open(url, '_docusign')
      } else {
        console.error(`DocuSign: submit error: ${status}`)
      }
      process.tick(); process.isComplete(true)

    } else if (status === 'needs_auth') {
      console.log('Docusign: need authentication')
      window.open(url, '_docusign')
      // Now wait for server to touch meta/terminal
      const { uid } = authManager.firebaseUser()
      const metaTrigger = `/users/${uid}/meta/terminal`
      const t = new Date()
      const metaUnsub = authManager.firestore.doc(metaTrigger).onSnapshot(async (doc) => {
        if (isBefore(new Date(doc.data().modified), t)) { return }
        console.log('Re-submitting pdf')
        const { status, url } = await authManager.firebaseFn('sendToDocuSign', { pdfBase64 })
        if ( status === 'ok' ) {
          console.log(`DocuSign: url:${url}`)
          window.open(url, '_docusign')
        } else {
          console.error(`DocuSign: redirect response error status=${status}`)
        }
        process.tick(); process.isComplete(true)
        metaUnsub()
      })
    }
  } catch (err) {
    console.error('sendForSig error', err)
  }
}
