
import ModalDialog from 'modal-dialog'
import { color, typography } from 'styles'

export default class GlobalGotoDialog extends ModalDialog {
  constructor () {
    super({ modalTitle: ['Go To ', <q-tip name='globalGoTo' />] })
  }

  get globalKeys () {
    const {
      entityList, calendar, users, personList, precedentList,
      //, account, security
    } = window.app.rootProviders
    return [
      { key: 'l', title: 'Entity List', provider: entityList },
      { key: 'c', title: 'Calendar', provider: calendar },
      { key: 'p', title: 'People', provider: personList },
      { key: 'r', title: 'Precedents', provider: precedentList },
      { key: '0', title: 'Settings', provider: users },

      // { key: 'u', title: 'Users List', provider: users },
      // { key: 'a', title: 'Account', provider: account },
      // { key: 's', title: 'Security', provider: security }
    ]
  }

  keyBinding (k) {
    const { key, provider } = k
    return {
      key,
      action: () => {
        window.app.panelProvider(provider)
        this.close()
      }
    }
  }

  nextTheme () {
    const t = window.app.theme
    t(t() === 'light' ? 'dark' : 'light')
    this.close()
  }

  async openWritingPanel () {
    // Avoid circular import.
    const {default: WritingPanelProvider} = await import('writing/WritingPanelProvider')
    window.app.panelProvider(new WritingPanelProvider({ app: window.app }))
    this.close()
  }

  get keyboardShortcuts () {
    return [
      ...super.keyboardShortcuts,
      { key: 'g', action: () => window.app.modal(undefined) },
      { key: 'x', action: () => this.nextTheme() },
      { key: 'd', action: () => this.openWritingPanel() },
      ...this.globalKeys.map(k => this.keyBinding(k))
    ]
  }

  static get modalOverrides () {
    return {
      inactiveShade: {
        ...super.css.inactiveShade,
        transition: 'unset',
      },
      activeShade: {
        ...super.css.activeShade,
        transition: 'unset',
      },
      inactiveContainer: {
        ...super.css.inactiveContainer,
        transition: 'unset',
      },
      activeContainer: {
        ...super.css.activeContainer,
        transition: 'unset'
      }
    }
  }

  static get css () {
    return {
      ...super.css,
      ...this.modalOverrides,
      content: {
        ...super.css.content,
        border: '1px solid transparent',
        margin: 'auto',
        width: 340,
        padding: '20px 30px 10px 30px',
        boxShadow: '2px 2px 21px 7px rgba(0,0,0,0.2)',
        color: color.text.light.primary,
        borderTop: 'none',
        borderRadius: 6,
        backgroundColor: color.shortcutModal.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.shortcutModal.dark.secondary,
          color: color.text.dark.primary
        },
        gridTemplateColumns: '10px auto 10px'
        // ... any custom `content` styles to overwrite those in
        // ModalDialog's `content` class
      },
      goto: {
        display: 'flex',
        flexDirection: 'column'
      },
      titleContainer: {
        fontSize: '2em',
        fontWeight: 'bold',
        fontFamily: typography.titleFamily,
        '--icon-height':'20px'
      },
      keyWrap: {
        display: 'grid',
        padding: '0px 0px 10px 0px',
        alignItems: 'center',
        marginBottom: 20,
        borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        gridTemplateColumns: '1fr auto',
      },
      key: {
        textTransform: 'uppercase',
        border: `1px solid ${color.light.keyBorder}`,
        margin: 5,
        padding: '6px 10px',
        boxShadow: '0 2px 3px rgba(0,0,0,.1)',
        borderBottom: `4px solid ${color.light.keyBorderBottom}`,
        borderRadius: 5,
        color: color.text.light.primary,
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
          border: `1px solid ${color.systemGrey.dark.six}`,
          borderBottom: `4px solid ${color.systemGrey.dark.six}`,
          color: color.text.dark.primary
        },
        display: 'flex',
        fontFamily: typography.titleFamily,
        fontWeight: 400,
        alignItems: 'center'
      },
      description: {
        padding: 10,
        gridRow: '1/2',
        fontSize: '0.7em',
        margin: '5px 0 0px 0',
        background: 'white',
        borderTop: '3px solid #ffd502',
        gridColumn: '1/-1',
        borderRadius: 2,
        //boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.05)',
        '&:hover': {
          boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1)'
        }
      },
      code: {
        fontFamily: typography.mono,
        whiteSpace: 'normal',
        backgroundColor: '#eff0f1',
        padding: '1px 5px 0px 5px'
      },
      title: {
        fontWeight: '700'
      },
    }
  }

  gotoHTML ({ key, title }) {
    const { jss } = this
    return [
      <div class={jss.keyWrap}>
        <div class={jss.title}>{title}</div>
        <div class={jss.key}>{key}</div>
      </div>
    ]
  }

  get contentHTML () {
    const { jss } = this
    return (
      <div class={jss.goto}>
        {this.globalKeys.map(g => this.gotoHTML(g)).flat()}
      </div>
    )
  }
}

GlobalGotoDialog.register()
