/**
 * Re. naming shares:
 *
 * " Where a corporation has more than one class of shares, the additional classes of shares are sometimes designated as "special shares" (see for example OBCA, s. 25 and s. 170(1)) or "preferred shares." There is no rule, however, that requires any class of shares to be designated by any particular name or description. Furthermore, there is no rule that requires any class of shares to have specific attributes. "
 *   - https://lso.ca/lawyers/practice-supports-and-resources/practice-area/business-law/how-to-structure-the-share-provisions-of-a-corpora
 */
import { uuidv4 } from 'utils/string'
import 'widgets'

import { buttons, typography, color } from 'styles'
import { inline } from 'icons'
import removeIcon from 'icons/light/times'

import TransactionEditor from './TransactionEditor'
import {
  TransactionAuthorize, TransactionAmend, TransactionDeauthorize
} from '../Transaction'
import {
  SHARE_CLASSIFICATION, CAPITAL_PROPERTY_TYPES, SHARE_SERIES
} from 'entity-information-summary/constants'
import {
  CapitalAssetProperty, CapitalAssetPropertyChoice, CapitalAssetPropertyType
} from '../interfaces'

import './capital-property-picker'

/**
 * The `<edit-authorized-capital>` component edits or adds a
 * transaction to the given `transactions` array.
 */
abstract class CapitalAuthEditorBase<T extends TransactionAuthorize> extends TransactionEditor<T> {
  trPicker (e, p) {
    return e.answerFor('capital')
      .transactions()
      .filter(t => t.code === 'capital.auth')
      .map((t: T) =>
        ko.unwrap(t[p] || t.getPropertyValue(p)))
      .filter(v => v)
      .flat()
  }

  abstract get dateTitle () : string

  get questionHTML () {
    const { jss } = this
    const {
      datetime, classification, prefix, properties, series,
      quantumAuthorized
    } = this.clone

    return (
      <div class={jss.blockMeta}>
        <div class={jss.block}>
          <div class={jss.propertyTitle}>
            {this.dateTitle}
          </div>
          <div class={jss.propertyEdit}>
            <date-picker-popover
              relatedDateGenerator={this.relatedDateGenerator}
              value={datetime}
              classes={{ inlineDateValue: jss.inputField }}
              my='top left' at='bottom left' />
          </div>

          <div class={jss.propertyTitle}>
            Classification
          </div>
          <div class={jss.propertyEdit}>
            <model-property-picker my='top left' at='bottom left'
              indexName='entity'
              value={classification}
              onSelect={classification}
              inputClass={this.jss.inputField}
              extraChoices={SHARE_CLASSIFICATION}
              propertyGetter={e => this.trPicker(e, 'classification')}
              />
          </div>

          <div class={jss.propertyTitle}>
            Series
          </div>
          <div class={jss.propertyEdit}>
            {this.seriesPropertyHTML({ value: series })}
          </div>


          <div class={jss.propertyTitle}>
            Prefix
          </div>
          <div class={jss.propertyEdit}>
            <model-property-picker my='top left' at='bottom left'
              indexName='entity'
              value={prefix}
              onSelect={prefix}
              inputClass={jss.inputField}
              propertyGetter={e => this.trPicker(e, 'prefix')}
              />
          </div>
          <div class={jss.propertyTitle}>
            Quantum Authorized
          </div>
          <div class={jss.quantumBlock}>
            <input type='text' class={jss.inputField}
              ko-textInput={quantumAuthorized} />
            <div class={jss.quantumUnlimited}
                ko-click={() => quantumAuthorized('unlimited')}>
                unlimited
            </div>
          </div>

          <div class={jss.propertiesSeparator} />

          {properties.map(p => this.propertyHTML(p))}

          <div class={jss.propertiesPickerTitle}>
            Add a property
          </div>
          <div class={jss.propertiesPicker}>
            <capital-property-picker my='bottom left' at='top left'
              inputClass={this.jss.inputField}
              filter={t => !this.findProperty(t.key)}
              onSelect={c => this.addProperty(c)}
              resetQueryOnSelect={true}
            />
          </div>
        </div>
      </div>
    )
  }

  findProperty (key: string) {
    return this.clone.properties().find(t => key === t.key)
  }

  static get propertyCSS () {
    return {
      propertiesSeparator: {
        gridColumn: '1/-1',
        height: '1px',
        borderBottom: '1px solid #aaa',
      },

      propertiesPickerTitle: {
        gridColumn: '1/2',
        fontWeight: 'bold',
        fontStyle: 'italic',
        justifySelf: 'flex-end',
      },

      propertiesPicker: {
        gridColumn: '2/-1'
      },

      propertyTitle: {
        alignItems: 'center',
        display: 'flex',
        fontFamily: typography.titleFamily,
        fontWeight: 'bold',
        gridColumn: '1 / 2',
        justifyContent: 'flex-end',
      },

      propertyEdit: {
        gridColumn: '2 / -1'
      },

      removeablePropertyEdit: {
        justifySelf: 'flex-end',
      },

      propertyRemove: {
        ...buttons.clickable,
      },
    }
  }

  addProperty (capc: CapitalAssetPropertyChoice) : void {
    if (this.findProperty(capc.key)) { return }
    this.clone.properties.push({
      type: capc.type,
      key: capc.key,
      value: ko.observable(capc.default),
      note: ko.observable(),
    })
  }

  boolPropertyHTML (kv: CapitalAssetProperty) {
    return <yes-no value={kv.value} />
  }

  flagPropertyHTML (kv: CapitalAssetProperty) {
    return 'yes'
  }

  numericPropertyHTML (kv: CapitalAssetProperty) {
    return (
      <input type='number' class={this.jss.inputField}
        ko-textInput={kv.value} />
    )
  }

  seriesPropertyHTML (kv: Pick<CapitalAssetProperty, 'value'>) {
    return (
      <model-property-picker my='top left' at='bottom left'
        indexName='entity'
        value={kv.value}
        onSelect={kv.value}
        inputClass={this.jss.inputField}
        extraChoices={SHARE_SERIES}
        propertyGetter={e => this.trPicker(e, 'series')}
      />
    )
  }

  moneyPropertyHTML (kv: CapitalAssetProperty) {
    return (
      <amount-of-currency-input
        my='bottom left' at='top left'
        value={kv.value} />
    )
  }

  propertyHTMLForType (type: CapitalAssetPropertyType) : (kv: CapitalAssetProperty) => any {
    const C = CapitalAssetPropertyType
    switch (type) {
      case C.bool: return this.boolPropertyHTML
      case C.numeric: return this.numericPropertyHTML
      case C.series: return this.seriesPropertyHTML
      case C.money: return this.moneyPropertyHTML
      case C.flag: return this.flagPropertyHTML
      default:
        console.error(`Bad CapitalAssetProperty: ${type}`)
        return () => null
    }
  }

  propertyHTML (kv: CapitalAssetProperty) {
    const { jss } = this

    if (!kv.type) {
      const inferredType = CAPITAL_PROPERTY_TYPES.find(t => t.key === kv.key)
      if (!inferredType) {
        console.error(`<capital-authorization-editor> Bad property type`, kv)
        return
      }
      kv.type = inferredType.type
    }
    const CapitalType = CAPITAL_PROPERTY_TYPES.find(t => t.key === kv.key)
    if (!CapitalType) {
      console.error(`<capital-authorization-editor> Missing property`, kv)
      return
    }
    const editHTML = this.propertyHTMLForType(kv.type).call(this, kv)
    const { title } = CapitalType
    const removeClick = () => this.clone.properties.remove(kv)

    return (
      <>
        <div class={jss.propertyTitle}>
          {title}
        </div>
        <div class={jss.removeablePropertyEdit}>
          {editHTML}
        </div>
        <div class={jss.propertyRemove} ko-ownClick={()=> removeClick()}>
          {inline(removeIcon)}
        </div>
      </>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.propertyCSS,
      layout: {},
      block: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridGap: '20px 25px',
        marginBottom: '20px'
      },
      inputField: {
        border: '1px solid transparent',
        borderRadius: 6,
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        fontSize: 15,
        minWidth: 250,
        outline: 'none',
        padding: '8px 20px',
        width: '100%',
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },
      },
      quantumBlock: {
        display: 'flex',
        gridColumn: '2/-1',
      },
      quantumUnlimited: {
        ...buttons.clickable,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderBottom: '4px solid #ffd502',
        boxShadow: '0px 1px 4px 4px rgba(0,0,0,0.05)',
        fontFamily: typography.mono,
        fontSize: '0.8em',
        padding: 10,
        marginLeft: '10px',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
      },
    }
  }

  isValidTransaction (args) {
    return true
  }
}

class CapitalAuthorizationEditor extends CapitalAuthEditorBase<TransactionAuthorize> {
  get dateTitle () { return 'Authorization Date' }
  createTransaction ({ assetClassID }) : TransactionAuthorize {
    return new TransactionAuthorize({
      assetClassID: assetClassID || uuidv4(),
      datetime: null,
      title: 'Authorize',
    })
  }
}

class CapitalAmendmentEditor extends CapitalAuthEditorBase<TransactionAmend> {
  get dateTitle () { return 'Amendment Date' }
  createTransaction ({ assetClassID }) : TransactionAmend {
    return new TransactionAmend({
      assetClassID: assetClassID || uuidv4(),
      datetime: null,
      title: 'Authorize',
    })
  }
}

class CapitalDeauthorizeEditor extends CapitalAuthEditorBase<TransactionDeauthorize> {
  get dateTitle () { return 'Deauthorization Date' }
  createTransaction ({ assetClassID }) : TransactionDeauthorize {
    return new TransactionDeauthorize({
      assetClassID: assetClassID || uuidv4(),
      datetime: null,
      title: 'Deauthorize',
    })
  }

  get questionHTML () {
    const { jss } = this
    const datetime = this.clone.datetime
    return (
      <div class={jss.blockMeta}>
        <div class={jss.block}>
          <div class={jss.propertyTitle}>
            {this.dateTitle}
          </div>
          <div class={jss.propertyEdit}>
            <date-picker-popover
              relatedDateGenerator={this.relatedDateGenerator}
              value={datetime}
              classes={{ inlineDateValue: jss.inputField }}
              my='top left' at='bottom left' />
          </div>
        </div>
      </div>
    )
  }

  isValidTransaction () {
    return Boolean(this.clone.datetime())
  }
}


CapitalAuthorizationEditor.register()
CapitalAmendmentEditor.register()
CapitalDeauthorizeEditor.register()
