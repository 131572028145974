/**
 * SectionTree maintains the sections and pages of the BookComponent
 */

export default class SectionTree {
  /**
   * @param {Observable.<BookPage>} pages
   * @param {Observable.<Section>} sections
   */
  constructor (pages, sections) {
    Object.assign(this, { pages, sections })
  }

  /**
   * Move the given section to the given index.
   * @param {int} fromIndex in `this.sections`
   * @param {int} toIndex in `this.sections`
   *
   * This is multi-step because we're mutating two arrays, the
   * this.sections and this.pages.
   */
  moveSection (fromIndex, toIndex) {
    const { sections } = this
    const from = this.sections()[fromIndex]

    const pagesForSection = new Map()
    for (const section of sections) {
      pagesForSection.set(section, this.getPagesForSection(section))
    }
    const firstSectionPage = sections()[0].startPage()
    const preSection = this.pages().slice(0, firstSectionPage - 1)

    sections.splice(fromIndex, 1)
    if (toIndex > fromIndex) { --toIndex }
    sections.splice(toIndex, 0, from)

    const pagesCopy = [...preSection]
    for (const section of sections) {
      section.pageNumber(pagesCopy.length + 1)
      pagesCopy.push(...pagesForSection.get(section))
    }
    this.pages(pagesCopy)
  }

  getSectionPageCount (section) {
    const sections = this.sections()
    const sectionIndex = sections.indexOf(section)
    const nextSection = sections[sectionIndex + 1]
    const pageStartIndex = section.startPage() - 1
    const pageEndIndex = nextSection
      ? nextSection.startPage() - 1
      : this.pages().length
    return pageEndIndex - pageStartIndex
  }

  /**
   * @param {Section} section
   */
  getPagesForSection (section) {
    const pageStartIndex = section.startPage() - 1
    return this.pages().slice(pageStartIndex, pageStartIndex + this.getSectionPageCount(section))
  }

  /**
   * @param {BookPage} bookPage
   * @return {Section|null} of the given bookPage
   */
  getSectionOfPage (bookPage) {
    const pageNumber = this.pages().indexOf(bookPage)
    const sections = this.sections()
      .filter(s => s.startPage() <= pageNumber)
    if (sections.length === 0) { return null }
    const sectionIndex = sections
      .reduce((iMax, s, i, arr) => s.startPage() > arr[iMax].startPage() ? i : iMax, 0)
    return sections[sectionIndex]
  }
}
