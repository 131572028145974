
import Editor from 'Editor'
import OutcomeNotification from 'notification-manager/outcome-notification'

import { buttons, color } from 'styles'

import successIcon from 'icons/solid/check-circle'
import failIcon from 'icons/solid/exclamation-triangle'

export default class ModelEditor extends Editor {
  get Model () { throw new Error('Overload with the Model class') }

  constructor (params) {
    super(params)
    Object.assign(this, {
      model: ko.observable(params.model)
    })
  }

  static get css () {
    return {
      ...super.css,
      successOutcome: {
        ...OutcomeNotification.css.outcome
      },
      successMessage: {},
      successIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      },
      failOutcome: {
        ...OutcomeNotification.css.outcome,
        backgroundColor: 'rgba(255,188,196,0.97)',
        border: `1px solid ${color.color.light.red}`,
        'body[dark] &': { // project batman
          border: `1px solid ${color.color.dark.red}`,
          backgroundColor: color.systemBackground.dark.primary,
        },
      },
      failMessage: {
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      failIcon: {
        '--icon-color': color.text.light.primary,
        'body[dark] &': { // project batman
          '--icon-color': color.text.dark.primary,
        },
      },

      clickToView: {
        ...buttons.clickable,
        padding: '2px 10px',
        color: color.text.light.secondary,
        backgroundColor: color.fill.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
          backgroundColor: color.fill.dark.secondary,
        },
        borderRadius: 4,
        fontSize: '0.8em',
        '&:hover': {
          color: 'rgba(0,0,0,0.9)',
          backgroundColor: 'rgba(0,0,0,0.2)',
          textDecoration: 'underline'
        }
      },
      metaContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr'
      },
      messageContainer: {
        gridColumn: '1'
      },
      clickToViewContainer: {
        gridColumn: '2',
        display: 'flex',
        justifyContent: 'flex-end'
      }
    }
  }

  get callbackArgs () { return [this.model()] }

  _notification (model, message) {
    const { jss } = this
    const onClick = () => {
      global.app.rootProviders.entityList.selectEntity(model)
    }

    const clickToView = (
      <span class={jss.clickToView}
        ko-click={onClick}>View Entity</span>
    )

    return [
      <div class={jss.metaContainer}>
        <span class={jss.messageContainer}>
          {message}
        </span>
        <span class={jss.clickToViewContainer}>
          {clickToView}
        </span>
      </div>, {
        icon: successIcon,
        style: {
          outcome: jss.successOutcome,
          message: jss.successMessage,
          icon: jss.successIcon
        }
      }
    ]
  }

  saveNotification (model) {
    return this._notification(model, <>Success: The entity <b>{model.cvModelTitle}</b> has been saved.</>)
  }

  newEntityNotification (model) {
    return this._notification(model, <>Success: The entity <b>{model.cvModelTitle}</b> has been created.</>)
  }

  unableToNotification (act, model) {
    const { jss } = this
    const onClick = () => {
      window.app.rootProviders.entityList.selectEntity(model)
    }

    const clickToView = (
      <span class={jss.clickToView}
        ko-click={onClick}>View Entity</span>
    )

    return [
      <div class={jss.metaContainer}>
        <span class={jss.messageContainer}>
          Warning: Unable to {act} <strong>{model.cvModelTitle}</strong>.
        </span>
        <span class={jss.clickToViewContainer}>
          {clickToView}
        </span>
      </div>, {
        icon: failIcon,
        style: {
          outcome: jss.failOutcome,
          message: jss.failMessage,
          icon: jss.failIcon
        }
      }]
  }

  /**
   * A model is "new" if it's never had an ID assigned, or that
   * ID was assigned via a deterministic process e.g. hash of an
   * uploaded file, in which caes `__isNew` is marked.
   */
  get isNew () {
    const model = this.model()
    return !model.id() || model.__isNew
  }

  async saveClick () {
    const { notifier } = window.app
    const model = this.model()
    try {
      await model.vmIsLoaded.when(true)
      await model.vmSave()
      if (this.isNew) {
        notifier.pushOutcome(...this.newEntityNotification(model))
        delete model.__isNew
      } else {
        notifier.pushOutcome(...this.saveNotification(model))
      }
    } catch (err) {
      const act = this.isNew ? 'create' : 'save'
      console.error(`Unable to ${act}: ${model.cvModelTitle}.`, err)
      notifier.pushOutcome(...this.unableToNotification(act, model))
    }
    return super.saveClick()
  }
}
