import { DataComponent } from 'DataModel'
import { EntityQuestions } from 'EntityModel/EntityModel'

import submenuIcon from 'icons/light/folder'
import variableIcon from 'icons/light/certificate'

import {
  SubmenuCommand, TitleCommand, SubmenuVariableCommand,
} from '../commands/Command'
import { InjectVariableCommand } from "../commands/InjectVariableCommand"
import { CommandMenu } from '../commands/CommandMenu'

import {
  ArrayComponentPureDate,
  ArrayComponentPerson,
  ArrayComponentVariable,
} from './ArrayComponentVariable'
import {
  SimpleComponentVariable, ComponentPerson, ComponentPureDate,
} from './ModelVariable'
import {
  CapitalAssetVariable, CapitalHolderVariable,
} from './CapitalVariable'


type FieldDescription = import('DataModel/components/UnitField').FieldDescription
type Command = import('../commands/Command').default
type SlotManager = import('./slots').SlotManager

interface ComponentDescriptor {
  title: string
  identifier: string
  component: string
  aliasBy: string
}

export function possibleEntityVariables () {
  return EntityQuestions
    .map(properties => componentMenuItem(properties))
    .flat()
    .filter((c: Command) => c)
    .sort((a: Command, b: Command) => a.text.localeCompare(b.text))
}

function componentMenuItem (properties: ComponentDescriptor): Command|Command[] {
  const DC = DataComponent.getComponentById(properties.component)
  const { identifier, title } = properties
  const { namespace } = DC

  if (namespace === 'primitive') {
    const vfn = (sm) => new SimpleComponentVariable(sm)
      .assignVariables({ component: identifier })
    return new InjectVariableCommand(title, variableIcon, vfn, `ac:${identifier}`)
  }

  if (namespace === 'person') {
    const sm = new CommandMenu(null)
    sm.add(
      new TitleCommand(`${title}`),
      ...['name', 'phone', 'email', 'address']
        .map(pp => personVariable(title, pp, identifier, 'person')),
    )
    return new SubmenuVariableCommand(title, submenuIcon, sm, `ac:${identifier}`)
  }

  if (namespace === 'date') {
    const vfn = (sm) => new ComponentPureDate(sm)
      .assignVariables({ component: identifier })
    return new InjectVariableCommand(title, variableIcon, vfn, `ac:${identifier}`)
  }

  if (namespace === 'array') {
    const AC = DC as unknown as ArrayComponentStatic
    const fields = AC.variableFieldDescriptions as FieldDescription[]
    const sm = new CommandMenu(null)
    sm.add(
      new TitleCommand(title),
      ...fields
        .map(f => fieldMenuItem(f.property, ko.unwrap(f.label), f.type, identifier)),
    )
    return new SubmenuVariableCommand(title, submenuIcon, sm, `ac:${identifier}`)
  }

  if (properties.component === 'entity.capital') {
    const ca = new CommandMenu(null)
    ca.add(
      new TitleCommand('Capital Assets'),
      ...assetCommands(identifier),
    )

    const ch = new CommandMenu(null)
    ch.add(
      new TitleCommand('Capital Holders'),
      ...holderCommands(identifier),
    )

    return [
      new SubmenuVariableCommand('Capital Assets', submenuIcon, ca, `ac:${identifier}:ccav`),
      new SubmenuVariableCommand('Capital Holders', submenuIcon, ch, `ac:${identifier}:ccsv`),
    ]
  }

  console.warn(`Ignoring component menu item: ${namespace}.${identifier} (${title})`)
  return null
}

function assetCommands (component: string): InjectVariableCommand[] {
  const g = (title, assetPropertyKey) => new InjectVariableCommand(
    title, variableIcon,
    sm => new CapitalAssetVariable(sm)
      .assignVariables({ component, assetPropertyKey }),
  )
  return CapitalAssetVariable.VARIABLE_PROPERTIES
    .map(({ text, property }) => g(text, property))
}

function holderCommands (component: string): InjectVariableCommand[] {
  const g = (title, holderProperty: holderTypes) => new InjectVariableCommand(
    title, variableIcon,
    sm => new CapitalHolderVariable(sm)
      .assignVariables({ component, holderProperty }),
  )

  return CapitalHolderVariable.VARIABLE_PROPERTIES
    .map(({ text, property }) => g(text, property))
}


function createForUnitViewType (type: string, sm: SlotManager) {
  switch (type) {
    case 'person': return new ComponentPerson(sm)
    case 'persons': return new ArrayComponentPerson(sm)
    case 'pureDate': return new ArrayComponentPureDate(sm)
    default: return new ArrayComponentVariable(sm)
  }
}

function fieldMenuItem (property: string, label: string, type: string, component: string): Command {
  if (type === 'person') {
    return personMenu(label, property, component)
  }

  const vfn = (sm) => createForUnitViewType(type, sm)
    .assignVariables({ component, property })
  return new InjectVariableCommand(label, variableIcon, vfn)
}

function personVariable (property, personProperty: string, component: string, unitTypeView = 'persons'): InjectVariableCommand {
  const vfn = (sm) => createForUnitViewType(unitTypeView, sm)
    .assignVariables({ component, personProperty, property })
  return new InjectVariableCommand(personProperty, variableIcon, vfn)
}

function personMenu (label: string, property: string, component: string): SubmenuCommand {
  const sm = new CommandMenu(null)
  sm.add(
    new TitleCommand(`${component} / ${label}`),
    ...['name', 'phone', 'email', 'residency']
      .map(pp => personVariable(property, pp, component)),
  )
  return new SubmenuCommand(label, submenuIcon, sm)
}
