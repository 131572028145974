
import CurrencyPickerBase from './CurrencyPickerBase'
import { lookup, parseCurrencyAmount, tCurrency, currencyAttr } from './currency'

const FALLBACK_CURRENCY = 'CAD'
import { color } from 'styles'


export default class AmountOfCurrencyInput extends CurrencyPickerBase {
  currency: KnockoutObservable<iso4217>
  amount: KnockoutComputed<string>

  constructor (params) {
    const valueToUpdate = params.value
    const given = parseCurrencyAmount(valueToUpdate())
    const value = ko.observable(given.currency || FALLBACK_CURRENCY)
    super ({...params, value, maxMatches: 7, placeholder: 'XXX' })
    Object.assign(this, {
      currency: ko.observable(given.currency || params.defaultCurrency || FALLBACK_CURRENCY),
      amount: ko.observable(given.amount || valueToUpdate())
    })
    this.computed(() => `${this.amount() || 0} ${this.currency()}`)
      .subscribe(valueToUpdate)
    this.matches.subscribe(m => this.onMatchesUpdate(m))
    this.shortcutsEnabled(false)
  }

  onMatchesUpdate (matches: tCurrency[]) {
    if (matches.length === 1) {
      this.onSelectEvent(matches[0])
    } else {
      const queryCode = (this.query() || '')
        .replace(/[-\d.\s]/g, '')
        .toUpperCase()
      const exactMatch = matches.find(m => m.code === queryCode)
      if (exactMatch) { this.onSelectEvent(exactMatch) }
    }
  }

  onSelectEvent (v: string|tCurrency) {
    if (typeof v === 'string') {
      this.currency(v.toUpperCase())
    } else {
      this.query(v.code)
      this.currency(v.code)
    }
    this.showing(false)
  }

  updateMatches (query: string) {
    if (/[a-zA-Z]{2,}/.test(query)) {
      return super.updateMatches(query)
    } else {
      this.matches([])
    }
  }

  filterChoice (item, query) {
    const q = (query || '').toLowerCase().replace(/[^a-z]/g, '')
    return item.name.toLowerCase().includes(q)
      || item.code.toLowerCase().includes(q)
  }

  formatValue (str: CurrencyAmountString) {
    const [amount, currency] = str.split(' ')
    const nf = new Intl.NumberFormat(undefined, { maximumFractionDigits: 20 })
    const { symbol, code } = lookup(currency) || { symbol: '', code: ''}
    const amt = nf.format(parseFloat(amount || '0'))
    return `${symbol}${amt} ${code}`
  }

  get shortcutKeyboardShortcuts () { return {} }

  get anchorHTML () {
    const { jss } = this
    return (
      <div class={jss.inputAndCurrencyAndSymbol}>
        <div class={jss.currencySymbol}>
          {this.computed(() => currencyAttr(this.currency(), 'symbol'))}
        </div>
        <div class={jss.inputAndCurrency}>
          <input class={jss.amount}
            ko-textInput={this.amount}
            placeholder='0.0' />
          <div class={jss.currencyCode}>
            {super.anchorHTML}
          </div>
        </div>
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      inputAndCurrency: {
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        border: '1px solid transparent',
        display: 'flex',
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      inputAndCurrencyAndSymbol: {
        display: 'flex'
      },
      amount: {
        ...super.css.input,
        textAlign: 'right',
        borderRight: 0,
        borderTopRightRadius: 0,
        fontSize: 'inherit',
        backgroundColor: 'unset',
        borderBottomRightRadius: 0,
        height: '40px',
      },
      currencySymbol: {
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center'
      },
      currencyCode: {
        textAlign: 'right',
        '& > input': {
          color: color.text.light.primary,
          background: color.textInput.light.primary,
          'body[dark] &': { // project batman
            color: color.text.dark.primary,
          background: color.textInput.dark.primary,
          },
        }
      },
      input: {
        ...super.css.input,
        borderLeft: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderLeft: `1px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.primary,
          background: color.textInput.dark.primary
        },
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: 'unset',
        height: '40px',
        width: '5em',
        textAlign: 'center',
      },
    }
  }
}

AmountOfCurrencyInput.register()
