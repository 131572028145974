import ViewComponent from 'ViewComponent'

export default class CertificateInput extends ViewComponent {
  value: KnockoutObservable<number>
  state: import('../CapitalState').default
  assetClassID: string
  inputClass: string
  currentAutoNumber: () => number

  constructor ({ state, assetClassID, value, inputClass, currentAutoNumber }) {
    super()
    Object.assign(this, { state, assetClassID, value, inputClass, currentAutoNumber })
  }

  static get css () {
    return {
      ...super.css,
      block: {
        display: 'flex',
        alignItems: 'center',
      },
      prefix: {
      },
      input: {
      },
    }
  }

  get template () {
    const { jss, assetClassID, state, value } = this

    return this.computed(() => {
      const stateNow = ko.unwrap(state)
      const asset = stateNow.getAsset(assetClassID)
      const prefix = asset.getProperty('prefix')

      return (
        <div class={jss.block}>
          <capital-asset-prefix class={jss.prefix} prefix={prefix} />
          —
          <auto-number
            currentAutoNumber={this.currentAutoNumber}
            target={value}
            />
        </div>
      )
    })
  }
}

CertificateInput.register()
