import ViewComponent from 'ViewComponent'

import { buttons, color, typography } from 'styles'

/**
 * Ask the server to generate a new QR Code.
 */
class OtpGenerate extends ViewComponent {
  constructor ({ authManager, onVerify }, ...args) {
    super({}, ...args)
    Object.assign(this, {
      authManager,
      onVerify,
      html: ko.observable(),
      qrCodeDataURL: ko.observable(),
      otpAuthUrl: ko.observable(),
      secret: ko.observable(),
      token: ko.observable(),
      verifyMessage: ko.observable()
    })
    this.generateCode()
  }

  async generateQRCode () {
    console.log(`<otp-generate> Requesting QRCode`)
    const result = await this.authManager.firebaseFn('otpGenerateSecret')
    console.log(`<otp-generate> QRCode result:`, result)
    this.qrCodeDataURL(result.qrCodeDataURL)
    this.secret(result.base32)
    this.otpAuthUrl(result.otpAuthUrl)
  }

  async generateCode () {
    this.verifyMessage('Awaiting Verification')
    this.html(this.loadingHTML)
    await this.generateQRCode()
    this.html(this.scanQrHTML)
  }

  async verifyToken (input) {
    const result = await this.authManager.firebaseFn('otpVerifyToken', input())
    if (result) {
      this.onVerify()
    } else {
      this.verifyMessage(`Verification failed. Please try again or contact support.`)
    }
  }

  static get verifyCSS () {
    return {
      verify: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
      },
      verifyInputBackground: {
        borderRadius: '4px',
        backgroundColor: 'rgba(0,0,0,0.05)',
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.quarternary,
        },
        padding: '10px',
        gridColumn: '1/3',
        margin: '20px 100px 20px 100px'
      },
      verifyInput: {
        gridColumn: '1/3',
        padding: '0px 24px',
        borderRadius: 2,
        height: 40,
        border: '1px solid rgba(0,0,0,0.3)',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          outline: 'none'
        },
        fontSize: '1em',
        textAlign: 'center'
      },
      verifyMessage2: {
        marginBottom: 10,
        gridColumn: '1/3'
      },
      verifyMessage: {
        color: '#333333',
        gridColumn: '1/3',
        marginLeft: 'auto',
        marginRight: 'auto',
        outline: 'none',
        fontSize: '0.8em',
        textTransform: 'uppercase',
        fontFamily: typography.mono,
        background: color.systemBackground.light.primary,
        padding: '12px 10px 10px 10px',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.2)',
        'body[dark] &': { // project batman
          background: color.fill.dark.primary,
          color: color.text.dark.primary,

          outline: 'none'
        },
        marginTop: 8
      },
      verifyButtons: {},
      verifyButton: {
        ...buttons.clickable,
        color: 'white',
        cursor: 'pointer',
        marginTop: 15,
        border: 'none',
        outline: 'none',
        display: 'flex',
        padding: '12px 18px',
        fontSize: 16,
        minWidth: 90,
        textAlign: 'center',
        userSelect: 'none',
        alignItems: 'center',
        borderRadius: 5,
        textDecoration: 'unset',
        justifyContent: 'center',
        backgroundColor: '#4A90E2',
        width: 130,
        marginLeft: 'auto',
        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.5)'
        }
      },
      regenButton: {
        ...buttons.clickable,
        color: '#4A90E2',
        cursor: 'pointer',
        marginTop: 15,
        border: 'none',
        outline: 'none',
        display: 'flex',
        padding: '12px 18px',
        fontSize: 16,
        minWidth: 90,
        textAlign: 'center',
        userSelect: 'none',
        alignItems: 'center',
        border: '1px solid #4A90E2',
        borderRadius: 5,
        textDecoration: 'unset',
        justifyContent: 'center',
        backgroundColor: color.button.light.modalCancel,
        'body[dark] &': { // project batman
          backgroundColor: color.button.dark.modalCancel,
          color: color.text.dark.primary
        },
        width: 240,
        marginRight: 'auto',
        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.5)'
        }
      }
    }
  }

  get verifyHTML () {
    const { jss } = this
    const input = ko.observable()
    return (
      <div class={jss.verify}>
        <div class={jss.verifyMessage2}>
          <strong>Step 3)</strong> Enter the code from your authenticator app in the box below and click <strong>Verify</strong> below:
        </div>
        <div class={jss.verifyMessage}>
          {this.verifyMessage}
        </div>
        <div class={jss.verifyInputBackground}>
          <input class={jss.verifyInput}
            placeholder='Enter your 2FA code'
            ko-hasFocus={1}
            ko-textInput={input}
            ko-keydown={{ Enter: () => this.verifyToken(input) }} />
        </div>
        <div class={jss.verifyButtons}>
          <async-button faceClass={jss.regenButton}
            action={() => this.generateCode()}>
            <template slot='face'>Generate a New Barcode</template>
          </async-button>
        </div>
        <div class={jss.verifyButtons}>
          <async-button faceClass={jss.verifyButton}
            action={() => this.verifyToken(input)}>
            <template slot='face'>Verify</template>
          </async-button>
        </div>
      </div>
    )
  }

  get loadingHTML () {
    const { jss } = this
    return (
      <div class={jss.loading}>
        <loading-spinner class={jss.loadingSpinner} />
      </div>
    )
  }

  static get scanQrCSS () {
    return {
      qr: {
        background: 'red'
      },
      qrImage: {
        maxWidth: '300px',
        marginBottom: '30px',
        padding: '20px 70px',
        backgroundColor: 'rgba(0,0,0,0.05)',
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.quarternary,
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '4px',
        display: 'grid'
      },
      scanNextButton: {
        ...buttons.clickable,
        color: 'white',
        cursor: 'pointer',
        margin: 5,
        border: 'none',
        outline: 'none',
        display: 'flex',
        padding: '12px 18px',
        fontSize: 16,
        minWidth: 90,
        textAlign: 'center',
        userSelect: 'none',
        alignItems: 'center',
        borderRadius: 5,
        textDecoration: 'unset',
        justifyContent: 'center',
        backgroundColor: '#4A90E2',
        width: 130,
        marginLeft: 'auto',
        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.5)'
        }
      }
    }
  }

  get scanQrHTML () {
    const { jss } = this
    const nextClick = () =>
      this.html(this.verifyHTML)
    return (
      <div class={jss.scanQr}>
        <div class={jss.scanQrInstructions}>
          <strong>Step 2)</strong> Open your authenticator app and use it to scan the barcode below.
        </div>
        <img src={this.qrCodeDataURL} class={jss.qrImage} />
        <div class={jss.scanNextButton}
          ko-click={nextClick}>
            Next
        </div>
      </div>
    )
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.layout}>
        {this.html}
      </div>
    )
  }

  static get css () {
    return {
      ...super.css,
      ...this.verifyCSS,
      ...this.scanQrCSS,
      layout: {
        display: 'flex',
        flexDirection: 'column'
      },
      loading: {
        '--icon-height': '48px',
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
      },
      loadingSpinner: {
        height: '40px'
      },
      scanQrInstructions: {
        marginBottom: 30
      }
    }
  }
}

OtpGenerate.register()
