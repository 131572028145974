
import PanelProvider from 'PanelProvider'
import { buttons, color } from 'styles'
import AppLoadingPanelProvider from './AppLoadingPanelProvider'

import './TermsModal'

export default class TermsOfServicePanelProvider extends PanelProvider {
  get panelWindowTitle () { return `Terms of Service` }

  get historyUrl () { return null }

  get top () {
  }
  get head () {
    const { jss } = this
    const bodyObs = ko.observable(
      <loading-spinner class={jss.spinner} />)
    return (
     <div class={jss.terms}
        ko-self={body => this.loadTerms(body, bodyObs)}>
        {bodyObs}
      </div>
    )
  }
  get main () {
    const { jss } = this
    const bodyObs = ko.observable(
      <loading-spinner class={jss.spinner} />)
    return (

    <div class={jss.wrapper}>
      <div class={jss.header}></div>
      <div class={jss.tools}></div>
      <div class={jss.toc}></div>
      <div class={jss.mainArea}></div>
      <div class={jss.footer}></div>
    </div>

    )
  }

  async loadTerms (body, bodyObs) {
    const { html } = await this.app.defaultAuthManager
      .firebaseFn('termsOfService')
    bodyObs(<terms-modal
      onAgree={() => this.agreeToTerms()}
      termsHTML={html} />)
  }

  agreeToTerms () {
    const { app } = this
    app.defaultAuthManager.termsOfServiceDate(new Date().toISOString())
  }

  get foot () {}

  static get css () {
    return {
      ...super.css,
      spinner: {
        display: 'block',
        marginTop: '20%',
        '--icon-height': '42px',
      },
      terms: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      },
      agree: {
        ...buttons.clickable,
        marginLeft: 'auto',
        padding: '20px',
        alignSelf: 'normal',
        backgroundColor: 'orange',
        borderLeft: '1px solid gray',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'pink',
        }
      },
      wrapper: {
        display: 'grid',
        gridTemplateColumns: '252px auto',
        gridTemplateRows: '82px 58px auto 75px',
        height: '100vh',
        gridTemplateAreas:`
        'header header'
        'tools tools'
        'toc mainArea'
        'footer footer'
        `
      },
      header: {
        gridArea: 'header',
        backgroundColor: color.systemBackground.light.primary,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      tools: {
        gridArea: 'tools',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
      },
      toc: {
        gridArea: 'toc',
        backgroundColor: color.systemBackground.light.secondary,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        borderRight: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.secondary,
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
          borderRight: `1px solid ${color.separator.dark.nonOpaque}`
        },
      },
      mainArea: {
        gridArea: 'mainArea',
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      footer: {
        gridArea: 'footer',
        backgroundColor: color.systemBackground.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
        },
      }

    }
  }
}
