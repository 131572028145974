import TransactionEditor from './TransactionEditor'

import { TransactionIssue } from '../Transaction'

import './certificate-input'
import { color } from 'styles'

/**
 * A Share Issuance consists of:
 *    - ShareCredit (S.+)
 *      - person
 *      - amount
 *      - assetClassID
 *
 *   Optional
 *   - ShareTransferNumber (S.#)
 *      - number
 *      - assetClassID
 */
class CapitalShareIssueEditor extends TransactionEditor<TransactionIssue> {
  createTransaction ({ assetClassID }) : TransactionIssue {
    return new TransactionIssue({
      assetClassID,
      person: '', amount: 0, datetime: null, title: 'Issue'
    })
  }

  get questionHTML () {
    const { jss, clone } = this

    return [
      this._datePicker('Date', clone.datetime),
      this._personPicker('Recipient', clone.person),
      this._numberInput('Amount', clone.amount),
      this._certificatePicker('Certificate', clone.certificateNumber),
      this._nextTransferNumber('Transfer Number', clone.trNumber),
      this._considerationPicker('Monetary Consideration', clone.monetaryConsideration, clone.considerationIsAggregate),
      this._textInput<string>('Non-Monetary Consideration',
        clone.nonMonetaryConsideration),
      this._proxyPicker('Voting Proxy', clone.votingProxy),
      this._textInput<string>('Ledger Note', clone.ledgerNote),
    ].filter(v => v).flat()
  }

  static get css () {
    return {
      ...super.css,
      input: {
        ...super.css.input,
        background: color.textInput.light.primary,
        'body[dark] &': { // project batman
          background: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },
      }
    }
  }

  isValidTransaction (args) {
    const { person, amount } = ko.toJS(args)
    return person && amount
  }
}

CapitalShareIssueEditor.register()
