import { uniqBy } from 'lodash-es'

import { formatForUser } from 'utils/dates'

import ViewComponent from 'ViewComponent'
import { color } from 'styles'

import {
  AuthorizedToInstruct, BusinessNumber, Director, DomainName, ExtraJurisdictionalRegistration, GoverningDocument, Incorporator, LandRegistration, MunicipalBusinessLicense, Officer, Patent, PPSA, RegulatoryLicense, ServiceProvider, Trademark, TradeName
} from 'EntityModel/components'

import { UnitView } from 'DataModel'
import { Responsible } from 'DataModel/components'

export default class TableColumn extends ViewComponent {
  items: import('DataModel').UnitView[]

  constructor ({ items }) {
    super()
    Object.assign(this, { items })
  }

  moreHTML (count) {
    return count === 0 ? null
      : (
        <div class={this.jss.moreContainer}>
          <div class={this.jss.more}>+{count}</div>
        </div>)
  }

  get template () {
    const { jss } = this
    const [v0, ...vrest] = uniqBy(this.items.filter(this.filterItem), 'elvValue')
    const v0elv = this.computed(() => v0 && this.itemToHTML(v0))
    const vrestHTML = vrest
      .map(v => <div class={jss.itemN}>{this.itemToHTML(v)}</div>)

    const restList = this.computed(() => vrest.length
      ? <div class={jss.itemList}>{vrestHTML}</div>
      : null
    )
    return (
      <tool-tip my='top right' at='bottom right'>
        <template slot='anchor'>
          <div class={jss.item0}>
            {v0elv} {this.moreHTML(vrest.length)}
          </div>
        </template>
        <template slot='content'>
          {restList}
        </template>
      </tool-tip>
    )
  }

  filterItem (item) {
    if (!item) { return false }
    switch (item.constructor) {
      case Officer:
      case Director:
        return !item.resigned() || item.resigned() > new Date()
    }
    return true
  }

  itemToHTML (item) {
    switch (item.constructor) {
      case AuthorizedToInstruct: return this.authorizedToInstructHTML(item)
      case BusinessNumber: return this.businessNumberHTML(item)
      case Director: return this.directorHTML(item)
      case DomainName: return this.domainNameHTML(item)
      case ExtraJurisdictionalRegistration: return this.extraJurisdictionalRegistrationHTML(item)
      case GoverningDocument: return this.governingDocumentHTML(item)
      case Incorporator: return this.personHTML(item)
      case LandRegistration: return this.landRegistrationHTML(item)
      case MunicipalBusinessLicense: return this.municipalBusinessLicenseHTML(item)
      case Officer: return this.officerHTML(item)
      case Patent: return this.patentHTML(item)
      case PPSA: return this.pPSAHTML(item)
      case RegulatoryLicense: return this.regulatoryLicenseHTML(item)
      case Responsible: return this.personHTML(item)
      case ServiceProvider: return this.serviceProviderHTML(item)
      case Trademark: return this.trademarkHTML(item)
      case TradeName: return this.tradeNameHTML(item)
      default: return ko.unwrap(item.htmlValue) || ko.unwrap(item.elvValue)
    }
  }

  static get authorizedToInstructCSS () {
    return {}
  }

  authorizedToInstructHTML (item: AuthorizedToInstruct) {
    return item.elvValue
  }

  static get businessNumberCSS () {
    return {
      businessNumber: {
        display: 'grid'
      },
      businessNumberName: {
        fontWeight: 700,
        fontSize: '0.7em'
      },
      businessNumberNumber: {
        fontSize: '0.9em'
      }
    }
  }

  businessNumberHTML (businessNumber : BusinessNumber) {
    const { jss } = this
    return (
      <div class={jss.businessNumber}>
        <div class={jss.businessNumberName}>{businessNumber.name}</div>
        <div class={jss.businessNumberNumber}>{businessNumber.number}</div>
      </div>
    )
  }

  static get directorCSS () {
    return {
      director: {
        display: 'grid'
      },
      directorPerson: {
        fontSize: '0.9em'
      },
      directorTitle: {
        textTransform: 'uppercase',
        fontSize: '0.6em',
        fontWeight: 700,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      },
      directorDate: {
        fontSize: '0.6em',
        fontWeight: 700,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      }
    }
  }

  directorHTML (director: Director) {
    const { jss } = this
    const person = [...director.getPersons(()=>true)].pop()
    return (
      <div class={jss.director}>
        { this.computed(() => ko.unwrap(person) ?
          <person-indicator noDetailsOnHover class={jss.directorPerson} person={person} /> : '') }
        <div class={jss.directorDate} ko-visible={director.elected}>
          Elected: {formatForUser(director.elected())}
        </div>
      </div>
    )
  }

  static get domainNameCSS () {
    return {
      domainName: {
        display: 'grid'
      },
      domainNameTitle: {
        textTransform: 'uppercase',
        fontSize: '0.7em',
        fontWeight: 700,
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      domainNameRegistrar: {
        fontSize: '0.6em',
        fontWeight: 600,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      domainNameDate: {
        fontSize: '0.6em',
        fontWeight: 600,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      }

    }
  }

  domainNameHTML (domainName : DomainName) {
    const { jss } = this
    return (
      <div class={jss.domainName}>
        <div class={jss.domainNameTitle}>{domainName.domain}</div>
        <div class={jss.domainNameRegistrar} ko-visible={domainName.registrar}>
          Registrar: {domainName.registrar}
        </div>
        <div class={jss.domainNameDate} ko-visible={domainName.expiry}>
          Expiry: {formatForUser(domainName.expiry())}
        </div>
      </div>
    )
  }

  static get extraJurisdictionalRegistrationCSS () {
    return {}
  }

  extraJurisdictionalRegistrationHTML (item: ExtraJurisdictionalRegistration) {
    return item.elvValue
  }

  static get governingDocumentCSS () {
    return {
      governingDocument: {
        display: 'grid'
      },
      governingDocumentTitle: {
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
      governingDocumentDate: {
        fontSize: '0.6em',
        fontWeight: 600,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      }

    }
  }

  governingDocumentHTML (governingDocument: GoverningDocument) {
    const { jss } = this
    return (
      <div class={jss.governingDocument}>
        <div class={jss.governingDocumentTitle}>{governingDocument.title}</div>
        <div class={jss.governingDocumentDate} ko-visible={governingDocument.end}>
          Expired: {formatForUser(governingDocument.end())}
        </div>
      </div>
    )
  }

  static get incorporatorCSS () {
    return {}
  }

  personHTML (item: UnitView) {
    const person = [...item.getPersons(() => true)].pop()
    return ko.unwrap(person) ?
      <person-indicator noDetailsOnHover person={person} />
      : item.elvValue
  }

  static get landRegistrationCSS () {
    return {}
  }

  landRegistrationHTML (item: LandRegistration) {
    return item.elvValue
  }

  static get municipalBusinessLicenseCSS () {
    return {}
  }

  municipalBusinessLicenseHTML (item: MunicipalBusinessLicense) {
    return item.elvValue
  }

  static get officerCSS () {
    return {
      officer: {
        display: 'grid'
      },
      officerPerson: {
        fontSize: '0.9em'
      },
      officerTitle: {
        textTransform: 'uppercase',
        fontSize: '0.6em',
        fontWeight: 700,
        color: color.text.light.tertiary,
        'body[dark] &': { // project batman
          color: color.text.dark.tertiary,
        },
      },
      officerDate: {
        fontSize: '0.6em',
        fontWeight: 700,
        color: color.text.light.secondary,
        'body[dark] &': { // project batman
          color: color.text.dark.secondary,
        },
      }

    }
  }

  officerHTML (officer: Officer) {
    const { jss } = this
    const person = [...officer.getPersons(()=>true)].pop()
    return (
      <div class={jss.officer}>
        { this.computed(() => ko.unwrap(person) ?
          <person-indicator noDetailsOnHover class={jss.officerPerson} person={person} /> : '') }
        <div class={jss.officerTitle} ko-visible={officer.title}>{officer.title}</div>
        <div class={jss.officerDate} ko-visible={officer.elected}>
          Elected: {formatForUser(officer.elected())}
        </div>
      </div>
    )
  }

  static get patentCSS () {
    return {}
  }

  patentHTML (item: Patent) {
    return item.elvValue
  }

  static get pPSACSS () {
    return {}
  }

  pPSAHTML (item: PPSA) {
    return item.elvValue
  }

  static get regulatoryLicenseCSS () {
    return {}
  }

  regulatoryLicenseHTML (item: RegulatoryLicense) {
    return item.elvValue
  }

  static get serviceProviderCSS () {
    return {}
  }

  serviceProviderHTML (item: ServiceProvider) {
    return item.elvValue
  }

  static get trademarkCSS () {
    return {
      trademark: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr'
      },
      trademarkFlag: { display: 'inline' },
      trademarkDesc: { position: 'static' },
      trademarkExpiry: {
        gridColumn: '1/3',
        fontSize: '0.6em'
      },
      trademarkAgent: {
        gridColumn: '1/3',
        fontSize: '0.6em',
      },
      trademarkAgentPerson: {
        display: 'inline'
      },
    }
  }

  trademarkHTML (item: Trademark) {
    const { jss } = this
    const agent = [...item.getPersons(()=>true)].pop()
    return (
      <div class={jss.trademark}>
        <jurisdiction-flag class={jss.trademarkFlag} value={item.jurisdiction} />
        <div class={jss.trademarkDesc}>{item.description()}</div>
        <div class={jss.trademarkExpiry} ko-visible={item.expiry}>Expiry: {formatForUser(item.expiry())}</div>
        {this.computed(() => (ko.unwrap(agent) ? (
          <div class={jss.trademarkAgent}>
            Agent: <person-indicator noDetailsOnHover class={jss.trademarkAgentPerson} person={agent} />
          </div>
        ) : ''))}
      </div>
    )
  }

  static get tradeNameCSS () {
    return {}
  }

  tradeNameHTML (item: TradeName) {
    return item.elvValue
  }


  static get css () {
    return {
      ...super.css,
      ...this.authorizedToInstructCSS,
      ...this.businessNumberCSS,
      ...this.directorCSS,
      ...this.domainNameCSS,
      ...this.extraJurisdictionalRegistrationCSS,
      ...this.governingDocumentCSS,
      ...this.incorporatorCSS,
      ...this.landRegistrationCSS,
      ...this.municipalBusinessLicenseCSS,
      ...this.officerCSS,
      ...this.patentCSS,
      ...this.pPSACSS,
      ...this.regulatoryLicenseCSS,
      ...this.serviceProviderCSS,
      ...this.trademarkCSS,
      ...this.tradeNameCSS,

      item0: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        paddingRight: '25px',
        minHeight: '46px',
        alignItems: 'center',
      },
      moreContainer: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'flex-end',
        height: '100%',
      },
      more: {
        fontSize: '0.5rem',
        color: color.text.light.primary,
        borderRadius: 12,
        textShadow: '0px 1px 0px white',
        'body[dark] &': { // project batman
          textShadow: '0px 1px 0px transparent',
          color: color.text.dark.primary,
          backgroundColor: color.fill.dark.opaque
        },
        backgroundColor: '#aee9f7',

        padding: '3px 6px',
      },
      itemList: {
        backgroundColor: color.systemBackground.light.primary,
        padding: 6,
        borderRadius: '4px',
        color: color.text.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemBackground.dark.primary,
          color: color.text.dark.primary
        },
      },
      itemN: {
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        padding: 10,
        '&:last-child': {
          borderBottom: 'none',
        }
      },
    }
  }
}

TableColumn.register()
