/**
 * There are several ways that things can keep track of where they are in
 * the book.
 *
 * Foremost, an array of PageRange instances keeps track of the actual PDF
 * pages that are displayed.
 *
 * Then bookmarks and notes can occur on a specific PDF page, identified
 * and tracked by the PDF url and the PDF page number.
 *
 * Sections are tracked based on the page number at which they start.
 *
 * Finally, "reserved" page numbers for drag-and-drop async events are
 * tracked by index.
 *
 * So we have two fundamental strategies: tracking by PDF page, and tracking
 * by page number.
 */

/**
 * Track an item based on the page number.  If items are added or deleted
 * before this marker, then the size of those additions/deletions are added
 * or removed from the marker's page number.
 */
export default class PageNumberMarker {
  pageNumber: KnockoutObservable<number>

  constructor (pageNumber) {
    Object.assign(this, { pageNumber: ko.observable(pageNumber) })
  }
}
