/**
 *
 * General-purpose SVG => icon styles.
 *
 * Usage:
 *
 *    import icons from 'icons'
 *    import faPlusSquare from 'icons/light/plus-square'
 *    ...
 *    <div>{icons.inline(faPlusSquare)}</div>
 *    <div>{icons.button(faPlusSquare)}</div>
 */

/**
 * Add global JSS-based style sheet for icons.
 */

const styleSheet = window.jssLib.createStyleSheet({
  inline: {
    display: 'inline-block',
    fontSize: 'var(--icon-font-size, inherit)',
    height: 'var(--icon-height,1em)',
    overflow: 'visible',
    verticalAlign: '-.125em',
    fill: 'var(--icon-color)',
  },
  button: {
    extend: 'inline',
    paddingRight: '0.45em'
  },
  caret: {
    extend: 'inline',
    paddingLeft: '0.45em',
    paddingTop: '2px',
    '&:hover': {
      fill: 'rgba(0,0,0,0.75)',
    }
  }
}).attach()

const iconClasses = styleSheet.classes

/**
 * @param {JSX} element to modify
 * @param {string} classToSet
 * @return {JSX}
 *
 * NOTE: In Jest tests the `element.attributes` may not be defined.
 */
function setClass (element, classToSet) {
  if (!element.attributes) { return element }
  element.attributes.class = classToSet
  return element
}

/**
 * Bound the SVG by the height of the line, with padding on the left
 * and right of the icon.
 */
export const inline = (svg) => setClass(svg, iconClasses.inline)

/**
 * Bound the same as an inlineIcon but include right-side padding.
 */
export const button = (svg) => setClass(svg, iconClasses.button)

export const caret = (svg) => setClass(svg, iconClasses.caret)

export default { inline, button, caret }
