
import ViewComponent from 'ViewComponent'
import { buttons } from 'styles'

export default class ConfirmDelete extends ViewComponent {
  constructor ({ onConfirm, onCancel, onFinish }) {
    super()
    Object.assign(this, { onConfirm, onCancel, onFinish })
  }

  static get css () {
    return {
      ...super.css,
      ...this.buttonCSS,
      block: {},
      messages: {},
    }
  }

  callCallback (fn) { this[fn] && this[fn]() }

  async confirmAction () {
    await this.callCallback('onConfirm')
    await this.callCallback('onFinish')
  }

  cancelClick () {
    this.callCallback('onCancel')
    this.callCallback('onFinish')
  }

  async confirmClick () {
    await this.confirmAction()
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.block}>
        <div class={jss.message}>{this.messageHTML}</div>
        <div class={jss.buttons}>
          <button class={jss.cancelButton}
            ko-click={() => this.cancelClick()}>
            Cancel
          </button>
          <async-button faceClass={jss.confirmButton}
            action={() => this.confirmClick()}>
            <template slot='face'>Confirm Delete</template>
          </async-button>
        </div>
      </div>
    )
  }

  static get buttonCSS () {
    return {
      _button: {},

      buttons: {
        gridColumn: '1/-1',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10px'
      },

      confirmButton: {
        ...buttons.modalOk,
      },

      cancelButton: {
        ...buttons.modalCancel
      }
    }
  }
}
