import { RegulatoryLicense } from 'EntityModel/components'

import EisArrayComponentTable from './EisArrayComponentTable'

class EisRegulatoryLicenses extends EisArrayComponentTable<RegulatoryLicense> {
  get caption () { return 'Regulatory Licenses' }
  get componentName () { return 'regulatory_licenses' }
  get listAddName () { return 'Regulatory License' }
  get columns () { return ['description', 'registered', 'renewal', 'expiry'] }
}

EisRegulatoryLicenses.register()
