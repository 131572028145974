import { StyleSheet } from 'jss'

/**
 * Component is the Knockout component base-class for all our view components.
 */
const { ko, jssLib } = window

const staticStyles = {}
const JSS = Symbol('Memoized ViewComponent.jss')

export default abstract class ViewComponent extends ko.Component {
  private _styles: any
  private static customElementName: string

  dispose () {
    super.dispose()
    if (this._styles) { jssLib.removeStyleSheet(this._styles) }
  }

  /**
   * Lazy getter, so subclass constructors can refer to observables and
   * computeds added to the class in the constructor
   * after `super` has been called.
   */
  get styles () : StyleSheet {
    if (!this.css) { return { classes: {} } as StyleSheet }
    const options = {
      meta: `⚙️  Dynamic Classes for ${this.constructor.name}>`,
      link: true,
      classNamePrefix: `${this.constructor.name}--`
    }
    const sheet = jssLib.createStyleSheet(this.css, options).attach()
    // sheet.update(window.app.jssThemeParams())
    // this.subscribe(window.app.jssThemeParams, v => sheet.update(v))
    return this._styles || (this._styles = sheet)
  }

  static get styles () : StyleSheet {
    if (this.name in staticStyles) { return staticStyles[this.name] }
    if (!this.css) { return { classes: {} } as StyleSheet }
    const options = {
      // link: true,
      meta: `🎨  Static Classes for ${this.name}`,
      classNamePrefix: `${this.name}__`
    }
    /**
     * Note that CSSOM rules (when link: true, or sheet.update are called)
     * are not editable.
     *
     * This is marked WONTFIX for Chromium
     *
     * https://bugs.chromium.org/p/chromium/issues/detail?id=387952
     */
    const sheet = jssLib.createStyleSheet(this.css, options).attach()
    // sheet.update(window.app.jssThemeParams())
    // window.app.jssThemeParams.subscribe(v => sheet.update(v))
    return (staticStyles[this.name] = sheet)
  }

  /**
   * Return the classes object for our JSS/CSS.
   */
  get jss (): Record<string, string> {
    return this[JSS] || (this[JSS] = {
      ...this.constructor.styles.classes,
      ...this.styles.classes,
    })
  }

  /**
   * @return {object|null} containing JSS-style classes that are specific to
   * the instance.
   *
   * This offers more dynamic options than `static get css`, but at a
   * substantial performance cost.  Use sparingly.
   */
  get css () { return null }

  /**
   * @return {object} containing JSS-style classes that apply to every
   * instance of this class.
   *
   * This is higher performance than `get css`
   */
  static get css (): any { return {} }

  /**
   * Called when the component is removed from the DOM.  Must be on the
   * prototype (for performance we don't add a callback for every component).
   */
  disconnectedCallback? (node: HTMLElement): void

  /**
   * See discussion at https://github.com/NetPleadings/MinuteBox/issues/187
   */
  static register (name = this.customElementName) {
    const registration = super.register(name)
    const wantsCallback = 'disconnectedCallback' in this.prototype
    if (!wantsCallback) { return }
    if (window.customElements) {
      customElements.define(name, class extends HTMLElement {
        // connectedCallback (this: HTMLElement) {
        //   const component = ko.dataFor(this.children[0])
        //   console.log(`connected`, this, ko.dataFor(this), component)
        // }
        disconnectedCallback (this: HTMLElement) {
          const component = ko.dataFor(this.children[0])
          if (component) { component.disconnectedCallback(this) }
        }
      })
    } else {
      console.warn(`"window.customElements" is not available.  Unable to
      register lifecycls connected/disconnected variables.`)
    }

    return registration
  }

  computed<T> (...args) : KnockoutComputed<T> {
    return super.computed(...args)
  }

  subscribe<T> (o: KnockoutObservable<T>, fn: (v: T) => void) {
    return super.subscribe(o, fn)
  }
}
