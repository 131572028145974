
import { computed } from 'utils/decorator'

export interface Orderable {
  orderIndex: KnockoutObservable<number>
}

type FilterFunction<O> = (i: O) => boolean
type MapFunction<O, T> = (value: O, index: number, array: O[]) => T
type VoidFunction<O> = (value: O, index: number, array: O[]) => void

/**
 * This is an orderable list of items that, when something is re-ordered,
 * does not trigger changes "down the line" in the diff chain.
 *
 * In other words, it aims to make changes to the order exhibit as changes
 * to the block.
 */
export class ArrayOrderedByIndex<O extends Orderable> {
  private items: KnockoutObservableArray<O> = ko.observableArray([])

  constructor (items: O[] = []) {
    this.items(items)
    this.homogenize()
  }

  get length () { return this.items.peek().length }
  * [Symbol.iterator] () { yield * this.ordered() }
  indexOf (item: O) { return this.ordered().indexOf(item) }
  every (fn: FilterFunction<O>) { return this.items().every(fn) }
  some (fn: FilterFunction<O>) { return this.items().some(fn) }
  filter (fn: FilterFunction<O>) { return this.ordered().filter(fn) }
  slice (start: number, end?: number) { return this.ordered().slice(start, end) }
  map<T> (fn: MapFunction<O, T>) { return this.ordered().map(fn) }
  forEach (fn: VoidFunction<O> ) { this.ordered().forEach(fn) }
  at (index: number) { return this.items().find(v => v.orderIndex() === index) }
  set (list: O[]) {
    this.items(list)
    this.homogenize()
  }

  @computed()
  private ordered () {
    return this.items().sort((a, b) => a.orderIndex() - b.orderIndex())
  }

  insertBefore (beforeIndex: number, ...items: O[]) {
    const itemCount = items.length
    const hStart = Math.min(...items.map(i => i.orderIndex()), beforeIndex)
    this.items()
      .filter(o => beforeIndex <= o.orderIndex())
      .forEach(o => o.orderIndex.modify(oi => oi + itemCount))
    items.forEach((o, i) => o.orderIndex(beforeIndex + i))
    this.items.push(...items)
    this.homogenize(hStart)
  }

  insertAfter (afterIndex: number, ...items: O[]) {
    this.insertBefore(afterIndex + 1, ...items)
  }

  /**
   * Delete the item at the given index.
   * @return the removed item.
   */
  delete (index: number): O {
    const r = this.items.remove(v => v.orderIndex() === index).pop()
    if (r) { this.homogenize(index) }
    return r
  }

  replace (atIndex: number, ...items: O[]) {
    this.items.remove(v => v.orderIndex() === atIndex)
    this.insertBefore(atIndex, ...items)
    this.homogenize(atIndex + items.length)
  }

  remove (...items: O[]) {
    const removed = this.items.removeAll(items)
    if (!removed.length) { return }
    this.homogenize(Math.min(...removed.map(r => r.orderIndex())))
  }

  move (toIndex: number, ...items: O[]) {
    const lowest = Math.min(0, toIndex, ...items.map(o => o.orderIndex()))
    this.homogenize(toIndex, items.length)
    items.forEach((item, n) => item.orderIndex(toIndex + n))
    this.homogenize(lowest)
  }

  push (...items: O[]) {
    const start = this.items.length
    items.forEach((i, n) => i.orderIndex(start + n))
    this.items.peek().push(...items)
    this.items.valueHasMutated()
  }

  swap (fromIndex: number, toIndex: number) {
    const o = ko.ignoreDependencies(() => this.ordered())
    o[fromIndex].orderIndex(toIndex)
    o[toIndex].orderIndex(fromIndex)
  }

  private homogenize (start = 0, margin = 0) {
    const list = this.ordered()
    list.slice(start).forEach((i, n) => i.orderIndex(n + start + margin))
  }
}


export default ArrayOrderedByIndex
