import { get } from 'lodash-es'

import RootPanelProvider from './RootPanelProvider'
import OutcomeNotification from 'notification-manager/outcome-notification'
import CommandSet from 'Command/CommandSet'

import { buttons, color } from 'styles'
import checkIcon from 'icons/solid/check'

export default class SecurityPanelProvider extends RootPanelProvider {
  get panelWindowTitle () { return `Security` }
  get panelID () { return 'Security' }
  get historyUrl () { return '#security' }
  get CommandSetConstructor () { return CommandSet }
  get peerPanelList () { return this.app.settingsProviderList }

  constructor (params) {
    super(params)
    Object.assign(this, {
      secondFactorState: ko.observable()
    })
  }

  static get css () {
    return {
      ...super.css,
      ...this.secondFactorCSS,
      ...this.webauthnCSS,
      ...this.getOtpAppCSS,
      main: {
        padding: '30px'
      },

      AppleBadge: {
        width: '160px',
        margin: '10px 10px 0px 0px'
      },

      GoogleBadge: {
        width: '175px',
        margin: '10px 0px 0px 0px',
        border: '1px solid black',
        borderRadius: '8px'
      },
      MetaAppGroup: {
        backgroundColor: 'rgba(0,0,0,0.05)',
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.quarternary,
        },
        borderRadius: 4,
        gridColumn: '1/3',
        margin: '0px 0px 0px 40px',
        paddingBottom: 15
      },

      AppGroup: {
        marginLeft: '40px',
        display: 'flex'
      },

      AppTitle: {
        lineHeight: 0,
        margin: '25px 0px 10px 40px',
        fontWeight: 600
      },

      AppIcon: {
        width: '53px',
        margin: '10px 10px 0px 0px'
      }
    }
  }

  static get appGridClassCSS () {
    return {
      ...super.appGridClassCSS,
      grid: {
        ...super.appGridClassCSS.grid,
        templateRows: 'auto auto auto auto 1fr',
        templateAreas: `
        'ty ty ty'
        'head head head'
        'top top top'
        'left main right'
        'left main right'
        `
      }
    }
  }


  disableSecondFactor () {
    const { jss } = this
    const disableClick = async () => {
      await this.app.defaultAuthManager.firebaseFn('otpDisable')
      this.onOtpDisable()
    }
    this.app.modal(
      <modal-dialog modalTitle='Disable Two Factor Authentication'>
        <template slot='content'>
          <div class={jss.disableMessage}>To <strong>disable</strong> Two Factor Authentication click <strong>Confirm</strong> below.</div>
          <async-button faceClass={jss.button} action={disableClick}>
            <template slot='face'>Confirm</template>
          </async-button>
        </template>
      </modal-dialog>
    )
  }

  get otpModalHTML () {
    return (
      <modal-dialog modalTitle='Enable Two Factor Authentication'>
        <template slot='content'>
          <otp-generate
            authManager={this.app.defaultAuthManager}
            onVerify={() => this.onOtpVerify()} />
        </template>
      </modal-dialog>
    )
  }

  static get getOtpAppCSS () {
    return {
      getOtpApp: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)'
      },
      getOtpAppMessage1: {
        gridColumn: '1/3'
      },
      getOtpAppMessage2: {
        gridColumn: '1/3',
        marginLeft: '40px',
        marginTop: 5
      },
      button: {
        ...buttons.clickable,
        color: 'white',
        cursor: 'pointer',
        margin: 5,
        border: 'none',
        outline: 'none',
        display: 'flex',
        padding: '12px 18px',
        gridColumn: '2/3',
        fontSize: 16,
        minWidth: 90,
        textAlign: 'center',
        userSelect: 'none',
        alignItems: 'center',
        borderRadius: 5,
        textDecoration: 'unset',
        justifyContent: 'center',
        backgroundColor: '#4A90E2',
        width: 130,
        marginLeft: 'auto',
        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.5)'
        }
      },
      disableMessage: {
        marginBottom: '20px'
      },
      getOtpAppNextButton: {
        ...buttons.clickable,
        color: 'white',
        cursor: 'pointer',
        margin: 5,
        border: 'none',
        outline: 'none',
        display: 'flex',
        padding: '12px 18px',
        gridColumn: '2/3',
        fontSize: 16,
        minWidth: 90,
        textAlign: 'center',
        userSelect: 'none',
        alignItems: 'center',
        borderRadius: 5,
        textDecoration: 'unset',
        justifyContent: 'center',
        backgroundColor: '#4A90E2',
        width: 130,
        marginLeft: 'auto',
        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.5)'
        }

      }
    }
  }

  enableSecondFactor () {
    const { jss } = this
    const nextClick = () => this.app.modal(this.otpModalHTML)

    this.app.modal(
      <modal-dialog modalTitle='Enable Two Factor Authentication'>
        <template slot='content'>
          <div class={jss.getOtpApp}>
            <div class={jss.getOtpAppMessage1}>
              Turning on <strong>two factor authentication</strong> is easy:
            </div>
            <div class={jss.getOtpAppMessage2}>
              <p><strong>Step 1)</strong> Download an authenticator app to your phone and then click <strong>Next</strong> below.</p>
            </div>
            <div class={jss.MetaAppGroup}>
              <div>
                <p class={jss.AppTitle}>Authenticator</p>
              </div>
              <div class={jss.AppGroup}>
                <div>
                  <img class={jss.AppIcon} src='https://www.minutebox.com/assets/app/badges/authenticator.png' />
                </div>
                <div>
                  <a href='https://itunes.apple.com/ca/app/authenticator/id766157276?mt=8' target='_blank'><img class={jss.AppleBadge} src='https://www.minutebox.com/assets/app/badges/getonitunes.png' /></a>
                </div>
              </div>
              <div>
                <p class={jss.AppTitle}>Google Authenticator</p>
              </div>
              <div class={jss.AppGroup}>
                <div>
                  <img class={jss.AppIcon} src='https://www.minutebox.com/assets/app/badges/google-authenticator.png' />
                </div>
                <div>
                  <a href='https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8' target='_blank'><img class={jss.AppleBadge} src='https://www.minutebox.com/assets/app/badges/getonitunes.png' /></a>
                </div>
                <div>
                  <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA' target='_blank'><img class={jss.GoogleBadge} src='https://www.minutebox.com/assets/app/badges/google-play-badge.png' /></a>
                </div>
              </div>
            </div>
            <div class={jss.getOtpAppMessage2}>
              <p>If you already have an authenticator app, click <strong>Next</strong> to advance to the next step.</p>
            </div>
            <div class={jss.getOtpAppNextButton}
              ko-click={nextClick}>Next</div>
          </div>
        </template>
      </modal-dialog>
    )
  }

  onOtpVerify () {
    this.app.modal(undefined)
    this.secondFactorState(this.secondFactorJustEnabledHTML)
    this.app.notifier.pushOutcome(...this.secondFactorEnabledNotification)
  }

  onOtpDisable () {
    this.app.modal(undefined)
    this.secondFactorState(this.secondFactorJustDisabledHTML)
    this.app.notifier.pushOutcome(...this.secondFactorDisableNotification)
  }

  get secondFactorEnabledNotification () {
    const { jss } = this
    return [<p><strong>Success:</strong> Two factor authentication is <strong>enabled</strong>.</p>, {
      icon: checkIcon,
      style: {
        outcome: jss.secondFactorEnableNotificationOutcome,
        icon: jss.secondFactorEnableNotificationIcon,
        message: jss.secondFactorEnableNotificationMessage
      }
    }]
  }

  get secondFactorDisableNotification () {
    const { jss } = this
    return [<p><strong>Success:</strong> Two factor authentication is <strong>disabled</strong>.</p>, {
      icon: checkIcon,
      style: {
        outcome: jss.secondFactorDisableNotificationOutcome,
        icon: jss.secondFactorDisableNotificationIcon,
        message: jss.secondFactorDisableNotificationMessage
      }
    }]
  }

  get secondFactorEnabledHTML () {
    const { jss } = this
    return (
      <div class={jss.secondFactorEnabled}
        ko-click={() => this.disableSecondFactor()}>
        Click to Disable
      </div>
    )
  }

  get secondFactorDisabledHTML () {
    const { jss } = this
    return (
      <div class={jss.secondFactorNotEnabled}
        ko-click={() => this.enableSecondFactor()}>
        Click to Enable
      </div>
    )
  }

  get secondFactorJustEnabledHTML () {
    return (
      <div class={this.jss.secondFactorJustEnabled}>
        {this.secondFactorEnabledHTML}
        <check-mark class={this.jss.secondFactorCheckMark} />
      </div>
    )
  }

  get secondFactorJustDisabledHTML () {
    return (
      <div class={this.jss.secondFactorJustDisabled}>
        {this.secondFactorDisabledHTML}
      </div>
    )
  }

  get secondFactorHTML () {
    const { jss } = this
    const claims = this.app.defaultAuthManager.userFirebaseClaims()
    this.secondFactorState(claims.otp
      ? this.secondFactorEnabledHTML
      : this.secondFactorDisabledHTML)

    return (
      <div class={jss.metaSettingsGroup}>
        <div class={jss.secondFactorTitle}>
          Two factor authentication
          <q-tip name='settingsSecondFactor' />
        </div>
        <div class={jss.secondFactorState}>
          {this.secondFactorState}
        </div>
      </div>
    )
  }

  static get secondFactorCSS () {
    return {
      metaSettingsGroup: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        backgroundColor: color.systemGroupBackground.light.secondary,
        'body[dark] &': { // project batman
          backgroundColor: color.systemGroupBackground.dark.primary,
        },
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.30)',
        padding: 30,
        marginTop: 10,
        '@media (max-width: 1130px)': {
          minWidth: '568px',
        },
        borderRadius: '5px',
        '&:first-child': {
          marginTop: '0px'
        }
      },
      _settingsButton: {
        ...buttons.clickable,
        fontWeight: '600',
        color: color.text.light.primary,
        background: color.fill.light.primary,
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
          color: color.text.dark.primary
        },
        borderRadius: 4,
        textAlign: 'center',
        padding: '5px 15px',
        '&:hover': {
          background: color.fill.light.secondary
        }
      },
      secondFactorEnabled: {
        extend: '_settingsButton'
      },
      secondFactorNotEnabled: {
        extend: '_settingsButton',
        gridColumn: '2/3'
      },
      secondFactorTitle: {
        marginRight: '10px'
      },
      secondFactorState: {
        marginLeft: 'auto'
      },
      settingDescription: {
        gridColumn: '1/3',
        gridRow: '2/3',
        marginTop: '25px',
        fontSize: '0.7em',
        padding: '10px',
        background: 'white',
        borderTop: '3px solid #ffd502',
        borderRadius: 2
      },

      _secondFactorJust: {
        display: 'grid',
        gridTemplateColumns: '11em auto',
        alignItems: 'center',
        gridColumnGap: '12px'
      },

      secondFactorJustEnabled: {
        extend: '_secondFactorJust'
      },

      secondFactorJustDisabled: {
        extend: '_secondFactorJust'
      },

      secondFactorCheckMark: {
        '--icon-height': '24px',
        gridColumn: '1/2',
        gridRow: '1/2',
        marginLeft: 'auto'
      },

      secondFactorEnableNotificationOutcome: {
        ...OutcomeNotification.css.outcome
      },
      secondFactorEnableNotificationIcon: {},
      secondFactorEnableNotificationMessage: {},

      secondFactorDisableNotificationOutcome: {
        ...OutcomeNotification.css.outcome
      },
      secondFactorDisableNotificationIcon: {},
      secondFactorDisableNotificationMessage: {}
    }
  }

  credentialHTML (c: firebase.firestore.QueryDocumentSnapshot) {
    const { jss } = this
    const { commonName, origin, registered } = c.data()

    return (
      <div class={jss.credential}>
        <div class={jss.credentialCommonName}>{commonName}</div>
        <div class={jss.credentialRegistered}>Registered {registered}</div>
        <div class={jss.credentialOrigin}>{origin}</div>
        <div class={jss.credentialRemove}
            ko-ownClick={() => c.ref.delete()}>
            Remove
          </div>
      </div>
    )
  }

  get webauthnHTML () {
    const { jss } = this
    const authManager = window.app.defaultAuthManager
    const credentials = ko.observableArray([])
    authManager.userDocRef
      .collection('credentials')
      .where(`verified`, '==', true)
      .onSnapshot(s => credentials(s.docs.map(c => this.credentialHTML(c)))
    )

    return (
      <div class={jss.metaSettingsGroup}>
        <div class={jss.secondFactorTitle}>
          Hardware Key or Biometric authentication
          <q-tip name='settingsHardwareKey' />
        </div>
        <div class={jss.metaWebauthn}>
          <div class={jss.authenticatorsList}>
            {credentials}
          </div>
          <div class={jss.metaWebauthAddButton}>
            <async-button faceClass={jss.webauthAddButton}
              action={() => this.addWebauthnCredential(authManager)}>
              <template slot='face'>Add</template>
            </async-button>
          </div>
          <div class={jss.metaWebAuthTestButton}>
            <async-button faceClass={jss.webAuthTestButton}
              action={() => this.testWebAuthn()}>
              <template slot='face'>Test</template>
            </async-button>
          </div>
        </div>
      </div>
    )
  }

  async addWebauthnCredential (authManager: AuthManager) {
    const email = authManager.firebaseUser().email
    const vr = ko.observable()

    window.app.modal(
      <webauth-register-modal
        email={email}
        authManager={authManager}
        verificationResult={vr} />
    )

    await vr.yet(undefined)
      .then(() => Promise.delay(1000))
      .then(() => window.app.modal(undefined))
  }

  async testWebAuthn () {
    const authManager = this.app.defaultAuthManager
    const email = authManager.firebaseUser().email
    const vr = ko.observable()

    window.app.modal(
      <webauth-challenge-modal
        email={email}
        authManager={authManager}
        verificationResult={vr} />
    )

    await vr.yet(undefined)
      .then(() => Promise.delay(5000))
      .then(() => window.app.modal(undefined))
  }

  static get webauthnCSS () {
    return {
      metaWebauthn: {
        gridColumn: '2/3',
        display: 'grid',
        gridGap: '5px',
        gridTemplateColumns: '1fr 1fr'
      },
      authenticatorsList: {
        gridColumn: '1/3'
      },
      webauthAddButton: {
        ...buttons.clickable,
        extend: '_settingsButton',
        gridColumn: '2/3'
      },
      metaWebauthAddButton: {
        gridColumn: '2/3'
      },
      webAuthTestButton: {
        ...buttons.clickable,
        extend: '_settingsButton',
        gridColumn: '1/2'
      },
      metaWebAuthTestButton: {
        gridColumn: '1/2',
        gridRow: '2'
      },
      credential: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 5,
        borderBottom: `1px solid ${color.separator.light.nonOpaque}`,
        'body[dark] &': { // project batman
          borderBottom: `1px solid ${color.separator.dark.nonOpaque}`,
        },
      },
      _credential: {
        fontSize: '0.6em',
        display: 'flex',
        alignItems: 'center'
      },
      credentialCommonName: {
        extend: '_credential',
      },
      credentialRegistered: {
        extend: '_credential',
      },
      credentialOrigin: {
        extend: '_credential',
      },
      credentialRemove: {
        ...buttons.clickable,
        extend: '_settingsButton',
      },
    }
  }

  get main () {
    return (
      <div class={this.jss.main}>
        {this.secondFactorHTML}
        {this.webauthnHTML}
      </div>
    )
  }
}
