import tko from '@tko/build.reference/dist/build.reference.es6'

const { DOCUMENT_POSITION_PRECEDING } = document

/**
 * @param {HTMLElement} a
 * @param {HTMLElement} b
 * @return {bool} true when node a occurs before b
 * This is also truthy when a contains b.
 */
export function elementIsBefore (a, b) {
  const position = b.compareDocumentPosition(a)
  return position & DOCUMENT_POSITION_PRECEDING
}

/**
 *
 * @param {HTMLElement} a
 * @param {HTMLElement} b
 * @return {bool}
 */
export function elementsAreAdjacent (a, b) {
  return (a.nextElementSibling === b) || (b.nextElementSibling === a)
}

/**
 * @param {HTMLElement} element
 * @return {object}
 */
export function attributesAsObject (element) {
  return Object.assign({},
    ...[...element.attributes].map(n => ({ [n.name]: n.value }))
  )
}

/**
 * @param {HTMLElement} element
 * @param {object} object
 */
export function attributesFromObject (element, object = {}) {
  for (const [k, v] of Object.entries(object)) {
    element.setAttribute(k, v)
  }
}

/**
 * @param {HTMLElement} node
 * @return {HTMLElement|null} the first focusable input element
 */
export function firstInputElement (node) : HTMLElement {
  return tabbableChildren(node).next().value
}


/**
 * True when the element can be tabbed to.
 *
 * See e.g.
 * - https://allyjs.io/data-tables/focusable.html
 * - https://stackoverflow.com/questions/35842798
 */
export function isTabbable (element: HTMLElement) : boolean {
  if (!element || !element.offsetParent) { return false }
  switch (element.tagName) {
    case 'INPUT':
    case 'TEXTAREA':
    case 'A':
    case 'BUTTON':
    case 'SELECT':
      return element.getAttribute('tabindex') !== '-1'
  }
  return element.hasAttribute('tabindex')
    && element.getAttribute('tabindex') !== '-1'
}


export function * tabbableChildren (parent: HTMLElement) : IterableIterator<HTMLElement> {
  for (const child of parent.children) {
    if (isTabbable(child as HTMLElement)) {
      yield child as HTMLElement
    } else {
      yield * tabbableChildren(child as HTMLElement)
    }
  }
}

function getTabbable (parent, offset, current: HTMLElement) : HTMLElement {
  const tKids = [...tabbableChildren(parent)]
  const index = tKids.indexOf(current)
  return tKids[index + offset]
}


export function nextTabbable (parent, current: HTMLElement = document.activeElement as HTMLElement): HTMLElement {
  return getTabbable(parent, 1, current)
}

export function prevTabbable (parent, current: HTMLElement = document.activeElement as HTMLElement): HTMLElement {
  return getTabbable(parent, -1, current)
}

const windowScrollPosition = tko.observable<number>()
  .extend({ rateLimit: { timeout: 50, method: "notifyWhenChangesStop" } })
window.addEventListener('scroll',
  () => windowScrollPosition(window.scrollY),
  { passive:true }
)
export { windowScrollPosition }

export function whenHeightStabilizes (element: HTMLElement, timeout = 100) : Promise<number> {
  let scrollHeight = element.scrollHeight
  const wait = async () => {
    await Promise.delay(timeout)
    if (scrollHeight === element.scrollHeight) { return element.scrollHeight }
    scrollHeight = element.scrollHeight
    return wait()
  }
  return wait()
}

/**
 * Remove the parents of the node, leaving the content in place.
 */
export function unwrap (node: Element) {
  node.replaceWith(...node.childNodes)
}

/**
 * Same as `element.innerHTML`, except for a `DocumentFragment`.
 */
export function documentFragmentInnerHTML (df: DocumentFragment) {
  return [...df.childNodes].map(f => f.outerHTML || f.textContent).join('')
}
