import icons from 'icons'
import checkIcon from 'icons/light/check'
import ModalDialog from 'modal-dialog'
import { buttons } from 'styles'

export default class TermsModal extends ModalDialog {
  constructor ({ onAgree, termsHTML }) {
    super({ modalTitle: 'Terms of Service' })
    Object.assign(this, { onAgree, termsHTML })
  }

  static get css () {
    return {
      ...super.css,
      ...this.boolCSS,
      activeShade: {
        display: 'none'
      },
      terms: {
        display: 'grid',
        width: 700,
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: `
        'intro intro'
        'legal legal'
        'checkmark checkmark'
        '. emailMe'
        '. buttons'
        `,
        '@media (max-width: 800px)': {
          width: 600
        },
        '@media (max-width: 690px)': {
          width: 400
        },
        '@media (max-width: 480px)': {
          width: 300
        }
      },
      intro: {
        gridArea: 'intro'
      },

      /**
       * The `legal` contains all the HTML from the Markdown
       * terms of service (in/copied from `www`).
       */
      legal: {
        padding: 20,
        border: '1px solid',
        gridArea: 'legal',
        marginTop: 15,
        marginBottom: 15,
        height: '300px',
        overflow: 'scroll',
        '& p': {
          // Paragraph styles
        },
        '& h1': {
          // Header level 1
        },
        '& h2': {
        },
        '& h3': {
        },
        '& h4': {
        },
        '& ol': {
        },
        '& li': {
        }
      },

      checkmark: {
        ...buttons.clickable,
        padding: '5px 0px',
        textAlign: 'right',
        gridArea: 'checkmark'
      },
      emailMe: {
        padding: '5px 0px',
        textAlign: 'right',
        gridArea: 'emailMe'
      },
      buttons: {
        padding: '5px 0px',
        textAlign: 'right',
        gridArea: 'buttons'
      },
      confirmButton: {
        ...buttons.modalOk,
        filter: 'grayscale(80%)',
        '&[agreed]': {
          filter: 'none'
        }
      }
    }
  }

  get contentHTML () {
    const { jss } = this
    const agreed = ko.observable(false)
    const buttonClass = this.computed(
      () => agreed() ? jss.boolButtonYes : jss.boolButtonNo)

    const setTerms = node => node.innerHTML = this.termsHTML
    return (
      <div class={jss.terms}>
        <div class={jss.intro}>
          <strong>In order to use MinuteBox you must agree to the Terms of Service below.</strong> You can also visit our website to read our <a href="https://www.minutebox.com/terms" target="_blank">Terms of Service</a> and <a href="https://www.minutebox.com/privacy" target="_blank">Privacy Policy</a>.
        </div>
        <div class={jss.legal} ko-self={setTerms} />
        <div class={jss.checkmark}
          ko-click={() => agreed.modify(v => !v)}>
          <button type='text' class={buttonClass}>
            {icons.inline(checkIcon)}
          </button>
          I have read and agree to the terms of service.
        </div>
        {/* <div class={jss.emailMe}>
        Email me a copy of these terms.
        </div> */}
        <div class={jss.buttons}>
          <div class={jss.confirmButton}
            ko-attr={{ agreed }}
            ko-click={() => agreed() && this.onAgree()}>
          Accept
          </div>
        </div>
      </div>
    )
  }

  static get boolCSS () {
    return {
      _boolButton: {
        // fontSize: '1rem',
        height: '25px',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        outline: 'none',
        border: 0,
        margin: '0px 15px',
        '&:active': {
          outline: 'none',
          boxShadow: 'inset 0 1px 2px 1px rgba(0,0,0,0.4)'
        }
      },
      boolButtonYes: {
        extend: '_boolButton',
        '--icon-color': 'green'
      },
      boolButtonNo: {
        extend: '_boolButton',
        '& svg': { opacity: 0 }
      }
    }
  }
}

TermsModal.register()
