/**
 * JWK utilities
 */

import { toBigInteger, fromBigInteger } from './base64url'
import { pointMultiply, BigIntType, Curve, BigPoint } from './ecc'

type CurveKey = {
  d: BigIntType,
  x: BigIntType,
  y: BigIntType
}

type JwkCurveKey = {
  d: string,
  x: string,
  y: string,
  kty: string,
  crv: string,
  key_ops: Array<string>,
}

export function ecJwkToBigInteger (jwk: JwkCurveKey) : CurveKey {
  return {
    d: toBigInteger(jwk.d),
    x: toBigInteger(jwk.x),
    y: toBigInteger(jwk.y),
  }
}

/**
 * @param {BigInteger} d
 * @param {object} curve one of p256, p384, x25519, etc.
 */
export function ecGeneratePublicXY (d: BigIntType, curve: Curve) : BigPoint {
  return pointMultiply(d, curve.gx, curve.gy, curve.a, curve.p)
}

/**
 * @param {string | BigInteger} d
 * @param {object} curve for ecGeneratePublicXY
 */
export function ecGeneratePrivateKey (d: BigIntType, curve: Curve, exportable : boolean = false) : JwkCurveKey {
  if (typeof d === 'string') { d = toBigInteger(d) }
  const { x, y } = ecGeneratePublicXY(d, curve)
  const bytePad = curve.bits / 8
  return {
    kty: 'EC',
    crv: curve.name,
    key_ops: ['deriveKey'],
    d: fromBigInteger(d, bytePad),
    x: fromBigInteger(x, bytePad),
    y: fromBigInteger(y, bytePad)
  }
}
