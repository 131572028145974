
import { color, typography } from 'styles'

import ViewComponent from 'ViewComponent'
const FIELD_TEXT_COLOR = '#333'

/**
 * Edit a list of addresses.
 */
export default class AddressListEditor extends ViewComponent {
  constructor ({ list }) {
    super()
    Object.assign(this, { list })
  }

  get css () {
    return {
      ...super.css,
      list: { },
      item: {
        marginBottom: '20px',
        paddingBottom: '20px',
        backgroundImage: `linear-gradient(to left, ${color.grey.d} 30%, rgba(255, 255, 255, 0) 0%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '7px 1px',
        backgroundRepeat: 'repeat-x',
        '&:last-child': {
          background: 'none',
          marginBottom: 0,
          paddingBottom: 0
        }
      },
      text: {
        whiteSpace: 'pre-line',
        margin: '2px',
        minHeight: '22px'
      },
      label: {
        ...typography.face.title,
        color: FIELD_TEXT_COLOR,
        padding: '2px 2px'
      }
    }
  }

  addressItemHTML (item) {
    const { jss } = this
    return (
      <div class={jss.item}>
        <div class={jss.text}>
          {item.text}
        </div>
        <div class={jss.label}>
          {item.label}
        </div>
      </div>
    )
  }

  get addressListHTML () {
    return ko.unwrap(this.list).map(l => this.addressItemHTML(l))
  }

  get template () {
    return (
      <div class={this.jss.list}>
        {this.computed(() => this.addressListHTML)}
      </div>
    )
  }
}

AddressListEditor.register()
