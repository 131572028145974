
import {
  addDays, addMonths, addYears
} from 'date-fns/esm'

import ModalDialog from 'modal-dialog'
import { buttons } from 'styles'

export default class DatePickerModal extends ModalDialog {
  target: KnockoutObservable<Date>
  focus: KnockoutObservable<Date>
  relatedDates: RelatedDatesSource

  constructor (params) {
    super(params)
    const { target, relatedDates } = params
    Object.assign(this, {
      target,
      relatedDates,
      focus: ko.observable(target() || new Date())
    })
  }

  static get css () : any {
    return {
      ...super.css,
      activeContainer: {
        ...super.css.activeContainer,
        top: 'unset',
        bottom: 0,
        height: 'auto',
      },
      inactiveContainer: {
        ...super.css.inactiveContainer,
        transform: 'translate3d(0, 100%, 0)',
      },
      pickerContent: {},
      buttons: {
        display: 'flex',
        padding: '10px 0px',
      },
      ok: {
        ...buttons.modalOk,
      },
      cancel: {
        ...buttons.modalCancel,
        marginLeft: 'auto',
      }
    }
  }

  get contentHTML () {
    const { jss } = this
    const onSelect = (day: Date) => {
      this.target(day)
      this.dismiss()
    }

    const onClear = () => onSelect(null)

    const finish = (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      window.app.modal(undefined)
    }

    const ok = (evt) => {
      this.target(this.focus())
      finish(evt)
    }

    const cancel = (evt) => finish(evt)

    return (
      <div class={jss.pickerContent}>
        <date-picker
          relatedDates={this.relatedDates}
          onSelect={onSelect}
          onClear={onClear}
          given={this.target()}
          focus={this.focus} />
        <div class={jss.buttons}>
          <div class={jss.cancel} ko-ownClick={cancel}>
            Cancel
          </div>
          <div class={jss.ok} ko-ownClick={ok}>
            Ok
          </div>
        </div>
      </div>
    )
  }

  get keyboardShortcuts () {
    const enter = () => {
      this.target(this.focus())
      window.app.modal(undefined)
    }
    const modifiers = ['shift']

    const jumpFn = (fn: typeof addDays, by: number) =>
      () => this.focus.modify(f => fn(f, by))

    return [
      ...super.keyboardShortcuts,
      { key: 'ArrowUp', action: jumpFn(addDays, -1) },
      { key: 'ArrowDown', action: jumpFn(addDays, 1) },
      { key: 'ArrowUp', modifiers, action: jumpFn(addDays, -7) },
      { key: 'ArrowDown', modifiers, action: jumpFn(addDays, 7) },
      { key: 'ArrowLeft', action: jumpFn(addMonths, -1) },
      { key: 'ArrowRight', action: jumpFn(addMonths, 1) },
      { key: 'ArrowLeft', modifiers, action: jumpFn(addYears, -1) },
      { key: 'ArrowRight', modifiers, action: jumpFn(addYears, 1) },
      { key: 'Enter', action: enter },
    ]
  }
}

DatePickerModal.register()
