
import Picker from 'picker'

import { CAPITAL_PROPERTY_TYPES } from 'entity-information-summary/constants'

import { CapitalAssetPropertyChoice } from '../interfaces'


export default class CapitalPropertyPicker extends Picker<CapitalAssetPropertyChoice> {
  choices () { return CAPITAL_PROPERTY_TYPES }

  filterChoice (item, query) {
    return item.title.toLowerCase().includes(query.toLowerCase())
  }

  itemHTML (item) {
    return item.title
  }

  get placeholderMessage () {
    return 'Search for property'
  }
}

CapitalPropertyPicker.register()
