import { CapitalAction } from './CapitalAction'
// import { AssetAction, AssetTransferLine, AssetLedgerLine } from './AssetAction'
import { AssetTransferLine, AssetCertificate } from './interfaces'

type CapitalState = import('./CapitalState')

/**
 * ## At present:
 * A `CertificateAction` represents an action __on__ a certificate,
 * but not the content __of__ the certificate.  Certificate actions
 * can be issuance and surrender (and endorsement, being surrender
 * + issuance).
 *
 * The content of a certificate is two-fold, being the **face**:
 * - `assetClassID`
 * - certificate `number`
 * - `person` who holds the certificate
 * - `amount` (of shares)
 * - `dated`
 * - `signatories` (usually one or two, being president +/ secretary, etc)
 * - `terms` (e.g. par-value, consideration, etc)
 *
 * The other content is usually literally the **back**, an endorsement:
 * - `recipient`
 * - `dated`
 * - `signatories`
 * - `terms` (may be included, may be by-reference to an external agreement)
 *
 * ## In future:
 *
 * We must decide where share certificate content is to be stored, either as
 * a transaction (such as the current, or other transactions), or in a separate
 * component, or otherwise.
 */
abstract class CertificateAction extends CapitalAction {
  constructor (public assetClassID: string, public number: number, public person: string) {
    super()
  }

  abstract certNumber (state: CapitalState) : number

  involves (assetClassID) { return this.assetClassID === assetClassID }
  affects (holder) { return this.person === holder.name }

  asCert (state: CapitalState, offset = 0) : AssetCertificate {
    const { assetClassID } = this
    return {
      assetClassID,
      person: this.person,
      number: this.certNumber(state) + offset,
    }
  }

  bumpAutoNumber (state: CapitalState) {
    state.autoNumber.bump(`${this.assetClassID}.cert`, this.number)
  }
}

export class CertificateIssue extends CertificateAction {
  constructor (assetClassID: string, number: number, person: string, private autoNumberOffset: number = 0) {
    super(assetClassID, number, person)
  }

  certNumber (state: CapitalState) {
    return this.number || state.autoNumber.get(`${this.assetClassID}.cert`)
  }

  updateTransferLine (stl: AssetTransferLine, state: CapitalState) : void {
    if (state.getAsset(this.assetClassID).getProperty('certificated')) {
      stl.certificatesIssued.push(this.asCert(state, this.number ? 0 : this.autoNumberOffset))
    }
  }

  applyTo (state: CapitalState) : void {
    if (state.getAsset(this.assetClassID).getProperty('certificated')) {
      state.getStakeholder(this.person).addCertificate(this.asCert(state))
      this.bumpAutoNumber(state)
    }
  }
}

/**
 * A Certificate is surrendered when it is returned to the Company or given to
 * someone else.   It appears on the share transfer ledger and the shareholder
 * ledger.
 */
export class CertificateSurrender extends CertificateAction {
  certNumber () { return this.number }

  updateTransferLine (stl: AssetTransferLine, state: CapitalState) : void {
    if (state.getAsset(this.assetClassID).getProperty('certificated')) {
      stl.certificatesSurrendered.push(this.asCert(state))
    }
  }

  applyTo (state: CapitalState) : void {
    if (state.getAsset(this.assetClassID).getProperty('certificated')) {
      state.getStakeholder(this.person)
        .removeCertificate(this.assetClassID, this.number)
    }
  }
}
