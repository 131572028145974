
import ConfirmModelDelete from './confirm-model-delete'

class ConfirmUserDelete extends ConfirmModelDelete {
  get messageHTML () {
    const name = this.model.cvModelTitle
    return (
      <div>
        <p>
          Please confirm if you would like to delete user <b>{name}</b>.
        </p>
        <p>This action cannot be undone.</p>
      </div>
    )
  }
}

ConfirmUserDelete.register()
