

import {
  caretStartsOnFirstLine, caretEndsOnLastLine, splitEditableAtSelection,
  selectionContainerElement, caretAtStart, elementPrecedingCaret,
} from 'utils/editable'

import { BlockRegister } from '../blocks'

type WritingDocumentEditor = import('./writing-document-editor').default
type BodyBlock = import('../blocks/BodyBlock').default
type Block = import('../blocks/Block').default

// Per MouseEvent.button
const LEFT_CLICK = 1
const RIGHT_CLICK = 2
const URI_JSON_WRITING_COPY = 'box://writing/copy'


export class WritingDocumentEvents {
  constructor (private editor: WritingDocumentEditor) {}

  get document () { return this.editor.document }
  get blocks () { return this.document.blocks}

  /**
   *
   *
   *        MOUSE
   *
   *
   */


  onMouseUp (evt: MouseEvent) {
    if (!this.editor.isSelecting) {
      this.editor.selectionStartIndex = null
    }
    setTimeout(() => {
      const sel = window.getSelection()
      if (!sel.rangeCount || sel.getRangeAt(0).collapsed) {
        this.editor.formattingBarEvent(null)
      } else {
        this.editor.onFormattingBarTriggeringEvent()
      }
    }, 5)
    return true
  }

  private onMouseDown (evt: MouseEvent) {
    const target = evt.target as HTMLElement
    if (target.closest('[grip]')) {
      ko.dataFor(target).block.isSelected(true)
      return false
    }
    if (evt.button !== RIGHT_CLICK) { this.editor.clearSelection() }
    this.editor.selectionStartIndex = this.editor.indexOfMouseOver(evt.target)
    return true
  }

  /**
   * For multi-block selection see:
   * - https://stackoverflow.com/questions/4777860
   * - https://github.com/NetPleadings/Nassau/blob/8efbbd7d2ef2d80b590fc385d89897aaa6a57839/packages/ko.components/src/writing/HandlerBase.js

   */
  private onMouseOver (evt: MouseEvent) {
    if (evt.buttons !== LEFT_CLICK) { return true }
    if (typeof this.editor.selectionStartIndex !== 'number') { return true }
    const target = evt.target as Element
    if (target.closest('[grip-area-active]')) { return true }
    const ce = target.closest('[contenteditable=true]')
    if (ce) {
      const overIndex = ko.dataFor(ce).block.orderIndex()
      this.editor.setSelectedBlocks(overIndex, this.editor.selectionStartIndex)
    }
    return true
  }

  /**
   *
   *
   *        KEYBOARD
   *
   *
   */

  onEnter (evt: KeyboardEvent) {
    evt.preventDefault()
    const block = this.editor.currentBlock()
    const { blocks } = this.document
    if (block.isBlank) {
      if (block.collapseToParagraphOnEnterWhenEmpty) {
        const blockIndex = blocks.indexOf(block)
        const lastParaBlock = blocks.slice(0, blockIndex)
          .reverse()
          .find(p => p.isParagraph)
        const paraCode = lastParaBlock ? lastParaBlock.code : 'p'
        const next = BlockRegister.factory(paraCode, {})
        blocks.replace(blockIndex, next)
        next.setFocus()
      } else {
        const next = block.makeNextBlock('')
        const nextIndex = blocks.indexOf(block)
        blocks.insertAfter(nextIndex, next)
        next.setFocus()
      }
    } else {
      const contents = splitEditableAtSelection()
      const nextText = contents.children[0].innerHTML
      const next = block.makeNextBlock(nextText)
      const nextIndex = blocks.indexOf(block)
      blocks.insertAfter(nextIndex, next)
      next.setFocus()
    }
  }

  onKeyUp (evt: KeyboardEvent) {
    if (evt.shiftKey) {
      this.editor.onFormattingBarTriggeringEvent()
    } else {
      if (evt.key !== 'Shift' && this.editor.formattingBarEvent()) {
        this.editor.formattingBarEvent(null)
      }
    }

    switch (evt.key) {
      case '/': this.editor.onForwardSlash(evt); break
      // case 'ArrowRight': break
      // case 'ArrowLeft': this.selectAdjacentPrevVariable(); break
    }
  }

  onArrowUp (evt) {
    if (!caretStartsOnFirstLine()) { return }
    const ps = this.editor.prevBlock(b => b.isFocusable)
    if (!ps) { return }
    ps.setFocus(true)
  }

  onArrowDown (evt: KeyboardEvent) {
    if (!caretEndsOnLastLine()) { return }

    const ns = this.editor.nextBlock(b => b.isFocusable)
    if (!ns) {
      const nuf = this.editor.nextBlock()
      if (nuf) { // next block is not focusable (e.g. Signature)
        const next = nuf.makeNextBlock('')
        const nextIndex = this.blocks.indexOf(nuf) + 1
        this.blocks.insertAfter(nextIndex, next)
        next.setFocus()
      }
      return
    }
    ns.setFocus()
  }

  onBackspace (evt: KeyboardEvent) {
    const node = selectionContainerElement()
    if (caretAtStart(node)) {
      const ps = this.editor.prevBlock() as BodyBlock | Block
      evt.preventDefault()
      evt.stopPropagation()
      if (!ps) { return true }
      this.onBackspaceIntoPrevBody(node, ps)
      return false
    }

    if (this.selectAdjacentPrevVariable()) { return false }
    return true
  }

  /**
   * True when the caret is right after a `<span>` for a variable.
   */
  selectAdjacentPrevVariable () {
    const n = elementPrecedingCaret() as Element
    if (n && n.nodeType === document.ELEMENT_NODE
      && n.hasAttribute('data-bind')) {
      window.getSelection().getRangeAt(0).selectNode(n)
      return true
    }
    return false
  }

  async onBackspaceIntoPrevBody (container: Element, ps: Block | BodyBlock) {
    if ('body' in ps) {
      const toRemove = this.editor.currentBlock()
      ps.setFocus(true)
      await ps.hasFocused()
      const r = new Range()
      r.selectNodeContents(container)
      const c = selectionContainerElement()
      c.appendChild(r.extractContents())
      this.blocks.remove(toRemove)
      c.dispatchEvent(new CustomEvent('needssave'))
    } else {
      this.blocks.remove(ps)
    }
  }

  onKeyDown (evt: KeyboardEvent) {
    this.editor.variableFloatingMenu(null)
    if (this.editor.commandMenuSlashEvent()) {
      this.editor.commandKeyDown(evt)
    } else if (this.editor.isSelecting) {
      return this.onKeyDownWhileSelecting(evt)
    } else {
      switch (evt.key) {
        case 'a':
          if (evt.metaKey || evt.ctrlKey) {
            this.editor.selectionStartIndex = 0
            this.editor.setSelectedBlocks(0, Number.POSITIVE_INFINITY)
            return false
          }
          break

        case 'u':
          if (evt.metaKey || evt.ctrlKey) {
            evt.preventDefault()
            document.execCommand('underline')
          }
          break

        case 'z':
          if (evt.metaKey || evt.ctrlKey) {
            evt.preventDefault()
            evt.stopPropagation()
            const pn = this.document.saveCaretPosition()

            this.editor.commandSet
              .commands[evt.shiftKey ? 'redoCommand' : 'undoCommand']
              .trigger(evt)
              .then(() => this.document.restoreCaretPosition(pn))
          }
          break

        case 'Backspace': return this.onBackspace(evt)

        case 'Enter':
          this.onEnter(evt)
          break
        case 'ArrowDown':
          this.onArrowDown(evt)
          evt.preventDefault()
          break
        case 'ArrowUp':
          this.onArrowUp(evt)
          evt.preventDefault()
          break
      }
    }
    return true // perform default
  }

  onKeyDownWhileSelecting (evt: KeyboardEvent) {
    switch (evt.key) {
      case 'Backspace':
      case 'Delete': {
        const selected = this.blocks.filter(b => b.isSelected())
        const atIndex = Math.min(...selected.map(s => s.orderIndex()))
        this.blocks.remove(...selected)
        if (this.blocks.length === 0) {
          this.blocks.push(BlockRegister.factory('p'))
        }
        this.blocks.at(Math.max(atIndex, 0)).setFocus()
        break
      }

      default: {
        const modified = evt.ctrlKey || evt.metaKey || evt.shiftKey
        if (!modified) { this.editor.clearSelection() }
      }
    }
    return true // perform default
  }


  /**
   *
   *
   *        COPY & PASTE
   *
   *
   */

  /**
  * `onCopy` cannot be async b/c `return true` must be synchronous for the
  * default event handler to be used.
  */
  private onCopy (evt: ClipboardEvent) {
    const { blocks } = this.document
    const selected = blocks.filter(b => b.isSelected())
    if (!selected.length) { return true }
    evt.preventDefault()
    evt.stopPropagation()
    return this.copySelectBlockToClipboard(selected, evt.clipboardData)
  }

  private async copySelectBlockToClipboard (selected: Block[], clipboardData: DataTransfer) {
    const { blocks, slotManager } = this.document
    const data = {
      type: URI_JSON_WRITING_COPY,
      content: { blocks: selected.map(b => b.encoded) },
    }

    const text = selected.map(b => b.asText || '').join('\n\n')
    const html = (await Promise.all(
      (selected as BodyBlock[])
        .filter(b => b.bodyWithInterpolation)
        .map(b => b.bodyWithInterpolation(slotManager))))
      .map<string>((div: HTMLDivElement) => `<p>${div.innerHTML}</p>`)
      .join('')
    const markdown = (await Promise.all(
      selected.map(b => b.asMarkdown([...blocks], slotManager))
    )).join('\b\b')

    clipboardData.setData('application/json', JSON.stringify(data))
    clipboardData.setData('text/plain', text)
    clipboardData.setData('text/html', html)
    clipboardData.setData('text/markdown', markdown)
    return false
  }

  private async onCut (evt: ClipboardEvent) {
    if (await this.onCopy(evt)) { return true }
    const { blocks } = this.document
    blocks.remove(...blocks.filter(b => b.isSelected()))
    return false // prevent default
  }

  private onPaste (evt: ClipboardEvent) {
    const { clipboardData } = evt
    const json = clipboardData.getData('application/json')

    if (json) {
      evt.preventDefault()
      const body = JSON.parse(json)
      if (body.type === URI_JSON_WRITING_COPY) {
        this.document.insert(body.content.blocks)
      }
      return false // prevent default
    }

    const text = clipboardData.getData('text/plain')
    if (text) {
      const parts = text.split(/\n/)
      if (parts.length > 1) {
        evt.preventDefault()
        this.document.insert(parts.map(p => ['p', {body: p}]))
        return false // prevent default
      }
    }

    return true // use default paste handler
  }

  /**
   *          HANDLERS
   *
   */
  get handlers () {
    return {
      copy: (_, evt) => this.onCopy(evt),
      cut: (_, evt) => this.onCut(evt),
      keydown: (_, evt) => this.onKeyDown(evt),
      keyup: (_, evt) => this.onKeyUp(evt),
      mousedown: (_, evt) => this.onMouseDown(evt),
      mouseover: (_, evt) => this.onMouseOver(evt),
      mouseup: (_, evt) => this.onMouseUp(evt),
      paste: (_, evt) => this.onPaste(evt),
    }
  }
}
