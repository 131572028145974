import icons from 'icons'
import checkIcon from 'icons/light/check'

import EisPrimitiveComponent from './EisPrimitiveComponent'

export default class EisBool extends EisPrimitiveComponent {
  static get css () {
    return {
      ...super.css,
      ...this.boolCSS,
      label: {
        display: 'flex',
        alignItems: 'center',
      },
      title: {
        padding: '0px 10px'
      },
      input: {}
    }
  }

  static get boolCSS () {
    return {
      _boolButton: {
        fontSize: '1rem',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        outline: 'none',
        border: 0,
        '&:active': {
          outline: 'none',
          boxShadow: 'inset 0 1px 2px 1px rgba(0,0,0,0.4)'
        }
      },
      boolButtonYes: {
        extend: '_boolButton',
        '--icon-color': 'green'
      },
      boolButtonNo: {
        extend: '_boolButton',
        '& svg': { opacity: 0 }
      }
    }
  }

  get displayHTML () {
    const { jss, component } = this
    const input = component._observable

    const cssClass = this.computed(
      () => input() ? jss.boolButtonYes : jss.boolButtonNo)

    return (
      <label class={jss.label}>
                <button type='text' class={cssClass}
          ko-click={() => input.modify(v => !v)}>
          {icons.inline(checkIcon)}
        </button>
        <div class={jss.title}>{component.title}</div>
      </label>
    )
  }

  onClick () { return }
  get changeAreaHTML () : any[] { return }
  get changeButtonHTML () : any[] { return }
}

EisBool.register()
