
import ViewComponent from 'ViewComponent'

import { AssetCertificate, AssetLedgerLineDescription } from '../interfaces'

export default class CertificatesList extends ViewComponent {
  issued: AssetCertificate[]
  surrendered: AssetCertificate[]
  asset: AssetLedgerLineDescription

  constructor ({asset, issued, surrendered}) {
    super()
    Object.assign(this, {asset, issued, surrendered})
  }

  static get css () {
    return {
      certificatesList: {
        fontSize: '0.75rem',
        display: 'grid'
      },
      _cert: {
        display: 'inline',
        padding: '0px 2px',
        whiteSpace: 'nowrap',
      },
      certIssued: {
        extend: '_cert',
        color: 'green',
        fontWeight: '600'
      },
      certSurrendered: {
        extend: '_cert',
        color: 'red',
      }
    }
  }

  get template () {
    const { jss } = this
    const { prefix } = this.asset
    const iSeen = new Set()
    const sSeen = new Set()
    const issued = this.issued
      .filter(i => i.number)
      .filter(i => iSeen.has(i.number) ? false : iSeen.add(i.number))
    const surrendered = this.surrendered
      .filter(i => i.number)
      .filter(i => sSeen.has(i.number) ? false : sSeen.add(i.number))
    return (
      <div class={jss.certificatesList}>
        {issued.map(c =>
          <span class={jss.certIssued}>
            {prefix}–{c.number}
          </span>)}
        {surrendered.map(c =>
          <span class={jss.certSurrendered}>
            [{prefix}–{c.number}]
          </span>)}
      </div>
    )
  }
}

CertificatesList.register()
