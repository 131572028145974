import { fromUnixTime } from 'date-fns/esm'

import StaticMediator from './StaticMediator'

const { FieldValue } = window.firebase.firestore

/**
 * A Static mediator is one that's saved to the database but doesn't appear
 * in `vmCurrentRepr`.
 */
export default class LastModifiedMediator extends StaticMediator<firebase.firestore.FieldValue> {
  constructor (name, model) { super(name, model, FieldValue.serverTimestamp()) }
  fromJS (v) { this.model[this.name](v && fromUnixTime(v.seconds)) }
  newHolderInstance () { return ko.observable() }
}
