import Big from 'big.js'
import { toLocaleDecimal } from 'utils/number'

import {
  CapitalAction
} from './CapitalAction'

import CapitalState from './CapitalState'
import { Transaction } from './Transaction'

import {
  AssetLedgerLine, AssetTransferLine, LedgerAction
} from './interfaces'

/**
 * The `AssetAction` is the base class of actions
 * that act upon an asset identifed by its
 * `assetClassID`.
 */
export abstract class AssetAction extends CapitalAction {
  constructor (public assetClassID: string) {
    super()
  }

  involves (assetClassID: string) : boolean {
    return this.assetClassID === assetClassID
  }

  _updateTransferLineWith (person: string, amount: string, line: AssetTransferLine, state: CapitalState, assetClassID = this.assetClassID) {
    const asset = state.getAsset(assetClassID)
    line.parts.push({
      person, assetClassID, amount: toLocaleDecimal(amount),
      asset: asset.assetNameParts,
    })
  }
}

/**
 * The `AssetAuthorize` instruction authorizes
 * an asset with the given `assetClassID`.
 */
export class AssetAuthorize extends AssetAction {
  get description () {
    return `Authorize ${this.assetClassID}`
  }

  /**
   * This is a no-op but it triggers the `state.assetTransferLine` in
   * `Transaction`, so asset authorization shows up on the ledger.
   */
  updateTransferLine (line: AssetTransferLine, state: CapitalState) {}

  applyTo (capital: CapitalState, transaction: Transaction) : void {
    capital.authorizeAsset(this.assetClassID, transaction)
  }
}

/**
 * The `AssetSetProperty` assigns a key, value pair
 * to the `assetClassID`.
 */
export class AssetSetProperty extends AssetAction {
  constructor (assetClassID: string, public key: string, public value: any) {
    super(assetClassID)
  }

  get description () {
    return `${this.assetClassID} Set "${this.key}" = "${this.value}"`
  }

  applyTo (capital: CapitalState) : void {
    capital.setAssetProperty(this.assetClassID, this.key, this.value)
  }
}

/**
 * The `AssetDeauthorize` instruction removes an asset
 * with the given `assetClassID`.
 */
export class AssetDeauthorize extends AssetAction {
  applyTo (capital: CapitalState): void {
    capital.deauthorizeAsset(this.assetClassID)
  }
  affects (holder: string, state: CapitalState) {
    return state.getStakeholder(holder).hasAsset(this.assetClassID)
  }

  get description () {
    return `Deauthorize ${this.assetClassID}`
  }

  updateTransferLine (line: AssetTransferLine, state: CapitalState) {
    const holders = Object.values(state.stakeholders)
      .filter(h => h.hasAsset(this.assetClassID))
    for (const h of holders) {
      const amt = `(${h.getAsset(this.assetClassID)})`
      this._updateTransferLineWith(h.name, amt, line, state)
    }
  }

  updateLedgerLine (line: AssetLedgerLine, state: CapitalState) : void {
    if (this.assetClassID !== line.assetClassID) { return }
    const h = state.getStakeholder(line.holder)
    // if (!h.hasAsset(line.assetClassID)) { return }
    const holds = h.getAsset(line.assetClassID)
    line.delta = holds.times(-1)
    line.balance = new Big(0)
  }
}


/**
 * `AssetTransfer` represents a crediting or debit of a `name` of the given
 * asset.
 *
 * Assets may be:
 * - Shares
 * - Certificates
 * - Warrants
 * - Options
 */
export abstract class AssetTransfer extends AssetAction implements LedgerAction {
  constructor (assetClassID: string, public person: string, public amount: string) {
    super(assetClassID)
    this.amount = this.amount || '0'
  }

  get description () {
    return `Transfer ${this.assetClassID} ${this.operation} ${this.amount} to ${this.person}`
  }

  abstract get operation (): 'credit' | 'debit'
  abstract updateTransferLine (stl: AssetTransferLine, state: CapitalState) : void
  abstract get lineAction () : string

  applyTo (capital: CapitalState, tr: Transaction) {
    const { person, amount, assetClassID } = this
    const holder = capital.getStakeholder(person)
    holder[this.operation](assetClassID, amount, tr.datetime())
  }

  affects (holder: string) { return holder === this.person }
  involves (assetClassID: string) { return this.assetClassID === assetClassID }

  updateLedgerLine (line: AssetLedgerLine) : void {
    if (this.assetClassID !== line.assetClassID) { return }
    if (this.person === line.holder) {
      line.delta = line.delta[this.lineAction](this.amount)
      line.balance = line.balance[this.lineAction](this.amount)
    } else {
      line.counterParties.add(this.person)
    }
  }
}

export class AssetCredit extends AssetTransfer {
  get operation (): 'credit' { return 'credit' }
  get lineAction (): string { return 'add' }
  updateTransferLine (line: AssetTransferLine, state: CapitalState) : void {
    const amount = toLocaleDecimal(this.amount)
    this._updateTransferLineWith(this.person, amount, line, state)
  }
}

export class AssetDebit extends AssetTransfer {
  get operation (): 'debit' { return 'debit' }
  get lineAction () : string { return 'minus' }
  updateTransferLine (line: AssetTransferLine, state: CapitalState) : void {
    const amount = `(${toLocaleDecimal(this.amount)})`
    this._updateTransferLineWith(this.person, amount, line, state)
  }
}

/**
 * `AssetAssign` gives every shareholder a 0 value.
 */
export class AssetZero extends AssetAction implements LedgerAction {
  applyTo (capital: CapitalState, tr: Transaction) {
    const { assetClassID } = this
    for (const holder of Object.values(capital.stakeholders)) {
      if (!holder.hasAsset(assetClassID)) { continue }
      holder.setAssetValue(assetClassID, 0, tr.datetime())
    }
  }

  get description () {
    return `Zero all ${this.assetClassID} assets.`
  }

  updateLedgerLine (line: AssetLedgerLine) : void {
    if (line.assetClassID === this.assetClassID) {
      const balanceBefore = line.balance
      line.balance = new Big(0)
      line.delta = balanceBefore.times(-1)
    }
  }

  updateTransferLine (stl: AssetTransferLine) : void {
    // stl.parts.push({
    //   person: '',
    //   amount: '0',
    //   assetClassID: this.assetClassID
    // })
  }

  affects (holder: string, state: CapitalState) {
    return !state.getStakeholder(holder).getAsset(this.assetClassID).eq(0)
  }
}


/**
 * `AssetFraction` is an abstract base class for operations that
 * involve mass multiplying shares by fraction either to themselves
 * (a split) or to another asset id (a conversion).
 */
abstract class AssetFraction extends AssetAction implements LedgerAction {
  abstract get toAssetClassID () : string
  get fromAssetClassID () { return this.assetClassID }

  constructor (assetClassID: string, public numerator: string, public denominator: string) {
    super(assetClassID)
  }

  applyTo (capital: CapitalState, tr: Transaction): void {
    for (const stakeholder of Object.values(capital.stakeholders)) {
      const isSplit = this.toAssetClassID === this.fromAssetClassID
      const startBalance = isSplit ? new Big(0)
        : stakeholder.getAsset(this.toAssetClassID)
      const newValue = startBalance.add(stakeholder.getAsset(this.fromAssetClassID)
        .times(this.numerator)
        .div(this.denominator))
      stakeholder.setAssetValue(this.toAssetClassID, newValue, tr.datetime())
    }
  }

  affects (holder: string, state: CapitalState) {
    return !state.getStakeholder(holder).getAsset(this.assetClassID).eq(0)
  }

  abstract updateLedgerLine (line: AssetLedgerLine, state: CapitalState) : void
  abstract updateTransferLine (line: AssetTransferLine, state: CapitalState) : void

}

/**
 * `AssetConvert` transforms one asset to another, according to a given
 * ratio, namely `assetClassID` => `targetAssetClassID`.
 */
export class AssetConvert extends AssetFraction {
  constructor (assetClassID: string, numerator: string, denominator: string, public targetAssetClassID: string) {
    super(assetClassID, numerator, denominator)
  }

  get toAssetClassID () { return this.targetAssetClassID }

  get description () {
    return `Convert ${this.assetClassID} ${this.numerator}:${this.denominator} ${this.targetAssetClassID}`
  }

  involves (assetClassID: string) : boolean {
    return super.involves(assetClassID) ||
      this.targetAssetClassID === assetClassID
  }

  updateLedgerLine (line: AssetLedgerLine, state: CapitalState) : void {
    if (line.assetClassID === this.targetAssetClassID) {
      const sourceBalance = state.getStakeholder(line.holder).getAsset(this.assetClassID)
      line.delta = sourceBalance
        .times(this.numerator)
        .div(this.denominator)
      line.balance = line.balance.add(line.delta)
    }
  }

  updateTransferLine (line: AssetTransferLine, state: CapitalState) : void {
    const { numerator, denominator } = this

    const denomAmount = 'from ' + denominator.toString()
    const numerAmount = 'to ' + numerator.toString()
    this._updateTransferLineWith('', denomAmount, line, state)
    this._updateTransferLineWith('', numerAmount, line, state,
      this.targetAssetClassID)
  }
}

/**
 * An `AssetSplit` multiplies an asset amount by (numerator/denominator)
 */
export class AssetSplit extends AssetFraction {
  get toAssetClassID () { return this.assetClassID }

  updateLedgerLine (line : AssetLedgerLine) : void {
    const balanceBefore = line.balance
    line.balance = line.balance
      .times(this.numerator)
      .div(this.denominator)
    line.delta = line.balance
      .minus(balanceBefore)
  }

  updateTransferLine (line: AssetTransferLine, state: CapitalState) : void {
    const { numerator, denominator } = this
    const amount = `${numerator} for ${denominator}`
    this._updateTransferLineWith('', amount, line, state)
  }

  get description () {
    return `Split ${this.assetClassID} ${this.denominator}:${this.numerator}`
  }
}


/**
 * Transfer numbers are either:
 *  1. the number itself (i.e. positive integers 1...N)
 *  2. null, meaning use auto-transfer number
 *  3. -1, meaning an unnumbered transfer
 */
export class AssetTransferNumber extends AssetAction {
  constructor (assetClassID: string, public number: number) {
    super(assetClassID)
  }

  get description () {
    return `Share transfer number ${this.assetClassID} ${this.number}`
  }

  applyTo (cs: CapitalState) : void {
    cs.autoNumber.bump('transferNumber', this.number)
  }
  affects () : boolean { return false }
  involves (assetClassID: string) : boolean {
    return this.assetClassID === assetClassID
  }

  updateTransferLine (stl: AssetTransferLine, cs: CapitalState) : void {
    if (this.number === -1) { return }
    stl.transferNumber = this.number || cs.autoNumber.get('transferNumber')
  }

  updateLedgerLine (sll: AssetLedgerLine, cs: CapitalState) : void {
    if (this.number === -1) { return }
    sll.transferNumber = this.number || cs.autoNumber.get('transferNumber')
  }
}
