
import Sorter from './Sorter'
import Condition from 'FilterModel/Condition'

export default class ModelListOptions {
  /**
   * @param {Array.<Column>} possibleColumns
   */
  constructor (possibleColumns, panelID) {
    const settingsName = panelID + '.columns'
    Object.assign(this, {
      panelID,
      possibleColumns,
      settingsName,
      conditions: ko.observableArray([]),
      sorters: ko.observableArray([]),
      shownColumns: ko.observableArray(null),
      defaultColumns: ko.observableArray(null).extend({ persistentSetting: settingsName }),
      filterColumns: ko.observable(null),
      columns: ko.observableArray(possibleColumns).extend({ rateLimit: 25 })
    })

    window.app.panelProvider().subscribe(this.filterColumns, filterColumnObs => {
      if (this._filterWatch) { this._filterWatch.dispose() }
      const currentColumns = ko.unwrap(filterColumnObs) || []
      if (!currentColumns.length) { filterColumnObs = this.defaultColumns }
      this.setShownColumns(filterColumnObs())
      this._filterWatch = this.shownColumns.subscribe(filterColumnObs)
    })
    window.app.panelProvider().subscribe(this.conditions,
      conds => (conds && conds.length) || this.filterColumns(null))

    if (!this.defaultColumns()) { this.defaultColumns(this.shownColumnTitles()) }
    this.filterColumns(this.defaultColumns)

    const firstFilterableCol = this.columns().find(c => c.filterable)
    if (firstFilterableCol) {
      this.sorters([new Sorter(firstFilterableCol)])
    }
  }

  setShownColumns (columnTitles) {
    if (this._watchColumnTitles) { this._watchColumnTitles.dispose() }
    this.shownColumns(columnTitles.filter(v => v))
    this.columns().forEach(c => c.show(this.shownColumns().includes(c.title)))
    const columnOrder = this.shownColumns().map(c => c.replace(/[()]/g,''))
    this.columns.sort((a, b) => {
      const ai = columnOrder.indexOf(a.title)
      const bi = columnOrder.indexOf(b.title)
      if (ai !== -1 && bi !== -1) { return ai - bi }
      if (a.givenColumnID === 'ui' || b.givenColumnID === 'selected' ) { return 1 }
      if (b.givenColumnID === 'ui' || a.givenColumnID === 'selected' ) { return -1 }
      if (ai !== -1 || bi !== -1) { return bi - ai }
      return this.possibleColumns.indexOf(a) - this.possibleColumns.indexOf(b)
    })
    this._watchColumnTitles = ko.pureComputed(() => this.shownColumnTitles()).subscribe(this.shownColumns)
  }

  shownColumnTitles () {
    return this.columns()
      .filter(c => c.title)
      .map(c => c.show() ? c.title : `(${c.title})`)
  }

  queryFilter () {
    return model => Condition.test(model, this.conditions())
  }

  filterAutoSave (filterModel) {
    if (this._filterAutoSave) { this._filterAutoSave.dispose() }
    if (!filterModel) { return }
    this._filterAutoSave =
      ko.pureComputed(() => filterModel.columns())
        .extend({deferred:true})
        .subscribe(() => filterModel.vmSave())
  }

  /**
   * @param {Array.<fbFilter>} filterModel created with Filter::toJS()
   */
  restoreSavedFilters (filterModel) {
    this.conditions(filterModel
      ? filterModel.conditions().map(c => c.clone())
      : [])
    if (filterModel) {
      if (filterModel.cvModelTitle.endsWith('.*"')) {
        this.filterColumns(this.defaultColumns)
        this.filterAutoSave(null)
      } else {
        this.filterColumns(filterModel.columns)
        this.filterAutoSave(filterModel)
      }
    }
  }

  clearFilters () {
    this.conditions([])
  }
}
