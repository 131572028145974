import ArrayComponent from 'DataModel/components/ArrayComponent'
import UnitView from 'DataModel/components/UnitView'

export class Patent extends UnitView {
  get elvValue () { return this.number }

  get fields () {
    return {
      description: 'string',
      number: 'string',
      status: 'string',
      // Status: None; Preliminary Search; Application filed; TMO Examination; Publication of Application; Receipt of Opposition; Registered
      agent: 'person',
      expiry: 'pureDate',
      jurisdiction: 'string'
    }
  }

  * getRoles () : IterableIterator<PersonRoleRecord> {
    yield { title: 'Patent Agent' }
  }
}

export default class PatentsComponent extends ArrayComponent {
  get ItemClass () { return Patent }
}

PatentsComponent.register()
