import { possibleEntityVariables } from './entityVariables'
import { staticVariables } from './staticVariables'
export { factory as variableFactory } from './registry'

export { Variable } from './Variable'
export { ModelVariable, ComponentVariable } from './ModelVariable'
export {
  ArrayComponentVariable, ArrayComponentPerson, ArrayComponentPureDate,
} from './ArrayComponentVariable'
export { CapitalHolderVariable, CapitalAssetVariable } from './CapitalVariable'


type Command = import('../commands').Command

export function possibleVariables (): Command[] {
  return [
    ...possibleEntityVariables(),
    ...staticVariables(),
  ]
}
