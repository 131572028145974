
import PersonComponent from './PersonComponent'

export default class ClientComponent extends PersonComponent {
  skip (given) {
    // 🐫 2.0 => 2.1 clientname => client.person.name
    this.set({ name: [given.clientname] })
  }
}

ClientComponent.register()
