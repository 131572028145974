
import ViewComponent from 'ViewComponent'
import { buttons, color } from 'styles'

interface Option<T> {
  value: T
  text: string
}

/**
 * Toggle choices e.g. <On | Off | Auto>
 *
 *  <toggle-button
 *     options={Options[]}
 *     selected={ko.observable} />
 */
export default class ToggleButton<T> extends ViewComponent {
  options: Option<T>[]
  selected: KnockoutObservable<T>

  constructor ({options, selected}) {
    super()
    Object.assign(this, { options, selected })
  }

  static get css () {
    return {
      ...super.css,
      options: {
        display: 'flex',
        background: color.fill.light.primary,
        borderRadius: 15,
        padding: '4px 5px',
        'body[dark] &': { // project batman
          backgroundColor: color.fill.dark.primary,
        },
      },
      choice: {
        ...buttons.clickable,
        color: color.text.light.primary,
        padding: '4px 6px',

        '&[selected=true]': {
          backgroundColor: color.fill.light.secondary,
          borderRadius: 10,
          fontWeight: '600',
          'body[dark] &': { // project batman
            backgroundColor: color.fill.dark.secondary,
          },
        },

        'body[dark] &': { // project batman
          color: color.text.dark.primary,
        },
      },
    }
  }

  optionHTML (o: Option<T>) {
    return (
      <div class={this.jss.choice}
        selected={this.computed(() => this.selected() === o.value)}
        ko-ownClick={() => this.selected(o.value)}>
        {o.text}
      </div>
    )
  }

  get template () {
    const { jss } = this
    return (
      <div class={jss.options}>
        {this.options.map(o => this.optionHTML(o))}
      </div>
    )
  }
}

ToggleButton.register()
