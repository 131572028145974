
/**
 * @param {string} url to get
 * @param {string} readAs one of:
 *  - ObjectURL     via URL.createObjectURL
 *  - DataURL       from FileReader::readAsDataURL
 *  - Text          from FileReader::readAsText
 *  - BinaryString  from FileReader::readAsBinaryString
 *  - ArrayBuffer   from FileReader::readAsArrayBuffer
 * @return {Promise.<url|string|binary|ArrayBuffer>}
 *
 * ! Memory Usage:
 *    1. Every time this is called, a new Blob may be allocated in memory.
 *    2. For `readAs` = `ObjectURL` in particular, the browser should call
 *       `URL.revokeObjectURL` when the data is expired.
 */
export function getUrlFile (url, readAs) {
  const xhr = new XMLHttpRequest()
  xhr.responseType = 'blob'
  xhr.open('GET', url)
  xhr.send()
  return new Promise((resolve, reject) => {
    if (readAs === 'ObjectURL') {
      xhr.addEventListener('load', () => resolve(URL.createObjectURL(xhr.response)))
    } else {
      const reader = new FileReader()
      const asFn = `readAs${readAs}`
      reader.addEventListener('loadend', () => resolve(reader.result))
      reader.addEventListener('error', reject)
      reader.addEventListener('abort', reject)
      xhr.addEventListener('load', () => reader[asFn](xhr.response))
    }
    xhr.addEventListener('error', reject)
    xhr.addEventListener('abort', reject)
  })
}
