
import ObjectProxy from './ObjectProxy'

/**
 * Given a request for an object with firestore path
 *
 *    path = /accounts/a/collection/model
 *
 * If the projection-id is listed, return that value:
 *
 *    path = /accounts/a/collection/model/projection/name@domain.com-content
 *
 * If no projection id is given, return the object value.
 *
 * Usage (proxy):
 *
 *    const proxy = new FirestoreProjectionProxy()
 *    const wrapped = proxy.wrap(object)
 *
 * Usage (unproxy):
 *
 *      unproxy(wrapped, {
 *        projection: {modelPath, projectionID, firestore}
 *      })
 *
 *   where e.g.
 *
 *         modelPath = `/accounts/account/object/modelid` and
 *         projectionID = `content-name@domain.com`
 *
 *
 */
export default class FirestoreProjectionProxy extends ObjectProxy {
  static get proxyPropertyName () { return 'FSP' }
  static async unwrapValue (object, options = {}) {
    const { modelPath, projectionID, firestore } = options.projection || {}
    if (!projectionID) { return object }
    const path = `${modelPath}/projection/${projectionID}`
    const snap = await firestore.doc(path).get()
    const content = (snap.data() || {}).content || {}
    return content.value
  }
}
