import { computed } from 'utils/decorator'

import WritingRightViewComponent from './WritingRightViewComponent'

type ArrayComponentVariable = import('../ArrayComponentVariable').ArrayComponentVariable
type UnitView = import('DataModel').UnitView

export default class WritingRightViewArrayComponent<T extends ArrayComponentVariable> extends WritingRightViewComponent<T> {
  arrayChoiceHTML (v: UnitView, index: number) {
    return (
      <option value={index}>{v.elvValue}</option>
    )
  }

  @computed()
  arrayComponentIndexPickerHTML () {
    const model = this.variable.model
    if (!model) { return null }

    const units = model.answerFor(this.variable.component())() as UnitView[]
    if (!units.length) { return null }

    const choices = units.map((v, i) => this.arrayChoiceHTML(v, i))

    this.monitorAndUpdatePeers(this.variable.index)
    const title = this.variable.componentProperties.title

    const select = (
      <select class={this.jss._select} ko-value={this.variable.index}>
        <option disabled value="">Choose {title}</option>
        {choices}
        <option value="*">All (A, B, ... and C)</option>
      </select>
    )

    return this.inputSection(title, select)
  }

  get body () {
    return (
      <>
        {super.body}
        {this.arrayComponentIndexPickerHTML}
        {this.inputSection('Slot', this.slotPickerHTML)}
      </>
    )
  }
}
