
import Editor from './Editor'

import { Cloneable } from './interfaces'

/**
 * A `CloneEditor` creates a clone of the `original` passed in.
 * 
 * The `onSave` parameter or overload needs to copy the `clone` into the 
 * `original` (or whatever else needs to be done with the updated
 * version).
 */
export default abstract class CloneEditor<C extends Cloneable> extends Editor {
  original: C
  clone: C

  constructor (params) {
    super(params)
    const { original } : { original: C } = params
    Object.assign(this, {
      original,
      clone: this.cloneOrCreate(original, params)
    })
  }

  abstract cloneOrCreate (original: C, params: any) : C

  abstract get questionHTML ()
}

