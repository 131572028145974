import DataModel from 'DataModel/DataModel'
import {
  PlainMediator
} from 'DataModel/mediators'

export default class AccountModel extends DataModel {
  firmname: KnockoutObservable<string>
  isActive: KnockoutObservable<boolean>

  constructor (values, authManager) {
    Object.assign(values, { id: '*', accountID: authManager.accountID() })
    super(values, authManager)
  }

  get cvModelTitle () { return 'Account Settings' }
  vmCreateMediatorInstances () {
    return [
      ...super.vmCreateMediatorInstances(),
      new PlainMediator('firmname', this),
      new PlainMediator('isActive', this),
    ]
  }

  get vmFirestoreDocPath () {
    return AccountModel.amGetAccountPath(this.accountID())
  }

  static amGetAccountPath (accountID) {
    return `accounts/${accountID}`
  }
}
