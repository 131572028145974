
import MediatorInterface from './MediatorInterface'

/**
 * A Static mediator is one that's saved to the database but doesn't appear
 * in `vmCurrentRepr`.
 */
export default class StaticMediator<T> extends MediatorInterface<T, T> {
  constructor (name, model, private staticValue: T) {
    super(name, model)
  }

  toJS () { return undefined }
  serialize () { return this.staticValue }
  fromJS (_) { return }
  newHolderInstance () { return this.staticValue }
}
