/**
 * Perform multiple proxies.
 *
 * The proxy wrapping is performed by a MultiProxy class whose parameters
 * are the proxies to apply.
 *
 * The unproxying is done by a MultiProxy static method; the ProxyClass
 * used to do the unwrapping is indicated in the `proxies` property.
 *
 * If MultiProxy is given a value without a `sentinel` parameter it
 * passes the object through.  This is useful for both legacy data
 * and testing.
 *
 */
import ObjectProxy from 'ObjectProxy'
import JsonProxy from 'ObjectProxy/JsonProxy'
import Uint8Proxy from 'ObjectProxy/Uint8Proxy'
import CompressProxy from 'ObjectProxy/CompressProxy'
import SuiteBCryptoProxy from 'ObjectProxy/SuiteBCryptoProxy'
import FirestoreBlobProxy from 'ObjectProxy/FirestoreBlobProxy'
import FirestoreProjectionProxy from 'ObjectProxy/FirestoreProjectionProxy'

/*
 * 🐉  Warning.
 * ⚠️  Once live data is saved with a proxy, then that proxy must persist
 * ⚠️  in the PROXY_LIST for decoding.
 * ⚠️  i.e. we cannot remove SuiteBCryptoProxy because we switch to an
 *     "E25519CryptoProxy"; even if SuiteB is not longer used for
 *     encrypting. SuiteB must remain in the PROXY_LIST for decrypting.
 */
const PROXY_LIST = [
  JsonProxy, Uint8Proxy, CompressProxy, SuiteBCryptoProxy, FirestoreBlobProxy, FirestoreProjectionProxy
]

const PROXIES = Object.assign({}, ...PROXY_LIST.map(p => ({
  [p.proxyPropertyName]: p,
  [p.name.replace('Proxy', '')]: p // e.g. Uint8 (used in some test code)
})))

/**
 * The MultiProxy can serialize a specified set of proxies and deserialize
 * any set of known proxies in the order they were serialized in.
 */
class MultiProxy extends ObjectProxy {
  static get proxyPropertyName () { return 'M' }

  /**
   * @param {Array.<ObjectProxy>} proxies
   */
  constructor (...proxies) {
    super()
    Object.assign(this, { proxies })
  }

  async wrapValue (value) {
    for (const proxy of this.proxies) {
      value = await proxy.wrapValue(value)
    }
    return {
      _sentinel_: 'multiproxy',
      proxies: this.proxies.map(p => p.proxyPropertyName),
      value
    }
  }

  static async unwrapValue (object, options) {
    const { proxies, value, _sentinel_ } = object
    if (_sentinel_ !== 'multiproxy') { return object }
    return [...proxies].reverse()
      .reduce(
        async (r, p) => this.getProxy(p).unwrap(await r, options),
        value)
  }

  static getProxy (id) {
    const ProxyClass = PROXIES[id]
    if (!ProxyClass) {
      console.debug(`Proxy Class ${id} not found; options:`, PROXIES)
      throw new Error(`No ProxyClass`)
    }
    return ProxyClass
  }
}

export default MultiProxy

export {
  MultiProxy, JsonProxy, Uint8Proxy, CompressProxy, SuiteBCryptoProxy, FirestoreBlobProxy, FirestoreProjectionProxy
}
