
const SENTRY = {
  production: 'https://3e702d6cc7a64d26add19be1e9798bdb@sentry.io/1388467',
  dev: 'https://51e0c7b1b37547bc9391fea6f373e5ae@sentry.io/1388462'
}


interface CryptoKey {
  crv: string
  ext: boolean
  key_ops: string[]
  kty: string
  x: string
  y: string
}

interface ProjectConfig {
  name: string
  projectId: string
  apiKey: string
  failsafePublicKey: CryptoKey
  storageBucket?: string
  dsn?: string
  hosts?: string[]
}

interface ProjectSettings {
  name: string
  failsafePublicKey: CryptoKey
  sentryDsn: string
  firebase: {
    apiKey: string
    projectId: string
    authDomain: string
    databaseURL: string
    storageBucket: string
  },
}

/**
 * Map from hostname to a project.
 */
export const DRIVERS: Record<string, ProjectSettings> = {}

/**
 * A ProjectManager keeps track of all the Google Cloud projects where
 * MinuteBox is set up.
 */
class ProjectManager {
  projects: Record<string, ProjectSettings> = {}

  recognize (id, params: ProjectConfig) {
    const {
      name, projectId, apiKey, failsafePublicKey,
      storageBucket, dsn, hosts,
    } = params

    const settings = {
      name,
      failsafePublicKey,
      sentryDsn: SENTRY[dsn || 'dev'],
      firebase: {
        apiKey,
        projectId,
        authDomain: `${projectId}.firebaseapp.com`,
        databaseURL: `https://${projectId}.firebaseio.com`,
        storageBucket: storageBucket || `${projectId}.appspot.com`
      },
    } as ProjectSettings

    this.projects[id] = DRIVERS[`${projectId}.web.app`] = settings
    for (const host of hosts || []) {
      if (DRIVERS[host]) { throw new Error(`Duplicate project host ${host}`) }
      DRIVERS[host] = settings
    }
  }

  get drivers () { return DRIVERS }
}

const manager = new ProjectManager()

/**
 * The following is the public key for our test servers, with private:
 *
 * d: 'GvEwmvWMt7q1Wq1U0Jowr8Ti1H1_YCwibavDMQVVYjJdvO6_33OOlWt8BUobp-Rc'
 *
 * This failsafe is presumed to be insecure, and only used for test
 * and demonstration purposes.
 */
const UNSAFE_FAILSAFE_KEY = {
  crv: 'P-384',
  ext: true,
  key_ops: [],
  kty: 'EC',
  x: 'jZqaf5QMv6VR1eOO7hKh1hY1_zwfY34F0prL-wNYx6k5LohicDyKDCUDx1etrvZE',
  y: '5YoBUBNKxe2GhUidXhyhRfztgK9M68aUl8suqf9gK7ZIxSR91FpEvsmPkzzqRnTc'
} as CryptoKey

manager.recognize('MASTER', {
  name: 'Master',
  projectId: 'minutebox-master-firebase',
  apiKey: 'AIzaSyDxcfF__znTdt4H6zI6mszCitb4SsLeMp8',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['localhost', 'minutebox.local', 'master.minutebox.xyz'],
})

manager.recognize('LABS', {
  name: 'MinuteBox Labs',
  projectId: 'minutebox-labs',
  apiKey: 'AIzaSyDIwN3kOkkDCSKLTN-_McRG-5KkjdDAQNU',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['labs.minutebox.xyz'],
})

manager.recognize('DEMO', {
  name: 'Demonstration Server',
  projectId: 'minutebox-demo-fb',
  apiKey: 'AIzaSyDdEm7pPouVnEWczcXvG_9M7Og4_ddCAHo',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['demo.minutebox.xyz'],
})

manager.recognize('DEMO2', {
  name: 'Demonstration Server 2',
  projectId: 'minutebox-demo2',
  apiKey: 'AIzaSyBaEnxANesdiXZiBRRZxBDt2DTA9nQWN7o',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['demo2.minutebox.xyz'],
})

manager.recognize('DEMO3', {
  name: 'Demonstration Server 3',
  projectId: 'minutebox-demo3',
  apiKey: 'AIzaSyCAznYXTEuYQqKfqvjTUWrGErwv1ZqZpBE',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['demo3.minutebox.xyz'],
})


manager.recognize('DEMO4', {
  name: 'Demonstration Server 4',
  projectId: 'minutebox-demo4',
  apiKey: 'AIzaSyD4G1J-hwyF2XEBAd1ndX8RlFrQa2YpSQk',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
})

manager.recognize('WEEKLY', {
  name: 'Weekly Master',
  projectId: 'minutebox-weekly',
  apiKey: 'AIzaSyCv5x8lF4awM06sBg_BcfViSl19zVPfA0k',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['weekly.minutebox.xyz']
})

manager.recognize('NIGHTLY', {
  name: 'Nightly Master',
  projectId: 'minutebox-nightly',
  apiKey: 'AIzaSyB3bDCJb87gBtMirww_OFFg0fkc-CeRYkY',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['nightly.minutebox.xyz']
})

manager.recognize('ALPHA', {
  name: 'Alpha Release',
  projectId: 'minutebox-alpha',
  apiKey: 'AIzaSyArcOGeakYtnAU2_YN9RhUoa4hVveog_Ng',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
  hosts: ['alpha.minutebox.com'],
})

manager.recognize('USCENTRAL', {
  name: 'US Central',
  projectId: 'minutebox-prod-us-central',
  apiKey: 'AIzaSyBTpNmRGJvYGNHYmbAcDaeq48rk7bZLSGY',
  dsn: 'production',
  failsafePublicKey: null,
})

manager.recognize('EUGERMANY', {
  name: 'Europe (Germany)',
  projectId: 'minutebox-prod-de',
  apiKey: 'AIzaSyAz7qOdHXbJT-kMGRwTp8Dk_molujwyr20',
  dsn: 'production',
  hosts: ['eu.minutebox.com'],
  failsafePublicKey: null,
})

// EU hosting with CA cloud storage.
manager.recognize('CANADA', {
  name: 'Canada (via Europe (Germany))',
  projectId: 'minutebox-prod-de',
  apiKey: 'AIzaSyAz7qOdHXbJT-kMGRwTp8Dk_molujwyr20',
  storageBucket: 'minutebox-prod-de-ca',
  dsn: 'production',
  failsafePublicKey: null,
  hosts: ['app.minutebox.com', 'stable.minutebox.com', 'beta.minutebox.com']
})

manager.recognize('RELEASE_2_1_0', {
  name: 'Release 2.1.0',
  projectId: 'minutebox-r-2-1-0',
  apiKey: 'AIzaSyAkNeuDyyS11SYMipioVzryGVp9ihSTp3I',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
})

manager.recognize('RELEASE_2_2_0', {
  name: 'Release 2.2.0',
  projectId: 'minutebox-r-2-2-0',
  apiKey: 'AIzaSyDXfs98euZFrVtAaBM4a0BOeuAnjNS2dxY',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
})

manager.recognize('RELEASE_3_0_0', {
  name: 'Release 3.0.0',
  projectId: 'minutebox-r-3-0-0',
  apiKey: 'AIzaSyBRzFtl9fhOW8cqStqPObiShqhn1lNluxM',
  failsafePublicKey: UNSAFE_FAILSAFE_KEY,
})

export default manager
