import { getUnixTime } from 'date-fns/esm'
import { getUrlFile } from 'request/file'

import { FileModel } from 'FileModel'
import { fileSha512HexDigest } from 'jcrypto'
import UserModel from 'UserModel'
import KeyMaster from 'KeyMaster'
import TeamFilterList from 'TeamFilterList'
import AccountModel from 'AccountModel'
import { keyPathForModel } from 'utils/firestore'

/**
 * Manage the identity & authentication to a given Firebase setup.
 *
 * AuthManagers are intended to map 1:1 with jurisdictions (and possibly with
 * large clients and/or specific purposes e.g. demos).
 *
 * For example, there may be an AuthManager for:
 *  1. demos
 *  2. Canada
 *  3. USA
 *  4. Europe
 * etc.
 *
 * Each AuthManager manages a single Firebase app, which in turn has a user
 * authentication associated with it.
 *
 * A user may in turn be authorized against multiple different apps in a
 * single Firebase app.
 */
export default class AuthManager {
  projectID: string
  accountID: KnockoutObservable<string>
  userFirebaseClaims: KnockoutObservable<FirebaseUserClaims>
  userIsAdmin: KnockoutComputed<boolean>
  termsOfServiceDate: KnockoutObservable<string>
  latestTermsOfServiceAgreed: KnockoutObservable<boolean> = ko.observable(false)

  userDocRef: firebase.firestore.DocumentReference
  userDoc: KnockoutObservable<any>

  isFirstSession: boolean
  lastSessionStart: UnixTimestamp
  lastSessionBuildNumber: number

  firebase: firebase.app.App
  firebaseUser: KnockoutObservable<firebase.User>
  firestore: firebase.firestore.Firestore
  storage: firebase.storage.Storage

  /**
   * @param {string} identifier The email or other identifier of the user
   * @param {ServiceServer} host The root URL
   */
  constructor (appConfig, firebaseFunctionVersion = window.FBFN_VER) {
    if (location.hostname !== 'localhost' && window.Sentry) {
      window.Sentry.init({
        dsn: appConfig.sentryDsn,
        beforeBreadcrumb (breadcrumb, hint) {
          if (breadcrumb.category === 'ui.click') {
            const { target } = hint.event
            if (target) {
              breadcrumb.message += ` "${target.textContent}"`
            }
          }
          return breadcrumb
        },
      })
    }

    Object.assign(this, {
      firebaseFunctionVersion,
      availableAccountIDs: ko.observableArray([]),
      firebaseConfig: appConfig.firebase,
      firebaseUser: ko.observable(Symbol('Initial Firebase User State')),
      userFirebaseClaims: ko.observable(),
      termsOfServiceDate: ko.observable(),
      userDoc: ko.observable(),
      keyMaster: new KeyMaster()
    })

    this.setupFirebase(appConfig)

    this.userIsAdmin = ko.computed(() => this._userIsAdmin())
  }

  /**
   * @return {boolean} true when the authManager has not yet
   * completed verification that user is (or is not) logged in.
   */
  get userIsLoading () {
    return typeof this.firebaseUser() === 'symbol'
  }

  /**
   * When testing we often will not have access to the firebase resources,
   * so those are set aside here for stubbing.
   */
  setupFirebase (appConfig) {
    const { projectId, apiKey } = appConfig.firebase
    const firebase = this.initializeApp(appConfig.firebase, projectId)
    const lastAccountIDKey = `mb.${apiKey}.lastAccountID`

    Object.assign(this, {
      firebase,
      accountID: ko.observable().extend({ localStorage: lastAccountIDKey }),
      projectID: projectId,
      firestore: firebase.firestore(),
      storage: firebase.storage()
    })

    // this.firestore.settings({ timestampsInSnapshots: true })

    firebase.auth().onAuthStateChanged(u => this.onAuthStateChange(u))
  }

  onAuthStateChange (fbUser) {
    if (fbUser) {
      console.info(`
        🔑  [AuthManager/${this.projectID}] Authenticated.
          uid: ${fbUser.uid}
          email: ${fbUser.email}
      `)
      this.subscribeToUserTokenUpdates(fbUser)
    } else {
      console.info(`
        🔒  [AuthManager] There is no current firebase user.
      `)
      this.firebaseUser(null)
    }
  }

  initializeApp (config, projectID) {
    return firebase.initializeApp(config, projectID)
  }

  get teamFilters () {
    Object.defineProperty(this, 'teamFilters', {
      value: new TeamFilterList(this)
    })
    return this.teamFilters
  }

  get userEmail () { return this.userFirebaseClaims()?.email }
  get userID () { return this.userFirebaseClaims()?.user_id }

  /**
   * Monitor for updates to the user.
   */
  async subscribeToUserTokenUpdates (user) {
    let sessionStarted = false
    const userDocPath = `/users/${user.uid}`
    const claimsTrigger = `/users/${user.uid}/meta/terminal`

    const refreshToken = async () => {
      console.info(`[AuthManager] Updating user ID Token`)
      await user.getIdToken(true)
      const { claims } = await user.getIdTokenResult()

      console.log(`
        [AuthManager] User ${user.email} claims:
          ${JSON.stringify(claims.accounts)}
      `)
      const accountIDs = Object.keys(claims.accounts || {})

      if (!sessionStarted) {
        await this.beforeSessionStart(claims)
        sessionStarted = true
      }

      if (!this.firebaseUser() !== user) {
        this.firebaseUser(user)
      }

      this.affirmAccountID(claims.accounts, user.uid)
      this.availableAccountIDs(accountIDs)
      this.userFirebaseClaims(claims)
    }

    const onErr = async err => console.error(`
      [AuthManager] 🔥 Snapshot cancelled for '${claimsTrigger}.
        Reload may be required. Details:'
    `, err, await user.getIdTokenResult())

    // Available accounts updated.
    this.firestore.doc(claimsTrigger).onSnapshot(refreshToken, onErr)
    this.userDocRef = this.firestore.doc(userDocPath)
    this.userDocRef.onSnapshot(d => this.userDoc(d.data()))
  }

  /**
   * Ensure that the selected account is one that the user has access to,
   * or a default account is selected.
   * @param {Array.<string>} accountIDs
   */
  affirmAccountID (accounts: UserAccountsClaim, uid) {
    const accountIDs = Object.keys(accounts || {})
    const accountIDsAsUser = Object.entries(accounts || {})
      .filter(([k, a]) => a.teamFilterKeys || a.admin)
      .map(([k]) => k)

    if (accountIDs.length === 0) {
      console.warn(`User ${uid} authenticated, but has access to no accounts.`)
      this.accountID(null)
      return
    }

    if (accountIDsAsUser.length > 1) {
      console.error(`
        User is authenticated to multiple accounts. This is disabled.

        UID: ${uid}
        Accounts: ${accountIDs.join(', ')}
      `)
      throw new Error(`Multi-account login not yet supported.`)
    }

    this.accountID(accountIDsAsUser[0])
    console.info(`
      [AuthManager] Auto-selected ${uid} accountID:`, this.accountID())
  }

  /**
   * On a session start we perform a number of things e.g.
   *  1. While our `help.minutebox.com` is private, we use a signed domain
   *     cookie __session value, so set that here.
   *  2. Load the latest terms of service from the server
   *  3. Load the session document for the user
   *  4.
   *   a) set `this.lastSessionStart` to the Timestamp value of the
   *      last session start.
   *   b) Update `/sessions/{userID}` with the Timestamps/email.
   *   c) Ensure the user has agreed to the Terms
   */
  async beforeSessionStart (claims) {
    console.debug(`[AuthManager] Session started.`)
    // 1.
    const tld = location.host.split('.').slice(-2).join('.')
    this.firebaseFn('proxyToHelpScoutJwsGenerator').then(value => {
      document.cookie = `__session=${value}; domain=.${tld}; path=/`
    })

    // 3.
    console.debug(`[AuthManager] Getting prior session.`)
    const sesDocRef = this.firestore.doc(`/sessions/${claims.user_id}`)

    // 4. a)
    console.debug(`[AuthManager] Setting new session`)

    try {
      const sesDoc = await sesDocRef.get()
      this.isFirstSession = !sesDoc.exists
      const lastSession = sesDoc.data() || {}
      this.lastSessionStart = lastSession.session_start
      this.lastSessionBuildNumber = lastSession.build_number
      this.termsOfServiceDate(lastSession.termsOfServiceDate || '')
    } catch (err) {
      console.error(`[AuthManager] Cannot get /sessions/${claims.user_id}`)
      return
    }

    console.info(`Last Session Info:
      isFirstSession:         ${this.isFirstSession}
      lastSessionStart:       ${this.lastSessionStart}
      lastSessionBuildNumber: ${this.lastSessionBuildNumber}
    `)

    // 4. b)
    try {
      await sesDocRef.set({
        session_start: getUnixTime(new Date()),
        seen: getUnixTime(new Date()),
        email: claims.email,
        termsOfServiceDate: this.termsOfServiceDate(),
      }, { merge: true })
    } catch (err) {
      console.error(`[AuthManager] Cannot set /sessions/${claims.user_id}`)
    }

    this.termsOfServiceDate.subscribe(termsOfServiceDate => {
      sesDocRef.set({ termsOfServiceDate }, { merge:true })
    })

    /**
     * Write the claims to the session for ease of debugging
     */
    this.userFirebaseClaims.subscribe((uc: FirebaseUserClaims) => {
      if (!uc) { return }
      const latestClaims = uc.accounts || {}
      const accounts = Object.keys(latestClaims) // for easy grep
      sesDocRef.update({ latestClaims, accounts })
      //        ^^ update overwrites these values; `merge` concatenates/melds.
    })
  }

  /**
   * Attempt to set the account ID.
   */
  async setAccountID (accountID) {
    // TODO: Check the /accounts/accountID/user/{firebaseUser}
    this.accountID(accountID)
  }

  /**
   *          FIREBASE 🔥  ⛺️
   */

  /**
   * @param {string} name
   * @return {string} version + name
   */
  _firebaseFunctionName (name) {
    const { firebaseFunctionVersion } = this
    return firebaseFunctionVersion
      ? `${firebaseFunctionVersion}-${name}` : name
  }

  /**
   * Call a Firebase Function
   * @param {string} name of the function to call
   * @param {Array.<any>} arguments to the function
   */
  async firebaseFn (name, ...params) {
    const fn = this._firebaseFunctionName(name)
    console.debug(`firebaseFn(${fn}`, params, ')')
    const functions = this.firebase.functions()
    try {
      const result = await functions.httpsCallable(fn)(...params)
      console.debug(`firebaseFn(${fn}) => `, result)
      // if (typeof result === 'object' && result.status !== 'ok') {
      //   console.error(`${name} failed: `, status)
      // }
      return result.data
    } catch (err) {
      if (err.code === 'internal') {
        console.error(`
        Firebase Function ${name} on ${this.projectID} reported an internal error.

        This can be caused when the function is not deployed.`)
      }
      throw err
    }
  }

  /**
   * @return {string} the region (for Firebase Functions).
   * Note: https://stackoverflow.com/questions/52094605
   */
  get firebaseRegion () {
    return this.firebase.functions().region_
  }

  /**
   * @return {bool} true when the user is an admin of the given (or
   * selected) accountID.
   */
  _userIsAdmin () {
    const accountID = this.accountID()
    const { accounts } = this.userFirebaseClaims() || {}
    return accounts
      ? (accounts[accountID] || {}).admin
      : false
  }

  /**
   * A user is "homeless" if they have an active account but
   * no `teamFilterKeys` property (or `admin=true` permission).
   *
   * A homeless user is an "ESR", or share recipient-only i.e. has no
   * "home" account.
   */
  get userIsHomeless () : boolean {
    const accountID = this.accountID()
    if (!accountID) { return true }
    const { accounts } = this.userFirebaseClaims() || {} as any
    if (!accounts) { return false }
    const { teamFilterKeys, admin } = accounts[accountID] || {} as any
    if (admin) { return false }
    return !teamFilterKeys
  }

  /**
   * @return {UserModel} of the current user
   */
  async getCurrentUserModel (accountID = this.accountID()) {
    const uid = this.firebaseUser().uid
    try {
      const firestoreKey = keyPathForModel(accountID, uid, UserModel.vmResourceName)
      return await UserModel.vmGet(this, firestoreKey)
    } catch (err) { console.warn(`Error getting current user.`) }
  }

  /**
   * Test whether the (shared) user has a specific permission.
   */
  userPermit (model: CrudModel, email: string, permission: PERMISSION_AUTHORITY) {
    if (model.accountID() === this.accountID()) { return true }
    const rights = model.sharing.getRightsOf(email)
    if (!rights) { return false }
    const permits = rights.permits()
    if (permission === 'any') { return permits.length > 0 }
    if (permits.find(p => p.target === '*')) { return true }
    if (permission === 'eis' || permission === 'book') {
      return permits.find(p =>
        p.target === 'content' && p.params[permission]) || false
    }
    return permits.find(p => p.target === permission) || false
  }

  /**
   * This ought to mirror the `userCan` in our Firestore rules.
   */
  userCan (model: CrudModel, permChar: PERMISSION_CHARACTER, claims = this.userFirebaseClaims().accounts[model.accountID()]) {
    if (claims.admin) { return true }
    const okTeams = model.teamFilterKeyPermissions()?.[permChar]
    const teamFilterKeys = claims.teamFilterKeys || []
    if (!okTeams || !teamFilterKeys) { return false }
    return Boolean(teamFilterKeys.find(t => okTeams.includes(t)))
  }

  /**
   * When a user has had their permissions updated, this is needed
   * to update the claims their token carries.
   *
   * See:
   *    https://firebase.google.com/docs/auth/admin/custom-claims
   *
   * @return {object} The updated claims
   */
  async firebaseReloadUserClaims () {
    const user = this.firebaseUser()
    await user.getIdToken(true)
    return (await user.getIdTokenResult()).claims
  }

  firestoreAccountPath (accountID = this.accountID()) {
    return `/accounts/${accountID}`
  }

  /**
   * @param {Array.<string>} Path to the Firestore collection
   */
  firestoreCollection (...paths) {
    const fullPath = [this.firestoreAccountPath(), ...paths].join('/')
    return this.firestore.collection(fullPath)
  }

  /**
   * @param {Array.<string>} Path to the Firestore document
   */
  firestoreDoc (...paths) {
    const fullPath = [this.firestoreAccountPath(), ...paths].join('/')
    return this.firestore.doc(fullPath)
  }

  async getAccountModel (accountID = this.accountID()) : Promise<AccountModel> {
    const path = AccountModel.amGetAccountPath(accountID)
    const values = {
      created: new Date().toISOString()
    }
    return await AccountModel.vmGetOrCreate(this, path, values)
  }

  //      📦 📦 📦  Storage

  /**
   * Write a file to the datastore.  We store files by their SHA-256 hash
   * to eliminate duplicate file uploads.
   *
   * @param {File} file to be saved
   * @param {object} metadata for the file
   * @param {string} accountID to save to
   * @return {FileStatus}
   *
   * Test with defaultAuthManager
   * >>> blob = new Blob([JSON.stringify({someContent: 1}, null, 2)], {type : 'application/json'});
   *
   * >>> defaultAuthManager.storagePut(blob)
   *
   * >>> defaultAuthManager
   *  .storageGet('8e794a2e8e1b4ae0d1f3a7229570b4ac0d7bc17e8e776578bfebe76fa2dfd56a')
   */
  async storagePut (file, customMetadata = {}, accountID = this.accountID()) {
    const sha512sum = await fileSha512HexDigest(file)
    const maxCacheAge = 60 * 60 * 24 * 7
    const metadata = {
      customMetadata,
      contentType: file.type,
      cacheControl: `private, max-age=${maxCacheAge}, immutable`
    }
    const fileModelID = FileModel.fmIdForSha512(sha512sum)
    const fileModelParams = {
      accountID,
      sha512sum,
      id: fileModelID,
      name: file.name || '',
      type: file.type,
      size: file.size,
      status: FileModel.NEW }
    const fsKey = FileModel.vmFirestoreDocPath(fileModelID, accountID)
    const fileModel = await FileModel.vmGetOrCreate(this, fsKey, fileModelParams)
    return fileModel.fmUpload(file, metadata)
  }

  /**
   * Get a file from the datastore.
   * @param {string} sha512sum of the file to get
   * @param {string} accountID of the file to get
   * @param {string} readAs parameter for `getUrlFile` (ObjectURL, DataURL,
   *    Text, BinaryString, ArrayBuffer)
   */
  async storageGet (sha512sum, accountID = this.accountID(), readAs = 'ObjectURL') {
    const fileModelID = FileModel.fmIdForSha512(sha512sum)
    const fsKey = FileModel.vmFirestoreDocPath(fileModelID, accountID)
    const fileModel = await FileModel.vmGet(this, fsKey)
    await fileModel.statusNumber.when(FileModel.COMPLETE)
    return getUrlFile(await fileModel.fmStorageUrl(), readAs)
  }

  /**
   * Google Cloud (proper) Functions.
   *
   * Where we can't use firebase's function-call (because e.g. we're using
   * Python functions, which currently don't have an onCall trigger),
   * we can use this.
   *
   * @param {string} name of the function.
   * @param {any} body to be POST'ed to the function as JSON.
   * @return {Response}
   */
  async callCloudFunction (name, body) {
    const region = this.firebaseRegion
    const { projectID } = this
    const url = `https://${region}-${projectID}.cloudfunctions.net/${name}`
    const authorization = await this.firebase.auth().currentUser.getIdToken()
    const headers = {
      authorization,
      'Content-Type': 'application/json; charset=utf-8'
    }

    const response = await fetch(url, {
      method: 'POST',
      headers,
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(body)
    })

    return response
  }
}
