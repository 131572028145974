
const MERGE = { merge: true }

export async function updateUserBuildNumber (app, userID) {
  const sesDoc = app.defaultAuthManager.firestore
    .doc(`/sessions/${userID}`)
  const data = { build_number: buildNumber() }
  return sesDoc.set(data, MERGE)
}


export function buildNumber () {
  return global.ABOUT_MINUTEBOX.BUILD_NUMBER
}
