
import ViewComponent from 'ViewComponent'
import { buttons, color } from 'styles'
import icons from 'icons'
import checkedIcon from 'icons/light/check-square'
import uncheckedIcon from 'icons/light/square'

export default class AutoNumber extends ViewComponent {
  private editable: KnockoutObservable<boolean>
  private intTarget: KnockoutObservable<number>
  private target: KnockoutObservable<number>
  private currentAutoNumber: () => number

  constructor ({ target, currentAutoNumber, classes }) {
    super()
    const int10 = v => parseInt(v, 10)
    Object.assign(this, {
      currentAutoNumber,
      target: target,
      intTarget: target.extend({ strict: int10 }),
      editable: ko.observable(Boolean(target())),
    })

    Object.assign(this.jss, classes)
  }

  static get css () {
    return {
      area: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        width: '100%',
        minHeight: '25px',
        alignItems: 'center',
      },
      number: {
        border: `1px solid ${color.separator.light.nonOpaque}`,
        padding: '8px 20px',
        width: 100,
        borderRadius: 6,
        color: color.text.light.secondary,
        backgroundColor: color.textInput.light.tertiary,
        'body[dark] &': { // project batman
          border: `1px solid ${color.separator.dark.nonOpaque}`,
          color: color.text.dark.secondary,
          backgroundColor: color.textInput.dark.tertiary,
        },

       },
      input: {
        border: '1px solid transparent',
        minWidth: '75px',
        width: 100,
        padding: '8px 20px',
        boxShadow: '0 1px 2px 1px rgba(0,0,0,0.3)',
        borderRadius: 6,
        fontWeight: 600,
        fontSize: '1em',
        outline: 'none',
        'body[dark] &': { // project batman
          backgroundColor: color.textInput.dark.primary,
          color: color.text.dark.primary,
          border: `1px solid ${color.separator.dark.nonOpaque}`
        },
      },
      indicator: {
        padding: '0px 5px',
        fontWeight: 'normal',
        fontSize: '0.8rem',
      },
      toggle: {
        ...buttons.clickable,
        padding: '0px 4px 0px 25px',
        lineHeight: '1em',
        fontSize: '0.8rem',
        fontWeight: '500'
      },
      toggleIcon: {
        ...buttons.clickable,
      },
    }
  }

  autoNumberHTML (icon) {
    const { jss } = this
    return (
      <>
        <div class={jss.toggle}
          ko-ownClick={() => this.editable.modify(v => !v)}>
          Auto Number
        </div>
        <div class={jss.toggleIcon}
          ko-ownClick={() => this.editable.modify(v => !v)}>
          {icons.inline(icon)}
        </div>
      </>
    )
  }

  get inputHTML () {
    const { jss } = this
    return (
      <>
        <input type='number'
          class={jss.input}
          ko-textInput={this.intTarget} />
        {this.autoNumberHTML(uncheckedIcon)}
      </>
    )
  }

  get autoHTML () {
    const { jss } = this
    this.target(null)
    return (
      <>
        <div class={jss.number}>{this.currentAutoNumber()}</div>
        {this.autoNumberHTML(checkedIcon)}
      </>
    )
  }

  get template () {
    const html = this.computed(
      () => this.editable() ? this.inputHTML : this.autoHTML)
      .extend({ deferred: true })
    return (
      <div class={this.jss.area}>
        {html}
      </div>
    )
  }
}

AutoNumber.register()
