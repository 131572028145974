import { noop } from 'lodash-es'

import { buttons } from 'styles'

import ModalDialog from './modal-dialog'

export default class OkModalDialog extends ModalDialog {
  onOk: () => void

  constructor (params) {
    super(params)
    Object.assign(this, { onOk: params.onOk || noop })
  }

  get keyboardShortcuts () {
    return [
      ...super.keyboardShortcuts,
      { key: 'Enter', action: () => this.dismiss() }
    ]
  }

  static get css () {
    return {
      ...super.css,

      confirmButton: {
        ...buttons.modalOk,
      },

      contentGrid: {
        display: 'grid',
        gap: '20px',
      },

      contentValue: {
      },

      contentButtons: {
        justifySelf: 'right',
      },
    }
  }

  get contentHTML () {
    const { jss } = this
    return (
      <div class={jss.contentGrid}>
        <div class={jss.contentValue}>{super.contentHTML}</div>
        <div class={jss.contentButtons}>
        <async-button faceClass={jss.confirmButton}
          action={() => this.onOk()}>
            <template slot='face'>Ok</template>
          </async-button>
        </div>
      </div>
    )
  }
}


OkModalDialog.register()
