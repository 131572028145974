
import { hashHSL } from 'utils/string'

import { UX_STATE } from './slots/interfaces'
import { transforms } from './transforms'

export type UnitView = import('DataModel/components/UnitView').default
export type SlotManager = import('./slots').SlotManager
type Slot = import('./slots').Slot
type SlotVariable = import('./slots/interfaces').SlotVariable
type Value = string | KnockoutSubscribable<string> | boolean
type VariableTransform = import('./transforms').VariableTransform

// FIXME: Prefer Object.defineProperty to [Symbol]
const NODE_SYM = Symbol('Variable HTMLElement bound to')
const BG_SAT_LUMEN = {
  s: [50, 100],
  l: [75, 80],
}

const BORDER_SAT_LUMEN = {
  s: [50, 100],
  l: [30, 50],
}


export abstract class Variable extends ko.LifeCycle implements SlotVariable {
  slot: KnockoutObservable<Slot>
  uxState: KnockoutObservable<UX_STATE>
  slotManager: SlotManager
  [NODE_SYM]: HTMLElement
  slotIndex = ko.observable<number|string>(0)
  transforms = ko.observableArray<VariableTransform>([])

  get boundToElement (): HTMLElement { return this[NODE_SYM] }
  set boundToElement (v) { this[NODE_SYM] = v }

  /**
   * The `slotManager` tracks all the variables, for peer-updates on changes.
   * It's omitted in some cases, e.g. on `InjectVariableCommand` where there
   * are no peers.
   */
  constructor (slotManager: SlotManager) {
    super()
    const slot = ko.observable()
    const uxState = ko.observable()
    Object.defineProperties(this, {
      'slotManager': { enumerable: false, value: slotManager },
      'slot': { enumerable: false, value: slot },
      uxState: { enumerable: false, value: uxState },
    })
  }

  dispose () {
    super.dispose()
    this.slotManager.removeVariable(this)
  }

  getValueOrImpliedValue (name: string) {
    const givenValue = this[name]()
    const sm = this.slotManager
    return (givenValue !== undefined && givenValue !== null)
      ? givenValue
      : sm.getVariableValue(name)
  }

  abstract get isCohate (): boolean
  abstract get incohateText (): string
  abstract value (): Value
  abstract get code (): string
  abstract get slotTitleText (): string

  incohateTextWithTransforms () { return this.applyTransforms(this.incohateText) }
  valueWithTransforms () { return this.applyTransforms() }

  get transformedResult () {
    return this.isCohate
      ? this.valueWithTransforms()
      : this.incohateTextWithTransforms()
  }

  protected applyTransforms (str = ko.unwrap(this.value()), toApply = this.transforms()) {
    return this.runTransforms(str,
      [this.leadTransforms, toApply, this.tailTransforms].flat())
  }

  private runTransforms (str: string, toApply: VariableTransform[]) {
    for (const t of toApply) {
      if (!t) { continue }
      const fn = transforms[t.type]
      if (!fn) { console.error(`Variable: Bad transform: ${t.type}`, t) }
      str = fn(str, this, t)
    }
    return str
  }

  protected get leadTransforms () { return [] }
  protected get tailTransforms () { return [] }

  abstract get slotGroupTitle (): string
  get hasSlots () { return false }
  isInSlot (slot: Slot) { return this.slot() === slot }

  getSlot () { return this.slot() }
  setSlot (slot: Slot) {
    slot.add(this)
    this.slot(slot)
    this.slotIndex(slot.index)
  }

  setSlotByIndex (index: number | string) {
    this.slot(this.slotManager.setIndexOf(this, index))
  }

  get slotIndexText () {
    const slot = this.slot()
    return slot
      ? this.slotManager.slotGroupFor(this).indexTextOfSlot(slot)
      : ''
  }

  protected abstract propagate (to: Variable): void
  propagateTo (...items: this[]) {
    for (const item of items) { this.propagate(item) }
  }

  /**
   * The slot group name identifies the group of slots for this variable.
   */
  get slotGroupCode () { return this.code }
  get slotGroup () {
    return this.slotManager.slotGroupByCode(this.slotGroupCode)
  }

  onBindingToDOM (e: HTMLElement) {
    this.boundToElement = e
    this.setSlot(this.slotManager.addVariable(this))
  }

  /**
   * If this variable is no longer referenced from the DOM, remove it.
   *
   * Note that a variable can be referenced in multiple places, such as
   * in the document, in the slots view, etc.
   *
   * When removed from the document, we want to remove the other references,
   * but not the other way around (since the slots elements are added
   * and removed as they changes).
   */
  disconnectFromDOM (node: HTMLElement) {
    if (node !== this.boundToElement) { return }
    this.boundToElement = null
    this.slotManager.removeVariable(this)
  }

  emitInputNotification () {
    const e = this.boundToElement
    if (!e) { return }
    const ce = e.closest('[contenteditable=false]')
    if (!ce) { return }
    ce.dispatchEvent(new CustomEvent('needssave'))
  }

  get isAttachedToDOM (): boolean {
    return this.boundToElement && document.body.contains(this.boundToElement)
  }

  get whenLoaded (): Promise<any> { return Promise.resolve(null) }
  serialize () { return { code: this.code, ...ko.toJS(this) } }

  /**
   * Assign any variables to this.  Note any items in `slotGroupCode` must be
   * assigned in a subclass must be assigned before `slotIndex` (here),
   * otherwise the `slotManager` won't know what index to use for the
   * `slotIndex`.
   */
  assignVariables (vars: any) {
    for (const [k, v] of Object.entries(vars)) { if (this[k]) { this[k](v) } }
    if ('slotIndex' in vars) {
      this.slotManager.setIndexOf(this, vars.slotIndex)
    }
    return this
  }

  get slotPeers () {
    const group = this.slotManager.slotGroupFor(this)
    if (!group) { return [] }
    const slot = group.getSlotFor(this)
    return [...slot].filter(v => v !== this)
  }

  onHoverChange (out = false) { this.changeUxState(UX_STATE.hover, !out) }

  /**
   * Modify the bitwise representation of the state.
   * @param state Bitwise representation of state(s)
   * @param add add or remove the state
   */
  changeUxState (state: UX_STATE, add: boolean) {
    this.uxState.modify(s => add ? (s | state) : (s & ~state))
  }

  get color () {
    return {
      bg: hashHSL(this.slotGroupCode, BG_SAT_LUMEN),
      border: hashHSL(this.slotGroupCode, BORDER_SAT_LUMEN),
    }
  }
}

export default Variable
