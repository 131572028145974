import ViewComponent from 'ViewComponent'

import mbCircle from './mb-circle'

class LoadingSpinner extends ViewComponent {
  static get css () {
    return {
      beat: {
        position: 'relative',
        width: '100%',
        height: 'var(--icon-height, inherit)',
        animation: 'loading-spinner 3s ease-out infinite',
        '& [left-bracket]': {
          animation: 'beat-left 3s ease-out infinite',
        },
        '& [right-bracket]': {
          animation: 'beat-right 3s ease-out infinite',
        }
      }
    }
  }

  get template () {
    mbCircle.attributes.class += ' ' + this.jss.beat
    return mbCircle
  }
}

LoadingSpinner.register()

class LoadingSpinnerPage extends LoadingSpinner {
  static get css () {
    return {
      ...super.css,
      container: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '--icon-height': '42px',
      },
    }
  }
  get template () {
    const { jss } = this
    return (
      <div class={jss.container}>
        {super.template}
      </div>
    )
  }
}

LoadingSpinnerPage.register()
